import React from 'react';
import { Typography } from '@shipengine/giger';
import { getAppHeaderPanelItemStyles } from './AppHedarPanelItemStyles';

interface IAppHeaderPanelItemProps {
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const AppHeaderPanelItem: React.FC<IAppHeaderPanelItemProps> = (
    props: IAppHeaderPanelItemProps,
): JSX.Element => {
    const { children, onClick } = props;

    return (
        <Typography css={getAppHeaderPanelItemStyles} component="li" variant="body2" onClick={onClick}>
            {children}
        </Typography>
    );
};
