import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const spinnerStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        marginTop: theme.spacing(5),
    });

export const contentStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(4),
    });

export const paginationStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    });
