import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonSize, InlineNotification, NotificationType } from '@shipengine/giger';
import { GTMEvents } from '@packlink/metrics';

import { SUBSCRIPTION_PLAN } from '@Subscriptions/constants';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { getSubscriptionNoticeStyles } from './SubscriptionNoticeStyles';
import { useDateFormat } from '@hooks/useDateFormat';
import { APP_ROUTE } from '@pages/router/routes';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useAmplitude } from '@hooks/useAmplitude';

interface ISubscriptionNoticeProps {
    activeMessage: string;
    planName: string;
    expirationDate?: string;
    isSubscriptionActive: boolean;
    isSubscriptionCancelled: boolean;
    section: string;
}

export const SubscriptionNotice: React.FC<ISubscriptionNoticeProps> = (
    props: ISubscriptionNoticeProps,
): JSX.Element => {
    const { activeMessage, planName, expirationDate, isSubscriptionActive, isSubscriptionCancelled, section } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const endDate = useDateFormat(expirationDate);
    const { sendGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const planDisplayName = t('subscriptions.plan.display-name', { planName });

    const [noticeType, title, message] = isSubscriptionCancelled
        ? [
              NotificationType.ALERT,
              t('subscription-notification.cancelled.title'),
              t('subscription-notification.cancelled.message', { expirationDate: endDate }),
          ]
        : isSubscriptionActive
          ? [
                NotificationType.SUCCESS,
                t('subscription-notification.active.title', { planName: planDisplayName }),
                t(activeMessage),
            ]
          : [
                NotificationType.INFO,
                t('subscription-notification.not-active.title', { planName: planDisplayName }),
                t('subscription-notification.not-active.message', { planName: planDisplayName }),
            ];

    const hideButton = isSubscriptionCancelled || isSubscriptionActive;

    const goToSubscription = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.SUBSCRIPTION_ACTIVATE, { [AmplitudeProperties.SECTION]: section });

        sendGtmEvent(GTMEvents.UPGRADE_ACCOUNT, {
            action: GTMActionFieldOption.UPGRADE_ACCOUNT_INTERESTED,
            typeOfPlan: SUBSCRIPTION_PLAN.PRO_PLUS,
        });
        navigate(APP_ROUTE.SUBSCRIPTIONS.INDEX);
    };

    const goToSubscriptionButton = (
        <Button size={ButtonSize.SMALL} onClick={goToSubscription}>
            {t('subscription-notification.subscribe.action')}
        </Button>
    );

    return (
        <InlineNotification
            css={getSubscriptionNoticeStyles}
            title={t(title)}
            type={noticeType}
            actionElement={hideButton ? undefined : goToSubscriptionButton}
        >
            {message}
        </InlineNotification>
    );
};
