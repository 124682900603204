import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export function getAppFooterStyles(theme: Theme): SerializedStyles {
    return css({
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        minWidth: '100%',
        height: '30px',
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
        background: theme.palette.gray.ultraLight,
        borderColor: theme.palette.gray.light,
        borderStyle: 'solid',
        borderWidth: '1px 0 0',
    });
}

export function getAppFooterTextRightStyles(theme: Theme): SerializedStyles {
    return css({
        float: 'right',
        margin: 0,
        color: theme.palette.gray.dark,
        fontWeight: theme.typography.fontWeight.normal,
        fontSize: theme.typography.fontSize.XXS,
        lineHeight: 1.4,

        '*': {
            paddingLeft: theme.spacing(2),
        },
    });
}

export function getAppFooterTextPowerStyles(theme: Theme): SerializedStyles {
    return css({
        display: 'none',

        [theme.mediaQuery('tablet')]: {
            display: 'inline-block',
        },
    });
}

export function getAppFooterTextImpressumStyles(theme: Theme): SerializedStyles {
    return css({
        display: 'none',

        [theme.mediaQuery('tablet')]: {
            display: 'inline-block',
        },
    });
}
