import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getInfoCardStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        alignItems: 'center',
        flex: 1,
        backgroundColor: theme.palette.info.light,
        textAlign: 'center',
        a: {
            fontSize: theme.typography.fontSize.XS,
        },
    });

export const getInfoTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
        fontSize: theme.typography.fontSize.S,
    });
