import { useCallback, useEffect, useState } from 'react';
import {
    Button,
    ButtonSize,
    Icon,
    IconSize,
    InlineNotification,
    NotificationType,
    Table,
    TableBody,
    TableHeader,
    TableHeaderCell,
    TableHeaderRow,
    useToast,
} from '@shipengine/giger';
import { IShippingRule, ShippingRulesRepository } from '@packlink/packlink-sdk';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { apiClient } from '@sdk';
import { useSelector } from 'react-redux';
import { getUserId } from '@store/selectors/user';
import { IconNames } from '@shipengine/giger-theme';
import { EmptyState } from '@components/EmptyState/EmptyState';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';
import { RuleRow } from './components/RuleRow/RuleRow';
import { arrowStyles, getInfoMessageStyles } from './RulesListTabStyles';
import { useShippingRules } from '../../context/shippingRules';

export const shippingRulesRepository = new ShippingRulesRepository(apiClient);

interface IRulesListTabProps {
    onEditRule: (rule?: IShippingRule) => void;
}

export function RulesListTab({ onEditRule }: IRulesListTabProps): JSX.Element {
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const toast = useToast(t);
    const userId = useSelector(getUserId);
    const [userRules, setUserRules] = useState<Array<IShippingRule>>([]);
    const [isInfoMessageOpen, setIsInfoMessageOpen] = useState(true);
    const [isFetching, setIsFetching] = useState(true);
    const { setNumberOfRules } = useShippingRules();

    const {
        cdn: { url: cdnUrl },
    } = config;
    const image = `${cdnUrl}/pro/statics/images/no-results.png`;

    const toggleRule = useCallback(
        (index: number): Promise<void> | void => {
            const ruleToToggle = userRules[index];
            const ruleId = ruleToToggle.id;
            if (!ruleId) return;
            const newRulesList = [...userRules];
            return shippingRulesRepository.enable(userId, ruleId, !ruleToToggle.enabled).then(() => {
                newRulesList[index].enabled = !ruleToToggle.enabled;
                setUserRules(newRulesList);
            });
        },
        [userId, userRules],
    );
    const switchOrder = useCallback(
        (index1: number, index2: number) => {
            const ruleOneId = userRules[index1]?.id;
            const ruleTwoId = userRules[index2]?.id;
            if (ruleOneId && ruleTwoId) {
                const newRulesList = [...userRules];
                shippingRulesRepository.switchOrder(userId, ruleOneId, ruleTwoId).then(() => {
                    [newRulesList[index1], newRulesList[index2]] = [newRulesList[index2], newRulesList[index1]];
                    setUserRules(newRulesList);
                });
            }
        },
        [userId, userRules],
    );

    const deleteRule = (index: number, ruleId: string) => {
        sendAmplitudeEvent(AmplitudeEvents.SHIPPING_RULE_DELETE);
        shippingRulesRepository.delete(userId, ruleId).then(() => {
            toast.success({ message: t('shipping-rules.success.deleted', { alias: userRules[index].alias }) });
            updateUserRules();
        });
    };

    const editRule = (ruleId: string) => {
        sendAmplitudeEvent(AmplitudeEvents.SHIPPING_RULE_EDIT);
        shippingRulesRepository.get(userId, ruleId).then((rule) => onEditRule(rule));
    };

    const goToNewRule = () => {
        sendAmplitudeEvent(AmplitudeEvents.SHIPPING_RULES_NEW_RULE_EMPTY_STATE);
        onEditRule();
    };

    const updateUserRules = useCallback(() => {
        shippingRulesRepository.getAll(userId).then((rules: Array<IShippingRule>) => {
            setUserRules(rules);
            setIsFetching(false);
            setNumberOfRules(rules.length);
        });
    }, [setNumberOfRules, userId]);

    useEffect(() => {
        updateUserRules();
    }, [updateUserRules]);

    return (
        <>
            {isInfoMessageOpen && !!userRules?.length && (
                <InlineNotification
                    type={NotificationType.INFO}
                    title={t('shipping-rules.info.order-title')}
                    closeText={t('toast.action.close')}
                    onClose={() => setIsInfoMessageOpen(false)}
                    css={getInfoMessageStyles}
                >
                    <Trans i18nKey="shipping-rules.info.order-message">
                        <Icon css={arrowStyles} size={IconSize.SIZE_SMALL} name={IconNames.ARROW_TOP} />
                        <Icon css={arrowStyles} size={IconSize.SIZE_SMALL} name={IconNames.ARROW_BOTTOM} />
                    </Trans>
                </InlineNotification>
            )}

            <Table
                header={
                    <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell>{t('shipping-rules.list.rule-name-header')}</TableHeaderCell>
                            <TableHeaderCell>{t('shipping-rules.list.modified-header')}</TableHeaderCell>
                            <TableHeaderCell>{t('shipping-rules.list.active-header')}</TableHeaderCell>
                            <TableHeaderCell>{t('shipping-rules.list.order-header')}</TableHeaderCell>
                            <TableHeaderCell>{}</TableHeaderCell>
                        </TableHeaderRow>
                    </TableHeader>
                }
            >
                {!userRules?.length && !isFetching ? (
                    <EmptyState
                        title={t('shipping-rules.empty-list.no-rules')}
                        subtitle={t('shipping-rules.empty-list.create-rules')}
                        image={image}
                    >
                        <Button size={ButtonSize.SMALL} onClick={goToNewRule} data-id="new-shipping-rules-empty-state">
                            {t('shipping-rules.settings.add-new-rules')}
                        </Button>
                    </EmptyState>
                ) : (
                    <TableBody>
                        {userRules.map((rule: IShippingRule, index: number) => (
                            <RuleRow
                                key={rule.id}
                                rule={rule}
                                index={index}
                                switchOrder={switchOrder}
                                toggleRule={toggleRule}
                                editRule={editRule}
                                deleteRule={deleteRule}
                                isLastRow={index === userRules.length - 1}
                            />
                        ))}
                    </TableBody>
                )}
            </Table>
        </>
    );
}
