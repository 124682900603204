import { Outlet } from 'react-router';
import { Helmet } from 'react-helmet';
import useLocation from 'react-use/lib/useLocation';
import { useTranslation } from '@packlink/translation-provider';
import { getHelpAndSupportContainerStyles, getHelpAndSupportStyles } from '../SupportCenterStyles';

export function SupportCenterLayout() {
    const { t } = useTranslation();
    const state = useLocation();
    // This a kind of ugly hack to ensure that conversation doesn't grow vertically in the ticket details screen
    const useAdjustedHeight = state.pathname?.includes('/support-center/details');

    return (
        <div css={getHelpAndSupportStyles}>
            <div css={getHelpAndSupportContainerStyles(useAdjustedHeight)}>
                <Helmet>
                    <title>
                        {t('support-center.routes.root')} - {t('app.helmet.title')}
                    </title>
                </Helmet>
                <Outlet />
            </div>
        </div>
    );
}
