import { useSelector } from 'react-redux';

import { TabList } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { useTenantConfig } from '@packlink/tenant-config-provider';

import { ITenantConfig } from '@types';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';
import { getHasAvailabilityForDeferredPayment } from '@store/selectors/payment';
import { RouterTab } from '@pages/router/components/RouterTab/RouterTab';

import { InvoicingRoute } from '../../router/routes';
import { getTabListStyles } from './SettingsBillingNavbarStyles';

export const SettingsBillingNavbar = (): JSX.Element => {
    const { t } = useTranslation();

    const { showDeferredPaymentDetails } = useTenantConfig<ITenantConfig>();
    const hasAvailabilityForDeferredPayment = useSelector(getHasAvailabilityForDeferredPayment);

    return (
        <>
            <SettingsPageHeader>{t('billing-and-invoices.settings.title')}</SettingsPageHeader>
            <TabList css={getTabListStyles}>
                <RouterTab tabId="invoices" text={t('settings.billing.invoices-title')} to={InvoicingRoute.INVOICES}>
                    {t('settings.billing.invoices-title')}
                </RouterTab>

                <RouterTab
                    tabId="billingData"
                    text={t('settings.billing.invoicing-title')}
                    to={InvoicingRoute.INVOICING}
                >
                    {t('settings.billing.invoicing-title')}
                </RouterTab>

                {showDeferredPaymentDetails && hasAvailabilityForDeferredPayment && (
                    <RouterTab tabId="payment" text={t('settings.billing.payment-title')} to={InvoicingRoute.PAYMENT}>
                        {t('settings.billing.payment-title')}
                    </RouterTab>
                )}
            </TabList>
        </>
    );
};
