import { useSelector } from 'react-redux';
import { SubscriptionFeatureBehavioursProvider as SubscriptionFeatureBehavioursProviderInternal } from '@providers/SubscriptionFeatureBehavioursProvider';
import { WithCommonProps } from '@shipengine/giger';
import { getClientIdentifier } from '@store/selectors/client';

export function SubscriptionFeatureBehavioursProvider({ children }: WithCommonProps<unknown>): JSX.Element {
    const clientId = useSelector(getClientIdentifier);

    return (
        <SubscriptionFeatureBehavioursProviderInternal clientId={clientId}>
            {children}
        </SubscriptionFeatureBehavioursProviderInternal>
    );
}
