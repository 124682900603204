import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';
import { TicketChatMessageWrapperBulletPosition } from './TicketChatMessageWrapper';
export const getTicketChatMessageWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'relative',
        paddingLeft: theme.spacing(3),
        paddingBottom: theme.spacing(4),
    });

export const getTicketChatMessageWrapperBulletStyles = (theme: Theme): SerializedStyles =>
    css({
        '::after': {
            position: 'absolute',
            left: 0,
            content: "''",
            borderRadius: '50%',
            width: theme.spacing(),
            height: theme.spacing(),
            backgroundColor: theme.palette.gray.light,
            zIndex: theme.elevations.above.zIndex,
        },
    });

export const getTicketChatMessageWrapperBulletPositionStyles =
    (bulletPosition: TicketChatMessageWrapperBulletPosition) =>
    (theme: Theme): SerializedStyles => {
        switch (bulletPosition) {
            case TicketChatMessageWrapperBulletPosition.CENTER:
                return css({
                    '::after': {
                        top: `calc(50% - ${theme.spacing(4) / 2}px)`,
                        transform: 'translateY(-50%)',
                    },
                });
            case TicketChatMessageWrapperBulletPosition.TOP:
            default:
                return css({
                    '::after': {
                        top: theme.spacing(),
                    },
                });
        }
    };

export const getTicketChatMessageWrapperDecorativeLineStyles =
    (bulletPosition: TicketChatMessageWrapperBulletPosition) =>
    (theme: Theme): SerializedStyles => {
        return css({
            '::before': {
                position: 'absolute',
                top: theme.spacing(),
                // Magic number to center the decorative line with the bullets
                left: 3,
                content: "''",
                height:
                    bulletPosition === TicketChatMessageWrapperBulletPosition.CENTER
                        ? `calc(50% - ${theme.spacing(3)}px)`
                        : '100%',
                width: theme.spacing(0.25),
                backgroundColor: theme.palette.gray.light,
            },
        });
    };
