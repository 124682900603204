import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { OwnContractModel, OwnContractRepository } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { ServicesFormValues } from './types';
import { ServiceSelectionForm } from './ServiceSelectionForm';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';

const contractRepository = new OwnContractRepository(apiClient);

export interface ServiceSelectionPanelProps {
    ownContract: OwnContractModel;
    logoUrl: string;
    onClose: () => void;
    onSendSuccess: () => void;
}

export const ServiceSelectionPanel = ({
    onClose,
    onSendSuccess,
    ownContract,
    logoUrl,
}: ServiceSelectionPanelProps): JSX.Element => {
    const [initialValues, setInitialValues] = useState<ServicesFormValues>({});
    const { sendAmplitudeClickEvent } = useAmplitude();

    useEffect(() => {
        const initialServices: ServicesFormValues = {};
        ownContract.services?.forEach((service) => {
            initialServices[service.id] = service.isSelected;
        });
        setInitialValues({ ...initialServices });
    }, [ownContract.services]);

    const handleOnSubmit = (services: ServicesFormValues): void => {
        const servicesIds = Object.keys(services).filter((k) => services[k] === true);

        const servicesNames = ownContract.services
            ?.filter((service) => servicesIds.includes(service.id))
            .map((service) => service.name);

        sendAmplitudeClickEvent(AmplitudeEvents.SAVE_BYOA_SERVICES, {
            [AmplitudeProperties.CARRIER]: ownContract?.carrierName,
            [AmplitudeProperties.SERVICES_NAMES]: servicesNames,
        });

        const newServices = { services: servicesIds, contractModelId: ownContract.id };
        if (ownContract.contractId) {
            contractRepository.linkServices(ownContract.contractId, newServices).then(onSendSuccess).then(onClose);
        }
    };

    return (
        <Formik onSubmit={handleOnSubmit} initialValues={initialValues} enableReinitialize>
            <Form>
                <ServiceSelectionForm logoUrl={logoUrl} onClose={onClose} ownContract={ownContract} />
            </Form>
        </Formik>
    );
};
