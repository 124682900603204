import React from 'react';

import { IconNames, Theme } from '@shipengine/giger-theme';
import { Icon, IconSize } from '@shipengine/giger';

import { getAppHeaderMenuItemStyles, getAppHeaderMenuItemTextStyles, getIconStyles } from './AppHeaderMenuItemStyles';
import { Interpolation } from '@emotion/react';

export interface IAppHeaderMenuItemProps {
    icon: IconNames;
    label: string;
    url?: string;
    target?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    rootCss?: Interpolation<Theme>;
    textCss?: Interpolation<Theme>;
}

export const AppHeaderMenuItem = ({
    label,
    icon,
    url,
    target,
    onClick,
    rootCss,
    textCss,
}: IAppHeaderMenuItemProps): JSX.Element => (
    <a
        css={[getAppHeaderMenuItemStyles, rootCss]}
        role="button"
        title={label}
        href={url}
        target={target}
        onClick={onClick}
    >
        <Icon css={getIconStyles} name={icon} size={IconSize.SIZE_SMALL} />
        <span css={[getAppHeaderMenuItemTextStyles, textCss]}>{label}</span>
    </a>
);
