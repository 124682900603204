import { WithCommonProps } from '@shipengine/giger';
import { CancelAlreadyRequestedNotification } from './CancelAlreadyRequestedNotification';
import { ShipmentNotCancelableNotification } from './ShipmentNotCancelableNotification';
import { ShipmentCancelDeadlineNotification } from './ShipmentCancelDeadlineNotification';
import {
    SupportCenterShipmentCancellationDeadlineReachedError,
    SupportCenterShipmentCancelAlreadyRequestedError,
    SupportCenterShipmentNotCancellableError,
    SupportCenterTicketAlreadyCreatedError,
    SupportCenterShipmentErrorData,
} from './SupportCenterShipmentErrorData';
import { TicketAlreadyCreatedNotification } from './TicketAlreadyCreatedNotification';

type SupportCenterShipmentErrorProps = WithCommonProps<{ error: SupportCenterShipmentErrorData }>;

export function SupportCenterShipmentError(props: SupportCenterShipmentErrorProps): JSX.Element | null {
    const { error, ...otherProps } = props;
    switch (error.constructor) {
        case SupportCenterShipmentCancelAlreadyRequestedError:
            return <CancelAlreadyRequestedNotification {...otherProps} />;
        case SupportCenterShipmentNotCancellableError:
            return <ShipmentNotCancelableNotification {...otherProps} />;
        case SupportCenterShipmentCancellationDeadlineReachedError:
            return <ShipmentCancelDeadlineNotification {...otherProps} />;
        case SupportCenterTicketAlreadyCreatedError: {
            const { ticket } = error as SupportCenterTicketAlreadyCreatedError;
            return <TicketAlreadyCreatedNotification ticket={ticket} {...otherProps} />;
        }
        default:
            return null;
    }
}
