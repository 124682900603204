import { useLoadScript } from '@react-google-maps/api';
import { getUserLanguage } from '@store/selectors/user';
import { useSelector } from 'react-redux';

export function GoogleMapsStarter() {
    const locale = useSelector(getUserLanguage);

    useLoadScript({
        googleMapsApiKey: config.maps.apiKey,
        version: config.maps.version,
        region: locale,
        language: locale,
    });

    return <></>;
}
