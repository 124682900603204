import { SET_STATUS } from '@store/actions/action-types';
import { IStatus } from '@types';

interface ISetStatusAction {
    type: 'SET_STATUS';
    payload: IStatus;
}

export type StatusAction = ISetStatusAction;

export const setAppStatus = (data: IStatus): ISetStatusAction => ({ type: SET_STATUS, payload: data });
