import { applyMiddleware, createStore, AnyAction, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { persistStore } from 'redux-persist';
import thunk, { ThunkAction, ThunkMiddleware, ThunkDispatch } from 'redux-thunk';

import rootReducer, { IAppState } from './reducers';
import { AppAction } from './actions';

export type TAppState = ReturnType<typeof rootReducer>;
export type ThunkResult<ReturnType = void> = ThunkAction<ReturnType, TAppState, void, AppAction>;
export type TDispatch = ThunkDispatch<TAppState, void, AnyAction>;
type TStore = Store<TAppState, AppAction> & { dispatch: TDispatch };

const middleWare = applyMiddleware(thunk as ThunkMiddleware<IAppState, AppAction>);

function configureStore(): TStore {
    return createStore(rootReducer, composeWithDevTools(middleWare));
}

export const store = configureStore();
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
