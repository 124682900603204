import { css, CSSObject, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentPanelRecipientPudoStyles = (): CSSObject => ({
    li: {
        marginRight: 0,
    },
});

export const getPickupNotificationStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });
