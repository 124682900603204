import { PropsWithChildren, useEffect } from 'react';
import { SdkProvider as PacklinkSdkProvider } from '@packlink/packlink-sdk-provider';
import Packlink from '@sdk';
import { useTenant } from '@packlink/tenant-provider';

export function SdkProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
    const tenant = useTenant();

    useEffect(() => {
        Packlink.setTenant(tenant);
    }, [tenant]);

    return <PacklinkSdkProvider sdk={Packlink}>{children}</PacklinkSdkProvider>;
}
