import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getGenerateApiKeysCopyButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });

export const getGenerateApiKeysCopyStyles = (theme: Theme): SerializedStyles =>
    css({
        fontSize: theme.toRem(theme.typography.fontSize.XS),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    });

export const getRemoveButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });
