import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const rowFooterStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 33,
        margin: 0,
        padding: `0 ${theme.spacing(2)}px`,
        borderTop: `1px solid ${theme.palette.gray.light}`,

        [theme.mediaQuery('tablet')]: {
            margin: `${theme.spacing()}px ${theme.spacing(2)}px 0`,
            padding: 0,
        },
    });
