import { IconNames } from '@shipengine/giger-theme';
import { WithCommonProps, Icon, Next } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { getTicketChatScrollToTopIconStyles, getTicketChatScrollToTopStyles } from './TicketChatScrollToTopStyles';

type TicketChatScrollToTopProps = WithCommonProps<{
    numberOfMessages: number;
    onClick: () => void;
}>;

export function TicketChatScrollToTop({
    numberOfMessages,
    onClick,
    ...props
}: TicketChatScrollToTopProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Next.Link css={getTicketChatScrollToTopStyles} onClick={onClick} {...props}>
            {t('support-center.ticket-chat.scroll-to-top', { count: numberOfMessages })}{' '}
            <Icon css={getTicketChatScrollToTopIconStyles} name={IconNames.ARROW_TOP} />
        </Next.Link>
    );
}
