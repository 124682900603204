import { FC } from 'react';

import { IInfoPanelProps, InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { IntegrationGuide } from '@Integrations/components/IntegrationGuide/IntegrationGuide';
import { IntegrationVideo } from '@Integrations/components/IntegrationVideo/IntegrationVideo';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';

import { getIntegrationVideoStyles } from './IntegrationGuideVideoStyles';
import { IntegrationGuideName } from './types';

type WixIntegrationGuideType = Omit<IInfoPanelProps, 'title' | 'collapsible'>;

export const WixIntegrationGuide: FC<WixIntegrationGuideType> = ({
    collapsed,
}: WixIntegrationGuideType): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const basePath = `${config.cdn.url}/pro/statics/videos/onboarding/${IntegrationGuideName.Wix}`;
    const subtitleFallbackPath = `${config.cdn.url}/pro/statics/videos/onboarding/${IntegrationGuideName.Wix}/en-GB.vtt`;

    return (
        <>
            <IntegrationVideo
                css={getIntegrationVideoStyles(collapsed)}
                src={`${basePath}/video.mp4`}
                poster={`${basePath}/poster.png`}
                subtitles={`${basePath}/${locale}.vtt`}
                fallbackSubtitles={subtitleFallbackPath}
                integrationName={IntegrationGuideName.Wix}
            />
            <IntegrationGuide>
                <InfoPanel title={t('integrations.guide.title')} collapsible={true} collapsed={collapsed}>
                    <InfoPanelStepList>
                        <InfoPanelStep title={t('integrations.wix.step1-title')}>
                            <Typography component="p">
                                <Trans i18nKey="integrations.wix.step1-text" />
                            </Typography>
                        </InfoPanelStep>
                        <InfoPanelStep title={t('integrations.wix.step2-title')}>
                            <ul>
                                <li>
                                    <Trans i18nKey="integrations.guide.default-information" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.guide.select-shipping" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.guide.enter-carrier" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.wix.shipping-zone-settings" />
                                </li>
                            </ul>
                        </InfoPanelStep>
                        <InfoPanelStep title={t('integrations.wix.step3-title')}>
                            <Typography component="p">{t('integrations.wix.step3-text')}</Typography>
                        </InfoPanelStep>
                    </InfoPanelStepList>
                </InfoPanel>
            </IntegrationGuide>
        </>
    );
};
