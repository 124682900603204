import { useQuery } from 'react-query';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { Button, Divider, Next, Typography } from '@shipengine/giger';
import { ClientFeature, OwnContractModelRepository, SubscriptionPlan } from '@packlink/packlink-sdk';
import { GTMEvents } from '@packlink/metrics';
import { apiClient } from '@sdk';
import { AmplitudeEvents } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { useSidePanel } from '@hooks/useSidePanel';
import { useAmplitude } from '@hooks/useAmplitude';
import { useSubscriptionPlanFeatures } from '@hooks/useSubscriptionPlanFeatures';
import { PanelName, SubscriptionLinks } from '@types';
import { useTenantSubscriptionConfig } from '@Subscriptions/hooks/useClientSubscriptions';
import { SUBSCRIPTION_PLAN } from '@Subscriptions/constants';
import { PlusPlanFeatures } from '../../../../../../common/components/PlusPlanFeatures/PlusPlanFeatures';
import {
    gerDividerStyles,
    getButtonStyles,
    getHeaderStyles,
    getHeadingStyles,
    getImageStyles,
    getOfferStyles,
    getResetMarginStyles,
    getSubscriptionPlanStyles,
} from './SubscriptionPlanStyles';
import { PlanPricing } from './components/PlanPricing';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';

const contractModelRepository = new OwnContractModelRepository(apiClient);

export interface ISubscriptionPlanCardProps {
    isCanceled: boolean;
    planDetails: SubscriptionPlan;
    onSelect: (plan: SubscriptionPlan) => void;
}

export function SubscriptionPlanCard({ isCanceled, planDetails, onSelect }: ISubscriptionPlanCardProps): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const { sendGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const { open: openSubscriptionPanel } = useSidePanel(PanelName.SUBSCRIPTIONS);
    const { links } = useTenantSubscriptionConfig();
    const { availableFeatures } = useSubscriptionPlanFeatures();
    const isOCFeatureAvailable = availableFeatures?.[ClientFeature.OWN_CONTRACT];
    const {
        cdn: { url: cdnUrl },
    } = config;

    const { data: carrierNames } = useQuery(
        'carrier-contract-names',
        () =>
            contractModelRepository
                .getModels()
                .then((contractModels) =>
                    new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' }).format(
                        contractModels.map(({ carrierName }) => carrierName),
                    ),
                ),
        { enabled: isOCFeatureAvailable },
    );
    const sendEvent = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.SUBSCRIPTION_GET_STARTED_NOW);

        sendGtmEvent(GTMEvents.UPGRADE_ACCOUNT, {
            action: GTMActionFieldOption.UPGRADE_ACCOUNT_SELECTED,
            typeOfPlan: SUBSCRIPTION_PLAN.PRO_PLUS,
        });
    };

    const openPanel = () => {
        onSelect(planDetails);
        sendEvent();
        openSubscriptionPanel();
    };

    return (
        <>
            <div css={getSubscriptionPlanStyles}>
                <header css={getHeaderStyles}>
                    <img
                        css={getImageStyles}
                        src={`${cdnUrl}/subscriptions/images/rocket.svg`}
                        alt=""
                        aria-hidden="true"
                        data-id="plan-img"
                    />
                    <div>
                        <Typography css={getHeadingStyles} variant="heading4" component="h4">
                            {t('subscriptions.plan.title-pro', { planName: planDetails.name })}
                        </Typography>

                        <Typography css={getResetMarginStyles} variant="body1" component="p">
                            {t('subscriptions.plan.sub-title')}
                        </Typography>

                        <PlanPricing
                            pricingType={planDetails.pricingType}
                            rampIntervals={planDetails.rampIntervals}
                            fixedPricing={planDetails.fixedPricing}
                        />
                    </div>
                </header>
                <Button css={getButtonStyles} onClick={openPanel} disabled={isCanceled}>
                    {t('subscriptions.action.get-started')}
                </Button>
                <Divider css={gerDividerStyles} />
                <Typography variant="body1" component="p">
                    {t('subscriptions.plan.access-to', { planName: planDetails.name })}
                </Typography>
                <PlusPlanFeatures
                    carriersValues={{ carriers: carrierNames }}
                    availableFeatures={availableFeatures || {}}
                />
                <Typography css={getOfferStyles} component="p">
                    {t('subscriptions.plan.cancel-anytime')}
                </Typography>
                {isOCFeatureAvailable && carrierNames && (
                    <Typography variant="small" component="p">
                        <Trans
                            i18nKey="subscriptions.plan.dynamic-carrier-contracts-disclamer"
                            tOptions={{ carriers: carrierNames }}
                            components={[
                                <Next.Link
                                    key="link1"
                                    isExternal
                                    target="_blank"
                                    href={links?.[SubscriptionLinks.ownContractSupport]}
                                />,
                                <Next.Link key="link2" isExternal target="_blank" href={links?.typeform} />,
                            ]}
                        />
                    </Typography>
                )}
            </div>
        </>
    );
}
