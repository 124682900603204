import { useEffect, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useFormikContext } from 'formik';
import { getComposedFieldName } from '@packlink/utils';
import { SelectAutocomplete, ISelectAutocompleteOption, Input, FormField } from '@shipengine/giger';
import { ShippingRuleInputType } from '@types';
import { IFormItem, IRuleForm } from '../../types/formTypes';
import { useFormFetch } from '../../hooks/useFormFetch';
import { getFormFieldMessage } from '../../utils/form';

export interface IValueFieldProps {
    item: IFormItem;
    fieldPrefix: string;
    identifier?: string;
    disabled: boolean;
}

export function ValueField({ item, fieldPrefix, identifier = '', disabled }: IValueFieldProps): JSX.Element | null {
    const { t } = useTranslation();
    const { handleChange, errors, touched } = useFormikContext<IRuleForm>();
    const [options, setOptions] = useState<ISelectAutocompleteOption[] | void>();
    const [label, setLabel] = useState<string>();
    const fetchByIdentifier = useFormFetch();

    const handleSelectorChange = (name: string, value: ISelectAutocompleteOption | null) => {
        handleChange({ target: { name: name, value } });
    };

    useEffect(() => {
        const fieldLabel =
            {
                toPostalZoneId: 'form.label.country',
                serviceId: 'form.label.service',
                source: 'form.label.source',
                warehouse: 'form.label.my-addresses',
                parcel: 'form.label.parcel',
                content: 'form.label.contents',
            }[identifier] || 'shipping-rules.form.value';

        setLabel(t(fieldLabel));
        fetchByIdentifier[identifier]?.().then(setOptions);
    }, [fetchByIdentifier, identifier, t]);

    switch (fieldInfoByIdentifier[identifier]?.inputType) {
        case ShippingRuleInputType.POSTALZONE:
        case ShippingRuleInputType.SERVICE:
        case ShippingRuleInputType.SOURCE:
        case ShippingRuleInputType.WAREHOUSE:
        case ShippingRuleInputType.PARCEL:
            return (
                <FormField
                    message={getFormFieldMessage(
                        getComposedFieldName(fieldPrefix, 'value'),
                        errors,
                        touched,
                        item.value,
                    )}
                >
                    <SelectAutocomplete
                        name={getComposedFieldName(fieldPrefix, 'value')}
                        options={options || []}
                        placeholder={label}
                        label={label}
                        showLabel={false}
                        value={item.value}
                        disabled={disabled || !options?.length}
                        onChange={handleSelectorChange}
                        noResultsLabel={t('autocomplete.messages.no-results-found')}
                        isInvalid={!!getFormFieldMessage(getComposedFieldName(fieldPrefix, 'value'), errors, touched)}
                    />
                </FormField>
            );

        case ShippingRuleInputType.NONE:
            return null;

        default:
            return (
                <FormField message={getFormFieldMessage(getComposedFieldName(fieldPrefix, 'value'), errors, touched)}>
                    <Input
                        label={label}
                        name={getComposedFieldName(fieldPrefix, 'value.value')}
                        value={item.value.value}
                        onChange={handleChange}
                        disabled={disabled}
                        isInvalid={!!getFormFieldMessage(getComposedFieldName(fieldPrefix, 'value'), errors, touched)}
                        type={fieldInfoByIdentifier[identifier]?.inputType}
                        step={fieldInfoByIdentifier[identifier]?.step}
                    />
                </FormField>
            );
    }
}

const fieldInfoByIdentifier = Object.fromEntries(
    [...config.shippingRules.conditions, ...config.shippingRules.actions].map((item) => [
        item.identifier,
        {
            inputType: item.inputType,
            step: item.step?.toString(),
        },
    ]),
);
