import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentStatusItem = (theme: Theme): CSSObject => ({
    listStyle: 'none',
    marginBottom: theme.spacing(),
    '&:last-of-type': {
        marginBottom: 0,
    },
});
