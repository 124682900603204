import { WithCommonProps, SingleFileUploader, SingleFileUploaderProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { useMutation } from 'react-query';
import {
    DEFAULT_UPLOAD_TIMEOUT,
    SupportCenterAttachment,
    TEMPORAL_UPLOAD_ID,
    useUploadAttachmentFileValidationSchema,
    useUploadConfirmationEvent,
    useUploadRequest,
} from './UploadAttachmentData';

export type UploadAttachmentProps = Pick<SingleFileUploaderProps, 'name' | 'message' | 'disabled' | 'label'> &
    WithCommonProps<{
        value?: SupportCenterAttachment;
        onChange: (fileData: SupportCenterAttachment | undefined) => void;
        onError: (message: string, e?: Error) => void;
        uploadTimeout?: number;
    }>;

export function UploadAttachment({
    onChange,
    onError,
    uploadTimeout = DEFAULT_UPLOAD_TIMEOUT,
    value,
    ...props
}: UploadAttachmentProps): JSX.Element {
    const { t } = useTranslation();
    const { handleChange, isUploading } = useFileHandler({
        onChange,
        onError,
        uploadTimeout,
    });
    const shouldShowFile = value?.uploadId !== TEMPORAL_UPLOAD_ID;

    return (
        <SingleFileUploader
            value={shouldShowFile ? value?.file : undefined}
            onChange={handleChange}
            isLoading={isUploading}
            {...props}
        >
            {t('support-center.new-ticket.attachment-button-label')}
        </SingleFileUploader>
    );
}

function useFileHandler({
    onError,
    onChange,
    uploadTimeout,
}: Required<Pick<UploadAttachmentProps, 'uploadTimeout' | 'onError' | 'onChange'>>) {
    const { t } = useTranslation();
    const cleanUp = () => {
        onChange(undefined);
    };
    const { eventUnbind } = useUploadConfirmationEvent();
    const fileValidation = useUploadAttachmentFileValidationSchema();
    const requestUpload = useUploadRequest(uploadTimeout);

    const {
        mutate: upload,
        isLoading: isUploading,
        isSuccess: isUploaded,
    } = useMutation(requestUpload, {
        // Retries and binary fields does not seem to be a good mix
        retry: 0,
        onMutate: (file: File) => onChange({ file, uploadId: TEMPORAL_UPLOAD_ID }),
        onSettled: () => eventUnbind(),
        onError: (error: Error) => {
            cleanUp();
            onError(t('support-center.new-ticket.attachment-error'), error);
        },
        onSuccess: (uploadId: string, file: File) => {
            onChange(new SupportCenterAttachment(file, uploadId));
        },
    });

    const handleChange = async (file: File | undefined) => {
        if (!file) {
            cleanUp();
            return;
        }

        try {
            fileValidation.validateSync(file);
        } catch (error: unknown) {
            if (error instanceof Error) {
                onError(error.message, error);
            }
            return;
        }

        upload(file);
    };

    return {
        handleChange,
        isUploading,
        isUploaded,
    };
}
