import * as yup from 'yup';
import { useContext } from 'react';

import { useSdk } from '@packlink/packlink-sdk-provider';
import { useTranslation } from '@packlink/translation-provider';
import { CreatePickupSupportTicketPayload, PickupSupportTicket, IShipment, Shipment } from '@packlink/packlink-sdk';

import { FormWithShipmentContext } from '../CommonFields/FormWithShipment/FormWithShipmentData';
import { NewTicketEventHandlers } from '../../NewTicketData';
import { isWeekend, isToday, formatDate } from '../../../../utils/dates';

export type PickupTicketFormData = Pick<PickupSupportTicket, 'additionalComments'> & {
    collectionDate: Date | null;
};

export function usePickupTicketFormValidationSchema(): yup.ObjectSchema<PickupTicketFormData> {
    const { t } = useTranslation();

    return yup
        .object()
        .shape<PickupTicketFormData>({
            collectionDate: yup
                .date()
                .required(t('form.error.required', { field: t('support-center.pickup-form.collection-date-label') }))
                .test(
                    'is-future',
                    t('support-center.pickup-form.collection-date-must-be-future-error'),
                    (date?: Date | null) => {
                        if (!date || isToday(date) || isWeekend(date)) {
                            return false;
                        }
                        return true;
                    },
                ),
            additionalComments: yup.string(),
        })
        .defined();
}

export function usePickupTicketFormHandlers({ onSubmit }: NewTicketEventHandlers): {
    handleSubmit: (values: PickupTicketFormData) => Promise<void>;
} {
    const { shipment } = useContext(FormWithShipmentContext);
    const sdk = useSdk();
    const handleSubmit = async (values: PickupTicketFormData) => {
        const pickupTicket = parsePickupTicket(values, shipment);
        await onSubmit(() => sdk.v1.supportCenter.createPickupSupportTicket(pickupTicket));
    };

    return {
        handleSubmit,
    };
}

function parsePickupTicket(
    { collectionDate, additionalComments }: PickupTicketFormData,
    shipment: Shipment<IShipment>,
): CreatePickupSupportTicketPayload {
    if (!collectionDate) {
        throw new Error('collectionDate should be defined');
    }

    return {
        shipmentReference: shipment.packlinkReference,
        collectionDate: formatDate(collectionDate),
        additionalComments,
    };
}
