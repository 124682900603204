import { Formik, FormikProps } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { ConfigurationType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { OAuthDummyIntegrationForm } from './OAuthDummyIntegrationForm';
import { OAuthDummyConfiguration, OAuthDummyIntegrationPanelContentProps, IOAuthDummyFormData } from './types';

export const OAuthDummyIntegrationPanel: FC<OAuthDummyIntegrationPanelContentProps> = (
    props: OAuthDummyIntegrationPanelContentProps,
): JSX.Element => {
    const { t } = useTranslation();
    const { onClose, integration, onDeactivate, onSubmit, isLoading, showError, clearError, onCancel } = props;

    const oAuthDummyConfiguration = integration.configuration;

    const defaultConfiguration: IOAuthDummyFormData = {
        store_id: oAuthDummyConfiguration?.data?.store_id,
    };

    const oAuthDummyOnSubmit = (formData: IOAuthDummyFormData): void => {
        const oAuthDummyConfiguration: OAuthDummyConfiguration = {
            identifier: integration.configuration?.identifier || '',
            integration: EcommerceAlias.OAuthDummy,
            type: ConfigurationType.INBOUND,
            data: { ...formData },
        };

        const oAuthDummyIntegration = { ...integration, configuration: oAuthDummyConfiguration };
        onSubmit(oAuthDummyIntegration);
    };

    const validationSchema = yup.object().shape({
        store_id: yup
            .number()
            .required(t('form.error.required', { field: t('integrations.dummyMarkerplace.username') })),
    });

    return (
        <Formik
            onSubmit={oAuthDummyOnSubmit}
            initialValues={defaultConfiguration}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<IOAuthDummyFormData>): React.ReactNode => (
                <OAuthDummyIntegrationForm
                    onCancel={onCancel}
                    onClose={onClose}
                    formProps={formProps}
                    isLoading={isLoading}
                    integration={integration}
                    onDeactivate={onDeactivate}
                    showError={showError}
                    clearError={clearError}
                />
            )}
        </Formik>
    );
};
