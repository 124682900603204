import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketDetailsWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'relative',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        gap: theme.spacing(2),
    });

export const getTicketDetailsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto auto',
        gap: theme.spacing(2),
        [theme.mediaQuery('tablet')]: {
            gridTemplateColumns: '63fr 37fr',
            gridTemplateRows: 'auto',
        },

        // This is needed to ensure scroll is shown correctly
        // https://stackoverflow.com/questions/65599857/css-grid-layout-1fr-exceeds-parent-height
        minHeight: 0,
    });

export const getTicketDetailsSectionPadding = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
        [theme.mediaQuery('tablet')]: {
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        },
    });
