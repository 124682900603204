import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';
import { useTranslation } from '@packlink/translation-provider';
import { Inbox, IParcel, IShipmentSource, ShipmentSource, WarehouseAddressesRepository } from '@packlink/packlink-sdk';
import Packlink, { apiClient } from '@sdk';
import { getClientIdentifier } from '@store/selectors/client';
import { LocationUtils } from '@utils/LocationUtils';
import { QueryKeys } from '../types/queryKeys';
import { FORM_QUERIES_STALE_TIME } from '../constants';
import {
    mapCountryToAutocomplete,
    mapParcelToAutocomplete,
    mapServiceToAutocomplete,
    mapSourceToAutocomplete,
    mapWarehouseToAutocomplete,
} from '../utils/shippingRulesMapper';
import { deserializeGuard } from '@common/hooks/useParcels';
import { ISelectAutocompleteOption } from '@shipengine/giger';

export function useFormFetch(): Record<string, () => Promise<ISelectAutocompleteOption[] | void>> {
    const clientId = useSelector(getClientIdentifier);

    const queryClient = useQueryClient();
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const fetchServices = useCallback(() => {
        return queryClient
            .fetchQuery(
                [QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_SERVICES_AVAILABLE],
                () => Packlink.v1.clients.getAvailableServices(clientId),
                {
                    staleTime: FORM_QUERIES_STALE_TIME,
                },
            )
            .then((data) => data.map((item) => mapServiceToAutocomplete(item, t)))
            .catch(() =>
                queryClient.invalidateQueries([QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_SERVICES_AVAILABLE]),
            );
    }, [clientId, queryClient, t]);

    const fetchCountries = useCallback(() => {
        return queryClient
            .fetchQuery(
                [QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_DESTINATIONS],
                () => LocationUtils.getDestinations(locale),
                {
                    staleTime: FORM_QUERIES_STALE_TIME,
                },
            )
            .then((data) => data.map(mapCountryToAutocomplete))
            .catch(() =>
                queryClient.invalidateQueries([QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_DESTINATIONS]),
            );
    }, [locale, queryClient]);

    const fetchSources = useCallback(() => {
        return queryClient
            .fetchQuery(
                [QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_SOURCES],
                () =>
                    Packlink.v1.shipments.getShipmentsSources(Inbox.ALL) as Promise<ShipmentSource<IShipmentSource>[]>,
                {
                    staleTime: FORM_QUERIES_STALE_TIME,
                },
            )
            .then((data) => data.map(mapSourceToAutocomplete))
            .catch(() => queryClient.invalidateQueries([QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_SOURCES]));
    }, [queryClient]);

    const fetchWarehouses = useCallback(() => {
        const warehouseAddressesRepository = new WarehouseAddressesRepository(apiClient);

        return queryClient
            .fetchQuery(
                [QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_WAREHOUSES],
                () => warehouseAddressesRepository.get(),
                {
                    staleTime: FORM_QUERIES_STALE_TIME,
                },
            )
            .then((data) => data.map(mapWarehouseToAutocomplete))
            .catch(() =>
                queryClient.invalidateQueries([QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_WAREHOUSES]),
            );
    }, [queryClient]);

    const fetchParcels = useCallback(() => {
        return queryClient
            .fetchQuery([QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_PARCELS], () => Packlink.pro.init(), {
                staleTime: FORM_QUERIES_STALE_TIME,
            })
            .then((data) =>
                data.parcels?.map((parcel) => deserializeGuard(parcel as IParcel)).map(mapParcelToAutocomplete),
            )
            .catch(() =>
                queryClient.invalidateQueries([QueryKeys.SHIPPING_RULES, QueryKeys.SHIPPING_RULES_WAREHOUSES]),
            );
    }, [queryClient]);

    const memoizedResponse = useMemo(
        () => ({
            toPostalZoneId: fetchCountries,
            serviceId: fetchServices,
            source: fetchSources,
            warehouse: fetchWarehouses,
            parcel: fetchParcels,
        }),
        [fetchCountries, fetchServices, fetchSources, fetchWarehouses, fetchParcels],
    );

    return memoizedResponse;
}
