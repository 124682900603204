import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSidebarStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(4),
        width: 334,
        clipPath: `inset(0 -20px 0 0)`, // avoids box-shadow bleeding to the header,
        flexShrink: 0,
    });
