import { PanelName } from '@types';
import { SET_ACTIVE_PANEL, CLEAR_ACTIVE_PANEL } from './action-types';

export interface ISetActivePanel {
    type: 'SET_ACTIVE_PANEL';
    payload: PanelName;
}

export interface IClearActivePanel {
    type: 'CLEAR_ACTIVE_PANEL';
}

export type SidePanelAction = ISetActivePanel | IClearActivePanel;

export const setActivePanel = (panelName: PanelName): ISetActivePanel => ({
    type: SET_ACTIVE_PANEL,
    payload: panelName,
});

export const clearActivePanel = (): IClearActivePanel => ({
    type: CLEAR_ACTIVE_PANEL,
});
