import { useHref, useMatch, useResolvedPath, useLinkClickHandler } from 'react-router';
import { useEffect, useRef } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { Icon, IconSize, Tag, TagColor } from '@shipengine/giger';
import { Browser } from '@utils/Browser';
import { AmplitudeEvents } from '@constants/amplitude';
import { AppRoute } from '@pages/router/routes';
import { useAmplitude } from '@hooks/useAmplitude';
import { iconStyles, inboxStyles, lockedIconStyles, nameStyles, newTagStyles } from './AppSidebarInboxStyles';
import { IconNames } from '@shipengine/giger-theme';

interface IAppSidebarItemProps {
    name: string;
    icon: IconNames;
    event: AmplitudeEvents;
    to: AppRoute;
    showNewTag?: boolean;
    isItemLocked?: boolean;
}

export function AppSidebarItem({ name, icon, event, to, showNewTag, isItemLocked }: IAppSidebarItemProps) {
    const activeElementRef = useRef<null | HTMLLIElement>(null);
    const { sendAmplitudeTableViewClickEvent } = useAmplitude();
    const { t } = useTranslation();
    const path = useResolvedPath(to);
    const match = useMatch({ path: path.pathname });
    const internalOnClick = useLinkClickHandler(path.pathname);
    const href = useHref(path.pathname);

    const isActive = !!match;

    const changeInbox = (e: React.MouseEvent<HTMLAnchorElement>) => {
        sendAmplitudeTableViewClickEvent(event);
        internalOnClick(e);
    };

    useEffect((): void => {
        if (!isActive) return;

        !Browser.isElementVisible(activeElementRef.current) &&
            activeElementRef.current?.scrollIntoView &&
            activeElementRef.current?.scrollIntoView();
    }, [isActive]);

    return (
        <li key={to} ref={isActive ? activeElementRef : null} data-inbox={to}>
            <a css={inboxStyles(isActive, false, false, false, showNewTag)} href={href} onClick={changeInbox}>
                <Icon css={iconStyles(false)} name={icon} size={IconSize.SIZE_MEDIUM} />
                <span css={nameStyles}>{t(name)}</span>
                {showNewTag && (
                    <Tag css={newTagStyles} color={TagColor.SUCCESS}>
                        {t('settings.menu.new-tag')}
                    </Tag>
                )}
                {isItemLocked && (
                    <Icon css={lockedIconStyles} name={IconNames.PADLOCK} title={t('settings-page.header.locked')} />
                )}
            </a>
        </li>
    );
}
