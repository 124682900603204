import { IPrice } from '@packlink/packlink-sdk';
import { useEffect, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { PaymentDetailsItem } from './PaymentDetailsItem';
import { getPaymentDetailsGroupStyles } from './PaymentDetailsStyles';

export interface IPaymentDetailsServicesProps {
    priceDetails: IPrice[];
    currency?: string;
    ownContract?: string;
    hasOwnContracts?: boolean;
}

interface IPaymentStat {
    basePrice: number;
    quantity: number;
}

interface IPaymentStats {
    porterages: IPaymentStat;
    insurances: IPaymentStat;
    adultSignatures: IPaymentStat;
    additionalHandling: IPaymentStat;
    managementFee: IPaymentStat;
    proofOfDeliveries: IPaymentStat;
    voucher: IPaymentStat & { name?: string };
    cashOnDelivery: IPaymentStat;
}

const initialStats: IPaymentStats = {
    porterages: {
        basePrice: 0,
        quantity: 0,
    },
    insurances: {
        basePrice: 0,
        quantity: 0,
    },
    adultSignatures: {
        basePrice: 0,
        quantity: 0,
    },
    additionalHandling: {
        basePrice: 0,
        quantity: 0,
    },
    managementFee: {
        basePrice: 0,
        quantity: 0,
    },
    proofOfDeliveries: {
        basePrice: 0,
        quantity: 0,
    },
    voucher: {
        basePrice: 0,
        quantity: 0,
        name: undefined,
    },
    cashOnDelivery: {
        basePrice: 0,
        quantity: 0,
    },
};

export const PaymentDetailsServices = (props: IPaymentDetailsServicesProps): JSX.Element => {
    const { priceDetails, currency, ownContract, hasOwnContracts = false } = props;
    const [stats, setStats] = useState<IPaymentStats>(initialStats);
    const { t } = useTranslation();

    const servicesKey = ownContract
        ? 'payment-details.item.carrier-service'
        : hasOwnContracts
          ? 'payment-details.item.tenant-service'
          : 'payment-details.item.service';

    useEffect(() => {
        const priceStats = priceDetails.reduce((acc: IPaymentStats, detail: IPrice) => {
            const insuranceBasePrice = detail?.products?.insurance?.basePrice;
            const adultSignatureBasePrice = detail?.products?.adultSignature?.basePrice;
            const additionalHandlingBasePrice = detail?.products?.additionalHandling?.basePrice;
            const managementFeeBasePrice = detail?.products?.managementFee?.basePrice;
            const proofOfDeliveryBasePrice = detail?.products?.proofOfDelivery?.basePrice;
            const voucherBasePrice = detail?.products?.voucher?.basePrice;
            const cashOnDeliveryBasePrice = detail?.products?.cashOnDelivery?.basePrice;

            return {
                porterages: {
                    basePrice: acc.porterages.basePrice + (detail?.products?.porterage?.basePrice ?? 0),
                    quantity: acc.porterages.quantity + 1,
                },
                insurances: {
                    basePrice: acc.insurances.basePrice + (insuranceBasePrice ?? 0),
                    quantity: acc.insurances.quantity + (insuranceBasePrice ? 1 : 0),
                },
                adultSignatures: {
                    basePrice: acc.adultSignatures.basePrice + (adultSignatureBasePrice ?? 0),
                    quantity: acc.adultSignatures.quantity + (adultSignatureBasePrice ? 1 : 0),
                },
                additionalHandling: {
                    basePrice: acc.additionalHandling.basePrice + (additionalHandlingBasePrice ?? 0),
                    quantity: acc.additionalHandling.quantity + (additionalHandlingBasePrice ? 1 : 0),
                },
                managementFee: {
                    basePrice: acc.managementFee.basePrice + (managementFeeBasePrice ?? 0),
                    quantity: acc.managementFee.quantity + (managementFeeBasePrice ? 1 : 0),
                },
                cashOnDelivery: {
                    basePrice: acc.cashOnDelivery.basePrice + (cashOnDeliveryBasePrice ?? 0),
                    quantity: acc.cashOnDelivery.quantity + (cashOnDeliveryBasePrice ? 1 : 0),
                },
                proofOfDeliveries: {
                    basePrice: acc.proofOfDeliveries.basePrice + (proofOfDeliveryBasePrice ?? 0),
                    quantity: acc.proofOfDeliveries.quantity + (proofOfDeliveryBasePrice ? 1 : 0),
                },
                voucher: {
                    name: detail?.products?.voucher?.name || acc.voucher.name,
                    basePrice: acc.voucher?.basePrice + (voucherBasePrice ?? 0),
                    quantity: acc.voucher?.quantity + (voucherBasePrice ? 1 : 0),
                },
            };
        }, initialStats);
        setStats(priceStats);
    }, [priceDetails]);

    return (
        <div css={getPaymentDetailsGroupStyles}>
            {!!stats.porterages.quantity && (
                <PaymentDetailsItem
                    label={t(servicesKey, {
                        carrierName: ownContract,
                        count: priceDetails.length,
                    })}
                    price={stats.porterages.basePrice}
                    quantity={priceDetails.length > 1 ? stats.porterages.quantity : undefined}
                    isOwnContract={!!ownContract}
                    currency={currency}
                />
            )}
            {!!stats.managementFee.quantity && (
                <PaymentDetailsItem
                    label={t('checkout.price-summary.management-fee')}
                    tooltip={t('checkout.price-summary.management-fee-info')}
                    price={stats.managementFee.basePrice}
                    quantity={priceDetails.length > 1 ? stats.managementFee.quantity : undefined}
                    currency={currency}
                />
            )}
            {!!stats.insurances.quantity && (
                <PaymentDetailsItem
                    label={t('payment-details.item.insurance')}
                    price={stats.insurances.basePrice}
                    quantity={priceDetails.length > 1 ? stats.insurances.quantity : undefined}
                    currency={currency}
                />
            )}
            {!!stats.proofOfDeliveries.quantity && (
                <PaymentDetailsItem
                    label={t('payment-details.item.proof-of-delivery')}
                    price={stats.proofOfDeliveries.basePrice}
                    quantity={priceDetails.length > 1 ? stats.proofOfDeliveries.quantity : undefined}
                    currency={currency}
                />
            )}
            {!!stats.adultSignatures.quantity && (
                <PaymentDetailsItem
                    label={t('payment-details.item.adult-signature')}
                    price={stats.adultSignatures.basePrice}
                    quantity={priceDetails.length > 1 ? stats.adultSignatures.quantity : undefined}
                    currency={currency}
                />
            )}
            {!!stats.additionalHandling.quantity && (
                <PaymentDetailsItem
                    label={t('payment-details.item.additional-handling')}
                    price={stats.additionalHandling.basePrice}
                    quantity={priceDetails.length > 1 ? stats.additionalHandling.quantity : undefined}
                    currency={currency}
                />
            )}
            {!!stats.cashOnDelivery.quantity && (
                <PaymentDetailsItem
                    label={t('payment-details.item.cash-on-delivery')}
                    price={stats.cashOnDelivery.basePrice}
                    quantity={priceDetails.length > 1 ? stats.cashOnDelivery.quantity : undefined}
                    currency={currency}
                />
            )}
            {!!stats.voucher.quantity && (
                <PaymentDetailsItem
                    label={stats.voucher.name || t('payment-details.item.voucher.default-text')}
                    price={stats.voucher.basePrice}
                    quantity={priceDetails.length > 1 ? stats.voucher.quantity : undefined}
                    currency={currency}
                />
            )}
        </div>
    );
};
