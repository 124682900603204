import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getShipmentListContentStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'relative',
        flex: '1 1 auto',
        margin: 0,
        padding: theme.spacing(2),
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: theme.palette.gray.megaLight,

        [theme.mediaQuery('tablet')]: {
            padding: 0,
        },
    });

export const getShipmentListContentWrapperStyles = (): SerializedStyles =>
    css({
        height: '100%',
        overflow: 'auto',
    });

export const getShipmentListContentLoadingStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing()}px 0`,
        backgroundColor: theme.palette.white,
    });

export const getSpinnerStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(5),
    });
