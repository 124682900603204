import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTaxCalculationPanelStyles = (theme: Theme): SerializedStyles =>
    css({
        backgroundColor: theme.palette.white,
        borderRadius: theme.borderRadius.S,
    });

export const getLineWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(),
        ':last-of-type': {
            marginBottom: 0,
        },
    });

export const getTaxCalculationDetailsStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        border: `1px solid ${theme.palette.gray.ultraLight}`,
    });

export const getTaxTotalStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        border: `1px solid ${theme.palette.gray.ultraLight}`,
        borderTop: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    });

export const getTotalSkeletonWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        border: `1px solid ${theme.palette.gray.ultraLight}`,
        borderTop: 'none',
        marginBottom: 0,
    });

export const getTaxCalculationTextStyles = (): SerializedStyles => css({ marginBottom: 0 });
export const getSkeletonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
        ':last-of-type': {
            marginBottom: 0,
        },
    });
