import { Typography, WithCommonProps } from '@shipengine/giger';
import { isEmpty } from 'lodash';
import { PostSaleTrackingParcelReference } from './PostSaleTrackingParcelReference';
import { getTrackingListStyles, getTrackingNumberStyles } from './PostSaleTrackingParcelReferenceListStyles';

type PostSaleTrackingParcelReferenceListProps = WithCommonProps<{
    trackings: string[];
    trackingUrl: string;
}>;

const SEPARATOR = '-';
export const PostSaleTrackingParcelReferenceList = ({
    trackings,
    trackingUrl,
    ...props
}: PostSaleTrackingParcelReferenceListProps): JSX.Element => {
    const uniqueTrackingIds = [...trackings.filter((tracking) => tracking.split(SEPARATOR).length !== 2)];
    if (isEmpty(uniqueTrackingIds)) {
        return (
            <Typography variant="body1" component="p" {...props}>
                ...
            </Typography>
        );
    }
    return (
        <ul css={getTrackingListStyles} {...props}>
            {uniqueTrackingIds.map((trackingNumber: string) => (
                <li key={trackingNumber} css={getTrackingNumberStyles}>
                    <PostSaleTrackingParcelReference trackingNumber={trackingNumber} trackingUrl={trackingUrl} />
                </li>
            ))}
        </ul>
    );
};
