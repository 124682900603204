import { Trans, useTranslation } from '@packlink/translation-provider';
import { IVolumeDiscountBand } from '@packlink/packlink-sdk';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { Next, Spinner, useToast } from '@shipengine/giger';
import { InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';
import { infoPanelStyles, spinnerStyles } from './VolumeDiscountSettingsStyles';
import { useVolumeDiscountData } from './hooks/useVolumeDiscountData';
import { RatesTable } from './components/RatesTable/RatesTable';
import { VolumeSelection } from './components/VolumeSelection/VolumeSelection';

export function VolumeDiscountSettings(): JSX.Element {
    const { t } = useTranslation();
    const toast = useToast(t);
    const { isLoading, isEstimated, volumeDiscountRates, volumeDiscountBands, setVolumeDiscountEstimate } =
        useVolumeDiscountData();

    const setVolumeEstimate = (band: IVolumeDiscountBand) => {
        return setVolumeDiscountEstimate(band)
            .then(() => {
                toast.success({ message: t('settings.success.information-saved') });
            })
            .catch(() => {
                toast.error({ message: t('settings.error.try-again') });
            });
    };
    const getHelpCenterPage = useHelpCenter();

    return (
        <SettingsPage>
            <SettingsPageHeader>{t('volume-discount.settings.title')}</SettingsPageHeader>

            {isLoading && (
                <div css={spinnerStyles}>
                    <Spinner />
                </div>
            )}

            {!isLoading && (
                <>
                    {volumeDiscountRates.length > 0 && <RatesTable volumeDiscountRates={volumeDiscountRates} />}

                    <VolumeSelection
                        volumeDiscountBands={volumeDiscountBands}
                        isEstimated={isEstimated}
                        onSetVolume={setVolumeEstimate}
                    />

                    <InfoPanel
                        css={infoPanelStyles}
                        title={t('volume-discount.settings.how-it-works')}
                        collapsible={true}
                        collapsed={true}
                    >
                        <InfoPanelStep>
                            <Trans
                                variant="body1"
                                i18nKey={'volume-discount.settings.how-it-works-info'}
                                components={[
                                    <Next.Link
                                        key="link"
                                        target="_blank"
                                        href={getHelpCenterPage(HELP_CENTER_KEYS.shippingRates)}
                                    />,
                                ]}
                            />
                        </InfoPanelStep>
                    </InfoPanel>
                </>
            )}
        </SettingsPage>
    );
}
