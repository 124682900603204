import { FC } from 'react';

import { IInfoPanelProps, InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { useTranslation } from '@packlink/translation-provider';

import { IntegrationGuide } from '../../IntegrationGuide/IntegrationGuide';

type AliexpressIntegrationGuideType = Omit<IInfoPanelProps, 'title'>;

export const AliexpressIntegrationGuide: FC<AliexpressIntegrationGuideType> = ({
    collapsible = false,
    collapsed,
}: AliexpressIntegrationGuideType): JSX.Element => {
    const { t } = useTranslation();

    return (
        <IntegrationGuide>
            <InfoPanel title={t('integrations.guide.title')} collapsible={collapsible} collapsed={collapsed}>
                <InfoPanelStepList>
                    <InfoPanelStep title={t('integrations.aliexpress.step1-title')}>
                        <p dangerouslySetInnerHTML={{ __html: t('integrations.aliexpress.step1-text') }} />
                        <ul>
                            <li>{t('integrations.aliexpress.step1-list-1')}</li>
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.aliexpress.step1-list-2') }} />
                            <li>{t('integrations.aliexpress.step1-list-3')}</li>
                        </ul>
                        <p>{t('integrations.aliexpress.step1-footer')}</p>
                    </InfoPanelStep>
                    <InfoPanelStep title={t('integrations.aliexpress.step2-title')}>
                        <p>{t('integrations.aliexpress.step2-text')}</p>
                    </InfoPanelStep>
                    <InfoPanelStep title={t('integrations.aliexpress.step3-title')}>
                        <p>{t('integrations.aliexpress.step3-text')}</p>
                    </InfoPanelStep>
                    <InfoPanelStep title={t('integrations.aliexpress.step4-title')}>
                        <p>{t('integrations.aliexpress.step4-text')}</p>
                        <ul>
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.aliexpress.step4-list-1') }} />
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.aliexpress.step4-list-2') }} />
                        </ul>
                    </InfoPanelStep>
                </InfoPanelStepList>
            </InfoPanel>
        </IntegrationGuide>
    );
};
