import { SET_CURRENT_INBOX, SET_INBOXES } from '@store/actions/action-types';

import { Inbox, Inboxes } from '@packlink/packlink-sdk';

interface ISetCurrentInboxAction {
    payload: Inbox;
    type: 'SET_CURRENT_INBOX';
}

interface ISetInboxesAction {
    payload: Inboxes;
    type: 'SET_INBOXES';
}

export type InboxAction = ISetCurrentInboxAction | ISetInboxesAction;

export const setCurrentInbox = (currentInbox: Inbox): ISetCurrentInboxAction => ({
    payload: currentInbox,
    type: SET_CURRENT_INBOX,
});

export const setInboxes = (inboxes: Inboxes): ISetInboxesAction => ({
    payload: inboxes,
    type: SET_INBOXES,
});
