import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getEmptyStateStyles = (theme: Theme): SerializedStyles =>
    css({
        maxWidth: 700,
        margin: '0 auto',

        mark: {
            marginLeft: theme.spacing(0.5),
            padding: theme.spacing(0.5),
            color: theme.palette.primary.ultraLight,
            fontWeight: theme.typography.fontWeight.semibold,
            fontSize: theme.toRem(theme.typography.fontSize.S),
            background: theme.palette.primary.light,
            borderRadius: theme.borderRadius.L,
        },
    });
