import { useCallback, useEffect } from 'react';
import { useEvent } from '@packlink/event-handler';
import { triggerDownload } from '@utils/download';
import { IInvoiceAvailableEvent, InvoicingEvent } from '@Invoicing/events/types';

export function useInvoiceAvailable(clientId: string) {
    const { eventBind, eventUnbind } = useEvent<IInvoiceAvailableEvent>(clientId, InvoicingEvent.INVOICE_AVAILABLE);

    const onDownloadReady = useCallback(({ url }: IInvoiceAvailableEvent) => {
        url && triggerDownload(url);
    }, []);

    useEffect(() => {
        eventBind(onDownloadReady);

        return () => {
            eventUnbind(onDownloadReady);
        };
    }, [eventBind, eventUnbind, onDownloadReady]);
}
