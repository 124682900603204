import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getDeliveryDateStyles = (theme: Theme): SerializedStyles => {
    return css({
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeight.light,
        display: 'block',
        margin: `${theme.spacing(2.5)}px 0 ${theme.spacing(2)}px`,
        textTransform: 'capitalize',
    });
};
