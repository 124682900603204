import { useNavigate } from 'react-router';
import { useTranslation } from '@packlink/translation-provider';
import { ButtonVariant, Spinner, Typography } from '@shipengine/giger';
import { SubscriptionPanel } from '@common/components/SubscriptionPanel/SubscriptionPanel';
import { useSubscriptionPlans } from '@Subscriptions/hooks/useClientSubscriptions';
import { useSidePanel } from '@hooks/useSidePanel';
import { PanelName } from '@types';
import { OnBoardingPlanFeatures } from '@Onboarding/components/OnBoardingPlanFeatures/OnBoardingPlanFeatures';
import { usePlanFeatures, planTypes } from '@common/hooks/usePlanFeatures';
import { getOnboardingPageBackgroundStyles, getPageTitleStyles } from './OnboardingPageStyles';
import { getPlanCardsContainerStyles, getSpinnerStyles } from './OnboardingSelectPlanStyles';
import { OnboardingPlanCard, PlanInfo } from '../components/OnboardingPlanCard/OnboardingPlanCard';
import { APP_ROUTE } from '@router/routes';

export const OnboardingSelectPlan = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { subscriptionPlans, isLoading } = useSubscriptionPlans();
    const { open: openSubscriptionPanel } = useSidePanel(PanelName.SUBSCRIPTIONS);
    const featurePlans = usePlanFeatures();
    // If at some point we have multiple subscription plans, this will have to change
    const plusPlan = subscriptionPlans?.[0];
    const nextRoute = APP_ROUTE.ONBOARDING.WELCOME;

    // These will be static until we have multiple plans available
    const plans: Array<PlanInfo> = [
        {
            name: planTypes.FREE,
            description: 'onboarding.plan-selection.free-description',
            price: '0€',
            buttonVariant: ButtonVariant.OUTLINED,
            featuresListComponent: <OnBoardingPlanFeatures planType={planTypes.FREE} features={featurePlans} />,
            buttonAction: () => navigate(nextRoute),
        },
        {
            name: planTypes.PLUS,
            description: 'onboarding.plan-selection.plus-description',
            price: '25€',
            buttonVariant: ButtonVariant.FILLED,
            featuresListComponent: <OnBoardingPlanFeatures planType={planTypes.PLUS} features={featurePlans} />,
            buttonAction: openSubscriptionPanel,
        },
    ];

    return isLoading ? (
        <Spinner css={getSpinnerStyles} />
    ) : (
        <>
            <div css={getOnboardingPageBackgroundStyles}>
                <Typography variant="body1" component="h3" css={getPageTitleStyles}>
                    {t('onboarding.plan-selection.title')}
                </Typography>
                <section css={getPlanCardsContainerStyles}>
                    {plans.map((plan) => (
                        <OnboardingPlanCard plan={plan} key={plan.name} />
                    ))}
                </section>
            </div>
            {plusPlan && <SubscriptionPanel planDetails={plusPlan} nextPage={nextRoute} />}
        </>
    );
};
