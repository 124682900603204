import { EmptyState, WithCommonProps } from '@shipengine/giger';
import { useCdnUrl } from '@packlink/provider';
import { useTranslation } from '@packlink/translation-provider';

type NoResultsTableContentProps = WithCommonProps<unknown>;

export function NoResultsTableContent(props: NoResultsTableContentProps): JSX.Element {
    const { t } = useTranslation();
    const cdnUrl = useCdnUrl();
    const src = `${cdnUrl}/apps/giger/images/no-results.svg`;

    return (
        <EmptyState
            title={t('support-center.tickets-table.no-results-table-title')}
            subtitle={t('support-center.tickets-table.no-results-table-subtitle')}
            src={src}
            {...props}
        />
    );
}
