import { Button, ButtonColor, ButtonSize, ButtonVariant, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { logSdkError } from '@utils/logger';
import { ProShipment } from '@packlink/packlink-sdk';
import { AmplitudeEvents, AmplitudeEventPrefixes } from '@constants/amplitude';
import Packlink from '@sdk';
import { useHref } from 'react-router';
import { APP_ROUTE } from '@pages/router/routes';
import { useAmplitude } from '@hooks/useAmplitude';

type CreateNewLabelProps = WithCommonProps<{
    shipment: ProShipment;
}>;

export function CreateNewLabel({ shipment, ...props }: CreateNewLabelProps): JSX.Element {
    const { t } = useTranslation();
    const { handleNewLabel } = useCreateNewLabelHandlers(shipment);
    return (
        <Button
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.SMALL}
            color={ButtonColor.PRIMARY}
            onClick={handleNewLabel}
            isFullWidth
            {...props}
        >
            {t('post-sale.actions.create-new-label')}
        </Button>
    );
}

function useCreateNewLabelHandlers(shipment: ProShipment) {
    const { sendAmplitudeEvent } = useAmplitude();
    const href = useHref(APP_ROUTE.CHECKOUT.EDIT);

    const handleNewLabel = () => {
        sendAmplitudeEvent(
            `${AmplitudeEvents.CLICK_ON_BULK_MULTI_LABEL_BUTTON} ${AmplitudeEventPrefixes.CONFIRMATION_PAGE}` as AmplitudeEvents,
        );

        Packlink.pro.shipments
            .createShipmentForMultiLabel(shipment.data.packlinkReference)
            .then((newShipment) => {
                window.open(`${window.location.origin}${href.replace(':id', newShipment.packlinkReference)}`, '_blank');
            })
            .catch(logSdkError);
    };

    return {
        handleNewLabel,
    };
}
