import { PropsWithChildren } from 'react';
import { SubscriptionFeatureBehavioursProvider } from '@Subscriptions/providers/SubscriptionFeatureBehavioursProvider';
import { SentryAdditionalDataWrapper } from './SentryAdditionalDataProvider';

export function BulkAuthenticatedPacklinkProviders({ children }: PropsWithChildren<unknown>): JSX.Element {
    return (
        <SubscriptionFeatureBehavioursProvider>
            <SentryAdditionalDataWrapper>{children}</SentryAdditionalDataWrapper>
        </SubscriptionFeatureBehavioursProvider>
    );
}
