import { useMemo } from 'react';
import isNil from 'lodash.isnil';
import { Currency } from '@packlink/packlink-sdk';

export const useFormatCurrency = (currency: string, amount?: number): string => {
    return useMemo((): string => {
        if (isNil(amount)) {
            return '';
        }

        return Currency.format(amount, currency);
    }, [amount, currency]);
};
