import { css } from '@emotion/react';
import { ButtonVariant } from '@shipengine/giger';
import { Theme } from '@shipengine/giger-theme';

const PURPLE_MAIN = '#7029FF';
const PURPLE_SECONDARY = '#F1EAFF';
const PURPLE_DARK = '#5C0AFF';

export type NotificationDisposition = 'inline' | 'row';

export const getNotificationStyles = (disposition: NotificationDisposition) => (theme: Theme) =>
    css({
        position: 'relative',
        display: 'flex',
        backgroundColor: PURPLE_SECONDARY,
        borderRadius: theme.borderRadius.XS,
        color: theme.palette.gray[900],
        padding: theme.spacing(2),

        ...(disposition === 'inline' && {
            justifyContent: 'center',
            alignItems: 'center',
        }),
    });

export const getIconStyles = (theme: Theme) =>
    css({
        color: PURPLE_MAIN,
        marginRight: theme.spacing(),
    });

export const getBodyStyles = () =>
    css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    });

export const getTitleStyles = () =>
    css({
        color: PURPLE_MAIN,
        marginBottom: 0,
    });

export const getBodyTextContentStyles = (theme: Theme) =>
    css({
        color: theme.palette.gray[900],
        marginBottom: 0,

        '&&': {
            marginTop: theme.spacing(),
        },
    });

export const getButtonStyles = (disposition: NotificationDisposition, buttonVariant: ButtonVariant) => (theme: Theme) =>
    css({
        width: 'fit-content',
        alignSelf: 'flex-end',

        ...(disposition === 'inline' && {
            marginLeft: theme.spacing(2),
        }),
        ...(disposition === 'row' && {
            marginTop: theme.spacing(),
        }),

        ...(buttonVariant === ButtonVariant.FILLED && {
            backgroundColor: PURPLE_MAIN,

            ':hover': {
                backgroundColor: PURPLE_DARK,
            },
        }),

        ...(buttonVariant === ButtonVariant.OUTLINED && {
            color: PURPLE_MAIN,
            borderColor: PURPLE_MAIN,
            backgroundColor: 'transparent',

            ':hover': {
                color: PURPLE_DARK,
                borderColor: PURPLE_DARK,
            },

            ':active': {
                backgroundColor: 'transparent',
            },
        }),
    });

export const getNotificationSpacingStyles = (theme: Theme) =>
    css({
        marginBottom: theme.spacing(3),
    });
