import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getServiceSelectionCardHeaderStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    });

export const getServiceSelectionCardLogoContainerStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            height: 48,
            lineHeight: theme.typography.lineHeight.XXL,
            marginBottom: theme.spacing(0.5),
            verticalAlign: 'middle',
            width: 80,
        });

export const getServiceSelectionCardLogoStyles = (): SerializedStyles =>
    css({
        maxHeight: 48,
        maxWidth: 80,
    });

export const getServiceSelectionCardActionStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            marginTop: theme.spacing(2),
            fontWeight: theme.typography.fontWeight.semibold,
            fontSize: theme.toRem(theme.typography.fontSize.XS),
        });

export const getServiceSelectionCardDescriptionStyles = (): SerializedStyles =>
    css({
        lineHeight: 1,
        margin: 0,
    });

export const getServiceSelectionCardStyles =
    (selected: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: 165,
            minHeight: 118,
            maxHeight: 160,

            padding: theme.spacing(2),
            border: `1px solid ${selected ? theme.palette.primary.main : theme.palette.gray.light}`,
            borderRadius: theme.borderRadius.M,
            boxShadow: `0 2px 4px 0 ${theme.palette.gray.light}`,
            backgroundColor: selected ? theme.palette.primary.ultraLight : theme.palette.gray.megaLight,

            '&:hover': {
                borderColor: theme.palette.primary.main,
                cursor: 'pointer',
            },
        });

export const getServiceSelectionCardOptionsStyles =
    (isDeactivate: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            color: isDeactivate ? theme.palette.error.main : 'auto',
        });
