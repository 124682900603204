import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { IInvoice, InvoiceDownload } from '@packlink/packlink-sdk';
import { Table, TableBody, TableBodyCell, TableRow } from '@shipengine/giger';
import { InvoiceDownloadPanel } from '@Invoicing/components/InvoiceTable/InvoiceDownloadPanel';
import { InvoiceTableRow } from '@Invoicing/components/InvoiceTable/InvoiceTableRow';
import { triggerDownload } from '@utils/download';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';
import { useInvoicesData, downloadInvoice } from '@Invoicing/hooks/useInvoicesData';
import { InvoiceTableHeader } from './InvoiceTableHeader';
import { InvoiceTableFooter } from './InvoiceTableFooter';

export const InvoiceTable = (): JSX.Element => {
    const { t } = useTranslation();
    const { invoices, isLoading, pagination, changeRequestParams } = useInvoicesData();
    const hasInvoices = !isLoading && !!invoices?.length;
    const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const { sendAmplitudeEvent } = useAmplitude();

    const handleDownloadInvoice = (ref: string): Promise<void> =>
        downloadInvoice(ref).then(({ url }: InvoiceDownload) => {
            url && triggerDownload(url);
        });

    const resetPanel = useCallback((): void => {
        setSelectedInvoices([]);
        setIsPanelOpen(false);
    }, []);

    const handleCheck = ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>): void => {
        if (checked) {
            const checkedInvoices = invoices?.map((i: IInvoice) => i.reference) ?? [];
            setSelectedInvoices(checkedInvoices);
            setIsPanelOpen(true);
        } else {
            resetPanel();
        }

        sendAmplitudeEvent(AmplitudeEvents.TABLE_INVOICES_CLICK_ON_BULK);
    };

    const handleSingleCheck = ({ currentTarget: { checked, value } }: React.ChangeEvent<HTMLInputElement>): void => {
        if (checked) {
            setSelectedInvoices([...selectedInvoices, value]);
            setIsPanelOpen(true);
        } else {
            setSelectedInvoices(selectedInvoices.filter((i: string): boolean => i !== value));
            if (selectedInvoices.length === 1) {
                setIsPanelOpen(false);
            }
        }
    };

    // close panel when unmounting component
    useEffect(() => {
        return (): void => {
            setIsPanelOpen(false);
        };
    }, []);

    // reset panel state when page is changed
    useEffect(() => {
        resetPanel();
    }, [isLoading, resetPanel]);
    const hasPartialSelectionOfInvoices = selectedInvoices.length > 0 && invoices?.length !== selectedInvoices.length;
    const onPageChange = (page: number) => {
        changeRequestParams({ pageNumber: page });
    };

    return (
        <>
            <Table
                data-id="invoices-table"
                header={
                    <InvoiceTableHeader
                        handleCheck={handleCheck}
                        hasInvoices={hasInvoices}
                        hasSelectedInvoices={selectedInvoices.length > 0}
                        isIndeterminate={hasPartialSelectionOfInvoices}
                    />
                }
                footer={<InvoiceTableFooter onPageChange={onPageChange} pagination={pagination} />}
            >
                <TableBody>
                    {hasInvoices ? (
                        invoices?.map((invoice: IInvoice, index: number) => (
                            <InvoiceTableRow
                                key={`${invoice.reference}-${index}`}
                                invoice={invoice}
                                downloadInvoice={handleDownloadInvoice}
                                handleCheck={handleSingleCheck}
                                checked={selectedInvoices.includes(invoice.reference)}
                            />
                        ))
                    ) : (
                        <TableRow>
                            <TableBodyCell colSpan={4}>{t('invoice-table.empty-message')}</TableBodyCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <InvoiceDownloadPanel
                isPanelOpen={isPanelOpen}
                resetPanel={resetPanel}
                invoices={selectedInvoices}
                downloadSingle={handleDownloadInvoice}
            />
        </>
    );
};
