import { DropOff, IDropOff } from '@packlink/packlink-sdk';
import moment from 'moment';

export type TranslatedOpeningTimes = (string | undefined)[][];

export type TranslatedDropOff = Omit<DropOff, 'openingTimes'> & {
    openingTimes: TranslatedOpeningTimes | undefined;
};

// TODO: Fix this implementation to return a proper DropOff<IDropOff> https://packlink.atlassian.net/browse/PS-837
export const translateOpeningTimes = (point: DropOff<IDropOff>): TranslatedDropOff => {
    const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    const translatedDropOff = point.clone() as TranslatedDropOff;

    if (point.openingTimes === undefined) {
        return point as TranslatedDropOff;
    }

    const sortedOpeningTimes: TranslatedOpeningTimes = point.openingTimes
        .map(({ day, time }) => [day, time])
        .sort(([day1], [day2]) => weekdays.indexOf(day1 ?? '') - weekdays.indexOf(day2 ?? ''))
        .map(([, time], index) => {
            const translatedDay = moment.weekdays(true, index);
            return [translatedDay, time];
        });

    translatedDropOff.openingTimes = sortedOpeningTimes;
    return translatedDropOff;
};
