import React from 'react';
import { getButtonDropdownItemStyles, getItemCaptionStyles } from './ButtonDropdownStyles';
import { Typography } from '@shipengine/giger';

interface IButtonDropdownItemProps {
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ButtonDropdownItem: React.FC<IButtonDropdownItemProps> = (
    props: IButtonDropdownItemProps,
): JSX.Element => {
    const { children, onClick } = props;

    return (
        <li css={getButtonDropdownItemStyles} onClick={onClick}>
            <Typography css={getItemCaptionStyles} component="p" variant="body2">
                {children}
            </Typography>
        </li>
    );
};
