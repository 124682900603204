import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const tooltipStyles =
    (index: number, numElements: number) =>
    (theme: Theme): SerializedStyles => {
        const tooltipIndex = index + 1;
        const firstThird = Math.ceil(numElements / 3);
        const secondThird = Math.ceil(firstThird * 2);
        let xTransform = 0;

        // Tooltip positioning issue https://github.com/plouc/nivo/issues/580
        if (tooltipIndex < firstThird) {
            xTransform = 50;
        } else if (tooltipIndex > secondThird) {
            xTransform = -50;
        }

        return css({
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
            backgroundColor: theme.palette.white,
            borderRadius: theme.borderRadius.XS,
            transform: `translateX(${xTransform}%)`,
            ...theme.getElevation('raised'),
        });
    };

export const valueStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(0.5),
    });

export const colorStyles =
    (color: string) =>
    (theme: Theme): SerializedStyles =>
        css({
            width: 12,
            height: 12,
            backgroundColor: color,
            borderRadius: theme.borderRadius.XS,
        });
