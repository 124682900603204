import { useCallback } from 'react';
import { useParams } from 'react-router';
import { APP_ROUTE } from '../../router/routes';
import { CheckoutRoute } from '../routes';

export function useCheckoutPath() {
    const params = useParams();

    const getCheckoutPath = useCallback(
        (step: CheckoutRoute) => {
            return params.id
                ? APP_ROUTE.CHECKOUT.STEP_EDIT.replace(':id', params.id).replace(':step', step)
                : APP_ROUTE.CHECKOUT.STEP.replace(':step', step);
        },
        [params.id],
    );

    return getCheckoutPath;
}
