import {
    getEditableFavoritesCardStyles,
    getEditableFavoritesCardFooterStyles,
    getEditableFavoritesCardHeaderStyles,
    getEditableFavoritesCardHeaderChipStyles,
    getEditableFavoritesCardDeleteConfirmationStyles,
    getEditableFavoritesCardHeaderTitleStyles,
    getEditableFavoritesCardInfoStyles,
    getEditableFavoritesCardActionStyles,
    getEditableFavoritesCardContentStyles,
} from './EditableFavoritesCardStyles';
import { IconNames } from '@shipengine/giger-theme';
import {
    WithCommonProps,
    Button,
    ButtonSize,
    ButtonVariant,
    ButtonColor,
    Tag,
    TagColor,
    ButtonIcon,
} from '@shipengine/giger';

import { useTranslation } from '@packlink/translation-provider';
import { useState } from 'react';

export interface IEditableFavoritesCardProps {
    name: string;
    isDefault?: boolean;
    hasMultipleCards?: boolean;
    canCreateDefault?: boolean;
    setDefault?: () => void;
    setDelete: () => void;
    setEdit: () => void;
}

export const EditableFavoritesCard = ({
    name,
    isDefault = false,
    hasMultipleCards,
    canCreateDefault = false,
    children,
    setDefault,
    setDelete,
    setEdit,
    ...rest
}: WithCommonProps<IEditableFavoritesCardProps>): JSX.Element => {
    const { t } = useTranslation();
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    return (
        <article css={getEditableFavoritesCardStyles(isDefault)} {...rest}>
            <div css={getEditableFavoritesCardContentStyles}>
                <header css={getEditableFavoritesCardHeaderStyles}>
                    {isDefault && (
                        <Tag css={getEditableFavoritesCardHeaderChipStyles} color={TagColor.SECONDARY}>
                            {t('editable-favorites-card.chip.default')}
                        </Tag>
                    )}
                    <h3 css={getEditableFavoritesCardHeaderTitleStyles}>{name}</h3>
                </header>
                <div css={getEditableFavoritesCardInfoStyles}>{children}</div>
            </div>
            <footer css={getEditableFavoritesCardFooterStyles}>
                {hasMultipleCards && canCreateDefault && (
                    <ButtonIcon
                        css={getEditableFavoritesCardActionStyles}
                        icon={IconNames.CHECK_MARK}
                        variant={isDefault ? ButtonVariant.FILLED : ButtonVariant.OUTLINED}
                        size={ButtonSize.SMALL}
                        disabled={isDefault}
                        onClick={setDefault}
                        ariaDescribedby={t('editable-favorites-card.chip.default')}
                        aria-label={t('editable-favorites-card.chip.default')}
                    >
                        {t('editable-favorites-card.chip.default')}
                    </ButtonIcon>
                )}
                <ButtonIcon
                    css={getEditableFavoritesCardActionStyles}
                    icon={IconNames.EDIT}
                    variant={ButtonVariant.OUTLINED}
                    size={ButtonSize.SMALL}
                    onClick={setEdit}
                    ariaDescribedby="settings.actions.edit"
                    aria-label={t('settings.actions.edit')}
                >
                    {t('settings.actions.edit')}
                </ButtonIcon>
                {(hasMultipleCards || !canCreateDefault) && (
                    <ButtonIcon
                        css={getEditableFavoritesCardActionStyles}
                        icon={IconNames.TRASH}
                        variant={ButtonVariant.OUTLINED}
                        size={ButtonSize.SMALL}
                        onClick={() => setDeleteConfirmation(true)}
                        ariaDescribedby="settings.actions.delete"
                        aria-label={t('settings.actions.delete')}
                    >
                        {t('settings.actions.delete')}
                    </ButtonIcon>
                )}
            </footer>

            {deleteConfirmation && (
                <div css={getEditableFavoritesCardDeleteConfirmationStyles}>
                    <Button
                        color={ButtonColor.PRIMARY}
                        variant={ButtonVariant.OUTLINED}
                        size={ButtonSize.SMALL}
                        onClick={() => setDeleteConfirmation(false)}
                    >
                        {t('editable-favorites-card.button.cancel')}
                    </Button>
                    <Button color={ButtonColor.PRIMARY} size={ButtonSize.SMALL} onClick={setDelete}>
                        {t('editable-favorites-card.button.delete')}
                    </Button>
                </div>
            )}
        </article>
    );
};
