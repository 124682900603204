import { SidePanelAction, ISetActivePanel } from '@store/actions/side-panel';
import { SET_ACTIVE_PANEL, CLEAR_ACTIVE_PANEL } from '@store/actions/action-types';
import { activePanel } from '@store/selectors/side-panel';
import { PanelName } from '@types';

export interface ISidePanelState {
    activePanel?: PanelName;
}

export const initialState: ISidePanelState = {
    activePanel: undefined,
};

export const reducer = (state: ISidePanelState = initialState, action: SidePanelAction): ISidePanelState =>
    ({
        [SET_ACTIVE_PANEL]: activePanel.set((action as ISetActivePanel).payload)(state),
        [CLEAR_ACTIVE_PANEL]: activePanel.set(undefined)(state),
    })[action.type] || state;
