import {
    getConnectionCardStyles,
    getConnectionCardLogoStyles,
    getConnectionCardActionStyles,
    getConnectionCardDescriptionStyles,
    getConnectionCardLogoContainerStyles,
} from './ConnectionCardStyles';
import { WithCommonProps } from '@shipengine/giger';

export interface IConnectionCardProps {
    name: string;
    logoUrl?: string;
    disabled?: boolean;
    selected: boolean;
    actionName: string;
    onClick: () => void;
}

export const ConnectionCard: React.FC<WithCommonProps<IConnectionCardProps>> = (
    props: IConnectionCardProps,
): JSX.Element => {
    const { name, logoUrl, disabled = false, selected, actionName, onClick, ...rest } = props;

    const handleOnClick = (): void => {
        if (!disabled) {
            onClick();
        }
    };
    return (
        <article
            css={getConnectionCardStyles(selected, disabled)}
            onClick={handleOnClick}
            aria-selected={selected}
            role="tab"
            {...rest}
        >
            <header>
                {logoUrl && (
                    <div css={getConnectionCardLogoContainerStyles()}>
                        <img css={getConnectionCardLogoStyles(disabled)} alt={name} src={logoUrl} />
                    </div>
                )}
                <p css={getConnectionCardDescriptionStyles()}>{name}</p>
            </header>
            <footer css={getConnectionCardActionStyles(selected, disabled)}>{actionName}</footer>
        </article>
    );
};
