import { useNavigate, useParams } from 'react-router';
import Packlink from '@sdk';
import { Spinner } from '@components/Spinner/Spinner';
import { ShipmentDetails } from './ShipmentDetails';
import { useQuery } from 'react-query';
import { Step } from '@packlink/packlink-sdk';
import { APP_ROUTE } from '@pages/router/routes';

export const ShipmentDetailsWrapper = (): JSX.Element => {
    const navigate = useNavigate();
    const params = useParams();
    const packlinkReference = params?.id || '';

    const { isLoading, data: shipment } = useQuery(
        ['post-sale', 'shipment', packlinkReference],
        () => Packlink.pro.shipments.getShipment(packlinkReference),
        {
            keepPreviousData: true,
            onSuccess: (shipment) =>
                shipment.step !== Step.PURCHASED &&
                navigate(APP_ROUTE.CHECKOUT.EDIT.replace(':id', shipment.data.packlinkReference)),
            onError: () => navigate(APP_ROUTE.SHIPMENTS.INBOX_ALL),
        },
    );
    if (!shipment || isLoading) {
        return <Spinner fullScreen />;
    }

    return <ShipmentDetails shipment={shipment} />;
};
