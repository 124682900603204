import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getExcludedServicesStyles = (theme: Theme): SerializedStyles =>
    css({
        fontStyle: 'italic',
        fontWeight: theme.typography.fontWeight.normal,
    });

export const getExclusiveRatesWrapperStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
    });
