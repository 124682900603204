import { ProgressHTMLAttributes, useEffect, useRef, useState } from 'react';
import { Next, Typography, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import {
    getLabelStyles,
    getProgressBarStyles,
    getProgressBarWrapperStyles,
    getSecondaryLabelStyles,
} from './ProgressBarStyles';

type ProgressBarProps = WithCommonProps<ProgressHTMLAttributes<HTMLProgressElement>> & {
    handleLoadingState?: (state: boolean) => void;
    setLoadingProgressValue?: (value: number) => void;
    isSidePanelContent?: boolean;
};
// We need to set a max value for the progress bar to work properly so that we make sure that this loading component is not working for more than 10 seconds in the worst case scenario
export const MAX_LOADING_PROGRESS = 20;

export const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({
    handleLoadingState,
    setLoadingProgressValue,
    isSidePanelContent = false,
    ...rest
}: ProgressBarProps): JSX.Element => {
    const { t } = useTranslation();

    const value = rest.value ?? 0;
    const max = rest.max ?? MAX_LOADING_PROGRESS;
    const TRANSITION_TIMEOUT_DELAY = 500;
    const FINALSTATE_TIMEOUT_DELAY = 1000;
    const increment = 2;
    const id = 'service-list-progress-bar';
    const [isSecondHalfActive, setSecondHalf] = useState(false);

    // We need to divide by 10 for Firefox because the transition with width is not working properly on this browser
    const getTransitionData = (variable: number) => {
        return variable && window.navigator.userAgent.includes('Firefox') ? variable / 10 : variable;
    };
    const transitionTimeout = useRef<ReturnType<typeof setTimeout>>();
    const finalStateTimeout = useRef<ReturnType<typeof setTimeout>>();
    useEffect(() => {
        setSecondHalf(Number(value) > Number(max) / 2);
        if (Number(value) < Number(max)) {
            transitionTimeout.current = setTimeout(() => {
                setLoadingProgressValue?.(Number(value) + getTransitionData(increment));
            }, getTransitionData(TRANSITION_TIMEOUT_DELAY));
        } else if (Number(value) === Number(max)) {
            transitionTimeout.current && clearTimeout(transitionTimeout.current);
            // Setting timeout to allow the progress bar to reach 100%
            finalStateTimeout.current = setTimeout(() => {
                handleLoadingState?.(true);
            }, FINALSTATE_TIMEOUT_DELAY);
        }
    }, [handleLoadingState, max, setLoadingProgressValue, value]);

    useEffect(() => {
        return (): void => {
            transitionTimeout.current && clearTimeout(transitionTimeout.current);
            finalStateTimeout.current && clearTimeout(finalStateTimeout.current);
        };
    }, []);

    return (
        <div css={getProgressBarWrapperStyles}>
            <Typography css={getLabelStyles(isSecondHalfActive)} variant="body1" component="label" htmlFor={id} bold>
                {t('progress-bar.labels.main')}
            </Typography>

            {isSecondHalfActive && (
                <Typography css={getSecondaryLabelStyles} variant="body1" component="p">
                    {t('progress-bar.labels.secondary')}
                </Typography>
            )}
            <Next.ProgressBar css={getProgressBarStyles(isSidePanelContent)} id={id} max={max} {...rest} />
        </div>
    );
};
