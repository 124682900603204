import { IAppState } from '@store/reducers';
import { ISidePanelState } from '@store/reducers/side-panel';
import { Lens } from 'monocle-ts';
import { createSelector } from 'reselect';

const sidePanel = Lens.fromProp<IAppState>()('sidePanel');

export const activePanel = Lens.fromProp<ISidePanelState>()('activePanel');

export const getActivePanel = createSelector(sidePanel.get, activePanel.get);
