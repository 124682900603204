import { IPriceDetail, IPriceInsurance } from '@packlink/packlink-sdk';

// TODO: PRO-417 move to sdk
export enum UpsellInsuranceType {
    PACKLINK_INSURANCE = 'PACKLINK_INSURANCE',
    NO_INSURANCE = 'NO_INSURANCE',
    STANDARD = 'STANDARD',
    ENHANCED = 'ENHANCED',
}

export enum UpsellServiceType {
    ADULT_SIGNATURE = 'ADULT_SIGNATURE',
    ADDITIONAL_HANDLING = 'ADDITIONAL_HANDLING',
    PRINT_IN_STORE = 'PRINT_IN_STORE',
    CASH_ON_DELIVERY = 'CASH_ON_DELIVERY',
    PROOF_OF_DELIVERY = 'PROOF_OF_DELIVERY',
}

export type UpsellInsurance = Partial<IPriceInsurance>;
export type UpsellService = Partial<IPriceDetail>;
