import { FC, useState } from 'react';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelContentError } from '@components/SidePanel/SidePanelContentError';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { IntegrationDeactivate } from '@Integrations/components/IntegrationDeactivate/IntegrationDeactivate';
import { IntegrationFormLogo } from '@Integrations/components/IntegrationForm/IntegrationFormLogo';
import { IGenericPanelContentProps } from '@Integrations/components/IntegrationPanel/types';
import { InlineNotification, NotificationType } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { TikTokUkIntegrationGuide } from './TikTokUkIntegrationGuide';

type ITikTokUkIntegrationFormProps = Omit<IGenericPanelContentProps, 'isLoading' | 'updateSuccessfully'>;

export const TikTokUkIntegrationForm: FC<ITikTokUkIntegrationFormProps> = (
    props: ITikTokUkIntegrationFormProps,
): JSX.Element => {
    const { integration, onClose, onSubmit, onDeactivate, showError, clearError, onCancel } = props;
    const { t } = useTranslation();
    const [successMessage, setSuccessMessage] = useState<string>();

    const onAction = (): void => {
        onSubmit(integration);
    };

    const handleCancel = (): void => {
        onCancel(integration.name);
    };

    const handleDeactivate = (): void => {
        onDeactivate(integration).then((integrationName?: string) => {
            if (integrationName) {
                setSuccessMessage(
                    t('integrations.panel.deactivation-success', {
                        integrationName,
                    }),
                );
            }
        });
    };

    return (
        <>
            <SidePanelHeader
                onAction={onClose}
                title={t(integration.isConnected ? 'integrations.panel.edit' : 'integrations.panel.connect-shop')}
            />
            <SidePanelContent>
                {!showError && (
                    <>
                        {successMessage && (
                            <InlineNotification type={NotificationType.SUCCESS}>{successMessage}</InlineNotification>
                        )}
                        <IntegrationFormLogo logo={integration.logo} alt={integration.name} />

                        {integration.isConnected && <IntegrationDeactivate handleDeactivate={handleDeactivate} />}
                        <TikTokUkIntegrationGuide collapsible={true} collapsed={integration.isConnected} />
                    </>
                )}
                {showError && (
                    <SidePanelContentError
                        onTryAgain={clearError}
                        title={t('integrations.panel.error-title')}
                        subtitle={t('integrations.panel.error-subtitle')}
                    />
                )}
            </SidePanelContent>
            {!showError && (
                <SidePanelFooter>
                    <SidePanelFooterActions
                        actionText={t('integrations.panel.connect')}
                        cancelText={t('integrations.panel.cancel')}
                        disabledAction={integration.isConnected}
                        onAction={onAction}
                        onCancel={handleCancel}
                    />
                </SidePanelFooter>
            )}
        </>
    );
};
