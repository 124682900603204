import { css } from '@emotion/react';

export const getTicketChatTimelineStyles = css({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
});

export const getTicketChatTimelineScrollToTopStyles = css({
    alignSelf: 'center',
});
