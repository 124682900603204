import { Formik, FormikProps } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { ConfigurationType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { AmazonIntegrationForm } from './AmazonIntegrationForm';
import { AmazonConfiguration, AmazonIntegrationPanelContentProps, IAmazonFormData } from './types';

export const AmazonIntegrationPanel: FC<AmazonIntegrationPanelContentProps> = (
    props: AmazonIntegrationPanelContentProps,
): JSX.Element => {
    const { t } = useTranslation();
    const { onClose, integration, onDeactivate, onSubmit, isLoading, showError, clearError, onCancel } = props;

    const amazonOnSubmit = (formData: IAmazonFormData): void => {
        const amazonConfiguration: AmazonConfiguration = {
            identifier: '',
            integration: EcommerceAlias.Amazon,
            type: ConfigurationType.INBOUND,
            data: { ...formData },
        };

        const amazonIntegration = { ...integration, configuration: amazonConfiguration };

        onSubmit(amazonIntegration);
    };

    const validationSchema = yup.object().shape({
        countryMarketplace: yup.string().required(t('form.error.required')),
    });

    return (
        <Formik
            onSubmit={amazonOnSubmit}
            initialValues={{ countryMarketplace: '' }}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<IAmazonFormData>): React.ReactNode => (
                <AmazonIntegrationForm
                    onCancel={onCancel}
                    onClose={onClose}
                    formProps={formProps}
                    isLoading={isLoading}
                    integration={integration}
                    onDeactivate={onDeactivate}
                    showError={showError}
                    clearError={clearError}
                />
            )}
        </Formik>
    );
};
