import { useNavigate } from 'react-router';
import { PropsWithChildren } from 'react';
import { Button, ButtonSize, ButtonVariant, Icon, IconSize, Typography, WithCommonProps } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { useAmplitude } from '@hooks';
import { APP_ROUTE } from '@pages/router/routes';
import {
    getBodyStyles,
    getBodyTextContentStyles,
    getButtonStyles,
    getIconStyles,
    getNotificationStyles,
    getTitleStyles,
    NotificationDisposition,
} from './SubscriptionNotificationStyles';

export type SubscriptionNotificationProps = {
    title: string;
    buttonVariant?: ButtonVariant;
    disposition?: NotificationDisposition;
    amplitudeEvent: string;
    amplitudeProperties?: Record<string, unknown>;
};

export function SubscriptionNotification({
    children,
    disposition = 'row',
    buttonVariant = ButtonVariant.FILLED,
    amplitudeEvent,
    amplitudeProperties = {},
    title,
    ...rest
}: WithCommonProps<SubscriptionNotificationProps>): JSX.Element {
    return (
        <article css={getNotificationStyles(disposition)} {...rest}>
            <Icon name={IconNames.UPGRADE} size={IconSize.SIZE_MEDIUM} css={getIconStyles} />

            {disposition === 'inline' ? (
                <NotificationBody
                    title={title}
                    buttonVariant={buttonVariant}
                    disposition={disposition}
                    amplitudeEvent={amplitudeEvent}
                    amplitudeProperties={amplitudeProperties}
                />
            ) : (
                <div css={getBodyStyles}>
                    <NotificationBody
                        title={title}
                        buttonVariant={buttonVariant}
                        disposition={disposition}
                        amplitudeEvent={amplitudeEvent}
                        amplitudeProperties={amplitudeProperties}
                    >
                        {children}
                    </NotificationBody>
                </div>
            )}
        </article>
    );
}

function NotificationBody({
    children,
    disposition,
    buttonVariant,
    title,
    amplitudeEvent,
    amplitudeProperties,
}: PropsWithChildren<Required<SubscriptionNotificationProps>>) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const goToSubscriptionsPage = () => {
        sendAmplitudeClickEvent(amplitudeEvent, amplitudeProperties);

        navigate(APP_ROUTE.SUBSCRIPTIONS.INDEX);
    };

    return (
        <>
            <Typography bold component="h3" variant="body2" css={getTitleStyles}>
                {title}
            </Typography>

            {children && (
                <Typography css={getBodyTextContentStyles} component="p" variant="body2">
                    {children}
                </Typography>
            )}

            <Button
                css={getButtonStyles(disposition, buttonVariant)}
                variant={buttonVariant}
                size={ButtonSize.SMALL}
                onClick={goToSubscriptionsPage}
            >
                {t('subscriptions.upgrade-notification.upgrade-now')}
            </Button>
        </>
    );
}
