import { RefObject, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useIntersection from 'react-use/lib/useIntersection';

import { AppDispatch } from '@store';
import { deleteShipmentsFromInboxNotifications } from '@store/actions/bulk';
import { useInboxNotifications } from './useInboxNotifications';

const TIME_IN_VIEW_BEFORE_CLEARING_NOTIFICATION = 5000;
const INTERSECTION_AREA = 0.5; // Intersection is triggered when 50% of the component is in the view

/**
 * Returns if the shipment has a notification and checks if the shipment has been in the user
 * view for more than 5 secs to reset the notification.
 */
export function useShipmentNotificationHandler(ref: RefObject<HTMLElement>, packlinkReference: string) {
    const { inboxNotifications, currentInbox } = useInboxNotifications();
    const dispatch = useDispatch<AppDispatch>();
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const intersection = useIntersection(ref, {
        threshold: INTERSECTION_AREA,
    });

    const hasNotification = useMemo(
        () => !!inboxNotifications?.[packlinkReference],
        [inboxNotifications, packlinkReference],
    );

    useEffect(() => {
        if (!hasNotification || !intersection?.isIntersecting) return;

        timeout.current = setTimeout(() => {
            dispatch(deleteShipmentsFromInboxNotifications(currentInbox, packlinkReference));
        }, TIME_IN_VIEW_BEFORE_CLEARING_NOTIFICATION);

        return () => timeout.current && clearTimeout(timeout.current);
    }, [dispatch, currentInbox, intersection, packlinkReference, hasNotification]);

    return {
        hasNotification,
    };
}
