import { useToggle } from '@packlink/utils';
import {
    Button,
    ButtonSize,
    ButtonVariant,
    Icon,
    IconSize,
    InlineNotification,
    WithCommonProps,
    NotificationType,
    Next,
} from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { StaticDisposition, PrintingFormat } from '@packlink/packlink-sdk';
import { Trans, useTranslation } from '@packlink/translation-provider';

import { PrintingPreferences } from './PrintingPreferences';

import {
    getDownloadLabelOptionsStyles,
    getDownloadLabelStyles,
    getFormatToggleStyles,
    getInfoPanelStyles,
    getFormatToggleIconStyles,
    getDownloadLabelButtonStyles,
    getQRLabelStyles,
} from './DownloadLabelStyles';

export enum ButtonAction {
    DOWNLOAD = 'download',
    PRINT = 'print',
}

export type DownloadLabelProps = WithCommonProps<{
    disabled: boolean;
    download: boolean;
    fetchLabels: (
        shipmentReferences: string[],
        printingFormat: PrintingFormat,
        disposition: StaticDisposition,
        actionType: ButtonAction,
        label?: string,
    ) => void;
    onChangePrintingFormat: (printingFormat: PrintingFormat) => void;
    onFinish?: () => void;
    label?: string;
    qrLabel?: string;
    printingFormat: PrintingFormat;
    shipmentReferences: string[];
    isFullWidth?: boolean;
    initiallyOpen?: boolean;
}>;

export const DownloadLabel = ({
    disabled,
    download,
    printingFormat,
    shipmentReferences,
    isFullWidth = false,
    initiallyOpen = false,
    label,
    qrLabel,
    onFinish,
    fetchLabels,
    onChangePrintingFormat,
    ...props
}: DownloadLabelProps): JSX.Element => {
    const { t } = useTranslation();
    const { state: isFormatChangeOpen, toggle: toggleFormatChange } = useToggle(!!initiallyOpen);

    const onChange = (changePrintingFormat: PrintingFormat): void => {
        onChangePrintingFormat(changePrintingFormat);
    };

    const getLabels = (buttonAction: ButtonAction): void =>
        fetchLabels(
            shipmentReferences,
            printingFormat,
            download ? StaticDisposition.ATTACHMENT : StaticDisposition.INLINE,
            buttonAction,
            label,
        );

    const handlePrintClick = (): void => {
        getLabels(ButtonAction.PRINT);
    };

    const handleDownloadClick = (): void => {
        getLabels(ButtonAction.DOWNLOAD);
    };

    return (
        <div css={getDownloadLabelStyles} {...props}>
            <div>
                <Button
                    isFullWidth={isFullWidth}
                    disabled={disabled}
                    onClick={handlePrintClick}
                    size={ButtonSize.SMALL}
                    data-id="print-label-button"
                >
                    {t('download-label.action.print-label', { count: shipmentReferences.length })}
                </Button>
                <Button
                    isFullWidth={isFullWidth}
                    disabled={disabled}
                    onClick={handleDownloadClick}
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.TEXT}
                    data-id="download-label-button"
                    css={getDownloadLabelButtonStyles}
                >
                    {t('download-label.action.download-label', { count: shipmentReferences.length })}
                </Button>
                {qrLabel && (
                    <>
                        <img css={getQRLabelStyles} src={qrLabel} alt="qr-label" />
                        <Next.Link
                            href={qrLabel}
                            buttonProps={{ isFullWidth: true, variant: ButtonVariant.TEXT, size: ButtonSize.SMALL }}
                            isButton
                            isExternal
                            target="_blank"
                        >
                            <span>{t('post-sale.sidebar.open-qr')}</span>
                        </Next.Link>
                    </>
                )}
            </div>

            <div css={getDownloadLabelOptionsStyles}>
                <div
                    role="button"
                    css={getFormatToggleStyles}
                    onClick={toggleFormatChange}
                    aria-label={t('download-label.action.change-format')}
                >
                    <span>{t('download-label.action.change-format')}</span>
                    <Icon
                        size={IconSize.SIZE_MEDIUM}
                        css={getFormatToggleIconStyles}
                        name={isFormatChangeOpen ? IconNames.CHEVRON_TOP : IconNames.CHEVRON_BOTTOM}
                    />
                </div>
                {isFormatChangeOpen && <PrintingPreferences onChange={onChange} printingFormat={printingFormat} />}
            </div>

            <InlineNotification css={getInfoPanelStyles} type={NotificationType.INFO}>
                <Trans>download-label.info.paper-size</Trans>
            </InlineNotification>
        </div>
    );
};
