import { useMutation } from 'react-query';
import Packlink from '@sdk';
import { logSdkError } from '@utils/logger';
import { ApiClientError } from '@packlink/packlink-sdk';

export const useLanguageChange = () => {
    const mutationUsersLanguage = useMutation(
        (locale: string) => {
            const language = locale.replace('-', '_');
            return Packlink.v1.users.updateLanguage(language);
        },
        {
            onError: (error: ApiClientError) => logSdkError(error),
        },
    );

    const mutationClientsLanguage = useMutation(
        (locale: string) => {
            const language = locale.replace('-', '_');
            return Packlink.v1.clients.updateLanguage(language);
        },
        {
            onError: (error: ApiClientError) => logSdkError(error),
        },
    );

    if (mutationUsersLanguage.isSuccess && mutationClientsLanguage.isSuccess) {
        window.location.reload();
    }

    return {
        mutationUsersLanguage,
        mutationClientsLanguage,
    };
};
