import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { ProShipment, ShipmentService, IWarehouseAddress } from '@packlink/packlink-sdk';
import { Origin } from '@types';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { LocationForm } from '@components/LocationForm/LocationForm';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { WarehouseSelector } from '@components/WarehouseSelector/WarehouseSelector';
import { useTranslation } from '@packlink/translation-provider';
import {
    AddressOption,
    RecipientAddressSelector,
} from '@Addresses/components/RecipientAddressSelector/RecipientAddressSelector';
import { useRecipientAddresses } from '@Addresses/hooks/useRecipientAddresses';
import { Button, ButtonSize, ButtonVariant, Icon, IconSize } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { IRecipientForm } from './types';
import { useAmplitude } from '@hooks/useAmplitude';

interface IInformationSectionProps {
    warehouseId: string;
    shipmentData: ProShipment['data'];
    service?: ShipmentService;
    onChangeWarehouse: (warehouse: IWarehouseAddress) => void;
}

export const InformationSection: React.FC<IInformationSectionProps> = (
    props: IInformationSectionProps,
): JSX.Element => {
    const { service, shipmentData, warehouseId, onChangeWarehouse } = props;
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const { values, handleChange } = useFormikContext<IRecipientForm>();
    const [selectedAddress, setSelectedAddress] = useState<AddressOption>();
    const [hasSelectedRecipientAddress, setHasSelectedRecipientAddress] = useState(false);
    const { addresses: recipientAddresses, isLoading: isLoadingRecipientAddresses } = useRecipientAddresses({});
    const hasRecipientAddresses = recipientAddresses && recipientAddresses?.pagination.totalElements > 0;

    const hasAddressInformation = () => {
        const address = shipmentData.to;
        return !!(address && Object.values(address).filter((a) => a?.length).length);
    };

    const [showRecipientForm, setShowRecipientForm] = useState(hasAddressInformation);

    const updateRecipientAddressForm = (address?: AddressOption) => {
        handleChange({
            target: {
                name: Origin.TO,
                value: {
                    firstName: address?.firstName,
                    lastName: address?.lastName,
                    email: address?.email,
                    phone: address?.phone,
                    company: address?.company,
                    street1: service?.dropOff?.destination ? undefined : address?.address,
                    alpha2Code: address?.alpha2Code,
                    city: address?.city,
                    zipCode: address?.zipCode,
                    state: address?.postalZone.translations[locale],
                    country: address && {
                        label: address.postalZone.translations[locale],
                        value: address.postalZone.id,
                    },
                    postalCode: address && {
                        label: `${address.zipCode} - ${address.city}`,
                        value: address.postalCodeId,
                    },
                },
            },
        });
    };

    const onDifferentRecipientAddress = (): void => {
        setSelectedAddress(undefined);
        updateRecipientAddressForm();
        setHasSelectedRecipientAddress(false);
        setShowRecipientForm(true);

        sendAmplitudeClickEvent(AmplitudeEvents.SHIP_DIFFERENT_ADDRESS, {
            [AmplitudeProperties.TYPE]: 'recipient',
            [AmplitudeProperties.SECTION]: 'side panel',
        });
    };

    const onChangeRecipient = (address: AddressOption): void => {
        setSelectedAddress(address);
        updateRecipientAddressForm(address);
        setHasSelectedRecipientAddress(true);
        setShowRecipientForm(true);

        sendAmplitudeClickEvent(AmplitudeEvents.ADDRESS_BOOK_SELECTOR, {
            [AmplitudeProperties.TYPE]: 'recipient',
        });
    };

    const handleOnChangeWarehouse = (warehouse: IWarehouseAddress) => {
        onChangeWarehouse(warehouse);

        sendAmplitudeClickEvent(AmplitudeEvents.ADDRESS_BOOK_SELECTOR, {
            [AmplitudeProperties.TYPE]: 'sender',
        });
    };

    return (
        <>
            <SidePanelContentSection title={t('recipient-panel.sender.section-title')}>
                {warehouseId ? (
                    <WarehouseSelector selectedWarehouseId={warehouseId} onChange={handleOnChangeWarehouse} />
                ) : (
                    <LocationForm
                        name={Origin.FROM}
                        hasPickUp={service?.dropOff?.destination}
                        initialPostalZoneId={shipmentData.additionalData.postal_zone_id_from as string}
                        initialPostalCodeId={shipmentData.additionalData.zip_code_id_from as string}
                        isCollapsed={true}
                    />
                )}
            </SidePanelContentSection>

            <SidePanelContentSection title={t('recipient-panel.recipient.section-title')}>
                {!isLoadingRecipientAddresses && (
                    <>
                        {hasRecipientAddresses && (
                            <RecipientAddressSelector
                                selectedAddress={selectedAddress}
                                address={values.to}
                                onChange={onChangeRecipient}
                            />
                        )}

                        {(showRecipientForm || !hasRecipientAddresses) && (
                            <LocationForm
                                name={Origin.TO}
                                hasPickUp={service?.dropOff?.destination}
                                initialPostalZoneId={shipmentData.additionalData.postal_zone_id_to as string}
                                initialPostalCodeId={shipmentData.additionalData.zip_code_id_to as string}
                                isCollapsed={true}
                                readOnly={hasSelectedRecipientAddress}
                            />
                        )}

                        {(!showRecipientForm || selectedAddress) && hasRecipientAddresses && (
                            <Button
                                variant={ButtonVariant.TEXT}
                                size={ButtonSize.SMALL}
                                onClick={onDifferentRecipientAddress}
                            >
                                <Icon name={IconNames.ADD} size={IconSize.SIZE_SMALL} />
                                <span>{t('select-address.address-book.send')}</span>
                            </Button>
                        )}
                    </>
                )}
            </SidePanelContentSection>
        </>
    );
};
