import { FieldMessageType, WithCommonProps } from '@shipengine/giger';
import { FastField, Form, Formik } from 'formik';
import { Trans, useTranslation } from '@packlink/translation-provider';
import {
    DamageTicketFormData,
    useDamageTicketFormHandlers,
    useDamageTicketFormValidationSchema,
} from './DamageTicketFormData';
import { SupportTicketPaymentFieldData } from '../CommonFields/SupportTicketPaymentField/SupportTicketPaymentFieldData';
import { SupportTicketPaymentField } from '../CommonFields/SupportTicketPaymentField/SupportTicketPaymentField';
import { NewTicketFieldset } from '../CommonFields/NewTicketFieldset/NewTicketFieldset';
import { SubmitButton } from '../CommonFields/SubmitButton/SubmitButton';
import { initialSupportTicketClaimCommonData } from '../CommonFields/SupportTicketClaimFields/SupportTicketClaimFieldsData';
import { UploadAttachmentField } from '@SupportCenter/components/UploadAttachment/UploadAttachmentField';
import { getSubmitButtonStyles } from '../../styles/FormStyles';
import { SupportTicketClaimFields } from '../CommonFields/SupportTicketClaimFields/SupportTicketClaimFields';
import { NewTicketEventHandlers } from '../../NewTicketData';
import { getFormFileInputStyles } from './DamageTicketFormStyles';

type DamageTicketFormProps = WithCommonProps<NewTicketEventHandlers>;

export function DamageTicketForm({ onSubmit, ...props }: DamageTicketFormProps): JSX.Element {
    const { t } = useTranslation();
    const { validationSchema, initialValues, handleSubmit } = useFormData({ onSubmit });

    return (
        <Formik<DamageTicketFormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
        >
            <Form {...props}>
                <SupportTicketClaimFields />
                <NewTicketFieldset title={t('support-center.damage-form.set-of-pictures-label')}>
                    <FastField
                        css={getFormFileInputStyles}
                        component={UploadAttachmentField}
                        label={<Trans i18nKey="support-center.damage-form.proof-of-damage-goods-label" />}
                        message={{
                            content: t('support-center.damage-form.proof-of-damage-required-message'),
                            type: FieldMessageType.INFO,
                        }}
                        name="proofOfDamageGoods"
                        data-id="damage-support-ticket-proof-of-damage-goods"
                    />
                    <FastField
                        css={getFormFileInputStyles}
                        component={UploadAttachmentField}
                        label={<Trans i18nKey="support-center.damage-form.proof-of-damage-external-packaging-label" />}
                        message={{
                            content: t('support-center.damage-form.proof-of-damage-required-message'),
                            type: FieldMessageType.INFO,
                        }}
                        name="proofOfDamageExternalPackaging"
                        data-id="damage-support-ticket-proof-of-damage-external-packaging"
                    />
                    <FastField
                        css={getFormFileInputStyles}
                        component={UploadAttachmentField}
                        label={<Trans i18nKey="support-center.damage-form.proof-of-damage-internal-packaging-label" />}
                        message={{
                            content: t('support-center.damage-form.proof-of-damage-required-message'),
                            type: FieldMessageType.INFO,
                        }}
                        name="proofOfDamageInternalPackaging"
                        data-id="damage-support-ticket-proof-of-damage-internal-packaging"
                    />
                </NewTicketFieldset>
                <NewTicketFieldset title={t('support-center.payment-field.payment-label')}>
                    <FastField
                        component={SupportTicketPaymentField}
                        name="payment"
                        data-id="damage-support-ticket-payment-method"
                    />
                </NewTicketFieldset>
                <SubmitButton css={getSubmitButtonStyles} />
            </Form>
        </Formik>
    );
}

function useFormData(eventHandlers: NewTicketEventHandlers) {
    const validationSchema = useDamageTicketFormValidationSchema();
    const initialValues: DamageTicketFormData = {
        ...initialSupportTicketClaimCommonData,
        proofOfDamageExternalPackaging: undefined,
        proofOfDamageGoods: undefined,
        proofOfDamageInternalPackaging: undefined,
        // Payment initial data should be set when select the payment type
        payment: {
            type: '',
            data: {},
        } as SupportTicketPaymentFieldData,
    };
    const { handleSubmit } = useDamageTicketFormHandlers(eventHandlers);

    return {
        validationSchema,
        initialValues,
        handleSubmit,
    };
}
