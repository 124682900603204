import { useTranslation, Trans } from '@packlink/translation-provider';

import {
    DialogHeader,
    RadioGroup,
    Radio,
    InlineNotification,
    NotificationType,
    Button,
    ButtonVariant,
    ButtonColor,
    Link,
} from '@shipengine/giger';
import { useHelpCenter, HELP_CENTER_KEYS } from '@packlink/provider';

import { getInlineNotificationStyles, getPrivacyWithdrawalModalContentStyles } from './PrivacyWithdrawalModalStyles';

interface IPrivacyWithdrawalModalFirstWarningProps {
    closeModalHandler: () => void;
    acceptWarningHandler: () => void;
}

export function PrivacyWithdrawalModalFirstWarning({
    closeModalHandler,
    acceptWarningHandler,
}: IPrivacyWithdrawalModalFirstWarningProps): JSX.Element {
    const { t } = useTranslation();

    const getHelpCenterPage = useHelpCenter();

    return (
        <>
            <DialogHeader title={t('privacy-settings.heading.personal-data')} />

            <section css={getPrivacyWithdrawalModalContentStyles}>
                <p>
                    <Trans
                        i18nKey="privacy-settings.description.data-processing"
                        components={[
                            <Link
                                key="link"
                                target="_blank"
                                href={getHelpCenterPage(HELP_CENTER_KEYS.privacyPolicy)}
                            />,
                        ]}
                    />
                </p>
                <RadioGroup name="dataProcessing" value="false" onChange={closeModalHandler}>
                    <Radio value="true">{t('privacy-settings.input-value.yes')}</Radio>
                    <Radio value="false">{t('privacy-settings.input-value.no')}</Radio>
                </RadioGroup>

                <InlineNotification
                    css={getInlineNotificationStyles}
                    type={NotificationType.ERROR}
                    title={t('privacy-settings.close-account.first-warning-title')}
                >
                    <Trans i18nKey="privacy-settings.close-account.first-warning" components={[<br key="br" />]} />
                </InlineNotification>
                <Button variant={ButtonVariant.TEXT} color={ButtonColor.ERROR} onClick={acceptWarningHandler}>
                    {t('privacy-settings.close-account.accept')}
                </Button>
            </section>
        </>
    );
}
