import { ResponsiveLine as ResponsiveLineNivo } from '@nivo/line';
import { ResponsiveLineProps } from '../../types';
import { useLineChartConfig } from '../../hooks/useChartConfig';
import { responsiveContainerStyles } from '../Layout/ChartStyles';

export function ResponsiveLine(props: ResponsiveLineProps): JSX.Element {
    const chartConfig = useLineChartConfig(props);

    return (
        <div css={responsiveContainerStyles} id={props.id}>
            <ResponsiveLineNivo {...chartConfig} />
        </div>
    );
}
