import { useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store';
import { Lens } from 'monocle-ts';
import { IShipment, ProShipment, Shipment } from '@packlink/packlink-sdk';
import Packlink from '@sdk';
import { AmplitudeEventPrefixes, AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import {
    IShipmentPanelContextProps,
    ShipmentPanelConextProp,
    ShipmentPanelContext,
    ShipmentPanelContextValue,
} from '@context/ShipmentPanel';
import { cleanSelectedShipments, removeShipmentsToPay } from '@store/actions/bulk';
import { PanelName, ShipmentPanelType } from '@types';
import { logSdkError } from '@utils/logger';
import { useSidePanel } from '@hooks/useSidePanel';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IUseShipmentPanel {
    panelState: IShipmentPanelContextProps;
    closePanel: (sendEvent?: boolean) => void;
    closePanelOverlay: () => void;
    timedClosePanel: () => void;
    exitPanel: () => void;
    openPanel: (
        panelName: ShipmentPanelType,
        shipment?: ProShipment,
        context?: ShipmentPanelConextProp,
        triggerEvent?: boolean,
    ) => void;
    updateShipment: (shipment: IShipment) => Promise<void>;
    changePanel: (panelName: ShipmentPanelType) => void;
    updatePanelContext: (context: ShipmentPanelConextProp) => void;
}

export const useShipmentPanel = (): IUseShipmentPanel => {
    const [panelState, setPanelState] = useContext<ShipmentPanelContextValue>(ShipmentPanelContext);

    const { open: openSidePanel, close: closeSidePanel } = useSidePanel(PanelName.SHIPMENTS);
    const timeoutId = useRef<ReturnType<typeof setTimeout>>();

    const dispatch = useDispatch<AppDispatch>();
    const { sendAmplitudeSidebarClickEvent, sendAmplitudeShipmentEvent } = useAmplitude();

    const sendSidebarEvent = useCallback(
        (eventName: AmplitudeEvents): void => {
            sendAmplitudeSidebarClickEvent(eventName, {
                [AmplitudeProperties.PANEL_NAME]: panelState.panelName,
            });
        },
        [panelState.panelName, sendAmplitudeSidebarClickEvent],
    );

    const resetPanel = useCallback((): void => {
        closeSidePanel();
        setPanelState({
            panelName: undefined,
            shipment: undefined,
            context: undefined,
        });
        dispatch(cleanSelectedShipments());
        dispatch(removeShipmentsToPay());
    }, [closeSidePanel, setPanelState, dispatch]);

    const openPanel = useCallback(
        (
            panelName: ShipmentPanelType,
            shipment?: ProShipment,
            context?: ShipmentPanelConextProp,
            triggerEvent = true,
        ): void => {
            openSidePanel();
            setPanelState({
                panelName,
                shipment,
                context,
            });

            if (triggerEvent) {
                const eventName =
                    `${AmplitudeEventPrefixes.TABLE_VIEW_CLICK} ${AmplitudeEvents.EDIT} ${panelName}` as AmplitudeEvents;
                if (shipment) {
                    sendAmplitudeShipmentEvent(eventName, shipment.data);
                }
            }
        },
        [openSidePanel, setPanelState, sendAmplitudeShipmentEvent],
    );

    const updatePanelContext = useCallback(
        (context: ShipmentPanelConextProp): void => {
            setPanelState({
                ...panelState,
                context,
            });
        },
        [panelState, setPanelState],
    );

    const exitPanel = useCallback((): void => {
        sendSidebarEvent(AmplitudeEvents.EXIT_BUTTON);
        resetPanel();
    }, [sendSidebarEvent, resetPanel]);

    const closePanel = useCallback(
        (sendEvent = true): void => {
            sendEvent && sendSidebarEvent(AmplitudeEvents.X);
            resetPanel();
        },
        [sendSidebarEvent, resetPanel],
    );

    const timedClosePanel = useCallback((): void => {
        timeoutId.current && clearTimeout(timeoutId.current);

        timeoutId.current = setTimeout((): void => {
            resetPanel();
        }, 4000);
    }, [resetPanel]);

    const closePanelOverlay = useCallback((): void => {
        sendSidebarEvent(AmplitudeEvents.OUT_OF_SIDEBAR_TO_EXIT);
        resetPanel();
    }, [sendSidebarEvent, resetPanel]);

    const updateShipment = useCallback(
        (shipment: IShipment): Promise<void> => {
            sendSidebarEvent(AmplitudeEvents.SAVE_BUTTON);

            const insuranceAmount = Lens.fromPath<IShipment>()(['upsales', 'insurance', 'amount']);
            // Backend checks if insurance amount is defined to change status to ready to pay
            const normalizedShipment = insuranceAmount.set(shipment.upsales.insurance.amount || 0)(shipment);

            return Packlink.v1.shipments.updateShipment(Shipment.deserialize(normalizedShipment)).catch(logSdkError);
        },
        [sendSidebarEvent],
    );

    const changePanel = (panelName: ShipmentPanelType): void => {
        setPanelState({
            ...panelState,
            panelName,
        });
    };

    useEffect(() => {
        return (): void => timeoutId.current && clearTimeout(timeoutId.current);
    }, []);

    return {
        panelState,
        closePanel,
        closePanelOverlay,
        timedClosePanel,
        exitPanel,
        openPanel,
        updateShipment,
        changePanel,
        updatePanelContext,
    };
};
