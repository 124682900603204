import { FormField, Textarea } from '@shipengine/formik-giger';
import { FastField } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { getFormElementStyles, getFormInputStyles } from '../../../styles/FormStyles';
import { NewTicketFieldset } from '../NewTicketFieldset/NewTicketFieldset';
import { UploadAttachmentField } from '@SupportCenter/components/UploadAttachment/UploadAttachmentField';
import { FieldMessageType, WithCommonProps } from '@shipengine/giger';

type GenericSupportTicketFieldsProps = WithCommonProps<{
    title: string;
    label: string;
    hint: string;
}>;

export function GenericSupportTicketFields({
    title,
    label,
    hint,
    ...otherProps
}: GenericSupportTicketFieldsProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <NewTicketFieldset title={title} {...otherProps}>
                <FormField
                    name="reason"
                    message={{ type: FieldMessageType.HINT, content: hint }}
                    css={[getFormElementStyles, getFormInputStyles]}
                >
                    <FastField component={Textarea} label={label} />
                </FormField>
            </NewTicketFieldset>
            <NewTicketFieldset title={t('support-center.generic-support-ticket-fields.additional-picture-label')}>
                <FastField
                    component={UploadAttachmentField}
                    css={getFormElementStyles}
                    label={t('support-center.new-ticket.attachment-restrictions-label')}
                    message={{
                        type: FieldMessageType.INFO,
                        content: t('form.hint.optional'),
                    }}
                    name="additionalPicture"
                    data-id="generic-support-ticket-fields-additional-picture"
                />
            </NewTicketFieldset>
        </>
    );
}
