import { useEffect, useMemo } from 'react';
import { WithChildrenCommonProps } from '@shipengine/giger';
import { getImageStyles } from './ImagePreviewStyles';

export type ImagePreviewProps = WithChildrenCommonProps<{
    image: File | string;
}>;

export function ImagePreview({ image, ...rest }: ImagePreviewProps): JSX.Element {
    const imageUrl = useMemo(() => (typeof image === 'string' ? image : URL.createObjectURL(image)), [image]);

    useEffect(() => {
        return () => {
            URL.revokeObjectURL(imageUrl);
        };
    }, [imageUrl]);

    return <img data-id="image-preview" css={getImageStyles} src={imageUrl} alt="" {...rest} />;
}
