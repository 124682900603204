import { useTheme } from '@emotion/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router';

import { IconNames } from '@shipengine/giger-theme';
import { GTMEvents, GTMPageType } from '@packlink/metrics';
import { useTranslation } from '@packlink/translation-provider';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { ImportSource, Inbox, Integration } from '@packlink/packlink-sdk';
import { BrandAssetType, BrandLogo, useAssetUrl } from '@packlink/tenant-theme-provider';
import { useMedia } from '@packlink/utils';
import { Button, ButtonColor, ButtonIcon, ButtonSize, ButtonVariant, Icon, IconSize } from '@shipengine/giger';

import { AppDispatch } from '@store';
import { ITenantConfig, PanelName } from '@types';
import { ShipmentSearch } from '@components/ShipmentSearch/ShipmentSearch';
import { ImportCSVModal } from '@CsvImport/components/ImportModal/ImportCSVModal';
import { getCurrentInbox } from '@store/selectors/inbox';
import { getHasPanelFilters } from '@store/selectors/bulk';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { GTMSectionIds, GTMSectionType } from '@constants/gtm';
import { useAmplitude } from '@hooks/useAmplitude';
import { useBeamer } from '@hooks/useBeamer';
import { useSidePanel } from '@hooks/useSidePanel';

import { AppRoute, APP_ROUTE } from '@pages/router/routes';
import { inboxToRoute } from '@pages/router/utils/paths';
import { IntegrationConfigurations } from '@Integrations/components/IntegrationPanel/types';
import { setActiveIntegrationImport } from '@Integrations/store/actions/integrations';

import { AppHeaderMenu } from './AppHeaderMenu/AppHeaderMenu';
import { AppHeaderPanel } from './AppHeaderPanel/AppHeaderPanel';
import { AppHeaderActions } from './AppHeaderActions/AppHeaderActions';

import {
    getAppHeaderActionsBackStyles,
    getAppHeaderActionsStyles,
    getAppHeaderExportStyles,
    getAppHeaderFilterStyles,
    getAppHeaderLogoStyles,
    getAppHeaderStyles,
    getBeamerButtonStyles,
    getClearFiltersStyles,
    getExportCsvIconStyles,
    getLogoWrapperStyles,
    getMenuDividerStyles,
} from './AppHeaderStyles';
import { CheckoutRoute } from '@pages/checkout/routes';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';

export const AppHeader = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const isDesktop = useMedia(theme.mediaQuery('tablet'));
    const getLogo = useAssetUrl(BrandAssetType.LOGO);
    const navigate = useNavigate();
    const { sendGtmEvent } = useGoogleTagManager();
    const {
        sendAmplitudeEvent,
        sendAmplitudeHeaderClickEvent,
        sendAmplitudeClickEvent,
        sendAmplitudeTableViewClickEvent,
    } = useAmplitude();

    const { canConnectToEshop } = useTenantConfig<ITenantConfig>();

    const inbox = useSelector(getCurrentInbox);
    const hasPanelFilters = useSelector(getHasPanelFilters);

    const {
        isSearchVisible,
        isFilteringVisible,
        isExportVisible,
        isBackToShipmentsVisible,
        isMobileMenuVisible,
        isActionsSectionVisible,
        isNotificationIndicatorVisible,
        isHeaderMenuVisible,
    } = useHeaderElementsVisibility(inbox, isDesktop);

    const { open: openPanel } = useSidePanel();
    const [dialogOpened, setDialogOpened] = useState(false);

    useBeamer();

    const connectShopButtonLabel = t('header.actions.connect-shop');

    const sendSearchBarAmplitudeEvent = (event: AmplitudeEvents) => {
        const eventName = `${event} ${AmplitudeEventPrefixes.SEARCH_BAR}`;
        sendAmplitudeClickEvent(eventName);
    };

    const goToIntegrations = () => {
        navigate(APP_ROUTE.SETTINGS.INTEGRATIONS);
        sendSearchBarAmplitudeEvent(AmplitudeEvents.INTEGRATIONS);
    };

    const goToNewShipment = () => {
        navigate(APP_ROUTE.CHECKOUT.STEP.replace(':step', CheckoutRoute.INFO), { state: { isNew: true } });
    };

    const createShipment = () => {
        goToNewShipment();
        sendSearchBarAmplitudeEvent(AmplitudeEvents.CREATE_SHIPMENT);
    };

    const addShipment = () => {
        goToNewShipment();
        sendSearchBarAmplitudeEvent(AmplitudeEvents.ADD_SHIPMENT);
    };

    const onClickSettings = () => {
        navigate(APP_ROUTE.SETTINGS.BILLING_INVOICES);
        sendAmplitudeEvent(AmplitudeEvents.CONFIGURATION);
    };

    const closeImportCSVDialog = (): void => {
        setDialogOpened(false);
    };

    const onOpenImportCSVModal = (): void => {
        setDialogOpened(true);
        sendAmplitudeHeaderClickEvent(AmplitudeEvents.IMPORT_CSV);
    };

    const onOpenImportOrdersModal = ({ name, alias, configuration }: Integration<IntegrationConfigurations>): void => {
        dispatch(setActiveIntegrationImport({ name, alias, configuration, source: ImportSource.MANUAL_INTEGRATION }));
    };

    const openFilters = (): void => {
        sendAmplitudeTableViewClickEvent(hasPanelFilters ? AmplitudeEvents.EDIT_FILTERS : AmplitudeEvents.FILTERS);
        openPanel(PanelName.FILTERS);
    };

    const openExport = (): void => {
        sendAmplitudeTableViewClickEvent(AmplitudeEvents.EXPORT_FILE);
        openPanel(PanelName.SHIPMENTS_EXPORT);
    };

    const clearFilters = (): void => {
        sendAmplitudeTableViewClickEvent(AmplitudeEvents.DELETE_FILTERS);
        navigate(inboxToRoute(inbox));
    };

    const handleAddShipment = (text: string): void => {
        sendGtmEvent(GTMEvents.BUTTON_CLICK, {
            sectionId: GTMSectionIds.ADD_SHIPMENT_BUTTON,
            sectionType: GTMSectionType.HEADER,
            pageType: GTMPageType.TABLE_PRO,
            clickText: text,
        });

        addShipment();
    };

    const handleSetupIntegration = (): void => {
        sendGtmEvent(GTMEvents.BUTTON_CLICK, {
            sectionId: GTMSectionIds.CONNECT_STORE_BUTTON,
            sectionType: GTMSectionType.HEADER,
            pageType: GTMPageType.TABLE_PRO,
            clickText: connectShopButtonLabel,
        });

        goToIntegrations();
    };

    const onClickBackToShipments = () => {
        navigate(inboxToRoute(inbox || Inbox.READY_TO_PURCHASE));
    };

    return (
        <header css={getAppHeaderStyles}>
            <section css={getLogoWrapperStyles}>
                <img
                    alt="logo"
                    css={getAppHeaderLogoStyles}
                    src={getLogo(BrandLogo.LOGO)}
                    title={t('app.helmet.title')}
                    onClick={onClickBackToShipments}
                />
            </section>

            {isMobileMenuVisible && (
                <AppHeaderPanel
                    onOpenImportsModal={onOpenImportOrdersModal}
                    onOpenImportCSVModal={onOpenImportCSVModal}
                    onCreateShipment={createShipment}
                    onClickSetupIntegrations={goToIntegrations}
                    onClickSettings={onClickSettings}
                />
            )}

            {isBackToShipmentsVisible && (
                <Button size={ButtonSize.SMALL} variant={ButtonVariant.TEXT} onClick={onClickBackToShipments}>
                    <Icon name={IconNames.CHEVRON_LEFT} size={IconSize.SIZE_SMALL} />
                    <span css={getAppHeaderActionsBackStyles}>{t('header.actions.back-to-shipments')}</span>
                </Button>
            )}

            {isSearchVisible && <ShipmentSearch />}

            {isFilteringVisible && (
                <div css={getAppHeaderFilterStyles}>
                    <Button variant={ButtonVariant.TEXT} color={ButtonColor.PRIMARY} onClick={openFilters}>
                        <Icon name={IconNames.FILTER} size={IconSize.SIZE_SMALL} />
                        <span>{hasPanelFilters ? t('filters.button.edit-filters') : t('filters.button.title')}</span>
                    </Button>
                    {hasPanelFilters && (
                        <ButtonIcon
                            onClick={clearFilters}
                            css={getClearFiltersStyles}
                            aria-label="filters.button.clear-filters"
                            variant={ButtonVariant.TEXT}
                            color={ButtonColor.PRIMARY}
                            ariaDescribedby="tooltip"
                            icon={IconNames.CIRCULAR_ARROW}
                        >
                            <span>{t('filters.button.clear-filters')}</span>
                        </ButtonIcon>
                    )}
                </div>
            )}

            {isExportVisible && (
                <div css={getAppHeaderExportStyles}>
                    <Button variant={ButtonVariant.TEXT} color={ButtonColor.PRIMARY} onClick={openExport}>
                        <Icon css={getExportCsvIconStyles} name={IconNames.DOWNLOAD} size={IconSize.SIZE_MEDIUM} />
                        {t('shipments-export.button.export-csv')}
                    </Button>
                </div>
            )}

            {isActionsSectionVisible && (
                <section css={getAppHeaderActionsStyles}>
                    <AppHeaderActions
                        onOpenImportsModal={onOpenImportOrdersModal}
                        onOpenImportCSVModal={onOpenImportCSVModal}
                        onAddShipment={handleAddShipment}
                        onCreateShipment={createShipment}
                    />

                    {canConnectToEshop && (
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            size={ButtonSize.SMALL}
                            onClick={handleSetupIntegration}
                            data-id="connect-store-button"
                        >
                            {connectShopButtonLabel}
                        </Button>
                    )}
                </section>
            )}

            {isHeaderMenuVisible && <AppHeaderMenu onClickSettings={onClickSettings} />}

            {isNotificationIndicatorVisible && (
                <>
                    {isDesktop && <div css={getMenuDividerStyles}></div>}
                    <Button
                        variant={ButtonVariant.TEXT}
                        color={ButtonColor.PRIMARY}
                        css={getBeamerButtonStyles}
                        id="beamerButton"
                        aria-label={t('header.actions.notifications')}
                    >
                        <Icon name={IconNames.BELL} size={IconSize.SIZE_SMALL} />
                    </Button>
                </>
            )}

            <ImportCSVModal isOpened={dialogOpened} onFileUploaded={closeImportCSVDialog} />
        </header>
    );
};

function useHeaderElementsVisibility(inbox: Inbox, isDesktop: boolean) {
    const isInSettings = !!useMatch({ path: AppRoute.SETTINGS, end: false });
    const isInShipments = !!useMatch({ path: AppRoute.SHIPMENTS, end: false });

    return {
        isSearchVisible: isInShipments,
        isFilteringVisible: isInShipments && inbox !== Inbox.ARCHIVED,
        isExportVisible: isInShipments,
        isBackToShipmentsVisible: isInSettings,
        isMobileMenuVisible: !isDesktop,
        isActionsSectionVisible: isDesktop,
        isNotificationIndicatorVisible: !!config.beamer?.id,
        isHeaderMenuVisible: isDesktop,
    };
}
