import { Tag, TagColor, TagVariant, WithCommonProps } from '@shipengine/giger';
import { SupportTicketStatus } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

type TicketStatusTagProps = WithCommonProps<{
    data: SupportTicketStatus;
}>;

export function TicketStatusTag({ data, ...props }: TicketStatusTagProps): JSX.Element {
    const label = useStatusLabel(data);
    return (
        <Tag variant={TagVariant.FILLED} color={getStatusTagColor(data)} uppercase={false} {...props}>
            {label}
        </Tag>
    );
}

function getStatusTagColor(status: SupportTicketStatus): TagColor {
    switch (status) {
        case SupportTicketStatus.OPEN:
        case SupportTicketStatus.NEW:
            return TagColor.INFO;
        case SupportTicketStatus.SOLVED:
        case SupportTicketStatus.CLOSED:
            return TagColor.SUCCESS;
        case SupportTicketStatus.AWAITING_INFORMATION:
            return TagColor.ERROR;
        default:
            return TagColor.ALERT;
    }
}

function useStatusLabel(status: SupportTicketStatus) {
    const { t } = useTranslation();
    switch (status) {
        case SupportTicketStatus.OPEN:
        case SupportTicketStatus.NEW:
            return t('support-center.tickets-table.support-ticket-status-open');
        case SupportTicketStatus.SOLVED:
        case SupportTicketStatus.CLOSED:
            return t('support-center.tickets-table.support-ticket-status-solved');
        case SupportTicketStatus.AWAITING_INFORMATION:
            return t('support-center.tickets-table.support-ticket-status-awaiting-information');
        default:
            return t('support-center.tickets-table.support-ticket-status-unknown');
    }
}
