import React, { useMemo } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import DOMPurify from 'dompurify';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { getTermsAndConditionsStyles } from './TermsAndConditionsStyles';
import { Typography } from '@shipengine/giger';

interface ITermsAndConditionsProps {
    buttonText: string;
    translationKey?: string;
}

export const TermsAndConditions: React.FC<ITermsAndConditionsProps> = (
    props: ITermsAndConditionsProps,
): JSX.Element => {
    const { buttonText, translationKey = 'shipment-panel.footer.terms-and-conditions' } = props;

    const { t } = useTranslation();
    const getHelpCenterPage = useHelpCenter();

    const termsAndConditions = useMemo(
        (): string =>
            DOMPurify.sanitize(
                t(translationKey, {
                    buttonText,
                    prohibitedItemsUrl: getHelpCenterPage(HELP_CENTER_KEYS.prohibitedItems),
                    properPackagingUrl: getHelpCenterPage(HELP_CENTER_KEYS.packagingGuide),
                }),
                { ADD_ATTR: ['target'] },
            ),
        [t, translationKey, buttonText, getHelpCenterPage],
    );

    return (
        <Typography
            css={getTermsAndConditionsStyles}
            component="p"
            variant="body2"
            dangerouslySetInnerHTML={{ __html: termsAndConditions }}
        />
    );
};
