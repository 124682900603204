import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export function getOriginDetailsStyles(): SerializedStyles {
    return css({
        gridArea: 'origin',
    });
}

export const getAsideDropoffInfoStyles = (): SerializedStyles =>
    css({
        margin: 0,
    });

export const getDropoffTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.dark,
        marginBottom: theme.spacing(1.5),
        fontSize: theme.toRem(theme.typography.fontSize.XXS),
        fontWeight: theme.typography.fontWeight.bold,
        textTransform: 'uppercase',
    });

export const getCollectionInfoStyles = (): SerializedStyles =>
    css({
        margin: 0,
    });

// TODO: Change Styles to GIGER when migrate Warning to React Component
export const getAsideParcelStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
        marginTop: theme.spacing(2.5),
        border: `1px solid ${theme.palette.gray.light}`,
        borderBottom: `2px solid ${theme.palette.info}`,
        borderRadius: '2px',

        '::before': {
            display: 'block',
            width: '15px',
            height: '15px',
            marginRight: theme.spacing(1.5),
            color: theme.palette.primary.main,
            backgroundImage: `url('${config.cdn.url}/pro/statics/icons/parcel--blue.svg')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            content: '""',
        },
    });

export const getLaPosteCollectionTextStyles = (): SerializedStyles => css({ margin: '0' });
