import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getResetAddressStyles = (): SerializedStyles =>
    css({
        fontStyle: 'normal',
    });

export const getShipmentDetailsAddressTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        textTransform: 'uppercase',
        marginBottom: theme.spacing(),
    });

export const getShipmentDetailsAddressLineStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
    });
