import { Shipment, IShipment, SupportTicketAmount } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import * as Yup from 'yup';
import {
    EMPTY_FILE_UPLOAD_ID,
    getUploadAttachmentValidationSchema,
    SupportCenterAttachment,
} from '@SupportCenter/components/UploadAttachment/UploadAttachmentData';

export type SupportTicketClaimFieldsData = {
    amount: number | '';
    proofOfValue: SupportCenterAttachment | undefined;
    productDescription: string;
};

export function useSupportTicketClaimFieldsSchema(): Yup.ObjectSchema<SupportTicketClaimFieldsData> {
    const { t } = useTranslation();
    const getRequiredErrorMsg = (fieldKey: string) => t('form.error.required', { field: t(fieldKey) });

    return Yup.object()
        .shape<SupportTicketClaimFieldsData>({
            amount: Yup.number()
                .typeError(t('support-center.claim-fields.claim-amount-not-number-error'))
                .positive(t('support-center.claim-fields.claim-amount-not-number-error'))
                .required(getRequiredErrorMsg('support-center.claim-fields.claim-amount-label')),
            proofOfValue: getUploadAttachmentValidationSchema({
                required: getRequiredErrorMsg('support-center.claim-fields.proof-of-value-label'),
            }),
            productDescription: Yup.string().required(
                getRequiredErrorMsg('support-center.claim-fields.product-description-label'),
            ),
        })
        .defined();
}

export const initialSupportTicketClaimCommonData: SupportTicketClaimFieldsData = {
    amount: '',
    proofOfValue: undefined,
    productDescription: '',
};

export function parseSupportTicketClaimCommonData(
    { amount: amountValue, proofOfValue, productDescription }: SupportTicketClaimFieldsData,
    shipment: Shipment<IShipment>,
): { amount: SupportTicketAmount; proofOfValue: string; productDescription: string } {
    return {
        proofOfValue: proofOfValue?.uploadId || EMPTY_FILE_UPLOAD_ID,
        productDescription,
        amount: {
            currency: shipment.currency,
            value: Number(amountValue),
        },
    };
}
