import { FC } from 'react';

import { IGenericPanelContentProps } from '@Integrations/components/IntegrationPanel/types';

import { OnboardingPartnerIntegrationForm } from './OnboardingPartnerIntegrationForm';

export const OnboardingPartnerIntegrationPanel: FC<IGenericPanelContentProps> = (
    props: IGenericPanelContentProps,
): JSX.Element => {
    const { onClose, integration, onCancel } = props;

    return <OnboardingPartnerIntegrationForm onClose={onClose} integration={integration} onCancel={onCancel} />;
};
