import { useState } from 'react';
import { useTranslation, Trans } from '@packlink/translation-provider';

import { UserPolicies } from '@packlink/packlink-sdk';
import {
    InlineNotification,
    NotificationType,
    Button,
    ButtonColor,
    ButtonVariant,
    Spinner,
    useToast,
} from '@shipengine/giger';

import { useUserPolicies } from '@User/hooks/useUserPolicies';
import { BrowserContext } from '@utils/BrowserContext';

import {
    getInlineNotificationStyles,
    getPrivacyWithdrawalModalContentStyles,
    getPrivacyWithdrawalModalSpinnerStyles,
} from './PrivacyWithdrawalModalStyles';

interface IPrivacyWithdrawalModalLastWarningProps {
    closeModalHandler: () => void;
}

export function PrivacyWithdrawalModalLastWarning({
    closeModalHandler,
}: IPrivacyWithdrawalModalLastWarningProps): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const toast = useToast(t);

    const [isLoading, setIsLoading] = useState(false);
    const { updateUserPolicies } = useUserPolicies();

    const closeAccountHandler = () => {
        setIsLoading(true);
        const policies = UserPolicies.deserialize({
            dataProcessing: false,
            marketingEmails: false,
        });

        updateUserPolicies(policies, {
            onSuccess: () => {
                window.location.assign(BrowserContext.getLogoutPath(config, locale));
            },
            onError: () => {
                setIsLoading(false);
                toast.error({ message: t('privacy-settings.save.fail') });
            },
        });
    };

    return (
        <section css={getPrivacyWithdrawalModalContentStyles}>
            <InlineNotification
                css={getInlineNotificationStyles}
                type={NotificationType.ERROR}
                title={t('privacy-settings.close-account.last-warning-title')}
            >
                <Trans i18nKey="privacy-settings.close-account.last-warning" components={[<br key="br" />]} />
            </InlineNotification>

            {isLoading && <Spinner css={getPrivacyWithdrawalModalSpinnerStyles} />}

            {!isLoading && (
                <>
                    <Button isLoading={isLoading} color={ButtonColor.PRIMARY} onClick={closeModalHandler}>
                        {t('privacy-settings.close-account.go-back')}
                    </Button>

                    <Button
                        isLoading={isLoading}
                        variant={ButtonVariant.TEXT}
                        color={ButtonColor.ERROR}
                        onClick={closeAccountHandler}
                    >
                        {t('privacy-settings.close-account.confirm')}
                    </Button>
                </>
            )}
        </section>
    );
}
