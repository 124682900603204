import { Trans, useTranslation } from '@packlink/translation-provider';
import { DateTimeDetails } from './DateTimeDetails';
import {
    getAsideDropoffInfoStyles,
    getAsideParcelStyles,
    getCollectionInfoStyles,
    getDropoffTitleStyles,
    getLaPosteCollectionTextStyles,
    getOriginDetailsStyles,
} from './OriginDetailsStyles';
import { Address, IAddress, ProShipment } from '@packlink/packlink-sdk';
import { WithCommonProps } from '@shipengine/giger';

type OriginDetailsProps = WithCommonProps<{
    shipment: ProShipment;
}>;

export function OriginDetails({ shipment, ...props }: OriginDetailsProps): JSX.Element {
    const { t } = useTranslation();

    const hasDropoffOrigin = shipment.data.service?.dropOff?.origin;
    const hasLaPosteCollectionInfo =
        shipment.data.service?.carrierName?.toLowerCase() === 'colissimo' && !hasDropoffOrigin;
    const address = shipment.data.from as Address<IAddress>;

    return (
        <section css={getOriginDetailsStyles} {...props}>
            <DateTimeDetails shipment={shipment} />
            {hasDropoffOrigin && !shipment.data.service?.isOwnContract && (
                <aside css={getAsideDropoffInfoStyles}>
                    <p css={getDropoffTitleStyles}>{t('post-sale.origin-details.dropoff')}</p>
                    <p css={getCollectionInfoStyles}>
                        <Trans
                            i18nKey="post-sale.origin-details.dropoff-collection-info"
                            values={{ address: `${address.zipCode} - ${address.city}` }}
                        />
                    </p>
                </aside>
            )}
            {hasLaPosteCollectionInfo && (
                <aside css={getAsideParcelStyles}>
                    <p css={getLaPosteCollectionTextStyles}>
                        {t('post-sale.origin-details.colissimo-collection-info')}
                    </p>
                </aside>
            )}
        </section>
    );
}
