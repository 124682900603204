import { ShipmentPanelType } from '@types';
import Packlink from '@sdk';
import { addSelectedShipments, handleShipments } from '@store/actions/bulk';
import { useDispatch, useSelector } from 'react-redux';
import { getShipments, getShipmentsToPay } from '@store/selectors/bulk';
import { getCurrentInbox } from '@store/selectors/inbox';
import { BulkFlows } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelBulk/typings';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { AppDispatch } from '@store';
import { useCallback, useEffect } from 'react';
import { Inbox, ProShipment } from '@packlink/packlink-sdk';
import { ContentState } from '@components/ShipmentList/ShipmentListContentReducer';

export function useSelectedForPayment(contentState: ContentState) {
    const dispatch = useDispatch<AppDispatch>();
    const shipments = useSelector(getShipments);
    const shipmentsToPay = useSelector(getShipmentsToPay);
    const currentInbox = useSelector(getCurrentInbox);
    const { openPanel } = useShipmentPanel();

    const showPaymentPanel = useCallback(() => {
        dispatch(addSelectedShipments(shipmentsToPay));
        openPanel(ShipmentPanelType.BULK, undefined, { flow: BulkFlows.PAYMENT });
    }, [dispatch, openPanel, shipmentsToPay]);

    useEffect(() => {
        if (
            shipments.length &&
            shipmentsToPay.length &&
            currentInbox === Inbox.READY_TO_PURCHASE &&
            contentState === ContentState.SHIPMENTS
        ) {
            // This is temporary, when this feature is available as a bulk action,
            // this next line can be removed and the 'find' below will have to change.
            const selectedShipmentToPayRef = shipmentsToPay[0];
            const shipmentFound = shipments.find(
                (shipment: ProShipment) => shipment.data.packlinkReference === selectedShipmentToPayRef,
            );
            if (shipmentFound) {
                showPaymentPanel();
            } else {
                Packlink.pro.shipments.getShipment(selectedShipmentToPayRef).then((response: ProShipment) => {
                    dispatch(handleShipments([response], false)).then(showPaymentPanel);
                });
            }
        }
    }, [contentState, currentInbox, dispatch, shipmentsToPay, shipments, showPaymentPanel]);
}
