import { InlineNotification, NotificationType, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

type ShipmentCancelDeadlineNotificationProps = WithCommonProps<unknown>;

export function ShipmentCancelDeadlineNotification(props: ShipmentCancelDeadlineNotificationProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <InlineNotification
            type={NotificationType.ERROR}
            title={t('support-center.cancel-form.shipment-cancel-error-title')}
            {...props}
        >
            {t('support-center.cancel-form.deadline-shipment-error')}
        </InlineNotification>
    );
}
