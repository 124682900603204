import { Inbox } from '@packlink/packlink-sdk';
import { IconNames } from '@shipengine/giger-theme';

export const inboxIcons = {
    [Inbox.ALL]: IconNames.BUFFER,
    [Inbox.PENDING]: IconNames.FILE_LIST,
    [Inbox.READY_TO_PURCHASE]: IconNames.FILE_LIST,
    [Inbox.DRAFT]: IconNames.FILE_LIST,
    [Inbox.PROCESSING]: IconNames.HOURGLASS,
    [Inbox.READY_FOR_SHIPPING]: IconNames.CARDBOARD_BOX,
    [Inbox.TRACKING]: IconNames.TRACKING,
    [Inbox.IN_TRANSIT]: IconNames.FILE_LIST,
    [Inbox.OUT_FOR_DELIVERY]: IconNames.FILE_LIST,
    [Inbox.DELIVERED]: IconNames.HOUSE,
    [Inbox.INCIDENT]: IconNames.FILE_LIST,
    [Inbox.RETURNED_TO_SENDER]: IconNames.FILE_LIST,
    [Inbox.ARCHIVED]: IconNames.INBOX,
    [Inbox.CANCELED]: IconNames.CANCEL,
};
