import { TenantName } from '@packlink/packlink-sdk';
import { TenantUtils } from './tenant';

export const getAsset = (staticName: string): string => {
    return `${config.cdn.url}/pro/statics/${staticName}`;
};

// TODO: https://packlink.atlassian.net/browse/IDE-229 Load from brand
export const getMapMarker = (): string =>
    TenantUtils.getTenantName() === TenantName.DPDBBEUN
        ? `${config.cdn.url}/checkout/statics/tenants/dpd/icons/marker.svg`
        : `${config.cdn.url}/pro/statics/icons/marker.svg`;
