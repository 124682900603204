import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getPlanFeaturesStyles = (threeBoldChildren?: boolean): SerializedStyles =>
    css({
        listStyleType: 'none',

        ...(threeBoldChildren && {
            '& li:nth-of-type(-n+4)': {
                fontWeight: 'bold',
            },
        }),
    });

export const getFeatureStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    });

export const getIconStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.success.main,
        marginRight: theme.spacing(),
        marginTop: theme.spacing(0.5),
    });
