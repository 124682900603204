import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Trans } from '@packlink/translation-provider';
import { Checkbox, CheckboxSize, FieldMessage, FieldMessageType, Next } from '@shipengine/giger';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { getUrlTermsAndConditions } from '@store/selectors/checkout';

type TermsAgreementCheckboxProps = {
    checked?: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
};
export const TermsAgreementCheckbox = ({ checked, handleChange, error }: TermsAgreementCheckboxProps): JSX.Element => {
    const getHelpCenterPage = useHelpCenter();
    const urlTermsAndConditions = useSelector(getUrlTermsAndConditions);

    const links = useMemo(() => {
        const currentLinks = [
            <Next.Link
                key="link1"
                target="_blank"
                href={getHelpCenterPage(HELP_CENTER_KEYS.carriersTermsAndConditions)}
            />,
        ];

        if (urlTermsAndConditions) {
            currentLinks.push(<Next.Link key="link2" target="_blank" href={urlTermsAndConditions} />);
        }
        return currentLinks;
    }, [urlTermsAndConditions, getHelpCenterPage]);

    return (
        <>
            <Checkbox
                checked={checked}
                onChange={handleChange}
                name="TermsAndConditions"
                data-id="terms-and-conditions-checkbox"
                size={CheckboxSize.MEDIUM}
                label={
                    <Trans
                        i18nKey={urlTermsAndConditions ? 'form.label.two-carriers-terms' : 'form.label.carriers-terms'}
                        components={links}
                    />
                }
            />
            {error && <FieldMessage type={FieldMessageType.ERROR}>{error}</FieldMessage>}
        </>
    );
};
