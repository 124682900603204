import { WithCommonProps } from '@shipengine/giger';
import {
    AnySupportTicket,
    CancelSupportTicket,
    DamageSupportTicket,
    LossSupportTicket,
    PickupSupportTicket,
    SupportTicketCommonData,
    SupportTicketType,
} from '@packlink/packlink-sdk';
import { CancelTicketSummaryMessage } from './components/CancelTicketSummaryMessage';
import { DamageTicketSummaryMessage } from './components/DamageTicketSummaryMessage';
import { GeneralTicketSummaryMessage } from './components/GeneralTicketSummaryMessage';
import { LossTicketSummaryMessage } from './components/LossTicketSummaryMessage';
import { PickUpTicketSummaryMessage } from './components/PickUpTicketSummaryMessage';
import { getSupportTicketSummaryMessageDataStyles } from './SupportTicketSummaryMessageDataStyles';

type SupportTicketSummaryMessageDataProps = WithCommonProps<{
    supportTicket: AnySupportTicket;
}>;

export function SupportTicketSummaryMessageData({
    supportTicket,
    ...props
}: SupportTicketSummaryMessageDataProps): JSX.Element | null {
    switch (supportTicket.type) {
        case SupportTicketType.BILLING:
        case SupportTicketType.DELIVERY:
        case SupportTicketType.LABEL:
        case SupportTicketType.OTHER:
            return (
                <GeneralTicketSummaryMessage
                    css={getSupportTicketSummaryMessageDataStyles}
                    data={supportTicket as SupportTicketCommonData}
                    data-id="general-ticket-additional-data"
                    {...props}
                />
            );
        case SupportTicketType.CANCEL:
            return (
                <CancelTicketSummaryMessage
                    css={getSupportTicketSummaryMessageDataStyles}
                    data={supportTicket as CancelSupportTicket}
                    data-id="cancel-ticket-additional-data"
                    {...props}
                />
            );
        case SupportTicketType.DAMAGE:
            return (
                <DamageTicketSummaryMessage
                    css={getSupportTicketSummaryMessageDataStyles}
                    data={supportTicket as DamageSupportTicket}
                    data-id="damage-ticket-additional-data"
                    {...props}
                />
            );
        case SupportTicketType.LOSS:
            return (
                <LossTicketSummaryMessage
                    css={getSupportTicketSummaryMessageDataStyles}
                    data={supportTicket as LossSupportTicket}
                    data-id="loss-ticket-additional-data"
                    {...props}
                />
            );
        case SupportTicketType.PICKUP:
            return (
                <PickUpTicketSummaryMessage
                    css={getSupportTicketSummaryMessageDataStyles}
                    data={supportTicket as PickupSupportTicket}
                    data-id="pickup-ticket-additional-data"
                    {...props}
                />
            );
        case SupportTicketType.UNKNOWN:
        default:
            return null;
    }
}
