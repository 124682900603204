import { useState } from 'react';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { BrandAssetType, BrandLogo, useAssetUrl } from '@packlink/tenant-theme-provider';

import { PrivacyWithdrawalModalLastWarning } from '@User/pages/PrivacySettings/PrivacyWithdrawalModalLastWarning';

import {
    Button,
    ButtonVariant,
    Dialog,
    DialogHeader,
    Grid,
    GridChild,
    InlineNotification,
    Link,
    NotificationType,
    Radio,
    RadioGroup,
    useToast,
} from '@shipengine/giger';

import { UserPolicies } from '@packlink/packlink-sdk';

import { useUserPolicies } from '@User/hooks/useUserPolicies';
import { TenantUtils } from '@utils/tenant';
import { getDialogStyles } from '../../PrivacyWithdrawalModalStyles';
import { getButtonStyles, getDialogHeaderImageStyles, getInlineNotificationStyles } from './PrivacySplashStyles';

export const PrivacySplash = (): JSX.Element => {
    const { t } = useTranslation();
    const toast = useToast(t);
    const getLogo = useAssetUrl(BrandAssetType.LOGO);
    const { userPolicies, updateUserPolicies } = useUserPolicies();

    const [isLoading, setIsLoading] = useState(false);
    const [showLastWarning, setShowLastWarning] = useState(false);
    const [showCloseAccountNotification, setShowCloseAccountNotification] = useState(false);

    const [dataProcessing, setDataProcessing] = useState<boolean | undefined>();
    const [marketingEmails, setMarketingEmails] = useState<boolean | undefined>(userPolicies?.marketingEmails);

    const getHelpCenterPage = useHelpCenter();

    const onDataProcessingChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setDataProcessing(value === 'true');
        setShowCloseAccountNotification(value === 'false');
    };

    const savePoliciesHandler = () => {
        if (!dataProcessing) {
            setShowLastWarning(true);
        } else {
            setIsLoading(true);

            const policies = UserPolicies.deserialize({
                dataProcessing,
                marketingEmails,
                marketingCalls: true,
            });

            updateUserPolicies(policies, {
                onSuccess: () => {
                    document.location.assign(`${TenantUtils.getURLPrefix()}/shipments/all`);
                },
                onError: () => {
                    setIsLoading(false);
                    toast.error({ message: t('privacy-settings.save.fail') });
                },
            });
        }
    };

    const closeLastWarningMessageHandler = () => {
        setDataProcessing(true);
        setShowLastWarning(false);
        setShowCloseAccountNotification(false);
    };

    return (
        <Dialog css={getDialogStyles} isOpen={true}>
            <DialogHeader>
                <img css={getDialogHeaderImageStyles} src={getLogo(BrandLogo.LOGO)} alt={t('app.helmet.title')} />
            </DialogHeader>

            {/* Last warning message it's the same as the one for PrivacySettings */}
            {showLastWarning && (
                <PrivacyWithdrawalModalLastWarning closeModalHandler={closeLastWarningMessageHandler} />
            )}

            {!showLastWarning && (
                <Grid>
                    <GridChild colSpan={12}>
                        <h5>{t('privacy-splash.modal.title')}</h5>
                    </GridChild>

                    <GridChild colSpan={12}>
                        <p>
                            <Trans
                                i18nKey="privacy-settings.description.data-processing"
                                components={[
                                    <Link
                                        key="link"
                                        target="_blank"
                                        href={getHelpCenterPage(HELP_CENTER_KEYS.privacyPolicy)}
                                    />,
                                ]}
                            />
                        </p>

                        <RadioGroup
                            name="dataProcessing"
                            value={dataProcessing?.toString()}
                            disabled={isLoading}
                            onChange={onDataProcessingChange}
                        >
                            <Radio value="true">{t('privacy-settings.input-value.yes')}</Radio>
                            <Radio value="false">{t('privacy-settings.input-value.no')}</Radio>
                        </RadioGroup>

                        {showCloseAccountNotification && (
                            <InlineNotification
                                css={getInlineNotificationStyles}
                                type={NotificationType.ERROR}
                                title={t('privacy-settings.close-account.first-warning-title')}
                            >
                                <Trans
                                    i18nKey="privacy-settings.close-account.first-warning"
                                    components={[<br key="br" />]}
                                />
                            </InlineNotification>
                        )}
                    </GridChild>

                    <GridChild colSpan={12}>
                        <p>{t('privacy-settings.description.marketing-emails')}</p>

                        <RadioGroup
                            value={marketingEmails?.toString()}
                            name="marketingEmails"
                            disabled={isLoading}
                            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
                                setMarketingEmails(value === 'true')
                            }
                        >
                            <Radio value="true">{t('privacy-settings.input-value.yes')}</Radio>
                            <Radio value="false">{t('privacy-settings.input-value.no')}</Radio>
                        </RadioGroup>

                        <Button
                            css={getButtonStyles}
                            isLoading={isLoading}
                            onClick={savePoliciesHandler}
                            disabled={marketingEmails === undefined || !dataProcessing === undefined}
                            variant={ButtonVariant.FILLED}
                            isFullWidth={true}
                        >
                            {t('privacy-settings.form.accept')}
                        </Button>
                    </GridChild>
                </Grid>
            )}
        </Dialog>
    );
};
