import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { ApiClientError, UserPolicies } from '@packlink/packlink-sdk';
import Packlink from '@sdk';
import { AppDispatch } from '@store';
import { getUserId, getUserPolicies } from '@store/selectors/user';
import { setUser } from '@store/actions/user';
import { logSdkError } from '@utils/logger';

export function useUserPolicies() {
    const dispatch = useDispatch<AppDispatch>();
    const userId = useSelector(getUserId);
    const userPolicies = useSelector(getUserPolicies);

    const { mutate: updateUserPolicies, isLoading: isUpdateUserPoliciesLoading } = useMutation({
        mutationFn: (policies: UserPolicies) =>
            Packlink.v1.users.policies.update(userId, policies, document.location.href),
        onSuccess: async () => {
            await Packlink.v1.users
                .get()
                .then((user) => dispatch(setUser(user)))
                .catch(logSdkError);
        },
        onError: (error: ApiClientError) => {
            logSdkError(error);
        },
    });

    return { updateUserPolicies, isUpdateUserPoliciesLoading, userPolicies };
}
