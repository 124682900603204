import { useParams } from 'react-router';
import { WithCommonProps } from '@shipengine/giger';
import { AnySupportTicket, hasShipmentReferenceInSupportTicket } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { useIsMobile } from '@SupportCenter/utils/useIsMobile';
import { SupportCenterHeader } from '../../components/SupportCenterHeader/SupportCenterHeader';
import { getTicketDetailsRoute, RouteData } from '../routes';
import { TicketChat } from './components/TicketChat/TicketChat';
import { TicketData } from './components/TicketData/TicketData';
import { useTicketDetailsData, useTicketDetailsDataHandlers } from './TicketDetailsData';
import { getTicketDetailsStyles, getTicketDetailsWrapperStyles } from './TicketDetailsStyles';

type TicketDetailsProps = WithCommonProps<unknown>;

export function TicketDetails({ ...props }: TicketDetailsProps): JSX.Element {
    const { id = '' } = useParams();
    const { data, isLoading } = useTicketDetailsData(id);
    const { addTicket } = useTicketDetailsDataHandlers(id);
    const activeRoute = useTicketDetailsActiveRoute(id, data?.supportTicket);
    const isMobile = useIsMobile();

    return (
        <main css={getTicketDetailsWrapperStyles} {...props}>
            <SupportCenterHeader activeRoutes={[activeRoute]} showFrequentlyAskedQuestionsButton={!isMobile} />
            <section css={getTicketDetailsStyles} data-id="support-center-ticket-details">
                <TicketChat
                    supportTicket={data?.supportTicket}
                    messages={data?.messages}
                    onNewMessage={addTicket}
                    isLoading={isLoading}
                />
                <TicketData
                    data-id="support-center-ticket-details-data"
                    supportTicket={data?.supportTicket}
                    shipment={data?.shipment}
                    isLoading={isLoading}
                />
            </section>
        </main>
    );
}

function useTicketDetailsActiveRoute(id: string, supportTicket?: AnySupportTicket): RouteData {
    const { t } = useTranslation();
    const path = getTicketDetailsRoute(id);
    if (!supportTicket) {
        return {
            path,
            name: t('support-center.routes.ticket-details-generic'),
        };
    }

    if (!hasShipmentReferenceInSupportTicket(supportTicket)) {
        return {
            path,
            name: t(`support-center.ticket-types.${supportTicket.type}`),
        };
    }

    return {
        path,
        name: t('support-center.routes.ticket-details', {
            type: t(`support-center.ticket-types.${supportTicket.type}`),
            shipmentReference: supportTicket.shipmentReference,
        }),
    };
}
