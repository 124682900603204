import { Outlet } from 'react-router';
import { BrandAssetType, BrandLogo, useAssetUrl } from '@packlink/tenant-theme-provider';
import { useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';
import {
    getHeadingStyles,
    getLogoWrapperStyles,
    getOnboardingLayoutStyles,
    getOnboardingLogoStyles,
    getOnboardingPageContainerStyles,
} from './OnboardingLayoutStyles';

export function OnboardingLayout(): JSX.Element {
    const { t } = useTranslation();
    const getLogo = useAssetUrl(BrandAssetType.LOGO);

    return (
        <main css={getOnboardingLayoutStyles}>
            <section css={getLogoWrapperStyles}>
                <img
                    alt="logo"
                    css={getOnboardingLogoStyles}
                    src={getLogo(BrandLogo.LOGO)}
                    title={t('app.helmet.title')}
                />
            </section>
            <div css={getHeadingStyles}>
                <Typography variant="heading2" component="h2">
                    {t('onboarding.layout.title')}
                </Typography>
            </div>
            <div css={getOnboardingPageContainerStyles}>
                <Outlet />
            </div>
        </main>
    );
}
