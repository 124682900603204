import { Dispatch, useCallback, useMemo, useState } from 'react';
import { AmplitudeTypeOfServices } from '@packlink/metrics';
import { Service, ServiceAllParams, ServiceDetails, IShipment } from '@packlink/packlink-sdk';
import { IDropOffPoint } from '@packlink/cartography';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { ServicesStep } from '../types';
import { useAmplitude } from '@hooks/useAmplitude';
import { useThirdPartyPreferencesBehavior } from '@common/hooks/useThirdPartyPreferencesBehavior';

export function useServiceList(
    shipmentDataJSON: IShipment,
    setStep: Dispatch<React.SetStateAction<ServicesStep>>,
    setSelectedDropoffId: Dispatch<React.SetStateAction<string | undefined>>,
) {
    const { from, to, source, parcels, thirdPartyPreferences } = shipmentDataJSON;
    const [selectedService, setSelectedService] = useState<Service>();
    const [dropOffPoints, setDropOffPoints] = useState<IDropOffPoint[]>();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();
    const { thirdPartyPreferencesEnabled } = useThirdPartyPreferencesBehavior(source);

    const searchParams = useMemo((): ServiceAllParams => {
        return {
            from: {
                country: from?.alpha2Code,
                zip: from?.zipCode,
            },
            packages: parcels,
            sortBy: 'totalPrice',
            source,
            to: {
                country: to?.alpha2Code,
                zip: to?.zipCode,
            },
            ...(thirdPartyPreferencesEnabled && {
                shipping_service_id_selected: thirdPartyPreferences?.serviceId,
                shipping_pick_up_point_id_selected: thirdPartyPreferences?.dropoffPointId,
                carrier: thirdPartyPreferences?.carrier,
            }),
        } as ServiceAllParams;
    }, [
        from?.alpha2Code,
        from?.zipCode,
        parcels,
        source,
        thirdPartyPreferences?.carrier,
        thirdPartyPreferences?.dropoffPointId,
        thirdPartyPreferences?.serviceId,
        thirdPartyPreferencesEnabled,
        to?.alpha2Code,
        to?.zipCode,
    ]);

    const sendAmplitudeEvent = (event: AmplitudeEvents, service: Service, index: number): void => {
        const originTypeOfService = service.dropOff?.origin
            ? AmplitudeTypeOfServices.DROP_OFF
            : AmplitudeTypeOfServices.COLLECTION;
        const destinationTypeOfService = service.dropOff?.destination
            ? AmplitudeTypeOfServices.PICK_UP
            : AmplitudeTypeOfServices.DELIVERY;

        // TODO: add type os service
        sendAmplitudeSidebarClickEvent(event, {
            [AmplitudeProperties.PRICE]: service.price?.basePrice,
            [AmplitudeProperties.POSITION]: index + 1,
            [AmplitudeProperties.SERVICE]: service.name,
            [AmplitudeProperties.CARRIER]: service.carrierName,
            [AmplitudeProperties.TYPE_OF_SERVICE]: [originTypeOfService, destinationTypeOfService],
            [AmplitudeProperties.OWN_CONTRACT]: service.isOwnContract,
        });
    };

    const setInitialService = useCallback(
        (services: Service[]) => {
            if (shipmentDataJSON.service.id) {
                const shipmentService = services.find((service) => service.id === shipmentDataJSON.service.id);
                setSelectedService(shipmentService);
            }
        },
        [shipmentDataJSON.service.id],
    );

    const selectService = (service: Service, index: number): void => {
        setSelectedService(service);
        setSelectedDropoffId(undefined);
        sendAmplitudeEvent(AmplitudeEvents.SELECT_SERVICE, service, index);
    };

    const seeDropOffPoints = (service: Service, points: IDropOffPoint[], index: number): void => {
        setDropOffPoints(points);
        setStep(ServicesStep.DROPOFFS);

        sendAmplitudeEvent(AmplitudeEvents.SEE_DROPOFF_MAP, service, index);
    };

    const seeServiceDetails = (service: Service, _details: ServiceDetails, index: number): void => {
        sendAmplitudeEvent(AmplitudeEvents.SERVICE_DETAILS, service, index);
    };

    return {
        searchParams,
        selectedService,
        dropOffPoints,
        setDropOffPoints,
        setInitialService,
        selectService,
        seeDropOffPoints,
        seeServiceDetails,
    };
}
