import { useTranslation } from '@packlink/translation-provider';
import { CancelShipment } from './components/CancelShipment/CancelShipment';
import { ContactSupport } from './components/ContactSupport/ContactSupport';
import { CreateNewLabel } from './components/CreateNewLabel/CreateNewLabel';
import { DownloadCustoms } from './components/DownloadCustoms/DownloadCustoms';
import { PrintDownloadLabel } from './components/PrintDownloadLabel/PrintDownloadLabel';
import { ReturnToTableView } from './components/ReturnToTableView/ReturnToTableView';
import { SendLabelByEmail } from './components/SendLabelByEmail/SendLabelByEmail';
import { ShipmentDetailsSidebarSection } from './components/ShipmentDetailsSidebarSection/ShipmentDetailsSidebarSection';
import { getShipmentDetailsSidebarSection, getShipmentDetailsSidebarStyles } from './ShipmentDetailsSidebarStyles';
import { isShipmentActionAvailable, ShipmentDetailsActions } from '../../ShipmentDetailsData';
import { ProShipment } from '@packlink/packlink-sdk';
import { WithCommonProps } from '@shipengine/giger';
import { useTenantBehavior } from '@packlink/tenant-config-provider';

type ShipmentDetailsSidebarProps = WithCommonProps<{
    shipment: ProShipment;
}>;

export function ShipmentDetailsSidebar({ shipment, ...props }: ShipmentDetailsSidebarProps): JSX.Element {
    const { t } = useTranslation();
    const customsInvoiceId = shipment.data.customs?.customsInvoiceId;
    const isCancelButtonVisible = useIsCancelButtonVisible();
    return (
        <aside css={getShipmentDetailsSidebarStyles} {...props}>
            <ShipmentDetailsSidebarSection css={getShipmentDetailsSidebarSection}>
                <ReturnToTableView data-id="back-to-list-button" />
            </ShipmentDetailsSidebarSection>

            <ShipmentDetailsSidebarSection
                title={t('post-sale.sidebar.prepare-shipment-title')}
                css={getShipmentDetailsSidebarSection}
            >
                {isShipmentActionAvailable(shipment, ShipmentDetailsActions.downloadCustoms) && (
                    <DownloadCustoms customsInvoiceId={customsInvoiceId} data-id="download-customs-papers-button" />
                )}
                {isShipmentActionAvailable(shipment, ShipmentDetailsActions.emailLabel) && (
                    <SendLabelByEmail shipment={shipment} />
                )}
                {isShipmentActionAvailable(shipment, ShipmentDetailsActions.printLabel) && (
                    <PrintDownloadLabel
                        data-id="print-download-label"
                        packlinkReference={shipment.data.packlinkReference}
                        label={shipment.data?.labels && shipment.data?.labels[0]}
                        qrLabel={shipment.data?.printInStore?.url}
                    />
                )}
            </ShipmentDetailsSidebarSection>

            {!shipment.data.isObfuscated && (
                <ShipmentDetailsSidebarSection
                    title={t('post-sale.sidebar.duplicate-section-title')}
                    tooltip={t('post-sale.sidebar.duplicate-section-description')}
                    css={getShipmentDetailsSidebarSection}
                >
                    <CreateNewLabel data-id="duplicate-label-button" shipment={shipment} />
                </ShipmentDetailsSidebarSection>
            )}

            <ShipmentDetailsSidebarSection
                title={t('post-sale.sidebar.other-section-title')}
                css={getShipmentDetailsSidebarSection}
            >
                <ContactSupport packlinkReference={shipment.data.packlinkReference} data-id="customer-support-button" />
                {isCancelButtonVisible &&
                    isShipmentActionAvailable(shipment, ShipmentDetailsActions.cancelShipment) && (
                        <CancelShipment shipment={shipment} />
                    )}
            </ShipmentDetailsSidebarSection>
        </aside>
    );
}

function useIsCancelButtonVisible() {
    const getTenantBehavior = useTenantBehavior();

    return !getTenantBehavior<{ enabled: boolean }>('HideCancelInPostsalePage')?.properties.enabled;
}
