import { useRef } from 'react';
import { FieldProps, Field, Form, Formik, useFormikContext, FormikProps, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { FormField, Typography, FieldMessageType, useToast, Next } from '@shipengine/giger';
import { Input, InputProps } from '@shipengine/formik-giger';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { getFormStyles, getOTPInputStyles, getParagraphStyles } from './OTPVerificationStyles';
import { useOtpVerification } from '@common/hooks/useOtpVerification';
import { useDispatch, useSelector } from 'react-redux';
import { getClientData } from '@store/selectors/client';
import {
    OtpPhoneNumberVerificationResponse,
    OtpVerificationErrorApiResponse,
    OtpVerificationResult,
} from '@packlink/packlink-sdk/dist/lib/domains/otp-verification/Otp';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks';
import { setClient } from '@store/actions/client';
import { AppDispatch } from '@store';

export type OTPVerificationProps = {
    confirmedPhoneNumber: string;
    onBack: VoidFunction;
    onVerified?: VoidFunction;
    onError: (errorCode?: string, remainingTimeInMs?: number) => void;
};

type FormSchema = {
    otp: string[];
};

const OTP_LENGTH = 5;

export function OTPVerification({
    confirmedPhoneNumber,
    onBack,
    onVerified,
    onError,
}: OTPVerificationProps): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const toast = useToast(t);
    const client = useSelector(getClientData);
    const dispatch = useDispatch<AppDispatch>();
    const { sendPhoneNumberMutation, verifyOtpCodeMutation } = useOtpVerification();
    const { sendAmplitudeClickEvent, sendAmplitudeEvent } = useAmplitude();
    const validationSchema = yup.object().shape({
        otp: yup
            .array<number>()
            .min(OTP_LENGTH, t('phone-verification.error.incorrect-otp'))
            .of(yup.number().min(0).max(9).required(t('phone-verification.error.incorrect-otp'))),
    });

    const resendCode = () => {
        const mutationEvents = {
            onSuccess: (response: OtpPhoneNumberVerificationResponse) => {
                if (response.verificationResult === OtpVerificationResult.SENT) {
                    toast.success({
                        message: t('phone-verification.resend.success-message'),
                    });
                } else if (response.verificationResult === OtpVerificationResult.BLOCKED) {
                    toast.alert({
                        title: t('phone-verification.resend.alert-title'),
                        message: t('phone-verification.resend.alert-message', {
                            count: Math.round((response.nextRetryInMs ?? 0) / 1000),
                        }),
                    });
                }
            },
            onError: (error?: { response: { data: { messages: OtpVerificationErrorApiResponse[] } } }) => {
                const errorContent = error?.response?.data?.messages?.[0];
                onError(errorContent?.error_code, errorContent?.remaining_time_in_ms);
            },
        };

        sendAmplitudeClickEvent(AmplitudeEvents.RESEND_CODE);
        sendPhoneNumberMutation(
            {
                phone_number: confirmedPhoneNumber,
                locale,
                identifier: client.id as string,
            },
            mutationEvents,
        );
    };

    const handleCheckOtpCode = (values: FormSchema, { resetForm }: FormikHelpers<FormSchema>) => {
        const mutationEvents = {
            onError: (error?: { response: { data: { messages: OtpVerificationErrorApiResponse[] } } }) => {
                const errorContent = error?.response?.data?.messages?.[0];
                sendAmplitudeEvent(AmplitudeEvents.VERIFICATION_FAILED);
                onError(errorContent?.error_code, errorContent?.remaining_time_in_ms);
                resetForm();
            },
            onSuccess: () => {
                sendAmplitudeEvent(AmplitudeEvents.VERIFICATION_SUCCESS);
                const updatedClient = client.clone();
                updatedClient.phoneNumberVerified = true;

                dispatch(setClient(updatedClient));
                onVerified?.();
            },
        };

        verifyOtpCodeMutation(
            {
                otp_code: values.otp.join(''),
                phone_number: confirmedPhoneNumber,
                identifier: client.id as string,
            },
            mutationEvents,
        );
    };

    return (
        <Formik<FormSchema>
            initialValues={{ otp: [] }}
            validationSchema={validationSchema}
            onSubmit={handleCheckOtpCode}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {({ isSubmitting }: FormikProps<FormSchema>) => (
                <Form css={getFormStyles}>
                    <SidePanelContent>
                        <Typography variant="body1" component="p">
                            <Trans
                                i18nKey="phone-verification.otp-verification.title"
                                values={{ phone: confirmedPhoneNumber }}
                            />
                        </Typography>

                        <OTPInput />

                        <Typography css={getParagraphStyles} variant="body2" component="p">
                            {t('phone-verification.otp-verification.expiration')}
                        </Typography>

                        <Typography variant="body2" component="p">
                            <Trans
                                i18nKey="phone-verification.otp-verification.resend"
                                components={[<Next.Link key="link" onClick={resendCode} />]}
                            />
                        </Typography>
                    </SidePanelContent>

                    <SidePanelFooter>
                        <SidePanelFooterActions
                            actionButtonType="submit"
                            isLoading={isSubmitting}
                            onCancel={onBack}
                            actionText={t('phone-verification.otp-verification.action')}
                            cancelText={t('shipment-panel.actions.back')}
                        />
                    </SidePanelFooter>
                </Form>
            )}
        </Formik>
    );
}

function isValueValid(value?: string) {
    return !isNaN(Number(value?.at(-1))) && value?.trim().length === 1;
}

function OTPInput() {
    const { errors, values, isSubmitting } = useFormikContext<FormSchema>();
    const inputRefs = useRef<Array<HTMLDivElement | null>>([]);

    return (
        <FormField
            message={{
                type: FieldMessageType.ERROR,
                content: typeof errors.otp === 'string' ? errors.otp : errors.otp?.filter(Boolean)[0],
            }}
        >
            <div css={getOTPInputStyles}>
                {new Array(OTP_LENGTH).fill('').map((_value, index) => (
                    <Field key={index} name={`otp[${index}]`}>
                        {({ field, ...rest }: FieldProps) => {
                            const fieldProps: FieldProps['field'] = {
                                ...field,
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (isValueValid(e.target.value.at(-1))) {
                                        inputRefs.current[index + 1]?.focus();
                                        e.target.value = e.target.value.at(-1) as string;
                                        field.onChange(e);
                                    }
                                },
                            };

                            return (
                                <Input
                                    field={fieldProps}
                                    form={rest.form as InputProps['form']}
                                    meta={rest.meta}
                                    disabled={isSubmitting}
                                    inputRef={(ref: HTMLInputElement) => (inputRefs.current[index] = ref)}
                                    // eslint-disable-next-line jsx-a11y/no-autofocus
                                    autoFocus={index === 0}
                                    isInvalid={!!errors.otp}
                                    onFocus={(e) => e.target.select()}
                                    onKeyDown={(e) => {
                                        // React does not trigger onChange when the same value is entered again.
                                        if (e.key === values.otp[index]) {
                                            e.preventDefault();
                                            inputRefs.current[index + 1]?.focus();
                                        }
                                    }}
                                />
                            );
                        }}
                    </Field>
                ))}
            </div>
        </FormField>
    );
}
