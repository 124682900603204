import { Inbox, Inboxes } from '@packlink/packlink-sdk';
import { Lens } from 'monocle-ts';
import { createSelector } from 'reselect';
import { IAppState } from '@store/reducers';
import { IInboxState } from '@store/reducers/inbox';

const getInbox = Lens.fromProp<IAppState>()('inbox').get;
export const currentInbox = Lens.fromProp<IInboxState>()('currentInbox');
export const inboxes = Lens.fromProp<IInboxState>()('inboxes');
const getSeralizedInboxes = createSelector(getInbox, inboxes.get);
export const getCurrentInbox = createSelector(getInbox, currentInbox.get);
export const getInboxes = createSelector(getSeralizedInboxes, Inboxes.deserialize);
export const getIsNewUser = createSelector(getInboxes, (inboxes) => !inboxes?.getAll());
export const getIsCurrentInboxUnderTracking = createSelector(getCurrentInbox, (inbox) =>
    [
        Inbox.TRACKING,
        Inbox.IN_TRANSIT,
        Inbox.OUT_FOR_DELIVERY,
        Inbox.DELIVERED,
        Inbox.INCIDENT,
        Inbox.RETURNED_TO_SENDER,
    ].includes(inbox),
);
