import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentListHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.gray.megaLight,

        [theme.mediaQuery('tablet')]: {
            height: theme.spacing(4),
            backgroundColor: theme.palette.secondary.main,
            borderWidth: 0,
        },
    });

export const getShipmentListHeaderMenuStyles = (theme: Theme) =>
    css({
        display: 'none',
        width: '100%',
        margin: 0,
        padding: 0,
        listStyle: 'none',

        [theme.mediaQuery('tablet')]: {
            display: 'flex',
            marginLeft: theme.spacing(4),
            visibility: 'visible',
            pointerEvents: 'visible',
        },
    });

export const getShipmentListHeaderMenuItemStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        padding: `0 ${theme.spacing(2)}px`,
        color: theme.palette.white,
        fontWeight: theme.typography.fontWeight.semibold,

        ':last-child': {
            flex: '0 0 132px',
        },
    });
