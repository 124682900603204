import { useTranslation } from '@packlink/translation-provider';
import { Currency, IShipmentsByCarrierResponse } from '@packlink/packlink-sdk';
import {
    CroppableContent,
    Table,
    TableBody,
    TableBodyCell,
    TableHeader,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
} from '@shipengine/giger';
import { circleStyles, tableContainerStyles, tableStyles } from './ShipmentsByCarrierChartStyles';

interface IChartTableProps {
    chartData?: IShipmentsByCarrierResponse;
}

export function ChartTable({ chartData }: IChartTableProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <div css={tableContainerStyles}>
            <Table
                data-id="shipments-by-carrier-table"
                header={
                    <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-carriers-tooltip">
                                    {t('analytics.label.carriers')}
                                </CroppableContent>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-shipments-tooltip">
                                    {t('analytics.label.shipments')}
                                </CroppableContent>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-percentage-tooltip">
                                    {t('analytics.label.percentage')}
                                </CroppableContent>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-avg-cost-tooltip">
                                    {t('analytics.label.avg-cost')}
                                </CroppableContent>
                            </TableHeaderCell>
                        </TableHeaderRow>
                    </TableHeader>
                }
                css={tableStyles}
            >
                <TableBody>
                    {chartData?.data.map(({ carrier, shipments, percentage, averageCost }, index: number) => (
                        <TableRow key={index}>
                            <TableBodyCell>
                                <CroppableContent id={`analytics-carrier-${index}-tooltip`}>
                                    <div css={circleStyles(index)} />
                                    {carrier}
                                </CroppableContent>
                            </TableBodyCell>
                            <TableBodyCell>{shipments}</TableBodyCell>
                            <TableBodyCell>{percentage}%</TableBodyCell>
                            <TableBodyCell>{Currency.format(averageCost, chartData.currency)}</TableBodyCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
