import { Typography } from '@shipengine/giger';
import { Currency, PricingTypeType, SubscriptionPlan } from '@packlink/packlink-sdk';
import { SubscriptionPrice } from '../../../components/SubscriptionPrice/SubscriptionPrice';
import { getFullPriceStyles, getPlanPricringStyles } from './PlanPricringStyles';

type PlanPricingProps = Pick<SubscriptionPlan, 'pricingType' | 'rampIntervals' | 'fixedPricing'>;

export function PlanPricing({ pricingType, rampIntervals, fixedPricing }: PlanPricingProps): JSX.Element {
    const isRampPricing = pricingType === PricingTypeType.RAMP;

    const price = isRampPricing ? rampIntervals?.[0].rampPricings[0] : fixedPricing;
    const rampLastIntervalPrice = isRampPricing && rampIntervals?.[rampIntervals.length - 1].rampPricings[0].unitAmount;

    const priceAmount = price?.unitAmount ?? 0;
    const priceCurrency = price?.currency ?? 'EUR';

    return (
        <Typography css={getPlanPricringStyles} variant="heading2" component="p">
            <>
                {rampLastIntervalPrice && (
                    <span data-id="ramp-plan-full-price" css={getFullPriceStyles}>
                        {Currency.format(Number(rampLastIntervalPrice), priceCurrency)}
                    </span>
                )}
                <SubscriptionPrice price={priceAmount} currency={priceCurrency} />
            </>
        </Typography>
    );
}
