import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { IClient, PaymentMethod, PaymentType } from '@packlink/packlink-sdk';
import {
    IAmplitude3Dsecure,
    IAmplitudeLocalPayment,
    PaymentOptions as PhaviourPaymentOptions,
    IPaymentOptionsProps as PhaviourIPaymentOptionsProps,
} from '@packlink/payment-options';
import { getClientData } from '@store/selectors/client';
import { AppDispatch } from '@store';
import { IPaymentCallbacksProps, usePaymentCallbacks } from '@components/PaymentOptions/hooks/usePaymentCallbacks';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { ITenantConfig } from '@types';

export interface onSaveProps {
    type: PaymentType;
    method: PaymentMethod;
    nonce?: string;
    metrics?: IAmplitude3Dsecure | IAmplitudeLocalPayment;
    deviceData?: string;
}

export type SubscriptionPaymentOptionsProps = Pick<IPaymentCallbacksProps, 'section' | 'onError' | 'onDelete'> &
    Pick<PhaviourIPaymentOptionsProps, 'payButtonTexts'> & {
        isDeferred?: boolean;
        isPaymentBlocked?: boolean;
        currency?: string;
        amount?: number;
        voucherPercentage?: number;
        isProcessing: boolean;
        onSave: (props: onSaveProps) => void;
        onStart?: (method: PaymentMethod) => void;
        onCheckForExternalError?: () => void;
        onChangeOption?: (paymentMethod: PaymentMethod) => void;
    };

export const SubscriptionPaymentOptions = ({
    amount,
    currency = 'EUR',
    isProcessing,
    section,
    payButtonTexts,
    onCheckForExternalError,
    onError,
    onDelete,
    onSave,
    onStart,
    onChangeOption,
}: SubscriptionPaymentOptionsProps): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { defaultCurrency } = useTenantConfig<ITenantConfig>();

    const client = useSelector(getClientData);
    const BASE_AMOUNT = 0.01;

    const sendOrder = (
        type: PaymentType,
        method: PaymentMethod,
        nonce?: string,
        metrics?: IAmplitude3Dsecure | IAmplitudeLocalPayment,
        deviceData?: string,
    ): void => {
        onSave({ type, method, nonce, metrics, deviceData });
    };

    const { handleRequestClientNonce, handleDeletePaymentMethod, handlePaymentError, handlePaymentStart, handleOrder } =
        usePaymentCallbacks({ dispatch, sendOrder, onError, onDelete, section });

    const handleStart = (paymentMethod: PaymentMethod) => {
        onStart?.(paymentMethod);
        handlePaymentStart(paymentMethod);
    };

    return (
        <PhaviourPaymentOptions
            currency={currency || defaultCurrency}
            locale={locale}
            allowedPaymentMethods={[PaymentMethod.CREDITCARD3DSECURE, PaymentMethod.PAYPAL]}
            fallbackOptions={{
                // We don't want search params for fallback URL
                url: window.location.origin + window.location.pathname,
                buttonText: t('payment.local-payment.fallback-button', {
                    pageTitle: document.title,
                }),
            }}
            amount={amount || BASE_AMOUNT}
            cdnUrl={config.cdn.baseUrl}
            client={client.toJSON() as IClient}
            isProcessing={isProcessing}
            payButtonTexts={payButtonTexts}
            onCheckForExternalError={onCheckForExternalError}
            onVerified={handleOrder}
            onError={handlePaymentError}
            onStart={handleStart}
            onRequestClientNonce={handleRequestClientNonce}
            onDeletePaymentMethod={handleDeletePaymentMethod}
            onChangeOption={onChangeOption}
        />
    );
};
