import React, { useCallback, useEffect, useState } from 'react';
import { CancelTokenSource } from 'axios';
import { useTranslation } from '@packlink/translation-provider';
import { Dialog, DialogHeader } from '@shipengine/giger';
import { getImportCSVModalStyles } from './ImportCSVModalStyles';
import { ImportCSVModalContent } from './ImportCSVModalContent';
import { InsuranceCSVModalContentMemoized } from '../InsuranceCSV/InsuranceCSVModalContent';

export interface IImportCSVModalProps {
    isOpened: boolean;
    onFileUploaded: () => void;
}

export const ImportCSVModal: React.FunctionComponent<IImportCSVModalProps> = (
    props: IImportCSVModalProps,
): JSX.Element => {
    const { isOpened, onFileUploaded } = props;
    const { t } = useTranslation();
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
    const [titleDialog, setTitleDialog] = useState(t('import-csv.header.title'));

    const [showInsuranceContent, setShowInsuranceContent] = useState(false);
    const [shipmentReferences, setShipmentReferences] = useState<Array<string>>([]);

    const resetModal = useCallback(() => {
        setShowInsuranceContent(false);
        setShipmentReferences([]);
        setCancelTokenSource(undefined);
    }, []);

    const cancelUpload = (): void => {
        cancelTokenSource?.cancel();
        resetModal();
        onFileUploaded();
    };

    useEffect((): (() => void) => {
        return (): void => {
            resetModal();
        };
    }, [resetModal]);

    return (
        <Dialog isOpen={isOpened} css={getImportCSVModalStyles}>
            <DialogHeader
                title={titleDialog}
                showClose
                closeAriaLabel={t('import-csv.header.close')}
                onClose={cancelUpload}
            />
            {showInsuranceContent && (
                <InsuranceCSVModalContentMemoized
                    shipmentReferences={shipmentReferences}
                    setTitleDialog={setTitleDialog}
                    onClose={cancelUpload}
                />
            )}
            {!showInsuranceContent && (
                <ImportCSVModalContent
                    setShipmentReferences={setShipmentReferences}
                    setShowInsuranceContent={setShowInsuranceContent}
                    cancelTokenSource={cancelTokenSource}
                    setCancelTokenSource={setCancelTokenSource}
                />
            )}
        </Dialog>
    );
};
