import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { IPriceInsurance, IShipment, ProShipment, Shipment } from '@packlink/packlink-sdk';
import { getIsPendingSectionInbox, getSelectedShipments } from '@store/selectors/bulk';
import { BulkSelectionInfo } from '@components/SidePanel/BulkSelectionInfo';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { getContentValidation } from '@components/ContentAutocomplete/ContentAutocomplete';
import { PriceUtils } from '@utils/PriceUtils';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { ShipmentPanelFooter } from '../../ShipmentPanelFooter';
import { Form, Formik, FormikProps } from 'formik';
import { IContentForm } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelContent/ShipmentPanelContent';
import * as yup from 'yup';
import {
    getContentValueValidation,
    ShipmentPanelContentInfo,
} from '@components/ShipmentList/ShipmentPanel/ShipmentPanelContent/ShipmentPanelContentInfo';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useShipmentsUpdate } from '../../hooks/useShipmentsUpdate';
import { shipmentPanelFormStyles } from '../../ShipmentPanelStyles';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IShipmentPanelBulkContentProps {
    onGoBack: () => void;
}

export const ShipmentPanelBulkContent = (props: IShipmentPanelBulkContentProps): JSX.Element => {
    const { onGoBack } = props;
    const selectedShipments = useSelector(getSelectedShipments);
    const isCurrentInboxPending = useSelector(getIsPendingSectionInbox);
    const firstShipment = selectedShipments[0]?.data;
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();

    const { mutateShipments, isWaitingUpdate, messageProps } = useShipmentsUpdate(isCurrentInboxPending);

    const updateShipments = useCallback(
        async (values: IContentForm): Promise<void> => {
            const { description, value } = values;

            const shipments = await Promise.all(
                selectedShipments.map(async (shipment: ProShipment): Promise<Shipment> => {
                    let insurancePrice: IPriceInsurance | undefined;
                    const { data: shipmentDataJSON } = ShipmentUtils.proShipmentToJSON(shipment);
                    const hasChangedValue = shipmentDataJSON.content.value !== value;
                    const shouldUpdateInsurance =
                        hasChangedValue && shipmentDataJSON.upsales.insurance.available && shipmentDataJSON.service.id;

                    if (shouldUpdateInsurance) {
                        const priceDetails = await PriceUtils.getPriceDetails(shipmentDataJSON, value);
                        insurancePrice = priceDetails?.shipmentsPriceDetails[0].availableProducts?.insurance;
                    }

                    const newShipmentData: IShipment = {
                        ...shipmentDataJSON,
                        content: {
                            ...shipmentDataJSON.content,
                            description: description as string,
                            value: value as number,
                        },
                        upsales: {
                            ...shipmentDataJSON.upsales,
                            insurance: {
                                ...shipmentDataJSON.upsales.insurance,
                                amount: shouldUpdateInsurance
                                    ? insurancePrice?.insured
                                    : shipmentDataJSON.upsales.insurance.amount || value,
                            },
                        },
                    };
                    return Shipment.deserialize(newShipmentData);
                }),
            );

            mutateShipments(shipments, {
                onSuccess: ({ shipmentsUpdated }) => {
                    if (shipmentsUpdated.length > 0) {
                        sendAmplitudeEvent(AmplitudeEvents.SIDEBAR_EDIT_CONTENT_SUCCESS, {
                            [AmplitudeProperties.NUMBER_OF_SHIPMENTS_EDITED]: shipmentsUpdated.length,
                        });
                    }
                },
            });
        },
        [selectedShipments, mutateShipments, sendAmplitudeEvent],
    );

    const initialFormValues = useMemo(
        (): IContentForm => ({
            description: firstShipment?.content?.description,
            value: firstShipment?.content?.value,
        }),
        [firstShipment],
    );

    const validationSchema = yup.object<IContentForm>().shape({
        description: getContentValidation(t),
        value: getContentValueValidation(t),
    });

    const renderForm = (formProps: FormikProps<IContentForm>): React.ReactNode => {
        const { isValid } = formProps;
        const hasInsurance = selectedShipments.some((s) => s.data.upsales?.insurance?.available);

        return (
            <>
                {isWaitingUpdate ? (
                    <Form css={shipmentPanelFormStyles()}>
                        <BulkSelectionInfo
                            count={selectedShipments.length}
                            actionsTitle="bulk-panel.actions.title"
                            actionsSubtitle="bulk-panel.content.subtitle"
                            showBackground
                        />

                        <SidePanelContent>
                            <ShipmentPanelContentInfo currency={firstShipment?.currency} hasInsurance={hasInsurance} />
                        </SidePanelContent>

                        <ShipmentPanelFooter
                            onExit={onGoBack}
                            cancelButtonLabel={t('shipment-panel.actions.back')}
                            isSaveDisabled={!isValid}
                        />
                    </Form>
                ) : (
                    <SidePanelContent>
                        <SidePanelMessage {...messageProps} />
                    </SidePanelContent>
                )}
            </>
        );
    };
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={updateShipments}
        >
            {(formProps: FormikProps<IContentForm>): React.ReactNode => renderForm(formProps)}
        </Formik>
    );
};
