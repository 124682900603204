import { FieldMessageType } from '@shipengine/giger';
import { FieldProps, FormikState, getIn } from 'formik';
import { UploadMultipleAttachmentProps, UploadMultipleAttachment } from './UploadMultipleAttachment';
import { SupportCenterAttachment } from './UploadAttachmentData';

type UploadMultipleAttachmentFieldProps = FieldProps<SupportCenterAttachment[]> &
    Omit<UploadMultipleAttachmentProps, 'onChange' | 'onError' | 'disabled' | 'value'>;

export function UploadMultipleAttachmentField({
    field,
    form,
    message,
    ...props
}: UploadMultipleAttachmentFieldProps): JSX.Element {
    const { errors, touched, isSubmitting, setFormikState } = form;
    const { value = [], name } = field;
    const error = getIn(errors, name);
    const isTouched = getIn(touched, name);
    const isInvalid = error && isTouched;

    // Why this strange handlers you may ask?
    // Using setField can lead to race conditions
    // https://github.com/jaredpalmer/formik/issues/834
    const handleChange = (fileData: SupportCenterAttachment[]) =>
        setFormikState((prevState: FormikState<Record<string, unknown>>) => ({
            ...prevState,
            values: { ...prevState.values, [name]: fileData },
        }));

    const handleError = (message: string) => {
        setFormikState((prevState: FormikState<unknown>) => ({
            ...prevState,
            errors: { ...prevState.errors, [name]: message },
            touched: { ...prevState.touched, [name]: true },
        }));
    };

    return (
        <UploadMultipleAttachment
            disabled={isSubmitting}
            message={isInvalid ? { type: FieldMessageType.ERROR, content: error } : message}
            onChange={handleChange}
            onError={handleError}
            value={value}
            {...props}
        />
    );
}
