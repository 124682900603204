import { WithChildrenCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { PrintingFormat } from '@packlink/packlink-sdk';

import { getImageStyles, getLabelStyles, getOptionStyles, getContainerStyles } from './PrintingOptionStyles';

export enum PrintingPreferencesSize {
    SMALL = 'small',
    LARGE = 'large',
}

export type PrintingOptionProps = WithChildrenCommonProps<{
    /** Image to represent the option */
    image: string;
    /** Flag to initially select, the selected status its handled internally */
    selected?: boolean;
    /** Value for this option */
    value: PrintingFormat;
    /** Size for the option */
    size?: PrintingPreferencesSize;
    /** onChange handler */
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: PrintingFormat) => void;
}>;

export const PrintingOption = ({
    value,
    image,
    children,
    selected,
    size = PrintingPreferencesSize.LARGE,
    onChange,
}: PrintingOptionProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <label css={getContainerStyles(selected)}>
            <input
                name="format"
                type="radio"
                value={value}
                checked={selected}
                css={getOptionStyles}
                onChange={(e) => onChange?.(e, value)}
            />
            <img src={image} css={getImageStyles(size)} alt={t(`printing-preferences.option.aria-label-${value}`)} />
            <p css={getLabelStyles(selected, size)}>{children}</p>
        </label>
    );
};
