import React from 'react';
import { useTranslation } from '@packlink/translation-provider';
import classNames from 'classnames';
import { IInvoice } from '@packlink/packlink-sdk';
import { IconNames } from '@shipengine/giger-theme';
import {
    Button,
    ButtonColor,
    ButtonVariant,
    Checkbox,
    Icon,
    IconSize,
    TableBodyCell,
    TableRow,
} from '@shipengine/giger';
import { useDateFormat } from '@hooks/useDateFormat';
import { useFormatCurrency } from '@hooks/useFormatCurrency';
import { getInvoiceTimeStyles } from '@Invoicing/components/InvoiceTable/InvoiceTableStyles';

interface IInvoiceTableRowProps {
    invoice: IInvoice;
    downloadInvoice: (ref: string) => Promise<void>;
    handleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
}

export const InvoiceTableRow = ({
    invoice,
    downloadInvoice,
    handleCheck,
    checked,
}: IInvoiceTableRowProps): JSX.Element => {
    const { t } = useTranslation();
    const [date, time] = useDateFormat(invoice.createdAt, 'DD/MM/YYYY HH:mm:ss').split(' ');

    const rowClasses = classNames({
        'invoice-table-row': true,
        'invoice-table-row--is-selected': checked,
    });

    return (
        <TableRow className={rowClasses}>
            <TableBodyCell>
                <Checkbox
                    name="invoiceCheckboxRow"
                    value={invoice.reference}
                    checked={checked}
                    onChange={handleCheck}
                />
            </TableBodyCell>
            <TableBodyCell>{invoice.paid ? invoice.reference : t('invoice-table.row.next-invoice')}</TableBodyCell>
            <TableBodyCell>
                <span>{date}</span> <span css={getInvoiceTimeStyles}>{time}</span>
            </TableBodyCell>
            <TableBodyCell>{useFormatCurrency(invoice.price?.currency || '', invoice.price?.totalPrice)}</TableBodyCell>
            <TableBodyCell>
                {invoice.hasInvoicePdf ? (
                    <Button
                        variant={ButtonVariant.TEXT}
                        color={ButtonColor.PRIMARY}
                        title={`${t('invoice-table.header.download')} ${invoice.paid ? invoice.reference : ''}`}
                        onClick={(): void => {
                            invoice.reference && downloadInvoice(invoice.reference);
                        }}
                    >
                        <Icon name={IconNames.FILE} size={IconSize.SIZE_REGULAR} />
                    </Button>
                ) : (
                    <span>{t('invoice-table.row.pending-invoice')}</span>
                )}
            </TableBodyCell>
        </TableRow>
    );
};
