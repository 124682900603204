import { API_ERRORS_OTP_MAPPING, API_ERRORS_OTP_MAPPING_KEYS } from '@constants/apiErrors';

type RemainingTime = {
    minutes?: number;
    seconds?: number;
};

const ONE_MINUTES_MS = 60000;

export const getFormattedTime = (timeInMs?: number): RemainingTime => {
    if (!timeInMs) {
        return {};
    }
    if (timeInMs < ONE_MINUTES_MS) {
        // Return time in seconds if less than a minute
        return { seconds: Math.floor(timeInMs / 1000), minutes: 0 };
    } else {
        // Return time in minutes and seconds
        const minutes = Math.floor(timeInMs / ONE_MINUTES_MS);
        const seconds = Math.floor((timeInMs % ONE_MINUTES_MS) / 1000);
        return { minutes, seconds };
    }
};

export const mapOtpErroKey = (errorCode: string) => {
    const mappingKey = Object.keys(API_ERRORS_OTP_MAPPING).find(
        (key) => API_ERRORS_OTP_MAPPING[key as keyof typeof API_ERRORS_OTP_MAPPING] === errorCode,
    );

    const newErrorKey =
        errorCode && mappingKey && Object.values<string>(API_ERRORS_OTP_MAPPING).includes(errorCode)
            ? API_ERRORS_OTP_MAPPING_KEYS[mappingKey as keyof typeof API_ERRORS_OTP_MAPPING]
            : API_ERRORS_OTP_MAPPING_KEYS.DEFAULT;

    return newErrorKey;
};
