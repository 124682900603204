import { FC } from 'react';

import { IInfoPanelProps, InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';

import { IntegrationGuide } from '../../../IntegrationGuide/IntegrationGuide';

type ShopwareIntegrationGuideType = Omit<IInfoPanelProps, 'title' | 'collapsible'>;

export const ShopwareIntegrationGuide: FC<ShopwareIntegrationGuideType> = ({
    collapsed,
}: ShopwareIntegrationGuideType): JSX.Element => {
    const { t } = useTranslation();

    return (
        <IntegrationGuide>
            <InfoPanel title={t('integrations.guide.title')} collapsible={true} collapsed={collapsed}>
                <InfoPanelStepList>
                    <InfoPanelStep title={t('integrations.guide.install-configure-module')}>
                        <Typography component="p">
                            <Trans i18nKey="integrations.shopware.step1-description" />
                        </Typography>
                    </InfoPanelStep>
                    <InfoPanelStep title={t('integrations.guide.account-sync')}>
                        <ul>
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.guide.default-information') }} />
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.guide.select-shipping') }} />
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.guide.enter-carrier') }} />
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.guide.configure-price') }} />
                        </ul>
                    </InfoPanelStep>
                    <InfoPanelStep title={t('integrations.guide.use-module')}>
                        <Typography component="p">
                            <Trans i18nKey="integrations.guide.access-packlink-pro" />
                        </Typography>
                    </InfoPanelStep>
                </InfoPanelStepList>
            </InfoPanel>
        </IntegrationGuide>
    );
};
