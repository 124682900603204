import { DEFAULT_HEIGHT } from '../Charts';
import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const tableStyles = (): SerializedStyles =>
    css({
        height: `${DEFAULT_HEIGHT}px`,
    });

export const flagStyles = (theme: Theme): SerializedStyles =>
    css({
        verticalAlign: 'baseline',
        marginRight: theme.spacing(),
    });

export const iconStyles = (theme: Theme): SerializedStyles =>
    css({
        verticalAlign: 'text-bottom',
        marginRight: theme.spacing(),
    });
