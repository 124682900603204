import { useState, createContext, PropsWithChildren, useContext } from 'react';

/**
 * Handle the Form Status in a context as multiple elements have effect
 * in this form
 */
export enum DeferredPaymentsFormStatus {
    OPEN = 'open',
    CLOSED = 'closed',
    LOADING = 'loading',
}

type IUseDeferredPaymentFormStatus = {
    deferredPaymentFormStatus: DeferredPaymentsFormStatus;
    setDeferredPaymentFormStatus: (status: DeferredPaymentsFormStatus) => void;
};

const DeferredPaymentFormStatusContext = createContext<IUseDeferredPaymentFormStatus | undefined>(undefined);

export function DeferredPaymentFormStatusProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
    const [deferredPaymentFormStatus, setDeferredPaymentFormStatus] = useState<DeferredPaymentsFormStatus>(
        DeferredPaymentsFormStatus.CLOSED,
    );

    return (
        <DeferredPaymentFormStatusContext.Provider
            value={{
                deferredPaymentFormStatus,
                setDeferredPaymentFormStatus,
            }}
        >
            {children}
        </DeferredPaymentFormStatusContext.Provider>
    );
}

export function useDeferredPaymentFormStatus(): IUseDeferredPaymentFormStatus {
    const context = useContext(DeferredPaymentFormStatusContext);

    if (context === undefined) {
        throw new Error('useDeferredPaymentFormStatus must be used within a DeferredPaymentFormStatusProvider');
    }

    return context;
}
