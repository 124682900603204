import { FC } from 'react';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelContentNote } from '@components/SidePanel/SidePanelContentNote';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';

import { AmplitudeEventsPro } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { IntegrationFormLogo } from '@Integrations/components/IntegrationForm/IntegrationFormLogo';
import { IGenericPanelContentProps } from '@Integrations/components/IntegrationPanel/types';
import { GTMEvents } from '@packlink/metrics';
import { TenantName } from '@packlink/packlink-sdk';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { useTenant } from '@packlink/tenant-provider';
import { useTranslation } from '@packlink/translation-provider';
import { ITenantConfig } from '@types';

import { WizishopIntegrationGuide } from './WizishopIntegrationGuide';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useAmplitude } from '@hooks/useAmplitude';

type IWizishopIntegrationFormProps = Omit<
    IGenericPanelContentProps,
    'onSubmit' | 'onDeactivate' | 'isLoading' | 'error' | 'clearError'
>;

export const WizishopIntegrationForm: FC<IWizishopIntegrationFormProps> = (
    props: IWizishopIntegrationFormProps,
): JSX.Element => {
    const { integration, onClose, onCancel } = props;
    const { sendGtmEvent } = useGoogleTagManager();
    const { sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();

    const { t } = useTranslation();

    const {
        integrations: {
            [integration.alias]: { action },
        },
    } = useTenantConfig<ITenantConfig>();
    const { name: tenantName } = useTenant();
    const actionUrl = action?.url[tenantName] || action?.url[TenantName.PACKLINKPRO];

    const handleCancel = (): void => {
        onCancel(integration.name);
    };

    const handleAction = (): void => {
        sendSuffixedAmplitudeEvent(
            sendAmplitudeClickEvent,
            integration.name,
        )(AmplitudeEventsPro.CONNECT_INTEGRATION_SIDE_PANEL);

        sendGtmEvent(GTMEvents.MARKETPLACE_INTEGRATION, {
            action: GTMActionFieldOption.INTEGRATION_INTEREST,
            integrationType: integration.name,
        });
        window.open(actionUrl, '_blank');
    };

    return (
        <>
            <SidePanelHeader onAction={onClose} title={t('integrations.panel.connect-shop')} />
            <SidePanelContent>
                <IntegrationFormLogo logo={integration.logo} alt={integration.name} />

                <WizishopIntegrationGuide />

                <SidePanelContentNote title={t('integrations.wizishop.note-title')}>
                    {t('integrations.wizishop.note-subtitle')}
                </SidePanelContentNote>
            </SidePanelContent>
            <SidePanelFooter>
                <SidePanelFooterActions
                    showActionButton={!!action}
                    onAction={handleAction}
                    onCancel={handleCancel}
                    actionText={t('integrations.panel.connect')}
                    cancelText={t('integrations.panel.cancel')}
                />
            </SidePanelFooter>
        </>
    );
};
