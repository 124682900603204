import { useTranslation } from '@packlink/translation-provider';
import { PaymentDetails } from '@components/PaymentDetails/PaymentDetails';
import { DestinationDetails } from './components/DestinationDetails/DestinationDetails';
import { OriginDetails } from './components/OriginDetails/OriginDetails';
import { OrderDetails } from './components/OrderDetails/OrderDetails';
import { PostSaleTracking } from './components/PostsaleTracking/PostSaleTracking';
import { ShipmentDetailsAddress } from './components/ShipmentDetailsAddress/ShipmentDetailsAddress';
import { ShipmentDetailsEmailWarning } from './components/ShipmentDetailsNotifications/ShipmentDetailsEmailWarning';
import { ShipmentDetailsPackagingAdvice } from './components/ShipmentDetailsNotifications/ShipmentDetailsPackagingAdvice';
import { ShipmentDetailsCarrierWarnings } from './components/ShipmentDetailsNotifications/ShipmentDetailsCarrierWarnings';
import { ShipmentDetailsPacklinkReference } from './components/ShipmentDetailsPacklinkReference/ShipmentsDetailsPacklinkReference';
import { PostsaleSection } from './components/PostsaleSection/PostsaleSection';
import { PrintInStoreDisclaimer } from '@Postsale/pages/ShipmentDetails/components/ShipmentDetailsContent/components/ShipmentDetailsNotifications/PrintInStoreDisclaimer';
import { CustomsDisclaimer } from '@Postsale/pages/ShipmentDetails/components/ShipmentDetailsContent/components/ShipmentDetailsNotifications/CustomsDisclaimer';
import { useSourceShortcode } from '@hooks/useSourceShortcode';
import { Address, IAddress, IPrice, IPriceDetail, ProShipment } from '@packlink/packlink-sdk';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import {
    getCustomsDisclaimerStyles,
    getPrintInStoreDisclaimerStyles,
    getShipmentDetailsCarrierWarningsStyles,
    getShipmentDetailsContentStyles,
    getShipmentDetailsEmailAndPackagingStyles,
    getShipmentSectionFullRowStyles,
} from './ShipmentDetailsContentStyles';

type ShipmentDetailsContentProps = {
    shipment: ProShipment;
};

export function ShipmentDetailsContent({ shipment, ...props }: ShipmentDetailsContentProps): JSX.Element {
    const { t } = useTranslation();

    const { title: trackingSectionTitle } = usePostsaleTrackingData(shipment);
    const { title: originDetailsSectionTitle, address: origin } = usePostsaleOriginDetailsData(shipment);
    const { title: destinationDetailsSectionTitle, address: destination } = usePostsaleDestinationDetailsData(shipment);
    const { title: orderDetailsSectionTitle } = usePostsaleOrderDetailsData(shipment);
    const paymentDetailsProps = usePostsalePaymentDetailsData(shipment);

    return (
        <section css={getShipmentDetailsContentStyles} {...props}>
            {showCustomsNotification(shipment) && (
                <CustomsDisclaimer
                    css={getCustomsDisclaimerStyles}
                    data-id="shipment-details-content-customs-notification"
                />
            )}

            <ShipmentDetailsCarrierWarnings
                css={getShipmentDetailsCarrierWarningsStyles}
                shipment={shipment}
                data-id="shipment-details-content-carrier-warnings"
            />

            {showPrintInStoreWarning(shipment) && (
                <PrintInStoreDisclaimer
                    css={getPrintInStoreDisclaimerStyles}
                    data-id="shipment-details-print-in-store-disclaimer"
                />
            )}

            <PostsaleSection
                title={t('post-sale.details.reference-number')}
                tooltip={t('post-sale.details.reference-number-for-contact')}
            >
                <ShipmentDetailsPacklinkReference shipment={shipment} />
            </PostsaleSection>

            <PostsaleSection title={trackingSectionTitle}>
                <PostSaleTracking shipment={shipment} />
            </PostsaleSection>

            <PostsaleSection title={originDetailsSectionTitle}>
                <OriginDetails shipment={shipment} data-id="shipment-details-content-origin-details" />
            </PostsaleSection>

            <PostsaleSection title={destinationDetailsSectionTitle}>
                <DestinationDetails shipment={shipment} data-id="shipment-details-content-destination-details" />
            </PostsaleSection>

            <PostsaleSection>
                <ShipmentDetailsAddress address={origin} data-id="shipment-details-content-origin-address-data" />
            </PostsaleSection>

            <PostsaleSection>
                <ShipmentDetailsAddress
                    address={destination}
                    data-id="shipment-details-content-destination-address-data"
                />
            </PostsaleSection>
            <div css={getShipmentSectionFullRowStyles}>
                <PostsaleSection css={getShipmentDetailsEmailAndPackagingStyles}>
                    <ShipmentDetailsPackagingAdvice data-id="shipment-details-content-packaging-advice" />
                </PostsaleSection>

                <PostsaleSection css={getShipmentDetailsEmailAndPackagingStyles}>
                    <ShipmentDetailsEmailWarning
                        address={destination}
                        data-id="shipment-details-content-email-warning"
                    />
                </PostsaleSection>
            </div>

            <PostsaleSection title={orderDetailsSectionTitle}>
                <OrderDetails data-id="shipment-details-content-order-details" shipment={shipment} />
            </PostsaleSection>

            <PostsaleSection title={t('post-sale.invoice.title')}>
                <PaymentDetails data-id="shipment-details-content-payment-details" {...paymentDetailsProps} />
            </PostsaleSection>
        </section>
    );
}

function showPrintInStoreWarning(shipment: ProShipment) {
    return !!shipment.data?.upsales?.printInStore?.available;
}

function showCustomsNotification(shipment: ProShipment) {
    return !ShipmentUtils.isShipmentCanceled(shipment.data) && shipment.data.hasCustoms;
}

function usePostsaleTrackingData(shipment: ProShipment) {
    const { t } = useTranslation();

    const carrier = shipment.data.service?.carrierName;
    return {
        title: `${carrier} ${t('post-sale.carrier.tracking')}`,
    };
}

function usePostsaleOrderDetailsData(shipment: ProShipment) {
    const { t } = useTranslation();

    const source = useSourceShortcode(shipment.data.source);
    const title = `${source} ${t('post-sale.order.title')}`;

    return {
        title,
    };
}

function usePostsaleOriginDetailsData(shipment: ProShipment) {
    const { t } = useTranslation();
    const title = shipment.data.service?.dropOff?.origin
        ? t('post-sale.origin-details.dropoff-point')
        : t('post-sale.origin-details.collection');

    return {
        title,
        address: shipment.data.from as Address<IAddress>,
    };
}

function usePostsaleDestinationDetailsData(shipment: ProShipment) {
    const { t } = useTranslation();
    const hasEstimatedDeliveryDate = shipment.data.estimatedDeliveryDate && !shipment.data.service?.dropOff?.origin;
    const title = hasEstimatedDeliveryDate
        ? t('postsale-destination.estimated-delivery')
        : t('postsale-destination.delivery');

    return {
        title,
        address: shipment.data.to as Address<IAddress>,
    };
}

function usePostsalePaymentDetailsData(shipment: ProShipment) {
    return {
        shipments: [shipment],
        shipmentsPriceDetails: [shipment.data.price] as IPrice[],
        total: shipment.data.price?.price as IPriceDetail,
    };
}
