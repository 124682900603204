import { css } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export function getOnboardingWelcomeContainerStyles() {
    return css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    });
}

export function getSkipLinkStyles(theme: Theme) {
    return css({
        textAlign: 'right',
        marginRight: theme.spacing(2),
    });
}
