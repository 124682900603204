import { AmplitudeEventsPro } from '@constants/amplitude';
import { ActiveIntegrationImport } from '@Integrations/types/integration';
import { DialogActions, Typography } from '@shipengine/giger';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { ImportOrdersDeactivateButton } from '../ImportOrdersDeactivateButton';
import { getImportOrdersResultImageStyles } from '../ImportOrdersModalStyles';

interface IInvalidAppKeyErrorContentProps {
    onClose: () => void;
    integration: ActiveIntegrationImport;
}

export const InvalidAppKeyErrorContent = ({ onClose, integration }: IInvalidAppKeyErrorContentProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            <img
                src={`${config.cdn.url}/pro/statics/animations/import-orders/goto.gif`}
                alt={''}
                css={getImportOrdersResultImageStyles}
            />
            <Typography component="div">
                <Trans>
                    {t('invalid-app-key-import-modal.content.description', { integration: integration.name })}
                </Trans>
            </Typography>
            <DialogActions>
                <ImportOrdersDeactivateButton
                    data-id="invalid-app-key-disconnect-button"
                    label={t('invalid-app-key-import-modal.content.disconnect', { integration: integration.name })}
                    event={AmplitudeEventsPro.APP_KEY_DISCONNECT_STORE_DIALOG}
                    onClose={onClose}
                    integration={integration}
                />
            </DialogActions>
        </>
    );
};
