import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';
import { ApiClientError, IApiClientErrorApiResponse, ApiErrorMessages, ApiErrorMessage } from '@packlink/packlink-sdk';
import { Breadcrumb, User } from '@sentry/browser';

declare let SENTRY_REF: string;
declare let SENTRY_ENV: string;

export enum LoggerBreadcrumb {
    PAYMENT = 'Payment',
    ORDER = 'Order',
}

export enum LoggerTag {
    PAYMENT_ERROR = 'payment.error',
    LOCALE = 'locale',
    PLAN = 'plan',
}

export enum LoggerContext {
    FEATURE_FLAGS = 'featureFlags',
    AVAILABLE_FEATURES = 'availableFeatures',
}

export const hasSentry = !!(SENTRY_REF && SENTRY_ENV);

export const init = (): void => {
    hasSentry &&
        Sentry.init({
            dsn: config.sentry.dsn,
            release: SENTRY_REF,
            environment: SENTRY_ENV,
            integrations: [SentryBrowser.dedupeIntegration()],
            normalizeDepth: 10,
            /**
             * ResizeObserver:
             *  - https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/58701523#58701523
             *  - https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
             */
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications.',
            ],
        });
};

const setUser = (user: User): void => {
    hasSentry && Sentry.withScope((scope) => scope.setUser(user));
};

const addBreadcrumb = (breadcrumb: Breadcrumb, maxBreadcrumbs?: number): void => {
    hasSentry && Sentry.withScope((scope) => scope.addBreadcrumb(breadcrumb, maxBreadcrumbs));
};

const setTag = (tag: LoggerTag, value: string): void => {
    hasSentry && Sentry.withScope((scope) => scope.setTag(tag, value));
};

const setContext = (context: string, value: unknown): void => {
    hasSentry && Sentry.withScope((scope) => scope.setContext(context, value as Record<string, unknown>));
};

const captureException = (error: Error, captureContext?: Parameters<typeof Sentry.captureException>[1]): void => {
    hasSentry && Sentry.captureException(error, captureContext);
};

const clear = (): void => {
    hasSentry && Sentry.withScope((scope) => scope.clear());
};

const logSdkError = (error: ApiClientError): void => {
    if (error.status && error.status < 500) {
        error.log();
    }
};

// TODO: to be removed when we fully use the sdk endpoints
const parseMessageApi = (message: ApiErrorMessage): string | undefined => {
    if (Array.isArray(message) && message.length) {
        return message[0];
    } else if (typeof message === 'string') {
        return message;
    }
    return undefined;
};

// TODO: to be removed when we fully use the sdk endpoints
const parseMessagesApi = (messages: ApiErrorMessages): string | undefined => {
    if (Array.isArray(messages) && messages.length) {
        const message = messages[0].message;
        return parseMessageApi(message);
    } else if (!Array.isArray(messages)) {
        return parseMessageApi(messages.message);
    }
    return undefined;
};

// TODO: to be removed when we fully use the sdk endpoints
const logApiError = (data: IApiClientErrorApiResponse, fallbackMessage: string): void => {
    let parsedMessage;
    if (data && data.message) {
        parsedMessage = parseMessageApi(data.message);
    } else if (data && data.messages) {
        parsedMessage = parseMessagesApi(data.messages);
    }
    const message = parsedMessage || fallbackMessage;

    const error = new Error(message);
    error.name = 'logApiError';

    captureException(error);
};

export { addBreadcrumb, setUser, setContext, setTag, captureException, clear, logSdkError, logApiError };
