import React from 'react';
import { rowFooterStyles } from './ShipmentRowFooterStyles';

interface IShipmentRowFooter {
    children: React.ReactNode;
}

export const ShipmentRowFooter: React.FC<IShipmentRowFooter> = (props: IShipmentRowFooter): JSX.Element => {
    const { children } = props;

    return <section css={rowFooterStyles}>{children}</section>;
};
