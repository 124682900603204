import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from '@packlink/translation-provider';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import Packlink from '@sdk';
import { Archive, ArchiveTime, ArchiveTimeType } from '@packlink/packlink-sdk';
import { useToast } from '@shipengine/giger';
import { logSdkError } from '@utils/logger';
import { Status, useStatus } from '@hooks/useStatus';
import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';
import { Spinner } from '@components/Spinner/Spinner';
import { APP_ROUTE } from '@pages/router/routes';
import { ArchivingForm } from './ArchivingForm';

export interface IArchivingFormFields {
    delivered: ArchiveTimeType;
    draft: ArchiveTimeType;
}

export const ArchivingSettings: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const toast = useToast(t);
    const navigate = useNavigate();

    const defaultValues = useMemo((): IArchivingFormFields => {
        return {
            delivered: ArchiveTimeType.NEVER,
            draft: ArchiveTimeType.NEVER,
        };
    }, []);
    const [initialValues, setInitialValues] = useState<IArchivingFormFields>(defaultValues);
    const { setStatus, isLoading, isReady } = useStatus();

    useEffect(() => {
        setStatus(Status.LOADING);
        Packlink.v1.archive
            .get()
            .then((archives) => {
                setInitialValues({
                    delivered: archives.delivered.active || defaultValues.delivered,
                    draft: archives.draft.active || defaultValues.draft,
                });
                setStatus(Status.READY);
            })
            .catch((error) => {
                logSdkError(error);
                toast.error({ message: t('processor.declined.error') });
                navigate(APP_ROUTE.SETTINGS.BILLING_INVOICES);
            });
    }, [defaultValues, navigate, setStatus, t, toast]);

    const optionValues = Object.values(ArchiveTimeType);
    const onSubmit = (formData: IArchivingFormFields): void => {
        const data = {
            delivered: new ArchiveTime(formData.delivered, optionValues),
            draft: new ArchiveTime(formData.draft, optionValues),
        };
        Packlink.v1.archive
            .put(Archive.deserialize(data))
            .then(() => {
                toast.success({ message: t('archiving.settings.save-success') });
            })
            .catch((error) => {
                logSdkError(error);
                toast.error({ message: 'archiving.settings.save-error' });
            });
    };

    const validationSchema = yup.object<IArchivingFormFields>().shape({
        // No required message needed, since there's a default value this case will never happen
        delivered: yup.mixed<ArchiveTime>().required(),
        draft: yup.mixed<ArchiveTime>().required(),
    });

    return (
        <SettingsPage>
            <SettingsPageHeader description={t('archiving.settings.description')}>
                {t('archiving.settings.title')}
            </SettingsPageHeader>

            {isLoading && <Spinner data-id="archiving-spinner" />}
            {isReady && (
                <Formik
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnMount={true}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<IArchivingFormFields>): React.ReactNode => (
                        <ArchivingForm formProps={formProps} />
                    )}
                </Formik>
            )}
        </SettingsPage>
    );
};
