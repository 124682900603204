import { useEffect, useState } from 'react';

import { Grid, GridChild, Typography, useToast } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { IWarehouseAddress } from '@packlink/packlink-sdk';

import { EditableFavoritesCard } from '@components/EditableFavoritesCard/EditableFavoritesCard';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';

import { useAddressesContext } from '../../../../context/addresses';
import { AddressCardContent } from '../../../../components/AddressCardContent/AddressCardContent';
import { AddressForm, AddressFormValues, formValuesToAddress } from '../../../../components/AddressForm/AddressForm';
import { LimitNotification } from '../../../../components/LimitNotification/LimitNotification';
import { AddressesEmptyState } from '../../../../components/AddressesEmptyState/AddressesEmptyState';
import { contentStyles } from './WarehousesBookStyles';
import { Origin } from '@types';
import { useAmplitude } from '@hooks/useAmplitude';
import { useWarehouses } from '@common/hooks/useWarehouses';

export function WarehousesBook(): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const toast = useToast(t);
    const { sendAmplitudeEvent } = useAmplitude();
    const { maxAddresses, hasReachedLimit, isAddressFormVisible, setIsAddressFormVisible, setNumberOfAddresses } =
        useAddressesContext();
    const { warehouses, isLoading, deleteWarehouseMutation, setDefaultWarehouseMutation, handleRequestSaveWarehouse } =
        useWarehouses();

    const [selectedWarehouse, setSelectedWarehouse] = useState<IWarehouseAddress>();

    const handleDefault = (warehouse: IWarehouseAddress) => {
        setDefaultWarehouseMutation(warehouse, {
            onError: () => {
                toast.error({ message: t('settings.error.try-again') });
                return;
            },
        });
    };

    const handleDelete = (warehouse: IWarehouseAddress) => {
        deleteWarehouseMutation(warehouse, {
            onSuccess: () => {
                toast.success({ message: t('addresses.settings.delete-success') });
            },
            onError: () => {
                toast.error({ message: t('settings.error.try-again') });
                return;
            },
        });
    };

    const handleEdit = (warehouse: IWarehouseAddress) => {
        setSelectedWarehouse(warehouse);
        setIsAddressFormVisible(true);
        sendAmplitudeEvent(AmplitudeEvents.SETTINGS_EDIT_ADDRESS_CLICK, {
            [AmplitudeProperties.SECTION]: 'sender',
        });
    };

    const resetForm = () => {
        setIsAddressFormVisible(false);
        setSelectedWarehouse(undefined);
    };

    const onSaveForm = (values: AddressFormValues) => {
        const isAddressDefault = warehouses?.length <= 0;
        const address = { ...formValuesToAddress(values, locale), isDefault: isAddressDefault } as IWarehouseAddress;

        handleRequestSaveWarehouse(address, {
            onSuccess: () => {
                toast.success({
                    message: values.id ? t('addresses.settings.edit-success') : t('addresses.settings.save-success'),
                });
                resetForm();
            },
            onError: () => toast.error({ message: t('settings.error.try-again') }),
        });
    };

    useEffect(() => {
        warehouses && setNumberOfAddresses(warehouses.length);
    }, [setNumberOfAddresses, warehouses]);

    return (
        <>
            <Typography component="p" variant="body2">
                {t('addresses.settings.manage-addresses', { max: maxAddresses })}
            </Typography>

            {isAddressFormVisible && (
                <AddressForm
                    origin={Origin.FROM}
                    css={contentStyles}
                    address={selectedWarehouse}
                    onCancel={resetForm}
                    onSave={onSaveForm}
                />
            )}

            {!isAddressFormVisible && (
                <>
                    {hasReachedLimit && <LimitNotification />}

                    {!isLoading && !warehouses?.length && (
                        <AddressesEmptyState onNewAddress={() => setIsAddressFormVisible(true)} />
                    )}

                    <Grid noPadding css={contentStyles}>
                        {warehouses.map((warehouse) => (
                            <GridChild colSpan={6} colSpanDesktop={4} key={warehouse.id}>
                                <EditableFavoritesCard
                                    name={warehouse.alias}
                                    isDefault={warehouse.isDefault}
                                    hasMultipleCards={warehouses.length > 1}
                                    canCreateDefault={true}
                                    setDefault={() => handleDefault(warehouse)}
                                    setDelete={() => handleDelete(warehouse)}
                                    setEdit={() => handleEdit(warehouse)}
                                >
                                    <AddressCardContent address={warehouse} />
                                </EditableFavoritesCard>
                            </GridChild>
                        ))}
                    </Grid>
                </>
            )}
        </>
    );
}
