import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getIntegrationFormLogoStyles = (): SerializedStyles =>
    css({
        maxHeight: 75,
        maxWidth: '100%',
    });

export const getIntegrationFormLogoContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        height: 75,
        lineHeight: '75px',
        marginBottom: theme.spacing(1),
        width: '80%',
    });
