import moment from 'moment';
import { useTenantBehavior } from '@packlink/tenant-config-provider';

type TenantFeature = { enabled: boolean };
export type CancellationTimeLimitFeature = { value: number; unit: moment.DurationInputArg2 };
type TenantFeatureMap = {
    isInternalDetailsPageEnabled: boolean;
    cancellationTimeLimit?: CancellationTimeLimitFeature;
};

export function useTenantFeatures(): TenantFeatureMap {
    const getTenantBehavior = useTenantBehavior();

    return {
        isInternalDetailsPageEnabled:
            !!getTenantBehavior<TenantFeature>('SupportCenterDetailsPage')?.properties.enabled,
        cancellationTimeLimit: getTenantBehavior<CancellationTimeLimitFeature>('CancellationTimeLimit')?.properties,
    };
}
