import { useMemo } from 'react';
import { ProShipment } from '@packlink/packlink-sdk';

/**
 * The first array are the shipments missing info to request services
 * The second array are the shipments valid to request services
 * @param shipments
 */
export const useShipmentsForServiceRequest = (shipments: ProShipment[]): [ProShipment[], ProShipment[]] => {
    const hasRequiredInfo = (shipment: ProShipment): boolean => {
        const { from, to, parcels, source } = shipment.data;

        return !!(from?.alpha2Code && from.zipCode && to?.alpha2Code && to.zipCode && parcels?.length && source);
    };

    return useMemo(
        () =>
            shipments.reduce(
                (result: [ProShipment[], ProShipment[]], shipment: ProShipment) => {
                    const arrayPosition = hasRequiredInfo(shipment) ? 1 : 0;
                    result[arrayPosition].push(shipment);
                    return result;
                },
                [[], []],
            ),
        [shipments],
    );
};
