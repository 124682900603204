import { Typography, WithChildrenCommonProps } from '@shipengine/giger';
import { getClipboardTooltipStyles, getTypographyContainerStyles } from './ContentWithClipboardStyles';
import { ClipboardTooltip } from './ClipboardTooltip';

interface ContentWithClipboardProps {
    valueToCopy: string;
    onClick?: () => void;
}
export const ContentWithClipboard = ({
    valueToCopy,
    onClick,
    children,
    ...rest
}: WithChildrenCommonProps<ContentWithClipboardProps>): JSX.Element => {
    return (
        <Typography component="span" css={getTypographyContainerStyles} {...rest}>
            {children}
            <span css={getClipboardTooltipStyles}>
                <ClipboardTooltip valueToCopy={valueToCopy} onClick={onClick} />
            </span>
        </Typography>
    );
};
