export enum SHORTCODES {
    amazon_inbound = 'AMZ',
    csv_inbound = 'CSV',
    ebay = 'EBY',
    ebay_inbound = 'EBY',
    epages_epages_inbound = 'EPA',
    epages_1and1 = '1&1',
    epages_1and1eu = '1&1',
    epages_1and1eu_inbound = '1&1',
    epages_acens = 'ACN',
    epages_acens_inbound = 'ACN',
    epages_amenfr = 'AMN',
    epages_amenfr_inbound = 'AMN',
    epages_arsys = 'ARS',
    epages_arsys_inbound = 'ARS',
    epages_aruba = 'ARB',
    epages_aruba_inbound = 'ARB',
    epages_hosteurope = 'HOS',
    epages_hosteurope_inbound = 'HOS',
    epages_packlink = 'EPG',
    epages_registerit = 'REG',
    epages_registerit_inbound = 'REG',
    epages_sage = 'SAG',
    epages_sage_inbound = 'SAG',
    epages_strato = 'STR',
    epages_strato_inbound = 'STR',
    mirakl_eprice = 'EPR',
    mirakl_eprice_inbound = 'EPR',
    mirakl_nature = 'NAT',
    mirakl_nature_inbound = 'NAT',
    mirakl_orange = 'ORN',
    mirakl_orange_inbound = 'ORN',
    mirakl_packlink = 'MKL',
    mirakl_privalia = 'PRI',
    mirakl_privalia_inbound = 'PRI',
    mirakl_pernod_ricard = 'LNC',
    mirakl_pernod_ricard_inbound = 'LNC',
    mirakl_twil = 'TWL',
    mirakl_twil_inbound = 'TWL',
    module_ecwid = 'ECW',
    module_magento = 'MAG',
    module_prestashop = 'PSP',
    module_woocommerce = 'WOO',
    module_bigcommerce = 'BIG',
    module_zapier = 'ZAP',
    module_shopware = 'SWR',
    module_shopify = 'SHO',
    module_wix = 'WIX',
    wizishop_inbound = 'WIZ',
    module_zencart = 'ZEN',
    paypal = 'PPL',
    paypal_inbound = 'PPL',
    prestashop_inbound = 'PSP',
    pro = 'PRO',
}
