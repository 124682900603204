import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getCommonQuestionsStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(6),
    });

export const getCommonQuestionsTitleStyles = (): SerializedStyles =>
    css({
        marginBottom: 0,
    });

export const getQuestionsListStyles = (): SerializedStyles =>
    css({
        listStyleType: 'none',
    });

export const getQuestionGroupStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(3),
    });

export const getQuestionGroupTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        gap: theme.spacing(),
    });

export const getQuestionStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getCommonQuestionsFooterStyles = (): SerializedStyles =>
    css({
        display: 'block',
    });

export const getNotificationStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });
