import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import { useQuery } from 'react-query';
import { ApiClientError, IPrice, IProShipmentApiResponse, ProPrice, ProShipment } from '@packlink/packlink-sdk';
import Packlink from '@sdk';
import { logSdkError } from '@utils/logger';
import { GTMActionFieldOption } from '@constants/gtm';
import { getClientIdentifier } from '@store/selectors/client';
import { useEvent } from '@packlink/event-handler';
import { EventName } from '@types';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';

export function useBulkPaymentPrice(
    selectedShipments: ProShipment[],
    isPaying: boolean,
    setGenericError: Dispatch<SetStateAction<string | undefined>>,
) {
    const clientId = useSelector(getClientIdentifier);
    const { eventBind, eventUnbind } = useEvent<IProShipmentApiResponse>(clientId, EventName.SHIPMENT_UPDATED);
    const { sendCheckoutStepGtmEvent } = useGoogleTagManager();

    const sendCheckoutStep2GtmEvent = useCallback(
        (shipmentsPriceDetails: IPrice[]) => {
            sendCheckoutStepGtmEvent({
                shipments: getGtmShipmentsInfo(selectedShipments, shipmentsPriceDetails),
                actionFieldOption: GTMActionFieldOption.QUICK_CHECKOUT,
                stepNumber: 2,
            });
        },
        [selectedShipments, sendCheckoutStepGtmEvent],
    );

    const priceQueryKeys = selectedShipments.map((proShipment: ProShipment) => ({
        packlinkReference: proShipment.data.packlinkReference,
        voucherName: proShipment.data.voucherName,
    }));

    const {
        data: priceDetail,
        isLoading: isLoadingPriceDetail,
        isFetching: isFetchingPriceDetail,
        refetch: refetchPriceDetail,
    } = useQuery(
        ['price', ...priceQueryKeys],
        () => Packlink.pro.shipments.price.get(selectedShipments.map((proShipment: ProShipment) => proShipment.data)),
        {
            onSuccess: (data: ProPrice) => {
                sendCheckoutStep2GtmEvent(data.shipmentsPriceDetails);
            },
            onError: (error: ApiClientError): void => {
                setGenericError('payment.error.price');
                logSdkError(error);
            },
            keepPreviousData: true,
            enabled: !isPaying,
        },
    );

    useEffect(() => {
        const shipmentUpdatedCallback = () => {
            refetchPriceDetail();
        };
        eventBind(shipmentUpdatedCallback);
        return () => {
            eventUnbind(shipmentUpdatedCallback);
        };
    }, [eventBind, eventUnbind, refetchPriceDetail]);

    const priceByShipmentRef = useMemo(
        () => keyBy(priceDetail?.shipmentsPriceDetails, 'packlinkReference'),
        [priceDetail?.shipmentsPriceDetails],
    );

    return {
        priceDetail,
        priceByShipmentRef,
        isLoadingPriceDetail,
        isFetchingPriceDetail,
        refetchPriceDetail,
    };
}

function getGtmShipmentsInfo(selectedShipments: ProShipment[], shipmentsPriceDetails?: IPrice[]) {
    return selectedShipments.map(({ data }) => {
        const price = shipmentsPriceDetails
            ? shipmentsPriceDetails.find(
                  (shipmentPriceDetail) => shipmentPriceDetail.packlinkReference === data.packlinkReference,
              )?.price
            : data.price?.price;

        return {
            service: { ...data.service, price },
            from: data.from,
            to: data.to,
            packages: data.parcels,
            insurance: !!data.upsales?.insurance?.available,
            source: data.source,
        };
    });
}
