import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getConnectionCardLogoContainerStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            height: 48,
            lineHeight: theme.typography.lineHeight.XXL,
            marginBottom: theme.spacing(0.5),
            verticalAlign: 'middle',
            width: 80,
        });

export const getConnectionCardLogoStyles = (disabled: boolean): SerializedStyles =>
    css({
        maxHeight: 48,
        maxWidth: 80,
        ...(disabled ? { filter: 'grayscale(100%)' } : {}),
    });

export const getConnectionCardActionStyles =
    (selected: boolean, disabled: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            marginTop: theme.spacing(2),
            fontWeight: theme.typography.fontWeight.semibold,
            fontSize: theme.toRem(theme.typography.fontSize.XS),
            color: selected || disabled ? theme.palette.gray.main : theme.palette.primary.main,
        });

export const getConnectionCardDescriptionStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            fontWeight: theme.typography.fontWeight.normal,
            fontSize: theme.toRem(theme.typography.fontSize.XS),
            lineHeight: 1,
            margin: 0,
        });

export const getConnectionCardStyles =
    (selected: boolean, disabled: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: 165,
            minHeight: 118,
            maxHeight: 160,
            ...(disabled ? { color: theme.palette.gray.main } : {}),

            padding: theme.spacing(2),
            border: disabled ? 'none' : `1px solid ${selected ? theme.palette.primary.main : theme.palette.gray.light}`,
            borderRadius: theme.borderRadius.M,
            boxShadow: `0 2px 4px 0 ${theme.palette.gray.light}`,
            backgroundColor: disabled
                ? theme.palette.gray.light
                : selected
                  ? theme.palette.primary.ultraLight
                  : theme.palette.gray.megaLight,

            '&:hover': {
                borderColor: theme.palette.primary.main,
                cursor: disabled ? 'not-allowed' : 'pointer',
            },
        });
