import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BraintreeError } from 'braintree-web';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { useTranslation } from '@packlink/translation-provider';
import { PaymentStep as PaymentStepType } from '@packlink/payment-options';
import { Grid, GridChild, Typography, useToast } from '@shipengine/giger';
import { PaymentMethod } from '@packlink/packlink-sdk';

import { PaymentVoucher } from '@components/PaymentVoucher/PaymentVoucher';
import { OnPayProps, PaymentOptions } from '@components/PaymentOptions/PaymentOptions';
import { AppDispatch } from '@store';
import {
    getCheckoutContent,
    getCheckoutDropoffPointId,
    getCheckoutFrom,
    getCheckoutInsuranceType,
    getCheckoutParcels,
    getCheckoutPriceDetails,
    getCheckoutService,
    getCheckoutSource,
    getCheckoutTo,
    getCheckoutVoucher,
    getInvoiceInfoSaved,
    getIsCheckoutBusy,
} from '@store/selectors/checkout';
import { isInvoiceAddressValid } from '@store/selectors/client';
import { BillingInfo } from './BillingInfo/BillingInfo';
import {
    getPaymentStepPaymentGridStyles,
    getPaymentStepTitleStyles,
    paymentOptionsTitleStyles,
} from './PaymentStepStyles';
import { StepContent } from '../StepContent';
import { scrollToTop } from '../hooks/useInitStep';
import { useCreateCheckoutOrder } from '../hooks/useCreateCheckoutOrder';
import { useRefreshPrice } from '../hooks/useRefreshPrice';
import { resetCheckout } from '@store/actions/checkout';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { useCheckoutPath } from '@pages/checkout/hooks/useCheckoutPath';
import { CheckoutRoute } from '@pages/checkout/routes';
import { TermsAgreement } from '@components/TermsAgreement/TermsAgreement';
import { useAmplitude } from '@hooks/useAmplitude';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useRequiresPhoneNumberVerification } from '@common/hooks/useRequiresPhoneNumberVerification';
import { useBillingForm } from '@common/hooks/useBillingForm';

export const PaymentStep = (): JSX.Element => {
    const { t } = useTranslation();
    const toast = useToast(t);
    const { sendAmplitudeClickEvent } = useAmplitude();
    const [isPaying, setIsPaying] = useState(false);
    const [isCarriersTermsAccepted, setCarriersTerms] = useState(false);
    const [carriersTermsError, setCarriersTermsError] = useState('');
    const hasInvoiceInfo = useSelector(isInvoiceAddressValid);
    const hasInvoiceInfoSaved = useSelector(getInvoiceInfoSaved);
    const priceDetail = useSelector(getCheckoutPriceDetails);
    const voucher = useSelector(getCheckoutVoucher);
    const selectedService = useSelector(getCheckoutService);
    const insuranceType = useSelector(getCheckoutInsuranceType);
    const isCheckoutBusy = useSelector(getIsCheckoutBusy);
    const [formEditable, setFormEditable] = useState<boolean>(!hasInvoiceInfoSaved);
    const { createCheckoutOrder } = useCreateCheckoutOrder();
    const { clientRequiresPhoneNumberVerification, isClientRequiresPhoneNumberVerificationLoading } =
        useRequiresPhoneNumberVerification();
    const { isPhoneNumberVerificationOpen, onBillingFormSuccess, closePhoneNumberVerification } = useBillingForm();
    const showBillingInfo =
        isPhoneNumberVerificationOpen ||
        !hasInvoiceInfo ||
        hasInvoiceInfoSaved ||
        clientRequiresPhoneNumberVerification;
    const disablePaymentSection = (formEditable && !!showBillingInfo) || isClientRequiresPhoneNumberVerificationLoading;

    useInitRedirect();

    const onEdit = (editable: boolean) => {
        setFormEditable(editable);
    };

    const handlePaymentError = (
        _step: PaymentStepType,
        _paymentMethod?: PaymentMethod,
        _error?: BraintreeError | Error,
        translationKey?: string,
    ) => {
        translationKey && toast.error({ message: t(translationKey) });
        setIsPaying(false);
    };

    const handlePayment = (props: OnPayProps) => {
        if (isCarriersTermsAccepted) {
            setIsPaying(true);
            sendAmplitudeClickEvent(AmplitudeEvents.PAY, {
                [AmplitudeProperties.PAYMENT_METHOD]: props.method,
                [AmplitudeProperties.PAYMENT_TYPE]: props.type,
                [AmplitudeProperties.INSURANCE]: insuranceType,
            });
            createCheckoutOrder({ ...props, carriersTermsAndConditions: isCarriersTermsAccepted });
        } else {
            setCarriersTermsError(t('form.error.carriers-terms'));
        }
    };

    const validateCarriersTerms = (checked: boolean) => {
        if (!checked) {
            setCarriersTermsError(t('form.error.carriers-terms'));
            return;
        } else {
            setCarriersTermsError('');
        }
    };

    const handleCarriersTerms = ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
        setCarriersTerms(!isCarriersTermsAccepted);
        validateCarriersTerms(checked);
    };

    return (
        <StepContent>
            <Typography css={getPaymentStepTitleStyles} variant="heading2">
                {t('payment.page.title')}
            </Typography>
            <Grid>
                {showBillingInfo && (
                    <GridChild colSpan={12} colSpanTablet={6}>
                        <BillingInfo
                            onEditInfo={onEdit}
                            isPhoneNumberVerificationOpen={isPhoneNumberVerificationOpen}
                            onBillingFormSuccess={onBillingFormSuccess}
                            closePhoneNumberVerification={closePhoneNumberVerification}
                        />
                    </GridChild>
                )}
                <GridChild css={getPaymentStepPaymentGridStyles(disablePaymentSection)} colSpan={12} colSpanTablet={6}>
                    <PaymentVoucher />
                    <TermsAgreement
                        checked={isCarriersTermsAccepted}
                        handleChange={handleCarriersTerms}
                        error={carriersTermsError.length ? carriersTermsError : undefined}
                        title={t('payment.terms-and-condition.title')}
                        formOrigin="Payment step"
                    />
                    <PaymentOptions
                        title={
                            <Typography css={paymentOptionsTitleStyles} variant="heading5">
                                {t('payment.payment-options.title')}
                            </Typography>
                        }
                        amount={priceDetail?.price.totalPrice}
                        voucherPercentage={voucher?.percentage}
                        currency={selectedService?.currency}
                        isPaying={isPaying || isCheckoutBusy}
                        section="checkout"
                        onCheckForExternalError={() => validateCarriersTerms(isCarriersTermsAccepted)}
                        onPay={handlePayment}
                        onError={handlePaymentError}
                        isPaymentBlocked={isCarriersTermsAccepted !== true}
                    />
                </GridChild>
            </Grid>
        </StepContent>
    );
};

function useInitRedirect() {
    const selectedService = useSelector(getCheckoutService);
    const to = useSelector(getCheckoutTo);
    const from = useSelector(getCheckoutFrom);
    const dropoffPointId = useSelector(getCheckoutDropoffPointId);
    const checkoutContent = useSelector(getCheckoutContent);
    const parcels = useSelector(getCheckoutParcels);
    const insuranceType = useSelector(getCheckoutInsuranceType);
    const source = useSelector(getCheckoutSource);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const getCheckoutPath = useCheckoutPath();
    const { refreshPrice } = useRefreshPrice();
    const { sendCheckoutStepGtmEvent } = useGoogleTagManager();

    useEffectOnce(() => {
        scrollToTop();

        const isMissingDropoffPointId = selectedService?.dropOff?.destination && !dropoffPointId;
        const isShipmentMissingInfo = !from?.alpha2Code || !to?.alpha2Code;
        const isMissingContent = !checkoutContent?.description || !checkoutContent?.value;

        if (isShipmentMissingInfo) {
            dispatch(resetCheckout());
            navigate(getCheckoutPath(CheckoutRoute.INFO));
        } else if (!selectedService) {
            navigate(getCheckoutPath(CheckoutRoute.SERVICE));
        } else if (isMissingDropoffPointId || isMissingContent) {
            navigate(getCheckoutPath(CheckoutRoute.ADDRESS));
        } else {
            sendCheckoutStepGtmEvent({
                stepNumber: 2,
                actionFieldOption: GTMActionFieldOption.MANUAL_CHECKOUT,
                shipments: [
                    {
                        service: selectedService,
                        from: from,
                        to: to,
                        packages: parcels,
                        insuranceType,
                        source: source,
                    },
                ],
            });

            refreshPrice();
        }
    });
}
