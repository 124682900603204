import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getIntegrationGuideStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(3),
        maxWidth: 780,

        ul: {
            paddingLeft: theme.spacing(3),
            wordBreak: 'break-word',
        },
    });
