import { Spinner, SpinnerSize, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { TicketDataSection } from '../TicketDataSection/TicketDataSection';
import {
    getTicketDataLoadingPlaceholderSpinnerStyles,
    getTicketDataLoadingPlaceholderStyles,
} from './TicketDataLoadingPlaceholderStyles';

type TicketDataLoadingPlaceholderProps = WithCommonProps<unknown>;

export function TicketDataLoadingPlaceholder(props: TicketDataLoadingPlaceholderProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <TicketDataSection
            css={getTicketDataLoadingPlaceholderStyles}
            title={t('support-center.ticket-data-details.common-data-title')}
            {...props}
        >
            <div css={getTicketDataLoadingPlaceholderSpinnerStyles}>
                <Spinner size={SpinnerSize.SIZE_LARGE} />
            </div>
        </TicketDataSection>
    );
}
