import { useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { Grid, GridChild, Pagination, Spinner, Typography, useToast } from '@shipengine/giger';
import { IAddressBook } from '@packlink/packlink-sdk';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useAddressesContext } from '../../../../context/addresses';
import { useRecipientAddresses, useMutateRecipientAddress } from '../../../../hooks/useRecipientAddresses';
import { AddressCardContent } from '../../../../components/AddressCardContent/AddressCardContent';
import { AddressForm, formValuesToAddress, AddressFormValues } from '../../../../components/AddressForm/AddressForm';
import { AddressSearcher } from '../../../../components/AddressSearcher/AddressSearcher';
import { AddressesEmptyState } from '../../../../components/AddressesEmptyState/AddressesEmptyState';
import { EmptySearch } from '../../../../components/EmptySearch/EmptySearch';
import { EditableFavoritesCard } from '../../../../../../components/EditableFavoritesCard/EditableFavoritesCard';
import { LimitNotification } from '../../../../components/LimitNotification/LimitNotification';
import { contentStyles, paginationStyles, spinnerStyles } from './RecipientsBookStyles';
import { Origin } from '@types';
import { useAmplitude } from '@hooks/useAmplitude';

export function RecipientsBook(): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const toast = useToast(t);
    const [selectedAddress, setSelectedAddress] = useState<IAddressBook>();
    const [searchInputValue, setSearchInputValue] = useState<string>();
    const { sendAmplitudeEvent } = useAmplitude();
    const { maxAddresses, hasReachedLimit, isAddressFormVisible, setIsAddressFormVisible, setNumberOfAddresses } =
        useAddressesContext();

    const { addresses, isLoading, requestParams, changeRequestParams } = useRecipientAddresses({
        setNumberOfAddresses,
    });
    const { saveAddress, deleteAddress } = useMutateRecipientAddress(requestParams, changeRequestParams);

    const isBookEmpty = !isLoading && addresses && addresses.pagination.totalElements === 0;
    const hasAddresses = addresses && addresses.pagination.totalElements > 0;
    const hasPagination = addresses && addresses.pagination.totalPages > 1;

    const handleDelete = (address: IAddressBook) => {
        deleteAddress(address.id, {
            onSuccess: () => {
                toast.success({ message: t('addresses.settings.delete-success') });
            },
            onError: () => {
                toast.error({ message: t('settings.error.try-again') });
            },
        });
    };

    const resetForm = () => {
        setIsAddressFormVisible(false);
        setSelectedAddress(undefined);
    };

    const handleEdit = (address: IAddressBook) => {
        setSelectedAddress(address);
        setIsAddressFormVisible(true);
        sendAmplitudeEvent(AmplitudeEvents.SETTINGS_EDIT_ADDRESS_CLICK, {
            [AmplitudeProperties.SECTION]: 'recipient',
        });
    };

    const onSaveForm = (values: AddressFormValues) => {
        const address = formValuesToAddress(values, locale);

        return saveAddress(address, {
            onSuccess: () => {
                toast.success({
                    message: values.id ? t('addresses.settings.edit-success') : t('addresses.settings.save-success'),
                });

                resetForm();
            },
            onError: () => {
                toast.error({ message: t('settings.error.try-again') });
            },
        });
    };

    const handlePageChange = (selectedPage: number) => {
        changeRequestParams({ page: selectedPage - 1 });
    };

    const handleSearch = (alias?: string) => {
        changeRequestParams({ alias, page: 0 });
    };

    return (
        <>
            <Typography component="p" variant="body2">
                {t('addresses.settings.manage-addresses', { max: maxAddresses })}
            </Typography>

            {isAddressFormVisible && (
                <AddressForm
                    origin={Origin.TO}
                    css={contentStyles}
                    address={selectedAddress}
                    onCancel={resetForm}
                    onSave={onSaveForm}
                />
            )}

            {!isAddressFormVisible && (
                <>
                    {(hasAddresses || requestParams.alias) && (
                        <AddressSearcher
                            value={searchInputValue}
                            onChangeValue={setSearchInputValue}
                            onSearch={handleSearch}
                        />
                    )}

                    {isLoading && <Spinner css={spinnerStyles} />}

                    {hasReachedLimit && <LimitNotification />}

                    {isBookEmpty &&
                        (requestParams.alias ? (
                            <EmptySearch />
                        ) : (
                            <AddressesEmptyState onNewAddress={() => setIsAddressFormVisible(true)} />
                        ))}

                    {hasAddresses && (
                        <>
                            <Grid noPadding css={contentStyles}>
                                {addresses.content.map((address) => (
                                    <GridChild colSpan={6} colSpanDesktop={4} key={address.id}>
                                        <EditableFavoritesCard
                                            name={address.alias}
                                            setDelete={() => handleDelete(address)}
                                            setEdit={() => handleEdit(address)}
                                            data-id="recipient-address-card"
                                        >
                                            <AddressCardContent address={address} />
                                        </EditableFavoritesCard>
                                    </GridChild>
                                ))}
                            </Grid>

                            {hasPagination && (
                                <div css={paginationStyles}>
                                    <Pagination
                                        totalPages={addresses.pagination.totalPages}
                                        currentPage={requestParams.page + 1}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}
