import { ActiveIntegrationImport } from '@Integrations/types/integration';

export const SET_INTEGRATIONS = 'SET_INTEGRATIONS';
export const SET_ACTIVE_INTEGRATION_IMPORT = 'SET_ACTIVE_INTEGRATION_IMPORT';
export const SET_SIDE_PANEL_ERROR = 'SET_SIDE_PANEL_ERROR';

export interface ISetActiveIntegrationImport {
    payload: ActiveIntegrationImport | undefined;
    type: 'SET_ACTIVE_INTEGRATION_IMPORT';
}

export interface ISetSidePanelError {
    payload: boolean;
    type: 'SET_SIDE_PANEL_ERROR';
}

export type IntegrationsAction = ISetActiveIntegrationImport | ISetSidePanelError;

export const setActiveIntegrationImport = (
    activeIntegrationImport: ActiveIntegrationImport | undefined,
): ISetActiveIntegrationImport => ({
    payload: activeIntegrationImport,
    type: SET_ACTIVE_INTEGRATION_IMPORT,
});

export const setSidePanelError = (sidePanelError: boolean): ISetSidePanelError => ({
    payload: sidePanelError,
    type: SET_SIDE_PANEL_ERROR,
});
