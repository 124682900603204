import intersectionWith from 'lodash.intersectionwith';
import { createSelector } from 'reselect';

import { IAppState } from '@store/reducers';
import { getCurrentInbox } from './inbox';
import { Inbox, IProShipment, ProShipment } from '@packlink/packlink-sdk';

import { ShipmentUtils } from '@utils/ShipmentUtils';
import { IBulkState } from '@store/reducers/bulk';
import { BulkPagination } from '@store/actions/bulk';
import { FILTER_PARAMS, hasFilters } from '@constants/shipmentFilters';
import { partition } from 'lodash';

const getBulk = (store: IAppState): IBulkState => store.bulk;

export const getSelectedShipmentReferences = (store: IAppState): string[] => getBulk(store).selectedShipments;
const getSerializedShipments = (store: IAppState): IProShipment[] => getBulk(store).shipments;
export const getBulkPagination = (store: IAppState): BulkPagination => getBulk(store).pagination;
export const getBulkSortBy = (store: IAppState): IBulkState['sortBy'] => getBulk(store).sortBy;
export const getBulkFilters = (store: IAppState): IBulkState['filters'] => getBulk(store).filters;
export const getBulkFiltersFromPanel = createSelector(
    getBulkFilters,
    (filters?: Record<string, unknown>): IBulkState['filters'] =>
        filters &&
        Object.fromEntries(
            Object.entries(filters).filter(([key, _value]: [string, unknown]) => key !== FILTER_PARAMS.Q),
        ),
);

export const getHasPanelFilters = createSelector(
    getBulkFiltersFromPanel,
    (filters?: Record<string, unknown>): boolean => hasFilters(filters),
);

export const getIsCurrentInboxEmpty = createSelector(
    getSerializedShipments,
    (shipments: IProShipment[]): boolean => shipments.length === 0,
);

export const getIsBulkActionsActive = createSelector(
    getSelectedShipmentReferences,
    (shipmentReferences: string[]): boolean => shipmentReferences.length > 0,
);

export const getAppliedVouchers = (store: IAppState): IBulkState['appliedVouchers'] => getBulk(store).appliedVouchers;

export const getShipments = createSelector(getSerializedShipments, (shipments: IProShipment[]): ProShipment[] =>
    shipments.map(ShipmentUtils.proShipmentDeserialize),
);

export const getSelectedShipments = createSelector(
    getSerializedShipments,
    getSelectedShipmentReferences,
    getAppliedVouchers,
    (serializedShipments, shipmentReferences, appliedVouchers): ProShipment[] => {
        const selectedShipments = intersectionWith(
            serializedShipments,
            shipmentReferences,
            (shipment: IProShipment, shipmentReference: string): boolean =>
                shipment.data.packlinkReference === shipmentReference,
        );

        const shipments = selectedShipments.map((shipment) => {
            if (appliedVouchers[shipment.data.packlinkReference]) {
                return {
                    ...shipment,
                    data: {
                        ...shipment.data,
                        voucherName: appliedVouchers[shipment.data.packlinkReference].name,
                    },
                };
            }
            return shipment;
        });

        return shipments.map(ShipmentUtils.proShipmentDeserialize);
    },
);

export const getShipmentsToPay = (store: IAppState) => getBulk(store).shipmentsToPay;

export const getInboxesNotifications = (store: IAppState) => getBulk(store).inboxNotifications;

export const getIsPendingSectionInbox = (store: IAppState): boolean =>
    [Inbox.PENDING, Inbox.READY_TO_PURCHASE, Inbox.DRAFT].includes(getCurrentInbox(store));

export const hasPaymentAction = (store: IAppState): boolean =>
    [Inbox.READY_TO_PURCHASE].includes(getCurrentInbox(store));

export const hasPrintAction = (store: IAppState): boolean =>
    [Inbox.READY_FOR_SHIPPING].includes(getCurrentInbox(store));

const hasDraftAction = (store: IAppState): boolean => [Inbox.DRAFT].includes(getCurrentInbox(store));

export const hasInsuranceAction = createSelector(
    [hasDraftAction, hasPaymentAction],
    (hasDraftAction, hasPaymentAction) => hasDraftAction || hasPaymentAction,
);

export const getSelectedShipmentsByAvailableInsurance = createSelector(getSelectedShipments, (selectedShipments) =>
    partition(selectedShipments, (shipment) => !!shipment.data.service?.id),
);

export const hasActions = (store: IAppState): boolean =>
    getIsPendingSectionInbox(store) || hasPaymentAction(store) || hasPrintAction(store) || hasInsuranceAction(store);

export const isShipmentSelected =
    (shipmentReference: string): ((store: IAppState) => boolean) =>
    (store: IAppState): boolean =>
        getSelectedShipmentReferences(store).includes(shipmentReference);
