import { IconNames } from '@shipengine/giger-theme';
import { WithCommonProps } from '@shipengine/giger';
import { PickupSupportTicket } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import moment from 'moment';
import { SupportTicketSummarySection } from '../../SupportTicketSummarySection/SupportTicketSummarySection';

type PickUpTicketSummaryMessageProps = WithCommonProps<{
    data: PickupSupportTicket;
}>;

export function PickUpTicketSummaryMessage({ data, ...props }: PickUpTicketSummaryMessageProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <ul {...props}>
            {data.additionalComments && (
                <SupportTicketSummarySection
                    title={t('support-center.ticket-chat.ticket-summary-additional-comments-label')}
                    icon={IconNames.CONVERSATION}
                >
                    {data.additionalComments}
                </SupportTicketSummarySection>
            )}
            <SupportTicketSummarySection
                title={t('support-center.ticket-chat.ticket-summary-collection-date-label')}
                icon={IconNames.CALENDAR}
            >
                {moment(data.collectionDate).format('YYYY-MM-DD')}
            </SupportTicketSummarySection>
        </ul>
    );
}
