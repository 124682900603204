import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getArticleStyles = (): SerializedStyles =>
    css({
        width: '70%',
    });

export const getSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(4),
    });

export const getListStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(3),
    });

export const getImageStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        maxWidth: '100%',
    });
