import { Navigate, Route, Routes } from 'react-router';
import { APP_ROUTE } from '@router/routes';
import { OnboardingLayout } from './OnboardingLayout';
import { OnboardingSelectPlan } from './OnboardingSelectPlan';
import { OnboardingWelcome } from './OnboardingWelcome';
import { OnboardingRoute } from '../routes';
import { useTenantSubscriptionConfig } from '@Subscriptions/hooks/useClientSubscriptions';

export function OnboardingRouter(): JSX.Element {
    const { isTenantSubscriptionEnabled } = useTenantSubscriptionConfig();
    const firstOnboardingPage = isTenantSubscriptionEnabled
        ? APP_ROUTE.ONBOARDING.SELECT_PLAN
        : APP_ROUTE.ONBOARDING.WELCOME;

    return (
        <Routes>
            <Route element={<OnboardingLayout />}>
                <Route path="*" element={<Navigate to={firstOnboardingPage} />} />
                {isTenantSubscriptionEnabled && (
                    <Route index path={OnboardingRoute.SELECT_PLAN} element={<OnboardingSelectPlan />} />
                )}
                <Route path={OnboardingRoute.WELCOME} element={<OnboardingWelcome />} />
            </Route>
        </Routes>
    );
}
