import { TableHeader, TableHeaderCell, TableHeaderRow, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { getTicketColumnIdStyles } from './TicketTableHeaderStyles';

type TicketTableHeaderProps = WithCommonProps<unknown>;

export function TicketTableHeader(props: TicketTableHeaderProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <TableHeader {...props}>
            <TableHeaderRow>
                <TableHeaderCell css={getTicketColumnIdStyles}>
                    {t('support-center.tickets-table.table-header-id')}
                </TableHeaderCell>
                <TableHeaderCell>{t('support-center.tickets-table.table-header-shipment-reference')}</TableHeaderCell>
                <TableHeaderCell>{t('support-center.tickets-table.table-header-type')}</TableHeaderCell>
                <TableHeaderCell>{t('support-center.tickets-table.table-header-creation-date')}</TableHeaderCell>
                <TableHeaderCell>{t('support-center.tickets-table.table-header-updated-at')}</TableHeaderCell>
                <TableHeaderCell>{t('support-center.tickets-table.table-header-status')}</TableHeaderCell>
            </TableHeaderRow>
        </TableHeader>
    );
}
