import { AppRoute } from '@pages/router/routes';
import { ShipmentsRoute } from '@pages/shipments/routes';
import { Navigate, useParams } from 'react-router';

export const ShipmentDetailsOldRedirect = () => {
    const params = useParams();
    const path = `/${AppRoute.SHIPMENTS}/${params?.id}/${ShipmentsRoute.DETAILS}`;

    return <Navigate to={path} replace />;
};
