import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconPopover, IconSize, Typography } from '@shipengine/giger';
import { Currency } from '@packlink/packlink-sdk';

import { getOwnContractIconStyles } from './PaymentDetailsItemStyles';
import {
    getPaymentDetailsItemIconStyles,
    getPaymentDetailsItemLabelStyles,
    getPaymentDetailsItemStyles,
    getPaymentDetailsItemValueStyles,
} from './PaymentDetailsStyles';

export interface IPaymentDetailsItemProps {
    label: string;
    tooltip?: string;
    isOwnContract?: boolean;
    price?: number;
    quantity?: number;
    currency?: string;
    isTotal?: boolean;
}

export const PaymentDetailsItem = ({
    label,
    tooltip,
    price,
    currency,
    quantity,
    isOwnContract = false,
    isTotal = false,
}: IPaymentDetailsItemProps): JSX.Element => {
    const typographyVariant = isTotal ? 'body2' : 'small';
    return (
        <div css={getPaymentDetailsItemStyles(isTotal)}>
            <Typography component="p" variant={typographyVariant} css={getPaymentDetailsItemLabelStyles}>
                {label} {!!quantity && `(${quantity})`}
                {tooltip && (
                    <IconPopover
                        css={getPaymentDetailsItemIconStyles}
                        icon={IconNames.INFO}
                        size={IconSize.SIZE_SMALL}
                        placement="top"
                    >
                        <Typography variant="body2">{tooltip}</Typography>
                    </IconPopover>
                )}
            </Typography>
            <Typography component="p" variant={typographyVariant} css={getPaymentDetailsItemValueStyles}>
                {isOwnContract && (
                    <Icon css={getOwnContractIconStyles} name={IconNames.CONTRACT} size={IconSize.SIZE_SMALL} />
                )}
                {Currency.format(price || 0, currency)}
            </Typography>
        </div>
    );
};
