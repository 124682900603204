import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const infoPanelStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(6),
    });

export const spinnerStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    });
