import React, { useRef } from 'react';
import { useToggle } from '@packlink/utils';
import { Button, ButtonSize, ButtonVariant, PopoutPlacement, Typography, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

import { PopoverCallToAction } from '@components/PopoverCallToAction/PopoverCallToAction';

export interface IBulkDeleteProps {
    disabled: boolean;
    buttonVariant: ButtonVariant;
    numberSelectedShipments: number;
    placement?: PopoutPlacement;
    onDeleting: () => void;
    children?: React.ReactNode;
}

export const BulkDelete = (props: WithCommonProps<IBulkDeleteProps>): JSX.Element => {
    const { disabled, buttonVariant, numberSelectedShipments, onDeleting, placement, children, className } = props;
    const { t } = useTranslation();
    const { state: showPopover, toggle: toggleShowPopover } = useToggle(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleOnDeleteClick = (): void => {
        onDeleting();
        toggleShowPopover();
    };

    return (
        <>
            <Button
                disabled={disabled}
                onClick={toggleShowPopover}
                variant={buttonVariant}
                size={ButtonSize.SMALL}
                css={className}
                data-id="bulk-delete-button"
                ref={buttonRef}
            >
                {children ?? t('bulk-delete.actions.delete', { count: numberSelectedShipments })}
            </Button>
            <PopoverCallToAction
                reference={buttonRef.current}
                isOpen={!disabled && showPopover}
                onClickAway={toggleShowPopover}
                placement={placement}
                primaryText="bulk-delete.actions.confirm-delete"
                secondaryText="bulk-delete.actions.cancel"
                onPrimaryClick={handleOnDeleteClick}
                onSecondaryClick={toggleShowPopover}
            >
                <>
                    <Typography variant="body2">
                        {t('bulk-delete.body.text', { count: numberSelectedShipments })}
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                        {t('bulk-delete.body.caution')}
                    </Typography>
                </>
            </PopoverCallToAction>
        </>
    );
};
