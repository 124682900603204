import { useTranslation } from '@packlink/translation-provider';
import { BottomSheet, BottomSheetSize, Typography } from '@shipengine/giger';
import {
    getShipmentSummaryHeadingStyles,
    getShipmentSummaryTextStyles,
    getShipmentSummaryTotalStyles,
} from './BottomBarStyles';
import { useSelector } from 'react-redux';
import { getCheckoutPriceDetails } from '@store/selectors/checkout';
import { useFormatCurrency } from '@hooks';

interface IBottomBarProps {
    children: React.ReactNode;
}

export const BottomBar = ({ children }: IBottomBarProps) => {
    const { t } = useTranslation();
    const priceDetails = useSelector(getCheckoutPriceDetails);
    const totalPrice = useFormatCurrency(priceDetails?.price.currency ?? '', priceDetails?.price?.totalPrice || 0);
    const showPrice = !!priceDetails?.price?.totalPrice;

    return (
        <aside>
            <BottomSheet opened={false} size={showPrice ? BottomSheetSize.SIZE_LARGE : BottomSheetSize.SIZE_REGULAR}>
                <>
                    {showPrice && (
                        <>
                            <Typography component="p" variant="small" css={getShipmentSummaryTextStyles}>
                                {t('checkout.price-summary.total-price')}
                            </Typography>
                            <Typography component="p" variant="heading6" css={getShipmentSummaryTotalStyles}>
                                {totalPrice}
                            </Typography>
                        </>
                    )}
                    <Typography component="h5" variant="subtitle2" css={getShipmentSummaryHeadingStyles}>
                        {t('checkout.sidebar.summary.title')}
                    </Typography>
                    {children}
                </>
            </BottomSheet>
        </aside>
    );
};
