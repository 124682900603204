import { TranslatedDropOff, translateOpeningTimes } from '@utils/translateOpeningTimes';
import { useEffect, useState, useCallback } from 'react';
import Packlink from '@sdk';
import { Address, DropOff, IAddress, IDropOff, PointType } from '@packlink/packlink-sdk';
import BluebirdPromise from 'bluebird';
import { setCheckoutDropoffPointId } from '@store/actions/checkout';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store';

type IUseDropoffInfoProps = {
    origin: Address<IAddress>;
    serviceId: number | string;
    initialDropoffId?: string;
    onDialogClose: () => void;
};
interface IUseDropoffInfo {
    currentPoint?: TranslatedDropOff;
    onPudoSelection: (dropoffPointId: string) => void;
}

export const useDropoffInfo = ({
    origin,
    serviceId,
    initialDropoffId,
    onDialogClose,
}: IUseDropoffInfoProps): IUseDropoffInfo => {
    const [currentPoint, setCurrentPoint] = useState<TranslatedDropOff>();
    const dispatch = useDispatch<AppDispatch>();

    const changePoint = useCallback(
        (dropoffId: string) =>
            Packlink.v1.dropOffs
                .detail({
                    city: origin.city,
                    code: origin.alpha2Code,
                    pointId: dropoffId,
                    serviceId: String(serviceId),
                    pointsType: PointType.PICK_UP,
                    zip: origin?.zipCode,
                })
                .then(setCurrentPointFromDropOff),
        [origin.alpha2Code, origin.city, origin?.zipCode, serviceId],
    );

    const onPudoSelection = (dropoffPointId: string) => {
        if (dropoffPointId) {
            changePoint(dropoffPointId);
            onDialogClose();
        }
    };

    const setCurrentPointFromDropOff = (values: DropOff<IDropOff>) => {
        const openingTimes = translateOpeningTimes(values);
        setCurrentPoint(openingTimes);
    };

    useEffect(() => {
        let changePointCall: BluebirdPromise<void> | undefined;
        if (initialDropoffId) {
            changePointCall = changePoint(initialDropoffId);
            dispatch(setCheckoutDropoffPointId(initialDropoffId));
        }
        return (): void => {
            if (changePointCall && changePointCall.isPending()) {
                changePointCall.cancel();
            }
        };
    }, [initialDropoffId, changePoint, dispatch]);

    return { currentPoint, onPudoSelection };
};
