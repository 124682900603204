import { Skeleton, SkeletonAnimation, SkeletonBackgroundColor, SkeletonVariant, Typography } from '@shipengine/giger';
import {
    getLineWrapperStyles,
    getTaxCalculationTextStyles,
    getSkeletonStyles,
    getTotalSkeletonWrapperStyles,
    getTaxTotalStyles,
} from './SubscriptionPanelTaxCalculationStyles';

interface SubscriptionPanelTaxContentProps {
    label?: string;
    value?: string;
    isLoading: boolean;
    isDetailCard?: boolean;
}

export function SubscriptionPanelTaxContent({
    label,
    value,
    isLoading,
    isDetailCard,
}: SubscriptionPanelTaxContentProps): JSX.Element {
    return (
        <>
            {isLoading ? (
                <div css={isDetailCard ? getSkeletonStyles : getTotalSkeletonWrapperStyles}>
                    <Skeleton
                        variant={SkeletonVariant.TEXT}
                        animation={SkeletonAnimation.WAVE}
                        height={20}
                        backgroundColor={!isDetailCard ? SkeletonBackgroundColor.DARK : undefined}
                    />
                </div>
            ) : (
                <div css={isDetailCard ? getLineWrapperStyles : getTaxTotalStyles}>
                    <Typography css={getTaxCalculationTextStyles} component="p" variant="small" bold={!isDetailCard}>
                        {label}
                    </Typography>
                    <Typography css={getTaxCalculationTextStyles} component="p" variant="small" bold={!isDetailCard}>
                        {value}
                    </Typography>
                </div>
            )}
        </>
    );
}
