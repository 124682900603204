export enum ShipmentPanelType {
    BULK = 'BULK',
    CONTENT = 'CONTENT',
    OTHER_BENEFITS = 'OTHER_BENEFITS',
    RECIPIENT = 'RECIPIENT',
    SERVICES = 'SERVICES',
}

export enum Origin {
    FROM = 'from',
    TO = 'to',
}
