import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { getCookie, setCookie } from '@packlink/utils';
import { GTM_INITIAL_TRACK_COOKIE_NAME } from '@constants/gtm';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';

export function RouterTracker(): JSX.Element {
    const location = useLocation();
    const { sendVirtualPageViewGtmEvent } = useGoogleTagManager();

    useEffect(() => {
        if (getCookie(GTM_INITIAL_TRACK_COOKIE_NAME)) {
            const urlPath = location.pathname;
            sendVirtualPageViewGtmEvent(urlPath);
        } else {
            setCookie(GTM_INITIAL_TRACK_COOKIE_NAME, '1');
        }
    }, [location.pathname, sendVirtualPageViewGtmEvent]);

    return <></>;
}
