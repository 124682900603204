import { IInlineNotificationProps, InlineNotification, WithChildrenCommonProps } from '@shipengine/giger';
import { TicketChatMessageDate } from './TicketChatMessageDate';
import { TicketChatMessageWrapper, TicketChatMessageWrapperBulletPosition } from './TicketChatMessageWrapper';
import {
    getTicketChatNotificationContentStyles,
    getTicketChatNotificationMessageStyles,
} from './TicketChatNotificationStyles';

type TicketChatNotificationProps = WithChildrenCommonProps<{
    createdAt: string;
    hasDecorativeLine?: boolean;
}> &
    Pick<IInlineNotificationProps, 'title' | 'type'>;

export function TicketChatNotification({
    createdAt,
    hasDecorativeLine,
    children,
    title,
    type,
    ...props
}: TicketChatNotificationProps) {
    return (
        <TicketChatMessageWrapper
            hasDecorativeLine={hasDecorativeLine}
            bulletPosition={TicketChatMessageWrapperBulletPosition.CENTER}
            {...props}
        >
            <section css={getTicketChatNotificationContentStyles}>
                <InlineNotification css={getTicketChatNotificationMessageStyles} title={title} type={type}>
                    {children}
                </InlineNotification>
                <TicketChatMessageDate date={createdAt} />
            </section>
        </TicketChatMessageWrapper>
    );
}
