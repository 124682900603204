import { Field, getIn, useFormikContext } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CashOnDelivery, Currency } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { Grid, GridChild, Typography } from '@shipengine/giger';
import { FormField, Input } from '@shipengine/formik-giger';
import { getCashOnDeliveryFormStyles, getCashOnDeliveryPriceStyles } from './CashOnDeliveryFormStyles';
import { getDetailsFormHeadingStyles } from '@components/Checkout/DetailsStep/DetailsForm/DetailsFormStyles';
import { useFormatCurrency } from '@hooks';
import { ICashOnDeliveryForm, ICashOnDeliveryFormErrors } from './CashOnDelivery';
import { setCashOnDeliveryAction } from '@store/actions/checkout';
import { IDetailsStepForm } from '@components/Checkout/DetailsStep/DetailsStep';

export interface CashOnDeliveryFormProps {
    currency: string;
    price?: number;
    handler: (formValues: ICashOnDeliveryForm) => void;
}

export const CashOnDeliveryForm = (props: CashOnDeliveryFormProps): JSX.Element => {
    const { currency, price, handler } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { values, errors, handleBlur } = useFormikContext<IDetailsStepForm>();
    const codValues: ICashOnDeliveryForm = getIn(values, 'cashOnDelivery');
    const codErrors: ICashOnDeliveryFormErrors = getIn(errors, 'cashOnDelivery');

    const formatCodPrice = useFormatCurrency(currency, price);

    useEffect((): (() => void) => {
        const timer = setTimeout((): void => {
            const cod = CashOnDelivery.deserialize({
                accountHolder: codValues?.accountHolder,
                amount: codValues?.amount,
                iban: codValues?.iban,
            });
            dispatch(setCashOnDeliveryAction(cod));
        }, 200);

        return (): void => {
            clearTimeout(timer);
        };
    }, [codErrors, codValues, dispatch]);

    const handleFieldBlur = (e: ChangeEvent<HTMLInputElement>): void => {
        handleBlur(e);
        handler(codValues);
    };

    return (
        <>
            <Grid css={getCashOnDeliveryFormStyles}>
                <GridChild colSpan={6}>
                    <FormField name="cashOnDelivery.amount">
                        <Field
                            component={Input}
                            type="number"
                            name="cashOnDelivery.amount"
                            label={t('form.label.amount')}
                            rightContent={Currency.getCurrencySymbol(currency)}
                            onBlur={handleFieldBlur}
                        />
                    </FormField>
                </GridChild>
                <GridChild colSpan={6}>
                    <Typography variant="heading4" bold css={getCashOnDeliveryPriceStyles}>
                        {formatCodPrice}
                    </Typography>
                </GridChild>
            </Grid>

            <Typography css={getDetailsFormHeadingStyles} variant="heading5" component="h3" bold>
                {t('form.heading.cod')}
            </Typography>
            <FormField name="cashOnDelivery.accountHolder">
                <Field
                    component={Input}
                    name="cashOnDelivery.accountHolder"
                    label={t('form.label.account-holder')}
                    onBlur={handleFieldBlur}
                />
            </FormField>
            <FormField name="cashOnDelivery.iban">
                <Field
                    component={Input}
                    name="cashOnDelivery.iban"
                    label={t('form.label.iban')}
                    onBlur={handleFieldBlur}
                />
            </FormField>
        </>
    );
};
