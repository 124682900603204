export enum SettingsRoute {
    BILLING = 'billing',
    BRANDED_EMAILS = 'branded-emails',
    ADDRESSES = 'addresses',
    API_KEY = 'api-key',
    ARCHIVING = 'archiving',
    INTEGRATIONS = 'integrations',
    OWN_CONTRACTS = 'own-contracts',
    PARCELS = 'parcels',
    PRIVACY = 'privacy',
    SHIPPING_RULES = 'shipping-rules',
    YOUR_ACCOUNT = 'your-account',
    VOLUME_DISCOUNT = 'volume-discounts',
}
