import { FC } from 'react';
import { useLocation } from 'react-router';

import { IAmazonFormData } from '@Integrations/components/Integrations/Amazon/types';
import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { OnboardingProvider } from '@packlink/packlink-sdk';

import { ActivateStartFlow } from '../ActivateStartFlow';

export const AmazonActivateStartFlow: FC = (): JSX.Element => {
    const state = useLocation().state as null | { data: IAmazonFormData };

    return (
        <ActivateStartFlow
            provider={OnboardingProvider.AMAZON}
            integrationAlias={EcommerceAlias.Amazon}
            pathParam={'/' + state?.data.countryMarketplace}
        />
    );
};
