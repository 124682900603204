import { useCallback } from 'react';

import { BraintreeError } from 'braintree-web';

import { useToast } from '@shipengine/giger';
import { PaymentMethod } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { PaymentStep } from '@packlink/payment-options';

import { OnPayProps } from '@components/PaymentOptions/PaymentOptions';
import { DeferredPaymentsFormStatus, useDeferredPaymentFormStatus } from '@Invoicing/context/deferredPaymentFormStatus';
import { useSettingsPaymentDeferredActions } from '@Invoicing/hooks/useSettingsPaymentDeferredActions';

export const useSettingsPaymentDeferredHandlers = () => {
    const { t } = useTranslation();
    const toast = useToast(t);

    const { setDeferredPaymentFormStatus } = useDeferredPaymentFormStatus();
    const { saveDeferredPaymentMethod } = useSettingsPaymentDeferredActions();

    const onVerificationSuccess = ({ method, nonce }: OnPayProps) => {
        /**
         * If there is no verification that means we are using
         * a vault payment method
         */
        if (!nonce) {
            setDeferredPaymentFormStatus(DeferredPaymentsFormStatus.CLOSED);
            return;
        }

        saveDeferredPaymentMethod(nonce, method);
    };

    const onError = useCallback(
        (
            _step: PaymentStep,
            _paymentMethod?: PaymentMethod,
            error?: BraintreeError | Error,
            translationKey?: string,
        ) => {
            if (translationKey) {
                toast.error({ message: t(translationKey) });
            } else {
                toast.error({ message: `${t('settings.payment-deferred.braintree-error')}: ${error}` });
            }

            setDeferredPaymentFormStatus(DeferredPaymentsFormStatus.CLOSED);
        },
        [setDeferredPaymentFormStatus, t, toast],
    );

    return {
        onError,
        onVerificationSuccess,
    };
};
