import { useTranslation } from '@packlink/translation-provider';
import { BarTooltipProps, ChartTooltip } from '../Charts';
import { Typography } from '@shipengine/giger';
import { Currency, IShipmentsByCarrierResponse, ShipmentsByCarrierDataResponse } from '@packlink/packlink-sdk';

interface ITooltipProps {
    tooltip: BarTooltipProps<ShipmentsByCarrierDataResponse>;
    chartData: IShipmentsByCarrierResponse;
}

export function Tooltip({ tooltip, chartData }: ITooltipProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <ChartTooltip index={tooltip.index} numElements={chartData.data.length}>
            <Typography variant="small" bold>
                {tooltip.data.carrier}
            </Typography>
            <Typography component="div" variant="small">
                <>
                    {t('analytics.label.shipments')}: <strong>{tooltip.data.shipments}</strong>
                </>
            </Typography>
            <Typography component="div" variant="small">
                <>
                    {t('analytics.label.percentage')}: <strong>{tooltip.data.percentage}%</strong>
                </>
            </Typography>
            <Typography component="div" variant="small">
                <>
                    {t('analytics.label.avg-cost')}:{' '}
                    <strong>{Currency.format(tooltip.data.averageCost || 0, chartData.currency)}</strong>
                </>
            </Typography>
        </ChartTooltip>
    );
}
