import { CSSObject, css } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getLoadingStyles = (): CSSObject => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
});

export const getSkipCustomsNotificationStyles = (theme: Theme) =>
    css({
        marginBottom: theme.spacing(3),
    });
