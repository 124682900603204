import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export function getParcelListStyles(theme: Theme): SerializedStyles {
    return css({
        margin: 0,
        color: theme.palette.gray.dark,
    });
}

export function getParcelTitleStyles(theme: Theme): SerializedStyles {
    return css({
        marginBottom: theme.spacing(0.5),
    });
}

export function getParcelDescriptionStyles(theme: Theme): SerializedStyles {
    return css({
        margin: 0,
        marginBottom: theme.spacing(0.5),
    });
}
