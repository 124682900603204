import { css, SerializedStyles } from '@emotion/react';
import { SlideInPosition } from '@shipengine/giger';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';

export const getIntegrationPanelStyles = (): SerializedStyles => {
    return css({
        ...getSidePanelClipPathStyles(SlideInPosition.RIGHT),

        // TODO extract header and footer from ${Ecommerce}IntegrationForm to be able to reuse them directly as SidePanel props
        '> section': {
            display: 'flex',
            flexDirection: 'column',
        },
    });
};
