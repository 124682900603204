import { ISelectAutocompleteOption } from '@shipengine/giger';
import { IFormItem } from '../types/formTypes';

export const EMPTY_ITEM: ISelectAutocompleteOption = {
    value: '',
    label: '',
};

export const EMPTY_CONDITION: IFormItem = {
    identifier: EMPTY_ITEM,
    operation: EMPTY_ITEM,
    value: EMPTY_ITEM,
};

export const EMPTY_ACTION: IFormItem = {
    ...EMPTY_CONDITION,
    operation: {
        value: 'ASSIGN',
        label: '',
    },
};

export const FORM_QUERIES_STALE_TIME = 300000; // 5 mins

export enum ActionOps {
    OVERWRITE = 'OVERWRITE',
    ASSIGN = 'ASSIGN',
}
