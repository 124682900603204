import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    IAddressBook,
    IRecipientAddressList,
    IRecipientListParams,
    RecipientAddressesRepository,
} from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { IAddressesContext } from '../context/addresses';

const recipientAddressesRepository = new RecipientAddressesRepository(apiClient);
const RECIPIENT_ADDRESSES_KEY = 'recipient-addresses';
const ADDRESSES_PAGE_SIZE = 9;

function fetchRecipientAddresses(params: IRecipientListParams) {
    return recipientAddressesRepository.getWithPostalCodeInfo(params);
}

function getRecipientAddressesCacheKey(params: Omit<IRecipientListParams, 'size'>) {
    return [RECIPIENT_ADDRESSES_KEY, ADDRESSES_PAGE_SIZE, params];
}
interface IUseRecipientAddressesProps {
    setNumberOfAddresses?: IAddressesContext['setNumberOfAddresses'];
    isEnabled?: boolean;
}

type UseRecipientAddresses = ReturnType<typeof useRecipientAddresses>;

export function useRecipientAddresses({ setNumberOfAddresses, isEnabled = true }: IUseRecipientAddressesProps) {
    const [requestParams, setRequestParams] = useState<IRecipientListParams>({ page: 0, size: ADDRESSES_PAGE_SIZE });

    const { data: addresses, isLoading } = useQuery(
        getRecipientAddressesCacheKey(requestParams),
        () => fetchRecipientAddresses(requestParams),
        {
            onSuccess: (addresses) => setNumberOfAddresses?.(addresses.pagination.totalElements),
            keepPreviousData: true,
            enabled: isEnabled,
        },
    );

    const changeRequestParams = useCallback((params: Partial<IRecipientListParams>) => {
        setRequestParams((prevParams) => ({ ...prevParams, ...params }));
    }, []);

    return {
        addresses,
        isLoading,
        requestParams,
        changeRequestParams,
    };
}

export function useMutateRecipientAddress(
    requestParams: IRecipientListParams,
    changeRequestParams?: UseRecipientAddresses['changeRequestParams'],
) {
    const queryClient = useQueryClient();

    const { mutateAsync: saveAddress } = useMutation(
        (address: IAddressBook) =>
            address.id ? recipientAddressesRepository.update(address) : recipientAddressesRepository.create(address),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(getRecipientAddressesCacheKey(requestParams));
            },
        },
    );

    const { mutate: deleteAddress } = useMutation((id: string) => recipientAddressesRepository.delete(id), {
        onMutate: async (id) => {
            await queryClient.cancelQueries(getRecipientAddressesCacheKey(requestParams));

            const previousAddresses = queryClient.getQueryData<IRecipientAddressList>(
                getRecipientAddressesCacheKey(requestParams),
            );

            if (previousAddresses) {
                queryClient.setQueryData<IRecipientAddressList | undefined>(
                    getRecipientAddressesCacheKey(requestParams),
                    {
                        ...previousAddresses,
                        content: previousAddresses.content.filter((address) => address.id !== id),
                    },
                );
            }

            return { previousAddresses };
        },
        onError: (_err, _variables, context?: { previousAddresses?: IRecipientAddressList }) => {
            queryClient.setQueryData<IRecipientAddressList | undefined>(
                getRecipientAddressesCacheKey(requestParams),
                context?.previousAddresses,
            );
        },
        onSettled: (_data, _err, _variables, context?: { previousAddresses?: IRecipientAddressList }) => {
            if (context?.previousAddresses?.content.length === 1 && requestParams.page > 0) {
                // Request the previous page if the last element of a page is deleted
                const previousPage = requestParams.page - 1;
                changeRequestParams?.({ page: previousPage });
                queryClient.invalidateQueries(getRecipientAddressesCacheKey({ ...requestParams, page: previousPage }));
            } else {
                queryClient.invalidateQueries(getRecipientAddressesCacheKey(requestParams));
            }
        },
    });

    return {
        saveAddress,
        deleteAddress,
    };
}
