import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        borderRadius: theme.borderRadius.S,
        border: `1px solid ${theme.palette.gray.light}`,
        padding: theme.spacing(3),
        maxWidth: 899,
    });

export const getHeaderStyles = (): SerializedStyles =>
    css({
        margin: 0,
    });
