import { useEffect, useState } from 'react';

import { ICountry } from '@packlink/packlink-sdk';
import { Grid, GridChild, Typography } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

import { LocationUtils } from '@utils/LocationUtils';

import {
    getListStyles,
    getWrapperStyles,
    getTitleStyles,
    getGridChildrenStyles,
} from './SettingsClientBusinessDataStyles';

interface ISettingsClientBusinessDataProps {
    taxId?: string;
    country?: string;
    alpha2Code?: string;
}

export const SettingsClientBusinessData = ({
    country,
    alpha2Code,
    taxId,
}: ISettingsClientBusinessDataProps): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const [clientCountry, setClientCountry] = useState<string | undefined>(undefined);

    useEffect((): void => {
        if (country) {
            setClientCountry(country);

            return;
        }

        LocationUtils.getAllCountries(locale).then((response: ICountry[]) => {
            setClientCountry(response.find((country: ICountry): boolean => country.alpha2Code === alpha2Code)?.name);
        });
    }, [locale, alpha2Code, country]);

    return (
        <div css={getWrapperStyles}>
            <Typography component="h4" variant="heading4" css={getTitleStyles}>
                {t('billing-details.business-data.taxes-title')}
            </Typography>

            <Grid css={getListStyles}>
                <GridChild colStart={0} colSpan={4} css={getGridChildrenStyles('left')}>
                    {t('billing-details.business-data.customer-type')}:{' '}
                    {t('billing-details.business-data.business-title')}
                </GridChild>

                <GridChild colStart={5} colSpan={4} css={getGridChildrenStyles('center')}>
                    {t('billing-details.business-data.country-title')}: {clientCountry}
                </GridChild>

                <GridChild colStart={9} colSpan={4} css={getGridChildrenStyles('right')}>
                    {t('billing-details.business-data.company-cif')}: {taxId}
                </GridChild>
            </Grid>
        </div>
    );
};
