import { Formik, FormikProps } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { ConfigurationType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { AliexpressIntegrationForm } from './AliexpressIntegrationForm';
import { AliexpressConfiguration, AliexpressIntegrationPanelContentProps, IAliexpressFormData } from './types';

export const AliexpressIntegrationPanel: FC<AliexpressIntegrationPanelContentProps> = (
    props: AliexpressIntegrationPanelContentProps,
): JSX.Element => {
    const { t } = useTranslation();
    const { onClose, integration, onDeactivate, onSubmit, isLoading, showError, clearError, onCancel } = props;

    const aliexpressConfiguration = integration.configuration;

    const defaultConfiguration: IAliexpressFormData = {
        token: aliexpressConfiguration?.data.token || '',
        api_key: aliexpressConfiguration?.data.api_key || '',
        app_secret: aliexpressConfiguration?.data.app_secret || '',
    };

    const aliexpressOnSubmit = (formData: IAliexpressFormData): void => {
        const aliexpressConfiguration: AliexpressConfiguration = {
            identifier: integration.configuration?.identifier || '',
            integration: EcommerceAlias.Aliexpress,
            type: ConfigurationType.INBOUND,
            data: { ...formData },
        };

        const aliexpressIntegration = { ...integration, configuration: aliexpressConfiguration };
        onSubmit(aliexpressIntegration);
    };

    const validationSchema = yup.object().shape({
        token: yup.string().required(t('form.error.required', { field: t('integrations.aliexpress.token') })),
        api_key: yup.string().required(t('form.error.required', { field: t('integrations.aliexpress.api-key') })),
        app_secret: yup.string().required(t('form.error.required', { field: t('integrations.aliexpress.app-secret') })),
    });

    return (
        <Formik
            onSubmit={aliexpressOnSubmit}
            initialValues={defaultConfiguration}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<IAliexpressFormData>): React.ReactNode => (
                <AliexpressIntegrationForm
                    onCancel={onCancel}
                    onClose={onClose}
                    formProps={formProps}
                    isLoading={isLoading}
                    integration={integration}
                    onDeactivate={onDeactivate}
                    showError={showError}
                    clearError={clearError}
                />
            )}
        </Formik>
    );
};
