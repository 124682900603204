import { useTranslation, Trans } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';

import { InfoPanel, IInfoPanelProps } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { IntegrationGuide } from '@Integrations/components/IntegrationGuide/IntegrationGuide';
import { IntegrationVideo } from '@Integrations/components/IntegrationVideo/IntegrationVideo';

import { getIntegrationVideoStyles } from './IntegrationGuideVideoStyles';
import { IntegrationGuideName } from './types';

type BigCommerceIntegrationGuideType = Omit<IInfoPanelProps, 'title' | 'collapsible'>;

export const BigCommerceIntegrationGuide: React.FC<BigCommerceIntegrationGuideType> = ({
    collapsed,
}: BigCommerceIntegrationGuideType): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const basePath = `${config.cdn.url}/pro/statics/videos/onboarding/${IntegrationGuideName.BigCommerce}`;
    const subtitleFallbackPath = `${config.cdn.url}/pro/statics/videos/onboarding/${IntegrationGuideName.BigCommerce}/en-GB.vtt`;

    return (
        <>
            <IntegrationVideo
                css={getIntegrationVideoStyles(collapsed)}
                src={`${basePath}/video.mp4`}
                subtitles={`${basePath}/${locale}.vtt`}
                fallbackSubtitles={subtitleFallbackPath}
                integrationName={IntegrationGuideName.BigCommerce}
            />
            <IntegrationGuide>
                <InfoPanel title={t('integrations.guide.title')} collapsible={true} collapsed={collapsed}>
                    <InfoPanelStepList>
                        <InfoPanelStep title={t('integrations.guide.install-configure-module')}>
                            <Typography component="p">
                                <Trans
                                    i18nKey="integrations.bigcommerce.add-app"
                                    values={{ integrationName: IntegrationGuideName.BigCommerce }}
                                />
                            </Typography>
                        </InfoPanelStep>
                        <InfoPanelStep title={t('integrations.guide.account-sync')}>
                            <ul>
                                <li>
                                    <Trans i18nKey="integrations.guide.default-information" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.bigcommerce.select-shipping" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.bigcommerce.enter-carrier" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.bigcommerce.configure-price" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.bigcommerce.shipping-zone-settings" />
                                </li>
                            </ul>
                        </InfoPanelStep>
                        <InfoPanelStep title={t('integrations.guide.use-module')}>
                            <Typography component="p">
                                <Trans i18nKey="integrations.guide.access-packlink-pro" />
                            </Typography>
                        </InfoPanelStep>
                    </InfoPanelStepList>
                </InfoPanel>
            </IntegrationGuide>
        </>
    );
};
