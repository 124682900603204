import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { Typography } from '@shipengine/giger';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { Track } from '@components/Video/Track';
import { Video } from '@components/Video/Video';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { ITenantConfig } from '@types';
import { useAmplitude } from '@hooks/useAmplitude';
import { getArticleStyles, getImageStyles, getListStyles, getSectionStyles } from './HowItWorksTabStyles';
import { useAvailableLanguages } from '@hooks';

export function HowItWorksTab(): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const { shippingRulesDemo } = useTenantConfig<ITenantConfig>();

    const { availableLanguages } = useAvailableLanguages();
    const {
        cdn: { url: cdnUrl },
    } = config;
    const subtitlesPath = `${cdnUrl}/pro/statics/videos/shipping-rules/subtitles`;
    const videoSrc = !isEmpty(shippingRulesDemo)
        ? `${cdnUrl}${shippingRulesDemo[locale] || shippingRulesDemo['en-GB']}`
        : undefined;

    const sendDuration = useCallback(
        (duration: number) => {
            if (duration > 0) {
                sendAmplitudeEvent(AmplitudeEvents.SHIPPING_RULES_VIDEO, {
                    [AmplitudeProperties.DURATION]: duration,
                });
            }
        },
        [sendAmplitudeEvent],
    );

    const getImageUrl = (position: number): string =>
        `${cdnUrl}/pro/statics/images/how-shipping-rules-works${position}.png`;

    return (
        <article css={getArticleStyles}>
            <Typography component="h2" variant="heading4">
                {t('shipping-rules.tutorial.title')}
            </Typography>

            <section css={getSectionStyles}>
                <Typography component="h3" variant="heading5">
                    {t('shipping-rules.tutorial.first-title')}
                </Typography>
                <Typography component="p">
                    <Trans i18nKey="shipping-rules.tutorial.first-paragraph" />
                </Typography>
                {videoSrc && (
                    <Video onCleanup={sendDuration} src={videoSrc} data-id="shipping-rules-video">
                        {availableLanguages.map((lang) => (
                            <Track
                                key={lang.locale}
                                kind={lang.locale === 'en-GB' ? 'captions' : 'subtitles'}
                                srcLang={lang.locale}
                                label={lang.language}
                                src={`${subtitlesPath}/${lang.locale}/demo-packlinkpro.vtt`}
                                fallbackSrc={`${subtitlesPath}/en-GB/demo-packlinkpro.vtt`}
                                default={lang.locale === locale}
                            />
                        ))}
                    </Video>
                )}
            </section>

            <section css={getSectionStyles}>
                <Typography component="h3" variant="heading5">
                    {t('shipping-rules.tutorial.second-title')}
                </Typography>
                <Typography component="p">
                    <Trans i18nKey="shipping-rules.tutorial.second-paragraph" />
                </Typography>
            </section>

            <section css={getSectionStyles}>
                <Typography component="h3" variant="heading5">
                    {t('shipping-rules.tutorial.third-title')}
                </Typography>
                <Typography component="p">
                    <Trans i18nKey="shipping-rules.tutorial.third-paragraph" />
                </Typography>
                <Typography component="p">
                    <Trans i18nKey="shipping-rules.tutorial.third-paragraph-list" />
                </Typography>
                <div css={getListStyles}>
                    <ul>
                        <li>
                            <Trans i18nKey="shipping-rules.tutorial.condition-postcode" />
                        </li>
                        <li>
                            <Trans i18nKey="shipping-rules.tutorial.action-cex" />
                        </li>
                    </ul>
                </div>
                <img
                    css={getImageStyles}
                    src={getImageUrl(1)}
                    alt={t('shipping-rules.tutorial.third-paragraph-list')}
                />

                <Typography component="p">
                    <Trans i18nKey="shipping-rules.tutorial.fourth-paragraph" />
                </Typography>
                <div css={getListStyles}>
                    <ul>
                        <li>
                            <Trans i18nKey="shipping-rules.tutorial.condition-postcode" />
                        </li>
                        <li>
                            <Trans i18nKey="shipping-rules.tutorial.action-address" />
                        </li>
                    </ul>
                </div>
                <img css={getImageStyles} src={getImageUrl(2)} alt={t('shipping-rules.tutorial.fourth-paragraph')} />

                <Typography component="p">
                    <Trans i18nKey="shipping-rules.tutorial.fifth-paragraph" />
                </Typography>
                <div css={getListStyles}>
                    <ul>
                        <li>
                            <Trans i18nKey="shipping-rules.tutorial.condition-shopify" />
                        </li>
                        <li>
                            <Trans i18nKey="shipping-rules.tutorial.action-insurance" />
                        </li>
                    </ul>
                </div>
                <img css={getImageStyles} src={getImageUrl(3)} alt={t('shipping-rules.tutorial.fifth-paragraph')} />

                <Typography component="p">
                    <Trans i18nKey="shipping-rules.tutorial.support" />
                </Typography>
            </section>
        </article>
    );
}
