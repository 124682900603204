import { useState } from 'react';
import { Trans, useTranslation } from '@packlink/translation-provider';

import { UserPolicies } from '@packlink/packlink-sdk';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { Grid, GridChild, Next, Radio, RadioGroup, useToast } from '@shipengine/giger';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';

import { ITenantConfig } from '@types';

import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';

import { PrivacyWithdrawalModal } from './PrivacyWithdrawalModal';
import { getFormSectionStyles, getMarketingSectionStyles, getSectionTitleStyles } from './PrivacySettingsStyles';
import { useUserPolicies } from '@User/hooks/useUserPolicies';

export const PrivacySettings = (): JSX.Element => {
    const { t } = useTranslation();
    const toast = useToast(t);

    const { userPolicies, updateUserPolicies, isUpdateUserPoliciesLoading } = useUserPolicies();
    const getHelpCenterPage = useHelpCenter();
    const { allowMarketingCalls } = useTenantConfig<ITenantConfig>();

    const [showPrivacyWithdrawalModal, setShowPrivacyWithdrawalModal] = useState(false);

    const handleInputChange = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
        const updatedPolicies = UserPolicies.deserialize({
            ...userPolicies?.toJSON(),
            [name]: value === 'true',
        });

        updateUserPolicies(updatedPolicies, {
            onSuccess: () => {
                toast.success({ message: t('privacy-settings.save.success') });
            },
            onError: () => {
                toast.error({ message: t('privacy-settings.save.fail') });
            },
        });
    };

    return (
        <>
            {showPrivacyWithdrawalModal && (
                <PrivacyWithdrawalModal closeModalHandler={() => setShowPrivacyWithdrawalModal(false)} />
            )}

            <SettingsPage>
                <SettingsPageHeader>{t('privacy.settings.title')}</SettingsPageHeader>

                <Grid noPadding>
                    <GridChild colSpan={6} rowStart={1} css={getFormSectionStyles}>
                        <h5 css={getSectionTitleStyles}>{t('privacy-settings.heading.treatment-data')}</h5>

                        <p>
                            <Trans
                                i18nKey="privacy-settings.description.data-processing"
                                components={[
                                    <Next.Link
                                        key="link"
                                        target="_blank"
                                        href={getHelpCenterPage(HELP_CENTER_KEYS.privacyPolicy)}
                                    />,
                                ]}
                            />
                        </p>
                        <RadioGroup
                            disabled={isUpdateUserPoliciesLoading}
                            name="dataProcessing"
                            value={userPolicies?.dataProcessing?.toString()}
                            onChange={() => setShowPrivacyWithdrawalModal(true)}
                        >
                            <Radio value="true">{t('privacy-settings.input-value.yes')}</Radio>
                            <Radio value="false">{t('privacy-settings.input-value.no')}</Radio>
                        </RadioGroup>
                    </GridChild>

                    <GridChild colSpan={6} rowStart={2} css={getFormSectionStyles}>
                        <h5 css={getSectionTitleStyles}>{t('privacy-settings.heading.contact-preferences')}</h5>

                        <p>{t('privacy-settings.description.marketing-emails')}</p>
                        <RadioGroup
                            disabled={isUpdateUserPoliciesLoading}
                            name="marketingEmails"
                            value={userPolicies?.marketingEmails?.toString()}
                            onChange={handleInputChange}
                        >
                            <Radio value="true">{t('privacy-settings.input-value.yes')}</Radio>
                            <Radio value="false">{t('privacy-settings.input-value.no')}</Radio>
                        </RadioGroup>

                        {allowMarketingCalls && (
                            <>
                                <p css={getMarketingSectionStyles}>
                                    {t('privacy-settings.description.marketing-calls')}
                                </p>
                                <RadioGroup
                                    disabled={isUpdateUserPoliciesLoading}
                                    name="marketingCalls"
                                    value={userPolicies?.marketingCalls?.toString()}
                                    onChange={handleInputChange}
                                >
                                    <Radio value="true">{t('privacy-settings.input-value.yes')}</Radio>
                                    <Radio value="false">{t('privacy-settings.input-value.no')}</Radio>
                                </RadioGroup>
                            </>
                        )}
                    </GridChild>

                    <GridChild colSpan={6} rowStart={3} css={getFormSectionStyles}>
                        <h5 css={getSectionTitleStyles}>{t('privacy-settings.heading.manage-data')}</h5>

                        <p>
                            <Trans
                                i18nKey="privacy-settings.description.data-manage"
                                values={{ email: config.privacyEmail }}
                                components={[<Next.Link key="link" href={`mailto:${config.privacyEmail}`} />]}
                            />
                        </p>
                    </GridChild>
                </Grid>
            </SettingsPage>
        </>
    );
};
