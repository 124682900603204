import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Address } from '@packlink/packlink-sdk';
import { Typography } from '@shipengine/giger';
import { Origin } from '@types';
import { useTranslation } from '@packlink/translation-provider';
import { ILocationFormField, LocationForm } from '@components/LocationForm/LocationForm';
import { AppDispatch } from '@store';
import { setCheckoutAddressDetails } from '@store/actions/checkout';
import { getCheckoutAdditionalData, getCheckoutService, getCheckoutSource } from '@store/selectors/checkout';

import { getUserAddressHeadingStyles } from './UserAddressStyles';
import { IDetailsForm } from '../DetailsForm/DetailsForm';
import { parseAddress } from '../../utils/address';

export const getUserAddressInitialValues = (
    address: Address,
    initialPostalInfo: { postalZoneId: string; postalCodeId: string },
): IDetailsForm => ({
    id: address?.id || '',
    alpha2Code: address?.alpha2Code || '',
    company: address?.company || '',
    email: address?.email || '',
    firstName: address?.firstName || '',
    lastName: address?.lastName || '',
    phone: address?.phone || '',
    street1: address?.street1 || '',
    zipCode: address?.zipCode || '',
    city: address?.city || '',
    country:
        address?.state && initialPostalInfo.postalZoneId
            ? {
                  label: address.state,
                  value: initialPostalInfo.postalZoneId,
              }
            : undefined,
    postalCode:
        address?.zipCode && address?.city && initialPostalInfo.postalCodeId
            ? {
                  label: `${address.zipCode} - ${address.city}`,
                  value: initialPostalInfo.postalCodeId,
              }
            : undefined,
});

export interface IUserAddressProps {
    origin: Origin;
}

export const UserAddress: React.FC<IUserAddressProps> = ({ origin }: IUserAddressProps): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const source = useSelector(getCheckoutSource);
    const additionalData = useSelector(getCheckoutAdditionalData);
    const selectedService = useSelector(getCheckoutService);
    const hideEmail = source?.includes('mirakl_');
    const initialPostalCodeId = additionalData[`zip_code_id_${origin.toLowerCase()}`] as string;
    const initialPostalZoneId = additionalData[`postal_zone_id_${origin.toLowerCase()}`] as string;
    const hasPickUp = selectedService?.dropOff?.destination;

    const handleChange = useCallback(
        (address: ILocationFormField): void => {
            dispatch(setCheckoutAddressDetails(parseAddress(address as Address), origin));
        },
        [dispatch, origin],
    );

    return (
        <>
            <Typography css={getUserAddressHeadingStyles} variant="heading5" component="h3" bold>
                {t(`user-address.title.${origin}`)}
            </Typography>
            <LocationForm
                hasPickUp={hasPickUp}
                hideEmail={hideEmail}
                initialPostalCodeId={initialPostalCodeId}
                initialPostalZoneId={initialPostalZoneId}
                name={origin}
                isPostalSelectorsDisabled={true}
                onHandleChange={handleChange}
            />
        </>
    );
};
