import React from 'react';
import { WithCommonProps } from '@shipengine/giger';
import { getClipboardCopierStyles } from './ClipboardCopierStyles';

interface IClipboardCopier {
    value: string;
    onClick?: () => void;
    children: React.ReactNode;
}

/**
 * This will be `false` in local environment because `navigator.clipboard` is only available in
 * secure origins. You can configure your local environment as secure in your browser
 * (i.e. chrome://flags/#unsafely-treat-insecure-origin-as-secure add http://pro.packitos.es:13002)
 */
export const browserSupportClipboardWritting = (): boolean => !!navigator?.clipboard?.writeText;

export const copyToClipboardAction = (value: string): Promise<void> => navigator?.clipboard?.writeText(value);

export const ClipboardCopier: React.FC<WithCommonProps<IClipboardCopier>> = ({
    value,
    onClick,
    children,
    ...rest
}: IClipboardCopier): JSX.Element | null => {
    if (!browserSupportClipboardWritting()) {
        return null;
    }

    const copyToClipboard = (): void => {
        copyToClipboardAction(value);
        onClick?.();
    };

    return (
        <div css={getClipboardCopierStyles} onClick={copyToClipboard} role="button" {...rest}>
            {children}
        </div>
    );
};
