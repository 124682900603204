import { useEffect, useState } from 'react';
import Packlink from '@sdk';
import { useLocation } from 'react-router';

export const useDebugMode = (): boolean => {
    const [active, setActive] = useState(false);
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const feature = urlParams.get('ft');

    useEffect(() => {
        if (feature === 'DM') {
            Packlink.v1.debug.isAvailable().then((debugModeAvailable: boolean) => {
                setActive(debugModeAvailable);
            });
        }
    }, [feature]);

    return active;
};
