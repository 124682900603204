import { useTenantBehavior } from '@packlink/tenant-config-provider';
import { ServiceSearchRestrictionBasedOnThirdPartyPreferencesType } from '@types';

export function useThirdPartyPreferencesBehavior(source?: string) {
    const getTenantBehavior = useTenantBehavior();
    const thirdPartyPreferencesSources = getTenantBehavior<ServiceSearchRestrictionBasedOnThirdPartyPreferencesType>(
        'ServiceSearchRestrictionBasedOnThirdPartyPreferences',
    )?.properties.sources;
    const thirdPartyPreferencesEnabled = source && thirdPartyPreferencesSources?.includes(source.toLowerCase());

    function getThirdPartyPreferencesEnabled(source: string) {
        return thirdPartyPreferencesSources?.includes(source);
    }

    return {
        thirdPartyPreferencesEnabled,
        getThirdPartyPreferencesEnabled,
    };
}
