import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonSize, ButtonVariant } from '@shipengine/giger';
import { EmptyState } from '@components/EmptyState/EmptyState';
import { getEmptyListButtonSectionStyles } from '../ShipmentListStyles';

export interface IFilterEmptyStateProps {
    editFilters: () => void;
    resetFilters: () => void;
}

export const FilterEmptyState = (props: IFilterEmptyStateProps): JSX.Element => {
    const { editFilters, resetFilters } = props;
    const { t } = useTranslation();
    const {
        cdn: { url: cdnUrl },
    } = config;
    const image = `${cdnUrl}/pro/statics/images/no-results.png`;

    return (
        <EmptyState title={t('filters.empty-list.title')} subtitle={t('filters.empty-list.subtitle')} image={image}>
            <section css={getEmptyListButtonSectionStyles}>
                <Button size={ButtonSize.SMALL} onClick={editFilters}>
                    {t('filters.button.edit-filters')}
                </Button>
                <Button variant={ButtonVariant.OUTLINED} size={ButtonSize.SMALL} onClick={resetFilters}>
                    {t('filters.button.clear-filters')}
                </Button>
            </section>
        </EmptyState>
    );
};
