import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getStatusStyles =
    (status: string, clickable = false) =>
    (theme: Theme): SerializedStyles => {
        const statusColorMap: Record<string, string> = {
            'awaiting-completion': theme.palette.error.main,
            'ready-to-purchase': theme.palette.info.main,
            'call-to-action': theme.palette.primary.main,
            'create-shipment': theme.palette.primary.main,
            'purchase-success': theme.palette.alert.main,
            retry: theme.palette.alert.main,
            incident: theme.palette.error.main,
            'returned-to-sender': theme.palette.gray.dark,
            'out-for-delivery': theme.palette.primary.dark,
            'carrier-ok': theme.palette.alert.main,
            'carrier-ko': theme.palette.alert.main,
            'carrier-pending': theme.palette.alert.main,
            'ready-to-print': theme.palette.success.main,
            'ready-for-collection': theme.palette.success.main,
            'in-transit': theme.palette.alert.main,
            delivered: theme.palette.success.main,
            canceled: theme.palette.gray.main,
            'cancel-requested': theme.palette.gray.main,
        };
        return css({
            color: statusColorMap[status],
            cursor: clickable ? 'pointer' : 'inherit',
            display: 'flex',
            textTransform: 'uppercase',
            marginBottom: 0,
        });
    };

export const getStatusIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
        marginTop: theme.spacing(0.25),
    });
