import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const ruleRowSwitchStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing(3)}px 0`,
    });

export const ruleRowOrderStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',

        button: {
            background: 'transparent',
            border: 'transparent',
        },

        'button:first-of-type': {
            paddingRight: 0,
            paddingLeft: 0,
        },
    });
