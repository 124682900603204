import { FC, ReactNode } from 'react';
import { Typography } from '@shipengine/giger';

import { getSidePanelContentNoteTitleStyles } from './SidePanelContentNoteStyles';

export interface ISidePanelContentNoteProps {
    children: ReactNode;
    title: string;
}

export const SidePanelContentNote: FC<ISidePanelContentNoteProps> = ({
    children,
    title,
}: ISidePanelContentNoteProps): JSX.Element => {
    return (
        <>
            <Typography component="p" variant="body2" bold css={getSidePanelContentNoteTitleStyles}>
                {title}
            </Typography>
            <Typography component="p" variant="body2">
                {children}
            </Typography>
        </>
    );
};
