import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { BraintreeError } from 'braintree-web';
import {
    IPrice,
    Order,
    PaymentClientMethod,
    PaymentMethod,
    PaymentType,
    ProPrice,
    ProShipment,
    Shipment,
} from '@packlink/packlink-sdk';
import { PaymentStep } from '@packlink/payment-options';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { getAppliedVouchers } from '@store/selectors/bulk';
import { ITenantConfig } from '@types';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { getDirectOwnContract } from '@store/selectors/payment';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { OnPayProps } from '@components/PaymentOptions/PaymentOptions';
import { useAmplitude } from '@hooks/useAmplitude';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { getPaymentErrors, PaymentApiError, PaymentError } from '@utils/error';
import { useCreateOrder } from '@common/hooks/useCreateOrder';

interface IUseBulkPaymentSubmitParams {
    selectedShipments: ProShipment[];
    priceDetail?: ProPrice;
    hasAmount: boolean;
    deferred?: PaymentClientMethod;
    setGenericError: Dispatch<SetStateAction<string | undefined>>;
    setIsPaying: Dispatch<SetStateAction<boolean>>;
    onPaymentSuccess: (order: Order) => void;
    isCarriersTermsAccepted?: boolean;
}

export function useBulkPaymentSubmit({
    selectedShipments,
    priceDetail,
    hasAmount,
    deferred,
    onPaymentSuccess,
    setIsPaying,
    setGenericError,
    isCarriersTermsAccepted,
}: IUseBulkPaymentSubmitParams) {
    const [errorKeys, setErrorKeys] = useState<PaymentError[]>();
    const vouchers = useSelector(getAppliedVouchers);
    const { closePanel } = useShipmentPanel();
    const { hasPrintInStoreByDefault: defaultPrintInStoreValue } = useTenantConfig<ITenantConfig>();
    const { sendPurchaseGtmEvent, sendCheckoutStepGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const ownContract = useSelector(getDirectOwnContract);

    const getGtmShipmentsInfo = useCallback(
        (shipmentsPriceDetails?: IPrice[]) => {
            return selectedShipments.map(({ data }) => {
                const price = shipmentsPriceDetails
                    ? shipmentsPriceDetails.find(
                          (shipmentPriceDetail) => shipmentPriceDetail.packlinkReference === data.packlinkReference,
                      )?.price
                    : data.price?.price;

                return {
                    service: { ...data.service, price },
                    from: data.from,
                    to: data.to,
                    packages: data.parcels,
                    insurance: !!data.upsales?.insurance?.available,
                    source: data.source,
                };
            });
        },
        [selectedShipments],
    );

    const onTryAgain = () => {
        setIsPaying(false);
        setGenericError(undefined);
        setErrorKeys(undefined);
    };

    const handlePaymentError = useCallback(
        (
            _step: PaymentStep,
            _paymentMethod?: PaymentMethod,
            _error?: BraintreeError | Error,
            translationKey?: string,
        ): void => {
            translationKey && setErrorKeys([{ key: translationKey }]);
            setIsPaying(false);
        },
        [setIsPaying],
    );

    const redirectToPostsale = (order: Order, paymentMethod: PaymentMethod): void => {
        sendPurchaseGtmEvent({
            purchaseId: order.orderReference,
            paymentMethod,
            purchaseDate: new Date().toISOString(),
            shipments: getGtmShipmentsInfo(),
            price: priceDetail?.total.price,
        });

        onPaymentSuccess(order);
        closePanel(false);
    };

    const { createOrder } = useCreateOrder('side panel');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        let payment: { type?: PaymentType; method?: PaymentMethod } | undefined;
        const areShipmentsOwnContract = selectedShipments.every(
            (s: ProShipment): boolean => !!s.data.service?.isOwnContract,
        );

        const isFullDiscount = Object.values(vouchers).every((v) => v.percentage === 100);

        if (!hasAmount && ownContract && areShipmentsOwnContract) {
            payment = ownContract;
        } else if (deferred) {
            payment = deferred;
        } else if (!hasAmount && isFullDiscount) {
            payment = {
                type: PaymentType.DIRECT,
                method: PaymentMethod.FREE,
            };
        } else {
            return;
        }

        sendOrder({
            type: payment?.type as PaymentType,
            method: payment?.method as PaymentMethod,
            carriersTermsAndConditions: isCarriersTermsAccepted,
        });
    };

    const sendOrder = ({ type, method, nonce, metrics, deviceData, carriersTermsAndConditions }: OnPayProps): void => {
        const forcePrintInStore = (shipment: ProShipment): Shipment => {
            return ShipmentUtils.updatePrintInStore(shipment.data, defaultPrintInStoreValue);
        };

        sendAmplitudeSidebarClickEvent(AmplitudeEvents.PAY, {
            [AmplitudeProperties.PAYMENT_METHOD]: method,
            [AmplitudeProperties.PAYMENT_TYPE]: type,
        });

        sendCheckoutStepGtmEvent({
            stepNumber: 3,
            paymentMethod: method,
            actionFieldOption: GTMActionFieldOption.QUICK_CHECKOUT,
            shipments: getGtmShipmentsInfo(),
        });
        setIsPaying(true);

        const mutationEvents = {
            onSuccess: (order: Order) => redirectToPostsale(order, method),
            onError: (error: { response: { data: { messages: PaymentApiError[] } } }) => {
                setIsPaying(false);
                const errorMessages: PaymentApiError[] = error?.response?.data?.messages as PaymentApiError[];
                const errorKeys = getPaymentErrors(errorMessages);
                setErrorKeys(errorKeys);
            },
        };
        createOrder(
            selectedShipments.map(forcePrintInStore),
            { type, method },
            nonce,
            metrics,
            deviceData,
            mutationEvents,
            carriersTermsAndConditions,
        );
    };

    return {
        errorKeys,
        onTryAgain,
        sendOrder,
        handleSubmit,
        handlePaymentError,
    };
}
