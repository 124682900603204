import { useCallback } from 'react';

import { PrintingFormat } from '@packlink/packlink-sdk';
import { WithCommonProps } from '@shipengine/giger';
import { ButtonAction, DownloadLabel } from '@components/DownloadLabel/DownloadLabel';
import { DownloadLabelDialog } from '@components/DownloadLabel/DownloadLabelDialog';
import { useDialogDownloadLabel } from '@components/DownloadLabel/hooks/useDialogDownloadLabel';
import { useDownloadLabel } from '@components/DownloadLabel/hooks/useDownloadLabel';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';

type PrintDownloadLabelProps = WithCommonProps<{
    packlinkReference: string;
    label?: string;
    qrLabel?: string;
}>;

export function PrintDownloadLabel({
    packlinkReference,
    label,
    qrLabel,
    ...props
}: PrintDownloadLabelProps): JSX.Element {
    const { sendAmplitudeClickEvent } = useAmplitude();

    const onClickAction = useCallback(
        (action: ButtonAction, eventProps: Record<string, unknown>) => {
            const actionEvent =
                action === ButtonAction.PRINT ? AmplitudeEvents.PRINT_LABEL : AmplitudeEvents.DOWNLOAD_LABEL;
            const eventName = `${actionEvent} ${AmplitudeEventPrefixes.CONFIRMATION_PAGE}` as AmplitudeEvents;

            sendAmplitudeClickEvent(eventName, eventProps);
        },
        [sendAmplitudeClickEvent],
    );

    const { closeDialog, onFinish, onError, onFetching, status } = useDialogDownloadLabel({ onClick: onClickAction });

    const { bulkPrintingFormat, buttonAction, fetchLabels, onChangePrintingFormat } = useDownloadLabel({
        onFetching,
        onError,
        onFinish,
    });

    const isPrintAction = buttonAction === ButtonAction.PRINT;

    const handleChangePrintingFormat = (printingFormat: PrintingFormat) => {
        const eventName =
            `${AmplitudeEvents.LABEL_FORMAT} ${printingFormat} ${AmplitudeEventPrefixes.CONFIRMATION_PAGE}` as AmplitudeEvents;
        sendAmplitudeClickEvent(eventName);
        onChangePrintingFormat(printingFormat);
    };

    return (
        <>
            <DownloadLabel
                disabled={!packlinkReference}
                download={true}
                fetchLabels={fetchLabels}
                printingFormat={bulkPrintingFormat}
                onChangePrintingFormat={handleChangePrintingFormat}
                shipmentReferences={[packlinkReference]}
                isFullWidth={true}
                label={label}
                qrLabel={qrLabel}
                {...props}
            />
            <DownloadLabelDialog
                status={status}
                isPrintAction={isPrintAction}
                numberShipments={1}
                onCloseDialog={closeDialog}
            />
        </>
    );
}
