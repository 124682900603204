import { getIntegrationFormItemStyle } from './IntegrationFormItemStyles';

export enum IntegrationFormItemSpacingSize {
    None = 0,
    Small = 1,
    Regular = 2,
    Big = 5,
}

export interface IIntegrationFormItem {
    size?: IntegrationFormItemSpacingSize;
    children: React.ReactNode;
}

export const IntegrationFormItem: React.FC<IIntegrationFormItem> = ({
    children,
    size = IntegrationFormItemSpacingSize.Regular,
}: IIntegrationFormItem) => {
    return <div css={getIntegrationFormItemStyle(size)}>{children}</div>;
};
