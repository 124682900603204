import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getActionsStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '* + *': {
            margin: 0,
        },
    });

export const getInsuranceInfoStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getInsuranceSelectorSectionStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
    });
