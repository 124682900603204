import { useFormikContext } from 'formik';

import { AmplitudeEvents, AmplitudeProperties, FLOW_AMPLITUDE_PROPERTY } from '@constants/amplitude';
import { AmplitudeTypeOfLocations } from '@packlink/metrics';
import { AppDispatch } from '@store';
import { FormField } from '@shipengine/formik-giger';
import { IconNames } from '@shipengine/giger-theme';
import { InsuranceSelector } from '@packlink/insurance-selector';
import { ProductOptionsInsurances } from '@utils/UpsellUtils';
import { Typography, Icon } from '@shipengine/giger';
import { UpsellInsuranceType } from '@types';
import { getCheckoutInsuranceType } from '@store/selectors/checkout';
import { setCheckoutInsuranceType } from '@store/actions/checkout';
import { useAmplitude } from '@hooks/useAmplitude';
import { useFormatCurrency } from '@hooks/useFormatCurrency';
import { useRefreshPrice } from '@components/Checkout/hooks/useRefreshPrice';
import { useTranslation } from '@packlink/translation-provider';

import { getDetailsFormHeadingStyles } from '../DetailsForm/DetailsFormStyles';
import { getInsuranceSubtitleTextStyles } from './InsuranceSectionStyles';
import { useDispatch, useSelector } from 'react-redux';
import { IDetailsStepForm } from '../DetailsStep';

interface InsuranceSectionProps {
    availableInsurances: ProductOptionsInsurances;
    baseCoverage: number;
    contentValue: number;
    currency: string;
    isSecondHand: boolean;
    hasProofOfDelivery: boolean;
}

export const InsuranceSection = ({
    availableInsurances,
    baseCoverage,
    contentValue,
    currency,
    isSecondHand,
    hasProofOfDelivery,
}: InsuranceSectionProps): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const { refreshPrice } = useRefreshPrice();
    const dispatch = useDispatch<AppDispatch>();
    const { setFieldValue } = useFormikContext<IDetailsStepForm>();

    const insuranceType = useSelector(getCheckoutInsuranceType);

    const {
        cdn: { url: cdnUrl },
    } = config;
    const isShipmentUnprotected = !insuranceType || insuranceType === UpsellInsuranceType.NO_INSURANCE;
    const isEnhancedOrStandardAvailable = !!availableInsurances.ENHANCED || !!availableInsurances.STANDARD;

    const insuredValue = isShipmentUnprotected
        ? Math.min(baseCoverage, contentValue)
        : (availableInsurances[insuranceType as UpsellInsuranceType]?.insured ?? 0);
    const insuredValueFormatted = useFormatCurrency(currency, insuredValue);
    const isFullyProtected = insuredValue >= contentValue;

    const handleInsuranceChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        if (value !== undefined) {
            setFieldValue('insuranceType', value);
            dispatch(setCheckoutInsuranceType(UpsellInsuranceType[value as UpsellInsuranceType]));
            sendAmplitudeEvent(AmplitudeEvents.CLICK_ON_INSURANCE, {
                [AmplitudeProperties.INSURANCE]: value,
                [AmplitudeProperties.FLOW]: FLOW_AMPLITUDE_PROPERTY.MANUAL_CHECKOUT,
                [AmplitudeProperties.LOCATION]: AmplitudeTypeOfLocations.ADDRESS_STEP,
            });
            refreshPrice();
        }
    };

    return (
        <>
            <Typography css={getDetailsFormHeadingStyles} variant="heading5" component="h3" bold>
                {t('details-form.protection.title')}
            </Typography>

            <Typography variant="body2" component="p" css={getInsuranceSubtitleTextStyles}>
                <Icon
                    name={
                        isEnhancedOrStandardAvailable || !isShipmentUnprotected
                            ? IconNames.SHIELD
                            : IconNames.SHIELD_DISABLED
                    }
                />
                {isEnhancedOrStandardAvailable
                    ? isFullyProtected
                        ? t('details-form.protection.protected-full', { number: insuredValueFormatted })
                        : t('details-form.protection.protected-up-to', {
                              number: insuredValueFormatted,
                          })
                    : isShipmentUnprotected
                      ? t('details-form.protection.no-protection')
                      : t('details-form.protection.protected')}
            </Typography>

            <FormField name="insuranceType">
                <InsuranceSelector
                    currency={currency}
                    name="insuranceType"
                    imageUrl={`${cdnUrl}/pro/statics/images/insurance-box.svg`}
                    onChange={handleInsuranceChange}
                    contentValue={contentValue}
                    insurancesToDisplay={availableInsurances}
                    insuranceTypeSelected={insuranceType}
                    isSecondHand={isSecondHand}
                    showInsuranceInformation={false}
                    useHalfWidth
                    hasProofOfDelivery={hasProofOfDelivery}
                />
            </FormField>
        </>
    );
};
