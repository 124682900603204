import BluebirdPromise from 'bluebird';
import Packlink from '@sdk';
import { IShipment, Shipment, ProPrice, ApiClientError } from '@packlink/packlink-sdk';
import { logSdkError } from '@utils/logger';

export class PriceUtils {
    static getPriceDetails(shipment: IShipment, contentValue?: number): BluebirdPromise<ProPrice | undefined> {
        const newShipmentData: IShipment = {
            ...shipment,
            content: {
                ...shipment.content,
                value: contentValue || shipment.content.value,
            },
        };

        return Packlink.pro.shipments.price
            .get([Shipment.deserialize(newShipmentData)])
            .catch((error: ApiClientError): undefined => {
                logSdkError(error);
                return;
            });
    }
}
