import { useCallback } from 'react';

import { AmplitudeEvents } from '@constants/amplitude';
import { ActiveIntegrationImport } from '@Integrations/types/integration';
import { Dialog, DialogContent, DialogHeader } from '@shipengine/giger';
import { Trans, useTranslation } from '@packlink/translation-provider';

import { getImportOrdersImageStyles, getImportOrdersResultImageStyles } from './ImportOrdersModalStyles';
import { UnauthorizedImportOrder } from './UnauthorizedImportOrder';
import { useAmplitude } from '@hooks/useAmplitude';

export enum ImportOrdersType {
    IMPORTING = 'importing',
    IMPORT_ENDED = 'import-ended',
    ERROR = 'error',
    UNAUTHORIZED_ERROR = 'unauthorized-error',
}

export enum ImportOrdersSubtype {
    GENERIC = 'GENERIC',
    INVALID_APP_KEY_ERROR = 'INVALID_APP_KEY',
    SUSPENDED_ACCOUNT = 'SUSPENDED_ACCOUNT',
}

interface IImportOrdersModalProps {
    onClose: () => void;
    type: ImportOrdersType;
    integration: ActiveIntegrationImport;
    numberOfOrders?: number;
    subtype?: ImportOrdersSubtype;
}

interface IImportOrdersModal {
    title: string;
    description?: string;
    content?: JSX.Element;
    image?: JSX.Element;
}

export const ImportOrdersModal = ({
    integration,
    onClose,
    type,
    numberOfOrders,
    subtype,
}: IImportOrdersModalProps): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const { name } = integration;

    const baseUrlImage = `${config.cdn.url}/pro/statics/animations/import-orders`;

    const modalInformation: IImportOrdersModal = {
        [ImportOrdersType.IMPORTING]: {
            title: t('import-modal.header.importing', { integration: name }),
            description: t('import-modal.content.importing'),
            image: <img src={`${baseUrlImage}/importing.gif`} alt={''} css={getImportOrdersImageStyles} />,
        },
        [ImportOrdersType.IMPORT_ENDED]: {
            title: t('import-modal.header.import-finish'),
            description: t('import-modal.content.import-finish', {
                number: numberOfOrders,
                integration: name,
            }),
            image: <img src={`${baseUrlImage}/import-ok.gif`} alt={''} css={getImportOrdersResultImageStyles} />,
        },
        [ImportOrdersType.ERROR]: {
            title: t('import-modal.header.error'),
            description: 'import-modal.content.error',
            image: <img src={`${baseUrlImage}/we-are-sorry.gif`} alt={''} css={getImportOrdersResultImageStyles} />,
        },
        [ImportOrdersType.UNAUTHORIZED_ERROR]: {
            title: t('import-modal.header.unhautorized-error', { integration: name }),
            content: <UnauthorizedImportOrder onClose={onClose} integration={integration} subtype={subtype} />,
        },
    }[type];

    const handleOnClose = useCallback(() => {
        sendAmplitudeEvent(AmplitudeEvents.CLOSE_INTEGRATION_IMPORT_ORDER_NOTIFICATION);
        onClose();
    }, [onClose, sendAmplitudeEvent]);

    return (
        <Dialog isOpen={!!type}>
            <DialogHeader
                title={modalInformation.title}
                showClose={true}
                closeAriaLabel={t('import-modal.header.close')}
                onClose={handleOnClose}
            />
            <DialogContent>
                {modalInformation.content ? (
                    modalInformation.content
                ) : (
                    <>
                        {modalInformation.image}
                        <p>
                            <Trans>{modalInformation.description}</Trans>
                        </p>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};
