import { Theme } from '@shipengine/giger-theme';
import { css } from '@emotion/react';

export function getOnboardingLayoutStyles(theme: Theme) {
    return css({
        padding: theme.spacing(4),
    });
}

export function getLogoWrapperStyles(theme: Theme) {
    return css({
        display: 'none',

        [theme.mediaQuery('tablet')]: {
            display: 'inline-block',
        },
    });
}

export function getOnboardingLogoStyles(theme: Theme) {
    return css({
        height: theme.spacing(6),
    });
}

export function getHeadingStyles(theme: Theme) {
    return css({
        display: 'inline-block',
        color: theme.palette.primary.main,
        textAlign: 'center',
        width: '100%',
    });
}

export function getOnboardingPageContainerStyles() {
    return css({
        height: '75vh',
        width: '100%',
    });
}
