import { useTranslation } from '@packlink/translation-provider';
import { IAddress } from '@packlink/packlink-sdk';
import {
    getSelectedAddressStyles,
    getSelectedAddressSubtitleStyles,
    getSelectedAddressTextStyles,
    getSelectedAddressTitleStyles,
} from './SelectedAddressStyles';
import { Typography } from '@shipengine/giger';

interface ISelectedAddressProps {
    address: IAddress;
}

export function SelectedAddress({ address }: ISelectedAddressProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <article css={getSelectedAddressStyles}>
            {address.firstName && (
                <Typography css={getSelectedAddressTitleStyles} variant="body1" bold component="h3">
                    {address.firstName}
                </Typography>
            )}
            <Typography css={getSelectedAddressSubtitleStyles} variant="small" bold component="h3">
                {t('COUNTRY')}
            </Typography>
            <Typography css={getSelectedAddressTextStyles} variant="small" component="p">
                {address.state}
            </Typography>
            <Typography css={getSelectedAddressSubtitleStyles} variant="small" bold component="h3">
                {t('POSTAL_CODE')} - {t('AREA')}
            </Typography>
            <Typography css={getSelectedAddressTextStyles} variant="small" component="p">
                {address.zipCode} - {address.city}
            </Typography>
        </article>
    );
}
