import React from 'react';
import { IconNames, Theme } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { ButtonIcon, ButtonSize, ButtonVariant, Typography } from '@shipengine/giger';
import { getCustomHeaderStyles, getCustomHeaderTitleStyles } from './SidePanelCustomHeaderStyles';
import { SerializedStyles } from '@emotion/react';
import { getTitleCase } from '@utils/titleCase';

interface SidePanelCustomHeaderProps {
    title: string;
    onClickAction?: () => void;
    actionIcon?: IconNames;
    cssHeader?: (theme: Theme) => SerializedStyles;
}

export const SidePanelCustomHeader: React.FC<SidePanelCustomHeaderProps> = (
    props: SidePanelCustomHeaderProps,
): JSX.Element => {
    const { title, onClickAction, actionIcon = IconNames.CLOSE, cssHeader } = props;
    const { t } = useTranslation();

    return (
        <header css={getCustomHeaderStyles(!!title, cssHeader)}>
            <Typography css={getCustomHeaderTitleStyles} variant="heading5" component="h5">
                {getTitleCase(title)}
            </Typography>
            {onClickAction && actionIcon && (
                <ButtonIcon
                    ariaDescribedby={t('sidebar.action.close')}
                    icon={actionIcon}
                    size={ButtonSize.LARGE}
                    variant={ButtonVariant.TEXT}
                    onClick={onClickAction}
                >
                    {t('sidebar.action.close')}
                </ButtonIcon>
            )}
        </header>
    );
};
