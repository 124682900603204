import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getSavingLoadingStyles = (theme: Theme): SerializedStyles =>
    css({
        width: '100%',
        marginBottom: theme.spacing(2),
    });

export const getInfoMessageStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
    });
