import React from 'react';

import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';

import {
    Button,
    ButtonColor,
    ButtonSize,
    ButtonVariant,
    Icon,
    IconSize,
    IPopoverProps,
    Popover,
} from '@shipengine/giger';

import { getButtonStyles, getContentStyles, getIconStyles, getPopoverStyles } from './PopoverCallToActionStyles';

interface IPopoverCallToActionProps extends IPopoverProps {
    icon?: IconNames;
    primaryText: string;
    secondaryText: string;
    children: React.ReactNode;
    primaryDataId?: string;
    secondaryDataId?: string;
    onPrimaryClick: () => void;
    onSecondaryClick: () => void;
}

export const PopoverCallToAction = ({
    icon = IconNames.ALERT,
    primaryText,
    secondaryText,
    children,
    onPrimaryClick,
    onSecondaryClick,
    primaryDataId,
    secondaryDataId,
    ...otherProps
}: IPopoverCallToActionProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Popover {...otherProps} css={getPopoverStyles}>
            <section css={getContentStyles}>
                <Icon css={getIconStyles} name={icon} size={IconSize.SIZE_SMALL} />
                <section>
                    {children}

                    <section>
                        <Button
                            data-id={primaryDataId}
                            onClick={onPrimaryClick}
                            color={ButtonColor.ERROR}
                            size={ButtonSize.SMALL}
                            variant={ButtonVariant.TEXT}
                        >
                            {t(primaryText)}
                        </Button>
                        <Button
                            data-id={secondaryDataId}
                            isInactive
                            css={getButtonStyles}
                            onClick={onSecondaryClick}
                            color={ButtonColor.SECONDARY}
                            size={ButtonSize.SMALL}
                            variant={ButtonVariant.TEXT}
                        >
                            {t(secondaryText)}
                        </Button>
                    </section>
                </section>
            </section>
        </Popover>
    );
};
