import { WithCommonProps, EmptyState as GigerEmptyState } from '@shipengine/giger';
import { getEmptyStateStyles } from './EmptyStateStyles';

interface IEmptyStateProps {
    title?: string;
    alt?: string;
    subtitle: JSX.Element | string;
    image?: string;
    imageBlackAndWhite?: boolean;
}

export interface IEmptyStateChildrenProps {
    onNewShipment: (clickText: string, sectionId: string) => void;
    onConnectShop: (clickText: string, sectionId: string) => void;
}

export function EmptyState({
    title,
    alt = title,
    subtitle,
    image,
    imageBlackAndWhite,
    children,
    ...rest
}: WithCommonProps<IEmptyStateProps>): JSX.Element {
    return (
        <GigerEmptyState
            title={title || ''}
            alt={alt}
            src={image}
            subtitle={subtitle}
            imageBlackAndWhite={imageBlackAndWhite}
            css={getEmptyStateStyles}
            isElevated={false}
            {...rest}
        >
            {children}
        </GigerEmptyState>
    );
}
