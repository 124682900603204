import { Typography } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { getNumbersHeadingStyles, getNumbersStyles, getNumbersWrapperStyles } from './TrackingReferenceStyles';

export interface TrackingReferenceProps {
    trackingNumber: string;
    shipmentRef: string;
}

export function TrackingReference({ trackingNumber, shipmentRef }: TrackingReferenceProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <div css={getNumbersWrapperStyles}>
            <div css={getNumbersStyles}>
                <Typography component="h4" variant="body1" bold css={getNumbersHeadingStyles}>
                    {t('branded-emails.template.tracking-number')}
                </Typography>
                <Typography component="p" variant="body1">
                    {trackingNumber}
                </Typography>
            </div>
            <div css={getNumbersStyles}>
                <Typography component="h4" variant="body1" bold css={getNumbersHeadingStyles}>
                    {t('branded-emails.template.shipment-ref')}
                </Typography>
                <Typography component="p" variant="body1">
                    {shipmentRef}
                </Typography>
            </div>
        </div>
    );
}
