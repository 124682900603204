import pullAll from 'lodash.pullall';
import pullAllWith from 'lodash.pullallwith';
import { omit } from 'lodash';

import { ShipmentUtils } from '@utils/ShipmentUtils';
import { IProShipment, IPagination, ShipmentsOrderBy, Inbox, IVoucher, ProShipment } from '@packlink/packlink-sdk';

import { BulkAction } from '@store/actions/bulk';
import {
    ADD_SELECTED_SHIPMENT,
    ADD_SELECTED_SHIPMENTS,
    CLEAN_SELECTED_SHIPMENTS,
    REMOVE_SELECTED_SHIPMENT,
    REMOVE_SELECTED_SHIPMENTS,
    ADD_SHIPMENT,
    ADD_SHIPMENT_INBOX_NOTIFICATION,
    DELETE_SHIPMENT_INBOX_NOTIFICATION,
    CLEAN_SHIPMENTS,
    SET_SELECTED_SHIPMENTS,
    SET_SHIPMENTS,
    SET_SHIPMENTS_TO_PAY,
    SET_BULK_PAGINATION,
    SELECT_ALL_SHIPMENTS,
    REMOVE_SHIPMENT,
    UPDATE_SHIPMENT,
    SET_BULK_SORT_BY,
    SET_BULK_FILTERS,
    SET_BULK_VOUCHER,
    REMOVE_SHIPMENTS_TO_PAY,
} from '@store/actions/action-types';

export interface IBulkState extends IPagination {
    selectedShipments: string[];
    shipments: IProShipment[];
    shipmentsToPay: string[];
    isLoading: boolean;
    filters?: Record<string, unknown>;
    sortBy?: ShipmentsOrderBy;
    inboxNotifications: Partial<Record<Inbox, Record<string, boolean>>>;
    appliedVouchers: Record<string, IVoucher>;
}

export const initialState: IBulkState = {
    selectedShipments: [],
    shipments: [],
    shipmentsToPay: [],
    isLoading: true,
    pagination: {
        currentPage: 0,
        totalElements: 0,
        totalPages: 0,
    },
    filters: undefined,
    sortBy: undefined,
    inboxNotifications: {},
    appliedVouchers: {},
};

const updateShipment = (shipments: IProShipment[], shipmentToUpdate: ProShipment): IProShipment[] => {
    return shipments.map((shipment: IProShipment): IProShipment => {
        if (shipment.data.packlinkReference !== shipmentToUpdate.data.packlinkReference) {
            return shipment;
        }

        return {
            ...shipment,
            ...ShipmentUtils.proShipmentToJSON(shipmentToUpdate),
        };
    });
};

const addSelectedShipments = (selectedShipments: string[], shipmentReferences: string[]): string[] => {
    const selectedShipmentsResult = selectedShipments.slice();
    shipmentReferences.forEach((shipmentReference: string): void => {
        const addShipmentReferenece = !selectedShipmentsResult.includes(shipmentReference);
        if (addShipmentReferenece) {
            selectedShipmentsResult.push(shipmentReference);
        }
    });
    return selectedShipmentsResult;
};

const removeShipments = (shipments: IProShipment[], shipmentReferences: string[]): IProShipment[] =>
    pullAllWith(
        shipments.slice(),
        shipmentReferences,
        (shipment: IProShipment, shipmentReference: string): boolean =>
            shipment.data.packlinkReference === shipmentReference,
    );

export const reducer = (state: IBulkState = initialState, action: BulkAction): IBulkState => {
    switch (action.type) {
        case ADD_SELECTED_SHIPMENT:
            return {
                ...state,
                selectedShipments: addSelectedShipments(state.selectedShipments, [action.payload]),
            };
        case ADD_SELECTED_SHIPMENTS:
            return {
                ...state,
                selectedShipments: addSelectedShipments(state.selectedShipments, action.payload),
            };
        case ADD_SHIPMENT:
            return {
                ...state,
                shipments: action.payload.addOnTop
                    ? [ShipmentUtils.proShipmentToJSON(action.payload.shipment), ...state.shipments]
                    : [...state.shipments, ShipmentUtils.proShipmentToJSON(action.payload.shipment)],
            };
        case CLEAN_SELECTED_SHIPMENTS:
            return {
                ...state,
                selectedShipments: [],
            };
        case CLEAN_SHIPMENTS:
            return {
                ...state,
                selectedShipments: [],
                shipments: [],
                pagination: {
                    currentPage: 0,
                    totalElements: 0,
                    totalPages: 0,
                },
            };
        case REMOVE_SELECTED_SHIPMENT:
            return {
                ...state,
                selectedShipments: pullAll(state.selectedShipments.slice(), [action.payload]),
            };
        case REMOVE_SELECTED_SHIPMENTS:
            return {
                ...state,
                selectedShipments: pullAll(state.selectedShipments.slice(), action.payload),
            };
        case REMOVE_SHIPMENT:
            return {
                ...state,
                selectedShipments: pullAll(state.selectedShipments.slice(), [action.payload]),
                shipments: removeShipments(state.shipments, [action.payload]),
            };
        case SELECT_ALL_SHIPMENTS:
            return {
                ...state,
                selectedShipments: state.shipments.map(
                    (shipment: IProShipment): string => shipment.data.packlinkReference,
                ),
            };
        case SET_SELECTED_SHIPMENTS:
            return {
                ...state,
                selectedShipments: action.payload,
            };
        case SET_SHIPMENTS:
            return {
                ...state,
                shipments: action.payload.map(ShipmentUtils.proShipmentToJSON),
            };
        case SET_SHIPMENTS_TO_PAY:
            return {
                ...state,
                shipmentsToPay: action.payload,
            };
        case REMOVE_SHIPMENTS_TO_PAY:
            return {
                ...state,
                shipmentsToPay: [],
            };
        case UPDATE_SHIPMENT:
            return {
                ...state,
                shipments: updateShipment(state.shipments, action.payload),
            };
        case SET_BULK_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
            };
        case SET_BULK_SORT_BY:
            return {
                ...state,
                sortBy: action.payload,
            };
        case SET_BULK_FILTERS:
            return {
                ...state,
                filters: action.payload,
            };
        case SET_BULK_VOUCHER:
            return {
                ...state,
                appliedVouchers: action.payload,
            };
        case ADD_SHIPMENT_INBOX_NOTIFICATION:
            return {
                ...state,
                inboxNotifications: {
                    ...state.inboxNotifications,
                    [action.payload.inbox]: {
                        ...state.inboxNotifications[action.payload.inbox],
                        [action.payload.shipmentRef]: true,
                    },
                },
            };
        case DELETE_SHIPMENT_INBOX_NOTIFICATION:
            return {
                ...state,
                inboxNotifications: {
                    [action.payload.inbox]: {
                        ...omit(state.inboxNotifications[action.payload.inbox], action.payload.shipmentRef),
                    },
                },
            };
        default:
            return state;
    }
};
