import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { Checkbox } from '@shipengine/giger';
import { UpsellServiceType } from '@types';
import { ProductOptionsServices } from '@utils/UpsellUtils';
import { AmplitudeEvents, AmplitudeProperties, FLOW_AMPLITUDE_PROPERTY } from '@constants/amplitude';
import { IOtherBenefitsForm } from './ShipmentPanelOtherBenefits';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { otherBenefitsStyles } from './OtherBenefitsServicesStyles';
import { useAmplitude } from '@hooks/useAmplitude';

interface IOtherBenefitsServicesProps {
    services: ProductOptionsServices;
}

export const OtherBenefitsServices: React.FC<IOtherBenefitsServicesProps> = (
    props: IOtherBenefitsServicesProps,
): JSX.Element => {
    const { services } = props;
    const { values, handleChange } = useFormikContext<IOtherBenefitsForm>();
    const { t } = useTranslation();
    const { sendAmplitudeSidebarClickEvent, sendAmplitudeClickEvent } = useAmplitude();

    const sendPanelEvent = (data: Record<string, unknown>): void => {
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.OTHER_BENEFITS_PANEL, data);
    };

    const onChangeService = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const {
            target: { checked, value },
        } = e;

        sendPanelEvent({
            [AmplitudeProperties.ADDITIONAL_SERVICE]: value,
            [AmplitudeProperties.SELECTED]: checked,
        });

        if (value === UpsellServiceType.ADULT_SIGNATURE) {
            sendAmplitudeClickEvent(AmplitudeEvents.ADULT_SIGNATURE, {
                [AmplitudeProperties.SELECTED]: checked,
                [AmplitudeProperties.FLOW]: FLOW_AMPLITUDE_PROPERTY.SIDE_PANEL,
            });
        }

        handleChange(e);
    };

    return (
        <SidePanelContentSection
            css={otherBenefitsStyles}
            title={t('other-benefits.additional-services.section-title')}
        >
            {(Object.keys(services) as UpsellServiceType[]).map((serviceType: UpsellServiceType): React.ReactNode => {
                const key = serviceType.toLowerCase().replace(/_/gi, '-');

                return (
                    <Checkbox
                        key={serviceType}
                        name="services"
                        label={t(`other-benefits.service.${key}`)}
                        value={serviceType}
                        checked={values.services.includes(serviceType)}
                        onChange={onChangeService}
                    />
                );
            })}
        </SidePanelContentSection>
    );
};
