import { useEffect, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useField } from 'formik';
import { Chip, ISelectAutocompleteOption, SelectAutocomplete } from '@shipengine/giger';
import { chipStyles } from './AutocompleteFilterStyles';
import { FormField } from '@shipengine/formik-giger';

type Option = string | Record<string, string>;
export interface IAutocompleteFilterProps {
    name: string;
    label: string;
    options?: unknown[];
    labelKey?: string;
    valueKey?: string;
}

function getKeyValue(option: ISelectAutocompleteOption<Option> | unknown, key: string) {
    if (typeof option === 'object') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return option?.[key] ?? option;
    }

    return option;
}

export const AutocompleteFilter = (props: IAutocompleteFilterProps): JSX.Element => {
    const { name, label, options, labelKey = 'label', valueKey = 'value' } = props;
    const [field, meta, helper] = useField<string[] | undefined>(name);
    const { t } = useTranslation();

    const [selectedOptions, setSelectedOptions] = useState<ISelectAutocompleteOption<Option>[]>();

    const selectOptions: ISelectAutocompleteOption<Option>[] =
        (options as (string | Record<string, string>)[])?.map((option) => {
            if (option && typeof option === 'object') {
                return { ...option, value: option[valueKey], label: option[labelKey] };
            } else {
                return { value: option, label: option };
            }
        }) || [];

    useEffect(() => {
        const getSelectedOptions = () =>
            options?.filter((option) =>
                field.value?.includes(getKeyValue(option, valueKey)),
            ) as unknown as ISelectAutocompleteOption<Option>[];

        if (options && field.value && !selectedOptions) {
            setSelectedOptions(getSelectedOptions());
        }
    }, [field.value, options, selectedOptions, valueKey]);

    const getOptionValue = (option: ISelectAutocompleteOption<Option>) => getKeyValue(option, valueKey);

    const handleChange = (_name: string, option: ISelectAutocompleteOption<Option> | null) => {
        if (!option) return;

        const value = getOptionValue(option);

        const currentFieldValue = field.value;

        if (!currentFieldValue?.includes(value)) {
            const newFieldValue = currentFieldValue?.length ? [...currentFieldValue, value] : [value];
            const newSelectedOptions = selectedOptions?.length ? [...selectedOptions, option] : [option];

            helper.setValue(newFieldValue);
            setSelectedOptions(newSelectedOptions);
        }
    };

    const removeOption = (option: ISelectAutocompleteOption<Option>) => {
        const optionValue = getKeyValue(option, valueKey);

        const newFieldValue = field.value?.filter((fieldValue: string) => fieldValue !== optionValue);
        const newSelectedOptions = selectedOptions?.filter(
            (selectedOption) => getKeyValue(selectedOption, valueKey) !== optionValue,
        );

        helper.setValue(newFieldValue?.length ? newFieldValue : undefined);
        setSelectedOptions(newSelectedOptions);
    };

    return (
        <>
            <FormField name={name}>
                <SelectAutocomplete
                    name={name}
                    options={selectOptions || []}
                    label={label}
                    onChange={handleChange}
                    noResultsLabel={t('autocomplete.messages.no-results-found')}
                    isInvalid={!!meta.error}
                />
            </FormField>
            <div css={chipStyles}>
                {selectedOptions?.map((option) => (
                    <Chip isSelected={true} onRemove={() => removeOption(option)} key={getKeyValue(option, valueKey)}>
                        {getKeyValue(option, labelKey)}
                    </Chip>
                ))}
            </div>
        </>
    );
};
