import { CustomsForm, CustomsFormData, otherTariffNumberOption, ValidTariffNumber } from '@packlink/customs-form';
import { useConfig } from '@packlink/utils';
import { Button, Grid, GridChild } from '@shipengine/giger';
import { getGoToPaymentStepTranslationKey } from '@store/selectors/checkout-steps';
import { getCheckoutContent, getParcelsTotalWeight } from '@store/selectors/checkout';
import { Form, useFormikContext } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { useSelector } from 'react-redux';

import { useMetrics } from '@providers/MetricsProvider';

type CustomsStepFormProps = {
    validTariffNumbers: ValidTariffNumber[];
};

export function CustomsStepForm({ validTariffNumbers }: CustomsStepFormProps): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const config = useConfig<IConfig>();
    const continueText = useSelector(getGoToPaymentStepTranslationKey);
    const tariffLink = config.tariffNumberUrls[locale];
    const totalWeight = useSelector(getParcelsTotalWeight);
    const checkoutContent = useSelector(getCheckoutContent);
    const contentValue = checkoutContent?.value ?? 0;
    const metrics = useMetrics();

    const { isSubmitting } = useFormikContext<CustomsFormData>();

    return (
        <Form>
            <Grid noPadding>
                <GridChild colSpan={12}>
                    <CustomsForm
                        locale={locale}
                        shipmentWeight={totalWeight}
                        tariffNumberLink={tariffLink}
                        validTariffNumbers={validTariffNumbers}
                        desktopInventoryColSpan={8}
                        tracker={metrics}
                        defaultCustomsCategory={otherTariffNumberOption}
                        contentValue={contentValue}
                    />
                </GridChild>
                <GridChild colSpan={12} colStart={0} colStartDesktop={9} colSpanDesktop={4}>
                    <Button type="submit" isLoading={isSubmitting} isFullWidth data-id="customs.submit">
                        {t(continueText)}
                    </Button>
                </GridChild>
            </Grid>
        </Form>
    );
}
