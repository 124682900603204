import { ImportSource } from '@packlink/packlink-sdk';
import { ImportOrdersSubtype } from '../ImportOrdersModal/ImportOrdersModal';

export interface IImportFinishEventData {
    channel: string;
    numberShipmentsImported?: number;
    status: ImportErrorStatus;
    callbackPayload?: ImportSource;
    errorMessage?: string;
    errorType?: ImportErrorType;
    errorSubtype?: ImportOrdersSubtype;
    eventName: string;
}

export enum ImportErrorType {
    UNKNOWN = 'UNKNOWN',
    UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum ImportErrorSubtype {
    API_KEY_ERROR = 'api-key-error',
    SUSPENDED_ERROR = 'suspended-error',
}

export enum ImportErrorStatus {
    IMPORT_FAILED = 'IMPORT_FAILED',
    IMPORT_SUCCESSFUL = 'IMPORT_SUCCESSFUL',
}
