import { PaymentClientMethod } from '@packlink/packlink-sdk';
import { createSelector } from 'reselect';
import { getCheckoutTotalPrice, getIsCustomsRequiredInCheckout } from '@store/selectors/checkout';
import { isInvoiceAddressValid } from '@store/selectors/client';
import { getDeferred } from '@store/selectors/payment';

export const GO_TO_CREATE_LABEL = 'checkout.continue-button.create';
export const GO_TO_PAYMENT_LABEL = 'checkout.continue-button.payment';
export const GO_TO_CUSTOMS_LABEL = 'checkout.continue-button.customs';

// TODO: These two selectors should be hooks, however they should be used in the Angular part
//       so these could be refactored when checkout is migrated to React.

export const canCreateShipmentInNextStep = createSelector(
    [isInvoiceAddressValid, getDeferred, getCheckoutTotalPrice],
    (
        validInvoiceAddress: boolean,
        deferredPayment: PaymentClientMethod | undefined,
        totalPrice: number | undefined,
    ): boolean => {
        const deferredPaymentAvailable = validInvoiceAddress && deferredPayment;
        return totalPrice === 0 || !!deferredPaymentAvailable;
    },
);

export const getGoToPaymentStepTranslationKey = createSelector(
    [canCreateShipmentInNextStep],
    (canCreateShipment: boolean): string => (canCreateShipment ? GO_TO_CREATE_LABEL : GO_TO_PAYMENT_LABEL),
);

export const getAddressStepContinueTranslationKey = createSelector(
    [getGoToPaymentStepTranslationKey, getIsCustomsRequiredInCheckout],
    (paymentStepTranslationKey, isCustomsRequired): string =>
        isCustomsRequired ? GO_TO_CUSTOMS_LABEL : paymentStepTranslationKey,
);
