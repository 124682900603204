import { ShipmentPanelType, EventName } from '@types';
import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { BulkFlows } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelBulk/typings';
import { IShipmentPanelBulkContext } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelBulk/ShipmentPanelBulk';

import { useSelector } from 'react-redux';
import { useEvent } from '@packlink/event-handler';
import { IProShipmentApiResponse, ProShipment } from '@packlink/packlink-sdk';
import { getClientIdentifier } from '@store/selectors/client';
import { hasTokenizationParams } from '@store/selectors/payment';
import { ShipmentUtils } from '@utils/ShipmentUtils';

interface IShipmentPanelProviderProps {
    children: React.ReactNode;
}

export type ShipmentPanelConextProp = IShipmentPanelBulkContext;

export interface IShipmentPanelContextProps {
    panelName?: ShipmentPanelType;
    shipment?: ProShipment;
    context?: ShipmentPanelConextProp;
}

const initialContext: IShipmentPanelContextProps = {
    panelName: undefined,
    shipment: undefined,
    context: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const initialDispatch = (): void => {};

type ShipmentPanelDispatch = Dispatch<SetStateAction<IShipmentPanelContextProps>>;
export type ShipmentPanelContextValue = [IShipmentPanelContextProps, ShipmentPanelDispatch];

const ShipmentPanelContext = React.createContext<ShipmentPanelContextValue>([initialContext, initialDispatch]);

const ShipmentPanelProvider = ({ children }: IShipmentPanelProviderProps): JSX.Element => {
    const hasPaymentTokenizationParams = useSelector(hasTokenizationParams);
    const [state, setState] = useState<IShipmentPanelContextProps>(initialContext);
    const clientId = useSelector(getClientIdentifier);
    const { eventBind, eventUnbind } = useEvent<IProShipmentApiResponse>(clientId, EventName.SHIPMENT_UPDATED);

    useEffect(() => {
        if (hasPaymentTokenizationParams) {
            setState({
                panelName: ShipmentPanelType.BULK,
                context: {
                    flow: BulkFlows.PAYMENT,
                },
            });
        }
    }, [hasPaymentTokenizationParams]);

    useEffect(() => {
        const setUpdatedShipment = (updatedShipment: IProShipmentApiResponse): void => {
            if (updatedShipment.data.packlink_reference !== state.shipment?.data.packlinkReference) {
                return;
            }

            setState({
                ...state,
                shipment: ShipmentUtils.proShipmentCreateFromResponse(updatedShipment),
            });
        };

        eventBind(setUpdatedShipment);

        return () => {
            eventUnbind(setUpdatedShipment);
        };
    }, [eventBind, eventUnbind, state]);

    return <ShipmentPanelContext.Provider value={[state, setState]}>{children}</ShipmentPanelContext.Provider>;
};

export { ShipmentPanelContext, ShipmentPanelProvider };
