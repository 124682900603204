import { Navigate, Route, Routes, useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { ProShipment } from '@packlink/packlink-sdk';
import { CustomsStepHandler } from '@Customs/components/CustomsStep/CustomsStepHandler';
import { APP_ROUTE } from '@pages/router/routes';
import { getIsCustomsRequiredInCheckout } from '@store/selectors/checkout';
import { DetailsStep } from '@components/Checkout/DetailsStep/DetailsStep';
import { InfoStep } from '@components/Checkout/InfoStep/InfoStep';
import { PaymentStep } from '@components/Checkout/PaymentStep/PaymentStep';
import { ServiceStep } from '@components/Checkout/ServiceStep/ServiceStep';
import { CheckoutLayout } from './components/CheckoutLayout/CheckoutLayout';
import { CheckoutRoute } from './routes';
import { useCheckoutPath } from './hooks/useCheckoutPath';
import { CheckoutRouterLoader } from './CheckoutRouterLoader';

export function CheckoutRouter(): JSX.Element {
    return (
        <Routes>
            <Route element={<CheckoutRouterLoader />}>
                <Route element={<CheckoutLayout />}>
                    <Route index element={<IndexHandler />} />
                    <Route path={CheckoutRoute.INFO} element={<InfoStep />} />
                    <Route path={CheckoutRoute.SERVICE} element={<ServiceStep />} />
                    <Route path={CheckoutRoute.ADDRESS} element={<DetailsStep />} />
                    <Route path={CheckoutRoute.CUSTOMS} element={<CustomsStepHandler />} />
                    <Route path={CheckoutRoute.PAYMENT} element={<PaymentStep />} />
                </Route>
            </Route>
        </Routes>
    );
}

function IndexHandler() {
    const params = useParams();
    const getCheckoutPath = useCheckoutPath();
    const queryClient = useQueryClient();
    const shipment = queryClient.getQueryData<ProShipment>(['checkout', 'shipment', params.id]);
    const isCustomsRequiredInCheckout = useSelector(getIsCustomsRequiredInCheckout);

    if (!params.id || !shipment) {
        return <Navigate to={getCheckoutPath(CheckoutRoute.INFO)} replace />;
    } else {
        const shipmentStateMap = getShipmentStateMap(isCustomsRequiredInCheckout, params.id, getCheckoutPath);

        return <Navigate to={shipmentStateMap[shipment.step] || getCheckoutPath(CheckoutRoute.INFO)} replace />;
    }
}

const getShipmentStateMap = (
    areCustomsRequired: boolean,
    id: string,
    getCheckoutPath: ReturnType<typeof useCheckoutPath>,
) => ({
    SEARCH_INFO: getCheckoutPath(CheckoutRoute.INFO),
    SERVICE_SELECTION: getCheckoutPath(CheckoutRoute.SERVICE),
    SHIPPING_DETAILS: getCheckoutPath(CheckoutRoute.ADDRESS),
    CUSTOMS: getCheckoutPath(CheckoutRoute.CUSTOMS),
    // RET-223 backend is still sending BILLING_INFO as step for the shipment
    // Backend is also sending BILLING_INFO as step for customs
    // this step should be removed when the info is correctly set
    BILLING_INFO: areCustomsRequired ? getCheckoutPath(CheckoutRoute.CUSTOMS) : getCheckoutPath(CheckoutRoute.PAYMENT),
    PAYMENT: getCheckoutPath(CheckoutRoute.PAYMENT),
    PURCHASED: APP_ROUTE.SHIPMENTS.DETAILS.replace(':id', id),
});
