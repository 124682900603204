import { Typography } from '@shipengine/giger';
import { getOrderDetailsItemStyles, getTitleStyles, getContentStyles } from './OrderDetailsItemStyles';

interface IOrderDetailsItemProps {
    title: string;
    children: React.ReactNode;
}

export function OrderDetailsItem({ title, children }: IOrderDetailsItemProps): JSX.Element {
    return (
        <div css={getOrderDetailsItemStyles}>
            <Typography component="dt" variant="subtitle1" css={getTitleStyles}>
                {title}
            </Typography>
            <dd css={getContentStyles}>{children}</dd>
        </div>
    );
}
