import {
    ISetActiveIntegrationImport,
    SET_ACTIVE_INTEGRATION_IMPORT,
    ISetSidePanelError,
    SET_SIDE_PANEL_ERROR,
} from '../actions/integrations';
import { ActiveIntegrationImport } from '@Integrations/types/integration';

type IIntegrationsAction = ISetActiveIntegrationImport | ISetSidePanelError;

export interface IIntegrationsState {
    activeIntegrationImport?: ActiveIntegrationImport;
    sidePanelError: boolean;
}

export const initialState = {
    isLoading: false,
    activeIntegrationImport: undefined,
    sidePanelError: false,
};

export const reducer = (state: IIntegrationsState = initialState, action: IIntegrationsAction): IIntegrationsState => {
    switch (action.type) {
        case SET_ACTIVE_INTEGRATION_IMPORT:
            return { ...state, activeIntegrationImport: action.payload };
        case SET_SIDE_PANEL_ERROR:
            return { ...state, sidePanelError: action.payload };
        default:
            return state;
    }
};
