import { ReactNode } from 'react';
import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Typography } from '@shipengine/giger';
import { getFeatureStyles, getIconStyles, getPlanFeaturesStyles } from './PlanFeaturesStyles';

interface IPlanFeaturesProps {
    features: ReactNode[];
    threeBoldChildren?: boolean;
}

export function PlanFeatures({ features, threeBoldChildren }: IPlanFeaturesProps): JSX.Element {
    return (
        <ul css={getPlanFeaturesStyles(threeBoldChildren)}>
            {features.map((feature, index) => (
                <Typography component="li" key={index} variant="body1" css={getFeatureStyles}>
                    <>
                        <Icon css={getIconStyles} name={IconNames.CHECK} size={IconSize.SIZE_SMALL} />
                        <span>{feature}</span>
                    </>
                </Typography>
            ))}
        </ul>
    );
}
