import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const shipmentPanelSpinnerStyles = (): SerializedStyles =>
    css({
        display: 'flex',
    });

export const shipmentPanelFormStyles = (reducedHeight = false): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        height: reducedHeight ? 'calc(100% - 56px)' : '100%',
    });

export const spinnerStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(5),
    });
