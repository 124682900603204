// TODO: Move to a common place https://packlink.atlassian.net/browse/FRON-402
import { useEffect, useRef, useCallback } from 'react';

function useClickOutside<T extends HTMLElement = HTMLElement>(callback: (e: MouseEvent) => void): React.RefObject<T> {
    const ref = useRef<T>(null);

    const listener = useCallback(
        (e: MouseEvent) => {
            if (ref.current && !ref.current.contains(e.target as Node)) {
                callback(e);
            }
        },
        [ref, callback],
    );

    useEffect(() => {
        document.addEventListener('mousedown', listener);

        return (): void => document.removeEventListener('mousedown', listener);
    }, [listener]);

    return ref;
}

export { useClickOutside };
