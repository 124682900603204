import { WithCommonProps } from '@shipengine/giger';
import { AnySupportTicket, SendSupportTicketMessagePayload, SupportTicketMessage } from '@packlink/packlink-sdk';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { getTicketDetailsSectionPadding } from '../../../../TicketDetailsStyles';
import { TicketChatSendMessageForm } from './TicketChatSendMessageForm';

type TicketChatSendMessageProps = WithCommonProps<{
    supportTicket: AnySupportTicket;
    onNewMessage: (data: SupportTicketMessage) => void;
}>;

export function TicketChatSendMessage({
    onNewMessage,
    supportTicket,
    ...props
}: TicketChatSendMessageProps): JSX.Element {
    const { onSubmit } = useTicketChatSendMessageHandlers({ onNewMessage, supportTicket });
    return (
        <section css={getTicketDetailsSectionPadding} {...props}>
            <TicketChatSendMessageForm onSubmit={onSubmit} />
        </section>
    );
}

function useTicketChatSendMessageHandlers({ onNewMessage, supportTicket }: TicketChatSendMessageProps) {
    // It's impossible to be undefined at this page, we already fetched the ticket through it
    const supportTicketId = supportTicket.externalReference as string;
    const sdk = useSdk();

    const onSubmit = async (messagePayload: SendSupportTicketMessagePayload) => {
        const newMessage = await sdk.v1.supportCenter.sendMessage(supportTicketId, messagePayload);
        onNewMessage(newMessage);
    };

    return {
        onSubmit,
    };
}
