import { useTranslation } from '@packlink/translation-provider';
import {
    Button,
    ButtonVariant,
    Dialog,
    DialogActions,
    DialogContent,
    DialogHeader,
    Typography,
} from '@shipengine/giger';
import { AmplitudeEvents } from '@constants/amplitude';
import { getCancelDialogStyles, getImageStyles, getMessageStyles, getTitleStyles } from './CancelDialogStyles';
import { useCancelClientSubscription } from '../../../../hooks/useCancelClientSubscription';
import { useAmplitude } from '@hooks/useAmplitude';

export interface ICancelDialogProps {
    isOpen: boolean;
    planName: string;
    onClose: () => void;
    expirationDate?: string;
}

export function CancelDialog({ isOpen, planName, onClose, expirationDate = '' }: ICancelDialogProps): JSX.Element {
    const { t } = useTranslation();
    const { cancelClientSubscription, isLoading, isSuccess } = useCancelClientSubscription();
    const { sendAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();

    const {
        cdn: { url: cdnUrl },
    } = config;

    const cancelSubscription = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.SUBSCRIPTION_CANCEL_CONFIRM);

        cancelClientSubscription(undefined, {
            onSuccess: () => {
                sendAmplitudeEvent(AmplitudeEvents.SUBSCRIPTION_CANCELLATION_CONFIRMED);
            },
        });
    };

    return (
        <Dialog css={getCancelDialogStyles} isOpen={isOpen}>
            <DialogHeader
                showClose
                closeAriaLabel={t('dialog.action.close')}
                onClose={onClose}
                title={isSuccess ? t('subscriptions.cancel.canceled-title') : undefined}
            />

            <DialogContent>
                {isSuccess && (
                    <img
                        css={getImageStyles}
                        src={`${cdnUrl}/subscriptions/images/check.svg`}
                        alt=""
                        aria-hidden="true"
                        data-id="cancel-img"
                    />
                )}

                <Typography css={getTitleStyles} variant="heading4" component="h4">
                    {!isSuccess && t('subscriptions.cancel.confirmation-title')}
                </Typography>

                {isSuccess && (
                    <Typography css={getMessageStyles} variant="body1" component="p">
                        {t('subscriptions.cancel.canceled-message', {
                            expirationDate: expirationDate,
                            planName: t('subscriptions.plan.display-name', { planName }),
                        })}
                    </Typography>
                )}
            </DialogContent>

            <DialogActions>
                {!isSuccess && (
                    <>
                        <Button variant={ButtonVariant.OUTLINED} onClick={onClose}>
                            {t('subscriptions.cancel.no')}
                        </Button>
                        <Button onClick={cancelSubscription} isLoading={isLoading}>
                            {t('subscriptions.cancel.yes')}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}
