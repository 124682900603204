import { useTranslation } from '@packlink/translation-provider';
import { Typography, Button, InlineNotification, NotificationType } from '@shipengine/giger';
import { PaymentCreditCard, PaymentMethod, PaymentPaypal } from '@packlink/packlink-sdk';

import { useDeferredPaymentFormStatus, DeferredPaymentsFormStatus } from '@Invoicing/context/deferredPaymentFormStatus';
import { useSettingsPaymentDeferredData } from '@Invoicing/hooks/useSettingsPaymentDeferredData';

import { getSpanStyle } from './SettingsPaymentSummaryStyles';

const usePaymentMethodLabel = (): string => {
    const { t } = useTranslation();
    const { clientDeferredPaymentMethod } = useSettingsPaymentDeferredData();

    let label = 'N/A';
    if (clientDeferredPaymentMethod?.method === PaymentMethod.CREDITCARD) {
        label = t('settings.payment-summary.creditcard-label');
    } else if (clientDeferredPaymentMethod?.method === PaymentMethod.PAYPAL) {
        label = 'PayPal';
    } else if (clientDeferredPaymentMethod?.method === PaymentMethod.DIRECTDEBIT) {
        label = t('settings.payment-summary.directdebit-label');
    }

    return label;
};

export const SettingsPaymentSummary = (): JSX.Element => {
    const { t } = useTranslation();

    const paymentMethodLabel = usePaymentMethodLabel();

    const { setDeferredPaymentFormStatus } = useDeferredPaymentFormStatus();
    const { clientDeferredPaymentMethod } = useSettingsPaymentDeferredData();

    return (
        <>
            <Typography>
                <p>
                    {t('settings.payment-summary.payment-method')}: <span css={getSpanStyle}>{paymentMethodLabel}</span>
                </p>
            </Typography>

            {clientDeferredPaymentMethod?.method === PaymentMethod.CREDITCARD && (
                <Typography>
                    <p>
                        {t('settings.payment-summary.card-info')}:{' '}
                        <span css={getSpanStyle}>
                            {t('settings.payment-summary.card-info-number', {
                                number: (clientDeferredPaymentMethod as PaymentCreditCard).cardNumber,
                            })}
                        </span>
                    </p>
                </Typography>
            )}

            {clientDeferredPaymentMethod?.method === PaymentMethod.PAYPAL && (
                <Typography>
                    <p>
                        {t('settings.payment-summary.email')}:{' '}
                        <span css={getSpanStyle}>{(clientDeferredPaymentMethod as PaymentPaypal).email}</span>
                    </p>
                </Typography>
            )}

            <Typography>
                <p>
                    {t('settings.payment-summary.invoices')}:{' '}
                    <span css={getSpanStyle}>{t('settings.payment-summary.invoices-info')}</span>
                </p>
            </Typography>

            {clientDeferredPaymentMethod?.method !== PaymentMethod.DIRECTDEBIT && (
                <Button onClick={() => setDeferredPaymentFormStatus(DeferredPaymentsFormStatus.OPEN)}>
                    {t('settings.payment-summary.edit-payment')}
                </Button>
            )}

            {clientDeferredPaymentMethod?.method === PaymentMethod.DIRECTDEBIT && (
                <InlineNotification type={NotificationType.INFO} title={t('settings.payment.edit-direct-debit-title')}>
                    {t('settings.payment-summary.edit-direct-debit')}
                </InlineNotification>
            )}
        </>
    );
};
