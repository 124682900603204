import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSupportTicketSummaryMessageDataStyles = (theme: Theme): SerializedStyles =>
    css({
        '& > *': {
            marginBottom: theme.spacing(),
            '::last-child': {
                marginBottom: 0,
            },
        },
    });
