import { AmplitudeEvents } from '@constants/amplitude';
import { ProviderName } from '@packlink/metrics';
import { Spinner, useToast } from '@shipengine/giger';
import { IShipment, ITracking, Shipment } from '@packlink/packlink-sdk';
import {
    ITrackingDetailsMetrics,
    TrackingDetails,
    TrackingDetailsMetricsEvent,
    useTracking,
} from '@packlink/tracking-details';
import { getAmplitudeTableViewClickEventName, getShipmentAmplitudePayload } from '@hooks/useAmplitude';
import { useMemo } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { getShipmentRowTrackingDetailsLoadingStyles } from './ShipmentRowTrackingDetailsStyles';
import { useMetrics } from '@providers/MetricsProvider';
interface IShipmentRowTrackingDetailsProps {
    shipment: Shipment<IShipment>;
}

export function ShipmentRowTrackingDetails({ shipment }: IShipmentRowTrackingDetailsProps): JSX.Element | null {
    const { value, error, loading } = useTracking(shipment.packlinkReference);
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const toast = useToast(t);
    const metrics = useMetrics();

    const trackingDetailsMetrics: ITrackingDetailsMetrics = useMemo(
        () => ({
            tracker: metrics,
            events: {
                [TrackingDetailsMetricsEvent.OnClickTrackingNumber]: {
                    providerName: ProviderName.AMPLITUDE,
                    eventName: getAmplitudeTableViewClickEventName(AmplitudeEvents.ID_TRACKING),
                    payload: getShipmentAmplitudePayload(locale, shipment),
                },
                [TrackingDetailsMetricsEvent.OnClickContactCenter]: {
                    providerName: ProviderName.AMPLITUDE,
                    eventName: getAmplitudeTableViewClickEventName(AmplitudeEvents.TRACKING_DETAILS_CONTACT),
                    payload: getShipmentAmplitudePayload(locale, shipment),
                },
            },
        }),
        [locale, metrics, shipment],
    );

    if (error) {
        toast.error({ message: t('error.message.try-again-later') });
        return null;
    }

    return loading ? (
        <div css={getShipmentRowTrackingDetailsLoadingStyles}>
            <Spinner />
        </div>
    ) : (
        <TrackingDetails tracking={value as ITracking} shipment={shipment} metrics={trackingDetailsMetrics} />
    );
}
