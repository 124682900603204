import { Inbox } from '@packlink/packlink-sdk';
import { AppRoute, APP_ROUTE } from '../routes';

type SnakeToLowerKebabCase<S extends string> = S extends `${infer T}_${infer U}`
    ? `${Lowercase<T>}-${Lowercase<SnakeToLowerKebabCase<U>>}`
    : Lowercase<S>;

export function inboxToPath<T extends Inbox>(inbox: T): SnakeToLowerKebabCase<T> {
    return inbox.toLowerCase().replace(/_/g, '-') as SnakeToLowerKebabCase<T>;
}

type InboxToRouteReturn<T extends Inbox> = `/${AppRoute.SHIPMENTS}/${SnakeToLowerKebabCase<T>}`;
export function inboxToRoute<T extends Inbox>(inbox: T): InboxToRouteReturn<T> {
    return APP_ROUTE.SHIPMENTS.INBOX.replace(':inbox', inboxToPath(inbox)) as InboxToRouteReturn<T>;
}
