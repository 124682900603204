import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketChatMessageHeader = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(),
    });
