import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import Packlink from '@sdk';
import { IInvoice } from '@packlink/packlink-sdk';

export const INVOICES_PAGINATION_DEFAULT = {
    pageSize: 10,
    pagination: {
        currentPage: 1,
        totalPages: 1,
        totalElements: 0,
    },
};
interface RequestParamsProps {
    pageNumber: number;
    pageSize: number;
}
function fetchInvoicesData({ pageNumber, pageSize }: RequestParamsProps) {
    return Packlink.v1.invoices.get(pageNumber, pageSize);
}

export const useInvoicesData = (
    pagination = INVOICES_PAGINATION_DEFAULT.pagination,
    pageSize = INVOICES_PAGINATION_DEFAULT.pageSize,
) => {
    const pageNumber = pagination.currentPage;
    const [requestParams, setRequestParams] = useState<RequestParamsProps>({ pageNumber, pageSize });

    const changeRequestParams = useCallback((params: Partial<RequestParamsProps>) => {
        setRequestParams((prevParams) => ({ ...prevParams, ...params }));
    }, []);

    const { data, isLoading } = useQuery(['invoices', requestParams], () => fetchInvoicesData(requestParams), {
        keepPreviousData: true,
    });

    return {
        invoices: (data?.invoices as IInvoice[]) || [],
        pagination: data?.pagination,
        isLoading,
        changeRequestParams,
    };
};

export const downloadInvoice = (ref: string) => Packlink.v1.invoices.download(ref);
