import { useNavigate } from 'react-router';
import { useTranslation } from '@packlink/translation-provider';

import { BrandedSettings, ClientFeature } from '@packlink/packlink-sdk';
import { AmplitudeEventPrefixes, AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { APP_ROUTE } from '@pages/router/routes';
import { useAmplitude } from '@hooks/useAmplitude';
import { useSubscriptionFeature } from '@hooks/useSubscriptionFeatureBehaviours';
import { EmailTemplatesList } from '@BrandedEmails/components/EmailTemplatesList/EmailTemplatesList';
import { SubscriptionNotification } from '@common/components/SubscriptionNotification/SubscriptionNotification';
import { getNotificationSpacingStyles } from '@common/components/SubscriptionNotification/SubscriptionNotificationStyles';
import { SettingsPage, SettingsPageHeader } from '@components/SettingsPage';

export function BrandedEmailsSettings(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const { isTenantSubscriptionEnabled, feature } = useSubscriptionFeature(ClientFeature.BRANDED_EMAILS);
    const isSubscriptionFeatureNotEnabled = isTenantSubscriptionEnabled && !feature.isEnabled;

    const onEditEmail = (settings?: BrandedSettings) => {
        sendAmplitudeClickEvent(AmplitudeEvents.BRANDED_EMAIL_EDIT);
        navigate(APP_ROUTE.BRANDED_EMAIL.INDEX, { state: settings });
    };

    const onToggle = (active: boolean) => {
        sendAmplitudeClickEvent(AmplitudeEvents.BRANDED_EMAIL_TOGGLE, {
            [AmplitudeProperties.ACTIVE]: active,
        });
    };

    return (
        <SettingsPage>
            <SettingsPageHeader
                description={isSubscriptionFeatureNotEnabled ? undefined : t('branded-emails.settings.description')}
            >
                {t('branded-emails.settings.title')}
            </SettingsPageHeader>

            {isSubscriptionFeatureNotEnabled && (
                <SubscriptionNotification
                    css={getNotificationSpacingStyles}
                    title={t('subscriptions.upgrade-notification.branded-emails')}
                    amplitudeEvent={`${AmplitudeEvents.SETTINGS_TOOLTIP_BRANDED_EMAIL} ${AmplitudeEventPrefixes.CONFIGURATION_MENU}`}
                >
                    {t('subscriptions.upgrade-notification.branded-emails-message')}
                </SubscriptionNotification>
            )}

            <EmailTemplatesList onEdit={onEditEmail} onToggle={onToggle} subscribed={feature.isEnabled} />
        </SettingsPage>
    );
}
