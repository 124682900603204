import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getRadioOptionsStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `${theme.spacing(2)}px 0`,
    });
export const getSpinnerStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(6),
        width: '100%',
    });
