import { useTranslation } from '@packlink/translation-provider';
import { IconNames } from '@shipengine/giger-theme';
import { Icon, Typography } from '@shipengine/giger';
import { ISubscription } from '@packlink/packlink-sdk';
import { useSubscriptionPlanFeatures } from '@hooks/useSubscriptionPlanFeatures';
import { PlusPlanFeatures } from '../../../../../../../../common/components/PlusPlanFeatures/PlusPlanFeatures';
import { SubscriptionPrice } from '../../../../components/SubscriptionPrice/SubscriptionPrice';
import { getBoxStyles, getPlanIconStyles, getPlanNameStyles, getPlanStyles } from './PlanInfoStyles';

interface PlanInfoProps {
    subscription: ISubscription;
}

export function PlanInfo({ subscription }: PlanInfoProps): JSX.Element {
    const { t } = useTranslation();
    const { availableFeatures } = useSubscriptionPlanFeatures();

    const planDisplayName = t('subscriptions.plan.display-name', { planName: subscription.plan.name });

    return (
        <>
            <div css={[getBoxStyles, getPlanStyles]}>
                <div css={getPlanNameStyles}>
                    <Icon css={getPlanIconStyles} name={IconNames.ROCKET} />
                    <Typography variant="body2" bold>
                        {planDisplayName}
                    </Typography>
                </div>

                <SubscriptionPrice
                    price={subscription.currentBillingAmount}
                    currency={subscription.currentBillingCurrency}
                />
            </div>

            <div css={getBoxStyles}>
                <Typography variant="body1" component="p">
                    {t('subscriptions.plan.details', { planName: planDisplayName })}
                </Typography>

                <PlusPlanFeatures showDisclaimers={false} availableFeatures={availableFeatures || {}} />
            </div>
        </>
    );
}
