import { IconNames } from '@shipengine/giger-theme';
import { ButtonVariant, Icon, IconSize, Link } from '@shipengine/giger';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { useTranslation } from '@packlink/translation-provider';
import {
    getFrequentlyAskedQuestionsButtonStyles,
    getFrequentlyAskedQuestionsIconStyles,
} from './FrequentlyAskedQuestionsLinkStyles';

export function FrequentlyAskedQuestionsLink({ ...props }): JSX.Element {
    const { t } = useTranslation();
    const getHelperCenter = useHelpCenter();

    return (
        <Link
            href={getHelperCenter(HELP_CENTER_KEYS.home)}
            target="_blank"
            isExternal
            variant={ButtonVariant.TEXT}
            css={getFrequentlyAskedQuestionsButtonStyles}
            {...props}
        >
            <>
                <Icon name={IconNames.HELP} css={getFrequentlyAskedQuestionsIconStyles} size={IconSize.SIZE_SMALL} />
                {t('support-center.actions.frequently-asked-questions')}
            </>
        </Link>
    );
}
