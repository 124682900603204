import { FC } from 'react';

import { Typography } from '@shipengine/giger';
import { IInfoPanelProps, InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { IntegrationGuide } from '@Integrations/components/IntegrationGuide/IntegrationGuide';
import { IntegrationVideo } from '@Integrations/components/IntegrationVideo/IntegrationVideo';
import { Trans, useTranslation } from '@packlink/translation-provider';

import { getIntegrationVideoStyles } from './IntegrationGuideVideoStyles';
import { IntegrationGuideName } from './types';

type WoocommerceIntegrationGuideType = Omit<IInfoPanelProps, 'title' | 'collapsible'>;

export const WoocommerceIntegrationGuide: FC<WoocommerceIntegrationGuideType> = ({
    collapsed,
}: WoocommerceIntegrationGuideType): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const basePath = `${config.cdn.url}/pro/statics/videos/onboarding/${IntegrationGuideName.Woocommerce}`;
    const subtitleFallbackPath = `${config.cdn.url}/pro/statics/videos/onboarding/${IntegrationGuideName.Woocommerce}/en-GB.vtt`;

    return (
        <>
            <IntegrationVideo
                css={getIntegrationVideoStyles(collapsed)}
                src={`${basePath}/video.mp4`}
                poster={`${basePath}/poster.png`}
                subtitles={`${basePath}/${locale}.vtt`}
                fallbackSubtitles={subtitleFallbackPath}
                integrationName={IntegrationGuideName.Woocommerce}
            />
            <IntegrationGuide>
                <InfoPanel title={t('integrations.guide.title')} collapsible={true} collapsed={collapsed}>
                    <InfoPanelStepList>
                        <InfoPanelStep>
                            <Typography component="p">
                                <Trans i18nKey="integrations.woocommerce.select-plugin" />
                            </Typography>
                        </InfoPanelStep>
                        <InfoPanelStep title={t('integrations.guide.account-sync')}>
                            <ul>
                                <li>
                                    <Trans i18nKey="integrations.guide.default-information" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.guide.select-shipping" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.guide.enter-carrier" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.woocommerce.price-policy" />
                                </li>
                            </ul>
                        </InfoPanelStep>
                        <InfoPanelStep title={t('integrations.guide.use-module')}>
                            <Typography component="p">
                                <Trans i18nKey="integrations.guide.access-packlink-pro" />
                            </Typography>
                        </InfoPanelStep>
                    </InfoPanelStepList>
                </InfoPanel>
            </IntegrationGuide>
        </>
    );
};
