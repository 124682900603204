import { PointTooltipProps } from '@nivo/line';
import { ChartTooltip, ChartTooltipColorBox, ChartTooltipValue } from '../Layout/';
import { lineTooltipStyles } from './LineTooltipStyles';

export type LineTooltipProps = PointTooltipProps & {
    xLabel?: string;
    yLabel?: string;
    numElementsX: number;
};
export function LineTooltip({ point, numElementsX, xLabel = 'x', yLabel = 'y' }: LineTooltipProps): JSX.Element {
    const indexFromId = Number(point.id.split('.')[1]);

    return (
        <ChartTooltip index={indexFromId} numElements={numElementsX}>
            <dl css={lineTooltipStyles}>
                <ChartTooltipColorBox color={point.serieColor} />
                <ChartTooltipValue label={xLabel} value={point.data.xFormatted} />
                <ChartTooltipValue label={yLabel} value={point.data.yFormatted} />
            </dl>
        </ChartTooltip>
    );
}
