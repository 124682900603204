import { Button, Icon, Typography } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { getCheckIconStyles, getTextStyles } from './OTPVerificationSuccessStyles';

export type OTPVerificationSuccessProps = {
    continueTranslationKey: string;
    onComplete?: VoidFunction;
};

export function OTPVerificationSuccess({
    continueTranslationKey,
    onComplete,
}: OTPVerificationSuccessProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <SidePanelContent>
                <Icon css={getCheckIconStyles} name={IconNames.CHECK} />

                <Typography css={getTextStyles} component="h5" variant="heading5">
                    {t('phone-verification.success.title')}
                </Typography>
            </SidePanelContent>

            <SidePanelFooter>
                <Button isFullWidth onClick={onComplete}>
                    {t(continueTranslationKey)}
                </Button>
            </SidePanelFooter>
        </>
    );
}
