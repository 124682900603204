import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getErrorNotificationStyles = (theme: Theme): CSSObject => ({
    marginTop: theme.spacing(5),
    maxWidth: theme.spacing(60),
});

export function getLoadingStyles(): CSSObject {
    return {
        display: 'flex',
        justifyContent: 'center',
    };
}

export function getFormShipmentInfoPanelTitleStyles(theme: Theme): CSSObject {
    return {
        marginBottom: theme.spacing(2),
    };
}

export function getFormShipmentInfoPanelSummaryStyles(theme: Theme): CSSObject {
    return {
        // Making that it has the same height than inputs
        padding: theme.spacing(1.5),
    };
}
