import { IconNames } from '@shipengine/giger-theme';
import { WithCommonProps } from '@shipengine/giger';
import { LossSupportTicket } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { SupportTicketAttachmentDetail } from '../../SupportTicketAttachmentDetail/SupportTicketAttachmentDetail';
import { SupportTicketSummarySection } from '../../SupportTicketSummarySection/SupportTicketSummarySection';

type LossTicketSummaryMessageProps = WithCommonProps<{
    data: LossSupportTicket;
}>;

export function LossTicketSummaryMessage({ data, ...props }: LossTicketSummaryMessageProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <ul {...props}>
            <SupportTicketSummarySection
                title={t('support-center.ticket-chat.ticket-summary-product-description-label')}
                icon={IconNames.FILE}
            >
                {data.productDescription}
            </SupportTicketSummarySection>

            {data.proofOfValueAttachmentDetail && (
                <SupportTicketSummarySection
                    title={t('support-center.ticket-chat.ticket-summary-proof-of-value-label')}
                    icon={IconNames.ATTACH}
                >
                    <SupportTicketAttachmentDetail attachment={data.proofOfValueAttachmentDetail} />
                </SupportTicketSummarySection>
            )}
        </ul>
    );
}
