import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SidePanel, SlideInPosition } from '@shipengine/giger';
import { GTMEvents } from '@packlink/metrics';
import { useTranslation } from '@packlink/translation-provider';
import {
    ApiClientError,
    OwnContractCredentials,
    OwnContractDeactivateResponse,
    OwnContractModel,
    OwnContractRegistrationParams,
    OwnContractRepository,
    PaymentClientMethod,
    PaymentMethod,
} from '@packlink/packlink-sdk';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { AppDispatch } from '@store';
import { setPaymentMethods } from '@store/actions/payment';
import { PanelName } from '@types';
import { useSidePanel } from '@hooks/useSidePanel';
import { useAmplitude } from '@hooks/useAmplitude';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';
import { logSdkError } from '@utils/logger';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { apiClient } from '@sdk';
import { OwnContractPanelContent } from './OwnContractPanelContent';
import { OwnContractCarrierFormData, OwnContractCarriers } from './constants';
import { OwnContractPanelSuccess } from './OwnContractPanelSuccess';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';

const contractRepository = new OwnContractRepository(apiClient);

export interface OwnContractPanelProps {
    ownContract: OwnContractModel;
    onClose: () => void;
    onDeactivateSuccess: () => void;
    onRegisterSuccess: () => void;
    onSelectServices: (id: string) => void;
}

export const OwnContractPanel = ({
    ownContract,
    onClose,
    onDeactivateSuccess,
    onRegisterSuccess,
    onSelectServices,
}: OwnContractPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const sidePanel = useSidePanel(PanelName.OWN_CONTRACT);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<ApiClientError>();
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const { sendGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeEvent, setAmplitudeUserProperties, sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } =
        useAmplitude();

    const { carrierId, formFields, customValidations, customInputTypes, videoTutorial } =
        OwnContractCarrierFormData()[ownContract.carrierName as OwnContractCarriers] ?? {};
    const panelTitle = ownContract.isRegistered ? t('own-contract.list.edit') : t('own-contract.panel.activate-title');

    const dispatch = useDispatch<AppDispatch>();

    const closePanel = (): void => {
        sidePanel.close();
        onClose();
    };

    const onSuccess = (): void => {
        sendAmplitudeEvent(AmplitudeEvents.OC_REGISTER_SUCCESS, {
            [AmplitudeProperties.OWN_CONTRACT]: ownContract.carrierName,
        });

        sendGtmEvent(GTMEvents.OWN_CONTRACT, {
            action: GTMActionFieldOption.OWN_CONTRACT_ACTIVATED_OK,
            carrierName: ownContract.carrierName,
        });
        setShowSuccess(true);
        onRegisterSuccess();
    };

    const clearError = (): void => {
        setError(undefined);
    };

    const onDeactivate = (contractId: string): Promise<string | void> => {
        return contractRepository
            .deactivate(contractId)
            .then(({ contractId, paymentMethods }: OwnContractDeactivateResponse) => {
                // Backend responds with the remaining payment methods, this is because this deactivation
                // can lead to remove the own contract payment method
                dispatch(setPaymentMethods(paymentMethods || []));

                const hasOwnContract = paymentMethods?.find(
                    (p: PaymentClientMethod): boolean => p.method === PaymentMethod.OWNCONTRACT,
                );
                if (!hasOwnContract) {
                    setAmplitudeUserProperties([{ key: AmplitudeProperties.OWN_CONTRACT, value: false }]);
                }

                onDeactivateSuccess();

                return contractId;
            })
            .catch((error: ApiClientError) => {
                setError(error);
                logSdkError(error);
            });
    };

    const registerContract = (credentials: OwnContractCredentials): void => {
        sendSuffixedAmplitudeEvent(
            sendAmplitudeClickEvent,
            ownContract.carrierName,
        )(AmplitudeEvents.CARRIER_CONTRACT_CONNECT);

        const contract: OwnContractRegistrationParams = {
            contractModelId: ownContract.id,
            credentials,
        };

        setIsSubmitting(true);

        contractRepository
            .register(contract)
            .then(onSuccess)
            .catch((error: ApiClientError) => {
                sendGtmEvent(GTMEvents.OWN_CONTRACT, {
                    action: GTMActionFieldOption.OWN_CONTRACT_ACTIVATED_KO,
                    carrierName: ownContract.carrierName,
                });

                logSdkError(error);
                setError(error);
            })
            .finally(() => setIsSubmitting(false));
    };

    const handleSelectServices = (): void => {
        onSelectServices(ownContract.carrierId);
    };

    return (
        <SidePanel
            css={getSidePanelClipPathStyles(SlideInPosition.RIGHT)}
            isOpen={sidePanel.isOpen}
            onBackdropClick={closePanel}
            containerId="settings-panel-container"
            header={<SidePanelHeader onAction={closePanel} title={panelTitle} />}
        >
            <SidePanelContent>
                {showSuccess ? (
                    <OwnContractPanelSuccess
                        onSelectServices={handleSelectServices}
                        carrierName={ownContract.carrierName}
                    />
                ) : (
                    <OwnContractPanelContent
                        onClose={closePanel}
                        onSubmit={registerContract}
                        isLoading={isSubmitting}
                        error={error as ApiClientError}
                        clearError={clearError}
                        ownContract={ownContract}
                        onDeactivate={onDeactivate}
                        formFields={formFields}
                        videoTutorial={videoTutorial}
                        carrierId={carrierId}
                        customInputTypes={customInputTypes}
                        customValidations={customValidations}
                    />
                )}
            </SidePanelContent>
        </SidePanel>
    );
};
