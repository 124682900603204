import { useNavigate } from 'react-router';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import {
    CustomsFormData,
    ICustomsInvoiceExternalData,
    transformFormToDraftCustomsInvoice,
} from '@packlink/customs-form';
import { Button, ButtonSize, ButtonVariant } from '@shipengine/giger';
import { IShipmentCustomsStatus, Shipment } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { inboxToRoute } from '@pages/router/utils/paths';
import Packlink from '@sdk';
import { AppDispatch } from '@store';
import { deleteShipments } from '@store/actions/bulk';
import { setCheckoutSelectedShipment } from '@store/actions/checkout';
import {
    getCheckoutContent,
    getCheckoutShipment,
    getIsCustomsRequiredInCheckout,
    getParcelsTotalWeight,
} from '@store/selectors/checkout';
import { getCurrentInbox } from '@store/selectors/inbox';
import {
    getCustomsForm,
    getCustomsInvoiceExternalData,
    getIsCustomsFormSkipped,
} from '@Customs/store/selectors/customs';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useAmplitude } from '@hooks/useAmplitude';

export const SidebarButtons = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const currentInbox = useSelector(getCurrentInbox);
    const shipment = useSelector(getCheckoutShipment);
    const isCustomsRequired = useSelector(getIsCustomsRequiredInCheckout);
    const customsInvoice = useSelector(getCustomsForm);
    const isCustomsFormSkipped = useSelector(getIsCustomsFormSkipped);
    const customsInventoryMaxWeight = useSelector(getParcelsTotalWeight);
    const customsExternalData = useSelector(getCustomsInvoiceExternalData);
    const checkoutContent = useSelector(getCheckoutContent);

    const { sendAmplitudeEvent } = useAmplitude();
    const contentValue = checkoutContent?.value ?? 0;

    const onClose = () => {
        sendAmplitudeEvent(AmplitudeEvents.CLOSE_SHIPMENT_FLOW, {
            [AmplitudeProperties.STEP]: window.location.href.split('/').pop(),
        });
        navigate(inboxToRoute(currentInbox));
    };

    const onDelete = () => {
        if (!shipment.packlinkReference) return;

        dispatch(deleteShipments([shipment.packlinkReference])).then(() => navigate(inboxToRoute(currentInbox)));
    };

    const onSave = async () => {
        // Save and store new shipment
        const shipment = await saveShipment();

        dispatch(setCheckoutSelectedShipment(shipment));
        sendAmplitudeEvent(AmplitudeEvents.SAVE_SHIPMENT_FLOW, {
            [AmplitudeProperties.STEP]: window.location.href.split('/').pop(),
        });

        navigate(inboxToRoute(currentInbox));
    };

    const saveShipment = async () => {
        const isPaymentStep = window.location.href.includes('payment');
        const hasUnsavedCustomsData = isCustomsRequired && customsInvoice;

        if (hasUnsavedCustomsData && !isCustomsFormSkipped) {
            if (isPaymentStep) {
                return apiSaveShipmentWithCustoms(shipment, customsInvoice.id);
            } else {
                const savedCustomsInvoice = await apiSaveDraftCustoms(
                    customsInvoice,
                    customsExternalData,
                    customsInventoryMaxWeight,
                    contentValue,
                );
                return apiSaveShipmentWithCustoms(shipment, savedCustomsInvoice.id);
            }
        } else if (isCustomsFormSkipped) {
            return apiSaveShipmentAndRemoveCustoms(shipment);
        } else {
            return apiSaveShipment(shipment);
        }
    };

    return (
        <>
            <Button variant={ButtonVariant.OUTLINED} isFullWidth size={ButtonSize.SMALL} onClick={onClose}>
                {t('checkout.actions.close')}
            </Button>
            {shipment.packlinkReference && (
                <Button variant={ButtonVariant.OUTLINED} isFullWidth size={ButtonSize.SMALL} onClick={onDelete}>
                    {t('checkout.actions.delete-shipment')}
                </Button>
            )}
            <Button isFullWidth size={ButtonSize.SMALL} onClick={onSave} data-id="checkout-save-button">
                {t('checkout.actions.save')}
            </Button>
        </>
    );
};

const apiSaveShipmentWithCustoms = async (shipment: Shipment, customsInvoiceId: string | undefined) => {
    const newShipment = ShipmentUtils.updateCustoms(shipment, customsInvoiceId);
    newShipment.hasCustoms = true;
    return apiSaveShipment(newShipment);
};

const apiSaveDraftCustoms = async (
    customsInvoiceForm: CustomsFormData,
    customsExternalData: ICustomsInvoiceExternalData,
    customsInventoryMaxWeight: number,
    customsInventoryMaxValue: number,
) => {
    const customsInvoice = transformFormToDraftCustomsInvoice(customsInvoiceForm, customsExternalData, {
        customsInventoryMaxWeight: customsInventoryMaxWeight,
        customsInventoryMaxValue: customsInventoryMaxValue,
    });

    const discardPrevious = customsInvoiceForm.status === IShipmentCustomsStatus.COMPLETED;

    return customsInvoice.id && !discardPrevious
        ? Packlink.v1.draftCustomsInvoices.update(customsInvoice)
        : Packlink.v1.draftCustomsInvoices.create(customsInvoice);
};

const apiSaveShipmentAndRemoveCustoms = async (shipment: Shipment) => {
    return apiSaveShipmentWithCustoms(shipment, undefined);
};

const apiSaveShipment = async (shipment: Shipment) => {
    // Backend does not seems to retrieve shipment data when updating
    // Forcing a return of the payload, in order to update the redux store
    if (shipment.packlinkReference) {
        await Packlink.v1.shipments.updateShipment(shipment);
    } else {
        await Packlink.v1.shipments.createShipment(shipment);
    }
    return shipment;
};
