import { useRef } from 'react';
import { useToggle } from '@packlink/utils';
import { Popover, Typography } from '@shipengine/giger';
import { IShipment, Shipment } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { AmplitudeEvents } from '@constants/amplitude';
import { ShipmentRowTracking } from './ShipmentRowTracking';
import { labelStyles, moreIdsStyles } from './ShipmentRowTrackingsStyles';
import {
    getShipmentRowAllTrackingsWrapperStyles,
    getShipmentRowExtraIdsStyles,
    getShipmentRowMoreTrackingsStyles,
} from '../ShipmentRowStyles';
import { useAmplitude } from '@hooks/useAmplitude';

interface IShipmentRowTrackings {
    shipment: Shipment<IShipment>;
}

export const ShipmentRowTrackings = (props: IShipmentRowTrackings): JSX.Element | null => {
    const {
        shipment: { trackings, trackingUrl },
    } = props;

    const { sendAmplitudeTableViewClickEvent } = useAmplitude();
    const { t } = useTranslation();
    const { state: isPopoverOpen, toggle: toggleIsPopoverOpen } = useToggle(false);
    const popoverRef = useRef<HTMLDivElement>(null);

    if (!trackings || trackings.length === 0) {
        return null;
    }

    const firstTracking = trackings && trackings[0];
    const hiddenTrackings = trackings.slice(1);
    const hasMultipleTrackings = trackings.length > 1;

    const sendEvent = (event: AmplitudeEvents) => sendAmplitudeTableViewClickEvent(event);
    const sendClickTrackingEvent = () => sendEvent(AmplitudeEvents.ID_TRACKING);
    const sendClickCopyEvent = () => sendEvent(AmplitudeEvents.ID_TRACKING_COPY);

    return (
        <section css={getShipmentRowAllTrackingsWrapperStyles}>
            <Typography component="span" variant="small" css={labelStyles}>
                {t('shipment-row.footer.tracking')}:
            </Typography>
            <ShipmentRowTracking
                tracking={firstTracking}
                trackingUrl={trackingUrl}
                onClickUrl={sendClickTrackingEvent}
                onClickCopy={sendClickCopyEvent}
            />
            {hasMultipleTrackings && (
                <>
                    <div
                        ref={popoverRef}
                        css={getShipmentRowMoreTrackingsStyles}
                        role="button"
                        onClick={toggleIsPopoverOpen}
                    >
                        <Typography component="span" variant="small" css={moreIdsStyles}>
                            {t('shipment-row.footer.more-tracking-ids', { n: hiddenTrackings.length })}
                        </Typography>
                    </div>
                    <Popover isOpen={isPopoverOpen} onClickAway={toggleIsPopoverOpen} reference={popoverRef.current}>
                        <section css={getShipmentRowExtraIdsStyles}>
                            {hiddenTrackings.map((tracking?: string) => {
                                if (!tracking) return null;

                                return (
                                    <ShipmentRowTracking
                                        key={tracking}
                                        tracking={tracking}
                                        trackingUrl={trackingUrl}
                                        onClickUrl={sendClickTrackingEvent}
                                        onClickCopy={sendClickCopyEvent}
                                    />
                                );
                            })}
                        </section>
                    </Popover>
                </>
            )}
        </section>
    );
};
