import { Radio, WithCommonProps } from '@shipengine/giger';
import { ClientType } from '@packlink/packlink-sdk';
import { ChangeEvent } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { getBillingClientTypeStyles } from './BillingClientTypeStyles';

type BillingClientTypeProps = WithCommonProps<{
    onChange: (e: ClientType) => void;
    type: ClientType;
}>;

export const BillingClientType = ({ type, onChange, ...rest }: BillingClientTypeProps): JSX.Element => {
    const { t } = useTranslation();
    const onRadioChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => onChange(e.target.value as ClientType);

    return (
        <div css={getBillingClientTypeStyles} {...rest}>
            <Radio
                value={ClientType.AUTONOMOUS}
                name="type"
                checked={ClientType.AUTONOMOUS === type}
                onChange={onRadioChangeHandler}
            >
                {t('form.label.autonomous')}
            </Radio>
            <Radio
                value={ClientType.BUSINESS}
                name="type"
                checked={ClientType.BUSINESS === type}
                onChange={onRadioChangeHandler}
            >
                {t('form.label.company')}
            </Radio>
        </div>
    );
};
