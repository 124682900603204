import { Route, Routes } from 'react-router';
import { NewTicket } from '@SupportCenter/pages/NewTicket/NewTicket';
import { FaqAndSupportTicketsTable } from '@SupportCenter/pages/FaqAndSupportTicketsTable/FaqAndSupportTicketsTable';
import { TicketDetails } from '@SupportCenter/pages/TicketDetails/TicketDetails';
import { SupportCenterLayout } from './SupportCenterLayout';

export function SupportCenterRouter(): JSX.Element {
    return (
        <Routes>
            <Route element={<SupportCenterLayout />}>
                <Route path="/" element={<FaqAndSupportTicketsTable />} />
                <Route path="/new-ticket" element={<NewTicket />} />
                <Route path="/details/:id" element={<TicketDetails />} />
            </Route>
        </Routes>
    );
}
