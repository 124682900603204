import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const sectionStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    });

export const getSpinnerStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        marginTop: theme.spacing(6),
    });

export const getNotificationStyles = (theme: Theme): SerializedStyles =>
    css({
        width: theme.breakPoints.mobileLarge,
        marginBottom: theme.spacing(4),

        [theme.mediaQuery('tablet')]: {
            width: 634,
        },
    });
