import { FC } from 'react';

import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { OnboardingProvider, OnboardingStart } from '@packlink/packlink-sdk';
import { getCookie, removeCookie, setCookie } from '@packlink/utils';

import { ActivateStartFlow } from '../ActivateStartFlow';

export const EbayActivateStartFlow: FC = (): JSX.Element => {
    const cleanSessionId = (): void => {
        const sessionIdCookie = getCookie('sessionId');
        if (sessionIdCookie) {
            removeCookie('sessionId');
        }
    };

    const beforeRedirect = async (onboardingStartFlow: OnboardingStart): Promise<void> => {
        const queryParams = new URLSearchParams(onboardingStartFlow.redirectUrl);
        const sessionId = queryParams.get('SessID') ?? '';
        setCookie('sessionId', sessionId);
    };

    cleanSessionId();

    return (
        <ActivateStartFlow
            beforeRedirect={beforeRedirect}
            provider={OnboardingProvider.EBAY}
            integrationAlias={EcommerceAlias.Ebay}
        />
    );
};
