import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getCheckoutLayoutStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flex: '1 1 auto',
        margin: 'auto',
        maxWidth: '1330px',
        position: 'relative',
        background: theme.palette.white,
        paddingBottom: theme.spacing(4),

        [theme.mediaQuery('desktop')]: {
            paddingBottom: 0,
            minWidth: '1280px',
        },
    });

export const getMainStyles = (): SerializedStyles =>
    css({
        flex: '1 1 75%',
    });

export const getAsideStyles = (theme: Theme): SerializedStyles =>
    css({
        flex: '0 0 25%',
        padding: theme.spacing(3),
        borderLeft: `1px solid ${theme.palette.gray.main}`,
        background: theme.palette.white,
    });
