import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(2),
    });

export const getContentStyles = (): SerializedStyles =>
    css({
        display: 'flex',
    });

export const getIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
        color: theme.palette.error.main,
    });

// The z-index is needed because we use the Popover inside the SidePanel.
// Without this, the Popover would not be visible because of the original elevation the Popover has in Giger.
export const getPopoverStyles = (): SerializedStyles =>
    css({
        zIndex: 1009,
    });
