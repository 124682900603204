import { getCheckoutPriceDetails } from '@store/selectors/checkout';
import { useSelector } from 'react-redux';
import { PriceSummary } from '../PriceSummary/PriceSummary';
import { ShipmentSummary } from '../ShipmentSummary/ShipmentSummary';
import { SidebarButtons } from '../SidebarButtons/SidebarButtons';
import {
    getSidebarSummaryButtonsSectionStyles,
    getSidebarSummarySectionStyles,
    getSidebarSummaryStyles,
} from './SidebarSummaryStyles';

export const SidebarSummary = (): JSX.Element => {
    const priceDetails = useSelector(getCheckoutPriceDetails);

    return (
        <div css={getSidebarSummaryStyles}>
            <section css={getSidebarSummaryButtonsSectionStyles}>
                <SidebarButtons />
            </section>
            <section css={getSidebarSummarySectionStyles}>
                <ShipmentSummary />
                {priceDetails && <PriceSummary priceDetails={priceDetails} />}
            </section>
        </div>
    );
};
