import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Typography } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { Currency, IPriceProducts, IShipment, Shipment } from '@packlink/packlink-sdk';

import { getBenefitIconStyles, getOwnContractIconStyles } from './PaymentSummaryAccordionSectionItemStyles';
import {
    getBenefitsItemStyles,
    getBenefitsStyles,
    getContentDescriptionStyles,
    getContentDetailsStyles,
    getContentPriceStyles,
    getContentReferenceStyles,
    getContentShipmentStyles,
    getContentTextStyles,
} from '../PaymentSummaryAccordionStyles';

interface IPaymentSummaryAccordionSectionItemProps {
    shipment: Shipment<IShipment>;
}

export const PaymentSummaryAccordionSectionItem = ({
    shipment,
}: IPaymentSummaryAccordionSectionItemProps): JSX.Element => {
    const { t } = useTranslation();

    const addressString = `${shipment.to?.firstName} ${shipment.to?.lastName} - ${shipment.to?.street1} (${shipment.to?.city} ${shipment.to?.zipCode})`;

    return (
        <li css={getContentShipmentStyles} key={shipment.packlinkReference}>
            <Typography component="p" variant="small" css={getContentDescriptionStyles}>
                {shipment.content?.description}
            </Typography>
            <Typography component="p" variant="small" css={getContentTextStyles}>
                {addressString}
            </Typography>
            <div css={getContentDetailsStyles}>
                <Typography component="span" variant="small" css={getContentReferenceStyles}>
                    {shipment.packlinkReference}
                </Typography>
                <Typography component="span" variant="small" css={getContentPriceStyles}>
                    {shipment.service?.isOwnContract && (
                        <Icon css={getOwnContractIconStyles} name={IconNames.CONTRACT} size={IconSize.SIZE_SMALL} />
                    )}
                    {Currency.format(shipment.price?.products?.porterage.totalPrice || 0, shipment.currency)}
                </Typography>
            </div>
            <div css={getBenefitsStyles}>
                <Benefit
                    label={t('payment-details.item.insurance')}
                    productKey="insurance"
                    products={shipment.price?.products}
                />
                <Benefit
                    label={t('checkout.price-summary.management-fee')}
                    productKey="managementFee"
                    products={shipment.price?.products}
                />
                <Benefit
                    label={t('payment-details.item.proof-of-delivery')}
                    productKey="proofOfDelivery"
                    products={shipment.price?.products}
                />
                <Benefit
                    label={t('payment-details.item.adult-signature')}
                    productKey="adultSignature"
                    products={shipment.price?.products}
                />
                <Benefit
                    label={t('payment-details.item.additional-handling')}
                    productKey="additionalHandling"
                    products={shipment.price?.products}
                />
                <Benefit
                    label={shipment.price?.products.voucher?.name || t('payment-details.item.voucher.default-text')}
                    productKey="voucher"
                    products={shipment.price?.products}
                />
            </div>
        </li>
    );
};

const Benefit = ({
    label,
    productKey,
    products,
    iconName,
}: {
    label: string;
    productKey: keyof IPriceProducts;
    products?: IPriceProducts;
    iconName?: IconNames;
}): JSX.Element | null =>
    products?.[productKey] && products[productKey].totalPrice !== 0 ? (
        <span css={getBenefitsItemStyles}>
            <Typography component="span" variant="small">
                {label}
            </Typography>
            <Typography component="span" variant="small">
                {iconName && <Icon css={getBenefitIconStyles} name={iconName} size={IconSize.SIZE_SMALL} />}{' '}
                {Currency.format(products?.[productKey]?.totalPrice || 0, products?.[productKey]?.currency)}
            </Typography>
        </span>
    ) : null;
