import { AddressesSettingsNavBar } from '@Addresses/components/AddressesSettingsNavBar/AddressesSettingsNavBar';
import { AddressesProvider } from '../../context/addresses';
import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { WarehousesBook } from './components/WarehousesBook/WarehousesBook';

const MAX_ADDRESSES = 8;

export function SenderSettings(): JSX.Element {
    return (
        <AddressesProvider maxAddresses={MAX_ADDRESSES}>
            <SettingsPage>
                <AddressesSettingsNavBar />

                <WarehousesBook />
            </SettingsPage>
        </AddressesProvider>
    );
}
