import { FormField, Input, Textarea } from '@shipengine/formik-giger';
import { FastField } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { getFormElementStyles, getFormFileInputStyles, getFormInputStyles } from '../../../styles/FormStyles';
import { NewTicketFieldset } from '../NewTicketFieldset/NewTicketFieldset';
import { UploadAttachmentField } from '@SupportCenter/components/UploadAttachment/UploadAttachmentField';
import { FieldMessageType } from '@shipengine/giger';
import { useContext } from 'react';
import { FormWithShipmentContext } from '../FormWithShipment/FormWithShipmentData';
import { Currency } from '@packlink/packlink-sdk';

export function SupportTicketClaimFields(): JSX.Element {
    const { t } = useTranslation();
    const { shipment } = useContext(FormWithShipmentContext);

    return (
        <>
            <NewTicketFieldset
                css={getFormElementStyles}
                title={t('support-center.claim-fields.claim-amount-label')}
                description={t('support-center.claim-fields.claim-amount-description')}
            >
                <FormField name="amount">
                    <FastField
                        component={Input}
                        css={getFormInputStyles}
                        label={t('support-center.claim-fields.claim-amount-label')}
                        type="number"
                        rightContent={Currency.getCurrencySymbol(shipment.currency)}
                    />
                </FormField>
            </NewTicketFieldset>
            <NewTicketFieldset
                css={getFormFileInputStyles}
                title={t('support-center.claim-fields.proof-of-value-label')}
                description={t('support-center.claim-fields.proof-of-value-description')}
            >
                <FastField
                    component={UploadAttachmentField}
                    name="proofOfValue"
                    label={t('support-center.new-ticket.attachment-restrictions-label')}
                    message={{
                        content: t('support-center.claim-fields.proof-of-value-required-message'),
                        type: FieldMessageType.INFO,
                    }}
                    data-id="support-ticket-proof-of-value"
                />
            </NewTicketFieldset>
            <NewTicketFieldset
                css={getFormElementStyles}
                title={t('support-center.claim-fields.product-description-label')}
            >
                <FormField
                    name="productDescription"
                    message={{
                        content: t('support-center.claim-fields.product-description-placeholder'),
                        type: FieldMessageType.HINT,
                    }}
                >
                    <FastField
                        component={Textarea}
                        css={getFormInputStyles}
                        label={t('support-center.claim-fields.product-description-label')}
                    />
                </FormField>
            </NewTicketFieldset>
        </>
    );
}
