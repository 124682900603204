import { Field, Form, Formik } from 'formik';

import { Input, Textarea, FormField } from '@shipengine/formik-giger';
import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonVariant, ButtonSize, ButtonColor } from '@shipengine/giger';

import {
    getSendLabelByEmailDialogFormSubmitButtonStyles,
    getSendLabelByEmailDialogFormStyles,
} from './SendLabelByEmailDialogFormStyles';

import {
    getSendLabelByEmailFormValidationSchema,
    SendLabelByEmailFormData,
    sendLabelByEmailFormInitialValues,
} from './SendLabelByEmailDialogFormData';

type SendLabelByEmailDialogFormProps = {
    onSubmit: (data: SendLabelByEmailFormData) => void;
};

export const SendLabelByEmailDialogForm = ({ onSubmit }: SendLabelByEmailDialogFormProps): JSX.Element => {
    const { t } = useTranslation();
    const validationSchema = getSendLabelByEmailFormValidationSchema(t);

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={sendLabelByEmailFormInitialValues}
            validationSchema={validationSchema}
        >
            <Form css={getSendLabelByEmailDialogFormStyles}>
                <FormField name="mail">
                    <Field component={Input} label={t('form.label.email')} type="email" />
                </FormField>

                <FormField name="message">
                    <Field component={Textarea} label={t('post-sale.details.send-label-by-email-form-message-label')} />
                </FormField>

                <Button
                    variant={ButtonVariant.FILLED}
                    size={ButtonSize.SMALL}
                    color={ButtonColor.PRIMARY}
                    data-id="send-labels-popover-send-button"
                    css={getSendLabelByEmailDialogFormSubmitButtonStyles}
                    type="submit"
                    isFullWidth
                >
                    {t('post-sale.details.send-label-by-email-submit-label')}
                </Button>
            </Form>
        </Formik>
    );
};
