import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getConnectionCardListStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            flexFlow: 'row wrap',

            '> article': {
                margin: `0 ${theme.spacing(3)}px ${theme.spacing(3)}px 0`,
            },
        });
