import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { OwnContractModel, OwnContractModelRepository } from '@packlink/packlink-sdk';
import { GTMEvents } from '@packlink/metrics';
import { Typography } from '@shipengine/giger';
import { ConnectionCard } from '@components/ConnectionCard/ConnectionCard';
import { ConnectionCardList } from '@components/ConnectionCardList/ConnectionCardList';
import {
    ServiceSelectionCard,
    ServiceSelectionOption,
    ServiceSelectionOptionValue,
} from '@components/ServiceSelectionCard/ServiceSelectionCard';
import { apiClient } from '@sdk';
import { useSidePanel } from '@hooks/useSidePanel';
import { OwnContractPanel } from '@OwnContract/components/OwnContractPanel/OwnContractPanel';
import { OwnContractDeactivateDialog } from '@OwnContract/components/OwnContractDeactivateDialog';
import { logSdkError } from '@utils/logger';
import { ServiceUtils } from '@utils/ServiceUtils';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { PanelName } from '@types';
import { ownContractListTitleStyles } from './OwnContractListStyles';
import { ServiceSelectionPanel } from './ServiceSelectionPanel/ServiceSelectionPanel';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useAmplitude } from '@hooks/useAmplitude';

const contractModelRepository = new OwnContractModelRepository(apiClient);

export const OwnContractList = (): JSX.Element => {
    const { t } = useTranslation();
    const { sendGtmEvent } = useGoogleTagManager();

    const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
    const [selectedOwnContract, setSelectedOwnContract] = useState<OwnContractModel | undefined>(undefined);
    const [availableOwnContracts, setAvailableOwnContracts] = useState<Array<OwnContractModel>>([]);
    const [selectedServiceAction, setSelectedServiceAction] = useState<ServiceSelectionOptionValue | undefined>(
        undefined,
    );
    const { open: openOwnContractPanel, close: closeOwnContractPanel } = useSidePanel(PanelName.OWN_CONTRACT);
    const { open: openServiceSelectionPanel, close: closeServiceSelectionPanel } = useSidePanel(
        PanelName.OWN_CONTRACT_SERVICE_SELECTION,
    );
    const isOwnContractPanelEnabled = selectedOwnContract && !selectedOwnContract.isRegistered;

    const isServiceSelectionPanelEnabled =
        selectedOwnContract && selectedServiceAction === ServiceSelectionOptionValue.EDIT_SERVICES;

    const isDeactivateDialogEnabled =
        selectedOwnContract && selectedServiceAction === ServiceSelectionOptionValue.DEACTIVATE;

    const { sendAmplitudeEvent } = useAmplitude();

    const onOwnContractSelected = (ownContract: OwnContractModel): void => {
        setSelectedOwnContract((prevSelectedOwnContract?: OwnContractModel) => {
            const selectedOwnContract = prevSelectedOwnContract === ownContract ? undefined : ownContract;

            if (selectedOwnContract) {
                sendAmplitudeEvent(AmplitudeEvents.ACTIVATE_BYOA, {
                    [AmplitudeProperties.CARRIER]: selectedOwnContract.carrierName,
                });

                sendGtmEvent(GTMEvents.OWN_CONTRACT, {
                    action: GTMActionFieldOption.CARRIER_CONTRACT_SELECTED,
                    carrierName: selectedOwnContract.carrierName,
                });
            }
            return selectedOwnContract;
        });
    };

    const onServiceOptionSelected = (response: ServiceSelectionOption, ownContract: OwnContractModel): void => {
        setSelectedServiceAction(response.value);
        setSelectedOwnContract(ownContract);
        if (response.value === ServiceSelectionOptionValue.DEACTIVATE) {
            setIsDeactivateDialogOpen(true);
        }
    };

    const onSelectServicesAction = (carrierId: string): void => {
        setSelectedOwnContract(availableOwnContracts.find((ownContract) => ownContract.carrierId === carrierId));
        setSelectedServiceAction(ServiceSelectionOptionValue.EDIT_SERVICES);
    };

    const onPanelClose = useCallback((): void => {
        setSelectedOwnContract(undefined);
        setSelectedServiceAction(undefined);
    }, [setSelectedOwnContract]);

    const isCardSelected = (ownContract: OwnContractModel): boolean => selectedOwnContract?.id === ownContract.id;

    const fetchModels = () =>
        contractModelRepository
            .getModels()
            .then((contractModels) => {
                setAvailableOwnContracts(contractModels);

                return contractModels;
            })
            .catch(logSdkError);

    const onDeactivateSuccess = (): void => {
        fetchModels().then((ownContractModels: OwnContractModel[] | void) => {
            if (selectedOwnContract && ownContractModels) {
                const newSelectedOwnContract = ownContractModels.find(
                    (ownContractModel: OwnContractModel): boolean => selectedOwnContract.id === ownContractModel.id,
                );

                setSelectedOwnContract(newSelectedOwnContract);
            }
        });
    };

    const handleDialogDeactivate = (): void => {
        fetchModels();
        handleCloseDialog();
    };

    const handleSaveServices = (): void => {
        fetchModels();
        setSelectedServiceAction(undefined);
    };

    const handleCloseDialog = (): void => {
        setIsDeactivateDialogOpen(false);
        setSelectedServiceAction(undefined);
    };

    useEffect(() => {
        if (selectedServiceAction === ServiceSelectionOptionValue.EDIT_SERVICES) {
            closeOwnContractPanel();
            openServiceSelectionPanel();
        } else if (!selectedOwnContract?.isRegistered) {
            openOwnContractPanel();
        } else {
            closeOwnContractPanel();
            closeServiceSelectionPanel();
        }
    }, [
        selectedOwnContract,
        openOwnContractPanel,
        closeOwnContractPanel,
        openServiceSelectionPanel,
        closeServiceSelectionPanel,
        selectedServiceAction,
    ]);

    useEffect(() => {
        fetchModels();
    }, []);

    return (
        <>
            <Typography variant="subtitle1" component="h3" css={ownContractListTitleStyles}>
                {t('own-contract.list.title')}
            </Typography>
            <ConnectionCardList>
                {availableOwnContracts.map(
                    (ownContract: OwnContractModel): JSX.Element =>
                        ownContract.isRegistered ? (
                            <ServiceSelectionCard
                                key={ownContract.id}
                                data-id={`service-selection-card-${ownContract.id}`}
                                selected={isCardSelected(ownContract)}
                                onClick={(response) => onServiceOptionSelected(response, ownContract)}
                                name={ownContract.carrierName as string}
                                logoUrl={ServiceUtils.getCarrierLogo(ownContract.logoId as string)}
                                services={ownContract.services}
                            />
                        ) : (
                            <ConnectionCard
                                key={ownContract.id}
                                data-id={`own-contract-card-${ownContract.id}`}
                                selected={isCardSelected(ownContract)}
                                onClick={(): void => onOwnContractSelected(ownContract)}
                                name={ownContract.carrierName as string}
                                actionName={t(
                                    ownContract.isRegistered ? 'own-contract.list.edit' : 'own-contract.list.activate',
                                )}
                                logoUrl={ServiceUtils.getCarrierLogo(ownContract.logoId as string)}
                            />
                        ),
                )}
                {isOwnContractPanelEnabled && (
                    <OwnContractPanel
                        onClose={onPanelClose}
                        onDeactivateSuccess={onDeactivateSuccess}
                        ownContract={selectedOwnContract}
                        onRegisterSuccess={fetchModels}
                        onSelectServices={onSelectServicesAction}
                    />
                )}

                {isServiceSelectionPanelEnabled && (
                    <ServiceSelectionPanel
                        onClose={onPanelClose}
                        onSendSuccess={handleSaveServices}
                        logoUrl={ServiceUtils.getCarrierLogo(selectedOwnContract.logoId as string)}
                        ownContract={selectedOwnContract}
                    />
                )}

                {isDeactivateDialogEnabled && (
                    <OwnContractDeactivateDialog
                        isOpen={isDeactivateDialogOpen}
                        ownContract={selectedOwnContract}
                        onClose={handleCloseDialog}
                        onDeactivateSuccess={handleDialogDeactivate}
                    />
                )}
            </ConnectionCardList>
        </>
    );
};
