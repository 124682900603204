import { AddressesSettingsNavBar } from '@Addresses/components/AddressesSettingsNavBar/AddressesSettingsNavBar';
import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { AddressesProvider } from '../../context/addresses';
import { RecipientsBook } from './components/RecipientsBook/RecipientsBook';

// TODO: add from config when migrated https://auctane.atlassian.net/browse/RET-845
const MAX_ADDRESSES = 500;

export function RecipientSettings(): JSX.Element {
    return (
        <AddressesProvider maxAddresses={MAX_ADDRESSES}>
            <SettingsPage>
                <AddressesSettingsNavBar />

                <RecipientsBook />
            </SettingsPage>
        </AddressesProvider>
    );
}
