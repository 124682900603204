import { css, SerializedStyles } from '@emotion/react';

export const containerStyles = (height: number, isMobile: boolean): SerializedStyles =>
    css({
        display: 'flex',
        height: height,
        width: '100%',

        '> *': {
            maxWidth: !isMobile ? '50%' : undefined,
        },
    });
