import React from 'react';
import { Typography } from '@shipengine/giger';
import { settingsPageHeaderStyles, settingsPageTitleStyles } from './SettingsPageStyles';

interface ISettingsPageHeader {
    description?: string;
    children: React.ReactNode;
}

export const SettingsPageHeader: React.FC<ISettingsPageHeader> = (props: ISettingsPageHeader): JSX.Element => {
    const { children, description } = props;
    return (
        <header css={settingsPageHeaderStyles(!!description)}>
            <Typography css={settingsPageTitleStyles} component="h2" variant="heading3">
                {children}
            </Typography>
            {description && <p>{description}</p>}
        </header>
    );
};
