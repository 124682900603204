import { useMemo } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import { IAddressBook } from '@packlink/packlink-sdk';
import {
    Button,
    ButtonColor,
    ButtonSize,
    ButtonVariant,
    ISelectAutocompleteOption,
    Typography,
    WithCommonProps,
} from '@shipengine/giger';
import { LocationForm } from '@components/LocationForm/LocationForm';
import { getLocationValidation } from '@components/LocationForm/locationFormValidation';
import { Origin } from '@types';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { addressFormStyles, addressFormTitleStyles, buttonStyles } from './AddressFormStyles';
import { useAmplitude } from '@hooks/useAmplitude';

interface IAddressFormProps {
    origin: Origin;
    address?: IAddressBook;
    onCancel: () => void;
    onSave: (values: AddressFormValues, formikHelpers: FormikHelpers<IAddressForm>) => void;
}

export type AddressFormValues = IAddressBook & {
    id?: string;
    postalCode?: ISelectAutocompleteOption;
    country?: ISelectAutocompleteOption;
    street1: string;
    state?: string;
};

interface IAddressForm {
    from?: AddressFormValues | Record<string, never>;
    to?: AddressFormValues | Record<string, never>;
}

export function AddressForm({
    address,
    origin,
    onCancel,
    onSave,
    ...rest
}: WithCommonProps<IAddressFormProps>): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();

    const section = origin === Origin.FROM ? 'sender' : 'recipient';

    const initialFormValues = useMemo((): IAddressForm => {
        function mapToLocationFormValues(address: IAddressBook): AddressFormValues {
            return {
                ...address,
                postalCode: {
                    label: `${address.zipCode} - ${address.city}`,
                    value: address.postalCodeId,
                },
                country: {
                    label: address.postalZone.translations[locale],
                    value: address.postalZone.id,
                },
                street1: address?.address,
            };
        }

        return {
            [origin]: address ? mapToLocationFormValues(address) : {},
        };
    }, [address, origin, locale]);

    const validationSchema = useMemo(() => {
        return yup.object().shape({
            [origin]: getLocationValidation(origin, false, t, true),
        });
    }, [t, origin]);

    const handleCancel = () => {
        sendAmplitudeEvent(AmplitudeEvents.SETTINGS_CANCEL_ADDRESS_CLICK, {
            [AmplitudeProperties.SECTION]: section,
        });

        onCancel();
    };

    const submitForm = (values: IAddressForm, formikHelpers: FormikHelpers<IAddressForm>) => {
        sendAmplitudeEvent(AmplitudeEvents.SETTINGS_SAVE_ADDRESS_CLICK, {
            [AmplitudeProperties.SECTION]: section,
        });

        return onSave(values[origin] as AddressFormValues, formikHelpers);
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={submitForm}
        >
            {(formProps: FormikProps<IAddressForm>): React.ReactNode => {
                return (
                    <Form css={addressFormStyles} {...rest}>
                        <Typography css={addressFormTitleStyles} component="h4" variant="heading5">
                            {address ? t('addresses.settings.edit-address') : t('addresses.settings.create-address')}
                        </Typography>

                        <LocationForm
                            name={origin}
                            hasAlias
                            initialPostalZoneId={address?.postalZone.id}
                            initialPostalCodeId={address?.postalCodeId}
                        />

                        <div css={buttonStyles}>
                            <Button
                                color={ButtonColor.PRIMARY}
                                variant={ButtonVariant.OUTLINED}
                                size={ButtonSize.MEDIUM}
                                onClick={handleCancel}
                                data-id="cancel-address-button"
                            >
                                {t('settings.form.cancel')}
                            </Button>
                            <Button
                                color={ButtonColor.PRIMARY}
                                size={ButtonSize.MEDIUM}
                                disabled={!formProps.isValid}
                                isLoading={formProps.isSubmitting}
                                type="submit"
                                data-id="save-address-button"
                            >
                                {address ? t('settings.form.save') : t('settings.form.create')}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

export function formValuesToAddress(values: AddressFormValues, locale: string): IAddressBook {
    return {
        ...values,
        address: values.street1,
        postalCodeId: values.postalCode?.value || '',
        ...(values.state && {
            postalZone: {
                id: values.country?.value || '',
                translations: { [locale]: values.state },
            },
        }),
    };
}
