import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const messageStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `${theme.spacing(2)}px 0`,
    });

export const getWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
        backgroundColor: theme.palette.gray.megaLight,
        borderBottom: `1px solid ${theme.palette.gray.ultraLight}`,

        'button:last-child': {
            marginLeft: theme.spacing(2),
        },
    });
