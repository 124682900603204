import { PaymentMethod, PaymentType } from '@packlink/packlink-sdk';

/**
 * List of pusher events.
 *
 * Other pusher events enums based on domains:
 * - IntegrationEvent
 * - CsvImportEvent
 * - InvoicingEvent
 */
export enum EventName {
    LABEL_MERGE_READY = 'multipleShipmentslabelMerge-ready',
    PAYMENT_METHOD_UPSERT = 'payment-method-upsert',
    SHIPMENT_CREATED = 'shipment_created',
    SHIPMENT_DELETED = 'shipment_deleted',
    SHIPMENT_UPDATED = 'shipment_updated',
    SHIPMENTS_REPORT = 'shipments-report',
}

export interface IPaymentMethodUpsertEvent {
    payment_method: PaymentMethod;
    payment_type: PaymentType;
    id: string;
}

export interface IShipmentsReportEvent {
    url?: string;
    expiration_time?: string;
    errors?: Record<string, string>;
}
