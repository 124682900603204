import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketChatMessageDateStyles = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
});

export const getTicketChatMessageHourStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.main,
    });
