import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const pageStyles = (): SerializedStyles =>
    css({
        ul: {
            margin: 0,
            padding: 0,
        },
    });

export const tabStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
    });

export const tagStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });
