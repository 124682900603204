import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { ISupportUser } from '@packlink/packlink-sdk';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { getUserEmail } from '@store/selectors/user';
import { ITenantConfig, TenantZendeskConfig } from '@types';
import { useMetrics } from '@providers/MetricsProvider';

import { ZendeskWidgetClient } from './ZendeskWidgetClient';
import { useTranslation } from '@packlink/translation-provider';

type ZendeskWidgetClientOutput = {
    client: ZendeskWidgetClient | undefined;
    user: ISupportUser | undefined;
    isLoading: boolean;
    config: TenantZendeskConfig;
};

export function useZendeskWidgetClient(): ZendeskWidgetClientOutput {
    const {
        i18n: { language: locale },
    } = useTranslation();
    const { zendesk: config } = useTenantConfig<ITenantConfig>();
    const [client, setClient] = useState<ZendeskWidgetClient | undefined>(undefined);
    const userEmail = useSelector(getUserEmail);
    const metrics = useMetrics();

    const { data: user, isLoading: isUserLoading } = useFetchCurrentSupportUser();

    useEffect(() => {
        async function createClient() {
            setClient(
                await ZendeskWidgetClient.create({
                    key: config.widgetKey,
                    locale: config.locale,
                    user,
                    tracker: metrics,
                    talkLineId: config.talkLineId,
                    userEmail,
                }),
            );
        }

        if (config.widgetEnabled && !isUserLoading) {
            createClient();
        }
    }, [config, isUserLoading, locale, user, userEmail, metrics]);

    return {
        client,
        user,
        config,
        isLoading: !client || isUserLoading,
    };
}

function useFetchCurrentSupportUser() {
    const sdk = useSdk();

    return useQuery('supportUser', () => sdk.v1.supportCenter.getCurrentUser());
}
