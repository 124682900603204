import { useCallback, useState, useRef, useEffect } from 'react';
import { ButtonAction } from '../DownloadLabel';
import { useStatus, Status, IUseStatus } from '../../../hooks/useStatus';
import { IUseDownloadLabelProps } from './useDownloadLabel';

interface IUseDialogDownloadLabelProps {
    onClick?: IUseDownloadLabelProps['onFetching'];
}

export interface IUseDialogDownloadLabel {
    closeDialog: () => void;
    onFinish: IUseDownloadLabelProps['onFinish'];
    onError: IUseDownloadLabelProps['onError'];
    onFetching: IUseDownloadLabelProps['onFetching'];
    status: {
        isDialogOpen: boolean;
        setStatus: IUseStatus['setStatus'];
        isError: boolean;
        isLoading: boolean;
        isReady: boolean;
    };
}

export const useDialogDownloadLabel = ({ onClick }: IUseDialogDownloadLabelProps): IUseDialogDownloadLabel => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const { setStatus, isError, isLoading, isReady } = useStatus();
    const closeTimeout = useRef<ReturnType<typeof setTimeout>>();

    const closeDialog = () => {
        setIsDialogOpen(false);
        closeTimeout.current && clearTimeout(closeTimeout.current);
    };

    const onFinish = useCallback((): void => {
        setStatus(Status.READY);

        closeTimeout.current = setTimeout(() => {
            setIsDialogOpen(false);
        }, 3000);
    }, [setStatus]);

    const onError = useCallback((): void => {
        setStatus(Status.ERROR);
    }, [setStatus]);

    const onFetching = useCallback(
        (action: ButtonAction, eventProps: Record<string, unknown>): void => {
            onClick?.(action, eventProps);
            setStatus(Status.LOADING);
            setIsDialogOpen(true);
        },
        [onClick, setStatus],
    );

    useEffect(() => {
        return (): void => closeTimeout.current && clearTimeout(closeTimeout.current);
    }, []);

    return {
        closeDialog,
        onFinish,
        onError,
        onFetching,
        status: {
            isDialogOpen,
            setStatus,
            isError,
            isLoading,
            isReady,
        },
    };
};
