import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSelectAddressToStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
    });

export const getDetailsFormHeadingStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.secondary.dark,
        marginBottom: theme.spacing(),
    });

export const getInsuranceSubtitleTextStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',

        '.icon': {
            marginRight: theme.spacing(0.5),
        },
    });

export const getSwitchCardPriceStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'flex-end',
        paddingBottom: `${theme.spacing(2)}px`,
    });
