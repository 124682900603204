import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getButtonDropdownMainStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        position: 'relative',
    });

export const getButtonWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        borderRight: `1px solid ${theme.palette.primary.dark}`,
    });

export const getButtonDropdownStyles = (): SerializedStyles =>
    css({
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    });

export const getButtonDropdownHandleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'center',
        width: theme.spacing(4),
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    });

export const getButtonDropdownContainerStyles =
    (isOpen?: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            position: 'absolute',
            top: '100%',
            right: 0,
            zIndex: 1,
            display: isOpen ? 'block' : 'none',
            minWidth: '100%',
            margin: `${theme.spacing()}px 0 0 0`,
            padding: 0,
            listStyle: 'none',
            backgroundColor: theme.palette.white,
            border: `1px solid ${theme.palette.gray.light}`,
            borderRadius: theme.spacing(1 / 2),
            boxShadow: theme.elevations.above.boxShadow,
        });

export const getButtonDropdownItemStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        padding: `0 ${theme.spacing(2)}px`,
        whiteSpace: 'nowrap',
        cursor: 'pointer',

        ':hover': {
            backgroundColor: theme.palette.gray.ultraLight,
        },
    });

export const getItemCaptionStyles = (): SerializedStyles =>
    css({
        marginBottom: 0,
    });
