import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BraintreeError } from 'braintree-web';
import { InlineNotification, NotificationType, SidePanel, SlideInPosition } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { PaymentMethod } from '@packlink/packlink-sdk';
import { PaymentStep as PaymentStepType } from '@packlink/payment-options';
import { PanelName } from '@types';
import { useSidePanel } from '@hooks/useSidePanel';
import { useAmplitude } from '@hooks/useAmplitude';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { AppDispatch } from '@store';
import { savePaymentMethod } from '@store/actions/payment';
import { useEditClientSubscription } from '@Subscriptions/hooks/useEditClientSubscription';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';
import { getInlineNotificationStyles } from '@common/components/SubscriptionPanel/SubscriptionPanelPaymentSummaryStyles';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import {
    onSaveProps,
    SubscriptionPaymentOptions,
} from '@common/components/SubscriptionPanel/SubscriptionPaymentOptions/SubscriptionPaymentOptions';

export const EditPaymentMethodPanel = (): JSX.Element => {
    const [selectedOption, setSelectedOption] = useState<PaymentMethod | undefined>();
    const [isEditing, setIsEditing] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showSuccess, setShowSuccess] = useState<boolean>();
    const { t } = useTranslation();
    const { sendAmplitudeSidebarClickEvent, sendAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();
    const { close, isOpen } = useSidePanel(PanelName.EDIT_PAYMENT_METHOD);
    const dispatch = useDispatch<AppDispatch>();
    const { editClientSubscription } = useEditClientSubscription();

    useEffect(() => {
        setErrorMessage(undefined);
        setShowSuccess(false);
        setSelectedOption(undefined);
    }, [isOpen]);

    const handleClose = () => {
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.X, {
            [AmplitudeProperties.PANEL_NAME]: PanelName.EDIT_PAYMENT_METHOD,
        });
        close();
    };

    const handleSuccess = (method: string, type: string) => {
        sendAmplitudeEvent(AmplitudeEvents.PAYMENT_METHOD_UPDATED, {
            [AmplitudeProperties.PAYMENT_METHOD]: method,
            [AmplitudeProperties.PAYMENT_TYPE]: type,
        });
        setShowSuccess(true);
    };

    const editSubscription = (paymentMethodId: string, paymentMethod: PaymentMethod, type: string) => {
        editClientSubscription(
            { paymentMethodId },
            {
                onSuccess: () => handleSuccess(paymentMethod, type),
                onError: () => setErrorMessage('error.payment.setup'),
                onSettled: () => setIsEditing(false),
            },
        );
    };

    const onEditPayment = ({ method, nonce, type }: onSaveProps) => {
        setErrorMessage(undefined);
        setIsEditing(true);

        if (nonce) {
            dispatch(savePaymentMethod(type, method, nonce, undefined))
                .then(({ id }: { id: string }) => {
                    editSubscription(id, method, type);
                })
                .catch((err) => {
                    setErrorMessage(err.message);
                    setIsEditing(false);
                });
        } else {
            setErrorMessage('error.payment.verification');
            setIsEditing(false);
        }
    };

    const sendEvent = (paymentMethod?: PaymentMethod) => {
        sendAmplitudeClickEvent(AmplitudeEvents.PAYMENT_METHOD_EDIT, {
            [AmplitudeProperties.PAYMENT_METHOD]: paymentMethod,
        });
    };

    const handleEditPaymentError = (
        _step: PaymentStepType,
        _paymentMethod?: PaymentMethod,
        _error?: BraintreeError | Error,
        translationKey?: string,
    ) => {
        setErrorMessage(translationKey);
        setIsEditing(false);
    };

    const handleChangePaymentOption = (paymentMethod: PaymentMethod) => {
        setSelectedOption(paymentMethod);
    };

    return (
        <SidePanel
            css={getSidePanelClipPathStyles(SlideInPosition.RIGHT)}
            isOpen={isOpen}
            containerId="shipments-main"
            applyBackdropToBody={false}
            onBackdropClick={close}
            header={<SidePanelHeader onAction={handleClose} title={t('edit-payment-method.side-panel.header')} />}
        >
            {isOpen && (
                <SidePanelContent>
                    {showSuccess ? (
                        <SidePanelMessage
                            title={t('edit-payment-method.side-panel.success-title')}
                            subtitle={t('edit-payment-method.side-panel.success-message')}
                            type={SidePanelMessageType.SUCCESS}
                        />
                    ) : (
                        <>
                            {errorMessage && (
                                <InlineNotification css={getInlineNotificationStyles} type={NotificationType.ERROR}>
                                    {t(errorMessage)}
                                </InlineNotification>
                            )}
                            <SidePanelContentSection title={t('edit-payment-method.side-panel.section-title')}>
                                {selectedOption === PaymentMethod.CREDITCARD3DSECURE && (
                                    <InlineNotification css={getInlineNotificationStyles} type={NotificationType.INFO}>
                                        {t('edit-payment-method.side-panel.notification-info')}
                                    </InlineNotification>
                                )}
                                <SubscriptionPaymentOptions
                                    isProcessing={isEditing}
                                    section="subscription"
                                    payButtonTexts={{
                                        paypal: t('edit-payment-method.side-panel.cta-text'),
                                        creditCard: t('edit-payment-method.side-panel.cta-text'),
                                    }}
                                    onSave={onEditPayment}
                                    onError={handleEditPaymentError}
                                    onStart={sendEvent}
                                    onChangeOption={handleChangePaymentOption}
                                />
                            </SidePanelContentSection>
                        </>
                    )}
                </SidePanelContent>
            )}
        </SidePanel>
    );
};
