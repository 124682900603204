import { useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';

import { getImageStyles, getContentSectionStyles, getContentContainerStyles } from './InsuranceResponseCSVModalStyles';

interface InsuranceResponseCSVModalProps {
    isError?: boolean;
}

export const InsuranceResponseCSVModal = ({ isError = false }: InsuranceResponseCSVModalProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        cdn: { url: cdnUrl },
    } = config;

    return (
        <section css={getContentContainerStyles}>
            {isError && (
                <div css={getContentSectionStyles}>
                    <Typography variant="body2">{t('error.message.processing')}</Typography>
                    <Typography variant="body2">{t('error.message.try-again-later')}</Typography>
                </div>
            )}
            {!isError && (
                <div css={getContentSectionStyles}>
                    <img
                        css={getImageStyles}
                        src={`${cdnUrl}/pro/statics/images/insurance-box.svg`}
                        alt={t('import-csv.insurance.insuring-text')}
                    />
                    <Typography variant="body2">{t('import-csv.insurance.insuring-text')}</Typography>
                </div>
            )}
        </section>
    );
};
