import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getOwnContractIconStyles = (theme: Theme): SerializedStyles =>
    css({
        alignSelf: 'center',
        color: theme.palette.alert.main,
        marginRight: theme.spacing() / 2,
    });
