import { Order } from '@packlink/packlink-sdk';
import { SidePanel, SlideInPosition } from '@shipengine/giger';
import { PanelName, ShipmentPanelType } from '@types';
import { useShipmentPanel } from '@hooks/useShipmentPanel';

import { ShipmentPanelOtherBenefits } from './ShipmentPanelOtherBenefits/ShipmentPanelOtherBenefits';
import { ShipmentPanelContent } from './ShipmentPanelContent/ShipmentPanelContent';
import { ShipmentPanelServices } from './ShipmentPanelServices/ShipmentPanelServices';
import { ShipmentPanelRecipient } from './ShipmentPanelRecipient/ShipmentPanelRecipient';
import { BulkFlows } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelBulk/typings';
import { ShipmentPanelBulk } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelBulk/ShipmentPanelBulk';
import { useSidePanel } from '@hooks/useSidePanel';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';

interface IShipmentPanelProps {
    onPaymentSuccess: (order: Order) => void;
}

export const ShipmentPanel = (props: IShipmentPanelProps): JSX.Element => {
    const { panelState, closePanelOverlay } = useShipmentPanel();

    const { isOpen: isSidePanelOpen } = useSidePanel(PanelName.SHIPMENTS);
    const shouldDisplayOverlay =
        panelState.panelName !== ShipmentPanelType.BULK ||
        (panelState.panelName === ShipmentPanelType.BULK && panelState.context?.flow !== BulkFlows.ACTIONS);

    const renderActivePanel = (): JSX.Element | null => {
        switch (panelState.panelName) {
            case ShipmentPanelType.OTHER_BENEFITS:
                return <ShipmentPanelOtherBenefits />;
            case ShipmentPanelType.CONTENT:
                return <ShipmentPanelContent />;
            case ShipmentPanelType.SERVICES:
                return <ShipmentPanelServices />;
            case ShipmentPanelType.RECIPIENT:
                return <ShipmentPanelRecipient />;
            case ShipmentPanelType.BULK:
                return <ShipmentPanelBulk {...props} />;
            default:
                return null;
        }
    };

    return (
        <SidePanel
            css={getSidePanelClipPathStyles(SlideInPosition.RIGHT)}
            isOpen={isSidePanelOpen}
            applyBackdropToBody={false}
            includeBackdrop={shouldDisplayOverlay}
            onBackdropClick={closePanelOverlay}
            containerId="shipments-main"
        >
            {renderActivePanel()}
        </SidePanel>
    );
};
