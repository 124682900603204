import { IModuleIntegration } from '@Integrations/types/module-integration';
import { HelpCenter } from '@packlink/provider';
import { TenantBehavior } from '@packlink/tenant-config-provider';

interface IConfigLinks {
    [locale: string]: string;
}

export type ModuleIntegrations = Record<string, IModuleIntegration>;

export type TenantIntegrations = Record<
    string,
    {
        action?: {
            url: Record<string, string>;
        };
        rank_weight?: number;
    }
>;

export type TenantZendeskConfig = {
    widgetEnabled: boolean;
    widgetKey: string;
    locale: string;
    talkEnabled: boolean;
    talkLineId: string;
};

interface BehaviorConfigProps {
    enabled: boolean;
    [name: string]: unknown;
}

export type ServiceSearchRestrictionBasedOnThirdPartyPreferencesType = { sources: string[] };

interface ConfigLink<T> {
    name: T | string;
    url: string;
}

export enum SubscriptionLinks {
    ownContractSupport = 'own-contract-support',
    typeform = 'typeform',
}

export interface SubscriptionBehaviorConfigProps {
    enabled: boolean;
    links: ConfigLink<SubscriptionLinks>[];
}

export interface AvailablePremiumCarriersConfigProps {
    carriers: string[];
}

export interface ITenantConfig {
    impressumLink: string;
    integrations: TenantIntegrations;
    defaultCurrency: string;
    defaultLanguage: string;
    // Used in @packlink/translation-provider
    translationsNamespaces: string[];
    availableLanguages: string[];
    moduleIntegrations: ModuleIntegrations;
    showPowerByPacklink: boolean;
    brand: string;
    title: string;
    volumeDiscounts: boolean;
    showLanguageSelector: boolean;
    canChangePassword: boolean;
    userCanGenerateApiKey: boolean;
    canConnectToEshop: boolean;
    showBuyerAddressDisclaimer: boolean;
    showDeferredPaymentDetails: boolean;
    allowMarketingCalls: boolean;
    showLabelsFilter: boolean;
    isDeferredPartiallySuported: boolean;
    isCSVTutorialAvailable: boolean;
    hasPrintInStoreByDefault: boolean;
    helpCenter: HelpCenter;
    links: Partial<Record<'support-center' | 'support-center-new-ticket', string>>;
    shippingRulesDemo: IConfigLinks;
    zendesk: TenantZendeskConfig;
    directDebitDocument: Record<string, string>;
    behaviors: TenantBehavior<BehaviorConfigProps>[];
}
