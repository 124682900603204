import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const addressFormStyles = (theme: Theme): SerializedStyles =>
    css({
        maxWidth: theme.breakPoints.mobileLarge,
    });

export const addressFormTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const buttonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(4),
        textAlign: 'end',

        '> :not(:last-child)': {
            marginRight: theme.spacing(2),
        },
    });
