import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSendLabelByEmailDialogFormStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        // TODO: FRON-564 - Remove when TextField is migrated to emotion
        '.field': {
            marginBottom: theme.spacing(1),
        },
    });

export const getSendLabelByEmailDialogFormSubmitButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(1),
    });
