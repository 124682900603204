import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getSpinnerStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(6),
        width: '100%',
    });
