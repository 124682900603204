import qs from 'qs';
import { useEffect, useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import { Navigate, useParams, useSearchParams } from 'react-router';
import omitBy from 'lodash.omitby';
import { useTranslation } from '@packlink/translation-provider';
import { ShipmentPanelProvider } from '@context/ShipmentPanel';
import { ShipmentListContent } from '@components/ShipmentList/ShipmentListContent';
import { ShipmentListHeader } from '@components/ShipmentList/ShipmentListHeader/ShipmentListHeader';
import { Inbox } from '@packlink/packlink-sdk';
import { setBulkFilters } from '@store/actions/bulk';
import { setCurrentInbox } from '@store/actions/inbox';
import { AppRoute } from '@pages/router/routes';
import { FILTER_PARAMS } from '@constants/shipmentFilters';
import { ShipmentListActions } from './ShipmentListActions/ShipmentListActions';
import { getNotificationStyles, getShipmentListStyles } from './ShipmentListStyles';
import { SubscriptionNotification } from '@common/components/SubscriptionNotification/SubscriptionNotification';
import { ButtonVariant } from '@shipengine/giger';
import { useLastClientSubscription } from '@Subscriptions/hooks/useClientSubscriptions';
import { AmplitudeEvents } from '@constants/amplitude';

export const ShipmentList = (): JSX.Element => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isActive, isCanceled, isTenantSubscriptionEnabled, isLoading } = useLastClientSubscription();
    const showSubscriptionNotification = isTenantSubscriptionEnabled && !isActive && !isCanceled && !isLoading;
    const { inbox } = useParams();
    const [searchParams, _setSearchParams] = useSearchParams();
    const appInbox = inbox?.toUpperCase().replace(/-/g, '_') as Inbox;
    const validFilters = useMemo(
        () =>
            omitBy(
                qs.parse(searchParams.toString()),
                (_v, key) => !FILTER_PARAMS[key.toUpperCase() as Uppercase<FILTER_PARAMS>],
            ),
        [searchParams],
    );

    useEffect(() => {
        batch(() => {
            dispatch(setCurrentInbox(appInbox));
            dispatch(setBulkFilters(validFilters));
        });
    }, [appInbox, validFilters, dispatch]);

    if (!appInbox || !Inbox[appInbox] || appInbox === Inbox.PENDING) {
        return <Navigate to={`/${AppRoute.SHIPMENTS}/all`} />;
    }

    return (
        <ShipmentPanelProvider>
            <section css={getShipmentListStyles}>
                {showSubscriptionNotification && (
                    <SubscriptionNotification
                        css={getNotificationStyles}
                        title={t('subscriptions.upgrade-notification.more-features')}
                        disposition="inline"
                        amplitudeEvent={AmplitudeEvents.SUBSCRIPTION_TABLE_VIEW_BANNER}
                        buttonVariant={ButtonVariant.OUTLINED}
                    />
                )}

                <ShipmentListActions />
                <ShipmentListHeader />
                <ShipmentListContent inbox={appInbox} filters={validFilters} />
            </section>
        </ShipmentPanelProvider>
    );
};
