import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getInvoicePanelActionsWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `-${theme.spacing(2)}px`,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.gray.megaLight,
        borderBottom: `1px solid ${theme.palette.gray.ultraLight}`,
    });

export const getInvoicePanelActionsButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        ':first-of-type': { marginRight: theme.spacing() },
    });
