import { useEvent } from '@packlink/event-handler';
import { ClientFeature, ISubscriptionResponse, SubscriptionFeatureBehavioursResponse } from '@packlink/packlink-sdk';

export enum EventName {
    FEATURE_BEHAVIOUR_UPSERT = 'feature-behaviour-upsert',
    SUBSCRIPTION_UPSERT = 'subscription-upsert',
}

export interface FeatureBehaviourUpsertEventData {
    feature_name: ClientFeature;
    enabled: boolean;
    behaviour_properties: Record<string, unknown>;
}

export function useFeatureBehaviourUpsertEvent(clientId: string) {
    return useEvent<FeatureBehaviourUpsertEventData>(clientId, EventName.FEATURE_BEHAVIOUR_UPSERT);
}

export type SubscriptionUpsertEventData = ISubscriptionResponse & SubscriptionFeatureBehavioursResponse['subscription'];

export function useSubscriptionUpsertEvent(clientId: string) {
    return useEvent<SubscriptionUpsertEventData>(clientId, EventName.SUBSCRIPTION_UPSERT);
}
