import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { getIsCustomsRequiredInCheckout } from '@store/selectors/checkout';
import { CustomsStep } from './CustomsStep';
import { CheckoutRoute } from '@pages/checkout/routes';
import { useCheckoutPath } from '@pages/checkout/hooks/useCheckoutPath';

export function CustomsStepHandler(): JSX.Element {
    const isCustomsRequired = useSelector(getIsCustomsRequiredInCheckout);
    const getCheckoutPath = useCheckoutPath();

    if (isCustomsRequired) {
        return <CustomsStep />;
    } else {
        return <Navigate to={getCheckoutPath(CheckoutRoute.ADDRESS)} replace />;
    }
}
