import React from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { PrintingFormat } from '@packlink/packlink-sdk';
import { getAsset } from '@utils/cdn';
import { getPrintingOptionsStyles } from './PrintingPreferencesStyles';
import { PrintingOption, PrintingPreferencesSize } from '@components/PrintingOption/PrintingOption';

enum PrintingFormatIcon {
    A4 = 'A4',
    A6 = '4x6',
    COMBINED_A4 = 'COMBINED_A4',
}

type PrintingPreferencesProps = {
    onChange: (printingFormat: PrintingFormat) => void;
    printingFormat: PrintingFormat;
};

export const PrintingPreferences: React.FunctionComponent<PrintingPreferencesProps> = ({
    onChange,
    printingFormat,
}: PrintingPreferencesProps): JSX.Element => {
    const { t } = useTranslation();

    const onChangePrintingForm = (_: React.ChangeEvent<HTMLInputElement>, value: PrintingFormat): void => {
        onChange(value);
    };

    return (
        <section css={getPrintingOptionsStyles}>
            <PrintingOption
                size={PrintingPreferencesSize.SMALL}
                value={PrintingFormat.A6}
                image={getAsset(`icons/label-${PrintingFormatIcon[PrintingFormat.A6]}.svg`)}
                onChange={onChangePrintingForm}
                selected={PrintingFormat.A6 === printingFormat}
            >
                {t('printing-preferences.format.a6')}
            </PrintingOption>
            <PrintingOption
                size={PrintingPreferencesSize.SMALL}
                value={PrintingFormat.COMBINED_A4}
                image={getAsset(`icons/label-${PrintingFormatIcon[PrintingFormat.A4]}.svg`)}
                onChange={onChangePrintingForm}
                selected={PrintingFormat.COMBINED_A4 === printingFormat}
            >
                {t('printing-preferences.format.combined-a4')}
            </PrintingOption>
        </section>
    );
};
