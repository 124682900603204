import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getAdviceStyles = (theme: Theme): SerializedStyles =>
    css({
        border: `1px solid ${theme.palette.gray.light}`,
        borderBottom: `2px solid ${theme.palette.primary.dark}`,
    });

export const getListStyles = (theme: Theme): SerializedStyles =>
    css({
        paddingLeft: theme.spacing(2),
        marginTop: theme.spacing(),

        'li:not(:last-of-type)': {
            marginBottom: theme.spacing(0.5),
        },
    });
