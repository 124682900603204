import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useSelector } from 'react-redux';
import { DeepPartial } from 'redux';
import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import mapValues from 'lodash.mapvalues';
import {
    IAddress,
    IProShipmentApiResponse,
    IShipment,
    IWarehouseAddress,
    ProShipment,
    ShipmentStatus,
} from '@packlink/packlink-sdk';
import { FocusOnError } from '@shipengine/formik-giger';
import { IconNames } from '@shipengine/giger-theme';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { getLocationValidation } from '@components/LocationForm/locationFormValidation';
import { ILocationFormField } from '@components/LocationForm/LocationForm';

import { EventName, Origin } from '@types';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { getClientIdentifier } from '@store/selectors/client';

import { SidePanelCustomHeader } from '@components/SidePanel/SidePanelCustomHeader';
import { ShipmentPanelFooter } from '../ShipmentPanelFooter';
import { ShipmentPanelRecipientPudo } from './ShipmentPanelRecipientPudo';
import { InformationSection } from './InformationSection';
import { PickUpSection } from './PickUpSection';
import { Status, useStatus } from '@hooks/useStatus';
import { ShipmentPanelGoToPayment } from '../../ShipmentPanelGoToPayment/ShipmentPanelGoToPayment';
import { useToggle } from '@packlink/utils';
import { useEvent } from '@packlink/event-handler';
import { IRecipientForm } from './types';
import { shipmentPanelFormStyles } from '../ShipmentPanelStyles';

enum HeaderActions {
    CANCEL = 'cancel',
    BACK = 'back',
}

export const ShipmentPanelRecipient = (): JSX.Element => {
    const { panelState, closePanel, updateShipment, exitPanel } = useShipmentPanel();
    // Need useMemo because are used in useCallback as depth
    const { data: shipmentData } = useMemo(() => panelState.shipment as ProShipment, [panelState]);
    const shipmentDataJSON = useMemo(() => shipmentData.toJSON(), [shipmentData]);

    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const [selectedWarehouse, setSelectedWarehouse] = useState<IWarehouseAddress>();
    const [isSelectingPickUp, setIsSelectingPickUp] = useState<boolean>(false);
    const { status, isReady, setStatus } = useStatus();
    const { state: isActionLoading, toggle: toggleActionLoading } = useToggle(false);
    const clientId = useSelector(getClientIdentifier);
    const { eventBind, eventUnbind } = useEvent<IProShipmentApiResponse>(clientId, EventName.SHIPMENT_UPDATED);

    const headerActions = useMemo(
        () => ({
            [HeaderActions.CANCEL]: {
                icon: IconNames.CLOSE,
                callback: closePanel,
            },
            [HeaderActions.BACK]: {
                icon: IconNames.ARROW_LEFT,
                callback: (): void => setIsSelectingPickUp(false),
            },
        }),
        [closePanel],
    );

    const [header, setHeader] = useState(headerActions[HeaderActions.CANCEL]);

    useEffect((): void => {
        setHeader(headerActions[isSelectingPickUp ? HeaderActions.BACK : HeaderActions.CANCEL]);
    }, [isSelectingPickUp, headerActions]);

    const initialFormValues = useMemo((): IRecipientForm => {
        const parseLocationInfo = (origin: Origin) => ({
            ...shipmentDataJSON[origin],
            country:
                shipmentDataJSON[origin].state && shipmentDataJSON.additionalData[`postal_zone_id_${origin}`]
                    ? {
                          label: shipmentDataJSON[origin].state || '',
                          value: shipmentDataJSON.additionalData[`postal_zone_id_${origin}`] as string,
                      }
                    : undefined,
            postalCode:
                shipmentDataJSON[origin].zipCode &&
                shipmentDataJSON[origin].city &&
                shipmentDataJSON.additionalData[`zip_code_id_${origin}`]
                    ? {
                          label: `${shipmentDataJSON[origin].zipCode} - ${shipmentDataJSON[origin].city}`,
                          value: shipmentDataJSON.additionalData[`zip_code_id_${origin}`] as string,
                      }
                    : undefined,
        });
        return {
            from: parseLocationInfo(Origin.FROM),
            to: parseLocationInfo(Origin.TO),
            dropoffPointId: shipmentDataJSON.dropoffPointId,
        };
    }, [shipmentDataJSON]);

    const mapFormValuesWithSelect = (form: DeepPartial<IAddress>) => {
        return {
            ...mapValues(form, () => true),
            country: true,
            postalCode: true,
        };
    };

    const initialFormTouched = useMemo(
        () => ({
            from: mapFormValuesWithSelect(shipmentDataJSON.from),
            to: mapFormValuesWithSelect(shipmentDataJSON.to),
            dropoffPointId: true,
        }),
        [shipmentDataJSON.from, shipmentDataJSON.to],
    );

    const validationSchema = useMemo((): yup.ObjectSchema => {
        const hasPickUp = !!shipmentData.service?.dropOff?.destination;

        return yup.object<IRecipientForm>().shape({
            from: getLocationValidation(Origin.FROM, hasPickUp, t),
            to: getLocationValidation(Origin.TO, hasPickUp, t),
            ...(hasPickUp && { dropoffPointId: yup.string().required() }),
        });
    }, [shipmentData, t]);

    const updateCallback = useCallback(
        ({ data }: IProShipmentApiResponse) => {
            if (data.packlink_reference === shipmentDataJSON.packlinkReference) {
                const updatedShipmentState = data.state;
                toggleActionLoading();
                if (updatedShipmentState === ShipmentStatus.READY_TO_PURCHASE) {
                    setStatus(Status.READY);
                } else {
                    closePanel(false);
                }
                eventUnbind(updateCallback);
            }
        },
        [closePanel, eventUnbind, setStatus, shipmentDataJSON.packlinkReference, toggleActionLoading],
    );

    const submitForm = useCallback(
        (values: IRecipientForm): void => {
            const transformLocationFormValuesToAddress = (values: ILocationFormField): IAddress => {
                const formValues = { ...values };

                return formValues as IAddress;
            };

            const getFrom = (
                currentFrom: ILocationFormField,
                currentWarehouseId: unknown,
                warehouse?: IWarehouseAddress,
            ): ILocationFormField => {
                if (warehouse && warehouse.id !== currentWarehouseId) {
                    return {
                        ...warehouse,
                        street1: warehouse.address,
                        state: warehouse.postalZone.translations[locale],
                        postalCode: {
                            label: `${warehouse.zipCode} - ${warehouse.city}`,
                            value: warehouse.postalCodeId,
                        },
                        country: {
                            label: warehouse.postalZone.translations[locale],
                            value: warehouse.postalZone.id,
                        },
                    };
                }

                return currentFrom;
            };

            const { from, to, dropoffPointId } = values;

            const currentWarehouseId = shipmentDataJSON.additionalData.selectedWarehouseId;

            const selectedFrom = getFrom(from, currentWarehouseId, selectedWarehouse);

            const newShipmentData: IShipment = {
                ...shipmentDataJSON,
                additionalData: {
                    ...shipmentDataJSON.additionalData,
                    postal_zone_id_from: selectedFrom.country
                        ? selectedFrom.country?.value
                        : shipmentDataJSON.additionalData.postal_zone_id_from,
                    postal_zone_id_to: to.country
                        ? to.country.value
                        : shipmentDataJSON.additionalData.postal_zone_id_to,
                    postal_zone_name_from: selectedFrom.state || shipmentDataJSON.additionalData.postal_zone_name_from,
                    postal_zone_name_to: to.state || shipmentDataJSON.additionalData.postal_zone_name_to,
                    zip_code_id_to: to.postalCode
                        ? to.postalCode?.value
                        : shipmentDataJSON.additionalData.zip_code_id_to,
                    zip_code_id_from: selectedFrom.postalCode
                        ? selectedFrom.postalCode.value
                        : shipmentDataJSON.additionalData.zip_code_id_from,
                    selectedWarehouseId: selectedWarehouse?.id || currentWarehouseId,
                },
                dropoffPointId,
                from: transformLocationFormValuesToAddress(selectedFrom),
                to: transformLocationFormValuesToAddress(to),
            };

            toggleActionLoading();
            updateShipment(newShipmentData);
            eventBind(updateCallback);
        },
        [eventBind, locale, selectedWarehouse, shipmentDataJSON, toggleActionLoading, updateCallback, updateShipment],
    );

    const showPickUp = (): void => {
        setIsSelectingPickUp(true);
    };

    const changeWarehouse = (warehouse: IWarehouseAddress): void => {
        setSelectedWarehouse(warehouse);
    };

    const renderInformationAndPickUp = (): JSX.Element => (
        <>
            <InformationSection
                warehouseId={shipmentData.additionalData.selectedWarehouseId as string}
                service={shipmentData.service}
                shipmentData={shipmentData}
                onChangeWarehouse={changeWarehouse}
            />

            {shipmentData.service?.dropOff?.destination && <PickUpSection onShowPickUp={showPickUp} />}
        </>
    );

    const renderForm = (formProps: FormikProps<IRecipientForm>): React.ReactNode => {
        const { isValid } = formProps;

        return (
            <Form css={shipmentPanelFormStyles()}>
                {/* The delay of 300 is to wait for the panel to be opened */}
                <FocusOnError initialValidation={true} focusDelay={300} />

                <SidePanelCustomHeader
                    onClickAction={header.callback}
                    actionIcon={header.icon}
                    title={t(`shipment-list-header.menu.recipient`)}
                />
                {!status && (
                    <>
                        <SidePanelContent>
                            {isSelectingPickUp ? (
                                <ShipmentPanelRecipientPudo shipmentData={shipmentData} />
                            ) : (
                                renderInformationAndPickUp()
                            )}
                        </SidePanelContent>

                        <ShipmentPanelFooter onExit={exitPanel} isSaveDisabled={!isValid} isLoading={isActionLoading} />
                    </>
                )}

                {isReady && (
                    <ShipmentPanelGoToPayment onExit={exitPanel} shipmentRef={shipmentData.packlinkReference} />
                )}
            </Form>
        );
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            initialTouched={initialFormTouched}
            onSubmit={submitForm}
        >
            {(formProps: FormikProps<IRecipientForm>): React.ReactNode => renderForm(formProps)}
        </Formik>
    );
};
