import { useState } from 'react';

import { Icon } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { IShipment, Service, Shipment } from '@packlink/packlink-sdk';

import { ServiceUtils } from '@utils/ServiceUtils';

import { PaymentSummaryAccordionSectionItem } from './PaymentSummaryAccordionSectionItem';
import {
    getAccordionStyles,
    getContentHiddenStyles,
    getContentListStyles,
    getSectionHeaderCarrierStyles,
    getSectionHeaderChevronStyles,
    getSectionHeaderStyles,
    getSectionIconStyles,
} from '../PaymentSummaryAccordionStyles';

interface IPaymentSummaryAccordionSectionProps {
    carrierInfo?: Service;
    shipments: Shipment<IShipment>[];
}

export const PaymentSummaryAccordionSection = ({
    carrierInfo,
    shipments,
}: IPaymentSummaryAccordionSectionProps): JSX.Element => {
    const { t } = useTranslation();
    const [isShowingShipments, setIsShowingShipments] = useState(false);

    return (
        <section
            role="button"
            css={getAccordionStyles}
            onClick={() => setIsShowingShipments((prevIsShowingShipments) => !prevIsShowingShipments)}
        >
            <div css={getSectionHeaderStyles}>
                {carrierInfo && (
                    <img
                        alt={carrierInfo.carrierName}
                        src={ServiceUtils.getCarrierLogo(carrierInfo.logoId)}
                        css={getSectionIconStyles}
                    />
                )}
                <p css={getSectionHeaderCarrierStyles}>
                    {t('payment-details.item.shipments', { count: shipments.length })}
                </p>
                <div data-id="chevron" css={getSectionHeaderChevronStyles}>
                    <Icon name={isShowingShipments ? IconNames.CHEVRON_TOP : IconNames.CHEVRON_BOTTOM} />
                </div>
            </div>
            <ul css={isShowingShipments ? getContentListStyles : getContentHiddenStyles} data-id="shipments-container">
                {shipments.map(
                    (shipment: Shipment<IShipment>): JSX.Element => (
                        <PaymentSummaryAccordionSectionItem key={shipment.packlinkReference} shipment={shipment} />
                    ),
                )}
            </ul>
        </section>
    );
};
