import { Typography, WithChildrenCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { TicketChatMessageDate } from './TicketChatMessageDate';
import { getTicketChatMessageHeader } from './TicketChatMessageStyles';
import { TicketChatMessageWrapper } from './TicketChatMessageWrapper';

type TicketChatMessageProps = WithChildrenCommonProps<{
    createdAt: string;
    sendedBy: TicketChatMessageSender;
    hasDecorativeLine?: boolean;
}>;

export enum TicketChatMessageSender {
    user = 'USER',
    agent = 'AGENT',
}

export function TicketChatMessage({
    createdAt,
    sendedBy,
    hasDecorativeLine,
    children,
    ...props
}: TicketChatMessageProps) {
    const sender = useSenderTranslation(sendedBy);
    return (
        <TicketChatMessageWrapper hasDecorativeLine={hasDecorativeLine} {...props}>
            <header css={getTicketChatMessageHeader}>
                <Typography variant="body1" bold>
                    {sender}
                </Typography>
                <TicketChatMessageDate date={createdAt} />
            </header>
            <section>{children}</section>
        </TicketChatMessageWrapper>
    );
}

function useSenderTranslation(sender: TicketChatMessageSender) {
    const { t } = useTranslation();

    switch (sender) {
        case TicketChatMessageSender.agent:
            return t('support-center.ticket-chat.agent-sender-title');
        case TicketChatMessageSender.user:
            return t('support-center.ticket-chat.user-sender-title');
        default:
            return t('support-center.ticket-chat.unknown-sender-title');
    }
}
