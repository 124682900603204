import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import Packlink from '@sdk';
import { getUserEmail } from '@store/selectors/user';

import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonColor, ButtonSize, ButtonVariant, FieldMessageType, Input, useToast } from '@shipengine/giger';
import { FormField } from '@shipengine/formik-giger';

import { getPasswordFormStyles } from './YourAccountSettingsPasswordFormStyles';
import { useMutation } from 'react-query';
import { ApiClientError } from '@packlink/packlink-sdk';
import { logSdkError } from '@utils/logger';
import { validatePassword } from '@utils/passwordValidation';

interface PasswordForm {
    oldPassword: string;
    password: string;
    repeatedPassword: string;
}

interface IYourAccountSettingsPasswordFormProps {
    closeFormHandler: () => void;
}

export const YourAccountSettingsPasswordForm = ({
    closeFormHandler,
}: IYourAccountSettingsPasswordFormProps): JSX.Element => {
    const { t } = useTranslation();
    const toast = useToast(t);

    const userEmail = useSelector(getUserEmail);

    const { mutate: changePassword, isLoading } = useMutation({
        mutationFn: ({ email, password, newPassword }: { email: string; password: string; newPassword: string }) =>
            Packlink.v1.users.changePassword.set(email, password, newPassword),
        onError: (error: ApiClientError) => logSdkError(error),
    });

    const validationSchema = yup.object<PasswordForm>().shape({
        oldPassword: yup.string().required(t('form.error.required', { field: t('your-account.form.old-password') })),
        password: yup
            .string()
            .required(t('form.error.required', { field: t('your-account.form.new-password') }))
            .test({
                name: 'password-validation',
                message: t('your-account.form.password-validation', { characters_number: 12 }),
                test: (value) => validatePassword(value),
            }),
        repeatedPassword: yup
            .string()
            .required(t('form.error.required', { field: t('your-account.form.repeat-password') }))
            .oneOf([yup.ref('password')], t('your-account.settings.password-not-match'))
            .test({
                name: 'password-validation',
                message: t('your-account.form.password-validation', { characters_number: 12 }),
                test: (value) => validatePassword(value),
            }),
    });

    const initialFormValues = {
        oldPassword: '',
        password: '',
        repeatedPassword: '',
    };

    const submitForm = ({ oldPassword, password }: PasswordForm): void => {
        if (userEmail) {
            changePassword(
                { email: userEmail, password: oldPassword, newPassword: password },
                {
                    onSuccess: () => {
                        toast.success({ message: t('your-account.form.password-updated') });
                        closeFormHandler();
                    },
                    onError: () => {
                        toast.error({ message: t('your-account.form.server-error') });
                    },
                },
            );
        }
    };

    const renderForm = ({ values, errors, handleChange, touched }: FormikProps<PasswordForm>): JSX.Element => (
        <Form css={getPasswordFormStyles}>
            <FormField name="oldPassword">
                <Input
                    value={values.oldPassword}
                    onChange={handleChange}
                    type="password"
                    label={t('your-account.form.old-password')}
                    isInvalid={!!(errors.oldPassword && touched.oldPassword)}
                />
            </FormField>

            <FormField
                name="password"
                message={{
                    type: FieldMessageType.HINT,
                    content: t('your-account.form.password-validation', { characters_number: 12 }),
                }}
            >
                <Input
                    value={values.password}
                    onChange={handleChange}
                    type="password"
                    label={t('your-account.form.new-password')}
                    isInvalid={!!(errors.password && touched.password)}
                />
            </FormField>

            <FormField name="repeatedPassword">
                <Input
                    value={values.repeatedPassword}
                    onChange={handleChange}
                    type="password"
                    label={t('your-account.form.repeat-password')}
                    isInvalid={!!(errors.repeatedPassword && touched.repeatedPassword)}
                />
            </FormField>

            <Button isLoading={isLoading} type="submit" color={ButtonColor.PRIMARY} size={ButtonSize.SMALL}>
                {t('your-account.form.save')}
            </Button>

            <Button
                isLoading={isLoading}
                onClick={closeFormHandler}
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
            >
                {t('your-account.form.cancel')}
            </Button>
        </Form>
    );

    return (
        <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={submitForm}>
            {(formProps: FormikProps<PasswordForm>): React.ReactNode => renderForm(formProps)}
        </Formik>
    );
};
