import { ChangeEvent, FormEvent } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { IconNames } from '@shipengine/giger-theme';
import { Button, FormElementGroup, Icon, Input } from '@shipengine/giger';
import { addressSearcherStyles } from './AddressSearcherStyles';

interface IAddressSearcherProps {
    value?: string;
    onChangeValue: (value?: string) => void;
    onSearch: (searchQuery?: string) => void;
}

export function AddressSearcher({ value, onChangeValue, onSearch }: IAddressSearcherProps): JSX.Element {
    const { t } = useTranslation();

    const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSearch(value);
    };

    const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        onChangeValue(value || undefined);
    };

    const handleClear = () => {
        onChangeValue(undefined);
        onSearch(undefined);
    };

    return (
        <form onSubmit={onSubmitHandler} css={addressSearcherStyles}>
            <FormElementGroup>
                <Input
                    value={value}
                    clearButtonTooltip={t('form.action.clear-search')}
                    onClear={handleClear}
                    label={t('form.label.search-by', { field: t('form.label.alias').toLowerCase() })}
                    name="search"
                    type="search"
                    leftContent={<Icon name={IconNames.SEARCH} />}
                    onChange={handleChange}
                />
                <Button type="submit">{t('form.action.search')}</Button>
            </FormElementGroup>
        </form>
    );
}
