import { useMutation } from 'react-query';
import { apiClient } from '@sdk';
import {
    ApiClientError,
    OtpRepository,
    OtpCodeVerificationParams,
    OtpPhoneNumberVerificationParams,
    OtpVerificationErrorApiResponse,
} from '@packlink/packlink-sdk';
import { logSdkError } from '@utils/logger';

const optRepository = new OtpRepository(apiClient);

export const useOtpVerification = () => {
    const { mutate: sendPhoneNumberMutation } = useMutation(
        (phoneNumberPayload: OtpPhoneNumberVerificationParams) =>
            optRepository.sendPhoneNumberVerification(phoneNumberPayload),
        {
            onError: (error?: { response: { data: { messages: OtpVerificationErrorApiResponse[] } } }) => {
                error && logSdkError(error as unknown as ApiClientError);
            },
        },
    );

    const { mutate: verifyOtpCodeMutation } = useMutation(
        (otpCodePayload: OtpCodeVerificationParams) => optRepository.verifyOtpCode(otpCodePayload),
        {
            onError: (error?: { response: { data: { messages: OtpVerificationErrorApiResponse[] } } }) => {
                error && logSdkError(error as unknown as ApiClientError);
            },
        },
    );

    return {
        sendPhoneNumberMutation,
        verifyOtpCodeMutation,
    };
};
