import { useCallback } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { Video } from '@components/Video/Video';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IOwnContractVideoProps {
    src: string;
    poster?: string;
    carrierName: string;
}

export const OwnContractVideo = ({ src = '', poster, carrierName }: IOwnContractVideoProps): JSX.Element => {
    const { t } = useTranslation();
    const { sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();

    const sendDuration = useCallback(
        (duration: number) => {
            if (duration > 0) {
                sendSuffixedAmplitudeEvent(sendAmplitudeClickEvent, carrierName)(AmplitudeEvents.VIDEO_TUTORIAL, {
                    [AmplitudeProperties.DURATION]: duration,
                });
            }
        },
        [carrierName, sendAmplitudeClickEvent, sendSuffixedAmplitudeEvent],
    );

    return (
        <SidePanelContentSection title={t('own-contract.video.title')} subtitle={t('own-contract.video.subtitle')}>
            <Video onCleanup={sendDuration} src={src} poster={poster} data-id="own-contract-video" />
        </SidePanelContentSection>
    );
};

OwnContractVideo.displayName = 'OwnContractVideo';

export default OwnContractVideo;
