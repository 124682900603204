import { useEffect, useRef } from 'react';
import { useHref, useMatch, useResolvedPath, useLinkClickHandler } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { size } from 'lodash';
import { Inbox, InboxKeys } from '@packlink/packlink-sdk';
import { getCurrentInbox, getInboxes } from '@store/selectors/inbox';
import { inboxToRoute } from '@pages/router/utils/paths';
import { AMPLITUDE_EVENTS_BY_INBOX } from '@constants/amplitude';
import { inboxIcons } from '@constants/shipments';
import { useInboxNotifications } from '@components/ShipmentList/hooks/useInboxNotifications';
import { Icon, IconSize, Typography } from '@shipengine/giger';
import { Browser } from '@utils/Browser';
import { countStyles, iconStyles, inboxStyles, nameStyles } from './AppSidebarInboxStyles';
import { useAmplitude } from '@hooks/useAmplitude';

interface IAppSidebarInboxItemProps {
    inbox: Inbox;
    inboxRoute: Inbox;
    hasChildren?: boolean;
    isChild?: boolean;
}

export function AppSidebarInboxItem({
    inbox,
    inboxRoute,
    hasChildren = false,
    isChild = false,
}: IAppSidebarInboxItemProps) {
    const inboxes = useSelector(getInboxes);
    const currentInbox = useSelector(getCurrentInbox);
    const { sendAmplitudeTableViewClickEvent } = useAmplitude();
    const activeElementRef = useRef<null | HTMLLIElement>(null);
    const { t } = useTranslation();
    const matchPath = useResolvedPath(inboxToRoute(inbox));
    const routePath = useResolvedPath(inboxToRoute(inboxRoute));
    const match = useMatch({ path: matchPath.pathname, end: false });
    const internalOnClick = useLinkClickHandler(routePath.pathname);
    const href = useHref(routePath.pathname);
    const isActive = !!match;

    const isArchivedInbox = Inbox.ARCHIVED === inbox;
    const count = inboxes?.get(inbox) || 0;
    const isDisabled = !isArchivedInbox && count === 0;

    const hasNotifications = useNotifications(inbox);

    const changeInbox = (e: React.MouseEvent<HTMLAnchorElement>) => {
        sendAmplitudeTableViewClickEvent(AMPLITUDE_EVENTS_BY_INBOX[inboxRoute]);
        internalOnClick(e);
    };

    useEffect((): void => {
        !Browser.isElementVisible(activeElementRef.current) &&
            activeElementRef.current?.scrollIntoView &&
            activeElementRef.current?.scrollIntoView();
    }, [currentInbox]);

    return (
        <li key={inbox} ref={isActive ? activeElementRef : null} data-inbox={inbox}>
            <a css={inboxStyles(isActive, isChild, hasChildren, isDisabled)} href={href} onClick={changeInbox}>
                {!isChild && <Icon css={iconStyles(isDisabled)} name={inboxIcons[inbox]} size={IconSize.SIZE_MEDIUM} />}

                <span css={nameStyles}>{t(`shipment-list-sidebar.inboxes.${InboxKeys[inbox]}`)}</span>

                {!isArchivedInbox && (
                    <Typography css={countStyles(isActive, hasNotifications)} variant="body2">
                        {count.toString()}
                    </Typography>
                )}
            </a>
        </li>
    );
}

function useNotifications(inbox: Inbox): boolean {
    const { inboxNotifications } = useInboxNotifications(inbox);

    return size(inboxNotifications) > 0;
}
