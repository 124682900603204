import { IconNames } from '@shipengine/giger-theme';

export enum SidePanelMessageType {
    ERROR = 'error',
    INFO = 'info',
    LOADING = 'loading',
    SUCCESS = 'success',
    WARNING = 'warning',
}

export const ICON_FOR_TYPE = {
    [SidePanelMessageType.ERROR]: IconNames.INFO_FILLED,
    [SidePanelMessageType.INFO]: IconNames.INFO_FILLED,
    [SidePanelMessageType.SUCCESS]: IconNames.CHECK_FILLED,
    [SidePanelMessageType.WARNING]: IconNames.ALERT,
};

export interface ISidePanelMessageProps {
    title?: string;
    subtitle?: string;
    type: SidePanelMessageType;
    showShippingRulesPromo?: boolean;
    showGoToPro?: boolean;
}
