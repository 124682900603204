import { Form } from 'formik';
import { getIntegrationFormStyles } from './IntegrationFormStyles';

export interface IIntegrationForm {
    children: React.ReactNode;
}

export const IntegrationForm: React.FC<IIntegrationForm> = ({ children }: IIntegrationForm) => {
    return <Form css={getIntegrationFormStyles}>{children}</Form>;
};
