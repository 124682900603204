import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getContentSectionStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    });

export const getImageStyles = (theme: Theme): SerializedStyles =>
    css({
        height: 200,
        margin: `${theme.spacing(3)}px 0`,
    });

export const getContentContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: 1,
        padding: theme.spacing(),
    });
