import { Button, Grid, GridChild, Typography, useToast } from '@shipengine/giger';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { useTranslation } from '@packlink/translation-provider';
import { useTenantConfig } from '@packlink/tenant-config-provider';

import { ITenantConfig } from '@types';

import { getAdviceStyles, getListStyles } from './SettingsPaymentDirectDebitAdviceStyles';

export const SettingsPaymentDirectDebitAdvice = (): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const toast = useToast(t);

    const sdk = useSdk();
    const { directDebitDocument } = useTenantConfig<ITenantConfig>();

    const setDirectDebit = () => {
        const {
            cdn: { url: cdnUrl },
        } = config;

        sdk.v1.billing
            .requestDirectDebit()
            .then(() => {
                if (directDebitDocument && directDebitDocument[locale]) {
                    window.open(cdnUrl + directDebitDocument[locale], '_blank');
                }
            })
            .catch(() => {
                toast.error({ message: t('settings.payment-direct-debit.server-error') });
            });
    };

    return (
        <Grid css={getAdviceStyles}>
            <GridChild colSpan={9}>
                <Typography bold variant="body2">
                    {t('settings.payment-direct-debit.requirements')}
                </Typography>

                <ol css={getListStyles}>
                    <Typography variant="body2" component="li">
                        {t('settings.payment-direct-debit.requirement-1')}
                    </Typography>
                    <Typography variant="body2" component="li">
                        {t('settings.payment-direct-debit.requirement-2')}
                    </Typography>
                    <Typography variant="body2" component="li">
                        {t('settings.payment-direct-debit.requirement-3')}
                    </Typography>
                </ol>
            </GridChild>

            <GridChild colSpan={3} colStart={10}>
                <Button isFullWidth onClick={setDirectDebit}>
                    {t('settings.payment-direct-debit.download-button')}
                </Button>
            </GridChild>
        </Grid>
    );
};
