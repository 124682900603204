import { WithCommonProps } from '@shipengine/giger';
import { ForwardedRef, forwardRef } from 'react';
import { contentStyle } from './SidePanelContentStyles';

export const SidePanelContent = forwardRef(function SidePanelContent(
    props: WithCommonProps<unknown>,
    ref?: ForwardedRef<HTMLElement>,
): JSX.Element {
    const { children, ...rest } = props;

    return (
        <section ref={ref} css={contentStyle} {...rest}>
            {children}
        </section>
    );
});
