import { ICustomsInvoiceExternalData } from '@packlink/customs-form';
import { ICustomsShipmentDetails } from '@packlink/packlink-sdk';
import { IAppState } from '@store/reducers';
import {
    getCheckoutFrom,
    getCheckoutParcels,
    getCheckoutPriceDetails,
    getCheckoutTo,
    getParcelsTotalWeight,
} from '@store/selectors/checkout';
import { createSelector } from 'reselect';
import { ICustomsState } from '../reducer/customs';

const getCustoms = (store: IAppState): ICustomsState => store.customs;

export const getCustomsForm = createSelector([getCustoms], (customs) => customs.customsForm);

const getCustomsShipmentDetails = createSelector(
    [getCheckoutParcels, getCheckoutPriceDetails, getParcelsTotalWeight],
    (parcels, priceInfo, totalWeight): ICustomsShipmentDetails => {
        const parcelCount = parcels.length;
        const totalPrice = priceInfo?.price?.totalPrice ?? 0;

        return {
            cost: totalPrice,
            parcelWeight: totalWeight,
            parcelCount,
        };
    },
);

export const getCustomsInvoiceExternalData = createSelector(
    [getCheckoutFrom, getCheckoutTo, getCustomsShipmentDetails],
    (from, to, shipmentDetails): ICustomsInvoiceExternalData => {
        const signatureFullName = `${from.firstName} ${from.lastName}`;
        const signatureCity = from.city ?? '';

        return {
            sender: from,
            receiver: to,
            shipmentDetails,
            signature: {
                fullName: signatureFullName,
                city: signatureCity,
            },
        };
    },
);

export const getIsCustomsFormSkipped = createSelector(getCustoms, ({ areCustomsSkipped }) => areCustomsSkipped);
