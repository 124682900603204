import { IconNames } from '@shipengine/giger-theme';
import { Button, Typography, ButtonVariant, Icon } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { CancelShipmentDialogForm } from './CancelShipmentDialogForm';
import {
    getCancelShipmentDialogCloseButtonStyles,
    getCancelShipmentDialogDescriptionStyles,
    getCancelShipmentDialogHeaderStyles,
    getCancelShipmentDialogTypographyStyles,
} from './CancelShipmentDialogStyles';

type CancelShipmentDialogProps = {
    onCancel: (reason: string) => void;
    onClose: () => void;
};

export function CancelShipmentDialog({ onCancel, onClose }: CancelShipmentDialogProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <Button
                css={getCancelShipmentDialogCloseButtonStyles}
                onClick={onClose}
                variant={ButtonVariant.TEXT}
                data-id="cancel-popover-close-button"
                aria-label={t('post-sale.details.close-popover')}
            >
                <Icon name={IconNames.CLOSE} />
            </Button>
            <header css={getCancelShipmentDialogHeaderStyles}>
                <Typography css={getCancelShipmentDialogTypographyStyles} component="h3" variant="heading5">
                    {t('post-sale.details.cancel-shipment')}
                </Typography>
            </header>
            <section css={getCancelShipmentDialogDescriptionStyles}>
                <Typography variant="body2" component="p">
                    {t('post-sale.details.contact-us-cancel-shipment-subtitle')}
                </Typography>
            </section>
            <CancelShipmentDialogForm onSubmit={onCancel} />
        </>
    );
}
