import { useTranslation } from '@packlink/translation-provider';
import { Button } from '@shipengine/giger';
import { EmptyState } from '@components/EmptyState/EmptyState';

interface IAddressesEmptyStateProps {
    onNewAddress: () => void;
}

export function AddressesEmptyState({ onNewAddress }: IAddressesEmptyStateProps): JSX.Element {
    const { t } = useTranslation();
    const {
        cdn: { url: cdnUrl },
    } = config;
    const image = `${cdnUrl}/pro/statics/images/send-a-parcel.png`;

    return (
        <EmptyState
            subtitle={t('addresses.settings.empty-message')}
            alt={t('addresses.settings.empty-message')}
            image={image}
        >
            <Button onClick={onNewAddress}>{t('addresses.settings.add-address')}</Button>
        </EmptyState>
    );
}
