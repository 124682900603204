import Packlink from '@sdk';
import { logSdkError } from '@utils/logger';
import { ThunkResult } from '@store';

import {
    ApiClientError,
    Payment,
    PaymentAvailableMethod,
    PaymentClientMethod,
    PaymentMethod,
    PaymentType,
} from '@packlink/packlink-sdk';

import {
    ADD_PAYMENT_METHOD,
    CLEAN_LOCAL_PAYMENT_FALLBACK,
    REMOVE_PAYMENT_METHOD,
    SET_AVAILABLE_PAYMENT_METHODS,
    SET_LOCAL_PAYMENT_FALLBACK,
    SET_PAYMENT_METHODS,
} from '@store/actions/action-types';

interface ISetLocalPaymentFallbackAction {
    payload: PaymentMethod;
    type: 'SET_LOCAL_PAYMENT_FALLBACK';
}

interface ICleanLocalPaymentFallbackAction {
    type: 'CLEAN_LOCAL_PAYMENT_FALLBACK';
}

interface ISetAvailablePaymentMethodsAction {
    payload: PaymentAvailableMethod[];
    type: 'SET_AVAILABLE_PAYMENT_METHODS';
}

interface ISetPaymentMethodsAction {
    payload: PaymentClientMethod[];
    type: 'SET_PAYMENT_METHODS';
}

interface IRemovePaymentMethodAction {
    payload: string;
    type: 'REMOVE_PAYMENT_METHOD';
}

interface IAddPaymentMethodAction {
    payload: PaymentClientMethod;
    type: 'ADD_PAYMENT_METHOD';
}

export type PaymentAction =
    | ISetAvailablePaymentMethodsAction
    | ISetPaymentMethodsAction
    | IRemovePaymentMethodAction
    | IAddPaymentMethodAction
    | ISetLocalPaymentFallbackAction
    | ICleanLocalPaymentFallbackAction;

export const setAvailablePaymentMethods = (
    availablePaymentMethods: PaymentAvailableMethod[],
): ISetAvailablePaymentMethodsAction => ({
    payload: availablePaymentMethods,
    type: SET_AVAILABLE_PAYMENT_METHODS,
});

export const setPaymentMethods = (paymentMethods: PaymentClientMethod[]): ISetPaymentMethodsAction => ({
    payload: paymentMethods,
    type: SET_PAYMENT_METHODS,
});

export const removePaymentMethod = (id: string): IRemovePaymentMethodAction => ({
    payload: id,
    type: REMOVE_PAYMENT_METHOD,
});

export const addPaymentMethod = (paymentMethod: PaymentClientMethod): IAddPaymentMethodAction => ({
    payload: paymentMethod,
    type: ADD_PAYMENT_METHOD,
});

export const setLocalPaymentFallbackPaymentMethod = (paymentMethod: PaymentMethod): ISetLocalPaymentFallbackAction => ({
    payload: paymentMethod,
    type: SET_LOCAL_PAYMENT_FALLBACK,
});

export const cleanLocalPaymentFallback = (): ICleanLocalPaymentFallbackAction => ({
    type: CLEAN_LOCAL_PAYMENT_FALLBACK,
});

export const savePaymentMethod =
    (
        type: PaymentType,
        method: PaymentMethod,
        nonce: string,
        currency?: string,
        id?: string,
    ): ThunkResult<Promise<{ id: string }>> =>
    async (): Promise<{ id: string }> => {
        /**
         * In Backend the PaymentMethod for Credit Cards is "PaymentMethod.CREDITCARD"
         * But our PaymentOption component handles "PaymentMethod.CREDITCARD3DSECURE" which causes an error.
         * So we will use PaymentMethod.CREDITCARD
         */
        const paymentMethod = method === PaymentMethod.CREDITCARD3DSECURE ? PaymentMethod.CREDITCARD : method;

        const newPayment = Payment.deserialize({
            id,
            method: paymentMethod,
            type,
            nonce,
            currency,
        });

        const handleError = (error: ApiClientError) => {
            logSdkError(error);

            const newError = {
                ...error,
                message: error.message || 'UNKNOWN_ERROR_0000',
            };

            return Promise.reject(newError);
        };
        const paymentDataRequestMethod = newPayment.type === PaymentType.DEFERRED && (id ? 'update' : 'create');
        if (paymentDataRequestMethod) {
            return Packlink.v1.billing[paymentDataRequestMethod](newPayment).catch((error: ApiClientError) =>
                handleError(error),
            );
        } else {
            return Packlink.v1.billing
                .registerPaymentMethod(newPayment)
                .catch((error: ApiClientError) => handleError(error));
        }
    };
