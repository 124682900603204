import { useLocation, useNavigate } from 'react-router';
import { IStepProps as IGigerStepProps, Step, Stepper as GigerStepper } from '@shipengine/giger';
import { useSelector } from 'react-redux';
import { AmplitudeCategories, AmplitudeEvents, AmplitudeProperties } from '@packlink/metrics';
import { getIsCustomsRequiredInCheckout } from '@store/selectors/checkout';
import { useTranslation } from '@packlink/translation-provider';
import { CheckoutRoute } from '../../routes';
import { useCheckoutPath } from '../../hooks/useCheckoutPath';
import { useAmplitude } from '@hooks/useAmplitude';

interface IStepProps extends IGigerStepProps {
    name: string;
}

export const Stepper = (): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const isCustomsRequired = useSelector(getIsCustomsRequiredInCheckout);
    const location = useLocation();
    const navigate = useNavigate();
    const getCheckoutPath = useCheckoutPath();

    const currentStepName = location.pathname.split('/').pop();

    const goToPreviousStep = (step: CheckoutRoute) => {
        // CON-1701 these steps will be migrated once the router will be finished
        sendAmplitudeEvent(AmplitudeEvents.CLICK_STEP, {
            [AmplitudeProperties.FROM_STEP]: currentStepName,
            [AmplitudeProperties.TO_STEP]: step,
            [AmplitudeProperties.CATEGORY]: AmplitudeCategories.CHECKOUT,
        });

        navigate(getCheckoutPath(step));
    };

    const initialSteps: IStepProps[] = [
        {
            name: CheckoutRoute.INFO,
            label: t('stepper.parcel.label'),
            onClick: () => goToPreviousStep(CheckoutRoute.INFO),
        },
        {
            name: CheckoutRoute.SERVICE,
            label: t('stepper.service.label'),
            onClick: () => goToPreviousStep(CheckoutRoute.SERVICE),
        },
        {
            name: CheckoutRoute.ADDRESS,
            label: t('stepper.address.label'),
            onClick: () => goToPreviousStep(CheckoutRoute.ADDRESS),
        },
    ];

    const paymentStep: IStepProps = {
        name: CheckoutRoute.PAYMENT,
        label: t('stepper.payment.label'),
    };

    const customsStep: IStepProps = {
        name: CheckoutRoute.CUSTOMS,
        label: t('stepper.customs.label'),
        onClick: () => goToPreviousStep(CheckoutRoute.CUSTOMS),
    };

    const pages = isCustomsRequired ? [...initialSteps, customsStep, paymentStep] : [...initialSteps, paymentStep];

    const getCurrentStep = () => {
        return pages.findIndex((page: IStepProps) => page.name === currentStepName) + 1;
    };

    const currentStep = getCurrentStep();

    return (
        <GigerStepper currentStep={currentStep}>
            {pages.map((step: IStepProps) => (
                <Step key={step.name} label={step.label} onClick={step.onClick} />
            ))}
        </GigerStepper>
    );
};
