import { IconNames } from '@shipengine/giger-theme';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { useToggle } from '@packlink/utils';
import { Icon, IconPopover, IconSize, Typography, WithChildrenCommonProps } from '@shipengine/giger';

import {
    getFilterContentStyles,
    getFilterStyles,
    getFilterTitleIconStyles,
    getFilterTitlePopoverStyles,
    getFilterTitleSpanStyles,
    getFilterTitleStyles,
} from './ShipmentFilterStyles';

import { DeleteFilter } from './DeleteFilter';

export interface IShipmentFilterProps {
    title: string;
    isActive: boolean;
    deleteFilters?: string[];
    infoMessage?: string;
}

export const ShipmentFilter = ({
    title,
    isActive,
    deleteFilters,
    infoMessage,
    children,
}: WithChildrenCommonProps<IShipmentFilterProps>): JSX.Element => {
    const { t } = useTranslation();
    const { state: isFilterOpened, toggle: toggleFilter } = useToggle(true);

    return (
        <article css={getFilterStyles(isActive)}>
            <div css={getFilterTitleStyles(isActive)} onClick={toggleFilter} role="button">
                <Typography css={getFilterTitleSpanStyles} variant="body1">
                    {title}
                </Typography>

                {infoMessage && (
                    <IconPopover
                        css={getFilterTitlePopoverStyles}
                        icon={IconNames.INFO}
                        size={IconSize.SIZE_SMALL}
                        data-id={`${title}-info`}
                    >
                        <Typography variant="body2" component="span">
                            <Trans>{infoMessage}</Trans>
                        </Typography>
                    </IconPopover>
                )}

                <Icon
                    size={IconSize.SIZE_REGULAR}
                    name={IconNames.CHEVRON_BOTTOM}
                    css={getFilterTitleIconStyles(isFilterOpened)}
                />
            </div>
            {isFilterOpened && (
                <div css={getFilterContentStyles(isActive)} aria-hidden={!isFilterOpened}>
                    {children}
                    {isActive && deleteFilters && (
                        <DeleteFilter filters={deleteFilters}>{t('filters.panel.delete-filter')}</DeleteFilter>
                    )}
                </div>
            )}
        </article>
    );
};
