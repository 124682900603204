import { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Next, Typography } from '@shipengine/giger';
import { getInvoiceInfoSaved } from '@store/selectors/checkout';
import { TenantUtils } from '@utils/tenant';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { Client } from '@packlink/packlink-sdk';
import { BillingInfoCard } from './BillingInfoCard';
import { BillingInfoForm } from './BillingInfoForm';
import { getBillingInfoStyles } from './BillingInfoStyles';

interface IBillingInfoProps {
    isPhoneNumberVerificationOpen: boolean;
    onBillingFormSuccess: (client: Client) => void;
    closePhoneNumberVerification: () => void;
    onEditInfo: (flag: boolean) => void;
}
export const BillingInfo = ({
    isPhoneNumberVerificationOpen,
    onBillingFormSuccess,
    closePhoneNumberVerification,
    onEditInfo,
}: IBillingInfoProps): JSX.Element => {
    const { t } = useTranslation();
    const hasInvoiceInfoSaved = useSelector(getInvoiceInfoSaved);

    const [editable, setEditable] = useState<boolean>(!hasInvoiceInfoSaved);

    const onEditable = (flag: boolean): void => {
        setEditable(flag);
        onEditInfo(flag);
    };

    return (
        <section css={getBillingInfoStyles}>
            <Typography variant="heading5">{t('invoice-form.section.title')}</Typography>
            <Typography component="span" variant="body2">
                <Trans
                    i18nKey="invoice-form.section.subtitle"
                    values={{ config: t('invoice-form.section.label-link') }}
                    components={{
                        a: (
                            <Next.Link
                                target="_blank"
                                title={t('invoice-form.section.label-link')}
                                href={`${TenantUtils.getURLPrefix()}/settings/billing/invoicing`}
                            />
                        ),
                        i: <Icon name={IconNames.SETTINGS} size={IconSize.SIZE_SMALL} />,
                    }}
                />
            </Typography>
            {!editable && <BillingInfoCard onEditClick={() => onEditable(true)} />}
            {editable && (
                <BillingInfoForm
                    isPhoneNumberVerificationOpen={isPhoneNumberVerificationOpen}
                    onBillingFormSuccess={onBillingFormSuccess}
                    closePhoneNumberVerification={closePhoneNumberVerification}
                    onFormSubmit={() => onEditable(false)}
                />
            )}
        </section>
    );
};
