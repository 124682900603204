import { Formik, FormikProps } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { ConfigurationType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { FrontendConfiguredIntegrationForm } from './FrontendConfiguredIntegrationForm';
import {
    FrontendConfiguredConfiguration,
    FrontendConfiguredPanelContentProps,
    IFrontendConfiguredFormData,
} from './types';

export const FrontendConfiguredIntegrationPanel: FC<FrontendConfiguredPanelContentProps> = (
    props: FrontendConfiguredPanelContentProps,
): JSX.Element => {
    const { t } = useTranslation();
    const { onClose, integration, onDeactivate, onSubmit, isLoading, showError, clearError, onCancel } = props;

    const frontendConfiguredConfiguration = integration.configuration;

    const defaultConfiguration: IFrontendConfiguredFormData = {
        shop_id: frontendConfiguredConfiguration?.data.shop_id || '',
        api_key: frontendConfiguredConfiguration?.data.api_key || '',
    };

    const frontendConfiguredOnSubmit = (formData: IFrontendConfiguredFormData): void => {
        const frontendConfiguredConfiguration: FrontendConfiguredConfiguration = {
            identifier: integration.configuration?.identifier || '',
            integration: integration.alias as EcommerceAlias,
            type: ConfigurationType.INBOUND,
            data: { ...formData },
        };

        const frontendConfiguredIntegration = { ...integration, configuration: frontendConfiguredConfiguration };
        onSubmit(frontendConfiguredIntegration);
    };

    const validationSchema = yup.object().shape({
        shop_id: yup
            .string()
            .required(t('form.error.required', { field: t('integrations.frontend-configured.shop-id') })),
        api_key: yup
            .string()
            .required(t('form.error.required', { field: t('integrations.frontend-configured.api-key') })),
    });

    return (
        <Formik
            onSubmit={frontendConfiguredOnSubmit}
            initialValues={defaultConfiguration}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<IFrontendConfiguredFormData>): React.ReactNode => (
                <FrontendConfiguredIntegrationForm
                    onCancel={onCancel}
                    onClose={onClose}
                    formProps={formProps}
                    isLoading={isLoading}
                    integration={integration}
                    onDeactivate={onDeactivate}
                    showError={showError}
                    clearError={clearError}
                />
            )}
        </Formik>
    );
};
