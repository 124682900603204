import { useTranslation } from '@packlink/translation-provider';
import {
    AnalyticsSortSeriesParam,
    IShipmentsByCountryApiResponse,
    UserAnalyticsRepository,
} from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { ChartContainer, getChartContainerStatus, useRequestOnIntersection } from '../Charts';
import { getSubtitleRangeParams } from '@Analytics/utils/dates';
import {
    CroppableContent,
    Flag,
    FlagSize,
    Icon,
    IconSize,
    Table,
    TableBody,
    TableBodyCell,
    TableHeader,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
} from '@shipengine/giger';
import { Charts, OTHERS } from '@Analytics/constants/analytics';
import { flagStyles, iconStyles, tableStyles } from './ShipmentsPerCountryChartStyles';
import { useCallback } from 'react';
import { ICountriesJsonData } from '@Integrations/components/Integrations/Amazon/AmazonIntegrationForm';
import { IconNames } from '@shipengine/giger-theme';

const userAnalyticsRepository = new UserAnalyticsRepository(apiClient);

export function ShipmentsPerCountryChart(props: AnalyticsSortSeriesParam): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const cdnUrl = config.cdn.baseUrl;

    const addCountryNameAndIcon = useCallback(
        ({ country, ...rest }: IShipmentsByCountryApiResponse, countries: ICountriesJsonData['values']) => {
            const countryName =
                country === OTHERS ? t('analytics.label.others') : countries?.[country]?.name[locale] || country;
            const countryFlag =
                country === OTHERS || countryName === country ? (
                    <Icon name={IconNames.WORLD} size={IconSize.SIZE_SMALL} css={iconStyles} />
                ) : (
                    <Flag country={country} size={FlagSize.SMALL} cdnUrl={cdnUrl} css={flagStyles} />
                );
            return { ...rest, countryName, countryFlag };
        },
        [cdnUrl, locale, t],
    );

    const requestShipmentsPerCountry = () =>
        userAnalyticsRepository.getShipmentsByCountry(props).then((results) =>
            fetch(`${cdnUrl}/apps/countries/countries.json`)
                .then((res) => res.json())
                .then((countries: ICountriesJsonData) =>
                    results.map((item) => addCountryNameAndIcon(item, countries.values)),
                ),
        );

    const { data, status, isFetching, intersectionRef } = useRequestOnIntersection({
        requestCacheKeys: [Charts.ShipmentsPerCountry, props],
        requestData: requestShipmentsPerCountry,
    });

    const isEmpty = !data || !data.length;
    const containerStatus = getChartContainerStatus(status, isFetching, isEmpty);

    return (
        <ChartContainer
            ref={intersectionRef}
            title={t('analytics.chart.shipments-per-country')}
            subtitle={t('analytics.chart.date-range', getSubtitleRangeParams(locale, props.from, props.to))}
            status={containerStatus}
            cdnUrl={cdnUrl}
        >
            <Table
                header={
                    <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-country-tooltip">
                                    {t('analytics.label.country')}
                                </CroppableContent>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-content-tooltip">
                                    {t('analytics.label.shipments')}
                                </CroppableContent>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-content-tooltip">
                                    {t('analytics.label.percentage')}
                                </CroppableContent>
                            </TableHeaderCell>
                        </TableHeaderRow>
                    </TableHeader>
                }
                css={tableStyles}
                data-id="shipments-per-country-table"
            >
                <TableBody>
                    {data?.map(({ countryName, countryFlag, shipments, percentage }, index: number) => {
                        return (
                            <TableRow key={index}>
                                <TableBodyCell>
                                    {countryFlag}
                                    {countryName}
                                </TableBodyCell>
                                <TableBodyCell>{shipments}</TableBodyCell>
                                <TableBodyCell>{`${percentage}%`}</TableBodyCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </ChartContainer>
    );
}
