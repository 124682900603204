import { GTMShipmentSource } from '@packlink/metrics';

export enum GTMSectionIds {
    ADD_SHIPMENT_BUTTON = 'add-shipment-button',
    EMPTY_STATE_FIRST_BUTTON = 'empty-state-first-button',
    NEW_USER_EMPTY_STATE_SECOND_BUTTON = 'new-user-empty-state-second-button',
    CONNECT_STORE_BUTTON = 'connect-your-store-button',
    EMPTY_STATE_SECOND_BUTTON = 'empty-state-second-button',
    NEW_USER_EMPTY_STATE_FIRST_BUTTON = 'new-user-empty-state-first-button',
}

export enum GTMSectionType {
    HEADER = 'header',
    TABLE = 'table',
}

export enum GTMActionFieldOption {
    MANUAL_CHECKOUT = 'Manual Checkout',
    QUICK_CHECKOUT = 'Quick Checkout',
    INTEGRATION_SELECTED = 'Integration selected',
    INTEGRATION_INTEREST = 'Integration interest',
    CARRIER_CONTRACT_SELECTED = 'Carrier Contract selected',
    OWN_CONTRACT_ACTIVATED_OK = 'Own contract activated ok',
    OWN_CONTRACT_ACTIVATED_KO = 'Own contract activated ko',
    UPGRADE_ACCOUNT_INTERESTED = 'upgrade account interested',
    UPGRADE_ACCOUNT_SELECTED = 'upgrade account selected',
}

export enum GTMShortcodeKeys {
    CSV_INBOUND = 'csv_inbound',
    PRO = 'PRO',
}

export const GTM_SHIPMENT_SOURCE_FROM_SHORTCODE = {
    [GTMShortcodeKeys.PRO]: GTMShipmentSource.MANUAL,
    [GTMShortcodeKeys.CSV_INBOUND]: GTMShipmentSource.CSV,
};

export enum GTMShipmentCategory {
    NATIONAL = 'National',
    CONTINENTAL = 'Continental',
    INTERNATIONAL = 'International',
}

export const GTM_INITIAL_TRACK_COOKIE_NAME = 'bulk.tracked';
