import { FC } from 'react';

import { IInfoPanelProps, InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { IntegrationGuide } from '@Integrations/components/IntegrationGuide/IntegrationGuide';
import { IntegrationVideo } from '@Integrations/components/IntegrationVideo/IntegrationVideo';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';

import { getIntegrationVideoStyles } from './IntegrationGuideVideoStyles';
import { IntegrationGuideName } from './types';

type PrestashopIntegrationGuideType = Omit<IInfoPanelProps, 'title' | 'collapsible'>;

export const PrestashopIntegrationGuide: FC<PrestashopIntegrationGuideType> = ({
    collapsed,
}: PrestashopIntegrationGuideType): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const videoPath = `${config.cdn.url}/pro/statics/videos/onboarding/${IntegrationGuideName.Prestashop}/video.mp4`;
    const basePath = `${config.cdn.url}/pro/statics/videos/onboarding/${IntegrationGuideName.Prestashop}/${locale}`;
    const fallbackBasePath = `${config.cdn.url}/pro/statics/videos/onboarding/${IntegrationGuideName.Prestashop}/en-GB`;

    return (
        <>
            <IntegrationVideo
                css={getIntegrationVideoStyles(collapsed)}
                src={videoPath}
                poster={`${basePath}.jpg`}
                subtitles={`${basePath}.vtt`}
                fallbackPoster={`${fallbackBasePath}.jpg`}
                fallbackSubtitles={`${fallbackBasePath}.vtt`}
                integrationName={IntegrationGuideName.Prestashop}
            />
            <IntegrationGuide>
                <InfoPanel title={t('integrations.guide.title')} collapsible={true} collapsed={collapsed}>
                    <InfoPanelStepList>
                        <InfoPanelStep title={t('integrations.guide.install-configure-module')}>
                            <Typography component="p">
                                <Trans
                                    i18nKey="integrations.guide.add-app"
                                    values={{ integrationName: IntegrationGuideName.Prestashop }}
                                />
                            </Typography>
                        </InfoPanelStep>
                        <InfoPanelStep title={t('integrations.guide.account-sync')}>
                            <ul>
                                <li>
                                    <Trans i18nKey="integrations.prestashop.default-information" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.prestashop.select-shipping" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.prestashop.enter-carrier" />
                                </li>
                                <li>
                                    <Trans i18nKey="integrations.prestashop.configure-price" />
                                </li>
                            </ul>
                        </InfoPanelStep>
                        <InfoPanelStep title={t('integrations.guide.use-module')}>
                            <Typography component="p">
                                <Trans i18nKey="integrations.guide.access-packlink-pro" />
                            </Typography>
                        </InfoPanelStep>
                    </InfoPanelStepList>
                </InfoPanel>
            </IntegrationGuide>
        </>
    );
};
