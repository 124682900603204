import { useState, createContext, PropsWithChildren, useContext, useEffect } from 'react';

export interface IShippingRulesContext {
    numberOfRules: number;
    hasReachedRulesLimit: boolean;
    setNumberOfRules: (number: number) => void;
}

export const ShippingRulesContext = createContext<IShippingRulesContext | undefined>(undefined);

export function ShippingRulesProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
    const [numberOfRules, setNumberOfRules] = useState(0);
    const [hasReachedRulesLimit, setHasReachedRulesLimit] = useState(false);
    const { maxShippingRules } = config;

    useEffect(() => {
        setHasReachedRulesLimit(numberOfRules >= maxShippingRules);
    }, [maxShippingRules, numberOfRules]);

    return (
        <ShippingRulesContext.Provider
            value={{
                numberOfRules,
                hasReachedRulesLimit,
                setNumberOfRules,
            }}
        >
            {children}
        </ShippingRulesContext.Provider>
    );
}

export function useShippingRules(): IShippingRulesContext {
    const context = useContext(ShippingRulesContext);

    if (context === undefined) {
        throw new Error('useShippingRules must be used within a ShippingRulesProvider');
    }

    return context;
}
