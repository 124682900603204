import { IVoucher, Voucher } from '@packlink/packlink-sdk';
import { PaymentVoucher as PhaviourPaymentVoucher } from '@packlink/payment-voucher';
import { deleteVoucherAction, setCheckoutVoucherAction } from '@store/actions/checkout';
import { getCheckoutService } from '@store/selectors/checkout';
import { getClientData } from '@store/selectors/client';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';
import { getPaymentVoucherStyles, getPaymentVoucherTitleStyles } from './PaymentVoucherStyles';
import { Typography } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useRefreshPrice } from '@components/Checkout/hooks/useRefreshPrice';
import { useAmplitude } from '@hooks/useAmplitude';
import { useVoucherAvailability } from './hooks/useVoucherAvailability';

export const PaymentVoucher = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { sendAmplitudeEvent } = useAmplitude();

    const { refreshPrice } = useRefreshPrice();

    const client = useSelector(getClientData);
    const service = useSelector(getCheckoutService);
    const { voucherAvailability } = useVoucherAvailability();

    const applyVoucher = useCallback(
        (voucher: Voucher<IVoucher> | Voucher<IVoucher>[]): void => {
            // In manual checkout we only handle 1 Voucher
            if (Array.isArray(voucher)) return;

            dispatch(setCheckoutVoucherAction(voucher));
            sendAmplitudeEvent(AmplitudeEvents.APPLY_PROMO_CODE, {
                [AmplitudeProperties.IS_VALID]: true,
                [AmplitudeProperties.SECTION]: 'checkout',
            });
            refreshPrice();
        },
        [dispatch, refreshPrice, sendAmplitudeEvent],
    );
    const removeVoucher = useCallback((): void => {
        dispatch(deleteVoucherAction());
        refreshPrice();
    }, [dispatch, refreshPrice]);

    return (
        <>
            {service && voucherAvailability && (
                <div css={getPaymentVoucherStyles}>
                    <Typography css={getPaymentVoucherTitleStyles} variant="heading5">
                        {t('payment.payment-voucher.title')}
                    </Typography>
                    <PhaviourPaymentVoucher
                        applyVoucher={applyVoucher}
                        onRemoveVoucher={removeVoucher}
                        serviceId={service.id}
                        clientId={client.id}
                    />
                </div>
            )}
        </>
    );
};
