import { Typography } from '@shipengine/giger';
import { Parcel } from '@packlink/packlink-sdk';
import { Fragment } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { getParcelListStyles, getParcelTitleStyles, getParcelDescriptionStyles } from './ParcelsDetailsStyles';

interface IParcelsDetailsProps {
    parcels: Parcel[];
}

function getDimensions(parcel: Parcel): string {
    return `${parcel.weight} kg, ${parcel.height} x ${parcel.length} x ${parcel.width} cm`;
}
export function ParcelsDetails({ parcels }: IParcelsDetailsProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <Typography component="dl" variant="body1" css={getParcelListStyles}>
            {parcels.map((parcel, index) => {
                return (
                    <Fragment key={index}>
                        <dt css={getParcelTitleStyles}>{`${t('post-sale.order.parcel')} ${index + 1}`}</dt>
                        <dd css={getParcelDescriptionStyles}>{getDimensions(parcel)}</dd>
                    </Fragment>
                );
            })}
        </Typography>
    );
}
