import { useContext, useMemo } from 'react';
import { ClientFeature, FeaturesBehaviours } from '@packlink/packlink-sdk';
import { SubscriptionFeatureBehavioursContext } from '../providers/SubscriptionFeatureBehavioursContext';
import { useTenantSubscriptionConfig } from './useTenantSubscriptionConfig';

export function useSubscriptionFeatureBehaviours() {
    const context = useContext(SubscriptionFeatureBehavioursContext);

    if (!context) {
        throw new Error('Wrap your Component in a SubscriptionFeatureBehavioursProvider Component');
    }

    return context;
}

export function useSubscriptionFeature<T extends ClientFeature>(featureName: T) {
    return useSubscriptionFeatures([featureName])[0];
}

export function useSubscriptionFeatures<T extends ClientFeature>(featureNames: T[]) {
    const subscriptionFeatureBehaviours = useSubscriptionFeatureBehaviours();
    const { isTenantSubscriptionEnabled } = useTenantSubscriptionConfig();

    return useMemo(() => {
        return featureNames.map((featureName) => {
            const selectedFeature = subscriptionFeatureBehaviours.clientFeatureBehaviours[featureName];
            const isFeatureAvailable = subscriptionFeatureBehaviours.availableFeatures?.[featureName];

            return {
                isTenantSubscriptionEnabled,
                subscription: subscriptionFeatureBehaviours.subscription,
                isLoading: subscriptionFeatureBehaviours.isLoading,
                feature: {
                    behaviourProperties:
                        (selectedFeature?.behaviourProperties as Extract<
                            FeaturesBehaviours,
                            { featureName: T }
                        >['behaviourProperties']) || {},
                    featureName,
                    isEnabled: !!selectedFeature,
                    isAvailable: isFeatureAvailable,
                },
            };
        });
    }, [
        isTenantSubscriptionEnabled,
        featureNames,
        subscriptionFeatureBehaviours.clientFeatureBehaviours,
        subscriptionFeatureBehaviours.availableFeatures,
        subscriptionFeatureBehaviours.subscription,
        subscriptionFeatureBehaviours.isLoading,
    ]);
}
