import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentDetailsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flex: '1 1 auto',
        margin: 'auto',
        minWidth: '1280px',
        maxWidth: theme.spacing(167),
        background: theme.palette.white,
    });

export const getShipmentDetailsMainStyles = (theme: Theme): SerializedStyles =>
    css({
        flex: '1 1 75%',
        padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    });

export const getShipmentDetailsHeaderStyles =
    (showSubscriptionNotification: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            marginBottom: showSubscriptionNotification ? 0 : theme.spacing(6),
        });
