import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SubscriptionPlan } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { InlineNotification, NotificationType, Spinner, Typography } from '@shipengine/giger';
import { AppDispatch } from '@store';
import { clearActivePanel } from '@store/actions/side-panel';
import { useDateFormat } from '@hooks/useDateFormat';
import { useAmplitude } from '@hooks/useAmplitude';
import { useLastClientSubscription, useSubscriptionPlans } from '../../hooks/useClientSubscriptions';
import { getNotificationStyles, getSpinnerStyles, sectionStyles } from './SubscriptionsSectionStyles';
import { CancelDialog } from './components/CancelDialog/CancelDialog';
import { SubscriptionPanel } from '../../../../common/components/SubscriptionPanel/SubscriptionPanel';
import { ClientPlanDetails } from './pages/ClientPlanDetails/ClientPlanDetails';
import { SubscriptionPlanCard } from './pages/SubscriptionPlanCard/SubscriptionPlanCard';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { EditPaymentMethodPanel } from './pages/ClientPlanDetails/components/EditPaymentMethodPanel/EditPaymentMethodPanel';

export function SubscriptionsSection(): JSX.Element {
    const dispatch = useDispatch<AppDispatch>();
    const { sendAmplitudeEvent } = useAmplitude();

    const [isCancelOpen, setIsCancelOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
    const { t } = useTranslation();
    const { isLoading, isActive, isCanceled, lastSubscription } = useLastClientSubscription();
    const { subscriptionPlans, isLoading: loadingPlans } = useSubscriptionPlans();
    const isLoadingInfo = isLoading || loadingPlans;

    const expirationDate = useDateFormat(lastSubscription?.currentPeriodEndsAt);

    const openCancelDialog = () => {
        setIsCancelOpen(true);
    };

    useEffect(() => {
        dispatch(clearActivePanel());
    }, [dispatch]);

    useEffect(() => {
        sendAmplitudeEvent(`${AmplitudeEvents.SUBSCRIPTION_PLAN} ${AmplitudeEventPrefixes.LOAD}`);
    }, [sendAmplitudeEvent]);

    return (
        <section css={sectionStyles}>
            <Typography component="h2" variant="heading3">
                {t('subscriptions.settings.title')}
            </Typography>
            {isLoadingInfo ? (
                <Spinner css={getSpinnerStyles} />
            ) : (
                <>
                    {isCanceled && (
                        <InlineNotification
                            css={getNotificationStyles}
                            title={t('subscriptions.notification.cancelled-title')}
                            type={NotificationType.ALERT}
                        >
                            {t('subscriptions.notification.cancelled-message', {
                                expirationDate: expirationDate,
                            })}
                        </InlineNotification>
                    )}

                    {!isActive &&
                        subscriptionPlans?.map((planInfo) => (
                            <SubscriptionPlanCard
                                key={planInfo.id}
                                onSelect={setSelectedPlan}
                                planDetails={planInfo}
                                isCanceled={isCanceled}
                            />
                        ))}

                    {isActive && lastSubscription && (
                        <ClientPlanDetails subscription={lastSubscription} onCancelFlow={openCancelDialog} />
                    )}
                </>
            )}

            <CancelDialog
                isOpen={isCancelOpen}
                planName={lastSubscription?.plan.name || ''}
                onClose={() => setIsCancelOpen(false)}
                expirationDate={expirationDate}
            />

            <SubscriptionPanel planDetails={selectedPlan} />
            <EditPaymentMethodPanel />
        </section>
    );
}
