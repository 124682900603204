import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

/**
 * TODO: https://packlink.atlassian.net/browse/IDE-712
 * Avoid giving custom style to Giger components using CSS classes.
 * Instead, pass it using the "css" property in the component
 */
export const getPasswordFormStyles = (theme: Theme): SerializedStyles =>
    css({
        '.field': {
            marginBottom: theme.spacing(2),
        },

        '.btn:first-of-type': {
            marginRight: theme.spacing(1),
        },
    });
