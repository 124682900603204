import { PropsWithChildren, useEffect } from 'react';

import moment from 'moment';
import { addBreadcrumb, captureException } from '@sentry/react';

import {
    TranslationProvider as PacklinkTranslationProvider,
    i18next,
    useTranslationProviderStatus,
} from '@packlink/translation-provider';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { Application } from '@packlink/packlink-sdk';
import { ITenantConfig } from '@types';

function TranslationProviderChild({ children }: PropsWithChildren<unknown>) {
    const { loading: translationsLoading, error: translationsError } = useTranslationProviderStatus();
    useEffect(() => {
        if (translationsLoading) return;
        if (translationsError) {
            addBreadcrumb({ level: 'info', message: 'Initialize TranslationProvider' });
            captureException(translationsError);
        }
    }, [translationsError, translationsLoading]);

    return <>{children}</>;
}

export const TranslationProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
    const { defaultLanguage } = useTenantConfig<ITenantConfig>() ?? { defaultLanguage: 'es-ES' };

    const setTranslationsLoaded = async (lang: string) => {
        try {
            lang !== 'en' && (await import(`moment/locale/${lang}`));
            moment.locale(lang);
        } catch {
            moment.locale('en');
        }
    };

    return (
        <PacklinkTranslationProvider
            cdn={config.cdn.baseUrl}
            locale={defaultLanguage}
            debug={!config.isProduction}
            application={Application.BULK}
            useSuspense={false}
            onLoad={setTranslationsLoaded}
        >
            <TranslationProviderChild>{children}</TranslationProviderChild>
        </PacklinkTranslationProvider>
    );
};

window.i18next = i18next;
