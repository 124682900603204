import { WithCommonProps } from '@shipengine/giger';
import { settingsPageStyles } from './SettingsPageStyles';

export function SettingsPage({ children, ...rest }: WithCommonProps<unknown>): JSX.Element {
    return (
        <section css={settingsPageStyles} {...rest}>
            {children}
        </section>
    );
}
