import { Next, WithCommonProps } from '@shipengine/giger';
import { SupportTicketAttachmentDetails } from '@packlink/packlink-sdk';

type SupportTicketAttachmentDetailProps = WithCommonProps<{
    attachment: SupportTicketAttachmentDetails;
}>;

export function SupportTicketAttachmentDetail({ attachment, ...props }: SupportTicketAttachmentDetailProps) {
    return (
        <Next.Link href={attachment.url} target="_blank" {...props}>
            {attachment.fileName}
        </Next.Link>
    );
}
