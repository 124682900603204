import { css, CSSObject, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getSummaryStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });

export const getBackButtonStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'flex-end',
    });

export const getBackButtonIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
    });

export const getHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        textAlign: 'center',
        marginBottom: theme.spacing(2),
    });

export const getHeaderIconStyles =
    (hasErrors: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            color: hasErrors ? theme.palette.alert.main : theme.palette.success.main,
        });

export const getSuccessMsgStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.dark,
        marginBottom: theme.spacing(),
    });

export const getBodyStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: '0 auto',
        padding: `${theme.spacing(2)}px 0`,

        [theme.mediaQuery('tabletLarge')]: {
            backgroundColor: theme.palette.gray.megaLight,
            border: `${theme.palette.gray.ultraLight} 1px solid`,
        },
    });

function getWidthStyles(theme: Theme): CSSObject {
    return {
        [theme.mediaQuery('mobileLarge')]: {
            width: '50%',
        },

        [theme.mediaQuery('tabletLarge')]: {
            width: '30%',
            minWidth: 360,
        },
    };
}

export const getContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        margin: '0 auto',
        flexDirection: 'column',
        backgroundColor: theme.palette.white,
        boxShadow: theme.elevations.above.boxShadow,

        ...getWidthStyles(theme),
    });

export const getSummaryLabelsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '0 auto',

        ...getWidthStyles(theme),
    });

export const getLabelsTextStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeight.semibold,
    });

export const getSpinnerStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.secondary.main,
        margin: `${theme.spacing()}px 0`,
    });

export const getLabelsReadyTextStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });

export const getDownloadLabelsStyles = (theme: Theme): SerializedStyles =>
    css({
        border: `1px solid ${theme.palette.gray.light}`,
        borderRadius: theme.borderRadius.S,
        margin: `${theme.spacing(3)}px 0`,
        padding: theme.spacing(3),
        width: '100%',
    });

export const getPaymentErrorModalStyles = (): SerializedStyles =>
    css({
        minWidth: '700px',
    });

export const getPaymentErrorTableStyles = (theme: Theme): SerializedStyles =>
    css({
        maxHeight: '350px',
        textAlign: 'left',
        marginBottom: theme.spacing(3),
    });

export const getPaymentErrorCellStyles = (): SerializedStyles =>
    css({
        whiteSpace: 'normal',
        textOverflow: 'unset',

        ':first-of-type': {
            width: '40%',
        },
    });

export const getLoadingStyles = (theme: Theme): SerializedStyles =>
    css({
        textAlign: 'center',
        marginTop: theme.spacing(2),
    });
