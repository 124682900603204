import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getIconStyles = (theme: Theme): SerializedStyles =>
    css({
        verticalAlign: 'text-top',
        marginLeft: theme.spacing(),
    });

export const getSubtitleStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing()}px 0 ${theme.spacing(2)}px 0`,
    });
