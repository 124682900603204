import { Category, CategoryProps } from '@shipengine/giger';
import { useMatch, useNavigate, useResolvedPath } from 'react-router';

export function RouterCategory({ to, onClick, ...rest }: CategoryProps<'button'> & { to: string }) {
    const navigate = useNavigate();
    const path = useResolvedPath(to);
    const match = useMatch({ path: path.pathname, end: false });

    const goToPage = () => {
        onClick?.();
        navigate(to);
    };

    return <Category onClick={() => goToPage()} active={!!match} aria-selected={!!match} role="tab" {...rest} />;
}
