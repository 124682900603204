import useEffectOnce from 'react-use/lib/useEffectOnce';
import { EventName } from '@types';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';
import { cleanSelectedShipments } from '@store/actions/bulk';
import { ShipmentDetailsContent } from './components/ShipmentDetailsContent/ShipmentDetailsContent';
import { ShipmentDetailsHeader } from './components/ShipmentDetailsHeader/ShipmentDetailsHeader';
import { ShipmentDetailsSidebar } from './components/ShipmentDetailsSidebar/ShipmentDetailsSidebar';
import {
    getShipmentDetailsHeaderStyles,
    getShipmentDetailsMainStyles,
    getShipmentDetailsStyles,
} from './ShipmentDetailsStyles';
import { useEffect, useState } from 'react';
import { IProShipmentApiResponse, ProShipment } from '@packlink/packlink-sdk';
import { getClientIdentifier } from '@store/selectors/client';
import { useEvent } from '@packlink/event-handler';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';
import { SubscriptionNotification } from '@common/components/SubscriptionNotification/SubscriptionNotification';
import { useTranslation } from '@packlink/translation-provider';
import { getNotificationSpacingStyles } from '@common/components/SubscriptionNotification/SubscriptionNotificationStyles';
import { useLastClientSubscription } from '@Subscriptions/hooks/useClientSubscriptions';

type ShipmentDetailsProps = {
    shipment: ProShipment;
};

export const ShipmentDetails = ({ shipment: propShipment }: ShipmentDetailsProps): JSX.Element => {
    const clientId = useSelector(getClientIdentifier);
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const { isTenantSubscriptionEnabled, isActive, isCanceled, isLoading } = useLastClientSubscription();
    const showSubscriptionNotification = isTenantSubscriptionEnabled && !isActive && !isCanceled && !isLoading;

    const { eventBind, eventUnbind } = useEvent<IProShipmentApiResponse>(clientId, EventName.SHIPMENT_UPDATED);
    const [shipment, setShipment] = useState<ProShipment>(propShipment);

    useEffectOnce(() => sendAmplitudeEvent(AmplitudeEvents.DETAILS_PAGE));

    useShipmentCleanUpEffects();

    useEffect(() => {
        const shipmentUpdatedCallback = (updatedShipment: IProShipmentApiResponse) => {
            if (shipment.data.packlinkReference === updatedShipment.data.packlink_reference) {
                setShipment(ShipmentUtils.proShipmentCreateFromResponse(updatedShipment));
            }
        };
        eventBind(shipmentUpdatedCallback);
        return () => {
            eventUnbind(shipmentUpdatedCallback);
        };
    }, [eventBind, eventUnbind, shipment.data.packlinkReference]);

    return (
        <article css={getShipmentDetailsStyles} data-id="shipment-details">
            <section css={getShipmentDetailsMainStyles}>
                <ShipmentDetailsHeader
                    css={getShipmentDetailsHeaderStyles(showSubscriptionNotification)}
                    shipment={shipment}
                />

                {showSubscriptionNotification && (
                    <SubscriptionNotification
                        css={getNotificationSpacingStyles}
                        title={t('subscriptions.upgrade-notification.branded-emails')}
                        amplitudeEvent={AmplitudeEvents.SUBSCRIPTION_POSTSALE_BANNER}
                    >
                        {t('subscriptions.upgrade-notification.branded-emails-message')}
                    </SubscriptionNotification>
                )}

                <ShipmentDetailsContent shipment={shipment} />
            </section>
            <ShipmentDetailsSidebar shipment={shipment} />
        </article>
    );
};

function useShipmentCleanUpEffects() {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(cleanSelectedShipments());
    }, [dispatch]);
}
