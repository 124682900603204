import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, IDropdownOption, Input, Option, Select, WithCommonProps } from '@shipengine/giger';
import { SupportTicketStatus } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import React, { useMemo } from 'react';
import {
    getShipmentReferenceFilterStyles,
    getStatusFilterStyles,
    getSupportTicketsTableFiltersStyles,
} from './SupportTicketsTableFiltersStyles';
import { SupportTicketsTableFiltersData } from '../../SupportTicketsTableData';

type SupportTicketsTableFiltersProps = WithCommonProps<{
    filters: SupportTicketsTableFiltersData;
    onChange: (data: SupportTicketsTableFiltersData) => void;
}>;

export function SupportTicketsTableFilters({
    onChange,
    filters,
    ...props
}: SupportTicketsTableFiltersProps): JSX.Element {
    const { t } = useTranslation();
    const shipmentReferenceFilterProps = useShipmentReferenceFilter({ onChange, filters });
    const {
        onChange: onStatusChange,
        onClear: onStatusClear,
        options,
        value: status,
    } = useStatusFilter({ onChange, filters });
    return (
        <div css={getSupportTicketsTableFiltersStyles} {...props}>
            <Input
                css={getShipmentReferenceFilterStyles}
                name="shipmentReference"
                label={t('support-center.tickets-filters.shipment-reference')}
                clearButtonTooltip={t('support-center.tickets-filter.clear-tooltip')}
                leftContent={<Icon name={IconNames.SEARCH} size={IconSize.SIZE_MEDIUM} />}
                {...shipmentReferenceFilterProps}
            />
            <Select
                css={getStatusFilterStyles}
                name="status"
                label={t('support-center.tickets-filters.ticket-status')}
                clearButtonTooltip={t('support-center.tickets-filter.clear-tooltip')}
                value={status}
                onChange={onStatusChange}
                onClear={onStatusClear}
            >
                {options.map((item: IDropdownOption<unknown>) => (
                    <Option key={item.value} value={item} selected={status?.value === item.value}>
                        {item.label}
                    </Option>
                ))}
            </Select>
        </div>
    );
}

function useShipmentReferenceFilter({
    onChange: onFilterUpdate,
    filters,
}: Pick<SupportTicketsTableFiltersProps, 'onChange' | 'filters'>) {
    const value = filters.shipmentReference;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        onFilterUpdate({
            ...filters,
            shipmentReference: e.target.value,
        });

    const onClear = () =>
        onFilterUpdate({
            ...filters,
            shipmentReference: '',
        });

    return {
        value,
        onChange,
        onClear,
    };
}

function useStatusFilter({
    onChange: onFilterUpdate,
    filters,
}: Pick<SupportTicketsTableFiltersProps, 'onChange' | 'filters'>) {
    const { t } = useTranslation();

    const options = useMemo(
        () => [
            {
                // Includes OPEN and NEW states
                value: `${SupportTicketStatus.OPEN},${SupportTicketStatus.NEW}`,
                label: t('support-center.tickets-table.support-ticket-status-open'),
            },
            {
                value: SupportTicketStatus.AWAITING_INFORMATION,
                label: t('support-center.tickets-table.support-ticket-status-awaiting-information'),
            },
            {
                value: `${SupportTicketStatus.SOLVED},${SupportTicketStatus.CLOSED}`,
                label: t('support-center.tickets-table.support-ticket-status-solved'),
            },
        ],
        [t],
    );

    const value = useMemo(() => options.find(({ value }) => value === filters.status), [filters.status, options]);

    const onChange = (_name: string, option: IDropdownOption<unknown> | null) =>
        onFilterUpdate({
            ...filters,
            status: option ? (option.value as SupportTicketStatus) : undefined,
        });

    const onClear = () => onFilterUpdate({ ...filters, status: undefined });

    return {
        onChange,
        onClear,
        options,
        value,
    };
}
