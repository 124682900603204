import { APP_ROUTE } from '@pages/router/routes';

export function getTicketDetailsRoute(id: string) {
    return APP_ROUTE.SUPPORT_CENTER.TICKET_DETAILS.replace(':id', id);
}

export type RouteData = {
    path: string;
    name: string;
};
