import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from '@packlink/translation-provider';
import { SubscriptionPlan } from '@packlink/packlink-sdk';
import { SidePanel, SlideInPosition } from '@shipengine/giger';
import { PanelName } from '@types';
import { useSidePanel } from '@hooks/useSidePanel';
import { useAmplitude } from '@hooks/useAmplitude';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';
import { SubscriptionPanelContent } from './SubscriptionPanelContent';

interface SubscriptionPanelProps {
    planDetails?: SubscriptionPlan;
    nextPage?: string;
}

export const SubscriptionPanel = ({ planDetails, nextPage }: SubscriptionPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();
    const { close, isOpen } = useSidePanel(PanelName.SUBSCRIPTIONS);
    const navigate = useNavigate();
    const [doNavigation, setDoNavigation] = useState(false);

    const closeAndNavigate = () => {
        close();
        doNavigation && nextPage && navigate(nextPage);
    };

    const handleClose = () => {
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.X, {
            [AmplitudeProperties.PANEL_NAME]: PanelName.SUBSCRIPTIONS,
        });
        closeAndNavigate();
    };

    return (
        <SidePanel
            css={getSidePanelClipPathStyles(SlideInPosition.RIGHT)}
            isOpen={isOpen}
            containerId="shipments-main"
            applyBackdropToBody={false}
            onBackdropClick={closeAndNavigate}
            header={<SidePanelHeader onAction={handleClose} title={t('subscriptions.side-panel.header-title')} />}
        >
            {isOpen && planDetails && (
                <SubscriptionPanelContent
                    planDetails={planDetails}
                    close={closeAndNavigate}
                    successCallback={nextPage ? () => setDoNavigation(true) : undefined}
                />
            )}
        </SidePanel>
    );
};
