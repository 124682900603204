import { ActiveIntegrationImport } from '@Integrations/types/integration';
import { GenericErrorContent } from './ImportOrdersErrorContent/GenericErrorContent';
import { InvalidAppKeyErrorContent } from './ImportOrdersErrorContent/InvalidAppKeyErrorContent';
import { SuspendedAccountErrorContent } from './ImportOrdersErrorContent/SuspendedAccountErrorContent';
import { ImportOrdersSubtype } from './ImportOrdersModal';

interface IUnauthorizedImportOrderProps {
    onClose: () => void;
    integration: ActiveIntegrationImport;
    subtype?: ImportOrdersSubtype;
}

export const UnauthorizedImportOrder = ({
    onClose,
    integration,
    subtype = ImportOrdersSubtype.GENERIC,
}: IUnauthorizedImportOrderProps): JSX.Element => {
    const content: JSX.Element = {
        [ImportOrdersSubtype.INVALID_APP_KEY_ERROR]: (
            <InvalidAppKeyErrorContent onClose={onClose} integration={integration} />
        ),
        [ImportOrdersSubtype.GENERIC]: <GenericErrorContent onClose={onClose} integration={integration} />,
        [ImportOrdersSubtype.SUSPENDED_ACCOUNT]: (
            <SuspendedAccountErrorContent onClose={onClose} integration={integration} />
        ),
    }[subtype] || <GenericErrorContent onClose={onClose} integration={integration} />;

    return <>{content}</>;
};
