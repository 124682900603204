// Typings related to Zendesk Widget API
// More info: https://developer.zendesk.com/api-reference/widget-messaging/web/core/

declare global {
    interface Window {
        zE: ZendeskWidgetAPI;
    }
}

export type ZendeskWidgetAPI = (action: string, event: string, parameters?: unknown) => void;

export enum ZendeskWidgetAction {
    OPEN = 'open',
    CLOSE = 'close',
}
