import { TFunction } from 'i18next';
import * as Yup from 'yup';

export type SendLabelByEmailFormData = {
    mail: string;
    message: string;
};

export const sendLabelByEmailFormInitialValues = {
    mail: '',
    message: '',
};

export function getSendLabelByEmailFormValidationSchema(
    t: TFunction,
): Yup.ObjectSchema<SendLabelByEmailFormData | undefined> {
    return Yup.object<SendLabelByEmailFormData>().shape({
        mail: Yup.string().email(t('forms.error.email-invalid')).required(t('forms.errors.email-required')),
        message: Yup.string().required(t('forms.error.field-required')),
    });
}
