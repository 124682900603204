import { useTenantBehavior } from '@packlink/tenant-config-provider';

export function useTenantSubscriptionConfig() {
    const getTenantBehavior = useTenantBehavior();
    const subscriptionBehavior = getTenantBehavior<{ enabled: boolean }>('SubscriptionModel');

    const isTenantSubscriptionEnabled = !!subscriptionBehavior?.properties.enabled;

    return { isTenantSubscriptionEnabled };
}
