import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentListStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',

        [theme.mediaQuery('tablet')]: {
            overflow: 'visible',
        },
    });

export const getEmptyListButtonSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(3),

        '.btn:not(:last-child)': {
            marginRight: theme.spacing(3),
        },
    });

export const getNotificationStyles = (): SerializedStyles =>
    css({
        borderRadius: 0,
    });
