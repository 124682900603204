import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getCheckIconStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.error.main,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(),
    });

export const getTextStyles = (): SerializedStyles =>
    css({
        textAlign: 'center',
    });
