import { ChangeEvent, useState } from 'react';
import * as yup from 'yup';
import { Next } from '@shipengine/giger';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { TFunction, Trans, useTranslation } from '@packlink/translation-provider';
import { CashOnDelivery as CashOnDeliveryModel } from '@packlink/packlink-sdk';
import { SwitchCard } from '../SwitchCard/SwitchCard';
import { CashOnDeliveryForm } from './CashOnDeliveryForm';
import { useFormikContext } from 'formik';
import { IDetailsStepForm } from '../DetailsStep';
import { isValidIban } from '@utils/iban';

export interface ICashOnDeliveryForm {
    accountHolder: string | undefined;
    amount: number | undefined;
    iban: string | undefined;
    selected: boolean | undefined;
}

export interface ICashOnDeliveryFormErrors {
    accountHolder: string;
    amount: string;
    iban: string;
    selected: string;
}

interface CashOnDeliveryProps {
    handler: (formValues: ICashOnDeliveryForm) => void;
    currency: string;
    price: number;
}

export const getCashOnDeliveryInitialValues = (
    hasCod: boolean,
    cod: CashOnDeliveryModel | undefined,
): ICashOnDeliveryForm => ({
    accountHolder: (hasCod && cod?.accountHolder) || undefined,
    amount: (hasCod && cod?.amount) || undefined,
    iban: (hasCod && cod?.iban) || undefined,
    selected: !!(hasCod && (cod?.accountHolder || cod?.amount || cod?.iban)),
});

export const getCashOnDeliveryValidation = (
    t: TFunction,
    amount: { max: number; formatted: string },
): yup.ObjectSchema<ICashOnDeliveryForm | undefined> => {
    return yup.object<ICashOnDeliveryForm>().shape({
        accountHolder: yup
            .string()
            .trim()
            .when('selected', {
                is: (selected: boolean): boolean => selected,
                then: yup
                    .string()
                    .min(1, t('form.error.min', { field: t('form.label.account-holder'), number: 1 }))
                    .max(50, t('form.error.max', { field: t('form.label.account-holder'), number: 50 }))
                    .required(t('form.error.required', { field: t('form.label.account-holder') })),
            }),
        amount: yup.number().when('selected', {
            is: (selected: boolean): boolean => selected,
            then: yup
                .number()
                .positive(t('form.error.positive', { field: t('form.label.amount') }))
                .lessThan(
                    amount.max,
                    t('form.error.max-amount', {
                        amount: amount.formatted,
                        field: t('form.label.amount'),
                    }),
                )
                .typeError(t('form.error.required', { field: t('form.label.amount') }))
                .required(t('form.error.required', { field: t('form.label.amount') })),
        }),
        iban: yup
            .string()
            .trim()
            .when('selected', {
                is: (selected: boolean): boolean => selected,
                then: yup
                    .string()
                    .min(1, t('form.error.min', { field: t('form.label.iban'), number: 1 }))
                    .max(50, t('form.error.max', { field: t('form.label.iban'), number: 50 }))
                    .required(t('form.error.required', { field: t('form.label.iban') }))
                    .test({
                        name: 'is-iban-or-account-number',
                        message: t('form.error.invalid-iban'),
                        test: (value) => value && isValidIban(value),
                    }),
            }),
        selected: yup.boolean().required(),
    });
};

export const CashOnDelivery = ({ handler, currency, price }: CashOnDeliveryProps): JSX.Element => {
    const { t } = useTranslation();
    const { values } = useFormikContext<IDetailsStepForm>();
    const [checked, setChecked] = useState(values.cashOnDelivery?.selected ?? false);
    const getHelpCenterPage = useHelpCenter();
    const codHelpCenterPage = getHelpCenterPage(HELP_CENTER_KEYS.cashOnDelivery);

    const showFormAndHandle = (e: ChangeEvent<HTMLInputElement>): void => {
        setChecked(e.target.checked);
        const codFormValues = e.target.checked
            ? { ...values.cashOnDelivery, selected: true }
            : { accountHolder: undefined, amount: undefined, iban: undefined, selected: false };
        handler(codFormValues);
    };

    return (
        <SwitchCard name="cashOnDelivery" checked={checked} handleChange={showFormAndHandle} showDescription={false}>
            {checked && (
                <>
                    <Trans
                        i18nKey="form.cod.info"
                        components={[
                            <Next.Link
                                key="link"
                                title={t('form.cod.more-info')}
                                href={codHelpCenterPage}
                                isExternal
                                target="_blank"
                            />,
                        ]}
                    />
                    <CashOnDeliveryForm currency={currency} price={price} handler={handler} />
                </>
            )}
        </SwitchCard>
    );
};
