import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';
import { ShipmentUtils } from '@utils/ShipmentUtils';

export const getShipmentDetailsHeaderStyles = (): SerializedStyles =>
    css({
        flex: '0 0 auto',
    });

export const getShipmentDetailsHeaderTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        fontWeight: theme.typography.fontWeight.light,
        margin: 0,
    });

export const getShipmentDetailsHeaderReceiverStyles = (theme: Theme): SerializedStyles =>
    css({
        fontWeight: theme.typography.fontWeight.semibold,
    });

export const getShipmentDetailsHeaderStatusStyles =
    (state: string) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            alignItems: 'center',
            lineHeight: theme.typography.lineHeight.M,
            color: ShipmentUtils.getColorByState(state, theme),
            fontWeight: theme.typography.fontWeight.light,
        });

export const getShipmentDetailsHeaderIcon = css({
    display: 'flex',
});
