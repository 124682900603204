import { useCallback } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { Integration } from '@packlink/packlink-sdk';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { ITenantConfig } from '@types';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { IntegrationConfigurations } from '@Integrations/components/IntegrationPanel/types';
import { getHeaderActionsIntegrationsStyles } from './AppHeaderActionsItemsStyles';
import { useAmplitude } from '@hooks/useAmplitude';
import { useIntegrations } from '@common/hooks/useIntegrations';

interface IComponentProps {
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export interface IAppHeaderActionsItemsProps {
    onOpenImportsModal: (integration: Integration<IntegrationConfigurations>) => void;
    onOpenImportCSVModal: () => void;
    onCreateShipment: () => void;
    onClickSetupIntegrations?: () => void;
    as: React.ElementType<IComponentProps>;
}

export const AppHeaderActionsItems: React.FC<IAppHeaderActionsItemsProps> = (
    props: IAppHeaderActionsItemsProps,
): JSX.Element => {
    const {
        onOpenImportsModal,
        onCreateShipment,
        onOpenImportCSVModal,
        onClickSetupIntegrations,
        as: Component,
    } = props;
    const { t } = useTranslation();
    const { canConnectToEshop } = useTenantConfig<ITenantConfig>();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const onClickImportPartner = useCallback(
        (integration: Integration<IntegrationConfigurations>): (() => void) =>
            (): void => {
                const eventName =
                    `${AmplitudeEventPrefixes.OPEN} ${AmplitudeEvents.INTEGRATION_IMPORT_MODAL}` as AmplitudeEvents;
                sendAmplitudeClickEvent(eventName);
                onOpenImportsModal(integration);
            },
        [onOpenImportsModal, sendAmplitudeClickEvent],
    );

    const { integrations } = useIntegrations();

    return (
        <>
            <Component onClick={onCreateShipment}>{t('header.actions.new-shipment')}</Component>

            {onClickSetupIntegrations && canConnectToEshop && (
                <Component onClick={onClickSetupIntegrations}>{t('header.actions.connect-shop')}</Component>
            )}

            <Component onClick={onOpenImportCSVModal}>{t('header.actions.import-csv')}</Component>

            {integrations && integrations.length > 0 && (
                <section css={getHeaderActionsIntegrationsStyles}>
                    {integrations
                        .filter(
                            (integration: Integration<IntegrationConfigurations>) =>
                                !!integration.configuration?.identifier,
                        )
                        .map(
                            (integration: Integration<IntegrationConfigurations>): JSX.Element => (
                                <Component onClick={onClickImportPartner(integration)} key={integration.alias}>
                                    {t('header.actions.import-partner', {
                                        partner: integration.name,
                                    })}
                                </Component>
                            ),
                        )}
                </section>
            )}
        </>
    );
};
