import React from 'react';

import { Grid, GridChild, Icon, IconSize, Next, Typography } from '@shipengine/giger';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { IconNames } from '@shipengine/giger-theme';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { getSupportCenterCardStyles } from '@SupportCenter/SupportCenterStyles';
import { AmplitudeEvents, AmplitudeProperties, SOURCE_AMPLITUDE_PROPERTY } from '@constants/amplitude';

import {
    getCommonQuestionsFooterStyles,
    getCommonQuestionsStyles,
    getCommonQuestionsTitleStyles,
    getNotificationStyles,
    getQuestionGroupStyles,
    getQuestionGroupTitleStyles,
    getQuestionsListStyles,
    getQuestionStyles,
} from './CommonQuestionsStyles';
import { useLastClientSubscription } from '@Subscriptions/hooks/useClientSubscriptions';
import { SubscriptionNotification } from '@common/components/SubscriptionNotification/SubscriptionNotification';

export function CommonQuestions() {
    const getHelperCenter = useHelpCenter();
    const { t } = useTranslation();
    const { isTenantSubscriptionEnabled, isActive, isCanceled, isLoading } = useLastClientSubscription();
    const showSubscriptionLink = isTenantSubscriptionEnabled && !isActive && !isCanceled && !isLoading;

    return (
        <Grid noPadding={true} css={getCommonQuestionsStyles}>
            <GridChild colSpan={12}>
                <Typography variant="heading4" component="h3" css={getCommonQuestionsTitleStyles}>
                    {t('support-center.common-questions.title')}
                </Typography>
            </GridChild>
            <QuestionGroup title={t('support-center.common-questions.billing')} icon={IconNames.MONEY}>
                <Question pageKey={HELP_CENTER_KEYS.unknownCharges}>
                    {t('support-center.common-questions.unknown-charges')}
                </Question>
                <Question pageKey={HELP_CENTER_KEYS.getAnInvoice}>
                    {t('support-center.common-questions.get-invoice')}
                </Question>
                <Question pageKey={HELP_CENTER_KEYS.paymentError}>
                    {t('support-center.common-questions.payment-error')}
                </Question>
            </QuestionGroup>
            <QuestionGroup title={t('support-center.common-questions.cancellations')} icon={IconNames.AGENT}>
                <Question pageKey={HELP_CENTER_KEYS.cancelAShipment}>
                    {t('support-center.common-questions.cancel-shipment')}
                </Question>
                <Question pageKey={HELP_CENTER_KEYS.refundWhen}>
                    {t('support-center.common-questions.refund-when')}
                </Question>
                <Question pageKey={HELP_CENTER_KEYS.cancellationPolicy}>
                    {t('support-center.common-questions.cancellation-policy')}
                </Question>
            </QuestionGroup>
            <QuestionGroup title={t('support-center.common-questions.delivery')} icon={IconNames.HAND_BOX}>
                <Question pageKey={HELP_CENTER_KEYS.proofOfDelivery}>
                    {t('support-center.common-questions.proof-of-delivery')}
                </Question>
                <Question pageKey={HELP_CENTER_KEYS.damagedParcel}>
                    {t('support-center.common-questions.damaged-parcel')}
                </Question>
                <Question pageKey={HELP_CENTER_KEYS.missingParcels}>
                    {t('support-center.common-questions.missing-parcel')}
                </Question>
            </QuestionGroup>
            <GridChild colSpan={12} css={getCommonQuestionsFooterStyles}>
                <div>
                    <Trans
                        i18nKey="support-center.common-questions.footer-with-faq"
                        components={[
                            <Next.Link
                                key="link"
                                isExternal
                                target="_blank"
                                href={getHelperCenter(HELP_CENTER_KEYS.home)}
                            />,
                        ]}
                    />
                </div>
                {showSubscriptionLink && (
                    <SubscriptionNotification
                        css={getNotificationStyles}
                        title={t('subscriptions.upgrade-notification.phone-support')}
                        amplitudeEvent={AmplitudeEvents.CALL_AGENT_HELP_CENTER}
                        amplitudeProperties={{
                            [AmplitudeProperties.SOURCE]: SOURCE_AMPLITUDE_PROPERTY.LINK,
                        }}
                    >
                        {t('subscriptions.upgrade-notification.phone-support-message')}
                    </SubscriptionNotification>
                )}
            </GridChild>
        </Grid>
    );
}

function QuestionGroup({ title, icon, children }: { title: string; icon: IconNames; children: React.ReactNode }) {
    return (
        <GridChild css={[getQuestionGroupStyles, getSupportCenterCardStyles]} colSpan={12} colSpanTablet={4}>
            <Typography variant="heading4" component="h4" css={getQuestionGroupTitleStyles}>
                <Icon name={icon} size={IconSize.SIZE_LARGE} />
                {title}
            </Typography>
            <ul css={getQuestionsListStyles}>{children}</ul>
        </GridChild>
    );
}

function Question({ pageKey, children }: { pageKey: HELP_CENTER_KEYS; children: React.ReactNode }) {
    const getHelperCenter = useHelpCenter();

    return (
        <li css={getQuestionStyles}>
            <Next.Link href={getHelperCenter(pageKey)} target="_blank" isExternal>
                {children}
            </Next.Link>
        </li>
    );
}
