import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { AmplitudeEventsPro } from '@constants/amplitude';
import { useDeactivate } from '@Integrations/hooks/useDeactivate';
import { setSidePanelError } from '@Integrations/store/actions/integrations';
import { ActiveIntegrationImport } from '@Integrations/types/integration';
import { Button, WithCommonProps } from '@shipengine/giger';
import { ApiClientError } from '@packlink/packlink-sdk';
import { APP_ROUTE } from '@pages/router/routes';
import { AppDispatch } from '@store';
import { logSdkError } from '@utils/logger';
import { useNavigate } from 'react-router';
import { GET_ALL_CONFIGURATIONS_QUERY_KEY } from '@common/hooks/useIntegrations';

type ImportOrdersDeactivateButtonProps = WithCommonProps<{
    label: string;
    event: AmplitudeEventsPro;
    integration: ActiveIntegrationImport;
    onClose: () => void;
}>;

export function ImportOrdersDeactivateButton({
    label,
    event,
    integration,
    onClose,
    ...props
}: ImportOrdersDeactivateButtonProps) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const dispatch = useDispatch<AppDispatch>();
    const { deactivateIntegration } = useDeactivate(event);

    const handleDeactivationComplete = (showError = false) => {
        dispatch(setSidePanelError(showError));
        navigate(APP_ROUTE.SETTINGS.INTEGRATIONS_ALIAS.replace(':alias', integration.alias));

        onClose();
    };

    const onDeactivate = (): Promise<void> => {
        return deactivateIntegration(integration.alias, integration.configuration?.identifier)
            .then(async () => {
                await queryClient.invalidateQueries([GET_ALL_CONFIGURATIONS_QUERY_KEY]);
                handleDeactivationComplete();
            })
            .catch((error: ApiClientError) => {
                logSdkError(error);
                handleDeactivationComplete(true);
            });
    };

    return (
        <Button onClick={onDeactivate} {...props}>
            {label}
        </Button>
    );
}
