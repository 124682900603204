import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getAddressBookChecked, getCheckoutPriceDetails, getCheckoutShipment } from '@store/selectors/checkout';
import { getAutomaticPaymentMethod, getIsPaymentStepSkippable } from '@store/selectors/payment';
import { useCheckoutPath } from '@pages/checkout/hooks/useCheckoutPath';
import { CheckoutRoute } from '@pages/checkout/routes';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useCreateCheckoutOrder } from './useCreateCheckoutOrder';
import { PaymentMethod, PaymentType } from '@packlink/packlink-sdk';
import { useAmplitude } from '@hooks/useAmplitude';

export function useGoToPaymentStep() {
    const isPaymentStepSkippable = useSelector(getIsPaymentStepSkippable);
    const automaticPaymentMethod = useSelector(getAutomaticPaymentMethod);
    const shipment = useSelector(getCheckoutShipment);
    const priceDetails = useSelector(getCheckoutPriceDetails);
    const addressBookChecked = useSelector(getAddressBookChecked);
    const { createCheckoutOrder } = useCreateCheckoutOrder();
    const navigate = useNavigate();
    const getCheckoutPath = useCheckoutPath();
    const { sendAmplitudeShipmentEvent } = useAmplitude();

    const goToPaymentStep = useCallback(
        (carriersTermsAndConditions?: boolean) => {
            if (isPaymentStepSkippable) {
                createCheckoutOrder({
                    type: automaticPaymentMethod?.type as PaymentType,
                    method: automaticPaymentMethod?.method as PaymentMethod,
                    carriersTermsAndConditions,
                });
            } else {
                const amplitudeData = {
                    [AmplitudeProperties.PRICE]: priceDetails?.price.basePrice,
                    [AmplitudeProperties.ADDRESS_BOOK_CHECKED]: addressBookChecked,
                    [AmplitudeProperties.CUSTOMS_REQUIRED]: shipment.hasCustoms,
                    [AmplitudeProperties.INSURANCE]: shipment.upsales?.insurance?.available,
                    [AmplitudeProperties.SERVICE_ID]: shipment.service?.id,
                };
                sendAmplitudeShipmentEvent(AmplitudeEvents.CHECKOUT_PAYMENT, shipment, amplitudeData);

                navigate(getCheckoutPath(CheckoutRoute.PAYMENT));
            }
        },
        [
            isPaymentStepSkippable,
            createCheckoutOrder,
            automaticPaymentMethod?.type,
            automaticPaymentMethod?.method,
            priceDetails?.price.basePrice,
            addressBookChecked,
            shipment,
            sendAmplitudeShipmentEvent,
            navigate,
            getCheckoutPath,
        ],
    );

    return { goToPaymentStep };
}
