import { Formik, FormikProps } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { ConfigurationType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { DummyMarketplaceIntegrationForm } from './DummyMarketplaceIntegrationForm';
import {
    DummyMarketplaceConfiguration,
    DummyMarketplaceIntegrationPanelContentProps,
    IDummyMarketplaceFormData,
} from './types';

export const DummyMarketplaceIntegrationPanel: FC<DummyMarketplaceIntegrationPanelContentProps> = (
    props: DummyMarketplaceIntegrationPanelContentProps,
): JSX.Element => {
    const { t } = useTranslation();
    const { onClose, integration, onDeactivate, onSubmit, isLoading, showError, clearError, onCancel } = props;

    const dummyMarketplaceConfiguration = integration.configuration;

    const defaultConfiguration: IDummyMarketplaceFormData = {
        username: dummyMarketplaceConfiguration?.data?.username || '',
        password: dummyMarketplaceConfiguration?.data?.password || '',
    };

    const dummyMarketplaceOnSubmit = (formData: IDummyMarketplaceFormData): void => {
        const dummyMarketplaceConfiguration: DummyMarketplaceConfiguration = {
            identifier: integration.configuration?.identifier || '',
            integration: EcommerceAlias.DummyMarketplace,
            type: ConfigurationType.INBOUND,
            data: { ...formData },
        };

        const dummyMarketplaceIntegration = { ...integration, configuration: dummyMarketplaceConfiguration };
        onSubmit(dummyMarketplaceIntegration);
    };

    const validationSchema = yup.object().shape({
        username: yup
            .string()
            .required(t('form.error.required', { field: t('integrations.dummyMarkerplace.username') })),
        password: yup
            .string()
            .required(t('form.error.required', { field: t('integrations.dummyMarkerplace.password') })),
    });

    return (
        <Formik
            onSubmit={dummyMarketplaceOnSubmit}
            initialValues={defaultConfiguration}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<IDummyMarketplaceFormData>): React.ReactNode => (
                <DummyMarketplaceIntegrationForm
                    onCancel={onCancel}
                    onClose={onClose}
                    formProps={formProps}
                    isLoading={isLoading}
                    integration={integration}
                    onDeactivate={onDeactivate}
                    showError={showError}
                    clearError={clearError}
                />
            )}
        </Formik>
    );
};
