import { useNavigate } from 'react-router';
import { useTranslation } from '@packlink/translation-provider';
import { ButtonSize, Next, WithCommonProps } from '@shipengine/giger';
import { SupportCenterMetricsEvent } from '@SupportCenter/components/types';
import { APP_ROUTE } from '@pages/router/routes';
import { useAmplitude } from '@hooks/useAmplitude';
import { getNewTicketLinkStyles } from './NewTicketLinkStyles';

type NewTicketLinkProps = WithCommonProps<{
    isFullWidth?: boolean;
}>;

export function NewTicketLink({ isFullWidth = false, ...rest }: NewTicketLinkProps): JSX.Element {
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const navigate = useNavigate();

    const handleClickEvent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        sendAmplitudeEvent(SupportCenterMetricsEvent.OnCreateTicketLinkClick);
        navigate(APP_ROUTE.SUPPORT_CENTER.NEW_TICKET);
    };

    return (
        <Next.Link
            isButton
            onClick={handleClickEvent}
            buttonProps={{ size: ButtonSize.LARGE, isFullWidth: isFullWidth }}
            css={getNewTicketLinkStyles}
            {...rest}
        >
            {t('support-center.actions.new-ticket')}
        </Next.Link>
    );
}
