import React from 'react';

import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Typography } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

import { ProShipment } from '@packlink/packlink-sdk';
import { useDateFormat } from '@hooks/useDateFormat';
import { useFormatCurrency } from '@hooks/useFormatCurrency';

import { ShipmentRowCell } from './ShipmentRowCell';
import { ShipmentRowCellEdit } from './ShipmentRowCellEdit';

import { getContractIconStyles, getSpecialServiceStyles } from './ShipmentRowServicesCellStyles';
import { getShipmentRowSubtitleContractStyles, getShipmentRowSubtitleStyles } from './ShipmentRowStyles';

interface IShipmentRowServicesCell {
    shipment: ProShipment;
    isDraft: boolean;
    onEdit: (e: React.MouseEvent<HTMLParagraphElement>) => void;
}

export const ShipmentRowServicesCell = ({
    shipment: { data: shipmentData },
    isDraft,
    onEdit,
}: IShipmentRowServicesCell): JSX.Element => {
    const { t } = useTranslation();

    const formatedCollectionDate = useDateFormat(shipmentData.collection?.date);
    const formatedCurrency = useFormatCurrency(shipmentData.currency, shipmentData.price?.price?.basePrice);
    const isServiceSet = !!shipmentData.service?.id;
    const titleText = isServiceSet
        ? `${shipmentData.service?.carrierName} ${shipmentData.service?.name}`
        : t('shipment-row.service.service-unavailable');

    return (
        <ShipmentRowCell isEditable={isDraft && isServiceSet} onEdit={onEdit}>
            <ShipmentRowCellEdit title={titleText} isEditable={isDraft && isServiceSet} />
            {isServiceSet && (
                <>
                    <Typography variant="small" component="p" css={getShipmentRowSubtitleContractStyles}>
                        {shipmentData.service?.isOwnContract && (
                            <span css={getSpecialServiceStyles}>
                                <Icon
                                    css={getContractIconStyles}
                                    name={IconNames.CONTRACT}
                                    size={IconSize.SIZE_SMALL}
                                />
                                {t('shipment-row.service.contract')} -
                            </span>
                        )}
                        <span>{formatedCurrency}</span>
                    </Typography>
                    <Typography variant="small" component="p" css={getShipmentRowSubtitleStyles}>
                        {shipmentData.collection?.date &&
                            `${t('shipment-row.service.pick-up')} ${formatedCollectionDate}`}
                    </Typography>
                </>
            )}
        </ShipmentRowCell>
    );
};
