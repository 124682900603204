import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

// Same value as SASS variable $shipment-list-sidebar---width;
export const APP_SIDEBAR_WIDTH = '300px';

export const sidebarStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        width: '100%',
        overflow: 'auto',
        backgroundColor: theme.palette.white,
        borderBottom: `1px solid ${theme.palette.gray.light}`,

        [theme.mediaQuery('tablet')]: {
            width: APP_SIDEBAR_WIDTH,
            height: '100%',
            borderRight: `1px solid ${theme.palette.gray.ultraLight}`,
            borderBottomWidth: 0,
        },
    });

export const inboxesStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        margin: 0,
        padding: 0,
        overflow: 'auto',
        listStyle: 'none',

        [theme.mediaQuery('tablet')]: {
            paddingBottom: theme.spacing(12),
            display: 'block',
        },
    });
