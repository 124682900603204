import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentProtectionStyles = (theme: Theme): SerializedStyles =>
    css({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.white,
        border: `1px solid ${theme.palette.gray.light}`,
        borderRadius: theme.borderRadius.S,
    });

export const getProtectionInfoStyles = (): SerializedStyles =>
    css({
        display: 'inline-flex',
        alignItems: 'center',
    });

export const getProtectionInfoTextStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });

export const getShipmentProtectionTextStyles = (theme: Theme): SerializedStyles =>
    css({
        fontSize: theme.typography.fontSize.S,
        fontWeight: theme.typography.fontWeight.bold,
        color: theme.palette.primary.light,
        alignItems: 'end',
    });
