import { Parcel } from '@packlink/packlink-sdk';

const customParcelId = 'custom-parcel-id';
export const customParcel = {
    name: 'CUSTOM_PARCEL',
    id: customParcelId,
    width: undefined,
    length: undefined,
    height: undefined,
    weight: undefined,
};

export const initialParcelModel = new Parcel(
    undefined,
    undefined,
    undefined,
    undefined,
    customParcel.id,
    customParcel.name,
    undefined,
);
