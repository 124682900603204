import { EmptyState, Icon, IconSize, Typography, WithCommonProps } from '@shipengine/giger';
import { useCdnUrl } from '@packlink/provider';
import { useTranslation } from '@packlink/translation-provider';
import { NewTicketLink } from '@SupportCenter/components/NewTicketLink/NewTicketLink';
import {
    getEmptyStateActionsLinkStyles,
    getEmptyStateActionsStyles,
    getEmptyStateContent,
    getEmptyStateInfoIconStyles,
    getEmptyStateInfoTextStyles,
    getNewTicketLinkStyles,
} from './EmptyTableContentStyles';
import { IconNames } from '@shipengine/giger-theme';
import { CallAgentLink } from '@SupportCenter/components/CallAgentLink/CallAgentLink';
import { useLastClientSubscription } from '@Subscriptions/hooks/useClientSubscriptions';

type EmptyTableContentProps = WithCommonProps<unknown>;

export function EmptyTableContent(props: EmptyTableContentProps): JSX.Element {
    const { t } = useTranslation();
    const cdnUrl = useCdnUrl();
    const src = `${cdnUrl}/apps/support-center/statics/images/no-tickets.png`;
    const { isTenantSubscriptionEnabled, isActive, isCanceled, isLoading } = useLastClientSubscription();
    const showSubscriptionButton = isTenantSubscriptionEnabled && !isActive && !isCanceled && !isLoading;

    return (
        <EmptyState
            title={t('support-center.tickets-table.empty-table-title')}
            subtitle={t('support-center.tickets-table.empty-table-subtitle')}
            src={src}
            css={getEmptyStateContent}
            {...props}
        >
            <Typography component="span" variant="small" css={getEmptyStateInfoTextStyles}>
                <Icon name={IconNames.INFO} size={IconSize.SIZE_SMALL} css={getEmptyStateInfoIconStyles} />{' '}
                {t('support-center.empty-ticket.info')}
            </Typography>

            {showSubscriptionButton && (
                <span css={getEmptyStateActionsStyles}>
                    <NewTicketLink
                        isFullWidth
                        data-id="support-tickets-empty-state-new-ticket"
                        css={getEmptyStateActionsLinkStyles}
                    />
                    <CallAgentLink isFullWidth css={getEmptyStateActionsLinkStyles} />
                </span>
            )}
            {!showSubscriptionButton && (
                <NewTicketLink
                    isFullWidth
                    data-id="support-tickets-empty-state-new-ticket"
                    css={getNewTicketLinkStyles}
                />
            )}
        </EmptyState>
    );
}
