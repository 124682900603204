import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { Outlet } from 'react-router';
import { SettingsBillingNavbar } from '../SettingsBillingNavbar/SettingsBillingNavbar';

export function SettingsBillingLayout(): JSX.Element {
    return (
        <SettingsPage>
            <SettingsBillingNavbar />

            <Outlet />
        </SettingsPage>
    );
}
