import { useRef } from 'react';
import { Button, ButtonColor, ButtonSize, ButtonVariant, Popover, useToast } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { AmplitudeEvents, AmplitudeEventPrefixes } from '@constants/amplitude';
import Packlink from '@sdk';
import { logSdkError } from '@utils/logger';
import { SendLabelByEmailDialog } from './SendLabelByEmailDialog';
import { ProShipment } from '@packlink/packlink-sdk';
import { getSendLabelByEmailContainerStyles } from './SendLabelByEmailStyles';
import { SendLabelByEmailFormData } from './SendLabelByEmailDialogFormData';
import { useDialog } from '@hooks/useDialog';
import { useAmplitude } from '@hooks/useAmplitude';

type SendLabelByEmailProps = {
    shipment: ProShipment;
};

export function SendLabelByEmail({ shipment }: SendLabelByEmailProps): JSX.Element {
    const { t } = useTranslation();
    const { open, handleOpen, handleClose } = useDialog();
    const { handleSubmit } = useSendLabelByEmailHandler(shipment, handleClose);
    const buttonRef = useRef<HTMLButtonElement>(null);
    return (
        <>
            <Button
                ref={buttonRef}
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                color={ButtonColor.PRIMARY}
                data-id="send-labels-button"
                isFullWidth
                onClick={handleOpen}
            >
                {t('post-sale.details.send-label-by-email-label')}
            </Button>
            <Popover
                reference={buttonRef.current}
                isOpen={open}
                placement="left-start"
                onClickAway={handleClose}
                css={getSendLabelByEmailContainerStyles}
            >
                <SendLabelByEmailDialog
                    data-id="send-label-by-email-dialog"
                    onSubmit={handleSubmit}
                    onClose={handleClose}
                />
            </Popover>
        </>
    );
}

function useSendLabelByEmailHandler(shipment: ProShipment, onFinish: () => void) {
    const { t } = useTranslation();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const toast = useToast(t);

    const handleSubmit = async ({ mail, message }: SendLabelByEmailFormData) => {
        const packlinkReference = shipment.data.packlinkReference as string;

        return Packlink.v1.shipmentsNotifications
            .sendLabels(packlinkReference, mail, message)
            .then(() => {
                toast.success({ message: t('post-sale.details.labels-sent-notification') });
                const eventName = `${AmplitudeEvents.SEND_BY_EMAIL} ${AmplitudeEventPrefixes.CONFIRMATION_PAGE}`;
                sendAmplitudeClickEvent(eventName);
            })
            .catch(logSdkError)
            .finally(onFinish);
    };

    return {
        handleSubmit,
    };
}
