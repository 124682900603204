import { css, SerializedStyles, Theme } from '@emotion/react';
import { getLinkStyles } from '@shipengine/giger';

// Override styles for zendesk injected html
export const getZendeskMessageStyles = (theme: Theme): SerializedStyles =>
    css({
        ['ol, ul']: {
            margin: 'revert',
            padding: 'revert',
        },
        img: {
            verticalAlign: 'text-bottom',
            maxWidth: '100%',
        },
        a: getLinkStyles(false)(theme),
        pre: {
            borderRadius: theme.spacing(),
            backgroundColor: theme.palette.gray.megaLight,
            border: `1px solid ${theme.palette.gray.light}`,
            padding: theme.spacing(),
        },
    });

export const getZendeskAttachmentSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(),
    });

export const getZendeskAttachmentWrapperStyles = css({
    display: 'flex',
    flexDirection: 'column',
});
