import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const shipmentExportPanelInboxMsgStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.gray.light}`,
    });

export const shipmentExportPanelInboxTextStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });

export const shipmentExportPanelIconStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'inline-flex',
        marginLeft: theme.spacing(),
        verticalAlign: 'bottom',
        height: 20,
    });
