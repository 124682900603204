import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { useTranslation } from '@packlink/translation-provider';
import { Currency, ProShipment } from '@packlink/packlink-sdk';

import { ShipmentPanelType, UpsellInsuranceType } from '@types';
import { UpsellUtils } from '@utils/UpsellUtils';
import { ServiceUtils } from '@utils/ServiceUtils';
import { APP_ROUTE } from '@pages/router/routes';
import { ShipmentRowCell } from '@components/ShipmentList/ShipmentRow/ShipmentRowCell';
import { ShipmentRowCellEdit } from './ShipmentRowCellEdit';
import { getShipmentRowSubtitleStyles } from './ShipmentRowStyles';
import { Typography } from '@shipengine/giger';

export interface IShipmentRowOtherBenefitsCell {
    shipment: ProShipment;
    isDraft: boolean;
    onEdit: (panel: ShipmentPanelType) => void;
}

export const ShipmentRowOtherBenefitsCell: React.FC<IShipmentRowOtherBenefitsCell> = (
    props: IShipmentRowOtherBenefitsCell,
): JSX.Element => {
    const {
        shipment: {
            data: { service, currency, upsales },
        },
        isDraft,
        onEdit,
    } = props;
    const { t } = useTranslation();
    const serviceException = ServiceUtils.getServiceTranslationKey(
        'insuranceException',
        service?.carrierName,
        service?.name,
    );
    const availableOptions = useMemo(
        () => UpsellUtils.computeAvailableInsurances(props.shipment.data, upsales?.insurance),
        [props.shipment.data, upsales?.insurance],
    );
    const insuranceType = UpsellUtils.getShipmentInsurance(props.shipment.data, upsales?.insurance);
    const navigate = useNavigate();

    const { packlinkReference } = props.shipment.data;
    const isEditable = isDraft && !!service?.id && !serviceException;

    const hasNoInsuranceSelected = insuranceType === UpsellInsuranceType.NO_INSURANCE;
    const isEnhancedOptionAvailable = availableOptions && availableOptions.includes(UpsellInsuranceType.ENHANCED);
    const isMissingServiceToBeAbleToGetInsurance = !service?.id && hasNoInsuranceSelected;
    const shipmentUnprotected = !service?.id || hasNoInsuranceSelected || (!isDraft && hasNoInsuranceSelected);
    const shipmentProtected =
        serviceException ||
        (!isEnhancedOptionAvailable && !hasNoInsuranceSelected) ||
        insuranceType === UpsellInsuranceType.ENHANCED;
    const shipmentPartiallyProtected =
        insuranceType !== UpsellInsuranceType.ENHANCED && isEnhancedOptionAvailable && !hasNoInsuranceSelected;

    const onComplete = (): void => {
        navigate(APP_ROUTE.CHECKOUT.EDIT.replace(':id', packlinkReference));
    };

    const onEditOtherBenefits = (): void => {
        if (isMissingServiceToBeAbleToGetInsurance) {
            onComplete();
            return;
        }

        onEdit(ShipmentPanelType.OTHER_BENEFITS);
    };

    const getTitleText = (): string => {
        if (shipmentUnprotected) {
            return t('shipment-row.insurance.unprotected');
        }
        if (shipmentProtected) {
            return t('shipment-row.insurance.protected');
        }
        if (shipmentPartiallyProtected) {
            return t('shipment-row.insurance.partially-protected');
        }
        return '';
    };

    return (
        <ShipmentRowCell isEditable={isEditable || isMissingServiceToBeAbleToGetInsurance} onEdit={onEditOtherBenefits}>
            <>
                <ShipmentRowCellEdit
                    title={getTitleText()}
                    isEditable={isEditable}
                    data-id={insuranceType}
                    unprotected={shipmentPartiallyProtected || hasNoInsuranceSelected}
                />
                {serviceException && (
                    <Typography variant="small" component="p" css={getShipmentRowSubtitleStyles}>
                        {Currency.replaceI18n(t(serviceException), currency)}
                    </Typography>
                )}
            </>
        </ShipmentRowCell>
    );
};
