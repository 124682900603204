import { useNavigate } from 'react-router';
import { EmptyState } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks';
import { getTileStyles } from './OnboardingTileStyles';

export type OnboardingTile = {
    title: string;
    imageSrc: string;
    navigateTo: string;
    amplitudeEvent: AmplitudeEvents;
};
export const OnboardingTile = ({ title, imageSrc, navigateTo, amplitudeEvent }: OnboardingTile): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const onClickAction = () => {
        sendAmplitudeClickEvent(amplitudeEvent);
        navigate(navigateTo);
    };

    return (
        <div onClick={onClickAction} data-id={`onboarding-tile-${title}`}>
            <EmptyState css={getTileStyles} title={t(title)} src={imageSrc} />
        </div>
    );
};
