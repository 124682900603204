import { FormikProps } from 'formik';
import { FC, useState } from 'react';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelContentError } from '@components/SidePanel/SidePanelContentError';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { IntegrationForm } from '@Integrations/components/IntegrationForm/IntegrationForm';
import { IntegrationDeactivate } from '@Integrations/components/IntegrationDeactivate/IntegrationDeactivate';
import {
    IntegrationFormItem,
    IntegrationFormItemSpacingSize,
} from '@Integrations/components/IntegrationForm/IntegrationFormItem';
import { IntegrationFormLogo } from '@Integrations/components/IntegrationForm/IntegrationFormLogo';
import { IIntegrationPanelContentProps } from '@Integrations/components/IntegrationPanel/types';
import { FormField } from '@shipengine/formik-giger';
import { InlineNotification, Input, NotificationType } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

import { FrontendConfiguredIntegrationGuide } from './FrontendConfiguredIntegrationGuide';
import { FrontendConfiguredConfiguration, IFrontendConfiguredFormData } from './types';

type IFrontendConfiguredIntegrationPanelProps = Omit<
    IIntegrationPanelContentProps<FrontendConfiguredConfiguration>,
    'onSubmit'
>;

interface IFrontendConfiguredIntegrationFormProps extends IFrontendConfiguredIntegrationPanelProps {
    formProps: FormikProps<IFrontendConfiguredFormData>;
}

export const FrontendConfiguredIntegrationForm: FC<IFrontendConfiguredIntegrationFormProps> = (
    props: IFrontendConfiguredIntegrationFormProps,
): JSX.Element => {
    const {
        formProps: { values, isValid, errors, handleChange, handleBlur, touched, setFieldValue },
        integration,
        isLoading,
        onClose,
        onDeactivate,
        showError,
        clearError,
        onCancel,
    } = props;

    const { t } = useTranslation();
    const [successMessage, setSuccessMessage] = useState<string>();

    const handleCancel = (): void => {
        onCancel(integration.name);
    };

    const isMiraklIntegration = integration.alias.includes('mirakl_');

    const handleDeactivate = (): void => {
        onDeactivate(integration).then((integrationName?: string) => {
            if (integrationName) {
                setFieldValue('shop_id', '');
                setFieldValue('api_key', '');
                setSuccessMessage(
                    t('integrations.panel.deactivation-success', {
                        integrationName,
                    }),
                );
            }
        });
    };

    return (
        <IntegrationForm>
            <SidePanelHeader
                onAction={onClose}
                title={t(integration.isConnected ? 'integrations.panel.edit' : 'integrations.panel.connect-shop')}
            />

            <SidePanelContent>
                {!showError && (
                    <>
                        {successMessage && (
                            <InlineNotification type={NotificationType.SUCCESS}>{successMessage}</InlineNotification>
                        )}
                        <IntegrationFormLogo alt={integration.name} logo={integration.logo} />
                        <IntegrationFormItem>
                            <FormField name="shop_id">
                                <Input
                                    disabled={integration.isConnected}
                                    label={t('integrations.frontend-configured.shop-id')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.shop_id}
                                    isInvalid={!!(errors.shop_id && touched.shop_id)}
                                />
                            </FormField>
                        </IntegrationFormItem>
                        <IntegrationFormItem size={IntegrationFormItemSpacingSize.None}>
                            <FormField name="api_key">
                                <Input
                                    disabled={integration.isConnected}
                                    label={t('integrations.frontend-configured.api-key')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.api_key}
                                    isInvalid={!!(errors.api_key && touched.api_key)}
                                />
                            </FormField>
                        </IntegrationFormItem>
                        {integration.isConnected && (
                            <IntegrationFormItem size={IntegrationFormItemSpacingSize.Big}>
                                <IntegrationDeactivate handleDeactivate={handleDeactivate} />
                            </IntegrationFormItem>
                        )}
                        {isMiraklIntegration && (
                            <FrontendConfiguredIntegrationGuide
                                collapsed={integration.isConnected}
                                partner={integration.name}
                            />
                        )}
                    </>
                )}
                {showError && (
                    <SidePanelContentError
                        onTryAgain={clearError}
                        subtitle={t('integrations.panel.error-subtitle')}
                        title={t('integrations.panel.error-title')}
                    />
                )}
            </SidePanelContent>
            {!showError && (
                <SidePanelFooter>
                    <SidePanelFooterActions
                        actionButtonType="submit"
                        actionText={t('integrations.panel.connect')}
                        disabledAction={!isValid}
                        cancelText={t('integrations.panel.cancel')}
                        isLoading={isLoading}
                        onCancel={handleCancel}
                    />
                </SidePanelFooter>
            )}
        </IntegrationForm>
    );
};
