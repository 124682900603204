import { TenantName, TenantPlatform, TenantPlatformCountry, Tenant } from '@packlink/packlink-sdk';
import { getBulkTenantName } from '@packlink/tenant-provider';

/**
 * @description: This is only for case of enter to bulk with a PayPal tenant but we can't identify the tenant, this happens
 * because PayPal tenants need part of the path to calculate the tenant
 * for example when url is paypal.packlink.com/private without country
 * */
const paypalValidation = () => {
    const hostname = window?.location.hostname || '';
    if (hostname.toLocaleLowerCase().includes('paypal')) {
        window.location.href = 'https://www.paypal.com/';
    }
};

/**
 * @description: This class is a workaround because we have an Angular App and need some tenant parameters
 * before load any page. For example we need to know tenant to calculate url prefix. This Singleton provide us access to
 * tenant name, platform and platform country of one tenant and url prefix for one tenant.
 * */
export class TenantUtils {
    private tenant: Tenant;
    private static _instance?: TenantUtils;

    private constructor() {
        // Hide to outside new instances
        const tenantName: TenantName | null = getBulkTenantName();
        if (!tenantName) {
            // TODO: https://packlink.atlassian.net/browse/IDE-93
            paypalValidation();

            throw new Error('[TenantUtils]: Unknown tenant');
        }

        this.tenant = new Tenant(tenantName);
    }

    private static getInstance(): TenantUtils {
        if (!this._instance) {
            this._instance = new TenantUtils();
        }
        return this._instance;
    }

    public static getTenant(): Tenant {
        return this.getInstance().tenant;
    }

    public static getTenantName(): TenantName {
        return this.getInstance().tenant.name;
    }

    public static getPlatform(): string {
        const tenantPlatform = TenantPlatform[this.getTenantName()];
        return tenantPlatform;
    }

    public static getPlatformCountry(): string {
        const tenantPlatformCountry = TenantPlatformCountry[this.getTenantName()];
        return tenantPlatformCountry;
    }

    public static getURLPrefix(): string {
        return (
            {
                DPDBBE: '/be/private',
                PRO: '/private',
                EBAY: '/private',
                PAYPAL: `/${TenantUtils.getPlatformCountry().toLowerCase()}/private`,
            }[TenantUtils.getPlatform()] || '/private'
        );
    }

    /**
     * These values are in tenant config `loginPath` key, but this is needed by AngularJS before TenantConfigProvider is bootstraped.
     * Since we don't have the config, the values are hardcoded here until we can access them just from React.
     */
    public static getLoginPath(config: IConfig): string {
        const tenantId = TenantUtils.getTenantName();
        return (
            {
                DPDBBE: `${config.auth.uri}/login?tenant_id=${tenantId}`,
                PRO: `${config.auth.uri}/login?tenant_id=${tenantId}`,
                CORREOS: `${config.auth.uri}/es-ES/correoses/login?tenant_id=${tenantId}`,
                EBAY: `/auth-gateway/onboarding/start?tenant_id=${tenantId}`,
                PAYPAL: `/auth-gateway/onboarding/start?tenant_id=${tenantId}`,
            }[TenantUtils.getPlatform()] || `${config.auth.uri}/login?tenant_id=${tenantId}`
        );
    }

    public static reset(): void {
        delete this._instance;
    }
}
