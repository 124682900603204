import { ShipmentUtils } from '@utils/ShipmentUtils';
import { Inbox, ProShipment } from '@packlink/packlink-sdk';

export enum ShipmentDetailsActions {
    printLabel,
    emailLabel,
    cancelShipment,
    downloadCustoms,
}

export function isShipmentActionAvailable(shipment: ProShipment, action: ShipmentDetailsActions): boolean {
    const shipmentState = ShipmentUtils.getFrontendStatus(shipment.data.state) as Inbox;
    // TODO: Use BFF shipment flags for this when they're ready/implemented
    switch (action) {
        case ShipmentDetailsActions.printLabel:
        case ShipmentDetailsActions.emailLabel:
            return [Inbox.READY_FOR_SHIPPING, Inbox.IN_TRANSIT, Inbox.OUT_FOR_DELIVERY].includes(shipmentState);
        case ShipmentDetailsActions.cancelShipment:
            return [Inbox.READY_FOR_SHIPPING, Inbox.PROCESSING].includes(shipmentState);
        case ShipmentDetailsActions.downloadCustoms: {
            return !ShipmentUtils.isShipmentCanceled(shipment.data) && !!shipment.data.hasCustoms;
        }
        default:
            return false;
    }
}
