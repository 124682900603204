import * as yup from 'yup';
import { useTranslation } from '@packlink/translation-provider';
import { useFormData } from './useFormData';
import { IRuleForm } from '../types/formTypes';

export function useFormValidationSchema() {
    const { numericConditionsOrActions } = useFormData();
    const { t } = useTranslation();

    const numericSchema = yup
        .number()
        .min(0, t('form.error.positive', { field: t('shipping-rules.form.value') }))
        .required(t('forms.error.field-required'));

    const fieldsShape = {
        identifier: yup.object().shape({
            value: yup.string().required(t('forms.error.field-required')),
        }),
        operation: yup.object().shape({
            value: yup.string().required(t('forms.error.field-required')),
        }),
        value: yup.object().when('identifier', ({ value }) => {
            if (numericConditionsOrActions[value]) {
                if (numericConditionsOrActions[value] % 1 === 0) {
                    return yup.object({
                        value: numericSchema.integer(
                            t('form.error.decimals-disallowed', { field: t('shipping-rules.form.value') }),
                        ),
                    });
                } else {
                    const numOfDecimals = numericConditionsOrActions[value]?.toString().split('.')[1].length;
                    const decimalsRegExp = `[,.'](\\d{${numOfDecimals + 1},})`;

                    return yup.object({
                        value: numericSchema.test(
                            'decimals-limit',
                            t('form.error.decimals-limit', {
                                field: t('shipping-rules.form.value'),
                                limit: numOfDecimals,
                            }),
                            (value: number | null | undefined): boolean => {
                                const regEx = new RegExp(decimalsRegExp);
                                return value ? !regEx.test(value + '') : false;
                            },
                        ),
                    });
                }
            } else {
                return yup.object({ value: yup.string().required(t('forms.error.field-required')) });
            }
        }),
    };
    const validationSchema = yup.object<IRuleForm>().shape({
        alias: yup.string().required(t('form.error.required', { field: t('shipping-rules.form.name') })),
        conditions: yup.array().of(yup.object().shape(fieldsShape)),
        actions: yup.array().of(yup.object().shape(fieldsShape)),
    });

    return validationSchema;
}
