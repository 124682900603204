import { useLocation } from 'react-router';
import { BrandedEmail as BrandedEmailInternal, BrandedEmailProps } from '@BrandedEmails/BrandedEmail';
import { useSelector } from 'react-redux';
import { getClientData } from '@store/selectors/client';

export function BrandedEmail(): JSX.Element {
    const client = useSelector(getClientData);

    const location = useLocation();
    const state = location.state as BrandedEmailProps['initialValues'];

    const initialValues: BrandedEmailProps['initialValues'] = {
        companyLogoUrl: state?.companyLogoUrl,
        companyName: state?.companyName || '',
        companyReplyToEmail: state?.companyReplyToEmail,
    };

    return <BrandedEmailInternal clientName={client.firstName} initialValues={initialValues} />;
}
