import { useLocation } from 'react-router';

import { OnboardingProvider } from '@packlink/packlink-sdk';
import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { ActivateStartFlow } from '../ActivateStartFlow';
import { IOAuthDummyFormData } from '@Integrations/components/Integrations/OAuthDummy/types';

export const OAuthDummyActivateStartFlow = () => {
    const state = useLocation().state as null | { data: IOAuthDummyFormData };

    return (
        <ActivateStartFlow
            provider={OnboardingProvider.SHIPENGINE_OAUTH_DUMMY}
            integrationAlias={EcommerceAlias.OAuthDummy}
            pathParam={'?store_id=' + state?.data.store_id}
        />
    );
};
