import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getIconStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
    });

export const getTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px 0`,
        color: theme.palette.gray.dark,
    });

export const getListStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: 0,
        marginTop: theme.spacing(),
        padding: 0,
        listStyleType: 'none',
    });

export const getListItemStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',

        ':not(:last-child)': {
            marginBottom: theme.spacing(1),
        },
    });

export const getSecondarySectionStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.gray.light}`,
        borderRadius: theme.spacing(1 / 4),
        marginTop: theme.spacing(4),
    });

export const getSuccessItemIcon = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
    });

export const getMainSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `0 ${theme.spacing()}px`,
    });
