import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const buttonTextStyles = (): SerializedStyles =>
    css({
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: 'center',
    });

export const getShipmentActionCellStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'inline-flex',
        justifyContent: 'flex-end',
        margin: `${theme.spacing(2)}px 0px`,

        [theme.mediaQuery('tablet')]: {
            flex: '0 0 132px',
            justifyContent: 'flex-start',
            margin: 0,
            padding: `0px ${theme.spacing(2)}px`,
        },

        'button, .btn': {
            width: 'auto',
            minWidth: 100,

            ':last-child': {
                marginLeft: `0px 0px 0px ${theme.spacing(2)}px`,

                [theme.mediaQuery('tablet')]: {
                    margin: `${theme.spacing()}px 0px 0px 0px`,
                },
            },

            [theme.mediaQuery('tablet')]: {
                width: '100%',
            },
        },
    });
