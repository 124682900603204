import {
    DropOff,
    IAddress,
    Inbox,
    IProShipment,
    IProShipmentApiResponse,
    IShipment,
    IWarehouseAddress,
    ProShipment,
    SerializedShipment,
    Service,
    ServiceUpsalesMapper,
    Shipment,
    ShipmentActions,
    ShipmentCollection,
    ShipmentStatus,
    ShipmentUpsales,
} from '@packlink/packlink-sdk';
import { IconNames, Theme } from '@shipengine/giger-theme';

export class ShipmentUtils {
    static proShipmentToJSON(shipment: ProShipment): IProShipment {
        const { actions, data, dropOff, inbox, step } = shipment;

        return {
            actions: actions.toJSON(),
            data: data.toJSON(),
            dropOff: dropOff?.toJSON(),
            inbox,
            step,
        } as IProShipment;
    }

    static proShipmentDeserialize(shipment: IProShipment): ProShipment {
        const { actions, data, dropOff, inbox, step } = shipment;

        return {
            actions: ShipmentActions.deserialize(actions),
            data: Shipment.deserialize(data) as Shipment<IShipment>,
            dropOff: dropOff && DropOff.deserialize(dropOff),
            inbox,
            step,
        };
    }

    static proShipmentCreateFromResponse(shipment: IProShipmentApiResponse): ProShipment {
        const { actions, data, view, inbox, step } = shipment;

        return {
            actions: ShipmentActions.createFromResponse(actions),
            data: Shipment.createFromResponse(data),
            dropOff: view.to.drop_off ? DropOff.createFromResponse(view.to.drop_off) : undefined,
            inbox,
            step,
        };
    }

    static updateService(
        shipment: IShipment,
        selectedService: Service,
        collection?: ShipmentCollection,
        selectedDropoffId?: string,
    ): IShipment {
        return {
            ...shipment,
            currency: selectedService?.currency || shipment.currency,
            service: {
                id: selectedService?.id,
            },
            upsales: {
                // We update the shipment with the same upsales the user had selected, if any and if available in the new service.
                ...shipment.upsales,
                proofOfDelivery: {
                    available: selectedService?.upsales?.proofOfDelivery?.available
                        ? shipment.upsales.proofOfDelivery.available
                        : false,
                },
                adultSignature: {
                    available: selectedService?.upsales?.adultSignature?.available
                        ? shipment.upsales.adultSignature.available
                        : false,
                },
                additionalHandling: {
                    available: selectedService?.upsales?.additionalHandling?.available
                        ? shipment.upsales.additionalHandling.available
                        : false,
                },
                printInStore: {
                    available: selectedService?.upsales?.printInStore?.available
                        ? shipment.upsales.printInStore.available
                        : false,
                },
            },
            ...(!selectedService.dropOff?.origin && {
                collection: {
                    date: collection?.date?.toISOString() || selectedService.availableDates?.[0]?.date?.toISOString(),
                    interval: collection?.interval?.toJSON() || selectedService.availableDates?.[0]?.intervals?.[0],
                },
            }),
            dropoffPointId: selectedDropoffId,
        };
    }

    static getFrontendStatus(status = ''): string {
        switch (status) {
            case ShipmentStatus.AWAITING_COMPLETION:
                return Inbox.DRAFT;
            case ShipmentStatus.CARRIER_OK:
            case ShipmentStatus.LABELS_KO:
            case ShipmentStatus.CARRIER_KO:
            case ShipmentStatus.PURCHASE_SUCCESS:
            case ShipmentStatus.CARRIER_PENDING:
            case ShipmentStatus.RETRY:
                return Inbox.PROCESSING;
            case ShipmentStatus.READY_TO_PRINT:
            case ShipmentStatus.READY_FOR_COLLECTION:
            case Inbox.READY_FOR_SHIPPING:
                return Inbox.READY_FOR_SHIPPING;
            case ShipmentStatus.CANCELED:
            case ShipmentStatus.CANCEL_REQUESTED:
                return Inbox.CANCELED;
            default:
                return status as Inbox;
        }
    }

    static getIconByState(state = ''): string {
        switch (state) {
            case ShipmentStatus.AWAITING_COMPLETION:
                return IconNames.INFO;
            case ShipmentStatus.READY_TO_PURCHASE:
                return IconNames.CHECK;
            case ShipmentStatus.READY_TO_PRINT:
            case ShipmentStatus.READY_FOR_COLLECTION:
                return IconNames.CARDBOARD_BOX;
            case ShipmentStatus.RETURNED_TO_SENDER:
                return IconNames.HAND_BOX;
            case ShipmentStatus.IN_TRANSIT:
                return IconNames.TRUCK;
            case ShipmentStatus.OUT_FOR_DELIVERY:
                return IconNames.TROLLEY;
            case ShipmentStatus.DELIVERED:
                return IconNames.CARDBOARD_BOX_CHECK;
            case ShipmentStatus.PURCHASE_SUCCESS:
            case ShipmentStatus.CARRIER_OK:
            case ShipmentStatus.CARRIER_KO:
            case ShipmentStatus.CARRIER_PENDING:
            case ShipmentStatus.RETRY:
                return IconNames.HOURGLASS;
            case ShipmentStatus.INCIDENT:
                return IconNames.ALERT;
            case ShipmentStatus.CANCELED:
            case ShipmentStatus.CANCEL_REQUESTED:
                return IconNames.CANCEL;
            default:
                return '';
        }
    }

    static updatePrintInStore<T extends SerializedShipment = SerializedShipment>(
        shipment: Shipment<T>,
        defaultValue: boolean,
    ): Shipment<T> {
        const updatedShipment = shipment.clone();
        const isPrintInStoreSupported = shipment.service?.hasPrintInStore ?? false;
        const printInStoreValue = isPrintInStoreSupported
            ? shipment.upsales?.printInStore?.available || defaultValue
            : false;

        updatedShipment.upsales = updatedShipment.upsales ?? new ShipmentUpsales();
        updatedShipment.upsales.printInStore = ServiceUpsalesMapper.convertToAvailableUpsale(printInStoreValue);

        return updatedShipment;
    }

    static updateCustoms(shipment: Shipment, customsInvoiceId: string | undefined): Shipment {
        const updatedShipment = shipment.clone();
        if (customsInvoiceId) {
            updatedShipment.customs = { customsInvoiceId };
        } else {
            updatedShipment.customs = undefined;
        }
        return updatedShipment;
    }

    static getColorByState(state = '', theme: Theme): string {
        switch (state) {
            case ShipmentStatus.READY_TO_PRINT:
            case ShipmentStatus.READY_FOR_COLLECTION:
            case ShipmentStatus.DELIVERED:
                return theme.palette.success.main;
            case ShipmentStatus.OUT_FOR_DELIVERY:
                return theme.palette.primary.dark;
            case ShipmentStatus.INCIDENT:
            case ShipmentStatus.AWAITING_COMPLETION:
                return theme.palette.error.main;
            case ShipmentStatus.READY_TO_PURCHASE:
                return theme.palette.info.main;
            case ShipmentStatus.CARRIER_OK:
            case ShipmentStatus.CARRIER_KO:
            case ShipmentStatus.PURCHASE_SUCCESS:
            case ShipmentStatus.CARRIER_PENDING:
            case ShipmentStatus.IN_TRANSIT:
            case ShipmentStatus.RETRY:
                return theme.palette.alert.main;
            case ShipmentStatus.RETURNED_TO_SENDER:
                return theme.palette.gray.dark;
            case ShipmentStatus.CANCELED:
            case ShipmentStatus.CANCEL_REQUESTED:
            default:
                return theme.palette.gray.main;
        }
    }

    static isShipmentCanceled(shipment: Shipment): boolean {
        return [ShipmentStatus.CANCELED, ShipmentStatus.CANCEL_REQUESTED].includes(shipment.state as ShipmentStatus);
    }

    static isShipmentDraft(shipment: Shipment): boolean {
        return [ShipmentStatus.AWAITING_COMPLETION, ShipmentStatus.READY_TO_PURCHASE].includes(
            shipment.state as ShipmentStatus,
        );
    }
}

export const warehouseToAddress = (w: IWarehouseAddress, locale: string): IAddress => ({
    ...w,
    state: w.postalZone.translations[locale],
    street1: w.address,
    firstName: w.alias,
    lastName: '',
});
