import { useMemo, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import {
    ApiClientError,
    IParcel,
    IShipment,
    IShipmentUpdateBulkResponse,
    Parcel,
    ProShipment,
    Shipment,
} from '@packlink/packlink-sdk';
import Packlink from '@sdk';
import { getIsPendingSectionInbox, getSelectedShipments } from '@store/selectors/bulk';
import { IContentForm } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelContent/ShipmentPanelContent';
import { BulkSelectionInfo } from '@components/SidePanel/BulkSelectionInfo';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { ISidePanelMessageProps, SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';
import { getParcelListValidation, ParcelFormList } from '@components/ParcelForm/ParcelFormList';
import { logSdkError } from '@utils/logger';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { ShipmentPanelFooter } from '../../ShipmentPanelFooter';
import { shipmentPanelFormStyles } from '../../ShipmentPanelStyles';
import { useAmplitude } from '@hooks/useAmplitude';
import { useParcels } from '@common/hooks/useParcels';

export interface IShipmentPanelBulkParcelsProps {
    onGoBack: () => void;
}

export function ShipmentPanelBulkParcels({ onGoBack }: IShipmentPanelBulkParcelsProps): JSX.Element {
    const { t } = useTranslation();
    const { timedClosePanel } = useShipmentPanel();
    const selectedShipments = useSelector(getSelectedShipments);

    const { defaultParcel } = useParcels();
    const isCurrentInboxPending = useSelector(getIsPendingSectionInbox);
    const [panelMessageProps, setPanelMessageProps] = useState<ISidePanelMessageProps>();
    const { sendAmplitudeEvent } = useAmplitude();

    const validationSchema = yup.object<IContentForm>().shape({
        parcels: getParcelListValidation(t),
    });

    const initialFormValues = useMemo(
        (): IContentForm => ({
            parcels:
                selectedShipments.length === 1
                    ? selectedShipments[0].data.parcels?.map((p: Parcel) => p.toJSON() as IParcel)
                    : defaultParcel
                      ? [defaultParcel?.toJSON()]
                      : [],
        }),
        [defaultParcel, selectedShipments],
    );

    const updateShipments = ({ parcels }: IContentForm) => {
        const shipmentsToEdit = selectedShipments.map((shipment: ProShipment): Shipment => {
            const { data: shipmentDataJSON } = ShipmentUtils.proShipmentToJSON(shipment);

            const newShipmentData: Partial<IShipment> = {
                ...shipmentDataJSON,
                additionalData: {
                    ...shipmentDataJSON.additionalData,
                    parcelIds: parcels?.map((parcel: IParcel): string => parcel.id as string),
                },
                parcels: parcels,
            };

            return Shipment.deserialize(newShipmentData);
        });

        Packlink.v1.shipments
            .updateShipments(shipmentsToEdit)
            .then(({ shipmentsFailed, shipmentsUpdated }: IShipmentUpdateBulkResponse) => {
                if (shipmentsFailed.length > 0) {
                    setPanelMessageProps({
                        type: SidePanelMessageType.WARNING,
                        title: t('bulk-notification.shipments.warning', { count: shipmentsFailed.length }),
                        subtitle: t('error.message.try-again-later'),
                    });
                } else {
                    setPanelMessageProps({
                        type: SidePanelMessageType.SUCCESS,
                        title: t('bulk-notification.shipments.updated-success', { count: shipmentsUpdated.length }),
                        showShippingRulesPromo: shipmentsUpdated.length > 1 && isCurrentInboxPending,
                    });
                }

                sendAmplitudeEvent(AmplitudeEvents.SIDEBAR_EDIT_PARCELS_SUCCESS, {
                    [AmplitudeProperties.NUMBER_OF_SHIPMENTS_EDITED]: shipmentsUpdated.length,
                });

                timedClosePanel();
            })
            .catch((error: ApiClientError) => {
                logSdkError(error);

                setPanelMessageProps({
                    type: SidePanelMessageType.ERROR,
                    title: t('error.message.processing'),
                    subtitle: t('error.message.try-again-later'),
                });
            });
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnMount
            onSubmit={updateShipments}
            enableReinitialize
        >
            {(formProps: FormikProps<IContentForm>): React.ReactNode => (
                <PanelContent formProps={formProps} onGoBack={onGoBack} panelMessageProps={panelMessageProps} />
            )}
        </Formik>
    );
}

interface IPanelContent {
    onGoBack: IShipmentPanelBulkParcelsProps['onGoBack'];
    formProps: FormikProps<IContentForm>;
    panelMessageProps?: ISidePanelMessageProps;
}

function PanelContent({ onGoBack, formProps, panelMessageProps }: IPanelContent): JSX.Element {
    const { t } = useTranslation();
    const selectedShipments = useSelector(getSelectedShipments);
    const { isValid } = formProps;

    return (
        <Form css={shipmentPanelFormStyles(true)}>
            {!panelMessageProps && (
                <>
                    <BulkSelectionInfo
                        count={selectedShipments.length}
                        actionsTitle="bulk-panel.actions.title"
                        actionsSubtitle="bulk-panel.parcels.subtitle"
                        showBackground
                    />

                    <SidePanelContent className="shipment-panel-content__content">
                        <SidePanelContentSection
                            title={t('content-panel.parcels.section-title')}
                            subtitle={t('content-panel.parcels.section-subtitle')}
                        >
                            <ParcelFormList name="parcels" isInSidebar />
                        </SidePanelContentSection>
                    </SidePanelContent>

                    <ShipmentPanelFooter
                        onExit={onGoBack}
                        cancelButtonLabel={t('shipment-panel.actions.back')}
                        isSaveDisabled={!isValid}
                    />
                </>
            )}
            {panelMessageProps && (
                <SidePanelContent>
                    <SidePanelMessage {...panelMessageProps} />
                </SidePanelContent>
            )}
        </Form>
    );
}
