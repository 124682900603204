import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSupportWidgetStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(2),
            left: 'unset',
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(),

            [theme.mediaQuery('tablet')]: {
                left: theme.spacing(2),
                right: 'unset',
            },
        });

export const getSupportWidgetButtonStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            ...theme.getElevation('overlay1'),
            height: 'fit-content',
            textAlign: 'center',
            borderRadius: '50%',
            padding: theme.spacing(),

            [theme.mediaQuery('tablet')]: {
                borderRadius: theme.borderRadius.S,
                padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
            },
        });
