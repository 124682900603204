import { WithCommonProps } from '@shipengine/giger';
import { FastField, Form, Formik } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { LossTicketFormData, useLossTicketFormHandlers, useLossTicketFormValidationSchema } from './LossTicketFormData';
import { NewTicketFieldset } from '../CommonFields/NewTicketFieldset/NewTicketFieldset';
import { SubmitButton } from '../CommonFields/SubmitButton/SubmitButton';
import { SupportTicketPaymentField } from '../CommonFields/SupportTicketPaymentField/SupportTicketPaymentField';
import { SupportTicketPaymentFieldData } from '../CommonFields/SupportTicketPaymentField/SupportTicketPaymentFieldData';
import { initialSupportTicketClaimCommonData } from '../CommonFields/SupportTicketClaimFields/SupportTicketClaimFieldsData';
import { SupportTicketClaimFields } from '../CommonFields/SupportTicketClaimFields/SupportTicketClaimFields';
import { getSubmitButtonStyles } from '../../styles/FormStyles';
import { NewTicketEventHandlers } from '../../NewTicketData';

type LossTicketFormProps = WithCommonProps<NewTicketEventHandlers>;

export function LossTicketForm({ onSubmit, ...props }: LossTicketFormProps): JSX.Element {
    const { t } = useTranslation();
    const { validationSchema, initialValues, handleSubmit } = useFormData({ onSubmit });

    return (
        <Formik<LossTicketFormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
        >
            <Form {...props}>
                <SupportTicketClaimFields />
                <NewTicketFieldset title={t('support-center.payment-field.payment-label')}>
                    <FastField
                        component={SupportTicketPaymentField}
                        name="payment"
                        data-id="loss-support-ticket-payment-method"
                    />
                </NewTicketFieldset>
                <SubmitButton css={getSubmitButtonStyles} />
            </Form>
        </Formik>
    );
}

function useFormData(eventHandlers: NewTicketEventHandlers) {
    const validationSchema = useLossTicketFormValidationSchema();
    const initialValues: LossTicketFormData = {
        ...initialSupportTicketClaimCommonData,
        // Payment initial data should be set when select the payment type
        payment: {
            type: '',
            data: {},
        } as SupportTicketPaymentFieldData,
    };
    const { handleSubmit } = useLossTicketFormHandlers(eventHandlers);

    return {
        validationSchema,
        initialValues,
        handleSubmit,
    };
}
