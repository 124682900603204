import { SidePanelDefaultHeader } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

import { useSidePanel } from '@hooks/useSidePanel';
import { getTitleCase } from '@utils/titleCase';

export interface SidePanelHeaderProps {
    title: string;
    onAction?: () => void;
    closeButtonTooltip?: string;
}

export const SidePanelHeader = (props: SidePanelHeaderProps): JSX.Element => {
    const sidePanel = useSidePanel();
    const { t } = useTranslation();

    const { title, onAction = sidePanel.close, closeButtonTooltip = t('sidebar.action.close') } = props;

    return (
        <header>
            <SidePanelDefaultHeader
                title={getTitleCase(title)}
                closeButtonTooltip={closeButtonTooltip}
                onClickClose={onAction}
            />
        </header>
    );
};
