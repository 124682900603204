import { useSelector } from 'react-redux';
import { Inbox } from '@packlink/packlink-sdk';
import { getCurrentInbox } from '@store/selectors/inbox';
import { getInboxesNotifications } from '@store/selectors/bulk';

/**
 * Returns the notifications for the current inbox, or the inbox passed as param
 */
export function useInboxNotifications(inbox?: Inbox) {
    const inboxesNotifications = useSelector(getInboxesNotifications);
    const currentInbox = useSelector(getCurrentInbox);

    const inboxNotifications = inboxesNotifications[inbox || currentInbox];

    return { inboxNotifications, currentInbox };
}
