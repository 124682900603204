import React, { useCallback } from 'react';
import { FormikState, getIn, useFormikContext } from 'formik';

import { IPostalCode, IPostalZone } from '@packlink/packlink-sdk';
import { CountryPostalZoneSelector } from '@components/CountryPostalZoneSelector/CountryPostalZoneSelector';
import { PostalCodeSelector } from '@components/PostalCodeSelector/PostalCodeSelector';
import { Origin } from '@types';
import { getPostalSelectorStyles } from './LocationFormStyles';
import { ILocationForm, ILocationFormField } from './LocationForm';

interface ICountryPostalCodeFormProps {
    name: Origin;
    initialPostalZoneId?: string;
    isPostalSelectorsDisabled?: boolean;
    isCollapsed?: boolean;
    readOnly?: boolean;
    onCountryChange?: (country: IPostalZone, name: Origin) => void;
    onPostalCodeChange?: (postalCode: IPostalCode, name: Origin) => void;
}

interface ICountryPostalCodeFormFieldErrors {
    alpha2Code?: string;
    zipCode?: string;
}

export const CountryPostalCodeForm: React.FC<ICountryPostalCodeFormProps> = ({
    name,
    initialPostalZoneId,
    isPostalSelectorsDisabled,
    isCollapsed,
    readOnly,
    onCountryChange,
    onPostalCodeChange,
}: ICountryPostalCodeFormProps): JSX.Element => {
    const { values, errors, setFormikState } = useFormikContext<ILocationForm>();

    const locationValues: ILocationFormField = getIn(values, name);
    const locationErrors: ICountryPostalCodeFormFieldErrors | undefined = getIn(errors, name);

    const handleCountryChange = useCallback(
        (postalZone: IPostalZone): void => {
            setFormikState((prevState: FormikState<ILocationForm>) => ({
                ...prevState,
                values: {
                    ...prevState.values,
                    [name]: {
                        ...prevState.values[name],
                        state: postalZone.name,
                        alpha2Code: postalZone.alpha2Code,
                        city: '',
                        zipCode: '',
                        postalCode: undefined,
                    },
                },
            }));

            onCountryChange?.(postalZone, name);
            const emptyPostalCode = {
                city: '',
                id: '',
                state: '',
                text: '',
                zipCode: '',
            };
            onPostalCodeChange?.(emptyPostalCode, name);
        },
        [name, setFormikState, onCountryChange, onPostalCodeChange],
    );

    const handlePostalCodeChange = useCallback(
        (postalCode: IPostalCode): void => {
            setFormikState((prevState: FormikState<ILocationForm>) => ({
                ...prevState,
                values: {
                    ...prevState.values,
                    [name]: {
                        ...prevState.values[name],
                        city: postalCode.city,
                        zipCode: postalCode.zipCode,
                    },
                },
            }));
            onPostalCodeChange?.(postalCode, name);
        },
        [name, setFormikState, onPostalCodeChange],
    );

    return (
        <div css={getPostalSelectorStyles(isCollapsed)}>
            <CountryPostalZoneSelector
                origin={name}
                error={locationErrors?.alpha2Code}
                isDisabled={isPostalSelectorsDisabled}
                initialZoneId={initialPostalZoneId}
                readOnly={readOnly}
                onChange={handleCountryChange}
            />
            <PostalCodeSelector
                name={`${name}.postalCode`}
                postalZoneId={locationValues.country?.value}
                error={locationErrors?.zipCode}
                isDisabled={isPostalSelectorsDisabled}
                readOnly={readOnly}
                onChange={handlePostalCodeChange}
            />
        </div>
    );
};
