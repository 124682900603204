import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getProgressBarWrapperStyles = (): SerializedStyles => {
    return css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    });
};

export const getLabelStyles =
    (isSecondHalfActive?: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            justifyContent: 'space-between',
            color: theme.palette.gray.dark,
            marginBottom: isSecondHalfActive ? theme.spacing(1 / 2) : theme.spacing(5.5),
            marginTop: 0,
        });

export const getSecondaryLabelStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.gray.dark,
        marginBottom: theme.spacing(2),
        marginTop: 0,
    });

export const getProgressBarStyles =
    (isSidePanelContent?: boolean) =>
    (theme: Theme): SerializedStyles => {
        return css({
            width: '296px',

            [theme.mediaQuery('desktop')]: {
                width: isSidePanelContent ? '296px' : '432px',
            },
        });
    };
