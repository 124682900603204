import { Outlet } from 'react-router';
import { AppHeader } from '../AppHeader/AppHeader';
import { getContentStyles } from './AppHeaderLayoutStyles';

export function AppHeaderLayout(): JSX.Element {
    return (
        <>
            <AppHeader />

            <div css={getContentStyles}>
                <Outlet />
            </div>
        </>
    );
}
