import React from 'react';

import { Typography, WithChildrenCommonProps } from '@shipengine/giger';
import { getInfoPanelStepHeaderStyles, getInfoPanelStepStyles } from './InfoPanelStyles';

export type IInfoPanelStepProps = WithChildrenCommonProps<{
    title?: string;
}>;

export const InfoPanelStep: React.FC<IInfoPanelStepProps> = (props: IInfoPanelStepProps): JSX.Element => {
    const { title, children, ...rest } = props;

    return (
        <section {...rest} css={getInfoPanelStepStyles}>
            <Typography variant="body2" component="section">
                {title && <section css={getInfoPanelStepHeaderStyles}>{title}</section>}
                {children}
            </Typography>
        </section>
    );
};
