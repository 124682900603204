import { useRef } from 'react';
import { IconNames } from '@shipengine/giger-theme';
import { WithCommonProps, Tooltip, useHoverableTooltip, Icon, Typography, IconSize } from '@shipengine/giger';
import { getInformationTooltipIconStyles } from './InformationTooltipStyles';

type InformationTooltipProps = WithCommonProps<unknown>;

export function InformationTooltip({ children, ...props }: InformationTooltipProps): JSX.Element | null {
    const tooltipRef = useRef<HTMLElement>(null);
    const { opened } = useHoverableTooltip(tooltipRef);

    return (
        <>
            <i css={getInformationTooltipIconStyles} {...props} ref={tooltipRef} aria-describedby="information-tooltip">
                <Icon size={IconSize.SIZE_SMALL} name={IconNames.INFO} />
            </i>
            <Tooltip id="information-tooltip" reference={tooltipRef.current} isOpen={opened}>
                <Typography>{children}</Typography>
            </Tooltip>
        </>
    );
}
