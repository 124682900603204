import * as yup from 'yup';
import DOMPurify from 'dompurify';

import { useTranslation } from '@packlink/translation-provider';
import { Captions } from './OwnContractPanelHelp';

export enum OwnContractCarriers {
    CHRONOPOST = 'Chronopost',
    CORREOS_EXPRESS = 'Correos Express',
    POSTE_ITALIANE = 'Poste Italiane',
    BARTOLINI = 'BRT - Contratto',
    SEUR = 'SEUR - Contrato',
}

export type CustomOwnContractFormData<T> = {
    [fieldName: string]: T;
};

export type VideoTutorial = {
    src: {
        [locale: string]: string;
        default: string;
    };
    poster?: string;
};

interface FormData {
    /** Name of the carrier as id */
    carrierId: string;
    /** Array of fields names to show in the form */
    formFields: string[];
    /** Captions for the help section in the form */
    helpCaptions: Captions[];
    /** Allows include custom fields validations. Default validation: required string */
    customValidations?: CustomOwnContractFormData<yup.Schema<unknown>>;
    /** Allows include custom input types. Default input type: "text" */
    customInputTypes?: CustomOwnContractFormData<string>;
    /** Video tutorial information  */
    videoTutorial?: VideoTutorial;
}

type OwnContractCarrierFormType = {
    [K in OwnContractCarriers]: FormData;
};

export const OwnContractCarrierFormData = (): OwnContractCarrierFormType => {
    const { t } = useTranslation();
    return {
        [OwnContractCarriers.CHRONOPOST]: {
            carrierId: 'chronopost',
            formFields: ['username', 'password'],
            helpCaptions: [
                {
                    title: t('own-contract.panel.chronopost-help-step1-title'),
                    content: t('own-contract.panel.chronopost-help-step1-content'),
                },
                {
                    title: t('own-contract.panel.chronopost-help-step2-title'),
                    content: DOMPurify.sanitize(
                        t('own-contract.panel.chronopost-help-step2-content', {
                            url: 'https://www.chronopost.fr/en/private/contact-us',
                            phone: '0825 885 866',
                        }),
                        {
                            ADD_ATTR: ['target'],
                        },
                    ),
                },
            ],
            customInputTypes: {
                password: 'password',
            },
        },
        [OwnContractCarriers.CORREOS_EXPRESS]: {
            carrierId: 'correosExpress',
            formFields: ['username', 'password', 'clientId', 'senderCode'],
            helpCaptions: [
                {
                    title: t('own-contract.panel.correos-express-help-step1-title'),
                    content: t('own-contract.panel.correos-express-help-step1-content'),
                },
                {
                    title: t('own-contract.panel.correos-express-help-step2-title'),
                    content: t('own-contract.panel.correos-express-help-step2-content'),
                },
            ],
            customInputTypes: {
                password: 'password',
            },
            videoTutorial: {
                src: {
                    'es-ES': `${config.cdn.url}/pro/statics/videos/own-contract/CorreosExpress/correos-express-es-ES.mp4`,
                    default: `${config.cdn.url}/pro/statics/videos/own-contract/CorreosExpress/correos-express-en-GB.mp4`,
                },
                poster: `${config.cdn.url}/pro/statics/videos/own-contract/CorreosExpress/correos-express-poster.jpg`,
            },
        },
        [OwnContractCarriers.POSTE_ITALIANE]: {
            carrierId: 'posteItaliane',
            formFields: ['clientId', 'secretId', 'customerId'],
            helpCaptions: [
                {
                    title: t('own-contract.panel.poste-italiane-help-step1-title'),
                    content: t('own-contract.panel.poste-italiane-help-step1-content'),
                },
                {
                    title: t('own-contract.panel.poste-italiane-help-step2-title'),
                    content: t('own-contract.panel.poste-italiane-help-step2-content'),
                },
            ],
            customInputTypes: {
                secretId: 'password',
            },
        },
        [OwnContractCarriers.BARTOLINI]: {
            carrierId: 'bartolini',
            formFields: [
                'userId',
                'password',
                'departureDepot',
                'pricingConditionCodeDropoffHome',
                'pricingConditionCodeDropoffPickup',
                'sftpUser',
                'sftpPassword',
            ],
            helpCaptions: [
                {
                    title: t('own-contract.panel.bartolini-help-step1-title'),
                    content: t('own-contract.panel.bartolini-help-step1-content'),
                },
                {
                    title: t('own-contract.panel.bartolini-help-step2-title'),
                    content: t('own-contract.panel.bartolini-help-step2-content'),
                },
            ],
            customValidations: {
                departureDepot: yup
                    .number()
                    .required(t('form.error.required', { field: t('own-contract.panel.bartolini-departure-depot') })),
                pricingConditionCodeDropoffHome: yup.string(),
                pricingConditionCodeDropoffPickup: yup.string(),
                sftpUser: yup.string(),
                sftpPassword: yup.string(),
            },
            customInputTypes: {
                departureDepot: 'number',
                password: 'password',
                sftpPassword: 'password',
            },
            videoTutorial: {
                src: {
                    default: `${config.cdn.url}/pro/statics/videos/own-contract/BRT/brt-it-IT.mp4`,
                },
            },
        },
        [OwnContractCarriers.SEUR]: {
            carrierId: 'seur',
            formFields: [
                'username',
                'password',
                'clientId',
                'clientSecret',
                'accountNumber',
                'idNumber',
                'originBusinessUnit',
            ],
            helpCaptions: [
                {
                    title: t('own-contract.panel.seur-help-step1-title'),
                    content: t('own-contract.panel.seur-help-step1-content'),
                },
                {
                    title: t('own-contract.panel.seur-help-step2-title'),
                    content: t('own-contract.panel.seur-help-step2-content'),
                },
            ],
            customInputTypes: {
                password: 'password',
                clientSecret: 'password',
            },
            videoTutorial: {
                src: {
                    default: `${config.cdn.url}/pro/statics/videos/own-contract/Seur/seur-es-ES.mp4`,
                },
            },
        },
    };
};

export const CREDENTIALS_ERROR_STATUS_CODE = 409;
// This is the Backend error message, necessary because the status code is also used for other stuff.
export const CREDENTIALS_ERROR_TEXT = 'Contract not valid';
