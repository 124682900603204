import { createSelector } from 'reselect';
import { IAppState } from '@store/reducers';
import { QUERY_PARAMS } from '@constants/braintree';
import { IPaymentState } from '@store/reducers/payment';

import {
    deserializePaymentMethod,
    IPaymentAvailableMethod,
    IPaymentClientMethod,
    PaymentAvailableMethod,
    PaymentClientMethod,
    PaymentCreditCard,
    PaymentDirectDebit,
    PaymentMethod,
    PaymentOwnContract,
    PaymentPaypal,
    PaymentType,
} from '@packlink/packlink-sdk';

import { getCheckoutPriceDetails, getCheckoutService } from '@store/selectors/checkout';
import { getClientData } from '@store/selectors/client';
import { isInvoiceAddressValid } from '@store/selectors/client';

const getPayment = (store: IAppState): IPaymentState => store.payment;
const getSeralizedPaymentMethods = (store: IAppState): IPaymentClientMethod[] => getPayment(store).paymentMethods;
const getSeralizedAvailablePaymentMethods = (store: IAppState): IPaymentAvailableMethod[] =>
    getPayment(store).availablePaymentMethods;

// Payment types
const isDeferred = (p: PaymentClientMethod | PaymentAvailableMethod): p is PaymentClientMethod =>
    p.type === PaymentType.DEFERRED;
const isDirect = (p: PaymentClientMethod): p is PaymentClientMethod => p.type === PaymentType.DIRECT;

// Payment methods
const isPaypal = (p: PaymentClientMethod | PaymentAvailableMethod): p is PaymentPaypal =>
    p.method === PaymentMethod.PAYPAL;
const isDirectDebit = (p: PaymentClientMethod): p is PaymentDirectDebit => p.method === PaymentMethod.DIRECTDEBIT;
const isCreditCard = (p: PaymentClientMethod | PaymentAvailableMethod): p is PaymentCreditCard =>
    p.method === PaymentMethod.CREDITCARD || p.method === PaymentMethod.CREDITCARD3DSECURE;
const isOwnContract = (p: PaymentClientMethod): p is PaymentOwnContract => p.method === PaymentMethod.OWNCONTRACT;

// Payment options
const isDirectCreditCard = (p: PaymentClientMethod): p is PaymentCreditCard => isDirect(p) && isCreditCard(p);
const isDirectPaypal = (p: PaymentClientMethod): p is PaymentPaypal => isDirect(p) && isPaypal(p);
const isDeferredCreditCard = (p: PaymentClientMethod): p is PaymentCreditCard => isDeferred(p) && isCreditCard(p);
const isDeferredPaypal = (p: PaymentClientMethod): p is PaymentPaypal => isDeferred(p) && isPaypal(p);
const isDeferredDirectDebit = (p: PaymentClientMethod | PaymentAvailableMethod): p is PaymentDirectDebit =>
    isDeferred(p) && isDirectDebit(p);

const isAvailableDeferredPayment = (p: PaymentAvailableMethod): p is PaymentAvailableMethod =>
    (isCreditCard(p) || isPaypal(p)) && isDeferred(p);

type SearchFn<T, R extends T = T> = (p: T) => p is R;
type GetPaymentReturn<T, R extends T = T> = (list: T[]) => R | undefined;
const getPaymentMethod =
    <T extends PaymentClientMethod = PaymentClientMethod, R extends T = T>(
        searchFn: SearchFn<T, R>,
    ): GetPaymentReturn<T, R> =>
    (paymentMethods: T[]): R | undefined =>
        paymentMethods.find(searchFn);

export const getPaymentMethods = createSelector(
    getSeralizedPaymentMethods,
    (seralizedPaymentMethods: IPaymentClientMethod[]): PaymentClientMethod[] =>
        seralizedPaymentMethods
            .map((p: IPaymentClientMethod): PaymentClientMethod | undefined =>
                deserializePaymentMethod(p.method as PaymentMethod, p),
            )
            .filter((p: PaymentClientMethod | undefined): p is PaymentClientMethod => !!p),
);

export const getAvailablePaymentMethods = createSelector(
    getSeralizedAvailablePaymentMethods,
    (availablePaymentMethods: IPaymentAvailableMethod[]) =>
        availablePaymentMethods.map((a) => PaymentAvailableMethod.deserialize<IPaymentAvailableMethod>(a)),
);

export const getIsPaymentLoading = (store: IAppState): boolean => getPayment(store).isLoading;

// DIRECT selectors
export const getDirectCreditCard = createSelector(getPaymentMethods, getPaymentMethod(isDirectCreditCard));
export const getDirectPaypal = createSelector(getPaymentMethods, getPaymentMethod(isDirectPaypal));
export const getDirectOwnContract = createSelector(getPaymentMethods, getPaymentMethod(isOwnContract));

// DEFERRED selectors
export const getDeferred = createSelector(getPaymentMethods, getPaymentMethod(isDeferred));
export const getDeferredPaypal = createSelector(getPaymentMethods, getPaymentMethod(isDeferredPaypal));
export const getDeferredCreditCard = createSelector(getPaymentMethods, getPaymentMethod(isDeferredCreditCard));

// Available PaymentMethods selectors
const getAvailableDeferredPayment = createSelector(
    getAvailablePaymentMethods,
    (availablePaymentMethods: PaymentAvailableMethod[]): PaymentAvailableMethod | undefined =>
        availablePaymentMethods.find(isAvailableDeferredPayment),
);

const getAvailableDeferred = createSelector(
    getAvailablePaymentMethods,
    (availablePaymentMethods: PaymentAvailableMethod[]): PaymentAvailableMethod | undefined =>
        availablePaymentMethods.find(isDeferred),
);

export const getAvailableDeferredDirectDebit = createSelector(
    getAvailablePaymentMethods,
    (availablePaymentMethods: PaymentAvailableMethod[]): PaymentAvailableMethod | undefined =>
        availablePaymentMethods.find(isDeferredDirectDebit),
);

export const getCanUseDeferredPayment = (store: IAppState): boolean => {
    const clientData = getClientData(store);

    return (
        !clientData.deferredPaymentBlocked &&
        isInvoiceAddressValid(store) &&
        (!!getAvailableDeferredPayment(store) || !!clientData.allowDeferred)
    );
};

export const getCanRequestAllowDeferredMethods = (store: IAppState): boolean => {
    const hasDeferredPaymentMethod = !!getAvailableDeferred(store) || !!getDeferred(store);

    return !hasDeferredPaymentMethod && !getClientData(store).allowDeferred && isInvoiceAddressValid(store);
};

export const getHasAvailabilityForDeferredPayment = (store: IAppState): boolean => {
    const clientData = getClientData(store);
    return !clientData.deferredPaymentBlocked;
};

export const getFallbackPaymentMethod = createSelector(
    getPayment,
    (payment: IPaymentState): PaymentMethod | undefined => payment.localPaymentFallback?.paymentMethod,
);

export const hasTokenizationParams = (): boolean => {
    const urlParams = new URLSearchParams(window.location.search);

    return (
        urlParams.has(QUERY_PARAMS.TOKEN) &&
        urlParams.has(QUERY_PARAMS.PAYMENT_ID) &&
        urlParams.has(QUERY_PARAMS.PAYER_ID)
    );
};

const getIsOwnContractShipment = createSelector(
    [getCheckoutPriceDetails, getDirectOwnContract, getCheckoutService],
    (priceDetails, ownContractPaymentMethod, selectedService) =>
        !!(priceDetails?.price?.totalPrice === 0 && ownContractPaymentMethod && selectedService?.isOwnContract),
);

export const getIsPaymentStepSkippable = createSelector(
    [isInvoiceAddressValid, getDeferred, getIsOwnContractShipment],
    (invoiceAddressValid, deferredPaymentMethod, isOwnContractShipment) =>
        !!(invoiceAddressValid && (deferredPaymentMethod || isOwnContractShipment)),
);

export const getAutomaticPaymentMethod = createSelector(
    [getIsOwnContractShipment, getDirectOwnContract, getDeferred],
    (isOwnContractShipment, ownContractPaymentMethod, deferredPaymentMethod): PaymentClientMethod | undefined =>
        isOwnContractShipment ? ownContractPaymentMethod : deferredPaymentMethod,
);
