import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';

import { Button, ButtonSize, ButtonVariant, Grid, GridChild } from '@shipengine/giger';
import { useTenantConfig } from '@packlink/tenant-config-provider';

import { getUserEmail } from '@store/selectors/user';

import { ITenantConfig } from '@types';
import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';
import { LanguageSelector } from '@components/LanguageSelector/LanguageSelector';

import { getEmailLabelStyle, getLabelStyles } from './YourAccountSettingsStyles';
import { YourAccountSettingsPasswordForm } from './YourAccountSettingsPasswordForm';

export const YourAccountSettings = (): JSX.Element => {
    const { t } = useTranslation();

    const userEmail = useSelector(getUserEmail);
    const [showForm, setShowForm] = useState(false);
    const { showLanguageSelector, canChangePassword } = useTenantConfig<ITenantConfig>();

    return (
        <>
            <SettingsPage>
                <SettingsPageHeader
                    description={canChangePassword ? t('your-account.settings.description') : undefined}
                >
                    {t('your-account.settings.title')}
                </SettingsPageHeader>

                <Grid noPadding>
                    <GridChild>
                        <p css={getLabelStyles}>{t('form.label.email')}</p>
                    </GridChild>

                    <GridChild>
                        <p css={getEmailLabelStyle}>{userEmail}</p>
                    </GridChild>

                    {!showForm && canChangePassword && (
                        <>
                            <GridChild colStart={1}>
                                <p css={getLabelStyles}>{t('your-account.settings.password')}</p>
                            </GridChild>

                            <GridChild>
                                <Button
                                    onClick={() => setShowForm(true)}
                                    variant={ButtonVariant.TEXT}
                                    size={ButtonSize.SMALL}
                                >
                                    {t('your-account.settings.change-password')}
                                </Button>
                            </GridChild>
                        </>
                    )}

                    {showForm && (
                        <GridChild colStart={1} colSpan={3}>
                            <YourAccountSettingsPasswordForm closeFormHandler={() => setShowForm(false)} />
                        </GridChild>
                    )}
                </Grid>
            </SettingsPage>

            {showLanguageSelector && (
                <SettingsPage>
                    <SettingsPageHeader>{t('settings.language.title')}</SettingsPageHeader>
                    <LanguageSelector />
                </SettingsPage>
            )}
        </>
    );
};
