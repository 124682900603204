// It supports converting both camelCase and PascalCase into kebab-case
type CamelToSnakeUpperCase<T extends string> = T extends `${infer First}${infer Rest}`
    ? Rest extends Uncapitalize<Rest>
        ? `${Uppercase<First>}${CamelToSnakeUpperCase<Rest>}`
        : `${Uppercase<First>}_${CamelToSnakeUpperCase<Uncapitalize<Rest>>}`
    : Uppercase<T>;

export function camelToSnakeUpperCase<T extends string>(str: T): CamelToSnakeUpperCase<T> {
    return str?.replace(/([a-zA-Z])(?=[A-Z])/g, '$1_').toUpperCase() as CamelToSnakeUpperCase<T>;
}
