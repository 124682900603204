import { Typography } from '@shipengine/giger';
import { useState } from 'react';
import { NewTicketLink } from '@SupportCenter/components/NewTicketLink/NewTicketLink';
import { getHelpAndSupportTitleStyles, getSupportCenterWrapperStyles } from '@SupportCenter/SupportCenterStyles';
import { SupportTicketsTableFilters } from './components/SupportTicketsTableFilters/SupportTicketsTableFilters';
import { TicketTable } from './components/TicketTable/TicketTable';
import {
    getSupportTicketsTableFlags,
    SupportTicketsTableFiltersData,
    useSupportTicketsTableData,
} from './SupportTicketsTableData';
import {
    getActionLinkStyles,
    getFirstActionLinkStyles,
    getSubscriptionButtonsStyles,
    getSupportTicketsTableActionsStyles,
    getTableTitleStyles,
} from './SupportTicketsTableStyles';
import { useTranslation } from '@packlink/translation-provider';
import { CommonQuestions } from '@SupportCenter/components/CommonQuestions/CommonQuestions';
import { CallAgentLink } from '@SupportCenter/components/CallAgentLink/CallAgentLink';
import { useLastClientSubscription } from '@Subscriptions/hooks/useClientSubscriptions';

export function FaqAndSupportTicketsTable({ ...props }): JSX.Element {
    const { t } = useTranslation();
    const {
        isActive,
        isCanceled,
        isTenantSubscriptionEnabled,
        isLoading: isLoadingSubscription,
    } = useLastClientSubscription();
    const showSubscriptionButton = isTenantSubscriptionEnabled && !isActive && !isCanceled && !isLoadingSubscription;

    const [filters, setFilters] = useState<SupportTicketsTableFiltersData>({
        shipmentReference: '',
        status: undefined,
    });
    const { data, isLoading } = useSupportTicketsTableData(filters);
    const { hasNoResults, hasActiveFilters } = getSupportTicketsTableFlags(data);
    const hasTicketsCreated = !hasNoResults || hasActiveFilters;

    return (
        <main {...props}>
            <Typography variant="heading3" css={getHelpAndSupportTitleStyles}>
                {t('support-center.help-and-support.title')}
            </Typography>
            <div css={getSupportCenterWrapperStyles}>
                <Typography variant="heading4" css={getHelpAndSupportTitleStyles}>
                    {t('support-center.help-and-support.hello')}
                </Typography>
                <CommonQuestions />

                <Typography component="h5" variant="heading5" css={getTableTitleStyles}>
                    {hasTicketsCreated
                        ? t('support-center.table.title-with-results')
                        : t('support-center.table.title-empty')}
                </Typography>

                <section data-id="support-center-support-tickets">
                    <div css={getSupportTicketsTableActionsStyles}>
                        {hasTicketsCreated && (
                            <>
                                <SupportTicketsTableFilters onChange={setFilters} filters={filters} />
                                {showSubscriptionButton && (
                                    <div css={getSubscriptionButtonsStyles}>
                                        <NewTicketLink css={[getFirstActionLinkStyles, getActionLinkStyles]} />
                                        <CallAgentLink css={getActionLinkStyles} />
                                    </div>
                                )}
                                {!showSubscriptionButton && <NewTicketLink />}
                            </>
                        )}
                    </div>
                    <TicketTable data={data} isLoading={isLoading} />
                </section>
            </div>
        </main>
    );
}
