import {
    ChartContainer,
    formatOnlyIntegersTicks,
    getChartContainerStatus,
    getTimeScale,
    ResponsiveLine,
    useRequestOnIntersection,
} from '../Charts';
import { useTranslation } from '@packlink/translation-provider';
import { AnalyticsTimeSeriesParams, UserAnalyticsRepository } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { getTimeAxis, getLinearScale } from '../../utils/charts';
import { getSubtitleRangeParams } from '../../utils/dates';
import { Charts } from '../../constants/analytics';

const userAnalyticsRepository = new UserAnalyticsRepository(apiClient);

export function ShipmentsOverTimeChart(props: AnalyticsTimeSeriesParams): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const requestShipmentsOverTime = () => {
        return userAnalyticsRepository.getShipmentsOverTimeSerie(props).then((serie) => [
            {
                id: t('analytics.chart.shipments-over-time'),
                data: serie.data,
            },
        ]);
    };

    const { data, status, isFetching, intersectionRef } = useRequestOnIntersection({
        requestCacheKeys: [Charts.ShipmentsOverTime, props],
        requestData: requestShipmentsOverTime,
    });

    const isEmpty = !data?.filter((serie) => serie.data.length).length;
    const containerStatus = getChartContainerStatus(status, isFetching, isEmpty);

    return (
        <ChartContainer
            ref={intersectionRef}
            title={t('analytics.chart.shipments-over-time')}
            subtitle={t('analytics.chart.date-range', getSubtitleRangeParams(locale, props.from, props.to))}
            status={containerStatus}
            cdnUrl={config.cdn.baseUrl}
        >
            {!isEmpty && (
                <ResponsiveLine
                    id={Charts.ShipmentsOverTime}
                    data={data}
                    xScale={getTimeScale()}
                    xFormat={(date: string | number | Date) => Intl.DateTimeFormat(locale).format(date as Date)}
                    yScale={getLinearScale()}
                    axisBottom={getTimeAxis(locale, data[0].data.length)}
                    axisLeft={{
                        format: formatOnlyIntegersTicks,
                    }}
                    tooltipXLabel={t('analytics.label.date')}
                    tooltipYLabel={t('analytics.label.shipments')}
                />
            )}
        </ChartContainer>
    );
}
