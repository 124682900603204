import keyBy from 'lodash.keyby';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useEvent } from '@packlink/event-handler';
import { useTranslation } from '@packlink/translation-provider';
import { Spinner, Typography } from '@shipengine/giger';
import { IProShipmentApiResponse, IShipment, Shipment, ShipmentStatus, PrintingFormat } from '@packlink/packlink-sdk';

import { EventName } from '@types';
import { getClientIdentifier } from '@store/selectors/client';
import { AmplitudeEventPrefixes, AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';

import { ButtonAction, DownloadLabel } from '@components/DownloadLabel/DownloadLabel';
import { DownloadLabelDialog } from '@components/DownloadLabel/DownloadLabelDialog';
import { useDialogDownloadLabel } from '@components/DownloadLabel/hooks/useDialogDownloadLabel';
import { useDownloadLabel } from '@components/DownloadLabel/hooks/useDownloadLabel';

import {
    getDownloadLabelsStyles,
    getLabelsReadyTextStyles,
    getLabelsTextStyles,
    getSpinnerStyles,
    getSummaryLabelsStyles,
} from '../PaymentSummaryStyles';
import { useAmplitude } from '@hooks/useAmplitude';

interface IPaymentSummaryLabelsProps {
    shipments?: Shipment<IShipment>[];
}

export function PaymentSummaryLabels({ shipments }: IPaymentSummaryLabelsProps): JSX.Element {
    const [labelsReady, setLabelsReady] = useState<string[]>(() => {
        return (
            shipments
                ?.map((s) => (s.state === ShipmentStatus.READY_TO_PRINT ? s.packlinkReference : undefined))
                .filter((ref): ref is string => !!ref) || []
        );
    });

    const shipmentsByRef = useMemo(() => keyBy(shipments, 'packlinkReference'), [shipments]);
    const areAllLabelsReady = labelsReady.length === shipments?.length;
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();

    const clientId = useSelector(getClientIdentifier);
    const { eventBind, eventUnbind } = useEvent<IProShipmentApiResponse>(clientId, EventName.SHIPMENT_UPDATED);

    const sendAmplitudeClickEvent = useCallback(
        (eventName: AmplitudeEvents, data?: Record<string, unknown>) => {
            const event =
                `${AmplitudeEventPrefixes.PAYMENT_SUMMARY} ${AmplitudeEventPrefixes.CLICK} ${eventName}` as AmplitudeEvents;
            sendAmplitudeEvent(event, { ...data, [AmplitudeProperties.LABELS_COUNT]: labelsReady.length });
        },
        [labelsReady.length, sendAmplitudeEvent],
    );

    const onClickAction = useCallback(
        (action: ButtonAction, eventProps: Record<string, unknown>) => {
            const actionEvent =
                action === ButtonAction.PRINT ? AmplitudeEvents.PRINT_LABELS : AmplitudeEvents.DOWNLOAD_LABELS;
            sendAmplitudeClickEvent(actionEvent, eventProps);
        },
        [sendAmplitudeClickEvent],
    );

    const { closeDialog, onFinish, onError, onFetching, status } = useDialogDownloadLabel({ onClick: onClickAction });

    const { bulkPrintingFormat, buttonAction, fetchLabels, onChangePrintingFormat } = useDownloadLabel({
        onFetching,
        onError,
        onFinish,
    });

    const handleChangePrintingFormat = (printingFormat: PrintingFormat) => {
        const eventName = `${AmplitudeEvents.LABEL_FORMAT} ${printingFormat}` as AmplitudeEvents;
        sendAmplitudeClickEvent(eventName);
        onChangePrintingFormat(printingFormat);
    };

    const isPrintAction = buttonAction === ButtonAction.PRINT;

    const handleShipmentUpdate = useCallback(
        ({ data }: IProShipmentApiResponse) => {
            const isShipmentReadyToPrint =
                shipmentsByRef[data.packlink_reference] && data.state === ShipmentStatus.READY_TO_PRINT;

            if (isShipmentReadyToPrint) {
                setLabelsReady((currentLabelsReady) => {
                    if (!currentLabelsReady.includes(data.packlink_reference)) {
                        return currentLabelsReady.concat(data.packlink_reference);
                    } else {
                        return currentLabelsReady;
                    }
                });
            }
        },
        [shipmentsByRef],
    );
    useEffect(() => {
        eventBind(handleShipmentUpdate);

        return () => {
            eventUnbind(handleShipmentUpdate);
        };
    }, [eventBind, eventUnbind, handleShipmentUpdate]);

    return (
        <>
            <div css={getSummaryLabelsStyles}>
                <Typography variant="body1" css={getLabelsTextStyles}>
                    <span>
                        {!areAllLabelsReady && (
                            <>
                                {t('bulk-print.loading.title')}
                                {labelsReady.length > 0 && (
                                    <span css={getLabelsReadyTextStyles}>
                                        (
                                        {t('bulk-print.labels.progress', {
                                            ready: labelsReady.length,
                                            total: shipments?.length,
                                        })}
                                        )
                                    </span>
                                )}
                            </>
                        )}
                        {areAllLabelsReady && t('bulk-print.labels.ready')}
                    </span>
                </Typography>

                {!areAllLabelsReady && <Spinner css={getSpinnerStyles} />}

                <div css={getDownloadLabelsStyles}>
                    <DownloadLabel
                        disabled={labelsReady.length === 0}
                        download={true}
                        fetchLabels={fetchLabels}
                        printingFormat={bulkPrintingFormat}
                        onChangePrintingFormat={handleChangePrintingFormat}
                        shipmentReferences={labelsReady}
                    />
                </div>
            </div>
            <DownloadLabelDialog
                status={status}
                isPrintAction={isPrintAction}
                numberShipments={labelsReady.length}
                onCloseDialog={closeDialog}
            />
        </>
    );
}
