const SATURDAY_ISO_WEEKDAY = 6;
const SUNDAY_ISO_WEEKDAY = 0;

const today = new Date();

export function isWeekend(date: Date): boolean {
    return date.getDay() === SATURDAY_ISO_WEEKDAY || date.getDay() === SUNDAY_ISO_WEEKDAY;
}

export function getTomorrowDate(): Date {
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    return tomorrow;
}

export function isToday(date: Date): boolean {
    return today.toDateString() === date.toDateString();
}

/**
 * Returns a date formated as YYYY-MM-DD
 */
export function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}
