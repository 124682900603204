import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentDetailsContentStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridTemplateRows: 'repeat(6, auto)',
        gridGap: theme.spacing(4),
        marginRight: theme.spacing(4),
        gridTemplateAreas: `
            "customs customs"
            "printerless printerless"
            "carrierWarnings carrierWarnings"
            "tracking1 tracking2"
            "origin destination"
            "sender recipient"
            "warning1 warning2"
            "order price"
        `,
    });

export const getCustomsDisclaimerStyles = css({
    gridArea: 'customs',
});

export const getPrintInStoreDisclaimerStyles = css({
    gridArea: 'printerless',
});

export const getShipmentDetailsCarrierWarningsStyles = css({
    gridArea: 'carrierWarnings',
});

export const getShipmentSectionFullRowStyles = (theme: Theme): SerializedStyles =>
    css({
        gridColumn: '1 / -1',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        gap: theme.spacing(4),
    });

export const getShipmentDetailsEmailAndPackagingStyles = (): SerializedStyles =>
    css({
        article: {
            height: '100%',
        },
        width: '50%',
    });
