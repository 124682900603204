import { useTranslation } from '@packlink/translation-provider';
import { ProShipment } from '@packlink/packlink-sdk';
import { useDateFormat } from '@hooks/useDateFormat';
import { getDateTimeDetailsStyles } from './DateTimeDetailsStyles';
import { Typography } from '@shipengine/giger';

interface IDateTimeDetailsProps {
    shipment: ProShipment;
}

export function DateTimeDetails({ shipment }: IDateTimeDetailsProps): JSX.Element {
    const { t } = useTranslation();
    const {
        data: { collection, service },
    } = shipment;

    const showTime = collection?.date && !service?.isOwnContract;
    const dateTime = useDateFormat(collection?.date, 'dddd, DD MMMM YYYY');
    const timeContent = `${dateTime}, ${collection?.interval?.getTimeStrip()}`;

    return (
        <Typography variant="heading3" component="h4" css={getDateTimeDetailsStyles}>
            {showTime && <time>{timeContent}</time>}
            {service?.isOwnContract && <span>{t('post-sale.own-contract.pick-up-carrier')}</span>}
        </Typography>
    );
}
