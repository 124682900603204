import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSidePanelContentErrorStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
    });

export const getSidePanelContentErrorIconStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.error.main,
        display: 'flex',
        justifyContent: 'center',
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(4)}px`,
    });

export const getSidePanelContentErrorActionStyles = (): SerializedStyles =>
    css({
        textAlign: 'center',
    });

export const getSidePanelContentErrorStepsTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
        fontWeight: theme.typography.fontWeight.semibold,
    });

export const getSidePanelContentErrorStepsStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `0 0 ${theme.spacing(6)}px`,
        padding: 0,
        listStyleType: 'none',
    });

export const getSidePanelContentErrorStepStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        ':not(:last-of-type)': {
            marginBottom: theme.spacing(2),
        },
    });

export const getSidePanelContentErrorStepIconStyles = (theme: Theme): SerializedStyles =>
    css({
        flexShrink: 0,
        svg: {
            marginRight: theme.spacing(),
            transform: 'rotate(180deg)',
        },
    });
