import { hasUniqueTrackingUrl } from '@packlink/tracking-details';
import { ProShipment } from '@packlink/packlink-sdk';
import { getTrackingInfoLinkStyles } from './PostSaleTrackingStyles';
import { PostSaleTrackingParcelReferenceList } from './PostSaleTrackingParcelReferenceList';
import { PostSaleTrackingCarrierWebsite } from './PostSaleTrackingCarrierWebsite';

interface IPostSaleTracking {
    shipment: ProShipment;
}

export const PostSaleTracking = ({ shipment }: IPostSaleTracking): JSX.Element => {
    const { trackings, trackingUrl } = getPostSaleTrackingData(shipment);
    const hasUniqueUrl = hasUniqueTrackingUrl(trackingUrl);
    return (
        <>
            <PostSaleTrackingParcelReferenceList
                trackings={trackings}
                trackingUrl={trackingUrl}
                data-id="tracking-carrier-section"
            />
            {!hasUniqueUrl && trackingUrl && (
                <PostSaleTrackingCarrierWebsite
                    data-id="tracking-carrier-website-link"
                    css={getTrackingInfoLinkStyles}
                    trackingUrl={trackingUrl}
                />
            )}
        </>
    );
};

function getPostSaleTrackingData(shipment: ProShipment) {
    return {
        trackings: (shipment.data.trackings || []) as string[],
        trackingUrl: shipment.data.trackingUrl || '',
    };
}
