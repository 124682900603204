import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { IconNames, Theme } from '@shipengine/giger-theme';
import { Icon, IconSize, Typography, WithChildrenCommonProps } from '@shipengine/giger';
import {
    getInfoPanelContentStyles,
    getInfoPanelHeaderStyles,
    getInfoPanelStyles,
    getInfoPanelSummaryStyles,
} from './InfoPanelStyles';
import { Interpolation } from '@emotion/react';

export type IInfoPanelProps = WithChildrenCommonProps<{
    title: string;
    collapsible: boolean;
    collapsed?: boolean;
    summaryCss?: Interpolation<Theme>;
}>;

export const InfoPanel: React.FC<IInfoPanelProps> = (props: IInfoPanelProps): JSX.Element => {
    const { title, children, collapsible, summaryCss, collapsed = false, ...rest } = props;
    const isInitiallyOpen = !collapsible || !collapsed;

    const [isOpen, setIsOpen] = useState(isInitiallyOpen);

    const handleOnToggle = ({ target: { open } }: ChangeEvent<HTMLDetailsElement>): void => {
        setIsOpen(open);
    };

    const handleOnClick = (event: SyntheticEvent<HTMLElement>): void => {
        !collapsible && event.preventDefault();
    };

    return (
        <details open={isInitiallyOpen} onToggle={handleOnToggle} css={getInfoPanelStyles} {...rest}>
            <summary css={getInfoPanelSummaryStyles} onClick={handleOnClick}>
                <Typography
                    variant="body2"
                    component="span"
                    bold={true}
                    css={[getInfoPanelHeaderStyles(collapsible), summaryCss]}
                >
                    {title}
                    {collapsible && (
                        <Icon
                            name={isOpen ? IconNames.CHEVRON_TOP : IconNames.CHEVRON_BOTTOM}
                            size={IconSize.SIZE_REGULAR}
                        />
                    )}
                </Typography>
            </summary>
            <div css={getInfoPanelContentStyles}>{children}</div>
        </details>
    );
};
