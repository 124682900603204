import { PropsWithChildren } from 'react';

import { TenantThemeProvider } from '@packlink/tenant-theme-provider';
import { useTranslation } from '@packlink/translation-provider';

export function TenantThemeProviderWrapper({ children }: PropsWithChildren<unknown>) {
    const { t } = useTranslation();

    return (
        <TenantThemeProvider headerProps={{ title: t('app.helmet.title'), description: t('html.meta.description') }}>
            {children}
        </TenantThemeProvider>
    );
}
