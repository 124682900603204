import { Form, FormikState, useFormikContext } from 'formik';
import useDebounce from 'react-use/lib/useDebounce';
import { Origin } from '@types';

import {
    Button,
    Grid,
    GridChild,
    Icon,
    InlineNotification,
    Next,
    NotificationType,
    Typography,
} from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { SelectWarehouse } from '@components/SelectAddress/SelectWarehouse';
import { IconNames } from '@shipengine/giger-theme';
import { IParcel, IPostalCode, IPostalZone, IWarehouseAddress, Parcel } from '@packlink/packlink-sdk';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCheckoutCountry,
    setCheckoutParcels,
    setCheckoutPostalCode,
    setCheckoutWarehouseAddress,
} from '@store/actions/checkout';
import { getCheckoutAdditionalData } from '@store/selectors/checkout';
import { useRef, useState } from 'react';
import { getInfoStepHeadingStyles, getInfoStepSubmitButtonStyles, getMeasureTextStyles } from './InfoStepStyles';
import { IInfoStepForm } from './InfoStep';
import { ParcelFormList } from '@components/ParcelForm/ParcelFormList';
import { CountryPostalCodeForm } from '@components/LocationForm/CountryPostalCodeForm';
import { useWeightDeviation } from '../hooks/useWeightDeviation';
import { AmplitudeCategories, AmplitudeEvents, AmplitudeProperties } from '@packlink/metrics';
import { AppDispatch } from '@store';
import { TenantUtils } from '@utils/tenant';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { useAmplitude } from '@hooks/useAmplitude';

export const InfoStepForm = (): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const getHelpCenterPage = useHelpCenter();
    const additionalData = useSelector(getCheckoutAdditionalData);
    const { initialValues, values, setFormikState } = useFormikContext<IInfoStepForm>();
    const [selectedWarehouseId, setSelectedWarehouseId] = useState<string | undefined>(
        additionalData?.selectedWarehouseId as string,
    );
    const [warehouseReset, setWarehouseReset] = useState<boolean>(false);

    const disclaimerWasShown = useRef(false);

    const {
        cdn: { url: cdnUrl },
    } = config;
    const image = `${cdnUrl}/pro/statics/images/empty-state.png`;

    const { sendAmplitudeEvent } = useAmplitude();

    useDebounce(
        () => {
            const packages = values.parcels.map((p: Partial<IParcel>) => Parcel.deserialize(p));
            dispatch(setCheckoutParcels(packages));
        },
        500,
        [values],
    );

    const handleWarehouseSelect = (warehouse?: IWarehouseAddress) => {
        dispatch(setCheckoutWarehouseAddress(warehouse, locale));
        setSelectedWarehouseId(warehouse?.id);
        setWarehouseReset(!warehouse);

        setFormikState((prevState: FormikState<IInfoStepForm>) => ({
            ...prevState,
            values: {
                ...prevState.values,
                [Origin.FROM]: {
                    zipCode: warehouse?.zipCode,
                    alpha2Code: warehouse?.alpha2Code,
                    city: warehouse?.city,
                    state: warehouse?.postalZone.translations[locale],
                    country: warehouse
                        ? { label: warehouse?.postalZone.translations[locale], value: warehouse?.postalZone.id }
                        : undefined,
                    postalCode: warehouse
                        ? { label: `${warehouse.zipCode} - ${warehouse.city}`, value: warehouse.postalCodeId }
                        : undefined,
                },
            },
        }));
    };

    const handleCountryChange = (country: IPostalZone, origin: Origin): void => {
        dispatch(setCheckoutCountry(country, origin));
    };
    const handlePostalCodeChange = (postalCode: IPostalCode, origin: Origin): void => {
        dispatch(setCheckoutPostalCode(postalCode, origin));
    };
    const onSuccessShowingDisclaimer = () => {
        sendAmplitudeEvent(AmplitudeEvents.SHOW_WEIGHT_DEVIATION_DISCLAIMER, {
            [AmplitudeProperties.TENANT]: TenantUtils.getTenantName(),
            [AmplitudeProperties.CATEGORY]: AmplitudeCategories.ACQUISITION,
        });
        disclaimerWasShown.current = true;
    };
    const { data: disclaimerData } = useWeightDeviation(
        values.to.alpha2Code || null,
        values.parcels || [],
        !disclaimerWasShown.current,
        onSuccessShowingDisclaimer,
    );

    return (
        <Form>
            <Grid noPadding>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <Typography css={getInfoStepHeadingStyles} variant="heading5" bold>
                        {t(`form.section.${Origin.FROM}`)}
                    </Typography>
                    {!selectedWarehouseId && (
                        <CountryPostalCodeForm
                            name={Origin.FROM}
                            initialPostalZoneId={warehouseReset ? undefined : initialValues.from.country?.value}
                            isPostalSelectorsDisabled={false}
                            isCollapsed={true}
                            onCountryChange={handleCountryChange}
                            onPostalCodeChange={handlePostalCodeChange}
                        />
                    )}
                    <SelectWarehouse selectedWarehouseId={selectedWarehouseId} onSelect={handleWarehouseSelect} />
                </GridChild>

                <GridChild colSpan={12} colSpanTablet={6}>
                    <Typography css={getInfoStepHeadingStyles} variant="heading5" bold>
                        {t(Origin.TO.toUpperCase())}
                    </Typography>
                    <CountryPostalCodeForm
                        name={Origin.TO}
                        initialPostalZoneId={initialValues.to.country?.value}
                        isPostalSelectorsDisabled={false}
                        isCollapsed={true}
                        onCountryChange={handleCountryChange}
                        onPostalCodeChange={handlePostalCodeChange}
                    />
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={12}>
                    <Typography css={getInfoStepHeadingStyles} variant="heading5" bold>
                        {t('form.section.parcels')}
                    </Typography>
                    <Typography css={getMeasureTextStyles} variant="body1" component="p">
                        {t('form.section.parcels-measure')}
                    </Typography>
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <ParcelFormList
                        name="parcels"
                        showWeightDeviationDisclaimer={disclaimerData?.showDisclaimer || disclaimerWasShown.current}
                        showInSingleColumn
                    />
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <InlineNotification
                        title={t('form.section.parcels-callout.title')}
                        type={NotificationType.INFO}
                        coloredTitle={false}
                        src={image}
                        actionElement={
                            <Next.Link href={getHelpCenterPage(HELP_CENTER_KEYS.weightDeviationPolicy)} target="_blank">
                                {t('form.section.parcels-callout.action')}
                            </Next.Link>
                        }
                    >
                        {t('form.section.parcels-callout.text')}
                    </InlineNotification>
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={12}>
                    <div css={getInfoStepSubmitButtonStyles}>
                        <Button type="submit">
                            <Typography variant="body1" bold>
                                {t('form.button.get-rates')}
                            </Typography>
                            <Icon name={IconNames.ARROW_RIGHT} />
                        </Button>
                    </div>
                </GridChild>
            </Grid>
        </Form>
    );
};
