import { IconNames } from '@shipengine/giger-theme';
import { Button, ButtonColor, ButtonVariant, Icon, IconSize, Typography } from '@shipengine/giger';
import { ClientType } from '@packlink/packlink-sdk';
import { getClientData } from '@store/selectors/client';
import { useTranslation } from '@packlink/translation-provider';
import { useSelector } from 'react-redux';
import {
    getBillingInfoCardContainerStyles,
    getBillingInfoCardListStyles,
    getBillingInfoCardStyles,
    getBillingInfoCardTitleStyles,
} from './BillingInfoCardStyles';

interface IBillingInfoCardProps {
    onEditClick: () => void;
}
export const BillingInfoCard = ({ onEditClick }: IBillingInfoCardProps): JSX.Element => {
    const { t } = useTranslation();
    const client = useSelector(getClientData);
    const toggleShowCard = (): void => {
        onEditClick();
    };
    return (
        <div css={getBillingInfoCardStyles}>
            <div css={getBillingInfoCardContainerStyles}>
                <Typography css={getBillingInfoCardTitleStyles} variant="body2" bold>
                    {t('invoice-card.label.title')}
                </Typography>
                <Button variant={ButtonVariant.TEXT} color={ButtonColor.PRIMARY} onClick={toggleShowCard}>
                    <Icon name={IconNames.EDIT} size={IconSize.SIZE_SMALL} />
                    <span>{t('invoice-card.label.edit')}</span>
                </Button>
                <ul css={getBillingInfoCardListStyles}>
                    {client.type === ClientType.BUSINESS ? (
                        <>
                            <Typography component="li" variant="subtitle2">
                                {t('form.label.company')}
                            </Typography>
                            <Typography component="li" variant="subtitle2">
                                {client.companyName}
                            </Typography>
                            <Typography component="li" variant="subtitle2">
                                {client.taxId}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography component="li" variant="subtitle2">
                                {t('form.label.autonomous')}
                            </Typography>
                            <Typography component="li" variant="subtitle2">
                                {`${client.firstName} ${client.lastName}`}
                            </Typography>
                        </>
                    )}
                    <Typography component="li" variant="subtitle2">
                        {client.email}
                    </Typography>
                    <Typography component="li" variant="subtitle2">
                        {client.phone}
                    </Typography>
                    <Typography component="li" variant="subtitle2">
                        {client.address} {client.address2}
                    </Typography>
                    <Typography component="li" variant="subtitle2">
                        {client.location} - {client.zipCode}
                    </Typography>
                    <Typography component="li" variant="subtitle2">
                        {client.state}
                    </Typography>
                </ul>
            </div>
        </div>
    );
};
