import { useNavigate } from 'react-router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DeepPartial } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import {
    getCheckoutFrom,
    getCheckoutParcels,
    getCheckoutService,
    getCheckoutShipment,
    getCheckoutSource,
    getCheckoutTo,
} from '@store/selectors/checkout';
import { IParcel, Parcel, Service } from '@packlink/packlink-sdk';
import { useToast } from '@shipengine/giger';
import { ServiceListEvents } from '@packlink/service-list';
import { AppDispatch } from '@store';
import { resetService, setCheckoutService } from '@store/actions/checkout';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useCheckoutPath } from '@pages/checkout/hooks/useCheckoutPath';
import { CheckoutRoute } from '@pages/checkout/routes';
import { ServiceList } from './ServiceList/ServiceList';
import { StepContent } from '../StepContent';
import { useAmplitude } from '@hooks/useAmplitude';
import { useTenantBehavior } from '@packlink/tenant-config-provider';
import { useLastClientSubscription } from '@Subscriptions/hooks/useClientSubscriptions';
import { SubscriptionNotification } from '@common/components/SubscriptionNotification/SubscriptionNotification';
import { getServiceStepStyles } from './ServiceStepStyles';

export const ServiceStep = (): JSX.Element => {
    const { t } = useTranslation();
    const toast = useToast(t);
    const [hasAllInfo, setHasAllInfo] = useState(false);
    const from = useSelector(getCheckoutFrom);
    const to = useSelector(getCheckoutTo);
    const parcels = useSelector(getCheckoutParcels);
    const selectedService = useSelector(getCheckoutService);
    const source = useSelector(getCheckoutSource);
    const shipment = useSelector(getCheckoutShipment);
    const firstRenderRef = useRef(true);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const getCheckoutPath = useCheckoutPath();
    const { sendAmplitudeShipmentEvent } = useAmplitude();
    const getTenantBehavior = useTenantBehavior();
    const { isTenantSubscriptionEnabled, isActive, isCanceled, isLoading } = useLastClientSubscription();
    const promotedGatedServices = getTenantBehavior<{ enabled: boolean }>('PromotedGatedServices')?.properties.enabled;
    const showPromotedGatedServicesLink =
        !isLoading && promotedGatedServices && isTenantSubscriptionEnabled && !isActive && !isCanceled;

    const selectService = (service: Service) => {
        dispatch(resetService());
        dispatch(setCheckoutService(service));

        navigate(getCheckoutPath(CheckoutRoute.ADDRESS));
    };

    const returnToDetailsStep = useCallback(() => {
        toast.alert({ message: t('checkout.services.not-found') });
        sendAmplitudeShipmentEvent(AmplitudeEvents.SEARCH_FAILED, shipment);
        navigate(getCheckoutPath(CheckoutRoute.INFO));
    }, [getCheckoutPath, navigate, sendAmplitudeShipmentEvent, shipment, t, toast]);

    const onFetchServices = (services: Service[]) => {
        if (!services?.length) {
            returnToDetailsStep();
        } else {
            sendAmplitudeShipmentEvent(AmplitudeEvents.LIST_SERVICES, shipment, {
                [AmplitudeProperties.SERVICES_SHOWN]: services.length,
                [AmplitudeProperties.CUSTOMS_REQUIRED]: services.every(
                    (service) => !!service.customsRequired?.available,
                ),
                [AmplitudeProperties.SERVICES_IDS]: services.map(({ id }) => id),
                [AmplitudeProperties.SERVICES_WITH_TAGS]: services
                    .filter(({ tags }) => tags?.length)
                    .map(({ id }) => id),
            });
        }
    };

    const onError = (eventType: ServiceListEvents) => {
        if (eventType === ServiceListEvents.SERVICES) {
            returnToDetailsStep();
        }
    };

    useEffect(() => {
        if (firstRenderRef.current) {
            // We only want to check this info the first time when entering the step
            firstRenderRef.current = false;

            if (selectedService) {
                dispatch(resetService());
            }

            const hasRequiredInfo =
                !!from.alpha2Code &&
                !!from.zipCode &&
                !!to.alpha2Code &&
                !!to.zipCode &&
                parcels?.length > 0 &&
                parcels.every((p: Parcel<DeepPartial<IParcel>>) => !!(p.height && p.length && p.weight && p.width));

            if (!hasRequiredInfo) {
                returnToDetailsStep();
                return;
            }

            setHasAllInfo(hasRequiredInfo);
        }
    }, [
        dispatch,
        from.alpha2Code,
        from.zipCode,
        parcels,
        returnToDetailsStep,
        selectedService,
        to.alpha2Code,
        to.zipCode,
    ]);

    return (
        <>
            {showPromotedGatedServicesLink && (
                <SubscriptionNotification
                    css={getServiceStepStyles}
                    title={t('subscriptions.upgrade-notification.more-savings')}
                    amplitudeEvent={AmplitudeEvents.CLICK_GATED_SERVICES}
                >
                    {t('subscriptions.upgrade-notification.services')}
                </SubscriptionNotification>
            )}

            <StepContent>
                {hasAllInfo && (
                    <ServiceList
                        from={from}
                        to={to}
                        packages={parcels}
                        source={source}
                        onSelectService={selectService}
                        onFetchServices={onFetchServices}
                        onError={onError}
                    />
                )}
            </StepContent>
        </>
    );
};
