import { useTranslation } from '@packlink/translation-provider';
import DOMPurify from 'dompurify';
import { IconNames } from '@shipengine/giger-theme';
import { Button, ButtonVariant, Icon, IconSize, Typography } from '@shipengine/giger';
import { useHelpCenter } from '@packlink/provider';
import {
    getSidePanelContentErrorActionStyles,
    getSidePanelContentErrorIconStyles,
    getSidePanelContentErrorStepIconStyles,
    getSidePanelContentErrorStepsStyles,
    getSidePanelContentErrorStepsTitleStyles,
    getSidePanelContentErrorStepStyles,
    getSidePanelContentErrorStyles,
} from './SidePanelContentErrorStyles';

export interface ISidePanelContentErrorProps {
    title?: string;
    subtitle?: string;
    messages?: string[];
    onTryAgain: () => void;
}

export const SidePanelContentError = (props: ISidePanelContentErrorProps): JSX.Element => {
    const { t } = useTranslation();
    const {
        title = t('side-panel.error.generic-title'),
        subtitle = t('side-panel.error.generic-subtitle'),
        messages,
        onTryAgain,
    } = props;
    const getHelpCenterPage = useHelpCenter();
    const contactUsTranslation = DOMPurify.sanitize(t('side-panel.steps.contact-us', { href: getHelpCenterPage() }), {
        ADD_ATTR: ['target'],
    });

    return (
        <section css={getSidePanelContentErrorStyles}>
            <div css={getSidePanelContentErrorIconStyles}>
                <Icon name={IconNames.INFO_FILLED} size={IconSize.SIZE_LARGE} />
            </div>

            <Typography component="div" variant="heading5">
                {title}
            </Typography>
            <Typography component="div" variant="body2">
                {subtitle}
            </Typography>
            <Typography component="div" variant="body2" css={getSidePanelContentErrorStepsTitleStyles}>
                {t('side-panel.steps.title')}
            </Typography>

            <ul css={getSidePanelContentErrorStepsStyles}>
                <li css={getSidePanelContentErrorStepStyles}>
                    <Icon
                        name={IconNames.ARROW_LEFT}
                        size={IconSize.SIZE_REGULAR}
                        css={getSidePanelContentErrorStepIconStyles}
                    />
                    <Typography
                        component="span"
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: contactUsTranslation }}
                    />
                </li>
                {messages?.map((m: string, index: number) => (
                    <li key={index} css={getSidePanelContentErrorStepStyles}>
                        <Icon
                            name={IconNames.ARROW_LEFT}
                            size={IconSize.SIZE_REGULAR}
                            css={getSidePanelContentErrorStepIconStyles}
                        />
                        <Typography component="span" variant="body2">
                            {t(m)}
                        </Typography>
                    </li>
                ))}
            </ul>

            <div css={getSidePanelContentErrorActionStyles}>
                <Button variant={ButtonVariant.OUTLINED} onClick={onTryAgain}>
                    {t('side-panel.button.try-again')}
                </Button>
            </div>
        </section>
    );
};
