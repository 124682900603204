import { IShipment, Shipment } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import * as yup from 'yup';
import {
    EMPTY_FILE_UPLOAD_ID,
    getUploadAttachmentValidationSchema,
    SupportCenterAttachment,
} from '@SupportCenter/components/UploadAttachment/UploadAttachmentData';

export type GenericSupportTicketFormData = { reason: string; additionalPicture: SupportCenterAttachment | undefined };

export function useGenericTicketFieldsFormValidationSchema(): yup.ObjectSchema<GenericSupportTicketFormData> {
    const { t } = useTranslation();

    return yup
        .object()
        .shape({
            reason: yup
                .string()
                .required(
                    t('form.error.required', { field: t('support-center.generic-support-ticket-fields.reason-label') }),
                ),
            additionalPicture: getUploadAttachmentValidationSchema(),
        })
        .defined();
}

export function createGenericSupportTicketInitialValues(): GenericSupportTicketFormData {
    return {
        reason: '',
        additionalPicture: undefined,
    };
}

export function parseGenericSupportTicketFormData({ reason, additionalPicture }: GenericSupportTicketFormData): {
    additionalPicture: string;
    reason: string;
};
export function parseGenericSupportTicketFormData(
    { reason, additionalPicture }: GenericSupportTicketFormData,
    shipment: Shipment<IShipment>,
): { shipmentReference: string; additionalPicture: string; reason: string };
export function parseGenericSupportTicketFormData(
    { reason, additionalPicture }: GenericSupportTicketFormData,
    shipment?: Shipment<IShipment>,
): { shipmentReference?: string; additionalPicture: string; reason: string } {
    return {
        shipmentReference: shipment?.packlinkReference,
        reason,
        additionalPicture: additionalPicture?.uploadId || EMPTY_FILE_UPLOAD_ID,
    };
}
