import { useTranslation } from '@packlink/translation-provider';
import { Button, WithCommonProps } from '@shipengine/giger';
import { useFormikContext } from 'formik';

type SubmitButtonProps = WithCommonProps<unknown>;

export function SubmitButton(props: SubmitButtonProps): JSX.Element {
    const { t } = useTranslation();
    const { isValid, isSubmitting } = useFormikContext();

    return (
        <Button type="submit" disabled={!isValid} isLoading={isSubmitting} {...props}>
            {t('support-center.form.submit')}
        </Button>
    );
}
