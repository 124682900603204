import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Typography, WithChildrenCommonProps } from '@shipengine/giger';
import {
    getSupportTicketSummarySectionContentStyles,
    getSupportTicketSummarySectionIconStyles,
    getSupportTicketSummarySectionStyles,
    getSupportTicketSummarySectionWrapperStyles,
} from './SupportTicketSummarySectionStyles';

type SupportTicketSummarySectionProps = WithChildrenCommonProps<{
    icon: IconNames;
    title: React.ReactNode;
}>;

export function SupportTicketSummarySection({
    icon,
    title,
    children,
    ...props
}: SupportTicketSummarySectionProps): JSX.Element {
    return (
        <li css={getSupportTicketSummarySectionStyles} {...props}>
            <div css={getSupportTicketSummarySectionWrapperStyles}>
                <Icon css={getSupportTicketSummarySectionIconStyles} name={icon} size={IconSize.SIZE_SMALL} />
                <Typography variant="body2" bold>
                    {title}
                </Typography>
            </div>
            <Typography css={getSupportTicketSummarySectionContentStyles} component="span" variant="body2">
                {children}
            </Typography>
        </li>
    );
}
