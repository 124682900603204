import { Client } from '@packlink/packlink-sdk';
import { SET_CLIENT_DATA } from '@store/actions/action-types';

export interface ISetClientAction {
    payload: Client;
    type: 'SET_CLIENT_DATA';
}

export type ClientAction = ISetClientAction;

export const setClient = (payload: Client): ISetClientAction => ({ payload, type: SET_CLIENT_DATA });
