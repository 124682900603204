import { useRef, useState, MouseEvent } from 'react';
import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import {
    ButtonColor,
    ButtonIcon,
    ButtonSize,
    ButtonVariant,
    DropdownOptionList,
    IDropdownOption,
    Option,
    Typography,
    WithCommonProps,
} from '@shipengine/giger';
import { useToggle } from '@packlink/utils';
import {
    getServiceSelectionCardStyles,
    getServiceSelectionCardLogoStyles,
    getServiceSelectionCardActionStyles,
    getServiceSelectionCardDescriptionStyles,
    getServiceSelectionCardLogoContainerStyles,
    getServiceSelectionCardOptionsStyles,
} from './ServiceSelectionCardStyles';
import { getServiceSelectionCardHeaderStyles } from './ServiceSelectionCardStyles';
import { OwnContractModelService } from '@packlink/packlink-sdk';

export enum ServiceSelectionOptionValue {
    EDIT_SERVICES = 'editServices',
    DEACTIVATE = 'deactivate',
}

export interface ServiceSelectionOption {
    value: ServiceSelectionOptionValue;
    label: string;
}
export interface ServiceSelectionCardProps {
    name: string;
    logoUrl?: string;
    selected: boolean;
    onClick: (response: ServiceSelectionOption) => void;
    services?: OwnContractModelService[];
}

export const ServiceSelectionCard: React.FC<WithCommonProps<ServiceSelectionCardProps>> = (
    props: ServiceSelectionCardProps,
): JSX.Element => {
    const { t } = useTranslation();

    const { name, logoUrl, selected, services, onClick, ...rest } = props;
    const [optionValue, setOptionValue] = useState<IDropdownOption<unknown>>();
    const { state, toggle } = useToggle(false);
    const buttonIconRef = useRef<HTMLButtonElement>(null);

    const selectOptions = [
        {
            label: t('own-contract.list.edit-services'),
            value: ServiceSelectionOptionValue.EDIT_SERVICES,
        },
        {
            label: t('own-contract.list.deactivate-services'),
            value: ServiceSelectionOptionValue.DEACTIVATE,
        },
    ];

    const handleClickAway = (event: MouseEvent) => {
        if (buttonIconRef.current?.contains(event.target as Node)) {
            return;
        }
        toggle();
    };

    const handleOnChange = (response: ServiceSelectionOption) => {
        onClick(response);
        toggle();
        setOptionValue(undefined);
    };

    const filterSelectedServices = (service: OwnContractModelService) => {
        return service.isSelected === true;
    };
    return (
        <article css={getServiceSelectionCardStyles(selected)} aria-selected={selected} role="tab" {...rest}>
            <header>
                <div css={getServiceSelectionCardHeaderStyles}>
                    {logoUrl && (
                        <div css={getServiceSelectionCardLogoContainerStyles}>
                            <img css={getServiceSelectionCardLogoStyles} alt={name} src={logoUrl} />
                        </div>
                    )}
                    <ButtonIcon
                        ref={buttonIconRef}
                        onClick={() => toggle()}
                        name="dropdown-button"
                        color={ButtonColor.PRIMARY}
                        ariaDescribedby="select-services-options"
                        variant={ButtonVariant.TEXT}
                        icon={IconNames.DOTS}
                        size={ButtonSize.SMALL}
                        data-id="select-services-options"
                    >
                        {t('own-contract.list.tooltip-open-dropdown')}
                    </ButtonIcon>
                    <DropdownOptionList
                        data-id="dropdown"
                        dropdownWidth="auto"
                        isOpen={state}
                        reference={buttonIconRef.current}
                        onChange={handleOnChange}
                        onClickAway={handleClickAway}
                    >
                        {selectOptions.map((item) => (
                            <Option
                                key={item.value}
                                selected={optionValue?.value === item.value}
                                value={item.value}
                                onClick={() => setOptionValue(item)}
                            >
                                <span
                                    css={getServiceSelectionCardOptionsStyles(
                                        item.value === ServiceSelectionOptionValue.DEACTIVATE,
                                    )}
                                >
                                    {item.label}
                                </span>
                            </Option>
                        ))}
                    </DropdownOptionList>
                </div>
                <Typography component="p" variant="small" css={getServiceSelectionCardDescriptionStyles()}>
                    {name}
                </Typography>
            </header>
            <footer css={getServiceSelectionCardActionStyles}>
                <Typography variant="small" bold>
                    {t('own-contract.list.services', { count: services?.filter(filterSelectedServices)?.length })}
                </Typography>
            </footer>
        </article>
    );
};
