import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

const BORDER_WIDTH = 4;

export const getFilterStyles =
    (isActive: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            borderBottom: `1px solid ${theme.palette.gray.ultraLight}`,
            borderLeft: isActive ? `${BORDER_WIDTH}px solid ${theme.palette.primary.dark}` : 'none',
        });

export const getFilterTitleStyles =
    (isActive: boolean) =>
    (theme: Theme): SerializedStyles => {
        const paddingLeftOffset = isActive ? BORDER_WIDTH : 0;

        return css({
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            padding: theme.spacing(2),
            justifyContent: 'space-between',
            paddingLeft: `${theme.spacing(2) - paddingLeftOffset}px`,
        });
    };

export const getFilterTitlePopoverStyles = (): SerializedStyles =>
    css({
        marginRight: 'auto',
    });

export const getFilterTitleIconStyles =
    (isContentVisible: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            marginLeft: 'auto',
            color: theme.palette.primary.main,

            ...(isContentVisible && {
                transform: 'rotate(-180deg)',
            }),
        });

export const getFilterTitleSpanStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(1),
    });

export const getFilterContentStyles =
    (isActive: boolean) =>
    (theme: Theme): SerializedStyles => {
        const paddingLeftOffset = isActive ? BORDER_WIDTH : 0;

        return css({
            paddingTop: 0,
            padding: theme.spacing(2),
            paddingLeft: `${theme.spacing(2) - paddingLeftOffset}px`,
        });
    };
