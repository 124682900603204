import { ButtonSize, Icon, Next, Typography } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { getCheckIconStyles, getTextStyles } from './OTPVerificationErrorStyles';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks';
import { useGetHelpUrl } from '@SupportCenter/hooks/useGetHelpUrl';
import { SupportTicketType } from '@packlink/packlink-sdk';
import { PhoneNumberVerificationErrorData } from './PhoneNumberVerificationPanel';
import { getFormattedTime } from '@utils/otpVerificationUtils';

export type OTPVerificationSuccessProps = {
    errorData: PhoneNumberVerificationErrorData;
};

export function OTPVerificationError({ errorData }: OTPVerificationSuccessProps): JSX.Element {
    const { t } = useTranslation();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const helpCenterUrl = useGetHelpUrl({ newTicketType: SupportTicketType.BILLING });
    const { remainingTimeInMs, key } = errorData;
    const onCreateTicket = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.CREATE_TICKET_VALIDATION);
    };

    const formattedRemainingTime = getFormattedTime(remainingTimeInMs);
    return (
        <>
            <SidePanelContent>
                <Icon css={getCheckIconStyles} name={IconNames.INFO} />

                <Typography css={getTextStyles} component="h5" variant="heading5">
                    {t('phone-verification.error.title')}
                </Typography>
                <Typography css={getTextStyles} component="p" variant="body1">
                    {t(key, {
                        minutes: t('phone-verification.common.minutes', {
                            count: formattedRemainingTime?.minutes ?? 0,
                        }),
                        seconds: t('phone-verification.common.seconds', {
                            count: formattedRemainingTime?.seconds ?? 0,
                        }),
                    })}
                </Typography>
            </SidePanelContent>

            <SidePanelFooter>
                <Next.Link
                    href={helpCenterUrl}
                    onClick={onCreateTicket}
                    isButton
                    buttonProps={{ size: ButtonSize.MEDIUM, isFullWidth: true }}
                    isExternal
                >
                    {t('error-page.button.create-ticket')}
                </Next.Link>
            </SidePanelFooter>
        </>
    );
}
