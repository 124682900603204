import { useQuery } from 'react-query';
import Packlink from '@sdk';
import { IShipment, Shipment, IPrice, IPriceProducts } from '@packlink/packlink-sdk';
import { useMemo } from 'react';

export function useBulkInsuranceBasePrice(shipments: Shipment<IShipment>[]) {
    const query = useQuery({
        queryKey: ['bulkInsurancePrice'],
        queryFn: () => Packlink.pro.shipments.price.get(shipments),
    });

    const bulkInsurancePrice = useMemo(() => {
        const pricesByRef: Record<string, IPriceProducts | undefined> = {};
        query?.data?.shipmentsPriceDetails.forEach(
            (shipmentPrice: IPrice) =>
                (pricesByRef[shipmentPrice.packlinkReference as string] = shipmentPrice.availableProducts),
        );

        return shipments.reduce((bulkInsurancePrice: number, shipment: Shipment<IShipment>) => {
            const currentContentValue = shipment.content?.value ?? 0;
            const insurance = pricesByRef[shipment.packlinkReference]?.insurance;
            const proofOfDelivery = pricesByRef[shipment.packlinkReference]?.proofOfDelivery;
            const baseCoverage = insurance?.baseCoverage ?? 0;
            const maxCoverage = insurance?.maxCoverage ?? 0;

            if (currentContentValue > baseCoverage && baseCoverage < maxCoverage) {
                bulkInsurancePrice += (insurance?.basePrice ?? 0) + (proofOfDelivery?.basePrice ?? 0);
            }

            return bulkInsurancePrice;
        }, 0);
    }, [query.data, shipments]);

    return bulkInsurancePrice;
}
