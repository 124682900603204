import { AmplitudeEventsPro } from '@constants/amplitude';
import { apiClient } from '@sdk';
import { ConfigurationRepository } from '@packlink/packlink-sdk';
import { useAmplitude } from '@hooks/useAmplitude';

interface IUseDeactivate {
    deactivateIntegration: (integrationAlias: string, identifier?: string) => Promise<string | void>;
}

const configurationRepository = new ConfigurationRepository(apiClient);

export const useDeactivate = (event: AmplitudeEventsPro): IUseDeactivate => {
    const { sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();
    const deactivateIntegration = (integrationAlias: string, identifier?: string): Promise<void> => {
        sendSuffixedAmplitudeEvent(sendAmplitudeClickEvent, integrationAlias)(event);

        return configurationRepository.deleteConfiguration(identifier || '');
    };

    return { deactivateIntegration };
};
