import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { IconNames } from '@shipengine/giger-theme';
import {
    Button,
    ButtonSize,
    ButtonVariant,
    FormField,
    Icon,
    IconSize,
    SelectAutocomplete,
    Typography,
} from '@shipengine/giger';
import { IFormRow, IRuleForm, IShippingRuleOption } from '../../types/formTypes';
import { getConditionFieldName, getFormFieldMessage } from '../../utils/form';
import { EMPTY_CONDITION } from '../../constants';
import { useFormData } from '../../hooks/useFormData';
import { ValueField } from '../ValueField/ValueField';
import { fieldStyles, formSectionStyles, ruleRowStyles } from '../RuleForm/RuleFormStyles';
import { buttonIconStyles, textConditionStyles } from './RuleConditionStyles';

export function RuleCondition({ isFormBlocked = false }: IFormRow): JSX.Element {
    const { t } = useTranslation();
    const { conditionOptions, operatorsByConditionIdentifier } = useFormData();
    const { handleChange, setFieldValue, values, errors, touched } = useFormikContext<IRuleForm>();

    const handleSelectorChange = (name: string, value: IShippingRuleOption | null) => {
        handleChange({ target: { name: name, value } });
    };

    const handleIdentifierChange = (name: string, value: IShippingRuleOption | null, index: number) => {
        setFieldValue(getConditionFieldName(index, 'operation'), { value: '', label: '' });
        setFieldValue(getConditionFieldName(index, 'value'), { value: '', label: '' });
        handleSelectorChange(name, value ?? { value: '', label: '' });
    };

    const handleOperationChange = (name: string, value: IShippingRuleOption | null) => {
        handleSelectorChange(name, value);
    };

    return (
        <div css={formSectionStyles}>
            <Typography variant="subtitle1" component="p">
                {t('shipping-rules.form.condition')}
            </Typography>

            <FieldArray
                name="conditions"
                render={(arrayProps: FieldArrayRenderProps) => (
                    <>
                        {values.conditions.map((condition, index) => (
                            <div css={ruleRowStyles} key={index}>
                                {index === 0 && (
                                    <Typography css={textConditionStyles(values.conditions.length)} variant="small">
                                        {t('shipping-rules.form.if')}
                                    </Typography>
                                )}

                                {index !== 0 && (
                                    <Typography css={textConditionStyles(values.conditions.length)} variant="small">
                                        {t('shipping-rules.form.and')}
                                    </Typography>
                                )}

                                <div css={fieldStyles}>
                                    <FormField
                                        message={getFormFieldMessage(
                                            getConditionFieldName(index, 'identifier'),
                                            errors,
                                            touched,
                                        )}
                                    >
                                        <SelectAutocomplete
                                            name={getConditionFieldName(index, 'identifier')}
                                            options={conditionOptions}
                                            placeholder={t('shipping-rules.form.select-property')}
                                            label={t('shipping-rules.form.select-property')}
                                            showLabel={false}
                                            onChange={(...args) => handleIdentifierChange(...args, index)}
                                            value={condition.identifier}
                                            noResultsLabel={t('autocomplete.messages.no-results-found')}
                                            disabled={isFormBlocked}
                                            isInvalid={
                                                !!getFormFieldMessage(
                                                    getConditionFieldName(index, 'identifier'),
                                                    errors,
                                                    touched,
                                                )
                                            }
                                        />
                                    </FormField>
                                </div>

                                <div css={fieldStyles}>
                                    <FormField
                                        message={getFormFieldMessage(
                                            getConditionFieldName(index, 'operation'),
                                            errors,
                                            touched,
                                        )}
                                    >
                                        <SelectAutocomplete
                                            name={getConditionFieldName(index, 'operation')}
                                            options={operatorsByConditionIdentifier[condition.identifier.value] || []}
                                            placeholder={t('shipping-rules.form.select-property')}
                                            label={t('shipping-rules.form.select-property')}
                                            showLabel={false}
                                            onChange={handleOperationChange}
                                            value={condition.operation}
                                            disabled={!condition.identifier.value || isFormBlocked}
                                            noResultsLabel={t('autocomplete.messages.no-results-found')}
                                            isInvalid={
                                                !!getFormFieldMessage(
                                                    getConditionFieldName(index, 'operation'),
                                                    errors,
                                                    touched,
                                                )
                                            }
                                        />
                                    </FormField>
                                </div>

                                <div css={fieldStyles}>
                                    <ValueField
                                        item={condition}
                                        fieldPrefix={getConditionFieldName(index)}
                                        identifier={condition.identifier.value}
                                        disabled={!condition.operation.value || isFormBlocked}
                                    />
                                </div>

                                {values.conditions.length > 1 && (
                                    <Button
                                        css={buttonIconStyles}
                                        variant={ButtonVariant.TEXT}
                                        size={ButtonSize.SMALL}
                                        onClick={() => arrayProps.remove(index)}
                                    >
                                        <Icon name={IconNames.TRASH} size={IconSize.SIZE_SMALL} />
                                    </Button>
                                )}
                            </div>
                        ))}

                        <Button
                            variant={ButtonVariant.TEXT}
                            size={ButtonSize.SMALL}
                            disabled={isFormBlocked}
                            onClick={() => arrayProps.push(EMPTY_CONDITION)}
                        >
                            <Icon name={IconNames.ADD} size={IconSize.SIZE_SMALL} />
                            <span>{t('shipping-rules.form.add-condition')}</span>
                        </Button>
                    </>
                )}
            />
        </div>
    );
}
