import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        height: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        padding: `0 ${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
        borderBottom: `1px solid ${theme.palette.gray.ultraLight}`,
        backgroundColor: theme.palette.white,
    });

export const getBackButtonStyles = (): SerializedStyles =>
    css({
        marginRight: 'auto',
    });

export const getIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
    });
