import { MouseEvent, useCallback, useState } from 'react';
import moment, { Moment } from 'moment';
import { Currency, Service } from '@packlink/packlink-sdk';
import { IconNames } from '@shipengine/giger-theme';
import { Button, ButtonSize, ButtonVariant, Icon, IconSize, Radio, Typography } from '@shipengine/giger';
import { IServiceCardProps } from '@packlink/service-card';
import { useTranslation } from '@packlink/translation-provider';

import {
    getRouteIconStyles,
    getCompactServiceCardWrapperStyles,
    getLogoStyles,
    getPriceContainerStyles,
    getCompactCardContentStyles,
    getOwnContractStyles,
    getRoutePointStyles,
    getCompactServiceCardStyles,
    getCompactServiceCardHeaderStyles,
    getRouteTitleStyles,
    getRouteStyles,
    getCompactServiceCardLinkStyles,
    getDetailsStyles,
    getCompactSericeCardChildrenStyles,
    getRadioStyles,
    getTransitStyles,
    getTitleStyles,
    getChevronStyles,
    getFooterStyles,
    getFeeStyles,
    getDetailsButtonStyles,
    getPriceStyles,
} from './CompactServiceCardStyles';

export interface ICompactServiceCardProps extends Omit<IServiceCardProps, 'translate'> {
    serviceId: number;
    selectedServiceId?: number;
    isShortVariant?: boolean;
    service: Service;
    onSelect?: (e: MouseEvent<HTMLButtonElement>) => void;
}

const HOURS_LIMIT = 48;

export const CompactServiceCard = ({
    serviceName,
    logo,
    details,
    fallbackLogo,
    price,
    taxPrice,
    products,
    serviceId,
    selectedServiceId,
    isOwnContract,
    isShortVariant = false,
    from,
    to,
    transit,
    onSelect,
    onDetailsRequest,
    onDropOffPointsRequest,
}: ICompactServiceCardProps): JSX.Element => {
    const { t } = useTranslation();
    const [carrierLogo, setCarrierLogo] = useState<string>(logo);
    const [detailsState, setDetails] = useState(details);
    const [isDetailsVisible, setIsDetailsVisible] = useState<boolean>(false);

    const { duration, guaranteed } = transit;

    const showTaxInfo = taxPrice !== 0;
    const managementFee = Currency.format(products?.managementFee?.basePrice ?? 0, products?.managementFee?.currency);
    const showDurationInDays = duration > HOURS_LIMIT;
    const transitUnit = t(`service-card.date.${showDurationInDays ? 'days' : 'hours'}`);
    const transitType = t(`service-card.transit.${guaranteed ? 'guaranteed' : 'estimated'}`);
    const displayedDuration = showDurationInDays ? Math.round(duration / 24) : duration;

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            onSelect?.(e as unknown as MouseEvent<HTMLButtonElement>);
        },
        [onSelect],
    );

    const onLogoError = useCallback((): void => {
        if (fallbackLogo) {
            setCarrierLogo(fallbackLogo);
        }
    }, [fallbackLogo]);

    const handleToggleDropOffPoints = useCallback(
        (event: MouseEvent): void => {
            event.preventDefault();
            onDropOffPointsRequest?.();
        },
        [onDropOffPointsRequest],
    );

    const handleDetailsRequest = useCallback(
        async (event: MouseEvent): Promise<void> => {
            event.preventDefault();
            if (onDetailsRequest && !detailsState) {
                setDetails(await onDetailsRequest());
            }
            setIsDetailsVisible((isDetailsVisible: boolean) => !isDetailsVisible);
        },
        [detailsState, onDetailsRequest],
    );

    return (
        <article css={getCompactServiceCardStyles(selectedServiceId === serviceId)}>
            <div css={getCompactServiceCardWrapperStyles}>
                <Radio
                    css={getRadioStyles}
                    name="service"
                    value={serviceId}
                    checked={selectedServiceId === serviceId}
                    onChange={onChange}
                >
                    <div css={getCompactSericeCardChildrenStyles}>
                        <img alt={serviceName} css={getLogoStyles} src={carrierLogo} onError={onLogoError} />
                        <div css={getCompactServiceCardHeaderStyles(isShortVariant)}>
                            <Typography css={getTitleStyles} variant="small" component="h3" bold>
                                {serviceName}
                            </Typography>
                            {!isShortVariant && (
                                <Typography css={getTransitStyles} variant="small">
                                    {`${displayedDuration} ${transitUnit} ${transitType}`}
                                </Typography>
                            )}
                        </div>
                    </div>
                </Radio>

                <section css={getCompactCardContentStyles}>
                    <div css={getRouteStyles}>
                        <RouteType
                            title={t(`service-card.collection-type.${from.dropOff ? 'drop-off' : 'collection'}`)}
                            date={from.date}
                            isDropOff={from.dropOff}
                            isShortVariant={isShortVariant}
                            toggleDropOffPoints={handleToggleDropOffPoints}
                        />

                        <Icon css={getRouteIconStyles} name={IconNames.ARROW_RIGHT} size={IconSize.SIZE_SMALL} />

                        <RouteType
                            title={t(`service-card.delivery-type.${to.dropOff ? 'pick-up' : 'delivery'}`)}
                            date={to.date}
                            isShortVariant={isShortVariant}
                        />
                    </div>
                    {isOwnContract && (
                        <span css={getOwnContractStyles}>
                            <Icon size={IconSize.SIZE_SMALL} name={IconNames.CONTRACT} />
                        </span>
                    )}
                </section>

                <section css={[getCompactCardContentStyles, getFooterStyles]}>
                    <Button
                        css={getDetailsButtonStyles}
                        variant={ButtonVariant.TEXT}
                        size={ButtonSize.SMALL}
                        onClick={handleDetailsRequest}
                    >
                        {t('service-card.details.see-details')}
                        <Icon
                            css={getChevronStyles}
                            name={isDetailsVisible ? IconNames.CHEVRON_TOP : IconNames.CHEVRON_BOTTOM}
                            size={IconSize.SIZE_SMALL}
                        />
                    </Button>

                    <div css={getPriceContainerStyles}>
                        {!isShortVariant && (
                            <div css={getPriceStyles}>
                                <Typography variant="body2" bold>
                                    {price}
                                </Typography>
                                {showTaxInfo && (
                                    <Typography variant="small" component="span">
                                        &nbsp;{t('service-card.price.tax-info')}
                                    </Typography>
                                )}
                            </div>
                        )}

                        {products?.managementFee && (
                            <Typography css={getFeeStyles} variant="small" component="span">
                                {t('service-card.price.management-fee-info', { amount: managementFee })}
                            </Typography>
                        )}
                    </div>
                </section>
            </div>

            {detailsState && isDetailsVisible && <div css={getDetailsStyles}>{detailsState}</div>}
        </article>
    );
};

function RouteType({
    title,
    isShortVariant,
    date,
    isDropOff,
    toggleDropOffPoints,
}: {
    title: string;
    isShortVariant: boolean;
    date: string | Moment;
    isDropOff?: boolean;
    toggleDropOffPoints?: (event: MouseEvent) => void;
}) {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const momentDate = typeof date === 'string' ? moment(date, 'YYYY/MM/DD') : date;

    return (
        <div css={getRoutePointStyles}>
            <Typography css={getRouteTitleStyles} variant="small" component="h3" bold>
                {title}
            </Typography>

            {!isShortVariant && (
                <>
                    {isDropOff ? (
                        <Typography
                            css={getCompactServiceCardLinkStyles}
                            variant="small"
                            component="a"
                            href="#"
                            role="button"
                            onClick={toggleDropOffPoints}
                        >
                            {t('service-card.drop-off.see-map')}
                        </Typography>
                    ) : (
                        <Typography variant="small" component="time" dateTime={momentDate.format('YYYY-MM-DD HH:mm')}>
                            {Intl.DateTimeFormat(locale, { weekday: 'long', month: 'short', day: '2-digit' }).format(
                                momentDate.toDate(),
                            )}
                        </Typography>
                    )}
                </>
            )}
        </div>
    );
}
