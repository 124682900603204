import { IconNames } from '@shipengine/giger-theme';
import { Typography, Icon, WithCommonProps } from '@shipengine/giger';
import { IShipment, ProShipment } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import {
    getShipmentDetailsHeaderIcon,
    getShipmentDetailsHeaderReceiverStyles,
    getShipmentDetailsHeaderStatusStyles,
    getShipmentDetailsHeaderStyles,
    getShipmentDetailsHeaderTitleStyles,
} from './ShipmentDetailsHeaderStyles';

type ShipmentDetailsHeaderProps = WithCommonProps<{
    shipment: ProShipment<IShipment>;
}>;

export function ShipmentDetailsHeader({ shipment, ...props }: ShipmentDetailsHeaderProps): JSX.Element {
    const { t } = useTranslation();
    const receiverName = `${shipment.data.to?.firstName} ${shipment.data.to?.lastName}`;
    const shipmentState = shipment.data.state;
    const shipmentStatus = {
        label: ShipmentUtils.getFrontendStatus(shipmentState),
        icon: ShipmentUtils.getIconByState(shipmentState) as IconNames,
    };
    return (
        <header css={getShipmentDetailsHeaderStyles} {...props}>
            <Typography css={getShipmentDetailsHeaderTitleStyles} variant="heading2" component="h1">
                {t('post-sale.header.delivery-to')}{' '}
                <span css={getShipmentDetailsHeaderReceiverStyles}>{receiverName}</span>
            </Typography>
            <Typography component="h2" variant="heading3" css={getShipmentDetailsHeaderStatusStyles(shipmentState)}>
                <Icon css={getShipmentDetailsHeaderIcon} name={shipmentStatus.icon} />
                &nbsp;{t(shipmentStatus.label)}
            </Typography>
        </header>
    );
}
