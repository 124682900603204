import { ProviderName } from '@packlink/metrics';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BeamerEditableProps } from '@packlink/metrics';
import { TenantPlatform } from '@packlink/packlink-sdk';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { getClientData } from '@store/selectors/client';
import { getPaymentMethods } from '@store/selectors/payment';
import { ITenantConfig } from '@types';
import { TenantUtils } from '@utils/tenant';
import { useMetrics } from '@providers/MetricsProvider';
import { useIntegrations } from '@common/hooks/useIntegrations';

export function useBeamer(): void {
    const client = useSelector(getClientData);
    const tenantName = TenantUtils.getTenantName();
    const { connectedIntegrationNames } = useIntegrations();
    const paymentMethods = useSelector(getPaymentMethods);
    const { brand } = useTenantConfig<ITenantConfig>();
    const metrics = useMetrics();

    useEffect(() => {
        if (!tenantName || !config.beamer?.id || !metrics) return;

        const paymentMethodsList = paymentMethods.map((payment) => `${payment.method}-${payment.type}`);
        const filters = [TenantPlatform[tenantName], tenantName, ...paymentMethodsList];

        const beamerUserConfig: BeamerEditableProps = {
            user_id: client.id,
            user_firstname: client.companyName || client.firstName || 'User',
            user_lastname: ' ', // Resets the autogenerated lastname by Beamer
            language: client.language?.slice(0, 2).toUpperCase(),
            filter: filters.join(';'),
            user_created_at: client.createdAt,
            tenant: tenantName,
            platform: TenantPlatform[tenantName],
            theme: brand,
        };
        const beamerConfig = {
            ...beamerUserConfig,
            selector: 'beamerButton',
            display: 'popup',
            button: false,
        };

        metrics.addProvider({
            key: config.beamer.id,
            name: ProviderName.BEAMER,
            configuration: beamerConfig,
        });
    }, [
        brand,
        client.companyName,
        client.createdAt,
        client.firstName,
        client.id,
        client.language,
        metrics,
        paymentMethods,
        tenantName,
    ]);

    useEffect(() => {
        if (!connectedIntegrationNames?.length || !window.beamer_config || !metrics) return;

        const newIntegrationsList = connectedIntegrationNames
            .map((name) => `integration-${name}`)
            .filter((name) => !window.beamer_config.filter?.includes(name))
            .join(';');

        if (!newIntegrationsList) return;

        metrics.setUserProperties({
            providerName: ProviderName.BEAMER,
            properties: [{ key: 'filter', value: `${window.beamer_config.filter};${newIntegrationsList}` }],
        });
    }, [connectedIntegrationNames, metrics]);
}
