import { useTranslation } from '@packlink/translation-provider';
import { InlineNotification, NotificationType } from '@shipengine/giger';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { ITenantConfig } from '@types';

export const BuyerAddressDisclaimer = (): JSX.Element => {
    const { t } = useTranslation();
    const { showBuyerAddressDisclaimer } = useTenantConfig<ITenantConfig>();

    if (!showBuyerAddressDisclaimer) {
        return <></>;
    }
    return (
        <div className="location-explanation">
            <InlineNotification type={NotificationType.INFO}>{t('address-form.section.disclaimer')}</InlineNotification>
        </div>
    );
};
