import { Trans } from '@packlink/translation-provider';
import { ClientFeature, SubscriptionPlanFeatures } from '@packlink/packlink-sdk';
import { useTenantBehavior } from '@packlink/tenant-config-provider';
import { AvailablePremiumCarriersConfigProps } from '@types';
import { TenantUtils } from '@utils/tenant';
import { PlanFeatures } from '../PlanFeatures/PlanFeatures';
import { getExcludedServicesStyles, getExclusiveRatesWrapperStyles } from './PlusPlanFeaturesStyles';
import { getExcludedServices } from '@common/hooks/usePlanFeatures';

interface PlusPlanFeaturesProps {
    showDisclaimers?: boolean;
    availableFeatures: SubscriptionPlanFeatures;
    carriersValues?: { carriers?: string };
}

export function PlusPlanFeatures({
    carriersValues = { carriers: '' },
    showDisclaimers = true,
    availableFeatures,
}: PlusPlanFeaturesProps): JSX.Element {
    const getTenantBehavior = useTenantBehavior();
    const premiumCarriers =
        getTenantBehavior<AvailablePremiumCarriersConfigProps>('AvailablePremiumCarriers')?.properties.carriers;

    const showPremiumCarriers = premiumCarriers && premiumCarriers.length > 0;
    const showExclusivePricesCopy = getTenantBehavior<{ enabled: boolean }>('ShowExclusivePrices')?.properties.enabled;
    const excludedServicesFromExclusivePrices = getExcludedServices()[TenantUtils.getTenantName()];
    const cashOnDeliveryServices = getTenantBehavior<{ enabled: boolean }>('CashOnDeliveryServices')?.properties
        .enabled;

    return (
        <PlanFeatures
            features={[
                showExclusivePricesCopy && (
                    <div css={getExclusiveRatesWrapperStyles} key="feature1">
                        <span>
                            <Trans i18nKey="subscriptions.feature-list.exclusive-prices" />
                        </span>
                        {excludedServicesFromExclusivePrices && (
                            <span css={getExcludedServicesStyles}>
                                <Trans
                                    variant="body1"
                                    i18nKey={`subscription.feature-list.excluded-services`}
                                    values={{ services: excludedServicesFromExclusivePrices?.join(', ') }}
                                />
                            </span>
                        )}
                    </div>
                ),
                availableFeatures[ClientFeature.VIP_SUPPORT] && (
                    <Trans key="feature2" i18nKey="subscriptions.feature-list.phone-support" />
                ),
                showPremiumCarriers && (
                    <Trans
                        key="feature3"
                        i18nKey="subscriptions.feature-list.premium-carriers"
                        values={{ carriers: premiumCarriers.join(', ') }}
                    />
                ),
                cashOnDeliveryServices && (
                    <Trans key="feature4" i18nKey="subscriptions.feature-list.cash-on-delivery-services" />
                ),
                availableFeatures[ClientFeature.SKIP_MANAGEMENT_FEE] && (
                    <Trans key="feature5" i18nKey="subscriptions.feature-list.no-fee" />
                ),
                <Trans key="feature6" i18nKey="subscriptions.feature-list.priority-claim" />,
                availableFeatures[ClientFeature.BRANDED_EMAILS] && (
                    <Trans key="feature7" i18nKey="subscriptions.feature-list.branded-emails" />
                ),
                availableFeatures[ClientFeature.OWN_CONTRACT] && (
                    <>
                        <Trans
                            key="feature8"
                            i18nKey="subscriptions.feature-list.own-carrier"
                            values={carriersValues}
                        />
                        {showDisclaimers && '*'}
                    </>
                ),
                <Trans key="feature9" i18nKey="subscriptions.feature-list.unlimited-integrations" />,
                <Trans key="feature10" i18nKey="subscriptions.feature-list.unlimited-shipping-rules" />,
                <Trans key="feature11" i18nKey="subscriptions.feature-list.analytics" />,
            ].filter(Boolean)}
            threeBoldChildren
        />
    );
}
