import { useDispatch } from 'react-redux';

import { ShipmentStatus } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonSize, ButtonVariant } from '@shipengine/giger';

import { AppDispatch } from '@store';
import { BulkDelete } from '@components/BulkDelete/BulkDelete';
import { ShipmentPanelType } from '@types';
import { AmplitudeEvents } from '@constants/amplitude';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { addSelectedShipment } from '@store/actions/bulk';

import { BulkFlows } from '../ShipmentPanel/ShipmentPanelBulk/typings';

import { buttonTextStyles, getShipmentActionCellStyles } from './ShipmentRowActionsCellStyles';
import { getShipmentRowCellStyles } from './ShipmentRowStyles';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IShipmentRowActionsCell {
    shipmentReference: string;
    canPrintLabels: boolean;
    hasTrackingDetails: boolean;
    isComplete: boolean;
    isDraft: boolean;
    isCanceled: boolean;
    shipmentState: string;
    onComplete: () => void;
    onDetails: () => void;
    onDelete: () => void;
    onPrintLabels: () => void;
    onTrackingDetails: () => void;
}

export const ShipmentRowActionsCell = ({
    shipmentReference,
    canPrintLabels,
    isComplete,
    isDraft,
    isCanceled,
    shipmentState,
    onComplete,
    onDetails,
    onDelete,
    onPrintLabels,
    hasTrackingDetails,
    onTrackingDetails,
}: IShipmentRowActionsCell): JSX.Element => {
    const { openPanel } = useShipmentPanel();
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { sendAmplitudeTableViewClickEvent } = useAmplitude();

    const canBePaid = isComplete || shipmentState === ShipmentStatus.READY_TO_PURCHASE;

    const handlePay = (): void => {
        dispatch(addSelectedShipment(shipmentReference));
        openPanel(ShipmentPanelType.BULK, undefined, { flow: BulkFlows.PAYMENT });
        sendAmplitudeTableViewClickEvent(AmplitudeEvents.PAY);
    };

    return (
        <div css={[getShipmentRowCellStyles(false), getShipmentActionCellStyles]} data-id="shipment-row-cell">
            {isDraft && (
                <Button
                    isFullWidth={true}
                    size={ButtonSize.SMALL}
                    onClick={canBePaid ? handlePay : onComplete}
                    data-id={canBePaid ? 'shipment-row-pay-button' : 'shipment-row-complete-button'}
                >
                    {canBePaid ? t('shipment-row.actions.book') : t('shipment-row.actions.complete')}
                </Button>
            )}
            {!isDraft && canPrintLabels && (
                <Button
                    isFullWidth={true}
                    size={ButtonSize.SMALL}
                    onClick={onPrintLabels}
                    data-id="shipment-row-print-label-button"
                >
                    {t('shipment-row.actions.print-label')}
                </Button>
            )}

            {isDraft && (
                <BulkDelete
                    css={buttonTextStyles}
                    disabled={false}
                    buttonVariant={ButtonVariant.TEXT}
                    placement="bottom-end"
                    onDeleting={onDelete}
                    numberSelectedShipments={1}
                />
            )}
            {!isDraft && !isCanceled && hasTrackingDetails && (
                <Button size={ButtonSize.SMALL} onClick={onTrackingDetails} data-id="shipment-row-see-tracking-button">
                    {t('shipment-row.actions.see-tracking')}
                </Button>
            )}

            {!isDraft && (
                <Button
                    css={buttonTextStyles}
                    variant={ButtonVariant.TEXT}
                    size={ButtonSize.SMALL}
                    onClick={onDetails}
                    data-id="shipment-row-see-details-button"
                >
                    {t('shipment-row.actions.see-details')}
                </Button>
            )}
        </div>
    );
};
