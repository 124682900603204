import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getImportOrdersModaContentStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
    });

export const getImportOrdersImageStyles = (): SerializedStyles =>
    css({
        maxWidth: 380,
        width: '100%',
    });

export const getImportOrdersResultImageStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
        maxWidth: 380,
        width: '100%',
    });
