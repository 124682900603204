/**
 * Triggers the download of the sent URL.
 * @param {string} url - The URL for the download.
 */
export const triggerDownload = (url: string): void => {
    const downloadLink = createInvisibleAnchor(url);
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
};

function createInvisibleAnchor(url: string): HTMLAnchorElement {
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', url);
    downloadLink.style.display = 'none';
    return downloadLink;
}
