import { FormikErrors, FormikTouched, getIn } from 'formik';
import { FieldMessageType, ISelectAutocompleteOption } from '@shipengine/giger';
import { getComposedFieldName } from '@packlink/utils';
import { IRuleForm } from '../types/formTypes';

export const getConditionFieldName = (index: number, name?: string): string =>
    getComposedFieldName('conditions', index.toString(), name);

export const getActionFieldName = (index: number, name?: string): string =>
    getComposedFieldName('actions', index.toString(), name);

export const getFormFieldMessage = (
    field: string,
    errors: FormikErrors<IRuleForm>,
    touched: FormikTouched<IRuleForm>,
    value?: ISelectAutocompleteOption,
) => {
    if (getIn(errors, field)?.value && getIn(touched, field)?.value) {
        return {
            type: FieldMessageType.ERROR,
            content: getIn(errors, field).value as string,
        };
    } else if (value?.sublabel) {
        return {
            type: FieldMessageType.HINT,
            content: value.sublabel,
        };
    }
    return undefined;
};
