import { useTheme } from '@emotion/react';
import { ForwardedRef, forwardRef, useState } from 'react';

import { useMedia } from '@packlink/utils';
import { ButtonIcon, ButtonSize, ButtonVariant, Spinner, SpinnerSize, Typography } from '@shipengine/giger';

import {
    getChartContainerStyles,
    getChartHeadingStyles,
    getChartIconsStyles,
    getChartSideStyle,
    getChartStyles,
    getChartSubtitleStyles,
    getChartTitleExtraStyles,
    getChartTitleStyles,
} from './ChartContainerStyles';

import { DoubleChart } from './DoubleChart';
import { ChartStateEmpty } from './ChartStateEmpty';
import { ChartStateError } from './ChartStateError';
import { ChartContainerProps, ChartStatus, DEFAULT_HEIGHT, Side } from '../../types';

export const ChartContainer = forwardRef(
    (
        {
            title,
            subtitle,
            height = DEFAULT_HEIGHT,
            status,
            cdnUrl,
            leftComponent,
            rightComponent,
            leftIcon,
            rightIcon,
            leftIconTooltip,
            rightIconTooltip,
            titleExtra,
            children,
            ...rest
        }: ChartContainerProps,
        ref: ForwardedRef<HTMLElement>,
    ): JSX.Element => {
        const [sideShown, setSideShown] = useState(Side.LEFT);
        const theme = useTheme();
        const isMobile = useMedia(`(max-width: ${theme.breakPoints.tablet}px)`);

        const onIconClick = (side: Side) => {
            setSideShown(side);
        };

        return (
            <article ref={ref} css={getChartContainerStyles} {...rest}>
                <div css={getChartHeadingStyles}>
                    <Typography component="h3" variant="body2" bold css={getChartTitleStyles}>
                        {title}
                        <div css={getChartTitleExtraStyles}>{titleExtra}</div>
                    </Typography>
                    <Typography component="p" variant="body2" css={getChartSubtitleStyles}>
                        {/* We want to preserve the subtitle spacing so the box doesn't flick between states */}
                        {status === ChartStatus.SUCCESS ? subtitle : <>&nbsp;</>}
                    </Typography>
                    {status === ChartStatus.SUCCESS && leftIcon && rightIcon && isMobile && (
                        <div css={getChartIconsStyles}>
                            <ButtonIcon
                                variant={ButtonVariant.TEXT}
                                icon={leftIcon}
                                size={ButtonSize.SMALL}
                                onClick={() => onIconClick(Side.LEFT)}
                                ariaDescribedby="show left"
                                css={getChartSideStyle(sideShown === Side.LEFT)}
                            >
                                {leftIconTooltip}
                            </ButtonIcon>
                            <ButtonIcon
                                variant={ButtonVariant.TEXT}
                                icon={rightIcon}
                                size={ButtonSize.SMALL}
                                onClick={() => onIconClick(Side.RIGHT)}
                                ariaDescribedby="show right"
                                css={getChartSideStyle(sideShown === Side.RIGHT)}
                            >
                                {rightIconTooltip}
                            </ButtonIcon>
                        </div>
                    )}
                </div>

                <div css={getChartStyles(height)}>
                    {status === ChartStatus.SUCCESS &&
                        (children || (
                            <DoubleChart
                                leftComponent={leftComponent}
                                rightComponent={rightComponent}
                                height={height}
                                side={sideShown}
                                isMobile={isMobile}
                            />
                        ))}
                    {status === ChartStatus.LOADING && <Spinner size={SpinnerSize.SIZE_LARGE} />}
                    {status === ChartStatus.EMPTY && <ChartStateEmpty cdnUrl={cdnUrl} />}
                    {status === ChartStatus.ERROR && <ChartStateError cdnUrl={cdnUrl} />}
                </div>
            </article>
        );
    },
);
ChartContainer.displayName = 'ChartContainer';
