import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getPaymentSummaryTotalStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
        color: theme.palette.gray.dark,
        fontWeight: theme.typography.fontWeight.semibold,
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
    });

export const getPaymentSummaryTotalTextStyles = (): SerializedStyles =>
    css({
        flexGrow: 1,
    });

export const getPaymentSummaryTotalAmountStyles = (): SerializedStyles =>
    css({
        alignSelf: 'flex-end',
    });

export const getPaymentSummaryNumberStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
        color: theme.palette.gray.dark,
        fontWeight: theme.typography.fontWeight.semibold,
        margin: 0,
        borderBottom: `${theme.palette.gray.light} 1px solid`,
    });

export const getPaymentSummaryFooterStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'sticky',
        bottom: 0,
        backgroundColor: theme.palette.white,
        boxShadow: theme.elevations.above.boxShadow,
    });

export const getPaymentSummaryHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.white,
    });
