import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const settingsPageStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(4),
    });

export const settingsPageBodyStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(4),
        paddingTop: theme.spacing(),
    });

export const settingsPageHeaderStyles =
    (hasDescription: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            flexDirection: 'column',
            marginBottom: hasDescription ? theme.spacing(4) : 0,
            '> *': {
                marginBottom: theme.spacing(),
            },
        });

export const settingsPageTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
        color: theme.palette.secondary.main,
        fontWeight: 'normal',

        '.btn': {
            marginLeft: theme.spacing(),
        },
    });

export const subscriptionPageHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.gray.ultraLight,
        padding: theme.spacing(4),
        marginBottom: theme.spacing(2),
    });

export const subscriptionPageHeaderTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
    });

export const subscriptionPageLockedStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        border: `1px solid ${theme.palette.black}`,
        borderRadius: theme.borderRadius.S,
        maxWidth: 'fit-content',
        alignItems: 'center',
        cursor: 'pointer',
        padding: theme.spacing(1 / 2),
        marginBottom: theme.spacing(1),
    });

export const subscriptionPageLockedCaptionStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `0px ${theme.spacing(1 / 2)}px`,
        fontSize: theme.typography.fontSize.XS,
    });
