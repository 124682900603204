import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getChartContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        ...theme.elevations.raised,
        position: 'relative',
        borderRadius: theme.borderRadius.S,
        backgroundColor: theme.palette.white,
        padding: theme.spacing(2),
    });

export const getChartHeadingStyles = (): SerializedStyles =>
    css({
        position: 'relative',
    });

export const getChartStyles = (height: number): SerializedStyles =>
    css({
        height: `${height}px`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    });

export const getChartTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.black,
        margin: `0 0 ${theme.spacing()}px 0`,
    });

export const getChartTitleExtraStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'inline-block',
        marginLeft: theme.spacing(),
    });

export const getChartSubtitleStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `0 0 ${theme.spacing(3)}px 0`,
    });

export const getChartIconsStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'absolute',
        right: 0,
        top: 0,
        margin: `${theme.spacing()}px ${theme.spacing()}px 0 0`,
    });

export const getChartSideStyle =
    (active: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            color: active ? theme.palette.primary.main : theme.palette.gray.main,
        });

export const getSkeletonIconStyles = (theme: Theme) =>
    css({
        color: theme.palette.gray.main,
    });
