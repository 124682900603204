import { Typography, WithChildrenCommonProps } from '@shipengine/giger';
import { getSupportCenterCardStyles } from '@SupportCenter/SupportCenterStyles';
import { getTicketDetailsSectionPadding } from '../../../../TicketDetailsStyles';
import {
    getTicketDataSectionListStyles,
    getTicketDataSectionStyles,
    getTicketDataSectionTitleStyles,
} from './TicketDataSectionStyles';

type TicketDataSectionProps = WithChildrenCommonProps<{
    title: React.ReactNode;
    isLoading?: boolean;
}>;

export function TicketDataSection({ title, children, ...props }: TicketDataSectionProps): JSX.Element {
    return (
        <section
            css={[getTicketDetailsSectionPadding, getSupportCenterCardStyles, getTicketDataSectionStyles]}
            {...props}
        >
            <Typography component="h3" css={getTicketDataSectionTitleStyles} variant="subtitle1">
                {title}
            </Typography>
            <ul css={getTicketDataSectionListStyles}>{children}</ul>
        </section>
    );
}
