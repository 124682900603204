import { useTranslation } from '@packlink/translation-provider';
import { AnalyticsSortSeriesParam, UserAnalyticsRepository } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { ChartContainer, getChartContainerStatus, useRequestOnIntersection } from '../Charts';
import { getSubtitleRangeParams } from '@Analytics/utils/dates';
import {
    CroppableContent,
    Table,
    TableBody,
    TableBodyCell,
    TableHeader,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
} from '@shipengine/giger';
import { Charts, OTHERS } from '@Analytics/constants/analytics';
import { tableStyles } from './ShipmentsPerContentChartStyles';

const userAnalyticsRepository = new UserAnalyticsRepository(apiClient);

export function ShipmentsPerContentChart(props: AnalyticsSortSeriesParam): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const cdnUrl = config.cdn.baseUrl;

    const requestShipmentsPerContent = () => userAnalyticsRepository.getShipmentsByContent(props);

    const { data, status, isFetching, intersectionRef } = useRequestOnIntersection({
        requestCacheKeys: [Charts.ShipmentsPerContent, props],
        requestData: requestShipmentsPerContent,
    });

    const isEmpty = !data || !data.length;
    const containerStatus = getChartContainerStatus(status, isFetching, isEmpty);

    return (
        <ChartContainer
            ref={intersectionRef}
            title={t('analytics.chart.shipments-per-content')}
            subtitle={t('analytics.chart.date-range', getSubtitleRangeParams(locale, props.from, props.to))}
            status={containerStatus}
            cdnUrl={cdnUrl}
        >
            <Table
                header={
                    <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-content-tooltip">
                                    {t('analytics.label.content')}
                                </CroppableContent>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-shipments-tooltip">
                                    {t('analytics.label.shipments')}
                                </CroppableContent>
                            </TableHeaderCell>
                            <TableHeaderCell>
                                <CroppableContent id="analytics-label-percentage-tooltip">
                                    {t('analytics.label.percentage')}
                                </CroppableContent>
                            </TableHeaderCell>
                        </TableHeaderRow>
                    </TableHeader>
                }
                css={tableStyles}
                data-id="shipments-per-content-table"
            >
                <TableBody>
                    {data?.map(({ content, shipments, percentage }, index: number) => {
                        return (
                            <TableRow key={index}>
                                <TableBodyCell>
                                    <CroppableContent id={`analytics-content-${index}-tooltip`}>
                                        {content === OTHERS ? t('analytics.label.others') : content}
                                    </CroppableContent>
                                </TableBodyCell>
                                <TableBodyCell>{shipments}</TableBodyCell>
                                <TableBodyCell>{`${percentage}%`}</TableBodyCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </ChartContainer>
    );
}
