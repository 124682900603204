import React from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { Button } from '@shipengine/giger';
import { Form, FormikProps } from 'formik';
import { IArchivingFormFields } from './ArchivingSettings';
import { ArchivingOption } from './ArchivingOption';

interface IArchivingFormProps {
    formProps: FormikProps<IArchivingFormFields>;
}

export const ArchivingForm: React.FC<IArchivingFormProps> = (props: IArchivingFormProps): JSX.Element => {
    const {
        formProps: { values, handleChange },
    } = props;
    const { t } = useTranslation();

    return (
        <Form>
            <ArchivingOption
                optionTitle={t('archiving.settings.delivered-legend')}
                optionName="delivered"
                value={values.delivered}
                handleChange={handleChange}
            />
            <ArchivingOption
                optionTitle={t('archiving.settings.draft-legend')}
                optionName="draft"
                value={values.draft}
                handleChange={handleChange}
            />
            <Button type="submit">{t('archiving.settings.save')}</Button>
        </Form>
    );
};
