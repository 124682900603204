import { useTranslation } from '@packlink/translation-provider';
import {
    getButtonStyles,
    getContainerStyles,
    getIconStyles,
    getInfoIconStyles,
    getSectionStyles,
} from './EmailTemplateListItemStyles';
import { useToggle } from '@packlink/utils';
import { Button, ButtonVariant, Icon, IconSize, IconPopover, Switch, Typography, useToast } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { BrandedEmailsSettingsRepository, EmailsSettings, EmailType } from '@packlink/packlink-sdk';
import { BRANDED_EMAILS_CACHE_KEY, emailTypesKeys } from '../../constants';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { useMutation, useQueryClient } from 'react-query';
import { ToggleDialog } from '../ToggleDialog/ToggleDialog';

export interface EmailTemplateListItemProps {
    template: EmailsSettings;
    onEdit: () => void;
    onToggle?: (active: boolean) => void;
    disableToggle: boolean;
}

export function EmailTemplateListItem({
    template,
    onEdit,
    onToggle,
    disableToggle,
}: EmailTemplateListItemProps): JSX.Element {
    const { t } = useTranslation();
    const toast = useToast(t);
    const { apiClient } = useSdk();
    const brandedEmailsSettingsRepository = new BrandedEmailsSettingsRepository(apiClient);
    const queryClient = useQueryClient();
    const { state: activeToggle, toggle } = useToggle(template.active);
    const { state: isDialogOpen, toggle: toggleDialog } = useToggle();

    const mutation = useMutation(
        (params: { emailType: EmailType; active: boolean }) => {
            return brandedEmailsSettingsRepository.toggle(params.emailType, params.active);
        },
        {
            onMutate: () => {
                // We trigger an optimistic update when the mutation is called
                toggle();
            },
            onSettled: () => {
                queryClient.invalidateQueries(BRANDED_EMAILS_CACHE_KEY);
            },
            onError() {
                toggle();
                toast.error({
                    message: t('branded-emails.error.try-again'),
                });
            },
        },
    );

    const onChangeSwitch = () => {
        if (template.active) {
            return toggleDialog();
        }
        mutation.mutate({ emailType: template.emailType, active: !template.active });
        onToggle?.(!template.active);
    };

    const disableActiveTemplate = () => {
        toggleDialog();
        mutation.mutate({ emailType: template.emailType, active: !template.active });
        onToggle?.(!template.active);
    };

    return (
        <div css={getContainerStyles}>
            <section css={getSectionStyles}>
                <div css={getIconStyles}>
                    <Icon name={IconNames.MAIL} />
                </div>
                <Typography component="span" variant="small" bold>
                    {t(`branded-emails.template-list.${emailTypesKeys[template.emailType]}`)}
                </Typography>
                <IconPopover
                    css={getInfoIconStyles}
                    icon={IconNames.INFO}
                    size={IconSize.SIZE_SMALL}
                    placement="bottom-start"
                    offset={15}
                >
                    <Typography variant="small">
                        {t(`branded-emails.template-list.${emailTypesKeys[template.emailType]}-info`)}
                    </Typography>
                </IconPopover>
            </section>
            <section css={getSectionStyles}>
                <Button variant={ButtonVariant.TEXT} onClick={onEdit} css={getButtonStyles}>
                    {t('branded-emails.template-list.edit-template')}
                </Button>
                <Switch
                    name={`is-enabled-${template.emailType}`}
                    value={activeToggle}
                    isChecked={activeToggle}
                    onChange={onChangeSwitch}
                    disabled={disableToggle}
                >
                    <p hidden>{`${t('branded-emails.template-list.active-email')} ${activeToggle}`}</p>
                </Switch>
            </section>
            <ToggleDialog isOpen={isDialogOpen} onClose={toggleDialog} onSave={disableActiveTemplate} />
        </div>
    );
}
