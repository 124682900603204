import { ResponsiveLineProps } from '@Analytics/components/Charts';
import { getShortDateFormat } from './dates';

export function getTimeAxis(locale: string, points?: number) {
    return {
        format: (date: Date) => getShortDateFormat(locale, date),
        tickValues: getTickValues(points),
    };
}

function getTickValues(points?: number) {
    if (!points || points <= 7) {
        return 'every 1 days';
    } else if (points > 7 && points <= 14) {
        return 'every 2 days';
    } else if (points > 14 && points <= 21) {
        return 'every 3 days';
    } else if (points > 21 && points <= 30) {
        return 'every 4 days';
    } else if (points > 30 && points <= 50) {
        return 'every 1 mondays';
    } else if (points > 50) {
        return 'every 2 mondays';
    }
}

export function getLinearScale(): ResponsiveLineProps['yScale'] {
    return { type: 'linear', min: 'auto', max: 'auto' };
}
