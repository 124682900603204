import { useCallback, useEffect, useState } from 'react';
import { useTranslation, TFunction } from '@packlink/translation-provider';
import { IVolumeDiscountBand, IVolumeDiscountRate } from '@packlink/packlink-sdk';
import Packlink from '@sdk';
import { IDropdownOption } from '@shipengine/giger';

type WithLabel<T> = T & {
    label?: string;
};

export type BandOption = IDropdownOption<IVolumeDiscountBand>;

export interface IUseVolumeDiscountData {
    isLoading: boolean;
    isEstimated: boolean;
    volumeDiscountRates: WithLabel<IVolumeDiscountRate>[];
    volumeDiscountBands: BandOption[];
    setVolumeDiscountEstimate: (band: IVolumeDiscountBand) => Promise<void>;
}

export function useVolumeDiscountData(): IUseVolumeDiscountData {
    const { t } = useTranslation();
    const [isEstimated, setIsEstimated] = useState(false);
    const [volumeDiscountRates, setVolumeDiscountRates] = useState<WithLabel<IVolumeDiscountRate>[]>([]);
    const [volumeDiscountBands, setVolumeDiscountBands] = useState<BandOption[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [maxBand, setMaxBand] = useState<number>();

    const getRates = useCallback(() => {
        Packlink.v1.volumeDiscount.getClientRates().then((rates) => {
            setVolumeDiscountRates(
                rates.map((rate) => ({
                    ...rate,
                    label: getLabel(t, rate, maxBand),
                })),
            );
        });
    }, [maxBand, t]);

    const setVolumeDiscountEstimate = (band: IVolumeDiscountBand) => {
        return Packlink.v1.volumeDiscount.createEstimate(band).then(() => {
            setIsEstimated(true);
            getRates();
        });
    };

    useEffect(() => {
        Promise.all([
            Packlink.v1.volumeDiscount.getIsEstimated(),
            Packlink.v1.volumeDiscount.getBands(),
            Packlink.v1.volumeDiscount.getClientRates(),
        ])
            .then(([isEstimated, bands, rates]) => {
                setIsEstimated(isEstimated);

                const maxBandShipments = bands[bands.length - 1]?.maxShipments;
                setMaxBand(maxBandShipments);

                setVolumeDiscountBands(
                    bands
                        .map((band) => {
                            const label = getLabel(t, band, maxBandShipments);

                            return {
                                ...band,
                                label,
                                value: label,
                            };
                        })
                        .filter((b): b is BandOption => !!b.label),
                );

                setVolumeDiscountRates(
                    rates.map((rate) => ({
                        ...rate,
                        label: getLabel(t, rate, maxBandShipments),
                    })),
                );
            })
            .finally(() => setIsLoading(false));
    }, [t]);

    return {
        isLoading,
        isEstimated,
        volumeDiscountRates,
        volumeDiscountBands,
        setVolumeDiscountEstimate,
    };
}

function getLabel(t: TFunction, rate: IVolumeDiscountBand, max?: number) {
    if (!rate.maxShipments) return;

    const label = `${rate.minShipments} ${
        rate.maxShipments === max ? t('volume-discount.settings.or-more') : `- ${rate.maxShipments}`
    }`;
    return label;
}
