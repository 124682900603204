import { Next, WithCommonProps } from '@shipengine/giger';
import { getTrackingUrl, hasUniqueTrackingUrl } from '@packlink/tracking-details';
import { ContentWithClipboard } from '@components/Clipboard/ContentWithClipboard';

type PostSaleTrackingParcelReferenceProps = WithCommonProps<{
    trackingNumber: string;
    trackingUrl: string;
}>;

export function PostSaleTrackingParcelReference({
    trackingNumber,
    trackingUrl,
    ...props
}: PostSaleTrackingParcelReferenceProps): JSX.Element {
    const hasUniqueUrl = hasUniqueTrackingUrl(trackingUrl);

    return (
        <ContentWithClipboard valueToCopy={trackingNumber} {...props}>
            {hasUniqueUrl ? (
                <Next.Link href={getTrackingUrl(trackingUrl, trackingNumber)} isExternal target="_blank">
                    {trackingNumber}
                </Next.Link>
            ) : (
                trackingNumber
            )}
        </ContentWithClipboard>
    );
}
