import { useSelector } from 'react-redux';

import { useTenantConfig } from '@packlink/tenant-config-provider';

import { PaymentClientMethod, PaymentCreditCard, PaymentPaypal } from '@packlink/packlink-sdk';

import {
    getAvailableDeferredDirectDebit,
    getCanRequestAllowDeferredMethods,
    getCanUseDeferredPayment,
    getDeferred,
    getDeferredCreditCard,
    getDeferredPaypal,
} from '@store/selectors/payment';
import { isInvoiceAddressValid as getIsInvoiceAddressValid } from '@store/selectors/client';

import { ITenantConfig } from '@types';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';

interface IHookReturn {
    clientDeferredPaymentMethod: PaymentClientMethod | undefined;
    clientInvoiceAddressIsValid: boolean;
    canUseDeferredPayment: boolean;
    isByDirectDebitAvailable: boolean;
    isDeferredPartiallySuported: boolean;
    showAllowDeferredFormAdvice: boolean;
    clientPaypalDeferred: PaymentPaypal | undefined;
    clientCreditCardDeferred: PaymentCreditCard | undefined;
    helpCenterPages: {
        termsAndConditions: string;
        privacyPolicy: string;
        requestDeferred: string;
    };
}

export const useSettingsPaymentDeferredData = (): IHookReturn => {
    const getHelpCenterPage = useHelpCenter();
    const requestDeferredHelpCenter = getHelpCenterPage(HELP_CENTER_KEYS.requestDeferred);
    const { isDeferredPartiallySuported } = useTenantConfig<ITenantConfig>();

    const clientPaymentMethod = useSelector(getDeferred);
    const clientPaypalDeferred = useSelector(getDeferredPaypal);
    const clientCreditCardDeferred = useSelector(getDeferredCreditCard);

    const isInvoiceAddressValid = useSelector(getIsInvoiceAddressValid);
    const canUseDeferredPayment = useSelector(getCanUseDeferredPayment);
    const isByDirectDebitAvailable = !!useSelector(getAvailableDeferredDirectDebit);
    const canRequestAllowDeferredMethods = useSelector(getCanRequestAllowDeferredMethods);
    const showAllowDeferredFormAdvice = !!requestDeferredHelpCenter && canRequestAllowDeferredMethods;

    return {
        clientDeferredPaymentMethod: clientPaymentMethod,
        clientInvoiceAddressIsValid: isInvoiceAddressValid,
        showAllowDeferredFormAdvice,
        canUseDeferredPayment,
        clientPaypalDeferred,
        clientCreditCardDeferred,
        isByDirectDebitAvailable,
        isDeferredPartiallySuported,
        helpCenterPages: {
            termsAndConditions: getHelpCenterPage(HELP_CENTER_KEYS.termsAndConditions),
            privacyPolicy: getHelpCenterPage(HELP_CENTER_KEYS.privacyPolicy),
            requestDeferred: requestDeferredHelpCenter,
        },
    };
};
