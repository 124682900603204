import { useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonSize, SelectAutocomplete, Typography } from '@shipengine/giger';
import { BandOption, IUseVolumeDiscountData } from '../../hooks/useVolumeDiscountData';
import { autocompleteStyles, buttonStyles, itemStyles, listStyles } from './VolumeSelectionStyles';

export interface IVolumeSelectionProps {
    volumeDiscountBands: IUseVolumeDiscountData['volumeDiscountBands'];
    isEstimated: IUseVolumeDiscountData['isEstimated'];
    onSetVolume: IUseVolumeDiscountData['setVolumeDiscountEstimate'];
}

export function VolumeSelection({ volumeDiscountBands, isEstimated, onSetVolume }: IVolumeSelectionProps): JSX.Element {
    const { t } = useTranslation();
    const [selectedBand, setSelectedBand] = useState<BandOption | null>(null);

    const handleBandChange = (_name: string, value: BandOption | null) => {
        setSelectedBand(value);
    };

    const handleSetVolume = () => {
        if (!selectedBand) return;

        onSetVolume(selectedBand);
    };

    return (
        <>
            <Typography component="p" variant="body1">
                {t('volume-discount.settings.rates-info')}
            </Typography>

            <ol css={listStyles}>
                <li css={itemStyles(true, isEstimated)}>
                    {!isEstimated && (
                        <>
                            <Typography component="p" variant="body1">
                                {t('volume-discount.settings.estimate-shipping-volume')}
                            </Typography>

                            <SelectAutocomplete
                                name="band"
                                css={autocompleteStyles}
                                options={volumeDiscountBands}
                                label={t('volume-discount.settings.quantity')}
                                onChange={handleBandChange}
                                value={selectedBand}
                                noResultsLabel={t('autocomplete.messages.no-results-found')}
                            />

                            <Button
                                css={buttonStyles}
                                disabled={!selectedBand}
                                onClick={handleSetVolume}
                                size={ButtonSize.SMALL}
                                data-id="set-volume-button"
                            >
                                {t('volume-discount.settings.estimated')}
                            </Button>
                        </>
                    )}
                    {isEstimated && (
                        <Typography variant="body1">{t('volume-discount.settings.volume-estimated')}</Typography>
                    )}
                </li>

                <li css={itemStyles(false, isEstimated)}>
                    {!isEstimated && (
                        <Typography variant="body1">{t('volume-discount.settings.enable-volume-discount')}</Typography>
                    )}
                    {isEstimated && (
                        <>
                            <Typography variant="body1">
                                {t('volume-discount.settings.volume-discount-enabled')}
                            </Typography>
                            <Typography component="p" variant="body1">
                                {t('volume-discount.settings.is-enabled')}
                            </Typography>
                        </>
                    )}
                </li>
            </ol>
        </>
    );
}
