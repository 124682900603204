import { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

import { PaymentVoucher } from '@packlink/payment-voucher';
import { InlineNotification, NotificationType, Spinner } from '@shipengine/giger';
import { Order } from '@packlink/packlink-sdk';

import { AppDispatch } from '@store';
import { getClientData } from '@store/selectors/client';
import { getSelectedShipments } from '@store/selectors/bulk';
import { getDeferred, getIsPaymentLoading } from '@store/selectors/payment';
import { removeShipmentsToPay } from '@store/actions/bulk';

import { PaymentDetails } from '@components/PaymentDetails/PaymentDetails';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelContentError } from '@components/SidePanel/SidePanelContentError';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { OnPayProps, PaymentOptions } from '@components/PaymentOptions/PaymentOptions';
import { TermsAgreement } from '@components/TermsAgreement/TermsAgreement';

import { ShipmentPanelFooter } from '../../ShipmentPanelFooter';
import { shipmentPanelSpinnerStyles } from '../../ShipmentPanelStyles';
import { getInlineNotificationTopStyles } from './ShipmentPanelBulkActionsStyles';
import { useBulkPaymentPrice } from './hooks/useBulkPaymentPrice';
import { useBulkPaymentSubmit } from './hooks/useBulkPaymentSubmit';
import { useBulkPaymentVoucher } from './hooks/useBulkPaymentVoucher';
import { AmplitudeEvents } from '@constants/amplitude';
import { IShipmentPanelBulkActionsProps } from './ShipmentPanelBulkActions';
import { BulkFlows } from '../typings';
import { ShipmentProtection } from '../ShipmentProtection/ShipmentProtection';
import { UpsellUtils } from '@utils/UpsellUtils';
import { useAmplitude } from '@hooks/useAmplitude';
import { getPaymentErrorsTranslated } from '@utils/error';

export interface IShipmentPanelBulkPaymentProps extends IShipmentPanelBulkActionsProps {
    onGoBack: () => void;
    onPaymentSuccess: (order: Order) => void;
}

export const ShipmentPanelBulkPayment = ({
    onSetFlow,
    onGoBack,
    onPaymentSuccess,
}: IShipmentPanelBulkPaymentProps): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const selectedShipments = useSelector(getSelectedShipments);
    const client = useSelector(getClientData);
    const deferred = useSelector(getDeferred);
    const isPaymentLoading = useSelector(getIsPaymentLoading);
    const [isPaying, setIsPaying] = useState(false);
    const [genericError, setGenericError] = useState<string>();
    const [isCarriersTermsAccepted, setCarriersTerms] = useState(false);
    const [carriersTermsError, setCarriersTermsError] = useState('');
    const PanelContentRef = useRef<HTMLElement>(null);

    const { priceDetail, priceByShipmentRef, isLoadingPriceDetail, isFetchingPriceDetail, refetchPriceDetail } =
        useBulkPaymentPrice(selectedShipments, isPaying, setGenericError);

    const { isVoucherAvailable, voucherBulkParams, applyVoucher, removeVoucher } = useBulkPaymentVoucher(
        selectedShipments,
        isPaying,
        priceByShipmentRef,
    );

    const hasAmount = priceDetail?.total.price.totalPrice !== 0;

    const { errorKeys, onTryAgain, sendOrder, handleSubmit, handlePaymentError } = useBulkPaymentSubmit({
        selectedShipments,
        priceDetail,
        hasAmount,
        deferred,
        onPaymentSuccess,
        setIsPaying,
        setGenericError,
        isCarriersTermsAccepted,
    });

    const { isProtectedShipment } = UpsellUtils.getShipmentStatusProtection(selectedShipments);

    const tryAgain = () => {
        onTryAgain();
        refetchPriceDetail();
    };

    useEffect(() => {
        return () => {
            dispatch(removeShipmentsToPay());
        };
    }, [dispatch]);

    useEffect(() => {
        if (deferred && errorKeys) {
            PanelContentRef.current?.scrollTo({ top: PanelContentRef.current?.scrollHeight, behavior: 'smooth' });
        }
    }, [deferred, errorKeys]);

    const validateCarriersTerms = (checked: boolean) => {
        if (!checked) {
            setCarriersTermsError(t('form.error.carriers-terms'));
        } else {
            setCarriersTermsError('');
        }
    };

    const handleCarriersTerms = ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
        setCarriersTerms(!isCarriersTermsAccepted);
        validateCarriersTerms(checked);
    };

    const handlePayment = (props: OnPayProps) => {
        if (isCarriersTermsAccepted) {
            sendOrder({ ...props, carriersTermsAndConditions: isCarriersTermsAccepted });
        } else {
            setCarriersTermsError(t('form.error.carriers-terms'));
        }
    };

    const handleDeferredPaymentSubmit = (e: FormEvent<HTMLFormElement>) => {
        if (isCarriersTermsAccepted) {
            handleSubmit(e);
        } else {
            e.preventDefault();
            setCarriersTermsError(t('form.error.carriers-terms'));
        }
    };

    const handleInsurance = (event: React.MouseEvent<HTMLElement>): void => {
        event.preventDefault();

        sendAmplitudeSidebarClickEvent(AmplitudeEvents.INSURE_SHIPMENT);

        onSetFlow(BulkFlows.INSURANCE_FROM_BULK_PAYMENT);
    };

    if (genericError) {
        return (
            <SidePanelContent className="shipment-panel-bulk__content">
                <SidePanelContentError
                    title={t(genericError)}
                    subtitle={t('payment.error.subtitle')}
                    onTryAgain={tryAgain}
                />
            </SidePanelContent>
        );
    } else {
        return (
            <>
                <SidePanelContent className="shipment-panel-bulk__content" ref={PanelContentRef}>
                    {(isLoadingPriceDetail || isFetchingPriceDetail) && <Spinner css={shipmentPanelSpinnerStyles} />}
                    {!isLoadingPriceDetail && (
                        <>
                            <SidePanelContentSection title={t('bulk-panel.payment.details-section')}>
                                {priceDetail && (
                                    <PaymentDetails
                                        shipmentsPriceDetails={priceDetail.shipmentsPriceDetails}
                                        total={priceDetail.total.price}
                                        shipments={selectedShipments}
                                    />
                                )}
                            </SidePanelContentSection>

                            {!isProtectedShipment && (
                                <SidePanelContentSection title={t('payment.shipment-protection.title')}>
                                    <ShipmentProtection
                                        handleInsurance={handleInsurance}
                                        shipmentsData={selectedShipments}
                                    />
                                </SidePanelContentSection>
                            )}

                            {isVoucherAvailable && (
                                <SidePanelContentSection title={t('payment.payment-voucher.title')}>
                                    <PaymentVoucher
                                        applyVoucher={applyVoucher}
                                        onRemoveVoucher={removeVoucher}
                                        clientId={client.id}
                                        shipments={voucherBulkParams}
                                        showHeader={false}
                                    />
                                </SidePanelContentSection>
                            )}
                            <SidePanelContentSection title={t('payment.terms-and-condition.title')}>
                                <TermsAgreement
                                    formOrigin="Bulk payment"
                                    checked={isCarriersTermsAccepted}
                                    handleChange={handleCarriersTerms}
                                    error={carriersTermsError.length ? carriersTermsError : undefined}
                                />
                            </SidePanelContentSection>
                            {!deferred && !!priceDetail?.total.price.totalPrice && (
                                <SidePanelContentSection title={t('bulk-panel.payment.info-section')}>
                                    {errorKeys && (
                                        <InlineNotification
                                            css={getInlineNotificationTopStyles}
                                            type={NotificationType.ERROR}
                                        >
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: DOMPurify.sanitize(
                                                        getPaymentErrorsTranslated(t, errorKeys),
                                                    ),
                                                }}
                                            />
                                        </InlineNotification>
                                    )}
                                    <PaymentOptions
                                        amount={priceDetail?.total.price.totalPrice}
                                        currency={priceDetail?.total.price.currency}
                                        isPaying={isPaying}
                                        section="side panel"
                                        onCheckForExternalError={() => validateCarriersTerms(isCarriersTermsAccepted)}
                                        onPay={handlePayment}
                                        onError={handlePaymentError}
                                        isPaymentBlocked={!isCarriersTermsAccepted}
                                    />
                                </SidePanelContentSection>
                            )}
                            {errorKeys && deferred && (
                                <InlineNotification css={getInlineNotificationTopStyles} type={NotificationType.ERROR}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(getPaymentErrorsTranslated(t, errorKeys)),
                                        }}
                                    />
                                </InlineNotification>
                            )}
                        </>
                    )}
                </SidePanelContent>

                {(deferred || !hasAmount) && (
                    <form onSubmit={handleDeferredPaymentSubmit}>
                        <ShipmentPanelFooter
                            onExit={onGoBack}
                            submitButtonLabel={t('bulk-panel.button.pay')}
                            isSaveDisabled={isPaymentLoading || isPaying || isFetchingPriceDetail}
                            showTermsAndConditions={false}
                        ></ShipmentPanelFooter>
                    </form>
                )}
            </>
        );
    }
};
