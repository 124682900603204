import React from 'react';
import { captureException } from '@utils/logger';

interface IErrorBoundaryState {
    error?: Error;
}

interface IErrorBoundaryProps {
    children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { error: undefined };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({ error });
        captureException(error, {
            extra: { ...errorInfo },
        });
    }

    render(): JSX.Element {
        return <>{!this.state.error && this.props.children}</>;
    }
}
