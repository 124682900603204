import { useMemo } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useMutation } from 'react-query';
import Packlink from '@sdk';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { ISidePanelMessageProps, SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';
import { Shipment } from '@packlink/packlink-sdk';

export function useShipmentsUpdate(shouldShowPromo?: boolean) {
    const { t } = useTranslation();
    const { timedClosePanel } = useShipmentPanel();

    const {
        mutate: mutateShipments,
        data: updateData,
        isIdle: isWaitingUpdate,
        status,
        isSuccess,
    } = useMutation((params: Shipment[]) => Packlink.v1.shipments.updateShipments(params), {
        onSuccess: () => {
            !shouldShowPromo && timedClosePanel();
        },
    });

    const messageProps: ISidePanelMessageProps = useMemo(() => {
        return {
            idle: {
                type: SidePanelMessageType.INFO,
            },
            loading: {
                type: SidePanelMessageType.LOADING,
            },
            success: {
                type: updateData?.shipmentsFailed.length ? SidePanelMessageType.WARNING : SidePanelMessageType.SUCCESS,
                title:
                    updateData && updateData.shipmentsFailed.length > 0
                        ? t('bulk-notification.shipments.warning', { count: updateData.shipmentsFailed.length })
                        : t('bulk-notification.shipments.updated-success', {
                              count: updateData?.shipmentsUpdated.length || 0,
                          }),
                subtitle: updateData?.shipmentsFailed.length ? t('error.message.try-again-later') : undefined,
                showShippingRulesPromo: updateData && updateData.shipmentsUpdated.length > 1 && shouldShowPromo,
            },
            error: {
                type: SidePanelMessageType.ERROR,
                title: t('error.message.processing'),
                subtitle: t('error.message.try-again-later'),
            },
        }[status];
    }, [updateData, t, shouldShowPromo, status]);

    return {
        mutateShipments,
        isWaitingUpdate,
        messageProps,
        isSuccess,
    };
}
