import { useState, createContext, PropsWithChildren, useContext } from 'react';

export interface IAddressesContext {
    maxAddresses: number;
    hasReachedLimit: boolean;
    isAddressFormVisible: boolean;
    setNumberOfAddresses: (number: number) => void;
    setIsAddressFormVisible: (number: boolean) => void;
}

const AddressesContext = createContext<IAddressesContext | undefined>(undefined);

interface IAddressesProvider {
    maxAddresses: number;
}

export function AddressesProvider({ children, maxAddresses }: PropsWithChildren<IAddressesProvider>): JSX.Element {
    const [numberOfAddresses, setNumberOfAddresses] = useState(0);
    const [isAddressFormVisible, setIsAddressFormVisible] = useState(false);

    return (
        <AddressesContext.Provider
            value={{
                maxAddresses,
                hasReachedLimit: numberOfAddresses >= maxAddresses,
                isAddressFormVisible,
                setNumberOfAddresses,
                setIsAddressFormVisible,
            }}
        >
            {children}
        </AddressesContext.Provider>
    );
}

export function useAddressesContext(): IAddressesContext {
    const context = useContext(AddressesContext);

    if (context === undefined) {
        throw new Error('useAddressesContext must be used within a AddressesProvider');
    }

    return context;
}
