import { useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';

import { Button, ButtonSize, ButtonColor, ButtonVariant, Input, useToast } from '@shipengine/giger';

import { useCreateApiKey, useGetApiKey } from '@common/hooks/useApiKey';

import { copyToClipboardAction, browserSupportClipboardWritting } from '@components/Clipboard/ClipboardCopier';

import { GenerateApiKeyRemoveModal } from './GenerateApiKeyRemoveModal';

import {
    getGenerateApiKeysCopyButtonStyles,
    getGenerateApiKeysCopyStyles,
    getRemoveButtonStyles,
} from './GenerateApiKeyStyles';

interface IGenerateApiKeyProps {
    canDelete: boolean;
}

export const GenerateApiKey = ({ canDelete }: IGenerateApiKeyProps): JSX.Element => {
    const { t } = useTranslation();
    const toast = useToast(t);

    const apiKey = useGetApiKey();
    const { createApiKey, isCreateApiKeyLoading } = useCreateApiKey();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const generateApiKey = (): void => {
        createApiKey(undefined, {
            onError: () => {
                toast.error({ message: t('generate-api-key.create.error') });
            },
        });
    };

    const onCopyClickHandler = (): void => {
        apiKey && copyToClipboardAction(apiKey);
    };

    return (
        <>
            {showDeleteModal && <GenerateApiKeyRemoveModal onCloseHandler={() => setShowDeleteModal(false)} />}

            {!apiKey && (
                <div>
                    <Button isLoading={isCreateApiKeyLoading} size={ButtonSize.SMALL} onClick={generateApiKey}>
                        {t('generate-api-key.button.generate-api-key')}
                    </Button>
                </div>
            )}

            {apiKey && (
                <>
                    <Input readOnly name="apiKey" value={apiKey} label={t('generate-api-key.label.packlink-api-key')} />

                    <div css={getGenerateApiKeysCopyButtonStyles}>
                        {browserSupportClipboardWritting() && (
                            <Button size={ButtonSize.SMALL} onClick={onCopyClickHandler}>
                                {t('generate-api-key.button.copy')}
                            </Button>
                        )}

                        {canDelete && (
                            <Button
                                size={ButtonSize.SMALL}
                                variant={ButtonVariant.TEXT}
                                color={ButtonColor.ERROR}
                                css={getRemoveButtonStyles}
                                onClick={() => setShowDeleteModal(true)}
                            >
                                {showDeleteModal && t('generate-api-key.button.revoking')}
                                {!showDeleteModal && t('generate-api-key.button.revoke')}
                            </Button>
                        )}

                        <p css={getGenerateApiKeysCopyStyles}>{t('generate-api-key.message.copy-api-key')}</p>
                    </div>
                </>
            )}
        </>
    );
};
