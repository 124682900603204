import { getCookie, removeCookie } from '@packlink/utils';
import { ApiClient, Application, IAuth, Packlink } from '@packlink/packlink-sdk';
import { BrowserContext } from '@utils/BrowserContext';

// Redirect to login if token and refresh token are expired
function setLogoutOnSessionExpire(apiClient: ApiClient) {
    const redirectURL = BrowserContext.getLoginPath(config);
    apiClient.client.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const token = getCookie('token');
            const sessionExpired = token && error?.response?.status === 401;
            if (sessionExpired) {
                removeCookie('token');
                window.location.href = redirectURL;
            }
            return Promise.reject(error);
        },
    );
}

export function getAuthorization() {
    const token = getCookie('token');
    let packlinkAuth: IAuth | undefined;

    if (token) {
        packlinkAuth = { token };
    }

    return packlinkAuth;
}

const apiConfig = { url: config.api.uri };
const packlinkAuth = getAuthorization();

const packlink = new Packlink(apiConfig, Application.BULK, undefined, packlinkAuth);
setLogoutOnSessionExpire(packlink.apiClient); // Set logout on session expire for apiClient

export const apiClient = packlink.apiClient;

export default packlink;
