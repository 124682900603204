import { useTranslation } from '@packlink/translation-provider';

import { Spinner, SpinnerSize, InlineNotification, NotificationType } from '@shipengine/giger';

import { useDeferredPaymentFormStatus } from '@Invoicing/context/deferredPaymentFormStatus';
import { DeferredPaymentsFormStatus } from '@Invoicing/context/deferredPaymentFormStatus';
import { PaymentOptionsDeferred } from '@Invoicing/components/SettingsPaymentMethodsDeferred/PaymentOptionsDeferred';

import { getSavingLoadingStyles, getInfoMessageStyles } from './SettingsPaymentDeferredPaymentFormStyles';

export const SettingsPaymentDeferredPaymentForm = (): JSX.Element => {
    const { t } = useTranslation();

    const { deferredPaymentFormStatus } = useDeferredPaymentFormStatus();

    return (
        <>
            <h3>{t('settings.payment-deferred.payment-info')}</h3>

            <InlineNotification
                type={NotificationType.INFO}
                title={t('settings.payment-deferred.notification-info-title')}
                css={getInfoMessageStyles}
            >
                {t('settings.payment-deferred.notification-info')}
            </InlineNotification>

            {deferredPaymentFormStatus === DeferredPaymentsFormStatus.LOADING && (
                <Spinner
                    css={getSavingLoadingStyles}
                    message={t('settings.payment-deferred.payment-saving')}
                    size={SpinnerSize.SIZE_LARGE}
                />
            )}

            <PaymentOptionsDeferred />
        </>
    );
};
