import { Navigate, Route, Routes } from 'react-router';

import { SubscriptionsSection } from '@Subscriptions/pages/Subscriptions/SubscriptionsSection';
import { Analytics } from '@Analytics/pages/Analytics/Analytics';
import { ShipmentList } from '@components/ShipmentList/ShipmentList';
import { BulkPaymentSummary } from '@Postsale/pages/BulkPaymentSummary/BulkPaymentSummary';
import { ShipmentDetailsOldRedirect } from '@Postsale/pages/ShipmentDetails/ShipmentDetailsOldRedirect';
import { ShipmentDetailsWrapper } from '@Postsale/pages/ShipmentDetails/ShipmentDetailsWrapper';
import { BrandedEmail } from '@BrandedEmails/pages/BrandedEmail/BrandedEmail';

import { AppHeaderLayout } from './components/AppLayouts/AppHeaderLayout';
import { AppSidebarLayout } from './components/AppLayouts/AppSidebarLayout';
import { SettingsRouter } from './settings/SettingsRouter';
import { IntegrationsRouter } from './integrations/IntegrationsRouter';
import { APP_ROUTE, AppRoute } from './router/routes';
import { ShipmentsRoute } from './shipments/routes';
import { CheckoutRouter } from './checkout/CheckoutRouter';
import { CheckoutRoute } from './checkout/routes';
import { OnboardingRouter } from '@Onboarding/pages/OnboardingRouter';
import { Root } from './Root';
import { SupportCenterRouter } from '@SupportCenter/pages/SupportCenterRouter';
import { AccountErrorPage } from '../domains/account-error/AccountErrorPage';

export function AppRouter(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={<Root />}>
                <Route path={AppRoute.ERROR} element={<AccountErrorPage />} />
                <Route path={AppRoute.ONBOARDING}>
                    <Route index path="*" element={<OnboardingRouter />} />
                </Route>
                <Route index element={<Navigate to={APP_ROUTE.SHIPMENTS.INBOX_READY_TO_PURCHASE} />} />
                <Route element={<AppHeaderLayout />}>
                    <Route element={<AppSidebarLayout />}>
                        <Route path={AppRoute.SUBSCRIPTIONS} element={<SubscriptionsSection />} />
                        <Route path={AppRoute.ANALYTICS} element={<Analytics />} />

                        <Route path={`${AppRoute.SHIPMENTS}/:inbox`} element={<ShipmentList />} />
                    </Route>

                    <Route path={AppRoute.SETTINGS}>
                        <Route index path="*" element={<SettingsRouter />} />
                    </Route>
                    <Route path={AppRoute.SUPPORT_CENTER}>
                        <Route index path="*" element={<SupportCenterRouter />} />
                    </Route>
                </Route>
                <Route path={AppRoute.SHIPMENTS}>
                    <Route index element={<Navigate to={APP_ROUTE.SHIPMENTS.INBOX_READY_TO_PURCHASE} />} />

                    <Route path={ShipmentsRoute.BULK}>
                        <Route path={ShipmentsRoute.SUMMARY} element={<BulkPaymentSummary />} />
                    </Route>

                    <Route path={ShipmentsRoute.ID}>
                        <Route path={ShipmentsRoute.DETAILS} element={<ShipmentDetailsWrapper />} />
                    </Route>

                    <Route path={`:id?/${CheckoutRoute.CREATE}/*`} element={<CheckoutRouter />} />

                    <Route path={ShipmentsRoute.DETAIL}>
                        <Route path={ShipmentsRoute.ID} element={<ShipmentDetailsOldRedirect />} />
                    </Route>
                </Route>

                <Route path={AppRoute.BRANDED_EMAIL} element={<BrandedEmail />} />
                <Route path={AppRoute.INTEGRATIONS}>
                    <Route index path="*" element={<IntegrationsRouter />} />
                </Route>
            </Route>
        </Routes>
    );
}
