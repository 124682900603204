import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getPrivacyWithdrawalModalContentStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(3),
    });

export const getInlineNotificationStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    });

export const getPrivacyWithdrawalModalSpinnerStyles = (): SerializedStyles =>
    css({
        width: '100%',
        textAlign: 'center',
    });

export const getDialogStyles = (): SerializedStyles =>
    css({
        minWidth: 420,
    });
