import { useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { InlineNotification, NotificationType } from '@shipengine/giger';
import { IRuleFormProps, RuleForm } from './components/RuleForm/RuleForm';
import { formStyles } from './RulesFormTabStyles';
import { useShippingRules } from '../../context/shippingRules';

export function RulesFormTab(props: IRuleFormProps): JSX.Element {
    const { t } = useTranslation();
    const [isWarningOpen, setIsWarningOpen] = useState(true);
    const { hasReachedRulesLimit } = useShippingRules();
    const isFormBlocked = hasReachedRulesLimit && !props.selectedRule;
    const { maxShippingRules } = config;

    return (
        <>
            {isFormBlocked && (
                <InlineNotification
                    type={NotificationType.ALERT}
                    title={t('shipping-rules.warning.max-reached-title', { max: maxShippingRules })}
                >
                    {t('shipping-rules.warning.max-reached-info')}
                </InlineNotification>
            )}

            {isWarningOpen && !isFormBlocked && (
                <InlineNotification
                    type={NotificationType.ALERT}
                    title={t('shipping-rules.form.warning-title')}
                    onClose={() => setIsWarningOpen(false)}
                    closeText={t('toast.action.close')}
                >
                    {t('shipping-rules.form.warning-prices')}
                </InlineNotification>
            )}

            <RuleForm css={formStyles} {...props} />
        </>
    );
}
