import { Typography, WithCommonProps } from '@shipengine/giger';
import { Address } from '@packlink/packlink-sdk';
import {
    getResetAddressStyles,
    getShipmentDetailsAddressLineStyles,
    getShipmentDetailsAddressTitleStyles,
} from './ShipmentDetailsAddressStyles';

type ShipmentDetailsAddressProps = WithCommonProps<{
    address: Address;
}>;

export function ShipmentDetailsAddress({ address, ...props }: ShipmentDetailsAddressProps): JSX.Element {
    return (
        <address css={getResetAddressStyles} {...props}>
            <Typography component="h6" variant="heading6" css={getShipmentDetailsAddressTitleStyles}>
                {address.firstName} {address.lastName}
            </Typography>
            <Typography component="p" css={getShipmentDetailsAddressLineStyles}>
                {address.company || '-'}
            </Typography>
            <Typography component="p" css={getShipmentDetailsAddressLineStyles}>
                {address.email}
            </Typography>
            <Typography component="p" css={getShipmentDetailsAddressLineStyles}>
                {address.phone}
            </Typography>
            <Typography component="p" css={getShipmentDetailsAddressLineStyles}>
                {address.street1} {address.street2}
            </Typography>
            <Typography component="p" css={getShipmentDetailsAddressLineStyles}>
                {address.zipCode} - {address.city}, {address.alpha2Code}
            </Typography>
        </address>
    );
}
