import { useTranslation } from '@packlink/translation-provider';
import { InlineNotification, NotificationType } from '@shipengine/giger';
import { BillingInvoicePanelContent } from '@components/BillingInvoicePanelContent/BillingInvoicePanelContent';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { ShipmentPanelFooter } from '../../ShipmentPanelFooter';
import { Client } from '@packlink/packlink-sdk';

export interface IShipmentPanelBulkBillingProps {
    onGoBack: () => void;
    billingFormRef: React.RefObject<HTMLFormElement>;
    isPhoneNumberVerificationOpen: boolean;
    submitBillingForm: () => void;
    onBillingFormSuccess: (client: Client) => void;
    closePhoneNumberVerification: () => void;
}

export function ShipmentPanelBulkBilling(props: IShipmentPanelBulkBillingProps): JSX.Element {
    const {
        onGoBack,
        billingFormRef,
        isPhoneNumberVerificationOpen,
        submitBillingForm,
        onBillingFormSuccess,
        closePhoneNumberVerification,
    } = props;
    const { t } = useTranslation();

    return (
        <>
            <InlineNotification type={NotificationType.INFO}>
                {t('billing.notification.first-payment')}
            </InlineNotification>

            <SidePanelContent>
                <BillingInvoicePanelContent
                    ref={billingFormRef}
                    isPhoneNumberVerificationOpen={isPhoneNumberVerificationOpen}
                    onSuccessCallback={onBillingFormSuccess}
                    onClosePhoneNumberVerification={closePhoneNumberVerification}
                />
            </SidePanelContent>

            <ShipmentPanelFooter
                onExit={onGoBack}
                onAction={submitBillingForm}
                actionButtonType="button"
                showTermsAndConditions={false}
            />
        </>
    );
}
