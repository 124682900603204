import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentSummaryHeadingStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.primary.dark,
        textTransform: 'uppercase',
        margin: `0 0 ${theme.spacing(2)}px`,
    });

export const getShipmentSummarySectionStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
        margin: `0 0 ${theme.spacing(2)}px 0`,
        boxShadow: theme.elevations.above.boxShadow,
    });

export const getShipmentSummaryLabelStyles = (theme: Theme): SerializedStyles =>
    css({
        textTransform: 'uppercase',
        margin: 0,

        ':not(:first-of-type)': {
            marginTop: theme.spacing(),
        },
    });

export const getShipmentSummaryDataStyles = (): SerializedStyles =>
    css({
        margin: 0,
    });

export const getShipmentSummaryParcelListStyles = (): SerializedStyles =>
    css({
        margin: 0,
        padding: 0,
        listStyle: 'none',
    });

export const getShipmentSummaryOwnContractIconStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.alert.main,
        marginRight: theme.spacing(),
    });
