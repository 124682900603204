import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

const commonStyles = {
    HEADER_HEIGHT: 5,
};

export const getSwitchCardStyles =
    (isChecked: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            width: '100%',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.white,
            border: `1px solid ${isChecked ? theme.palette.primary.main : theme.palette.gray.light}`,
            borderRadius: theme.borderRadius.S,
            flexGrow: 1,
        });

export const getSwitchCardHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        gap: theme.spacing(),
        justifyContent: 'space-between',
        //We have to ensure that all headers have the same height, since not all of them have the same title length
        minHeight: theme.spacing(commonStyles.HEADER_HEIGHT),
        alignItems: 'center',
    });

export const getSwitchCardDividerStyles = (): SerializedStyles =>
    css({
        margin: '8px 0',
    });

export const getSwitchCardDescriptionStyles = (theme: Theme): SerializedStyles =>
    css({
        fontWeight: theme.typography.fontWeight.normal,
        color: theme.palette.black,
    });

export const getSwitchCardTitleStyles = (): SerializedStyles =>
    css({
        textTransform: 'uppercase',
    });

export const getSwitchStyles = (): SerializedStyles =>
    css({
        '& label': {
            alignItems: 'center',
        },
    });
