import {
    IShipment,
    Shipment,
    CreateLossSupportTicketPayload,
    SupportTicketPaymentMethod,
    SupportCenterRepository,
} from '@packlink/packlink-sdk';
import { useContext } from 'react';
import * as yup from 'yup';
import { apiClient } from '@sdk';
import {
    SupportTicketClaimFieldsData,
    useSupportTicketClaimFieldsSchema,
    parseSupportTicketClaimCommonData,
} from '../CommonFields/SupportTicketClaimFields/SupportTicketClaimFieldsData';
import {
    SupportTicketPaymentFieldData,
    parseTicketPayment,
    useSupportTicketPaymentValidationSchema,
} from '../CommonFields/SupportTicketPaymentField/SupportTicketPaymentFieldData';
import { FormWithShipmentContext } from '../CommonFields/FormWithShipment/FormWithShipmentData';
import { NewTicketEventHandlers } from '../../NewTicketData';

export type LossTicketFormData<PaymentMethod extends SupportTicketPaymentMethod = SupportTicketPaymentMethod> =
    SupportTicketClaimFieldsData & {
        payment: SupportTicketPaymentFieldData<PaymentMethod>;
    };

export function useLossTicketFormValidationSchema() {
    const paymentMethodSchema = useSupportTicketPaymentValidationSchema();
    const supportTicketClaimFieldsSchema = useSupportTicketClaimFieldsSchema();

    return yup
        .object<LossTicketFormData>()
        .shape({
            payment: paymentMethodSchema,
        })
        .concat(supportTicketClaimFieldsSchema)
        .defined();
}

export function useLossTicketFormHandlers({ onSubmit }: NewTicketEventHandlers): {
    handleSubmit: (values: LossTicketFormData) => Promise<void>;
} {
    const supportCenterRepository = new SupportCenterRepository(apiClient);
    const { shipment } = useContext(FormWithShipmentContext);
    const handleSubmit = async (values: LossTicketFormData) => {
        const lossTicket = await parseLossTicket(values, shipment);
        await onSubmit(() => supportCenterRepository.createLossSupportTicket(lossTicket));
    };

    return {
        handleSubmit,
    };
}

function parseLossTicket(
    { proofOfValue, productDescription, amount, payment }: LossTicketFormData,
    shipment: Shipment<IShipment>,
): CreateLossSupportTicketPayload {
    return {
        shipmentReference: shipment.packlinkReference,
        ...parseSupportTicketClaimCommonData({ proofOfValue, productDescription, amount }, shipment),
        paymentMethod: parseTicketPayment(payment),
    };
}
