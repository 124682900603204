import { DeepPartial } from 'redux';
import { createSelector } from 'reselect';
import { Lens } from 'monocle-ts';
import { IAppState } from '@store/reducers';
import { TenantUtils } from '@utils/tenant';
import {
    Address,
    CashOnDelivery,
    Content,
    IContent,
    IInterval,
    Interval,
    IParcel,
    IPrice,
    IShipment,
    IShipmentCollection,
    Parcel,
    Service,
    Shipment,
    ShipmentCollection,
} from '@packlink/packlink-sdk';
import { ICheckoutState, PartialAddress } from '@store/reducers/checkout';
import { ServiceUtils } from '../../utils/ServiceUtils';
import { UpsellInsuranceType } from '@types';

const getCheckout = Lens.fromProp<IAppState>()('checkout').get;

// Parcels
export const checkoutParcels = Lens.fromProp<ICheckoutState>()('parcels');
const getSerializedCheckoutParcels = createSelector(getCheckout, checkoutParcels.get);
export const getCheckoutParcels = createSelector(getSerializedCheckoutParcels, (parcels) =>
    parcels.map((parcel) => Parcel.deserialize(parcel)),
);

// Service
export const checkoutService = Lens.fromProp<ICheckoutState>()('service');
export const getCheckoutService = createSelector(getCheckout, checkoutService.get);

export const getIsCarrierUPSFromService = createSelector(
    [getCheckoutService],
    (service) => !!service?.carrierName.toUpperCase().includes('UPS'),
);

// From & to
export const checkoutFrom = Lens.fromProp<ICheckoutState>()('from');
const getSerializedCheckoutFrom = createSelector(getCheckout, checkoutFrom.get);
export const getCheckoutFrom = createSelector(getSerializedCheckoutFrom, (from: PartialAddress) =>
    Address.deserialize(from),
);

export const checkoutTo = Lens.fromProp<ICheckoutState>()('to');
const getSerializedCheckoutTo = createSelector(getCheckout, checkoutTo.get);
export const getCheckoutTo = createSelector(getSerializedCheckoutTo, (to: PartialAddress) => Address.deserialize(to));

export const checkoutAddressId = Lens.fromProp<PartialAddress>()('id');
export const checkoutAddressFirstName = Lens.fromProp<PartialAddress>()('firstName');
export const checkoutAddressLastName = Lens.fromProp<PartialAddress>()('lastName');
export const checkoutAddressCompany = Lens.fromProp<PartialAddress>()('company');

export const checkoutAddressStreet1 = Lens.fromProp<PartialAddress>()('street1');
export const checkoutAddressStreet2 = Lens.fromProp<PartialAddress>()('street2');
export const checkoutAddressEmail = Lens.fromProp<PartialAddress>()('email');
export const checkoutAddressPhone = Lens.fromProp<PartialAddress>()('phone');

export const checkoutAddressAlpha2Code = Lens.fromProp<PartialAddress>()('alpha2Code');
export const checkoutAddressState = Lens.fromProp<PartialAddress>()('state');
export const checkoutAddressZipCode = Lens.fromProp<PartialAddress>()('zipCode');
export const checkoutAddressCity = Lens.fromProp<PartialAddress>()('city');

// Additional data
export const checkoutAdditionalData = Lens.fromProp<ICheckoutState>()('additionalData');
export const getCheckoutAdditionalData = createSelector(getCheckout, checkoutAdditionalData.get);

export const checkoutThirdPartyPreferences = Lens.fromProp<ICheckoutState>()('thirdPartyPreferences');
export const getCheckoutThirdPartyPreferences = createSelector(getCheckout, checkoutThirdPartyPreferences.get);

export const checkoutDropoffPointId = Lens.fromProp<ICheckoutState>()('dropoffPointId');
export const getCheckoutDropoffPointId = createSelector(getCheckout, checkoutDropoffPointId.get);

export const checkoutCollectionDate = Lens.fromProp<ICheckoutState>()('collectionDate');
export const getCheckoutCollectionDate = createSelector(getCheckout, checkoutCollectionDate.get);

export const checkoutCollectionTime = Lens.fromProp<ICheckoutState>()('collectionTime');
const getSerializedCheckoutCollectionTime = createSelector(getCheckout, checkoutCollectionTime.get);
export const getCheckoutCollectionTime = createSelector(getSerializedCheckoutCollectionTime, Interval.deserialize);

export const checkoutPriceDetails = Lens.fromProp<ICheckoutState>()('priceDetails');
export const getCheckoutPriceDetails = createSelector(getCheckout, checkoutPriceDetails.get);

export const getCheckoutInsurancePrice = createSelector(
    getCheckoutPriceDetails,
    (priceDetails?: IPrice) => priceDetails?.availableProducts?.insurance,
);

export const checkoutInsuranceType = Lens.fromProp<ICheckoutState>()('insuranceType');
export const getCheckoutInsuranceType = createSelector(getCheckout, checkoutInsuranceType.get);

export const checkoutAdultSignatureType = Lens.fromProp<ICheckoutState>()('adultSignature');
export const getCheckoutAdultSignature = createSelector(getCheckout, checkoutAdultSignatureType.get);

export const checkoutAdditionalHandling = Lens.fromProp<ICheckoutState>()('additionalHandling');
export const getCheckoutAdditionalHandling = createSelector(getCheckout, checkoutAdditionalHandling.get);

export const checkoutCashOnDelivery = Lens.fromProp<ICheckoutState>()('cashOnDelivery');
export const getCashOnDelivery = createSelector(getCheckout, checkoutCashOnDelivery.get);

export const checkoutProofOfDelivery = Lens.fromProp<ICheckoutState>()('proofOfDelivery');
export const getCheckoutProofOfDelivery = createSelector(getCheckout, checkoutProofOfDelivery.get);

export const checkoutPrintInStore = Lens.fromProp<ICheckoutState>()('printInStore');
export const getCheckoutPrintInStore = createSelector(getCheckout, checkoutPrintInStore.get);

export const checkoutContent = Lens.fromProp<ICheckoutState>()('content');
const getSerializedCheckoutContent = createSelector(getCheckout, checkoutContent.get);
export const getCheckoutContent = createSelector(getSerializedCheckoutContent, Content.deserialize);

const getSerializedCheckoutCollection = createSelector(
    getCheckoutCollectionDate,
    getSerializedCheckoutCollectionTime,
    (collectionDate?: string, collectionTime?: IInterval): IShipmentCollection => ({
        date: collectionDate,
        interval: collectionTime,
    }),
);
export const getCheckoutCollection = createSelector(getSerializedCheckoutCollection, ShipmentCollection.deserialize);

export const checkoutSelectedShipment = Lens.fromProp<ICheckoutState>()('selectedShipment');
const getSerializedSelectedShipment = createSelector(getCheckout, checkoutSelectedShipment.get);

export const getCheckoutSource = createSelector(
    getSerializedSelectedShipment,
    (selectedShipment?) => selectedShipment?.source ?? TenantUtils.getPlatform(),
);

export const getSelectedShipmentAdultSignature = createSelector(
    getSerializedSelectedShipment,
    (selectedShipment?) => selectedShipment?.upsales?.adultSignature?.available,
);

export const getSelectedShipmentProofOfDelivery = createSelector(
    getSerializedSelectedShipment,
    (selectedShipment?) => selectedShipment?.upsales?.proofOfDelivery?.available,
);

export const getSelectedShipmentPrintInStore = createSelector(
    getSerializedSelectedShipment,
    (selectedShipment?) => selectedShipment?.upsales?.printInStore?.available,
);

export const getSelectedShipmentAdditionalHandling = createSelector(
    getSerializedSelectedShipment,
    (selectedShipment?) => selectedShipment?.upsales?.additionalHandling?.available,
);

export const getShowAdditionalInsurance = createSelector(
    getCheckout,
    (checkout: ICheckoutState): boolean | undefined => checkout.service?.upsales?.insurance?.additionalInsurance,
);

export const getSelectedShipment = createSelector(
    getSerializedSelectedShipment,
    (selectedShipment?) => selectedShipment,
);

export const voucher = Lens.fromProp<ICheckoutState>()('voucher');
export const getCheckoutVoucher = createSelector(getCheckout, voucher.get);

export const getInvoiceInfoSaved = createSelector(
    getCheckout,
    (checkout: ICheckoutState): boolean | undefined => !!checkout.invoiceInfoSaved,
);
export const getIsCheckoutBusy = createSelector(getCheckout, (checkout: ICheckoutState): boolean => checkout.isBusy);

export const getUrlTermsAndConditions = createSelector(
    getCheckout,
    (checkout: ICheckoutState): string | undefined => checkout.service?.urlTermsAndConditions,
);

interface ICombinedData {
    source: string;
    voucherName?: string;
    isCustomsRequired: boolean;
    additionalData: Record<string, unknown>;
}

export const getIsCustomsRequiredInCheckout = createSelector([getCheckoutService], (service) =>
    service ? ServiceUtils.getServiceFlags(service).customsRequired : false,
);

// This is because the createSelector does not accept more than 12 parameters
const getCombinedData = createSelector(
    getCheckoutSource,
    getIsCustomsRequiredInCheckout,
    getCheckoutVoucher,
    getCheckoutAdditionalData,
    (source, isCustomsRequired, voucher, additionalData): ICombinedData => {
        return {
            source,
            voucherName: voucher?.name,
            isCustomsRequired,
            additionalData,
        };
    },
);

export const getCheckoutShipment = createSelector(
    getSerializedCheckoutParcels,
    getSerializedCheckoutFrom,
    getSerializedCheckoutTo,
    getCombinedData,
    getCheckoutService,
    getCheckoutDropoffPointId,
    getCheckoutPriceDetails,
    getCheckoutInsuranceType,
    getSerializedCheckoutContent,
    getSerializedCheckoutCollection,
    getSerializedSelectedShipment,
    getCheckoutAdultSignature,
    getCheckoutAdditionalHandling,
    getCashOnDelivery,
    getCheckoutProofOfDelivery,
    getCheckoutPrintInStore,
    (
        parcels: DeepPartial<IParcel>[],
        from: PartialAddress,
        to: PartialAddress,
        combinedData: ICombinedData,
        service?: Service,
        dropoffPointId?: string,
        priceDetails?: IPrice,
        insuranceType?: UpsellInsuranceType,
        content?: Partial<IContent>,
        collection?: IShipmentCollection,
        selectedShipment?: DeepPartial<IShipment>,
        adultSignature?: boolean,
        additionalHandling?: boolean,
        cashOnDelivery?: CashOnDelivery,
        proofOfDelivery?: boolean,
        printInStore?: boolean,
    ): Shipment => {
        const hasInsurance =
            insuranceType &&
            [UpsellInsuranceType.PACKLINK_INSURANCE, UpsellInsuranceType.ENHANCED].includes(insuranceType);

        const hasProofOfDelivery = proofOfDelivery;

        const hasCashOnDelivery =
            !!cashOnDelivery?.amount &&
            cashOnDelivery.amount > 0 &&
            !!cashOnDelivery?.accountHolder &&
            !!cashOnDelivery?.iban;

        return Shipment.deserialize({
            ...selectedShipment,
            ...combinedData,
            parcels: parcels.map(
                (p): IParcel => ({
                    ...p,
                    weight: Number(p.weight),
                    height: Number(p.height),
                    length: Number(p.length),
                    width: Number(p.width),
                }),
            ),
            from,
            to,
            dropoffPointId,
            content,
            collection,
            upsales: {
                ...selectedShipment?.upsales,
                insurance: {
                    available: hasInsurance,
                    amount: hasInsurance ? priceDetails?.availableProducts?.insurance?.insured : 0,
                },
                proofOfDelivery: {
                    available: hasProofOfDelivery,
                },
                adultSignature: {
                    available: adultSignature,
                },
                additionalHandling: {
                    available: additionalHandling,
                },
                cashOnDelivery: {
                    ...cashOnDelivery,
                    available: hasCashOnDelivery,
                },
                printInStore: {
                    available: printInStore,
                },
            },
            currency: service?.currency,
            service: {
                ...selectedShipment?.service,
                id: service?.id,
                carrierName: service?.carrierName,
                name: service?.name,
                hasPrintInStore: service?.upsales.printInStore.available,
            },
            hasCustoms: combinedData.isCustomsRequired,
        });
    },
);

export const getCheckoutTotalPrice = createSelector(
    [getCheckoutPriceDetails],
    (priceDetails: IPrice | undefined): number | undefined => priceDetails?.price?.totalPrice,
);

export const getCheckoutCurrency = createSelector([getCheckoutService], (service?: Service) => service?.currency);

export const getParcelsTotalWeight = createSelector([getCheckoutParcels], (parcels: Parcel[]) =>
    parcels.reduce((totalWeight: number, parcel: Parcel) => totalWeight + Number(parcel.weight), 0),
);

export const getAddressBookChecked = createSelector([getCheckout], (state) => state.addressBookChecked);
