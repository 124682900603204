import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const headerStyles = (theme: Theme): SerializedStyles =>
    // Replicating class "eb-create-shipment__fieldset legend" to keep consistency with current checkout
    // until section titles are migrated
    css({
        margin: '0 0 15px',
        color: theme.palette.secondary.dark,
        fontSize: theme.toRem(theme.typography.fontSize.L),
        fontWeight: theme.typography.fontWeight.bold,
        lineHeight: theme.getLineHeight(theme.typography.fontSize.L, theme.typography.fontSize.L),
        letterSpacing: theme.toRem('1px'),
    });
