import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import printJS from 'print-js';
import { useEvent } from '@packlink/event-handler';
import { Label, PrintingFormat, StaticDisposition } from '@packlink/packlink-sdk';
import Packlink from '@sdk';
import { downloadLabel, ILabelGenerated } from '@utils/LabelUtils';
import { ButtonAction } from '../DownloadLabel';
import { AmplitudeProperties } from '@constants/amplitude';
import { getClientIdentifier } from '@store/selectors/client';
import { EventName } from '@types';

type LabelGenerated = ILabelGenerated & { hasError?: boolean };
interface IUseDownloadLabel {
    bulkPrintingFormat: PrintingFormat;
    buttonAction?: ButtonAction;
    fetchLabels: (
        shipmentReferences: string[],
        printingFormat: PrintingFormat,
        disposition: StaticDisposition,
        actionType: ButtonAction,
        label?: string,
    ) => void;
    onChangePrintingFormat: (printingFormat: PrintingFormat) => void;
}

export interface IUseDownloadLabelProps {
    onFetching?: (action: ButtonAction, eventProps: Record<string, unknown>) => void;
    onError?: () => void;
    onFinish?: () => void;
}

export const useDownloadLabel = ({ onFetching, onError, onFinish }: IUseDownloadLabelProps): IUseDownloadLabel => {
    const [buttonAction, setButtonAction] = useState<ButtonAction>();
    const [bulkPrintingFormat, setBulkPrintingFormat] = useState<PrintingFormat>(PrintingFormat.A6);
    const clientId = useSelector(getClientIdentifier);
    const { eventBind, eventUnbind } = useEvent<ILabelGenerated>(clientId, EventName.LABEL_MERGE_READY);

    const handleLabel = useCallback(
        (data: LabelGenerated, selectedButtonAction?: ButtonAction): void => {
            if (data.hasError) {
                onError?.();
            } else {
                switch (selectedButtonAction) {
                    case ButtonAction.DOWNLOAD:
                        downloadLabel(data);
                        onFinish?.();
                        break;
                    case ButtonAction.PRINT:
                        printJS({
                            printable: data.url_label,
                            onLoadingEnd: () => onFinish?.(),
                        });
                        break;
                }
            }
        },
        [onError, onFinish],
    );

    const getFormattedLabels = (
        shipmentReferences: string[],
        printingFormat: PrintingFormat,
        disposition: StaticDisposition,
        newButtonAction: ButtonAction,
    ): void => {
        Packlink.pro.shipments
            .getCombinedLabels(shipmentReferences, disposition)
            .then((formattedLabels: Label): void => {
                const formattedLabel = formattedLabels[printingFormat as unknown as Uppercase<PrintingFormat>];

                handleLabel({ url_label: formattedLabel?.url }, newButtonAction);
            })
            .catch((): void => handleLabel({ hasError: true }));
    };

    const getMultipleLabels = (shipmentReferences: string[]): void => {
        Packlink.v1.shipments.getLabels(shipmentReferences).catch(() => handleLabel({ hasError: true }));
    };

    const fetchLabels = (
        shipmentReferences: string[],
        printingFormat: PrintingFormat,
        disposition: StaticDisposition,
        action: ButtonAction,
        label?: string,
    ): void => {
        setButtonAction(action);
        const eventProps = { [AmplitudeProperties.LABEL_FORMAT]: printingFormat };
        onFetching?.(action, eventProps);
        printingFormat === PrintingFormat.COMBINED_A4
            ? getFormattedLabels(shipmentReferences, printingFormat, disposition, action)
            : shipmentReferences.length > 1
              ? getMultipleLabels(shipmentReferences)
              : handleLabel({ url_label: label, hasError: label === undefined }, action);
    };

    const onChangePrintingFormat = (printingFormat: PrintingFormat): void => {
        setBulkPrintingFormat(printingFormat);
    };

    const onGeneratedLabel = useCallback(
        (data: ILabelGenerated) => {
            const hasError = !data.references?.length || !data.url_label || data.status !== '200';
            handleLabel({ ...data, hasError }, buttonAction);
        },
        [buttonAction, handleLabel],
    );

    useEffect((): (() => void) => {
        eventBind(onGeneratedLabel);

        return (): void => {
            eventUnbind(onGeneratedLabel);
        };
    }, [eventBind, eventUnbind, onGeneratedLabel]);

    return {
        bulkPrintingFormat,
        buttonAction,
        fetchLabels,
        onChangePrintingFormat,
    };
};
