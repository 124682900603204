import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getShipmentPanelEmptyServicesMessageStyles = (theme: Theme): SerializedStyles =>
    css({
        textAlign: 'center',
        marginTop: theme.spacing(3),
    });
export const getShipmentPanelServicesDropoffContentStyles = (): SerializedStyles =>
    css({
        height: '100%',
        maxHeight: '540px',

        '& > div': {
            height: '100%',
        },
    });
