import { TrackHTMLAttributes, useCallback, useRef } from 'react';

type TrackProps = TrackHTMLAttributes<HTMLTrackElement> & {
    fallbackSrc?: string;
};

export function Track({ fallbackSrc, ...rest }: TrackProps): JSX.Element {
    const trackRef = useRef<HTMLTrackElement | null>(null);

    const onError = useCallback(
        (e: ErrorEvent) => {
            if (!fallbackSrc) return;
            const trackElement = e.target as HTMLTrackElement;
            trackElement.src = fallbackSrc;
        },
        [fallbackSrc],
    );

    const handleRef = (ref: HTMLTrackElement | null) => {
        if (ref) {
            // onError property is not triggered when the track src fails, but error event does.
            ref.addEventListener('error', onError);
        } else {
            trackRef.current?.removeEventListener('error', onError);
        }

        trackRef.current = ref;
    };

    return <track ref={handleRef} {...rest} />;
}
