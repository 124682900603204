import { FC } from 'react';

import { InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { useTranslation } from '@packlink/translation-provider';

import { IntegrationGuide } from '../../IntegrationGuide/IntegrationGuide';

interface IFrontendConfiguredIntegrationGuide {
    collapsed: boolean;
    partner: string;
}

export const FrontendConfiguredIntegrationGuide: FC<IFrontendConfiguredIntegrationGuide> = ({
    collapsed,
    partner,
}: IFrontendConfiguredIntegrationGuide): JSX.Element => {
    const { t } = useTranslation();

    return (
        <IntegrationGuide>
            <InfoPanel title={t('integrations.guide.title')} collapsible={true} collapsed={collapsed}>
                <InfoPanelStepList>
                    <InfoPanelStep title={t('INTEGRATION_MIRAKL_STEP_1', { partner })} />
                    <InfoPanelStep title={t('INTEGRATION_MIRAKL_STEP_2')} />
                    <InfoPanelStep title={t('INTEGRATION_MIRAKL_STEP_3')} />
                    <InfoPanelStep title={t('INTEGRATION_MIRAKL_STEP_4', { partner })} />
                    <InfoPanelStep title={t('INTEGRATION_MIRAKL_STEP_5')} />
                    <InfoPanelStep title={t('INTEGRATION_MIRAKL_STEP_6', { partner })} />
                </InfoPanelStepList>
            </InfoPanel>
        </IntegrationGuide>
    );
};
