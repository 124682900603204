import { Typography } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { getInfoCardStyles, getInfoTitleStyles } from './InfoCardStyles';

export interface InfoCardProps {
    title: string;
    children?: React.ReactNode;
}

export function InfoCard({ title, children }: InfoCardProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <div css={getInfoCardStyles}>
            <Typography component="h5" variant="body1" css={getInfoTitleStyles}>
                {t(title)}
            </Typography>
            {children}
        </div>
    );
}
