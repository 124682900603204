import { Outlet } from 'react-router';
import { Stepper } from '../Stepper/Stepper';
import { SidebarSummary } from '../SidebarSummary/SidebarSummary';
import { getAsideStyles, getCheckoutLayoutStyles, getMainStyles } from './CheckoutLayoutStyles';
import { useMedia } from '@packlink/utils';
import { useTheme } from '@emotion/react';
import { BottomBar } from '../BottomBar/BottomBar';

export const CheckoutLayout = () => {
    const theme = useTheme();
    const isDesktop = useMedia(theme.mediaQuery('desktop'));

    return (
        <article css={getCheckoutLayoutStyles}>
            <main css={getMainStyles}>
                <Stepper />

                <Outlet />
            </main>

            {isDesktop ? (
                <aside css={getAsideStyles}>
                    <SidebarSummary />
                </aside>
            ) : (
                <BottomBar>
                    <SidebarSummary />
                </BottomBar>
            )}
        </article>
    );
};
