import { PropsWithChildren, useEffect, useLayoutEffect } from 'react';
import { useQuery } from 'react-query';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Packlink from '@sdk';
import { ApiClientError, IProInitResponse } from '@packlink/packlink-sdk';
import { AmplitudeEvents } from '@constants/amplitude';
import { i18next } from '@packlink/translation-provider';
import moment from 'moment';
import { setUser } from '@store/actions/user';
import { setClient } from '@store/actions/client';
import { setAvailablePaymentMethods, setPaymentMethods } from '@store/actions/payment';
import { getUserId } from '@store/selectors/user';
import { AppDispatch } from '@store';
import { LoggerContext, logSdkError, setContext } from '@utils/logger';
import { TenantUtils } from '@utils/tenant';
import { Spinner } from '@components/Spinner/Spinner';
import { GTM_INITIAL_TRACK_COOKIE_NAME } from '@constants/gtm';
import { BrowserContext } from '@utils/BrowserContext';
import { PrivacySplash } from '@User/pages/PrivacySettings/components/PrivacySplash/PrivacySplash';
import { BulkAuthenticatedPacklinkProviders } from '@providers/BulkAuthenticatedPacklinkProviders';
import { getIdentifiedUserContext } from '@utils/launchDarklyUtils';
import { removeCookie } from '@packlink/utils';
import { GTMCustomDimensions, GTMPageType, IGTMConfiguration, ProviderName } from '@packlink/metrics';
import { useMetrics } from '@providers/MetricsProvider';
import { useAmplitude } from '@hooks/useAmplitude';

export function AuthGate({ children }: PropsWithChildren<unknown>): JSX.Element {
    const userId = useSelector(getUserId);
    const { initResponse, isLoading, error } = useInitialize();

    const showPrivacySplash =
        initResponse?.user.policies &&
        (!initResponse?.user.policies?.dataProcessing || initResponse?.user.policies?.marketingEmails === null);

    if (isLoading || error || !userId) {
        return <Spinner fullScreen />;
    }

    if (showPrivacySplash) {
        return <PrivacySplash />;
    }

    return <BulkAuthenticatedPacklinkProviders>{children}</BulkAuthenticatedPacklinkProviders>;
}

function useInitialize() {
    const userId = useSelector(getUserId);
    const ldClient = useLDClient();
    const dispatch = useDispatch<AppDispatch>();
    const metrics = useMetrics();
    const { setAmplitudeUserInfo, sendAmplitudeEvent, setAmplitudeFeatureFlags } = useAmplitude();

    const { data, error, isLoading } = useQuery<IProInitResponse, ApiClientError>({
        queryKey: ['init'],
        queryFn: () => Packlink.pro.init(),
    });

    const setCurrentLocale = async (locale: string) => {
        const momentLocale = locale.split('-')[0];
        momentLocale !== 'en' && (await import(`moment/locale/${momentLocale}`));
        moment.locale(locale);

        if (i18next.language && i18next.language !== locale) {
            i18next.changeLanguage(locale);
        }
    };

    useLayoutEffect(() => {
        if (!data) return;

        const { availablePaymentMethods, client, paymentMethods, user } = data;

        const locale = user.language?.replace('_', '-') ?? 'en-GB';
        setCurrentLocale(locale);

        if (metrics && config.gtm.container) {
            removeCookie(GTM_INITIAL_TRACK_COOKIE_NAME);

            const { href, pathname } = window.location;

            const GTMDefaultDataLayer = {
                [GTMCustomDimensions.ENVIRONMENT]: config.gtm.environment || 'development',
                [GTMCustomDimensions.COUNTRY]: locale,
                [GTMCustomDimensions.TENANT]: TenantUtils.getTenantName(),
                [GTMCustomDimensions.PAGE_TYPE]: GTMPageType.TABLE_PRO,
                [GTMCustomDimensions.PAGE_TITLE]: document.title,
                [GTMCustomDimensions.PAGE_URL]: href,
                [GTMCustomDimensions.PAGE_PATH]: pathname,
                [GTMCustomDimensions.USER_LOGGED_IN]: 'true',
            };

            const defaultConsentBehaviours = {
                ad_storage: 'granted',
                analytics_storage: 'granted',
                functionality_storage: 'granted',
                personalization_storage: 'granted',
                security_storage: 'granted',
            };

            const configuration: IGTMConfiguration = {
                defaultConsentBehaviours,
                ...GTMDefaultDataLayer,
            };

            metrics.addProvider({
                key: config.gtm.container,
                name: ProviderName.GTM,
                configuration,
            });
        }

        batch(() => {
            dispatch(setAvailablePaymentMethods(availablePaymentMethods));
            dispatch(setClient(client));
            dispatch(setPaymentMethods(paymentMethods));
            dispatch(setUser(user));

            setAmplitudeUserInfo();

            // We have to wait until the LD flags are ready to update Amplitude and Sentry
            ldClient?.identify(getIdentifiedUserContext(userId), undefined, (_, flags) => {
                setAmplitudeFeatureFlags(flags);
                setContext(LoggerContext.FEATURE_FLAGS, flags);
            });
            sendAmplitudeEvent(AmplitudeEvents.START_OF_SESSION);
        });
    }, [dispatch, data, metrics, setAmplitudeUserInfo, sendAmplitudeEvent, ldClient, userId, setAmplitudeFeatureFlags]);

    useLayoutEffect(() => {
        if (error) {
            if (error.status !== 401) {
                logSdkError(error);
            }
            removeCookie('token');
            window.location.assign(BrowserContext.getLoginPath(config));
        }
    }, [error]);

    useEffect(() => {
        if (!data) return;
        if (userId && ldClient) {
            ldClient.identify(getIdentifiedUserContext(userId));
        }
    }, [data, ldClient, userId]);

    return {
        initResponse: data,
        error,
        isLoading,
    };
}
