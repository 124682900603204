import React from 'react';
import { SupportTicketType } from '@packlink/packlink-sdk';
import { Icon, IconSize, Typography } from '@shipengine/giger';
import {
    getCheckSelectedIconStyle,
    getTicketTypeStyles,
    getTypeIconStyle,
    getTypeTextStyle,
} from './TicketTypeCardStyle';
import { useTranslation } from '@packlink/translation-provider';
import { IconNames } from '@shipengine/giger-theme';

interface TicketTypeCardProps {
    option: {
        value: SupportTicketType;
        icon: IconNames;
    };
    handleChange: (value: SupportTicketType | null) => void;
    selected: boolean;
}

export const TicketTypeCard: React.FC<TicketTypeCardProps> = ({ option, handleChange, selected }) => {
    const { t } = useTranslation();

    return (
        <div css={getTicketTypeStyles(selected)} key={option.value} onClick={() => handleChange(option.value)}>
            {selected && (
                <Icon name={IconNames.CHECK_FILLED} size={IconSize.SIZE_REGULAR} css={getCheckSelectedIconStyle} />
            )}
            <Icon name={option.icon} size={IconSize.SIZE_REGULAR} css={getTypeIconStyle} />
            <div css={getTypeTextStyle}>
                <Typography variant="heading6">{t(`support-center.ticket-types.${option.value}`)}</Typography>
                <Typography variant="body2">{t(`support-center.ticket-types-info.${option.value}`)}</Typography>
            </div>
        </div>
    );
};
