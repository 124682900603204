import { triggerDownload } from '@utils/download';

export interface ILabelGenerated {
    references?: string[];
    id?: string;
    message?: string;
    url_label?: string;
    status?: string;
}

export const downloadLabel = (data: ILabelGenerated): void => {
    if (!data.url_label) {
        return;
    }

    triggerDownload(data.url_label);
};
