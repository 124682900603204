import { useRef } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { IShippingRule } from '@packlink/packlink-sdk';
import { IconNames } from '@shipengine/giger-theme';
import { useToggle } from '@packlink/utils';

import {
    ButtonColor,
    ButtonIcon,
    ButtonSize,
    ButtonVariant,
    Switch,
    TableBodyCell,
    TableRow,
    Typography,
} from '@shipengine/giger';

import { useDateFormat } from '@hooks/useDateFormat';
import { ruleRowOrderStyles, ruleRowSwitchStyles } from './RuleRowStyles';
import { PopoverCallToAction } from '@components/PopoverCallToAction/PopoverCallToAction';

interface IRuleRowProps {
    rule: IShippingRule;
    index: number;
    switchOrder: (id1: number, id2: number) => void;
    toggleRule: (index: number) => Promise<void> | void;
    editRule: (id: string) => void;
    deleteRule: (index: number, id: string) => void;
    isLastRow: boolean;
}

export function RuleRow(props: IRuleRowProps): JSX.Element {
    const {
        rule: { alias, enabled, updated, id },
        index,
        switchOrder,
        toggleRule,
        editRule,
        deleteRule,
        isLastRow,
    } = props;
    const { t } = useTranslation();
    const { state: activeToggle, toggle } = useToggle(enabled);
    const updatedDate = useDateFormat(updated, 'DD/MM/YYYY HH:mm');
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { state: showPopover, toggle: toggleShowPopover } = useToggle(false);

    const toggleActiveRule = () => {
        toggleRule(index)?.finally(toggle);
    };

    return (
        <TableRow>
            <TableBodyCell>{alias}</TableBodyCell>
            <TableBodyCell>{updatedDate}</TableBodyCell>
            <TableBodyCell css={ruleRowSwitchStyles}>
                <Switch
                    name={`is-enabled-${index}`}
                    value={activeToggle}
                    isChecked={activeToggle}
                    onChange={toggleActiveRule}
                >
                    <p hidden>{`${t('shipping-rules.list.active-header')} ${activeToggle}`}</p>
                </Switch>
            </TableBodyCell>
            <TableBodyCell>
                <div css={ruleRowOrderStyles}>
                    <ButtonIcon
                        variant={ButtonVariant.TEXT}
                        icon={IconNames.ARROW_TOP}
                        size={ButtonSize.SMALL}
                        color={ButtonColor.PRIMARY}
                        ariaDescribedby={t('shipping-rules.list.increase-priority')}
                        onClick={() => switchOrder(index, index - 1)}
                        isInactive={index === 0}
                    >
                        {t('shipping-rules.list.increase-priority')}
                    </ButtonIcon>
                    <ButtonIcon
                        variant={ButtonVariant.TEXT}
                        icon={IconNames.ARROW_BOTTOM}
                        size={ButtonSize.SMALL}
                        color={ButtonColor.PRIMARY}
                        ariaDescribedby={t('shipping-rules.list.decrease-priority')}
                        onClick={() => switchOrder(index, index + 1)}
                        isInactive={isLastRow}
                    >
                        {t('shipping-rules.list.decrease-priority')}
                    </ButtonIcon>
                </div>
            </TableBodyCell>
            <TableBodyCell>
                <div css={ruleRowOrderStyles}>
                    <ButtonIcon
                        variant={ButtonVariant.TEXT}
                        icon={IconNames.EDIT}
                        size={ButtonSize.SMALL}
                        color={ButtonColor.PRIMARY}
                        ariaDescribedby={t('shipping-rules.list.edit-rule')}
                        onClick={() => editRule(id)}
                    >
                        {t('shipping-rules.list.edit-rule')}
                    </ButtonIcon>
                    <ButtonIcon
                        variant={ButtonVariant.TEXT}
                        icon={IconNames.TRASH}
                        size={ButtonSize.SMALL}
                        color={ButtonColor.PRIMARY}
                        ariaDescribedby={t('shipping-rules.list.delete-rule')}
                        onClick={toggleShowPopover}
                        ref={buttonRef}
                    >
                        {t('shipping-rules.list.delete-rule')}
                    </ButtonIcon>
                    <PopoverCallToAction
                        reference={buttonRef.current}
                        isOpen={showPopover}
                        onClickAway={toggleShowPopover}
                        placement="bottom-end"
                        primaryText="shipment-rules.actions.confirm-delete"
                        secondaryText="shipment-rules.actions.cancel"
                        onPrimaryClick={() => deleteRule(index, id)}
                        onSecondaryClick={toggleShowPopover}
                    >
                        <Typography component="span" variant="body2">
                            {t('shipment-rules.delete.body-text')}
                        </Typography>
                        <Typography component="p" variant="body2" bold>
                            {t('shipment-rules.delete.body-caution')}
                        </Typography>
                    </PopoverCallToAction>
                </div>
            </TableBodyCell>
        </TableRow>
    );
}
