import { PaymentMethod, PaymentType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { Button } from '@shipengine/giger';

export interface FreePaymentProps {
    onPay: (event: { type: PaymentType; method: PaymentMethod }) => void;
}
export const FreePayment = ({ onPay }: FreePaymentProps): JSX.Element => {
    const { t } = useTranslation();

    const handleFreePaymentMethodClick = () => {
        onPay({ type: PaymentType.DIRECT, method: PaymentMethod.FREE });
    };

    return (
        <Button
            onClick={() => {
                handleFreePaymentMethodClick();
            }}
        >
            {t('payment.free-payment.label')}
        </Button>
    );
};
