import { WithCommonProps, FileUploader, FileUploaderProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { useMutation } from 'react-query';
import {
    DEFAULT_UPLOAD_TIMEOUT,
    SupportCenterAttachment,
    TEMPORAL_UPLOAD_ID,
    useUploadAttachmentFileValidationSchema,
    useUploadConfirmationEvent,
    useUploadRequest,
} from './UploadAttachmentData';
import { isEmpty } from 'lodash';

export type UploadMultipleAttachmentProps = Pick<FileUploaderProps, 'name' | 'message' | 'disabled' | 'label'> &
    WithCommonProps<{
        onChange: (attachments: SupportCenterAttachment[]) => void;
        onError: (message: string, e?: Error) => void;
        uploadTimeout?: number;
        value: SupportCenterAttachment[];
    }>;

export function UploadMultipleAttachment({
    onChange,
    onError,
    uploadTimeout = DEFAULT_UPLOAD_TIMEOUT,
    value,
    ...props
}: UploadMultipleAttachmentProps): JSX.Element {
    const { t } = useTranslation();
    const { handleChange, isUploading, files } = useFileHandler({
        onChange,
        onError,
        uploadTimeout,
        value,
    });

    return (
        <FileUploader value={files} onChange={handleChange} isLoading={isUploading} {...props}>
            {t('support-center.new-ticket.attachment-button-label')}
        </FileUploader>
    );
}

function useFileHandler({
    value,
    onError,
    onChange,
    uploadTimeout,
}: Required<Pick<UploadMultipleAttachmentProps, 'uploadTimeout' | 'onError' | 'onChange' | 'value'>>) {
    const { t } = useTranslation();
    const cleanUp = () => {
        onChange(value.filter(({ uploadId }) => uploadId !== TEMPORAL_UPLOAD_ID));
    };
    const { eventUnbind } = useUploadConfirmationEvent();
    const fileValidation = useUploadAttachmentFileValidationSchema();
    const requestUpload = useUploadRequest(uploadTimeout);
    const {
        mutateAsync: upload,
        isLoading: isUploading,
        isSuccess: isUploaded,
    } = useMutation(
        async (filesData: SupportCenterAttachment[]) => {
            const uploadedFiles: SupportCenterAttachment[] = [];
            // Need to use a for loop to make sequential requests
            for (let i = 0; i < filesData.length; i++) {
                const { file } = filesData[i];
                const uploadId = await requestUpload(file);
                uploadedFiles.push({ uploadId, file });
            }

            return uploadedFiles;
        },
        {
            // Retries and binary fields does not seem to be a good mix
            retry: 0,
            onSettled: () => eventUnbind(),
        },
    );

    const handleChange = async (files: File[]) => {
        // This is a n^2 operation, but hopefully no-one uploads more than 100 files
        // Find new files without upload and clear deleted files
        const previousFiles: SupportCenterAttachment[] = [];
        const unsavedFiles: SupportCenterAttachment[] = [];
        files.forEach((file) => {
            const uploadedFile = value.find((fileData) => file === fileData.file);
            if (uploadedFile) {
                previousFiles.push(uploadedFile);
            } else {
                unsavedFiles.push({ file, uploadId: TEMPORAL_UPLOAD_ID });
            }
        });

        if (isEmpty(unsavedFiles)) {
            onChange(previousFiles);
            return;
        }

        // Check that format of new files is valid
        try {
            unsavedFiles.forEach(({ file }) => fileValidation.validateSync(file));
        } catch (error: unknown) {
            if (error instanceof Error) {
                onError(error.message, error);
            }
            return;
        }

        // Upload files
        onChange([...previousFiles, ...unsavedFiles]);
        try {
            const uploadedFiles = await upload(unsavedFiles);
            onChange([...previousFiles, ...uploadedFiles]);
        } catch (error: unknown) {
            cleanUp();
            if (error instanceof Error) {
                onError(t('support-center.new-ticket.attachment-error'), error);
            }
        }
    };

    const files = value?.filter(({ uploadId }) => uploadId !== TEMPORAL_UPLOAD_ID).map(({ file }) => file);

    return {
        handleChange,
        isUploading,
        isUploaded,
        files,
    };
}
