import { createSelector } from 'reselect';
import { IAppState } from '@store/reducers';
import { IUser, User } from '@packlink/packlink-sdk';
import { IUserState } from '@store/reducers/user';

const getUser = (store: IAppState): IUserState => store.user;
const getUserData = createSelector(getUser, (user) => User.deserialize<IUser>(user));
export const getUserEmail = createSelector(getUserData, (user) => user.email);
export const getUserLanguage = createSelector(getUserData, (user) => user.language);
export const getUserId = createSelector(getUserData, (user) => user.id);
export const getUserPolicies = createSelector(getUserData, (user) => user.policies);
