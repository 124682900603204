import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonSize, ButtonVariant } from '@shipengine/giger';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { ITenantConfig } from '@types';
import { GTMSectionIds } from '@constants/gtm';
import { EmptyState, IEmptyStateChildrenProps } from '@components/EmptyState/EmptyState';
import { getEmptyListButtonSectionStyles } from '../ShipmentListStyles';

export const ListEmptyState = (props: IEmptyStateChildrenProps): JSX.Element => {
    const { onNewShipment, onConnectShop } = props;
    const { t } = useTranslation();
    const { canConnectToEshop } = useTenantConfig<ITenantConfig>();
    const {
        cdn: { url: cdnUrl },
    } = config;
    const image = `${cdnUrl}/pro/statics/images/empty-state.png`;

    const newShipmentButtonText = t('header.actions.new-shipment');
    const connectYourStoreButtonText = t('header.actions.connect-shop');

    return (
        <EmptyState
            title={t('shipment-list.empty-state.title')}
            subtitle={t('shipment-list.empty-state.subtitle')}
            image={image}
        >
            <section css={getEmptyListButtonSectionStyles}>
                <Button
                    size={ButtonSize.SMALL}
                    onClick={() => onNewShipment(newShipmentButtonText, GTMSectionIds.EMPTY_STATE_FIRST_BUTTON)}
                >
                    {newShipmentButtonText}
                </Button>

                {canConnectToEshop && (
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        size={ButtonSize.SMALL}
                        onClick={() =>
                            onConnectShop(connectYourStoreButtonText, GTMSectionIds.EMPTY_STATE_SECOND_BUTTON)
                        }
                    >
                        {connectYourStoreButtonText}
                    </Button>
                )}
            </section>
        </EmptyState>
    );
};
