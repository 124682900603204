import { WithChildrenCommonProps } from '@shipengine/giger';
import { BrandedSettings } from '@packlink/packlink-sdk';
import { FormManager } from './providers/FormManager/FormManager';

export type BrandedEmailProvidersProps = WithChildrenCommonProps<{
    initialValues?: BrandedSettings;
}>;

export function BrandedEmailProviders({ initialValues, children }: BrandedEmailProvidersProps): JSX.Element {
    return <FormManager initialValues={initialValues}>{children}</FormManager>;
}
