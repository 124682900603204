import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Dialog } from '@shipengine/giger';
import { Spinner } from '@components/Spinner/Spinner';
import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { ApiClientError, IOnboardingStart, OnboardingProvider, OnboardingStart } from '@packlink/packlink-sdk';
import packlink from '@sdk';
import { AppDispatch } from '@store';
import { logSdkError } from '@utils/logger';
import { setSidePanelError } from '@Integrations/store/actions/integrations';
import { APP_ROUTE } from '@pages/router/routes';

interface IActivateStartFlowProps {
    beforeRedirect?: (onboardingStartFlow: OnboardingStart) => Promise<void>;
    onError?: () => void;
    provider: OnboardingProvider;
    integrationAlias: EcommerceAlias;
    pathParam?: string;
}

export const ActivateStartFlow: React.FunctionComponent<IActivateStartFlowProps> = ({
    beforeRedirect,
    onError,
    provider,
    integrationAlias,
    pathParam = '',
}: IActivateStartFlowProps): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const goToIntegration = useCallback((): void => {
        dispatch(setSidePanelError(true));
        navigate(`${APP_ROUTE.SETTINGS.INTEGRATIONS}/${integrationAlias}`);
    }, [dispatch, integrationAlias, navigate]);

    useEffect((): void => {
        packlink.v1.onboarding.login
            .startFlow(provider, pathParam)
            .then(async (onboardingStart: OnboardingStart<IOnboardingStart>): Promise<void> => {
                beforeRedirect && beforeRedirect(onboardingStart);
                window.location.replace(onboardingStart.redirectUrl);
            })
            .catch((error: ApiClientError): void => {
                logSdkError(error);
                onError ? onError() : goToIntegration();
            });
        // TOOD: https://packlink.atlassian.net/browse/PRO-790
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Dialog fullScreen isOpen>
            <Spinner fullScreen={true} />;
        </Dialog>
    );
};
