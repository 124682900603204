import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';
import { SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';

export const shippingRulesPromoStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(3),
        borderTop: `1px solid ${theme.palette.gray.ultraLight}`,
        padding: theme.spacing(),
    });

export const goToProButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(3),
    });

export const sidePanelMessageStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',

        strong: {
            fontWeight: theme.typography.fontWeight.semibold,
        },
    });

export const shippingRulesPromoImgStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
    });

export const iconStyles =
    (iconColor: SidePanelMessageType) =>
    (theme: Theme): SerializedStyles =>
        css({
            marginBottom: theme.spacing(),
            color: getColor(iconColor, theme),
        });

const getColor = (iconColor: SidePanelMessageType, theme: Theme) => {
    switch (iconColor) {
        case SidePanelMessageType.ERROR:
            return theme.palette.error.main;
        case SidePanelMessageType.SUCCESS:
            return theme.palette.success.main;
        case SidePanelMessageType.WARNING:
            return theme.palette.alert.main;
        case SidePanelMessageType.INFO:
        default:
            return theme.palette.info.main;
    }
};
