import { WithCommonProps } from '@shipengine/giger';
import { FastField } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { getFieldsetResetStyles, getFormInputStyles } from '../../../styles/FormStyles';
import { FormField, Input } from '@shipengine/formik-giger';

type SupportTicketPaypalPaymentFieldProps = WithCommonProps<{
    name: string;
}>;

export function SupportTicketPaypalPaymentField({
    name,
    ...otherProps
}: SupportTicketPaypalPaymentFieldProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <fieldset css={getFieldsetResetStyles} {...otherProps}>
            <FormField name={`${name}.account`} css={getFormInputStyles}>
                <FastField
                    component={Input}
                    name={`${name}.account`}
                    label={t('support-center.payment-field.paypal-account-holder-label')}
                />
            </FormField>
        </fieldset>
    );
}
