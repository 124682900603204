import { APP_SIDEBAR_WIDTH } from '../AppSidebar/AppSidebarStyles';
import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getAppHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: theme.spacing(8),
        padding: `0 ${theme.spacing(2)}px`,
        border: `1px solid ${theme.palette.gray.ultraLight}`,
    });

const getAppHeaderButtonsStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',

        '.btn': {
            padding: 0,
        },
    });

export const getMenuDividerStyles = (theme: Theme): SerializedStyles =>
    css({
        width: 2,
        height: 12,
        margin: `0 ${theme.spacing()}px`,
        backgroundColor: theme.palette.gray.light,
    });

export const getBeamerButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: 0,

        '.beamer_icon.active': {
            top: -8,
            right: -8,
            width: theme.spacing(2),
            height: theme.spacing(2),
            lineHeight: `${theme.spacing(2)}px`,
            backgroundColor: `${theme.palette.error.main} !important`,
        },
    });

export const getClearFiltersStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: 0,
        marginLeft: theme.spacing() / 2,
    });

export const getExportCsvIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
    });

export const getLogoWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'none',
        flexShrink: 0,
        width: `calc(${APP_SIDEBAR_WIDTH} - ${theme.spacing(2)}px)`,

        [theme.mediaQuery('tablet')]: {
            display: 'block',
        },
    });

export const getAppHeaderLogoStyles = (theme: Theme): SerializedStyles =>
    css({
        cursor: 'pointer',
        height: theme.spacing(4),
    });

export const getAppHeaderActionsStyles = (theme: Theme): SerializedStyles =>
    css({
        alignItems: 'center',
        display: 'flex',
        flex: '1 0 auto',
        justifyContent: 'flex-end',
        margin: `0 ${theme.spacing(3)}px 0 auto`,
        marginLeft: theme.spacing(3),

        '> div:first-of-type': {
            [theme.mediaQuery('tablet')]: {
                marginRight: theme.spacing(3),
            },
        },
    });

export const getAppHeaderActionsBackStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(3),

        [theme.mediaQuery('tablet')]: {
            marginRight: 0,
        },
    });

export const getAppHeaderFilterStyles = (theme: Theme): SerializedStyles =>
    css(
        {
            marginLeft: theme.spacing(2),
        },
        { ...getAppHeaderButtonsStyles() },
    );

export const getAppHeaderExportStyles = (theme: Theme): SerializedStyles =>
    css(
        {
            borderLeft: `1px solid ${theme.palette.gray.light}`,
            marginLeft: theme.spacing(),
            paddingLeft: theme.spacing(),
        },
        { ...getAppHeaderButtonsStyles() },
    );
