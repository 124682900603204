import moment from 'moment';
import { useMemo } from 'react';
import { useField } from 'formik';

import { DatePicker } from '@shipengine/giger';
import { FormField } from '@shipengine/formik-giger';
import { useTranslation } from '@packlink/translation-provider';

interface IDatesFilterProps {
    name: string;
}

export const DatesFilter = ({ name }: IDatesFilterProps): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const today = new Date();
    const toName = `${name}_to`;
    const fromName = `${name}_from`;

    const [fieldTo, _metaTo, helperTo] = useField<string>(toName);
    const [fieldFrom, _metaFrom, helperFrom] = useField<string>(fromName);

    const fieldFromSelected = useMemo(
        () => (fieldFrom.value ? new Date(fieldFrom.value) : undefined),
        [fieldFrom.value],
    );

    const fieldToSelected = useMemo(() => (fieldTo.value ? new Date(fieldTo.value) : undefined), [fieldTo.value]);

    const FILTER_DATE_FORMAT = 'YYYY-MM-DD';

    const labels = useMemo(
        () => ({
            selectDateIconTooltip: t('datepicker.labels.select-date'),
            previousMonthTooltip: t('datepicker.labels.previous-month'),
            nextMonthTooltip: t('datepicker.labels.next-month'),
            cancelButton: t('datepicker.labels.cancel'),
            confirmButton: t('datepicker.labels.confirm'),
        }),
        [t],
    );

    const handleConfirm = (name: string, value: Date | [Date, Date]): void => {
        if (Array.isArray(value)) return; // Only possible in range datepicker, which is not the case. Typescript safe check.

        const stringDate = moment(value).format(FILTER_DATE_FORMAT);

        if (name === fromName) {
            helperFrom.setValue(stringDate);
        } else {
            helperTo.setValue(stringDate);
        }
    };

    return (
        <>
            <FormField name={fromName}>
                <DatePicker
                    highlightDates={[today]}
                    label={t('filters.panel.from')}
                    labels={labels}
                    locale={locale}
                    maxDate={fieldToSelected || today}
                    selected={fieldFromSelected}
                    onClickConfirm={(date: Date | [Date, Date]) => handleConfirm(fromName, date)}
                />
            </FormField>
            <FormField name={toName}>
                <DatePicker
                    highlightDates={[today]}
                    label={t('filters.panel.to')}
                    labels={labels}
                    locale={locale}
                    minDate={fieldFromSelected}
                    maxDate={today}
                    selected={fieldToSelected}
                    onClickConfirm={(date: Date | [Date, Date]) => handleConfirm(toName, date)}
                />
            </FormField>
        </>
    );
};
