import { useFormikContext } from 'formik';
import { Button, ButtonVariant, WithCommonProps } from '@shipengine/giger';
import { AmplitudeEvents } from '@constants/amplitude';
import { IFiltersForm } from '../types';
import { deleteFilterStyles } from './DeleteFilterStyles';
import { useAmplitude } from '@hooks/useAmplitude';

interface IDeleteFilterProps {
    filters: string[];
}

export const DeleteFilter = ({ filters, children, ...rest }: WithCommonProps<IDeleteFilterProps>): JSX.Element => {
    const { setFieldValue } = useFormikContext<IFiltersForm>();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const deleteFilters = () => {
        filters.forEach((filter: string) => {
            setFieldValue(filter, undefined);
        });

        sendAmplitudeSidebarClickEvent(AmplitudeEvents.DELETE_FILTERS);
    };

    return (
        <div css={deleteFilterStyles} {...rest}>
            <Button onClick={deleteFilters} variant={ButtonVariant.TEXT}>
                {children}
            </Button>
        </div>
    );
};
