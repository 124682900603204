import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSectionStyles =
    (showBackground = false, hideMargins = false) =>
    (theme: Theme): SerializedStyles =>
        css({
            marginBottom: showBackground ? theme.spacing(2) : theme.spacing(3),

            ...(hideMargins && { margin: 0 }),

            ...(showBackground && {
                padding: theme.spacing(2),
                backgroundColor: theme.palette.gray.megaLight,
                borderBottom: `1px solid ${theme.palette.gray.ultraLight}`,
            }),
        });

export const getTextStyles =
    (hideMargins = false) =>
    (): SerializedStyles =>
        css({
            ...(hideMargins && { margin: 0 }),
        });
