import { Pagination, TableFooter } from '@shipengine/giger';
import { Trans } from '@packlink/translation-provider';
import { getTableFooterStyles } from './InvoiceTableStyles';
import { INVOICES_PAGINATION_DEFAULT } from '@Invoicing/hooks/useInvoicesData';

interface InvoiceTableFooterProps {
    onPageChange: (page: number) => void;
    pagination?: {
        currentPage: number;
        totalPages: number;
        totalElements: number;
    };
}
export const InvoiceTableFooter = ({ onPageChange, pagination }: InvoiceTableFooterProps): JSX.Element => {
    const { pageSize: defaultPageSize, pagination: defaultPagination } = INVOICES_PAGINATION_DEFAULT;
    const { totalElements, currentPage } = pagination ?? defaultPagination;
    const firstElementInPage = (currentPage - 1) * defaultPageSize + 1;
    const lastElementInPage = Math.min(currentPage * defaultPageSize, totalElements);
    const showPagination = totalElements > defaultPageSize;

    return (
        <TableFooter css={getTableFooterStyles}>
            <span>
                <Trans
                    i18nKey="invoice-table.footer.showing"
                    values={{
                        first: firstElementInPage,
                        last: lastElementInPage,
                        total: totalElements,
                    }}
                />
            </span>
            {showPagination && (
                <Pagination
                    totalPages={pagination?.totalPages ?? 1}
                    currentPage={pagination?.currentPage ?? defaultPagination.currentPage}
                    onPageChange={onPageChange}
                />
            )}
        </TableFooter>
    );
};
