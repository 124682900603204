import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getAddressStyles =
    (isSelected: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row-reverse',
            width: 'calc(50% - 10px)',
            minHeight: '135px',
            marginBottom: theme.spacing(3),
            overflow: 'hidden',
            padding: theme.spacing(2),
            border: `1px solid ${theme.palette.gray.main}`,
            borderRadius: theme.borderRadius.S,
            background: theme.palette.gray.ultraLight,

            ...(isSelected && {
                background: theme.palette.primary.ultraLight,
                borderColor: theme.palette.primary.main,
            }),

            ':hover': {
                borderColor: theme.palette.primary.main,
                cursor: 'pointer',
            },
        });
export const getAddressContentStyles = (): SerializedStyles =>
    css({
        overflow: 'hidden',
    });
export const getAddressHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(),
    });
export const getAddressTitleStyles = (): SerializedStyles =>
    css({
        margin: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    });
export const getAddressTextStyles = (): SerializedStyles =>
    css({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
    });
export const getAddressTextAddressStyles = (): SerializedStyles =>
    css({
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    });
export const getAddressFooterStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(0.5),
    });
