import { WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { PostsaleSection } from '../PostsaleSection/PostsaleSection';

type CustomsDisclaimerProps = WithCommonProps<unknown>;

export function CustomsDisclaimer(props: CustomsDisclaimerProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <PostsaleSection title={t('post-sale.customs.title')} {...props}>
            <p dangerouslySetInnerHTML={{ __html: t('post-sale.customs.regular-disclaimer') }} />
        </PostsaleSection>
    );
}
