import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const deleteFilterStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),

        '.btn': {
            padding: 0,
        },
    });
