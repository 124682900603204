import { useQuery } from 'react-query';
import { apiClient } from '@sdk';
import { ConfigurationRepository, Integration, IntegrationRepository } from '@packlink/packlink-sdk';
import { ITenantConfig } from '@types';
import { IntegrationConfigurations } from '@Integrations/components/IntegrationPanel/types';
import {
    addConfiguration,
    addLogo,
    addRank,
    hideIntegrationCard,
    isNotHidden,
    moduleIntegrationToIntegration,
    orderBasedOnRank,
} from '@Integrations/services/integrations-service';
import { useTenantConfig } from '@packlink/tenant-config-provider';

const configurationRepository = new ConfigurationRepository(apiClient);
const integrationRepository = new IntegrationRepository(apiClient);

export const GET_ALL_CONFIGURATIONS_QUERY_KEY = 'getAllConfigurations';
export const GET_ALL_INBOUND_INTEGRATIONS_QUERY_KEY = 'getAllInboundIntegrations';

export const useIntegrations = () => {
    const { integrations: tenantIntegrations, moduleIntegrations: tenantModuleIntegrations } =
        useTenantConfig<ITenantConfig>();

    const { data: allConfigurations } = useQuery({
        queryKey: GET_ALL_CONFIGURATIONS_QUERY_KEY,
        queryFn: () => configurationRepository.getAllConfigurations() as unknown as IntegrationConfigurations[],
        // useGoogleTagManager uses this hook because needs the integrations, this can potentially call the backend in a lot of pages
        // we'll handle this by invalidating the query when the user creates/deletes/updates a configuration
        staleTime: Infinity,
    });

    const { data: allInboundIntegrations } = useQuery({
        queryKey: GET_ALL_INBOUND_INTEGRATIONS_QUERY_KEY,
        queryFn: () =>
            integrationRepository.getAllInboundIntegrations() as unknown as Integration<IntegrationConfigurations>[],
        select: (data: Integration<IntegrationConfigurations>[]) => {
            const mappedModuleIntegrations =
                Object.values(tenantModuleIntegrations).map(moduleIntegrationToIntegration);

            return data
                .concat(mappedModuleIntegrations)
                .map((nonConfiguredIntegration: Integration<IntegrationConfigurations>) =>
                    addConfiguration(nonConfiguredIntegration, allConfigurations as IntegrationConfigurations[]),
                )
                .filter(isNotHidden)
                .map((integration: Integration<IntegrationConfigurations>) => {
                    if (!tenantIntegrations[integration.alias]) {
                        hideIntegrationCard(integration);
                    }

                    return addRank(addLogo(integration), tenantIntegrations[integration.alias]?.rank_weight);
                })
                .sort(orderBasedOnRank);
        },
        enabled: !!allConfigurations,
        staleTime: Infinity,
    });

    return {
        integrations: allInboundIntegrations,
        connectedIntegrationNames:
            allInboundIntegrations
                ?.filter((integration: Integration) => integration.isConnected)
                .map((activeIntegration: Integration) => activeIntegration.name) || [],
    };
};
