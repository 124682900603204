import { Outlet } from 'react-router';
import { Toaster } from '@shipengine/giger';
import { BrandName } from '@packlink/brands';
import { useTenant } from '@packlink/tenant-provider';
import { TenantName } from '@packlink/packlink-sdk';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { AppFooter } from '@components/AppFooter/AppFooter';
import { GlobalEventHandler } from '@components/GlobalEventHandler/GlobalEventHandler';
import { ImportOrders } from '@Integrations/components/ImportOrders/ImportOrders';
import { RouterTracker } from './router/components/RouterTracker/RouterTracker';
import { getBodyStyles, getRootStyles } from './RootStyles';
import { SupportWidget } from '@SupportWidget/components/SupportWidget';
import { GoogleMapsStarter } from '@components/GoogleMapsStarter/GoogleMapsStarter';
import { ITenantConfig } from '@types';

export function Root(): JSX.Element {
    const { brand } = useTenantConfig<ITenantConfig>();
    const { name } = useTenant();

    const hasAppTopBorder = brand === BrandName.CORREOS;
    const isTenantDPDBEUN = name === TenantName.DPDBBEUN;

    return (
        <>
            <div css={getRootStyles(hasAppTopBorder)}>
                <div css={getBodyStyles} id="main-scrollable-window">
                    <Outlet />
                </div>

                <AppFooter />
            </div>

            <RouterTracker />
            <ImportOrders />
            {!isTenantDPDBEUN && <SupportWidget />}
            <GlobalEventHandler />
            <GoogleMapsStarter />
            <Toaster position="bottom-right" />
        </>
    );
}
