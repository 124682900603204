import { Checkbox, TableBaseRow, TableHeader, TableHeaderCell } from '@shipengine/giger';
import { getTableCheckboxColumnStyles } from '@Invoicing/components/InvoiceTable/InvoiceTableStyles';
import { useTranslation } from '@packlink/translation-provider';
import React from 'react';

interface InvoiceTableHeaderProps {
    hasInvoices: boolean;
    hasSelectedInvoices: boolean;
    isIndeterminate: boolean;
    handleCheck: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InvoiceTableHeader = (props: InvoiceTableHeaderProps): JSX.Element => {
    const { hasInvoices, hasSelectedInvoices, isIndeterminate, handleCheck } = props;
    const { t } = useTranslation();

    return (
        <TableHeader>
            <TableBaseRow>
                {hasInvoices && (
                    <TableHeaderCell css={getTableCheckboxColumnStyles}>
                        <Checkbox
                            className="invoice-table__checkbox-input"
                            name="bulkInvoice"
                            checked={hasSelectedInvoices}
                            onChange={handleCheck}
                            indeterminate={isIndeterminate}
                        />
                    </TableHeaderCell>
                )}
                <TableHeaderCell>{t('invoice-table.header.id')}</TableHeaderCell>
                <TableHeaderCell>{t('invoice-table.header.date')}</TableHeaderCell>
                <TableHeaderCell>{t('invoice-table.header.amount')}</TableHeaderCell>
                <TableHeaderCell>{t('invoice-table.header.download')}</TableHeaderCell>
            </TableBaseRow>
        </TableHeader>
    );
};
