import { useQuery, UseQueryResult } from 'react-query';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { onReadOnlyQueryRetry } from '../../utils/reactQuery';
import { useDebouncedValue } from '../../utils/useDebouncedValue';
import { AnySupportTicket, ApiClientError, SupportTicketStatus } from '@packlink/packlink-sdk';
import { isEmpty, some } from 'lodash';

export type SupportTicketsTableFiltersData = {
    shipmentReference: string;
    status?: SupportTicketStatus;
};

export type SupportTicketsTableQueryData = {
    tickets: AnySupportTicket[];
    activeFilters?: SupportTicketsTableFiltersData;
};

const SUPPORT_TICKETS_QUERY_KEYS = ['support-center', 'previous-tickets'];

export function useSupportTicketsTableData(
    filters: SupportTicketsTableFiltersData,
): UseQueryResult<SupportTicketsTableQueryData, ApiClientError> {
    const sdk = useSdk();
    const debouncedFilters = useDebouncedValue(filters, 300);

    // Filters are needed for some UX / UI (show a different message in table view)
    // We included them in the API response to be synced with the data
    return useQuery<SupportTicketsTableQueryData, ApiClientError>(
        [...SUPPORT_TICKETS_QUERY_KEYS, debouncedFilters],
        async () => {
            const tickets = await sdk.v1.supportCenter.list(debouncedFilters);
            return {
                tickets,
                activeFilters: debouncedFilters,
            };
        },
        {
            retry: onReadOnlyQueryRetry,
        },
    );
}

export function getSupportTicketsTableFlags(data: SupportTicketsTableQueryData | undefined): {
    hasNoResults: boolean;
    hasActiveFilters: boolean;
} {
    if (!data) {
        return {
            hasNoResults: true,
            hasActiveFilters: false,
        };
    }
    const hasNoResults = isEmpty(data.tickets);
    const hasActiveFilters = some(data.activeFilters, (filter) => !!filter);

    return {
        hasNoResults,
        hasActiveFilters,
    };
}
