import { useMutation, useQueryClient } from 'react-query';
import { CreateClientSubscriptionPayload, SubscriptionsRepository } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { SUBSCRIPTIONS_CACHE_KEY } from '../constants';

const subscriptionsRepository = new SubscriptionsRepository(apiClient);

export function useCreateClientSubscription() {
    const queryClient = useQueryClient();

    const { mutate: createClientSubscription } = useMutation(
        (params: CreateClientSubscriptionPayload) => subscriptionsRepository.createClientSubscription(params),
        {
            onSuccess: () => queryClient.invalidateQueries(SUBSCRIPTIONS_CACHE_KEY),
        },
    );

    return {
        createClientSubscription,
    };
}
