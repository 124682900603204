import { SidebarMenu } from '@shipengine/giger';
import { TemplateForm } from '../TemplateForm/TemplateForm';
import { getSidebarStyles } from './SidebarStyles';

export function Sidebar(): JSX.Element {
    return (
        <SidebarMenu css={getSidebarStyles}>
            <TemplateForm />
        </SidebarMenu>
    );
}
