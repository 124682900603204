import { Divider, Spinner, SpinnerSize, WithCommonProps } from '@shipengine/giger';
import { AnySupportTicket, SupportTicketHelpers, SupportTicketMessage } from '@packlink/packlink-sdk';
import { getSupportCenterCardStyles } from '@SupportCenter/SupportCenterStyles';
import { TicketChatTimeline } from './components/TicketChatTimeline/TicketChatTimeline';
import { TicketChatHeader } from './components/TicketChatHeader/TicketChatHeader';
import { TicketChatSendMessage } from './components/TicketChatSendMessage/TicketChatSendMessage';
import { getTicketChatSpinnerWrapper, getTicketChatStyles } from './TicketChatStyle';

type TicketChatProps = WithCommonProps<{
    supportTicket: AnySupportTicket | undefined;
    messages: SupportTicketMessage[] | undefined;
    isLoading?: boolean;
    onNewMessage: (data: SupportTicketMessage) => void;
}>;

export function TicketChat({
    supportTicket,
    messages,
    isLoading,
    onNewMessage,
    ...props
}: TicketChatProps): JSX.Element {
    const hasAvailableData = !isLoading && !!supportTicket && !!messages;
    const numberOfMessages = messages?.length || 0;

    return (
        <section css={[getTicketChatStyles, getSupportCenterCardStyles]} {...props}>
            <TicketChatHeader data-id="ticket-chat-header" numberOfMessages={numberOfMessages} />
            <Divider />
            {isLoading && (
                <div css={getTicketChatSpinnerWrapper} data-id="ticket-chat-timeline-loading">
                    <Spinner size={SpinnerSize.SIZE_LARGE} />
                </div>
            )}
            {hasAvailableData && (
                <>
                    <TicketChatTimeline
                        data-id="ticket-chat-timeline"
                        supportTicket={supportTicket}
                        messages={messages}
                    />
                    <Divider />
                    {!SupportTicketHelpers.isTicketClosed(supportTicket) && (
                        <TicketChatSendMessage
                            data-id="ticket-chat-input"
                            supportTicket={supportTicket}
                            onNewMessage={onNewMessage}
                        />
                    )}
                </>
            )}
        </section>
    );
}
