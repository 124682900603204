import { OwnContractList } from '@OwnContract/components/OwnContractList';
import { useTranslation } from '@packlink/translation-provider';
import { ClientFeature } from '@packlink/packlink-sdk';
import { useSubscriptionFeature } from '@hooks/useSubscriptionFeatureBehaviours';
import { SubscriptionNotice } from '@Subscriptions/components/SubscriptionNotice/SubscriptionNotice';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { SubscriptionNotification } from '@common/components/SubscriptionNotification/SubscriptionNotification';
import { SettingsPage, SettingsPageHeader } from '@components/SettingsPage';
import { getNotificationSpacingStyles } from '@common/components/SubscriptionNotification/SubscriptionNotificationStyles';

export const OwnContractSettings = (): JSX.Element => {
    const { t } = useTranslation();
    const { isTenantSubscriptionEnabled, subscription, feature } = useSubscriptionFeature(ClientFeature.OWN_CONTRACT);
    const isSubscriptionFeatureNotEnabled = isTenantSubscriptionEnabled && !feature.isEnabled;

    return (
        <SettingsPage>
            <SettingsPageHeader
                description={isSubscriptionFeatureNotEnabled ? undefined : t('own-contract.settings.description')}
            >
                {t('own-contract.settings.title')}
            </SettingsPageHeader>

            {isSubscriptionFeatureNotEnabled && (
                <SubscriptionNotification
                    css={getNotificationSpacingStyles}
                    title={t('subscriptions.upgrade-notification.own-contracts')}
                    amplitudeEvent={`${AmplitudeEvents.SETTINGS_TOOLTIP_OWN_CONTRACTS} ${AmplitudeEventPrefixes.CONFIGURATION_MENU}`}
                >
                    {t('subscriptions.upgrade-notification.own-contracts-message')}
                </SubscriptionNotification>
            )}

            {isTenantSubscriptionEnabled && subscription?.isCanceled && (
                <SubscriptionNotice
                    planName={subscription.planName}
                    isSubscriptionActive={subscription.isActive}
                    isSubscriptionCancelled={subscription.isCanceled}
                    section="carrier contracts"
                    activeMessage="subscription-notification.active.message-oc"
                    expirationDate={subscription?.currentPeriodEndsAt}
                />
            )}
            <OwnContractList />
        </SettingsPage>
    );
};
