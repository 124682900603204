import { useField } from 'formik';
import { Switch } from '@shipengine/giger';
import { doubleFieldFilterStyles } from './DoubleFieldFilterStyles';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

interface IDoubleSwitchFilterProps {
    name: string;
    firstSwitchText: string;
    secondSwitchText: string;
    isFirstTrueSwitch: boolean; // Which switch is the one that reads as true in the request param
}

export const DoubleSwitchFilter = (props: IDoubleSwitchFilterProps): JSX.Element => {
    const { name, firstSwitchText, secondSwitchText, isFirstTrueSwitch } = props;
    const firstName = `${name}_first`;
    const secondName = `${name}_second`;
    const [field, meta, helper] = useField<boolean | string | undefined>(name);

    const currentFilterValue = useMemo(() => {
        // UI Router returns a string but formik knows it's a boolean so we need to parse the value
        if (field.value === true || field.value === 'true') {
            return true;
        } else if (field.value === false || field.value === 'false') {
            return false;
        } else {
            // Undefined state is when no filter is applied
            return undefined;
        }
    }, [field.value]);

    // Initial states will depend on whether the value for the filter
    // is true and that switch is the one that represents "true"
    const [firstSwitchValue, setFirstSwitchValue] = useState<boolean>(
        (isFirstTrueSwitch && !!currentFilterValue) ||
            (!isFirstTrueSwitch && currentFilterValue !== undefined && !currentFilterValue),
    );
    const [secondSwitchValue, setSecondSwitchValue] = useState<boolean>(
        (!isFirstTrueSwitch && !!currentFilterValue) ||
            (isFirstTrueSwitch && currentFilterValue !== undefined && !currentFilterValue),
    );

    useEffect(() => {
        // Reset both switches when the "remove filters" button is clicked within the panel
        if (currentFilterValue === undefined) {
            setFirstSwitchValue(false);
            setSecondSwitchValue(false);
        }
    }, [currentFilterValue]);

    const handleChange = ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>): void => {
        const isFirstClicked = name === firstName;
        const trueSwitchClicked = (isFirstTrueSwitch && isFirstClicked) || (!isFirstTrueSwitch && !isFirstClicked);
        const filterValue = checked ? trueSwitchClicked : undefined;
        helper.setValue(filterValue);
        // Only one switch can be on
        if (isFirstClicked) {
            setFirstSwitchValue(checked);
            checked && secondSwitchValue && setSecondSwitchValue(false);
        } else if (!isFirstClicked) {
            setSecondSwitchValue(checked);
            checked && firstSwitchValue && setFirstSwitchValue(false);
        }
    };

    return (
        <div css={doubleFieldFilterStyles}>
            <Switch
                name={firstName}
                value={!!currentFilterValue}
                isChecked={firstSwitchValue}
                onChange={handleChange}
                error={meta.error}
            >
                {firstSwitchText}
            </Switch>
            <Switch
                name={secondName}
                value={!!currentFilterValue}
                isChecked={secondSwitchValue}
                onChange={handleChange}
                error={meta.error}
            >
                {secondSwitchText}
            </Switch>
        </div>
    );
};
