import { useQuery } from 'react-query';

import { Client, OtpRepository } from '@packlink/packlink-sdk';
import Packlink from '@sdk';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';
import { setClient } from '@store/actions/client';
import { getClientData } from '@store/selectors/client';

export const GET_CLIENT_QUERY_KEY = 'getClient';
export function useRequiresPhoneNumberVerification() {
    const optRepository = new OtpRepository(Packlink.apiClient);
    const client = useSelector(getClientData);
    const dispatch = useDispatch<AppDispatch>();

    const { data: phoneNumberVerificationStatus, isLoading: isClientRequiresPhoneNumberVerificationLoading } = useQuery(
        {
            queryFn: () => optRepository.getOtpStatus(),
        },
    );

    const { isLoading: isClientDataLoading, isSuccess: isClientDataSuccess } = useQuery<Client>({
        queryKey: [GET_CLIENT_QUERY_KEY],
        queryFn: () => Packlink.v1.clients.get(),
        enabled: phoneNumberVerificationStatus?.active,
        onSuccess: (clientResponse) => {
            dispatch(setClient(clientResponse));
        },
    });

    return {
        clientRequiresPhoneNumberVerification:
            phoneNumberVerificationStatus?.active && isClientDataSuccess && !client?.phoneNumberVerified,
        isClientRequiresPhoneNumberVerificationLoading:
            isClientRequiresPhoneNumberVerificationLoading || isClientDataLoading,
        isRequiresPhoneNumberVerificationEnabled: phoneNumberVerificationStatus?.active,
        isPhoneNumberVerified: client?.phoneNumberVerified,
    };
}
