import { isEqualWith } from 'lodash';
import { useNavigate } from 'react-router';
import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { BrandedSettingsSaveParams } from '@packlink/packlink-sdk';
import { useToggle } from '@packlink/utils';
import { useAmplitude, getViewAmplitudePayload } from '@hooks/useAmplitude';
import { Button, ButtonSize, ButtonVariant, Icon, IconSize } from '@shipengine/giger';
import { FormFields } from '../../providers/FormManager/FormManager';
import { BackDialog } from '../BackDialog/BackDialog';
import { getHeaderStyles, getIconStyles, getBackButtonStyles } from './HeaderStyles';
import { AmplitudeEventsBulkBrandedEmails } from '@BrandedEmails/metrics/amplitude';
import { APP_ROUTE } from '@pages/router/routes';
import { useFormikContext } from 'formik';

export function Header(): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const navigate = useNavigate();
    const { values: currentValues, initialValues, handleSubmit, isSubmitting } = useFormikContext<FormFields>();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const { state: isDialogOpen, toggle: toggleDialog } = useToggle();

    const handleGoBack = () => {
        sendAmplitudeClickEvent(
            AmplitudeEventsBulkBrandedEmails.BRANDED_EMAILS_GO_BACK,
            getViewAmplitudePayload(locale),
        );

        if (isEqualWith(currentValues, initialValues, customizer)) {
            navigate(APP_ROUTE.SETTINGS.BRANDED_EMAILS);
        } else {
            toggleDialog();
        }
    };

    const handleSave = async () => {
        sendAmplitudeClickEvent(
            AmplitudeEventsBulkBrandedEmails.BRANDED_EMAILS_SAVE_BRANDED_EMAIL_MODAL,
            getViewAmplitudePayload(locale),
        );

        await handleSubmit();
        toggleDialog();
    };

    const handleCancel = () => {
        toggleDialog();
        navigate(APP_ROUTE.SETTINGS.BRANDED_EMAILS);
    };

    return (
        <>
            <header css={getHeaderStyles}>
                <Button
                    css={getBackButtonStyles}
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.TEXT}
                    onClick={handleGoBack}
                >
                    <Icon css={getIconStyles} name={IconNames.CHEVRON_LEFT} size={IconSize.SIZE_SMALL} />
                    {t('branded-emails.header.go-back')}
                </Button>

                <Button
                    size={ButtonSize.SMALL}
                    type="submit"
                    isLoading={isSubmitting}
                    onClick={() =>
                        sendAmplitudeClickEvent(
                            AmplitudeEventsBulkBrandedEmails.BRANDED_EMAILS_SAVE_BRANDED_EMAIL,
                            getViewAmplitudePayload(locale),
                        )
                    }
                >
                    {t('branded-emails.header.save')}
                </Button>
            </header>

            <BackDialog
                onSave={handleSave}
                onCancel={handleCancel}
                isOpen={isDialogOpen}
                onClose={toggleDialog}
                isSubmitting={isSubmitting}
            ></BackDialog>
        </>
    );
}

type CustomizerParam = Partial<BrandedSettingsSaveParams> | string | null | undefined;
function customizer(value: CustomizerParam, otherValue?: CustomizerParam) {
    if (typeof value === 'object' && typeof otherValue === 'object') return undefined;

    // We want to check each property against falsy values, treat as equal "", undefined and null
    return value === otherValue || (!value && !otherValue);
}
