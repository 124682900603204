import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { EcommerceAlias, Ecommerces } from '@Integrations/constants/ecommerce';
import { setSidePanelError } from '@Integrations/store/actions/integrations';
import { OnboardingProvider } from '@packlink/packlink-sdk';
import { AppDispatch } from '@store';
import { BrowserContext } from '@utils/BrowserContext';
import { APP_ROUTE } from '@pages/router/routes';

import { ActivateCompleteFlow } from '../ActivateCompleteFlow';

export const AmazonActivateCompleteFlow: FC = (): JSX.Element | null => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const goToIntegrationsList = useCallback((): void => {
        dispatch(setSidePanelError(true));
        navigate(`${APP_ROUTE.SETTINGS.INTEGRATIONS}/${OnboardingProvider.AMAZON}`);
    }, [dispatch, navigate]);

    const onError = async (): Promise<void> => {
        goToIntegrationsList();
    };

    const queryParams = BrowserContext.getQueryParams();
    const state = queryParams.get('state');
    const selling_partner_id = queryParams.get('selling_partner_id');
    const spapi_oauth_code = queryParams.get('spapi_oauth_code');

    useEffect(() => {
        if (!state || !selling_partner_id || !spapi_oauth_code) {
            goToIntegrationsList();
        }
    }, [goToIntegrationsList, selling_partner_id, spapi_oauth_code, state]);

    return (
        <ActivateCompleteFlow
            onError={onError}
            params={{
                state,
                selling_partner_id,
                spapi_oauth_code,
            }}
            integration={{
                name: Ecommerces.Amazon,
                alias: EcommerceAlias.Amazon,
            }}
            provider={OnboardingProvider.AMAZON}
        />
    );
};
