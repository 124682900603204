import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import useUnmount from 'react-use/lib/useUnmount';
import Packlink from '@sdk';
import { AppDispatch } from '@store';
import { setBulkVoucher } from '@store/actions/bulk';
import { IPrice, IVoucher, IVoucherValidateBulkShipments, Voucher, ProShipment } from '@packlink/packlink-sdk';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { keyBy } from 'lodash';
import { useAmplitude } from '@hooks/useAmplitude';

export function useBulkPaymentVoucher(
    selectedShipments: ProShipment[],
    isPaying: boolean,
    priceByShipmentRef: Record<string, IPrice>,
) {
    const dispatch = useDispatch<AppDispatch>();
    const { sendAmplitudeEvent } = useAmplitude();

    const voucherQueryKeys = selectedShipments.map((proShipment: ProShipment) => proShipment.data.service?.id);

    const { data: voucherAvailability } = useQuery(
        ['voucherAvailability', ...voucherQueryKeys],
        () =>
            Packlink.v1.vouchers.availability({
                service_ids: selectedShipments.map(
                    (proShipment: ProShipment) => proShipment.data.service?.id as number,
                ),
            }),
        { enabled: !isPaying && selectedShipments.length > 0 },
    );

    const isVoucherAvailable = voucherAvailability?.some(({ allowsVoucher }) => allowsVoucher);

    const voucherBulkParams: IVoucherValidateBulkShipments[] = useMemo(
        () =>
            selectedShipments.map((shipment) => ({
                packlinkReference: shipment.data.packlinkReference,
                serviceId: shipment.data.service?.id as number,
                totalBasePrice: priceByShipmentRef[shipment.data.packlinkReference]?.price.basePrice as number,
            })),
        [priceByShipmentRef, selectedShipments],
    );

    const applyVoucher = (voucher: Voucher<IVoucher> | Voucher<IVoucher>[]) => {
        const vouchers = Array.isArray(voucher)
            ? keyBy(
                  voucher.map((v) => v.toJSON()),
                  'shipmentReference',
              )
            : { [selectedShipments[0].data.packlinkReference]: voucher.toJSON() };

        dispatch(setBulkVoucher(vouchers));
        sendAmplitudeEvent(AmplitudeEvents.APPLY_PROMO_CODE, {
            [AmplitudeProperties.IS_VALID]: true,
            [AmplitudeProperties.SECTION]: 'side panel',
        });
    };

    const removeVoucher = () => {
        dispatch(setBulkVoucher({}));
    };

    useUnmount(() => dispatch(setBulkVoucher({})));

    return {
        isVoucherAvailable,
        voucherBulkParams,
        applyVoucher,
        removeVoucher,
    };
}
