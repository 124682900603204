import { useCallback, useEffect, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { useTranslation } from '@packlink/translation-provider';

import { LocationUtils } from '@utils/LocationUtils';
import { IPostalCode } from '@packlink/packlink-sdk';
import { Field } from 'formik';
import { FormField, SelectAutocomplete } from '@shipengine/formik-giger';
import { ISelectAutocompleteOption } from '@shipengine/giger';
import { getPostalSelectorFieldStyles } from '@components/LocationForm/LocationFormStyles';

export interface IPostalCodeSelectorProps {
    name: string;
    alpha2Code?: string;
    postalZoneId?: string;
    error?: string;
    touched?: boolean;
    isDisabled?: boolean;
    readOnly?: boolean;
    onChange: (postalCode: IPostalCode) => void;
    onBlur?: (name: string) => void;
}

export const PostalCodeSelector = (props: IPostalCodeSelectorProps): JSX.Element => {
    const { name, postalZoneId, alpha2Code, touched, isDisabled, readOnly, onChange, onBlur } = props;
    const [postalCodes, setPostalCodes] = useState<IPostalCode[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const postalCodeOptions = useCallback(() => {
        if (postalCodes?.length === 0) {
            return [];
        }
        const getParsedOption = (option: IPostalCode) => {
            const getOptionLabel = (option: IPostalCode): string => option?.text;
            const getOptionValue = (option: IPostalCode): string => option?.id;
            return { label: getOptionLabel(option), value: getOptionValue(option) };
        };

        return postalCodes.map(getParsedOption);
    }, [postalCodes]);

    const fetchPostalCodes = useCallback((): Promise<IPostalCode[]> => {
        return alpha2Code && !postalZoneId
            ? LocationUtils.getPostalCodesByAlpha2Code(alpha2Code, inputValue)
            : !alpha2Code && postalZoneId
              ? LocationUtils.getPostalCodes(locale, postalZoneId, inputValue)
              : new Promise(() => []);
    }, [postalZoneId, alpha2Code, inputValue, locale]);

    const handleChange = useCallback(
        (_name: string, option: ISelectAutocompleteOption): void => {
            const selectedPostalCode = postalCodes.find((postalCode: IPostalCode) => postalCode.id === option?.value);
            if (!selectedPostalCode) {
                return;
            }
            onChange(selectedPostalCode);
        },
        [onChange, postalCodes],
    );
    useDebounce(
        () => {
            if (inputValue) {
                fetchPostalCodes().then(setPostalCodes);
            }
        },
        500,
        [inputValue],
    );
    const handleInputChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
        setInputValue(value);
    }, []);

    const noOptionsMessage = (): string =>
        inputValue
            ? t('autocomplete.messages.no-results-found')
            : t('autocomplete.messages.start-typing', { field: t('form.label.city-or-zipcode') });

    useEffect((): void => {
        setPostalCodes([]);
    }, [postalZoneId, alpha2Code]);

    return (
        <div css={getPostalSelectorFieldStyles}>
            <FormField name={name}>
                <Field
                    component={SelectAutocomplete}
                    options={postalCodeOptions()}
                    name={name}
                    label={t('form.label.city-or-zipcode')}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    touched={touched}
                    onBlur={onBlur}
                    noResultsLabel={noOptionsMessage()}
                    disabled={!(postalZoneId || alpha2Code) || isDisabled}
                    readOnly={readOnly}
                />
            </FormField>
        </div>
    );
};
