import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const addressLineStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(0.5),

        ':last-of-type': {
            marginBottom: 0,
        },
    });
