import { WithCommonProps } from '@shipengine/giger';
import { getSupportHeaderActionsStyles } from './SupportCenterHeaderActionsStyles';
import { FrequentlyAskedQuestionsLink } from '@SupportCenter/components/FrequentlyAskedQuestionsLink/FrequentlyAskedQuestionsLink';

type SupportCenterHeaderActionsProps = WithCommonProps<{
    showFrequentlyAskedQuestionsButton?: boolean;
}>;

export function SupportCenterHeaderActions({
    showFrequentlyAskedQuestionsButton = true,
    ...props
}: SupportCenterHeaderActionsProps): JSX.Element {
    return (
        <div {...props} css={getSupportHeaderActionsStyles}>
            {showFrequentlyAskedQuestionsButton && <FrequentlyAskedQuestionsLink />}
        </div>
    );
}
