import { useMemo, useState } from 'react';
import { TFunction, useTranslation } from '@packlink/translation-provider';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';

import {
    Button,
    ButtonColor,
    ButtonSize,
    ButtonVariant,
    Grid,
    GridChild,
    Spinner,
    SpinnerSize,
    useToast,
} from '@shipengine/giger';
import { IParcel, Parcel } from '@packlink/packlink-sdk';

import { EditableFavoritesCard } from '@components/EditableFavoritesCard/EditableFavoritesCard';
import { getParcelWithNameValidation, ParcelForm } from '@components/ParcelForm/ParcelForm';
import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';
import { AmplitudeEvents } from '@constants/amplitude';

import { getParcelDetailsFooterStyles, getParcelDetailsLegendStyles, getParcelsContentStyles } from './ParcelsStyles';
import { useAmplitude } from '@hooks/useAmplitude';
import { useParcels } from '@common/hooks/useParcels';

interface IContentForm {
    parcels?: IParcel[];
}

const MAX_PARCELS = 16;

const createEmptyParcel = (parcels: Parcel[]): IParcel => ({
    name: '',
    height: 0,
    length: 0,
    weight: 0,
    width: 0,
    isDefault: parcels.length === 0,
    id: '',
});

type ParcelDetailsProps = {
    parcel: Parcel<IParcel>;
    isEdition: boolean;
    onSave: (parcel: Parcel) => void;
    onCancel: () => void;
};

const getParcelListValidation = (t: TFunction) => {
    return yup.array().of(getParcelWithNameValidation(t)).required();
};

const ParcelDetails = ({ parcel, onSave, onCancel, isEdition }: ParcelDetailsProps) => {
    const { t } = useTranslation();
    const validationSchema = yup.object<IContentForm>().shape({
        parcels: getParcelListValidation(t),
    });
    const { sendAmplitudeClickEvent } = useAmplitude();

    const saveParcel = ({ parcels: currentEditParcel }: IContentForm): void => {
        sendAmplitudeClickEvent(AmplitudeEvents.SAVE_PARCEL);
        if (currentEditParcel) {
            const editedParcel = Parcel.deserialize(currentEditParcel[0]);
            onSave(editedParcel);
        }
    };

    const handleCancel = (): void => {
        sendAmplitudeClickEvent(AmplitudeEvents.CANCEL_PARCEL);
        onCancel();
    };

    const initialValue: IContentForm = useMemo(
        () => ({
            parcels: [parcel.toJSON() as IParcel],
        }),
        [parcel],
    );

    return (
        <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            validateOnMount={false}
            validateOnBlur={false}
            onSubmit={saveParcel}
        >
            {(formProps: FormikProps<IContentForm>): React.ReactNode => (
                <form onSubmit={formProps.handleSubmit}>
                    <div css={getParcelDetailsLegendStyles}>
                        <span>
                            {t(isEdition ? 'parcel-details.form.subtitle-edit' : 'parcel-details.form.subtitle-create')}
                        </span>
                    </div>
                    <ParcelForm parcelEdit index={0} name="parcels" />
                    <footer css={getParcelDetailsFooterStyles}>
                        <Button
                            color={ButtonColor.PRIMARY}
                            size={ButtonSize.MEDIUM}
                            type="submit"
                            data-id="save-parcel-button"
                        >
                            {t('parcel-details.form.button-save')}
                        </Button>
                        <Button
                            color={ButtonColor.PRIMARY}
                            variant={ButtonVariant.OUTLINED}
                            size={ButtonSize.MEDIUM}
                            onClick={handleCancel}
                            data-id="cancel-parcel-button"
                        >
                            {t('parcel-details.form.button-cancel')}
                        </Button>
                    </footer>
                </form>
            )}
        </Formik>
    );
};

export const Parcels = (): JSX.Element => {
    const { t } = useTranslation();
    const toast = useToast(t);
    const { parcels, isLoading, isFetching } = useParcels();
    const [selectedParcel, setSelectedParcel] = useState<Parcel<IParcel> | null>(null);
    const [isEdition, setIsEdition] = useState<boolean>(false);
    const { sendAmplitudeClickEvent } = useAmplitude();
    const { updateParcelMutation, deleteParcelMutation, saveParcelMutation } = useParcels();

    const handleDefault = (parcel: Parcel<IParcel>): void => {
        if (!parcel.isDefault) {
            const updatedParcel = parcel.clone();
            updatedParcel.isDefault = true;
            updateParcelMutation(updatedParcel, {
                onError: () => {
                    toast.error({ message: t('parcels.save-default.error') });
                },
            });
        }
    };

    const handleDelete = (parcel: Parcel<IParcel>): void => {
        deleteParcelMutation(parcel, {
            onError: () => {
                toast.error({ message: t('parcels.delete.error') });
            },
        });
    };

    const handleNewParcel = (): void => {
        sendAmplitudeClickEvent(AmplitudeEvents.ADD_PARCEL);
        setSelectedParcel(Parcel.deserialize(createEmptyParcel(parcels)));
        setIsEdition(false);
    };

    const handleEdit = (parcel: Parcel<IParcel>): void => {
        setSelectedParcel(parcel);
        setIsEdition(true);
    };

    const handleCancel = (): void => {
        setSelectedParcel(null);
    };

    // TODO: only needed due angular parcelForm, remove when following task done PRO-875
    const parseParcel = (parcel: Parcel): Parcel => {
        const formattedParcel = parcel.clone();
        formattedParcel.width = Number(parcel.width);
        formattedParcel.weight = Number(parcel.weight);
        formattedParcel.length = Number(parcel.length);
        formattedParcel.height = Number(parcel.height);
        return formattedParcel;
    };

    const handleSave = (currentParcel: Parcel): void => {
        if (selectedParcel) {
            const formattedParcel = parseParcel(currentParcel);
            const mutationEvents = {
                onError: () => {
                    toast.error({ message: t('parcels.save.error') });
                },
                onSuccess: () => {
                    toast.success({ message: t('parcels.save.success') });
                    setSelectedParcel(null);
                    setIsEdition(false);
                },
            };

            isEdition
                ? updateParcelMutation(formattedParcel, mutationEvents)
                : saveParcelMutation(formattedParcel, mutationEvents);
        }
    };

    return (
        <SettingsPage>
            <SettingsPageHeader description={t('parcels.settings.description')}>
                {t('parcels.settings.title')}
                {parcels?.length < MAX_PARCELS && !selectedParcel && (
                    <Button type="submit" onClick={handleNewParcel} size={ButtonSize.SMALL} data-id="add-parcel-button">
                        <span>{t('parcels.settings.add-parcel')}</span>
                    </Button>
                )}
            </SettingsPageHeader>

            {isLoading || isFetching ? (
                <Spinner size={SpinnerSize.SIZE_LARGE} />
            ) : (
                <div css={getParcelsContentStyles(!!selectedParcel)}>
                    {selectedParcel ? (
                        <ParcelDetails
                            parcel={selectedParcel}
                            onSave={handleSave}
                            onCancel={handleCancel}
                            isEdition={isEdition}
                        />
                    ) : (
                        <Grid noPadding>
                            {parcels &&
                                parcels?.map((parcel: Parcel<IParcel>) => (
                                    <GridChild colSpan={6} colSpanDesktop={4} key={parcel.id}>
                                        <EditableFavoritesCard
                                            key={parcel.id}
                                            name={parcel.name as string}
                                            isDefault={parcel.isDefault}
                                            hasMultipleCards={parcels.length > 1 ? true : false}
                                            canCreateDefault={true}
                                            setDefault={() => handleDefault(parcel)}
                                            setDelete={() => handleDelete(parcel)}
                                            setEdit={() => handleEdit(parcel)}
                                        >
                                            <span>
                                                {parcel.weight} kg {parcel.length} x {parcel.width} x {parcel.height} cm
                                            </span>
                                        </EditableFavoritesCard>
                                    </GridChild>
                                ))}
                        </Grid>
                    )}
                </div>
            )}
        </SettingsPage>
    );
};
