import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';
import { ChartPalette, DEFAULT_HEIGHT } from '../Charts';

export const tableContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        [theme.mediaQuery('tablet')]: {
            marginRight: theme.spacing(3),
        },
    });

export const tableStyles = (): SerializedStyles =>
    css({
        height: `${DEFAULT_HEIGHT}px`,
    });

export const circleStyles =
    (index: number) =>
    (theme: Theme): SerializedStyles => {
        const chartPalette = Object.values(theme.palette.dataVisualization[ChartPalette.LOW_CONTRAST]);

        return css({
            height: theme.spacing(),
            width: theme.spacing(),
            borderRadius: '50%',
            backgroundColor: chartPalette[index],
            marginRight: theme.spacing(),
            display: 'inline-flex',
            verticalAlign: 'middle',
        });
    };
