import React from 'react';

/**
 * Gets only the valid children of a component,
 * and ignores any nullish or falsy child.
 *
 * @param children the children
 */
function getActiveChildren(children: React.ReactNode): React.ReactElement[] {
    return React.Children.toArray(children).filter((child) => React.isValidElement(child)) as React.ReactElement[];
}

/**
 * Returns if react component has active children
 *
 * @param children the children
 */
export function hasActiveChildren(children: React.ReactNode): boolean {
    return getActiveChildren(children).length >= 1;
}
