import React, { useCallback, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { TFunction } from 'i18next';
import { useField } from 'formik';
import * as yup from 'yup';
import { FieldMessageType, FormField, ISelectAutocompleteOption, SelectAutocomplete } from '@shipengine/giger';
import { contentTypes } from '@constants/content';

export const getContentValidation = (t: TFunction): yup.StringSchema<string> =>
    yup.string().required(t('form.error.required', { field: t('content-panel.content.section-title') }));
interface IContentAutocompleteProps {
    onChange?: (option: string | undefined) => void;
}
export const ContentAutocomplete: React.FC<IContentAutocompleteProps> = ({
    onChange,
}: IContentAutocompleteProps): JSX.Element => {
    const { t } = useTranslation();
    const [field, meta, helper] = useField<string>('description');
    const initialContent = field.value ? { label: field.value, value: field.value } : null;
    const [selectedContent, setSelectedContent] = useState<ISelectAutocompleteOption | null>(initialContent);

    const contentOptions: ISelectAutocompleteOption[] = contentTypes.map((key) => {
        const value = t(key);

        return { label: value, value };
    });

    const handleContentChange = useCallback(
        (_name: string, option: ISelectAutocompleteOption | null): void => {
            setSelectedContent(option);
            helper.setValue(option?.value ?? '');
            onChange && onChange(option?.value);
        },
        [helper, onChange],
    );

    return (
        <FormField message={meta.error ? { type: FieldMessageType.ERROR, content: meta.error } : undefined}>
            <SelectAutocomplete
                name="description"
                options={contentOptions}
                label={t('form.label.contents')}
                placeholder={t('content-panel.content.placeholder')}
                onChange={handleContentChange}
                isInvalid={!!meta.error}
                value={selectedContent}
                noResultsLabel={t('autocomplete.messages.no-results-found')}
                optionsHeaderText={t('autocomplete.messages.suggestions')}
                showRightIcon={false}
                freeInput
            />
        </FormField>
    );
};
