export const contentTypes = [
    'CONTENT_ELECTRONICS',
    'CONTENT_MOBILE_PHONES',
    'CONTENT_CLOTHING',
    'CONTENT_FURNITURE',
    'CONTENT_FOOD',
    'CONTENT_BOOKS',
    'CONTENT_DOCUMENTS',
    'CONTENT_OTHER',
];

export const MAX_DECLARED_VALUE = 99999999;
