import { css, SerializedStyles } from '@emotion/react';
import { getTypographyStyles, SlideInPosition } from '@shipengine/giger';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';
import { Theme } from '@shipengine/giger-theme';

export const getAppHeaderPanelSidePanelStyles = (): SerializedStyles =>
    css({
        width: 250,
        ...getSidePanelClipPathStyles(SlideInPosition.LEFT),
    });

export const getAppHeaderPanelSidePanelHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.white,
        backgroundColor: theme.palette.secondary.main,
        padding: `${theme.spacing(0.5)}px 0 ${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.gray.light}`,
        minHeight: '56px',
    });

export const getAppHeaderPanelMenuIcon = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        color: theme.palette.primary.main,
    });

export const getAppHeaderPanelActions = (): SerializedStyles =>
    css({
        listStyleType: 'none',
        margin: 0,
        padding: 0,
    });

export const getAppHeaderMenuStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    });

export const getAppHeaderMenuItemStyles = (theme: Theme): SerializedStyles =>
    css({
        flexDirection: 'row',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    });

export const getAppHeaderMenuItemText = (theme: Theme): SerializedStyles =>
    css([
        getTypographyStyles('body2')(theme),
        {
            margin: `0 0 0 ${theme.spacing()}px`,
            color: theme.palette.gray.main,
        },
    ]);

export const getAppHeaderPanelStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(1),
    });

export const getAppHeaderPanelZendeskActiveStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
    });
