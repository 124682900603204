import { useTranslation } from '@packlink/translation-provider';
import { Icon, Typography } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { getContainerStyles, getIconStyles } from './EmailTemplatesFooterStyles';

export interface EmailTemplatesFooterProps {
    subscribed: boolean;
}

export function EmailTemplatesFooter({ subscribed }: EmailTemplatesFooterProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <div css={getContainerStyles}>
            <Icon name={subscribed ? IconNames.ROCKET : IconNames.UPGRADE} css={getIconStyles} />
            <Typography component="span" variant="small">
                {t(subscribed ? 'branded-emails.template-list.coming-soon' : 'branded-emails.template-list.subscribe')}
            </Typography>
        </div>
    );
}
