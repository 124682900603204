import { InlineNotification, NotificationType, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

type CancelAlreadyRequestedNotificationProps = WithCommonProps<unknown>;

export function CancelAlreadyRequestedNotification(props: CancelAlreadyRequestedNotificationProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <InlineNotification
            type={NotificationType.ERROR}
            title={t('support-center.cancel-form.already-canceled-shipment-error')}
            {...props}
        />
    );
}
