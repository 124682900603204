import { WithCommonProps } from '@shipengine/giger';
import { useTranslation, Trans } from '@packlink/translation-provider';
import { PostsaleSection } from '../PostsaleSection/PostsaleSection';

type PrintInStoreDisclaimerProps = WithCommonProps<unknown>;

export function PrintInStoreDisclaimer(props: PrintInStoreDisclaimerProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <PostsaleSection title={t('post-sale.print-in-store.title')} {...props}>
            <Trans i18nKey="post-sale.print-in-store.content" />
        </PostsaleSection>
    );
}
