import { IconNames } from '@shipengine/giger-theme';
import { WithCommonProps } from '@shipengine/giger';
import { CancelSupportTicket } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { SupportTicketSummarySection } from '../../SupportTicketSummarySection/SupportTicketSummarySection';
import { useSupportCenterCancelReasons } from '@SupportCenter/hooks/useSupportCenterCancelReasons';

type CancelTicketSummaryMessageProps = WithCommonProps<{
    data: CancelSupportTicket;
}>;

export function CancelTicketSummaryMessage({ data, ...props }: CancelTicketSummaryMessageProps): JSX.Element {
    const cancelReasons = useSupportCenterCancelReasons();
    const { t } = useTranslation();

    return (
        <ul {...props}>
            <SupportTicketSummarySection
                title={t('support-center.ticket-chat.ticket-summary-reason-label')}
                icon={IconNames.CONVERSATION}
            >
                {t(cancelReasons[data.reason])}
            </SupportTicketSummarySection>
        </ul>
    );
}
