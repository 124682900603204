import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelCustomHeader } from '@components/SidePanel/SidePanelCustomHeader';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { AppHeaderMenu } from '../AppHeaderMenu/AppHeaderMenu';
import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, SidePanel, SlideInPosition } from '@shipengine/giger';
import { Integration } from '@packlink/packlink-sdk';
import { IntegrationConfigurations } from '@Integrations/components/IntegrationPanel/types';
import { cleanSelectedShipments } from '@store/actions/bulk';
import { PanelName } from '@types';
import { AppHeaderActionsItems } from '../AppHeaderActions/AppHeaderActionsItems';
import { useTranslation } from '@packlink/translation-provider';
import { AppHeaderPanelItem } from './AppHeaderPanelItem';
import { useSidePanel } from '@hooks/useSidePanel';
import {
    getAppHeaderMenuItemStyles,
    getAppHeaderMenuItemText,
    getAppHeaderMenuStyles,
    getAppHeaderPanelActions,
    getAppHeaderPanelMenuIcon,
    getAppHeaderPanelSidePanelHeaderStyles,
    getAppHeaderPanelSidePanelStyles,
    getAppHeaderPanelStyles,
    getAppHeaderPanelZendeskActiveStyles,
} from './AppHeaderPanelStyles';

export interface IAppHeaderPanelProps {
    onOpenImportsModal: (integration: Integration<IntegrationConfigurations>) => void;
    onOpenImportCSVModal: () => void;
    onCreateShipment: () => void;
    onClickSetupIntegrations: () => void;
    onClickSettings: () => void;
}

export const AppHeaderPanel: React.FC<IAppHeaderPanelProps> = (props: IAppHeaderPanelProps): JSX.Element => {
    const { onOpenImportsModal, onClickSettings, onCreateShipment, onOpenImportCSVModal, onClickSetupIntegrations } =
        props;
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const { toggle: toggleSidePanel, close: closeSidePanel, isOpen } = useSidePanel(PanelName.HEADER_MENU);

    const togglePanel = (): void => {
        dispatch(cleanSelectedShipments());
        toggleSidePanel();
    };

    const handleImport = useCallback(
        (fn: (params: Integration<IntegrationConfigurations>) => void) =>
            (params: Integration<IntegrationConfigurations>) => {
                closeSidePanel();
                fn(params);
            },
        [closeSidePanel],
    );

    const closePanel = useCallback(
        (fn: VoidFunction) => () => {
            closeSidePanel();
            fn();
        },
        [closeSidePanel],
    );

    const isZendeskActive = !!window.zE;

    return (
        <div css={getAppHeaderPanelStyles}>
            <a css={getAppHeaderPanelMenuIcon} role="button" title="menu" onClick={togglePanel}>
                <Icon name={IconNames.MENU} size={IconSize.SIZE_LARGE} />
            </a>

            <SidePanel
                css={getAppHeaderPanelSidePanelStyles}
                isOpen={isOpen}
                position={SlideInPosition.LEFT}
                onBackdropClick={togglePanel}
                header={
                    <SidePanelCustomHeader cssHeader={getAppHeaderPanelSidePanelHeaderStyles} title={t('SHIPMENTS')} />
                }
            >
                <SidePanelContent>
                    <ul css={getAppHeaderPanelActions}>
                        <AppHeaderActionsItems
                            as={AppHeaderPanelItem}
                            onOpenImportsModal={handleImport(onOpenImportsModal)}
                            onOpenImportCSVModal={closePanel(onOpenImportCSVModal)}
                            onCreateShipment={closePanel(onCreateShipment)}
                            onClickSetupIntegrations={closePanel(onClickSetupIntegrations)}
                        />
                    </ul>
                </SidePanelContent>

                <SidePanelFooter css={isZendeskActive && getAppHeaderPanelZendeskActiveStyles}>
                    <AppHeaderMenu
                        onClickSettings={closePanel(onClickSettings)}
                        rootCss={getAppHeaderMenuStyles}
                        itemCss={getAppHeaderMenuItemStyles}
                        textCss={getAppHeaderMenuItemText}
                    />
                </SidePanelFooter>
            </SidePanel>
        </div>
    );
};
