import { IconNames } from '@shipengine/giger-theme';
import { WithCommonProps } from '@shipengine/giger';
import { SupportTicketCommonData } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { SupportTicketAttachmentDetail } from '../../SupportTicketAttachmentDetail/SupportTicketAttachmentDetail';
import { SupportTicketSummarySection } from '../../SupportTicketSummarySection/SupportTicketSummarySection';

type GeneralTicketSummaryMessageProps = WithCommonProps<{
    data: SupportTicketCommonData;
}>;

export function GeneralTicketSummaryMessage({ data, ...props }: GeneralTicketSummaryMessageProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <ul {...props}>
            <SupportTicketSummarySection
                title={t('support-center.ticket-chat.ticket-summary-reason-label')}
                icon={IconNames.CONVERSATION}
            >
                {data.reason}
            </SupportTicketSummarySection>
            {data.additionalPictureAttachmentDetail && (
                <SupportTicketSummarySection
                    title={t('support-center.ticket-chat.ticket-summary-attached-file-label')}
                    icon={IconNames.ATTACH}
                >
                    <SupportTicketAttachmentDetail attachment={data.additionalPictureAttachmentDetail} />
                </SupportTicketSummarySection>
            )}
        </ul>
    );
}
