import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const formStyles = (theme: Theme): SerializedStyles =>
    css({
        border: `1px solid ${theme.palette.gray.light}`,
        padding: theme.spacing(2),
    });

export const cancelButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(2),
    });

export const formSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing(3)}px 0`,
    });

export const switchStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing(3)}px 0`,

        '> *:first-of-type': {
            marginBottom: theme.spacing(),
        },
    });

export const aliasFieldStyles = (theme: Theme): SerializedStyles =>
    css({
        maxWidth: 300,
        marginTop: theme.spacing(3),
    });

export const fieldStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(3),
        width: '100%',
    });

export const ruleRowStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(),
    });

export const infoIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: 'auto',
        marginLeft: theme.spacing(0.5),
    });
