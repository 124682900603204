import { useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { PaymentMethod, SubscriptionPlan } from '@packlink/packlink-sdk';
import { InlineNotification, NotificationType } from '@shipengine/giger';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';
import { SubscriptionPanelInvoicingDetails } from './SubscriptionPanelInvoicingDetails';
import { SubscriptionPanelTaxCalculation } from './SubscriptionPanelTaxCalculation';
import { SubscriptionPanelPayment } from './SubscriptionPanelPayment';
import { getInlineNotificationStyles } from './SubscriptionPanelPaymentSummaryStyles';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';

interface SubscriptionPanelPaymentSummaryProps {
    planDetails: SubscriptionPlan;
    onEditInvoiceDetails: VoidFunction;
    successCallback?: VoidFunction;
}

export function SubscriptionPanelPaymentSummary({
    planDetails,
    onEditInvoiceDetails,
    successCallback,
}: SubscriptionPanelPaymentSummaryProps): JSX.Element {
    const { t } = useTranslation();
    const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>();
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const { sendAmplitudeEvent } = useAmplitude();

    const handlePaymentError = (translationKey?: string) => {
        setPaymentErrorMessage(translationKey);
    };

    const handleSuccess = (method: PaymentMethod, planCode: string) => {
        sendAmplitudeEvent(AmplitudeEvents.SUBSCRIPTION_UPGRADE_COMPLETED, {
            [AmplitudeProperties.PAYMENT_METHOD]: method,
            [AmplitudeProperties.PLAN]: planCode,
        });
        setShowSuccess(true);
        successCallback?.();
    };

    return (
        <SidePanelContent>
            {showSuccess ? (
                <SidePanelMessage
                    title={t('subscriptions.success.title')}
                    subtitle={t('subscriptions.success.message', {
                        planName: t('subscriptions.plan.display-name', { planName: planDetails.name }),
                    })}
                    type={SidePanelMessageType.SUCCESS}
                    showGoToPro={!!successCallback}
                />
            ) : (
                <>
                    {paymentErrorMessage && (
                        <InlineNotification css={getInlineNotificationStyles} type={NotificationType.ERROR}>
                            {t(paymentErrorMessage)}
                        </InlineNotification>
                    )}

                    <SidePanelContentSection
                        title={t('subscriptions.payment.subscription', { planName: planDetails.name })}
                    >
                        <SubscriptionPanelTaxCalculation planDetails={planDetails} onError={handlePaymentError} />
                    </SidePanelContentSection>

                    <SidePanelContentSection title={t('subscriptions.payment.invoicing-details')}>
                        <SubscriptionPanelInvoicingDetails onEdit={onEditInvoiceDetails} />
                    </SidePanelContentSection>

                    <SidePanelContentSection title={t('payment.payment-options.title')}>
                        <SubscriptionPanelPayment
                            onSuccess={handleSuccess}
                            onError={handlePaymentError}
                            planCode={planDetails.code}
                        />
                    </SidePanelContentSection>
                </>
            )}
        </SidePanelContent>
    );
}
