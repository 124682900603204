import { useNavigate } from 'react-router';

import { useTranslation } from '@packlink/translation-provider';
import { ButtonSize, ButtonVariant, Next, Typography, WithCommonProps } from '@shipengine/giger';
import { APP_ROUTE } from '@pages/router/routes';
import { useAmplitude } from '@hooks';
import { AmplitudeEvents, AmplitudeProperties, SOURCE_AMPLITUDE_PROPERTY } from '@constants/amplitude';
import { getCallAgentPlusClientsStyle } from './CallAgentLinkStyles';

export type CallAgentLinkProps = WithCommonProps<{
    isFullWidth?: boolean;
}>;

export function CallAgentLink({ isFullWidth = false, ...rest }: CallAgentLinkProps): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const handleClickEvent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();

        sendAmplitudeClickEvent(AmplitudeEvents.CALL_AGENT_HELP_CENTER, {
            [AmplitudeProperties.SOURCE]: SOURCE_AMPLITUDE_PROPERTY.BUTTON,
        });
        navigate(APP_ROUTE.SUBSCRIPTIONS.INDEX);
    };

    return (
        <div {...rest}>
            <Next.Link
                isButton
                buttonProps={{ variant: ButtonVariant.OUTLINED, size: ButtonSize.LARGE, isFullWidth: isFullWidth }}
                onClick={handleClickEvent}
            >
                {t('support-center.actions.call-agent')}
            </Next.Link>
            <Typography component="p" variant="small" css={getCallAgentPlusClientsStyle}>
                {t('support-center.actions.plus-clients')}
            </Typography>
        </div>
    );
}
