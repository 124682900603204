import React, { useMemo } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';

import { getBulkSortBy, getIsCurrentInboxEmpty } from '@store/selectors/bulk';
import { getInboxes } from '@store/selectors/inbox';
import { SortButton } from '@components/SortButton/SortButton';
import { IconNames } from '@shipengine/giger-theme';
import { ShipmentsOrderBy } from '@packlink/packlink-sdk';
import { setBulkSortBy } from '@store/actions/bulk';
import { AmplitudeEvents, AmplitudeProperties, SHIPMENT_SORT_TO_AMPLITUDE_PROPERTY } from '@constants/amplitude';
import {
    getShipmentListHeaderMenuItemStyles,
    getShipmentListHeaderMenuStyles,
    getShipmentListHeaderStyles,
} from './ShipmentListHeaderStyles';
import { Typography } from '@shipengine/giger';
import { useMedia } from '@packlink/utils';
import { useTheme } from '@emotion/react';
import { useAmplitude } from '@hooks/useAmplitude';

const headers = [
    {
        name: 'recipient',
    },
    {
        name: 'contents',
    },
    {
        name: 'service',
        sortingOptions: [
            { sortKey: undefined, translationKey: 'sorting.option.creation-date', icon: IconNames.SORT },
            {
                sortKey: ShipmentsOrderBy.SERVICE_ASC,
                translationKey: 'sorting.option.alpha-asc',
                icon: IconNames.SORT_ALPHA_ASC,
            },
            {
                sortKey: ShipmentsOrderBy.SERVICE_DESC,
                translationKey: 'sorting.option.alpha-desc',
                icon: IconNames.SORT_ALPHA_DESC,
            },
        ],
    },
    {
        name: 'other-benefits',
    },
    {
        name: 'status',
    },
    {
        name: 'actions',
    },
];

export const ShipmentListHeader = (): JSX.Element => {
    const isCurrentInboxEmpty = useSelector(getIsCurrentInboxEmpty);
    const inboxes = useSelector(getInboxes);

    const isNewUser = useMemo(() => !inboxes?.getAll(), [inboxes]);
    const isEmptyState = isNewUser || isCurrentInboxEmpty;
    const theme = useTheme();
    const isFromTablet = useMedia(theme.mediaQuery('tablet'));
    const showHeader = !isEmptyState || isFromTablet;

    return showHeader ? (
        <header css={getShipmentListHeaderStyles}>
            <ul css={getShipmentListHeaderMenuStyles}>
                <MenuItems />
            </ul>
        </header>
    ) : (
        <></>
    );
};

const MenuItems = (): JSX.Element => {
    const currentSortBy = useSelector(getBulkSortBy);
    const isCurrentInboxEmpty = useSelector(getIsCurrentInboxEmpty);
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const handleChangeSorting = (sortBy?: ShipmentsOrderBy): void => {
        dispatch(setBulkSortBy(sortBy as ShipmentsOrderBy));
        sendAmplitudeClickEvent(AmplitudeEvents.SORTING_IN_SERVICE_TYPE, {
            [AmplitudeProperties.TYPE_OF_SORTING]: !sortBy
                ? SHIPMENT_SORT_TO_AMPLITUDE_PROPERTY.default
                : SHIPMENT_SORT_TO_AMPLITUDE_PROPERTY[sortBy],
        });
    };

    const handleOnClick = (): void => {
        sendAmplitudeClickEvent(AmplitudeEvents.SORTING_IN_SERVICE);
    };

    return (
        <>
            {headers.map(
                (item): React.ReactNode => (
                    <Typography
                        variant="small"
                        component="li"
                        key={item.name}
                        data-id={item.name}
                        css={getShipmentListHeaderMenuItemStyles}
                    >
                        <span>{t(`shipment-list-header.menu.${item.name}`)}</span>
                        {item.sortingOptions && !isCurrentInboxEmpty && (
                            <SortButton
                                onChangeSorting={handleChangeSorting}
                                options={item.sortingOptions}
                                selectedSort={currentSortBy}
                                onClick={handleOnClick}
                            />
                        )}
                    </Typography>
                ),
            )}
        </>
    );
};
