import { Typography } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { getImageStyles, getTitleStyles, getSubtitleStyles, getChartStateStyles } from './ChartStateStyles';

export interface IChartStateLayoutProps {
    image: string;
    title: string;
    subtitle: string;
}

export function ChartStateLayout({ title, subtitle, image }: IChartStateLayoutProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <div css={getChartStateStyles}>
            <img css={getImageStyles} src={image} alt={title} />

            <Typography css={getTitleStyles} component="h4" variant="heading4">
                {t(title)}
            </Typography>

            <Typography css={getSubtitleStyles} variant="body1">
                {t(subtitle)}
            </Typography>
        </div>
    );
}
