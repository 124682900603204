import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getPlanCardStyles = (theme: Theme) => {
    return css({
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.gray.ultraLight}`,
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.white,
        boxShadow: `0px 4px 6px ${theme.palette.gray.light}`,
        height: 'auto',
        width: '95%',

        [theme.mediaQuery('tablet')]: {
            width: '490px',
        },
    });
};

const smallBottomMarginStyles = (theme: Theme) => {
    return css({
        marginBottom: theme.spacing(1),
    });
};

export const getPlanHeaderSection = () => {
    return css({
        display: 'flex',
        flexDirection: 'column',
    });
};

export const getPlanNameStyles = () => {
    return css({
        ...smallBottomMarginStyles,
    });
};

export const getPlanDescriptionStyles = (theme: Theme) => {
    return css({
        ...smallBottomMarginStyles,
        height: theme.spacing(6),
    });
};

export const getButtonStyles = (): SerializedStyles =>
    css({
        margin: 0,
        alignSelf: 'center',
        width: '100%',
        textAlign: 'center',
        display: 'block',
    });

export const getDividerStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing(2)}px 0`,
    });
