import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketDataDetailsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'min-content',
        gap: theme.spacing(2),
    });

export const getTicketDataFAQLinkStyles = (theme: Theme): SerializedStyles =>
    css({
        justifyContent: 'center',
        padding: theme.spacing(),
    });
