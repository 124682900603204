export enum Charts {
    ShipmentsOverTime = 'shipments-over-time',
    TotalShippingCost = 'total-shipping-cost',
    ShipmentsByCarrier = 'shipments-by-carrier',
    ShipmentsPerCountry = 'shipments-per-country',
    ShipmentsPerContent = 'shipments-per-content',
}

export const OTHERS = 'Others';
export const DATA_LIMIT = 8;
