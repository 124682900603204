import {
    ChartContainer,
    getChartContainerStatus,
    getTimeScale,
    ResponsiveLine,
    useRequestOnIntersection,
} from '../Charts';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { AnalyticsTimeSeriesParams, UserAnalyticsRepository } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { getLinearScale, getTimeAxis } from '../../utils/charts';
import { getSubtitleRangeParams } from '../../utils/dates';
import { IconNames } from '@shipengine/giger-theme';
import { IconPopover, IconSize, Typography } from '@shipengine/giger';
import { infoIconStyles } from './ShippingCostOverTimeChartStyles';
import { Charts } from '../../constants/analytics';

const userAnalyticsRepository = new UserAnalyticsRepository(apiClient);

export function ShippingCostOverTimeChart(props: AnalyticsTimeSeriesParams): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const requestShippingCostOverTime = () => {
        return userAnalyticsRepository.getShippingCostOverTimeSeries(props).then((serie) => [
            {
                id: t('analytics.chart.total-shipping-cost'),
                data: serie.data,
            },
        ]);
    };

    const { data, status, isFetching, intersectionRef } = useRequestOnIntersection({
        requestCacheKeys: [Charts.TotalShippingCost, props],
        requestData: requestShippingCostOverTime,
    });

    const isEmpty = !data?.filter((serie) => serie.data.length).length;
    const containerStatus = getChartContainerStatus(status, isFetching, isEmpty);

    return (
        <ChartContainer
            ref={intersectionRef}
            title={t('analytics.chart.total-shipping-cost')}
            subtitle={t('analytics.chart.date-range', getSubtitleRangeParams(locale, props.from, props.to))}
            status={containerStatus}
            cdnUrl={config.cdn.baseUrl}
            titleExtra={
                <IconPopover
                    css={infoIconStyles}
                    icon={IconNames.INFO}
                    size={IconSize.SIZE_SMALL}
                    data-id="total-shipping-cost-info"
                >
                    <Typography variant="body2" component="span">
                        <Trans>{t('analytics.chart.total-shipping-cost-info')}</Trans>
                    </Typography>
                </IconPopover>
            }
        >
            {!isEmpty && (
                <ResponsiveLine
                    id={Charts.TotalShippingCost}
                    data={data}
                    xScale={getTimeScale()}
                    xFormat={(date: string | number | Date) => Intl.DateTimeFormat(locale).format(date as Date)}
                    yScale={getLinearScale()}
                    axisBottom={getTimeAxis(locale, data[0].data.length)}
                    tooltipXLabel={t('analytics.label.date')}
                    tooltipYLabel={t('analytics.label.cost')}
                />
            )}
        </ChartContainer>
    );
}
