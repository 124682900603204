import React from 'react';
import { getIn, useFormikContext } from 'formik';
import useDebounce from 'react-use/lib/useDebounce';

import { useTranslation } from '@packlink/translation-provider';
import {
    FieldMessageType,
    FormField,
    Icon,
    IconSize,
    Input,
    ISelectAutocompleteOption,
    Textarea,
} from '@shipengine/giger';
import { IAddress } from '@packlink/packlink-sdk';
import { BuyerAddressDisclaimer } from '@components/BuyerAddressDisclaimer/BuyerAddressDisclaimer';
import { IconNames } from '@shipengine/giger-theme';
import { Origin } from '@types';
import { CountryPostalCodeForm } from './CountryPostalCodeForm';

export interface ILocationFormProps {
    name: Origin;
    hasPickUp?: boolean;
    hideEmail?: boolean;
    hasAlias?: boolean;
    initialPostalZoneId?: string;
    initialPostalCodeId?: string;
    isPostalSelectorsDisabled?: boolean;
    isCollapsed?: boolean;
    readOnly?: boolean;
    onHandleChange?: (address: ILocationFormField) => void;
}

export interface ILocationForm {
    from?: ILocationFormField;
    to?: ILocationFormField;
}
export interface ILocationFormField extends IAddress {
    alias?: string;
    postalCode?: ISelectAutocompleteOption;
    country?: ISelectAutocompleteOption;
}

interface ILocationFormFieldErrors {
    alias?: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    phone?: string;
    email?: string;
    street1?: string;
    alpha2Code?: string;
    zipCode?: string;
}

export const LocationForm: React.FC<ILocationFormProps> = (props: ILocationFormProps): JSX.Element => {
    const {
        name,
        hasPickUp,
        hideEmail,
        hasAlias = false,
        initialPostalZoneId,
        isPostalSelectorsDisabled,
        isCollapsed,
        readOnly,
        onHandleChange,
    } = props;
    const { t } = useTranslation();
    const { values, touched, errors, handleChange, handleBlur } = useFormikContext();

    const touchedValues = getIn(touched, name);
    const locationValues: ILocationFormField = getIn(values, name);
    const locationErrors: ILocationFormFieldErrors | undefined = getIn(errors, name);

    const showAddress = name === Origin.FROM || (name === Origin.TO && !hasPickUp);

    useDebounce(
        () => {
            onHandleChange?.(locationValues);
        },
        300,
        [locationValues],
    );

    const getFormFieldMessage = (field: keyof ILocationFormFieldErrors) => {
        if (locationErrors?.[field] && touchedValues?.[field]) {
            return {
                type: FieldMessageType.ERROR,
                content: locationErrors[field],
            };
        }
        return undefined;
    };

    return (
        <section>
            <section>
                {hasAlias && (
                    <FormField message={getFormFieldMessage('alias')}>
                        <Input
                            label={t('form.label.alias')}
                            name={`${name}.alias`}
                            isInvalid={touchedValues?.alias && locationErrors?.alias}
                            value={locationValues.alias ?? ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>
                )}
                <FormField message={getFormFieldMessage('firstName')}>
                    <Input
                        label={t('form.label.first-name')}
                        name={`${name}.firstName`}
                        isInvalid={touchedValues?.firstName && locationErrors?.firstName}
                        value={locationValues.firstName ?? ''}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
                <FormField message={getFormFieldMessage('lastName')}>
                    <Input
                        label={t('form.label.last-name')}
                        name={`${name}.lastName`}
                        isInvalid={touchedValues?.lastName && locationErrors?.lastName}
                        value={locationValues.lastName ?? ''}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
                <FormField message={getFormFieldMessage('company')}>
                    <Input
                        label={`${t('form.label.company')} (${t('form.label.optional')})`}
                        name={`${name}.company`}
                        isInvalid={touchedValues?.company && locationErrors?.company}
                        value={locationValues.company ?? ''}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
                {!hideEmail && (
                    <FormField message={getFormFieldMessage('email')}>
                        <Input
                            label={t('form.label.email')}
                            name={`${name}.email`}
                            isInvalid={touchedValues?.email && locationErrors?.email}
                            value={locationValues.email ?? ''}
                            readOnly={readOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="email"
                        />
                    </FormField>
                )}
                <FormField message={getFormFieldMessage('phone')}>
                    <Input
                        label={t('form.label.phone')}
                        leftContent={<Icon name={IconNames.MOBILE} size={IconSize.SIZE_MEDIUM} />}
                        name={`${name}.phone`}
                        isInvalid={touchedValues?.phone && locationErrors?.phone}
                        value={locationValues.phone ?? ''}
                        readOnly={readOnly}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                </FormField>
                {showAddress && (
                    <FormField message={getFormFieldMessage('street1')}>
                        <Textarea
                            label={t('form.label.address')}
                            name={`${name}.street1`}
                            isInvalid={touchedValues?.street1 && locationErrors?.street1}
                            value={locationValues.street1 ?? ''}
                            readOnly={readOnly}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </FormField>
                )}

                <CountryPostalCodeForm
                    name={name}
                    initialPostalZoneId={initialPostalZoneId}
                    isPostalSelectorsDisabled={isPostalSelectorsDisabled}
                    isCollapsed={isCollapsed}
                    readOnly={readOnly}
                />
            </section>
            {name === Origin.TO && <BuyerAddressDisclaimer />}
        </section>
    );
};
