// note that the enum option and value need to be equal
export enum FILTER_PARAMS {
    CARRIER_NAME = 'carrier_name',
    CREATED_AT_FROM = 'created_at_from',
    CREATED_AT_TO = 'created_at_to',
    DELIVERY_DATE_FROM = 'delivery_date_from',
    DELIVERY_DATE_TO = 'delivery_date_to',
    LABEL_DOWNLOADED = 'label_downloaded',
    Q = 'q',
    RECIPIENT_NAME = 'recipient_name',
    RECIPIENT_SURNAME = 'recipient_surname',
    SHIPMENT_CUSTOM_REFERENCE = 'shipment_custom_reference',
    SOURCE = 'source',
}

export function hasFilters(filters: Record<string, unknown> | undefined) {
    return (
        !!filters &&
        Object.values(filters).filter((value: unknown) => !!(value || typeof value === 'boolean')).length > 0
    );
}
