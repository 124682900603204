import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentSummaryHeadingStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.primary.dark,
        textTransform: 'uppercase',
        margin: `0 0 ${theme.spacing(2)}px`,
    });

export const getShipmentSummaryTextStyles = (): SerializedStyles =>
    css({
        marginBottom: 0,
    });

export const getShipmentSummaryTotalStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(0.5),
    });
