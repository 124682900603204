import { Typography, WithCommonProps } from '@shipengine/giger';
import moment from 'moment';
import { getTicketChatMessageDateStyles, getTicketChatMessageHourStyles } from './TicketChatMessageDateStyles';

type TicketChatMessageDateProps = WithCommonProps<{
    date: string;
}>;

export function TicketChatMessageDate({ date, ...props }: TicketChatMessageDateProps) {
    const parsedDate = moment(date);

    return (
        <div css={getTicketChatMessageDateStyles} {...props}>
            <Typography variant="body2" bold>
                {parsedDate.format('Do MMM YYYY')}
            </Typography>
            <Typography css={getTicketChatMessageHourStyles} variant="small">
                {parsedDate.format('HH:mm')}
            </Typography>
        </div>
    );
}
