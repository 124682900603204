import { useNavigate } from 'react-router';
import { useTranslation } from '@packlink/translation-provider';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';
import { Inbox } from '@packlink/packlink-sdk';
import { AmplitudeEvents } from '@constants/amplitude';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';
import { setShipmentsToPay } from '@store/actions/bulk';
import { getCurrentInbox } from '@store/selectors/inbox';
import { inboxToRoute } from '@pages/router/utils/paths';
import { useAmplitude } from '@hooks/useAmplitude';

interface IShipmentPanelGoToPayment {
    onExit: () => void;
    shipmentRef: string;
}

export const ShipmentPanelGoToPayment = ({ onExit, shipmentRef }: IShipmentPanelGoToPayment): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentInbox = useSelector(getCurrentInbox);
    const navigate = useNavigate();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const onGoToPayment = () => {
        onExit();
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.PAY_FROM_DRAFT);
        dispatch(setShipmentsToPay([shipmentRef]));
        currentInbox !== Inbox.READY_TO_PURCHASE && navigate(inboxToRoute(Inbox.READY_TO_PURCHASE));
    };

    return (
        <>
            <SidePanelContent className="shipment-panel-content__content">
                <SidePanelMessage
                    title={t('shipment-panel.go-to-payment.title')}
                    subtitle={t('shipment-panel.go-to-payment.subtitle')}
                    type={SidePanelMessageType.SUCCESS}
                />
            </SidePanelContent>
            <SidePanelFooter>
                <SidePanelFooterActions
                    onAction={onGoToPayment}
                    actionText={t('shipment-panel.go-to-payment.button')}
                    cancelText={t('shipment-panel.actions.exit')}
                    onCancel={onExit}
                />
            </SidePanelFooter>
        </>
    );
};
