import { FieldMessageType } from '@shipengine/giger';
import { FieldProps, FormikState, getIn } from 'formik';
import { UploadAttachmentProps, UploadAttachment } from './UploadAttachment';
import { SupportCenterAttachment } from './UploadAttachmentData';

type UploadAttachmentFieldProps = FieldProps<SupportCenterAttachment | undefined> &
    Omit<UploadAttachmentProps, 'onChange' | 'onError' | 'disabled' | 'value'>;

export function UploadAttachmentField({ field, form, message, ...props }: UploadAttachmentFieldProps): JSX.Element {
    const { errors, touched, isSubmitting, setFormikState, setFieldValue } = form;
    const error = getIn(errors, field.name);
    const isTouched = getIn(touched, field.name);
    const isInvalid = error && isTouched;
    const handleChange = (fileData: SupportCenterAttachment | undefined) =>
        setFieldValue(field.name, fileData, !!fileData);
    const handleError = (message: string) => {
        // Why this strange thing you may ask?
        // Using setField can lead to race conditions
        // https://github.com/jaredpalmer/formik/issues/834
        setFormikState((prevState: FormikState<unknown>) => ({
            ...prevState,
            errors: { ...prevState.errors, [field.name]: message },
            touched: { ...prevState.touched, [field.name]: true },
        }));
    };

    return (
        <UploadAttachment
            disabled={isSubmitting}
            message={isInvalid ? { type: FieldMessageType.ERROR, content: error } : message}
            onChange={handleChange}
            onError={handleError}
            value={field.value}
            {...props}
        />
    );
}
