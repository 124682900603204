import { useCallback, useEffect } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';
import {
    Icon,
    IconPopover,
    IconSize,
    Radio,
    SidePanel,
    SidePanelDefaultFooter,
    SlideInPosition,
    Typography,
} from '@shipengine/giger';
import { Inbox } from '@packlink/packlink-sdk';
import { IconNames } from '@shipengine/giger-theme';
import { useEvent } from '@packlink/event-handler';
import Packlink from '@sdk';
import { Status, useStatus } from '@hooks/useStatus';
import { useCurrentInbox } from '@hooks/useCurrentInbox';
import { useSidePanel } from '@hooks/useSidePanel';
import { useAmplitude } from '@hooks/useAmplitude';
import { EventName, IShipmentsReportEvent, PanelName } from '@types';
import { getBulkFilters } from '@store/selectors/bulk';
import { getClientIdentifier } from '@store/selectors/client';
import { getAppStatus } from '@store/selectors/status';
import { setAppStatus } from '@store/actions/status';
import { AmplitudeEvents, AmplitudeEventSuffixes } from '@constants/amplitude';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { SidePanelContentError } from '@components/SidePanel/SidePanelContentError';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';

import {
    shipmentExportPanelIconStyles,
    shipmentExportPanelInboxMsgStyles,
    shipmentExportPanelInboxTextStyles,
} from './ShipmentExportStyles';

export const ShipmentExportPanel = (): JSX.Element | null => {
    const { close, delayedClose, isOpen } = useSidePanel(PanelName.SHIPMENTS_EXPORT);
    const { t } = useTranslation();
    const { inbox, inboxIconName, inboxTranslation } = useCurrentInbox();
    const filters = useSelector(getBulkFilters);
    const { fetchingReport } = useSelector(getAppStatus);
    const clientId = useSelector(getClientIdentifier);
    const { eventBind, eventUnbind } = useEvent<IShipmentsReportEvent>(clientId, EventName.SHIPMENTS_REPORT);
    const { status, setStatus, isError, isLoading, isReady } = useStatus(() =>
        fetchingReport ? Status.LOADING : undefined,
    );
    const dispatch = useDispatch<AppDispatch>();
    const { sendSuffixedAmplitudeEvent, sendAmplitudeSidebarEvent, sendAmplitudeSidebarClickEvent } = useAmplitude();

    const sendStatusEvent = useCallback(
        (eventStatus: AmplitudeEventSuffixes) => {
            setStatus(eventStatus === AmplitudeEventSuffixes.SUCCESS ? Status.READY : Status.ERROR);
            sendSuffixedAmplitudeEvent(sendAmplitudeSidebarEvent, eventStatus)(AmplitudeEvents.EXPORT_FILE);
        },
        [sendAmplitudeSidebarEvent, sendSuffixedAmplitudeEvent, setStatus],
    );

    const requestReport = () => {
        dispatch(setAppStatus({ fetchingReport: true }));
        setStatus(Status.LOADING);
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.EXPORT_FILE);

        Packlink.v1.shipments
            .requestReport({ inbox, ...filters })
            .catch(() => sendStatusEvent(AmplitudeEventSuffixes.FAILED));
    };

    useEffect(() => {
        function handleEvent({ url, errors }: IShipmentsReportEvent) {
            if (url) {
                sendStatusEvent(AmplitudeEventSuffixes.SUCCESS);
                delayedClose();
            }

            if (!url || Object.keys(errors || {}).length > 0) {
                sendStatusEvent(AmplitudeEventSuffixes.FAILED);
            }
        }

        eventBind(handleEvent);

        return () => {
            eventUnbind(handleEvent);
        };
    }, [delayedClose, eventBind, eventUnbind, sendStatusEvent]);

    return (
        <SidePanel
            css={getSidePanelClipPathStyles(SlideInPosition.RIGHT)}
            isOpen={isOpen}
            applyBackdropToBody={false}
            onBackdropClick={close}
            containerId="shipments-main"
            header={<SidePanelHeader onAction={close} title={t('shipments-export.panel.title')} />}
            footer={
                !status && (
                    <SidePanelDefaultFooter
                        onClickCancel={close}
                        onClickSubmit={requestReport}
                        cancelText={t('shipments-export.panel.cancel')}
                        submitText={t('shipments-export.panel.export')}
                    />
                )
            }
        >
            {!status && (
                <div css={shipmentExportPanelInboxMsgStyles}>
                    <Icon name={inboxIconName} size={IconSize.SIZE_SMALL} />
                    <Typography variant="body2" css={shipmentExportPanelInboxTextStyles}>
                        <span>
                            {t('shipments-export.panel.inbox-text', { status: inboxTranslation })}

                            {inbox === Inbox.ALL && (
                                <IconPopover
                                    css={shipmentExportPanelIconStyles}
                                    icon={IconNames.INFO}
                                    size={IconSize.SIZE_SMALL}
                                    data-id="export-info"
                                >
                                    <Typography variant="body2">{t('shipments-export.panel.archived-msg')}</Typography>
                                </IconPopover>
                            )}
                        </span>
                    </Typography>
                </div>
            )}

            <SidePanelContent>
                {!status && (
                    <>
                        <p>{t('shipments-export.panel.file-format')}</p>
                        <Radio value="csv" checked={true} readOnly>
                            csv
                        </Radio>
                    </>
                )}

                {isLoading && (
                    <SidePanelMessage
                        title={t('shipments-export.panel.loading-title')}
                        subtitle={t('shipments-export.panel.loading-subtitle')}
                        type={SidePanelMessageType.LOADING}
                    />
                )}

                {isReady && (
                    <SidePanelMessage title={t('shipments-export.panel.success')} type={SidePanelMessageType.SUCCESS} />
                )}

                {isError && <SidePanelContentError onTryAgain={requestReport} />}
            </SidePanelContent>
        </SidePanel>
    );
};
