// Add react-router logic to Crumb
// TODO: This should be a common component in, maybe, a common package for react routing (@packlink/react-router?)

import { Crumb, ICrumbProps } from '@shipengine/giger';
import { useLinkClickHandler, useMatch } from 'react-router';

export type RouterCrumbProps = Omit<ICrumbProps, 'isCurrent'>;

export function RouterCrumb({ name, href = '', ...rest }: RouterCrumbProps): JSX.Element {
    // Base <a> give some headaches when using both angular and react-router
    // TODO: Remove when angular is dead

    const internalOnClick = useLinkClickHandler(href);
    const isCurrent = useMatch(href);
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        internalOnClick(e);
    };
    return <Crumb name={name} onClick={handleClick as () => void} isCurrent={!!isCurrent} {...rest} />;
}
