export enum ContentState {
    SHIPMENTS = 'SHIPMENTS',
    NEW_USER = 'NEW_USER',
    EMPTY = 'EMPTY',
    EMPTY_FILTER = 'EMPTY_FILTER',
    ERROR = 'ERROR',
    LOADING = 'LOADING',
    ERROR_NEXT_PAGE = 'ERROR_NEXT_PAGE',
    LOADING_NEXT_PAGE = 'LOADING_NEXT_PAGE',
}

export enum ContentEvent {
    LOAD = 'LOAD',
    ERROR = 'ERROR',
    SHIPMENT_LIST = 'SHIPMENT_LIST',
    NEW_USER = 'NEW_USER',
    EMPTY = 'EMPTY',
    EMPTY_FILTER = 'EMPTY_FILTER',
    NEXT_PAGE = 'NEXT_PAGE',
}

const NEXT_STATE_GRAPH: Record<ContentState, Partial<Record<ContentEvent, ContentState>>> = {
    [ContentState.LOADING]: {
        [ContentEvent.SHIPMENT_LIST]: ContentState.SHIPMENTS,
        [ContentEvent.ERROR]: ContentState.ERROR,
        [ContentEvent.NEW_USER]: ContentState.NEW_USER,
        [ContentEvent.EMPTY]: ContentState.EMPTY,
        [ContentEvent.EMPTY_FILTER]: ContentState.EMPTY_FILTER,
    },
    [ContentState.SHIPMENTS]: {
        [ContentEvent.LOAD]: ContentState.LOADING,
        [ContentEvent.NEXT_PAGE]: ContentState.LOADING_NEXT_PAGE,
        [ContentEvent.EMPTY]: ContentState.EMPTY,
        [ContentEvent.EMPTY_FILTER]: ContentState.EMPTY_FILTER,
    },
    [ContentState.ERROR]: {
        [ContentEvent.LOAD]: ContentState.LOADING,
    },
    [ContentState.ERROR_NEXT_PAGE]: {
        [ContentEvent.LOAD]: ContentState.LOADING_NEXT_PAGE,
    },
    [ContentState.LOADING_NEXT_PAGE]: {
        [ContentEvent.SHIPMENT_LIST]: ContentState.SHIPMENTS,
        [ContentEvent.ERROR]: ContentState.ERROR_NEXT_PAGE,
        [ContentEvent.EMPTY]: ContentState.SHIPMENTS,
    },
    [ContentState.EMPTY]: {
        [ContentEvent.SHIPMENT_LIST]: ContentState.SHIPMENTS,
        [ContentEvent.LOAD]: ContentState.LOADING,
    },
    [ContentState.NEW_USER]: {
        [ContentEvent.SHIPMENT_LIST]: ContentState.SHIPMENTS,
    },
    [ContentState.EMPTY_FILTER]: {
        [ContentEvent.LOAD]: ContentState.LOADING,
    },
};

export const reducer = (state: ContentState, event: ContentEvent): ContentState => {
    const nextState = NEXT_STATE_GRAPH[state][event];
    return nextState !== undefined ? nextState : state;
};
