import moment from 'moment';

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const MAX_RANGE_DAYS = 90;
const TODAY = new Date();
export const defaultMaxDate = moment(TODAY).subtract(1, 'days').toDate();
export const defaultMinDate = moment(TODAY).subtract(MAX_RANGE_DAYS, 'days').toDate();

const to = moment().subtract(1, 'days');
const from = moment(to).subtract(6, 'days');

export const defaultDatesRange = {
    from: from.format(API_DATE_FORMAT),
    to: to.format(API_DATE_FORMAT),
};

export function getShortDateFormat(locale: string, date: Date) {
    return Intl.DateTimeFormat(locale, { month: 'short', day: 'numeric' }).format(date);
}

export function getSubtitleRangeParams(locale: string, from: string, to: string) {
    return {
        from: Intl.DateTimeFormat(locale).format(moment(from, API_DATE_FORMAT).toDate()),
        to: Intl.DateTimeFormat(locale).format(moment(to, API_DATE_FORMAT).toDate()),
    };
}
