import { css } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getServiceStepStyles = (theme: Theme) =>
    css({
        margin: `${theme.spacing()}px ${theme.spacing(3)}px`,

        [theme.mediaQuery('desktop')]: {
            minWidth: 'max-content',
        },
    });
