import { useTranslation } from '@packlink/translation-provider';
import { IconNames } from '@shipengine/giger-theme';
import { Button, ButtonSize, ButtonVariant, Icon, IconSize, TabList } from '@shipengine/giger';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { RouterTab } from '@pages/router/components/RouterTab/RouterTab';
import { APP_ROUTE } from '@pages/router/routes';
import { useAddressesContext } from '../../context/addresses';
import { addAddressButtonStyles, addAddressIconStyles, tabListWrapperStyles } from './AddressesSettingsNavBarStyles';
import { useAmplitude } from '@hooks/useAmplitude';

export function AddressesSettingsNavBar(): JSX.Element {
    const { t } = useTranslation();
    const { hasReachedLimit, setIsAddressFormVisible } = useAddressesContext();
    const { sendAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();

    const handleNewAddress = () => {
        setIsAddressFormVisible(true);
        sendAmplitudeEvent(AmplitudeEvents.SETTINGS_ADD_ADDRESS_CLICK, {
            [AmplitudeProperties.SECTION]: location.pathname.split('/').pop(),
        });
    };

    const onClickTab = (route: string) => {
        sendAmplitudeClickEvent(AmplitudeEvents.ADDRESS_TAB, {
            [AmplitudeProperties.SECTION]: route.split('.').pop(),
        });
    };

    return (
        <>
            <SettingsPageHeader>{t('addresses.settings.title')}</SettingsPageHeader>
            <div css={tabListWrapperStyles}>
                <TabList>
                    <RouterTab
                        tabId="sender"
                        text={t('addresses.settings.sender')}
                        to={APP_ROUTE.SETTINGS.ADDRESSES_SENDER}
                        onClick={() => onClickTab('settings.addresses.sender')}
                    >
                        {t('addresses.settings.sender')}
                    </RouterTab>

                    <RouterTab
                        tabId="recipient"
                        text={t('addresses.settings.recipient')}
                        to={APP_ROUTE.SETTINGS.ADDRESSES_RECIPIENT}
                        onClick={() => onClickTab('settings.addresses.recipient')}
                    >
                        {t('addresses.settings.recipient')}
                    </RouterTab>
                </TabList>

                <Button
                    css={addAddressButtonStyles}
                    variant={ButtonVariant.TEXT}
                    size={ButtonSize.MEDIUM}
                    disabled={hasReachedLimit}
                    onClick={handleNewAddress}
                >
                    <Icon css={addAddressIconStyles} name={IconNames.ADD} size={IconSize.SIZE_SMALL} />
                    {t('addresses.settings.add-address')}
                </Button>
            </div>
        </>
    );
}
