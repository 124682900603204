import { useMemo } from 'react';
import moment, { Moment } from 'moment';

export const useDateFormat = (date?: string | Moment, dateFormat?: string): string => {
    return useMemo((): string => {
        const format = dateFormat || 'DD/MM/YYYY';

        if (!date) {
            return '';
        }

        const momentDate = typeof date === 'string' ? moment(new Date(date)) : date;

        return momentDate.format(format);
    }, [date, dateFormat]);
};
