import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { EcommerceAlias, Ecommerces } from '@Integrations/constants/ecommerce';
import { setSidePanelError } from '@Integrations/store/actions/integrations';
import { OnboardingProvider } from '@packlink/packlink-sdk';
import { AppDispatch } from '@store';
import { BrowserContext } from '@utils/BrowserContext';
import { APP_ROUTE } from '@pages/router/routes';

import { ActivateCompleteFlow } from '../ActivateCompleteFlow';

export const TikTokUkActivateCompleteFlow: FC = (): JSX.Element | null => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const goToIntegrationsList = useCallback((): void => {
        dispatch(setSidePanelError(true));
        navigate(`${APP_ROUTE.SETTINGS.INTEGRATIONS}/${OnboardingProvider.SHIPENGINE_TIKTOK_UK}`);
    }, [dispatch, navigate]);

    const onError = async (): Promise<void> => {
        goToIntegrationsList();
    };

    const queryParams = BrowserContext.getQueryParams();
    const state = queryParams.get('auth_state');
    const code = queryParams.get('code');

    useEffect(() => {
        if (!state || !code) {
            goToIntegrationsList();
        }
    }, [code, goToIntegrationsList, state]);

    return (
        <ActivateCompleteFlow
            onError={onError}
            params={{
                auth_state: state,
                code,
            }}
            integration={{
                name: Ecommerces.TikTokUk,
                alias: EcommerceAlias.TikTokUk,
            }}
            provider={OnboardingProvider.SHIPENGINE_TIKTOK_UK}
        />
    );
};
