import { useMutation, useQueryClient } from 'react-query';
import { EditClientSubscriptionPayload, SubscriptionsRepository } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { SUBSCRIPTIONS_CACHE_KEY } from '../constants';

const subscriptionsRepository = new SubscriptionsRepository(apiClient);

export function useEditClientSubscription() {
    const queryClient = useQueryClient();

    const { mutate: editClientSubscription } = useMutation(
        (params: EditClientSubscriptionPayload) => subscriptionsRepository.editClientSubscription(params),
        {
            onSuccess: () => queryClient.invalidateQueries(SUBSCRIPTIONS_CACHE_KEY),
        },
    );

    return {
        editClientSubscription,
    };
}
