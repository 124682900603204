import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PaymentMethod } from '@packlink/packlink-sdk';
import { getAvailablePaymentMethods, getFallbackPaymentMethod } from '@store/selectors/payment';

const defaultPaymentMethods = [PaymentMethod.CREDITCARD3DSECURE];

export const usePaymentMethods = (
    hasCreditCard: boolean,
    hasPaypal: boolean,
    allowedPaymentMethods?: PaymentMethod[],
) => {
    const availablePaymentMethodsStore = useSelector(getAvailablePaymentMethods);
    const fallbackPaymentMethod = useSelector(getFallbackPaymentMethod);

    const availablePaymentMethods = availablePaymentMethodsStore.map(({ method }) => {
        return method === PaymentMethod.CREDITCARD ? PaymentMethod.CREDITCARD3DSECURE : method;
    });

    const selectedPaymentMethod = useMemo(() => {
        if (fallbackPaymentMethod === PaymentMethod.BANCONTACT) {
            return PaymentMethod.BANCONTACT;
        } else if (hasCreditCard) {
            return PaymentMethod.CREDITCARD3DSECURE;
        } else if (hasPaypal) {
            return PaymentMethod.PAYPAL;
        }

        return allowedPaymentMethods?.[0] || availablePaymentMethods[0];
    }, [hasCreditCard, hasPaypal, availablePaymentMethods, allowedPaymentMethods, fallbackPaymentMethod]);

    return {
        availablePaymentMethods: availablePaymentMethods.length > 0 ? availablePaymentMethods : defaultPaymentMethods,
        selectedPaymentMethod,
    };
};
