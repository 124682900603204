import { Radio, Grid, GridChild, Typography } from '@shipengine/giger';
import { ClientType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { getTitleStyles, getWrapperStyles } from './SettingsClientTypeSelectorStyles';

interface ISettingsClientTypeSelectorProps {
    clientType: ClientType;
    onClientTypeChange: (selectedType: ClientType) => void;
}

export const SettingsClientTypeSelector = ({
    clientType,
    onClientTypeChange,
}: ISettingsClientTypeSelectorProps): JSX.Element => {
    const { t } = useTranslation();

    const onRadioChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onClientTypeChange(e.target.value as ClientType);
    };

    return (
        <div css={getWrapperStyles}>
            <Typography component="h4" variant="heading4" css={getTitleStyles}>
                {t('billing-details.business-data.taxes-title')}
            </Typography>

            <Grid noPadding>
                <GridChild colStart={0} colSpan={12} colSpanTablet={6}>
                    <Radio
                        name="clientType"
                        value={ClientType.BUSINESS}
                        checked={clientType === ClientType.BUSINESS}
                        onChange={onRadioChangeHandler}
                    >
                        {t('billing-details.business-data.business-title')}
                    </Radio>
                </GridChild>

                <GridChild colStartTablet={7} colStart={0} colSpan={12} colSpanTablet={6}>
                    <Radio
                        name="clientType"
                        value={ClientType.AUTONOMOUS}
                        checked={clientType === ClientType.AUTONOMOUS}
                        onChange={onRadioChangeHandler}
                    >
                        {t('billing-details.business-data.autonomous-title')}
                    </Radio>
                </GridChild>
            </Grid>
        </div>
    );
};
