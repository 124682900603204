import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonColor, ButtonSize, ButtonVariant, Typography, useToast } from '@shipengine/giger';
import { Address, IAddress } from '@packlink/packlink-sdk';
import { TimeTable } from '@components/TimeTable/TimeTable';
import { PudoDialog } from '@components/PudoDialog/PudoDialog';
import { useDropoffInfo } from './useDropoffInfo';
import {
    getAddressStyles,
    getBusinessNameTitleStyles,
    getDropoffInfoStyles,
    getDropoffInfoTitleStyles,
    getMainButtonStyles,
    getReferenceStyles,
} from './DropoffInfoStyles';
import { useEffect, useState } from 'react';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IDropoffInfoProps {
    origin: Address<IAddress>;
    serviceId: number | string;
    dropoffPointId?: string;
    showDropoffRequiredError?: boolean;
    showDropoffSelection?: boolean;
}

export function DropoffInfo({
    origin,
    serviceId,
    dropoffPointId,
    showDropoffRequiredError,
    showDropoffSelection,
}: IDropoffInfoProps): JSX.Element {
    const { showPudoDialog, handleDialogClose, handleDialogOpen } = useDropoffInfoDialog();
    const { currentPoint, onPudoSelection } = useDropoffInfo({
        origin,
        serviceId,
        initialDropoffId: dropoffPointId,
        onDialogClose: handleDialogClose,
    });
    const haveDropoffSelected = !!currentPoint;
    const { t } = useTranslation();
    const toast = useToast(t);

    useEffect(() => {
        if (showDropoffRequiredError) {
            toast.error({ message: t('dropoff-info.error.text') });
        }
    }, [showDropoffRequiredError, t, toast]);

    const address = `${currentPoint?.address}, ${currentPoint?.zipCode}, ${currentPoint?.city}`;

    return (
        <div data-id="dropoffInfo" css={getDropoffInfoStyles}>
            <Typography css={getDropoffInfoTitleStyles} variant="heading5" component="h3" bold>
                {t('dropoff-info.dropoff.title')}
            </Typography>

            {haveDropoffSelected && (
                <div>
                    <Typography css={getBusinessNameTitleStyles} variant="body2" component="p">
                        {currentPoint?.businessName?.toLowerCase()}
                    </Typography>
                    <Typography css={getReferenceStyles} variant="body2" component="p">
                        {t('dropoff-info.dropoff-point.reference')}: {currentPoint?.id}
                    </Typography>

                    <Typography data-id="dropoff-info-data" css={getAddressStyles}>
                        {address.toLowerCase()}
                    </Typography>
                    {currentPoint?.openingTimes && <TimeTable openingTimes={currentPoint.openingTimes} />}
                </div>
            )}
            {showDropoffSelection && (
                <Button
                    css={getMainButtonStyles(haveDropoffSelected)}
                    variant={haveDropoffSelected ? ButtonVariant.OUTLINED : ButtonVariant.FILLED}
                    color={ButtonColor.PRIMARY}
                    size={ButtonSize.LARGE}
                    onClick={handleDialogOpen}
                >
                    {t(`dropoff-info.dropoff-point.${haveDropoffSelected ? 'change' : 'select'}`)}
                </Button>
            )}
            {showPudoDialog && (
                <PudoDialog
                    serviceId={serviceId}
                    address={origin}
                    onDialogClose={handleDialogClose}
                    onPudoSelection={onPudoSelection}
                />
            )}
        </div>
    );
}

function useDropoffInfoDialog() {
    const { sendAmplitudeEvent } = useAmplitude();

    const [showPudoDialog, setShowPudoDialog] = useState(false);

    const handleDialogClose = () => setShowPudoDialog(false);
    const handleDialogOpen = () => {
        setShowPudoDialog(true);
        sendAmplitudeEvent(AmplitudeEvents.PUDO_OPEN);
    };
    return { showPudoDialog, handleDialogClose, handleDialogOpen };
}
