import { useTranslation } from '@packlink/translation-provider';
import { IconNames } from '@shipengine/giger-theme';
import { Category, Icon, SidebarMenu, Tag, TagColor } from '@shipengine/giger';
import { ClientFeature } from '@packlink/packlink-sdk';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { ITenantConfig } from '@types';
import { BrowserContext } from '@utils/BrowserContext';
import { RouterCategory } from '../../../router/components/RouterCategory/RouterCategory';
import { SettingsRoute } from '../../routes';
import { getSettingsMenuStyles } from './SettingsMenuStyles';
import { useAmplitude } from '@hooks/useAmplitude';
import { useSubscriptionFeature } from '@hooks/useSubscriptionFeatureBehaviours';

export function SettingsMenu(): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const { volumeDiscounts, userCanGenerateApiKey } = useTenantConfig<ITenantConfig>();

    const { isTenantSubscriptionEnabled, feature: ownContractFeature } = useSubscriptionFeature(
        ClientFeature.OWN_CONTRACT,
    );

    const { feature: brandedEmailsFeature } = useSubscriptionFeature(ClientFeature.BRANDED_EMAILS);

    const showOwnContract = ownContractFeature.isAvailable;

    const sendEvent = (eventName: AmplitudeEvents) => {
        sendAmplitudeClickEvent(`${eventName} ${AmplitudeEventPrefixes.CONFIGURATION_MENU}`);
    };

    return (
        <SidebarMenu css={getSettingsMenuStyles}>
            <RouterCategory
                text={t('billing-and-invoices.settings.title')}
                iconName={IconNames.FILE}
                to={SettingsRoute.BILLING}
                onClick={() => sendEvent(AmplitudeEvents.BILLING)}
            />
            <RouterCategory
                text={t('your-account.settings.title')}
                iconName={IconNames.USER}
                to={SettingsRoute.YOUR_ACCOUNT}
                onClick={() => sendEvent(AmplitudeEvents.ACCOUNT)}
            />
            <RouterCategory
                text={t('privacy.settings.title')}
                iconName={IconNames.USER}
                to={SettingsRoute.PRIVACY}
                onClick={() => sendEvent(AmplitudeEvents.PRIVACY)}
            />
            <RouterCategory
                text={t('shipping-rules.settings.title')}
                iconName={IconNames.AUTOMATION}
                to={SettingsRoute.SHIPPING_RULES}
                onClick={() => sendEvent(AmplitudeEvents.SHIPPING_RULES)}
                rightContent={<Tag color={TagColor.SUCCESS}>{t('settings.menu.new-tag')}</Tag>}
            />
            <RouterCategory
                text={t('addresses.settings.title')}
                iconName={IconNames.HOUSE}
                to={SettingsRoute.ADDRESSES}
                onClick={() => sendEvent(AmplitudeEvents.PRE_SET_ADDRESSES)}
            />
            {volumeDiscounts && (
                <RouterCategory
                    text={t('volume-discounts.settings.title')}
                    iconName={IconNames.SAVINGS}
                    to={SettingsRoute.VOLUME_DISCOUNT}
                    onClick={() => sendEvent(AmplitudeEvents.VOLUME_DISCOUNT)}
                />
            )}
            <RouterCategory
                text={t('parcels.settings.title')}
                iconName={IconNames.CARDBOARD_BOX}
                to={SettingsRoute.PARCELS}
                onClick={() => sendEvent(AmplitudeEvents.PARCELS)}
            />
            <RouterCategory
                text={t('integrations.settings.title')}
                iconName={IconNames.WORLD}
                to={SettingsRoute.INTEGRATIONS}
                onClick={() => sendEvent(AmplitudeEvents.CONNECT_STORE)}
            />
            {showOwnContract && (
                <RouterCategory
                    text={t('menu.options.own-contracts')}
                    iconName={IconNames.CONTRACT}
                    to={SettingsRoute.OWN_CONTRACTS}
                    onClick={() => sendEvent(AmplitudeEvents.CARRIER_CONTRACTS)}
                    rightContent={
                        isTenantSubscriptionEnabled && !ownContractFeature.isEnabled ? (
                            <Icon name={IconNames.PADLOCK} title={t('settings-page.header.locked')} />
                        ) : undefined
                    }
                />
            )}
            {brandedEmailsFeature.isAvailable && (
                <RouterCategory
                    text={t('menu.options.branded-emails')}
                    iconName={IconNames.BUCKET}
                    to={SettingsRoute.BRANDED_EMAILS}
                    onClick={() => sendEvent(AmplitudeEvents.BRANDED_EMAILS)}
                    rightContent={
                        !brandedEmailsFeature.isEnabled ? (
                            <Icon name={IconNames.PADLOCK} title={t('settings-page.header.locked')} />
                        ) : undefined
                    }
                    role="tab"
                />
            )}
            {userCanGenerateApiKey && (
                <RouterCategory
                    text={t('api-key.settings.title')}
                    iconName={IconNames.KEY}
                    to={SettingsRoute.API_KEY}
                />
            )}
            <RouterCategory
                text={t('archiving-menu.settings.title')}
                iconName={IconNames.BUFFER}
                to={SettingsRoute.ARCHIVING}
                onClick={() => sendEvent(AmplitudeEvents.ARCHIVING)}
            />

            <Category
                text={t('menu.options.sign-out')}
                iconName={IconNames.LOGOUT}
                htmlElement="a"
                href={BrowserContext.getLogoutPath(config, locale)}
                role="tab"
            />
        </SidebarMenu>
    );
}
