import { useState } from 'react';
import { FastField, useFormikContext } from 'formik';
import { Checkbox, FormField } from '@shipengine/formik-giger';
import { useTranslation } from '@packlink/translation-provider';
import {
    InlineNotification,
    Next,
    NotificationType,
    SidePanel,
    SidePanelDefaultFooter,
    SlideInPosition,
    Typography,
} from '@shipengine/giger';
import { OwnContractModel, OwnContractModelService } from '@packlink/packlink-sdk';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { ViewMoreLessButton } from '@components/ViewMoreLessButton/ViewMoreLessButton';
import { useTenantSubscriptionConfig } from '@Subscriptions/hooks/useClientSubscriptions';
import {
    getCarrierLogoStyles,
    getFieldStyles,
    getInfoMessageStyles,
    getMainSectionStyles,
} from './ServiceSelectionPanelStyles';
import { MAX_OWN_CONTRACT_SERVICES } from './constants';
import { ServicesFormValues } from './types';
import { useSidePanel } from '@hooks/useSidePanel';
import { PanelName } from '@types';

interface ServiceSelectionFormProps {
    ownContract: OwnContractModel;
    logoUrl: string;
    onClose: () => void;
}

export const ServiceSelectionForm = ({ onClose, ownContract, logoUrl }: ServiceSelectionFormProps): JSX.Element => {
    const { t } = useTranslation();
    const { handleSubmit } = useFormikContext<ServicesFormValues[]>();
    const sidePanel = useSidePanel(PanelName.OWN_CONTRACT_SERVICE_SELECTION);
    const defaultVisibleOwnContractServices = ownContract.services?.slice(0, MAX_OWN_CONTRACT_SERVICES);
    const { links } = useTenantSubscriptionConfig();
    const [isMoreActive, setIsMoreActive] = useState<boolean>(false);
    const [visibleOwnContractServices, setVisibleOwnContractServices] = useState<OwnContractModelService[]>(
        defaultVisibleOwnContractServices,
    );
    const servicesLength = ownContract.services?.length;
    const isHiddenOwnContractServices: boolean = servicesLength > visibleOwnContractServices.length;

    const handleOnClick = () => {
        setVisibleOwnContractServices(
            isHiddenOwnContractServices ? ownContract.services : defaultVisibleOwnContractServices,
        );
        setIsMoreActive(!isMoreActive);
    };

    const closePanel = (): void => {
        sidePanel.close();
        onClose();
    };

    const handleOnSubmit = (): void => {
        handleSubmit();
    };

    return (
        <SidePanel
            position={SlideInPosition.RIGHT}
            isOpen={sidePanel.isOpen}
            onBackdropClick={closePanel}
            containerId="settings-panel-container"
            applyBackdropToBody
            includeBackdrop
            fullWidthOnMobile
            footer={
                <SidePanelDefaultFooter
                    onClickCancel={onClose}
                    onClickSubmit={handleOnSubmit}
                    isSubmitBtnDisabled={!ownContract.services}
                    cancelText={t('own-contract.panel.cancel')}
                    submitText={t('own-contract.service-selection-panel.save')}
                />
            }
            header={
                <SidePanelHeader title={t('own-contract.service-selection-panel.header-title')} onAction={onClose} />
            }
        >
            <SidePanelContent>
                <section css={getMainSectionStyles}>
                    <img css={getCarrierLogoStyles} alt={ownContract.carrierName} src={logoUrl} />
                    <Typography variant="subtitle2">
                        {t('own-contract.service-selection-panel.subtitle', {
                            carrier: ownContract.carrierName,
                        })}
                    </Typography>
                </section>
                {visibleOwnContractServices.map(
                    ({ name, id }: OwnContractModelService): JSX.Element => (
                        <FormField css={getFieldStyles} name={id} key={`${name}-key`}>
                            <FastField component={Checkbox} name={id} label={name} />
                        </FormField>
                    ),
                )}
                {servicesLength > MAX_OWN_CONTRACT_SERVICES && (
                    <ViewMoreLessButton handleOnClick={handleOnClick} isMoreActive={isMoreActive} />
                )}
                <InlineNotification
                    css={getInfoMessageStyles}
                    actionElement={
                        <Next.Link key="link2" isExternal target="_blank" href={links?.typeform}>
                            {t('own-contract.service-selection-panel.add-service')}
                        </Next.Link>
                    }
                    type={NotificationType.INFO}
                    title={t('own-contract.service-selection-panel.info-title')}
                >
                    {t('own-contract.service-selection-panel.info-message')}
                </InlineNotification>
            </SidePanelContent>
        </SidePanel>
    );
};
