import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getDownloadLabelStyles = (): SerializedStyles =>
    css({
        width: '100%',
    });

export const getDownloadLabelButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(),
    });

export const getDownloadLabelOptionsStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });

export const getFormatToggleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',

        '::before, ::after': {
            flex: 1,
            borderBottom: `1px solid ${theme.palette.gray.light}`,
            content: '""',
        },

        ':not(:empty)::before': {
            marginRight: theme.spacing(0.5),
        },

        ':not(:empty)::after': {
            marginLeft: theme.spacing(0.5),
        },
    });

export const getFormatToggleIconStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        color: theme.palette.primary.main,
    });

export const getInfoPanelStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
        textAlign: 'left',
    });

export const getQRLabelStyles = (): SerializedStyles =>
    css({
        width: '100%',
    });
