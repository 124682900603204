import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketChatStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'grid',
        gridTemplateRows: 'min-content min-content max-content',
        [theme.mediaQuery('tablet')]: {
            gridTemplateRows: 'min-content min-content 1fr',
            // This is needed to ensure scroll is shown correctly
            // https://stackoverflow.com/questions/65599857/css-grid-layout-1fr-exceeds-parent-height
            minHeight: 0,
        },
    });

export const getTicketChatSpinnerWrapper = (theme: Theme): SerializedStyles =>
    css({
        width: '100%',
        height: theme.spacing(20),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.mediaQuery('tablet')]: {
            height: theme.spacing(40),
        },
    });
