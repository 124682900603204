import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { InsuranceSelector } from '@packlink/insurance-selector';
import { AmplitudeTypeOfLocations } from '@packlink/metrics';
import { useTranslation } from '@packlink/translation-provider';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { AmplitudeEvents, AmplitudeProperties, FLOW_AMPLITUDE_PROPERTY } from '@constants/amplitude';
import { ProductOptionsInsurances } from '@utils/UpsellUtils';
import { IOtherBenefitsForm } from './ShipmentPanelOtherBenefits';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IOtherBenefitsInsuranceProps {
    currency: string;
    insurances: ProductOptionsInsurances;
    onChangeValue: (value: number) => void;
    contentValue?: number;
    isSecondHand?: boolean;
    hasProofOfDelivery: boolean;
}

export const OtherBenefitsInsurance: React.FC<IOtherBenefitsInsuranceProps> = (
    props: IOtherBenefitsInsuranceProps,
): JSX.Element => {
    const { contentValue, currency, insurances, isSecondHand, hasProofOfDelivery } = props;
    const {
        cdn: { url: cdnUrl },
    } = config;
    const { t } = useTranslation();
    const { values, setFieldValue, validateForm } = useFormikContext<IOtherBenefitsForm>();
    const { sendAmplitudeEvent, sendAmplitudeSidebarClickEvent } = useAmplitude();

    useEffect((): void => {
        // Fix for enableReinitialize that resets form validations when async initialValues are set
        validateForm(values);
    }, [values, validateForm]);

    const sendPanelEvent = (data: Record<string, unknown>): void => {
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.OTHER_BENEFITS_PANEL, data);
    };

    const onChangeInsurance = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
        sendPanelEvent({
            [AmplitudeProperties.INSURANCE]: value,
            [AmplitudeProperties.CONTENT_VALUE]: !!contentValue,
        });
        setFieldValue('insurance', value);
        sendAmplitudeEvent(AmplitudeEvents.CLICK_ON_INSURANCE, {
            [AmplitudeProperties.INSURANCE]: value,
            [AmplitudeProperties.FLOW]: FLOW_AMPLITUDE_PROPERTY.SIDE_PANEL,
            [AmplitudeProperties.LOCATION]: AmplitudeTypeOfLocations.ADDRESS_STEP,
        });
    };

    return (
        <SidePanelContentSection
            title={t('other-benefits.insurance.section-title')}
            subtitle={t('other-benefits.insurance.section-subtitle')}
        >
            <InsuranceSelector
                currency={currency}
                name="insurance"
                imageUrl={`${cdnUrl}/pro/statics/images/insurance-box.svg`}
                onChange={onChangeInsurance}
                contentValue={contentValue ?? 0}
                insurancesToDisplay={insurances}
                insuranceTypeSelected={values.insurance}
                isSecondHand={isSecondHand}
                hasVerticalLayout
                hasProofOfDelivery={hasProofOfDelivery}
            />
        </SidePanelContentSection>
    );
};
