import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getFormStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    });

export const getOTPInputStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        gap: theme.spacing(),

        '&&> *': {
            width: '43px',
        },
    });

export const getParagraphStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(0.5),
    });
