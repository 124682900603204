import { InlineNotification, NotificationType, Link, WithCommonProps } from '@shipengine/giger';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { Trans } from '@packlink/translation-provider';

type ShipmentDetailsPackagingAdviceProps = WithCommonProps<unknown>;

export function ShipmentDetailsPackagingAdvice(props: ShipmentDetailsPackagingAdviceProps): JSX.Element {
    const getHelpCenter = useHelpCenter();
    return (
        <InlineNotification type={NotificationType.INFO} {...props}>
            <Trans
                i18nKey="post-sale.advices.good-packaging"
                components={[
                    <Link
                        key="link"
                        href={getHelpCenter(HELP_CENTER_KEYS.packagingGuide)}
                        isExternal
                        target="_blank"
                    />,
                ]}
            />
        </InlineNotification>
    );
}
