import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, ButtonSize, ButtonVariant, Spinner, useToast } from '@shipengine/giger';
import { InvoiceForm, PhoneNumberValue } from '@packlink/invoice-form';
import { ApiClientError, Client, ClientType, IClientApiUpdateResponse } from '@packlink/packlink-sdk';

import { AppDispatch } from '@store';
import { setInvoiceInfoSavedAction } from '@store/actions/checkout';
import { getCheckoutFrom } from '@store/selectors/checkout';
import { AmplitudeProperties } from '@constants/amplitude';
import { AmplitudeCategories, AmplitudeEvents } from '@packlink/metrics';
import { BillingClientType } from '@components/BillingForm/BillingClientType';
import { getRadioOptionsStyles, getSpinnerStyles } from './BillingInfoFormStyles';
import { useBillingForm } from '@common/hooks/useBillingForm';
import { useAmplitude } from '@hooks/useAmplitude';
import { PhoneNumberVerificationPanel } from '@common/components/PhoneNumberVerificationPanel/PhoneNumberVerificationPanel';

interface IBillingInfoFormProps {
    isPhoneNumberVerificationOpen: boolean;
    onBillingFormSuccess: (client: Client) => void;
    closePhoneNumberVerification: () => void;
    onFormSubmit: () => void;
}

const validationInvoiceForm = {
    validateOnBlur: false,
    validateOnChange: true,
};

export const BillingInfoForm = ({
    isPhoneNumberVerificationOpen,
    onBillingFormSuccess,
    closePhoneNumberVerification,
    onFormSubmit,
}: IBillingInfoFormProps): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const from = useSelector(getCheckoutFrom);
    const { sendAmplitudeEvent } = useAmplitude();
    const toast = useToast(t);
    const {
        client,
        billingFormRef,
        clientRequiresPhoneNumberVerification,
        isRequiresPhoneNumberVerificationEnabled,
        isPhoneNumberVerified,
        isClientRequiresPhoneNumberVerificationLoading,
        submitBillingForm,
    } = useBillingForm();

    const [formType, setFormType] = useState(() =>
        client.type && [ClientType.AUTONOMOUS, ClientType.BUSINESS].includes(client.type)
            ? client.type
            : ClientType.AUTONOMOUS,
    );

    const [initialPhoneNumberValue, setInitialPhoneNumberValue] = useState<PhoneNumberValue | string>();

    const continueWithPayment = () => {
        dispatch(setInvoiceInfoSavedAction(true));
        onFormSubmit();
    };

    const onSave = (_data: IClientApiUpdateResponse, client: Client, phoneNumberValue: PhoneNumberValue | string) => {
        onBillingFormSuccess(client);
        sendAmplitudeEvent(AmplitudeEvents.SAVE_BILLING_INFO, {
            [AmplitudeProperties.TYPE_OF_BILLING]: formType.toLowerCase(),
            [AmplitudeProperties.CATEGORY]: AmplitudeCategories.CHECKOUT,
        });

        setInitialPhoneNumberValue(phoneNumberValue);

        !clientRequiresPhoneNumberVerification && continueWithPayment();
    };

    const onFailure = (error: ApiClientError) => {
        if (error.status === 422) {
            toast.error({
                message: t('invoice-form.error.unprocessable-entity'),
            });
        } else {
            toast.error({
                message: t('invoice-form.error.default'),
            });
        }
    };

    return (
        <>
            <BillingClientType type={formType} onChange={setFormType} css={getRadioOptionsStyles} />
            {isClientRequiresPhoneNumberVerificationLoading ? (
                <Spinner css={getSpinnerStyles} />
            ) : (
                <>
                    <InvoiceForm
                        type={formType}
                        client={client}
                        from={from}
                        validation={validationInvoiceForm}
                        onSuccess={onSave}
                        showSubmitButton={false}
                        ref={billingFormRef}
                        cdnUrl={config.cdn.baseUrl}
                        isPhoneNumberVerified={isPhoneNumberVerified}
                        requiresPhoneNumberVerification={isRequiresPhoneNumberVerificationEnabled}
                        onFailure={onFailure}
                    />

                    <Button
                        isFullWidth
                        onClick={submitBillingForm}
                        size={ButtonSize.LARGE}
                        variant={ButtonVariant.OUTLINED}
                    >
                        {t('invoice-form.label.save-button')}
                    </Button>
                </>
            )}

            <PhoneNumberVerificationPanel
                isOpen={isPhoneNumberVerificationOpen}
                initialPhoneNumberValue={initialPhoneNumberValue}
                alpha2Code={client.alpha2Code}
                onClose={closePhoneNumberVerification}
                onComplete={continueWithPayment}
            />
        </>
    );
};
