import { ScaleTimeSpec } from '@nivo/scales';

export function formatOnlyIntegersTicks(data: number) {
    return Math.floor(data) === data && data;
}

export function getTimeScale(): ScaleTimeSpec {
    return {
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: false,
    };
}
