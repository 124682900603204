import { useTranslation } from '@packlink/translation-provider';
import { InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { Typography } from '@shipengine/giger';

export interface Captions {
    title: string;
    content: string;
}

interface OwnContractPanelHelpProps {
    title: string;
    captions?: Captions[];
    footNote: string;
}

export const OwnContractPanelHelp = ({ captions, title, footNote }: OwnContractPanelHelpProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <InfoPanel title={t(title)} collapsible={true}>
            <InfoPanelStepList>
                {captions?.map(({ content, title }) => (
                    <InfoPanelStep key={title} title={title}>
                        <Typography dangerouslySetInnerHTML={{ __html: content }} variant="body2" component="p" />
                    </InfoPanelStep>
                ))}
            </InfoPanelStepList>
            <InfoPanelStep>
                <Typography variant="body2" component="p">
                    <strong>{t('own-contract.help.note')}: </strong>
                    {t(footNote)}
                </Typography>
            </InfoPanelStep>
        </InfoPanel>
    );
};
