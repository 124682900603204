import { useSubscriptionFeatureBehaviours, useSubscriptionFeatures } from '@hooks';
import { ClientFeature } from '@packlink/packlink-sdk';
import { setContext, setTag, setUser } from '@sentry/react';
import { LoggerContext, LoggerTag } from '@utils/logger';
import { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getClientIdentifier } from '@store/selectors/client';
import { getUserId } from '@store/selectors/user';
import { useTranslation } from '@packlink/translation-provider';

export function SentryAdditionalDataWrapper({ children }: PropsWithChildren<unknown>): JSX.Element {
    const {
        i18n: { language: locale },
    } = useTranslation();
    const clientId = useSelector(getClientIdentifier);
    const userId = useSelector(getUserId);
    const subscriptionData = useSubscriptionFeatureBehaviours();
    const featuresEnableStatus = useGetFeatureEnableStatus();

    useEffect(() => {
        setUser({ id: userId, client: clientId });
        setTag(LoggerTag.LOCALE, locale);
        setTag(LoggerTag.PLAN, subscriptionData.subscription?.planName ?? 'Unsubscribed');
        setContext(LoggerContext.AVAILABLE_FEATURES, featuresEnableStatus);
    }, [locale, userId, clientId, subscriptionData, featuresEnableStatus]);

    return <>{children}</>;
}

/**
 * Returns all subscription features and their enable status.
 */
function useGetFeatureEnableStatus() {
    const subscriptionData = useSubscriptionFeatureBehaviours();
    const features = useSubscriptionFeatures(Object.keys(subscriptionData.availableFeatures ?? {}) as ClientFeature[]);
    const featuresEnableStatus: Partial<Record<ClientFeature, boolean>> = {};

    features.forEach(({ feature, isTenantSubscriptionEnabled }) => {
        featuresEnableStatus[feature.featureName] = feature.isEnabled && isTenantSubscriptionEnabled;
    });

    return featuresEnableStatus;
}
