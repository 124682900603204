import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getLabelStyles = (theme: Theme): SerializedStyles =>
    css({
        fontSize: theme.toRem(theme.typography.fontSize.M),
        whiteSpace: 'nowrap',
    });

export const getEmailLabelStyle = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.main,
        fontSize: theme.toRem(theme.typography.fontSize.M),
    });
