import { SubscriptionsRepository } from '@packlink/packlink-sdk';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { useQuery } from 'react-query';
import { SUBSCRIPTION_PLAN_FEATURES_CACHE_KEY } from '@providers/SubscriptionFeatureBehavioursContext';
import { useTenantSubscriptionConfig } from './useTenantSubscriptionConfig';

export function useSubscriptionPlanFeatures() {
    const sdk = useSdk();
    const subscriptionsRepository = new SubscriptionsRepository(sdk.apiClient);
    const { isTenantSubscriptionEnabled } = useTenantSubscriptionConfig();

    const response = useQuery(
        [SUBSCRIPTION_PLAN_FEATURES_CACHE_KEY],
        () => subscriptionsRepository.getAvailablePlanFeatures(),
        {
            enabled: isTenantSubscriptionEnabled,
            refetchOnMount: false,
            staleTime: 150,
        },
    );

    return { availableFeatures: response.data };
}
