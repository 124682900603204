import { FC } from 'react';

import { InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { useTranslation } from '@packlink/translation-provider';

import { IntegrationGuide } from '../../IntegrationGuide/IntegrationGuide';

interface IOnboardingPartnerIntegrationGuide {
    partner: string;
}

export const OnboardingPartnerIntegrationGuide: FC<IOnboardingPartnerIntegrationGuide> = ({
    partner,
}: IOnboardingPartnerIntegrationGuide): JSX.Element => {
    const { t } = useTranslation();

    return (
        <IntegrationGuide>
            <InfoPanel title={t('integrations.guide.title')} collapsible={true}>
                <InfoPanelStepList>
                    <InfoPanelStep title={t('INTEGRATION_EPAGES_STEP1', { partner })} />
                    <InfoPanelStep title={t('INTEGRATION_EPAGES_STEP2')} />
                    <InfoPanelStep title={t('INTEGRATION_EPAGES_STEP3')} />
                </InfoPanelStepList>
            </InfoPanel>
        </IntegrationGuide>
    );
};
