import { useTranslation } from '@packlink/translation-provider';
import {
    Table,
    TableBody,
    TableBodyCell,
    TableHeader,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
    Typography,
} from '@shipengine/giger';
import { IUseVolumeDiscountData } from '../../hooks/useVolumeDiscountData';
import { tableStyles } from './RatesTableStyles';

export interface IRatesTableProps {
    volumeDiscountRates: IUseVolumeDiscountData['volumeDiscountRates'];
}

export function RatesTable({ volumeDiscountRates }: IRatesTableProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <Typography component="p" variant="body1">
                {t('volume-discount.settings.table-info')}
            </Typography>

            <Table
                css={tableStyles}
                header={
                    <TableHeader>
                        <TableHeaderRow>
                            <TableHeaderCell>{t('volume-discount.settings.carriers')}</TableHeaderCell>
                            <TableHeaderCell>{t('volume-discount.settings.your-current-volume')}</TableHeaderCell>
                        </TableHeaderRow>
                    </TableHeader>
                }
            >
                <TableBody>
                    {volumeDiscountRates.map((rate) => (
                        <TableRow key={rate.carrierId}>
                            <TableBodyCell>{rate.carrierName}</TableBodyCell>
                            <TableBodyCell>
                                {rate.label
                                    ? t('volume-discount.settings.per-month', { value: rate.label })
                                    : t('volume-discount.settings.no-rate')}
                            </TableBodyCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}
