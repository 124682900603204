import { ISpinnerProps as BaseSpinnerProps, Spinner as BaseSpinner, SpinnerSize } from '@shipengine/giger';
import { getAsset } from '@utils/cdn';
import React from 'react';

type SpinnerProps = BaseSpinnerProps & {
    fullScreen?: boolean;
};

export const Spinner: React.FunctionComponent<SpinnerProps> = ({
    fullScreen,
    image = <img src={getAsset('icons/spinner.svg')} alt="Loading" />,
    ...rest
}: SpinnerProps): JSX.Element => {
    const renderSpinner = (): JSX.Element => (
        <BaseSpinner image={image} size={fullScreen ? SpinnerSize.SIZE_LARGE : rest.size} {...rest} />
    );

    const renderWrappedSpinner = (): JSX.Element => (
        <div
            style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%)',
            }}
        >
            {renderSpinner()}
        </div>
    );

    return fullScreen ? renderWrappedSpinner() : renderSpinner();
};
