import { useTranslation } from '@packlink/translation-provider';
import { Select, Option, IDropdownOption, ButtonVariant } from '@shipengine/giger';
import { useState } from 'react';
import { CancelShipmentButton } from './CancelShipmentButton';
import { getCancelShipmentDialogFormSubmitButtonStyles } from './CancelShipmentDialogFormStyles';
import { getCancelReasonLabelKey } from '@Postsale/utils/cancellation';

type CancelShipmentDialogFormProps = {
    onSubmit: (reason: string) => void;
};

export function CancelShipmentDialogForm({ onSubmit }: CancelShipmentDialogFormProps): JSX.Element {
    const { t } = useTranslation();
    const cancelReasons = config.contactSupportReasons.CANCEL_SHIPMENT as string[];
    const options = cancelReasons.map((reason) => ({
        label: t(getCancelReasonLabelKey(reason)),
        value: reason,
    }));
    const [selectedReason, setSelectedReason] = useState(options[0]);
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit(selectedReason.value);
    };

    return (
        <form onSubmit={handleSubmit} onClick={preventEvents}>
            <Select
                value={selectedReason}
                name="contact-reason-select"
                label={t('post-sale.details.contact-reason-label')}
            >
                {options.map((option: IDropdownOption<unknown>) => (
                    <Option
                        onClick={() => setSelectedReason(option)}
                        key={option.label}
                        value={option.value}
                        selected={selectedReason === option}
                    >
                        {option.label}
                    </Option>
                ))}
            </Select>

            <CancelShipmentButton
                data-id="cancel-popover-cancel-button"
                css={getCancelShipmentDialogFormSubmitButtonStyles}
                type="submit"
                variant={ButtonVariant.FILLED}
                isFullWidth
            />
        </form>
    );
}

function preventEvents(e: React.SyntheticEvent) {
    e.stopPropagation();
}
