import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';
import { getClientData } from '@store/selectors/client';
import { setClient } from '@store/actions/client';
import { Client } from '@packlink/packlink-sdk';
import { useToast } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { useRequiresPhoneNumberVerification } from './useRequiresPhoneNumberVerification';
import { useAmplitude } from '@hooks';
import { AmplitudeEvents } from '@constants/amplitude';

export function useBillingForm() {
    const billingFormRef = useRef<HTMLFormElement>(null);
    const dispatch = useDispatch<AppDispatch>();
    const client = useSelector(getClientData);
    const { t } = useTranslation();
    const toast = useToast(t);
    const { sendAmplitudeEvent } = useAmplitude();

    const [isPhoneNumberVerificationOpen, setIsPhoneNumberVerificationOpen] = useState(false);

    const {
        isClientRequiresPhoneNumberVerificationLoading,
        clientRequiresPhoneNumberVerification,
        isRequiresPhoneNumberVerificationEnabled,
        isPhoneNumberVerified,
    } = useRequiresPhoneNumberVerification();

    const submitBillingForm = () => {
        billingFormRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    };

    const onBillingFormSuccess = useCallback(
        (client: Client) => {
            dispatch(setClient(client));

            if (clientRequiresPhoneNumberVerification) {
                setIsPhoneNumberVerificationOpen(true);
                sendAmplitudeEvent(AmplitudeEvents.VERIFICATION_STARTED);
            } else {
                toast.success({ message: t('settings.success.information-saved') });
            }
        },
        [dispatch, sendAmplitudeEvent, clientRequiresPhoneNumberVerification, toast, t],
    );

    const closePhoneNumberVerification = () => {
        setIsPhoneNumberVerificationOpen(false);
    };

    return {
        billingFormRef,
        submitBillingForm,
        onBillingFormSuccess,
        closePhoneNumberVerification,
        client,
        clientRequiresPhoneNumberVerification,
        isRequiresPhoneNumberVerificationEnabled,
        isPhoneNumberVerificationOpen,
        isPhoneNumberVerified,
        isClientRequiresPhoneNumberVerificationLoading,
    };
}
