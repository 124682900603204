import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getSectionTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        textTransform: 'uppercase',
        marginBottom: theme.spacing(2),
        color: theme.palette.secondary.main,
    });

export const getFormSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getMarketingSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });
