import { WithCommonProps } from '@shipengine/giger';
import { FastField, FieldProps } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { SupportTicketPaymentMethod } from '@packlink/packlink-sdk';
import { getFormElementStyles } from '../../../styles/FormStyles';
import { SupportTicketBankPaymentField } from './SupportTicketBankPaymentField';
import { SupportTicketPaypalPaymentField } from './SupportTicketPaypalPaymentField';
import {
    initialSupportTicketBankPaymentData,
    initialSupportTicketPaypalPaymentData,
    SupportTicketPaymentFieldData,
} from './SupportTicketPaymentFieldData';
import { SupportTicketPaymentMethodField } from './SupportTicketPaymentMethodField';
import { getPaymentMethodStyles } from './SupportTicketPaymentFieldStyles';

type SupportTicketPaymentFieldProps = WithCommonProps<unknown> & FieldProps<SupportTicketPaymentFieldData>;

export function SupportTicketPaymentField({
    field: { name },
    ...otherProps
}: SupportTicketPaymentFieldProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <section css={[getPaymentMethodStyles, getFormElementStyles]} {...otherProps}>
            <FastField
                name={name}
                component={SupportTicketPaymentMethodField}
                label={t('support-center.payment-field.bank-payment-method-label')}
                type={SupportTicketPaymentMethod.BANK}
                initialData={initialSupportTicketBankPaymentData}
            >
                <SupportTicketBankPaymentField data-id="support-ticket-bank-payment-fields" name={`${name}.data`} />
            </FastField>
            <FastField
                name={name}
                component={SupportTicketPaymentMethodField}
                label={t('support-center.payment-field.paypal-payment-method-label')}
                type={SupportTicketPaymentMethod.PAYPAL}
                initialData={initialSupportTicketPaypalPaymentData}
            >
                <SupportTicketPaypalPaymentField data-id="support-ticket-paypal-payment-fields" name={`${name}.data`} />
            </FastField>
        </section>
    );
}
