import { IconNames } from '@shipengine/giger-theme';
import { Typography, IconSize, IconPopover, WithCommonProps } from '@shipengine/giger';
import { getIconStyles, getSubtitleStyles } from './TemplateFieldStyles';

export type TemplateFieldProps = WithCommonProps<{
    title: string;
    subtitle: string;
    info: string;
}>;

export function TemplateField({ title, subtitle, info, children }: TemplateFieldProps): JSX.Element {
    return (
        <>
            <Typography variant="body1" component="span" bold>
                <>
                    {title}

                    <IconPopover
                        css={getIconStyles}
                        icon={IconNames.INFO}
                        size={IconSize.SIZE_SMALL}
                        placement="top-start"
                        offset={15}
                    >
                        <Typography variant="small">{info}</Typography>
                    </IconPopover>
                </>
            </Typography>
            <Typography css={getSubtitleStyles} variant="body2" component="p">
                {subtitle}
            </Typography>

            {children}
        </>
    );
}
