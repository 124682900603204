import { useTranslation } from '@packlink/translation-provider';
import { Grid, GridChild, Next, Typography } from '@shipengine/giger';
import { AmplitudeEvents } from '@constants/amplitude';
import { APP_ROUTE } from '@router/routes';
import { getOnboardingPageBackgroundStyles, getPageTitleStyles } from './OnboardingPageStyles';
import { getOnboardingWelcomeContainerStyles, getSkipLinkStyles } from './OnboardingWelcomeStyles';
import { OnboardingTile } from '../components/OnboardingTile/OnboardingTile';
import { useNavigate } from 'react-router';
import { useAmplitude } from '@hooks';

export const OnboardingWelcome = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const tiles: Array<OnboardingTile> = [
        {
            title: 'onboarding.welcome.make-first-shipment',
            imageSrc: `${config.cdn.baseUrl}/apps/pro/statics/images/shipment-boxes.png`,
            navigateTo: APP_ROUTE.CHECKOUT.CREATE,
            amplitudeEvent: AmplitudeEvents.ONBOARDING_WELCOME_SHIPMENT,
        },
        {
            title: 'onboarding.welcome.connect-store',
            imageSrc: `${config.cdn.baseUrl}/apps/pro/statics/images/manage-shipments.png`,
            navigateTo: APP_ROUTE.SETTINGS.INTEGRATIONS,
            amplitudeEvent: AmplitudeEvents.ONBOARDING_WELCOME_STORE,
        },
    ];

    const clickOnSkip = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.ONBOARDING_WELCOME_SKIP);
        navigate(APP_ROUTE.SHIPMENTS.INBOX_ALL);
    };

    return (
        <div css={getOnboardingPageBackgroundStyles}>
            <Typography variant="body1" component="h3" css={getPageTitleStyles}>
                {t('onboarding.welcome.title')}
            </Typography>
            <div css={getOnboardingWelcomeContainerStyles}>
                <Grid>
                    {tiles.map((tile) => (
                        <GridChild key={tile.title} colSpan={12} colSpanTablet={6}>
                            <OnboardingTile {...tile} />
                        </GridChild>
                    ))}
                    <GridChild key="skip" colSpan={12} css={getSkipLinkStyles}>
                        <Next.Link onClick={clickOnSkip}>{t('onboarding.welcome.skip')}</Next.Link>
                    </GridChild>
                </Grid>
            </div>
        </div>
    );
};
