import Packlink from '@sdk';
import {
    Inbox,
    IPagination,
    IProShipmentApiResponse,
    ShipmentsOrderBy,
    IVoucher,
    ProShipment,
    IShipmentApiDeleteResponse,
} from '@packlink/packlink-sdk';

import {
    ADD_SELECTED_SHIPMENT,
    ADD_SELECTED_SHIPMENTS,
    CLEAN_SELECTED_SHIPMENTS,
    REMOVE_SELECTED_SHIPMENT,
    REMOVE_SELECTED_SHIPMENTS,
    ADD_SHIPMENT,
    ADD_SHIPMENT_INBOX_NOTIFICATION,
    DELETE_SHIPMENT_INBOX_NOTIFICATION,
    CLEAN_SHIPMENTS,
    SELECT_ALL_SHIPMENTS,
    REMOVE_SHIPMENT,
    UPDATE_SHIPMENT,
    SET_BULK_PAGINATION,
    SET_BULK_SORT_BY,
    SET_BULK_FILTERS,
    SET_BULK_VOUCHER,
    SET_SHIPMENTS_TO_PAY,
    REMOVE_SHIPMENTS_TO_PAY,
} from './action-types';

import { ThunkResult, AppDispatch } from '@store';
import { getSelectedShipmentReferences, getShipments, getInboxesNotifications } from '@store/selectors/bulk';
import { getCurrentInbox } from '@store/selectors/inbox';
import { IAppState } from '@store/reducers';
import { logSdkError } from '@utils/logger';

export type BulkPagination = IPagination['pagination'];

interface IAddSelectedShipmentAction {
    payload: string;
    type: 'ADD_SELECTED_SHIPMENT';
}

interface IAddSelectedShipmentsAction {
    payload: string[];
    type: 'ADD_SELECTED_SHIPMENTS';
}

interface IAddShipmentAction {
    payload: {
        shipment: ProShipment;
        addOnTop: boolean;
    };
    type: 'ADD_SHIPMENT';
}

interface ICleanSelectedShipmentsAction {
    type: 'CLEAN_SELECTED_SHIPMENTS';
}

interface ICleanShipmentsAction {
    type: 'CLEAN_SHIPMENTS';
}

interface IRemoveSelectedShipmentAction {
    payload: string;
    type: 'REMOVE_SELECTED_SHIPMENT';
}

interface IRemoveShipmentAction {
    payload: string;
    type: 'REMOVE_SHIPMENT';
}

interface IRemoveSelectedShipmentsAction {
    payload: string[];
    type: 'REMOVE_SELECTED_SHIPMENTS';
}

interface ISelectAllShipmentsAction {
    type: 'SELECT_ALL_SHIPMENTS';
}

interface ISetSelectedShipmentsAction {
    payload: string[];
    type: 'SET_SELECTED_SHIPMENTS';
}

interface ISetShipmentsAction {
    payload: ProShipment[];
    type: 'SET_SHIPMENTS';
}

interface ISetShipmentsToPayAction {
    payload: string[];
    type: 'SET_SHIPMENTS_TO_PAY';
}

interface IRemoveShipmentsToPayAction {
    type: 'REMOVE_SHIPMENTS_TO_PAY';
}

interface IUpdateShipmentAction {
    payload: ProShipment;
    type: 'UPDATE_SHIPMENT';
}

interface ISetBulkPaginationAction {
    payload: BulkPagination;
    type: 'SET_BULK_PAGINATION';
}

interface ISetBulkSortByAction {
    payload?: ShipmentsOrderBy;
    type: 'SET_BULK_SORT_BY';
}
interface ISetBulkFiltersAction {
    payload?: Record<string, unknown>;
    type: 'SET_BULK_FILTERS';
}

interface ISetVoucherToShipmentAction {
    payload: Record<string, IVoucher>;
    type: 'SET_BULK_VOUCHER';
}

interface IAddShipmentToInboxNotificationsAction {
    payload: {
        inbox: Inbox;
        shipmentRef: string;
    };
    type: 'ADD_SHIPMENT_INBOX_NOTIFICATION';
}

interface IDeleteShipmentsFromInboxNotificationsAction {
    payload: {
        inbox: Inbox;
        shipmentRef: string;
    };
    type: 'DELETE_SHIPMENT_INBOX_NOTIFICATION';
}

export type BulkAction =
    | IAddSelectedShipmentAction
    | IAddSelectedShipmentsAction
    | IAddShipmentAction
    | ICleanSelectedShipmentsAction
    | ICleanShipmentsAction
    | IRemoveSelectedShipmentAction
    | IRemoveSelectedShipmentsAction
    | IRemoveShipmentAction
    | ISelectAllShipmentsAction
    | ISetSelectedShipmentsAction
    | ISetShipmentsAction
    | ISetShipmentsToPayAction
    | IRemoveShipmentsToPayAction
    | IUpdateShipmentAction
    | ISetBulkPaginationAction
    | ISetBulkSortByAction
    | ISetBulkFiltersAction
    | ISetVoucherToShipmentAction
    | IAddShipmentToInboxNotificationsAction
    | IDeleteShipmentsFromInboxNotificationsAction;

export const PAGE_LIMIT = 20;

export const addSelectedShipment = (shipmentReference: string): IAddSelectedShipmentAction => ({
    payload: shipmentReference,
    type: ADD_SELECTED_SHIPMENT,
});

export const addSelectedShipments = (shipmentReferences: string[]): IAddSelectedShipmentsAction => ({
    payload: shipmentReferences,
    type: ADD_SELECTED_SHIPMENTS,
});

const addShipment = (shipment: ProShipment, addOnTop: boolean): IAddShipmentAction => ({
    payload: {
        shipment,
        addOnTop,
    },
    type: ADD_SHIPMENT,
});

export const cleanSelectedShipments = (): ICleanSelectedShipmentsAction => ({
    type: CLEAN_SELECTED_SHIPMENTS,
});

export const cleanShipments = (): ICleanShipmentsAction => ({
    type: CLEAN_SHIPMENTS,
});

export const deleteSelectedShipments =
    (): ThunkResult<Promise<void | IShipmentApiDeleteResponse[]>> =>
    async (dispatch: AppDispatch, getState: () => IAppState): Promise<void | IShipmentApiDeleteResponse[]> => {
        const selectedShipments = getSelectedShipmentReferences(getState());
        return dispatch(deleteShipments(selectedShipments));
    };

export const deleteShipments =
    (shipmentsReferences: string[]): ThunkResult<Promise<void | IShipmentApiDeleteResponse[]>> =>
    async (): Promise<void | IShipmentApiDeleteResponse[]> =>
        Packlink.v1.shipments.deleteShipments(shipmentsReferences).catch(logSdkError);

export const removeSelectedShipment = (shipmentReference: string): IRemoveSelectedShipmentAction => ({
    payload: shipmentReference,
    type: REMOVE_SELECTED_SHIPMENT,
});

export const removeSelectedShipments = (shipmentReferences: string[]): IRemoveSelectedShipmentsAction => ({
    payload: shipmentReferences,
    type: REMOVE_SELECTED_SHIPMENTS,
});

const removeShipment = (shipmentReference: string): IRemoveShipmentAction => ({
    payload: shipmentReference,
    type: REMOVE_SHIPMENT,
});

export const selectAllShipments = (): ISelectAllShipmentsAction => ({
    type: SELECT_ALL_SHIPMENTS,
});

export const setShipmentsToPay = (shipmentReferences: string[]): ISetShipmentsToPayAction => ({
    payload: shipmentReferences,
    type: SET_SHIPMENTS_TO_PAY,
});

export const removeShipmentsToPay = (): IRemoveShipmentsToPayAction => ({
    type: REMOVE_SHIPMENTS_TO_PAY,
});

const updateShipment = (shipment: ProShipment): IUpdateShipmentAction => ({
    payload: shipment,
    type: UPDATE_SHIPMENT,
});

export const setBulkPagination = (pagination: BulkPagination): ISetBulkPaginationAction => ({
    payload: pagination,
    type: SET_BULK_PAGINATION,
});

export const setBulkSortBy = (sortBy?: ShipmentsOrderBy): ISetBulkSortByAction => ({
    payload: sortBy,
    type: SET_BULK_SORT_BY,
});

export const setBulkFilters = (filters?: Record<string, unknown>): ISetBulkFiltersAction => ({
    payload: filters,
    type: SET_BULK_FILTERS,
});

export const setBulkVoucher = (vouchers: Record<string, IVoucher>): ISetVoucherToShipmentAction => ({
    payload: vouchers,
    type: SET_BULK_VOUCHER,
});

const addShipmentToInboxNotifications = (
    inbox: Inbox,
    shipmentRef: string,
): IAddShipmentToInboxNotificationsAction => ({
    payload: {
        inbox,
        shipmentRef,
    },
    type: ADD_SHIPMENT_INBOX_NOTIFICATION,
});

export const deleteShipmentsFromInboxNotifications = (
    inbox: Inbox,
    shipmentRef: string,
): IDeleteShipmentsFromInboxNotificationsAction => ({
    payload: {
        inbox,
        shipmentRef,
    },
    type: DELETE_SHIPMENT_INBOX_NOTIFICATION,
});

export const handleShipments =
    (shipmentsToHandle: ProShipment[], addOnTop: boolean): ThunkResult<Promise<void>> =>
    async (dispatch: AppDispatch, getState: () => IAppState): Promise<void> => {
        const shipments = getShipments(getState());
        const currentInbox = getCurrentInbox(getState());

        shipmentsToHandle.forEach((shipmentToHandle: ProShipment): void => {
            const isInCurrentInbox = shipmentToHandle.inbox.indexOf(currentInbox) !== -1;
            const shipmentIndex = shipments.findIndex(
                (shipment: ProShipment): boolean =>
                    shipment.data.packlinkReference === shipmentToHandle.data.packlinkReference,
            );

            if (shipmentIndex !== -1) {
                if (isInCurrentInbox) {
                    dispatch(updateShipment(shipmentToHandle));
                } else {
                    dispatch(removeShipment(shipmentToHandle.data.packlinkReference as string));
                }
            } else if (isInCurrentInbox) {
                dispatch(addShipment(shipmentToHandle, addOnTop));
            }

            handleShipmentForNotification(
                shipmentToHandle,
                addOnTop,
                currentInbox,
                shipmentIndex !== -1,
                dispatch,
                getState,
            );
        });
    };

export const handleDeleteShipmentEvent =
    (shipment: IProShipmentApiResponse): ThunkResult<Promise<void>> =>
    async (dispatch: AppDispatch, getState: () => IAppState): Promise<void> => {
        const inboxesNotifications = getInboxesNotifications(getState());
        dispatch(removeShipment(shipment.data.packlink_reference));

        const shipmentInbox = shipment.inbox.filter((i) => i !== Inbox.ALL)[0];

        if (inboxesNotifications[shipmentInbox]?.[shipment.data.packlink_reference]) {
            dispatch(deleteShipmentsFromInboxNotifications(shipmentInbox, shipment.data.packlink_reference));
        }
    };

function handleShipmentForNotification(
    shipment: ProShipment,
    isFromPusher: boolean,
    currentInbox: Inbox,
    isShipmentInCurrentInbox: boolean,
    dispatch: AppDispatch,
    getState: () => IAppState,
) {
    if (!isFromPusher) return;

    const inboxesNotifications = getInboxesNotifications(getState());
    const isShipmentInReadyToPurchase = shipment.inbox.includes(Inbox.READY_TO_PURCHASE);

    // New shipment appears in Ready to purchase inbox
    if (isShipmentInReadyToPurchase && (currentInbox !== Inbox.READY_TO_PURCHASE || !isShipmentInCurrentInbox)) {
        dispatch(addShipmentToInboxNotifications(Inbox.READY_TO_PURCHASE, shipment.data.packlinkReference));
    } else if (
        // A shipment that was in Ready to purchase is no longuer in that inbox
        !isShipmentInReadyToPurchase &&
        inboxesNotifications[Inbox.READY_TO_PURCHASE]?.[shipment.data.packlinkReference]
    ) {
        dispatch(deleteShipmentsFromInboxNotifications(Inbox.READY_TO_PURCHASE, shipment.data.packlinkReference));
    }
}
