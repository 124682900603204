import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from '@packlink/translation-provider';
import { InvoiceForm, FieldNames, ContactFormOrientation, PhoneNumberValue } from '@packlink/invoice-form';
import { Client, ClientType, IClientApiUpdateResponse } from '@packlink/packlink-sdk';
import { Button, ButtonSize, ButtonVariant, Spinner, Typography } from '@shipengine/giger';

import { isBusinessClient as isBusinessClientSelector } from '@store/selectors/client';

import { SettingsClientTypeSelector } from './SettingsClientTypeSelector';
import { SettingsClientBusinessData } from './SettingsClientBusinessData';

import { getSpinnerStyles, getTitleStyles } from './SettingsBillingDetailsStyles';
import { useBillingForm } from '@common/hooks/useBillingForm';
import { PhoneNumberVerificationPanel } from '@common/components/PhoneNumberVerificationPanel/PhoneNumberVerificationPanel';

export const SettingsBillingDetails = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        client,
        billingFormRef,
        isPhoneNumberVerificationOpen,
        isPhoneNumberVerified,
        isRequiresPhoneNumberVerificationEnabled,
        isClientRequiresPhoneNumberVerificationLoading,
        submitBillingForm,
        onBillingFormSuccess,
        closePhoneNumberVerification,
    } = useBillingForm();
    const isBusinessClient = useSelector(isBusinessClientSelector);

    const [clientType, setClientType] = useState(() =>
        client.type && [ClientType.AUTONOMOUS, ClientType.BUSINESS].includes(client.type)
            ? client.type
            : ClientType.AUTONOMOUS,
    );
    const [initialPhoneNumberValue, setInitialPhoneNumberValue] = useState<PhoneNumberValue | string>();

    const onInvoiceFormSave = useCallback(
        (_data: IClientApiUpdateResponse, client: Client, phoneNumberValue: PhoneNumberValue | string) => {
            onBillingFormSuccess(client);
            setInitialPhoneNumberValue(phoneNumberValue);
        },
        [onBillingFormSuccess],
    );

    const getDisabledFields = {
        [FieldNames.COUNTRY]: isBusinessClient,
        [FieldNames.TAXID]: isBusinessClient,
    };

    return (
        <>
            {isBusinessClient && (
                <SettingsClientBusinessData
                    country={client.state}
                    alpha2Code={client.alpha2Code}
                    taxId={client.taxId}
                />
            )}
            {!isBusinessClient && (
                <SettingsClientTypeSelector clientType={clientType} onClientTypeChange={setClientType} />
            )}

            <Typography component="h4" variant="heading4" css={getTitleStyles}>
                {t('billing-details.form.title')}
            </Typography>
            {isClientRequiresPhoneNumberVerificationLoading ? (
                <Spinner css={getSpinnerStyles} />
            ) : (
                <>
                    <InvoiceForm
                        config={{
                            twoColumns: true,
                            showLegends: false,
                            contactFormOrientation: ContactFormOrientation.VERTICAL,
                        }}
                        validation={{
                            validateOnBlur: false,
                            validateOnChange: true,
                        }}
                        type={clientType}
                        client={client}
                        onSuccess={onInvoiceFormSave}
                        disabledFields={getDisabledFields}
                        showSubmitButton={false}
                        ref={billingFormRef}
                        isPhoneNumberVerified={isPhoneNumberVerified}
                        requiresPhoneNumberVerification={isRequiresPhoneNumberVerificationEnabled}
                        cdnUrl={config.cdn.baseUrl}
                    />

                    <Button onClick={submitBillingForm} size={ButtonSize.LARGE} variant={ButtonVariant.FILLED}>
                        {t('invoice-form.label.save-button')}
                    </Button>
                </>
            )}

            <PhoneNumberVerificationPanel
                isOpen={isPhoneNumberVerificationOpen}
                initialPhoneNumberValue={initialPhoneNumberValue}
                alpha2Code={client.alpha2Code}
                continueTranslationKey="dialog.action.close"
                onClose={closePhoneNumberVerification}
            />
        </>
    );
};
