import { Typography } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { getSectionStyles, getTextStyles } from './BulkSelectionInfoStyles';

interface IBulkSelectionInfoProps {
    count: number;
    actionsTitle: string;
    actionsSubtitle?: string;
    showBackground?: boolean;
    hideMargins?: boolean;
}

export const BulkSelectionInfo = ({
    count,
    actionsTitle,
    actionsSubtitle,
    showBackground = false,
    hideMargins = false,
}: IBulkSelectionInfoProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <section css={getSectionStyles(showBackground, hideMargins)}>
            <Typography variant="subtitle1" component="p" css={getTextStyles(hideMargins)}>
                {t(actionsTitle, { count })}
            </Typography>
            {actionsSubtitle && <Typography variant="body2">{t(actionsSubtitle, { count })}</Typography>}
        </section>
    );
};
