import { TenantUtils } from '@utils/tenant';
import { useTranslation } from '@packlink/translation-provider';
import { Checkbox, Next, Typography } from '@shipengine/giger';
import { ChangeEvent, useCallback, useState } from 'react';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { getSaveWarehouseFootnoteStyles, getSaveWarehouseStyles } from './SaveAddressStyles';
import { useAmplitude } from '@hooks/useAmplitude';
import { MAX_WAREHOUSES, useWarehouses } from '@common/hooks/useWarehouses';

interface ISaveAddressProps {
    defaultIsChecked: boolean;
    isAddressBook?: boolean;
    isUpdatedAddress?: boolean;
    onChange?: (value: boolean) => void;
}

export const SaveAddress = ({
    defaultIsChecked,
    isAddressBook = false,
    isUpdatedAddress = false,
    onChange,
}: ISaveAddressProps): JSX.Element => {
    const { t } = useTranslation();
    const [isChecked, setIsChecked] = useState(defaultIsChecked);
    const { canAddWarehouse } = useWarehouses();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const handleChange = useCallback(
        ({ target: { checked } }: ChangeEvent<HTMLInputElement>): void => {
            setIsChecked(checked);

            if (isAddressBook) {
                const eventName = isUpdatedAddress
                    ? AmplitudeEvents.UPDATE_ADDRESS_BOOK
                    : AmplitudeEvents.SAVE_ADDRESS_BOOK;

                sendAmplitudeClickEvent(eventName, {
                    [AmplitudeProperties.SELECTED]: checked,
                });
            }
            onChange && onChange(checked);
        },
        [isAddressBook, isUpdatedAddress, onChange, sendAmplitudeClickEvent],
    );
    const name = isAddressBook ? 'address-book' : 'warehouse';
    const operation = isUpdatedAddress ? 'update' : 'save';

    return (
        <div css={getSaveWarehouseStyles}>
            <div className="form-group">
                <Checkbox
                    checked={isChecked}
                    label={t(`create-details.${name}.${operation}`, { maxWarehouses: MAX_WAREHOUSES })}
                    name={name}
                    value={name}
                    onChange={handleChange}
                    disabled={!isAddressBook && !canAddWarehouse}
                />
            </div>
            {!isAddressBook && (
                <Typography css={getSaveWarehouseFootnoteStyles} component="p">
                    {!canAddWarehouse && t('create-details.warehouse.limit-exceeded')}

                    <Next.Link
                        target="_blank"
                        title={t('create-details.warehouse.manage')}
                        href={`${TenantUtils.getURLPrefix()}/settings/addresses/sender`}
                    >
                        {t('create-details.warehouse.manage')}
                    </Next.Link>
                </Typography>
            )}
        </div>
    );
};
