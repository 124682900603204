import { useMemo } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { PaymentDetailsItem } from './PaymentDetailsItem';
import { PaymentDetailsServices } from './PaymentDetailsServices';
import { Typography, WithCommonProps } from '@shipengine/giger';
import { IPrice, IPriceDetail, ProShipment } from '@packlink/packlink-sdk';
import {
    getNumberOfShipmentsStyles,
    getPaymentDetailsGroupStyles,
    getPaymentDetailsItemStyles,
    getPaymentDetailsItemValueStyles,
    getPaymentDetailsStyles,
    getShipmentContentDescriptionStyles,
} from './PaymentDetailsStyles';

export type PaymentDetailsProps = WithCommonProps<{
    shipments: ProShipment[];
    shipmentsPriceDetails: IPrice[];
    total: IPriceDetail;
}>;

export const PaymentDetails = ({
    shipments,
    shipmentsPriceDetails,
    total,
    ...props
}: PaymentDetailsProps): JSX.Element => {
    const { t } = useTranslation();
    const numberOfShipments = shipments.length;

    const [packlinkServices, ownContractServices] = useMemo((): [IPrice[], Record<string, IPrice[]>] => {
        const shipmentsByRef: Record<string, ProShipment> = {};
        shipments.forEach((s: ProShipment): void => {
            shipmentsByRef[s.data.packlinkReference] = s;
        });

        const [ownContractsDetails, packlinkDetails] = shipmentsPriceDetails.reduce<[IPrice[], IPrice[]]>(
            ([ownContracts, packlink]: [IPrice[], IPrice[]], priceDetail: IPrice) =>
                shipmentsByRef[priceDetail.packlinkReference as string]?.data.service?.isOwnContract
                    ? [[...ownContracts, priceDetail], packlink]
                    : [ownContracts, [...packlink, priceDetail]],
            [[], []],
        );

        const ownContractsByCarrier: Record<string, IPrice[]> = ownContractsDetails.reduce(
            (acc: Record<string, IPrice[]>, priceDetail: IPrice) => {
                if (!priceDetail.packlinkReference) {
                    return acc;
                }

                const carrierName = shipmentsByRef[priceDetail.packlinkReference].data.service?.carrierName as string;

                return acc[carrierName]
                    ? { ...acc, [carrierName]: [...acc[carrierName], priceDetail] }
                    : { ...acc, [carrierName]: [priceDetail] };
            },
            {},
        );

        return [packlinkDetails, ownContractsByCarrier];
    }, [shipments, shipmentsPriceDetails]);

    return (
        <section css={getPaymentDetailsStyles} {...props}>
            {numberOfShipments === 1 && (
                <div css={getPaymentDetailsGroupStyles}>
                    <Typography component="p" variant="small" css={() => getPaymentDetailsItemStyles()}>
                        {shipments[0].data.to?.firstName} {shipments[0].data.to?.lastName}
                    </Typography>
                    <div css={() => getPaymentDetailsItemStyles()}>
                        <span css={getShipmentContentDescriptionStyles}>{shipments[0].data.content?.description}</span>
                        <span css={getPaymentDetailsItemValueStyles}>{shipments[0].data.service?.carrierName}</span>
                    </div>
                </div>
            )}
            {numberOfShipments > 1 && (
                <div css={getPaymentDetailsGroupStyles}>
                    <div css={getNumberOfShipmentsStyles}>
                        {t('payment-details.item.shipments', { count: numberOfShipments })}
                    </div>
                </div>
            )}
            {!!packlinkServices?.length && (
                <PaymentDetailsServices
                    priceDetails={packlinkServices}
                    currency={total.currency}
                    hasOwnContracts={ownContractServices && !!Object.keys(ownContractServices).length}
                />
            )}
            {ownContractServices &&
                Object.keys(ownContractServices).map((carrierName: string) => (
                    <PaymentDetailsServices
                        key={carrierName}
                        priceDetails={ownContractServices[carrierName]}
                        ownContract={carrierName}
                        currency={total.currency}
                    />
                ))}

            {!!total.basePrice && total.basePrice > 0 && (
                <div css={getPaymentDetailsGroupStyles}>
                    <PaymentDetailsItem
                        label={t('payment-details.item.price-before-taxes')}
                        price={total.basePrice}
                        currency={total.currency}
                    />
                    <PaymentDetailsItem
                        label={t('payment-details.item.taxes', { amount: total.taxes?.[0].percentage })}
                        price={total.taxPrice}
                        currency={total.currency}
                    />
                </div>
            )}
            <div css={getPaymentDetailsGroupStyles}>
                <PaymentDetailsItem
                    label={t('payment-details.item.total-price')}
                    price={total.totalPrice}
                    currency={total.currency}
                    isTotal
                />
            </div>
        </section>
    );
};
