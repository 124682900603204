import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const settingsLayoutStyles = (theme: Theme): SerializedStyles =>
    css({
        minWidth: `${theme.breakPoints.desktop}px`,
        width: '100%',
        display: 'flex',
        height: '100%',
    });

export const settingsLayoutContentStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flex: '1 1 auto',
        flexFlow: 'column',
        position: 'relative',
        overflow: 'auto',
    });
