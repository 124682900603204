import {
    SupportTicketPayment,
    SupportTicketPaymentData,
    SupportTicketPaymentMethod,
    SupportTicketBankPayment,
    SupportTicketPaypalPayment,
    CreateLossSupportTicketPayload,
} from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import * as Yup from 'yup';
import { isValidIban } from '@utils/iban';

export type SupportTicketPaymentFieldData<
    PaymentMethod extends SupportTicketPaymentMethod = SupportTicketPaymentMethod,
> = {
    type: SupportTicketPaymentFieldData | string;
    data: SupportTicketPayment<PaymentMethod>['data'];
};

const ACCOUNT_NUMBER_REGEX = /^[0-9]{8}$/;
const BIC_SWIFT_REGEX = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
const SORT_CODE = /^[0-9]{2}-[0-9]{2}-[0-9]{2}$/;
const ENGLISH_LOCALE = 'en-GB';

const isIbanOrAccountNumber = function (locale: string, value?: string) {
    const sanitizedValue = value ?? '';
    const accountNumberMatch = sanitizedValue.match(ACCOUNT_NUMBER_REGEX) ?? [];

    return locale === ENGLISH_LOCALE ? accountNumberMatch.length > 0 : isValidIban(sanitizedValue);
};

const isBicSwiftOrSortCode = function (locale: string, value?: string) {
    const sanitizedValue = value ?? '';
    const codeRegex = locale === ENGLISH_LOCALE ? SORT_CODE : BIC_SWIFT_REGEX;
    const codeMatch = sanitizedValue.match(codeRegex) ?? [];

    return codeMatch.length > 0;
};

export function useSupportTicketPaymentValidationSchema(): Yup.ObjectSchema<SupportTicketPaymentFieldData> {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const getRequiredErrorMsg = (fieldKey: string) => t('form.error.required', { field: t(fieldKey) });
    const codeFieldLabel =
        locale === ENGLISH_LOCALE
            ? t('support-center.payment-field.bank-sort-code-label')
            : t('support-center.payment-field.bank-eu-code-label');
    const codeFieldError =
        locale === ENGLISH_LOCALE
            ? t('support-center.payment-field.bank-sort-code-error')
            : t('support-center.payment-field.bank-eu-code-error');

    return Yup.object()
        .shape<SupportTicketPaymentFieldData>({
            type: Yup.string().required(getRequiredErrorMsg('support-center.payment-field.payment-method-label')),
            data: Yup.mixed<SupportTicketPaymentData>().when('type', {
                is: SupportTicketPaymentMethod.BANK,
                then: () =>
                    Yup.object()
                        .shape<SupportTicketBankPayment>({
                            accountHolder: Yup.string().required(
                                getRequiredErrorMsg('support-center.payment-field.bank-account-holder-label'),
                            ),
                            iban: Yup.string()
                                .required(getRequiredErrorMsg('support-center.payment-field.bank-iban-label'))
                                .test({
                                    name: 'is-iban-or-account-number',
                                    message: t('support-center.payment-field.invalid-iban-error'),
                                    params: { locale },
                                    test: (value) => isIbanOrAccountNumber(locale, value),
                                }),
                            code: Yup.string()
                                .required(getRequiredErrorMsg(codeFieldLabel))
                                .test({
                                    name: 'is-bic-swift-or-sort-code',
                                    message: codeFieldError,
                                    params: { locale },
                                    test: (value) => isBicSwiftOrSortCode(locale, value),
                                }),
                        })
                        .defined(),
                otherwise: () =>
                    Yup.object()
                        .shape<SupportTicketPaypalPayment>({
                            account: Yup.string()
                                .email(t('forms.error.email-invalid'))
                                .required(
                                    getRequiredErrorMsg('support-center.payment-field.paypal-account-holder-label'),
                                ),
                        })
                        .defined(),
            }),
        })
        .defined();
}

export const initialSupportTicketBankPaymentData: SupportTicketBankPayment = {
    accountHolder: '',
    iban: '',
    code: '',
};

export const initialSupportTicketPaypalPaymentData: SupportTicketPaypalPayment = {
    account: '',
};

export function parseTicketPayment({
    type,
    data,
}: SupportTicketPaymentFieldData): CreateLossSupportTicketPayload['paymentMethod'] {
    if (!type) {
        throw new Error('type should be defined');
    }

    return {
        type: type as SupportTicketPaymentMethod,
        data,
    };
}
