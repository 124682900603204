import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { isEmpty, reduce, values as objValues } from 'lodash';
import { TFunction, useTranslation } from '@packlink/translation-provider';
import { BrandedEmailsSettingsRepository, BrandedSettings, BrandedSettingsSaveParams } from '@packlink/packlink-sdk';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { useToast } from '@shipengine/giger';
import { APP_ROUTE } from '@pages/router/routes';
import { Form, Formik } from 'formik';

export type FormFields = Partial<BrandedSettingsSaveParams>;

const MAX_BYTE_FILE_SIZE = 200000; // 200Kb
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export interface FormManagerProps {
    initialValues?: BrandedSettings;
}

export function FormManager({ initialValues, children }: PropsWithChildren<FormManagerProps>): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { apiClient } = useSdk();
    const toast = useToast(t);

    const validationSchema = getValidationSchema(t);

    const onSubmit = (values: FormFields) => {
        const brandedEmailsSettingsRepository = new BrandedEmailsSettingsRepository(apiClient);

        const params = getInputParams(values);

        const isObjEmpty = reduce(objValues(initialValues), (it, el) => it && isEmpty(el), true);

        return brandedEmailsSettingsRepository
            .saveSettings(params, isObjEmpty)
            .then(() => {
                toast.success({
                    title: t('branded-emails.form.submit-success-title'),
                    message: t('branded-emails.form.submit-success'),
                });

                navigate(APP_ROUTE.SETTINGS.BRANDED_EMAILS);
            })
            .catch(() => {
                toast.error({
                    message: t('branded-emails.error.try-again'),
                });
            });
    };

    return (
        <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
                companyName: initialValues?.companyName,
                companyReplyToEmail: initialValues?.companyReplyToEmail,
                companyLogo: initialValues?.companyLogoUrl,
            }}
            validateOnBlur
            validateOnChange
        >
            <Form>{children}</Form>
        </Formik>
    );
}

function getInputParams({ companyName, companyLogo, companyReplyToEmail }: FormFields): BrandedSettingsSaveParams {
    return {
        // After form validations, these fields can only end up here as this types.
        companyName: companyName as string,
        companyLogo,
        companyReplyToEmail,
    };
}

function getValidationSchema(t: TFunction) {
    return yup.object<FormFields>({
        companyName: yup
            .string()
            .required(t('form.error.required', { field: t('form.label.company-name') }))
            .max(250, t('form.error.max', { field: t('form.label.company-name'), number: 250 })),
        companyReplyToEmail: yup
            .string()
            .optional()
            .email(t('form.error.format', { field: t('form.label.company-email') }))
            .max(50, t('form.error.max', { field: t('form.label.company-email'), number: 50 })),
        companyLogo: yup
            .mixed<File | string>()
            .optional()
            .test(
                'fileSize',
                t('branded-emails.email.file-size-error'),
                (value?: File | string | null): value is File => {
                    // If it's an url we don't need to validate
                    return typeof value === 'string' || (value ? value?.size <= MAX_BYTE_FILE_SIZE : true);
                },
            )
            .test(
                'fileInvalidType',
                t('branded-emails.email.file-type-error'),
                (value?: File | string | null): value is File => {
                    // If it's an url we don't need to validate
                    return typeof value === 'string' || (value ? SUPPORTED_FORMATS.includes(value.type) : true);
                },
            ),
    });
}
