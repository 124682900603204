import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const notificationStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const analyticsSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        overflowY: 'auto',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
        width: '100%',
    });

export const titleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
    });
