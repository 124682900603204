import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getDropoffInfoStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: 0,
        marginBottom: theme.spacing(2),
    });

export const getDropoffInfoTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.secondary.dark,
        marginBottom: theme.spacing(),
    });

export const getBusinessNameTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
        color: theme.palette.gray.dark,
        textTransform: 'capitalize',
    });
export const getReferenceStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: 0,
        fontSize: theme.toRem(theme.typography.fontSize.XS),
    });
export const getAddressStyles = (theme: Theme): SerializedStyles =>
    css({
        textTransform: 'capitalize',
        fontSize: theme.toRem(theme.typography.fontSize.XS),
    });
export const getMainButtonStyles =
    (haveDropoffSelected: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            marginTop: haveDropoffSelected ? theme.spacing(2) : 0,
        });
