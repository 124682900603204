import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { useTranslation } from '@packlink/translation-provider';
import { DatePicker as GigerDatePicker, Grid, GridChild, ISelectOption, Option, Select } from '@shipengine/giger';

import { API_DATE_FORMAT, defaultMaxDate, defaultMinDate } from '../../utils/dates';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';

import { dateInputsStyles, datePickerStyles } from './DatePickerStyles';
import { useAmplitude } from '@hooks/useAmplitude';

enum RangeOptions {
    NONE = 'none',
    WEEK = '7',
    TWO_WEEKS = '14',
    MONTH = '30',
}

export interface IDateRange {
    from: string;
    to: string;
}

interface IDatePickerProps {
    onChange: (range: IDateRange) => void;
}

export function DatePicker({ onChange }: IDatePickerProps): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const { labels, customRange, handleConfirm, setCustomRange } = useDatePicker(onChange);
    const { rangeOptions, selectedOption, handleDateRange } = useRangeOptions(onChange, setCustomRange);

    return (
        <Grid noPadding css={datePickerStyles}>
            <GridChild colSpan={12} colSpanTablet={3}>
                <Select showLabel={false} value={selectedOption} name="dateRange" onChange={handleDateRange}>
                    {rangeOptions.map((item) => (
                        <Option key={item.value} value={item} selected={selectedOption?.value === item.value}>
                            {item.label}
                        </Option>
                    ))}
                </Select>
            </GridChild>

            {selectedOption?.value === RangeOptions.NONE && (
                <>
                    <GridChild colSpan={12} colSpanTablet={2} css={dateInputsStyles}>
                        <GigerDatePicker
                            name="dateFrom"
                            label={t('form.label.from')}
                            labels={labels}
                            locale={locale}
                            minDate={defaultMinDate}
                            maxDate={customRange?.to || defaultMaxDate}
                            onClickConfirm={(date: Date | [Date, Date]) => handleConfirm('from', date)}
                        />
                    </GridChild>

                    <GridChild colSpan={12} colSpanTablet={2} css={dateInputsStyles}>
                        <GigerDatePicker
                            name="dateTo"
                            label={t('form.label.to')}
                            labels={labels}
                            locale={locale}
                            minDate={customRange?.from || defaultMinDate}
                            maxDate={defaultMaxDate}
                            onClickConfirm={(date: Date | [Date, Date]) => handleConfirm('to', date)}
                        />
                    </GridChild>
                </>
            )}
        </Grid>
    );
}

interface ICustomDateRange {
    from?: Date;
    to?: Date;
}

function useDatePicker(onChange: IDatePickerProps['onChange']) {
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const [customRange, setCustomRange] = useState<ICustomDateRange>();

    const labels = useMemo(
        () => ({
            selectDateIconTooltip: t('datepicker.labels.select-date'),
            previousMonthTooltip: t('datepicker.labels.previous-month'),
            nextMonthTooltip: t('datepicker.labels.next-month'),
            cancelButton: t('datepicker.labels.cancel'),
            confirmButton: t('datepicker.labels.confirm'),
        }),
        [t],
    );

    const handleConfirm = (name: string, date: Date | [Date, Date]) => {
        setCustomRange((prevState) => ({
            ...prevState,
            [name]: date,
        }));
    };

    useEffect(() => {
        if (!customRange?.from || !customRange?.to) return;

        const from = moment(customRange.from).format(API_DATE_FORMAT);
        const to = moment(customRange.to).format(API_DATE_FORMAT);

        onChange({
            from,
            to,
        });

        sendAmplitudeEvent(AmplitudeEvents.ANALYTICS_DATA_FILTER, {
            [AmplitudeProperties.FREQUENCY]: 'daily',
            [AmplitudeProperties.CUSTOM_DATA_RANGES]: `${from} - ${to}`,
        });
    }, [customRange, onChange, sendAmplitudeEvent]);

    return {
        labels,
        customRange,
        handleConfirm,
        setCustomRange,
    };
}

function useRangeOptions(
    onChange: IDatePickerProps['onChange'],
    setCustomRange: Dispatch<SetStateAction<ICustomDateRange | undefined>>,
) {
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const [selectedOption, setSelectedOption] = useState<ISelectOption>();
    const optionToDate = moment(Date.now()).subtract(1, 'days');

    const rangeOptions = useMemo(
        () => [
            {
                label: t('datepicker.labels.custom'),
                value: RangeOptions.NONE,
            },
            {
                label: t('datepicker.labels.last-days', { days: RangeOptions.WEEK }),
                value: RangeOptions.WEEK,
            },
            {
                label: t('datepicker.labels.last-days', { days: RangeOptions.TWO_WEEKS }),
                value: RangeOptions.TWO_WEEKS,
            },
            {
                label: t('datepicker.labels.last-days', { days: RangeOptions.MONTH }),
                value: RangeOptions.MONTH,
            },
        ],
        [t],
    );

    const handleDateRange = (_: string, option: ISelectOption | null) => {
        if (!option) return;

        setSelectedOption(option);
        setCustomRange(undefined);

        if (option.value !== RangeOptions.NONE) {
            const optionFromDate = moment(optionToDate).subtract(Number(option.value) - 1, 'days');
            onChange({ from: optionFromDate.format(API_DATE_FORMAT), to: optionToDate.format(API_DATE_FORMAT) });

            sendAmplitudeEvent(AmplitudeEvents.ANALYTICS_DATA_FILTER, {
                [AmplitudeProperties.FREQUENCY]: 'daily',
                [AmplitudeProperties.CUSTOM_DATA_RANGES]: `Last ${option.value} days`,
            });
        }
    };

    useEffect(() => {
        setSelectedOption(rangeOptions[1]);
    }, [rangeOptions]);

    return {
        rangeOptions,
        selectedOption,
        handleDateRange,
    };
}
