import React, { useState } from 'react';

import { IconNames } from '@shipengine/giger-theme';
import { Button, ButtonSize, Icon, IconSize } from '@shipengine/giger';

import { useClickOutside } from '@hooks/useClickOutside';
import {
    getButtonDropdownContainerStyles,
    getButtonDropdownHandleStyles,
    getButtonDropdownMainStyles,
    getButtonDropdownStyles,
    getButtonWrapperStyles,
} from './ButtonDropdownStyles';

export interface IButtonDropdownProps {
    label: string;
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ButtonDropdown = ({ label, children, onClick }: IButtonDropdownProps): JSX.Element => {
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const closeDropdown = (): void => {
        setIsDropdownOpened(false);
    };

    const toggleDropdown = (): void => {
        setIsDropdownOpened((value: boolean) => !value);
    };

    const dropdownRef = useClickOutside<HTMLDivElement>(closeDropdown);

    return (
        <div css={getButtonDropdownMainStyles}>
            <div css={getButtonWrapperStyles}>
                <Button css={getButtonDropdownStyles} size={ButtonSize.SMALL} onClick={onClick}>
                    <span>{label}</span>
                </Button>
            </div>
            <div ref={dropdownRef}>
                <Button
                    css={getButtonDropdownHandleStyles}
                    size={ButtonSize.SMALL}
                    onClick={toggleDropdown}
                    data-id="dropdown-handle"
                >
                    &#8203; {/* To get automatically the button font lineHeight */}
                    <Icon
                        size={IconSize.SIZE_SMALL}
                        name={isDropdownOpened ? IconNames.CHEVRON_TOP : IconNames.CHEVRON_BOTTOM}
                    />
                </Button>
                <ul css={getButtonDropdownContainerStyles(isDropdownOpened)} aria-hidden={!isDropdownOpened}>
                    {children}
                </ul>
            </div>
        </div>
    );
};
