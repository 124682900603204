import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getChangeIconButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        paddingRight: theme.spacing(),
    });

export const getFloatingButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'absolute',
        right: `${theme.spacing(3)}px`,
        top: `${theme.spacing(3)}px`,
    });

export const getSelectAddressStyles = (name: string) => (): SerializedStyles =>
    css({
        position: 'relative',
        display: 'inline-block',

        ...(name === 'warehouse' && { width: '100%' }),
        ...(name === 'address-book' && { textAlign: 'right' }),
    });

export const getSelectAddressContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        ...theme.getElevation('raised'),

        position: 'absolute',
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.gray.light}`,
        background: theme.palette.white,
        top: `calc(100% + ${theme.spacing()}px)`,

        [theme.mediaQuery('tablet')]: {
            padding: theme.spacing(4),
            marginLeft: '50%',
            left: '-100px',
            width: '600px',
        },

        '::before': {
            position: 'absolute',
            bottom: '100%',
            left: '30px',
            marginLeft: '-7px',
            borderStyle: 'solid',
            borderWidth: theme.spacing(),
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: theme.palette.gray.light,
            borderLeftColor: 'transparent',
            content: "''",
        },

        '::after': {
            position: 'absolute',
            bottom: '100%',
            left: '31px',
            marginLeft: '-7px',
            borderStyle: 'solid',
            borderWidth: '7px',
            borderTopColor: 'transparent',
            borderRightColor: 'transparent',
            borderBottomColor: theme.palette.white,
            borderLeftColor: 'transparent',
            content: "''",
        },
    });

export const getSelectAddressHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(2),
        textAlign: 'left',
    });

export const getSelectAddressTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
        fontWeight: theme.typography.fontWeight.light,
    });

export const getSelectAddressBodyStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        maxHeight: '320px',
        overflow: 'auto',
        paddingRight: theme.spacing(2),
    });

export const getSelectAddressFooterStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(3),
        textAlign: 'left',
    });
