import { useMutation, useQueryClient } from 'react-query';
import { SubscriptionsRepository } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { SUBSCRIPTIONS_CACHE_KEY } from '../constants';

const subscriptionsRepository = new SubscriptionsRepository(apiClient);

export function useCancelClientSubscription() {
    const queryClient = useQueryClient();

    const {
        mutate: cancelClientSubscription,
        isLoading,
        isSuccess,
    } = useMutation(() => subscriptionsRepository.cancelClientSubscription(), {
        onSuccess: () => {
            queryClient.invalidateQueries(SUBSCRIPTIONS_CACHE_KEY);
        },
    });

    return {
        cancelClientSubscription,
        isLoading,
        isSuccess,
    };
}
