import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const autocompleteStyles = (): SerializedStyles =>
    css({
        maxWidth: 300,
    });

export const buttonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });

export const listStyles = (theme: Theme): SerializedStyles =>
    css({
        li: {
            marginTop: theme.spacing(3),
        },
    });

export const itemStyles =
    (isActive: boolean, isEstimated: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            color:
                isActive && !isEstimated
                    ? theme.palette.gray.dark
                    : isEstimated
                      ? theme.palette.primary.main
                      : theme.palette.gray.main,
        });
