import { useToast } from '@shipengine/giger';
import { AnySupportTicket, SupportTicketType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { useNavigate } from 'react-router';
import { useTenantFeatures } from '@SupportCenter/utils/tenantFeatures';
import { getTicketDetailsRoute } from '../routes';
import { SupportCenterMetricsEvent } from '../../components/types';
import { APP_ROUTE } from '@pages/router/routes';
import { useAmplitude } from '@hooks/useAmplitude';

type SupportTicketCreator = () => Promise<AnySupportTicket>;

export type NewTicketEventHandlers = {
    onSubmit: (createTicket: SupportTicketCreator) => Promise<void>;
};

export function useNewTicketEventHandlers(type?: SupportTicketType): NewTicketEventHandlers {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const toast = useToast(t);
    const { isInternalDetailsPageEnabled } = useTenantFeatures();
    const { sendAmplitudeEvent } = useAmplitude();

    const onSubmit = async (createTicket: SupportTicketCreator) => {
        try {
            const ticketData = await createTicket();
            const ticketId = ticketData.externalReference || ticketData.id;
            const ticketLink =
                isInternalDetailsPageEnabled && ticketId
                    ? getTicketDetailsRoute(ticketId)
                    : APP_ROUTE.SUPPORT_CENTER.INDEX;

            navigate(ticketLink);
            sendAmplitudeEvent(SupportCenterMetricsEvent.OnSupportTicketCreation, { type });

            toast.success({
                title: t('support-center.form.submit-success-title'),
                message: t('support-center.form.submit-success'),
                position: 'bottom-right',
            });
        } catch {
            toast.error({
                title: t('support-center.form.submit-error-title'),
                message: t('support-center.form.submit-error'),
                position: 'bottom-right',
            });
        }
    };

    return {
        onSubmit,
    };
}
