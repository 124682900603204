import { Address, IAddress, ProShipment } from '@packlink/packlink-sdk';
import { DropoffInfo } from '@components/DropoffInfo/DropoffInfo';
import { useDateFormat } from '@hooks/useDateFormat';
import { getDeliveryDateStyles } from './DestinationDetailsStyles';
import { Typography, WithCommonProps } from '@shipengine/giger';

export type DestinationDetailsProps = WithCommonProps<{
    shipment: ProShipment;
    showDropoffSelection?: boolean;
}>;

export function DestinationDetails({
    shipment,
    showDropoffSelection = false,
    ...props
}: DestinationDetailsProps): JSX.Element {
    const hasDropoffDestination = shipment.data.service?.dropOff?.destination;
    const hasDropoffOrigin = shipment.data.service?.dropOff?.origin;
    const deliveryDate = useDateFormat(shipment.data.estimatedDeliveryDate, 'dddd, DD MMMM YYYY');

    /**
     * This is a type inconsistency, in this page serviceId is always present.
     * This should be tackled in the SDK refactor
     * */
    const origin = shipment.data.to as Address<IAddress>;
    const dropoffPointId = shipment.data.dropoffPointId;

    return (
        <section {...props}>
            {deliveryDate && !hasDropoffOrigin && (
                <Typography variant="heading3" component="h4" css={getDeliveryDateStyles}>
                    <time data-id="delivery-date-time">{deliveryDate}</time>
                </Typography>
            )}
            {hasDropoffDestination && (
                <DropoffInfo
                    origin={origin}
                    serviceId={shipment.data.service?.id as unknown as string}
                    dropoffPointId={dropoffPointId}
                    showDropoffSelection={showDropoffSelection}
                />
            )}
        </section>
    );
}
