import { useTranslation } from '@packlink/translation-provider';
import { ISubscription } from '@packlink/packlink-sdk';
import { Typography, WithCommonProps } from '@shipengine/giger';
import { getHeaderStyles } from '../../ClientPlanDetailsStyles';
import { useDateFormat } from '@hooks/useDateFormat';
import { PaymentMethod } from '../PaymentMethod/PaymentMethod';

interface IPaymentFrecuencyProps {
    subscription: ISubscription;
}

export function PaymentFrecuency({ subscription, ...rest }: WithCommonProps<IPaymentFrecuencyProps>): JSX.Element {
    const { t } = useTranslation();
    const expirationDate = useDateFormat(subscription.currentPeriodEndsAt);

    return (
        <div {...rest}>
            <Typography component="h5" variant="heading5" css={getHeaderStyles}>
                {t('subscriptions.plan.payment-frequency-title')}
            </Typography>

            <Typography variant="body2" component="p">
                {t('subscriptions.plan.payment-frequency-text', { date: expirationDate })}
            </Typography>

            <PaymentMethod subscription={subscription} />
        </div>
    );
}
