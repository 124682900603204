import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEvent } from '@packlink/event-handler';
import { PaymentMethod } from '@packlink/packlink-sdk';
import { AppDispatch } from '@store';
import { addPaymentMethod } from '@store/actions/payment';
import { getPaymentMethods } from '@store/selectors/payment';
import { AmplitudeProperties } from '@constants/amplitude';
import { EventName, IPaymentMethodUpsertEvent } from '@types';
import Packlink from '@sdk';
import { useAmplitude } from '@hooks/useAmplitude';

export function usePaymentMethodUpsert(clientId: string) {
    const paymentMethods = useSelector(getPaymentMethods);
    const dispatch = useDispatch<AppDispatch>();
    const { eventBind: paymentMethodUpsertBind, eventUnbind: paymentMethodUpsertUnbind } =
        useEvent<IPaymentMethodUpsertEvent>(clientId, EventName.PAYMENT_METHOD_UPSERT);
    const { setAmplitudeUserProperties } = useAmplitude();

    const onNewPaymentMethod = useCallback(
        (data: IPaymentMethodUpsertEvent) => {
            const { payment_method: method, id } = data;

            const existsPaymentMethod = paymentMethods.find((p) => p.id === id);

            if (!existsPaymentMethod) {
                Packlink.v1.billing.get(id).then((paymentData) => {
                    paymentData && dispatch(addPaymentMethod(paymentData));
                });

                if (method === PaymentMethod.OWNCONTRACT) {
                    setAmplitudeUserProperties([{ key: AmplitudeProperties.OWN_CONTRACT, value: true }]);
                }
            }
        },
        [dispatch, paymentMethods, setAmplitudeUserProperties],
    );

    useEffect(() => {
        paymentMethodUpsertBind(onNewPaymentMethod);

        return () => {
            paymentMethodUpsertUnbind(onNewPaymentMethod);
        };
    }, [onNewPaymentMethod, paymentMethodUpsertBind, paymentMethodUpsertUnbind]);
}
