import { useTranslation } from '@packlink/translation-provider';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { BillingInvoicePanelContent } from '@components/BillingInvoicePanelContent/BillingInvoicePanelContent';
import { useBillingForm } from '@common/hooks/useBillingForm';
import { Client } from '@packlink/packlink-sdk';

interface SubscriptionPanelInvoiceFormProps {
    isPhoneNumberVerificationOpen: boolean;
    onBillingFormSuccess: (client: Client) => void;
    closePhoneNumberVerification: () => void;
    onAction: () => void;
    onClose: () => void;
}

export const SubscriptionPanelInvoiceForm = ({
    isPhoneNumberVerificationOpen,
    onBillingFormSuccess,
    closePhoneNumberVerification,
    onAction,
    onClose,
}: SubscriptionPanelInvoiceFormProps): JSX.Element => {
    const { t } = useTranslation();

    const { billingFormRef, submitBillingForm, clientRequiresPhoneNumberVerification } = useBillingForm();

    const onSuccess = (client: Client) => {
        onBillingFormSuccess(client);
        !isPhoneNumberVerificationOpen || (!clientRequiresPhoneNumberVerification && onAction());
    };

    return (
        <>
            <SidePanelContent>
                <BillingInvoicePanelContent
                    ref={billingFormRef}
                    isPhoneNumberVerificationOpen={isPhoneNumberVerificationOpen}
                    onSuccessCallback={onSuccess}
                    onPhoneNumberVerified={onAction}
                    onClosePhoneNumberVerification={closePhoneNumberVerification}
                />
            </SidePanelContent>
            <SidePanelFooter>
                <SidePanelFooterActions
                    actionText={t('subscriptions.side-panel.footer-action')}
                    onAction={submitBillingForm}
                    cancelText={t('subscriptions.side-panel.footer-cancel')}
                    onCancel={onClose}
                />
            </SidePanelFooter>
        </>
    );
};
