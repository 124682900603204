import { useMemo } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useSelector } from 'react-redux';
import { Inbox, InboxKeys } from '@packlink/packlink-sdk';
import { getCurrentInbox } from '@store/selectors/inbox';
import { inboxIcons } from '@constants/shipments';
import { IconNames } from '@shipengine/giger-theme';

interface IUseCurrentInbox {
    inbox: Inbox;
    inboxTranslation: string;
    inboxIconName: IconNames;
}

export const useCurrentInbox = (): IUseCurrentInbox => {
    const { t } = useTranslation();
    const inbox = useSelector(getCurrentInbox);

    const inboxTranslation = useMemo(
        () => t(`shipment-list-sidebar.inboxes.${InboxKeys[inbox]}`).toLowerCase(),
        [inbox, t],
    );

    return {
        inbox,
        inboxTranslation,
        inboxIconName: inboxIcons[inbox],
    };
};
