import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';
import {
    FieldMessageType,
    FormField,
    Grid,
    GridChild,
    Input,
    Spinner,
    WithChildrenCommonProps,
} from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { ApiClientError, SupportTicketType } from '@packlink/packlink-sdk';
import { SupportCenterMetricsEvent } from '@SupportCenter/components/types';
import { getFormElementStyles, getFormInputStyles } from '../../../styles/FormStyles';
import { getErrorNotificationStyles, getLoadingStyles } from './FormWithShipmentStyles';
import { NewTicketFieldset } from '../NewTicketFieldset/NewTicketFieldset';
import { SupportCenterShipmentError } from '../SupportCenterShipmentError/SupportCenterShipmentError';
import { FormWithShipmentContext, ShipmentValidateFunction, useGetShipment } from './FormWithShipmentData';
import { ShipmentReferenceHelp } from './ShipmentReferenceHelp';
import { useAmplitude } from '@hooks/useAmplitude';

type FormWithShipmentProps = WithChildrenCommonProps<{
    validateShipment?: ShipmentValidateFunction;
    ticketType: SupportTicketType;
}>;

export function FormWithShipment({
    children,
    validateShipment,
    ticketType,
    ...props
}: FormWithShipmentProps): JSX.Element {
    const { t } = useTranslation();
    const [shipmentReference, setShipmentReference] = useShipmentReference();
    const { isLoading, data: shipment, error } = useGetShipment({ shipmentReference, validateShipment, ticketType });
    const { sendAmplitudeEvent } = useAmplitude();

    const hasValidShipment = shipment && !isLoading && !error;

    useEffect(() => {
        if (hasValidShipment) {
            sendAmplitudeEvent(SupportCenterMetricsEvent.OnShipmentReferenceComplete);
        }
    }, [hasValidShipment, sendAmplitudeEvent]);

    const errorMessage =
        error instanceof ApiClientError
            ? {
                  content: t('support-center.new-ticket.unknown-shipment-error'),
                  type: FieldMessageType.ERROR,
              }
            : undefined;

    return (
        <section {...props}>
            <Grid noPadding>
                <GridChild colSpan={6}>
                    <NewTicketFieldset
                        css={getFormElementStyles}
                        title={t('support-center.new-ticket.shipment-reference-title')}
                    >
                        <FormField message={errorMessage} css={getFormInputStyles}>
                            <Input
                                name="shipment-reference"
                                label={t('support-center.new-ticket.shipment-reference')}
                                value={shipmentReference}
                                isInvalid={!!errorMessage}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    setShipmentReference(event.target.value)
                                }
                            />
                        </FormField>
                    </NewTicketFieldset>
                    {error && (
                        <SupportCenterShipmentError
                            css={getErrorNotificationStyles}
                            data-id="support-center-shipment-error-notification"
                            error={error}
                        />
                    )}
                </GridChild>
                <GridChild colSpan={6}>
                    <ShipmentReferenceHelp />
                </GridChild>
            </Grid>

            {isLoading && (
                <div css={getLoadingStyles}>
                    <Spinner />
                </div>
            )}
            {hasValidShipment && (
                <FormWithShipmentContext.Provider value={{ shipment }}>{children}</FormWithShipmentContext.Provider>
            )}
        </section>
    );
}

function useShipmentReference() {
    const [params] = useSearchParams();
    return useState<string>(params.get('shipmentReference') || '');
}
