import { Typography, WithChildrenCommonProps } from '@shipengine/giger';
import { colorStyles, tooltipStyles, valueStyles } from './ChartTooltipStyles';

export type ChartTooltipProps = WithChildrenCommonProps<{
    index?: number;
    numElements?: number;
}>;

export function ChartTooltip({ index = 0, numElements = 0, children }: ChartTooltipProps): JSX.Element {
    return (
        <div role="tooltip" css={tooltipStyles(index, numElements)}>
            {children}
        </div>
    );
}

interface IChartTooltipColorBoxProps {
    color: string;
}

export function ChartTooltipColorBox({ color }: IChartTooltipColorBoxProps): JSX.Element {
    return <div css={colorStyles(color)}></div>;
}

interface IChartTooltipValueProps {
    label: string;
    value: string | number;
}

export function ChartTooltipValue({ label, value }: IChartTooltipValueProps): JSX.Element {
    return (
        <>
            <Typography component="dt" variant="small">
                {label}:
            </Typography>
            <Typography component="dd" variant="small" bold css={valueStyles}>
                {value}
            </Typography>
        </>
    );
}
