import { Select, FormField } from '@shipengine/formik-giger';
import { WithCommonProps } from '@shipengine/giger';
import { FastField, Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { getFormInputStyles, getSubmitButtonStyles } from '../../styles/FormStyles';
import { NewTicketFieldset } from '../CommonFields/NewTicketFieldset/NewTicketFieldset';
import { SubmitButton } from '../CommonFields/SubmitButton/SubmitButton';
import {
    CancelTicketFormData,
    useCancelTicketFormHandlers,
    useCancelTicketFormValidationSchema,
} from './CancelTicketFormData';
import { useSupportCenterCancelReasons } from '@SupportCenter/hooks/useSupportCenterCancelReasons';
type CancelTicketFormProps = WithCommonProps<unknown>;

export function CancelTicketForm(props: CancelTicketFormProps): JSX.Element {
    const { initialValues, validationSchema, handleSubmit, cancelOptions } = useFormData();
    const { t } = useTranslation();

    return (
        <Formik<CancelTicketFormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
        >
            <Form {...props}>
                <NewTicketFieldset title={t('support-center.cancel-form.reason-hint')}>
                    <FormField name="reason" css={getFormInputStyles}>
                        <FastField
                            component={Select}
                            label={t('support-center.cancel-form.reason-label')}
                            options={cancelOptions}
                        />
                    </FormField>
                </NewTicketFieldset>
                <SubmitButton css={getSubmitButtonStyles} />
            </Form>
        </Formik>
    );
}

function useFormData() {
    const initialValues: CancelTicketFormData = {
        reason: '',
    };

    const { t } = useTranslation();
    const validationSchema = useCancelTicketFormValidationSchema();
    const { handleSubmit } = useCancelTicketFormHandlers();
    const cancelReasons = useSupportCenterCancelReasons();

    const cancelOptions = useMemo(
        () =>
            Object.keys(cancelReasons).map((reasonId) => ({
                label: t(cancelReasons[reasonId]),
                value: reasonId,
            })),
        [cancelReasons, t],
    );

    return { initialValues, validationSchema, handleSubmit, cancelOptions };
}
