import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSidePanelFooterActionsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        '* + *': {
            marginLeft: theme.spacing(2),
        },
    });
