import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getFieldsetLegendStyles = (theme: Theme): CSSObject => ({
    marginBottom: theme.spacing(2),
});

export const getFieldsetLegendTitleStyles = (theme: Theme): CSSObject => ({
    color: theme.palette.secondary.dark,
    display: 'flex',
    alignItems: 'center',
});

export const getFieldsetLegendDescriptionStyles = (theme: Theme): CSSObject => ({
    color: theme.palette.gray.dark,
    marginTop: theme.spacing(),
});

export const getFieldsetLegendTooltipStyles = (theme: Theme): CSSObject => ({
    marginLeft: theme.spacing(),
    color: theme.palette.primary.main,
});
