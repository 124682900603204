import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSidePanelContentSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        ':not(:last-child)': {
            marginBottom: theme.spacing(3),
        },
    });

export const getSidePanelContentSectionTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeight.semibold,
        marginBottom: theme.spacing(),
    });

export const getSidePanelContentSectionSubtitleStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.dark,
    });

export const getSidePanelContentSectionBodyStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });
