import { useQuery, useQueryClient } from 'react-query';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { onReadOnlyQueryRetry } from '../../utils/reactQuery';
import { UseQueryResult } from 'react-query';
import {
    AnySupportTicket,
    ApiClientError,
    hasShipmentReferenceInSupportTicket,
    IShipment,
    Shipment,
    SupportTicketMessage,
} from '@packlink/packlink-sdk';

const TICKET_DETAILS_QUERY_KEYS = ['support-center', 'ticket-details'];

type TicketDetailsData = {
    supportTicket: AnySupportTicket;
    messages: SupportTicketMessage[];
    shipment?: Shipment<IShipment>;
};

export function useTicketDetailsData(ticketId: string): UseQueryResult<TicketDetailsData, ApiClientError> {
    const sdk = useSdk();

    return useQuery<TicketDetailsData, ApiClientError>(
        [...TICKET_DETAILS_QUERY_KEYS, ticketId],
        async () => {
            const [supportTicket, messages] = await Promise.all([
                sdk.v1.supportCenter.get(ticketId),
                sdk.v1.supportCenter.getMessages(ticketId),
            ]);

            let shipment = undefined;
            if (hasShipmentReferenceInSupportTicket(supportTicket)) {
                shipment = (await sdk.v1.shipments.getShipment(supportTicket.shipmentReference)) as Shipment<IShipment>;
            }

            return {
                supportTicket,
                messages,
                shipment,
            };
        },
        {
            retry: onReadOnlyQueryRetry,
            enabled: !!ticketId,
        },
    );
}

type TicketDetailsDataHandlers = {
    addTicket: (data: SupportTicketMessage) => void;
};

export function useTicketDetailsDataHandlers(ticketId: string): TicketDetailsDataHandlers {
    const queryClient = useQueryClient();
    const addTicket = (data: SupportTicketMessage) => {
        queryClient.setQueryData<TicketDetailsData | undefined>(
            [...TICKET_DETAILS_QUERY_KEYS, ticketId],
            (previousTicketDetailsData: TicketDetailsData | undefined) => ({
                supportTicket: { ...previousTicketDetailsData?.supportTicket } as AnySupportTicket,
                messages: [...(previousTicketDetailsData?.messages as SupportTicketMessage[]), data],
            }),
        );
    };

    return {
        addTicket,
    };
}
