import { ReactNode } from 'react';
import { Button, ButtonVariant, Divider, Typography } from '@shipengine/giger';
import {
    getButtonStyles,
    getDividerStyles,
    getPlanCardStyles,
    getPlanDescriptionStyles,
    getPlanHeaderSection,
    getPlanNameStyles,
} from './OnboardingPlanCardStyles';
import { useTranslation } from '@packlink/translation-provider';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';

export type PlanInfo = {
    name: string;
    description: string;
    price: string;
    buttonVariant: ButtonVariant;
    featuresListComponent: ReactNode;
    buttonAction: () => void;
};

type OnboardingPlanCardProps = {
    plan: PlanInfo;
};

export const OnboardingPlanCard = ({ plan }: OnboardingPlanCardProps): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const buttonAction = () => {
        const eventKey = `ONBOARDING_PAGE_${plan.name.toUpperCase()}_PLAN` as keyof typeof AmplitudeEvents;
        sendAmplitudeClickEvent(AmplitudeEvents[eventKey]);
        plan.buttonAction();
    };

    return (
        <div css={getPlanCardStyles}>
            <section css={getPlanHeaderSection}>
                <Typography variant="heading3" component="h3" css={getPlanNameStyles}>
                    {plan.name}
                </Typography>
                <Typography variant="body1" component="p" css={getPlanDescriptionStyles}>
                    {t(plan.description)}
                </Typography>
                <p>
                    <Typography variant="heading2" component="span">
                        {[plan.price]}
                    </Typography>
                    <Typography variant="body1" component="span" data-id="onboarding-plan-price-extra">
                        {t('onboarding.plan-selection.per-month')} {t('product.price.tax-name')}
                    </Typography>
                </p>
                <Button css={getButtonStyles} variant={plan.buttonVariant} onClick={buttonAction}>
                    {t('onboarding.plan-selection.select-button', {
                        planName: plan.name,
                    })}
                </Button>
                <Divider css={getDividerStyles} />
            </section>
            {plan.featuresListComponent}
        </div>
    );
};
