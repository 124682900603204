import { ProShipment } from '@packlink/packlink-sdk';
import { ContentWithClipboard } from '@components/Clipboard/ContentWithClipboard';

interface ShipmentDetailsPacklinkReferenceProps {
    shipment: ProShipment;
}

export function ShipmentDetailsPacklinkReference({ shipment }: ShipmentDetailsPacklinkReferenceProps): JSX.Element {
    const { packlinkReference } = shipment.data;
    return (
        <ContentWithClipboard valueToCopy={packlinkReference} data-id="postsale-packlink-reference-text">
            {packlinkReference || '...'}
        </ContentWithClipboard>
    );
}
