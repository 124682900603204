import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentFiltersPanelInboxStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.gray.light}`,
    });

export const getShipmentFiltersPanelInboxTextStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });

export const getShipmentFiltersPanelContentStyles = (): SerializedStyles =>
    css({
        padding: 0,
    });

export const getShipmentFiltersPanelFormStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    });
