import { FC } from 'react';

import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';
import { useTranslation } from '@packlink/translation-provider';

import { IntegrationsList } from './IntegrationsList';

export const IntegrationsListSettings: FC = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <SettingsPage>
            <SettingsPageHeader description={t('integrations.settings.description')}>
                {t('integrations.settings.title')}
            </SettingsPageHeader>
            <IntegrationsList />
        </SettingsPage>
    );
};
