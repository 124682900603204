import { useCallback } from 'react';

import { Video } from '@components/Video/Video';
import { Track } from '@components/Video/Track';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { useAmplitude } from '@hooks/useAmplitude';

interface IIntegrationVideoProps {
    src: string;
    poster?: string;
    subtitles?: string;
    integrationName: string;
    fallbackSrc?: string;
    fallbackPoster?: string;
    fallbackSubtitles?: string;
}

export const IntegrationVideo = ({
    subtitles,
    src,
    poster,
    integrationName,
    fallbackPoster,
    fallbackSrc,
    fallbackSubtitles,
    ...rest
}: WithCommonProps<IIntegrationVideoProps>): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const { sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();

    const sendDuration = useCallback(
        (duration: number) => {
            if (duration > 0) {
                sendSuffixedAmplitudeEvent(sendAmplitudeClickEvent, integrationName)(AmplitudeEvents.VIDEO_TUTORIAL, {
                    [AmplitudeProperties.DURATION]: duration,
                });
            }
        },
        [sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent, integrationName],
    );

    return (
        <SidePanelContentSection title={t('integration.video.title')} {...rest}>
            <Video
                onCleanup={sendDuration}
                src={src}
                poster={poster}
                data-id="integration-video"
                fallbackPoster={fallbackPoster}
                fallbackSrc={fallbackSrc}
            >
                {subtitles && (
                    <Track
                        default
                        data-id="integration-subtitles"
                        key={locale}
                        fallbackSrc={fallbackSubtitles}
                        kind={'subtitles'}
                        srcLang={locale}
                        label={locale.slice(0, 2)}
                        src={subtitles}
                    />
                )}
            </Video>
        </SidePanelContentSection>
    );
};
