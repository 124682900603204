import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getMainSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
    });

export const getFieldStyles = (theme: Theme): SerializedStyles =>
    css({
        paddingBottom: theme.spacing(3),
        ':last-of-type': {
            paddingBottom: theme.spacing(2),
        },
    });

export const getInfoMessageStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });

export const getCarrierLogoStyles = (): SerializedStyles =>
    css({
        width: '150px',
    });
