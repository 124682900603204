import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getContentStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        minHeight: 342,
        minWidth: 652,
        margin: theme.spacing(3),
        padding: 0,
        border: `1px solid ${theme.palette.primary.ultraLight}`,
    });

export const getContentContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexGrow: 1,
        padding: theme.spacing(),
    });

export const getContentSectionStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    });

export const getActionsStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '* + *': {
            margin: 0,
        },
    });

export const getErrorStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
        color: theme.palette.error.main,
        alignSelf: 'center',
    });

export const getInfoPanelStyles = (theme: Theme): SerializedStyles =>
    css({
        flexGrow: 1,
        alignItems: 'flex-start',
        minWidth: '40%',
        maxWidth: '40%',
        textAlign: 'initial',
        border: 'none',
        backgroundColor: theme.palette.gray.ultraLight,
        borderRadius: 0,
    });

export const getStepStyles = (theme: Theme): SerializedStyles =>
    css({ fontSize: theme.typography.fontSize.XS, marginTop: 0 });

export const getHiddenStyles = (): SerializedStyles => css({ display: 'none' });
