import { WithCommonProps } from '@shipengine/giger';
import { AnySupportTicket, hasShipmentReferenceInSupportTicket } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { TicketStatusTag } from '@SupportCenter/components/TicketStatusTag/TicketStatusTag';
import { TicketDataSection } from '../TicketDataSection/TicketDataSection';
import { TicketDataSectionItem } from '../TicketDataSection/TicketDataSectionItem';

type TicketCommonDataProps = WithCommonProps<{
    supportTicket: AnySupportTicket;
}>;

export function TicketCommonData({ supportTicket, ...props }: TicketCommonDataProps): JSX.Element {
    const { t } = useTranslation();
    const shipmentReference = hasShipmentReferenceInSupportTicket(supportTicket)
        ? supportTicket.shipmentReference
        : undefined;

    return (
        <TicketDataSection title={t('support-center.ticket-data-details.common-data-title')} {...props}>
            <TicketDataSectionItem title={t('support-center.ticket-data-details.common-data-ticket-id-label')}>
                {supportTicket.externalReference}
            </TicketDataSectionItem>
            <TicketDataSectionItem title={t('support-center.ticket-data-details.common-data-ticket-status-label')}>
                <TicketStatusTag data={supportTicket.status} />
            </TicketDataSectionItem>
            <TicketDataSectionItem title={t('support-center.ticket-data-details.common-data-ticket-type-label')}>
                {t(`support-center.ticket-types.${supportTicket.type}`)}
            </TicketDataSectionItem>
            {shipmentReference && (
                <TicketDataSectionItem
                    title={t('support-center.ticket-data-details.common-data-ticket-type-shipment-reference')}
                >
                    {shipmentReference}
                </TicketDataSectionItem>
            )}
        </TicketDataSection>
    );
}
