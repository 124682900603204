import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getInboxes } from '@store/selectors/inbox';
import { CheckAllShipments } from '@components/CheckAllShipments/CheckAllShipments';
import { shipmentListActionsStyles } from './ShipmentListActionsStyles';

export function ShipmentListActions(): JSX.Element | null {
    const inboxes = useSelector(getInboxes);
    const isNewUser = useMemo(() => !inboxes?.getAll(), [inboxes]);

    if (isNewUser) {
        return null;
    }

    return (
        <div css={shipmentListActionsStyles}>
            <CheckAllShipments />
        </div>
    );
}
