import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getEmailTemplateSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        maxWidth: '600px',
        height: 'max-content',
        margin: `${theme.spacing(6)}px auto`,
    });

export const getInfoCardsWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: theme.spacing(2),
    });

export const getCompanyLogoWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        height: '92px',
        width: 'auto',
        objectFit: 'contain',
        marginBottom: theme.spacing(2),
    });

export const getInfoCaptionStyles = (theme: Theme): SerializedStyles =>
    css({
        lineHeight: theme.typography.lineHeight.M,
        marginBottom: theme.spacing(),
    });

export const getInfoNumberStyles = (theme: Theme): SerializedStyles =>
    css({
        lineHeight: theme.typography.lineHeight.XXL,
        marginBottom: theme.spacing(),
    });

export const getCarrierLogoStyles = (): SerializedStyles =>
    css({
        height: '50px',
        width: 'auto',
        objectFit: 'contain',
    });

export const getJourneyLinkStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    });
