import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.borderRadius.S,
        border: `1px dashed ${theme.palette.gray.light}`,
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    });

export const getIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
        padding: `0 ${theme.spacing()}px`,
    });
