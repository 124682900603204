import { EmailTemplate } from './components/EmailTemplate/EmailTemplate';
import { Header } from './components/Header/Header';
import { Sidebar } from './components/Sidebar/Sidebar';
import { getMainStyles } from './BrandedEmailStyles';

export type BrandedEmailContentProps = {
    clientName?: string;
};

export function BrandedEmailContent({ clientName }: BrandedEmailContentProps): JSX.Element {
    return (
        <>
            <Header />

            <main css={getMainStyles}>
                <Sidebar />

                <EmailTemplate
                    trackingNumber="R00088B9005256041S"
                    shipmentRef="ES2022PRO0000000780"
                    clientName={clientName}
                    deliveryAddress="Av. Ciudad de Barcelona, 20 Madrid 28001"
                    deliveryDateNumber={28}
                />
            </main>
        </>
    );
}
