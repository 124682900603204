export enum BulkFlows {
    ACTIONS = 'actions',
    COLLECTION_DATE = 'collection_date',
    CONTENT = 'content',
    INSURANCE = 'insurance',
    PARCELS = 'parcels',
    PAYMENT = 'payment',
    SERVICES = 'services',
    INSURANCE_FROM_BULK_PAYMENT = 'insurance_from_bulk_payment',
}
