import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';

import { handleDeleteShipmentEvent, handleShipments } from '@store/actions/bulk';
import { getClientIdentifier } from '@store/selectors/client';
import { useEvent } from '@packlink/event-handler';
import { EventName } from '@types';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { AmplitudeEvents } from '@constants/amplitude';
import { AppRoute } from '@pages/router/routes';
import { useSubscriptionFeature } from '@hooks';
import { ClientFeature, Inbox, IProShipmentApiResponse } from '@packlink/packlink-sdk';
import { IconNames } from '@shipengine/giger-theme';

import { AppSidebarInbox, ISidebarInbox } from './AppSidebarInbox';
import { inboxesStyles, sidebarStyles } from './AppSidebarStyles';
import { AppSidebarItem } from './AppSidebarItem';
import { useGetInboxes } from '@common/hooks/useInboxes';

const inboxesToRender: ISidebarInbox[] = [
    { inbox: Inbox.ALL },
    {
        inbox: Inbox.PENDING,
        childrenInbox: [{ inbox: Inbox.READY_TO_PURCHASE }, { inbox: Inbox.DRAFT }],
    },
    { inbox: Inbox.PROCESSING },
    { inbox: Inbox.READY_FOR_SHIPPING },
    {
        inbox: Inbox.TRACKING,
        childrenInbox: [
            { inbox: Inbox.IN_TRANSIT },
            { inbox: Inbox.OUT_FOR_DELIVERY },
            { inbox: Inbox.DELIVERED },
            { inbox: Inbox.INCIDENT },
            { inbox: Inbox.RETURNED_TO_SENDER },
        ],
    },
    { inbox: Inbox.CANCELED },
    { inbox: Inbox.ARCHIVED },
];

export function AppSidebar(): JSX.Element {
    const dispatch = useDispatch<AppDispatch>();
    const clientId = useSelector(getClientIdentifier);
    const { feature: userAnalyticsFeature, isTenantSubscriptionEnabled } = useSubscriptionFeature(
        ClientFeature.USER_ANALYTICS,
    );
    const { eventBind: deleteEventBind, eventUnbind: deleteEventUnbind } = useEvent<IProShipmentApiResponse>(
        clientId,
        EventName.SHIPMENT_DELETED,
    );
    const { eventBind: updateEventBind, eventUnbind: updateEventUnbind } = useEvent<IProShipmentApiResponse>(
        clientId,
        EventName.SHIPMENT_UPDATED,
    );
    const { eventBind: createEventBind, eventUnbind: createEventUnbind } = useEvent<IProShipmentApiResponse>(
        clientId,
        EventName.SHIPMENT_CREATED,
    );

    const { refetchInboxesDebounced } = useGetInboxes();

    useEffect((): (() => void) => {
        const handleShipmentDeleted = (data: IProShipmentApiResponse) => {
            dispatch(handleDeleteShipmentEvent(data));
            refetchInboxesDebounced();
        };

        const handleShipmentUpdated = (shipment: IProShipmentApiResponse) => {
            const shipmentSdk = ShipmentUtils.proShipmentCreateFromResponse(shipment);
            dispatch(handleShipments([shipmentSdk], true));
            refetchInboxesDebounced();
        };

        createEventBind(handleShipmentUpdated);
        updateEventBind(handleShipmentUpdated);
        deleteEventBind(handleShipmentDeleted);

        return (): void => {
            createEventUnbind(handleShipmentUpdated);
            updateEventUnbind(handleShipmentUpdated);
            deleteEventUnbind(handleShipmentDeleted);
        };
    }, [
        dispatch,
        deleteEventBind,
        deleteEventUnbind,
        updateEventBind,
        updateEventUnbind,
        createEventBind,
        createEventUnbind,
        refetchInboxesDebounced,
    ]);

    return (
        <aside css={sidebarStyles}>
            <ul css={inboxesStyles}>
                {isTenantSubscriptionEnabled && (
                    <AppSidebarItem
                        name="subscriptions.settings.title"
                        to={AppRoute.SUBSCRIPTIONS}
                        icon={IconNames.UPGRADE}
                        event={AmplitudeEvents.SUBSCRIPTION_PLAN}
                        showNewTag
                    />
                )}
                {userAnalyticsFeature.isAvailable && (
                    <AppSidebarItem
                        name="analytics.menu.title"
                        to={AppRoute.ANALYTICS}
                        icon={IconNames.ANALYTICS}
                        event={AmplitudeEvents.ANALYTICS}
                        isItemLocked={isTenantSubscriptionEnabled && !userAnalyticsFeature.isEnabled}
                    />
                )}
                {inboxesToRender.map((inboxItem: ISidebarInbox) => (
                    <AppSidebarInbox key={inboxItem.inbox} {...inboxItem} />
                ))}
            </ul>
        </aside>
    );
}
