import { PlanFeaturesResponse, planTypes } from '@common/hooks/usePlanFeatures';
import { OnBoardingPlanFeaturesGroup } from './OnBoardingPlanFeaturesGroup';
import { getPlanFeaturesWrapperStyles } from './OnBoardingPlanFeaturesStyles';
import { Typography } from '@shipengine/giger';
import { Trans } from '@packlink/translation-provider';

interface OnBoardingPlanFeaturesProps {
    features: PlanFeaturesResponse;
    planType: planTypes;
}
export const OnBoardingPlanFeatures = ({ features, planType }: OnBoardingPlanFeaturesProps): JSX.Element => {
    const { DISCOUNTS_AND_BENEFITS, FEATURES, ASSISTANCE } = features;
    return (
        <div>
            <ul css={getPlanFeaturesWrapperStyles(planType)}>
                {DISCOUNTS_AND_BENEFITS && (
                    <OnBoardingPlanFeaturesGroup
                        featuresGroup={DISCOUNTS_AND_BENEFITS}
                        featuresGroupName={'subscriptions.feature-list.discounts-and-benefits'}
                        planType={planType}
                    />
                )}
                {FEATURES && (
                    <OnBoardingPlanFeaturesGroup
                        featuresGroup={FEATURES}
                        featuresGroupName={'subscriptions.feature-list.features'}
                        planType={planType}
                    />
                )}
                {ASSISTANCE && (
                    <OnBoardingPlanFeaturesGroup
                        featuresGroup={ASSISTANCE}
                        featuresGroupName={'subscriptions.feature-list.assistance'}
                        planType={planType}
                    />
                )}
            </ul>
            {planType !== planTypes.FREE && (
                <Typography>
                    <Trans variant="body1" i18nKey={'onboarding.plan-selection.cancel-anytime'} />
                </Typography>
            )}
        </div>
    );
};
