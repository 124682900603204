import { useSidePanel } from '@hooks/useSidePanel';
import { Button, ButtonVariant } from '@shipengine/giger';
import { getSidePanelFooterActionsStyles } from './SidePanelFooterActionsStyles';

export interface ISidePanelFooterActionsProps {
    disabledAction?: boolean;
    // This is optional because the action could be a SUBMIT button
    onAction?: () => void;
    onCancel?: () => void;
    actionText?: string;
    cancelText: string;
    actionButtonType?: 'button' | 'submit';
    isLoading?: boolean;
    showActionButton?: boolean;
}

export const SidePanelFooterActions = (props: ISidePanelFooterActionsProps): JSX.Element => {
    const sidePanel = useSidePanel();

    const {
        disabledAction = false,
        actionButtonType = 'button',
        onCancel = sidePanel.close,
        isLoading = false,
        onAction,
        actionText,
        cancelText,
        showActionButton = true,
    } = props;

    return (
        <section css={getSidePanelFooterActionsStyles}>
            <Button
                data-id="side-panel-footer-cancel"
                variant={ButtonVariant.OUTLINED}
                isFullWidth={true}
                onClick={onCancel}
            >
                {cancelText}
            </Button>
            {showActionButton && (
                <Button
                    data-id="side-panel-footer-action"
                    isFullWidth={true}
                    disabled={disabledAction}
                    type={actionButtonType}
                    onClick={onAction}
                    isLoading={isLoading}
                >
                    {actionText}
                </Button>
            )}
        </section>
    );
};
