import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getUPSTrademarkNoticeStyles = (theme: Theme): SerializedStyles =>
    css({
        flex: `0 1 ${theme.toPercentage(1 / 2)}`,
        marginLeft: 0,
        paddingTop: theme.spacing(),
    });

export const getTypographyUPSTrademarkNoticeStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.main,
        fontSize: theme.typography.fontSize.XXS,
    });
