import { Field, useFormikContext } from 'formik';
import { FileRejection } from 'react-dropzone';

import { useTranslation } from '@packlink/translation-provider';
import { FormField, Input } from '@shipengine/formik-giger';
import { TemplateField } from './TemplateField';
import { getImageUploaderStyles } from './TemplateFormStyles';
import { Next } from '@shipengine/giger';
import { FormFields } from '@BrandedEmails/providers/FormManager/FormManager';

export function TemplateForm(): JSX.Element {
    const { t } = useTranslation();
    const { errors, values, setFieldTouched, handleChange } = useFormikContext<FormFields>();

    const handleOnChange = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        const file = acceptedFiles.length ? acceptedFiles[0] : rejectedFiles[0]?.file;

        setFieldTouched('companyLogo', true);
        handleChange({ target: { name: 'companyLogo', value: file } });
    };

    return (
        <>
            <TemplateField
                title={t('form.label.logo')}
                subtitle={t('branded-emails.logo.subtitle')}
                info={t('branded-emails.logo.info')}
            >
                <FormField name="companyLogo">
                    <Next.ImageUploader
                        accept={{
                            'image/jpeg': ['.jpeg', '.jpg'],
                            'image/png': ['.png'],
                        }}
                        css={getImageUploaderStyles}
                        labels={{
                            toUpload: t('image-uploader.button.upload-image'),
                            toDrag: t('image-uploader.info.drop-image'),
                            toDrop: t('image-uploader.info.drop-image'),
                            toRemove: t('image-uploader.button.remove'),
                        }}
                        image={!errors.companyLogo ? values.companyLogo : undefined}
                        onChange={handleOnChange}
                    />
                </FormField>
            </TemplateField>

            <TemplateField
                title={t('form.label.company-name')}
                subtitle={t('branded-emails.company.subtitle')}
                info={t('branded-emails.company.info')}
            >
                <FormField name="companyName">
                    <Field component={Input} label={t('form.label.company-name')} />
                </FormField>
            </TemplateField>

            <TemplateField
                title={t('form.label.company-email')}
                subtitle={t('branded-emails.email.subtitle')}
                info={t('branded-emails.email.info')}
            >
                <FormField name="companyReplyToEmail">
                    <Field component={Input} label={t('form.label.account-holder')} />
                </FormField>
            </TemplateField>
        </>
    );
}
