import { useCallback } from 'react';
import { warehouseToAddress } from '@utils/ShipmentUtils';
import { IAddress, IWarehouseAddress } from '@packlink/packlink-sdk';

import { SelectAddress } from './SelectAddress';
import { useTranslation } from '@packlink/translation-provider';
import { useWarehouses } from '@common/hooks/useWarehouses';

interface ISelectAddressProps {
    selectedWarehouseId?: string;
    onSelect: (a?: IWarehouseAddress) => void;
}

export function SelectWarehouse({ selectedWarehouseId, onSelect }: ISelectAddressProps): JSX.Element {
    const {
        i18n: { language: locale },
    } = useTranslation();
    const { warehouses, defaultWarehouse } = useWarehouses();
    const getSelectedWarehouse = useCallback((id?: string) => warehouses?.find((w) => w.id === id), [warehouses]);

    const onSelectHandler = useCallback(
        (address?: IAddress) => {
            onSelect(getSelectedWarehouse(address?.id));
        },
        [getSelectedWarehouse, onSelect],
    );

    return (
        <>
            {warehouses.length > 0 && (
                <SelectAddress
                    addresses={warehouses.map((w) => warehouseToAddress(w, locale))}
                    defaultAddress={defaultWarehouse && warehouseToAddress(defaultWarehouse, locale)}
                    onClear={onSelectHandler}
                    onSelect={onSelectHandler}
                    selectedId={selectedWarehouseId}
                />
            )}
        </>
    );
}
