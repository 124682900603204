import { cloneFormData, CustomsFormData } from '@packlink/customs-form';
import {
    ICustomsAction,
    setCustomsForm,
    resetCustomsForm,
    skipCustomsForm,
    requireCustomsForm,
} from '../actions/customs';
import { getType } from 'typesafe-actions';

export interface ICustomsState {
    customsForm: CustomsFormData | undefined;
    areCustomsSkipped: boolean;
}

export const initialState: ICustomsState = {
    customsForm: undefined,
    areCustomsSkipped: false,
};

export const reducer = (state: ICustomsState = initialState, action: ICustomsAction): ICustomsState => {
    switch (action.type) {
        case getType(setCustomsForm):
            return {
                ...state,
                customsForm: cloneFormData(action.payload),
            };
        case getType(resetCustomsForm):
            return {
                ...state,
                customsForm: undefined,
                areCustomsSkipped: false,
            };
        case getType(skipCustomsForm):
            return {
                ...state,
                areCustomsSkipped: true,
            };
        case getType(requireCustomsForm):
            return {
                ...state,
                areCustomsSkipped: false,
            };
        default:
            return state;
    }
};
