export const API_ERRORS_SHIPPING_RULES: Record<string, string> = {
    SHIPPING_RULE_LIMIT_EXCEEDED: 'shipping-rules.error.limit-exceeded',
    SHIPPING_RULE_ALIAS_DUPLICATED: 'shipping-rules.error.duplicated',
};

export const API_ERRORS_OTP_MAPPING_KEYS = {
    INVALID_PHONE_NUMBER: 'phone-verification.error.invalid-phone-number',
    COOLDOWN_ACTIVE: 'phone-verification.error.cooldown-active',
    INVALID_OTP: 'phone-verification.error.invalid-otp',
    DEFAULT: 'phone-verification.error.default',
};

export enum API_ERRORS_OTP_MAPPING {
    INVALID_PHONE_NUMBER = 'InvalidPhoneNumber',
    COOLDOWN_ACTIVE = 'CooldownActive',
    INVALID_OTP = 'InvalidOTP',
    DEFAULT = 'Default',
}

export enum API_ERRORS_MAPPING {
    DEFAULT = 'Default',
    ERROR_FIELD = 'ErrorField',
    REQUIRED_FIELD = 'RequiredField',
    VALUE_NOT_ALLOWED_FIELD = 'ValueNotAllowedField',
    INVALID_FORMAT_FIELD = 'InvalidFormatField',
    MAX_SIZE_EXCEEDED_FIELD = 'MaxSizeExceededField',
    MIN_SIZE_NOT_REACHED_FIELD = 'MinSizeNotReachedField',
    SIZE_NOT_EQUALS_FIELD = 'SizeNotEqualsField',
    MUST_NOT_BE_BLANK_FIELD = 'MustNotBeBlankField',
    MUST_NOT_BE_EMPTY_FIELD = 'MustNotBeEmptyField',
    MUST_BE_POSITIVE_FIELD = 'MustBePositiveField',
    INVALID_PAYLOAD = 'InvalidPayload',
    PAYMENT_DENIED = 'PaymentDenied',
    CARRIER_TERMS_AND_CONDITIONS_REJECTED = 'CarrierTermsAndConditionsRejected',
    REQUEST_ALREADY_RECEIVED = 'RequestAlreadyReceived',
    CLIENT_NOT_AUTHORIZED = 'ClientNotAuthorized',
    SHIPMENT_ALREADY_PAYED = 'ShipmentAlreadyPayed',
    MISSING_CARRIER_PRODUCT = 'MissingCarrierProduct',
    MISSING_CUSTOMS_DOCUMENT = 'MissingCustomsDocument',
    CUSTOMS_ALREADY_USED = 'CustomsAlreadyUsed',
    CUSTOMS_NOT_ON_COMPLETED_STATUS = 'CustomsNotOnCompletedStatus',
    CARRIER_PRODUCT_FAILURE = 'CarrierProductFailure',
}

export const API_ERRORS_MAPPING_KEYS = {
    DEFAULT: 'payment.error.generic-message',
    ERROR_FIELD: 'payment.error.error-field',
    REQUIRED_FIELD: 'payment.error.required-field',
    VALUE_NOT_ALLOWED_FIELD: 'payment.error.value-not-allowed-field',
    INVALID_FORMAT_FIELD: 'payment.error.invalid-format-field',
    MAX_SIZE_EXCEEDED_FIELD: 'payment.error.max-size-exceeded-field',
    MIN_SIZE_NOT_REACHED_FIELD: 'payment.error.min-size-not-reached-field',
    SIZE_NOT_EQUALS_FIELD: 'payment.error.size-not-equals-field',
    MUST_NOT_BE_BLANK_FIELD: 'payment.error.required-field',
    MUST_NOT_BE_EMPTY_FIELD: 'payment.error.required-field',
    MUST_BE_POSITIVE_FIELD: 'payment.error.must-be-positive-field',
    INVALID_PAYLOAD: 'payment.error.generic-message',
    PAYMENT_DENIED: 'payment.error.payment-denied',
    CARRIER_TERMS_AND_CONDITIONS_REJECTED: 'payment.error.carrier-terms-and-conditions-rejected',
    REQUEST_ALREADY_RECEIVED: 'payment.error.request-already-received',
    CLIENT_NOT_AUTHORIZED: 'payment.error.generic-message',
    SHIPMENT_ALREADY_PAYED: 'payment.error.already-payed',
    MISSING_CARRIER_PRODUCT: 'payment.error.generic-message',
    MISSING_CUSTOMS_DOCUMENT: 'payment.error.customs',
    CUSTOMS_ALREADY_USED: 'payment.error.customs',
    CUSTOMS_NOT_ON_COMPLETED_STATUS: 'payment.error.customs',
    CARRIER_PRODUCT_FAILURE: 'payment.error.carrier-product-failure',
};
