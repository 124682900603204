import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getServiceListStyles = (theme: Theme): SerializedStyles =>
    css({
        maxWidth: '600px',

        [theme.mediaQuery('tablet')]: {
            maxWidth: '100%',
        },
    });
