import { useNavigate } from 'react-router';
import { StepContent } from '../StepContent';
import { TFunction, useTranslation } from '@packlink/translation-provider';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { useEffect, useMemo, useRef } from 'react';
import { InfoStepForm } from './InfoStepForm';

import { Address, IParcel, Parcel } from '@packlink/packlink-sdk';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCheckoutAdditionalData,
    getCheckoutFrom,
    getCheckoutParcels,
    getCheckoutService,
    getCheckoutShipment,
    getCheckoutTo,
} from '@store/selectors/checkout';
import { resetService, setCheckoutParcels } from '@store/actions/checkout';
import { AmplitudeEvents, GTMActions, GTMCustomDimensions, GTMEvents } from '@packlink/metrics';
import { customParcel } from '@utils/Parcel';
import { getParcelListValidation } from '@components/ParcelForm/ParcelFormList';
import { ISelectAutocompleteOption } from '@shipengine/giger';
import { AppDispatch } from '@store';
import { useCheckoutPath } from '@pages/checkout/hooks/useCheckoutPath';
import { CheckoutRoute } from '@pages/checkout/routes';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useAmplitude } from '@hooks/useAmplitude';
import { useParcels } from '@common/hooks/useParcels';

type InfoStepFormFields = Pick<Address, 'alpha2Code' | 'zipCode' | 'city' | 'state'> & {
    postalCode?: ISelectAutocompleteOption;
    country?: ISelectAutocompleteOption;
};
export interface IInfoStepForm {
    from: InfoStepFormFields | Record<string, never>;
    to: InfoStepFormFields | Record<string, never>;
    parcels: Partial<IParcel>[];
}
const getCountryPostalCodeValidation = (t: TFunction) => {
    return yup.object<InfoStepFormFields>().shape({
        country: yup
            .object<ISelectAutocompleteOption>()
            .nullable(true)
            .required(t('form.error.required', { field: t('form.label.country') })),
        postalCode: yup
            .object<ISelectAutocompleteOption>()
            .nullable(true)
            .required(t('form.error.required', { field: t('form.label.city-or-zipcode') })),
    });
};

export const InfoStep = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const getCheckoutPath = useCheckoutPath();
    const { sendGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeShipmentEvent } = useAmplitude();

    const additionalData = useSelector(getCheckoutAdditionalData);
    const selectedService = useSelector(getCheckoutService);
    const from = useSelector(getCheckoutFrom);
    const to = useSelector(getCheckoutTo);
    const parcels = useSelector(getCheckoutParcels);
    const shipment = useSelector(getCheckoutShipment);
    const { defaultParcel } = useParcels();

    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (firstRenderRef.current) {
            // We only want to check this info the first time when entering the step
            firstRenderRef.current = false;

            if (selectedService) {
                dispatch(resetService());
            }
        }
    }, [dispatch, selectedService]);

    const validationSchema = useMemo(() => {
        return yup.object().shape({
            from: getCountryPostalCodeValidation(t),
            to: getCountryPostalCodeValidation(t),
            parcels: getParcelListValidation(t),
        });
    }, [t]);

    const submitForm = (values: IInfoStepForm): void => {
        let checkParcels = true;
        const deserializedParcels = values.parcels.map((p: Partial<IParcel>) => {
            checkParcels = checkParcels && !!(p.height && p.length && p.weight && p.width);
            return Parcel.deserialize(p);
        });
        if (!checkParcels) {
            return;
        }
        dispatch(setCheckoutParcels(deserializedParcels));

        sendGtmEvent(GTMEvents.SHIPPING_SEARCH, {
            action: GTMActions.FORM_SUBMIT_SUCCESSFUL,
            formType: GTMEvents.SHIPPING_SEARCH,
            [GTMCustomDimensions.PARCELS_COUNT]: parcels.length,
            [GTMCustomDimensions.PARCELS_WEIGHT]: parcels
                .reduce((allParcelsWeight, parcel) => allParcelsWeight + (parcel.weight || 0), 0)
                .toString(),
        });

        sendAmplitudeShipmentEvent(AmplitudeEvents.START_CHECKOUT, shipment);
        navigate(getCheckoutPath(CheckoutRoute.SERVICE));
    };

    const initialPostalInfo = useMemo(
        () => ({
            from: {
                postalZoneId: additionalData.postal_zone_id_from as string,
                postalCodeId: additionalData.zip_code_id_from as string,
            },
            to: {
                postalZoneId: additionalData.postal_zone_id_to as string,
                postalCodeId: additionalData.zip_code_id_to as string,
            },
        }),
        [
            additionalData.zip_code_id_from,
            additionalData.zip_code_id_to,
            additionalData.postal_zone_id_from,
            additionalData.postal_zone_id_to,
        ],
    );

    const initialParcels = useMemo(
        () =>
            parcels?.length > 0
                ? parcels.map((p) => p.toJSON())
                : [defaultParcel ? defaultParcel.toJSON() : { ...customParcel }],
        [defaultParcel, parcels],
    );

    const initialFormValues: IInfoStepForm = {
        from: {
            zipCode: from.zipCode,
            alpha2Code: from.alpha2Code,
            city: from.city,
            state: from.state,
            country: {
                label: from.state || '',
                value: initialPostalInfo.from.postalZoneId || '',
            },
            postalCode: {
                label: from.zipCode && from.city ? `${from.zipCode} - ${from.city}` : '',
                value: initialPostalInfo.from.postalCodeId || '',
            },
        },
        to: {
            zipCode: to.zipCode,
            alpha2Code: to.alpha2Code,
            city: to.city,
            state: to.state,
            country:
                to.state && initialPostalInfo.to.postalZoneId
                    ? {
                          label: to.state,
                          value: initialPostalInfo.to.postalZoneId,
                      }
                    : undefined,
            postalCode:
                to.zipCode && to.city && initialPostalInfo.to.postalCodeId
                    ? {
                          label: `${to.zipCode} - ${to.city}`,
                          value: initialPostalInfo.to.postalCodeId,
                      }
                    : undefined,
        },
        parcels: initialParcels,
    };

    return (
        <StepContent>
            <Formik
                initialValues={initialFormValues}
                validationSchema={validationSchema}
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={submitForm}
            >
                {(_formProps: FormikProps<IInfoStepForm>): React.ReactNode => <InfoStepForm />}
            </Formik>
        </StepContent>
    );
};
