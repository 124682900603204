import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useToast } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { PaymentMethod, PaymentType } from '@packlink/packlink-sdk';

import { AppDispatch } from '@store';
import { savePaymentMethod } from '@store/actions/payment';
import { useSettingsPaymentDeferredData } from '@Invoicing/hooks/useSettingsPaymentDeferredData';
import { useDeferredPaymentFormStatus, DeferredPaymentsFormStatus } from '@Invoicing/context/deferredPaymentFormStatus';

interface IHookReturn {
    handleDeletePaymentMethod: () => void;
    saveDeferredPaymentMethod: (nonce: string, paymentMethod: PaymentMethod) => void;
}

export const useSettingsPaymentDeferredActions = (): IHookReturn => {
    const { t } = useTranslation();
    const toast = useToast(t);
    const dispatch = useDispatch<AppDispatch>();

    const { setDeferredPaymentFormStatus } = useDeferredPaymentFormStatus();
    const { clientCreditCardDeferred, clientPaypalDeferred } = useSettingsPaymentDeferredData();

    const currentDeferredPaymentMethod = clientPaypalDeferred || clientCreditCardDeferred;

    const saveDeferredPaymentMethod = useCallback(
        (nonce: string, paymentMethod: PaymentMethod) => {
            setDeferredPaymentFormStatus(DeferredPaymentsFormStatus.LOADING);

            const currentDeferredPaymentMethodId = currentDeferredPaymentMethod && currentDeferredPaymentMethod.id;

            dispatch(
                savePaymentMethod(
                    PaymentType.DEFERRED,
                    paymentMethod,
                    nonce,
                    undefined,
                    currentDeferredPaymentMethodId,
                ),
            )
                .then(() => toast.success({ message: t('settings.payment-deferred.payment-updated') }))
                .catch((err) => toast.error({ message: t(err.message) }))
                .finally(() => setDeferredPaymentFormStatus(DeferredPaymentsFormStatus.CLOSED));
        },
        [currentDeferredPaymentMethod, dispatch, setDeferredPaymentFormStatus, t, toast],
    );

    const handleDeletePaymentMethod = useCallback(() => {
        setDeferredPaymentFormStatus(DeferredPaymentsFormStatus.CLOSED);
    }, [setDeferredPaymentFormStatus]);

    return {
        saveDeferredPaymentMethod,
        handleDeletePaymentMethod,
    };
};
