import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export function getOrderDetailsItemStyles(theme: Theme): SerializedStyles {
    return css({
        marginBottom: theme.spacing(2),
    });
}

export function getTitleStyles(theme: Theme): SerializedStyles {
    return css({
        textTransform: 'uppercase',
        color: theme.palette.gray.dark,
        marginBottom: theme.spacing(0.5),
    });
}
export function getContentStyles(): SerializedStyles {
    return css({
        margin: '0',
    });
}
