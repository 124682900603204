import { RadioGroup, Radio, Typography } from '@shipengine/giger';
import { ArchiveTimeType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { ChangeEvent } from 'react';
import { getArchivingOptionStyles, getArchivingOptionTitleStyles } from './ArchivingStyles';

interface IArchivingOptionProps {
    optionTitle: string;
    optionName: string;
    value: ArchiveTimeType | undefined;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ArchivingOption = (props: IArchivingOptionProps): JSX.Element => {
    const { optionTitle, optionName, value, handleChange } = props;
    const { t } = useTranslation();

    return (
        <section css={getArchivingOptionStyles}>
            <Typography component="p" variant="body1" css={getArchivingOptionTitleStyles}>
                {optionTitle}
            </Typography>
            <RadioGroup name={optionName} value={value} onChange={handleChange}>
                <Radio value={ArchiveTimeType.ONEWEEK}>{t('archiving.settings.options-oneweek')}</Radio>
                <Radio value={ArchiveTimeType.TWOWEEKS}>{t('archiving.settings.options-twoweeks')}</Radio>
                <Radio value={ArchiveTimeType.MONTH}>{t('archiving.settings.options-month')}</Radio>
                <Radio value={ArchiveTimeType.NEVER}>{t('archiving.settings.options-never')}</Radio>
            </RadioGroup>
        </section>
    );
};
