export enum PanelName {
    FILTERS = 'FILTERS',
    HEADER_MENU = 'HEADER_MENU',
    INTEGRATIONS = 'INTEGRATIONS',
    INVOICES = 'INVOICES',
    OWN_CONTRACT = 'OWN_CONTRACT',
    SHIPMENTS = 'SHIPMENTS',
    SHIPMENTS_EXPORT = 'SHIPMENTS_EXPORT',
    SUBSCRIPTIONS = 'SUBSCRIPTIONS',
    OWN_CONTRACT_SERVICE_SELECTION = 'OWN_CONTRACT_SERVICE_SELECTION',
    EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD',
}
