import { FC } from 'react';

import { IGenericPanelContentProps } from '@Integrations/components/IntegrationPanel/types';

import { ModuleIntegrationForm } from './ModuleIntegrationForm';

export const ModuleIntegrationPanel: FC<IGenericPanelContentProps> = (
    props: IGenericPanelContentProps,
): JSX.Element => {
    const { onClose, integration, onCancel } = props;

    return <ModuleIntegrationForm onCancel={onCancel} onClose={onClose} integration={integration} />;
};
