import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getBulkActionsStyles = (): SerializedStyles =>
    css({
        padding: 0,
    });

export const getMoreActionsTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        letterSpacing: 0,
        padding: theme.spacing(2),
        margin: 0,
        borderBottom: `1px solid ${theme.palette.gray.ultraLight}`,
    });

export const getActionTooltipStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });

export const getMoreActionsOptionStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        padding: `${theme.spacing(2)}px 0`,
        margin: `0 ${theme.spacing(2)}px`,

        ':not(:last-child)': {
            borderBottom: `1px solid ${theme.palette.gray.ultraLight}`,
        },

        '.btn': {
            display: 'flex',
            padding: 0,
        },
    });

export const getBulkActionsMessageStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `${theme.spacing(2)}px 0`,
    });

export const getInlineNotificationTopStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getBulkDeleteStyles = (): SerializedStyles =>
    css({
        padding: 0,
        display: 'block',
    });

export const getActionsWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
        backgroundColor: theme.palette.gray.megaLight,
        borderBottom: `1px solid ${theme.palette.gray.ultraLight}`,

        'button:last-child': {
            marginLeft: theme.spacing(2),
        },
    });

export const getWarningStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing(4)}px 0 0`,
    });
