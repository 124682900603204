import { getIntegrationFormLogoStyles, getIntegrationFormLogoContainerStyles } from './IntegrationFormLogoStyles';

export interface IIntegrationFormLogo {
    logo: string;
    alt: string;
}

export const IntegrationFormLogo: React.FC<IIntegrationFormLogo> = ({
    logo,
    alt,
}: IIntegrationFormLogo): JSX.Element => {
    return (
        <div css={getIntegrationFormLogoContainerStyles}>
            <img css={getIntegrationFormLogoStyles} src={logo} alt={alt} />
        </div>
    );
};
