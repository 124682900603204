import { useTranslation } from '@packlink/translation-provider';
import { useFormatCurrency } from '@hooks/useFormatCurrency';
import { OrderDetailsItem } from './OrderDetailsItem';
import { TFunction } from 'i18next';
import { ParcelsDetails } from './ParcelsDetails';
import { IShipment, ProShipment } from '@packlink/packlink-sdk';
import { WithCommonProps } from '@shipengine/giger';

export type OrderDetailsProps = WithCommonProps<{
    shipment: ProShipment<IShipment>;
}>;

const getSectionTitleCreator =
    (t: TFunction) =>
    (titleKey: string): string =>
        `${t(titleKey)} :`;

export function OrderDetails({ shipment, ...props }: OrderDetailsProps): JSX.Element {
    const { t } = useTranslation();

    const getSectionTitle = getSectionTitleCreator(t);

    return (
        <dl {...props}>
            <OrderDetailsItem title={getSectionTitle('post-sale.order.content')}>
                {shipment.data.content?.description}
            </OrderDetailsItem>
            {shipment.data.content?.secondHand && (
                <OrderDetailsItem title={getSectionTitle('post-sale.order.second-hand')}>
                    {t('post-sale.order.yes')}
                </OrderDetailsItem>
            )}
            <OrderDetailsItem title={getSectionTitle('post-sale.order.value')}>
                {useFormatCurrency(shipment.data.currency, shipment.data.content?.value)}
            </OrderDetailsItem>
            {shipment.data.parcels && (
                <OrderDetailsItem title={getSectionTitle('post-sale.order.parcels')}>
                    <ParcelsDetails parcels={shipment.data.parcels} />
                </OrderDetailsItem>
            )}
        </dl>
    );
}
