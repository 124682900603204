import { StatusAction } from '@store/actions/status';
import { SET_STATUS } from '@store/actions/action-types';
import { IStatus } from '@types';

export type IStatusState = IStatus;

export const initialState = {
    fetchingReport: false,
};

export const reducer = (state: IStatusState = initialState, action: StatusAction): IStatusState => {
    const { type, payload } = action;

    switch (type) {
        case SET_STATUS: {
            return { ...state, ...payload };
        }
        default:
            return state;
    }
};
