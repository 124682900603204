import { Checkbox } from '@shipengine/giger';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';

import { getSelectedShipmentReferences, isShipmentSelected } from '@store/selectors/bulk';
import { addSelectedShipment, removeSelectedShipment } from '@store/actions/bulk';
import { AmplitudeEvents } from '@constants/amplitude';
import { ProShipment } from '@packlink/packlink-sdk';
import { ShipmentPanelType } from '@types';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { BulkFlows } from '../ShipmentPanel/ShipmentPanelBulk/typings';
import { GTMActionFieldList } from '@packlink/metrics';
import { getShipmentRowCellStyles } from './ShipmentRowStyles';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IShipmentRowCheckboxCell {
    index: number;
    shipment: ProShipment;
}

export const ShipmentRowCheckboxCell: React.FC<IShipmentRowCheckboxCell> = (
    props: IShipmentRowCheckboxCell,
): JSX.Element => {
    const {
        index,
        shipment: {
            data: { packlinkReference: shipmentReference, from, to, parcels: packages, service, price, source },
        },
    } = props;
    const isSelected = useSelector(isShipmentSelected(shipmentReference));
    const selectedShipmentReferences = useSelector(getSelectedShipmentReferences);
    const { openPanel, closePanel } = useShipmentPanel();
    const dispatch = useDispatch<AppDispatch>();
    const { sendProductClickGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeTableViewClickEvent } = useAmplitude();

    const handleOnChange = ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>): void => {
        if (checked) {
            dispatch(addSelectedShipment(shipmentReference));
            openPanel(ShipmentPanelType.BULK, undefined, { flow: BulkFlows.ACTIONS });
            sendAmplitudeTableViewClickEvent(AmplitudeEvents.SELECT_SHIPMENT);
            sendProductClickGtmEvent({
                actionFieldList: GTMActionFieldList.SERVICE_SELECT_PRO,
                index,
                from,
                to,
                service: { ...service, price: price?.price },
                packages,
                source,
            });
        } else {
            if (selectedShipmentReferences.length === 1) {
                closePanel(false);
            }

            dispatch(removeSelectedShipment(shipmentReference));
            sendAmplitudeTableViewClickEvent(AmplitudeEvents.DESELECT_SHIPMENT);
        }
    };

    return (
        <div css={[getShipmentRowCellStyles(false, true)]} data-id="shipment-row-cell">
            <Checkbox name="bulk" checked={isSelected} onChange={handleOnChange} />
        </div>
    );
};
