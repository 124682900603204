import { apiClient } from '@sdk';
import {
    CountriesRepository,
    PostalCodesRepository,
    PostalZonesRepository,
    IPostalZone,
    IPostalCode,
    ICountry,
} from '@packlink/packlink-sdk';
import { logSdkError } from '@utils/logger';

const getBackendLocale = (locale: string): string => locale.replace('-', '_');

const countriesRepository = new CountriesRepository(apiClient);
const postalCodesRepository = new PostalCodesRepository(apiClient);
const postalZonesRepository = new PostalZonesRepository(apiClient);

export class LocationUtils {
    static getDestinations(locale: string): Promise<IPostalZone[]> {
        const language = getBackendLocale(locale);

        return postalZonesRepository.allDestinations({ language }).catch(logSdkError);
    }

    static getOrigins(locale: string): Promise<IPostalZone[]> {
        const language = getBackendLocale(locale);

        return postalZonesRepository.allOrigins({ language }).catch(logSdkError);
    }

    static getPostalCodes(locale: string, postalzone: string, searchQuery: string): Promise<IPostalCode[]> {
        const language = getBackendLocale(locale);

        return postalCodesRepository.all({ language, postalzone, q: searchQuery }).catch(logSdkError);
    }

    static getPostalCodesByAlpha2Code(alpha2Code: string, searchQuery: string): Promise<IPostalCode[]> {
        return postalCodesRepository.allByAlpha2Code(alpha2Code, { q: searchQuery }).catch(logSdkError);
    }

    static getAllCountries(locale: string): Promise<ICountry[]> {
        const language = getBackendLocale(locale);

        return countriesRepository.all({ language }).catch(logSdkError);
    }
}
