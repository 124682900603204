import { useState, useLayoutEffect } from 'react';
import { Margin } from '@nivo/core';

/**
 * Returns the margins needed to fit the chart within the svg
 */
export function useMargin(id: string): Margin | undefined {
    const [margin, setMargin] = useState<Margin>();

    useLayoutEffect(() => {
        const observer = new MutationObserver((mutationsList, observer) => {
            const mutation = mutationsList[0];

            if (mutation.type === 'childList') {
                const svg = mutation.target.parentElement?.querySelector('svg');
                const chart = mutation.target.parentElement?.querySelector('svg g');

                if (!svg || !chart) return;

                const svgDimensions = svg.getBoundingClientRect();
                const chartDimensions = chart.getBoundingClientRect();

                const offsetLeft = svgDimensions.x - chartDimensions.x;
                const offsetRight = chartDimensions.width - svgDimensions.width - offsetLeft;
                const offsetTop = svgDimensions.top - chartDimensions.top;
                const offsetBottom = chartDimensions.height - svgDimensions.height - offsetTop;

                setMargin({
                    top: offsetTop,
                    right: offsetRight,
                    bottom: offsetBottom,
                    left: offsetLeft,
                });

                observer.disconnect();
            }
        });

        const chartWrapper = document.querySelector(`#${id}`);

        if (!chartWrapper) return;

        observer.observe(chartWrapper, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, [id]);

    return margin;
}
