import { useCallback, useEffect, useState } from 'react';

import { ISelectOption, Option, Select } from '@shipengine/giger';

import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { getLanguageSelectStyles } from './LanguageSelectorStyles';
import { useAmplitude } from '@hooks/useAmplitude';
import { useLanguageChange } from './hooks/useLanguageChange';
import { useTranslation } from '@packlink/translation-provider';
import { useAvailableLanguages } from '@hooks';

interface ISelectorOptions {
    label: string;
    value: string;
}

export const LanguageSelector = (): JSX.Element => {
    const { sendAmplitudeClickEvent } = useAmplitude();
    const {
        i18n: { language: locale },
    } = useTranslation();

    const [options, setOptions] = useState<ISelectorOptions[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<ISelectOption | undefined>();

    const { availableLanguages } = useAvailableLanguages();

    const { mutationClientsLanguage, mutationUsersLanguage } = useLanguageChange();

    useEffect(() => {
        setOptions(
            availableLanguages.map(
                (lang): ISelectorOptions => ({
                    label: lang.language,
                    value: lang.locale,
                }),
            ),
        );
    }, [availableLanguages]);

    useEffect(() => {
        setSelectedLanguage(options.find((option: ISelectorOptions): boolean => option.value === locale));
    }, [locale, options]);

    const onChange = useCallback(
        (_name: string, value: ISelectOption | null): void => {
            if (!value) return;

            sendAmplitudeClickEvent(AmplitudeEvents.LANGUAGE_SELECTOR_SETTINGS, {
                [AmplitudeProperties.CURRENT_LANGUAGE]: selectedLanguage?.value,
                [AmplitudeProperties.SELECTED_LANGUAGE]: value,
            });

            setSelectedLanguage(value);
            mutationUsersLanguage.mutate(value.value);
            mutationClientsLanguage.mutate(value.value);
        },
        [mutationClientsLanguage, mutationUsersLanguage, selectedLanguage?.value, sendAmplitudeClickEvent],
    );

    return (
        <div className="language-selector">
            <Select
                showLabel={false}
                onChange={onChange}
                name="language-selector"
                value={selectedLanguage}
                css={getLanguageSelectStyles}
            >
                {options.map((option) => (
                    <Option value={option.value} key={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        </div>
    );
};
