import { Spinner, SpinnerSize, Table, TableBody, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { getSupportTicketsTableFlags, SupportTicketsTableQueryData } from '../../SupportTicketsTableData';
import { EmptyTableContent } from './components/EmptyTableContent/EmptyTableContent';
import { TicketTableFooter } from './components/TicketTableFooter/TicketTableFooter';
import { TicketTableHeader } from './components/TicketTableHeader/TicketTableHeader';
import { TicketTableRow } from './components/TicketTableRow/TicketTableRow';
import { NoResultsTableContent } from './components/NoResultsTableContent/NoResultsTableContent';
import { getSupportTicketsTableSpinnerStyles } from './TicketTableStyles';

type TicketTableProps = WithCommonProps<{
    data?: SupportTicketsTableQueryData;
    isLoading?: boolean;
}>;

export function TicketTable({ data = { tickets: [] }, isLoading = false, ...props }: TicketTableProps): JSX.Element {
    const { t } = useTranslation();
    const { hasNoResults, hasActiveFilters } = getSupportTicketsTableFlags(data);

    if (isLoading) {
        return (
            <Table header={<TicketTableHeader />} {...props}>
                <div css={getSupportTicketsTableSpinnerStyles}>
                    <Spinner
                        size={SpinnerSize.SIZE_LARGE}
                        data-id="previous-ticket-loading"
                        message={t('support-center.tickets-table.loading-message')}
                    />
                </div>
            </Table>
        );
    }

    if (hasNoResults) {
        return hasActiveFilters ? (
            <Table header={<TicketTableHeader />} {...props}>
                <NoResultsTableContent data-id="ticket-table-no-results-message" />
            </Table>
        ) : (
            <EmptyTableContent data-id="ticket-table-empty-message" />
        );
    }

    return (
        <Table header={<TicketTableHeader />} footer={<TicketTableFooter data={data.tickets} />} {...props}>
            <TableBody>
                {data.tickets.map((supportTicket) => (
                    <TicketTableRow data={supportTicket} key={supportTicket.id} />
                ))}
            </TableBody>
        </Table>
    );
}
