import { FC } from 'react';

import { IGenericPanelContentProps } from '@Integrations/components/IntegrationPanel/types';

import { EbayIntegrationForm } from './EbayIntegrationForm';

export const EbayIntegrationPanel: FC<IGenericPanelContentProps> = (props: IGenericPanelContentProps): JSX.Element => {
    const { clearError, showError, integration, onClose, onDeactivate, onSubmit, onCancel } = props;

    return (
        <EbayIntegrationForm
            onCancel={onCancel}
            clearError={clearError}
            showError={showError}
            integration={integration}
            onClose={onClose}
            onDeactivate={onDeactivate}
            onSubmit={onSubmit}
        />
    );
};
