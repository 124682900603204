import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTermsAndConditionsStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(),
        marginBottom: 0,
        color: theme.palette.gray.main,
        fontSize: theme.typography.fontSize.XXS,
        lineHeight: '12px',
    });
