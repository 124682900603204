import { useMemo } from 'react';
import { SHORTCODES } from '@constants/shortcodes';

export const useSourceShortcode = (source: string): string => {
    return useMemo((): string => {
        if (!source) {
            return '';
        }

        const sourceKey = source.toLowerCase();

        return SHORTCODES[sourceKey as keyof typeof SHORTCODES] || source.substr(0, 3).toUpperCase();
    }, [source]);
};
