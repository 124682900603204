import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export function getShipmentRowExtensionStyles(theme: Theme): CSSObject {
    return {
        margin: theme.spacing(1),
        padding: `0px ${theme.spacing(3)}px`,
        boxShadow: theme.elevations.popout1.boxShadow,
        borderRadius: theme.borderRadius.S,
        backgroundColor: theme.palette.white,
        maxHeight: '538px',
        overflowY: 'scroll',
    };
}
