import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getIconStyles = (theme: Theme): SerializedStyles =>
    css({
        [theme.mediaQuery('tablet')]: {
            display: 'none',
        },
    });

export const getAppHeaderMenuItemStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: theme.palette.primary.main,
    });

export const getAppHeaderMenuItemTextStyles = (theme: Theme): SerializedStyles =>
    css({
        fontWeight: theme.typography.fontWeight.semibold,
        fontSize: theme.typography.fontSize.S,
    });
