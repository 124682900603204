import { Button, ButtonSize, ButtonVariant, Icon, IconPopover, IconSize, Typography } from '@shipengine/giger';
import { getActionTooltipStyles, getMoreActionsOptionStyles } from './ShipmentPanelBulkActionsStyles';
import { getSelectedShipments, getSelectedShipmentsByAvailableInsurance } from '@store/selectors/bulk';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';
import { removeSelectedShipments } from '@store/actions/bulk';
import { AmplitudeEvents } from '@constants/amplitude';
import { IShipmentPanelBulkActionsProps } from './ShipmentPanelBulkActions';
import { BulkFlows } from '../typings';
import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { useAmplitude } from '@hooks/useAmplitude';

interface IShipmentPanelBulInsuranceActionProps extends IShipmentPanelBulkActionsProps {
    isOverMaxShipmentsAllowed: boolean;
}

export function ShipmentPanelBulkInsuranceAction({
    onSetFlow,
    isOverMaxShipmentsAllowed,
}: IShipmentPanelBulInsuranceActionProps): JSX.Element {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const selectedShipments = useSelector(getSelectedShipments);
    const numberSelectedShipments = selectedShipments.length;

    const [selectedInsurableShipments, selectedNotInsurableShipments] = useSelector(
        getSelectedShipmentsByAvailableInsurance,
    );

    const handleEditInsurance = (): void => {
        if (selectedNotInsurableShipments.length) {
            const references = selectedNotInsurableShipments.map((s) => s.data.packlinkReference);
            dispatch(removeSelectedShipments(references));
        }

        sendAmplitudeSidebarClickEvent(AmplitudeEvents.INSURE_SHIPMENT);

        onSetFlow(BulkFlows.INSURANCE);
    };

    return (
        <div css={getMoreActionsOptionStyles}>
            <Button
                onClick={handleEditInsurance}
                variant={ButtonVariant.TEXT}
                size={ButtonSize.SMALL}
                disabled={!selectedInsurableShipments.length || isOverMaxShipmentsAllowed}
                data-id="bulk-buy-insurance-button"
            >
                <Icon name={IconNames.CARDBOARD_BOX_SHIELD} />
                <span>{t('bulk-edit.actions.buy-insurance')}</span>
            </Button>

            {selectedNotInsurableShipments.length > 0 && (
                <IconPopover
                    icon={IconNames.INFO}
                    size={IconSize.SIZE_SMALL}
                    css={getActionTooltipStyles}
                    data-id="bulk-buy-insurance-button"
                >
                    <Typography variant="body2">
                        {selectedInsurableShipments.length === 0
                            ? t('bulk-edit.actions.insurance-message-none', {
                                  count: selectedNotInsurableShipments.length,
                              })
                            : t('bulk-edit.actions.insurance-message', {
                                  totalShipments: numberSelectedShipments,
                                  nonEditableShipments: selectedNotInsurableShipments.length,
                                  editableShipments: selectedInsurableShipments.length,
                              })}
                    </Typography>
                </IconPopover>
            )}
        </div>
    );
}
