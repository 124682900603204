import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { Grid, GridChild, Icon, IconSize } from '@shipengine/giger';

import { GenerateApiKey } from '@Integrations/components/GenerateApiKey/GenerateApiKey';
import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';
import { InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';

import { getInfoPanelStepChildrenStyle } from './ApiKeySettingsStyles';

export const ApiKeySettings = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <SettingsPage>
            <SettingsPageHeader description={t('api-key.settings.subtitle')}>
                {t('api-key.settings.title')}
            </SettingsPageHeader>

            <Grid noPadding>
                <GridChild colSpan={4} rowStart={1}>
                    <GenerateApiKey canDelete />
                </GridChild>

                <GridChild colSpan={7} rowStart={2}>
                    <InfoPanel collapsible={false} title={t('api-key.settings.benefits-title')}>
                        <InfoPanelStep css={getInfoPanelStepChildrenStyle(false)}>
                            <Icon name={IconNames.INFO} size={IconSize.SIZE_SMALL} />
                            <p>{t('api-key.settings.benefits-1')}</p>
                        </InfoPanelStep>

                        <InfoPanelStep css={getInfoPanelStepChildrenStyle(false)}>
                            <Icon name={IconNames.INFO} size={IconSize.SIZE_SMALL} />
                            <p>{t('api-key.settings.benefits-2')}</p>
                        </InfoPanelStep>

                        <InfoPanelStep css={getInfoPanelStepChildrenStyle(true)}>
                            <Icon name={IconNames.INFO} size={IconSize.SIZE_SMALL} />
                            <p>{t('api-key.settings.benefits-3')}</p>
                        </InfoPanelStep>
                    </InfoPanel>
                </GridChild>
            </Grid>
        </SettingsPage>
    );
};
