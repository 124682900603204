export enum CsvImportEvent {
    CSV_IMPORT_ENDED = 'csv-shipments-import-finished',
}

export interface ICsvImportFinishedData {
    shipment_references: string[];
}

export interface ICsvImportFinishedError {
    error: string;
}
