import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiClient } from '@sdk';
import { ApiClientError, IWarehouseAddress, WarehouseAddressesRepository } from '@packlink/packlink-sdk';
import { logSdkError } from '@utils/logger';

export const MAX_WAREHOUSES = 8;
const WAREHOUSES_CACHE_KEY = 'getWarehouses';
const warehouseAddressesRepository = new WarehouseAddressesRepository(apiClient);
export const useWarehouses = () => {
    const {
        data: warehousesWithPostalInfo,
        isLoading,
        isSuccess,
    } = useQuery({
        queryKey: WAREHOUSES_CACHE_KEY,
        queryFn: () => warehouseAddressesRepository.get(),
    });

    const queryClient = useQueryClient();
    const { mutate: setDefaultWarehouseMutation } = useMutation(
        (warehouse: IWarehouseAddress) => warehouseAddressesRepository.setDefault(warehouse.id),
        {
            onError: (error?: ApiClientError) => {
                error && logSdkError(error);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(WAREHOUSES_CACHE_KEY);
            },
        },
    );

    const { mutate: deleteWarehouseMutation } = useMutation(
        (warehouse: IWarehouseAddress) => warehouseAddressesRepository.delete(warehouse.id),
        {
            onError: (error?: ApiClientError) => {
                error && logSdkError(error);
            },
            onSuccess: (_response, warehouse) => {
                const isDefault = warehouse.isDefault;
                isDefault && warehousesWithPostalInfo && setDefaultWarehouseMutation(warehousesWithPostalInfo[0]);
                queryClient.invalidateQueries(WAREHOUSES_CACHE_KEY);
            },
        },
    );

    const { mutate: updateWarehouseMutation } = useMutation(
        (data: IWarehouseAddress) => warehouseAddressesRepository.update(data),
        {
            onError: (error?: ApiClientError) => {
                error && logSdkError(error);
            },
            onSuccess: (_response) => {
                queryClient.invalidateQueries(WAREHOUSES_CACHE_KEY);
            },
        },
    );

    const { mutate: createWarehouseMutation } = useMutation(
        (data: IWarehouseAddress) => warehouseAddressesRepository.create(data),
        {
            onError: (error?: ApiClientError) => {
                error && logSdkError(error);
            },
            onSuccess: (_response) => {
                queryClient.invalidateQueries(WAREHOUSES_CACHE_KEY);
            },
        },
    );

    const handleRequestSaveWarehouse = (
        data: IWarehouseAddress,
        mutationEvents?: {
            onSuccess?: (warehouse: IWarehouseAddress) => void;
            onError?: () => void;
        },
    ) => {
        return data.id ? updateWarehouseMutation(data, mutationEvents) : createWarehouseMutation(data, mutationEvents);
    };

    const canAddWarehouse = (warehousesWithPostalInfo?.length ?? 0) < MAX_WAREHOUSES;
    const defaultWarehouse = warehousesWithPostalInfo?.find((w) => w.isDefault);
    return {
        warehouses: warehousesWithPostalInfo ?? [],
        isLoading,
        isSuccess,
        canAddWarehouse,
        defaultWarehouse,
        setDefaultWarehouseMutation,
        deleteWarehouseMutation,
        handleRequestSaveWarehouse,
    };
};
