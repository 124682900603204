import { Next } from '@shipengine/giger';
import { getShipmentRowTrackingStyles } from '../ShipmentRowStyles';
import { ContentWithClipboard } from '@components/Clipboard/ContentWithClipboard';

export interface IShipmentRowTracking {
    tracking: string;
    trackingUrl?: string;
    onClickUrl?: () => void;
    onClickCopy?: () => void;
}

export const ShipmentRowTracking = (props: IShipmentRowTracking): JSX.Element => {
    const { tracking, trackingUrl, onClickUrl, onClickCopy } = props;

    return (
        <ContentWithClipboard valueToCopy={tracking} onClick={onClickCopy} css={getShipmentRowTrackingStyles}>
            {trackingUrl && (
                <Next.Link isExternal={true} target="_blank" title={tracking} href={trackingUrl} onClick={onClickUrl}>
                    {tracking}
                </Next.Link>
            )}
            {!trackingUrl && <span>{tracking}</span>}
        </ContentWithClipboard>
    );
};
