import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getArchivingOptionStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            marginBottom: theme.spacing(5),
        });

export const getArchivingOptionTitleStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            marginBottom: theme.spacing(2.5),
        });
