import { Typography, WithChildrenCommonProps } from '@shipengine/giger';
import { getTicketDataDetailsSectionItemStyles } from './TicketDataSectionItemStyles';

type TicketDataSectionItemProps = WithChildrenCommonProps<{
    title: React.ReactNode;
}>;

export function TicketDataSectionItem({ title, children, ...props }: TicketDataSectionItemProps): JSX.Element {
    return (
        <li css={getTicketDataDetailsSectionItemStyles} {...props}>
            <Typography component="div" variant="body2" bold>
                {title}
            </Typography>
            <Typography component="div" variant="body2">
                {children}
            </Typography>
        </li>
    );
}
