import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const tabListWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    });

export const addAddressButtonStyles = (): SerializedStyles =>
    css({
        marginLeft: 'auto',
    });

export const addAddressIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
    });
