import { ISupportUser } from '@packlink/packlink-sdk';
import { AmplitudeEvents, AmplitudeProperties, ProviderName, Tracker } from '@packlink/metrics';
import { ZendeskWidgetAction } from './types';

type ZendeskWidgetCreationParams = {
    key: string;
    // Supported locales: https://support.zendesk.com/api/v2/locales/public.json
    locale: string;
    user?: ISupportUser;
    tracker: Tracker;
    talkLineId: string;
    userEmail: string;
};

export const ZENDESK_SCRIPT_ID = 'ze-snippet';

function addZendeskScriptToHead(key: string): HTMLScriptElement {
    const zendeskUrl = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = zendeskUrl;
    script.id = ZENDESK_SCRIPT_ID;
    script.async = false;
    document.head.appendChild(script);

    return script;
}

function isZendeskAvailable(): boolean {
    return !!window.zE;
}

function getUserEmailConversationTag(email: string): string {
    return `requesteremail_${email.replace('@', '___at___')}`;
}

/**
 * Initializes a Zendesk Widget and exposes its API and its integration with the Voice Messaging API
 * More info: https://developer.zendesk.com/api-reference/widget-messaging/web/core/
 *            https://developer.zendesk.com/documentation/zendesk-web-widget-sdks/sdks/web/voice-messaging-qs/
 */
export class ZendeskWidgetClient {
    private constructor(
        private tracker: Tracker,
        private user: ISupportUser | undefined,
        private talkLineId: string,
        private userEmail: string,
    ) {}

    /**
     * Initializes a Zendesk widget, by adding Zendesk script in <head> if needed.
     */
    static create({
        key,
        locale,
        user,
        tracker,
        talkLineId,
        userEmail,
    }: ZendeskWidgetCreationParams): Promise<ZendeskWidgetClient> {
        const existingZendeskScript = document.getElementById(ZENDESK_SCRIPT_ID) as HTMLScriptElement;

        if (existingZendeskScript) {
            return Promise.resolve(new ZendeskWidgetClient(tracker, user, talkLineId, userEmail));
        }

        const zendeskScript = addZendeskScriptToHead(key);

        return new Promise((resolve, reject) => {
            // Zendesk script is loaded asynchronously, so we need to wait for it to be loaded.
            zendeskScript.addEventListener(
                'load',
                (): void => {
                    if (isZendeskAvailable()) {
                        const clientInstance = new ZendeskWidgetClient(tracker, user, talkLineId, userEmail);

                        window.zE?.('messenger:set', 'locale', locale);
                        clientInstance.configureListeners();

                        resolve(clientInstance);
                    } else {
                        reject('Zendesk widget could not be loaded!');
                    }
                },
                false,
            );
        });
    }

    open(): void {
        window.zE?.('messenger', ZendeskWidgetAction.OPEN);
        window.zE?.('messenger:set', 'conversationTags', [getUserEmailConversationTag(this.userEmail)]);
    }

    close(): void {
        window.zE?.('messenger', ZendeskWidgetAction.CLOSE);
    }

    openCall(): void {
        window.zE?.('messenger:open', 'voice', this.talkLineId);
        this.sendMetric(AmplitudeEvents.ZENDESK_CALL_SUBMIT);
    }

    private configureListeners(): void {
        window.zE?.('messenger:on', ZendeskWidgetAction.OPEN, () =>
            this.sendMetric(AmplitudeEvents.ZENDESK_WIDGET_OPEN),
        );
        window.zE?.('messenger:on', ZendeskWidgetAction.CLOSE, () =>
            this.sendMetric(AmplitudeEvents.ZENDESK_WIDGET_MINIMIZED),
        );
    }

    private sendMetric(eventName: string): void {
        this.tracker.track({
            providerName: ProviderName.AMPLITUDE,
            payload: {
                [AmplitudeProperties.VIP_USER]: this.user?.isVip ?? false,
            },
            eventName,
        });
    }
}
