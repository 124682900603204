import { TableFooter, Typography, WithCommonProps } from '@shipengine/giger';
import { AnySupportTicket } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

type TicketTableFooterProps = WithCommonProps<{
    data: AnySupportTicket[];
}>;

export function TicketTableFooter({ data, ...props }: TicketTableFooterProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <TableFooter {...props}>
            <Typography variant="body2" component="span">
                {t('support-center.tickets-table.table-footer', { currentItems: data.length })}
            </Typography>
        </TableFooter>
    );
}
