import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Next, Typography } from '@shipengine/giger';
import { useCdnUrl } from '@packlink/provider';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { FormFields } from '../../providers/FormManager/FormManager';
import { InfoCard } from '../InfoCard/InfoCard';
import { TemplateFooter } from '../TemplateFooter/TemplateFooter';
import { TrackingReference } from '../TrackingReference/TrackingReference';
import { ImagePreview } from '../ImagePreview/ImagePreview';
import {
    getInfoCaptionStyles,
    getEmailTemplateSectionStyles,
    getInfoCardsWrapperStyles,
    getCompanyLogoWrapperStyles,
    getCarrierLogoStyles,
    getJourneyLinkStyles,
    getInfoNumberStyles,
} from './EmailTemplateStyles';
import { useFormikContext } from 'formik';

export interface EmailTemplateProps {
    clientName?: string;
    trackingNumber: string;
    shipmentRef: string;
    deliveryAddress: string;
    deliveryDateNumber: number;
    deliveryCarrierName?: string;
    deliveryCarrierLogo?: string;
}

export function EmailTemplate({
    trackingNumber,
    shipmentRef,
    clientName,
    deliveryAddress,
    deliveryDateNumber,
    deliveryCarrierName = 'UPS',
    deliveryCarrierLogo = 'ups-square',
}: EmailTemplateProps): JSX.Element {
    const { t } = useTranslation();
    const cdnUrl = useCdnUrl();
    const {
        values: { companyName, companyLogo },
        errors,
    } = useFormikContext<FormFields>();

    const headingCaption = t('branded-emails.template.intro', {
        name: clientName || t('branded-emails.template.user'),
    });

    return (
        <section css={getEmailTemplateSectionStyles}>
            {companyLogo && !errors.companyLogo && (
                <ImagePreview css={getCompanyLogoWrapperStyles} image={companyLogo} />
            )}

            <Typography component="h4" variant="heading6">
                {headingCaption},
            </Typography>
            <Typography component="p" variant="body1">
                <Trans
                    i18nKey="branded-emails.template.confirmation"
                    values={{ company_name: companyName ?? t('form.label.company-name') }}
                />
            </Typography>

            <div css={getInfoCardsWrapperStyles}>
                <InfoCard title="branded-emails.template.info-card-1">
                    <img
                        css={getCarrierLogoStyles}
                        src={`${cdnUrl}/apps/carrier-logos/${deliveryCarrierLogo}.svg`}
                        alt={`${deliveryCarrierLogo} logo`}
                    />
                    <Typography component="h4" variant="body1" bold css={getInfoCaptionStyles}>
                        {deliveryCarrierName}
                    </Typography>
                    <Next.Link href="#" css={getJourneyLinkStyles}>
                        {t('branded-emails.template.parcel-journey')}
                        <Icon size={IconSize.SIZE_SMALL} name={IconNames.CHEVRON_RIGHT} />
                    </Next.Link>
                </InfoCard>
                <InfoCard title="branded-emails.template.info-card-2">
                    <Typography component="h4" variant="body1" bold css={getInfoCaptionStyles}>
                        {t('branded-emails.template.date-day')}
                    </Typography>
                    <Typography component="h4" variant="heading1" css={getInfoNumberStyles}>
                        {deliveryDateNumber}
                    </Typography>
                    <Typography component="h4" variant="body1" bold css={getInfoCaptionStyles}>
                        {t('branded-emails.template.date-month')}
                    </Typography>
                </InfoCard>
                <InfoCard title="branded-emails.template.info-card-3">
                    <Typography component="h4" variant="body1" bold css={getInfoCaptionStyles}>
                        {deliveryAddress}
                    </Typography>
                </InfoCard>
            </div>

            <TrackingReference trackingNumber={trackingNumber} shipmentRef={shipmentRef} />

            <TemplateFooter />
        </section>
    );
}
