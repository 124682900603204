import { AmplitudeEventsPro } from '@constants/amplitude';
import { ActiveIntegrationImport } from '@Integrations/types/integration';
import { DialogActions, Typography } from '@shipengine/giger';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { ImportOrdersDeactivateButton } from '../ImportOrdersDeactivateButton';
import { getImportOrdersModaContentStyles, getImportOrdersResultImageStyles } from '../ImportOrdersModalStyles';

interface IGenericErrorContentProps {
    onClose: () => void;
    integration: ActiveIntegrationImport;
}

export const GenericErrorContent = ({ onClose, integration }: IGenericErrorContentProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            <img
                src={`${config.cdn.url}/pro/statics/animations/import-orders/goto.gif`}
                alt={''}
                css={getImportOrdersResultImageStyles}
            />
            <Typography component="p" bold css={getImportOrdersModaContentStyles}>
                <Trans i18nKey="unhautorized-import-modal.content.title" />
            </Typography>
            <Trans i18nKey="unhautorized-import-modal.content.description" />
            <DialogActions>
                <ImportOrdersDeactivateButton
                    data-id="unhautorized-disconnect-button"
                    label={t('unhautorized-import-modal.content.disconnect')}
                    event={AmplitudeEventsPro.CONFIGURATION_DISCONNECT_STORE_DIALOG}
                    onClose={onClose}
                    integration={integration}
                />
            </DialogActions>
        </>
    );
};
