import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getAppSidebarLayoutStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'relative',
        bottom: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: theme.palette.gray.megaLight,

        [theme.mediaQuery('tablet')]: {
            flexDirection: 'row',
            minWidth: theme.breakPoints.desktop,
            backgroundColor: theme.palette.white,
        },
    });

export const getContentStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flex: 1,
        overflow: 'auto',
    });
