import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { PrintingFormat } from '@packlink/packlink-sdk';

import { getSelectedShipmentReferences } from '@store/selectors/bulk';
import { BulkSelectionInfo } from '@components/SidePanel/BulkSelectionInfo';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';
import { AmplitudeEvents } from '@constants/amplitude';
import { ButtonAction, DownloadLabel } from '../DownloadLabel/DownloadLabel';
import { useDownloadLabel } from '../DownloadLabel/hooks/useDownloadLabel';
import { getWrapperStyles, messageStyles } from './BulkPrintStyles';
import { useAmplitude } from '@hooks/useAmplitude';

enum StatusType {
    ERROR = 'error',
    LOADING = 'loading',
    READY = 'ready',
}

interface IBulkPrintProps {
    numberSelectedShipments: number;
    onActionCompleted?: () => void;
    selectedLabel?: string;
}

export const BulkPrint: React.FunctionComponent<IBulkPrintProps> = ({
    numberSelectedShipments,
    onActionCompleted,
    selectedLabel,
}: IBulkPrintProps): JSX.Element => {
    // TODO: https://packlink.atlassian.net/browse/PRO-704 implement state machine
    const [status, setStatus] = useState<StatusType | null>(null);
    const { t } = useTranslation();
    const selectedShipmentReferences = useSelector(getSelectedShipmentReferences);
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const onFinish = useCallback((): void => {
        setStatus(StatusType.READY);
        onActionCompleted?.();
    }, [onActionCompleted]);

    const onError = useCallback((): void => setStatus(StatusType.ERROR), []);
    const onFetching = useCallback(
        (action: ButtonAction, eventProps: Record<string, unknown>): void => {
            const event =
                action === ButtonAction.PRINT ? AmplitudeEvents.PRINT_LABELS : AmplitudeEvents.DOWNLOAD_LABELS;
            sendAmplitudeSidebarClickEvent(event, eventProps);
            setStatus(StatusType.LOADING);
        },
        [sendAmplitudeSidebarClickEvent],
    );

    const { bulkPrintingFormat, buttonAction, fetchLabels, onChangePrintingFormat } = useDownloadLabel({
        onFetching,
        onError,
        onFinish,
    });

    const handleChangePrintingFormat = (printingFormat: PrintingFormat) => {
        const eventName = `${AmplitudeEvents.LABEL_FORMAT} ${printingFormat}` as AmplitudeEvents;
        sendAmplitudeSidebarClickEvent(eventName);
        onChangePrintingFormat(printingFormat);
    };

    return (
        <>
            {!status && (
                <section css={getWrapperStyles}>
                    <BulkSelectionInfo
                        count={numberSelectedShipments}
                        actionsTitle="bulk-panel.actions.title"
                        actionsSubtitle="bulk-panel.actions.subtitle"
                    />
                    <DownloadLabel
                        disabled={false}
                        fetchLabels={fetchLabels}
                        label={selectedLabel}
                        printingFormat={bulkPrintingFormat}
                        onChangePrintingFormat={handleChangePrintingFormat}
                        shipmentReferences={selectedShipmentReferences}
                        initiallyOpen
                        download
                    />
                </section>
            )}
            {status === StatusType.LOADING && (
                <SidePanelMessage
                    css={messageStyles}
                    title={t('bulk-print.loading.title')}
                    subtitle={t('bulk-print.loading.subtitle')}
                    type={SidePanelMessageType.LOADING}
                />
            )}
            {status === StatusType.READY && <ReadyMessage buttonAction={buttonAction} />}
            {status === StatusType.ERROR && (
                <SidePanelMessage
                    css={messageStyles}
                    title={t('bulk-print.error.title')}
                    subtitle={t('bulk-print.error.subtitle')}
                    type={SidePanelMessageType.ERROR}
                />
            )}
        </>
    );
};

const ReadyMessage = ({ buttonAction }: { buttonAction: ButtonAction | undefined }): JSX.Element => {
    const { t } = useTranslation();

    const isPrintAction = buttonAction === ButtonAction.PRINT;
    const title = t(`bulk-print.ready.${isPrintAction ? 'print-title' : 'download-title'}`);
    const subtitle = isPrintAction ? undefined : t('bulk-print.ready.download-subtitle');

    return (
        <SidePanelMessage css={messageStyles} title={title} subtitle={subtitle} type={SidePanelMessageType.SUCCESS} />
    );
};
