import React, { useCallback } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { FieldArray, FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import {
    Button,
    ButtonSize,
    ButtonVariant,
    Grid,
    GridChild,
    Icon,
    InlineNotification,
    Link,
    NotificationType,
    Typography,
} from '@shipengine/giger';
import { IParcel } from '@packlink/packlink-sdk';
import { customParcel } from '@utils/Parcel';
import { getParcelValidation, ParcelForm } from './ParcelForm';
import {
    getAddParcelButtonStyles,
    getParcelsNotificationStyles,
    getParcelsNotificationTextStyles,
} from './ParcelFormListStyles';
import { IconNames } from '@shipengine/giger-theme';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { IInfoStepForm } from '@components/Checkout/InfoStep/InfoStep';
import { useParcels } from '@common/hooks/useParcels';

interface IParcelFormListProps {
    name: string;
    showInSingleColumn?: boolean;
    showWeightDeviationDisclaimer?: boolean;
    isInSidebar?: boolean;
}

export const getParcelListValidation = (t: TFunction) => {
    return yup.array().of(getParcelValidation(t)).required();
};

export const ParcelFormList: React.FC<IParcelFormListProps> = (props: IParcelFormListProps): JSX.Element => {
    const { name, showInSingleColumn = true, isInSidebar = false, showWeightDeviationDisclaimer = false } = props;
    const { t } = useTranslation();
    const [field] = useField(name);
    const { defaultParcel } = useParcels();

    const parcelColSpan = showInSingleColumn ? 12 : 6;
    const getHelpCenterPage = useHelpCenter();
    const { values } = useFormikContext<IInfoStepForm>();

    const showUnderDeclareDisclaimer: boolean =
        !!values.parcels?.length &&
        values.parcels.some((parcel) => parcel.weight && parcel.height && parcel.length && parcel.width);

    const addParcel = useCallback(
        (arrayHelpers: FieldArrayRenderProps): (() => void) =>
            (): void =>
                arrayHelpers.push(defaultParcel?.toJSON() || { ...customParcel }),
        [defaultParcel],
    );

    const removeParcel = useCallback(
        (arrayHelpers: FieldArrayRenderProps): ((index: number) => void) =>
            (index: number): void =>
                arrayHelpers.remove(index),
        [],
    );

    const renderList = (arrayHelpers: FieldArrayRenderProps): JSX.Element => (
        <Grid noPadding>
            {showWeightDeviationDisclaimer && (
                <GridChild colSpan={parcelColSpan}>
                    <InlineNotification
                        actionElement={
                            <Link
                                variant={ButtonVariant.TEXT}
                                size={ButtonSize.SMALL}
                                href={getHelpCenterPage(HELP_CENTER_KEYS.weightDeviationPolicy)}
                                target="_blank"
                            >
                                {t('parcels.notification.weight-deviation-disclaimer-link')}
                            </Link>
                        }
                        type={NotificationType.ALERT}
                        title={t('parcels.notification.weight-deviation-disclaimer-title')}
                    >
                        {t('parcels.notification.weight-deviation-disclaimer-text')}
                    </InlineNotification>
                </GridChild>
            )}
            {field.value.map(
                (parcel: IParcel, index: number): JSX.Element => (
                    <GridChild colSpan={parcelColSpan} key={`${index}${parcel.id}`}>
                        <ParcelForm
                            name={name}
                            index={index}
                            onRemoveParcel={removeParcel(arrayHelpers)}
                            isInSidebar={isInSidebar}
                        />
                    </GridChild>
                ),
            )}

            <GridChild colSpan={parcelColSpan}>
                {showUnderDeclareDisclaimer && (
                    <div css={getParcelsNotificationStyles}>
                        <Icon name={IconNames.INFO} />

                        <Typography variant="body2" component="p" css={getParcelsNotificationTextStyles}>
                            {t('parcels.notification.under-declaration')}
                        </Typography>
                    </div>
                )}
                <Button
                    css={getAddParcelButtonStyles(showUnderDeclareDisclaimer)}
                    variant={ButtonVariant.TEXT}
                    size={ButtonSize.LARGE}
                    onClick={addParcel(arrayHelpers)}
                >
                    <Icon name={IconNames.ADD} />
                    <span>{t('form.parcel.add-parcel')}</span>
                </Button>
            </GridChild>
        </Grid>
    );

    return <FieldArray name={name} render={renderList} />;
};
