import { useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';

import { getCollapseStyles, getTableStyles, getToggleButtonStyles, getWeekdayStyles } from './TimeTableStyles';
import { TranslatedOpeningTimes } from '@utils/translateOpeningTimes';
import { Button, ButtonVariant, Typography } from '@shipengine/giger';

export type TimeTableProps = {
    openingTimes: TranslatedOpeningTimes;
};

export function TimeTable({ openingTimes }: TimeTableProps): JSX.Element {
    const [collapse, setCollapse] = useState(true);
    const { t } = useTranslation();

    const getHours = (day: (string | undefined)[]) => (day[1] !== '' ? day[1] : t('time-table.hours.weekday-closed'));

    const toggleHours = () => setCollapse((prevCollapse) => !prevCollapse);

    return (
        <section>
            <Button variant={ButtonVariant.TEXT} css={getToggleButtonStyles} onClick={toggleHours}>
                <Typography variant="small" bold>
                    {t(`time-table.button.${collapse ? 'see' : 'hide'}-full-schedule`)}
                </Typography>
            </Button>
            <div css={getCollapseStyles(collapse)}>
                <table css={getTableStyles}>
                    <tbody>
                        {(openingTimes ?? []).map((day, index) => (
                            <tr key={index}>
                                <td css={getWeekdayStyles}>{day[0]}</td>
                                <td>{getHours(day)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    );
}
