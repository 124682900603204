import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getPaymentDetailsStyles = (theme: Theme): SerializedStyles =>
    css({
        border: `1px solid ${theme.palette.gray.ultraLight}`,
    });

export const getPaymentDetailsGroupStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
        borderBottom: `1px solid ${theme.palette.gray.ultraLight}`,
        ':last-child': {
            border: 'none',
        },
    });

export const getPaymentDetailsItemStyles =
    (isTotal?: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            fontSize: theme.typography.fontSize.XS,
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(),
            ':last-child': {
                marginBottom: 0,
            },
            fontWeight: isTotal ? theme.typography.fontWeight.semibold : 'normal',
            textTransform: isTotal ? 'uppercase' : 'none',
            lineHeight: isTotal ? theme.typography.lineHeight.M : theme.typography.lineHeight.S,
        });

export const getPaymentDetailsItemIconStyles = (theme: Theme): SerializedStyles =>
    css({
        verticalAlign: 'text-bottom',
        marginLeft: theme.spacing(0.5),
        span: {
            color: theme.palette.gray.dark,
        },
    });

export const getPaymentDetailsItemLabelStyles = (): SerializedStyles =>
    css({
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginBottom: 0,
        fontWeight: 'inherit',
        lineHeight: 'inherit',
    });

export const getPaymentDetailsItemValueStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexShrink: 0,
        marginLeft: theme.spacing(),
        marginBottom: 0,
        fontWeight: 'inherit',
        lineHeight: 'inherit',
    });

const getPaymentDetailsContentStyles = (theme: Theme): SerializedStyles =>
    css({
        fontWeight: theme.typography.fontWeight.semibold,
    });

export const getNumberOfShipmentsStyles = (theme: Theme): SerializedStyles =>
    css({ ...getPaymentDetailsItemStyles() }, { ...getPaymentDetailsContentStyles(theme) });

export const getShipmentContentDescriptionStyles = (theme: Theme): SerializedStyles =>
    css({ ...getPaymentDetailsItemLabelStyles() }, { ...getPaymentDetailsContentStyles(theme) });
