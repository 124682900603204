import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getBenefitIconStyles = (theme: Theme): SerializedStyles =>
    css({
        verticalAlign: 'middle',
        marginRight: theme.spacing() / 2,
    });

export const getOwnContractIconStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.alert.main,
        marginRight: theme.spacing() / 2,
    });
