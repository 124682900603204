import { useState } from 'react';

import { IconNames } from '@shipengine/giger-theme';
import { Button, ButtonVariant, Icon, IconPopover, IconSize, Typography } from '@shipengine/giger';
import {
    getRowFooterActionStyles,
    getRowFooterActionTextStyles,
    getRowFooterActionButtonStyles,
    getIconPopoverStyles,
} from './ShipmentRowFooterActionStyles';

export interface IShipmentRowFooterActionProps {
    isLastActionInFooter?: boolean;
    disabled?: boolean;
    disabledInfo?: string;
    icon: IconNames;
    children?: React.ReactNode;
    onClick: () => void;
}

export const ShipmentRowFooterAction = ({
    isLastActionInFooter,
    disabled,
    disabledInfo,
    icon,
    children,
    onClick,
}: IShipmentRowFooterActionProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);

    const clickAction = () => {
        setIsLoading(true);
        onClick();
    };

    return (
        <div css={getRowFooterActionStyles(!!isLastActionInFooter)}>
            <Button
                onClick={clickAction}
                isLoading={isLoading}
                variant={ButtonVariant.TEXT}
                css={getRowFooterActionButtonStyles}
                disabled={disabled}
            >
                <Icon name={icon} size={IconSize.SIZE_SMALL} />
                <Typography component="span" variant="small" css={getRowFooterActionTextStyles}>
                    {children}
                </Typography>
            </Button>

            {disabled && !!disabledInfo && (
                <IconPopover css={getIconPopoverStyles} icon={IconNames.INFO} size={IconSize.SIZE_SMALL}>
                    <Typography variant="body2" component="span">
                        {disabledInfo}
                    </Typography>
                </IconPopover>
            )}
        </div>
    );
};
