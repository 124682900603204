import { Trans } from '@packlink/translation-provider';
import { Next, Typography, WithCommonProps } from '@shipengine/giger';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';

export function TermsAndConditionsLink(props: WithCommonProps<unknown>): JSX.Element {
    const getHelpCenterPage = useHelpCenter();

    return (
        <Typography variant="body2" component="p" {...props}>
            <Trans
                i18nKey="subscriptions.plan.terms-and-conditions-link"
                components={[
                    <Next.Link
                        key="link"
                        href={getHelpCenterPage(HELP_CENTER_KEYS.subscriptionsTermsAndConditions)}
                        isExternal
                        target="_blank"
                    />,
                ]}
            />
        </Typography>
    );
}
