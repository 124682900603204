import { useQuery } from 'react-query';
import { ClientFeature, OwnContractModelRepository, TenantName } from '@packlink/packlink-sdk';
import { useTenantBehavior } from '@packlink/tenant-config-provider';
import { apiClient } from '@sdk';
import { AvailablePremiumCarriersConfigProps } from '@types';
import { useSubscriptionPlanFeatures } from '@hooks/useSubscriptionPlanFeatures';
import { TenantUtils } from '@utils/tenant';
import { useTranslation } from '@packlink/translation-provider';

export enum planFeaturesCategories {
    DISCOUNTS_AND_BENEFITS = 'DISCOUNTS_AND_BENEFITS',
    FEATURES = 'FEATURES',
    ASSISTANCE = 'ASSISTANCE',
}
export enum planTypes {
    FREE = 'FREE',
    PLUS = 'PLUS',
}

export interface PlanFeature {
    key: {
        [planTypes.FREE]?: string;
        [planTypes.PLUS]?: string;
    };
    values?: { [key: string]: string | undefined };
    showDisclaimers?: boolean;
    showExcludedServices?: string;
}

export interface PlanFeaturesResponse {
    [planFeaturesCategories.DISCOUNTS_AND_BENEFITS]?: PlanFeature[];
    [planFeaturesCategories.FEATURES]?: PlanFeature[];
    [planFeaturesCategories.ASSISTANCE]?: PlanFeature[];
}

export interface ExcludedServices {
    [tenantName: string]: string[];
}

export const getExcludedServices = (): ExcludedServices => ({ [TenantName.PACKLINKPROFR]: ['UPS', 'Colissimo'] });

export const usePlanFeatures = (): PlanFeaturesResponse => {
    const {
        i18n: { language: locale },
    } = useTranslation();
    const getTenantBehavior = useTenantBehavior();
    const contractModelRepository = new OwnContractModelRepository(apiClient);
    const premiumCarriers =
        getTenantBehavior<AvailablePremiumCarriersConfigProps>('AvailablePremiumCarriers')?.properties.carriers ?? [];

    const showPremiumCarriers = premiumCarriers?.length > 0;
    const showExclusivePricesCopy = getTenantBehavior<{ enabled: boolean }>('ShowExclusivePrices')?.properties.enabled;
    const cashOnDeliveryServices = getTenantBehavior<{ enabled: boolean }>('CashOnDeliveryServices')?.properties
        .enabled;
    const { availableFeatures } = useSubscriptionPlanFeatures();
    const excludedServices = getExcludedServices()[TenantUtils.getTenantName()];
    const isOCFeatureAvailable = availableFeatures?.[ClientFeature.OWN_CONTRACT];
    const isBrandedEmailsAvailable = availableFeatures?.[ClientFeature.BRANDED_EMAILS];
    const isVipSupportAvailable = availableFeatures?.[ClientFeature.VIP_SUPPORT];
    const hasNoManagementFee = availableFeatures?.[ClientFeature.SKIP_MANAGEMENT_FEE];

    const { data: carrierNames } = useQuery(
        'carrier-contract-names',
        () =>
            contractModelRepository
                .getModels()
                .then((contractModels) =>
                    new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' }).format(
                        contractModels.map(({ carrierName }) => carrierName),
                    ),
                ),
        { enabled: isOCFeatureAvailable },
    );
    return {
        [planFeaturesCategories.DISCOUNTS_AND_BENEFITS]:
            showExclusivePricesCopy || showPremiumCarriers || cashOnDeliveryServices || hasNoManagementFee
                ? [
                      {
                          key: {
                              [planTypes.PLUS]: showExclusivePricesCopy
                                  ? 'subscriptions.feature-list.exclusive-prices'
                                  : undefined,
                              [planTypes.FREE]: showExclusivePricesCopy
                                  ? 'free-plan.feature-list.discounted-rates'
                                  : undefined,
                          },
                          showExcludedServices: excludedServices
                              ? `subscription.feature-list.excluded-services`
                              : undefined,
                          values: {
                              services: excludedServices?.join(', '),
                          },
                      },
                      {
                          key: {
                              [planTypes.PLUS]: cashOnDeliveryServices
                                  ? 'subscriptions.feature-list.cash-on-delivery-services'
                                  : undefined,
                          },
                      },
                      {
                          key: {
                              [planTypes.PLUS]: showPremiumCarriers
                                  ? 'subscriptions.feature-list.premium-carriers'
                                  : undefined,
                          },
                          values: {
                              carriers: premiumCarriers?.join(', '),
                          },
                      },
                      {
                          key: {
                              [planTypes.PLUS]: hasNoManagementFee ? 'subscriptions.feature-list.no-fee' : undefined,
                          },
                      },
                  ]
                : undefined,
        [planFeaturesCategories.FEATURES]: [
            {
                key: {
                    [planTypes.PLUS]: 'subscriptions.feature-list.unlimited-integrations',
                    [planTypes.FREE]: 'free-plan.feature-list.integrations',
                },
            },
            {
                key: {
                    [planTypes.PLUS]: 'subscriptions.feature-list.unlimited-shipping-rules',
                    [planTypes.FREE]: 'free-plan.feature-list.shipping-rules',
                },
            },
            {
                key: {
                    [planTypes.PLUS]: isBrandedEmailsAvailable
                        ? 'subscriptions.feature-list.branded-emails'
                        : undefined,
                },
            },
            {
                key: {
                    [planTypes.PLUS]: isOCFeatureAvailable ? 'subscriptions.feature-list.own-carrier' : undefined,
                },
                values: { carriers: carrierNames },
                showDisclaimers: isOCFeatureAvailable,
            },
            {
                key: {
                    [planTypes.PLUS]: 'subscriptions.feature-list.analytics',
                },
            },
        ],
        [planFeaturesCategories.ASSISTANCE]: [
            {
                key: {
                    [planTypes.PLUS]: 'subscriptions.feature-list.chat-and-tickets',
                    [planTypes.FREE]: 'subscriptions.feature-list.chat-and-tickets',
                },
            },
            {
                key: {
                    [planTypes.PLUS]: 'subscriptions.feature-list.priority-claim',
                },
            },
            {
                key: {
                    [planTypes.PLUS]: isVipSupportAvailable ? 'subscriptions.feature-list.phone-support' : undefined,
                },
            },
            {
                key: {
                    [planTypes.PLUS]: 'subscriptions.feature-list.dedicated-agent-setup',
                },
            },
        ],
    };
};
