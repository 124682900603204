import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getPaymentInfoStyles = (theme: Theme): SerializedStyles =>
    css({
        width: '369px',
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
        borderRadius: theme.borderRadius.M,
        backgroundColor: theme.palette.white,
        ...theme.getElevation('popout2'),
    });

export const getPaymentInfoRowStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        ':last-child': {
            p: {
                marginBottom: 0,
            },
        },
    });

export const getPaymentInfoTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(),
    });

export const getPaymentInfoCreditCardStyles = (): SerializedStyles => css({ marginBottom: 0 });

export const getPaymentPaypalEmailStyles = (): SerializedStyles =>
    css({ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '60%' });
