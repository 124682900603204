import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getNewUserStateSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        marginTop: theme.spacing(2),
        padding: `${theme.spacing(3)}px 0`,
        overflow: 'auto',
        textAlign: 'center',

        [theme.mediaQuery('tabletLarge')]: {
            justifyContent: 'center',
            paddingLeft: theme.spacing(3),
        },
    });

export const getNewUserOptionStyles = (theme: Theme): SerializedStyles =>
    css({
        ...theme.getCardStyle(),
        boxShadow: theme.elevations.above.boxShadow,
        zIndex: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 205,
        padding: `${theme.spacing(3)}px ${theme.spacing()}px`,
        color: theme.palette.gray.dark,
        border: `1px solid ${theme.palette.gray.light}`,
        borderRadius: theme.borderRadius.L,

        [theme.mediaQuery('mobileLarge')]: {
            maxWidth: 246,
        },

        [theme.mediaQuery('tablet')]: {
            width: 361,
            maxWidth: 'none !important',
            minHeight: 444,
            padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
        },

        ':first-of-type': {
            marginRight: theme.spacing(),

            [theme.mediaQuery('mobileLarge')]: {
                marginRight: theme.spacing(6),
            },
        },
    });

export const getNewUserFooterStyles = (theme: Theme): SerializedStyles =>
    css({
        width: '100%',
        padding: `0 ${theme.spacing(2)}px`,

        [theme.mediaQuery('tabletLarge')]: {
            padding: `0 ${theme.spacing(6)}px`,
        },
    });

export const getNewUserImageStyles = (theme: Theme): SerializedStyles =>
    css({
        maxHeight: 130,

        [theme.mediaQuery('tablet')]: {
            maxHeight: 160,
        },
    });

export const getNewUserTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing(3)}px auto`,
        fontWeight: theme.typography.fontWeight.semibold,
    });

export const getNewUserListStyles = (theme: Theme): SerializedStyles =>
    css({
        height: '100%',
        margin: `0 auto ${theme.spacing(3)}px auto`,
        padding: 0,
        listStyle: 'none',
    });
