import { useState } from 'react';

import { Dialog } from '@shipengine/giger';

import { PrivacyWithdrawalModalLastWarning } from './PrivacyWithdrawalModalLastWarning';
import { PrivacyWithdrawalModalFirstWarning } from './PrivacyWithdrawalModalFirstWarning';
import { getDialogStyles } from './PrivacyWithdrawalModalStyles';

interface IPrivacyWithdrawalModalProps {
    closeModalHandler: () => void;
}

export const PrivacyWithdrawalModal = ({ closeModalHandler }: IPrivacyWithdrawalModalProps): JSX.Element => {
    const [showLastWarning, setShowLastWarning] = useState(false);

    return (
        <Dialog css={getDialogStyles} isOpen={true}>
            {!showLastWarning && (
                <PrivacyWithdrawalModalFirstWarning
                    acceptWarningHandler={() => setShowLastWarning(true)}
                    closeModalHandler={closeModalHandler}
                />
            )}

            {showLastWarning && <PrivacyWithdrawalModalLastWarning closeModalHandler={closeModalHandler} />}
        </Dialog>
    );
};
