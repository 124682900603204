// Client
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';

// Status actions
export const SET_STATUS = 'SET_STATUS';

// Bulk actions
export const ADD_SELECTED_SHIPMENT = 'ADD_SELECTED_SHIPMENT';
export const ADD_SELECTED_SHIPMENTS = 'ADD_SELECTED_SHIPMENTS';
export const ADD_SHIPMENT = 'ADD_SHIPMENT';
export const CLEAN_SELECTED_SHIPMENTS = 'CLEAN_SELECTED_SHIPMENTS';
export const CLEAN_SHIPMENTS = 'CLEAN_SHIPMENTS';
export const REMOVE_SELECTED_SHIPMENT = 'REMOVE_SELECTED_SHIPMENT';
export const REMOVE_SELECTED_SHIPMENTS = 'REMOVE_SELECTED_SHIPMENTS';
export const REMOVE_SHIPMENT = 'REMOVE_SHIPMENT';
export const SELECT_ALL_SHIPMENTS = 'SELECT_ALL_SHIPMENTS';
export const SET_SELECTED_SHIPMENTS = 'SET_SELECTED_SHIPMENTS';
export const SET_SHIPMENTS = 'SET_SHIPMENTS';
export const SET_SHIPMENTS_TO_PAY = 'SET_SHIPMENTS_TO_PAY';
export const REMOVE_SHIPMENTS_TO_PAY = 'REMOVE_SHIPMENTS_TO_PAY';
export const UPDATE_SHIPMENT = 'UPDATE_SHIPMENT';
export const SET_BULK_PAGINATION = 'SET_BULK_PAGINATION';
export const SET_BULK_SORT_BY = 'SET_BULK_SORT_BY';
export const SET_BULK_FILTERS = 'SET_BULK_FILTERS';
export const SET_BULK_VOUCHER = 'SET_BULK_VOUCHER';
export const ADD_SHIPMENT_INBOX_NOTIFICATION = 'ADD_SHIPMENT_INBOX_NOTIFICATION';
export const DELETE_SHIPMENT_INBOX_NOTIFICATION = 'DELETE_SHIPMENT_INBOX_NOTIFICATION';

// Inbox actions
export const SET_CURRENT_INBOX = 'SET_CURRENT_INBOX';
export const SET_INBOXES = 'SET_INBOXES';

// Parcel actions
export const ADD_PARCEL = 'ADD_PARCEL';
export const SET_PARCELS = 'SET_PARCELS';
export const UPDATE_PARCEL = 'UPDATE_PARCEL';
export const DELETE_PARCEL = 'DELETE_PARCEL';
export const SET_PARCEL_DEFAULT = 'SET_PARCEL_DEFAULT';

// User actions
export const SET_USER_DATA = 'SET_USER_DATA';

// Payment actions
export const SET_AVAILABLE_PAYMENT_METHODS = 'SET_AVAILABLE_PAYMENT_METHODS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';
export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const SET_LOCAL_PAYMENT_FALLBACK = 'SET_LOCAL_PAYMENT_FALLBACK';
export const CLEAN_LOCAL_PAYMENT_FALLBACK = 'CLEAN_LOCAL_PAYMENT_FALLBACK';

// Side panel actions
export const SET_ACTIVE_PANEL = 'SET_ACTIVE_PANEL';
export const CLEAR_ACTIVE_PANEL = 'CLEAR_ACTIVE_PANEL';

// Checkout actions
export const RESET_CHECKOUT = 'RESET_CHECKOUT';
export const SET_CHECKOUT_PARCELS = 'SET_CHECKOUT_PARCELS';
export const SET_CHECKOUT_ADDRESS_DETAILS = 'SET_CHECKOUT_ADDRESS_DETAILS';
export const SET_CHECKOUT_ADDRESS_COUNTRY = 'SET_CHECKOUT_ADDRESS_COUNTRY';
export const SET_CHECKOUT_ADDRESS_POSTALCODE = 'SET_CHECKOUT_ADDRESS_POSTALCODE';
export const SET_CHECKOUT_WAREHOUSE_ADDRESS = 'SET_CHECKOUT_WAREHOUSE_ADDRESS';
export const SET_CHECKOUT_SELECTED_WAREHOUSE = 'SET_CHECKOUT_SELECTED_WAREHOUSE';
export const SET_CHECKOUT_ADDRESS = 'SET_CHECKOUT_ADDRESS';
export const SET_CHECKOUT_ADDITIONAL_DATA = 'SET_CHECKOUT_ADDITIONAL_DATA';
export const SET_CHECKOUT_THIRD_PARTY_PREFERENCES = 'SET_CHECKOUT_THIRD_PARTY_PREFERENCES';
export const RESET_CHECKOUT_SERVICE = 'RESET_CHECKOUT_SERVICE';
export const SET_CHECKOUT_SERVICE = 'SET_CHECKOUT_SERVICE';
export const SET_CHECKOUT_DROPOFF_POINT_ID = 'SET_CHECKOUT_DROPOFF_POINT_ID';
export const SET_CHECKOUT_COLLECTION_DATE = 'SET_CHECKOUT_COLLECTION_DATE';
export const SET_CHECKOUT_COLLECTION_TIME = 'SET_CHECKOUT_COLLECTION_TIME';
export const SET_CHECKOUT_PRICE_DETAILS = 'SET_CHECKOUT_PRICE_DETAILS';
export const SET_CHECKOUT_INSURANCE_TYPE = 'SET_CHECKOUT_INSURANCE_TYPE';
export const SET_CHECKOUT_ADULT_SIGNATURE_VALUE = 'SET_CHECKOUT_ADULT_SIGNATURE_VALUE';
export const SET_CHECKOUT_PROOF_OF_DELIVERY = 'SET_CHECKOUT_PROOF_OF_DELIVERY';
export const SET_CHECKOUT_PRINT_IN_STORE = 'SET_CHECKOUT_PRINT_IN_STORE';
export const SET_CHECKOUT_ADDITIONAL_HANDLING_VALUE = 'SET_CHECKOUT_ADDITIONAL_HANDLING_VALUE';
export const SET_CHECKOUT_VOUCHER = 'SET_CHECKOUT_VOUCHER';
export const SET_CHECKOUT_BUSY = 'SET_CHECKOUT_BUSY';
export const SET_CHECKOUT_CONTENT = 'SET_CHECKOUT_CONTENT';
export const SET_CHECKOUT_SELECTED_SHIPMENT = 'SET_CHECKOUT_SELECTED_SHIPMENT';
export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const SET_ADDRESS_BOOK_CHECKED = 'SET_ADDRESS_BOOK_CHECKED';
export const SET_INVOICE_INFO_SAVED = 'SET_INVOICE_INFO_SAVED';
export const SET_CHECKOUT_CASH_ON_DELIVERY = 'SET_CHECKOUT_CASH_ON_DELIVERY';
