import { AmplitudeEventsBulkCustoms } from '@Customs/metrics/amplitude';
import {
    AmplitudeEvents as AmplitudeEventsCommon,
    AmplitudeProperties as AmplitudePropertiesCommon,
} from '@packlink/metrics';
import { Inbox, ShipmentsOrderBy } from '@packlink/packlink-sdk';

export enum AmplitudeEventsPro {
    ACCOUNT = 'Account',
    ADD_SHIPMENT = 'Add Shipment',
    ADD_PARCEL = 'Add Parcel',
    ADDRESS_BOOK_LINK = 'Address Book Link',
    ADDRESS_BOOK_SELECTION = 'Selected an address from Address Book',
    ADDRESS_BOOK_SELECTOR = 'address book selector',
    ADDRESS_ONBOARDING = 'Set Address Book from Onboarding',
    ADDRESS_TAB = 'addresses tab',
    ADDRESS_TOUR_ONBOARDING_INTEGRATION = 'Set Address Book from Tour Onboarding Integration',
    ALL_SHIPMENTS = 'All Shipments',
    AMAZON_MARKETPLACE = 'Amazon Marketplace',
    AMAZON_MARKETPLACE_NO_COUNTRY = 'No country',
    ANALYTICS = 'analytics',
    ANALYTICS_DATA_FILTER = 'analytics data filter',
    APPLY_FILTERS = 'apply filters',
    APP_KEY_DISCONNECT_STORE_DIALOG = 'app key error dialog Disconnect store',
    ARCHIVED = 'Archived',
    ARCHIVING = 'Archiving',
    AUTOMATIC_ARCHIVE = 'Activate automatic archive',
    BACK = 'Back',
    BILLING = 'Billing',
    BRANDED_EMAILS = 'branded emails',
    BRANDED_EMAIL_EDIT = 'edit branded email',
    BRANDED_EMAIL_TOGGLE = 'branded email toggle',
    BRANDED_EMAIL_POST_SALE_PAGE = 'branded email in the post sale page',
    BULK = 'Bulk',
    CALENDAR_COLLECTION_DATE = 'calendar collection date',
    CANCEL = 'Cancel',
    CANCEL_INTEGRATION_SIDE_PANEL = 'Cancel Side Panel',
    CANCEL_PARCEL = 'Cancel Parcel',
    CANCELED = 'Canceled',
    CARRIER_CONTRACT_CONNECT = 'Carrier Contract Connect',
    CARRIER_CONTRACTS = 'Carrier Contracts',
    CHECK_CARRIERS_POLICIES = 'check and accept the Terms and Conditions of the transport agency',
    UNCHECK_CARRIERS_POLICIES = 'uncheck the Terms and Conditions of the transport agency',
    CLOSE_INTEGRATION_IMPORT_ORDER_NOTIFICATION = 'Closed Integration Import order notification',
    CLOSE_SHIPMENT_FLOW = 'Close Shipment Flow',
    COMPLETE_SHIPMENT = 'Complete Shipment',
    CONFIGURATION = 'Configuration',
    CONFIGURATION_CONNECT_STORE = 'Configuration Connect Store',
    CONFIGURATION_DISCONNECT_STORE = 'Configuration Disconnect Store',
    CONFIGURATION_DISCONNECT_STORE_DIALOG = 'dialog Disconnect store',
    CONFIGURATION_INTEGRATION = 'Configuration Integration',
    CONFIGURATION_SAVE_STORE = 'Configuration Save Store',
    CONNECT_INTEGRATION_SIDE_PANEL = 'Connect Side Panel',
    CONNECT_SERVICE = 'Connect Service',
    CONNECT_STORE = 'Connect your store',
    CONTACT_SUPPORT = 'Contact Support',
    CONTRACT = 'Contract',
    CREATE_TICKET_VALIDATION = 'on SMS validation Create Ticket',
    CREATE_SHIPMENT = 'Create Shipment',
    CREATE_SHIPPING_RULE = 'create shipping rule',
    CSV_IMPORT_KO = 'ko csv import',
    CSV_IMPORT_SELECTION = 'choose csv file to import',
    CSV_IMPORT_SUCCESS = 'ok csv import',
    DELETE = 'Delete',
    DELETE_FILTERS = 'delete filters',
    DELETE_SHIPMENT = 'Delete Shipment',
    DELIVERED = 'Delivered',
    DESELECT_SHIPMENT = 'Deselect Shipment',
    DOWNLOAD_INVOICES_IN_BULK = 'download invoices in bulk',
    DOWNLOAD_INVOICES_IN_BULK_FAILED = 'download invoices in bulk failed',
    DOWNLOAD_INVOICES_IN_BULK_SUCCESSS = 'download invoices in bulk success',
    DOWNLOAD_LABEL = 'Download Label',
    DOWNLOAD_LABELS = 'Download Labels',
    DRAFT = 'Draft',
    DUPLICATE_SHIPMENT = 'duplicate shipment',
    EDIT = 'Edit',
    EDIT_COLLECTION_DATE = 'edit collection date',
    EDIT_CONTENT = 'edit content',
    EDIT_FILTERS = 'edit filters',
    EDIT_PARCEL = 'edit parcel',
    EDIT_SERVICES = 'edit services',
    ERROR_PAGE_CLOSE = 'on close error page',
    ERROR_PAGE_CREATE_TICKET = 'on create ticket from error page',
    EXIT_BUTTON = 'Exit Button',
    EXPORT_FILE = 'export file',
    FILTER_LIST = 'Service List Filter',
    FILTERS = 'filters',
    GENERATING_LABELS = 'Generating Labels',
    GET_LABEL = 'Get Label',
    ID_TRACKING = 'id tracking',
    ID_TRACKING_COPY = 'id tracking copy',
    IMPORT_CSV = 'Import CSV',
    IN_TRANSIT = 'In Transit',
    INCIDENT = 'Incident',
    INSURE_SHIPMENT = 'insure shipment',
    INTEGRATION_AUTO_IMPORT_MODAL = 'integration auto import modal',
    INTEGRATION_IMPORT_MODAL = 'integration import Modal',
    INTEGRATION_DISABLED = 'Integration disabled Dialog',
    INTEGRATION_DISABLED_GO_TO_INTEGRATIONS = 'Integration disabled go to integration list',
    INTEGRATION_TABLE_VIEW = 'Integrate for your online shop table view',
    INTEGRATIONS = 'Integrations',
    LABEL_FORMAT = 'Label Format',
    LANGUAGE_SELECTOR_SETTINGS = 'Laguage Selector in Settings',
    MODIFY_PARCEL = 'Modify Parcel',
    MODIFY_SERVICE = 'Modify Service',
    MY_SHIPPING_RULES = 'my shipping rules',
    NO_RESULTS = 'filter no results',
    NOTIFY_ME = 'click on notify me',
    OC_REGISTER_SUCCESS = 'oc register success',
    ONBOARDING_CLOSE = 'close tour component',
    ONBOARDING_CLOSE_OUTSIDE = 'outside grey tour component to close',
    ONBOARDING_CONNECT_GO_CONNECT = 'connect your shop NOW',
    ONBOARDING_CONNECT_GO_CONNECT_BUTTON = 'connect your shop NOW Button',
    ONBOARDING_CONNECT_GO_PACKLINK = 'connect your shop how PRO can help you',
    ONBOARDING_GO_HOW_TO_CONNECT = 'packlink tour how to connect your shop',
    ONBOARDING_CTA_SET_UP_ADDRESS = 'CTA Set up Address',
    ONBOARDING_CTA_SET_UP_PARCEL = 'CTA Set up parcel',
    ONBOARDING_PACKLINK_GO_CONNECT_BUTTON = 'connect you shop NOW Packlink Tour Button',
    ONBOARDING_SIDE_PANEL_EXPERIENCE = 'onboarding sidepanel experience',
    ONBOARDING_SLIDE_CONNECT = 'connect your shop slide',
    ONBOARDING_SLIDE_PACKLINK = 'packlink tour slide',
    ONBOARDING_SLIDE_TOUR_ONBOARDING_INTEGRATION = 'Slide tour onboarding integration',
    ONBOARDING_SLIDE_WHATS_NEXT = 'slide whats next tour',
    ONBOARDING_SLIDE_WELCOME = 'slide welcome to packlink pro',
    ONBOARDING_WHATS_NEXT_GO_PARCEL = 'CTA go to parcel',
    ONBOARDING_WELCOME_CONNECT = 'welcome click connect your store',
    ONBOARDING_WELCOME_PACKLINK = 'welcome click packlink tour',
    ONBOARDING_WELCOME_TOUR_ONBOARDING_INTEGRATION = 'welcome tour onboarding integration',
    ONBOARDING_PAGE_FREE_PLAN = 'on free plan in onboarding',
    ONBOARDING_PAGE_BASIC_PLAN = 'on basic plan in onboarding',
    ONBOARDING_PAGE_PLUS_PLAN = 'on plus plan in onboarding',
    ONBOARDING_WELCOME_SHIPMENT = 'on "Send my first shipment" from onboarding',
    ONBOARDING_WELCOME_STORE = 'on "Connect my store" from onboarding',
    ONBOARDING_WELCOME_HOME = 'on "Go to the Packlink PRO homepage" from onboarding',
    ONBOARDING_WELCOME_CONFIGURE = 'on "Finish configuring my account" from onboarding',
    ONBOARDING_WELCOME_SKIP = 'on "Skip" from onboarding',
    OTHER_BENEFITS_PANEL = 'Other Benefits Panel',
    OUT_FOR_DELIVERY = 'Out for delivery',
    OUT_OF_SIDEBAR_TO_EXIT = 'Out of Sidebar to Exit',
    PARCELS = 'Parcels',
    PARCELS_ONBOARDING = 'Set Parcels from Onboarding',
    PARCELS_TOUR_ONBOARDING_INTEGRATION = 'Set Parcels from Tour Onboarding Integration',
    PAY = 'Pay',
    PAY_ALL = 'Pay All',
    PAY_FROM_DRAFT = 'go to pay from draft',
    PAYMENT_METHOD_DELETE = 'payment method delete confirmation',
    PENDING_STATUS = 'Pending Status',
    PRE_SET_ADDRESSES = 'Pre-set Addresses',
    PRINT_LABEL = 'Print Label',
    PRINT_LABELS = 'Print Labels',
    PRIVACY = 'Privacy',
    READY_FOR_SHIPPING = 'Ready for Shipping',
    READY_TO_PAY = 'Ready To Pay',
    REMINDER_CONNECT_YOUR_SHOP = 'Display reminder connect your shop',
    RESEND_CODE = 'on SMS resend button',
    RETURN_LABEL = 'return label',
    RETURNED_TO_SENDER = 'Returned to sender',
    SAVE_ADDRESS_BOOK = 'Save Address Book',
    SAVE_BYOA_SERVICES = 'on Save BYOA services',
    SAVE_BUTTON = 'Save Button',
    SAVE_EDITED_PARCEL = 'Save Edited Parcel',
    SAVE_PARCEL = 'Save Parcel',
    SAVE_SHIPMENT_FLOW = 'Save Shipment Flow',
    SEARCH_BAR = 'search bar',
    SEE_DETAILS = 'See Details',
    SEE_DROPOFF_MAP = 'See Dropoff Map',
    SEE_TRACKING = 'See Tracking',
    SELECT_SHIPMENT = 'Select Shipment',
    SELECT_SERVICE = 'on Select Service now',
    SEND_BY_EMAIL = 'Send By Email',
    SEND_CODE = 'on Send Code',
    SETTINGS_ADD_ADDRESS_CLICK = 'settings click on add pre-set address',
    SETTINGS_SUBSCRIPTION_BLOCKED = '"blocked" label',
    SETTINGS_TOOLTIP_OWN_CONTRACTS = 'Link from locked tooltip-Own contract',
    SETTINGS_TOOLTIP_BRANDED_EMAIL = 'Link from locked tooltip-Branded email',
    SETTINGS_TOOLTIP_USER_ANALYTICS = 'Link from locked tooltip-User analytics page',
    SETTINGS_CANCEL_ADDRESS_CLICK = 'settings click on cancel pre-set address',
    SETTINGS_EDIT_ADDRESS_CLICK = 'settings click on edit address',
    SETTINGS_SAVE_ADDRESS_CLICK = 'settings click on save pre-set address',
    SHIPPING_RULES = 'shipping rules',
    SHIPPING_RULES_HOW_IT_WORKS_TAB = 'click how shipping rules work',
    SHIPPING_RULES_NEW_RULE = 'add new shipping rules',
    SHIPPING_RULES_NEW_RULE_EMPTY_STATE = 'click add new shipping rule in empty state',
    SHIPPING_RULES_VIDEO = 'video shipping rules visualization',
    SHIPPING_RULE_ADDED_KO = 'shipping rule added KO',
    SHIPPING_RULE_ADDED_SUCCESS = 'shipping rule added success',
    SHIPPING_RULE_EDITED_SUCCESS = 'shipping rule edited success',
    SHIPPING_RULE_DELETE = 'delete shipping rule',
    SHIPPING_RULE_EDIT = 'edit shipping rule',
    SHIP_DIFFERENT_ADDRESS = 'ship to a different address',
    SIDEBAR_EDIT_COLLECTION_DATE_SUCCESS = 'sidebar edit collection date success',
    SIDEBAR_EDIT_CONTENT_SUCCESS = 'sidebar edit content success',
    SIDEBAR_EDIT_PARCELS_SUCCESS = 'sidebar edit parcels success',
    SIDEBAR_EDIT_SERVICE_ERROR = 'sidebar edit services error',
    SIDEBAR_EDIT_SERVICE_SUCCESS = 'sidebar edit services success',
    SIDEBAR_INSURE_SHIPMENTS_BULK = 'sidebar insure shipments in bulk',
    SORTING_IN_SERVICE = 'sorting in service',
    SORTING_IN_SERVICE_TYPE = 'sorting in service type',
    START_OF_SESSION = 'start of session',
    SUBSCRIPTION_ACTIVATE = 'activate your plan',
    SUBSCRIPTION_CANCEL = 'cancel my subscription',
    SUBSCRIPTION_CANCEL_CONFIRM = 'confirm the cancellation of my subscription',
    SUBSCRIPTION_CANCELLATION_CONFIRMED = 'subscription cancellation confirmed',
    SUBSCRIPTION_GET_STARTED_NOW = 'get started now in subscription plan',
    SUBSCRIPTION_SAVE_INVOICING_INFO = 'sidebar save subscription invoicing information',
    SUBSCRIPTION_EDIT_INVOICING_INFO = 'sidebar subscription invoicing information edit',
    SUBSCRIPTION_PLAN = 'Subscription plan',
    SUBSCRIPTION_PLAN_ACTIVATED_SUCCESS = 'subscription plan activated success',
    SUBSCRIPTION_UPGRADE_COMPLETED = 'Subscription upgrade completed',
    SUBSCRIPTION_UPGRADE_NOW = '"upgrade now" for subscribe from table view sidebar',
    SUBSCRIPTION_PAYMENT_METHOD_EDIT = 'on edit subscription payment method',
    SUBSCRIPTION_TABLE_VIEW_BANNER = 'on table view subscription banner',
    SUBSCRIPTION_POSTSALE_BANNER = 'on postsale subscription banner',
    PAYMENT_METHOD_EDIT = 'on edit payment method',
    SUSPENDED_ACCOUNT_IMPORT_ORDERS = 'redirect suspended account import',
    TABLE_INVOICES_CLICK_ON_BULK = 'table invoices click on bulk',
    TOUR_ONBOARDING_INTEGRATION_CTA_SET_UP_PARCEL = 'CTA Set up parcel',
    TOUR_ONBOARDING_INTEGRATION_CTA_SET_UP_ADDRESS = 'CTA Set up address',
    TOUR_ONBOARDING_INTEGRATION_GO_TO_SIDEPANEL = 'go to sidepanel Tour Onboarding Integration',
    TOUR_ONBOARDING_INTEGRATION_START_FLOW = 'start flow Tour Onboarding Integration',
    TRACKING_DETAILS_CONTACT = 'tracking details contact support',
    TRACKING_INBOX = 'Tracking',
    TRASH_ICON = 'Trash icon',
    TWILIO_CODE_SENT = 'SMS code sent',
    UPDATE_ADDRESS_BOOK = 'Update Address Book',
    VIDEO_TUTORIAL = 'video tutorial',
    VOLUME_DISCOUNT = 'Volume Discount',
    WHATS_NEXT = 'Display Whats next tour',
    X = 'X',
    DETAILS_PAGE = 'Details page',
    ACTIVATE_BYOA = 'Click on Activate BYOA',
    LOGIN = 'Login',
    REGISTER = 'Register',
    REGISTER_FOOTER = 'Register (Footer)',
    EMAIL = 'Email',
    PASSWORD = 'Password',
    SHIPMENTS = 'How many shipments',
    ECOMMERCES = 'Sell products from',
    MARKETPLACE = 'Online store',
    PHONEPREFIX = 'Prefix country',
    PHONENUMBER = 'Phone number',
    MARKETING = 'Authorise Commercial Comms',
    TERMS = 'Accept the Terms of Service',
    ADDITIONAL_HANDLING = 'additional handling',
    ADULT_SIGNATURE = 'adult signature',
    CASH_ON_DELIVERY = 'cash on delivery',
    PROOF_OF_DELIVERY = 'proof of delivery',
    PRINT_IN_STORE = 'print in store',
    CALL_AGENT_HELP_CENTER = 'on call an agent in the help center',
    CLICK_GATED_SERVICES = 'on gated service cta in the checkout',
    SUPPORT_PAGE_MONTHLY_INVOICES = 'support page for monthly invoices',
    PAYMENT_METHOD_UPDATED = 'Payment method updated',
    VERIFICATION_STARTED = 'SMS Verification started',
    VERIFICATION_SUCCESS = 'SMS Verification success',
    VERIFICATION_FAILED = 'SMS Verification failed',
}

export enum AmplitudePropertiesPro {
    ACTIVE = 'active',
    ADDITIONAL_SERVICE = 'Additional service',
    ADDRESS_BOOK_CHECKED = 'Address book checked',
    ADDRESS_BOOK_USED = 'Selected an address from Address Book',
    BULK = 'Bulk',
    CARRIER = 'Carrier',
    COD = 'cash on delivery',
    COD_COUNT = 'cash on delivery count',
    CURRENT_LANGUAGE = 'Current language',
    CUSTOM_DATA_RANGES = 'custom data ranges',
    CSV_IMPORT_SHIPMENTS_COUNT = 'CSV shipments import count',
    DURATION = 'Duration',
    FILTER = 'Filter',
    FILTER_TYPE = 'Filter Type',
    FROM_STEP = 'From step',
    FREQUENCY = 'frequency',
    INBOX = 'inbox',
    INSURANCE = 'insurance',
    INTEGRATION_SOURCE = 'Integration (Source)',
    MORE_INFO = 'More info',
    NUMBER_OF_INVOICES = 'number of invoices',
    NUMBER_OF_SHIPMENTS_EDITED = 'number of shipments edited',
    LABEL_FORMAT = 'label format',
    LABELS_COUNT = 'labels count',
    OWN_CONTRACT = 'Own contract',
    OVERWRITE = 'overwrite',
    PANEL_NAME = 'Panel name',
    PAYMENT_METHOD = 'Payment method',
    PAYMENT_TYPE = 'Payment type',
    PLAN = 'Plan',
    QUANTITY_INSURED = 'quantity insured',
    SEARCH_QUERY = 'search query',
    SECTION = 'section',
    SELECTED = 'Selected',
    SELECTED_LANGUAGE = 'Selected language',
    SERVICES_NAMES = 'Service name',
    SHIPMENT_ID = 'Shipment ID',
    SHIPMENTS_COUNT = 'shipments count',
    SHIPPING_RULES_CONDITION = 'condition',
    SHIPPING_RULES_ACTION = 'action',
    STEP = 'Step',
    TAB_TYPE = 'Tab type',
    TYPE = 'Type',
    TYPE_OF_SORTING = 'type of sorting',
    TYPE_OF_BILLING = 'type of billing',
    URL = 'url',
    URL_PATH = 'url path',
    FLOW = 'flow',
    SHIPMENTS = 'Nº of shipments',
    ECOMMERCES = 'Sell products from',
    MARKETPLACE = 'Online store',
}

export type AmplitudeEvents = AmplitudeEventsCommon | AmplitudeEventsPro | AmplitudeEventsBulkCustoms;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AmplitudeEvents = { ...AmplitudeEventsCommon, ...AmplitudeEventsPro };

export type AmplitudeProperties = AmplitudePropertiesCommon | AmplitudePropertiesPro;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AmplitudeProperties = { ...AmplitudePropertiesCommon, ...AmplitudePropertiesPro };

export enum AmplitudeEventPrefixes {
    ANALYTICS = 'analytics',
    CLICK = 'Click',
    OPEN = 'Open',
    CONFIRMATION_PAGE = 'in Confirmation Page',
    EMPTY_STATE = 'From Empty State',
    PAYMENT_SUMMARY = 'payment summary',
    SEARCH_BAR = 'From Search Bar',
    SIDEBAR = 'SideBar',
    SIDEBAR_CLICK = 'SideBar Click on',
    SIDEPANEL = 'side panel',
    TABLE_VIEW = 'Table View',
    TABLE_VIEW_CLICK = 'Table View Click on',
    HEADER_CLICK = 'header click on',
    CONFIGURATION_MENU = 'From Configuration Menu',
    CHECKOUT_CLICK = 'checkout click',
    ENTER = 'Enter a',
    CHOOSE = 'Choose',
    LOAD = 'Page load',
}

export enum AmplitudeEventSuffixes {
    SUCCESS = 'success',
    FAILED = 'failed',
}

export const AMPLITUDE_EVENTS_BY_INBOX = {
    [Inbox.ALL]: AmplitudeEvents.ALL_SHIPMENTS,
    [Inbox.PENDING]: AmplitudeEvents.PENDING_STATUS,
    [Inbox.PROCESSING]: AmplitudeEvents.GENERATING_LABELS,
    [Inbox.READY_FOR_SHIPPING]: AmplitudeEvents.READY_FOR_SHIPPING,
    [Inbox.READY_TO_PURCHASE]: AmplitudeEvents.READY_TO_PAY,
    [Inbox.DRAFT]: AmplitudeEvents.DRAFT,
    [Inbox.TRACKING]: AmplitudeEvents.TRACKING_INBOX,
    [Inbox.IN_TRANSIT]: AmplitudeEvents.IN_TRANSIT,
    [Inbox.OUT_FOR_DELIVERY]: AmplitudeEvents.OUT_FOR_DELIVERY,
    [Inbox.DELIVERED]: AmplitudeEvents.DELIVERED,
    [Inbox.INCIDENT]: AmplitudeEvents.INCIDENT,
    [Inbox.RETURNED_TO_SENDER]: AmplitudeEvents.RETURNED_TO_SENDER,
    [Inbox.ARCHIVED]: AmplitudeEvents.ARCHIVED,
    [Inbox.CANCELED]: AmplitudeEvents.CANCELED,
};

export const SHIPMENT_SORT_TO_AMPLITUDE_PROPERTY = {
    default: 'by date',
    [ShipmentsOrderBy.SERVICE_ASC]: 'ascending',
    [ShipmentsOrderBy.SERVICE_DESC]: 'descending',
};

export const FLOW_AMPLITUDE_PROPERTY = {
    MANUAL_CHECKOUT: 'manual checkout',
    SIDE_PANEL: 'side panel',
    CSV_IMPORT: 'CSV import',
};

export const SOURCE_AMPLITUDE_PROPERTY = {
    LINK: 'click on link',
    BUTTON: 'click on button',
};
export type AmplitudePayload = Partial<Record<AmplitudeProperties, unknown>>;
