import { useTranslation } from '@packlink/translation-provider';
import { IAddressBook } from '@packlink/packlink-sdk';
import { Typography } from '@shipengine/giger';
import { addressLineStyles } from './AddressCardContentStyles';

interface IAddressCardContentProps {
    address: IAddressBook;
}

export function AddressCardContent({ address }: IAddressCardContentProps): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    return (
        <>
            <Typography css={addressLineStyles} component="p" variant="body2">
                {address.address}
            </Typography>
            <Typography css={addressLineStyles} component="p" variant="body2">
                {address.zipCode} {address.city}
            </Typography>
            <Typography css={addressLineStyles} component="p" variant="body2">
                {address.postalZone.translations[locale]}
            </Typography>
            <Typography css={addressLineStyles} component="p" variant="body2">
                {t('form.label.phone')}: {address.phone}
            </Typography>
        </>
    );
}
