import { createSelector } from 'reselect';
import { IAppState } from '@store/reducers';
import { Client, ClientType } from '@packlink/packlink-sdk';

const clientId = (client: Client): string => client?.id || '';
const isAutonomous = (client: Client): boolean => client?.type === ClientType.AUTONOMOUS;
const isBusiness = (client: Client): boolean => client?.taxId !== '-' && client?.type === ClientType.BUSINESS;

export const getClientData = (store: IAppState): Client => store.client;
export const getClientIdentifier = createSelector(getClientData, clientId);
export const isBusinessClient = createSelector(getClientData, isBusiness);
const isAutonomousClient = createSelector(getClientData, isAutonomous);
export const isInvoiceAddressValid = createSelector(
    isBusinessClient,
    isAutonomousClient,
    (business: boolean, autonomous: boolean): boolean => business || autonomous,
);
