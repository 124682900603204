import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Typography } from '@shipengine/giger';

import {
    getChartContainerStyles,
    getChartHeadingStyles,
    getChartStyles,
    getChartTitleStyles,
    getSkeletonIconStyles,
} from './ChartContainerStyles';

import { DEFAULT_HEIGHT } from '../../types';

type ChartContainerSkeletonProps = {
    title: string;
    height?: number;
};
export const ChartContainerSkeleton = ({
    title,
    height = DEFAULT_HEIGHT,
}: ChartContainerSkeletonProps): JSX.Element => {
    return (
        <article css={getChartContainerStyles}>
            <div css={getChartHeadingStyles}>
                <Typography component="h3" variant="body2" bold css={getChartTitleStyles}>
                    {title}
                </Typography>
            </div>

            <div css={getChartStyles(height)}>
                <Icon css={getSkeletonIconStyles} name={IconNames.CHART_UPWARDS} size={IconSize.SIZE_LARGE} />
            </div>
        </article>
    );
};
