import { useState } from 'react';

type UseDialogResult = {
    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
};

export function useDialog(): UseDialogResult {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return {
        open,
        handleOpen,
        handleClose,
    };
}
