import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export function getClipboardCopierStyles(theme: Theme): SerializedStyles {
    return css({
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    });
}
