/* eslint-disable no-control-regex */
import { TFunction } from 'i18next';
import * as yup from 'yup';

import { Origin } from '@types';
import { ILocationFormField } from './LocationForm';
import { ISelectAutocompleteOption } from '@shipengine/giger';

export const getLocationValidation = (
    origin: Origin,
    hasPickUp: boolean,
    t: TFunction,
    hasAlias = false,
    hideEmail = false,
) => {
    const isFrom = origin === Origin.FROM;

    // Safe check because if the user saves a shipment from checkout
    // after deleting a field, it will then come as null instead of empty string.
    // And to use .nullable() we need to change the whole interface IAddress.
    const nullGuard = (value: string | null): string => value || '';

    // Same email regex validation as in the backend
    const emailRegex =
        /^(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

    const addressSchema =
        isFrom || !hasPickUp
            ? yup
                  .string()
                  .trim()
                  .transform(nullGuard)
                  .max(80, t('form.error.max', { field: t('form.label.address'), number: 80 }))
                  .required(t('form.error.required', { field: t('form.label.address') }))
            : yup.string();

    return yup.object<ILocationFormField>().shape({
        firstName: yup
            .string()
            .trim()
            .transform(nullGuard)
            .max(50, t('form.error.max', { field: t('form.label.first-name'), number: 50 }))
            .required(t('form.error.required', { field: t('form.label.first-name') })),
        lastName: yup
            .string()
            .trim()
            .transform(nullGuard)
            .max(50, t('form.error.max', { field: t('form.label.last-name'), number: 50 }))
            .required(t('form.error.required', { field: t('form.label.last-name') })),
        company: yup
            .string()
            .transform(nullGuard)
            .max(50, t('form.error.max', { field: t('form.label.company'), number: 50 })),
        ...(!hideEmail && {
            email: yup
                .string()
                .trim()
                .transform(nullGuard)
                .email(t('form.error.format', { field: t('form.label.email') }))
                .max(50, t('form.error.max', { field: t('form.label.email'), number: 50 }))
                .matches(emailRegex, t('form.error.format', { field: t('form.label.email') }))
                .required(t('form.error.required', { field: t('form.label.email') })),
        }),
        phone: yup
            .string()
            .trim()
            .transform(nullGuard)
            .min(8, t('form.error.min', { field: t('form.label.phone'), number: 8 }))
            .max(18, t('form.error.max', { field: t('form.label.phone'), number: 18 }))
            .matches(/^[^a-zA-Z]+$/, t('form.error.format', { field: t('form.label.phone') }))
            .required(t('form.error.required', { field: t('form.label.phone') })),
        street1: addressSchema,
        ...(hasAlias && {
            alias: yup
                .string()
                .trim()
                .transform(nullGuard)
                .max(50, t('form.error.max', { field: t('form.label.alias'), number: 50 }))
                .required(t('form.error.required', { field: t('form.label.alias') })),
        }),
        country: yup
            .object<ISelectAutocompleteOption>()
            .nullable(true)
            .required(t('form.error.required', { field: t('form.label.country') })),
        postalCode: yup
            .object<ISelectAutocompleteOption>()
            .nullable(true)
            .required(t('form.error.required', { field: t('form.label.city-or-zipcode') })),
    });
};
