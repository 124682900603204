import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getAccordionStyles = (): SerializedStyles =>
    css({
        flexGrow: 1,
        maxHeight: '560px',
        overflow: 'auto',
    });

export const getSectionHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        width: '100%',
        height: theme.spacing(6),
        padding: theme.spacing(2),
        alignItems: 'center',
        borderBottom: `${theme.palette.gray.light} 1px solid`,
    });

export const getSectionIconStyles = (theme: Theme): SerializedStyles =>
    css({
        width: theme.spacing(6),
    });

export const getSectionHeaderCarrierStyles = (theme: Theme): SerializedStyles =>
    css({
        flexGrow: 1,
        margin: `0 ${theme.spacing()}px`,
        fontWeight: theme.typography.fontWeight.semibold,
    });

export const getSectionHeaderChevronStyles = (theme: Theme): SerializedStyles =>
    css({
        lineHeight: 0,
        color: theme.palette.primary.main,
    });

export const getContentTextStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: 0,
        color: theme.palette.gray.dark,
    });

export const getContentHiddenStyles = (): SerializedStyles =>
    css({
        display: 'none',
    });

export const getContentListStyles = (): SerializedStyles =>
    css({
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    });

export const getContentDescriptionStyles = (theme: Theme): SerializedStyles =>
    css({
        fontWeight: theme.typography.fontWeight.semibold,
        marginBottom: 0,
        color: theme.palette.gray.dark,
    });

export const getContentDetailsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.spacing(),
        marginBottom: 0,
        color: theme.palette.gray.dark,
    });

export const getContentReferenceStyles = (): SerializedStyles =>
    css({
        flexGrow: 1,
    });

export const getContentPriceStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignSelf: 'flex-end',
        fontWeight: theme.typography.fontWeight.semibold,
    });

export const getContentShipmentStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
        borderBottom: `${theme.palette.gray.light} 1px solid`,
    });

export const getBenefitsStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.main,
    });

export const getBenefitsItemStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(),
    });
