import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getDialogIconStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'block',
        marginBottom: theme.spacing(2),
        color: theme.palette.error.main,
    });

export const getDialoParagraphStyles = (): SerializedStyles =>
    css({
        marginBottom: 0,
    });

export const getDialogStyles = (): SerializedStyles =>
    css({
        minWidth: 420,
    });
