import { TFunction } from '@packlink/translation-provider';
import {
    IClientService,
    IParcel,
    IPostalZone,
    IShipmentSource,
    IShippingRule,
    IShippingRuleItem,
    IWarehouseAddress,
    Optional,
    Parcel,
    ShipmentSource,
} from '@packlink/packlink-sdk';
import { IFormItem, IRuleForm } from '../types/formTypes';
import { ActionOps } from '../constants';

const getOperation = (currentOp: string, overwrite?: boolean) => {
    if (overwrite === undefined) {
        return currentOp;
    } else {
        return overwrite ? ActionOps.OVERWRITE : ActionOps.ASSIGN;
    }
};

const mapFormValuesItems = (items: IFormItem[], overwrite?: boolean): IShippingRuleItem[] =>
    items.map(({ identifier, operation, value }) => ({
        identifier: identifier.value,
        operation: getOperation(operation.value, overwrite),
        value: value.value,
    }));

export const mapFormValuesToNewRule = ({
    id,
    alias,
    enabled,
    overwrite,
    conditions,
    actions,
}: IRuleForm): Optional<IShippingRule, 'id'> => ({
    id,
    alias,
    enabled,
    conditions: mapFormValuesItems(conditions),
    actions: mapFormValuesItems(actions, overwrite),
});

export const mapServiceToAutocomplete = (service: IClientService, t: TFunction) => {
    const category = `${service.category.charAt(0).toUpperCase()}${service.category.slice(1)}`;
    const origin = service.dropOff.origin
        ? t('service-card.collection-type.drop-off')
        : t('service-card.collection-type.collection');
    const destination = service.dropOff.destination
        ? t('service-card.delivery-type.pick-up')
        : t('service-card.delivery-type.delivery');

    return {
        label: `${service.carrierName} ${service.serviceName}`,
        value: service.serviceId.toString(),
        sublabel: `${category} / ${origin} / ${destination}`,
    };
};

export const mapCountryToAutocomplete = (country: IPostalZone) => ({
    label: country.name,
    value: country.id,
});

export const mapSourceToAutocomplete = (source: ShipmentSource<IShipmentSource>) => ({
    label: source.name,
    value: source.alias,
});

export const mapWarehouseToAutocomplete = (warehouse: IWarehouseAddress) => ({
    label: warehouse.alias,
    value: warehouse.id,
});

export const mapParcelToAutocomplete = (parcel: Parcel<IParcel>) => ({
    label: `${parcel.name} (${parcel.weight}kg ${parcel.length} x ${parcel.width} x ${parcel.height} cm)`,
    value: parcel.id as string,
});
