import { Inbox } from '@packlink/packlink-sdk';
import { AddressesRoute } from '@Addresses/router/routes';
import { InvoicingRoute } from '@Invoicing/router/routes';
import { IntegrationRoute } from '../integrations/routes';
import { ShipmentsRoute } from '../shipments/routes';
import { SettingsRoute } from '../settings/routes';
import { CheckoutRoute } from '../checkout/routes';
import { inboxToPath } from './utils/paths';

export enum AppRoute {
    ANALYTICS = 'analytics',
    INTEGRATIONS = 'integrations',
    SETTINGS = 'settings',
    SHIPMENTS = 'shipments',
    SUBSCRIPTIONS = 'subscriptions',
    SUPPORT_CENTER = 'support-center',
    BRANDED_EMAIL = 'branded-email',
    ONBOARDING = 'onboarding',
    ERROR = 'error',
}

export const APP_ROUTE = {
    SETTINGS: {
        BILLING: `/${AppRoute.SETTINGS}/${SettingsRoute.BILLING}`,
        BILLING_INVOICES: `/${AppRoute.SETTINGS}/${SettingsRoute.BILLING}/${InvoicingRoute.INVOICES}`,
        BILLING_INVOICING: `/${AppRoute.SETTINGS}/${SettingsRoute.BILLING}/${InvoicingRoute.INVOICING}`,
        BILLING_PAYMENT: `/${AppRoute.SETTINGS}/${SettingsRoute.BILLING}/${InvoicingRoute.PAYMENT}`,
        ADDRESSES: `/${AppRoute.SETTINGS}/${SettingsRoute.ADDRESSES}`,
        ADDRESSES_SENDER: `/${AppRoute.SETTINGS}/${SettingsRoute.ADDRESSES}/${AddressesRoute.SENDER}`,
        ADDRESSES_RECIPIENT: `/${AppRoute.SETTINGS}/${SettingsRoute.ADDRESSES}/${AddressesRoute.RECIPIENT}`,
        API_KEY: `/${AppRoute.SETTINGS}/${SettingsRoute.API_KEY}`,
        ARCHIVING: `/${AppRoute.SETTINGS}/${SettingsRoute.ARCHIVING}`,
        INTEGRATIONS: `/${AppRoute.SETTINGS}/${SettingsRoute.INTEGRATIONS}`,
        INTEGRATIONS_ALIAS: `/${AppRoute.SETTINGS}/${SettingsRoute.INTEGRATIONS}/:alias`,
        OWN_CONTRACTS: `/${AppRoute.SETTINGS}/${SettingsRoute.OWN_CONTRACTS}`,
        PARCELS: `/${AppRoute.SETTINGS}/${SettingsRoute.PARCELS}`,
        PRIVACY: `/${AppRoute.SETTINGS}/${SettingsRoute.PRIVACY}`,
        SHIPPING_RULES: `/${AppRoute.SETTINGS}/${SettingsRoute.SHIPPING_RULES}`,
        YOUR_ACCOUNT: `/${AppRoute.SETTINGS}/${SettingsRoute.YOUR_ACCOUNT}`,
        VOLUME_DISCOUNT: `/${AppRoute.SETTINGS}/${SettingsRoute.VOLUME_DISCOUNT}`,
        BRANDED_EMAILS: `/${AppRoute.SETTINGS}/${SettingsRoute.BRANDED_EMAILS}`,
    },
    INTEGRATIONS: {
        ACTIVATE_START_FLOW: `/${AppRoute.INTEGRATIONS}/:alias/${IntegrationRoute.ACTIVATE_START_FLOW}`,
        AMAZON_ACTIVATE_START_FLOW: `/${AppRoute.INTEGRATIONS}/${IntegrationRoute.AMAZON_ACTIVATE_START_FLOW}`,
        AMAZON_ACTIVATE_COMPLETE_FLOW: `/${AppRoute.INTEGRATIONS}/${IntegrationRoute.AMAZON_ACTIVATE_COMPLETE_FLOW}`,
        EBAY_ACTIVATE_START_FLOW: `/${AppRoute.INTEGRATIONS}/${IntegrationRoute.EBAY_ACTIVATE_START_FLOW}`,
        EBAY_ACTIVATE_COMPLETE_FLOW: `/${AppRoute.INTEGRATIONS}/${IntegrationRoute.EBAY_ACTIVATE_COMPLETE_FLOW}`,
        ETSY_ACTIVATE_START_FLOW: `/${AppRoute.INTEGRATIONS}/${IntegrationRoute.ETSY_ACTIVATE_START_FLOW}`,
        ETSY_ACTIVATE_COMPLETE_FLOW: `/${AppRoute.INTEGRATIONS}/${IntegrationRoute.ETSY_ACTIVATE_COMPLETE_FLOW}`,
    },
    SUBSCRIPTIONS: {
        INDEX: `/${AppRoute.SUBSCRIPTIONS}`,
    },
    BRANDED_EMAIL: {
        INDEX: `/${AppRoute.BRANDED_EMAIL}`,
    },
    SHIPMENTS: {
        BULK_SUMMARY: `/${AppRoute.SHIPMENTS}/${ShipmentsRoute.BULK}/${ShipmentsRoute.SUMMARY}`,
        INBOX: `/${AppRoute.SHIPMENTS}/:inbox`,
        INBOX_ALL: `/${AppRoute.SHIPMENTS}/${inboxToPath(Inbox.ALL)}`,
        INBOX_READY_TO_PURCHASE: `/${AppRoute.SHIPMENTS}/${inboxToPath(Inbox.READY_TO_PURCHASE)}`,
        DETAILS: `/${AppRoute.SHIPMENTS}/:id/${ShipmentsRoute.DETAILS}`,
    },
    CHECKOUT: {
        CREATE: `/${AppRoute.SHIPMENTS}/${CheckoutRoute.CREATE}`,
        EDIT: `/${AppRoute.SHIPMENTS}/:id/${CheckoutRoute.CREATE}`,
        STEP: `/${AppRoute.SHIPMENTS}/${CheckoutRoute.CREATE}/:step`,
        STEP_EDIT: `/${AppRoute.SHIPMENTS}/:id/${CheckoutRoute.CREATE}/:step`,
    },
    AUTH: {
        LOGIN: '/:partner/login',
        REGISTER: '/:partner/register',
        RECOVER_PASSWORD: '/:partner/recover-password',
        RECOVER_PASSWORD_CONFIRM: '/:partner/new-password',
    },
    SUPPORT_CENTER: {
        INDEX: `/${AppRoute.SUPPORT_CENTER}`,
        TICKET_DETAILS: `/${AppRoute.SUPPORT_CENTER}/details/:id`,
        NEW_TICKET: `/${AppRoute.SUPPORT_CENTER}/new-ticket`,
    },
    ONBOARDING: {
        SELECT_PLAN: `/${AppRoute.ONBOARDING}/select-plan`,
        WELCOME: `/${AppRoute.ONBOARDING}/welcome`,
    },
    ERROR: {
        INDEX: `/${AppRoute.ERROR}`,
    },
} as const;
