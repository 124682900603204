import { getCancelReasonLabelKey } from '@Postsale/utils/cancellation';
import { useMemo } from 'react';

export function useSupportCenterCancelReasons(): Record<string, string> {
    const cancelReasonsIds = config.contactSupportReasons.CANCEL_SHIPMENT as string[];
    return useMemo(
        () =>
            cancelReasonsIds.reduce(
                (reasons, reasonId) => ({ ...reasons, [reasonId]: getCancelReasonLabelKey(reasonId) }),
                {},
            ),
        [cancelReasonsIds],
    );
}
