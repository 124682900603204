import React from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';
import { getTypographyUPSTrademarkNoticeStyles, getUPSTrademarkNoticeStyles } from './UPSTrademarkNoticeStyles';

export const UPSTrademarkNoticeFooter: React.FC = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div css={getUPSTrademarkNoticeStyles}>
            <Typography variant="small" component="p" css={getTypographyUPSTrademarkNoticeStyles}>
                {t('checkout.summary.ups-trademark-notice')}
            </Typography>
        </div>
    );
};
