import React from 'react';

import { Typography, WithChildrenCommonProps } from '@shipengine/giger';

import {
    getSidePanelContentSectionBodyStyles,
    getSidePanelContentSectionStyles,
    getSidePanelContentSectionSubtitleStyles,
    getSidePanelContentSectionTitleStyles,
} from './SidePanelContentSectionStyles';

export type ISidePanelContentSectionProps = WithChildrenCommonProps<{
    title: string;
    subtitle?: string;
}>;

export const SidePanelContentSection: React.FC<ISidePanelContentSectionProps> = (
    props: ISidePanelContentSectionProps,
): JSX.Element => {
    const { title, subtitle, children, ...rest } = props;

    const renderSubtitle = (): JSX.Element => (
        <Typography
            component="div"
            variant="small"
            css={getSidePanelContentSectionSubtitleStyles}
            data-id="section-subtitle"
        >
            {subtitle}
        </Typography>
    );

    return (
        <section css={getSidePanelContentSectionStyles} {...rest}>
            <Typography
                component="div"
                variant="body1"
                css={getSidePanelContentSectionTitleStyles}
                data-id="section-title"
            >
                {title}
            </Typography>

            {subtitle && renderSubtitle()}

            <div css={getSidePanelContentSectionBodyStyles}>{children}</div>
        </section>
    );
};
