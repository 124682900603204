import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { ServiceCollection } from '@packlink/service-collection';
import { DropOff, DropOffTheme, IDropOffPoint } from '@packlink/cartography';
import { Address, IAddress, ProShipment, Service, ServiceAvailableDate } from '@packlink/packlink-sdk';
import { ServiceItem, ServiceList, ServiceListCardProps, ServiceListFilters } from '@packlink/service-list';

import { getMapMarker } from '@utils/cdn';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { useShipmentPanel } from '@hooks/useShipmentPanel';

import { MAX_LOADING_PROGRESS, ProgressBar } from '@components/ProgressBar/ProgressBar';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';

import { SidePanelCustomHeader } from '@components/SidePanel/SidePanelCustomHeader';
import { ShipmentPanelFooter } from '../ShipmentPanelFooter';

import {
    getShipmentPanelEmptyServicesMessageStyles,
    getShipmentPanelServicesDropoffContentStyles,
} from './ShipmentPanelServicesStyles';

import { ServicesStep } from './types';
import { useCollection } from './hooks/useCollection';
import { useServiceList } from './hooks/useServiceList';
import { shipmentPanelFormStyles } from '../ShipmentPanelStyles';
import { useMetrics } from '@providers/MetricsProvider';
import { CompactServiceCard } from '@components/ShipmentList/components';

enum HeaderActions {
    CANCEL = 'cancel',
    BACK = 'back',
}

export function ShipmentPanelServices() {
    const { panelState, closePanel, updateShipment, exitPanel } = useShipmentPanel();
    // Need useMemo because are used in useCallback as depth
    const { data: shipmentData } = useMemo(() => panelState.shipment as ProShipment, [panelState]);
    const shipmentDataJSON = useMemo(() => shipmentData.toJSON(), [shipmentData]);
    const [step, setStep] = useState<ServicesStep>(ServicesStep.LIST);
    const [isLoadingStateComplete, setLoadingStateComplete] = useState(false);
    const [loadingProgressValue, setLoadingProgressValue] = useState(0);
    const [selectedDropoffId, setSelectedDropoffId] = useState<string | undefined>(shipmentDataJSON.dropoffPointId);

    const { t } = useTranslation();
    const metrics = useMetrics();

    const {
        searchParams,
        selectedService,
        dropOffPoints,
        setDropOffPoints,
        selectService,
        seeDropOffPoints,
        seeServiceDetails,
        setInitialService,
    } = useServiceList(shipmentDataJSON, setStep, setSelectedDropoffId);

    const { selectedCollection, setSelectedCollection, onChangeCollection } = useCollection(
        shipmentData,
        step,
        selectedService,
    );

    const submitButtonLabel =
        selectedService?.dropOff?.origin || step === ServicesStep.COLLECTION_DATE
            ? t('shipment-panel.actions.save')
            : t('shipment-panel.actions.next');

    const headerActions = useMemo(
        () => ({
            [HeaderActions.CANCEL]: {
                icon: IconNames.CLOSE,
                callback: closePanel,
            },
            [HeaderActions.BACK]: {
                icon: IconNames.ARROW_LEFT,
                callback: (): void => {
                    setDropOffPoints(undefined);
                    setSelectedCollection(undefined);
                    setStep(ServicesStep.LIST);
                },
            },
        }),
        [closePanel, setDropOffPoints, setSelectedCollection],
    );

    const showUPSTrademarkNotice: boolean = useMemo(() => {
        return !!selectedService?.carrierName.toUpperCase().includes('UPS');
    }, [selectedService]);

    const [header, setHeader] = useState(headerActions[HeaderActions.CANCEL]);

    useEffect((): void => {
        if ([ServicesStep.DROPOFFS, ServicesStep.COLLECTION_DATE].includes(step)) {
            setHeader(headerActions[HeaderActions.BACK]);
        } else {
            setHeader(headerActions[HeaderActions.CANCEL]);
        }
    }, [headerActions, step]);

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>): void => {
            e.preventDefault();

            if (step !== ServicesStep.COLLECTION_DATE && !selectedService?.dropOff?.origin) {
                setStep(ServicesStep.COLLECTION_DATE);
                return;
            }

            if (!selectedService) return;

            const newShipmentData = ShipmentUtils.updateService(
                shipmentDataJSON,
                selectedService,
                selectedCollection,
                selectedDropoffId,
            );

            updateShipment(newShipmentData);
            closePanel(false);
        },
        [closePanel, selectedCollection, selectedDropoffId, selectedService, shipmentDataJSON, step, updateShipment],
    );

    const handleFetchServices = useCallback(
        (services: Service[]): void => {
            setLoadingProgressValue(MAX_LOADING_PROGRESS);
            setInitialService(services);
        },
        [setInitialService],
    );
    return (
        <form css={shipmentPanelFormStyles()} onSubmit={handleSubmit}>
            <SidePanelCustomHeader
                onClickAction={header.callback}
                actionIcon={header.icon}
                title={t(`shipment-list-header.menu.service`)}
            />

            <SidePanelContent>
                {step === ServicesStep.DROPOFFS && dropOffPoints && (
                    <div css={getShipmentPanelServicesDropoffContentStyles}>
                        <DropOff
                            addresses={dropOffPoints as IDropOffPoint[]}
                            theme={DropOffTheme.MOBILE}
                            allowClose={true}
                            // TODO: https://packlink.atlassian.net/browse/IDE-229 Load by brand
                            markerIcon={getMapMarker()}
                        />
                    </div>
                )}

                {step === ServicesStep.LIST && (
                    <ServiceList
                        tracker={metrics}
                        searchParams={searchParams}
                        cdnUrl={config.cdn.baseUrl}
                        from={shipmentData.from as Address<IAddress>}
                        showFilters={ServiceListFilters.CHIPS}
                        LoadingState={
                            <ProgressBar
                                value={loadingProgressValue}
                                handleLoadingState={setLoadingStateComplete}
                                setLoadingProgressValue={setLoadingProgressValue}
                                isSidePanelContent
                            />
                        }
                        isLoadingStateComplete={isLoadingStateComplete}
                        EmptyState={EmptyState}
                        onSelectService={selectService}
                        onDropOffPointsRequest={seeDropOffPoints}
                        onDetailsRequest={seeServiceDetails}
                        onFetchServices={handleFetchServices}
                    >
                        {(cardProps: ServiceListCardProps): React.ReactNode => (
                            <CompactServiceCard {...cardProps} selectedServiceId={selectedService?.id} />
                        )}
                    </ServiceList>
                )}
                {step === ServicesStep.COLLECTION_DATE && (
                    <>
                        <ServiceItem
                            index={0}
                            service={selectedService as Service}
                            from={shipmentData.from as Address<IAddress>}
                            cdnUrl={config.cdn.baseUrl}
                            onSelect={noop}
                            showVat={false}
                        >
                            {(cardProps: ServiceListCardProps): React.ReactNode => (
                                <CompactServiceCard {...cardProps} selectedServiceId={selectedService?.id} />
                            )}
                        </ServiceItem>

                        <ServiceCollection
                            name="collectionDate"
                            availableDates={selectedService?.availableDates as ServiceAvailableDate[]}
                            date={selectedCollection?.date}
                            interval={selectedCollection?.interval}
                            onChange={onChangeCollection}
                        />
                    </>
                )}
            </SidePanelContent>

            <ShipmentPanelFooter
                onExit={exitPanel}
                isSaveDisabled={!isLoadingStateComplete || !selectedService?.id}
                submitButtonLabel={submitButtonLabel}
                showUPSTrademarkNotice={showUPSTrademarkNotice}
            />
        </form>
    );
}

function EmptyState() {
    const { t } = useTranslation();
    return <p css={getShipmentPanelEmptyServicesMessageStyles}>{t('services-panel.service-list.empty')}</p>;
}
