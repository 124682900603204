import { useCallback } from 'react';
import { BraintreeError, LocalPaymentTokenizePayload } from 'braintree-web';

import { PaymentClientNonce, PaymentMethod, PaymentType } from '@packlink/packlink-sdk';
import {
    IAmplitude3Dsecure,
    IAmplitudeLocalPayment,
    ICreditCardVerifyPayload,
    IPaypalVerifyPayload,
    PaymentStep,
} from '@packlink/payment-options';
import { removePaymentMethod, setLocalPaymentFallbackPaymentMethod } from '@store/actions/payment';
import Packlink from '@sdk';
import { TDispatch } from '@store';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IPaymentCallbacksProps {
    section: 'checkout' | 'side panel' | 'settings deferred' | 'subscription';
    dispatch: TDispatch;
    sendOrder: (
        paymentType: PaymentType,
        paymentMethod: PaymentMethod,
        nonce?: string,
        paymentMetrics?: IAmplitude3Dsecure | IAmplitudeLocalPayment,
        deviceData?: string,
    ) => void;
    onError: (
        step: PaymentStep,
        paymentMethod?: PaymentMethod,
        error?: BraintreeError | Error,
        translationKey?: string,
    ) => void;
    onDelete?: VoidFunction;
}

export const usePaymentCallbacks = ({ dispatch, onError, onDelete, sendOrder, section }: IPaymentCallbacksProps) => {
    const { sendAmplitudeEvent } = useAmplitude();
    const handleRequestClientNonce = useCallback(
        (nonceCurrency?: string): Promise<PaymentClientNonce> => Packlink.v1.billing.braintree.get(nonceCurrency),
        [],
    );

    const handleDeletePaymentMethod = (id: string, paymentMethod: PaymentMethod): void => {
        dispatch(removePaymentMethod(id));
        sendAmplitudeEvent(AmplitudeEvents.PAYMENT_METHOD_DELETE, {
            [AmplitudeProperties.SECTION]: section,
            [AmplitudeProperties.PAYMENT_METHOD]: paymentMethod,
        });
        onDelete?.();
    };

    const handlePaymentError = useCallback(
        (step: PaymentStep, paymentMethod?: PaymentMethod, error?: BraintreeError | Error): void => {
            let translationKey: string | undefined;

            switch (step) {
                case PaymentStep.SETUP:
                    translationKey = 'error.payment.setup';
                    break;
                case PaymentStep.VERIFICATION:
                    translationKey = 'error.payment.verification';
                    break;
            }

            onError(step, paymentMethod, error, translationKey);
        },
        [onError],
    );

    const handlePaymentStart = useCallback(
        (paymentMethod: PaymentMethod) => {
            if (paymentMethod === PaymentMethod.BANCONTACT) {
                dispatch(setLocalPaymentFallbackPaymentMethod(PaymentMethod.BANCONTACT));
            }
        },
        [dispatch],
    );

    const handleOrder = (
        paymentMethod: PaymentMethod,
        verification?: ICreditCardVerifyPayload | IPaypalVerifyPayload | LocalPaymentTokenizePayload,
        paymentMetrics?: IAmplitude3Dsecure | IAmplitudeLocalPayment,
    ): void => {
        const method = paymentMethod === PaymentMethod.CREDITCARD3DSECURE ? PaymentMethod.CREDITCARD : paymentMethod;
        sendOrder(
            PaymentType.DIRECT,
            method,
            verification?.nonce,
            paymentMetrics,
            (verification as ICreditCardVerifyPayload | IPaypalVerifyPayload)?.deviceData,
        );
    };

    return { handleRequestClientNonce, handleDeletePaymentMethod, handlePaymentError, handlePaymentStart, handleOrder };
};
