/* eslint-disable react/jsx-no-target-blank */
import { useTranslation } from '@packlink/translation-provider';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { ITenantConfig } from '@types';
import {
    getAppFooterStyles,
    getAppFooterTextImpressumStyles,
    getAppFooterTextPowerStyles,
    getAppFooterTextRightStyles,
} from './AppFooterStyles';

export const AppFooter = (): JSX.Element => {
    const { t } = useTranslation();
    const { impressumLink, showPowerByPacklink } = useTenantConfig<ITenantConfig>();
    const getHelpCenterPage = useHelpCenter();

    return (
        <footer css={getAppFooterStyles}>
            <div>
                <p css={getAppFooterTextRightStyles}>
                    {showPowerByPacklink && (
                        <span css={getAppFooterTextPowerStyles}>{t('footer.text.power-packlink')}</span>
                    )}
                    <a
                        target="_blank"
                        href={getHelpCenterPage(HELP_CENTER_KEYS.termsAndConditions)}
                        title={t('footer.links.terms')}
                    >
                        {t('footer.links.terms')}
                    </a>
                    <a
                        target="_blank"
                        href={getHelpCenterPage(HELP_CENTER_KEYS.privacyPolicy)}
                        title={t('footer.links.privacy-policy')}
                    >
                        {t('footer.links.privacy-policy')}
                    </a>
                    {impressumLink && (
                        <a target="_blank" href={impressumLink} title="Impressum" css={getAppFooterTextImpressumStyles}>
                            Impressum
                        </a>
                    )}
                </p>
            </div>
        </footer>
    );
};
