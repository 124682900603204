import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketDataSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        [theme.mediaQuery('tablet')]: {
            padding: theme.spacing(3),
        },
    });

export const getTicketDataSectionTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getTicketDataSectionListStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridAutoRows: 'auto',
        gap: theme.spacing(),
    });
