import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { ServiceCollection as ServiceCollectionComp } from '@packlink/service-collection';
import { IInterval, ServiceAvailableDate } from '@packlink/packlink-sdk';
import { AppDispatch } from '@store';
import { getCheckoutCollectionDate, getCheckoutCollectionTime, getCheckoutService } from '@store/selectors/checkout';
import { setCheckoutCollectionDate, setCheckoutCollectionTime } from '@store/actions/checkout';
import { headerStyles } from './ServiceCollectionStyles';

export function ServiceCollection(): JSX.Element {
    const selectedService = useSelector(getCheckoutService);
    const collectionDate = useSelector(getCheckoutCollectionDate);
    const collectionTime = useSelector(getCheckoutCollectionTime);
    const dispatch = useDispatch<AppDispatch>();

    const onChangeCollection = (_name: string, date?: moment.Moment, interval?: IInterval) => {
        dispatch(setCheckoutCollectionDate(date?.toISOString()));
        dispatch(setCheckoutCollectionTime(interval));
    };
    return (
        <ServiceCollectionComp
            name="collectionDate"
            availableDates={selectedService?.availableDates as ServiceAvailableDate[]}
            date={collectionDate ? moment(collectionDate) : undefined}
            interval={collectionTime}
            headersStyles={headerStyles}
            onChange={onChangeCollection}
        />
    );
}
