import { ChartContainerProps, DEFAULT_HEIGHT, Side } from '../../types';
import { containerStyles } from './DoubleChartStyles';

type DoubleChartProps = Pick<ChartContainerProps, 'leftComponent' | 'rightComponent' | 'height'> & {
    side: Side;
    isMobile: boolean;
};

export function DoubleChart({
    leftComponent,
    rightComponent,
    height = DEFAULT_HEIGHT,
    side,
    isMobile,
}: DoubleChartProps): JSX.Element {
    return (
        <div css={containerStyles(height, isMobile)}>
            {(!isMobile || side === Side.LEFT) && leftComponent}
            {(!isMobile || side === Side.RIGHT) && rightComponent}
        </div>
    );
}
