import { Theme } from '@shipengine/giger-theme';
import { css } from '@emotion/react';

export const getTileStyles = (theme: Theme) => {
    return css({
        justifyContent: 'space-around',
        margin: theme.spacing(),
        boxShadow: `0px 4px 6px ${theme.palette.gray.light}`,
        border: `1px solid ${theme.palette.gray.ultraLight}`,
        borderRadius: theme.spacing(1),
        height: '220px',
        width: '275px',

        ':hover': {
            border: `1px solid ${theme.palette.primary.light}`,
            cursor: 'pointer',
        },

        img: {
            height: '5rem',
            marginBottom: theme.spacing(2),
        },
    });
};
