import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { useTranslation } from '@packlink/translation-provider';
import { IAddress } from '@packlink/packlink-sdk';
import { FormField, IDropdownOption, SelectAutocomplete, WithCommonProps } from '@shipengine/giger';
import { RecipientAddress } from '@packlink/packlink-sdk';
import { useRecipientAddresses } from '@Addresses/hooks/useRecipientAddresses';

export type AddressOption = IDropdownOption<RecipientAddress>;
interface IRecipientAddressSelectorProps {
    selectedAddress?: AddressOption;
    address?: IAddress;
    onChange: (address: AddressOption) => void;
}

export const RecipientAddressSelector = ({
    selectedAddress,
    address,
    onChange,
    ...rest
}: WithCommonProps<IRecipientAddressSelectorProps>): JSX.Element => {
    const { t } = useTranslation();
    const [enableQuery, setEnableQuery] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>();

    const { addresses, isLoading, requestParams, changeRequestParams } = useRecipientAddresses({
        isEnabled: enableQuery,
    });

    const addressOptions = useMemo(
        () => addresses?.content.map((a): AddressOption => ({ ...a, label: a.alias, value: a.id })),
        [addresses],
    );

    const noResultsLabel = useMemo(
        () =>
            isLoading || searchQuery !== requestParams.alias
                ? t('autocomplete.messages.loading')
                : enableQuery
                  ? t('autocomplete.messages.no-results-found')
                  : t('form.label.search-by', { field: t('form.label.alias').toLowerCase() }),
        [enableQuery, isLoading, requestParams.alias, searchQuery, t],
    );

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const cleanUpSelect = () => {
        setEnableQuery(false);

        if (searchQuery) {
            setSearchQuery(undefined);
            changeRequestParams({ alias: undefined });
        }
    };

    const handleChange = useCallback(
        (_name: string, option: AddressOption | null): void => {
            if (!option) return;

            setSearchQuery(undefined);
            changeRequestParams({ alias: undefined });
            onChange(option);
        },
        [changeRequestParams, onChange],
    );

    useDebounce(
        () => {
            if (searchQuery === undefined) return;

            setEnableQuery(true);
            changeRequestParams({ alias: searchQuery || undefined });
        },
        300,
        [searchQuery],
    );

    useEffect(() => {
        changeRequestParams({
            city: address?.city || undefined,
            zipCode: address?.zipCode || undefined,
            country: address?.alpha2Code || undefined,
        });
    }, [address?.city, address?.zipCode, address?.alpha2Code, changeRequestParams]);

    return (
        <FormField>
            <SelectAutocomplete
                name="recipient"
                options={addressOptions || []}
                label={t('form.label.my-addresses')}
                onChange={handleChange}
                onInputChange={handleInputChange}
                onClickAway={cleanUpSelect}
                value={selectedAddress}
                noResultsLabel={noResultsLabel}
                {...rest}
            />
        </FormField>
    );
};
