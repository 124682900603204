import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';

import { useTranslation } from '@packlink/translation-provider';
import { InlineNotification, NotificationType } from '@shipengine/giger';
import { ServiceList, ServiceListCardProps, ServiceListFilters } from '@packlink/service-list';
import { CompactServiceCard } from '@components/ShipmentList/components';
import { Parcel, ProShipment, Service, ServiceBulkParams, ServiceShipment, Shipment } from '@packlink/packlink-sdk';

import { ShipmentUtils } from '@utils/ShipmentUtils';
import { removeSelectedShipments } from '@store/actions/bulk';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { getIsPendingSectionInbox, getSelectedShipments } from '@store/selectors/bulk';

import { MAX_LOADING_PROGRESS, ProgressBar } from '@components/ProgressBar/ProgressBar';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { BulkSelectionInfo } from '@components/SidePanel/BulkSelectionInfo';

import { useShipmentsUpdate } from '../../hooks/useShipmentsUpdate';
import { ShipmentPanelFooter } from '../../ShipmentPanelFooter';
import { shipmentPanelFormStyles } from '../../ShipmentPanelStyles';
import { useMetrics } from '@providers/MetricsProvider';
import { useAmplitude } from '@hooks/useAmplitude';
import { useThirdPartyPreferencesBehavior } from '@common/hooks/useThirdPartyPreferencesBehavior';

export interface IShipmentPanelBulkServicesProps {
    onGoBack: () => void;
}

export const ShipmentPanelBulkServices = (props: IShipmentPanelBulkServicesProps): JSX.Element => {
    const { onGoBack } = props;
    const [selectedService, setSelectedService] = useState<Service>();
    const [isLoadingStateComplete, setLoadingStateComplete] = useState(false);
    const [loadingProgressValue, setLoadingProgressValue] = useState(0);
    const selectedShipments = useSelector(getSelectedShipments);
    const isCurrentInboxPending = useSelector(getIsPendingSectionInbox);
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const metrics = useMetrics();
    const { sendAmplitudeEvent } = useAmplitude();
    const { getThirdPartyPreferencesEnabled } = useThirdPartyPreferencesBehavior();

    const { mutateShipments, isWaitingUpdate, messageProps } = useShipmentsUpdate(isCurrentInboxPending);

    const searchParams: ServiceBulkParams = useMemo(() => {
        return {
            shipments: selectedShipments.map((shipment: ProShipment) => {
                const { from, to, parcels, source, thirdPartyPreferences } = shipment.data;
                const thirdPartyPreferencesEnabled = getThirdPartyPreferencesEnabled(source);

                return {
                    from: {
                        country: from?.alpha2Code,
                        zip: from?.zipCode,
                    },
                    packages: parcels?.map((p: Parcel) => ({
                        height: p.height,
                        length: p.length,
                        weight: p.weight,
                        width: p.width,
                    })),
                    source: source,
                    to: {
                        country: to?.alpha2Code,
                        zip: to?.zipCode,
                    },
                    ...(thirdPartyPreferencesEnabled && {
                        shipping_service_id_selected: thirdPartyPreferences?.serviceId,
                        shipping_pick_up_point_id_selected: thirdPartyPreferences?.dropoffPointId,
                        carrier: thirdPartyPreferences?.carrier,
                    }),
                } as ServiceShipment;
            }),
        };
    }, [getThirdPartyPreferencesEnabled, selectedShipments]);

    const selectService = (service: Service): void => {
        setSelectedService(service);
    };

    const sendEvent = (event: AmplitudeEvents, data: unknown[]): void => {
        sendAmplitudeEvent(event, {
            [AmplitudeProperties.NUMBER_OF_SHIPMENTS_EDITED]: data.length,
        });
    };

    const continueProcess = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        updateShipments();
    };

    const updateShipments = (): void => {
        const updatedShipments = selectedShipments.map((shipment: ProShipment): Shipment => {
            if (!selectedService) {
                return shipment.data;
            }

            const { data: shipmentDataJSON } = ShipmentUtils.proShipmentToJSON(shipment);

            const newShipmentData = ShipmentUtils.updateService(shipmentDataJSON, selectedService);

            return Shipment.deserialize(newShipmentData);
        });

        mutateShipments(updatedShipments, {
            onSuccess: ({ shipmentsFailed, shipmentsUpdated }) => {
                if (shipmentsFailed.length > 0) {
                    // Uncheck the failed shipments, so the client could pay the ones that were success
                    dispatch(removeSelectedShipments(shipmentsFailed));
                }
                sendEvent(AmplitudeEvents.SIDEBAR_EDIT_SERVICE_SUCCESS, shipmentsUpdated);
            },
            onError: () => {
                sendEvent(AmplitudeEvents.SIDEBAR_EDIT_SERVICE_ERROR, updatedShipments);
            },
        });
    };

    const handleFetchServices = useCallback(
        (services: Service[]): void => {
            services && setLoadingProgressValue(MAX_LOADING_PROGRESS);
        },
        [setLoadingProgressValue],
    );

    return (
        <>
            {isWaitingUpdate ? (
                <form css={shipmentPanelFormStyles(true)} onSubmit={continueProcess}>
                    <BulkSelectionInfo
                        count={selectedShipments.length}
                        actionsTitle="bulk-panel.actions.title"
                        actionsSubtitle="bulk-panel.services.subtitle"
                        showBackground
                    />

                    <SidePanelContent>
                        <ServiceList
                            tracker={metrics}
                            searchParams={searchParams}
                            cdnUrl={config.cdn.baseUrl}
                            showFilters={ServiceListFilters.CHIPS}
                            LoadingState={
                                <ProgressBar
                                    value={loadingProgressValue}
                                    handleLoadingState={setLoadingStateComplete}
                                    setLoadingProgressValue={setLoadingProgressValue}
                                    isSidePanelContent
                                />
                            }
                            isLoadingStateComplete={isLoadingStateComplete}
                            EmptyState={ServiceListEmptyState}
                            onSelectService={selectService}
                            onFetchServices={handleFetchServices}
                        >
                            {(cardProps: ServiceListCardProps): React.ReactNode => (
                                <CompactServiceCard
                                    {...cardProps}
                                    selectedServiceId={selectedService?.id}
                                    isShortVariant={true}
                                />
                            )}
                        </ServiceList>
                    </SidePanelContent>
                    <ShipmentPanelFooter
                        onExit={onGoBack}
                        cancelButtonLabel={t('shipment-panel.actions.back')}
                        isSaveDisabled={!isLoadingStateComplete || !selectedService?.id}
                    />
                </form>
            ) : (
                <SidePanelContent>
                    <SidePanelMessage {...messageProps} />
                </SidePanelContent>
            )}
        </>
    );
};

const ServiceListEmptyState = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <InlineNotification type={NotificationType.ALERT}>{t('services-panel.service-list.empty')}</InlineNotification>
    );
};
