import { useNavigate } from 'react-router';
import {
    Button,
    ButtonColor,
    Icon,
    IconSize,
    Spinner,
    SpinnerSize,
    Typography,
    WithCommonProps,
} from '@shipengine/giger';
import DOMPurify from 'dompurify';
import {
    goToProButtonStyles,
    iconStyles,
    shippingRulesPromoImgStyles,
    shippingRulesPromoStyles,
    sidePanelMessageStyles,
} from '@components/SidePanel/SidePanelMessages/SidePanelMessageStyles';
import { useTranslation } from '@packlink/translation-provider';
import {
    AMPLITUDE_EVENTS_BY_INBOX,
    AmplitudeEventPrefixes,
    AmplitudeEvents,
    AmplitudeProperties,
} from '@constants/amplitude';
import { getCurrentInbox } from '@store/selectors/inbox';
import { APP_ROUTE } from '@pages/router/routes';
import { useSelector } from 'react-redux';
import { ICON_FOR_TYPE, ISidePanelMessageProps, SidePanelMessageType } from './types';
import { useAmplitude } from '@hooks/useAmplitude';

export const SidePanelMessage = ({
    title,
    subtitle,
    type,
    showShippingRulesPromo,
    showGoToPro,
}: WithCommonProps<ISidePanelMessageProps>): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const currentInbox = useSelector(getCurrentInbox);
    const isLoadingType = type === SidePanelMessageType.LOADING;
    const shippingRulesPromoImg = `${config.cdn.url}/pro/statics/animations/shipping-rules-promo.gif`;

    const goToShippingRules = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.CREATE_SHIPPING_RULE, {
            [AmplitudeProperties.INBOX]: `${AmplitudeEventPrefixes.SIDEPANEL} ${AMPLITUDE_EVENTS_BY_INBOX[currentInbox]}`,
        });
        navigate(APP_ROUTE.SETTINGS.SHIPPING_RULES);
    };

    const goToPacklinkPro = () => {
        navigate(APP_ROUTE.SHIPMENTS.INBOX_READY_TO_PURCHASE);
    };

    return (
        <article css={sidePanelMessageStyles}>
            <div css={iconStyles(type)}>
                {isLoadingType && <Spinner size={SpinnerSize.SIZE_LARGE} />}
                {!isLoadingType && <Icon name={ICON_FOR_TYPE[type]} size={IconSize.SIZE_LARGE} />}
            </div>

            {title && <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />}
            {subtitle && <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtitle) }} />}

            {!!showShippingRulesPromo && (
                <section css={shippingRulesPromoStyles}>
                    <img
                        src={shippingRulesPromoImg}
                        alt={t('side-panel.shipping-rules-promo.button')}
                        css={shippingRulesPromoImgStyles}
                    />
                    <Typography component="p" variant="body2" bold>
                        {t('side-panel.shipping-rules-promo.first')}
                    </Typography>
                    <Typography component="p" variant="body2">
                        {t('side-panel.shipping-rules-promo.second')}
                    </Typography>
                    <Button onClick={goToShippingRules} color={ButtonColor.PRIMARY}>
                        {t('side-panel.shipping-rules-promo.button')}
                    </Button>
                </section>
            )}

            {!!showGoToPro && (
                <section css={goToProButtonStyles}>
                    <Button onClick={goToPacklinkPro} color={ButtonColor.PRIMARY}>
                        {t('side-panel.button.go-to-pro')}
                    </Button>
                </section>
            )}
        </article>
    );
};
