import { Typography } from '@shipengine/giger';
import { Currency } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { getSubscriptionPriceStyles } from './SubscriptionPriceStyles';

interface ISubscriptionPriceProps {
    showDisclaimer?: boolean;
    price: number;
    currency: string;
}

export function SubscriptionPrice({ showDisclaimer = false, price, currency }: ISubscriptionPriceProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Typography
            css={getSubscriptionPriceStyles}
            variant="heading2"
            component="span"
            data-id="subscription-plan-price"
        >
            <>
                {Currency.format(Number(price), currency)}
                <Typography variant="heading5">
                    <span>
                        /{t('subscriptions.price.month-abbr')} {t('product.price.tax-name')}
                        {showDisclaimer && '*'}
                    </span>
                </Typography>
            </>
        </Typography>
    );
}
