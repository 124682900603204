import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiClient } from '@sdk';
import { UserApiKeyRepository } from '@packlink/packlink-sdk';

const userApiKeyRepository = new UserApiKeyRepository(apiClient);
const GET_CURRENT_API_KEY_QUERY_KEY = 'getCurrentApiKey';

export function useGetApiKey() {
    const { data } = useQuery({
        queryKey: [GET_CURRENT_API_KEY_QUERY_KEY],
        queryFn: () => userApiKeyRepository.getCurrentApiKey(),
    });

    return data?.token;
}

export function useCreateApiKey() {
    const queryClient = useQueryClient();

    const { mutate: createApiKey, isLoading: isCreateApiKeyLoading } = useMutation({
        mutationFn: () => userApiKeyRepository.generateNewApiKey(),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: GET_CURRENT_API_KEY_QUERY_KEY }),
    });

    return { createApiKey, isCreateApiKeyLoading };
}

export function useDeleteApiKey() {
    const queryClient = useQueryClient();

    const {
        mutate: deleteApiKey,
        isLoading: isDeleteApiKeyLoading,
        isSuccess: isDeleteApiKeySuccess,
    } = useMutation({
        mutationFn: () => userApiKeyRepository.revokeCurrentApiKey(),
        onSuccess: () => {
            // This needs to be resetQueries instead of invalidateQueries to clear cache
            // After deletion the refetch returns 404, so it will not use the cached data.
            queryClient.resetQueries({ queryKey: GET_CURRENT_API_KEY_QUERY_KEY });
        },
    });

    return { deleteApiKey, isDeleteApiKeyLoading, isDeleteApiKeySuccess };
}
