import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getInvoiceTimeStyles = (theme: Theme): SerializedStyles =>
    css({
        fontSize: theme.typography.fontSize.XS,
    });

export const getTableCheckboxColumnStyles = (): SerializedStyles =>
    css({
        width: 70,
    });

export const getTableFooterStyles = (): SerializedStyles =>
    css({
        justifyContent: 'space-between',
    });

export const getTopBannerStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: theme.spacing(2),
        marginBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 0,
    });

export const getBottomBannerStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: theme.spacing(2),
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
        paddingLeft: theme.spacing(4.5),
    });
