import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getAppHeaderMenuDividerStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'none',
        width: 2,
        height: 12,
        margin: `0 ${theme.spacing()}px`,
        backgroundColor: theme.palette.gray.light,

        [theme.mediaQuery('tablet')]: {
            display: 'block',
        },
    });

export const getAppHeaderMenuStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
    });
