import { useMemo } from 'react';
import { SubscriptionsRepository, SubscriptionState } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { useQuery } from 'react-query';
import { useTenantBehavior } from '@packlink/tenant-config-provider';
import { SubscriptionBehaviorConfigProps, SubscriptionLinks } from '@types';
import { SUBSCRIPTION_PLAN_PRICE_CACHE_KEY, SUBSCRIPTION_PLANS_CACHE_KEY, SUBSCRIPTIONS_CACHE_KEY } from '../constants';

const subscriptionsRepository = new SubscriptionsRepository(apiClient);

function fetchClientSubscriptions(forcePullFromRecurly = false) {
    return subscriptionsRepository.getClientSubscriptions(forcePullFromRecurly);
}

/**
 *  Returns whether subscriptions are active in the tenant and the hosted page URL
 */
export function useTenantSubscriptionConfig() {
    const getTenantBehavior = useTenantBehavior();
    const subscriptionBehavior = getTenantBehavior<SubscriptionBehaviorConfigProps>('SubscriptionModel');

    const isTenantSubscriptionEnabled = !!subscriptionBehavior?.properties.enabled;
    const linkArray = subscriptionBehavior?.properties.links;
    const links: Partial<Record<SubscriptionLinks, string>> | undefined = linkArray?.reduce(
        (linkObj: Record<string, string>, item) => ((linkObj[item.name] = item.url), linkObj),
        {},
    );

    return useMemo(() => ({ isTenantSubscriptionEnabled, links }), [isTenantSubscriptionEnabled, links]);
}

/**
 * Returns all available subscription plans for the tenant
 */
export function useSubscriptionPlans() {
    const { isTenantSubscriptionEnabled } = useTenantSubscriptionConfig();
    const { data: subscriptionPlans, isLoading } = useQuery(
        SUBSCRIPTION_PLANS_CACHE_KEY,
        () => subscriptionsRepository.getSubscriptionPlans(),
        {
            enabled: isTenantSubscriptionEnabled,
        },
    );

    return { isTenantSubscriptionEnabled, subscriptionPlans, isLoading };
}

/**
 * Returns the last subscription of the client, it could be the current active one or the last that was active.
 */
export function useLastClientSubscription(forcePullFromRecurly = false) {
    const { isTenantSubscriptionEnabled } = useTenantSubscriptionConfig();

    const { data: lastSubscription, isLoading } = useQuery(
        SUBSCRIPTIONS_CACHE_KEY,
        () => fetchClientSubscriptions(forcePullFromRecurly),
        {
            select: (subscriptions) => {
                return subscriptions[0];
            },
            enabled: isTenantSubscriptionEnabled,
        },
    );

    return {
        isTenantSubscriptionEnabled,
        lastSubscription,
        isActive: lastSubscription?.state === SubscriptionState.ACTIVE,
        isCanceled: lastSubscription?.state === SubscriptionState.CANCELED,
        isLoading,
    };
}

/**
 * Returns the plan price for the authenticated client.
 */
export function useSubscriptionPlanPrice(planCode: string, onErrorCallback: () => void) {
    const { data: planPrice, isLoading } = useQuery(
        SUBSCRIPTION_PLAN_PRICE_CACHE_KEY,
        () => subscriptionsRepository.getSubscriptionPlanPrice(planCode),
        {
            onError: onErrorCallback,
        },
    );

    return {
        planPrice,
        isLoading,
    };
}
