import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from 'react-query';

import { Typography } from '@shipengine/giger';
import { InsuranceSelector } from '@packlink/insurance-selector';
import { AmplitudeTypeOfLocations } from '@packlink/metrics';
import { IPrice, IShipment, ProPrice, ProShipment, Shipment } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { BulkSelectionInfo } from '@components/SidePanel/BulkSelectionInfo';
import { AmplitudeEvents, AmplitudeProperties, FLOW_AMPLITUDE_PROPERTY } from '@constants/amplitude';

import { getIsPendingSectionInbox, getSelectedShipments } from '@store/selectors/bulk';
import { UpsellInsuranceType } from '@types';
import { ProductOptionsInsurances, UpsellUtils } from '@utils/UpsellUtils';

import { useShipmentsUpdate } from '../../hooks/useShipmentsUpdate';
import { ShipmentPanelFooter } from '../../ShipmentPanelFooter';
import { shipmentPanelFormStyles } from '../../ShipmentPanelStyles';
import { useBulkInsuranceBasePrice } from './hooks/useBulkInsuranceBasePrice';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IShipmentPanelBulkInsuranceProps {
    onGoBack: () => void;
    isPaymentOrigin?: boolean;
}

export const ShipmentPanelBulkInsurance = (props: IShipmentPanelBulkInsuranceProps): JSX.Element => {
    const { onGoBack, isPaymentOrigin } = props;
    const {
        cdn: { url: cdnUrl },
    } = config;
    const { t } = useTranslation();
    const { sendAmplitudeEvent, sendAmplitudeSidebarClickEvent } = useAmplitude();
    const queryClient = useQueryClient();
    const selectedShipments = useSelector(getSelectedShipments);
    const isCurrentInboxPending = useSelector(getIsPendingSectionInbox);
    const { mutateShipments, isWaitingUpdate, messageProps, isSuccess } = useShipmentsUpdate(isCurrentInboxPending);
    const [selectedInsurance, setSelectedInsurance] = useState<UpsellInsuranceType>(
        UpsellInsuranceType.PACKLINK_INSURANCE,
    );

    if (isSuccess && isPaymentOrigin) {
        onGoBack();
    }

    const shipments = useMemo(
        (): Shipment<IShipment>[] => selectedShipments.map((s: ProShipment) => s.data),
        [selectedShipments],
    );

    const bulkInsurancePrice = useBulkInsuranceBasePrice(shipments);

    const insurancesToDisplay: ProductOptionsInsurances = {
        [UpsellInsuranceType.PACKLINK_INSURANCE]: { basePrice: bulkInsurancePrice },
        [UpsellInsuranceType.NO_INSURANCE]: {},
    };

    const sendPanelEvent = (data: Record<string, unknown>): void => {
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.SIDEBAR_INSURE_SHIPMENTS_BULK, data);
    };

    const onChangeInsurance = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
        sendPanelEvent({
            [AmplitudeProperties.INSURANCE]: value,
        });
        sendAmplitudeEvent(AmplitudeEvents.CLICK_ON_INSURANCE, {
            [AmplitudeProperties.INSURANCE]: value,
            [AmplitudeProperties.FLOW]: FLOW_AMPLITUDE_PROPERTY.SIDE_PANEL,
            [AmplitudeProperties.LOCATION]: AmplitudeTypeOfLocations.ADDRESS_STEP,
        });
        setSelectedInsurance(value as UpsellInsuranceType);
    };

    const updateShipments = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const shipmentsPrice = queryClient.getQueryData<ProPrice>('bulkInsurancePrice');

        const pricesByRef: Record<string, IPrice> = {};
        shipmentsPrice?.shipmentsPriceDetails.forEach(
            (shipmentPrice: IPrice) => (pricesByRef[shipmentPrice.packlinkReference as string] = shipmentPrice),
        );
        const newShipments = selectedShipments.map((shipment: ProShipment): Shipment => {
            const hasInsurance = selectedInsurance === UpsellInsuranceType.PACKLINK_INSURANCE;

            // No need to perform any update if the upsell is already what we want to set
            if (
                (hasInsurance && shipment.data.upsales?.insurance?.available) ||
                (!hasInsurance && !shipment.data.upsales?.insurance?.available)
            ) {
                return shipment.data;
            }

            const newShipment = UpsellUtils.setShipmentInsurance(
                shipment.data,
                pricesByRef[shipment.data.packlinkReference]?.availableProducts,
                hasInsurance,
            );
            return newShipment;
        });

        mutateShipments(newShipments, {
            onSuccess: ({ shipmentsUpdated }) => {
                sendAmplitudeEvent(AmplitudeEvents.SIDEBAR_INSURE_SHIPMENTS_BULK, {
                    [AmplitudeProperties.QUANTITY_INSURED]: shipmentsUpdated.length,
                });
            },
        });
    };

    return (
        <>
            {isWaitingUpdate ? (
                <form css={shipmentPanelFormStyles(true)} onSubmit={updateShipments}>
                    <BulkSelectionInfo
                        count={selectedShipments.length}
                        actionsTitle="bulk-panel.insurance.info-title"
                        showBackground
                        hideMargins
                    />

                    <SidePanelContent>
                        <Typography variant="body2" component="p">
                            {t('bulk-panel.insurance.content-text')}
                        </Typography>
                        <InsuranceSelector
                            currency={selectedShipments[0].data.currency}
                            name="insurance"
                            imageUrl={`${cdnUrl}/pro/statics/images/insurance-box.svg`}
                            onChange={onChangeInsurance}
                            insurancesToDisplay={insurancesToDisplay}
                            insuranceTypeSelected={selectedInsurance}
                            hasVerticalLayout
                            numberShipmentsInBulk={selectedShipments.length}
                            contentValue={0}
                        />
                    </SidePanelContent>

                    <ShipmentPanelFooter
                        onExit={onGoBack}
                        cancelButtonLabel={t('shipment-panel.actions.back')}
                        isSaveDisabled={!selectedInsurance}
                    />
                </form>
            ) : (
                <SidePanelContent>
                    <SidePanelMessage {...messageProps} />
                </SidePanelContent>
            )}
        </>
    );
};
