import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { Icon, IconSize, Tag, TagColor, TagVariant } from '@shipengine/giger';

import { getFooterLabelIconStyles, getFooterLabelStyles } from './ShipmentRowFooterLabelStyles';

interface IShipmentRowFooterLabelProps {
    isLabelDownloaded: boolean;
}

export const ShipmentRowFooterLabel = ({ isLabelDownloaded }: IShipmentRowFooterLabelProps): JSX.Element => {
    const { t } = useTranslation();

    const [color, translationKey] = isLabelDownloaded
        ? [TagColor.SUCCESS, 'shipment-row.footer.label-managed']
        : [TagColor.GRAY, 'shipment-row.footer.label-no-managed'];

    return (
        <div css={getFooterLabelStyles}>
            {isLabelDownloaded && (
                <Icon css={getFooterLabelIconStyles} name={IconNames.CHECK_MARK} size={IconSize.SIZE_SMALL} />
            )}

            <Tag color={color} variant={TagVariant.OUTLINED}>
                {t(translationKey)}
            </Tag>
        </div>
    );
};
