import { FC } from 'react';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { IntegrationFormLogo } from '@Integrations/components/IntegrationForm/IntegrationFormLogo';
import { IGenericPanelContentProps } from '@Integrations/components/IntegrationPanel/types';
import { useTranslation } from '@packlink/translation-provider';

import { OnboardingPartnerIntegrationGuide } from './OnboardingPartnerIntegrationGuide';

type IOnboardingPartnerIntegrationFormProps = Omit<
    IGenericPanelContentProps,
    'onSubmit' | 'onDeactivate' | 'isLoading' | 'error' | 'clearError'
>;

export const OnboardingPartnerIntegrationForm: FC<IOnboardingPartnerIntegrationFormProps> = (
    props: IOnboardingPartnerIntegrationFormProps,
): JSX.Element => {
    const { integration, onClose, onCancel } = props;

    const { t } = useTranslation();

    const handleCancel = (): void => {
        onCancel(integration.name);
    };

    return (
        <>
            <SidePanelHeader onAction={onClose} title={t('integrations.panel.show-guide')} />
            <SidePanelContent>
                <IntegrationFormLogo alt={integration.name} logo={integration.logo} />
                <OnboardingPartnerIntegrationGuide partner={integration.name} />
            </SidePanelContent>
            <SidePanelFooter>
                <SidePanelFooterActions
                    cancelText={t('integrations.panel.cancel')}
                    onCancel={handleCancel}
                    showActionButton={false}
                />
            </SidePanelFooter>
        </>
    );
};
