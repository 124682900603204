import { Inbox } from '@packlink/packlink-sdk';
import { AppSidebarInboxItem } from './AppSidebarInboxItem';

export interface ISidebarInbox {
    inbox: Inbox;
    childrenInbox?: IAppSidebarInboxProps[];
}

type IAppSidebarInboxProps = ISidebarInbox;

export function AppSidebarInbox({ inbox, childrenInbox }: IAppSidebarInboxProps): JSX.Element {
    const inboxRoute = childrenInbox?.[0].inbox || inbox;

    return (
        <>
            <AppSidebarInboxItem inbox={inbox} inboxRoute={inboxRoute} hasChildren={!!childrenInbox} />

            {childrenInbox?.map((childInbox: ISidebarInbox) => (
                <AppSidebarInboxItem
                    key={childInbox.inbox}
                    inbox={childInbox.inbox}
                    inboxRoute={childInbox.inbox}
                    isChild
                />
            ))}
        </>
    );
}
