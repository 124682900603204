import { ResponsiveBar as ResponsiveBarNivo, BarDatum } from '@nivo/bar';
import { ResponsiveBarProps } from '../../types';
import { useBarChartConfig } from '../../hooks/useChartConfig';
import { responsiveContainerStyles } from '../Layout/ChartStyles';

export function ResponsiveBar<RawDatum extends BarDatum>(props: ResponsiveBarProps<RawDatum>): JSX.Element {
    const chartConfig = useBarChartConfig(props);

    return (
        <div css={responsiveContainerStyles} id={props.id}>
            <ResponsiveBarNivo {...chartConfig} />
        </div>
    );
}
