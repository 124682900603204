import { first, isEmpty } from 'lodash';
import React from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { AnySupportTicket, ApiClientError, IShipment, Shipment, SupportTicketType } from '@packlink/packlink-sdk';
import Packlink from '@sdk';
import { useDebouncedValue } from '@SupportCenter/utils/useDebouncedValue';
import { onReadOnlyQueryRetry } from '@SupportCenter/utils/reactQuery';
import { useTenantFeatures, CancellationTimeLimitFeature } from '@SupportCenter/utils/tenantFeatures';
import {
    SupportCenterShipmentErrorData,
    SupportCenterTicketAlreadyCreatedError,
} from '../SupportCenterShipmentError/SupportCenterShipmentErrorData';

type FormWithShipmentData = {
    shipment: Shipment<IShipment>;
};

export const FormWithShipmentContext = React.createContext<FormWithShipmentData>({} as FormWithShipmentData);

/**
 * Function that validates a shipment an returns an error message if found
 */
export type ShipmentValidateFunction = (
    shipment: Shipment,
    cancellationTimeLimit?: CancellationTimeLimitFeature,
) => Promise<void> | void;

type UseGetShipmentParams = {
    shipmentReference?: string;
    validateShipment?: ShipmentValidateFunction;
    ticketType: SupportTicketType;
};

export function useGetShipment({
    shipmentReference,
    validateShipment,
    ticketType,
}: UseGetShipmentParams): UseQueryResult<Shipment<IShipment>, SupportCenterShipmentErrorData> {
    const debouncedValue = useDebouncedValue(shipmentReference, 500);
    const { cancellationTimeLimit } = useTenantFeatures();

    return useQuery<Shipment<IShipment>, ApiClientError>(
        ['shipment', debouncedValue, ticketType],
        async () => {
            const shipment = (await Packlink.v1.shipments.getShipment(debouncedValue as string)) as Shipment<IShipment>;

            await validateIfHasOpenTicket({
                shipmentReference: shipment.packlinkReference,
                type: ticketType,
            });

            if (validateShipment) {
                await validateShipment(shipment, cancellationTimeLimit);
            }

            return shipment;
        },
        {
            enabled: !!debouncedValue,
            retry: onReadOnlyQueryRetry,
        },
    );
}
async function validateIfHasOpenTicket({
    shipmentReference,
    type,
}: {
    shipmentReference: string;
    type: SupportTicketType;
}): Promise<SupportCenterShipmentErrorData | undefined> {
    const shipmentTickets = await Packlink.v1.supportCenter.list({ shipmentReference, type });
    if (isEmpty(shipmentTickets)) {
        return;
    }

    throw new SupportCenterTicketAlreadyCreatedError(first(shipmentTickets) as AnySupportTicket);
}
