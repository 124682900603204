import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getPudoDialogStyles = (theme: Theme): CSSObject => ({
    width: '100%',
    height: '100%',

    [theme.mediaQuery('tablet')]: {
        width: theme.toPercentage(10 / 12),
    },

    [theme.mediaQuery('desktop')]: {
        width: theme.toPercentage(8 / 12),
    },
});

export const getPudoDialogContentStyles =
    (expanded: boolean) =>
    (theme: Theme): CSSObject => ({
        height: '100%',
        textAlign: 'left',

        ...(expanded && {
            li: {
                marginRight: theme.spacing(4),
            },
        }),
    });

export const getPudoDialogHeaderStyles = (): CSSObject => ({
    border: 0,
});
