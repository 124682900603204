import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useEvent } from '@packlink/event-handler';
import { triggerDownload } from '@utils/download';
import { AppDispatch } from '@store';
import { setAppStatus } from '@store/actions/status';
import { EventName, IShipmentsReportEvent } from '@types';

export function useShipmentsReport(clientId: string) {
    const { eventBind, eventUnbind } = useEvent<IShipmentsReportEvent>(clientId, EventName.SHIPMENTS_REPORT);
    const dispatch = useDispatch<AppDispatch>();

    const onDownloadReady = useCallback(
        ({ url }: IShipmentsReportEvent) => {
            url && triggerDownload(url);
            dispatch(setAppStatus({ fetchingReport: false }));
        },
        [dispatch],
    );

    useEffect(() => {
        eventBind(onDownloadReady);

        return () => {
            eventUnbind(onDownloadReady);
        };
    }, [eventBind, eventUnbind, onDownloadReady]);
}
