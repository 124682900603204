import { InlineNotification, NotificationType, Typography, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { ServiceUtils } from '@utils/ServiceUtils';
import { ProShipment } from '@packlink/packlink-sdk';
import { getWarningStyles } from './ShipmentDetailsCarrierWarningsStyles';

type ShipmentDetailsCarrierWarningsProps = WithCommonProps<{
    shipment: ProShipment;
}>;

export function ShipmentDetailsCarrierWarnings({
    shipment,
    ...props
}: ShipmentDetailsCarrierWarningsProps): JSX.Element | null {
    const { t } = useTranslation();
    const carrier = shipment.data.service?.carrierName as string;
    const warning = ServiceUtils.getCarrierShipmentWarnings(carrier);

    if (!warning) {
        return null;
    }

    const content = warning.warnings.map((key) => (
        <Typography css={getWarningStyles} key={key} component="span">
            {t(key)}
        </Typography>
    ));

    return (
        <InlineNotification type={NotificationType.ALERT} title={t(warning.title)} {...props}>
            {content}
        </InlineNotification>
    );
}
