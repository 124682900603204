import React from 'react';
import { Integration } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { IntegrationConfigurations } from '@Integrations/components/IntegrationPanel/types';
import { ButtonDropdown } from '@components/ButtonDropdown/ButtonDropdown';
import { ButtonDropdownItem } from '@components/ButtonDropdown/ButtonDropdownItem';
import { AppHeaderActionsItems } from './AppHeaderActionsItems';

export interface IAppHeaderActionsProps {
    onOpenImportsModal: (integrationName: Integration<IntegrationConfigurations>) => void;
    onOpenImportCSVModal: () => void;
    onAddShipment: (text: string) => void;
    onCreateShipment: () => void;
}

export const AppHeaderActions: React.FC<IAppHeaderActionsProps> = (props: IAppHeaderActionsProps): JSX.Element => {
    const { onOpenImportsModal, onAddShipment, onCreateShipment, onOpenImportCSVModal } = props;
    const { t } = useTranslation();

    const buttonLabel = t('header.actions.new-shipment');

    return (
        <ButtonDropdown label={buttonLabel} onClick={() => onAddShipment(buttonLabel)}>
            <AppHeaderActionsItems
                as={ButtonDropdownItem}
                onOpenImportsModal={onOpenImportsModal}
                onOpenImportCSVModal={onOpenImportCSVModal}
                onCreateShipment={onCreateShipment}
            />
        </ButtonDropdown>
    );
};
