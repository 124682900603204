import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketChatNotificationContentStyles = css({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
});
export const getTicketChatNotificationMessageStyles = (theme: Theme): SerializedStyles =>
    css({
        flex: '1 1 auto',
        marginRight: theme.spacing(4),
    });
