import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

import { PrintingPreferencesSize } from './PrintingOption';

export const getContainerStyles =
    (selected = false) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
            borderRadius: theme.borderRadius.M,
            border: `1px solid ${theme.palette.gray.main}`,
            padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,

            ...(selected && {
                borderColor: theme.palette.white,
                backgroundColor: theme.palette.primary.main,
            }),
        });

export const getOptionStyles = (): SerializedStyles =>
    css({
        width: 0,
        height: 0,
        position: 'absolute',
    });

export const getImageStyles =
    (size: PrintingPreferencesSize) =>
    (theme: Theme): SerializedStyles =>
        css({
            width: size === PrintingPreferencesSize.LARGE ? theme.spacing(8) : theme.spacing(4),
        });

export const getLabelStyles =
    (selected = false, size: PrintingPreferencesSize) =>
    (theme: Theme): SerializedStyles =>
        css({
            marginBottom: 0,
            marginTop: theme.spacing(),
            color: theme.palette.gray.main,

            ...(selected && {
                color: theme.palette.white,
            }),

            ...(size === PrintingPreferencesSize.LARGE
                ? {
                      fontWeight: theme.typography.fontWeight.bold,
                      fontSize: theme.toRem(theme.typography.fontSize.S),
                  }
                : {
                      fontWeight: theme.typography.fontWeight.normal,
                      fontSize: theme.toRem(theme.typography.fontSize.XS),
                  }),
        });
