import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export function getShipmentRowTrackingDetailsLoadingStyles(theme: Theme): CSSObject {
    return {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(4),
    };
}
