import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, ButtonColor, ButtonSize, ButtonVariant, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { AmplitudeEvents, AmplitudeEventPrefixes } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';
import { getCurrentInbox } from '@store/selectors/inbox';
import { inboxToRoute } from '@pages/router/utils/paths';

type ReturnToTableViewProps = WithCommonProps<unknown>;

export function ReturnToTableView(props: ReturnToTableViewProps): JSX.Element {
    const { t } = useTranslation();
    const { onClick } = useReturnToTableViewHandlers();

    return (
        <Button
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.SMALL}
            color={ButtonColor.PRIMARY}
            isFullWidth
            onClick={onClick}
            {...props}
        >
            {t('post-sale.actions.return-to-table')}
        </Button>
    );
}

function useReturnToTableViewHandlers() {
    const navigate = useNavigate();
    const { sendAmplitudeEvent } = useAmplitude();
    const currentInbox = useSelector(getCurrentInbox);

    const onClick = () => {
        navigate(inboxToRoute(currentInbox));
        sendAmplitudeEvent(`${AmplitudeEvents.BACK} ${AmplitudeEventPrefixes.CONFIRMATION_PAGE}` as AmplitudeEvents);
    };

    return {
        onClick,
    };
}
