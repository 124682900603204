import { useCallback } from 'react';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import omitBy from 'lodash.omitby';
import isNil from 'lodash.isnil';
import {
    AmplitudeEventPrefixes,
    AmplitudeEvents,
    AmplitudePayload,
    AmplitudeProperties,
    AmplitudePropertiesPro,
} from '@constants/amplitude';
import { Parcel, Shipment } from '@packlink/packlink-sdk';
import { IAmplitudeShipment } from '@types';
import { ProviderName } from '@packlink/metrics';
import { useMetrics } from '@providers/MetricsProvider';
import { getDirectOwnContract } from '@store/selectors/payment';
import { getUserId } from '@store/selectors/user';
import { useTranslation } from '@packlink/translation-provider';
import { TenantUtils } from '@utils/tenant';
import { ISetUserPropertiesArray } from '@packlink/metrics/dist/lib/providers/Provider';

export type AmplitudeAction = (eventName: AmplitudeEvents | string, data?: Record<string, unknown>) => void;

export type AmplitudeFunctions = {
    sendAmplitudeEvent: AmplitudeAction;
    setAmplitudeUserProperties: (props: ISetUserPropertiesArray[]) => void;
    setAmplitudeUserInfo: () => void;
    sendAmplitudeChooseEvent: AmplitudeAction;
    sendAmplitudeEnterEvent: AmplitudeAction;
    sendAmplitudeShipmentEvent: (
        eventName: AmplitudeEvents,
        shipmentData: Shipment,
        data?: Record<string, unknown>,
    ) => void;
    sendAmplitudeSidebarClickEvent: AmplitudeAction;
    sendAmplitudeSidebarEvent: AmplitudeAction;
    sendAmplitudeTableViewEvent: AmplitudeAction;
    sendAmplitudeTableViewClickEvent: AmplitudeAction;
    sendAmplitudeHeaderClickEvent: AmplitudeAction;
    sendAmplitudeClickEvent: AmplitudeAction;
    sendAmplitudeAnalyticsEvent: AmplitudeAction;
    sendSuffixedAmplitudeEvent: (
        action: AmplitudeAction,
        suffix: string,
    ) => (eventName: AmplitudeEvents, ...otherArgs: Record<string, unknown>[]) => void;
    setAmplitudeFeatureFlags: (flags?: LDFlagSet | null) => void;
};

export const getAmplitudeClickEventName = (eventName: string): AmplitudeEvents =>
    `${AmplitudeEventPrefixes.CLICK} ${eventName}` as AmplitudeEvents;

export const getAmplitudeTableViewClickEventName = (eventName: AmplitudeEvents | string): AmplitudeEvents =>
    `${AmplitudeEventPrefixes.TABLE_VIEW_CLICK} ${eventName}` as AmplitudeEvents;

export const getShipmentAmplitudePayload = (locale: string, shipmentData: Shipment): Partial<IAmplitudeShipment> => {
    const carrier = shipmentData.service?.carrierName;
    const fromCountry = shipmentData.from?.state;
    const fromZipCode = shipmentData.from?.zipCode;
    const packages = shipmentData.parcels;
    const totalPrice = shipmentData.price?.price?.totalPrice;
    const service = shipmentData.service?.name;
    const source = shipmentData.source;
    const toCountry = shipmentData.to?.state;
    const toZipCode = shipmentData.to?.zipCode;

    const shipmentTrackData: Record<string, string | number | undefined> = {
        [AmplitudeProperties.FROM_COUNTRY]: fromCountry,
        [AmplitudeProperties.POSTCODE_FROM]: fromZipCode,
        [AmplitudeProperties.TO_COUNTRY]: toCountry,
        [AmplitudeProperties.POSTCODE_TO]: toZipCode,
        [AmplitudeProperties.CARRIER]: carrier,
        [AmplitudeProperties.SERVICE]: service,
        [AmplitudeProperties.PRICE]: totalPrice,
        [AmplitudeProperties.LANGUAGE]: locale,
        [AmplitudeProperties.PACKAGES]: packages?.length,
        [AmplitudePropertiesPro.INTEGRATION_SOURCE]: source,
    };

    packages &&
        packages.forEach((item: Parcel, index: number) => {
            shipmentTrackData[`${AmplitudeProperties.WEIGHT} ${index + 1}`] = item.weight;
            shipmentTrackData[`${AmplitudeProperties.LENGTH} ${index + 1}`] = item.length;
            shipmentTrackData[`${AmplitudeProperties.WIDTH} ${index + 1}`] = item.width;
            shipmentTrackData[`${AmplitudeProperties.HEIGHT} ${index + 1}`] = item.height;
        });

    // TODO: FRON-296 configure amplitude logLevel and remove omitBy.
    return omitBy(shipmentTrackData, isNil);
};

export function getViewAmplitudePayload(locale: string): AmplitudePayload {
    return {
        [AmplitudeProperties.LANGUAGE]: locale,
        [AmplitudeProperties.URL]: window.location.pathname,
    };
}

export function useAmplitude(): AmplitudeFunctions {
    const metrics = useMetrics();
    const {
        i18n: { language: locale },
    } = useTranslation();
    const userId = useSelector(getUserId);
    const ownContract = useSelector(getDirectOwnContract);
    const hasOwnContract = !!ownContract;

    function standardisedPathname() {
        return window.location.pathname.replace(/([A-Z]+[0-9]+[A-Z]+[0-9]+)/, 'shipmentRef');
    }

    const trackEvent = useCallback(
        (eventName: string, payload?: Record<string, unknown>): void => {
            metrics.track({
                eventName,
                payload: {
                    [AmplitudeProperties.URL_PATH]: standardisedPathname(),
                    ...payload,
                },
                providerName: ProviderName.AMPLITUDE,
            });
        },
        [metrics],
    );

    const setAmplitudeUserProperties = useCallback(
        (props: ISetUserPropertiesArray[]): void =>
            metrics.setUserProperties({
                properties: props,
                providerName: ProviderName.AMPLITUDE,
            }),
        [metrics],
    );

    const setAmplitudeUserInfo = useCallback((): void => {
        userId && metrics.setUserId({ providerName: ProviderName.AMPLITUDE, userId });
        setAmplitudeUserProperties([
            { key: AmplitudeProperties.TENANT, value: TenantUtils.getTenantName() },
            { key: AmplitudeProperties.PLATFORM, value: TenantUtils.getPlatform() },
            { key: AmplitudeProperties.PLATFORM_COUNTRY, value: TenantUtils.getPlatformCountry().toUpperCase() },
            { key: AmplitudeProperties.OWN_CONTRACT, value: hasOwnContract },
        ]);
    }, [metrics, hasOwnContract, setAmplitudeUserProperties, userId]);

    const sendAmplitudeChooseEvent = useCallback(
        (eventName: AmplitudeEvents | string, data?: Record<string, unknown>): void => {
            const event = `${AmplitudeEventPrefixes.CHOOSE} ${eventName}` as AmplitudeEvents;
            trackEvent(event, data);
        },
        [trackEvent],
    );

    const sendAmplitudeEnterEvent = useCallback(
        (eventName: AmplitudeEvents | string, data?: Record<string, unknown>): void => {
            const event = `${AmplitudeEventPrefixes.ENTER} ${eventName}` as AmplitudeEvents;
            trackEvent(event, data);
        },
        [trackEvent],
    );

    const sendAmplitudeShipmentEvent = useCallback(
        (eventName: AmplitudeEvents, shipmentData: Shipment, data?: Record<string, unknown>): void => {
            const shipmentTrackData = getShipmentAmplitudePayload(locale, shipmentData);
            const payload = {
                ...shipmentTrackData,
                ...data,
            };
            trackEvent(eventName, payload);
        },
        [locale, trackEvent],
    );

    const sendAmplitudeSidebarClickEvent = useCallback(
        (eventName: AmplitudeEvents | string, data?: Record<string, unknown>): void => {
            const event = `${AmplitudeEventPrefixes.SIDEBAR_CLICK} ${eventName}` as AmplitudeEvents;
            trackEvent(event, data);
        },
        [trackEvent],
    );

    const sendAmplitudeSidebarEvent = useCallback(
        (eventName: AmplitudeEvents | string, data?: Record<string, unknown>): void => {
            const event = `${AmplitudeEventPrefixes.SIDEBAR} ${eventName}` as AmplitudeEvents;
            trackEvent(event, data);
        },
        [trackEvent],
    );

    const sendAmplitudeTableViewEvent = useCallback(
        (eventName: AmplitudeEvents | string, data?: Record<string, unknown>): void => {
            const event = `${AmplitudeEventPrefixes.TABLE_VIEW} ${eventName}` as AmplitudeEvents;
            trackEvent(event, data);
        },
        [trackEvent],
    );

    const sendAmplitudeTableViewClickEvent = useCallback(
        (eventName: AmplitudeEvents | string, data?: Record<string, unknown>): void => {
            const event = getAmplitudeTableViewClickEventName(eventName);
            trackEvent(event, data);
        },
        [trackEvent],
    );

    const sendAmplitudeHeaderClickEvent = useCallback(
        (eventName: AmplitudeEvents | string, data?: Record<string, unknown>): void => {
            const event = `${AmplitudeEventPrefixes.HEADER_CLICK} ${eventName}` as AmplitudeEvents;
            trackEvent(event, data);
        },
        [trackEvent],
    );

    const sendAmplitudeClickEvent = useCallback(
        (eventName: AmplitudeEvents | string, data?: Record<string, unknown>): void => {
            const event = getAmplitudeClickEventName(eventName);
            trackEvent(event, data);
        },
        [trackEvent],
    );

    const sendAmplitudeAnalyticsEvent = useCallback(
        (eventName: AmplitudeEvents | string, data?: Record<string, unknown>): void => {
            const event = `${AmplitudeEventPrefixes.ANALYTICS} ${eventName}` as AmplitudeEvents;
            trackEvent(event, data);
        },
        [trackEvent],
    );

    const sendSuffixedAmplitudeEvent = useCallback(
        (action: AmplitudeAction, suffix: string) =>
            (eventName: AmplitudeEvents, ...otherArgs: Record<string, unknown>[]): void =>
                action(`${eventName} ${suffix}` as AmplitudeEvents, ...otherArgs),
        [],
    );

    const setAmplitudeFeatureFlags = useCallback(
        (flags?: LDFlagSet | null): void => {
            if (!metrics.providers[ProviderName.AMPLITUDE]) return;

            metrics.providers[ProviderName.AMPLITUDE].commonPayload = {
                ...metrics.providers[ProviderName.AMPLITUDE].commonPayload,
                [AmplitudeProperties.FEATURE_FLAGS]: flags,
            };
        },
        [metrics.providers],
    );

    return {
        sendAmplitudeEvent: trackEvent,
        setAmplitudeUserProperties,
        setAmplitudeUserInfo,
        sendAmplitudeChooseEvent,
        sendAmplitudeEnterEvent,
        sendAmplitudeShipmentEvent,
        sendAmplitudeSidebarClickEvent,
        sendAmplitudeSidebarEvent,
        sendAmplitudeTableViewEvent,
        sendAmplitudeTableViewClickEvent,
        sendAmplitudeHeaderClickEvent,
        sendAmplitudeClickEvent,
        sendAmplitudeAnalyticsEvent,
        sendSuffixedAmplitudeEvent,
        setAmplitudeFeatureFlags,
    };
}
