import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '@packlink/translation-provider';
import { IClient, PaymentMethod, PaymentType } from '@packlink/packlink-sdk';
import {
    IAmplitude3Dsecure,
    IAmplitudeLocalPayment,
    PaymentOptions as PhaviourPaymentOptions,
    IPaymentOptionsProps as PhaviourIPaymentOptionsProps,
} from '@packlink/payment-options';

import { getClientData } from '@store/selectors/client';
import {
    getDeferredCreditCard,
    getDeferredPaypal,
    getDirectCreditCard,
    getDirectPaypal,
    getIsPaymentLoading,
} from '@store/selectors/payment';
import { AppDispatch } from '@store';
import { IPaymentCallbacksProps, usePaymentCallbacks } from './hooks/usePaymentCallbacks';
import { usePaymentMethods } from './hooks/usePaymentMethods';
import { FreePayment } from './FreePayment';
import { freePaymentStyles } from './FreePaymentStyles';

export interface OnPayProps {
    type: PaymentType;
    method: PaymentMethod;
    nonce?: string;
    metrics?: IAmplitude3Dsecure | IAmplitudeLocalPayment;
    deviceData?: string;
    carriersTermsAndConditions?: boolean;
}

export type IPaymentOptionsProps = Pick<IPaymentCallbacksProps, 'section' | 'onError' | 'onDelete'> &
    Pick<PhaviourIPaymentOptionsProps, 'payButtonTexts' | 'allowedPaymentMethods'> & {
        isDeferred?: boolean;
        isPaymentBlocked?: boolean;
        currency?: string;
        amount?: number;
        voucherPercentage?: number;
        isPaying: boolean;
        title?: React.ReactNode;
        onPay: (props: OnPayProps) => void;
        onStart?: () => void;
        onCheckForExternalError?: () => void;
    };

export const PaymentOptions = ({
    isDeferred = false,
    isPaymentBlocked = false,
    allowedPaymentMethods,
    currency = 'EUR',
    amount = 0,
    voucherPercentage,
    isPaying,
    section,
    title,
    payButtonTexts,
    onCheckForExternalError,
    onError,
    onDelete,
    onPay,
    onStart,
}: IPaymentOptionsProps): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const client = useSelector(getClientData);
    const isPaymentLoading = useSelector(getIsPaymentLoading);
    const creditCardDirect = useSelector(getDirectCreditCard);
    const creditCardDeferred = useSelector(getDeferredCreditCard);
    const paypalDirect = useSelector(getDirectPaypal);
    const paypalDeferred = useSelector(getDeferredPaypal);

    const creditCard = isDeferred ? creditCardDeferred : creditCardDirect;
    const paypal = isDeferred ? paypalDeferred : paypalDirect;

    const sendOrder = (
        type: PaymentType,
        method: PaymentMethod,
        nonce?: string,
        metrics?: IAmplitude3Dsecure | IAmplitudeLocalPayment,
        deviceData?: string,
    ): void => {
        onPay({ type, method, nonce, metrics, deviceData });
    };

    const { handleRequestClientNonce, handleDeletePaymentMethod, handlePaymentError, handlePaymentStart, handleOrder } =
        usePaymentCallbacks({ dispatch, sendOrder, onError, onDelete, section });

    const { availablePaymentMethods, selectedPaymentMethod } = usePaymentMethods(
        !!creditCard,
        !!paypal,
        allowedPaymentMethods,
    );

    const isFreePayment = useMemo(() => {
        return amount === 0 && voucherPercentage === 100;
    }, [amount, voucherPercentage]);

    const handleStart = (paymentMethod: PaymentMethod) => {
        onStart?.();
        handlePaymentStart(paymentMethod);
    };

    return (
        <>
            {isFreePayment ? (
                <div css={freePaymentStyles}>
                    <FreePayment onPay={onPay} />
                </div>
            ) : (
                <>
                    {title}

                    <PhaviourPaymentOptions
                        currency={currency}
                        locale={locale}
                        allowedPaymentMethods={allowedPaymentMethods || availablePaymentMethods}
                        fallbackOptions={{
                            // We don't want search params for fallback URL
                            url: window.location.origin + window.location.pathname,
                            buttonText: t('payment.local-payment.fallback-button', {
                                pageTitle: document.title,
                            }),
                        }}
                        amount={amount}
                        creditCard={creditCard}
                        paypal={paypal}
                        cdnUrl={config.cdn.baseUrl}
                        client={client.toJSON() as IClient}
                        selectedPaymentMethod={selectedPaymentMethod}
                        isPaymentBlocked={isPaymentBlocked}
                        isProcessing={isPaymentLoading || isPaying || !amount}
                        payButtonTexts={payButtonTexts}
                        onCheckForExternalError={onCheckForExternalError}
                        onVerified={handleOrder}
                        onError={handlePaymentError}
                        onStart={handleStart}
                        onRequestClientNonce={handleRequestClientNonce}
                        onDeletePaymentMethod={handleDeletePaymentMethod}
                    />
                </>
            )}
        </>
    );
};
