import { useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonColor, ButtonSize, ButtonVariant, Icon, IconSize, Typography } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import {
    getInvoicingDetailsLineStyles,
    getInvoicingDetailsStyles,
    getInvoicingDetailsTitleCaptionStyles,
    getInvoicingDetailsTitleStyles,
} from './SubscriptionPanelInvoicingDetailsStyles';
import { getClientData, isBusinessClient } from '@store/selectors/client';

interface SubscriptionPanelInvoicingDetailsProps {
    onEdit: () => void;
}

export function SubscriptionPanelInvoicingDetails({ onEdit }: SubscriptionPanelInvoicingDetailsProps): JSX.Element {
    const { t } = useTranslation();
    const { email, taxId, address, zipCode, city, firstName, lastName, companyName } = useSelector(getClientData);
    const isBusiness = useSelector(isBusinessClient);
    const name = isBusiness ? companyName : `${firstName} ${lastName}`;

    return (
        <div css={getInvoicingDetailsStyles}>
            <div css={getInvoicingDetailsTitleStyles}>
                <Typography css={getInvoicingDetailsTitleCaptionStyles} component="p" variant="body1" bold>
                    {name}
                </Typography>
                <Button
                    variant={ButtonVariant.TEXT}
                    color={ButtonColor.PRIMARY}
                    size={ButtonSize.SMALL}
                    onClick={onEdit}
                >
                    <Icon name={IconNames.EDIT} size={IconSize.SIZE_SMALL} />
                    <Typography variant="small" bold>
                        {t('subscriptions.side-panel.invoicing-edit')}
                    </Typography>
                </Button>
            </div>
            {isBusiness && (
                <Typography css={getInvoicingDetailsLineStyles} component="p" variant="small">
                    {taxId}
                </Typography>
            )}
            <Typography css={getInvoicingDetailsLineStyles} component="p" variant="small">
                {email}
            </Typography>
            <Typography css={getInvoicingDetailsLineStyles} component="p" variant="small">
                {address}
            </Typography>
            <Typography css={getInvoicingDetailsLineStyles} component="p" variant="small">
                {zipCode} {city}
            </Typography>
        </div>
    );
}
