import { FastField, FieldProps } from 'formik';

import { Radio } from '@shipengine/formik-giger';
import { WithChildrenCommonProps } from '@shipengine/giger';
import { SupportTicketPaymentMethod } from '@packlink/packlink-sdk';

import { getFormInputStyles } from '../../../styles/FormStyles';
import { SupportTicketPaymentFieldData } from './SupportTicketPaymentFieldData';

import {
    getSupportTicketPaymentMethodFormWrapperStyles,
    getSupportTicketPaymentMethodSelectedStyles,
    getSupportTicketPaymentMethodStyles,
} from './SupportTicketPaymentMethodFieldStyles';

type SupportTicketPaymentMethodFieldProps = WithChildrenCommonProps<{
    initialData: SupportTicketPaymentFieldData['data'];
    type: SupportTicketPaymentMethod;
    label: string;
}> &
    FieldProps<SupportTicketPaymentFieldData>;

export function SupportTicketPaymentMethodField({
    field: { value: payment, name },
    form: { setFieldValue },
    initialData,
    children,
    type,
    label,
    ...otherProps
}: SupportTicketPaymentMethodFieldProps): JSX.Element {
    const onPaymentTypeChange = () => {
        setFieldValue(`${name}.data`, initialData);
    };

    const isSelected = payment.type === type;

    return (
        <fieldset
            css={[
                getSupportTicketPaymentMethodStyles,
                isSelected && getSupportTicketPaymentMethodSelectedStyles,
                getFormInputStyles,
            ]}
            {...otherProps}
        >
            <FastField component={Radio} value={type} onChange={onPaymentTypeChange} name={`${name}.type`}>
                {label}
            </FastField>
            {isSelected && <section css={getSupportTicketPaymentMethodFormWrapperStyles}>{children}</section>}
        </fieldset>
    );
}
