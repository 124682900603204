import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getInfoPanelStepChildrenStyle =
    (isLastStep: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            section: {
                display: 'flex',
                ...(!isLastStep && { marginBottom: theme.spacing() }),

                p: {
                    marginLeft: theme.spacing(),
                },
            },
        });
