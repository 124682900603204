import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';

import {
    Button,
    ButtonVariant,
    Dialog,
    DialogActions,
    DialogContent,
    DialogHeader,
    Icon,
    Spinner,
    useToast,
} from '@shipengine/giger';
import { useDeleteApiKey } from '@common/hooks/useApiKey';

import { getDialogIconStyles, getDialogStyles, getDialoParagraphStyles } from './GenerateApiKeyRemoveModalStyles';

interface IGenerateApiKeyRemoveModalProps {
    onCloseHandler: () => void;
}

export const GenerateApiKeyRemoveModal = ({ onCloseHandler }: IGenerateApiKeyRemoveModalProps): JSX.Element => {
    const { t } = useTranslation();
    const toast = useToast(t);
    const { deleteApiKey, isDeleteApiKeyLoading, isDeleteApiKeySuccess } = useDeleteApiKey();

    const removeApiKeyHandler = () => {
        deleteApiKey(undefined, {
            onError: () => {
                toast.error({ message: t('generate-api-key.create.error') });
            },
        });
    };

    return (
        <Dialog css={getDialogStyles} isOpen>
            <DialogHeader
                showClose
                closeAriaLabel="Close"
                onClose={onCloseHandler}
                title={
                    isDeleteApiKeySuccess
                        ? t('generate-api-key.modal.success-title')
                        : t('generate-api-key.modal.title')
                }
            />

            <DialogContent>
                {!isDeleteApiKeySuccess && (
                    <>
                        <Icon css={getDialogIconStyles} name={IconNames.ALERT} />
                        <p>{t('generate-api-key.modal.text-1')}</p>
                        <p css={getDialoParagraphStyles}>{t('generate-api-key.modal.text-2')}</p>
                    </>
                )}

                {isDeleteApiKeySuccess && <p>{t('generate-api-key.modal.revoke-success')}</p>}
            </DialogContent>

            {!isDeleteApiKeySuccess && (
                <DialogActions>
                    {isDeleteApiKeyLoading && <Spinner />}

                    {!isDeleteApiKeyLoading && (
                        <>
                            <Button onClick={removeApiKeyHandler}>{t('generate-api-key.modal.revoke-action')}</Button>

                            <Button variant={ButtonVariant.OUTLINED} onClick={onCloseHandler}>
                                {t('generate-api-key.modal.cancel-revoke-action')}
                            </Button>
                        </>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};
