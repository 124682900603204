import { useTranslation } from '@packlink/translation-provider';
import { useSelector } from 'react-redux';
import { getIsBulkActionsActive } from '@store/selectors/bulk';
import { Typography, WithCommonProps } from '@shipengine/giger';
import {
    getShipmentRowCellInteractiveTextStyles,
    getShipmentRowCellInteractiveTitleStyles,
    getShipmentRowEditBtnStyles,
} from './ShipmentRowStyles';

export type ShipmentRowCellEditProps = WithCommonProps<{
    title: string;
    isEditable: boolean;
    unprotected?: boolean;
}>;

export function ShipmentRowCellEdit({
    title,
    isEditable,
    unprotected,
    ...props
}: ShipmentRowCellEditProps): JSX.Element {
    const isBulkActionsActive = useSelector(getIsBulkActionsActive);
    const { t } = useTranslation();

    return isEditable && !isBulkActionsActive ? (
        <Typography component="p" variant="body2" css={getShipmentRowCellInteractiveTitleStyles} {...props}>
            <span css={getShipmentRowEditBtnStyles}>
                {unprotected ? t('shipment-row.actions.edit-protection') : t('shipment-row.actions.edit')}
            </span>
            <span css={getShipmentRowCellInteractiveTextStyles}>{title}</span>
        </Typography>
    ) : (
        <Typography component="p" variant="body2" css={getShipmentRowCellInteractiveTitleStyles} {...props}>
            {title}
        </Typography>
    );
}
