import { apiClient } from '@sdk';
import { ServiceAllParams, Parcel, IParcel, Service, ProShipment, ServiceRepository } from '@packlink/packlink-sdk';
import { logSdkError } from '@utils/logger';

type ServiceFlags = Record<
    'customsRequired' | 'hasDropoffOrigin' | 'hasDropoffDestination' | 'hasOwnContract',
    boolean
>;

type CarrierWarningKeys = {
    title: string;
    warnings: string[];
};

const colissimo = {
    'colissimo france': {
        insuranceException: 'LA_POSTE_INSURANCE_TEXT_23KG',
    },
    'colissimo france boîte aux lettres': {
        submissionTextString: 'LA_POSTE_SUBMISSION_BOITE_AUX_LETTRES',
        insuranceException: 'LA_POSTE_INSURANCE_TEXT_23KG',
    },
    'colissimo international boîte aux lettres': {
        submissionTextString: 'LA_POSTE_SUBMISSION_BOITE_AUX_LETTRES',
        insuranceException: 'LA_POSTE_INSURANCE_TEXT_INTERNATIONAL',
    },
    'colissimo international': {
        insuranceException: 'LA_POSTE_INSURANCE_TEXT_INTERNATIONAL',
    },
    'colissimo france boîte aux lettres avec recommandation r1': {
        submissionTextString: 'LA_POSTE_SUBMISSION_BOITE_AUX_LETTRES',
        insuranceException: 'LA_POSTE_INSURANCE_TEXT_50_23KG',
    },
    'colissimo france avec recommandation r1': {
        insuranceException: 'LA_POSTE_INSURANCE_TEXT_50_23KG',
    },
};

const serviceExceptions: Record<string, Record<string, string | Record<string, string>>> = {
    colissimo,
    default: {
        submissionTextString: 'IN_HOME',
    },
};

// TODO: PRO-406 see if we can move this to the service excel.
const carrierWarnings: Record<string, CarrierWarningKeys> = {
    dhl: {
        title: 'SERVICES_DHL_IT_SHIPMENT_DETAILS_WARNING_TITLE',
        warnings: [
            'SERVICES_DHL_IT_SHIPMENT_DETAILS_WARNING_TEXT1',
            'SERVICES_DHL_IT_SHIPMENT_DETAILS_WARNING_TEXT2',
            'SERVICES_DHL_IT_SHIPMENT_DETAILS_WARNING_TEXT3',
        ],
    },
};

const serviceRepository = new ServiceRepository(apiClient);

export class ServiceUtils {
    static getServiceTranslationKey(key: string, carrier?: string, service?: string): string {
        if (!carrier || !service) {
            return '';
        }

        const carrierName = carrier.toLowerCase();
        const serviceName = service.toLowerCase();
        const serviceExceptionsCarrier = serviceExceptions[carrierName];
        const serviceExceptionsService = serviceExceptionsCarrier?.[serviceName];
        const serviceExceptionsKey =
            typeof serviceExceptionsService === 'object' ? serviceExceptionsService[key] : undefined;

        return (serviceExceptionsKey ||
            serviceExceptionsCarrier?.[key] ||
            serviceExceptions.default[key] ||
            '') as string;
    }

    static getCarrierShipmentWarnings(carrier: string): CarrierWarningKeys | undefined {
        const carrierName = carrier.toLowerCase();
        return carrierWarnings[carrierName];
    }

    /**
     * i18n will translate the second element if the first doesn't exist.
     */
    static getDropoffKey(carrier: string, point: 'from' | 'to'): string[] {
        const carrierName = carrier.replace(/\s/g, '-').toLowerCase();

        return [`service.${carrierName}.dropoff-${point}`, `service.default.dropoff-${point}`];
    }

    static getServiceParams(shipment: ProShipment['data'], serviceId: number): ServiceAllParams {
        const params: ServiceAllParams = {
            source: shipment.source,
            from: {
                country: shipment.from?.alpha2Code as string,
                zip: shipment.from?.zipCode as string,
            },
            to: {
                country: shipment.to?.alpha2Code as string,
                zip: shipment.to?.zipCode as string,
            },
            packages: shipment.parcels?.map((parcel: Parcel): IParcel => parcel.toJSON() as IParcel) || [],
        };

        if (serviceId) {
            params.service_id = serviceId;
        }

        return params;
    }

    static getService(shipment: ProShipment['data'], serviceId: number): Promise<Service> {
        const params = this.getServiceParams(shipment, serviceId);

        return serviceRepository
            .getServicesForSpecificSearch(params)
            .then((services: Service[]): Service => {
                return services[0];
            })
            .catch((e) => {
                logSdkError(e);
                return Promise.reject(e);
            });
    }

    static getCarrierLogo(logoId: string, cdnUrl: string = config.cdn.url): string {
        return `${cdnUrl}/carrier-logos/${logoId}.svg`;
    }

    static getServiceFlags(service?: Service): ServiceFlags {
        return {
            customsRequired: !!service?.customsRequired?.available,
            hasDropoffOrigin: !!service?.dropOff?.origin,
            hasDropoffDestination: !!service?.dropOff?.destination,
            hasOwnContract: !!service?.isOwnContract,
        };
    }
}
