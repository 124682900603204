import { IconNames } from '@shipengine/giger-theme';
import { Button, Typography, ButtonVariant, Icon } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { SendLabelByEmailDialogForm } from './SendLabelByEmailDialogForm';
import { SendLabelByEmailFormData } from './SendLabelByEmailDialogFormData';
import {
    getSendLabelByEmailDialogCloseButtonStyles,
    getSendLabelByEmailDialogHeaderStyles,
    getSendLabelByEmailDialogTypographyStyles,
} from './SendLabelByEmailDialogStyles';

type SendLabelByEmailDialogProps = {
    onSubmit: (data: SendLabelByEmailFormData) => void;
    onClose: () => void;
};

export function SendLabelByEmailDialog({ onSubmit, onClose }: SendLabelByEmailDialogProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <>
            <Button
                css={getSendLabelByEmailDialogCloseButtonStyles}
                onClick={onClose}
                variant={ButtonVariant.TEXT}
                data-id="send-labels-popover-close-button"
                aria-label={t('post-sale.details.close-popover')}
            >
                <Icon name={IconNames.CLOSE} />
            </Button>
            <header css={getSendLabelByEmailDialogHeaderStyles}>
                <Typography css={getSendLabelByEmailDialogTypographyStyles} component="h3" variant="heading5">
                    {t('post-sale.details.send-label-by-email-label')}
                </Typography>
            </header>
            <SendLabelByEmailDialogForm onSubmit={onSubmit} />
        </>
    );
}
