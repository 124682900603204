import { Address, IAddress, IWarehouseAddress } from '@packlink/packlink-sdk';
import { DeepPartial } from 'redux';
import { IDetailsForm } from '../DetailsStep/DetailsForm/DetailsForm';

function addressHasChanged(originalAddress: IAddress, currentAddress: IDetailsForm): boolean {
    // Treat empty string and undefined as equal
    return Object.entries(originalAddress).some(
        ([key, value]) =>
            value !== currentAddress[key as keyof IAddress] && !!(value && currentAddress[key as keyof IAddress]),
    );
}

export function showSaveWarehouse(
    selectedWarehouseId: string,
    warehouses: IWarehouseAddress[],
    checkoutFrom: Address<DeepPartial<IAddress>>,
    fromValues: IDetailsForm,
) {
    if (!selectedWarehouseId) {
        return true;
    }
    // If a warehouse is selected, compare the senderAddress against that
    const originWarehouse = warehouses.find(({ id }) => id === selectedWarehouseId);
    // Unfortunately, since addresses are not standard, we need to transform the warehouse
    const addressWarehouse: IAddress = {
        city: originWarehouse?.city || '',
        company: originWarehouse?.company || '',
        alpha2Code: originWarehouse?.alpha2Code || '',
        email: originWarehouse?.email,
        firstName: originWarehouse?.firstName || '',
        phone: originWarehouse?.phone || '',
        street1: originWarehouse?.address,
        lastName: originWarehouse?.lastName || '',
        zipCode: originWarehouse?.zipCode || '',
    };

    return addressHasChanged(addressWarehouse || checkoutFrom, fromValues);
}

export function hasAddressBookChanged(
    hasDropoffDestination: boolean,
    checkoutTo: Address<DeepPartial<IAddress>>,
    toValues: IDetailsForm,
) {
    if (hasDropoffDestination) {
        return false;
    }
    const fieldsToCompare = ['email', 'firstName', 'lastName', 'street1', 'phone', 'company'] as const;
    return fieldsToCompare.some((field) => (checkoutTo[field] ?? '') !== (toValues[field] ?? ''));
}

export function parseAddress(address: Address) {
    return {
        ...address,
        company: address.company || '',
        email: address.email || '',
        street1: address.street1 || '',
        street2: address.street2 || '',
    };
}
