import { css, CSSObject, SerializedStyles } from '@emotion/react';
import { getTypographyVariantDefaultStyles } from '@shipengine/giger';
import { Theme } from '@shipengine/giger-theme';

interface IInboxStyles {
    normal?: CSSObject;
    tablet?: CSSObject;
}

function getActiveStyles(theme: Theme): IInboxStyles {
    return {
        normal: {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeight.bold,
            borderBottom: `2px solid ${theme.palette.primary.main}`,
        },

        tablet: {
            color: theme.palette.secondary.dark,
            borderWidth: 0,
        },
    };
}

function getChildStyles(theme: Theme): IInboxStyles {
    return {
        tablet: {
            height: 'auto',
            paddingLeft: theme.spacing(8),
        },
    };
}

function getParentStyles(): IInboxStyles {
    return {
        normal: {
            display: 'none',
        },

        tablet: {
            display: 'flex',
        },
    };
}

function getDisabledStyles(theme: Theme): IInboxStyles {
    return {
        normal: {
            color: theme.palette.gray.main,
            pointerEvents: 'none',
        },

        tablet: {
            color: theme.palette.gray.dark,
        },
    };
}

function getSelectedStyles(theme: Theme): IInboxStyles {
    return {
        tablet: {
            backgroundColor: theme.palette.gray.ultraLight,
        },
    };
}

export const lockedIconStyles = (): SerializedStyles =>
    css({
        marginLeft: 'auto',
    });

export const inboxStyles =
    (isActive: boolean, isChild: boolean, hasChildren: boolean, isDisabled: boolean, hasRightTag?: boolean) =>
    (theme: Theme): SerializedStyles => {
        const typographyVariant = isChild ? 'body2' : 'body1';
        const defaultTypographyStyles = getTypographyVariantDefaultStyles(theme)[typographyVariant];
        const {
            fontSize = defaultTypographyStyles.fontSize,
            lineHeight = defaultTypographyStyles.lineHeight,
            fontWeight = defaultTypographyStyles.fontWeight,
            letterSpacing = defaultTypographyStyles.letterSpacing,
        } = theme.getComponentOverride('Typography')[typographyVariant] || {};

        const isSelected = isActive && !hasChildren;

        return css({
            fontSize: theme.toRem(fontSize),
            lineHeight: theme.getLineHeight(fontSize, lineHeight),
            fontWeight,
            letterSpacing: theme.toEm(letterSpacing),
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
            color: theme.palette.gray.dark,

            ...(hasRightTag && {
                position: 'relative',
            }),

            ...(isActive && getActiveStyles(theme).normal),
            ...(hasChildren && getParentStyles().normal),
            ...(isDisabled && getDisabledStyles(theme).normal),

            [theme.mediaQuery('tablet')]: {
                height: theme.spacing(8),
                padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
                color: theme.palette.secondary.dark,

                ...(isActive && getActiveStyles(theme).tablet),
                ...(isChild && getChildStyles(theme).tablet),
                ...(hasChildren && getParentStyles().tablet),
                ...(isDisabled && getDisabledStyles(theme).tablet),
                ...(isSelected && getSelectedStyles(theme).tablet),
            },

            ':hover': {
                color: theme.palette.gray.main,

                [theme.mediaQuery('tablet')]: {
                    color: theme.palette.gray.dark,
                    backgroundColor: theme.palette.gray.ultraLight,
                },
            },
        });
    };

export const iconStyles =
    (isDisabled: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'none',
            marginRight: theme.spacing(),
            ...(isDisabled && { color: theme.palette.gray.dark }),

            [theme.mediaQuery('tablet')]: {
                display: 'inline-flex',
            },
        });

export const nameStyles = (): SerializedStyles =>
    css({
        whiteSpace: 'nowrap',
    });

export const countStyles =
    (isActive: boolean, hasNotifications: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            position: 'relative',
            display: 'none',
            marginLeft: 'auto',
            color: theme.palette.gray.main,

            [theme.mediaQuery('tablet')]: {
                display: 'inline',
            },

            ...(isActive && { fontWeight: theme.typography.fontWeight.semibold }),

            ...(hasNotifications && {
                backgroundColor: theme.palette.success.light,
                borderRadius: theme.borderRadius.S,
                padding: `0 ${theme.spacing(0.5)}px`,

                '::after': {
                    content: '""',
                    position: 'absolute',
                    backgroundColor: theme.palette.success.main,
                    borderRadius: 50,
                    width: 8,
                    height: 8,
                    top: -3,
                },
            }),
        });

export const newTagStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        position: 'absolute',
        right: theme.spacing(3),
    });
