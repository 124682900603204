import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentDetailsSidebarStyles = (theme: Theme): SerializedStyles =>
    css({
        flex: '0 0 25%',
        padding: theme.spacing(4),
        borderWidth: '0 0 0 1px',
        borderStyle: 'solid',
        borderColor: theme.palette.gray.main,
        background: theme.palette.white,
    });

export const getShipmentDetailsSidebarSection = (theme: Theme): SerializedStyles =>
    css({ marginBottom: theme.spacing(3) });
