import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getCancelShipmentDialogHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(),
    });

export const getCancelShipmentDialogTypographyStyles = (): SerializedStyles =>
    css({
        marginBottom: 0,
    });

export const getCancelShipmentDialogDescriptionStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getCancelShipmentDialogCloseButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'absolute',
        top: theme.spacing(),
        right: theme.spacing(),
        padding: 0,
    });
