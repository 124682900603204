import { FC } from 'react';

import { GenericConfigurationData, IGenericPanelContentProps } from '@Integrations/components/IntegrationPanel/types';

import { TikTokUkIntegrationForm } from './TikTokUkIntegrationForm';
import { Configuration, ConfigurationType, Integration } from '@packlink/packlink-sdk';
import { EcommerceAlias } from '@Integrations/constants/ecommerce';

export const TikTokUkIntegrationPanel: FC<IGenericPanelContentProps> = (
    props: IGenericPanelContentProps,
): JSX.Element => {
    const { clearError, showError, integration, onClose, onDeactivate, onSubmit, onCancel } = props;

    const handleSubmit = (integration: Integration<Configuration<GenericConfigurationData>>) => {
        const tikTokUkconfiguration = {
            identifier: integration.configuration?.identifier || '',
            integration: EcommerceAlias.TikTokUk,
            type: ConfigurationType.INBOUND,
            data: {},
        };

        const tikTokUkIntegration = { ...integration, configuration: tikTokUkconfiguration };

        onSubmit(tikTokUkIntegration);
    };

    return (
        <TikTokUkIntegrationForm
            clearError={clearError}
            showError={showError}
            integration={integration}
            onClose={onClose}
            onDeactivate={onDeactivate}
            onSubmit={handleSubmit}
            onCancel={onCancel}
        />
    );
};
