import { Typography, WithChildrenCommonProps } from '@shipengine/giger';
import { InformationTooltip } from '@components/InformationTooltip/InformationTooltip';
import { getHeaderStyles, getHeaderTitleStyles, getHeaderTooltipStyles } from './PostsaleSectionStyles';

type PostsaleSectionProps = WithChildrenCommonProps<{
    title?: React.ReactNode;
    tooltip?: React.ReactNode;
}>;

export function PostsaleSection({ children, title, tooltip, ...props }: PostsaleSectionProps): JSX.Element {
    return (
        <section {...props}>
            {title && (
                <header css={getHeaderStyles}>
                    <Typography component="h3" variant="heading5" css={getHeaderTitleStyles}>
                        {title}
                        {tooltip && (
                            <InformationTooltip css={getHeaderTooltipStyles} data-id="postsale-section-tooltip">
                                {tooltip}
                            </InformationTooltip>
                        )}
                    </Typography>
                </header>
            )}
            {children}
        </section>
    );
}
