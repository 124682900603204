import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getInfoMessageStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
    });

export const arrowStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.main,
    });
