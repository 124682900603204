import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getEditableFavoritesCardStyles =
    (selected?: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 'calc(45% - 20px)',
            flex: 1,
            minHeight: '150px',
            padding: theme.spacing(2),
            overflow: 'hidden',
            backgroundColor: selected ? theme.palette.primary.ultraLight : theme.palette.gray.megaLight,
            border: selected ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.gray.light}`,
            borderRadius: theme.borderRadius.S,
            position: 'relative',
        });

export const getEditableFavoritesCardFooterStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'right',
        justifyContent: 'flex-start',
    });

export const getEditableFavoritesCardHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        minHeight: theme.spacing(3),
        marginBottom: theme.spacing(),
    });

export const getEditableFavoritesCardHeaderTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: '0',
        overflow: 'hidden',
        color: theme.palette.gray.dark,
        fontWeight: theme.typography.fontWeight.semibold,
        fontSize: theme.toRem(theme.typography.fontSize.M),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    });

export const getEditableFavoritesCardHeaderChipStyles = (theme: Theme): SerializedStyles =>
    css({
        width: 'min-content',
        marginBottom: theme.spacing(2),
    });

export const getEditableFavoritesCardInfoStyles = (theme: Theme): SerializedStyles =>
    css({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.palette.gray.dark,
        fontSize: theme.toRem(theme.typography.fontSize.S),
    });

export const getEditableFavoritesCardContentStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    });

export const getEditableFavoritesCardDeleteConfirmationStyles = (theme: Theme): SerializedStyles =>
    css({
        ...theme.getElevation('overlay2'),

        backgroundColor: theme.palette.white,
        opacity: 0.85,
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',

        '.btn:first-of-type': {
            marginRight: theme.spacing(),
        },
    });

export const getEditableFavoritesCardActionStyles = (theme: Theme): SerializedStyles =>
    css({
        ':not(:last-of-type)': {
            marginBottom: theme.spacing(),
        },
    });
