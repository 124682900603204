import { useMutation, useQuery, useQueryClient } from 'react-query';
import Packlink from '@sdk';
import { ApiClientError, IParcel, Parcel } from '@packlink/packlink-sdk';
import { logSdkError } from '@utils/logger';
const PARCELS_CACHE_KEY = 'parcels';

export const deserializeGuard = (parcel: IParcel): Parcel<IParcel> => Parcel.deserialize<IParcel>(parcel);

export const useParcels = () => {
    const queryClient = useQueryClient();
    const { mutate: deleteParcelMutation } = useMutation((parcel: Parcel) => Packlink.v1.parcels.delete(parcel), {
        onError: (error?: ApiClientError) => {
            error && logSdkError(error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(PARCELS_CACHE_KEY);
        },
    });

    const { mutate: saveParcelMutation } = useMutation((parcel: Parcel) => Packlink.v1.parcels.set(parcel), {
        onError: (error?: ApiClientError) => {
            error && logSdkError(error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(PARCELS_CACHE_KEY);
        },
    });

    const { mutate: updateParcelMutation } = useMutation((parcel: Parcel) => Packlink.v1.parcels.update(parcel), {
        onError: (error?: ApiClientError) => {
            error && logSdkError(error);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(PARCELS_CACHE_KEY);
        },
    });

    const { data, error, isLoading, isFetching } = useQuery<Parcel<IParcel>[], ApiClientError>(
        PARCELS_CACHE_KEY,
        async () => {
            const response = await Packlink.pro.init();
            const { parcels } = response;
            return parcels?.map((parcel) => deserializeGuard(parcel as IParcel)) ?? [];
        },
        { refetchOnMount: false, staleTime: 150 },
    );

    const getDefaultParcel = (): Parcel<IParcel> | undefined => {
        return data?.find((parcel: Parcel<IParcel> | undefined): boolean => !!parcel?.isDefault);
    };

    return {
        deleteParcelMutation,
        saveParcelMutation,
        updateParcelMutation,
        parcels: data ?? [],
        error,
        isLoading,
        isFetching,
        defaultParcel: getDefaultParcel(),
    };
};
