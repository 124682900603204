// Related documentation about IBAN algorithm: https://en.wikipedia.org/wiki/International_Bank_Account_Number#Algorithms
export const isValidIban = function (iban: string) {
    const cleanIBAN = iban.replace(/\s+/g, '').replace(' ', '').replace('-', '').toUpperCase();
    const code = cleanIBAN.substring(0, 2);
    const checksum = cleanIBAN.substring(2, 4);
    const bban = cleanIBAN.substring(4);

    // Assemble digit string
    let digits = '';
    for (let i = 0; i < bban.length; ++i) {
        const ch = bban.charAt(i);
        if ('0' <= ch && ch <= '9') digits += ch;
        else digits += capital2digits(ch);
    }
    for (let i = 0; i < code.length; ++i) {
        digits += capital2digits(code.charAt(i));
    }
    digits += checksum;

    // Calculate checksum
    return 1 === mod97(digits);
};

// Convert a capital letter into digits: A -> 10 ... Z -> 35 (ISO 13616).
const capital2digits = function (ch: string) {
    const capitals = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return 10 + capitals.indexOf(ch);
};

// Modulo 97 for huge numbers given as digit strings.
const mod97 = function (digit_string: string) {
    let m = 0;
    for (let i = 0; i < digit_string.length; ++i) m = (m * 10 + parseInt(digit_string.charAt(i))) % 97;
    return m;
};
