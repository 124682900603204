import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getFooterLabelStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        marginLeft: 'auto',
        alignItems: 'center',
    });

export const getFooterLabelIconStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        marginRight: theme.spacing(0.5),
        color: theme.palette.secondary.main,
    });
