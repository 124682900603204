import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IntegrationEvent } from '@Integrations/components/IntegrationEvents/events';
import { IImportFinishEventData } from '@Integrations/components/IntegrationEvents/types';
import { setActiveIntegrationImport } from '@Integrations/store/actions/integrations';
import { getActiveIntegrationImport } from '@Integrations/store/selectors/integrations';
import { ActiveIntegrationImport } from '@Integrations/types/integration';
import { useEvent } from '@packlink/event-handler';
import { ImportRepository, ImportSource } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { AppDispatch } from '@store';
import { getClientIdentifier } from '@store/selectors/client';
import { ImportOrdersModal, ImportOrdersSubtype, ImportOrdersType } from '../ImportOrdersModal/ImportOrdersModal';
import { ImportErrorType } from '../IntegrationEvents/types';

const TIME_TO_CLOSE_MODAL = 6000;

const importRepository = new ImportRepository(apiClient);
export const ImportOrders = (): JSX.Element => {
    const dispatch = useDispatch<AppDispatch>();
    const [importOrderType, setImportOrderType] = useState<ImportOrdersType | undefined>();
    const [importOrderSubtype, setImportOrderSubtype] = useState<ImportOrdersSubtype | undefined>();
    const [numberOfOrders, setNumberOfOrders] = useState<number>();
    const [selectedIntegration, setSelectedIntegration] = useState<ActiveIntegrationImport | undefined>();
    const activeIntegrationImport = useSelector(getActiveIntegrationImport);
    const clientId = useSelector(getClientIdentifier);

    const { eventBind: importEndBind, eventUnbind: importEndUnbind } = useEvent<IImportFinishEventData>(
        clientId,
        IntegrationEvent.IMPORT_ENDED,
    );

    const closeModal = useCallback(() => {
        setImportOrderType(undefined);
    }, []);

    const openImportsModal = useCallback(
        (integration: ActiveIntegrationImport): void => {
            const today = new Date();
            importRepository
                .triggerFiveDayImportFromIntegration({
                    integration: integration.alias,
                    date: today,
                    source: integration.source,
                })
                .then(() => {
                    setSelectedIntegration(integration);
                    setImportOrderType(ImportOrdersType.IMPORTING);
                })
                .catch(closeModal);
        },
        [closeModal],
    );

    const onImportEnded = useCallback(
        (data: IImportFinishEventData): void => {
            if (data.callbackPayload && data.callbackPayload !== ImportSource.CSV) {
                if (data.numberShipmentsImported !== undefined && data.numberShipmentsImported > 0) {
                    setNumberOfOrders(data.numberShipmentsImported);
                    setImportOrderType(ImportOrdersType.IMPORT_ENDED);
                } else if (
                    data.errorType === ImportErrorType.UNAUTHORIZED &&
                    data.callbackPayload === ImportSource.MANUAL_INTEGRATION
                ) {
                    setImportOrderSubtype(data.errorSubtype);
                    setImportOrderType(ImportOrdersType.UNAUTHORIZED_ERROR);
                } else {
                    setImportOrderType(ImportOrdersType.ERROR);
                    setTimeout(closeModal, TIME_TO_CLOSE_MODAL);
                }
            }
        },
        [closeModal],
    );

    useEffect(() => {
        // Opens import modal when there is a new active integration to import
        if (activeIntegrationImport) {
            openImportsModal(activeIntegrationImport);
            dispatch(setActiveIntegrationImport(undefined));
        }
    }, [activeIntegrationImport, dispatch, openImportsModal]);

    // Listens events related to the import
    useEffect(() => {
        importEndBind(onImportEnded);

        return () => {
            importEndUnbind(onImportEnded);
        };
    }, [importEndBind, importEndUnbind, onImportEnded]);

    return (
        <>
            {!!importOrderType && !!selectedIntegration && (
                <ImportOrdersModal
                    onClose={closeModal}
                    type={importOrderType}
                    subtype={importOrderSubtype}
                    integration={selectedIntegration}
                    numberOfOrders={numberOfOrders}
                />
            )}
        </>
    );
};
