import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getInsuranceSubtitleTextStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',

        '.icon': {
            marginRight: theme.spacing(0.5),
        },
    });
