import { MouseEvent } from 'react';
import { ITabProps, Tab, WithChildrenCommonProps } from '@shipengine/giger';
import { useMatch, useNavigate, useResolvedPath } from 'react-router';

type RouterTabProps = Omit<WithChildrenCommonProps<ITabProps>, 'selected'> & { to: string };

export function RouterTab({ children, to, onClick, ...other }: RouterTabProps) {
    const navigate = useNavigate();
    const path = useResolvedPath(to);
    const match = useMatch(path.pathname);

    const handleOnClick = (selectedItem: string | undefined, e: MouseEvent<HTMLButtonElement>) => {
        onClick?.(selectedItem, e);
        navigate(to);
    };

    return (
        <Tab {...other} selected={!!match} onClick={handleOnClick}>
            {children}
        </Tab>
    );
}
