import { FastField } from 'formik';
import { WithCommonProps } from '@shipengine/giger';
import { FormField, Input } from '@shipengine/formik-giger';
import { useTranslation } from '@packlink/translation-provider';
import { getFieldsetResetStyles, getFormElementStyles, getFormInputStyles } from '../../../styles/FormStyles';

type SupportTicketBankPaymentFieldProps = WithCommonProps<{
    name: string;
}>;

const ENGLISH_LOCALE = 'en-GB';

export function SupportTicketBankPaymentField({
    name,
    ...otherProps
}: SupportTicketBankPaymentFieldProps): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const codeFieldLabel =
        locale === ENGLISH_LOCALE
            ? t('support-center.payment-field.bank-sort-code-label')
            : t('support-center.payment-field.bank-eu-code-label');

    return (
        <fieldset css={getFieldsetResetStyles} {...otherProps}>
            <FormField name={`${name}.accountHolder`} css={[getFormElementStyles, getFormInputStyles]}>
                <FastField component={Input} label={t('support-center.payment-field.bank-account-holder-label')} />
            </FormField>
            <FormField name={`${name}.iban`} css={[getFormElementStyles, getFormInputStyles]}>
                <FastField component={Input} label={t('support-center.payment-field.bank-iban-label')} />
            </FormField>
            <FormField name={`${name}.code`} css={getFormInputStyles}>
                <FastField component={Input} name={`${name}.code`} label={codeFieldLabel} />
            </FormField>
        </fieldset>
    );
}
