import { Typography, WithCommonProps } from '@shipengine/giger';
import { Trans } from '@packlink/translation-provider';
import { getTicketDetailsSectionPadding } from '../../../../TicketDetailsStyles';

type TicketChatHeaderProps = WithCommonProps<{
    numberOfMessages: number;
}>;

export function TicketChatHeader({ numberOfMessages, ...props }: TicketChatHeaderProps): JSX.Element {
    return (
        <header css={getTicketDetailsSectionPadding} {...props}>
            <Typography component="span" variant="body2">
                <Trans i18nKey="support-center.ticket-chat.header-title" count={numberOfMessages + 1} />
            </Typography>
        </header>
    );
}
