import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { AppDispatch } from '@store';
import { getSelectedShipments, getShipments, hasActions } from '@store/selectors/bulk';
import { Checkbox } from '@shipengine/giger';
import { cleanSelectedShipments, selectAllShipments } from '@store/actions/bulk';
import { AmplitudeEvents } from '@constants/amplitude';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { ShipmentPanelType } from '@types';
import { BulkFlows } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelBulk/typings';
import { checkboxStyles } from './CheckAllShipmentsStyles';
import { useAmplitude } from '@hooks/useAmplitude';

export function CheckAllShipments(): JSX.Element {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const inboxHasActions = useSelector(hasActions);
    const { length: shipmentsLength } = useSelector(getShipments);
    const { length: selectedShipmentsLength } = useSelector(getSelectedShipments);
    const { openPanel, closePanel } = useShipmentPanel();
    const { sendAmplitudeTableViewClickEvent } = useAmplitude();

    const areAllShipmentsSelected = shipmentsLength > 0 && selectedShipmentsLength === shipmentsLength;

    const toggleSelectAll = useCallback(
        ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>): void => {
            if (checked) {
                sendAmplitudeTableViewClickEvent(AmplitudeEvents.BULK);
                dispatch(selectAllShipments());
                openPanel(ShipmentPanelType.BULK, undefined, { flow: BulkFlows.ACTIONS });
            } else {
                dispatch(cleanSelectedShipments());
                closePanel(false);
            }
        },
        [closePanel, dispatch, openPanel, sendAmplitudeTableViewClickEvent],
    );

    return (
        <div css={checkboxStyles}>
            <Checkbox
                disabled={shipmentsLength === 0 || !inboxHasActions}
                checked={selectedShipmentsLength > 0}
                indeterminate={!areAllShipmentsSelected && selectedShipmentsLength > 0}
                label={t('shipment-list-header.action.bulk-selection')}
                name="bulkAll"
                onChange={toggleSelectAll}
            />
        </div>
    );
}
