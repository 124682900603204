export const validatePassword = (password: string): boolean => {
    // Password must not be empty and must contain at least 12 characters
    if (!password || password.trim() === '' || password.trim().length < 12) {
        return false;
    }

    // Password must contain at least one lowercase letter, one uppercase letter, one number and one special character
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_=+|{}[\]:;'"<>,./?~\\-\s]).*/;

    return regex.test(password);
};
