import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import {
    IOrderCreateParams,
    IOrderShipment,
    Order,
    OrderRepository,
    Payment,
    PaymentClientMethod,
    PaymentMethod,
    PaymentType,
    Shipment,
    ApiClientError,
} from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { apiClient } from '@sdk';
import { getPaymentErrors, getPaymentErrorsTranslated, parsePaymentErrorMessage, PaymentApiError } from '@utils/error';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useAmplitude } from '@hooks';
import { IAmplitude3Dsecure, IAmplitudeLocalPayment } from '@packlink/payment-options';
import { logSdkError } from '@utils/logger';
import { getPaymentMethods } from '@store/selectors/payment';
import { OnPayProps } from '@components/PaymentOptions/PaymentOptions';
import { useNavigate } from 'react-router';
import { APP_ROUTE } from '@router/routes';

const ordersRepository = new OrderRepository(apiClient);

export type OrderError = { response: { data: { messages: PaymentApiError[] }; status: number } };

export const useCreateOrder = (section?: 'side panel' | 'checkout') => {
    const { t } = useTranslation();
    const allPaymentMethods = useSelector(getPaymentMethods);
    const { sendAmplitudeEvent, sendAmplitudeShipmentEvent } = useAmplitude();
    const navigate = useNavigate();

    interface MutationProps {
        order: IOrderCreateParams;
        paymentMetrics?: IAmplitude3Dsecure | IAmplitudeLocalPayment;
    }

    const { mutate: createOrderMutation } = useMutation(
        ({ order }: MutationProps) => ordersRepository.createOrder(order),
        {
            onSuccess: (response: Order, { order, paymentMetrics }: MutationProps) => {
                let numShipmentsWithError = 0;
                const shipmentErrors: string[] = [];

                response.shipments.forEach((shipment: IOrderShipment) => {
                    if (
                        (typeof shipment.status === 'boolean' ? !shipment.status : shipment.status >= 400) &&
                        shipment.message
                    ) {
                        numShipmentsWithError++;
                        shipmentErrors.push(parsePaymentErrorMessage(shipment.message));
                    }
                });

                let numInsuredShipments = 0;
                let numCODShipments = 0;
                order.shipments.forEach((shipment: Shipment) => {
                    shipment.upsales?.insurance?.available && numInsuredShipments++;
                    shipment.upsales?.cashOnDelivery && numCODShipments++;
                });

                const amplitudeShipmentData =
                    order.shipments.length === 1
                        ? {
                              [AmplitudeProperties.SHIPMENT_ID]: order.shipments[0].packlinkReference,
                              [AmplitudeProperties.ADDRESS_BOOK_USED]: !!order.shipments[0].to?.id,
                          }
                        : {};
                const amplitudeCommonData = {
                    ...paymentMetrics,
                    ...amplitudeShipmentData,
                    [AmplitudeProperties.PAYMENT_METHOD]: order.payment.method,
                    [AmplitudeProperties.PAYMENT_TYPE]: order.payment.type,
                    [AmplitudeProperties.BULK]: order.shipments.length > 1,
                    [AmplitudeProperties.OWN_CONTRACT]: order.shipments[0].service?.isOwnContract,
                };

                const amplitudeData = {
                    ...amplitudeCommonData,
                    [AmplitudeProperties.PRICE]: getOrderBasePrice(order),
                    [AmplitudeProperties.SHIPMENTS_COUNT]: order.shipments.length - numShipmentsWithError,
                    [AmplitudeProperties.INSURANCE]: numInsuredShipments > 0,
                    [AmplitudeProperties.PROMO_CODE_APPLIED]: order.shipments[0].voucherName,
                    [AmplitudeProperties.CUSTOMS_REQUIRED]: order.shipments[0].hasCustoms,
                    [AmplitudeProperties.SERVICE_ID]: order.shipments[0].service?.id,
                    [AmplitudeProperties.SECTION]: section,
                    [AmplitudeProperties.COD]: numCODShipments > 0,
                    [AmplitudeProperties.COD_COUNT]: numCODShipments,
                };

                if (order.shipments.length > 1) {
                    sendAmplitudeEvent(AmplitudeEvents.PAYMENT_SUCCESS, amplitudeData);
                    if (numShipmentsWithError > 0) {
                        sendAmplitudeEvent(AmplitudeEvents.PAYMENT_FAILED, {
                            [AmplitudeProperties.PRICE]: getOrderBasePrice(order),
                            [AmplitudeProperties.PAYMENT_METHOD]: order.payment.method,
                            [AmplitudeProperties.PAYMENT_TYPE]: order.payment.type,
                            [AmplitudeProperties.ERROR]: shipmentErrors,
                            [AmplitudeProperties.SECTION]: 'payment summary bulk',
                            [AmplitudeProperties.SHIPMENTS_COUNT]: numShipmentsWithError,
                        });
                    }
                } else {
                    sendAmplitudeShipmentEvent(AmplitudeEvents.PAYMENT_SUCCESS, order.shipments[0], amplitudeData);
                }
            },
            onError: (error: OrderError, { order }: MutationProps) => {
                logSdkError(error as unknown as ApiClientError);
                const isNotAllowedError = error.response['status'] === 403;
                const errorMessages: PaymentApiError[] = error?.response?.data?.messages as PaymentApiError[];
                const paymentErrors = getPaymentErrors(errorMessages);
                const errorsTranslated = isNotAllowedError
                    ? 'Purchase is not allowed'
                    : getPaymentErrorsTranslated(t, paymentErrors);

                sendAmplitudeEvent(AmplitudeEvents.PAYMENT_FAILED, {
                    [AmplitudeProperties.PRICE]: getOrderBasePrice(order),
                    [AmplitudeProperties.PAYMENT_METHOD]: order.payment.method,
                    [AmplitudeProperties.PAYMENT_TYPE]: order.payment.type,
                    [AmplitudeProperties.ERROR]: errorsTranslated,
                    [AmplitudeProperties.SECTION]: section,
                });

                if (isNotAllowedError) {
                    navigate(APP_ROUTE.ERROR.INDEX);
                }
            },
        },
    );

    const createOrder = (
        orderShipments: Shipment[],
        { type, method }: OnPayProps,
        nonce?: string,
        paymentMetrics?: IAmplitude3Dsecure | IAmplitudeLocalPayment,
        deviceData?: string,
        mutationEvents?: {
            onSuccess: (order: Order) => void;
            onError: (error: OrderError) => void;
        },
        carriersTermsAndConditions?: boolean,
    ) => {
        // bulk uses PaymentMethod.CREDITCARD as default (the flow in backend also does 3d secure for CREDITCARD)
        const paymentMethod = method === PaymentMethod.CREDITCARD3DSECURE ? PaymentMethod.CREDITCARD : method;
        const savedPaymentMethod = allPaymentMethods.find(
            (p: PaymentClientMethod) => p.method === method && p.type === type,
        );

        const order: IOrderCreateParams = {
            orderCustomReference: 'ref',
            payment: new Payment(
                savedPaymentMethod?.id,
                paymentMethod as PaymentMethod,
                nonce,
                type as PaymentType,
                orderShipments[0].currency,
                deviceData,
            ),
            shipments: orderShipments,
            carriersTermsAndConditions: carriersTermsAndConditions,
        };

        createOrderMutation({ order, paymentMetrics }, mutationEvents);
    };

    return { createOrder };
};

const getOrderBasePrice = (order: IOrderCreateParams) =>
    order.shipments.reduce((price: number, shipment: Shipment) => price + (shipment.price?.price?.basePrice || 0), 0);
