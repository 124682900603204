import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonColor, ButtonSize, ButtonVariant, EmptyState, Icon, IconSize, Next } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { useNavigate } from 'react-router';
import {
    getCloseButtonIconStyles,
    getCloseButtonStyles,
    getErrorStateStyles,
    getHeaderLogoStyles,
    getSectionStyles,
} from './AccountErrorPageStyles';
import { BrandAssetType, BrandLogo, useAssetUrl } from '@packlink/tenant-theme-provider';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks';
import { SupportTicketType } from '@packlink/packlink-sdk';
import { APP_ROUTE } from '@router/routes';
import { useGetHelpUrl } from '@SupportCenter/hooks/useGetHelpUrl';

export const AccountErrorPage = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const getLogo = useAssetUrl(BrandAssetType.LOGO);
    const helpCenterUrl = useGetHelpUrl({ newTicketType: SupportTicketType.OTHER });

    const {
        cdn: { url: cdnUrl },
    } = config;
    const image = `${cdnUrl}/pro/statics/images/shipment-boxes.png`;

    const onClose = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.ERROR_PAGE_CLOSE);
        navigate(APP_ROUTE.SHIPMENTS.INBOX_READY_TO_PURCHASE);
    };

    const onCreateTicket = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.ERROR_PAGE_CREATE_TICKET);
    };

    return (
        <section css={getSectionStyles}>
            <img alt="logo" css={getHeaderLogoStyles} src={getLogo(BrandLogo.LOGO)} title={t('app.helmet.title')} />
            <Button
                color={ButtonColor.PRIMARY}
                size={ButtonSize.SMALL}
                variant={ButtonVariant.TEXT}
                onClick={onClose}
                css={getCloseButtonStyles}
            >
                <Icon css={getCloseButtonIconStyles} name={IconNames.CARDBOARD_BOX} size={IconSize.SIZE_SMALL} />
                {t('error-page.button.close')}
            </Button>
            <EmptyState
                css={getErrorStateStyles}
                title={t('error-page.text.title')}
                subtitle={t('error-page.text.subtitle')}
                headerImage={{ src: image, alt: t('error-page.text.title') }}
                isElevated={false}
            >
                <Next.Link
                    href={helpCenterUrl}
                    onClick={onCreateTicket}
                    isButton
                    buttonProps={{ size: ButtonSize.SMALL }}
                    isExternal
                >
                    {t('error-page.button.create-ticket')}
                </Next.Link>
            </EmptyState>
        </section>
    );
};
