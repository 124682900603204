import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getPostalSelectorStyles =
    (isCollapsed?: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            marginBottom: theme.spacing(),

            ...(!isCollapsed && {
                display: 'flex',

                '> :not(:first-of-type)': {
                    marginLeft: theme.spacing(),
                },
            }),
        });
export const getPostalSelectorFieldStyles = (): SerializedStyles =>
    css({
        flex: 1,
    });
