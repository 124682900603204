import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: theme.borderRadius.S,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        ...theme.getElevation('popout2'),
    });

export const getSectionStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    });

export const getIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
        backgroundColor: theme.palette.primary.dark,
        padding: theme.spacing(),
        color: theme.palette.white,
        borderRadius: theme.borderRadius.S,
        display: 'flex',
        alignItems: 'center',
    });

export const getInfoIconStyles = (theme: Theme): SerializedStyles =>
    css({
        verticalAlign: 'text-top',
        marginLeft: theme.spacing(),
    });

export const getButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(2),
    });
