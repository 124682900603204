import { CustomsFormData } from '@packlink/customs-form';

import { ActionType, createAction } from 'typesafe-actions';

export const setCustomsForm = createAction('SET_CUSTOMS_FORM')<CustomsFormData>();
export const resetCustomsForm = createAction('RESET_CUSTOMS_FORM')();
export const skipCustomsForm = createAction('SKIP_CUSTOMS_FORM')();
export const requireCustomsForm = createAction('CLEAR_SKIP_CUSTOMS_FLAG')();

export type ICustomsAction = ActionType<
    typeof setCustomsForm | typeof resetCustomsForm | typeof skipCustomsForm | typeof requireCustomsForm
>;
