import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getFrequentlyAskedQuestionsIconStyles = (theme: Theme): CSSObject => ({
    marginRight: theme.spacing(0.5),
});

export const getFrequentlyAskedQuestionsButtonStyles = {
    display: 'flex',
    flex: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};
