import { useTranslation } from '@packlink/translation-provider';
import {
    Button,
    ButtonSize,
    ButtonVariant,
    Dialog,
    DialogActions,
    DialogContent,
    DialogHeader,
    Typography,
    WithChildrenCommonProps,
} from '@shipengine/giger';

export type BackDialogProps = WithChildrenCommonProps<{
    isOpen: boolean;
    isSubmitting: boolean;
    onClose: VoidFunction;
    onCancel: VoidFunction;
    onSave: VoidFunction;
}>;

export function BackDialog({ isOpen, isSubmitting, onClose, onCancel, onSave }: BackDialogProps): JSX.Element {
    const { t } = useTranslation();

    const handleClose = () => {
        if (isSubmitting) return;

        onClose();
    };

    return (
        <Dialog isOpen={isOpen}>
            <DialogHeader showClose onClose={handleClose} title={t('branded-emails.go-back-dialog.title')} />

            <DialogContent>
                <Typography variant="body1">{t('branded-emails.go-back-dialog.content')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.OUTLINED}
                    onClick={onCancel}
                    isLoading={isSubmitting}
                >
                    {t('branded-emails.go-back-dialog.reject')}
                </Button>
                <Button size={ButtonSize.SMALL} onClick={onSave} isLoading={isSubmitting}>
                    {t('branded-emails.go-back-dialog.accept')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
