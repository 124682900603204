import { useTranslation } from '@packlink/translation-provider';

import { Icon, IconSize, Typography } from '@shipengine/giger';
import { ProShipment } from '@packlink/packlink-sdk';
import { IconNames } from '@shipengine/giger-theme';

import {
    getProtectionInfoStyles,
    getProtectionInfoTextStyles,
    getShipmentProtectionStyles,
    getShipmentProtectionTextStyles,
} from './ShipmentProtectionStyles';
import { UpsellUtils } from '@utils/UpsellUtils';

export type IShipmentProtectionProps = {
    handleInsurance: (event: React.MouseEvent<HTMLElement>) => void;
    shipmentsData: ProShipment[];
};

export function ShipmentProtection(props: IShipmentProtectionProps): JSX.Element {
    const { t } = useTranslation();
    const { shipmentsData, handleInsurance } = props;

    const { isProtectedShipment, someShipmentPartiallyProtected } =
        UpsellUtils.getShipmentStatusProtection(shipmentsData);

    return (
        <>
            {!isProtectedShipment && (
                <div css={getShipmentProtectionStyles}>
                    <div css={getProtectionInfoStyles}>
                        <Icon name={IconNames.ALERT} size={IconSize.SIZE_SMALL} />
                        <Typography css={getProtectionInfoTextStyles} variant="body2">
                            {someShipmentPartiallyProtected
                                ? t('bulk-panel.payment.insurance-section-partially-protected')
                                : t('bulk-panel.payment.insurance-section-unprotected')}
                        </Typography>
                    </div>
                    <a href="#" target="_blank" onClick={handleInsurance} css={getShipmentProtectionTextStyles}>
                        {t('bulk-panel.payment.insurance-section-select-protection')}
                    </a>
                </div>
            )}
        </>
    );
}
