import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import {
    Button,
    ButtonColor,
    ButtonVariant,
    Dialog,
    DialogActions,
    DialogContent,
    DialogHeader,
    Typography,
    useToast,
} from '@shipengine/giger';
import {
    ApiClientError,
    OwnContractDeactivateResponse,
    OwnContractModel,
    OwnContractRepository,
    PaymentClientMethod,
    PaymentMethod,
} from '@packlink/packlink-sdk';
import { AppDispatch } from '@store';
import { setPaymentMethods } from '@store/actions/payment';
import { AmplitudeProperties } from '@constants/amplitude';
import { logSdkError } from '@utils/logger';
import { apiClient } from '@sdk';
import { useAmplitude } from '@hooks/useAmplitude';

const contractRepository = new OwnContractRepository(apiClient);

export interface OwnContractDeactivateDialogProps {
    ownContract: OwnContractModel;
    isOpen: boolean;
    onClose: () => void;
    onDeactivateSuccess: () => void;
}

export const OwnContractDeactivateDialog: React.FC<OwnContractDeactivateDialogProps> = ({
    ownContract,
    isOpen,
    onClose,
    onDeactivateSuccess,
}: OwnContractDeactivateDialogProps) => {
    const { t } = useTranslation();
    const toast = useToast(t);
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState(false);
    const { setAmplitudeUserProperties } = useAmplitude();

    const onDeactivate = (contractId: string) => {
        return contractRepository
            .deactivate(contractId)
            .then(({ paymentMethods }: OwnContractDeactivateResponse) => {
                // Backend responds with the remaining payment methods, this is because this deactivation
                // can lead to remove the own contract payment method
                dispatch(setPaymentMethods(paymentMethods || []));

                const hasOwnContract = paymentMethods?.find(
                    (p: PaymentClientMethod): boolean => p.method === PaymentMethod.OWNCONTRACT,
                );
                if (!hasOwnContract) {
                    setAmplitudeUserProperties([{ key: AmplitudeProperties.OWN_CONTRACT, value: false }]);
                }
                setIsLoading(false);
                onDeactivateSuccess();
                toast.success({ duration: 3000, message: t('toast.title.success') });
            })
            .catch((error: ApiClientError) => {
                logSdkError(error);
                toast.error({ duration: 3000, message: t('toast.title.error') });
            });
    };

    const handleDeactivate = (): void => {
        onDeactivate(ownContract.contractId as string);
        setIsLoading(true);
    };

    return (
        <div>
            <Dialog isOpen={isOpen}>
                <DialogHeader
                    showClose
                    closeAriaLabel={t('dialog.action.close')}
                    onClose={onClose}
                    title={t('own-contract.list.dialog-deactivate-header')}
                />
                <DialogContent>
                    <Typography variant="body1" component="p">
                        {t('own-contract.list.dialog-deactivate-content', {
                            name: ownContract.carrierName,
                        })}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button bold variant={ButtonVariant.OUTLINED} onClick={onClose}>
                        {t('own-contract.list.dialog-deactivate-button-cancel')}
                    </Button>
                    <Button bold color={ButtonColor.ERROR} onClick={handleDeactivate} isLoading={isLoading}>
                        {t('own-contract.list.dialog-deactivate-button-cta')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
