import React, { useCallback, useMemo } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import { useTranslation } from '@packlink/translation-provider';
import {
    IParcel,
    IPriceInsurance,
    IProShipmentApiResponse,
    IShipment,
    Parcel,
    ProShipment,
    ShipmentStatus,
} from '@packlink/packlink-sdk';
import { FocusOnError } from '@shipengine/formik-giger';
import { useToggle } from '@packlink/utils';
import { EventName } from '@types';
import { Status, useStatus } from '@hooks/useStatus';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { PriceUtils } from '@utils/PriceUtils';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { getParcelListValidation, ParcelFormList } from '@components/ParcelForm/ParcelFormList';
import { getContentValidation } from '@components/ContentAutocomplete/ContentAutocomplete';

import { getContentValueValidation, ShipmentPanelContentInfo } from './ShipmentPanelContentInfo';
import { ShipmentPanelGoToPayment } from '../../ShipmentPanelGoToPayment/ShipmentPanelGoToPayment';
import { ShipmentPanelFooter } from '../ShipmentPanelFooter';
import { useEvent } from '@packlink/event-handler';
import { useSelector } from 'react-redux';
import { getClientIdentifier } from '@store/selectors/client';
import { shipmentPanelFormStyles } from '../ShipmentPanelStyles';

export interface IContentForm {
    parcels?: IParcel[];
    description?: string;
    value?: number;
}

export const ShipmentPanelContent: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const { panelState, closePanel, updateShipment, exitPanel } = useShipmentPanel();
    const { status, isReady, setStatus } = useStatus();
    const { state: isActionLoading, toggle: toggleActionLoading } = useToggle(false);
    const clientId = useSelector(getClientIdentifier);
    const { eventBind, eventUnbind } = useEvent<IProShipmentApiResponse>(clientId, EventName.SHIPMENT_UPDATED);

    // Need useMemo because are used in useCallback as depth
    const { data: shipmentData } = useMemo(() => panelState.shipment as ProShipment, [panelState]);
    const shipmentDataJSON = useMemo(() => shipmentData.toJSON(), [shipmentData]);

    const initialFormValues = useMemo(
        (): IContentForm => ({
            parcels:
                shipmentData.parcels &&
                shipmentData.parcels.map((parcel: Parcel): IParcel => parcel.toJSON() as IParcel),
            description: shipmentData.content?.description,
            value: shipmentData.content?.value,
        }),
        [shipmentData],
    );

    const validationSchema = yup.object<IContentForm>().shape({
        parcels: getParcelListValidation(t),
        description: getContentValidation(t),
        value: getContentValueValidation(t),
    });

    const updateCallback = useCallback(
        ({ data }: IProShipmentApiResponse) => {
            if (data.packlink_reference === shipmentDataJSON.packlinkReference) {
                const updatedShipmentState = data.state;
                toggleActionLoading();
                if (updatedShipmentState === ShipmentStatus.READY_TO_PURCHASE) {
                    setStatus(Status.READY);
                } else {
                    closePanel(false);
                }
                eventUnbind(updateCallback);
            }
        },
        [closePanel, eventUnbind, setStatus, shipmentDataJSON.packlinkReference, toggleActionLoading],
    );

    const submitForm = useCallback(
        async (values: IContentForm): Promise<void> => {
            const { parcels, description, value } = values;
            let insurancePrice: IPriceInsurance | undefined;

            const hasChangedValue = shipmentDataJSON.content.value !== value;
            const shouldUpdateInsurance =
                hasChangedValue && shipmentDataJSON.upsales.insurance.available && shipmentDataJSON.service.id;

            if (shouldUpdateInsurance) {
                const priceDetails = await PriceUtils.getPriceDetails(shipmentDataJSON, value);
                insurancePrice = priceDetails?.shipmentsPriceDetails[0].availableProducts?.insurance;

                // Not handled correctly, but we shouldn't save a shipment with insurance true and amount 0
                if (!insurancePrice) {
                    return;
                }
            }

            const newShipmentData: IShipment = {
                ...shipmentDataJSON,
                additionalData: {
                    ...shipmentDataJSON.additionalData,
                    parcelIds: parcels?.map((parcel: IParcel): string => parcel.id as string),
                },
                content: {
                    ...shipmentDataJSON.content,
                    description: description as string,
                    value: value as number,
                },
                upsales: {
                    ...shipmentDataJSON.upsales,
                    insurance: {
                        ...shipmentDataJSON.upsales.insurance,
                        amount: shouldUpdateInsurance
                            ? insurancePrice?.insured
                            : shipmentDataJSON.upsales.insurance.amount,
                    },
                },
                parcels: parcels as IParcel[],
            };
            toggleActionLoading();
            updateShipment(newShipmentData);
            eventBind(updateCallback);
        },
        [eventBind, shipmentDataJSON, toggleActionLoading, updateCallback, updateShipment],
    );

    const renderForm = (formProps: FormikProps<IContentForm>): React.ReactNode => {
        const { isValid } = formProps;

        return (
            <Form css={shipmentPanelFormStyles()}>
                {/* The delay of 300 is to wait for the panel to be opened */}
                <FocusOnError initialValidation={true} focusDelay={300} />

                <SidePanelHeader onAction={closePanel} title={t(`shipment-list-header.menu.contents`)} />

                {!status && (
                    <>
                        <SidePanelContent className="shipment-panel-content__content">
                            <SidePanelContentSection
                                title={t('content-panel.parcels.section-title')}
                                subtitle={t('content-panel.parcels.section-subtitle')}
                            >
                                <ParcelFormList name="parcels" isInSidebar />
                            </SidePanelContentSection>

                            <ShipmentPanelContentInfo
                                currency={shipmentData.currency}
                                hasInsurance={shipmentData.upsales?.insurance?.available || false}
                            />
                        </SidePanelContent>

                        <ShipmentPanelFooter isSaveDisabled={!isValid} onExit={exitPanel} isLoading={isActionLoading} />
                    </>
                )}

                {isReady && (
                    <ShipmentPanelGoToPayment onExit={exitPanel} shipmentRef={shipmentData.packlinkReference} />
                )}
            </Form>
        );
    };

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            onSubmit={submitForm}
        >
            {(formProps: FormikProps<IContentForm>): React.ReactNode => renderForm(formProps)}
        </Formik>
    );
};
