import { PanelName } from '@types';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store';
import { clearActivePanel, setActivePanel } from '@store/actions/side-panel';
import { getActivePanel } from '@store/selectors/side-panel';

interface ISidePanelManager {
    isOpen: boolean;
    activePanel?: string;
    close: () => void;
    delayedClose: () => void;
    open: (panelName?: PanelName) => void;
    toggle: () => void;
}

export const useSidePanel = (panelName?: PanelName): ISidePanelManager => {
    const dispatch = useDispatch<AppDispatch>();
    const activePanel = useSelector(getActivePanel);
    const timeoutId = useRef<ReturnType<typeof setTimeout>>();

    // These useCallback are used to avoid infinite loops when used as an useEffect dependency
    const openPanel = useCallback(
        (panelNameParam?: PanelName): void => {
            const panelToOpen = panelName || panelNameParam;

            if (!panelToOpen) {
                return;
            }

            dispatch(setActivePanel(panelToOpen));
        },
        [dispatch, panelName],
    );

    const closePanel = useCallback(() => {
        dispatch(clearActivePanel());
        timeoutId.current && clearTimeout(timeoutId.current);
    }, [dispatch]);

    const delayedClosePanel = useCallback((): void => {
        timeoutId.current = setTimeout((): void => {
            closePanel();
        }, 4000);
    }, [closePanel]);

    const isCurrentPanelOpen = useMemo(() => activePanel === panelName, [panelName, activePanel]);

    const togglePanel = useCallback((): void => {
        if (isCurrentPanelOpen) {
            closePanel();
        } else {
            openPanel();
        }
    }, [closePanel, isCurrentPanelOpen, openPanel]);

    useEffect(() => {
        return (): void => timeoutId.current && clearTimeout(timeoutId.current);
    }, []);

    return {
        isOpen: isCurrentPanelOpen,
        activePanel: activePanel,
        close: closePanel,
        delayedClose: delayedClosePanel,
        open: openPanel,
        toggle: togglePanel,
    };
};
