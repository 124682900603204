import { useState } from 'react';
import { useSelector } from 'react-redux';
import { SubscriptionPlan } from '@packlink/packlink-sdk';
import { Spinner } from '@shipengine/giger';
import { isInvoiceAddressValid } from '@store/selectors/client';
import { useRequiresPhoneNumberVerification } from '@common/hooks/useRequiresPhoneNumberVerification';
import { SubscriptionPanelInvoiceForm } from './SubscriptionPanelInvoiceForm';
import { SubscriptionPanelPaymentSummary } from './SubscriptionPanelPaymentSummary';
import { AmplitudeEvents } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';
import { getSpinnerStyles } from './SubscriptionPanelContentStyles';
import { useBillingForm } from '@common/hooks/useBillingForm';

interface SubscriptionsPanelContentProps {
    planDetails: SubscriptionPlan;
    close: VoidFunction;
    successCallback?: VoidFunction;
}

export const SubscriptionPanelContent = ({
    planDetails,
    close,
    successCallback,
}: SubscriptionsPanelContentProps): JSX.Element => {
    const hasBillingInfo = useSelector(isInvoiceAddressValid);
    const { clientRequiresPhoneNumberVerification, isClientRequiresPhoneNumberVerificationLoading } =
        useRequiresPhoneNumberVerification();

    const { isPhoneNumberVerificationOpen, onBillingFormSuccess, closePhoneNumberVerification } = useBillingForm();

    const { sendAmplitudeClickEvent } = useAmplitude();

    const [showInvoiceForm, setShowInvoiceForm] = useState(!hasBillingInfo);

    const goToPaymentSummary = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.SUBSCRIPTION_SAVE_INVOICING_INFO);
        setShowInvoiceForm(false);
    };

    const goToInvoiceForm = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.SUBSCRIPTION_EDIT_INVOICING_INFO);
        setShowInvoiceForm(true);
    };

    if (isClientRequiresPhoneNumberVerificationLoading) {
        return <Spinner css={getSpinnerStyles} />;
    }

    return (
        <>
            {isPhoneNumberVerificationOpen || showInvoiceForm || clientRequiresPhoneNumberVerification ? (
                <SubscriptionPanelInvoiceForm
                    onAction={goToPaymentSummary}
                    onClose={close}
                    isPhoneNumberVerificationOpen={isPhoneNumberVerificationOpen}
                    onBillingFormSuccess={onBillingFormSuccess}
                    closePhoneNumberVerification={closePhoneNumberVerification}
                />
            ) : (
                <SubscriptionPanelPaymentSummary
                    planDetails={planDetails}
                    onEditInvoiceDetails={goToInvoiceForm}
                    successCallback={successCallback}
                />
            )}
        </>
    );
};
