import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getCancelDialogStyles = (): SerializedStyles =>
    css({
        minWidth: 680,
    });

export const getImageStyles = (theme: Theme): SerializedStyles =>
    css({
        height: 140,
        marginBottom: theme.spacing(3),
    });

export const getTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getMessageStyles = (): SerializedStyles =>
    css({
        marginBottom: 0,
    });
