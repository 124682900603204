import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

// Due to theme compatibility issues, we need to define the color manually and use it explicitly.
const CORREOS_YELLOW = '#FFCD00';

export const getRootStyles =
    (hasAppTopBorder: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',

            ...(hasAppTopBorder && { borderTop: `${theme.spacing()}px solid ${CORREOS_YELLOW}` }),
        });

export const getBodyStyles = (theme: Theme): SerializedStyles =>
    css({
        WebkitFontSmoothing: 'antialiased',
        webkitosxfontSmoothing: 'grayscale',
        MozOsxFontSmoothing: 'antialiased',
        textRendering: 'optimizeLegibility',
        lineHeight: 1.4,

        display: 'flex',
        flex: 1,
        // TODO: https://auctane.atlassian.net/browse/PCK-2859
        flexDirection: 'column',
        overflow: 'auto',

        p: {
            marginTop: '0',
        },

        img: {
            verticalAlign: 'middle',
        },

        a: {
            textDecoration: 'none',
            ':hover': {
                color: `darken(${theme.palette.primary.main}, 5%)`,
                cursor: 'pointer',
            },
        },
    });
