import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getPlanPricringStyles = (): SerializedStyles =>
    css({
        margin: 0,
    });

export const getFullPriceStyles = (theme: Theme): SerializedStyles =>
    css({
        textDecoration: 'line-through',
        fontWeight: theme.typography.fontWeight.normal,
        marginRight: theme.spacing(2),
    });
