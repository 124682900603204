import { Theme } from '@shipengine/giger-theme';
import { css } from '@emotion/react';

export function getPlanCardsContainerStyles(theme: Theme) {
    return css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        marginTop: theme.spacing(4),
        width: '100%',

        [theme.mediaQuery('tablet')]: {
            flexDirection: 'row',
            width: 'fit-content',
            margin: '0 auto',
        },
    });
}

export const getSpinnerStyles = () => {
    return css({
        display: 'flex',
        justifyContent: 'center',
    });
};
