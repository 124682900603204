import { Client } from '@packlink/packlink-sdk';
import { ISetClientAction } from '@store/actions/client';
import { SET_CLIENT_DATA } from '@store/actions/action-types';

export type IClientState = Client;
export const initialState = {} as Client;

export const reducer = (state: IClientState = initialState, action: ISetClientAction): Client => {
    switch (action.type) {
        case SET_CLIENT_DATA:
            return action.payload;
        default:
            return state;
    }
};
