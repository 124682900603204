import { useField } from 'formik';
import { Input } from '@shipengine/giger';
import { FormField } from '@shipengine/formik-giger';

interface ITextInputFilterProps {
    name: string;
    label: string;
}

export const TextInputFilter = (props: ITextInputFilterProps): JSX.Element => {
    const { name, label } = props;
    const [field, meta] = useField<string>(name);

    return (
        <FormField name={name}>
            <Input label={label} value={field.value || ''} onChange={field.onChange} isInvalid={!!meta.error} />
        </FormField>
    );
};
