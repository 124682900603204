import { IUser } from '@packlink/packlink-sdk';
import { UserAction } from '@store/actions/user';
import { SET_USER_DATA } from '@store/actions/action-types';

export type IUserState = IUser;

export const initialState = {} as IUser;

export const reducer = (state: IUserState = initialState, action: UserAction): IUserState => {
    switch (action.type) {
        case SET_USER_DATA:
            return { ...state, ...action.payload.toJSON() };
        default:
            return state;
    }
};
