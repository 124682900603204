import { BrandedSettings } from '@packlink/packlink-sdk';
import { BrandedEmailProviders } from './BrandedEmailProviders';
import { BrandedEmailContent } from './BrandedEmailContent';

export type BrandedEmailProps = {
    initialValues?: BrandedSettings;
    clientName?: string;
};

export function BrandedEmail({ initialValues, clientName }: BrandedEmailProps): JSX.Element {
    return (
        <BrandedEmailProviders initialValues={initialValues}>
            <BrandedEmailContent clientName={clientName} />
        </BrandedEmailProviders>
    );
}
