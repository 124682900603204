import { useCallback, useState, ChangeEvent } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useField } from 'formik';
import { Currency } from '@packlink/packlink-sdk';
import { FormField } from '@shipengine/formik-giger';
import { IInlineNotificationProps, InlineNotification, Input, NotificationType } from '@shipengine/giger';
import { ContentAutocomplete } from '@components/ContentAutocomplete/ContentAutocomplete';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { MAX_DECLARED_VALUE } from '@constants/content';

import { TFunction } from 'i18next';
import * as yup from 'yup';

interface IShipmentPanelContentInfo {
    currency: string;
    hasInsurance: boolean;
}

export const getContentValueValidation = (t: TFunction): yup.NumberSchema<number> =>
    yup
        .number()
        .typeError(t('form.error.required', { field: t('content-panel.content.value') }))
        .required(t('form.error.required', { field: t('content-panel.content.value') }))
        .positive(t('form.error.positive', { field: t('content-panel.content.value') }))
        .lessThan(MAX_DECLARED_VALUE, t('content-form.error.max', { field: t('content-panel.content.value') }))
        .test(
            'decimals-limit',
            t('form.error.decimals-limit', { field: t('content-panel.content.value'), limit: 2 }),
            (value: number | null | undefined): boolean => {
                const regEx = new RegExp(/[,.'](\d{3,})/);
                return value ? !regEx.test(value + '') : false;
            },
        );

export const ShipmentPanelContentInfo = ({ currency, hasInsurance }: IShipmentPanelContentInfo): JSX.Element => {
    const { t } = useTranslation();
    const [field, meta, helper] = useField<number>('value');
    const [contentValue, setContentValue] = useState<number | undefined>(field.value);
    const [notificationProps, setNotificationProps] = useState<IInlineNotificationProps | undefined>();

    const handleContentValueChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
            setContentValue(value as unknown as number);
            helper.setValue(value as unknown as number);
            hasInsurance &&
                setNotificationProps({
                    type: NotificationType.ALERT,
                    children: t('content-panel.content.price-change-notification'),
                });
        },
        [helper, hasInsurance, t],
    );

    return (
        <SidePanelContentSection
            title={t('content-panel.content.section-title')}
            subtitle={t('content-panel.content.section-subtitle')}
        >
            <ContentAutocomplete />

            <FormField name="value">
                <Input
                    label={t('content-panel.content.value')}
                    type="number"
                    step="0.01"
                    isInvalid={!!meta.error}
                    value={contentValue}
                    rightContent={Currency.getCurrencySymbol(currency)}
                    onChange={handleContentValueChange}
                    onBlur={field.onBlur}
                />
            </FormField>

            {notificationProps && <InlineNotification {...notificationProps} />}
        </SidePanelContentSection>
    );
};
