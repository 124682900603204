import { FC } from 'react';

import { IInfoPanelProps, InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { useTranslation } from '@packlink/translation-provider';

import { IntegrationGuide } from '../../IntegrationGuide/IntegrationGuide';

type EbayIntegrationGuideType = Omit<IInfoPanelProps, 'title'>;

export const EbayIntegrationGuide: FC<EbayIntegrationGuideType> = ({
    collapsible = false,
    collapsed,
}: EbayIntegrationGuideType): JSX.Element => {
    const { t } = useTranslation();

    return (
        <IntegrationGuide>
            <InfoPanel title={t('integrations.guide.title')} collapsible={collapsible} collapsed={collapsed}>
                <InfoPanelStepList>
                    <InfoPanelStep title={t('integrations.ebay.step1')} />
                    <InfoPanelStep title={t('integrations.ebay.step2')} />
                    <InfoPanelStep title={t('integrations.ebay.step3')} />
                </InfoPanelStepList>
            </InfoPanel>
        </IntegrationGuide>
    );
};
