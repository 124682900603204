import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';

import { useEffect, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider } from 'react-query';

import { hasSentry, init as initSentry } from '@utils/logger';
import { persistor, store } from '@store';
import { configureQueryClient } from '@utils/QueryClient';
import { TenantUtils } from '@utils/tenant';
import { AuthGate } from './providers/AuthGate';
import { AppRouter } from '@pages/AppRouter';
import { BulkPacklinkProviders } from './providers/BulkPacklinkProviders';
import { ErrorBoundary } from './providers/ErrorBoundary';

if (hasSentry) {
    initSentry();
}

function AppWithProviders() {
    const queryClient = useMemo(configureQueryClient, []);

    useEffect(() => {
        // Workaround to keep compatibility with an old redirect from AngularJS, where the url https://webparcel.dpd.com/private would redirect to https://webparcel.dpd.com/be/private
        // This can't be handled in the Router since it expect the basename to be /be/private so entering the app with only /private is not expected and can't be fixed with a 404 redirect.
        // We should investigate if this can be at nginx or any other way, but due to urgency I thought of this as the fastest fix.
        const urlStartsWithTenantPrefix = document.location.pathname.startsWith(TenantUtils.getURLPrefix());
        if (!urlStartsWithTenantPrefix && document.location.pathname.startsWith('/private')) {
            document.location.replace(document.location.pathname.replace('/private', TenantUtils.getURLPrefix()));
        } else if (!urlStartsWithTenantPrefix) {
            document.location.replace(TenantUtils.getURLPrefix());
        }
    }, []);

    return (
        <ErrorBoundary key="error-boundary">
            <Provider key="store-provider" store={store}>
                <PersistGate key="store-persist-gate" loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <BulkPacklinkProviders>
                            <AuthGate>
                                <AppRouter />
                            </AuthGate>
                        </BulkPacklinkProviders>
                    </QueryClientProvider>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
}

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<AppWithProviders />);
