import { useSearchParams } from 'react-router';
import { Typography } from '@shipengine/giger';
import { InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { getFieldsetLegendTitleStyles } from '../NewTicketFieldset/NewTicketFieldsetStyles';
import { getFormShipmentInfoPanelSummaryStyles, getFormShipmentInfoPanelTitleStyles } from './FormWithShipmentStyles';
import { useTranslation } from '@packlink/translation-provider';

export function ShipmentReferenceHelp(): JSX.Element {
    const { t } = useTranslation();

    const [params] = useSearchParams();
    const isShipmentReferenceFromSearchParams = params.has('shipmentReference');

    return (
        <>
            <Typography
                component="span"
                variant="subtitle1"
                css={[getFieldsetLegendTitleStyles, getFormShipmentInfoPanelTitleStyles]}
            >
                {t('support-center.new-ticket.shipment-reference-help-title')}
            </Typography>
            <InfoPanel
                title={t('support-center.new-ticket.shipment-reference-help-summary')}
                collapsible={true}
                collapsed={isShipmentReferenceFromSearchParams}
                summaryCss={getFormShipmentInfoPanelSummaryStyles}
            >
                <InfoPanelStepList>
                    <InfoPanelStep title={t('support-center.new-ticket.shipment-reference-step-1')} />
                    <InfoPanelStep title={t('support-center.new-ticket.shipment-reference-step-2')} />
                    <InfoPanelStep title={t('support-center.new-ticket.shipment-reference-step-3')} />
                    <InfoPanelStep title={t('support-center.new-ticket.shipment-reference-step-4')} />
                </InfoPanelStepList>
            </InfoPanel>
        </>
    );
}
