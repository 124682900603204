import { LineSvgProps } from '@nivo/line';
import { BarDatum, BarSvgProps } from '@nivo/bar';
import { WithCommonProps } from '@shipengine/giger';
import { ReactNode } from 'react';
import { IconNames } from '@shipengine/giger-theme';

export enum ChartStatus {
    EMPTY = 'empty',
    ERROR = 'error',
    LOADING = 'loading',
    SUCCESS = 'success',
}

export enum ChartPalette {
    LOW_CONTRAST = 'lowContrast',
    HIGH_CONTRAST = 'highContrast',
    ALL = 'all',
}

export enum LegendPreset {
    BOTTOM = 'bottom',
    RIGHT = 'right',
}

export enum Side {
    LEFT = 'left',
    RIGHT = 'right',
}

export type TooltipLabels = {
    tooltipXLabel?: string;
    tooltipYLabel?: string;
};

type CommonChartProps = {
    colorPalette?: ChartPalette;
    legendPreset?: LegendPreset;
    id: string;
};

export type ResponsiveLineProps = LineSvgProps & CommonChartProps & TooltipLabels;

export type ResponsiveBarPropsNivo<RawDatum extends BarDatum> = Omit<BarSvgProps<RawDatum>, 'height' | 'width'>;
export type ResponsiveBarProps<RawDatum extends BarDatum> = ResponsiveBarPropsNivo<RawDatum> & CommonChartProps;

export type ChartContainerProps = WithCommonProps<{
    /** Heading of the chart box */
    title: string;
    /** Subtitle of the chart box */
    subtitle: string;
    /** Height used by the charts, default 350px */
    height?: number;
    /** ChartStatus to display the different states */
    status: ChartStatus;
    /** CDN url to fech the images used in the different states */
    cdnUrl: string;
    /** Only needed to display 2 components in the same box */
    leftComponent?: ReactNode;
    /** Only needed to display 2 components in the same box */
    rightComponent?: ReactNode;
    /** When using 2 components, icon to display in mobile to change between components */
    leftIcon?: IconNames;
    /** When using 2 components, icon to display in mobile to change between components */
    rightIcon?: IconNames;
    /** When using 2 components, tooltip for the icon */
    leftIconTooltip?: string;
    /** When using 2 components, tooltip for the icon */
    rightIconTooltip?: string;
    /** Will show an extra content in the chart container title */
    titleExtra?: ReactNode;
}>;

export interface IChartStateProps {
    cdnUrl: string;
}

export const DEFAULT_HEIGHT = 350;

/**
 * Nivo direct exports
 */
export type { BarTooltipProps } from '@nivo/bar';
export type { AxisProps } from '@nivo/axes';
