import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import { Address, Content, IShipment, Shipment, ShipmentThirdPartyPreferencesProps } from '@packlink/packlink-sdk';
import Packlink from '@sdk';
import { APP_ROUTE } from '@pages/router/routes';
import { Spinner } from '@components/Spinner/Spinner';
import { AppDispatch } from '@store';
import {
    resetCheckout,
    setCheckoutSelectedShipment,
    setCheckoutAddress,
    setCheckoutAdditionalData,
    setCheckoutDropoffPointId,
    setCheckoutParcels,
    setCheckoutCollectionDate,
    setCheckoutCollectionTime,
    setCheckoutContent,
    setCheckoutBusy,
    setCheckoutWarehouseAddress,
    setCheckoutService,
    setCheckoutInsuranceType,
    setCheckoutThirdPartyPreferences,
    setCheckoutAdultSignature,
    setCheckoutAdditionalHandling,
    setCashOnDeliveryAction,
    setCheckoutProofOfDelivery,
    setCheckoutPrintInStore,
} from '@store/actions/checkout';
import { cleanSelectedShipments } from '@store/actions/bulk';
import { clearActivePanel } from '@store/actions/side-panel';
import { getCheckoutInsuranceType } from '@store/selectors/checkout';
import { initialParcelModel } from '@utils/Parcel';
import { ServiceUtils } from '@utils/ServiceUtils';
import { UpsellUtils } from '@utils/UpsellUtils';
import { useParcels } from '@common/hooks/useParcels';
import { useTranslation } from '@packlink/translation-provider';
import { useWarehouses } from '@common/hooks/useWarehouses';

/**
 * This component/loader pretends to mimic the behaviour of the "resolvers" from the old AngularJS router.
 * This probably can be replaced by a react-router `loader` in the future, when using `createbrowserrouter`
 */
export function CheckoutRouterLoader(): JSX.Element {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { defaultParcel } = useParcels();
    const insuranceType = useSelector(getCheckoutInsuranceType);
    const {
        warehouses,
        defaultWarehouse,
        isSuccess: isFetchWarehousesSuccess,
        isLoading: isFetchWarehousesLoading,
    } = useWarehouses();
    const {
        i18n: { language: locale },
    } = useTranslation();
    const state = useLocation().state as null | { isNew?: boolean };
    const isNewShipment = state?.isNew ?? false;

    useEffectOnce(() => {
        batch(() => {
            dispatch(setCheckoutBusy(false));
            dispatch(cleanSelectedShipments());
            dispatch(clearActivePanel());
        });
    });

    /**
     * We reset the checkout this way to make sure that the first step is loaded after the reset, otherwise we had some problems with `from` and `to` inputs.
     * The reset is only required when creating a new shipment from the app, table view, etc.. this means that a browser reload from any step
     * will not reset the checkout, it will load the data saved in the store.
     */
    const { isFetching: isResetting } = useQuery({
        queryKey: ['reset'],
        queryFn: () => {
            return new Promise<void>((resolve) => {
                dispatch(resetCheckout());
                if (warehouses) {
                    defaultWarehouse && dispatch(setCheckoutWarehouseAddress(defaultWarehouse, locale));
                    resolve();
                } else {
                    resolve();
                }
            });
        },
        enabled: isNewShipment && isFetchWarehousesSuccess,
    });

    const { isFetching: isFetchingShipment, data: shipment } = useQuery({
        queryKey: ['checkout', 'shipment', params.id],
        queryFn: () => Packlink.pro.shipments.getShipment(params.id as string),
        enabled: !!params.id && !isResetting,
        onSuccess: (shipment) => {
            batch(() => {
                const baseCoverage = shipment.data.upsales?.insurance?.baseCoverage ?? 0;
                const parcels = shipment.data.parcels?.length
                    ? shipment.data.parcels
                    : [defaultParcel || initialParcelModel];
                const defaultSelectedInsurance = UpsellUtils.getDefaultCheckoutInsuranceType(
                    baseCoverage,
                    shipment.data.upsales,
                );

                dispatch(resetCheckout());
                dispatch(setCheckoutSelectedShipment(shipment.data));
                dispatch(setCheckoutAddress(shipment.data.from as Address, 'from'));
                dispatch(setCheckoutAddress(shipment.data.to as Address, 'to'));
                dispatch(setCheckoutAdditionalData(shipment.data.additionalData));
                shipment.data?.thirdPartyPreferences &&
                    dispatch(
                        setCheckoutThirdPartyPreferences(
                            shipment.data.thirdPartyPreferences as ShipmentThirdPartyPreferencesProps,
                        ),
                    );
                dispatch(setCheckoutDropoffPointId(shipment.data.dropoffPointId));
                dispatch(setCheckoutParcels(parcels));
                dispatch(setCheckoutCollectionDate(shipment.data.collection?.date?.toISOString()));
                dispatch(setCheckoutCollectionTime(shipment.data.collection?.interval));

                dispatch(setCheckoutInsuranceType(defaultSelectedInsurance || insuranceType));
                dispatch(setCheckoutContent(shipment.data.content as Content));
                shipment.data.upsales?.adultSignature?.available &&
                    dispatch(setCheckoutAdultSignature(shipment.data.upsales?.adultSignature?.available as boolean));

                shipment.data.upsales?.proofOfDelivery?.available &&
                    dispatch(setCheckoutProofOfDelivery(shipment.data.upsales?.proofOfDelivery?.available as boolean));

                shipment.data.upsales?.printInStore?.available &&
                    dispatch(setCheckoutPrintInStore(shipment.data.upsales?.printInStore?.available as boolean));

                shipment.data.upsales?.additionalHandling?.available &&
                    dispatch(
                        setCheckoutAdditionalHandling(shipment.data.upsales?.additionalHandling?.available as boolean),
                    );
                shipment.data.upsales?.cashOnDelivery &&
                    dispatch(setCashOnDeliveryAction(shipment.data.upsales?.cashOnDelivery));
            });
        },
        onError: () => navigate(APP_ROUTE.SHIPMENTS.INBOX_ALL),
    });

    const { isFetching: isFetchingService } = useQuery({
        queryKey: ['checkout', 'service', shipment?.data, shipment?.data.service?.id],
        queryFn: () =>
            ServiceUtils.getService(shipment?.data as Shipment<IShipment>, shipment?.data.service?.id as number),
        enabled: !!shipment?.data.service?.id && !isFetchingShipment,
        onSuccess: (service) => dispatch(setCheckoutService(service)),
    });

    if (isFetchingShipment || isResetting || isFetchingService || isFetchWarehousesLoading) {
        return <Spinner fullScreen />;
    } else {
        return <Outlet />;
    }
}
