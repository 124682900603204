import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ConnectionCard } from '@components/ConnectionCard/ConnectionCard';
import { ConnectionCardList } from '@components/ConnectionCardList/ConnectionCardList';
import { AmplitudeEvents, AmplitudeEventsPro } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { useAmplitude } from '@hooks/useAmplitude';
import { useSidePanel } from '@hooks/useSidePanel';
import { IntegrationPanel } from '@Integrations/components/IntegrationPanel/IntegrationPanel';
import { Ecommerces } from '@Integrations/constants/ecommerce';
import { getSidePanelError } from '@Integrations/store/selectors/integrations';
import { setSidePanelError } from '@Integrations/store/actions/integrations';
import { GTMEvents } from '@packlink/metrics';
import { Integration, IntegrationType } from '@packlink/packlink-sdk';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { useTranslation } from '@packlink/translation-provider';
import { AppDispatch } from '@store';
import { ITenantConfig, PanelName } from '@types';
import { TenantUtils } from '@utils/tenant';
import { APP_ROUTE } from '@pages/router/routes';

import { IntegrationConfigurations } from '../IntegrationPanel/types';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useIntegrations } from '@common/hooks/useIntegrations';

export const IntegrationsList: FC = (): JSX.Element => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [selectedIntegrationName, setSelectedIntegrationName] = useState<string>();
    const { open: openPanel, close: closePanel } = useSidePanel(PanelName.INTEGRATIONS);
    const [showError, setShowError] = useState<boolean | undefined>(false);
    const { integrations: tenantIntegrations, moduleIntegrations } = useTenantConfig<ITenantConfig>();
    const [integrationAlias, setIntegrationAlias] = useState<string | undefined>();
    const { integrationAlias: integrationAliasParam } = useParams();
    const sidePanelError = useSelector(getSidePanelError);
    const [isRouteParaRead, setIsRouteParamRead] = useState<boolean>(false);
    const { sendGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeEvent, sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();
    const { integrations } = useIntegrations();

    const selectedIntegration = integrations?.find(
        (integration: Integration) => integration.name === selectedIntegrationName,
    );

    useEffect(() => {
        if (integrationAliasParam && !isRouteParaRead) {
            history.pushState({}, '', `${TenantUtils.getURLPrefix()}${APP_ROUTE.SETTINGS.INTEGRATIONS}`);
            setIntegrationAlias(integrationAliasParam);
            setIsRouteParamRead(true);
        }
    }, [integrationAliasParam, isRouteParaRead]);

    useEffect(() => {
        sidePanelError && setShowError(true);
    }, [dispatch, sidePanelError]);

    useEffect(() => {
        sendAmplitudeEvent(AmplitudeEvents.INTEGRATION_TABLE_VIEW);
    }, [tenantIntegrations, moduleIntegrations, sendAmplitudeEvent]);

    useEffect(() => {
        selectedIntegrationName ? openPanel() : closePanel();
    }, [selectedIntegrationName, openPanel, closePanel]);

    useEffect(() => {
        if (integrationAlias && integrations) {
            const integration = integrations.find((integration: Integration) => integration.alias === integrationAlias);
            if (integration) {
                setSelectedIntegrationName(integration.name);
            }
        }
    }, [integrationAlias, integrations]);

    const openIntegration = (name: string): void => {
        setSelectedIntegrationName(name);
        sendSuffixedAmplitudeEvent(sendAmplitudeClickEvent, name)(AmplitudeEventsPro.CONFIGURATION_INTEGRATION);

        sendGtmEvent(GTMEvents.MARKETPLACE_INTEGRATION, {
            action: GTMActionFieldOption.INTEGRATION_SELECTED,
            integrationType: name,
        });
    };

    const onPanelClose = useCallback((): void => {
        dispatch(setSidePanelError(false));
        setSelectedIntegrationName(undefined);
        setIntegrationAlias(undefined);
    }, [dispatch]);

    const getIntegrationActionName = (integration: Integration): string => {
        switch (integration.type) {
            case IntegrationType.FRONTEND_CONFIGURED:
            case IntegrationType.ONBOARDING_PACKLINK:
                return t(integration.isConnected ? 'integrations.list.edit' : 'integrations.list.connect-store');
            case IntegrationType.ONBOARDING_PARTNER:
                return t('integrations.list.show-guide');
            case IntegrationType.MODULE:
                if (integration.name === Ecommerces.Wizishop) {
                    return t('integrations.list.connect-store');
                }
                return t('integrations.list.show-key');
            default:
                return '';
        }
    };

    const getNonHiddenIntegrations = (integration: Integration) => !integration.hideIntegrationCard;

    return (
        <ConnectionCardList>
            {integrations
                ?.filter(getNonHiddenIntegrations)
                .map((integration: Integration<IntegrationConfigurations>): JSX.Element => {
                    const { alias, name, logo } = integration;

                    return (
                        <ConnectionCard
                            key={alias}
                            data-id={`integration-list-item-${alias}`}
                            selected={selectedIntegrationName === name}
                            onClick={() => openIntegration(name)}
                            name={name}
                            actionName={getIntegrationActionName(integration)}
                            logoUrl={logo}
                        />
                    );
                })}

            {selectedIntegration && (
                <IntegrationPanel
                    onClose={onPanelClose}
                    integration={selectedIntegration}
                    showError={showError}
                    setShowError={setShowError}
                ></IntegrationPanel>
            )}
        </ConnectionCardList>
    );
};
