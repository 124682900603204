import { WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { Form, Formik } from 'formik';
import { SubmitButton } from '../CommonFields/SubmitButton/SubmitButton';
import { GenericSupportTicketFields } from '../CommonFields/GenericSupportTicketFields/GenericSupportTicketFields';
import {
    useGenericTicketFieldsFormValidationSchema,
    GenericSupportTicketFormData,
    createGenericSupportTicketInitialValues,
    parseGenericSupportTicketFormData,
} from '../CommonFields/GenericSupportTicketFields/GenericSupportTicketFieldsData';
import { FormWithShipmentContext } from '../CommonFields/FormWithShipment/FormWithShipmentData';

import { useContext } from 'react';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { NewTicketEventHandlers } from '../../NewTicketData';
import { getSubmitButtonStyles } from '../../styles/FormStyles';

type LabelTicketFormProps = WithCommonProps<NewTicketEventHandlers>;

export function LabelTicketForm({ onSubmit, ...props }: LabelTicketFormProps): JSX.Element {
    const { t } = useTranslation();
    const { validationSchema, initialValues, handleSubmit } = useFormData({ onSubmit });

    return (
        <Formik<GenericSupportTicketFormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
        >
            <Form {...props}>
                <GenericSupportTicketFields
                    title={t('support-center.label-form.reason-hint')}
                    label={t('support-center.label-form.reason-label')}
                    hint={t('support-center.label-form.reason-help')}
                />

                <SubmitButton css={getSubmitButtonStyles} />
            </Form>
        </Formik>
    );
}

function useFormData(eventHandlers: NewTicketEventHandlers) {
    const validationSchema = useGenericTicketFieldsFormValidationSchema();
    const initialValues: GenericSupportTicketFormData = createGenericSupportTicketInitialValues();
    const { handleSubmit } = useLabelTicketFormHandlers(eventHandlers);

    return {
        validationSchema,
        initialValues,
        handleSubmit,
    };
}

function useLabelTicketFormHandlers({ onSubmit }: NewTicketEventHandlers): {
    handleSubmit: (values: GenericSupportTicketFormData) => Promise<void>;
} {
    const { shipment } = useContext(FormWithShipmentContext);
    const sdk = useSdk();
    const handleSubmit = async (values: GenericSupportTicketFormData) => {
        const label = parseGenericSupportTicketFormData(values, shipment);
        await onSubmit(() => sdk.v1.supportCenter.createLabelSupportTicket(label));
    };

    return {
        handleSubmit,
    };
}
