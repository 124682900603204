import { useMemo } from 'react';

import { PaymentMethod } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { InlineNotification, NotificationType } from '@shipengine/giger';

import { useSettingsPaymentDeferredData } from '@Invoicing/hooks/useSettingsPaymentDeferredData';
import {
    DeferredPaymentFormStatusProvider,
    DeferredPaymentsFormStatus,
    useDeferredPaymentFormStatus,
} from '@Invoicing/context/deferredPaymentFormStatus';

import { getInlineNotificationStyle } from './SettingsPaymentStyles';

import { SettingsPaymentSummary } from './SettingsPaymentSummary';
import { SettingsPaymentDirectDebitAdvice } from './SettingsPaymentDirectDebitAdvice';
import { SettingsPaymentDeferredPaymentForm } from './SettingsPaymentDeferredPaymentForm';
import { SettingsPaymentAllowDeferredFormAdvice } from './SettingsPaymentAllowDeferredFormAdvice';

/**
 * Simple wrapper to use a Context for Settings Payment page
 */
export const SettingsPayment = (): JSX.Element => {
    return (
        <DeferredPaymentFormStatusProvider>
            <SettingsPaymentPage />
        </DeferredPaymentFormStatusProvider>
    );
};

const SettingsPaymentPage = (): JSX.Element => {
    const { t } = useTranslation();
    const { deferredPaymentFormStatus } = useDeferredPaymentFormStatus();

    const {
        clientDeferredPaymentMethod,
        isByDirectDebitAvailable,
        isDeferredPartiallySuported,
        clientInvoiceAddressIsValid,
        canUseDeferredPayment,
        showAllowDeferredFormAdvice,
    } = useSettingsPaymentDeferredData();

    const showPaymentDeferredForm = useMemo(
        () =>
            (canUseDeferredPayment && !clientDeferredPaymentMethod) ||
            deferredPaymentFormStatus === DeferredPaymentsFormStatus.OPEN ||
            deferredPaymentFormStatus === DeferredPaymentsFormStatus.LOADING,
        [canUseDeferredPayment, clientDeferredPaymentMethod, deferredPaymentFormStatus],
    );

    const showDirectDebitDownloadButton = useMemo(
        () =>
            clientInvoiceAddressIsValid &&
            isByDirectDebitAvailable &&
            !(clientDeferredPaymentMethod?.method === PaymentMethod.DIRECTDEBIT),
        [clientDeferredPaymentMethod?.method, clientInvoiceAddressIsValid, isByDirectDebitAvailable],
    );

    return (
        <>
            {isDeferredPartiallySuported && (
                <InlineNotification
                    type={NotificationType.INFO}
                    title={t('settings.payment.deferred-title')}
                    css={getInlineNotificationStyle}
                >
                    {t('settings.payment.deferred-uk-not-available')}
                </InlineNotification>
            )}

            <p>{t('settings.payment.deferred-info')}</p>

            {/* Summary with the selected deferred payment method */}
            {clientDeferredPaymentMethod && deferredPaymentFormStatus === DeferredPaymentsFormStatus.CLOSED && (
                <SettingsPaymentSummary />
            )}

            {/* Message with button to enable the Direct Debit payment */}
            {showDirectDebitDownloadButton && <SettingsPaymentDirectDebitAdvice />}

            {!clientInvoiceAddressIsValid && <p>{t('settings.payment.select-client-type')}</p>}

            {showPaymentDeferredForm && <SettingsPaymentDeferredPaymentForm />}

            {/* Message and button to activate the Deferred payment */}
            {showAllowDeferredFormAdvice && <SettingsPaymentAllowDeferredFormAdvice />}
        </>
    );
};
