import { IconNames } from '@shipengine/giger-theme';
import { IconSize, Typography, WithCommonProps, IconPopover } from '@shipengine/giger';

import { getFieldsetResetStyles } from '../../../styles/FormStyles';

import {
    getFieldsetLegendStyles,
    getFieldsetLegendTooltipStyles,
    getFieldsetLegendTitleStyles,
    getFieldsetLegendDescriptionStyles,
} from './NewTicketFieldsetStyles';

type NewTicketFieldsetProps = WithCommonProps<{
    title: string;
    description?: string;
    tooltip?: string;
}>;

export function NewTicketFieldset({
    children,
    title,
    description,
    tooltip,
    ...props
}: NewTicketFieldsetProps): JSX.Element {
    return (
        <fieldset css={getFieldsetResetStyles} {...props}>
            <legend css={getFieldsetLegendStyles}>
                <span css={getFieldsetLegendTitleStyles}>
                    <Typography component="span" variant="subtitle1">
                        {title}
                    </Typography>
                    {tooltip && (
                        <IconPopover
                            css={getFieldsetLegendTooltipStyles}
                            data-id="new-ticket-fieldset-tooltip"
                            placement="right"
                            icon={IconNames.INFO}
                            size={IconSize.SIZE_MEDIUM}
                        >
                            {tooltip}
                        </IconPopover>
                    )}
                </span>
                {description && (
                    <Typography css={getFieldsetLegendDescriptionStyles} component="span" variant="subtitle2">
                        {description}
                    </Typography>
                )}
            </legend>

            {children}
        </fieldset>
    );
}
