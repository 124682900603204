import { PropsWithChildren } from 'react';
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import { launchDarklyClientSideID, launchDarklyFlags } from '@utils/launchDarklyUtils';

// This wrapper prevents the app from rendering until the LaunchDarkly client is ready.
function LDAsyncWrapper({ children }: PropsWithChildren<unknown>) {
    const client = useLDClient();

    return client ? <>{children}</> : <>loading...</>;
}

export function LaunchDarklyProvider({ children }: PropsWithChildren<unknown>) {
    return (
        <LDProvider clientSideID={launchDarklyClientSideID} flags={launchDarklyFlags}>
            <LDAsyncWrapper>{children}</LDAsyncWrapper>
        </LDProvider>
    );
}
