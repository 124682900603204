import { useTranslation, Trans } from '@packlink/translation-provider';
import { Button, Link, Grid, GridChild, Typography } from '@shipengine/giger';

import { useSettingsPaymentDeferredData } from '@Invoicing/hooks/useSettingsPaymentDeferredData';

import { getAdviceStyles } from './SettingsPaymentAllowDeferredFormAdviceStyles';

export const SettingsPaymentAllowDeferredFormAdvice = (): JSX.Element => {
    const { t } = useTranslation();

    const {
        helpCenterPages: { termsAndConditions, privacyPolicy, requestDeferred },
    } = useSettingsPaymentDeferredData();

    const onButtonClick = () => window.open(requestDeferred, '_blank');

    return (
        <Grid css={getAdviceStyles}>
            <GridChild colSpan={9}>
                <p>{t('settings.payment-advice.allow-deferred-text')}</p>
                <Typography variant="small">{t('settings.payment-advice.data-use')}</Typography>
            </GridChild>

            <GridChild colSpan={3} colStart={10}>
                <Button isFullWidth onClick={onButtonClick}>
                    <span>{t('settings.payment-advice.button')}</span>
                </Button>

                <Typography variant="small">
                    <p>
                        <Trans
                            i18nKey="settings.payment-advice.terms-and-privacy"
                            components={[
                                <Link
                                    key="link1"
                                    aria-label={t('settings.payment-advice.link-label')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={termsAndConditions}
                                />,
                                <Link
                                    key="link2"
                                    aria-label={t('settings.payment-advice.link-label')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={privacyPolicy}
                                />,
                            ]}
                        />
                    </p>
                </Typography>
            </GridChild>
        </Grid>
    );
};
