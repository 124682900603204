import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getNumbersWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: theme.spacing(3),
    });

export const getNumbersStyles = (theme: Theme): SerializedStyles =>
    css({
        width: theme.toPercentage(1 / 2),
    });

export const getNumbersHeadingStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
    });
