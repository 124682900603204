import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Packlink from '@sdk';
import { getCheckoutService } from '@store/selectors/checkout';
import { IVoucherAvailabilityResponse, Service } from '@packlink/packlink-sdk';

export const useVoucherAvailability = () => {
    const service: Service | undefined = useSelector(getCheckoutService);
    const serviceIds = service?.id ? [service.id] : [];

    const { data } = useQuery<IVoucherAvailabilityResponse[]>({
        queryKey: ['voucherAvailability', serviceIds],
        queryFn: () =>
            Packlink.v1.vouchers.availability({
                service_ids: serviceIds,
            }),
        enabled: serviceIds.length > 0,
    });

    return { voucherAvailability: data ? data[0].allowsVoucher : false };
};
