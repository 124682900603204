import { useMemo, useRef } from 'react';

import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { useToggle } from '@packlink/utils';
import { Icon, IconSize, Popover } from '@shipengine/giger';

import {
    getButtonOpenStyles,
    getButtonStyles,
    getIconStyles,
    getSortOptionButtonStyles,
    getSortOptionTextStyles,
} from './SortButtonStyles';

interface ISortingOption<SortBy> {
    sortKey?: SortBy;
    translationKey: string;
    icon: IconNames;
}

export interface ISortButtonProps<SortBy> {
    options: ISortingOption<SortBy>[];
    selectedSort?: string;
    onClick?: () => void;
    onChangeSorting: (sortBy?: SortBy) => void;
}

export const SortButton = <SortBy,>({
    onChangeSorting,
    options,
    selectedSort,
    onClick,
}: ISortButtonProps<SortBy>): JSX.Element => {
    const { t } = useTranslation();
    const popoverRef = useRef<HTMLDivElement>(null);
    const { state: isOpen, toggle: togglePopover } = useToggle(false);

    const selectedOption = useMemo(
        () => options.find((option) => option.sortKey === selectedSort),
        [options, selectedSort],
    );

    const handleOnClick = (sortKey?: SortBy): void => {
        togglePopover();
        onChangeSorting(sortKey);
    };

    const handleButtonOnClick = (): void => {
        togglePopover();
        onClick && onClick();
    };

    return (
        <>
            <div
                role="button"
                ref={popoverRef}
                onClick={handleButtonOnClick}
                css={[getButtonStyles, isOpen && getButtonOpenStyles]}
            >
                <Icon
                    name={selectedOption?.icon || IconNames.SORT}
                    size={IconSize.SIZE_MEDIUM}
                    css={getIconStyles(false)}
                />
            </div>
            <Popover reference={popoverRef.current} isOpen={isOpen} onClickAway={togglePopover}>
                {options.map(({ sortKey, translationKey }) => {
                    const isSelected = sortKey === selectedSort;

                    return (
                        <section
                            role="button"
                            key={translationKey}
                            onClick={() => handleOnClick(sortKey)}
                            css={getSortOptionButtonStyles(isSelected)}
                        >
                            {isSelected && (
                                <Icon
                                    css={getIconStyles(true)}
                                    name={IconNames.CHECK_MARK}
                                    size={IconSize.SIZE_SMALL}
                                />
                            )}
                            <div css={getSortOptionTextStyles}>{t(translationKey)}</div>
                        </section>
                    );
                })}
            </Popover>
        </>
    );
};
