import { useTranslation } from '@packlink/translation-provider';
import { ISubscription } from '@packlink/packlink-sdk';
import {
    Button,
    ButtonColor,
    ButtonSize,
    ButtonVariant,
    Icon,
    IconSize,
    Typography,
    WithCommonProps,
} from '@shipengine/giger';
import {
    getPaymentInfoRowStyles,
    getPaymentInfoStyles,
    getPaymentInfoTitleStyles,
    getPaymentInfoCreditCardStyles,
    getPaymentPaypalEmailStyles,
} from './PaymentMethodStyles';
import { IconNames } from '@shipengine/giger-theme';
import { useSidePanel } from '@hooks';
import { useAmplitude } from '@hooks/useAmplitude';
import { PanelName } from '@types';
import { AmplitudeEvents } from '@constants/amplitude';

interface IPaymentMethodProps {
    subscription: ISubscription;
}

export function PaymentMethod({ subscription }: WithCommonProps<IPaymentMethodProps>): JSX.Element {
    const { t } = useTranslation();
    const { open: openEditPaymentMethodPanel } = useSidePanel(PanelName.EDIT_PAYMENT_METHOD);
    const { sendAmplitudeClickEvent } = useAmplitude();

    const handleEditPaymentMethod = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.SUBSCRIPTION_PAYMENT_METHOD_EDIT);
        openEditPaymentMethodPanel();
    };

    const isSubcriptionPaymentCreditCard = subscription?.paymentMethod === 'CREDITCARD';

    return (
        <div css={getPaymentInfoStyles}>
            <div css={getPaymentInfoTitleStyles}>
                <Typography variant="body1" component="p" bold css={getPaymentInfoCreditCardStyles}>
                    {t(isSubcriptionPaymentCreditCard ? 'subscriptions.plan.credit-card' : 'subscriptions.plan.paypal')}
                </Typography>

                <Button
                    variant={ButtonVariant.TEXT}
                    color={ButtonColor.PRIMARY}
                    size={ButtonSize.SMALL}
                    onClick={handleEditPaymentMethod}
                >
                    <Icon name={IconNames.EDIT} size={IconSize.SIZE_SMALL} />
                    <Typography variant="small" bold>
                        {t('subscriptions.plan.edit')}
                    </Typography>
                </Button>
            </div>
            {isSubcriptionPaymentCreditCard ? (
                <>
                    <div css={getPaymentInfoRowStyles}>
                        <Typography variant="body2" component="p">
                            {t('subscriptions.plan.card-number')}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {`**** **** **** ${subscription?.cardLastFour}`}
                        </Typography>
                    </div>
                    <div css={getPaymentInfoRowStyles}>
                        <Typography variant="body2" component="p">
                            {t('subscriptions.plan.card-type')}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {subscription?.cardType}
                        </Typography>
                    </div>
                    <div css={getPaymentInfoRowStyles}>
                        <Typography variant="body2" component="p">
                            {t('subscriptions.plan.card-date')}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {subscription?.cardExpiration}
                        </Typography>
                    </div>
                </>
            ) : (
                <div css={getPaymentInfoRowStyles}>
                    <Typography variant="body2" component="p">
                        {t('subscriptions.plan.paypal-email')}
                    </Typography>
                    <Typography css={getPaymentPaypalEmailStyles} variant="body2" component="p">
                        {subscription?.paypalAccountEmail}
                    </Typography>
                </div>
            )}
        </div>
    );
}
