import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useTranslation } from '@packlink/translation-provider';
import {
    IInterval,
    Interval,
    IShipment,
    ProShipment,
    ServiceAllParams,
    ServiceAvailableDate,
    ServiceRepository,
    Shipment,
    ShipmentCollection,
} from '@packlink/packlink-sdk';
import { InlineNotification, NotificationType, Spinner } from '@shipengine/giger';
import { getIsPendingSectionInbox, getSelectedShipments } from '@store/selectors/bulk';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { BulkSelectionInfo } from '@components/SidePanel/BulkSelectionInfo';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { ServiceCollection } from '@packlink/service-collection';
import { ShipmentPanelFooter } from '../../ShipmentPanelFooter';
import { useShipmentsUpdate } from '../../hooks/useShipmentsUpdate';
import { spinnerStyles } from './ShipmentPanelBulkCollectionStyles';
import { shipmentPanelFormStyles } from '../../ShipmentPanelStyles';
import { useAmplitude } from '@hooks/useAmplitude';
import { apiClient } from '@sdk';

const serviceRepository = new ServiceRepository(apiClient);

export interface IShipmentPanelBulkCollectionProps {
    onGoBack: () => void;
}

export const ShipmentPanelBulkCollection = ({ onGoBack }: IShipmentPanelBulkCollectionProps): JSX.Element => {
    const selectedShipments = useSelector(getSelectedShipments);
    const isCurrentInboxPending = useSelector(getIsPendingSectionInbox);
    const [selectedCollection, setSelectedCollection] = useState<ShipmentCollection | undefined>(
        selectedShipments.length === 1 ? selectedShipments[0].data.collection : undefined,
    );
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();

    const params: ServiceAllParams[] = useMemo(() => {
        return selectedShipments.map((shipment: ProShipment) => {
            const { from, to, parcels, source, service } = shipment.data;

            return {
                service_id: service?.id,
                from: {
                    country: from?.alpha2Code,
                    zip: from?.zipCode,
                },
                packages: parcels?.map((p) => ({
                    height: p.height,
                    length: p.length,
                    weight: p.weight,
                    width: p.width,
                })),
                source: source,
                to: {
                    country: to?.alpha2Code,
                    zip: to?.zipCode,
                },
            } as ServiceAllParams;
        });
    }, [selectedShipments]);

    const {
        data: availableCollectionDates,
        isLoading: isLoadingCollectionDates,
        error,
    } = useQuery(['services', ...params], () => serviceRepository.getCollectionDates(params));

    const { mutateShipments, isWaitingUpdate, messageProps } = useShipmentsUpdate(isCurrentInboxPending);

    const onChangeCollection = (_name: string, date?: moment.Moment, interval?: IInterval) => {
        const newInterval = interval && new Interval(interval?.start, interval?.end);
        const shipmentCollection = new ShipmentCollection(date, newInterval);
        setSelectedCollection(shipmentCollection);
    };

    const updateShipments = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const shipmentsToEdit = selectedShipments.map((shipment: ProShipment): Shipment => {
            const { data: shipmentDataJSON } = ShipmentUtils.proShipmentToJSON(shipment);

            const newShipmentData: Partial<IShipment> = {
                ...shipmentDataJSON,
                collection: selectedCollection?.toJSON(),
            };

            return Shipment.deserialize(newShipmentData);
        });

        mutateShipments(shipmentsToEdit, {
            onSuccess: (data) => {
                if (data.shipmentsUpdated) {
                    sendAmplitudeEvent(AmplitudeEvents.SIDEBAR_EDIT_COLLECTION_DATE_SUCCESS, {
                        [AmplitudeProperties.NUMBER_OF_SHIPMENTS_EDITED]: data.shipmentsUpdated.length,
                    });
                }
            },
        });
    };

    return (
        <form css={shipmentPanelFormStyles()} onSubmit={updateShipments}>
            {isWaitingUpdate && (
                <>
                    <BulkSelectionInfo
                        count={selectedShipments.length}
                        actionsTitle="bulk-panel.actions.title"
                        actionsSubtitle="bulk-panel.collection.subtitle"
                        showBackground
                    />

                    <SidePanelContent>
                        {isLoadingCollectionDates && <Spinner css={spinnerStyles} />}
                        {availableCollectionDates?.length && (
                            <ServiceCollection
                                name="collectionDate"
                                availableDates={availableCollectionDates as ServiceAvailableDate[]}
                                date={selectedCollection?.date}
                                interval={selectedCollection?.interval}
                                onChange={onChangeCollection}
                            />
                        )}

                        {!!error && (
                            <InlineNotification
                                type={NotificationType.ALERT}
                                title={t('bulk-panel.collection.no-match-title')}
                            >
                                {t('bulk-panel.collection.no-match-message')}
                            </InlineNotification>
                        )}
                    </SidePanelContent>

                    <ShipmentPanelFooter
                        onExit={onGoBack}
                        cancelButtonLabel={t('shipment-panel.actions.back')}
                        isSaveDisabled={!selectedCollection}
                    />
                </>
            )}
            {!isWaitingUpdate && (
                <SidePanelContent>
                    <SidePanelMessage {...messageProps} />
                </SidePanelContent>
            )}
        </form>
    );
};
