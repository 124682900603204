import { FC } from 'react';

import { IInfoPanelProps, InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { useTranslation } from '@packlink/translation-provider';

import { IntegrationGuide } from '../../IntegrationGuide/IntegrationGuide';

type WizishopIntegrationGuideType = Omit<IInfoPanelProps, 'title' | 'collapsible'>;

export const WizishopIntegrationGuide: FC<WizishopIntegrationGuideType> = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <IntegrationGuide>
            <InfoPanel title={t('integrations.guide.title')} collapsible={false}>
                <InfoPanelStepList>
                    <InfoPanelStep title={t('integrations.wizishop.step1-title')}></InfoPanelStep>
                    <InfoPanelStep title={t('integrations.wizishop.step2-title')}></InfoPanelStep>
                    <InfoPanelStep title={t('integrations.wizishop.step3-title')}></InfoPanelStep>
                    <InfoPanelStep title={t('integrations.wizishop.step4-title')}></InfoPanelStep>
                    <InfoPanelStep title={t('integrations.wizishop.step5-title')}></InfoPanelStep>
                    <InfoPanelStep title={t('integrations.wizishop.step6-title')}></InfoPanelStep>
                </InfoPanelStepList>
            </InfoPanel>
        </IntegrationGuide>
    );
};
