import { NotificationType, WithCommonProps } from '@shipengine/giger';
import { AnySupportTicket, SupportTicketHelpers, SupportTicketMessage } from '@packlink/packlink-sdk';
import { MutableRefObject, useEffect, useRef } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { getTicketDetailsSectionPadding } from '../../../../TicketDetailsStyles';
import { ZendeskMessage } from './components/ZendeskMessage/ZendeskMessage';
import { SupportTicketSummaryMessage } from './components/SupportTicketSummaryMessage/SupportTicketSummaryMessage';
import { TicketChatMessage, TicketChatMessageSender } from './components/TicketChatMessage/TicketChatMessage';
import { TicketChatNotification } from './components/TicketChatMessage/TicketChatNotification';
import { TicketChatScrollToTop } from './components/TicketChatScrollToTop/TicketChatScrollToTop';
import { getTicketChatTimelineScrollToTopStyles, getTicketChatTimelineStyles } from './TicketChatTimelineStyles';
import { useIsMobile } from '@SupportCenter/utils/useIsMobile';

type TicketChatTimelineProps = WithCommonProps<{
    supportTicket: AnySupportTicket;
    messages: SupportTicketMessage[];
}>;

export function TicketChatTimeline({ supportTicket, messages, ...props }: TicketChatTimelineProps): JSX.Element {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const sectionRef = useRef<HTMLElement | null>(null);

    const numberOfMessages = messages.length + 1;
    const hasCloseNotification = SupportTicketHelpers.isTicketClosed(supportTicket);

    useScrollEffects(sectionRef, isMobile);

    return (
        <section css={[getTicketChatTimelineStyles, getTicketDetailsSectionPadding]} ref={sectionRef} {...props}>
            {/* We always render a "fake" chat message that shows the summary of the created ticket */}
            <TicketChatMessage
                sendedBy={TicketChatMessageSender.user}
                createdAt={supportTicket.createdAt}
                data-id="ticket-summary-chat-message-container"
                hasDecorativeLine={numberOfMessages > 1 || hasCloseNotification}
            >
                <SupportTicketSummaryMessage data-id="ticket-summary-message" supportTicket={supportTicket} />
            </TicketChatMessage>
            {messages.map((message, index) => (
                <TicketChatMessage
                    key={`ticket-chat-${message.createdAt}`}
                    sendedBy={message.author.isAgent ? TicketChatMessageSender.agent : TicketChatMessageSender.user}
                    createdAt={message.createdAt}
                    /** If the ticket is closed, we always render a notification at the end of the chat */
                    hasDecorativeLine={index + 1 < messages.length || hasCloseNotification}
                >
                    <ZendeskMessage message={message} />
                </TicketChatMessage>
            ))}
            {hasCloseNotification && (
                <TicketChatNotification
                    data-id="ticket-chat-timeline-closed-notification"
                    title={t('support-center.ticket-chat.ticket-closed-notification-title')}
                    type={NotificationType.INFO}
                    createdAt={supportTicket.updatedAt}
                >
                    {t('support-center.ticket-chat.ticket-closed-notification-content')}
                </TicketChatNotification>
            )}
            {isMobile && numberOfMessages > 1 && (
                <TicketChatScrollToTop
                    css={getTicketChatTimelineScrollToTopStyles}
                    numberOfMessages={numberOfMessages}
                    onClick={() => sectionRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                />
            )}
        </section>
    );
}

function useScrollEffects(chatRef: MutableRefObject<HTMLElement | null>, isMobile: boolean) {
    useEffect(() => {
        if (isMobile && chatRef.current) {
            chatRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [chatRef, isMobile]);

    useEffect(() => {
        if (!chatRef.current || isMobile) {
            return;
        }
        chatRef.current.scrollTo({ top: chatRef.current.scrollHeight, behavior: 'smooth' });
    }, [chatRef, isMobile]);
}
