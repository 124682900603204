import { PropsWithChildren, useMemo } from 'react';
import { BrowserRouter } from 'react-router';

import Pusher from 'pusher-js';

import { EventHandlerProvider } from '@packlink/event-handler';
import { Application } from '@packlink/packlink-sdk';
import { PacklinkProvider } from '@packlink/provider';
import { TenantConfigProvider } from '@packlink/tenant-config-provider';
import { TenantProvider } from '@packlink/tenant-provider';

import { SdkProvider } from './SdkProvider';
import { TranslationProvider } from './TranslationProvider';
import { TenantThemeProviderWrapper } from './TenantThemeProviderWrapper';
import { TenantUtils } from '@utils/tenant';
import { LaunchDarklyProvider } from './LaunchDarklyProvider';
import { MetricsProvider } from './MetricsProvider';

export const BulkPacklinkProviders = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
    const pusherInstance = useMemo(() => new Pusher(config.pusher.key, { cluster: config.pusher.cluster }), []);

    return (
        <PacklinkProvider cdnUrl={config.cdn.baseUrl} application={Application.BULK}>
            <TenantProvider application={Application.BULK}>
                <TenantConfigProvider application={Application.BULK} cdnUrl={config.cdn.baseUrl}>
                    <SdkProvider>
                        <TranslationProvider>
                            <EventHandlerProvider client={pusherInstance}>
                                <TenantThemeProviderWrapper>
                                    <BrowserRouter basename={TenantUtils.getURLPrefix()}>
                                        <LaunchDarklyProvider>
                                            <MetricsProvider>{children}</MetricsProvider>
                                        </LaunchDarklyProvider>
                                    </BrowserRouter>
                                </TenantThemeProviderWrapper>
                            </EventHandlerProvider>
                        </TranslationProvider>
                    </SdkProvider>
                </TenantConfigProvider>
            </TenantProvider>
        </PacklinkProvider>
    );
};
