import React from 'react';
import { ProShipment } from '@packlink/packlink-sdk';
import { useDateFormat } from '@hooks/useDateFormat';
import { useTranslation } from '@packlink/translation-provider';
import { ShipmentRowCellEdit } from './ShipmentRowCellEdit';
import { ShipmentRowCell } from '@components/ShipmentList/ShipmentRow/ShipmentRowCell';
import { Typography } from '@shipengine/giger';
import { getShipmentRowSubtitleStyles } from './ShipmentRowStyles';

export interface IShipmentRowPackagesCell {
    shipment: ProShipment;
    isDraft: boolean;
    onEdit: (e: React.MouseEvent<HTMLParagraphElement>) => void;
}

export const ShipmentRowPackagesCell: React.FC<IShipmentRowPackagesCell> = (
    props: IShipmentRowPackagesCell,
): JSX.Element => {
    const {
        shipment: {
            data: { parcels, content, orderDate },
        },
        isDraft,
        onEdit,
    } = props;

    const { t } = useTranslation();
    const formatedOrderDate = useDateFormat(orderDate);

    const getCellContent = (): JSX.Element => {
        let titleText: string = content?.description || t('shipment-row.parcel.no-content');
        let subtitle;

        switch (parcels?.length) {
            case 0:
                titleText = t('shipment-row.parcel.details-missing');
                subtitle = t('shipment-row.parcel.details-missing');
                break;
            case 1: {
                const { height, length, weight, width } = parcels[0];
                subtitle = `${weight || '-'} kg ${length || '-'} x ${width || '-'} x ${height || '-'} cm`;
                break;
            }
            default:
                subtitle = `${parcels?.length} ${t('shipment-row.parcel.items')}`;
                break;
        }

        return (
            <>
                <ShipmentRowCellEdit title={titleText} isEditable={isDraft} />
                <Typography variant="small" component="p" css={getShipmentRowSubtitleStyles}>
                    {subtitle}
                </Typography>
                <Typography variant="small" component="p" css={getShipmentRowSubtitleStyles}>{`${t(
                    'shipment-row.parcel.created',
                )} ${formatedOrderDate}`}</Typography>
            </>
        );
    };

    return (
        <ShipmentRowCell isEditable={isDraft} onEdit={onEdit}>
            {getCellContent()}
        </ShipmentRowCell>
    );
};
