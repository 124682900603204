import { flow } from 'lodash';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { useToast } from '@shipengine/giger';
import Packlink from '@sdk';
import { setCheckoutBusy } from '@store/actions/checkout';
import { getCheckoutService, getCheckoutShipment, getCheckoutPriceDetails } from '@store/selectors/checkout';
import { getCurrentInbox } from '@store/selectors/inbox';
import { GTMActionFieldOption } from '@constants/gtm';
import { addBreadcrumb, LoggerBreadcrumb } from '@utils/logger';
import { AppDispatch } from '@store';
import { OnPayProps } from '@components/PaymentOptions/PaymentOptions';
import { inboxToRoute } from '@pages/router/utils/paths';
import { APP_ROUTE } from '@pages/router/routes';
import { useCheckoutPath } from '@pages/checkout/hooks/useCheckoutPath';
import { CheckoutRoute } from '@pages/checkout/routes';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { getCustomsForm, getIsCustomsFormSkipped } from '@Customs/store/selectors/customs';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { API_ERRORS_MAPPING } from '@constants/apiErrors';
import { getPaymentErrors, getPaymentErrorsTranslated, PaymentApiError } from '@utils/error';
import { OrderError, useCreateOrder } from '@common/hooks/useCreateOrder';
import { Order } from '@packlink/packlink-sdk';
import { useCallback } from 'react';

export function useCreateCheckoutOrder() {
    const checkoutService = useSelector(getCheckoutService);
    const shipment = useSelector(getCheckoutShipment);
    const priceDetails = useSelector(getCheckoutPriceDetails);
    const currentInbox = useSelector(getCurrentInbox);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const toast = useToast(t);
    const navigate = useNavigate();
    const getCheckoutPath = useCheckoutPath();
    const { sendPurchaseGtmEvent, sendCheckoutStepGtmEvent } = useGoogleTagManager();
    const section = 'checkout';
    const customsInvoice = useSelector(getCustomsForm);
    const isCustomsFormSkipped = useSelector(getIsCustomsFormSkipped);

    const { createOrder } = useCreateOrder(section);
    const createCheckoutOrderCallback = useCallback(
        ({ type, method, nonce, metrics, deviceData, carriersTermsAndConditions }: OnPayProps) => {
            dispatch(setCheckoutBusy(true));

            const customsInvoiceId = shipment.hasCustoms && !isCustomsFormSkipped ? customsInvoice?.id : undefined;

            const orderShipment = flow((shipment) => ShipmentUtils.updateCustoms(shipment, customsInvoiceId))(shipment);

            addBreadcrumb({
                message: 'Create order request',
                category: LoggerBreadcrumb.ORDER,
                data: {
                    packlinkReference: shipment.packlinkReference,
                },
            });

            const mutationEvents = {
                onSuccess: (order: Order) => {
                    navigate(APP_ROUTE.SHIPMENTS.DETAILS.replace(':id', order.shipments[0].shipmentReference));

                    const gtmShipment = [
                        {
                            service: checkoutService,
                            from: shipment.from,
                            to: shipment.to,
                            packages: shipment.parcels,
                            insuranceSelected: shipment.upsales?.insurance?.available,
                            source: shipment.source,
                        },
                    ];

                    sendCheckoutStepGtmEvent({
                        shipments: gtmShipment,
                        actionFieldOption: GTMActionFieldOption.MANUAL_CHECKOUT,
                        stepNumber: 3,
                        paymentMethod: method,
                    });

                    sendPurchaseGtmEvent({
                        purchaseId: order.shipments[0].shipmentReference,
                        paymentMethod: method,
                        purchaseDate: new Date().toISOString(),
                        shipments: gtmShipment,
                        price: priceDetails?.price,
                    });
                },
                onError: (error: OrderError) => {
                    if (error.response['status'] === 403) return; // This case is handled by useCreateOrder
                    const errorMessages: PaymentApiError[] = error?.response?.data?.messages as PaymentApiError[];
                    const errorKeys = getPaymentErrors(errorMessages);
                    const firstErrorCode = errorMessages?.[0]?.error_code ?? '';
                    const firstErrorMessage = errorMessages?.[0]?.message ?? '';

                    addBreadcrumb({
                        message: 'Create order error',
                        category: LoggerBreadcrumb.ORDER,
                        data: errorKeys ? { message: getPaymentErrorsTranslated(t, errorKeys) } : undefined,
                    });

                    if (firstErrorCode) {
                        if (
                            firstErrorCode === API_ERRORS_MAPPING.CARRIER_PRODUCT_FAILURE &&
                            firstErrorMessage.includes('Service not available for this user')
                        ) {
                            toast.error({ message: t(errorKeys[0].key) });
                            navigate(getCheckoutPath(CheckoutRoute.SERVICE));
                        } else if (firstErrorCode === API_ERRORS_MAPPING.SHIPMENT_ALREADY_PAYED) {
                            if (shipment.packlinkReference) {
                                const reference = shipment.packlinkReference;
                                Packlink.pro.shipments
                                    .getShipment(reference)
                                    .then((shipment) => {
                                        const status = shipment.data.state;
                                        if (!/^(AWAITING_COMPLETION|READY_TO_PURCHASE)/.test(status)) {
                                            navigate(APP_ROUTE.SHIPMENTS.DETAILS.replace(':id', reference));
                                        } else {
                                            toast.error({ message: t('SERVER_REQUEST_ERROR') });
                                            navigate(inboxToRoute(currentInbox));
                                        }
                                    })
                                    .catch(() => {
                                        toast.error({ message: t('SERVER_REQUEST_ERROR') });
                                        navigate(inboxToRoute(currentInbox));
                                    });
                            } else {
                                navigate(inboxToRoute(currentInbox));
                            }
                        } else {
                            toast.error({ message: getPaymentErrorsTranslated(t, errorKeys) });
                            navigate(getCheckoutPath(CheckoutRoute.ADDRESS));
                        }
                    } else {
                        toast.error({ message: t('SERVER_REQUEST_ERROR') });
                    }
                },
                onSettled: () => {
                    dispatch(setCheckoutBusy(false));
                },
            };

            createOrder(
                [orderShipment],
                { type, method },
                nonce,
                metrics,
                deviceData,
                mutationEvents,
                carriersTermsAndConditions,
            );
        },
        [
            dispatch,
            shipment,
            isCustomsFormSkipped,
            customsInvoice?.id,
            createOrder,
            navigate,
            checkoutService,
            sendCheckoutStepGtmEvent,
            sendPurchaseGtmEvent,
            priceDetails?.price,
            t,
            toast,
            getCheckoutPath,
            currentInbox,
        ],
    );

    return { createCheckoutOrder: createCheckoutOrderCallback };
}
