import React from 'react';

import { IOrderShipment } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import {
    Button,
    Dialog,
    DialogContent,
    DialogHeader,
    Table,
    TableBody,
    TableBodyCell,
    TableHeader,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
} from '@shipengine/giger';

import { getPaymentErrorMessageKey } from '@utils/error';

import {
    getPaymentErrorCellStyles,
    getPaymentErrorModalStyles,
    getPaymentErrorTableStyles,
} from '../PaymentSummaryStyles';

interface IPaymentSummaryErrorsModalProps {
    isOpened: boolean;
    shipments: IOrderShipment[];
    onClose: () => void;
    onGoToDrafts: () => void;
}

export const PaymentSummaryErrorsModal: React.FunctionComponent<IPaymentSummaryErrorsModalProps> = (
    props: IPaymentSummaryErrorsModalProps,
): JSX.Element => {
    const { isOpened, shipments, onClose, onGoToDrafts } = props;
    const { t } = useTranslation();

    return (
        <Dialog isOpen={isOpened} css={getPaymentErrorModalStyles}>
            <DialogHeader
                title={t('payment-summary.errors-modal.title')}
                showClose={true}
                closeAriaLabel={t('payment-summary.errors-modal.close')}
                onClose={onClose}
            />
            <DialogContent>
                <Table
                    css={getPaymentErrorTableStyles}
                    header={
                        <TableHeader>
                            <TableHeaderRow>
                                <TableHeaderCell css={getPaymentErrorCellStyles}>
                                    {t('payment-summary.errors-modal.ref')}
                                </TableHeaderCell>
                                <TableHeaderCell css={getPaymentErrorCellStyles}>
                                    {t('payment-summary.errors-modal.error')}
                                </TableHeaderCell>
                            </TableHeaderRow>
                        </TableHeader>
                    }
                >
                    <TableBody>
                        {shipments.map((shipment: IOrderShipment) => (
                            <TableRow key={shipment.shipmentReference}>
                                <TableBodyCell css={getPaymentErrorCellStyles}>
                                    {shipment.shipmentReference}
                                </TableBodyCell>
                                <TableBodyCell css={getPaymentErrorCellStyles}>
                                    {t(getPaymentErrorMessageKey(shipment))}
                                </TableBodyCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Button onClick={onGoToDrafts}>{t('payment-summary.errors-modal.drafts-button')}</Button>
            </DialogContent>
        </Dialog>
    );
};
