import { IntegrationConfigurations } from '@Integrations/components/IntegrationPanel/types';
import { Integration, IntegrationType } from '@packlink/packlink-sdk';

import { IModuleIntegration } from '../types/module-integration';

// TODO: hidden property is only used on "epages_epages" ecommerce which is probably
// not necessary anymore. Investigate this case to remove the hidden property and
// this related filter https://packlink.atlassian.net/browse/MUL-145
export const isNotHidden = (inboundIntegration: Integration<IntegrationConfigurations>): boolean =>
    !inboundIntegration.hidden;

export const moduleIntegrationToIntegration = (
    moduleIntegration: IModuleIntegration,
): Integration<IntegrationConfigurations> => {
    const { name, alias, integrationType, hidden } = moduleIntegration.config;

    return {
        name,
        alias,
        type: integrationType || IntegrationType.MODULE,
        hidden,
        logo: '',
        rank: 0,
        isConnected: false,
    };
};

// Rank number comes from tenant and its added to the inbound integrations.
// Its the only value from that file we will need for now and may be removed soon.
export const addRank = (
    integration: Integration<IntegrationConfigurations>,
    rank?: number,
): Integration<IntegrationConfigurations> => {
    integration.rank = rank || 0;
    return integration;
};

// Hides the integration card from integration list
export const hideIntegrationCard = (
    integration: Integration<IntegrationConfigurations>,
): Integration<IntegrationConfigurations> => {
    integration.hideIntegrationCard = true;
    return integration;
};

export const addLogo = (
    integration: Integration<IntegrationConfigurations>,
): Integration<IntegrationConfigurations> => {
    const cleanAlias = integration.alias.replace(/epages_|shipengine_|mirakl_|_module/gi, '');
    integration.logo = `${config.cdn.url}/integration-logos/${cleanAlias}.svg`;
    return integration;
};

export const orderBasedOnRank = (
    a: Integration<IntegrationConfigurations>,
    b: Integration<IntegrationConfigurations>,
): number => b.rank - a.rank;

export const addConfiguration = (
    integration: Integration<IntegrationConfigurations>,
    configurations: IntegrationConfigurations[],
): Integration<IntegrationConfigurations> => {
    const configuration = configurations.find(
        (configuration: IntegrationConfigurations) => configuration.integration === integration.alias,
    );

    integration.configuration = configuration;
    integration.isConnected = !!configuration;

    return integration;
};
