import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });

export const getInlineNotificationStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
    });

export const getDialogHeaderImageStyles = (theme: Theme): SerializedStyles =>
    css({
        height: theme.spacing(4),
    });
