import { Outlet } from 'react-router';
import { AppSidebar } from '../AppSidebar/AppSidebar';
import { getAppSidebarLayoutStyles, getContentStyles } from './AppSidebarLayoutStyles';

export function AppSidebarLayout(): JSX.Element {
    return (
        <main css={getAppSidebarLayoutStyles} id="shipments-main">
            <AppSidebar />

            <div css={getContentStyles}>
                <Outlet />
            </div>
        </main>
    );
}
