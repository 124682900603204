import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTermsTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'block',
        marginBottom: theme.spacing(),
        color: theme.palette.secondary.dark,
    });

export const getTermsStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(4),
    });
