import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getCashOnDeliveryFormStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: 0,
        paddingTop: theme.spacing(3),

        [theme.mediaQuery('tablet')]: {
            padding: 0,
            paddingTop: theme.spacing(3),
        },
    });

export const getCashOnDeliveryPriceStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.secondary.dark,
        textAlign: 'end',
    });
