import { useZendeskWidgetClient } from '@SupportWidget/hooks/useZendeskWidgetClient';
import { ButtonColor, Button, Icon, IconSize, Typography, ButtonSize } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { MouseEventHandler, ReactNode, useEffect } from 'react';
import { getSupportWidgetButtonStyles, getSupportWidgetStyles } from './SupportWidgetStyles';
import { useTranslation } from '@packlink/translation-provider';
import { useIsMobile } from '@SupportCenter/utils/useIsMobile';

export function SupportWidget() {
    const { client: zendeskWidgetClient, user, isLoading, config } = useZendeskWidgetClient();
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    useEffect(() => {
        if (!isLoading) {
            // Zendesk Widget remembers the widget state, so we close it on load
            zendeskWidgetClient?.close();
        }
    }, [zendeskWidgetClient, isLoading]);

    if (isLoading || !config.widgetEnabled) {
        return null;
    }

    const onContactClick = () => {
        zendeskWidgetClient?.open();
    };

    const onCallClick = () => {
        zendeskWidgetClient?.openCall();
    };

    const areCallsEnabled = user?.isVip && config.talkEnabled;

    return (
        <div css={getSupportWidgetStyles}>
            {areCallsEnabled && (
                <SupportWidgetButton onClick={onCallClick} icon={IconNames.AGENT} showText={!isMobile}>
                    {t('support-widget.button.call')}
                </SupportWidgetButton>
            )}

            <SupportWidgetButton onClick={onContactClick} icon={IconNames.HELP} showText={!isMobile}>
                {t('support-widget.button.contact')}
            </SupportWidgetButton>
        </div>
    );
}

function SupportWidgetButton({
    onClick,
    icon,
    showText,
    children,
}: {
    onClick: MouseEventHandler<HTMLButtonElement>;
    icon: IconNames;
    showText: boolean;
    children: ReactNode;
}) {
    return (
        <Button
            css={getSupportWidgetButtonStyles}
            size={ButtonSize.SMALL}
            color={ButtonColor.SECONDARY}
            onClick={onClick}
        >
            <Icon name={icon} size={IconSize.SIZE_MEDIUM} color={ButtonColor.SECONDARY} />
            {showText && <Typography variant="heading6">{children}</Typography>}
        </Button>
    );
}
