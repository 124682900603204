import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getPaymentStepTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `0 ${theme.spacing(2)}px`,
        color: theme.palette.secondary.dark,

        [theme.mediaQuery('tablet')]: {
            padding: `0 ${theme.spacing(3)}px`,
        },
    });

export const getPaymentStepPaymentGridStyles = (editable: boolean) => (): SerializedStyles =>
    css({
        opacity: editable ? 0.3 : 1,
        pointerEvents: editable ? 'none' : 'all',

        // Using `&` twice to increase specificity
        '&&': {
            label: {
                pointerEvents: editable ? 'none' : 'inherit',
            },
        },
    });

export const paymentOptionsTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'block',
        marginBottom: theme.spacing(2),
        color: theme.palette.secondary.dark,
    });
