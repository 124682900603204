import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketChatSendMessageFormStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'grid',
        rowGap: theme.spacing(2),
        columnGap: theme.spacing(3),
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto auto auto',
        gridTemplateAreas: `
                "fileInput"
                "textInput"
                "submitButton"
            `,
        [theme.mediaQuery('tablet')]: {
            gridTemplateColumns: `auto ${theme.spacing(9)}px`,
            gridTemplateRows: 'auto auto',
            gridTemplateAreas: `
                "fileInput fileInput"
                "textInput submitButton"
            `,
        },
    });

export const getTicketChatSendMessageFileInputStyles = css({
    gridArea: 'fileInput',
});

export const getTicketChatSendMessageTextInputStyles = (theme: Theme): SerializedStyles =>
    css({
        gridArea: 'textInput',
        minHeight: theme.spacing(10),
        maxHeight: theme.spacing(27),
    });

export const getTicketChatSendMessageSubmitButtonStyles = css({
    gridArea: 'submitButton',
    alignSelf: 'start',
    // Is no other way of centering button text?
    justifyContent: 'center',
});
