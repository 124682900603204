import React from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { Button, Icon, IconSize, Typography } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import {
    getIconStyles,
    getListItemStyles,
    getListStyles,
    getMainSectionStyles,
    getSecondarySectionStyles,
    getSuccessItemIcon,
    getTitleStyles,
} from './OwnContractPanelSuccessStyles';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';

export interface OwnContractPanelSuccessProps {
    carrierName: string;
    onSelectServices: () => void;
}

export const OwnContractPanelSuccess: React.FC<OwnContractPanelSuccessProps> = ({
    carrierName,
    onSelectServices,
}: OwnContractPanelSuccessProps): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const handleClick = (): void => {
        sendAmplitudeClickEvent(AmplitudeEvents.SELECT_SERVICE, {
            [AmplitudeProperties.CARRIER]: carrierName,
        });
        onSelectServices();
    };
    return (
        <div>
            <section css={getMainSectionStyles}>
                <Icon css={getIconStyles} name={IconNames.CHECK_FILLED} size={IconSize.SIZE_EXTRA_LARGE} />
                <Typography component="p" variant="body2" bold css={getTitleStyles}>
                    {t('own-contract.panel.success-title', { carrierName })}
                </Typography>
                <Typography component="p" variant="body2">
                    {t('own-contract.panel.success-subtitle')}
                </Typography>
                <Button onClick={handleClick}>
                    <Typography variant="small" bold>
                        {t('own-contract.panel.success-cta')}
                    </Typography>
                </Button>
            </section>

            <section css={getSecondarySectionStyles}>
                <Typography variant="body1" bold>
                    {t('own-contract.panel.success-list-title')}
                </Typography>
                <ul css={getListStyles}>
                    <Typography component="li" variant="body2" css={getListItemStyles}>
                        <Icon css={getSuccessItemIcon} name={IconNames.ARROW_RIGHT} size={IconSize.SIZE_SMALL} />
                        {t('own-contract.panel.success-list-item-1', { carrierName })}
                    </Typography>
                    <Typography component="li" variant="body2" css={getListItemStyles}>
                        <Icon css={getSuccessItemIcon} name={IconNames.ARROW_RIGHT} size={IconSize.SIZE_SMALL} />
                        {t('own-contract.panel.success-list-item-2')}
                    </Typography>
                </ul>
            </section>
        </div>
    );
};
