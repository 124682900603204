import {
    IShipment,
    Shipment,
    SupportTicketPaymentMethod,
    CreateDamageSupportTicketPayload,
} from '@packlink/packlink-sdk';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { useTranslation } from '@packlink/translation-provider';
import { useContext } from 'react';
import * as yup from 'yup';
import {
    SupportTicketPaymentFieldData,
    parseTicketPayment,
    useSupportTicketPaymentValidationSchema,
} from '../CommonFields/SupportTicketPaymentField/SupportTicketPaymentFieldData';
import { FormWithShipmentContext } from '../CommonFields/FormWithShipment/FormWithShipmentData';
import { parseSupportTicketClaimCommonData } from '../CommonFields/SupportTicketClaimFields/SupportTicketClaimFieldsData';
import {
    SupportTicketClaimFieldsData,
    useSupportTicketClaimFieldsSchema,
} from '../CommonFields/SupportTicketClaimFields/SupportTicketClaimFieldsData';
import {
    EMPTY_FILE_UPLOAD_ID,
    getUploadAttachmentValidationSchema,
    SupportCenterAttachment,
} from '@SupportCenter/components/UploadAttachment/UploadAttachmentData';
import { NewTicketEventHandlers } from '../../NewTicketData';

export type DamageTicketFormData<PaymentMethod extends SupportTicketPaymentMethod = SupportTicketPaymentMethod> =
    SupportTicketClaimFieldsData & {
        payment: SupportTicketPaymentFieldData<PaymentMethod>;
        proofOfDamageExternalPackaging: SupportCenterAttachment | undefined;
        proofOfDamageGoods: SupportCenterAttachment | undefined;
        proofOfDamageInternalPackaging: SupportCenterAttachment | undefined;
    };

export function useDamageTicketFormValidationSchema() {
    const { t } = useTranslation();
    const paymentMethodSchema = useSupportTicketPaymentValidationSchema();
    const supportTicketClaimFieldsSchema = useSupportTicketClaimFieldsSchema();
    const getRequiredErrorMsg = (fieldKey: string) => t('form.error.required', { field: t(fieldKey) });

    return yup
        .object<DamageTicketFormData>()
        .shape({
            proofOfDamageExternalPackaging: getUploadAttachmentValidationSchema({
                required: getRequiredErrorMsg('support-center.damage-form.proof-of-damage-external-packaging'),
            }),
            proofOfDamageGoods: getUploadAttachmentValidationSchema({
                required: getRequiredErrorMsg('support-center.damage-form.proof-of-damage-goods'),
            }),
            proofOfDamageInternalPackaging: getUploadAttachmentValidationSchema({
                required: getRequiredErrorMsg('support-center.damage-form.proof-of-damage-internal-packaging'),
            }),
            payment: paymentMethodSchema,
        })
        .concat(supportTicketClaimFieldsSchema)
        .defined();
}

export function useDamageTicketFormHandlers({ onSubmit }: NewTicketEventHandlers): {
    handleSubmit: (values: DamageTicketFormData) => Promise<void>;
} {
    const { shipment } = useContext(FormWithShipmentContext);
    const sdk = useSdk();
    const handleSubmit = async (values: DamageTicketFormData) => {
        const damageTicket = parseDamageTicket(values, shipment);
        await onSubmit(() => sdk.v1.supportCenter.createDamageSupportTicket(damageTicket));
    };

    return {
        handleSubmit,
    };
}

function parseDamageTicket(
    {
        proofOfValue,
        productDescription,
        amount,
        payment,
        proofOfDamageExternalPackaging,
        proofOfDamageGoods,
        proofOfDamageInternalPackaging,
    }: DamageTicketFormData,
    shipment: Shipment<IShipment>,
): CreateDamageSupportTicketPayload {
    return {
        shipmentReference: shipment.packlinkReference,
        ...parseSupportTicketClaimCommonData({ proofOfValue, productDescription, amount }, shipment),
        proofOfDamageExternalPackaging: proofOfDamageExternalPackaging?.uploadId || EMPTY_FILE_UPLOAD_ID,
        proofOfDamageGoods: proofOfDamageGoods?.uploadId || EMPTY_FILE_UPLOAD_ID,
        proofOfDamageInternalPackaging: proofOfDamageInternalPackaging?.uploadId || EMPTY_FILE_UPLOAD_ID,
        paymentMethod: parseTicketPayment(payment),
    };
}
