import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentSearchStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexGrow: 1,
        marginRight: 'auto',

        [theme.mediaQuery('tablet')]: {
            minWidth: 200,
            maxWidth: 250,
        },
    });

export const getShipmentSearchInputStyles = (theme: Theme): SerializedStyles =>
    css({
        borderRightWidth: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

        '&:hover': {
            borderRightWidth: 0,
        },

        '& input': {
            height: '100%',
            padding: `${theme.spacing(0.5)}px 0px ${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
        },
    });

export const getShipmentSearchButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        padding: theme.spacing(),
    });
