import React from 'react';
import { WithChildrenCommonProps } from '@shipengine/giger';
import { getInfoPanelStepListStyles, getInfoPanelStepListItemStyles } from './InfoPanelStyles';

export const InfoPanelStepList = ({ children }: WithChildrenCommonProps<unknown>): JSX.Element => {
    return (
        <ol css={getInfoPanelStepListStyles}>
            {React.Children.map(children, (child, index) => (
                <li css={getInfoPanelStepListItemStyles} key={index}>
                    {child}
                </li>
            ))}
        </ol>
    );
};
