import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSelectedAddressStyles = (theme: Theme): SerializedStyles =>
    css({
        ...theme.getCardStyle(),
        ...theme.getElevation('above'),

        padding: theme.spacing(2),
        margin: 0,
    });
export const getSelectedAddressTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'block',
        color: theme.palette.black,
        marginBottom: theme.spacing(),
    });
export const getSelectedAddressSubtitleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'block',
        color: theme.palette.black,
        marginBottom: 0,
    });
export const getSelectedAddressTextStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'block',
        color: theme.palette.gray.dark,
        marginBottom: theme.spacing(),
    });
