import { createContext, PropsWithChildren, useContext } from 'react';
import { AmplitudeLogLevels, ProviderName, Tracker } from '@packlink/metrics';
import { AmplitudeProperties } from '@constants/amplitude';
import { TenantUtils } from '@utils/tenant';

const metrics = new Tracker({ debug: false });
export const MetricsContext = createContext<Tracker>(metrics);
export function MetricsProvider({ children }: PropsWithChildren<unknown>): JSX.Element {
    const isProduction = config.isProduction;

    if (!!config.amplitude && !!config.amplitude.apikey) {
        metrics.addProvider({
            key: config.amplitude.apikey,
            name: ProviderName.AMPLITUDE,
            configuration: {
                logLevel: isProduction ? AmplitudeLogLevels.DISABLE : AmplitudeLogLevels.WARN,
            },
            commonPayload: {
                [AmplitudeProperties.TENANT]: TenantUtils.getTenantName(),
                [AmplitudeProperties.PLATFORM]: TenantUtils.getPlatform(),
                [AmplitudeProperties.PLATFORM_COUNTRY]: TenantUtils.getPlatformCountry().toUpperCase(),
            },
        });
    }

    if (isProduction && !!config.hotjar?.id) {
        metrics.addProvider({
            key: config.hotjar.id,
            name: ProviderName.HOTJAR,
        });
    }

    if (isProduction && config?.facebookPixel?.id) {
        metrics.addProvider({
            key: config.facebookPixel.id,
            name: ProviderName.FACEBOOK,
        });
    }

    return <MetricsContext.Provider value={metrics}>{children}</MetricsContext.Provider>;
}

export function useMetrics(): Tracker {
    return useContext(MetricsContext);
}
