import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getNotificationTagStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing()}px auto`,
    });

export const getShipmentRowStyles =
    (isSelected = false, isDraft = false) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(),

            [theme.mediaQuery('tablet')]: {
                minHeight: 108,
                marginBottom: 0,
                padding: `${theme.spacing(2)}px 0px 0px`,
                backgroundColor: isSelected ? theme.palette.primary.ultraLight : theme.palette.white,
                borderTop: `1px solid ${theme.palette.gray.light}`,
                borderBottom: `1px solid ${theme.palette.gray.light}`,
            },

            ':not(:first-of-type)': {
                marginTop: theme.spacing(),
            },
            ...(isDraft && {
                ':hover': {
                    'span:first-of-type': {
                        display: 'inline-block',
                    },
                },
            }),
        });

export const getShipmentRowTrackingStyles = (theme: Theme): SerializedStyles =>
    css({
        fontSize: theme.typography.fontSize.S,
        '> * + *': {
            marginLeft: theme.spacing(1 / 2),
        },
    });

export const getShipmentRowAllTrackingsWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '> * + *': {
            marginLeft: theme.spacing(1 / 2),
        },
    });

export const getShipmentRowMoreTrackingsStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(1 / 2),
        paddingLeft: theme.spacing(),
        color: theme.palette.info.main,
        fontSize: theme.typography.fontSize.S,
        borderLeft: `1px solid ${theme.palette.gray.light}`,
        cursor: 'pointer',
    });

export const getShipmentRowExtraIdsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1 / 2),
    });

export const getShipmentRowContentStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
    });

export const getShipmentRowFieldsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.mediaQuery('tablet')]: {
            flexDirection: 'row',
            boxShadow: 'none',
        },
    });

export const getShipmentRowCustomRefStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
        color: theme.palette.gray.main,
    });

export const getShipmentRowMetaStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'none',
        marginBottom: 0,
        [theme.mediaQuery('tablet')]: {
            display: 'block',
            marginBottom: theme.spacing(1 / 2),
        },
    });

export const getShipmentRowSubtitleStyles = (theme: Theme): SerializedStyles =>
    css({
        maxWidth: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginBottom: 0,
        display: 'none',
        color: theme.palette.gray.main,
        lineHeight: 1.3,
        ':last-child': {
            marginBottom: 0,
        },
        [theme.mediaQuery('tablet')]: {
            display: 'block',
            marginBottom: theme.spacing(1 / 2),
        },
    });

export const getShipmentRowSubtitleContractStyles = (): SerializedStyles =>
    css(
        { ...getShipmentRowSubtitleStyles },
        {
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1.3,
        },
    );

export const getShipmentRowInfoStyles =
    (isSelected = false, isBulkActionsActive = false) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: isSelected ? theme.palette.primary.ultraLight : theme.palette.white,
            borderRadius: theme.borderRadius.S,
            boxShadow: `0 2px 8px 0 ${theme.palette.gray.ultraLight}`,

            [theme.mediaQuery('tablet')]: {
                borderRadius: 0,
                boxShadow: 'none',
            },
            ...(isBulkActionsActive && {
                pointerEvents: 'none',
                touchAction: 'none',
            }),
        });

export const getShipmentRowCellStyles =
    (showBorder = true, isCheckbox = false) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            alignItems: 'center',
            minHeight: '40px',
            padding: `0 ${theme.spacing(2)}px`,
            overflow: 'hidden',
            borderBottom: showBorder ? `1px solid ${theme.palette.gray.light}` : 'none',
            width: 'auto',
            ...(isCheckbox && {
                alignItems: 'flex-start',
                minHeight: 0,
                padding: `0 ${theme.spacing(2)}px 0 0`,
                width: 'auto',
            }),

            [theme.mediaQuery('tablet')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
                flex: isCheckbox ? '0 0 auto' : 1,
                borderBottom: 'none',
                width: '1px',
                ...(isCheckbox && {
                    padding: `0 0 0 ${theme.spacing(2)}px`,
                    width: 'auto',
                }),
            },
        });

export const getShipmentRowEditableCellStyles = (theme: Theme): SerializedStyles =>
    css({
        ':hover': {
            backgroundColor: theme.palette.gray.megaLight,
            borderRadius: theme.borderRadius.M,
            cursor: 'pointer',
        },
    });

export const getShipmentRowEditBtnStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'none',
        alignItems: 'center',
        marginRight: theme.spacing(),
        paddingRight: theme.spacing(),
        color: theme.palette.primary.main,
        borderRight: `1px solid ${theme.palette.gray.light}`,
    });

export const getShipmentRowCellInteractiveTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        flexShrink: 0,
        fontWeight: theme.typography.fontWeight.semibold,
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginBottom: 0,
        lineHeight: 1.7,
        [theme.mediaQuery('tablet')]: {
            marginBottom: theme.spacing(1 / 2),
        },
    });

export const getShipmentRowCellInteractiveTextStyles = (): SerializedStyles =>
    css({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    });
