import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getBoxStyles = (theme: Theme): SerializedStyles =>
    css({
        borderRadius: theme.borderRadius.S,
        border: `1px solid ${theme.palette.gray.main}`,
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.white,
    });

export const getPlanStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    });

export const getPlanNameStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
    });

export const getPlanIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
    });
