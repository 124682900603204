import { useTranslation } from '@packlink/translation-provider';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { ISidePanelFooterActionsProps, SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { TermsAndConditions } from '@components/TermsAndConditions/TermsAndConditions';
import { UPSTrademarkNoticeFooter } from '@components/UPSTrademarkNotice/UPSTrademarkNoticeFooter';
import { WithChildrenCommonProps } from '@shipengine/giger';

type ShipmentPanelFooterProps = Pick<ISidePanelFooterActionsProps, 'onAction' | 'actionButtonType' | 'isLoading'> &
    WithChildrenCommonProps<{
        submitButtonLabel?: string;
        cancelButtonLabel?: string;
        isSaveDisabled?: boolean;
        showTermsAndConditions?: boolean;
        showUPSTrademarkNotice?: boolean;
        onExit: () => void;
    }>;

export function ShipmentPanelFooter(props: ShipmentPanelFooterProps): JSX.Element {
    const { t } = useTranslation();
    const {
        isSaveDisabled = false,
        children,
        showTermsAndConditions = true,
        actionButtonType = 'submit',
        submitButtonLabel = t('shipment-panel.actions.save') as string,
        cancelButtonLabel = t('shipment-panel.actions.exit') as string,
        onExit,
        onAction,
        isLoading = false,
        showUPSTrademarkNotice = false,
    } = props;

    return (
        <SidePanelFooter>
            <SidePanelFooterActions
                disabledAction={isSaveDisabled}
                actionButtonType={actionButtonType}
                actionText={submitButtonLabel}
                cancelText={cancelButtonLabel}
                onCancel={onExit}
                onAction={onAction}
                isLoading={isLoading}
            ></SidePanelFooterActions>

            {showTermsAndConditions && <TermsAndConditions buttonText={submitButtonLabel as string} />}
            {showUPSTrademarkNotice && <UPSTrademarkNoticeFooter />}

            {children}
        </SidePanelFooter>
    );
}
