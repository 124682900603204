import { useCallback, useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { useTranslation } from '@packlink/translation-provider';
import { Origin } from '@types';
import { LocationUtils } from '@utils/LocationUtils';
import { IPostalZone } from '@packlink/packlink-sdk';
import { Field, FormikState, useFormikContext } from 'formik';
import { FormField, SelectAutocomplete } from '@shipengine/formik-giger';
import { ISelectAutocompleteOption } from '@shipengine/giger';
import { getPostalSelectorFieldStyles } from '@components/LocationForm/LocationFormStyles';
import { ILocationForm } from '@components/LocationForm/LocationForm';

export interface ICountryPostalZoneSelectorProps {
    origin: Origin;
    error?: string;
    touched?: boolean;
    isDisabled?: boolean;
    initialZoneId?: string;
    readOnly?: boolean;
    onChange: (country: IPostalZone) => void;
    onBlur?: (name: string) => void;
}

export const CountryPostalZoneSelector = (props: ICountryPostalZoneSelectorProps): JSX.Element => {
    const { origin, touched, isDisabled, initialZoneId, readOnly, onChange, onBlur } = props;
    const { setFormikState } = useFormikContext<ILocationForm>();
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const fetchedCountries = useAsync(async () => {
        return origin === Origin.FROM
            ? await LocationUtils.getOrigins(locale)
            : await LocationUtils.getDestinations(locale);
    });

    const countryOptions = useCallback(() => {
        if (fetchedCountries.value?.length === 0) {
            return [];
        }
        const getParsedOption = (option: IPostalZone) => {
            const getOptionLabel = (option: IPostalZone): string => option?.name;
            const getOptionValue = (option: IPostalZone): string => option?.id;
            return { label: getOptionLabel(option), value: getOptionValue(option) };
        };

        return fetchedCountries?.value?.map(getParsedOption) || [];
    }, [fetchedCountries]);

    const searchPostalZone = useCallback(
        (field: 'id', value?: string): IPostalZone | undefined => {
            if (!value) return;
            return fetchedCountries?.value?.find((postalZone: IPostalZone) => postalZone[field] === value);
        },
        [fetchedCountries?.value],
    );

    const handleChange = useCallback(
        (_name: string, option: ISelectAutocompleteOption | null): void => {
            const selectedCountry = searchPostalZone('id', option?.value);

            if (!selectedCountry) {
                return;
            }
            onChange(selectedCountry);
        },
        [onChange, searchPostalZone],
    );

    const setPostalZoneState = useCallback(
        (postalZone: IPostalZone) => {
            setFormikState((prevState: FormikState<ILocationForm>) => ({
                ...prevState,
                values: {
                    ...prevState.values,
                    [origin]: {
                        ...prevState.values[origin],
                        country: {
                            value: postalZone.id,
                            label: postalZone.name,
                        },
                        alpha2Code: postalZone.alpha2Code,
                        state: postalZone.name,
                    },
                },
            }));
        },
        [origin, setFormikState],
    );

    useEffect(() => {
        if (initialZoneId) {
            const initialPostalZone = searchPostalZone('id', initialZoneId);

            if (initialPostalZone) {
                setPostalZoneState(initialPostalZone);
            }
        }
    }, [fetchedCountries?.value, initialZoneId, origin, searchPostalZone, setFormikState, setPostalZoneState]);

    const noOptionsMessage = (): string => t('autocomplete.messages.no-results-found');

    return (
        <div css={getPostalSelectorFieldStyles}>
            <FormField name={`${origin}.country`}>
                <Field
                    component={SelectAutocomplete}
                    name={`${origin}.country`}
                    options={countryOptions()}
                    label={t('form.label.country')}
                    onChange={handleChange}
                    touched={touched}
                    onBlur={onBlur}
                    noResultsLabel={noOptionsMessage()}
                    disabled={isDisabled}
                    readOnly={readOnly}
                />
            </FormField>
        </div>
    );
};
