import { WithCommonProps } from '@shipengine/giger';
import {
    Currency,
    DamageSupportTicket,
    IShipment,
    LossSupportTicket,
    Shipment,
    SupportTicketPaymentMethod,
} from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { TicketDataSection } from '../TicketDataSection/TicketDataSection';
import { TicketDataSectionItem } from '../TicketDataSection/TicketDataSectionItem';

type TicketClaimDataProps = WithCommonProps<{
    supportTicket: LossSupportTicket | DamageSupportTicket;
    shipment: Shipment<IShipment>;
}>;

export function TicketClaimData({ supportTicket, shipment, ...props }: TicketClaimDataProps): JSX.Element {
    const { t } = useTranslation();
    const compensationCoverage = useCompensationCoverage(shipment);
    const paymentMethod = usePaymentMethod(supportTicket);

    return (
        <TicketDataSection title={t('support-center.ticket-data-details.claim-data-title')} {...props}>
            <TicketDataSectionItem
                title={t('support-center.ticket-data-details.claim-data-compensation-coverage-label')}
            >
                {compensationCoverage}
            </TicketDataSectionItem>
            <TicketDataSectionItem title={t('support-center.ticket-data-details.claim-data-claim-amount-label')}>
                {Currency.format(supportTicket.amount.value, supportTicket.amount.currency)}
            </TicketDataSectionItem>
            {paymentMethod && (
                <TicketDataSectionItem title={t('support-center.ticket-data-details.common-data-payment-method-label')}>
                    {paymentMethod}
                </TicketDataSectionItem>
            )}
        </TicketDataSection>
    );
}

function useCompensationCoverage({ upsales }: Shipment<IShipment>) {
    const { t } = useTranslation();
    const compensationCoverage = upsales?.insurance?.amount;

    return compensationCoverage
        ? t('support-center.ticket-data-details.claim-data-with-compensation-coverage')
        : t('support-center.ticket-data-details.claim-data-no-compensation-coverage');
}

function usePaymentMethod(supportTicket: LossSupportTicket | DamageSupportTicket) {
    const { t } = useTranslation();

    switch (supportTicket.paymentMethod.type) {
        case SupportTicketPaymentMethod.BANK:
            return t('support-center.ticket-data-details.payment-method-bank');
        case SupportTicketPaymentMethod.PAYPAL:
            return t('support-center.ticket-data-details.payment-method-paypal');
        case SupportTicketPaymentMethod.UNKNOWN:
            return undefined;
    }
}
