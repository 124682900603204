import { SerializedStyles, css } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getNewTicketFormSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(5),
    });

export const getFormFieldStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
    });

export const getFormElementStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
    });

export const getFormFileInputStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
    });

export const getFormInputStyles = (theme: Theme): SerializedStyles =>
    css({
        maxWidth: theme.spacing(60),
    });

// More info about this reset: https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
export const getFieldsetResetStyles = (): SerializedStyles =>
    css({
        border: 0,
        padding: '0.01em 0 0 0',
        margin: 0,
        minWidth: 0,
        legend: {
            padding: 0,
        },
    });

export const getSubmitButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(4),
    });
