import { PaymentOptions } from '@components/PaymentOptions/PaymentOptions';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { PaymentMethod } from '@packlink/packlink-sdk';

import { useSettingsPaymentDeferredHandlers } from '@Invoicing/hooks/useSettingsPaymentDeferredHandlers';
import { DeferredPaymentsFormStatus, useDeferredPaymentFormStatus } from '@Invoicing/context/deferredPaymentFormStatus';

import { useSettingsPaymentDeferredActions } from '@Invoicing/hooks/useSettingsPaymentDeferredActions';

import { ITenantConfig } from '@types';

const VERIFICATION_AMOUNT = 0.01;

export const PaymentOptionsDeferred = () => {
    const { defaultCurrency } = useTenantConfig<ITenantConfig>();

    const { deferredPaymentFormStatus } = useDeferredPaymentFormStatus();
    const { onError, onVerificationSuccess } = useSettingsPaymentDeferredHandlers();
    const { handleDeletePaymentMethod } = useSettingsPaymentDeferredActions();

    return (
        <PaymentOptions
            isDeferred
            amount={VERIFICATION_AMOUNT}
            currency={defaultCurrency}
            isPaying={deferredPaymentFormStatus === DeferredPaymentsFormStatus.LOADING}
            allowedPaymentMethods={[PaymentMethod.CREDITCARD3DSECURE, PaymentMethod.PAYPAL]}
            section="settings deferred"
            onPay={onVerificationSuccess}
            onError={onError}
            onDelete={handleDeletePaymentMethod}
        />
    );
};
