import { useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';
import { ISubscription } from '@packlink/packlink-sdk';
import { getClientPlanDetailsStyles, getSectionStyles, getHeaderStyles } from './ClientPlanDetailsStyles';
import { CancelLink } from './components/CancelLink/CancelLink';
import { PaymentFrecuency } from './components/PaymentFrecuency/PaymentFrecuency';
import { PlanInfo } from './components/PlanInfo/PlanInfo';
import { TermsAndConditionsLink } from './components/TermsAndConditionsLink/TermsAndConditionsLink';

export interface IClientPlanDetails {
    subscription: ISubscription;
    onCancelFlow: () => void;
}

export function ClientPlanDetails({ subscription, onCancelFlow }: IClientPlanDetails): JSX.Element {
    const { t } = useTranslation();

    return (
        <section css={getClientPlanDetailsStyles}>
            <Typography component="h5" variant="heading5" css={getHeaderStyles}>
                {t('subscriptions.plan.active-plan')}
            </Typography>

            <PlanInfo subscription={subscription} />

            <TermsAndConditionsLink css={getSectionStyles} />

            <PaymentFrecuency css={getSectionStyles} subscription={subscription} />

            <CancelLink css={getSectionStyles} onCancelFlow={onCancelFlow} />
        </section>
    );
}
