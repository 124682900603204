import { TenantName } from '@packlink/packlink-sdk';
import { useSupportCenter, useHelpCenter, SUPPORT_CENTER_KEYS, HELP_CENTER_KEYS } from '@packlink/provider';
import { TenantUtils } from '@utils/tenant';

// Currently EBAYGB users doesn't have access to Shipments Reference number
// hence we are redirecting them to Help Center instead of Support Center.

type useGetHelpUrlProps = {
    shipmentReference?: string;
    newTicketType?: string;
};

export function useGetHelpUrl({ shipmentReference, newTicketType }: useGetHelpUrlProps) {
    const getSupportCenterPage = useSupportCenter();
    const getHelpCenterPage = useHelpCenter();

    const supportUrl = shipmentReference
        ? getSupportCenterPage(SUPPORT_CENTER_KEYS.newTicket, { shipmentReference })
        : newTicketType
          ? `${getSupportCenterPage(SUPPORT_CENTER_KEYS.home)}new-ticket?ticketType=${newTicketType}`
          : getSupportCenterPage(SUPPORT_CENTER_KEYS.home);

    const helpCenterUrl = getHelpCenterPage(HELP_CENTER_KEYS.home);

    // TODO: Remove this check of the tenant name
    // https://auctane.atlassian.net/browse/PCK-3097
    const isEBAYGB = TenantUtils.getTenantName() === TenantName.EBAYGB;

    return isEBAYGB ? helpCenterUrl : supportUrl;
}
