import { QueryStatus } from 'react-query';
import { LegendProps } from '@nivo/legends';
import { BarLegendProps } from '@nivo/bar';

import { Theme } from '@shipengine/giger-theme';

import { ChartStatus, LegendPreset } from '../types';

export function getBarChartLegendPreset(
    theme: Theme,
    preset?: LegendPreset,
    customLegendConfig?: Partial<BarLegendProps>,
): BarLegendProps | undefined {
    const commonConfig = getCommonLegendPreset(theme, preset);
    if (!commonConfig) return;

    return {
        ...commonConfig,
        dataFrom: 'indexes',
        ...customLegendConfig,
    };
}

export function getLineChartLegendPreset(
    theme: Theme,
    preset?: LegendPreset,
    customLegendConfig?: Partial<LegendProps>,
): LegendProps | undefined {
    const commonConfig = getCommonLegendPreset(theme, preset);
    if (!commonConfig) return;

    return {
        ...commonConfig,
        ...customLegendConfig,
    };
}

export function getCommonLegendPreset(theme: Theme, preset?: LegendPreset): LegendProps | undefined {
    switch (preset) {
        case LegendPreset.BOTTOM:
            return {
                anchor: 'bottom',
                direction: 'row',
                translateY: 45,
                itemWidth: 100,
                itemHeight: 10,
                itemsSpacing: theme.spacing(0.5),
                symbolSize: 8,
                symbolShape: 'circle',
                itemTextColor: theme.palette.gray.dark,
            };
        case LegendPreset.RIGHT:
            return {
                anchor: 'right',
                direction: 'column',
                translateX: 120,
                itemWidth: 100,
                itemHeight: 10,
                itemsSpacing: theme.spacing(2),
                symbolSize: 8,
                symbolShape: 'circle',
                itemTextColor: theme.palette.gray.dark,
            };
        default:
            return undefined;
    }
}

export function getChartContainerStatus(status: QueryStatus, isFetching: boolean, isEmpty: boolean) {
    return {
        idle: ChartStatus.LOADING,
        loading: ChartStatus.LOADING,
        error: ChartStatus.ERROR,
        success: isEmpty ? ChartStatus.EMPTY : ChartStatus.SUCCESS,
    }[isFetching ? 'loading' : status];
}
