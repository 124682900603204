import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { useEvent } from '@packlink/event-handler';
import { Button, ButtonSize, SidePanel, SlideInPosition } from '@shipengine/giger';
import Packlink from '@sdk';
import { useSidePanel } from '@hooks/useSidePanel';
import { PanelName } from '@types';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { getClientIdentifier } from '@store/selectors/client';
import { IInvoiceAvailableEvent, InvoicingEvent } from '../../events/types';
import { getInvoicePanelActionsButtonStyles, getInvoicePanelActionsWrapperStyles } from './InvoiceDownloadPanelStyles';
import { useAmplitude } from '@hooks/useAmplitude';
import { BulkSelectionInfo } from '@components/SidePanel/BulkSelectionInfo';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';

enum StatusType {
    ERROR = 'error',
    LOADING = 'loading',
    READY = 'ready',
}

interface IInvoiceDownloadPanelProps {
    isPanelOpen: boolean;
    resetPanel: () => void;
    invoices: string[];
    downloadSingle: (s: string) => Promise<void>;
}

interface IStatus {
    type: StatusType;
    message?: string;
}

export const InvoiceDownloadPanel = (props: IInvoiceDownloadPanelProps): JSX.Element => {
    const { isPanelOpen, resetPanel, invoices, downloadSingle } = props;
    const { open, close, isOpen } = useSidePanel(PanelName.INVOICES);
    const [status, setStatus] = useState<IStatus | null>(null);
    const [numberOfInvoicesRequested, setNumberOfInvoicesRequested] = useState<number>();
    const clientId = useSelector(getClientIdentifier);
    const { eventBind, eventUnbind } = useEvent<IInvoiceAvailableEvent>(clientId, InvoicingEvent.INVOICE_AVAILABLE);
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const numberOfInvoices = invoices.length;

    const closePanel = useCallback((): void => {
        close();
        resetPanel();
        (status?.type === StatusType.READY || status?.type === StatusType.ERROR) && setStatus(null);
    }, [close, resetPanel, status]);

    const dispatchAmplitudeEvent = useCallback(
        (eventName: AmplitudeEvents, amountOfInvoices: number = numberOfInvoices): void => {
            sendAmplitudeEvent(eventName, {
                [AmplitudeProperties.NUMBER_OF_INVOICES]: amountOfInvoices,
            });
        },
        [numberOfInvoices, sendAmplitudeEvent],
    );

    const showGenericError = useCallback((): void => {
        // TO-DO: PRO-1197 Probably need to show a specific error message if backend is locked (423)
        setStatus({ type: StatusType.ERROR, message: t('invoice.bulk-panel.generic-error') });
    }, [t]);

    useEffect(() => {
        isPanelOpen ? open() : closePanel();
    }, [isPanelOpen, closePanel, open]);

    useEffect(() => {
        function handleInviceAvailable({ url, errors }: IInvoiceAvailableEvent) {
            if (!url) {
                showGenericError();
                dispatchAmplitudeEvent(AmplitudeEvents.DOWNLOAD_INVOICES_IN_BULK_FAILED, numberOfInvoicesRequested);
                return;
            }

            const errorsList = (errors && Object.keys(errors)) || [];
            if (errorsList.length > 0) {
                setStatus({
                    type: StatusType.ERROR,
                    message: `${t('invoice.bulk-panel.partial-error')} ${errorsList.join(', ')}`,
                });
                dispatchAmplitudeEvent(AmplitudeEvents.DOWNLOAD_INVOICES_IN_BULK_FAILED, numberOfInvoicesRequested);
            } else {
                setStatus({ type: StatusType.READY });
                dispatchAmplitudeEvent(AmplitudeEvents.DOWNLOAD_INVOICES_IN_BULK_SUCCESSS, numberOfInvoicesRequested);
            }
        }

        eventBind(handleInviceAvailable);

        return (): void => {
            eventUnbind(handleInviceAvailable);
        };
    }, [t, showGenericError, dispatchAmplitudeEvent, numberOfInvoicesRequested, eventBind, eventUnbind]);

    const handleDownload = (): void => {
        setStatus({ type: StatusType.LOADING });
        if (numberOfInvoices === 1) {
            downloadSingle(invoices[0])
                .then((): void => {
                    setStatus({ type: StatusType.READY });
                })
                .catch((): void => {
                    showGenericError();
                });
        } else {
            Packlink.pro.invoices
                .getInvoices(invoices)
                .then((): void => {
                    setNumberOfInvoicesRequested(numberOfInvoices);
                })
                .catch((): void => {
                    showGenericError();
                    dispatchAmplitudeEvent(AmplitudeEvents.DOWNLOAD_INVOICES_IN_BULK_FAILED);
                });

            dispatchAmplitudeEvent(AmplitudeEvents.DOWNLOAD_INVOICES_IN_BULK);
        }
    };

    return (
        <SidePanel
            css={getSidePanelClipPathStyles(SlideInPosition.RIGHT)}
            isOpen={isOpen}
            includeBackdrop={false}
            containerId="settings-panel-container"
            header={<SidePanelHeader title={t('invoice.bulk-panel.title')} onAction={closePanel} />}
        >
            <SidePanelContent>
                {!status && (
                    <section css={getInvoicePanelActionsWrapperStyles}>
                        <BulkSelectionInfo
                            count={numberOfInvoices}
                            actionsTitle="invoice.bulk-panel.actions-title"
                            actionsSubtitle="invoice.bulk-panel.actions-subtitle"
                        />
                        <section>
                            <Button
                                css={getInvoicePanelActionsButtonStyles}
                                onClick={handleDownload}
                                size={ButtonSize.SMALL}
                            >
                                {t('invoice.bulk-panel.download')}
                            </Button>
                        </section>
                    </section>
                )}
                {status?.type === StatusType.LOADING && (
                    <SidePanelMessage
                        title={t('invoice.bulk-panel.loading-title')}
                        subtitle={t('invoice.bulk-panel.loading-subtitle')}
                        type={SidePanelMessageType.LOADING}
                    />
                )}
                {status?.type === StatusType.ERROR && (
                    <SidePanelMessage
                        title={t('invoice.bulk-panel.error-title')}
                        subtitle={status.message || ''}
                        type={SidePanelMessageType.ERROR}
                    />
                )}
                {status?.type === StatusType.READY && (
                    <SidePanelMessage
                        title={t('invoice.bulk-panel.success-title')}
                        type={SidePanelMessageType.SUCCESS}
                    />
                )}
            </SidePanelContent>
        </SidePanel>
    );
};
