import { SerializedStyles, css } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketTypeStyles =
    (selected: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            position: 'relative',
            borderRadius: '4px',
            overflow: 'hidden',
            backgroundColor: selected ? theme.palette.info.light : theme.palette.white,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(2),
            border: selected ? `1px solid ${theme.palette.info.light}` : `1px solid ${theme.palette.gray.light}`,
            height: '100px',

            '&:hover': {
                ...theme.getElevation('above'),
                borderColor: theme.palette.gray.main,
            },
        });

export const getCheckSelectedIconStyle = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.primary.light,
        fontWeight: 'lighter',
        position: 'absolute',
        right: '5px',
        top: '10px',
        fontSize: theme.spacing(3),
    });

export const getTypeTextStyle = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    });

export const getTypeIconStyle = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(),
        borderRadius: '50%',
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.ultraLight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });
