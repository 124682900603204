import { InlineNotification, NotificationType, WithCommonProps } from '@shipengine/giger';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { getSupportTicketRequirementListStyles } from './SupportTicketRequirementsStyles';

type LossRequirementsProps = WithCommonProps<unknown>;

export function LossRequirements(props: LossRequirementsProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <InlineNotification
            type={NotificationType.INFO}
            title={t('support-center.support-ticket-requirements.loss-ticket-title')}
            {...props}
        >
            <Trans
                i18nKey="support-center.support-ticket-requirements.loss-ticket-requirements"
                components={{
                    ul: <ul css={getSupportTicketRequirementListStyles} />,
                    li: <li />,
                }}
            />
        </InlineNotification>
    );
}
