import React, { useCallback, useMemo, useState } from 'react';

import {
    ServiceList as ServiceListComp,
    ServiceListCardProps,
    ServiceListEvents,
    ServiceListFilters,
} from '@packlink/service-list';
import { getCheckoutThirdPartyPreferences } from '@store/selectors/checkout';

import { ServiceCard } from '@packlink/service-card';

import {
    Address,
    ApiClientError,
    IAddress,
    Parcel,
    Service,
    ServiceAllParams,
    ServiceTag,
} from '@packlink/packlink-sdk';

import { AmplitudeTypeOfServices, GTMActionFieldList } from '@packlink/metrics';
import { useDebugMode } from '@hooks/useDebugMode';
import { useAmplitude } from '@hooks/useAmplitude';
import { getMapMarker } from '@utils/cdn';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { MAX_LOADING_PROGRESS, ProgressBar } from '@components/ProgressBar/ProgressBar';
import { getServiceListStyles } from './ServiceListStyles';
import { useMetrics } from '@providers/MetricsProvider';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useSelector } from 'react-redux';
import { useThirdPartyPreferencesBehavior } from '@common/hooks/useThirdPartyPreferencesBehavior';

export interface IServiceListProps {
    from: Address;
    to: Address;
    packages: Parcel[];
    source: string;
    onSelectService: (service: Service, index: number) => void;
    onFetchServices: (services: Service[]) => void;
    onError: (eventType: ServiceListEvents, error: ApiClientError) => void;
}

export const ServiceList = (props: IServiceListProps): JSX.Element => {
    const { from, to, source, packages, onSelectService, onFetchServices, onError } = props;
    const { thirdPartyPreferencesEnabled } = useThirdPartyPreferencesBehavior(source);
    const [isLoadingStateComplete, setLoadingStateComplete] = useState(false);
    const [loadingProgressValue, setLoadingProgressValue] = useState(0);
    const [services, setServices] = useState<Service[]>();
    const isDebugMode = useDebugMode();
    const metrics = useMetrics();
    const { sendProductClickGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeEvent } = useAmplitude();
    const thirdPartyPreferences = useSelector(getCheckoutThirdPartyPreferences);

    const searchParams = useMemo((): ServiceAllParams => {
        return {
            from: {
                country: from?.alpha2Code,
                zip: from?.zipCode,
            },
            packages: packages.map((p: Parcel) => ({
                height: p.height,
                length: p.length,
                weight: p.weight,
                width: p.width,
            })),
            sortBy: 'totalPrice',
            source,
            to: {
                country: to?.alpha2Code,
                zip: to?.zipCode,
            },
            ...(thirdPartyPreferencesEnabled && {
                shipping_service_id_selected: thirdPartyPreferences?.serviceId,
                shipping_pick_up_point_id_selected: thirdPartyPreferences?.dropoffPointId,
                carrier: thirdPartyPreferences?.carrier,
            }),
        } as ServiceAllParams;
    }, [from, to, packages, source, thirdPartyPreferencesEnabled, thirdPartyPreferences]);

    const handleDetailsRequest = useCallback(
        (service: Service): void => {
            sendAmplitudeEvent(AmplitudeEvents.SERVICE_DETAILS, {
                [AmplitudeProperties.CARRIER]: service.carrierName,
                [AmplitudeProperties.SERVICE]: service.name,
            });
        },
        [sendAmplitudeEvent],
    );

    const handleSelectService = useCallback(
        (service: Service, index: number, priceBasedPosition: number): void => {
            const originTypeOfService = service.dropOff?.origin
                ? AmplitudeTypeOfServices.DROP_OFF
                : AmplitudeTypeOfServices.COLLECTION;
            const destinationTypeOfService = service.dropOff?.destination
                ? AmplitudeTypeOfServices.PICK_UP
                : AmplitudeTypeOfServices.DELIVERY;

            onSelectService(service, index);
            sendAmplitudeEvent(AmplitudeEvents.SELECT_SERVICE, {
                [AmplitudeProperties.PRICE]: service.price?.basePrice,
                [AmplitudeProperties.POSITION]: index + 1,
                [AmplitudeProperties.SERVICES_SHOWN]: services?.length,
                [AmplitudeProperties.OWN_CONTRACT]: service.isOwnContract,
                [AmplitudeProperties.TYPE_OF_SERVICE]: [originTypeOfService, destinationTypeOfService],
                [AmplitudeProperties.CUSTOMS_REQUIRED]: !!service.customsRequired?.available,
                [AmplitudeProperties.TAGS]: service?.tags?.map(({ id }: ServiceTag) => id),
                [AmplitudeProperties.SERVICE_ID]: service.id,
                [AmplitudeProperties.PRICE_BASED_POSITION]: priceBasedPosition,
            });

            sendProductClickGtmEvent({
                actionFieldList: GTMActionFieldList.SERVICE_SELECT_PRO,
                service,
                from,
                to,
                packages,
                index,
                source,
            });
        },
        [onSelectService, sendAmplitudeEvent, services?.length, sendProductClickGtmEvent, from, to, packages, source],
    );

    const handleFetchServices = useCallback(
        (services: Service[]): void => {
            setLoadingProgressValue(MAX_LOADING_PROGRESS);
            setServices(services);
            onFetchServices(services);
        },
        [onFetchServices],
    );

    return (
        <section css={getServiceListStyles}>
            <ServiceListComp
                searchParams={searchParams}
                cdnUrl={config.cdn.baseUrl}
                showFilters={ServiceListFilters.SIDEPANEL}
                LoadingState={
                    <ProgressBar
                        value={loadingProgressValue}
                        handleLoadingState={setLoadingStateComplete}
                        setLoadingProgressValue={setLoadingProgressValue}
                    />
                }
                isLoadingStateComplete={isLoadingStateComplete}
                tracker={metrics}
                from={from as Address<IAddress>}
                onSelectService={handleSelectService}
                onFetchServices={handleFetchServices}
                onDetailsRequest={handleDetailsRequest}
                onError={onError}
                debugMode={isDebugMode}
                tagsFeatureEnabled
                sortingFeatureEnabled
            >
                {(cardProps: ServiceListCardProps): React.ReactNode => (
                    <ServiceCard
                        {...cardProps}
                        // TODO: https://packlink.atlassian.net/browse/IDE-229 Load from brand
                        markerIcon={getMapMarker()}
                    />
                )}
            </ServiceListComp>
        </section>
    );
};
