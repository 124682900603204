import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getCustomHeaderStyles =
    (title?: boolean, additionalStyles?: (theme: Theme) => SerializedStyles) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.gray.light}`,
            padding: `${theme.spacing(0.5)}px 0 ${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
            ...(title ? { justifyContent: 'space-between' } : { justifyContent: 'flex-end' }),
            ...additionalStyles?.(theme),
        });
export const getCustomHeaderTitleStyles = (): SerializedStyles => css({ marginBottom: 0 });
