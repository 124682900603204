import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getParcelsContentStyles =
    (selectedParcel?: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            maxWidth: selectedParcel ? theme.breakPoints.mobileLarge : theme.breakPoints.tabletLarge,
        });

export const getParcelDetailsLegendStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: '40px',
        paddingRight: `${theme.spacing()}px`,
        whiteSpace: 'nowrap',
        position: 'relative',
        width: '100%',

        span: {
            position: 'relative',
            zIndex: 1,
            paddingRight: `${theme.spacing()}px`,
            color: theme.palette.gray.dark,
            fontSize: theme.toRem(theme.typography.fontSize.L),
            lineHeight: theme.typography.lineHeight.M,
            backgroundColor: theme.palette.white,
        },

        '&::after': {
            position: 'absolute',
            top: '50%',
            left: '0',
            width: '100%',
            borderBottom: `1px solid ${theme.palette.gray.light}`,
            content: '" "',
        },
    });

export const getParcelDetailsFooterStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'start',
        marginTop: theme.spacing(2),

        '> button': {
            margin: `0 ${theme.spacing()}px 0 0`,
        },
    });
