import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from '@packlink/translation-provider';
import { OwnContractCredentials } from '@packlink/packlink-sdk';
import { OwnContractPanelContentProps } from './OwnContractPanelTypes';
import { OwnContractPanelForm } from './OwnContractPanelForm';
import { toKebabCase } from '@utils/toKebabCase';

export const OwnContractPanelContent: React.FC<OwnContractPanelContentProps> = (
    props: OwnContractPanelContentProps,
): JSX.Element => {
    const { t } = useTranslation();
    const {
        onClose,
        onSubmit,
        isLoading,
        error,
        clearError,
        ownContract,
        onDeactivate,
        carrierId,
        formFields,
        videoTutorial,
        customValidations,
        customInputTypes,
    } = props;

    const initialValues = formFields?.reduce((obj, item) => {
        return { ...obj, [item]: ownContract.isRegistered ? '*****' : '' };
    }, {});

    const validationSchema = () => {
        const fields: Record<string, yup.Schema<string, object>> = {};
        formFields?.forEach(
            (name) =>
                (fields[name] = yup.string().required(
                    t('form.error.required', {
                        field: t(`own-contract.panel.${toKebabCase(carrierId + '-' + name)}`),
                    }),
                )),
        );
        return yup.object().shape({ ...fields, ...customValidations });
    };

    const handleOnSubmit = (formData: OwnContractCredentials): void => {
        const valuesToSubmit: OwnContractCredentials = {};
        formFields?.forEach(
            (name) =>
                (valuesToSubmit[name as keyof OwnContractCredentials] = formData[name as keyof OwnContractCredentials]),
        );
        onSubmit(valuesToSubmit);
    };

    return (
        <Formik
            onSubmit={handleOnSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema()}
            validateOnMount
            enableReinitialize
        >
            <OwnContractPanelForm
                onClose={onClose}
                isLoading={isLoading}
                error={error}
                clearError={clearError}
                ownContract={ownContract}
                onDeactivate={onDeactivate}
                formFields={formFields}
                videoTutorial={videoTutorial}
                customInputTypes={customInputTypes}
            />
        </Formik>
    );
};
