import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

const STEP_COUNTER_SIZE = 18;

export const getInfoPanelStyles = (theme: Theme): SerializedStyles =>
    css({
        borderRadius: theme.borderRadius.S,
        border: `1px solid ${theme.palette.gray.light}`,
    });

export const getInfoPanelHeaderStyles =
    (isCollapsible: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: isCollapsible ? 'pointer' : 'default',
            padding: `${theme.spacingBase}px ${theme.spacing(2)}px`,
            backgroundColor: theme.palette.primary.ultraLight,
            color: theme.palette.primary.dark,
        });

export const getInfoPanelContentStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        backgroundColor: theme.palette.gray.ultraLight,
    });

export const getInfoPanelStepStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',

        ul: {
            marginBottom: theme.spacing(2),
        },
    });

export const getInfoPanelStepHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        fontWeight: theme.typography.fontWeight.bold,

        ':only-child': {
            marginBottom: theme.spacing(2),
        },
    });

export const getInfoPanelSummaryStyles = (): SerializedStyles =>
    css({
        listStyle: 'none',

        '::marker': {
            display: 'none',
            content: '""',
        },

        '::-webkit-details-marker': {
            display: 'none',
        },
    });

export const getInfoPanelStepListStyles = (): SerializedStyles =>
    css({
        counterReset: 'step-counter',
        listStyle: 'none',
        margin: 0,
        padding: 0,
    });

export const getInfoPanelStepListItemStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: 0,
        counterIncrement: 'step-counter',
        position: 'relative',

        '::before': {
            float: 'left',
            width: STEP_COUNTER_SIZE,
            minWidth: STEP_COUNTER_SIZE,
            height: STEP_COUNTER_SIZE,
            marginRight: theme.spacingBase,
            color: theme.palette.gray.dark,
            fontWeight: theme.typography.fontWeight.bold,
            fontSize: theme.typography.fontSize.XS,
            lineHeight: `${STEP_COUNTER_SIZE}px`,
            textAlign: 'center',
            backgroundColor: theme.palette.white,
            border: `1px solid ${theme.palette.gray.dark}`,
            borderRadius: '50%',
            content: 'counter(step-counter)',
        },
    });
