import { InboxAction } from '@store/actions/inbox';
import { SET_INBOXES, SET_CURRENT_INBOX } from '@store/actions/action-types';
import { Inboxes, Inbox, IInboxes } from '@packlink/packlink-sdk';
import { currentInbox, inboxes } from '@store/selectors/inbox';

export interface IInboxState {
    currentInbox: Inbox;
    inboxes: IInboxes;
}

export const initialState = {
    currentInbox: Inbox.READY_TO_PURCHASE,
    inboxes: new Inboxes().toJSON() as IInboxes,
};

export const reducer = (state: IInboxState = initialState, action: InboxAction): IInboxState => {
    switch (action.type) {
        case SET_CURRENT_INBOX:
            return currentInbox.set(action.payload)(state);
        case SET_INBOXES:
            return inboxes.set(action.payload.toJSON() as IInboxes)(state);
        default:
            return state;
    }
};
