import {
    Button,
    ButtonSize,
    ButtonVariant,
    InlineNotification,
    NotificationType,
    WithCommonProps,
} from '@shipengine/giger';
import { Trans, useTranslation } from '@packlink/translation-provider';

type SkipCustomsNotificationProps = WithCommonProps<{
    onAction: () => void;
    actionText: string;
}>;

export function SkipCustomsNotification({ onAction, actionText, ...props }: SkipCustomsNotificationProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <InlineNotification
            type={NotificationType.INFO}
            title={t('customs.skip-notification.title')}
            actionElement={
                <Button size={ButtonSize.SMALL} variant={ButtonVariant.TEXT} onClick={onAction}>
                    {actionText}
                </Button>
            }
            {...props}
        >
            <Trans i18nKey="customs.skip-notification.content" />
        </InlineNotification>
    );
}
