import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const labelStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.main,
        marginRight: theme.spacing(0.5),
    });

export const moreIdsStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeight.semibold,
        marginRight: theme.spacing(0.5),
    });
