import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getSpecialServiceStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: theme.spacing(0.5),
    });

export const getContractIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(0.5),
        color: theme.palette.alert.main,
    });
