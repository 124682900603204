import { batch } from 'react-redux';
import {
    Address,
    CashOnDelivery,
    Content,
    IInterval,
    Interval,
    IParcel,
    IPostalCode,
    IPostalZone,
    IPrice,
    IWarehouseAddress,
    Parcel,
    Service,
    Shipment,
    ShipmentThirdPartyPreferencesProps,
    Voucher,
} from '@packlink/packlink-sdk';
import {
    DELETE_VOUCHER,
    RESET_CHECKOUT,
    RESET_CHECKOUT_SERVICE,
    SET_ADDRESS_BOOK_CHECKED,
    SET_CHECKOUT_ADDITIONAL_DATA,
    SET_CHECKOUT_ADDRESS,
    SET_CHECKOUT_ADDRESS_COUNTRY,
    SET_CHECKOUT_ADDRESS_DETAILS,
    SET_CHECKOUT_ADDRESS_POSTALCODE,
    SET_CHECKOUT_ADULT_SIGNATURE_VALUE,
    SET_CHECKOUT_BUSY,
    SET_CHECKOUT_COLLECTION_DATE,
    SET_CHECKOUT_COLLECTION_TIME,
    SET_CHECKOUT_CONTENT,
    SET_CHECKOUT_DROPOFF_POINT_ID,
    SET_CHECKOUT_INSURANCE_TYPE,
    SET_CHECKOUT_PARCELS,
    SET_CHECKOUT_PRICE_DETAILS,
    SET_CHECKOUT_SELECTED_SHIPMENT,
    SET_CHECKOUT_SELECTED_WAREHOUSE,
    SET_CHECKOUT_SERVICE,
    SET_CHECKOUT_THIRD_PARTY_PREFERENCES,
    SET_CHECKOUT_VOUCHER,
    SET_CHECKOUT_WAREHOUSE_ADDRESS,
    SET_INVOICE_INFO_SAVED,
    SET_CHECKOUT_ADDITIONAL_HANDLING_VALUE,
    SET_CHECKOUT_CASH_ON_DELIVERY,
    SET_CHECKOUT_PROOF_OF_DELIVERY,
    SET_CHECKOUT_PRINT_IN_STORE,
} from './action-types';
import { AppDispatch, ThunkResult } from '@store';
import { resetCustomsForm } from '@Customs/store/actions/customs';
import { UpsellInsuranceType } from '@types';

export type CheckoutAddressOrigin = 'to' | 'from';

export type CheckoutAddressDetails = Pick<
    Address,
    'firstName' | 'lastName' | 'company' | 'street1' | 'street2' | 'email' | 'phone' | 'id'
>;

interface ISetCheckoutAddressPayload {
    address: Address;
    origin: CheckoutAddressOrigin;
}

interface ISetCheckoutCountryPayload {
    postalZone: IPostalZone;
    origin: CheckoutAddressOrigin;
}

interface ISetCheckoutPostalCodePayload {
    postalCode: IPostalCode;
    origin: CheckoutAddressOrigin;
}

interface ISetCheckoutAddressDetailsPayload {
    addressDetails: CheckoutAddressDetails;
    origin: CheckoutAddressOrigin;
}

interface ISetCheckoutWarehouseAddressPayload {
    warehouse?: IWarehouseAddress;
    locale: string;
}

interface ISetCheckoutAdditionalDataPayload {
    additionalData: Record<string, unknown>;
}

export interface SetCheckoutThirdPartyPreferencesPayload {
    thirdPartyPreferences: ShipmentThirdPartyPreferencesProps;
}

export interface ISetParcelsCheckoutAction {
    payload: Parcel<Partial<IParcel>>[];
    type: 'SET_CHECKOUT_PARCELS';
}

interface ISetCheckoutAddressDetailsAction {
    payload: ISetCheckoutAddressDetailsPayload;
    type: 'SET_CHECKOUT_ADDRESS_DETAILS';
}

interface ISetCheckoutAddressCountryAction {
    payload: ISetCheckoutCountryPayload;
    type: 'SET_CHECKOUT_ADDRESS_COUNTRY';
}

interface ISetCheckoutAddressPostalCodeAction {
    payload: ISetCheckoutPostalCodePayload;
    type: 'SET_CHECKOUT_ADDRESS_POSTALCODE';
}

interface ISetCheckoutWarehouseAddressAction {
    payload: ISetCheckoutWarehouseAddressPayload;
    type: 'SET_CHECKOUT_WAREHOUSE_ADDRESS';
}

interface ISetCheckoutSelectedWarehouseAction {
    payload: string | undefined;
    type: 'SET_CHECKOUT_SELECTED_WAREHOUSE';
}

interface ISetCheckoutAddressAction {
    payload: ISetCheckoutAddressPayload;
    type: 'SET_CHECKOUT_ADDRESS';
}

interface ISetCheckoutAdditionalDataAction {
    payload: ISetCheckoutAdditionalDataPayload;
    type: 'SET_CHECKOUT_ADDITIONAL_DATA';
}

interface SetCheckoutThirdPartyPreferencesAction {
    payload: SetCheckoutThirdPartyPreferencesPayload;
    type: 'SET_CHECKOUT_THIRD_PARTY_PREFERENCES';
}

interface ISetCheckoutDropoffPointId {
    payload?: string;
    type: 'SET_CHECKOUT_DROPOFF_POINT_ID';
}

interface ISetCheckoutCollectionDate {
    payload?: string;
    type: 'SET_CHECKOUT_COLLECTION_DATE';
}

interface ISetCheckoutCollectionTime {
    payload?: IInterval;
    type: 'SET_CHECKOUT_COLLECTION_TIME';
}

interface ISetCheckoutPriceDetails {
    payload: IPrice;
    type: 'SET_CHECKOUT_PRICE_DETAILS';
}

interface ISetCheckoutInsuranceType {
    payload?: UpsellInsuranceType;
    type: 'SET_CHECKOUT_INSURANCE_TYPE';
}

interface ISetCheckoutAdultSignatureValue {
    payload?: boolean;
    type: 'SET_CHECKOUT_ADULT_SIGNATURE_VALUE';
}

interface ISetCheckoutProofOfDelivery {
    payload?: boolean;
    type: 'SET_CHECKOUT_PROOF_OF_DELIVERY';
}

interface ISetCheckoutPrintInStore {
    payload?: boolean;
    type: 'SET_CHECKOUT_PRINT_IN_STORE';
}
export interface ISetCheckoutAdditionalHandlingValue {
    payload?: boolean;
    type: 'SET_CHECKOUT_ADDITIONAL_HANDLING_VALUE';
}

export interface ISetCashOnDeliveryAction {
    payload?: CashOnDelivery;
    type: 'SET_CHECKOUT_CASH_ON_DELIVERY';
}

interface ISetCheckoutBusy {
    payload: boolean;
    type: 'SET_CHECKOUT_BUSY';
}

interface ISetCheckoutServiceAction {
    payload: Service;
    type: 'SET_CHECKOUT_SERVICE';
}

interface ISetCheckoutContent {
    payload: Content;
    type: 'SET_CHECKOUT_CONTENT';
}

interface IResetCheckoutServiceAction {
    type: 'RESET_CHECKOUT_SERVICE';
}

interface ISetCheckoutSelectedShipment {
    payload: Shipment;
    type: 'SET_CHECKOUT_SELECTED_SHIPMENT';
}

interface IResetCheckoutAction {
    type: 'RESET_CHECKOUT';
}

interface ISetCheckoutVoucherAction {
    type: 'SET_CHECKOUT_VOUCHER';
    payload: Voucher;
}

interface IDeleteVoucherAction {
    type: 'DELETE_VOUCHER';
    payload: null;
}

interface ISetAddressBookCheckedAction {
    type: 'SET_ADDRESS_BOOK_CHECKED';
    payload: boolean;
}
interface ISetInvoiceInfoSavedAction {
    type: 'SET_INVOICE_INFO_SAVED';
    payload: boolean;
}

export type CheckoutAction =
    | ISetParcelsCheckoutAction
    | ISetCheckoutAddressDetailsAction
    | ISetCheckoutAddressCountryAction
    | ISetCheckoutAddressPostalCodeAction
    | ISetCheckoutWarehouseAddressAction
    | ISetCheckoutSelectedWarehouseAction
    | ISetCheckoutAddressAction
    | ISetCheckoutAdditionalDataAction
    | ISetCheckoutServiceAction
    | IResetCheckoutServiceAction
    | IResetCheckoutAction
    | ISetCheckoutDropoffPointId
    | ISetCheckoutCollectionDate
    | ISetCheckoutCollectionTime
    | ISetCheckoutPriceDetails
    | ISetCheckoutInsuranceType
    | IDeleteVoucherAction
    | ISetCheckoutContent
    | ISetCheckoutSelectedShipment
    | ISetCheckoutVoucherAction
    | ISetAddressBookCheckedAction
    | ISetInvoiceInfoSavedAction
    | ISetCheckoutBusy
    | ISetCheckoutAdultSignatureValue
    | ISetCheckoutProofOfDelivery
    | ISetCheckoutPrintInStore
    | ISetCheckoutAdditionalHandlingValue
    | SetCheckoutThirdPartyPreferencesAction
    | ISetCashOnDeliveryAction;

export const setCheckoutParcels = (parcels: Parcel<Partial<IParcel>>[]): ISetParcelsCheckoutAction => ({
    payload: parcels,
    type: SET_CHECKOUT_PARCELS,
});

export const setCheckoutAddressDetails = (
    addressDetails: CheckoutAddressDetails,
    origin: CheckoutAddressOrigin,
): ISetCheckoutAddressDetailsAction => ({
    payload: { addressDetails, origin },
    type: SET_CHECKOUT_ADDRESS_DETAILS,
});

export const setCheckoutCountry = (
    postalZone: IPostalZone,
    origin: CheckoutAddressOrigin,
): ISetCheckoutAddressCountryAction => ({
    payload: { postalZone, origin },
    type: SET_CHECKOUT_ADDRESS_COUNTRY,
});

export const setCheckoutPostalCode = (
    postalCode: IPostalCode,
    origin: CheckoutAddressOrigin,
): ISetCheckoutAddressPostalCodeAction => ({
    payload: { postalCode, origin },
    type: SET_CHECKOUT_ADDRESS_POSTALCODE,
});

export const setCheckoutWarehouseAddress = (
    warehouse: IWarehouseAddress | undefined,
    locale: string,
): ISetCheckoutWarehouseAddressAction => ({
    payload: { warehouse, locale },
    type: SET_CHECKOUT_WAREHOUSE_ADDRESS,
});

export const setCheckoutSelectedWarehouse = (warehouseId: string | undefined): ISetCheckoutSelectedWarehouseAction => ({
    payload: warehouseId,
    type: SET_CHECKOUT_SELECTED_WAREHOUSE,
});

export const setCheckoutAddress = (address: Address, origin: CheckoutAddressOrigin): ISetCheckoutAddressAction => ({
    payload: {
        address,
        origin,
    },
    type: SET_CHECKOUT_ADDRESS,
});

export const setCheckoutAdditionalData = (
    additionalData: Record<string, unknown>,
): ISetCheckoutAdditionalDataAction => ({
    payload: { additionalData },
    type: SET_CHECKOUT_ADDITIONAL_DATA,
});

export const setCheckoutThirdPartyPreferences = (
    thirdPartyPreferences: ShipmentThirdPartyPreferencesProps,
): SetCheckoutThirdPartyPreferencesAction => {
    return {
        payload: {
            thirdPartyPreferences,
        },
        type: SET_CHECKOUT_THIRD_PARTY_PREFERENCES,
    };
};

export const setCheckoutDropoffPointId = (id?: string): ISetCheckoutDropoffPointId => ({
    payload: id,
    type: SET_CHECKOUT_DROPOFF_POINT_ID,
});

export const setCheckoutCollectionDate = (date?: string): ISetCheckoutCollectionDate => ({
    payload: date,
    type: SET_CHECKOUT_COLLECTION_DATE,
});

export const setCheckoutCollectionTime = (time?: IInterval): ISetCheckoutCollectionTime => ({
    payload: time && new Interval(time?.start, time?.end),
    type: SET_CHECKOUT_COLLECTION_TIME,
});

export const setCheckoutPriceDetails = (priceDetails: IPrice): ISetCheckoutPriceDetails => ({
    payload: priceDetails,
    type: SET_CHECKOUT_PRICE_DETAILS,
});

export const setCheckoutInsuranceType = (insuranceType?: UpsellInsuranceType): ISetCheckoutInsuranceType => ({
    payload: insuranceType,
    type: SET_CHECKOUT_INSURANCE_TYPE,
});

export const setCheckoutAdultSignature = (adultSignature?: boolean): ISetCheckoutAdultSignatureValue => ({
    payload: adultSignature,
    type: SET_CHECKOUT_ADULT_SIGNATURE_VALUE,
});

export const setCheckoutProofOfDelivery = (proofOfDelivery?: boolean): ISetCheckoutProofOfDelivery => ({
    payload: proofOfDelivery,
    type: SET_CHECKOUT_PROOF_OF_DELIVERY,
});

export const setCheckoutPrintInStore = (proofOfDelivery?: boolean): ISetCheckoutPrintInStore => ({
    payload: proofOfDelivery,
    type: SET_CHECKOUT_PRINT_IN_STORE,
});

export const setCheckoutAdditionalHandling = (additionalHandling?: boolean): ISetCheckoutAdditionalHandlingValue => ({
    payload: additionalHandling,
    type: SET_CHECKOUT_ADDITIONAL_HANDLING_VALUE,
});

export const setCheckoutBusy = (isBusy: boolean): ISetCheckoutBusy => ({
    payload: isBusy,
    type: SET_CHECKOUT_BUSY,
});

export const deleteVoucherAction = (): IDeleteVoucherAction => ({
    payload: null,
    type: DELETE_VOUCHER,
});

export const setCheckoutService = (service: Service): ISetCheckoutServiceAction => ({
    payload: service,
    type: SET_CHECKOUT_SERVICE,
});

export const setCheckoutContent = (data: Content): ISetCheckoutContent => ({
    payload: data,
    type: SET_CHECKOUT_CONTENT,
});

const resetCheckoutService = (): IResetCheckoutServiceAction => ({
    type: RESET_CHECKOUT_SERVICE,
});

export const setCheckoutSelectedShipment = (shipment: Shipment): ISetCheckoutSelectedShipment => ({
    payload: shipment,
    type: SET_CHECKOUT_SELECTED_SHIPMENT,
});

export const resetCheckoutState = (): IResetCheckoutAction => ({
    type: RESET_CHECKOUT,
});

export const resetService =
    (): ThunkResult<Promise<void>> =>
    async (dispatch: AppDispatch): Promise<void> => {
        batch(() => {
            dispatch(resetCheckoutService());
            dispatch(setCheckoutDropoffPointId(undefined));
            dispatch(setCheckoutCollectionDate(undefined));
            dispatch(setCheckoutCollectionTime(undefined));
            dispatch(setCheckoutInsuranceType(undefined));
            dispatch(setCheckoutAdultSignature(undefined));
            dispatch(setCheckoutProofOfDelivery(undefined));
            dispatch(setCheckoutPrintInStore(undefined));
            dispatch(setCheckoutAdditionalHandling(undefined));
            dispatch(resetCustomsForm());
        });
    };

export const resetCheckout =
    (): ThunkResult<Promise<void>> =>
    async (dispatch: AppDispatch): Promise<void> => {
        batch(() => {
            dispatch(resetCheckoutState());
            dispatch(resetCustomsForm());
        });
    };

export const setCheckoutVoucherAction = (data: Voucher): ISetCheckoutVoucherAction => ({
    payload: data,
    type: SET_CHECKOUT_VOUCHER,
});

export const setAddressBookCheckedAction = (checked: boolean): ISetAddressBookCheckedAction => ({
    payload: checked,
    type: SET_ADDRESS_BOOK_CHECKED,
});

export const setInvoiceInfoSavedAction = (data: boolean): ISetInvoiceInfoSavedAction => ({
    payload: data,
    type: SET_INVOICE_INFO_SAVED,
});

export const setCashOnDeliveryAction = (data: CashOnDelivery): ISetCashOnDeliveryAction => ({
    payload: data,
    type: SET_CHECKOUT_CASH_ON_DELIVERY,
});
