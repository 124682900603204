import { Trans, useTranslation } from '@packlink/translation-provider';
import { ButtonVariant } from '@shipengine/giger';
import { EmptyState, IEmptyStateChildrenProps } from '@components/EmptyState/EmptyState';
import { NewUserEmptyStateOption } from './NewUserEmptyStateOption';
import { GTMSectionIds } from '@constants/gtm';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { ITenantConfig } from '@types';
import { getNewUserStateSectionStyles } from './EmptyStateStyles';

export const NewUserEmptyState = (props: IEmptyStateChildrenProps): JSX.Element => {
    const { onNewShipment, onConnectShop } = props;
    const { t } = useTranslation();
    const { canConnectToEshop } = useTenantConfig<ITenantConfig>();
    const {
        cdn: { url: cdnUrl },
    } = config;

    const integrationsImage = `${cdnUrl}/pro/statics/images/small-integrations.png`;
    const proImage = `${cdnUrl}/pro/statics/images/small-pro.png`;

    const newShipmentButtonText = t('shipment-list.new-user-empty-state.new-shipment');
    const connectYourStoreButtonTet = t('header.actions.connect-shop');

    return (
        <EmptyState
            title={t('shipment-list.new-user-empty-state.title')}
            subtitle={<Trans components={{ mark: <mark /> }}>shipment-list.new-user-empty-state.subtitle</Trans>}
        >
            <section css={getNewUserStateSectionStyles}>
                <NewUserEmptyStateOption
                    image={proImage}
                    title={t('shipment-list.new-user-empty-state.option-shipment')}
                    stepTranslationKey="new-user-empty-state.option-shipment.list-item"
                    numSteps={3}
                    buttonText={newShipmentButtonText}
                    buttonVariant={ButtonVariant.OUTLINED}
                    onAction={() =>
                        onNewShipment(newShipmentButtonText, GTMSectionIds.NEW_USER_EMPTY_STATE_SECOND_BUTTON)
                    }
                />

                {canConnectToEshop && (
                    <NewUserEmptyStateOption
                        image={integrationsImage}
                        title={t('shipment-list.new-user-empty-state.option-integrations')}
                        stepTranslationKey="new-user-empty-state.option-integrations.list-item"
                        numSteps={3}
                        buttonText={connectYourStoreButtonTet}
                        buttonVariant={ButtonVariant.FILLED}
                        onAction={() =>
                            onConnectShop(connectYourStoreButtonTet, GTMSectionIds.NEW_USER_EMPTY_STATE_FIRST_BUTTON)
                        }
                    />
                )}
            </section>
        </EmptyState>
    );
};
