import React from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { Button, ButtonSize, ButtonVariant, InlineNotification, NotificationType } from '@shipengine/giger';
import { useFormikContext } from 'formik';
import { IRecipientForm } from './types';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { getPickupNotificationStyles } from './ShipmentPanelRecipientStyles';

interface IPickUpSectionProps {
    onShowPickUp: () => void;
}

export const PickUpSection: React.FC<IPickUpSectionProps> = (props: IPickUpSectionProps): JSX.Element => {
    const { onShowPickUp } = props;
    const { values, errors } = useFormikContext<IRecipientForm>();
    const { t } = useTranslation();

    const formErrorsLength = Object.keys(errors).length;
    const allowPickUp = (formErrorsLength === 1 && errors.dropoffPointId) || !formErrorsLength;

    return (
        <SidePanelContentSection title={t('recipient-panel.pick-up.section-title')}>
            <Button
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                disabled={!allowPickUp}
                onClick={onShowPickUp}
                name="dropoffPointId"
            >
                {t(
                    values.dropoffPointId
                        ? 'recipient-panel.pick-up.modify-pick-up'
                        : 'recipient-panel.pick-up.select-pick-up',
                )}
            </Button>

            {!values.dropoffPointId && allowPickUp && (
                <div css={getPickupNotificationStyles}>
                    <InlineNotification type={NotificationType.INFO}>
                        {t('recipient-panel.pick-up.missing-message')}
                    </InlineNotification>
                </div>
            )}
        </SidePanelContentSection>
    );
};
