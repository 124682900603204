import { useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { Order } from '@packlink/packlink-sdk';
import { Spinner } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { isInvoiceAddressValid } from '@store/selectors/client';

import { useRequiresPhoneNumberVerification } from '@common/hooks/useRequiresPhoneNumberVerification';
import { BulkFlows } from './typings';
import { ShipmentPanelBulkActions } from './flows/ShipmentPanelBulkActions';
import { ShipmentPanelBulkPayment } from './flows/ShipmentPanelBulkPayment';
import { ShipmentPanelBulkBilling } from './flows/ShipmentPanelBulkBilling';
import { ShipmentPanelBulkServices } from './flows/ShipmentPanelBulkServices';
import { ShipmentPanelBulkInsurance } from './flows/ShipmentPanelBulkInsurance';
import { ShipmentPanelBulkContent } from './flows/ShipmentPanelBulkContent';
import { ShipmentPanelBulkParcels } from './flows/ShipmentPanelBulkParcels';
import { getShipmentPanelBulkStyles, getSpinnerStyles } from './ShipmentPanelBulkStyles';
import { ShipmentPanelBulkCollection } from './flows/ShipmentPanelBulkCollection';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { getSelectedShipments } from '@store/selectors/bulk';
import { useBillingForm } from '@common/hooks/useBillingForm';

export interface IShipmentPanelBulkContext {
    flow: BulkFlows;
}

export interface IShipmentPanelBulkProps {
    onPaymentSuccess: (order: Order) => void;
}

export const ShipmentPanelBulk = ({ onPaymentSuccess }: IShipmentPanelBulkProps): JSX.Element => {
    const { closePanel, panelState, updatePanelContext } = useShipmentPanel();
    const flow = panelState.context?.flow || BulkFlows.ACTIONS;
    const { t } = useTranslation();
    const selectedShipments = useSelector(getSelectedShipments);

    const onClosePanel = (): void => {
        closePanel();
    };

    const activateFlow = (flow: BulkFlows): void => {
        updatePanelContext({ flow });
    };

    const handleActivateActionsFlow = (): void => {
        const numberSelectedShipments = selectedShipments.length;
        if (numberSelectedShipments > 0) {
            activateFlow(BulkFlows.ACTIONS);
        } else {
            closePanel();
        }
    };

    const headerSettings = {
        [BulkFlows.ACTIONS]: {
            icon: IconNames.CLOSE,
            title: 'bulk-panel.header.title',
            callback: onClosePanel,
        },
        [BulkFlows.PAYMENT]: {
            icon: IconNames.ARROW_LEFT,
            title: 'bulk-panel.header.payment-title',
            callback: handleActivateActionsFlow,
        },
        [BulkFlows.SERVICES]: {
            icon: IconNames.ARROW_LEFT,
            title: 'bulk-panel.header.services-title',
            callback: handleActivateActionsFlow,
        },
        [BulkFlows.INSURANCE]: {
            icon: IconNames.ARROW_LEFT,
            title: 'bulk-panel.header.buy-insurance',
            callback: handleActivateActionsFlow,
        },
        [BulkFlows.INSURANCE_FROM_BULK_PAYMENT]: {
            icon: IconNames.ARROW_LEFT,
            title: 'bulk-panel.header.buy-insurance',
            callback: (): void => activateFlow(BulkFlows.PAYMENT),
        },
        [BulkFlows.CONTENT]: {
            icon: IconNames.ARROW_LEFT,
            title: 'bulk-panel.header.edit-content',
            callback: handleActivateActionsFlow,
        },
        [BulkFlows.PARCELS]: {
            icon: IconNames.ARROW_LEFT,
            title: 'bulk-panel.header.edit-parcels',
            callback: handleActivateActionsFlow,
        },
        [BulkFlows.COLLECTION_DATE]: {
            icon: IconNames.ARROW_LEFT,
            title: 'bulk-edit.actions.edit-collection-date',
            callback: handleActivateActionsFlow,
        },
    };

    return (
        <div css={getShipmentPanelBulkStyles}>
            <SidePanelHeader onAction={headerSettings[flow].callback} title={t(headerSettings[flow].title)} />
            {flow === BulkFlows.ACTIONS && <ShipmentPanelBulkActions onSetFlow={activateFlow} />}
            {flow === BulkFlows.PAYMENT && (
                <PaymentFlow
                    handleActivateActionsFlow={handleActivateActionsFlow}
                    onPaymentSuccess={onPaymentSuccess}
                    activateFlow={activateFlow}
                />
            )}

            {flow === BulkFlows.SERVICES && <ShipmentPanelBulkServices onGoBack={handleActivateActionsFlow} />}

            {flow === BulkFlows.INSURANCE && <ShipmentPanelBulkInsurance onGoBack={handleActivateActionsFlow} />}

            {flow === BulkFlows.INSURANCE_FROM_BULK_PAYMENT && (
                <ShipmentPanelBulkInsurance isPaymentOrigin onGoBack={(): void => activateFlow(BulkFlows.PAYMENT)} />
            )}

            {flow === BulkFlows.CONTENT && <ShipmentPanelBulkContent onGoBack={handleActivateActionsFlow} />}

            {flow === BulkFlows.PARCELS && <ShipmentPanelBulkParcels onGoBack={handleActivateActionsFlow} />}

            {flow === BulkFlows.COLLECTION_DATE && <ShipmentPanelBulkCollection onGoBack={handleActivateActionsFlow} />}
        </div>
    );
};

function PaymentFlow({
    handleActivateActionsFlow,
    onPaymentSuccess,
    activateFlow,
}: {
    handleActivateActionsFlow: VoidFunction;
    onPaymentSuccess: IShipmentPanelBulkProps['onPaymentSuccess'];
    activateFlow: (flow: BulkFlows) => void;
}) {
    const hasBillingInfo = useSelector(isInvoiceAddressValid);
    const { clientRequiresPhoneNumberVerification, isClientRequiresPhoneNumberVerificationLoading } =
        useRequiresPhoneNumberVerification();
    const {
        billingFormRef,
        isPhoneNumberVerificationOpen,
        submitBillingForm,
        onBillingFormSuccess,
        closePhoneNumberVerification,
    } = useBillingForm();

    const showBillingForm = isPhoneNumberVerificationOpen || !hasBillingInfo || clientRequiresPhoneNumberVerification;

    if (isClientRequiresPhoneNumberVerificationLoading) {
        return <Spinner css={getSpinnerStyles} />;
    }

    return (
        <>
            {showBillingForm ? (
                <ShipmentPanelBulkBilling
                    onGoBack={handleActivateActionsFlow}
                    billingFormRef={billingFormRef}
                    isPhoneNumberVerificationOpen={isPhoneNumberVerificationOpen}
                    submitBillingForm={submitBillingForm}
                    onBillingFormSuccess={onBillingFormSuccess}
                    closePhoneNumberVerification={closePhoneNumberVerification}
                />
            ) : (
                <ShipmentPanelBulkPayment
                    onGoBack={handleActivateActionsFlow}
                    onPaymentSuccess={onPaymentSuccess}
                    onSetFlow={activateFlow}
                />
            )}
        </>
    );
}
