import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { Icon, IconSize, Tag, TagColor, TagVariant, Typography } from '@shipengine/giger';

import { ShipmentUtils } from '@utils/ShipmentUtils';

import { getNotificationTagStyles, getShipmentRowCellStyles } from './ShipmentRowStyles';
import { getStatusIconStyles, getStatusStyles } from './ShipmentRowStatusCellStyles';

export interface IShipmentRowStatusCell {
    hasNotification: boolean;
    shipmentState: string;
    canBePurchased: boolean;
    isDraft: boolean;
    onCreate: () => void;
    onComplete: () => void;
    onDetails: () => void;
    clickEnabled: boolean;
}

export const ShipmentRowStatusCell = ({
    hasNotification,
    canBePurchased,
    shipmentState,
    isDraft,
    clickEnabled,
    onCreate,
    onComplete,
    onDetails,
}: IShipmentRowStatusCell): JSX.Element => {
    const { t } = useTranslation();
    const iconName = ShipmentUtils.getIconByState(shipmentState) as IconNames;
    const packageState = t(ShipmentUtils.getFrontendStatus(shipmentState));
    const key = shipmentState.toLowerCase().replace(/_/gi, '-');
    const actionOnClick = isDraft ? (canBePurchased ? onCreate : onComplete) : onDetails;

    return (
        <div css={getShipmentRowCellStyles()} data-id="shipment-row-cell">
            <Typography
                variant="body2"
                component="p"
                css={getStatusStyles(key, clickEnabled)}
                onClick={clickEnabled ? actionOnClick : undefined}
                role="link"
            >
                <Icon css={getStatusIconStyles} name={iconName} size={IconSize.SIZE_SMALL} />
                {packageState}
            </Typography>

            {hasNotification && (
                <Tag css={getNotificationTagStyles} variant={TagVariant.OUTLINED} color={TagColor.SUCCESS}>
                    {t('shipment-row.notification.new')}
                </Tag>
            )}
        </div>
    );
};
