import { Typography, WithCommonProps } from '@shipengine/giger';
import { Currency } from '@packlink/packlink-sdk';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { first } from 'lodash';
import { useContext } from 'react';
import { FormWithShipmentContext } from '../CommonFields/FormWithShipment/FormWithShipmentData';
import { getShipmentStatusItem } from './ShipmentSummaryStyles';

type ShipmentSummaryProps = WithCommonProps<unknown>;

export function ShipmentSummary(props: ShipmentSummaryProps): JSX.Element {
    const { t } = useTranslation();
    const {
        shipment: { service, trackings = [], upsales, currency },
    } = useContext(FormWithShipmentContext);
    const trackingCode = first(trackings);
    const compensationCoverage = upsales?.insurance?.amount;
    return (
        <ul {...props}>
            <Typography css={getShipmentStatusItem} component="li">
                <Trans parent="strong" i18nKey="support-center.shipment-summary.compensation-coverage" />{' '}
                {compensationCoverage
                    ? Currency.format(compensationCoverage, currency)
                    : t('support-center.shipment-summary.no-compensation-coverage')}
            </Typography>
            <Typography css={getShipmentStatusItem} component="li">
                <Trans parent="strong" i18nKey="support-center.shipment-summary.carrier-name" /> {service?.carrierName}
            </Typography>
            <Typography css={getShipmentStatusItem} component="li">
                <Trans parent="strong" i18nKey="support-center.shipment-summary.carrier-tracking-number" />{' '}
                {trackingCode}
            </Typography>
        </ul>
    );
}
