import React, { useCallback, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';

import { useMedia } from '@packlink/utils';
import { Dialog, DialogContent, DialogHeader } from '@shipengine/giger';
import { Address, DropOff, IAddress, IDropOff } from '@packlink/packlink-sdk';
import { Pudo } from '@packlink/pudo';
import { AppDispatch } from '@store';
import { AmplitudeEvents } from '@packlink/metrics';

import { setCheckoutDropoffPointId } from '@store/actions/checkout';
import { getCheckoutDropoffPointId } from '@store/selectors/checkout';

import { getPudoDialogStyles, getPudoDialogContentStyles, getPudoDialogHeaderStyles } from './PudoDialogStyles';
import { AmplitudeProperties } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';
import { useTranslation } from '@packlink/translation-provider';

interface IPudoDialogProps {
    serviceId: number | string;
    address: Address<IAddress>;
    onDialogClose: () => void;
    onPudoSelection: (dropoffPointId: DropOff<IDropOff>['id']) => void;
}

export const PudoDialog: React.FC<IPudoDialogProps> = ({
    serviceId,
    address,
    onDialogClose,
    onPudoSelection,
}: IPudoDialogProps): JSX.Element => {
    const dropoffPointId = useSelector(getCheckoutDropoffPointId);
    const [selectedId] = useState<DropOff<IDropOff>['id'] | undefined>(dropoffPointId);
    const dispatch = useDispatch<AppDispatch>();
    const theme = useTheme();
    const { sendAmplitudeEvent } = useAmplitude();
    const { t } = useTranslation();

    const onConfirm = useCallback(
        (selectedPudo: DropOff): void => {
            dispatch(setCheckoutDropoffPointId(selectedPudo.id));
            sendAmplitudeEvent(AmplitudeEvents.PUDO_CONFIRMATION, {
                [AmplitudeProperties.SOURCE]: selectedPudo.address,
            });
            onPudoSelection(selectedPudo.id as string);
        },
        [dispatch, sendAmplitudeEvent, onPudoSelection],
    );

    const [filtered, setFiltered] = useState(false);
    const [interacted, setInteracted] = useState(false);

    const onFilter = !filtered
        ? (): void => {
              setFiltered(true);
              sendAmplitudeEvent(AmplitudeEvents.PUDO_FILTERING);
          }
        : undefined;

    const onMapIdle = !interacted
        ? (): void => {
              setInteracted(true);
              sendAmplitudeEvent(AmplitudeEvents.PUDO_INTERACTION);
          }
        : undefined;

    const isExpanded = useMedia(theme.mediaQuery('tablet'));
    const isDesktop = useMedia(theme.mediaQuery('desktop'));

    return (
        <Dialog isOpen={true} onRequestClose={onDialogClose} css={getPudoDialogStyles} fullScreen>
            {!isDesktop && (
                <DialogHeader
                    showClose
                    closeAriaLabel={t('dialog.action.close')}
                    closePosition="left"
                    onClose={onDialogClose}
                    css={getPudoDialogHeaderStyles}
                />
            )}
            <DialogContent css={getPudoDialogContentStyles(isExpanded)}>
                <Pudo
                    selectedId={selectedId}
                    serviceId={serviceId.toString()}
                    address={address}
                    onConfirm={onConfirm}
                    onFilter={onFilter}
                    onMapIdle={onMapIdle}
                />
            </DialogContent>
        </Dialog>
    );
};
