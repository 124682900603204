import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useDispatch } from 'react-redux';
import { IWarehouseAddress } from '@packlink/packlink-sdk';
import { IDropdownOption, SelectAutocomplete } from '@shipengine/giger';
import { AppDispatch } from '@store';
import { useWarehouses } from '@common/hooks/useWarehouses';

type WarehouseOption = IDropdownOption<IWarehouseAddress>;
export interface IWarehouseSelectorProps {
    selectedWarehouseId?: string;
    onChange: (warehouse: IWarehouseAddress) => void;
}

export const WarehouseSelector = (props: IWarehouseSelectorProps): JSX.Element => {
    const { selectedWarehouseId, onChange } = props;
    const { warehouses, defaultWarehouse } = useWarehouses();

    const warehouseOptions = useMemo(
        () => warehouses?.map((w): WarehouseOption => ({ ...w, label: w.alias, value: w.id })),
        [warehouses],
    );
    const defaultWarehouseOption = useMemo(
        () =>
            defaultWarehouse
                ? { ...defaultWarehouse, value: defaultWarehouse?.id, label: defaultWarehouse?.alias }
                : undefined,
        [defaultWarehouse],
    );
    const initialWarehouse = useMemo(
        () => warehouseOptions?.find((w): boolean => w.id === selectedWarehouseId) || defaultWarehouseOption,
        [defaultWarehouseOption, selectedWarehouseId, warehouseOptions],
    );

    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseOption | undefined>();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const handleChange = useCallback(
        (_name: string, option: WarehouseOption | null): void => {
            if (!option) return;
            const originalOption = warehouses?.find((w): boolean => w.id === option.id);
            setSelectedWarehouse(option);
            onChange(originalOption as IWarehouseAddress);
        },
        [onChange, warehouses],
    );

    useEffect(() => {
        initialWarehouse && setSelectedWarehouse(initialWarehouse);
    }, [dispatch, initialWarehouse]);

    if (!warehouses || warehouses.length === 0) {
        return <></>;
    }

    return (
        <SelectAutocomplete
            name="warehouse"
            options={warehouseOptions || []}
            label={t('form.label.my-addresses')}
            onChange={handleChange}
            value={selectedWarehouse}
            noResultsLabel={t('autocomplete.messages.no-results-found')}
        />
    );
};
