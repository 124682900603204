import React from 'react';
import useMountedState from 'react-use/lib/useMountedState';
import useDebounce from 'react-use/lib/useDebounce';

export function useDebouncedValue<T>(value: T, ms: number) {
    const isMounted = useMountedState();
    const [debouncedValue, setDebouncedValue] = React.useState(value);
    useDebounce(() => isMounted() && setDebouncedValue(value), ms, [value]);

    return debouncedValue;
}
