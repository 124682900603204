import { Typography } from '@shipengine/giger';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { TermsAgreementCheckbox } from './TermsAgreementCheckbox';
import { getTermsStyles, getTermsTitleStyles } from './TermsAgreementStyles';
import { useAmplitude } from '@hooks/useAmplitude';

type TermsAgreementProps = {
    checked?: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    title?: string;
    formOrigin: string;
};

export const TermsAgreement = ({
    checked,
    handleChange,
    error,
    title,
    formOrigin,
}: TermsAgreementProps): JSX.Element => {
    const { sendAmplitudeClickEvent } = useAmplitude();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const {
            currentTarget: { checked },
        } = event;
        handleChange(event);
        const eventStatus = checked
            ? AmplitudeEvents.CHECK_CARRIERS_POLICIES
            : AmplitudeEvents.UNCHECK_CARRIERS_POLICIES;

        sendAmplitudeClickEvent(eventStatus, { [AmplitudeProperties.FROM_STEP]: formOrigin });
    };
    return (
        <div css={getTermsStyles}>
            {title && (
                <Typography css={getTermsTitleStyles} variant="heading5">
                    {title}
                </Typography>
            )}
            <TermsAgreementCheckbox checked={checked} handleChange={onChange} error={error} />
        </div>
    );
};
