import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getFormStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    });

export const getFormFieldStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(),
    });
