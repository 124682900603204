import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import Packlink from '@sdk';
import { AppDispatch } from '@store';
import { getCheckoutShipment } from '@store/selectors/checkout';
import { setCheckoutBusy, setCheckoutPriceDetails } from '@store/actions/checkout';

export function useRefreshPrice() {
    const shipment = useSelector(getCheckoutShipment);
    const dispatch = useDispatch<AppDispatch>();

    // Currently we can't get rid of the Redux data (angular, using price from multiple places, updating from multiple places).
    // So the easiest way to sync with Redux is using a mutation. Ideally this should be a useQuery at top level of the checkout.
    const { mutate: refreshPrice } = useMutation({
        mutationFn: () => Packlink.pro.shipments.price.get([shipment]),
        onMutate: () => {
            dispatch(setCheckoutBusy(true));
        },
        onSuccess: (price) => {
            dispatch(setCheckoutPriceDetails(price.shipmentsPriceDetails[0]));
        },
        onSettled: () => {
            dispatch(setCheckoutBusy(false));
        },
    });

    return useMemo(
        () => ({
            // "hack" to run refreshPrice in the next cycle and have the shipment from Redux updated.
            refreshPrice: () => setTimeout(() => refreshPrice(), 0),
        }),
        [refreshPrice],
    );
}
