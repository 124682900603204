import { css, SerializedStyles } from '@emotion/react';

import { IconNames, Theme } from '@shipengine/giger-theme';

export const getDialogContentStyles = (): SerializedStyles =>
    css({
        minHeight: 150,
        textAlign: 'center',
    });

export const getDialogIconStyles =
    (icon?: IconNames) =>
    (theme: Theme): SerializedStyles => {
        let iconColor: string;

        if (icon === IconNames.INFO_FILLED) {
            iconColor = theme.palette.error.main;
        } else if (icon === IconNames.CHECK_FILLED) {
            iconColor = theme.palette.success.main;
        } else {
            iconColor = theme.palette.primary.main;
        }

        return css({
            display: 'flex',
            color: iconColor,
            justifyContent: 'center',
            marginBottom: theme.spacing(3),
        });
    };
