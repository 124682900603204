import { TenantUtils } from './tenant';

const getSeparatorChar = (path: string): string => (path.indexOf('?') === -1 ? '?' : '&');

const getAuthUrl = (config: IConfig, url: string, qs: URLSearchParams): string =>
    `${config.auth.uri}${url}${getSeparatorChar(url)}${qs.toString()}`;

export class BrowserContext {
    static getLoginPath(config: IConfig): string {
        try {
            const loginPath = TenantUtils.getLoginPath(config);
            return `${loginPath}&return_url=${encodeURIComponent(window.location.href)}`;
        } catch {
            return '/login';
        }
    }

    static getLogoutPath(config: IConfig, locale: string): string {
        try {
            const qs = new URLSearchParams();
            qs.append('tenant_id', TenantUtils.getTenantName());
            return getAuthUrl(config, config.logoutPath, qs).replace('{{LANGUAGE}}', `${locale}`);
        } catch {
            return '/logout';
        }
    }

    static getQueryParams(): URLSearchParams {
        return new URLSearchParams(document.location.search.substring(1));
    }
}
