import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTrackingListStyles = (): SerializedStyles => {
    return css({
        margin: 0,
        padding: 0,
        listStyle: 'none',
    });
};

export const getTrackingNumberStyles = (theme: Theme): SerializedStyles => {
    return css({
        margin: `0 0 ${theme.spacing(0.5)}px`,
    });
};
