import { Typography, WithCommonProps } from '@shipengine/giger';
import { AnySupportTicket } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { SupportTicketSummaryMessageData } from './SupportTicketSummaryMessageData';
import { getSupportTicketSummaryMessageAdditionalDataStyles } from './SupportTicketSummaryMessageStyles';

type SupportTicketSummaryMessageProps = WithCommonProps<{
    supportTicket: AnySupportTicket;
}>;

export function SupportTicketSummaryMessage({
    supportTicket,
    ...props
}: SupportTicketSummaryMessageProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <div {...props}>
            <Typography variant="body2">{t('support-center.ticket-chat.ticket-summary-message')}</Typography>
            <SupportTicketSummaryMessageData
                data-id="support-ticket-additional-data"
                css={getSupportTicketSummaryMessageAdditionalDataStyles}
                supportTicket={supportTicket}
            />
        </div>
    );
}
