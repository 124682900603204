import { Trans } from '@packlink/translation-provider';
import { Button, ButtonVariant, Typography, WithCommonProps } from '@shipengine/giger';
import { AmplitudeEvents } from '@constants/amplitude';
import { getButtonStyles } from './CancelLinkStyles';
import { useAmplitude } from '@hooks/useAmplitude';

interface IClientPlanDetails {
    onCancelFlow: () => void;
}

export function CancelLink({ onCancelFlow, ...rest }: WithCommonProps<IClientPlanDetails>): JSX.Element {
    const { sendAmplitudeClickEvent } = useAmplitude();

    const openCancelFlow = () => {
        sendAmplitudeClickEvent(AmplitudeEvents.SUBSCRIPTION_CANCEL);
        onCancelFlow();
    };

    return (
        <Typography variant="body2" component="p" {...rest}>
            <Trans
                i18nKey="subscriptions.plan.cancel"
                components={[
                    <Button
                        variant={ButtonVariant.TEXT}
                        key="button"
                        data-id="subs-cancel-btn"
                        css={getButtonStyles}
                        onClick={openCancelFlow}
                    />,
                ]}
            />
        </Typography>
    );
}
