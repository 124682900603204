import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';

import { Dialog } from '@shipengine/giger';
import { Spinner } from '@components/Spinner/Spinner';
import { AmplitudeEvents } from '@constants/amplitude';
import { setActiveIntegrationImport } from '@Integrations/store/actions/integrations';
import { ActiveIntegrationImport } from '@Integrations/types/integration';
import { ApiClientError, ImportSource, OnboardingProvider } from '@packlink/packlink-sdk';
import { APP_ROUTE } from '@pages/router/routes';
import packlink from '@sdk';
import { AppDispatch } from '@store';
import { logSdkError } from '@utils/logger';
import { useAmplitude } from '@hooks/useAmplitude';
import { GET_ALL_CONFIGURATIONS_QUERY_KEY } from '@common/hooks/useIntegrations';

type ActivateCompleteFlowProps = {
    afterActivate?: () => Promise<void>;
    onError: () => void;
    params: Record<string, unknown>;
    provider: OnboardingProvider;
    integration: ActiveIntegrationImport;
};

export const ActivateCompleteFlow: React.FunctionComponent<ActivateCompleteFlowProps> = ({
    afterActivate,
    onError,
    params,
    provider,
    integration,
}: ActivateCompleteFlowProps): JSX.Element => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { sendAmplitudeEvent } = useAmplitude();
    const goToShipments = useCallback((): void => {
        sendAmplitudeEvent(AmplitudeEvents.INTEGRATION_AUTO_IMPORT_MODAL);
        dispatch(setActiveIntegrationImport({ ...integration, source: ImportSource.NEW_INTEGRATION }));
        navigate(APP_ROUTE.SHIPMENTS.INBOX_ALL);
    }, [dispatch, integration, navigate, sendAmplitudeEvent]);

    useEffect((): void => {
        packlink.v1.onboarding
            .activate(provider, params)
            .then(async (): Promise<void> => {
                afterActivate && (await afterActivate());
                await queryClient.invalidateQueries([GET_ALL_CONFIGURATIONS_QUERY_KEY]);
                goToShipments();
            })
            .catch((error: ApiClientError): void => {
                logSdkError(error);
                onError();
            });
    }, [afterActivate, goToShipments, onError, params, provider, queryClient]);

    return (
        <Dialog fullScreen isOpen>
            <Spinner fullScreen={true} />
        </Dialog>
    );
};
