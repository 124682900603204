import { useTranslation } from '@packlink/translation-provider';
import {
    Button,
    ButtonSize,
    ButtonVariant,
    Dialog,
    DialogActions,
    DialogContent,
    DialogHeader,
    Typography,
} from '@shipengine/giger';

export type ToggleDialogProps = {
    isOpen: boolean;
    onClose: VoidFunction;
    onSave: VoidFunction;
};

export function ToggleDialog({ isOpen, onClose, onSave }: ToggleDialogProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Dialog isOpen={isOpen}>
            <DialogHeader showClose onClose={onClose} title={t('branded-emails.toggle-dialog.title')} />

            <DialogContent>
                <Typography variant="body1">{t('branded-emails.toggle-dialog.content')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button size={ButtonSize.SMALL} variant={ButtonVariant.OUTLINED} onClick={onSave}>
                    {t('branded-emails.toggle-dialog.accept')}
                </Button>
                <Button size={ButtonSize.SMALL} onClick={onClose}>
                    {t('branded-emails.toggle-dialog.reject')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
