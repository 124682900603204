import { SerializedStyles, css } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSupportTicketPaymentMethodStyles = (theme: Theme): SerializedStyles =>
    css({
        background: theme.palette.white,
        borderRadius: theme.spacing(0.5),
        border: `1px solid ${theme.palette.gray.light}`,
        padding: theme.spacing(2),
    });

export const getSupportTicketPaymentMethodSelectedStyles = (theme: Theme): SerializedStyles =>
    css({
        border: `2px solid ${theme.palette.primary.main}`,
        transform: 'border ease-in-out',
    });

export const getSupportTicketPaymentMethodFormWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
    });
