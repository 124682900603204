import { useMemo } from 'react';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import Packlink from '@sdk';
import { AppDispatch } from '@store';
import { setInboxes } from '@store/actions/inbox';
import { logSdkError } from '@utils/logger';

const GET_STATS_QUERY_KEY = 'getStats';

export function useGetInboxes() {
    const dispatch = useDispatch<AppDispatch>();

    const { data: inboxes, refetch: refetchInboxes } = useQuery({
        queryKey: [GET_STATS_QUERY_KEY],
        queryFn: () => Packlink.pro.stats.get(),
        onSuccess: (inboxes) => {
            dispatch(setInboxes(inboxes));
        },
        onError: logSdkError,
    });

    const refetchInboxesDebounced = useMemo(() => debounce(refetchInboxes, 800), [refetchInboxes]);

    return { inboxes, refetchInboxes, refetchInboxesDebounced };
}
