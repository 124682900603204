import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export function getHeaderStyles(theme: Theme): SerializedStyles {
    return css({
        marginBottom: theme.spacing(2),
    });
}

export const getHeaderTitleStyles = css({
    textTransform: 'uppercase',
    // TODO: Remove global styles from typography tags
    marginBottom: 0,
});

export const getHeaderTooltipStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });
