import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getRegistrationStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    });

export const getCarrierLogoStyles = (): SerializedStyles =>
    css({
        height: '115px',
    });

export const getDeactivateStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(4),
    });

export const getDeactivateButtonStyles = (): SerializedStyles =>
    css({
        padding: 0,
    });
