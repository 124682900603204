import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const datePickerStyles = (theme: Theme): CSSObject => ({
    marginBottom: theme.spacing(2),
});

export const dateInputsStyles = (): CSSObject => ({
    minWidth: 175,
});
