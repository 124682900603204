import { ISelectOption, useToast } from '@shipengine/giger';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { useContext } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import * as yup from 'yup';
import moment from 'moment';
import { FormWithShipmentContext } from '../CommonFields/FormWithShipment/FormWithShipmentData';
import {
    SupportCenterShipmentCancelAlreadyRequestedError,
    SupportCenterShipmentCancellationDeadlineReachedError,
    SupportCenterShipmentNotCancellableError,
} from '../CommonFields/SupportCenterShipmentError/SupportCenterShipmentErrorData';
import { Shipment, IShipment, ShipmentStatus, SupportTicketType } from '@packlink/packlink-sdk';
import { CancellationTimeLimitFeature } from '@SupportCenter/utils/tenantFeatures';
import { useNavigate } from 'react-router';
import { SupportCenterMetricsEvent } from '@SupportCenter/components/types';
import { APP_ROUTE } from '@pages/router/routes';
import { useAmplitude } from '@hooks/useAmplitude';

export type CancelTicketFormData = { reason: ISelectOption | '' };

export function useCancelTicketFormValidationSchema(): yup.ObjectSchema<CancelTicketFormData> {
    const { t } = useTranslation();

    return yup
        .object()
        .shape<CancelTicketFormData>({
            reason: yup
                .mixed<ISelectOption | ''>()
                .required(t('form.error.required', { field: t('support-center.cancel-form.reason-label') })),
        })
        .defined();
}

// Cancel tickets work in a different manner than other tickets
export function useCancelTicketFormHandlers(): {
    handleSubmit: (values: CancelTicketFormData) => Promise<unknown>;
} {
    const { shipment } = useContext(FormWithShipmentContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const toast = useToast(t);
    const sdk = useSdk();
    const { sendAmplitudeEvent } = useAmplitude();

    const handleSubmit = async (values: CancelTicketFormData) => {
        if (!values.reason) {
            throw new Error('Invalid cancellation reason');
        }

        const cancelReason = values.reason.value;
        try {
            await sdk.v1.shipments.cancelShipment(shipment.packlinkReference, cancelReason);
            navigate(APP_ROUTE.SUPPORT_CENTER.INDEX);
            sendAmplitudeEvent(SupportCenterMetricsEvent.OnSupportTicketCreation, { type: SupportTicketType.CANCEL });

            toast.success({
                title: t('support-center.form.submit-success-title'),
                message: t('support-center.form.cancel-success'),
                position: 'bottom-right',
            });
        } catch {
            toast.error({
                title: t('support-center.form.submit-error-title'),
                message: t('support-center.form.submit-error'),
                position: 'bottom-right',
            });
        }
    };
    return { handleSubmit };
}

export function validateCancellableShipment(
    { state, purchaseDate }: Shipment<IShipment>,
    cancellationTimeLimit?: CancellationTimeLimitFeature,
): void {
    const allowedStates = [
        ShipmentStatus.READY_TO_PRINT,
        ShipmentStatus.CARRIER_OK,
        ShipmentStatus.CARRIER_KO,
        ShipmentStatus.LABELS_KO,
        ShipmentStatus.PURCHASE_SUCCESS,
        ShipmentStatus.CARRIER_PENDING,
        ShipmentStatus.RETRY,
    ];

    const cancellationStates = [ShipmentStatus.CANCELED, ShipmentStatus.CANCEL_REQUESTED];

    const shipmentState = state as ShipmentStatus;

    if (cancellationStates.includes(shipmentState)) {
        throw new SupportCenterShipmentCancelAlreadyRequestedError();
    }

    if (!allowedStates.includes(shipmentState)) {
        throw new SupportCenterShipmentNotCancellableError();
    }

    if (
        cancellationTimeLimit &&
        purchaseDate?.isBefore(moment().subtract(cancellationTimeLimit.value, cancellationTimeLimit.unit))
    ) {
        throw new SupportCenterShipmentCancellationDeadlineReachedError();
    }
}
