import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import {
    CustomsFormData,
    otherTariffNumberOption,
    useCustomsFormValidationSchema,
    useGetCustomsInitialValues,
    useSubmitCustomsInvoice,
    ValidTariffNumber,
} from '@packlink/customs-form';
import { Spinner, SpinnerSize, useToast } from '@shipengine/giger';
import { CustomsReasonToExport, IShipment, IShipmentCustomsStatus, Shipment } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import {
    getCheckoutContent,
    getCheckoutCurrency,
    getCheckoutFrom,
    getCheckoutService,
    getCheckoutShipment,
    getCheckoutTo,
    getParcelsTotalWeight,
} from '@store/selectors/checkout';
import { getClientData, getClientIdentifier } from '@store/selectors/client';
import { getCustomsForm, getCustomsInvoiceExternalData } from '@Customs/store/selectors/customs';
import { canCreateShipmentInNextStep } from '@store/selectors/checkout-steps';
import { getIsPaymentStepSkippable } from '@store/selectors/payment';
import { AppDispatch } from '@store';
import { requireCustomsForm, setCustomsForm, skipCustomsForm } from '@Customs/store/actions/customs';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { AmplitudeEventsBulkCustoms } from '@Customs/metrics/amplitude';
import { useGoToPaymentStep } from '@components/Checkout/hooks/useGoToPaymentStep';
import { useInitStep } from '@components/Checkout/hooks/useInitStep';
import { StepContent } from '@components/Checkout/StepContent';
import { getLoadingStyles, getSkipCustomsNotificationStyles } from './CustomsStepStyles';
import { SkipCustomsNotification } from './SkipCustomsNotification';
import { CustomsStepForm } from './CustomsStepForm';
import { useAmplitude } from '@hooks/useAmplitude';

interface ICustomsStepProps {
    goToNextStep: (carriersTermsAndConditions?: boolean | undefined) => void;
}

export const CustomsStep = (): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();
    const isPaymentStepSkippable = useSelector(getIsPaymentStepSkippable);

    const { carriersTermsAndConditions } = ((isPaymentStepSkippable && location.state) || {}) as {
        carriersTermsAndConditions?: boolean;
    };

    const { isLoading, initialValues, validTariffNumbers, isSkippableCustoms } = useCustomsFormData();

    const { validationSchema } = useCustomsFormValidation();
    const { goToPaymentStep } = useGoToPaymentStep();
    const { submit } = useCustomsFormSubmitHandler(
        validTariffNumbers,
        { goToNextStep: goToPaymentStep },
        carriersTermsAndConditions,
    );
    const { handleSkipCustoms, skipCustomsText } = useCustomsFormSkipNotification({
        goToNextStep: goToPaymentStep,
    });

    useInitStep();

    if (isLoading) {
        return (
            <StepContent>
                <div css={getLoadingStyles}>
                    <Spinner size={SpinnerSize.SIZE_LARGE} message={t('HOLD')} />
                </div>
            </StepContent>
        );
    }

    return (
        <StepContent>
            {isSkippableCustoms && (
                <SkipCustomsNotification
                    css={getSkipCustomsNotificationStyles}
                    onAction={() => handleSkipCustoms(carriersTermsAndConditions)}
                    actionText={skipCustomsText}
                />
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submit}
                enableReinitialize={true}
            >
                <CustomsStepForm validTariffNumbers={validTariffNumbers} />
            </Formik>
        </StepContent>
    );
};

function useCustomsFormData() {
    const from = useSelector(getCheckoutFrom);
    const to = useSelector(getCheckoutTo);
    const currency = useSelector(getCheckoutCurrency) ?? '';
    const shipment = useSelector(getCheckoutShipment) as Shipment<IShipment>;
    const service = useSelector(getCheckoutService);
    const client = useSelector(getClientData);
    const validTariffNumbers = config.validTariffNumbers;

    // Retrieve saved form values
    // Only retrieve form in first render
    // Ideally, this should not be stored in redux
    const savedCustomsForm = useSelector(getCustomsForm, () => true);

    const [emptyValues, isLoading] = useGetCustomsInitialValues({
        currency,
        sender: from,
        receiver: to,
        customs: shipment.customs,
        client,
        fetchSavedSenderData: true,
        validTariffNumbers,
        defaultCustomsCategory: otherTariffNumberOption,
        defaultExportReason: CustomsReasonToExport.PURCHASE_OR_SALE,
    });

    const initialValues = savedCustomsForm ?? emptyValues;
    return {
        initialValues,
        initialStatus: shipment.customs?.status || IShipmentCustomsStatus.DRAFT,
        isLoading,
        validTariffNumbers,
        isSkippableCustoms: !service?.hasPaperlessCustoms,
    };
}

function useCustomsFormValidation() {
    const totalWeight = useSelector(getParcelsTotalWeight);
    const checkoutContent = useSelector(getCheckoutContent);
    const contentValue = checkoutContent?.value ?? 0;
    const validationSchema = useCustomsFormValidationSchema({
        customsInventoryMaxWeight: totalWeight,
        customsInventoryMaxValue: contentValue,
    });

    return { validationSchema };
}

function useCustomsFormSubmitHandler(
    validTariffNumbers: ValidTariffNumber[],
    { goToNextStep }: ICustomsStepProps,
    carriersTermsAndConditions?: boolean,
) {
    const { t } = useTranslation();
    const toast = useToast(t);
    const clientId = useSelector(getClientIdentifier);
    const shipment = useSelector(getCheckoutShipment) as Shipment<IShipment>;
    const { sendAmplitudeShipmentEvent } = useAmplitude();
    const customsInvoice = useSelector(getCustomsForm);
    const customsExternalData = useSelector(getCustomsInvoiceExternalData);
    const submitCustomsInvoice = useSubmitCustomsInvoice({ validTariffNumbers, clientId });
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        customsInvoice && goToNextStep(carriersTermsAndConditions);
        // the next line lets us avoiding carriersTermsAndConditions and goToNextStep as dependencies, since adding them would cause an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customsInvoice]);

    const submit = (values: CustomsFormData) => {
        submitCustomsInvoice(values, customsExternalData)
            .then((updatedCustomsForm) => dispatch(setCustomsForm(updatedCustomsForm)))
            .then(() => sendAmplitudeShipmentEvent(AmplitudeEventsBulkCustoms.FINISH_CUSTOMS_FORM, shipment))
            .catch(() => toast.error({ message: t('SAVE_ERROR') }));
    };
    return {
        submit,
    };
}

function useCustomsFormSkipNotification({ goToNextStep }: ICustomsStepProps) {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const canCreateShipment = useSelector(canCreateShipmentInNextStep);
    const shipment = useSelector(getCheckoutShipment) as Shipment<IShipment>;
    const { sendAmplitudeShipmentEvent } = useAmplitude();

    useEffectOnce(() => {
        dispatch(requireCustomsForm());
    });

    const handleSkipCustoms = (carriersTermsAndConditions?: boolean) => {
        dispatch(skipCustomsForm());
        goToNextStep(carriersTermsAndConditions);
        sendAmplitudeShipmentEvent(
            `${AmplitudeEventPrefixes.CLICK} ${AmplitudeEventsBulkCustoms.SKIP_CUSTOMS_FORM}` as AmplitudeEvents,
            shipment,
        );
    };

    return {
        handleSkipCustoms,
        skipCustomsText: canCreateShipment
            ? t('customs.skip-notification.action-create-shipment')
            : t('customs.skip-notification.action-go-to-payment'),
    };
}
