import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import qs from 'qs';
import { useTranslation } from '@packlink/translation-provider';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useSelector } from 'react-redux';
import { IconNames } from '@shipengine/giger-theme';
import { Button, ButtonSize, Icon, IconSize, Input } from '@shipengine/giger';
import { getBulkFiltersFromPanel } from '@store/selectors/bulk';
import { getCurrentInbox } from '@store/selectors/inbox';
import { inboxToRoute } from '@pages/router/utils/paths';
import {
    getShipmentSearchButtonStyles,
    getShipmentSearchInputStyles,
    getShipmentSearchStyles,
} from './ShipmentSearchStyles';
import { useAmplitude } from '@hooks/useAmplitude';

export const ShipmentSearch = (): JSX.Element => {
    const currentInbox = useSelector(getCurrentInbox);
    const panelFilters = useSelector(getBulkFiltersFromPanel);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendAmplitudeTableViewEvent } = useAmplitude();

    const searchShipments = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        sendAmplitudeTableViewEvent(AmplitudeEvents.SEARCH_BAR, { [AmplitudeProperties.SEARCH_QUERY]: searchQuery });

        const searchFilters = { ...panelFilters, ...(!!searchQuery && { q: searchQuery }) };

        navigate({
            pathname: inboxToRoute(currentInbox),
            search: qs.stringify(searchFilters, { arrayFormat: 'brackets', encode: false }),
        });
    };

    const onChangeHandler = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchQuery(value);
    };

    return (
        <form css={getShipmentSearchStyles} onSubmit={searchShipments}>
            <Input
                label={t('header.search.search-shipments')}
                showLabel={false}
                css={getShipmentSearchInputStyles}
                placeholder={t('header.search.search-shipments')}
                type="text"
                name="shipmentSearch"
                onChange={onChangeHandler}
                value={searchQuery}
            />
            <Button css={getShipmentSearchButtonStyles} type="submit" size={ButtonSize.SMALL}>
                <Icon name={IconNames.SEARCH} size={IconSize.SIZE_SMALL} />
            </Button>
        </form>
    );
};
