import { ForwardedRef, forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import { FieldNames, InvoiceForm, PhoneNumberValue } from '@packlink/invoice-form';
import { Client, ClientType, IClientApiUpdateResponse } from '@packlink/packlink-sdk';
import { isBusinessClient } from '@store/selectors/client';
import { BillingClientType } from '@components/BillingForm/BillingClientType';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { getClientTypeStyles } from '@components/BillingInvoicePanelContent/BillingInvoicePanelContentStyles';
import { PhoneNumberVerificationPanel } from '@common/components/PhoneNumberVerificationPanel/PhoneNumberVerificationPanel';
import { useBillingForm } from '@common/hooks/useBillingForm';

interface BillingInvoicePanelContentProps {
    isPhoneNumberVerificationOpen: boolean;
    onClosePhoneNumberVerification: () => void;
    onSuccessCallback: (client: Client) => void;
    onPhoneNumberVerified?: () => void;
}

export const BillingInvoicePanelContent = forwardRef(function BillingInvoicePanelContent(
    props: BillingInvoicePanelContentProps,
    ref: ForwardedRef<HTMLFormElement>,
): JSX.Element {
    const { isPhoneNumberVerificationOpen, onSuccessCallback, onPhoneNumberVerified, onClosePhoneNumberVerification } =
        props;
    const { t } = useTranslation();
    const isBusiness = useSelector(isBusinessClient);
    const { client, isPhoneNumberVerified, isRequiresPhoneNumberVerificationEnabled } = useBillingForm();
    const [formType, setFormType] = useState(() =>
        client.type && [ClientType.AUTONOMOUS, ClientType.BUSINESS].includes(client.type)
            ? client.type
            : ClientType.AUTONOMOUS,
    );
    const [initialPhoneNumberValue, setInitialPhoneNumberValue] = useState<PhoneNumberValue | string>();

    const onSuccess = (
        _data: IClientApiUpdateResponse,
        client: Client,
        phoneNumberValue: PhoneNumberValue | string,
    ) => {
        onSuccessCallback(client);
        setInitialPhoneNumberValue(phoneNumberValue);
    };

    return (
        <>
            <SidePanelContentSection title={t('invoice-form.section.title')}>
                {!isBusiness && <BillingClientType type={formType} onChange={setFormType} css={getClientTypeStyles} />}
                <InvoiceForm
                    type={formType}
                    client={client}
                    validation={{
                        validateOnBlur: false,
                        validateOnChange: true,
                    }}
                    onSuccess={onSuccess}
                    disabledFields={{
                        [FieldNames.COUNTRY]: isBusiness,
                        [FieldNames.TAXID]: isBusiness,
                    }}
                    showSubmitButton={false}
                    ref={ref}
                    cdnUrl={config.cdn.baseUrl}
                    isPhoneNumberVerified={isPhoneNumberVerified}
                    requiresPhoneNumberVerification={isRequiresPhoneNumberVerificationEnabled}
                />
            </SidePanelContentSection>

            <PhoneNumberVerificationPanel
                isOpen={isPhoneNumberVerificationOpen}
                alpha2Code={client.alpha2Code}
                initialPhoneNumberValue={initialPhoneNumberValue}
                onClose={onClosePhoneNumberVerification}
                onComplete={onPhoneNumberVerified}
            />
        </>
    );
});
