import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getPriceSummarySectionStyles = (theme: Theme): SerializedStyles =>
    css({
        boxShadow: theme.elevations.above.boxShadow,
    });

export const getPriceSummaryItemsStyles =
    (total: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            padding: `${theme.spacing(2)}px 0`,
            margin: `0 ${theme.spacing(2)}px`,

            ':not(:first-of-type)': {
                borderTop: `1px solid ${theme.palette.gray.light}`,
            },

            ...(total && {
                textTransform: 'uppercase',
                margin: 0,
                padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
            }),
        });

export const getPriceSummaryItemStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'space-between',
        ':not(:last-of-type)': {
            marginBottom: theme.spacing(),
        },
    });

export const getPriceSummaryIconStyles = (theme: Theme): SerializedStyles =>
    css({
        verticalAlign: 'text-bottom',
        marginLeft: theme.spacing(0.5),
        span: {
            color: theme.palette.gray.dark,
        },
    });
