import React, { useRef } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useToggle } from '@packlink/utils';
import { Button, ButtonColor, ButtonSize, ButtonVariant, Typography } from '@shipengine/giger';
import { PopoverCallToAction } from '@components/PopoverCallToAction/PopoverCallToAction';
import { getDeactivateButtonStyles, getDeactivateStyles } from './OwnContractPanelStyles';

interface OwnContractPanelDeactivateProps {
    handleDeactivate: () => void;
}

export const OwnContractPanelDeactivate: React.FC<OwnContractPanelDeactivateProps> = (
    props: OwnContractPanelDeactivateProps,
): JSX.Element => {
    const { handleDeactivate } = props;
    const { t } = useTranslation();
    const { state: isDeactivateOpen, toggle: toggleIsDeactivateOpen } = useToggle(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleDeactivateAndReset = (): void => {
        handleDeactivate();
        toggleIsDeactivateOpen();
    };

    return (
        <section css={getDeactivateStyles}>
            <Button
                onClick={toggleIsDeactivateOpen}
                variant={ButtonVariant.TEXT}
                color={ButtonColor.ERROR}
                size={ButtonSize.SMALL}
                ref={buttonRef}
                css={getDeactivateButtonStyles}
            >
                {t('own-contract.panel.deactivate')}
            </Button>
            <PopoverCallToAction
                reference={buttonRef.current}
                isOpen={isDeactivateOpen}
                onClickAway={toggleIsDeactivateOpen}
                placement="bottom-end"
                primaryText="own-contract.panel.deactivate-confirm"
                secondaryText="own-contract.panel.cancel"
                onPrimaryClick={handleDeactivateAndReset}
                onSecondaryClick={toggleIsDeactivateOpen}
            >
                <Typography variant="body2">{t('own-contract.panel.deactivate-message')}</Typography>
            </PopoverCallToAction>
        </section>
    );
};
