import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import {
    Button,
    ButtonVariant,
    Divider,
    FieldMessageType,
    FormField,
    IconPopover,
    IconSize,
    Input,
    Switch,
    Typography,
    useToast,
    WithCommonProps,
} from '@shipengine/giger';
import { ApiClientError, IShippingRule, ShippingRulesRepository } from '@packlink/packlink-sdk';
import { getUserId } from '@store/selectors/user';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { API_ERRORS_SHIPPING_RULES } from '@constants/apiErrors';
import { useAmplitude } from '@hooks/useAmplitude';
import { apiClient } from '@sdk';
import { IconNames } from '@shipengine/giger-theme';

import { useShippingRules } from '../../../../context/shippingRules';
import { IFormItem, IRuleForm } from '../../types/formTypes';
import { mapFormValuesToNewRule } from '../../utils/shippingRulesMapper';
import { useFormValidationSchema } from '../../hooks/useFormValidationSchema';
import { useInitialValues } from '../../hooks/useInitialValues';
import { aliasFieldStyles, cancelButtonStyles, formStyles, infoIconStyles, switchStyles } from './RuleFormStyles';
import { RuleCondition } from '../RuleCondition/RuleCondition';
import { RuleAction } from '../RuleAction/RuleAction';

export const shippingRulesRepository = new ShippingRulesRepository(apiClient);

export interface IRuleFormProps {
    selectedRule?: IShippingRule;
    onCancel: VoidFunction;
    onSuccess: VoidFunction;
}

export function RuleForm({ onCancel, onSuccess, selectedRule, ...rest }: WithCommonProps<IRuleFormProps>): JSX.Element {
    const { t } = useTranslation();
    const userId = useSelector(getUserId);
    const { sendAmplitudeEvent } = useAmplitude();
    const toast = useToast(t);
    const { hasReachedRulesLimit } = useShippingRules();
    const isFormBlocked = hasReachedRulesLimit && !selectedRule;

    const { id, enabled, overwrite, alias, conditions, actions } = useInitialValues(selectedRule);
    const validationSchema = useFormValidationSchema();

    const initialFormValues = useMemo((): IRuleForm => {
        return {
            enabled,
            overwrite,
            alias,
            conditions,
            actions,
        };
    }, [actions, alias, conditions, enabled, overwrite]);

    const submitForm = (values: IRuleForm, formikHelpers: FormikHelpers<IRuleForm>) => {
        const fullForm = id ? { ...values, id } : values;
        const newRule = mapFormValuesToNewRule({ ...fullForm });

        shippingRulesRepository
            .save(userId, newRule)
            .then(() => {
                const successMessage = t(id ? 'shipping-rules.success.edited' : 'shipping-rules.success.added', {
                    alias: values.alias,
                });
                const amplitudeProps = {
                    [AmplitudeProperties.SHIPPING_RULES_CONDITION]: values.conditions.map(
                        (condition: IFormItem) => condition.identifier.value,
                    ),
                    [AmplitudeProperties.SHIPPING_RULES_ACTION]: values.actions.map(
                        (action: IFormItem) => action.identifier.value,
                    ),
                    [AmplitudeProperties.ACTIVE]: values.enabled,
                    [AmplitudeProperties.OVERWRITE]: values.overwrite,
                };
                onSuccess();

                toast.success({ message: successMessage });

                sendAmplitudeEvent(
                    id ? AmplitudeEvents.SHIPPING_RULE_EDITED_SUCCESS : AmplitudeEvents.SHIPPING_RULE_ADDED_SUCCESS,
                    amplitudeProps,
                );
            })
            .catch((e: ApiClientError) => {
                formikHelpers.setSubmitting(false);
                const errorKey = e.code && API_ERRORS_SHIPPING_RULES[e.code];
                toast.error({ message: t(errorKey || 'shipping-rules.error.save', { alias: values.alias }) });
                sendAmplitudeEvent(AmplitudeEvents.SHIPPING_RULE_ADDED_KO, {
                    [AmplitudeProperties.ERROR]: e.code || e.message,
                });
            });
    };

    const formTitle = t(selectedRule ? 'shipping-rules.form.edit-rule' : 'shipping-rules.form.new-rule');

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            onSubmit={submitForm}
        >
            {(formProps: FormikProps<IRuleForm>): React.ReactNode => (
                <Form css={formStyles} {...rest}>
                    <Typography variant="heading5" component="h5">
                        {formTitle}
                    </Typography>

                    <Typography variant="subtitle1" component="p">
                        {t('shipping-rules.form.rule-name')}
                    </Typography>
                    <div css={aliasFieldStyles}>
                        <FormField
                            message={
                                formProps.errors.alias && formProps.touched.alias
                                    ? { type: FieldMessageType.ERROR, content: formProps.errors.alias }
                                    : undefined
                            }
                        >
                            <Input
                                label={t('shipping-rules.form.name')}
                                name="alias"
                                value={formProps.values.alias}
                                isInvalid={!!(formProps.errors.alias && formProps.touched.alias)}
                                disabled={isFormBlocked}
                                onChange={formProps.handleChange}
                            />
                        </FormField>
                    </div>

                    <Typography variant="subtitle1" component="p">
                        {t('shipping-rules.form.settings')}
                    </Typography>
                    <div css={switchStyles}>
                        <Switch
                            value={formProps.values.overwrite}
                            name="overwrite"
                            isChecked={formProps.values.overwrite}
                            onChange={formProps.handleChange}
                            disabled={isFormBlocked}
                        >
                            {t('shipping-rules.form.overwrite')}
                            <IconPopover
                                css={infoIconStyles}
                                icon={IconNames.INFO}
                                size={IconSize.SIZE_SMALL}
                                data-id="shipping-rules-overwrite-info"
                            >
                                <Typography variant="body2" component="span">
                                    {t('shipping-rules.form.overwrite-info')}
                                </Typography>
                            </IconPopover>
                        </Switch>
                        <Switch
                            value={true}
                            name="enabled"
                            isChecked={formProps.values.enabled}
                            onChange={formProps.handleChange}
                            disabled={isFormBlocked}
                        >
                            {t('shipping-rules.form.active-from-now')}
                        </Switch>
                    </div>

                    <Divider />

                    <RuleCondition isFormBlocked={isFormBlocked} />

                    <Divider />

                    <RuleAction isFormBlocked={isFormBlocked} />

                    <Button css={cancelButtonStyles} variant={ButtonVariant.OUTLINED} onClick={onCancel}>
                        {t('settings.form.cancel')}
                    </Button>

                    <Button
                        type="submit"
                        disabled={!formProps.isValid || isFormBlocked}
                        isLoading={formProps.isSubmitting}
                    >
                        {t('settings.form.save')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
}
