import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getTransitStyles = (theme: Theme): SerializedStyles =>
    css({
        textTransform: 'lowercase',
        lineHeight: theme.typography.fontSize.XS,
    });

export const getTitleStyles = (): SerializedStyles =>
    css({
        margin: 0,
        wordBreak: 'break-word',
    });

export const getRouteStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        margin: `${theme.spacing()}px 0 0 ${theme.spacing(3)}px`,
    });

export const getRouteIconStyles = (theme: Theme): SerializedStyles =>
    css({
        alignItems: 'center',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        color: theme.palette.secondary.main,
    });

export const getRouteTitleStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        margin: 0,
    });

export const getCompactServiceCardStyles =
    (isSelected: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            width: '100%',
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.gray.megaLight,
            border: `1px solid ${isSelected ? theme.palette.primary.main : theme.palette.gray.light}`,
            borderRadius: theme.borderRadius.S,
            boxShadow: theme.elevations.flat1.boxShadow,
        });

export const getCompactSericeCardChildrenStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
    });

export const getCompactServiceCardWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(),
    });

export const getCompactServiceCardLinkStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.primary.main,
    });

export const getCompactServiceCardHeaderStyles = (isShortVariant: boolean) => (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...(isShortVariant && { display: 'flex', alignItems: 'center' }),
    });

export const getRoutePointStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
    });

export const getLogoStyles = (theme: Theme): SerializedStyles =>
    css({
        height: '30px',
        marginRight: theme.spacing(),
    });

export const getCompactCardContentStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    });

export const getOwnContractStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.alert.main,
    });

export const getDetailsStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `0 ${theme.spacing()}px ${theme.spacing()}px ${theme.spacing()}px`,

        '& li': {
            color: theme.palette.gray.dark,
            marginTop: theme.spacing(0.5),
        },

        '& div': {
            flexWrap: 'wrap',

            '& section': {
                paddingRight: 0,

                ':first-of-type': {
                    paddingTop: 0,
                },
            },
        },

        '& h5': {
            display: 'none',
        },
    });

export const getRadioStyles = (): SerializedStyles =>
    css({
        width: '100%',
    });

export const getChevronStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });

export const getFooterStyles = (theme: Theme): SerializedStyles =>
    css({
        alignItems: 'center',
        borderTop: `1px solid ${theme.palette.gray.light}`,
        margin: `${theme.spacing()}px -${theme.spacing()}px -${theme.spacing()}px -${theme.spacing()}px`,
        padding: theme.spacing(),
    });

export const getPriceContainerStyles = (): SerializedStyles =>
    css({
        textAlign: 'end',
        display: 'flex',
        flexDirection: 'column',
    });

export const getPriceStyles = (): SerializedStyles =>
    css({
        whiteSpace: 'nowrap',
    });

export const getFeeStyles = (theme: Theme): SerializedStyles =>
    css({
        lineHeight: theme.typography.fontSize.XS,
    });

export const getDetailsButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        ':hover': {
            backgroundColor: theme.palette.gray.light,
        },
    });
