import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketDataLoadingPlaceholderStyles = css({
    height: 'min-content',
});

export const getTicketDataLoadingPlaceholderSpinnerStyles = (theme: Theme): SerializedStyles =>
    css({
        width: '100%',
        height: theme.spacing(20),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });
