import { Next, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

type PostSaleTrackingCarrierWebsiteProps = WithCommonProps<{
    trackingUrl: string;
}>;

export const PostSaleTrackingCarrierWebsite = ({
    trackingUrl,
    ...props
}: PostSaleTrackingCarrierWebsiteProps): JSX.Element | null => {
    const { t } = useTranslation();
    return (
        <Next.Link href={trackingUrl} target="__blank" isExternal {...props}>
            {t('post-sale.carrier.goto-web-site')}
        </Next.Link>
    );
};
