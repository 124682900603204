import { useMemo } from 'react';
import { FastField, Form, Formik } from 'formik';

import { WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { DatePicker, FormField, Textarea } from '@shipengine/formik-giger';

import { SubmitButton } from '../CommonFields/SubmitButton/SubmitButton';
import { NewTicketFieldset } from '../CommonFields/NewTicketFieldset/NewTicketFieldset';

import {
    PickupTicketFormData,
    usePickupTicketFormHandlers,
    usePickupTicketFormValidationSchema,
} from './PickupTicketFormData';

import { NewTicketEventHandlers } from '../../NewTicketData';
import { isWeekend, getTomorrowDate } from '../../../../utils/dates';

import { getFieldDateStyles } from './PickupTicketFormStyles';
import { getFormInputStyles, getSubmitButtonStyles } from '../../styles/FormStyles';

type PickupTicketFormProps = WithCommonProps<NewTicketEventHandlers>;

export function PickupTicketForm({ onSubmit, ...props }: PickupTicketFormProps): JSX.Element {
    const { t } = useTranslation();
    const { validationSchema, initialValues, handleSubmit } = useFormData({ onSubmit });

    const labels = useMemo(
        () => ({
            selectDateIconTooltip: t('datepicker.labels.select-date'),
            previousMonthTooltip: t('datepicker.labels.previous-month'),
            nextMonthTooltip: t('datepicker.labels.next-month'),
            cancelButton: t('datepicker.labels.cancel'),
            confirmButton: t('datepicker.labels.confirm'),
        }),
        [t],
    );

    return (
        <Formik<PickupTicketFormData>
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            <Form {...props}>
                <NewTicketFieldset title={t('support-center.new-ticket.collection-date-hint')}>
                    <FormField name="collectionDate">
                        <FastField
                            component={DatePicker}
                            css={getFieldDateStyles}
                            labels={labels}
                            label={t('support-center.pickup-form.collection-date-label')}
                            filterDate={filterDate}
                            minDate={getTomorrowDate()}
                        />
                    </FormField>
                    <FormField name="additionalComments" css={getFormInputStyles}>
                        <FastField component={Textarea} label={t('support-center.pickup-form.comments-label')} />
                    </FormField>
                </NewTicketFieldset>
                <SubmitButton css={getSubmitButtonStyles} />
            </Form>
        </Formik>
    );
}

function filterDate(date: Date) {
    return !isWeekend(date);
}

function useFormData(eventHandlers: NewTicketEventHandlers) {
    const validationSchema = usePickupTicketFormValidationSchema();
    const initialValues: PickupTicketFormData = { collectionDate: null };
    const { handleSubmit } = usePickupTicketFormHandlers(eventHandlers);

    return {
        validationSchema,
        initialValues,
        handleSubmit,
    };
}
