import { useTranslation } from '@packlink/translation-provider';
import { InlineNotification, NotificationType } from '@shipengine/giger';
import { useState } from 'react';
import { limitNotificationStyles } from './LimitNotificationStyles';

export function LimitNotification(): JSX.Element | null {
    const { t } = useTranslation();
    const [isClosed, setIsClosed] = useState(false);

    if (isClosed) return null;

    return (
        <InlineNotification
            css={limitNotificationStyles}
            type={NotificationType.ALERT}
            title={t('addresses.settings.limit-notification-title')}
            onClose={() => setIsClosed(true)}
            closeText={t('toast.action.close')}
        >
            {t('addresses.settings.limit-notification-message')}
        </InlineNotification>
    );
}
