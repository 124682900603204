import { Theme } from '@shipengine/giger-theme';
import { css, CSSObject, SerializedStyles } from '@emotion/react';

export const getSectionStyles = (): CSSObject => ({
    height: '100%',
});

export const getHeaderLogoStyles = (theme: Theme): CSSObject => ({
    position: 'absolute',
    top: theme.spacing(4),
    left: theme.spacing(4),
});

export const getCloseButtonStyles = (theme: Theme): CSSObject => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
});

export const getCloseButtonIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: theme.spacing(),
    });

export const getErrorStateStyles = (): CSSObject => ({
    height: '100%',

    img: {
        height: 100,
    },
});
