import React, { useRef } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from '@packlink/translation-provider';
import { useDispatch, useSelector } from 'react-redux';
import { Inbox, ProShipment } from '@packlink/packlink-sdk';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import Packlink from '@sdk';
import { IconNames } from '@shipengine/giger-theme';
import { useToggle } from '@packlink/utils';
import { Button, ButtonSize, ButtonVariant, useToast } from '@shipengine/giger';
import { useEvent } from '@packlink/event-handler';
import { deleteShipments } from '@store/actions/bulk';
import { getIsBulkActionsActive, hasActions, isShipmentSelected } from '@store/selectors/bulk';
import { getClientIdentifier } from '@store/selectors/client';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { downloadLabel, ILabelGenerated } from '@utils/LabelUtils';
import { ShipmentUtils } from '@utils/ShipmentUtils';
import { EventName, ITenantConfig, ShipmentPanelType } from '@types';
import { AppDispatch } from '@store';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { APP_ROUTE } from '@pages/router/routes';

import { useShipmentNotificationHandler } from '../hooks/useShipmentNotificationHandler';
import { ShipmentRowCheckboxCell } from './ShipmentRowCheckboxCell';
import { ShipmentRowRecipientCell } from './ShipmentRowRecipientCell';
import { ShipmentRowPackagesCell } from './ShipmentRowPackagesCell';
import { ShipmentRowServicesCell } from './ShipmentRowServicesCell';
import { ShipmentRowStatusCell } from './ShipmentRowStatusCell';
import { ShipmentRowActionsCell } from './ShipmentRowActionsCell';
import { ShipmentRowOtherBenefitsCell } from './ShipmentRowOtherBenefitsCell';
import { ShipmentRowExtension } from './ShipmentRowExtension/ShipmentRowExtension';
import { ShipmentRowFooter } from './ShipmentRowFooter/ShipmentRowFooter';
import { ShipmentRowFooterAction } from './ShipmentRowFooter/ShipmentRowFooterAction';
import { ShipmentRowFooterLabel } from './ShipmentRowFooter/ShipmentRowFooterLabel';
import { ShipmentRowTrackings } from './ShipmentRowFooter/ShipmentRowTrackings';
import { ShipmentRowTrackingDetails } from './ShipmentRowTrackingDetails/ShipmentRowTrackingDetails';
import {
    getShipmentRowContentStyles,
    getShipmentRowFieldsStyles,
    getShipmentRowInfoStyles,
    getShipmentRowStyles,
} from './ShipmentRowStyles';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IShipmentRowProps {
    shipment: ProShipment;
    index: number;
}

export const ShipmentRow: React.FC<IShipmentRowProps> = (props: IShipmentRowProps): JSX.Element => {
    const { shipment, index } = props;
    const { t } = useTranslation();
    const { packlinkReference, labels, isObfuscated } = shipment.data;
    const isCanceled = ShipmentUtils.isShipmentCanceled(shipment.data);
    const isDraft = ShipmentUtils.isShipmentDraft(shipment.data);
    const showBulkAction = useSelector(hasActions);
    const isSelected = useSelector(isShipmentSelected(packlinkReference));
    const clientId = useSelector(getClientIdentifier);
    const isBulkActionsActive = useSelector(getIsBulkActionsActive);
    const dispatch = useDispatch<AppDispatch>();
    const { openPanel } = useShipmentPanel();
    const { showLabelsFilter } = useTenantConfig<ITenantConfig>();
    const intersectionRef = useRef(null);
    const { eventBind, eventUnbind } = useEvent<ILabelGenerated>(clientId, EventName.LABEL_MERGE_READY);
    const toast = useToast(t);
    const navigate = useNavigate();
    const { sendAmplitudeTableViewClickEvent, sendAmplitudeShipmentEvent } = useAmplitude();

    const { hasNotification } = useShipmentNotificationHandler(intersectionRef, packlinkReference);

    const sendAmplitudeTableViewShipmentEvent = (event: AmplitudeEvents): void => {
        const eventName = `${AmplitudeEventPrefixes.TABLE_VIEW_CLICK} ${event}` as AmplitudeEvents;
        sendAmplitudeShipmentEvent(eventName, shipment.data);
    };

    const onComplete = (): void => {
        navigate(APP_ROUTE.CHECKOUT.EDIT.replace(':id', packlinkReference));
        sendAmplitudeTableViewShipmentEvent(AmplitudeEvents.COMPLETE_SHIPMENT);
    };

    const onCreate = (): void => {
        navigate(APP_ROUTE.CHECKOUT.EDIT.replace(':id', packlinkReference));
        sendAmplitudeTableViewShipmentEvent(AmplitudeEvents.CONTRACT);
    };

    const onDetails = (): void => {
        navigate(APP_ROUTE.SHIPMENTS.DETAILS.replace(':id', packlinkReference));

        sendAmplitudeTableViewShipmentEvent(AmplitudeEvents.SEE_DETAILS);
    };

    const handleSuccessDownload = (data: ILabelGenerated): void => {
        toast.success({
            message: t('shipment-row.labels.ready'),
            actionElement: (
                <Button size={ButtonSize.SMALL} variant={ButtonVariant.TEXT} onClick={() => downloadLabel(data)}>
                    {t('download-label.action.download')}
                </Button>
            ),
        });
    };

    const handleLabelNotification = (data: ILabelGenerated) => {
        const hasError = !data.references?.length || !data.url_label || data.status !== '200';
        if (hasError) {
            toast.error({ message: t('shipment-row.labels.not-generated') });
        } else {
            handleSuccessDownload(data);
        }
        eventUnbind(handleLabelNotification);
    };

    const onPrintLabels = (): void => {
        if (labels && labels[0]) {
            handleSuccessDownload({ url_label: labels[0] });
        } else {
            eventBind(handleLabelNotification);
            Packlink.v1.shipments.getLabels([packlinkReference]);
        }

        sendAmplitudeTableViewShipmentEvent(AmplitudeEvents.PRINT_LABEL);
    };

    const onEditRecipient = (): void => {
        openShipmentPanel(ShipmentPanelType.RECIPIENT);
    };

    const onEditParcel = (): void => {
        openShipmentPanel(ShipmentPanelType.CONTENT);
        sendAmplitudeTableViewShipmentEvent(AmplitudeEvents.MODIFY_PARCEL);
    };

    const onServicesDropdown = (): void => {
        openShipmentPanel(ShipmentPanelType.SERVICES);
        sendAmplitudeTableViewShipmentEvent(AmplitudeEvents.MODIFY_SERVICE);
    };

    const onDeleteDropdown = (): void => {
        sendAmplitudeTableViewShipmentEvent(AmplitudeEvents.DELETE_SHIPMENT);
        dispatch(deleteShipments([shipment.data.packlinkReference])).then(() => {
            toast.success({ message: t('shipment-row.delete.success', { number: 1 }) });
        });
    };

    const openShipmentPanel = (panelName: ShipmentPanelType, triggerEvent?: boolean): void => {
        openPanel(panelName, shipment, undefined, triggerEvent);
    };

    const onTrackingDetails = (): void => {
        if (isTrackingDetailsOpen === false) {
            sendAmplitudeTableViewShipmentEvent(AmplitudeEvents.SEE_TRACKING);
        }
        toggleTrackingDetails();
    };

    const duplicateShipment = () => {
        sendAmplitudeTableViewClickEvent(AmplitudeEvents.DUPLICATE_SHIPMENT);

        Packlink.pro.shipments.createShipmentForMultiLabel(packlinkReference).then((newShipment) => {
            navigate(APP_ROUTE.CHECKOUT.EDIT.replace(':id', newShipment.packlinkReference));
        });
    };

    const returnShipment = () => {
        sendAmplitudeTableViewClickEvent(AmplitudeEvents.RETURN_LABEL);

        Packlink.v1.shipments.createReturnShipment(packlinkReference).then((newShipmentReference) => {
            navigate(APP_ROUTE.CHECKOUT.EDIT.replace(':id', newShipmentReference));
        });
    };

    const shipmentState = ShipmentUtils.getFrontendStatus(shipment.data.state) as Inbox;

    const shipmentHasTrackings = shipment.data.trackings && shipment.data.trackings.length !== 0;
    const shipmentHasTrackingDetails = !!shipmentHasTrackings && shipmentState !== Inbox.READY_FOR_SHIPPING;
    const shipmentHasTrackingAndNoDetails = !!shipmentHasTrackings && shipmentState === Inbox.READY_FOR_SHIPPING;
    const clickStatusEnabled = !shipmentHasTrackingDetails;

    const isShipmentPending = [Inbox.DRAFT, Inbox.READY_TO_PURCHASE].includes(shipmentState);
    const showLabelManaged = showLabelsFilter && shipmentState === Inbox.READY_FOR_SHIPPING;

    const { state: isTrackingDetailsOpen, toggle: toggleTrackingDetails } = useToggle(false);

    return (
        <>
            <div css={getShipmentRowStyles(isSelected, isDraft)} ref={intersectionRef}>
                <div css={getShipmentRowContentStyles}>
                    {showBulkAction && <ShipmentRowCheckboxCell shipment={shipment} index={index} />}
                    <div css={getShipmentRowInfoStyles(isSelected, isBulkActionsActive)}>
                        <div css={getShipmentRowFieldsStyles}>
                            <ShipmentRowRecipientCell shipment={shipment} isDraft={isDraft} onEdit={onEditRecipient} />
                            <ShipmentRowPackagesCell shipment={shipment} isDraft={isDraft} onEdit={onEditParcel} />
                            <ShipmentRowServicesCell
                                shipment={shipment}
                                isDraft={isDraft}
                                onEdit={onServicesDropdown}
                            />
                            <ShipmentRowOtherBenefitsCell
                                shipment={shipment}
                                isDraft={isDraft}
                                onEdit={openShipmentPanel}
                            />
                            <ShipmentRowStatusCell
                                hasNotification={hasNotification}
                                canBePurchased={shipment.actions.canBePurchased}
                                shipmentState={shipment.data.state}
                                isDraft={isDraft}
                                onCreate={onCreate}
                                onComplete={onComplete}
                                onDetails={onDetails}
                                clickEnabled={clickStatusEnabled}
                            />
                            <ShipmentRowActionsCell
                                shipmentReference={packlinkReference}
                                isDraft={isDraft}
                                isCanceled={isCanceled}
                                onComplete={onComplete}
                                onDetails={onDetails}
                                onDelete={onDeleteDropdown}
                                onPrintLabels={onPrintLabels}
                                canPrintLabels={shipment.actions.canDownloadLabels}
                                isComplete={shipment.actions.canBePurchased}
                                shipmentState={shipment.data.state}
                                hasTrackingDetails={shipmentHasTrackingDetails}
                                onTrackingDetails={onTrackingDetails}
                            />
                        </div>
                        <ShipmentRowFooter>
                            {!isShipmentPending && (
                                <>
                                    <ShipmentRowFooterAction
                                        onClick={duplicateShipment}
                                        icon={IconNames.DUPLICATE}
                                        disabled={isObfuscated}
                                        disabledInfo={t('shipment-row.footer.obfuscated-info')}
                                    >
                                        {t('shipment-row.footer.duplicate-shipment')}
                                    </ShipmentRowFooterAction>
                                    <ShipmentRowFooterAction
                                        isLastActionInFooter={!shipmentHasTrackingAndNoDetails}
                                        onClick={returnShipment}
                                        icon={IconNames.SCAN_BARCODE}
                                        disabled={isObfuscated}
                                        disabledInfo={t('shipment-row.footer.obfuscated-info')}
                                    >
                                        {t('shipment-row.footer.return-shipment')}
                                    </ShipmentRowFooterAction>
                                </>
                            )}

                            {shipmentHasTrackingAndNoDetails && <ShipmentRowTrackings shipment={shipment.data} />}

                            {showLabelManaged && (
                                <ShipmentRowFooterLabel isLabelDownloaded={shipment.data.labelDownloaded} />
                            )}
                        </ShipmentRowFooter>
                    </div>
                </div>
            </div>
            {isTrackingDetailsOpen && (
                <ShipmentRowExtension>
                    <ShipmentRowTrackingDetails shipment={shipment.data} />
                </ShipmentRowExtension>
            )}
        </>
    );
};
