import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { initialState as bulkInitialState, IBulkState, reducer as bulk } from './bulk';
import { initialState as userInitialState, IUserState, reducer as user } from './user';
import { initialState as inboxInitialState, IInboxState, reducer as inbox } from './inbox';
import { initialState as clientInitialState, IClientState, reducer as client } from './client';
import { initialState as statusInitialState, IStatusState, reducer as status } from './status';
import { initialState as paymentInitialState, IPaymentState, reducer as payment } from './payment';
import { initialState as sidePanelInitialState, ISidePanelState, reducer as sidePanel } from './side-panel';
import { initialState as checkoutInitialState, ICheckoutState, reducer as checkout } from './checkout';
import { initialState as customsInitialState, ICustomsState, reducer as customs } from '@Customs/store/reducer/customs';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import {
    initialState as integrationsInitialState,
    IIntegrationsState,
    reducer as integrations,
} from '@Integrations/store/reducers/integrations';

export interface IAppState {
    bulk: IBulkState;
    checkout: ICheckoutState;
    client: IClientState;
    inbox: IInboxState;
    payment: IPaymentState;
    status: IStatusState;
    user: IUserState;
    sidePanel: ISidePanelState;
    integrations: IIntegrationsState;
    customs: ICustomsState;
}

export const initialState: IAppState = {
    bulk: bulkInitialState,
    checkout: checkoutInitialState,
    client: clientInitialState,
    inbox: inboxInitialState,
    payment: paymentInitialState,
    status: statusInitialState,
    user: userInitialState,
    sidePanel: sidePanelInitialState,
    integrations: integrationsInitialState,
    customs: customsInitialState,
};

const paymentPersistConfig = {
    key: 'payment',
    storage,
    whitelist: ['localPaymentFallback'],
};

const bulkPersistConfig = {
    key: 'bulk',
    storage,
    whitelist: ['selectedShipments', 'shipments'],
};

const rootPersistConfig = {
    key: 'root',
    storage,
    whitelist: ['printing', 'sidePanel', 'checkout', 'customs'],
    stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
    bulk: persistReducer(bulkPersistConfig, bulk),
    checkout,
    client,
    inbox,
    payment: persistReducer(paymentPersistConfig, payment),
    status,
    user,
    sidePanel,
    integrations,
    customs,
});

export default persistReducer<ReturnType<typeof rootReducer>>(rootPersistConfig, rootReducer);
