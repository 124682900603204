import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        cursor: 'pointer',
        borderRadius: '50%',
        marginLeft: theme.spacing() / 2,
    });

export const getButtonOpenStyles = (theme: Theme): SerializedStyles =>
    css({
        backgroundColor: theme.toRGBA(theme.palette.white, 0.2),
    });

export const getSortOptionButtonStyles =
    (isSelected: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',

            ':not(:last-child)': {
                marginBottom: theme.spacing(),
            },

            ...(isSelected
                ? {
                      color: theme.palette.secondary.main,
                  }
                : {
                      paddingLeft: theme.iconSize.small,
                  }),
        });

export const getSortOptionTextStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing() / 2,
    });

export const getIconStyles =
    (isSelected: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',

            ...(isSelected && {
                color: theme.palette.success.main,
            }),
        });
