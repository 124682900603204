import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTypographyContainerStyles = (): SerializedStyles => {
    return css({
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
    });
};

export const getClipboardTooltipStyles = (theme: Theme): SerializedStyles => {
    return css({
        marginLeft: theme.spacing(0.5),
    });
};
