import { WithChildrenCommonProps } from '@shipengine/giger';
import {
    getTicketChatMessageWrapperDecorativeLineStyles,
    getTicketChatMessageWrapperStyles,
    getTicketChatMessageWrapperBulletStyles,
    getTicketChatMessageWrapperBulletPositionStyles,
} from './TicketChatMessageWrapperStyles';

export enum TicketChatMessageWrapperBulletPosition {
    TOP = 'TOP',
    CENTER = 'CENTER',
}

type TicketChatMessageWrapperProps = WithChildrenCommonProps<{
    hasDecorativeLine?: boolean;
    bulletPosition?: TicketChatMessageWrapperBulletPosition;
}>;

export function TicketChatMessageWrapper({
    hasDecorativeLine = false,
    bulletPosition = TicketChatMessageWrapperBulletPosition.TOP,
    children,
    ...props
}: TicketChatMessageWrapperProps) {
    const shouldRenderDecorativeLine =
        hasDecorativeLine || bulletPosition !== TicketChatMessageWrapperBulletPosition.TOP;
    return (
        <article
            css={[
                getTicketChatMessageWrapperStyles,
                getTicketChatMessageWrapperBulletStyles,
                getTicketChatMessageWrapperBulletPositionStyles(bulletPosition),
                shouldRenderDecorativeLine && getTicketChatMessageWrapperDecorativeLineStyles(bulletPosition),
            ]}
            {...props}
        >
            {children}
        </article>
    );
}
