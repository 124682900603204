import { IconNames } from '@shipengine/giger-theme';
import { Typography, WithCommonProps } from '@shipengine/giger';
import { SupportTicketMessage } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import DOMPurify from 'dompurify';
import { isEmpty } from 'lodash';
import { SupportTicketAttachmentDetail } from '../SupportTicketAttachmentDetail/SupportTicketAttachmentDetail';
import { SupportTicketSummarySection } from '../SupportTicketSummarySection/SupportTicketSummarySection';
import {
    getZendeskAttachmentWrapperStyles,
    getZendeskAttachmentSectionStyles,
    getZendeskMessageStyles,
} from './ZendeskMessageStyles';

type ZendeskMessageProps = WithCommonProps<{ message: SupportTicketMessage }>;

export function ZendeskMessage({ message, ...props }: ZendeskMessageProps): JSX.Element {
    const { t } = useTranslation();
    const content = DOMPurify.sanitize(message.htmlBody);
    const hasAttachments = !isEmpty(message.attachments);

    return (
        <div {...props}>
            <Typography css={getZendeskMessageStyles} variant="body2">
                <span dangerouslySetInnerHTML={{ __html: content }} />
            </Typography>
            {hasAttachments && (
                <SupportTicketSummarySection
                    title={t('support-center.ticket-chat.zendesk-attached-files-label', {
                        count: message.attachments.length,
                    })}
                    icon={IconNames.ATTACH}
                    css={getZendeskAttachmentSectionStyles}
                >
                    <div css={getZendeskAttachmentWrapperStyles}>
                        {message.attachments.map((attachment) => (
                            <SupportTicketAttachmentDetail key={attachment.id} attachment={attachment} />
                        ))}
                    </div>
                </SupportTicketSummarySection>
            )}
        </div>
    );
}
