import { Form, Formik } from 'formik';
import { WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { SupportCenterRepository } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import {
    createGenericSupportTicketInitialValues,
    GenericSupportTicketFormData,
    parseGenericSupportTicketFormData,
    useGenericTicketFieldsFormValidationSchema,
} from '../CommonFields/GenericSupportTicketFields/GenericSupportTicketFieldsData';
import { SubmitButton } from '../CommonFields/SubmitButton/SubmitButton';
import { GenericSupportTicketFields } from '../CommonFields/GenericSupportTicketFields/GenericSupportTicketFields';
import { NewTicketEventHandlers } from '../../NewTicketData';
import { getSubmitButtonStyles } from '../../styles/FormStyles';

type OtherTicketFormProps = WithCommonProps<NewTicketEventHandlers>;

export function OtherTicketForm({ onSubmit, ...props }: OtherTicketFormProps): JSX.Element {
    const { t } = useTranslation();
    const { validationSchema, initialValues, handleSubmit } = useFormData({ onSubmit });
    return (
        <Formik<GenericSupportTicketFormData>
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
        >
            <Form {...props}>
                <GenericSupportTicketFields
                    title={t('support-center.other-form.reason-hint')}
                    label={t('support-center.other-form.reason-label')}
                    hint={t('support-center.other-form.reason-help')}
                />
                <SubmitButton css={getSubmitButtonStyles} />
            </Form>
        </Formik>
    );
}

function useFormData(eventHandlers: NewTicketEventHandlers) {
    const validationSchema = useGenericTicketFieldsFormValidationSchema();
    const initialValues: GenericSupportTicketFormData = createGenericSupportTicketInitialValues();
    const { handleSubmit } = useOtherTicketFormHandlers(eventHandlers);

    return {
        validationSchema,
        initialValues,
        handleSubmit,
    };
}

function useOtherTicketFormHandlers({ onSubmit }: NewTicketEventHandlers): {
    handleSubmit: (values: GenericSupportTicketFormData) => Promise<void>;
} {
    const supportCenterRepository = new SupportCenterRepository(apiClient);

    const handleSubmit = async (values: GenericSupportTicketFormData) => {
        const ticket = parseGenericSupportTicketFormData(values);
        await onSubmit(() => supportCenterRepository.createOtherSupportTicket(ticket));
    };

    return {
        handleSubmit,
    };
}
