import { useNavigate } from 'react-router';

import { AmplitudeEventsPro } from '@constants/amplitude';
import { ActiveIntegrationImport } from '@Integrations/types/integration';
import { Button, DialogActions, Typography } from '@shipengine/giger';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { APP_ROUTE } from '@pages/router/routes';

import { getImportOrdersResultImageStyles } from '../ImportOrdersModalStyles';
import { useAmplitude } from '@hooks/useAmplitude';

interface ISuspendedAccountErrorContentProps {
    onClose: () => void;
    integration: ActiveIntegrationImport;
}

export const SuspendedAccountErrorContent = ({
    onClose,
    integration,
}: ISuspendedAccountErrorContentProps): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();

    const onGoToIntegration = () => {
        sendSuffixedAmplitudeEvent(
            sendAmplitudeClickEvent,
            integration.alias,
        )(AmplitudeEventsPro.SUSPENDED_ACCOUNT_IMPORT_ORDERS);
        onClose();

        navigate(APP_ROUTE.INTEGRATIONS.ACTIVATE_START_FLOW.replace(':alias', integration.alias), {
            state: { data: integration.configuration?.data },
        });
    };

    return (
        <>
            <img
                src={`${config.cdn.url}/pro/statics/animations/import-orders/goto.gif`}
                alt={''}
                css={getImportOrdersResultImageStyles}
            />
            <Typography component="div">
                <Trans>{t('suspended-import-modal.content.description', { integration: integration.name })}</Trans>
            </Typography>
            <DialogActions>
                <Button onClick={onGoToIntegration} data-id="suspended-error-go-to-button">
                    {t('suspended-import-modal.content.go-to', { integration: integration.name })}
                </Button>
            </DialogActions>
        </>
    );
};
