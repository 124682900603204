import { useField } from 'formik';
import { Input } from '@shipengine/giger';
import { FormField } from '@shipengine/formik-giger';

interface IDoubleTextInputFilterProps {
    firstName: string;
    firstLabel: string;
    secondName: string;
    secondLabel: string;
}

export const DoubleTextInputFilter = (props: IDoubleTextInputFilterProps): JSX.Element => {
    const { firstName, firstLabel, secondName, secondLabel } = props;
    const [firstField, firstMeta] = useField<string>(firstName);
    const [secondField, secondMeta] = useField<string>(secondName);

    return (
        <div>
            <FormField name={firstName}>
                <Input
                    label={firstLabel}
                    value={firstField.value || ''}
                    onChange={firstField.onChange}
                    isInvalid={!!firstMeta.error}
                />
            </FormField>
            <FormField name={secondName}>
                <Input
                    label={secondLabel}
                    value={secondField.value || ''}
                    onChange={secondField.onChange}
                    isInvalid={!!secondMeta.error}
                />
            </FormField>
        </div>
    );
};
