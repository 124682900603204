import { Theme } from '@shipengine/giger-theme';
import { css, SerializedStyles } from '@emotion/react';

export const getWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(3),
    });

export const getTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getListStyles = (theme: Theme): SerializedStyles =>
    css({
        backgroundColor: theme.palette.gray.megaLight,
    });

export const getGridChildrenStyles =
    (textAlign: 'left' | 'right' | 'center') =>
    (theme: Theme): SerializedStyles =>
        css({
            textAlign: textAlign,
            color: theme.palette.gray.dark,
        });
