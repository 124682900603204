import React from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { useSourceShortcode } from '@hooks/useSourceShortcode';
import { ProShipment } from '@packlink/packlink-sdk';
import { ShipmentRowCellEdit } from './ShipmentRowCellEdit';
import { ShipmentRowCell } from '@components/ShipmentList/ShipmentRow/ShipmentRowCell';
import { Tag, TagColor, TagVariant, Typography } from '@shipengine/giger';
import {
    getShipmentRowCustomRefStyles,
    getShipmentRowMetaStyles,
    getShipmentRowSubtitleStyles,
} from './ShipmentRowStyles';

interface IShipmentRowRecipientCell {
    shipment: ProShipment;
    isDraft: boolean;
    onEdit: (e: React.MouseEvent<HTMLParagraphElement>) => void;
}

export const ShipmentRowRecipientCell: React.FC<IShipmentRowRecipientCell> = (
    props: IShipmentRowRecipientCell,
): JSX.Element => {
    const {
        shipment: {
            data: { to, source, shipmentCustomReference },
        },
        isDraft,
        onEdit,
    } = props;

    const { t } = useTranslation();
    const sourceShortcode = useSourceShortcode(source);

    const recipientCellTitle =
        to?.firstName && to?.lastName ? `${to.firstName} ${to.lastName}` : t('shipment-row.recipient.no-recipient');

    const recipientCellSubtitle =
        to?.city && to?.zipCode && to?.alpha2Code
            ? `${to.city} ${to.zipCode} ${to.alpha2Code}`
            : t('shipment-row.recipient.missing-address');

    return (
        <ShipmentRowCell isEditable={isDraft} onEdit={onEdit}>
            <ShipmentRowCellEdit title={recipientCellTitle} isEditable={isDraft} />
            <Typography variant="small" component="p" css={getShipmentRowSubtitleStyles}>
                {recipientCellSubtitle}
            </Typography>
            <Typography variant="small" component="p" css={getShipmentRowMetaStyles}>
                {!!source && (
                    <abbr title={source}>
                        <Tag color={TagColor.BLACK} variant={TagVariant.OUTLINED}>
                            {sourceShortcode}
                        </Tag>
                    </abbr>
                )}
                {shipmentCustomReference && (
                    <span css={getShipmentRowCustomRefStyles} title={shipmentCustomReference}>
                        {shipmentCustomReference}
                    </span>
                )}
            </Typography>
        </ShipmentRowCell>
    );
};
