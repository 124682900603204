import { useSelector } from 'react-redux';
import { getClientIdentifier } from '@store/selectors/client';
import { useInvoiceAvailable } from './hooks/useInvoiceAvailable';
import { usePaymentMethodUpsert } from './hooks/usePaymentMethodUpsert';
import { useShipmentsReport } from './hooks/useShipmentsReport';

/**
 * For now, use this component only as a last resource for events that can be triggered at any moment
 * and independently from where the user is located within the app.
 *
 * Otherwise, choose a place closer to where the logic is used.
 *
 * Caution! this is currently out of ui-view="content" (where we assume that TenantBootstrap is already loaded) in order
 * to have this component loaded in all AngularJS views.
 * This might cause issues if you need something from Tenant or TenantTheme within this component.
 */
export function GlobalEventHandler(): JSX.Element {
    const clientId = useSelector(getClientIdentifier);

    usePaymentMethodUpsert(clientId);
    useInvoiceAvailable(clientId);
    useShipmentsReport(clientId);

    return <></>;
}
