import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSupportTicketSummarySectionStyles = css({
    display: 'flex',
    flexDirection: 'column',
});

export const getSupportTicketSummarySectionIconStyles = (theme: Theme): SerializedStyles =>
    css({
        flex: '0 0 auto',
        marginRight: theme.spacing(),
    });

export const getSupportTicketSummarySectionWrapperStyles = css({
    display: 'flex',
    alignItems: 'center',
});

export const getSupportTicketSummarySectionContentStyles = (theme: Theme): SerializedStyles =>
    css({
        paddingLeft: theme.spacing(3),
    });
