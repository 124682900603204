import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const textConditionStyles =
    (numberConditions: number) =>
    (theme: Theme): SerializedStyles =>
        css({
            textTransform: 'uppercase',
            marginBottom: theme.spacing(4),
            ...(numberConditions > 1 && {
                minWidth: theme.spacing(4),
                justifyContent: 'center',
                textAlign: 'center',
            }),
        });

export const buttonIconStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: 0,
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(4),
    });
