import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getInfoStepHeadingStyles = (theme: Theme): SerializedStyles =>
    css({
        paddingBottom: theme.spacing(),
    });

export const getMeasureTextStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.main,
        marginBottom: 0,
    });

export const getInfoStepSubmitButtonStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'flex-end',
    });
