import { FC } from 'react';

import { IInfoPanelProps, InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';

import { IntegrationGuide } from '../../../IntegrationGuide/IntegrationGuide';

type ZencartIntegrationGuideType = Omit<IInfoPanelProps, 'title' | 'collapsible'>;

export const ZencartIntegrationGuide: FC<ZencartIntegrationGuideType> = ({
    collapsed,
}: ZencartIntegrationGuideType): JSX.Element => {
    const { t } = useTranslation();

    return (
        <IntegrationGuide>
            <InfoPanel title={t('integrations.guide.title')} collapsible={true} collapsed={collapsed}>
                <InfoPanelStepList>
                    <InfoPanelStep title={t('integrations.guide.install-configure-module')}>
                        <Typography component="p">{t('integrations.zencart.step1-text')}</Typography>
                        <ul>
                            <li>{t('integrations.zencart.step1-list-1')}</li>
                            <li>{t('integrations.zencart.step1-list-2')}</li>
                            <li>{t('integrations.zencart.step1-list-3')}</li>
                            <li>{t('integrations.zencart.step1-list-4')}</li>
                            <li>{t('integrations.zencart.step1-list-5')}</li>
                            <li>{t('integrations.zencart.step1-list-6')}</li>
                            <li>{t('integrations.zencart.step1-list-7')}</li>
                        </ul>
                    </InfoPanelStep>
                    <InfoPanelStep title={t('integrations.guide.account-sync')}>
                        <ul>
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.guide.default-information') }} />
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.guide.select-shipping') }} />
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.guide.enter-carrier') }} />
                            <li dangerouslySetInnerHTML={{ __html: t('integrations.guide.configure-price') }} />
                        </ul>
                    </InfoPanelStep>
                    <InfoPanelStep title={t('integrations.guide.use-module')}>
                        <Typography component="p">{t('integrations.guide.access-packlink-pro')}</Typography>
                    </InfoPanelStep>
                </InfoPanelStepList>
            </InfoPanel>
        </IntegrationGuide>
    );
};
