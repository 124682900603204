import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Typography, WithChildrenCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import {
    getCheckoutCollection,
    getCheckoutContent,
    getCheckoutFrom,
    getCheckoutParcels,
    getCheckoutService,
    getCheckoutTo,
} from '@store/selectors/checkout';
import { useSelector } from 'react-redux';
import {
    getShipmentSummaryDataStyles,
    getShipmentSummaryHeadingStyles,
    getShipmentSummaryLabelStyles,
    getShipmentSummaryOwnContractIconStyles,
    getShipmentSummaryParcelListStyles,
    getShipmentSummarySectionStyles,
} from './ShipmentSummaryStyles';
import { useTheme } from '@emotion/react';
import { useMedia } from '@packlink/utils';

export const ShipmentSummary = (): JSX.Element => {
    const from = useSelector(getCheckoutFrom);
    const to = useSelector(getCheckoutTo);
    const parcels = useSelector(getCheckoutParcels);
    const content = useSelector(getCheckoutContent);
    const selectedService = useSelector(getCheckoutService);
    const collection = useSelector(getCheckoutCollection);

    const { t } = useTranslation();
    const theme = useTheme();
    const isDesktop = useMedia(theme.mediaQuery('desktop'));

    return (
        <>
            {isDesktop && (
                <Typography component="h3" variant="heading5" css={getShipmentSummaryHeadingStyles}>
                    {t('checkout.sidebar.summary.title')}
                </Typography>
            )}

            {(from.zipCode || to.zipCode) && (
                <SummarySection>
                    {from.zipCode && (
                        <>
                            <SummaryLabel>{t('checkout.sidebar.summary.from')}</SummaryLabel>
                            <SummaryData>{`${from.zipCode} - ${from.city}, ${from.state}`}</SummaryData>
                        </>
                    )}

                    {to.zipCode && (
                        <>
                            <SummaryLabel>{t('checkout.sidebar.summary.to')}</SummaryLabel>
                            <SummaryData>{`${to.zipCode} - ${to.city}, ${to.state}`}</SummaryData>
                        </>
                    )}
                </SummarySection>
            )}

            {parcels.length > 0 && (
                <SummarySection>
                    <SummaryLabel>{t('checkout.sidebar.summary.parcels')}</SummaryLabel>

                    <ul css={getShipmentSummaryParcelListStyles}>
                        {parcels.map((parcel) => (
                            <Typography
                                component="li"
                                variant="body2"
                                css={getShipmentSummaryDataStyles}
                                key={parcel.id}
                            >
                                {`${parcel.weight || 0} kg, ${parcel.length || 0} x ${parcel.width || 0} x ${
                                    parcel.height || 0
                                } cm`}
                            </Typography>
                        ))}
                    </ul>

                    {content?.description && (
                        <>
                            <SummaryLabel>{t('checkout.sidebar.summary.content')}</SummaryLabel>
                            <SummaryData>{content.description}</SummaryData>
                        </>
                    )}
                </SummarySection>
            )}

            {selectedService && (
                <SummarySection>
                    <SummaryLabel>{t('checkout.sidebar.summary.service')}</SummaryLabel>

                    {selectedService.isOwnContract && (
                        <SummaryData>{t('checkout.sidebar.summary.contract')}</SummaryData>
                    )}

                    <SummaryData>
                        <>
                            {selectedService.isOwnContract && (
                                <Icon
                                    name={IconNames.CONTRACT}
                                    size={IconSize.SIZE_SMALL}
                                    css={getShipmentSummaryOwnContractIconStyles}
                                />
                            )}
                            {`${selectedService.carrierName} - ${selectedService.name}`}
                        </>
                    </SummaryData>

                    {!selectedService.isOwnContract && collection?.date && (
                        <>
                            <SummaryLabel>{t('checkout.sidebar.summary.pick-up-date')}</SummaryLabel>

                            <SummaryData>
                                <>
                                    <time>{collection?.date?.format('dddd D MMMM')}</time>
                                    <time> {collection?.interval?.getTimeStrip()} </time>
                                </>
                            </SummaryData>
                        </>
                    )}
                </SummarySection>
            )}
        </>
    );
};

const SummarySection = ({ children }: WithChildrenCommonProps<unknown>): JSX.Element => {
    return <section css={getShipmentSummarySectionStyles}>{children}</section>;
};

const SummaryLabel = ({ children }: WithChildrenCommonProps<unknown>): JSX.Element => {
    return (
        <Typography component="h4" variant="small" css={getShipmentSummaryLabelStyles} bold>
            {children}
        </Typography>
    );
};

const SummaryData = ({ children }: WithChildrenCommonProps<unknown>): JSX.Element => {
    return (
        <Typography component="p" variant="body2" css={getShipmentSummaryDataStyles}>
            {children}
        </Typography>
    );
};
