import { useRef } from 'react';
import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Tooltip, useTriggeredTooltip } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

import { ClipboardCopier } from '@components/Clipboard/ClipboardCopier';

interface IClipboardTooltip {
    valueToCopy: string;
    onClick?: () => void;
}
export const ClipboardTooltip = ({ valueToCopy, onClick }: IClipboardTooltip): JSX.Element => {
    const { triggerProps, opened, triggered, onClickOutside } = useTriggeredTooltip();
    const tooltipRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    return (
        <>
            <div ref={tooltipRef} {...triggerProps}>
                <ClipboardCopier value={valueToCopy} onClick={onClick} aria-describedby="clipboard-tooltip">
                    <Icon name={IconNames.COPY} size={IconSize.SIZE_SMALL} />
                </ClipboardCopier>
            </div>
            <Tooltip id="clipboard-tooltip" reference={tooltipRef.current} isOpen={opened} onClickAway={onClickOutside}>
                <span>
                    {triggered ? t('shipment-row.footer.copied-tracking') : t('shipment-row.footer.copy-tracking')}
                </span>
            </Tooltip>
        </>
    );
};
