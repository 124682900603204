import { Button, ButtonColor, ButtonSize, ButtonVariant, IButtonExtendProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

type CancelShipmentButtonProps = IButtonExtendProps;

export function CancelShipmentButton(props: CancelShipmentButtonProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <Button
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.SMALL}
            color={ButtonColor.PRIMARY}
            isFullWidth
            {...props}
        >
            {t('post-sale.details.cancel-shipment')}
        </Button>
    );
}
