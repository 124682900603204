import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import BluebirdPromise from 'bluebird';
import { IPrice, IShipment, ProPrice, ProShipment } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';
import { UpsellInsuranceType, UpsellServiceType } from '@types';
import { PriceUtils } from '@utils/PriceUtils';
import { IProductOptions, UpsellUtils } from '@utils/UpsellUtils';
import { MAX_DECLARED_VALUE } from '@constants/content';

import { ShipmentPanelFooter } from '../ShipmentPanelFooter';
import { OtherBenefitsInsurance } from './OtherBenefitsInsurance';
import { OtherBenefitsServices } from './OtherBenefitsServices';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { shipmentPanelFormStyles, spinnerStyles } from '../ShipmentPanelStyles';
import { Spinner } from '@shipengine/giger';

export interface IOtherBenefitsForm {
    contentValue?: number;
    insurance: UpsellInsuranceType;
    services: UpsellServiceType[];
}

export const ShipmentPanelOtherBenefits: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const [availableOptions, setAvailableOptions] = useState<IProductOptions>();
    const [priceDetails, setPriceDetails] = useState<IPrice>();
    const priceRequest = useRef<BluebirdPromise<ProPrice | undefined> | null>(null);
    const { panelState, closePanel, updateShipment, exitPanel } = useShipmentPanel();
    const [showShippingRulesPromo, setShowShippingRulesPromo] = useState(false);
    const showAdditionalInsurance = true; // TODO https://auctane.atlassian.net/browse/PCK-5210
    // Need useMemo because are used in useCallback as dependency
    const { actions: shipmentActions, data: shipmentData } = useMemo(
        () => panelState.shipment as ProShipment,
        [panelState],
    );
    const shipmentDataJSON = useMemo(() => shipmentData.toJSON(), [shipmentData]);

    const shipmentServices = useMemo(
        (): UpsellServiceType[] => UpsellUtils.getShipmentServices(shipmentData),
        [shipmentData],
    );

    const initialFormValues = useMemo((): IOtherBenefitsForm => {
        return {
            insurance: UpsellUtils.getDefaultInsuranceType(shipmentData, priceDetails?.availableProducts?.insurance),
            services: shipmentServices,
        };
    }, [priceDetails, shipmentData, shipmentServices]);

    const validationSchema = yup.object<IOtherBenefitsForm>().shape({
        insurance: yup.string().required(),
        services: yup.array().of(yup.string()),
        contentValue: yup
            .number()
            .lessThan(MAX_DECLARED_VALUE, t('content-form.error.max', { field: t('content-panel.content.value') })),
    });

    const getPrice = useCallback(
        (shipmentContentValue: number): void => {
            if (priceRequest.current?.isPending()) {
                priceRequest.current.cancel();
            }

            priceRequest.current = PriceUtils.getPriceDetails(shipmentDataJSON, shipmentContentValue);

            priceRequest.current.then((response: ProPrice | undefined): void => {
                if (!response) {
                    return;
                }

                const shipmentPriceDetails = response.shipmentsPriceDetails[0];
                setPriceDetails(shipmentPriceDetails);
                setAvailableOptions(
                    UpsellUtils.computeAvailableProducts(
                        shipmentPriceDetails,
                        shipmentActions.canSelectPrintInStore,
                        shipmentDataJSON.content?.value ?? 0,
                        shipmentData,
                        showAdditionalInsurance,
                    ),
                );
            });
        },
        [shipmentActions.canSelectPrintInStore, shipmentData, shipmentDataJSON, showAdditionalInsurance],
    );

    useEffect((): void => {
        getPrice(shipmentData.content?.value || 0);
    }, [shipmentData, getPrice]);

    const submitForm = useCallback(
        (_values: IOtherBenefitsForm): void => {
            const { insurance, services } = _values;
            const hasInsurance = [UpsellInsuranceType.PACKLINK_INSURANCE, UpsellInsuranceType.ENHANCED].includes(
                insurance,
            );
            const insurancePrice = hasInsurance ? priceDetails?.availableProducts?.insurance : null;
            const isProofOfDeliveryAvailable = !!priceDetails?.availableProducts?.proofOfDelivery;

            const hasProofOfDelivery = [UpsellInsuranceType.ENHANCED].includes(insurance) && isProofOfDeliveryAvailable;

            const newShipmentData: IShipment = {
                ...shipmentDataJSON,
                upsales: {
                    ...shipmentDataJSON.upsales,
                    insurance: {
                        amount: hasInsurance ? insurancePrice?.insured : 0,
                        available: hasInsurance,
                    },
                    proofOfDelivery: {
                        available: hasProofOfDelivery,
                    },
                    printInStore: {
                        available: services.includes(UpsellServiceType.PRINT_IN_STORE),
                    },
                    adultSignature: {
                        available: services.includes(UpsellServiceType.ADULT_SIGNATURE),
                    },
                    additionalHandling: {
                        available: services.includes(UpsellServiceType.ADDITIONAL_HANDLING),
                    },
                },
            };

            updateShipment(newShipmentData).then(() => {
                hasInsurance ? setShowShippingRulesPromo(true) : closePanel(false);
            });
        },
        [closePanel, priceDetails, shipmentDataJSON, updateShipment],
    );

    const handleChangeValue = (value: number): void => {
        getPrice(value);
    };

    const renderServices = (): React.ReactNode => {
        // We need to filter out the COD service until it is implemented for the side-panel
        // Filter Proof Of Delivery
        const { CASH_ON_DELIVERY, PROOF_OF_DELIVERY, ...services } = availableOptions?.services ?? {};
        return availableOptions && Object.keys(services).length > 0 ? (
            <OtherBenefitsServices services={services} />
        ) : null;
    };

    const renderForm = (formProps: FormikProps<IOtherBenefitsForm>): React.ReactNode => {
        const { isValid } = formProps;

        return (
            <Form css={shipmentPanelFormStyles()}>
                <SidePanelHeader onAction={closePanel} title={t(`shipment-list-header.menu.other-benefits`)} />
                {!availableOptions && <Spinner css={spinnerStyles} />}
                {availableOptions?.insurances && (
                    <SidePanelContent>
                        <OtherBenefitsInsurance
                            contentValue={shipmentData.content?.value}
                            currency={shipmentData.currency}
                            insurances={availableOptions.insurances}
                            onChangeValue={handleChangeValue}
                            isSecondHand={shipmentDataJSON.content.secondHand}
                            hasProofOfDelivery={!!priceDetails?.availableProducts?.proofOfDelivery}
                        />

                        {renderServices()}
                    </SidePanelContent>
                )}

                <ShipmentPanelFooter isSaveDisabled={!isValid} onExit={exitPanel} />
            </Form>
        );
    };

    return showShippingRulesPromo ? (
        <>
            <SidePanelHeader onAction={closePanel} title={t(`shipment-list-header.menu.other-benefits`)} />
            <SidePanelContent>
                <SidePanelMessage
                    type={SidePanelMessageType.SUCCESS}
                    title={t('shipment-panel.shipments.updated')}
                    showShippingRulesPromo={showShippingRulesPromo}
                />
            </SidePanelContent>
        </>
    ) : (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            enableReinitialize
            validateOnMount
            onSubmit={submitForm}
        >
            {(formProps: FormikProps<IOtherBenefitsForm>): React.ReactNode => renderForm(formProps)}
        </Formik>
    );
};
