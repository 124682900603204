import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getParcelsNotificationStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        color: theme.palette.primary.main,
    });

export const getParcelsNotificationTextStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: 0,
        marginLeft: theme.spacing(),
    });

export const getAddParcelButtonStyles =
    (showUnderDeclareDisclaimer: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: showUnderDeclareDisclaimer ? theme.spacing(2) : 0,
            button: {
                padding: 0,
            },
        });
