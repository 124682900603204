import { ChangeEvent } from 'react';
import { IAddress } from '@packlink/packlink-sdk';
import { Radio, Typography } from '@shipengine/giger';
import {
    getAddressContentStyles,
    getAddressFooterStyles,
    getAddressHeaderStyles,
    getAddressStyles,
    getAddressTextAddressStyles,
    getAddressTextStyles,
    getAddressTitleStyles,
} from './AddressStyles';

interface IAddressProps {
    address: IAddress;
    isSelected?: boolean;
    onClick: (event: IAddress) => void;
}

export function Address({ address, onClick, isSelected = false }: IAddressProps): JSX.Element {
    const handleOnChange = ({ target: value }: ChangeEvent): void => {
        onClick(value && address);
    };
    return (
        <article onClick={() => onClick(address)} css={getAddressStyles(isSelected)}>
            <div css={getAddressContentStyles}>
                <header css={getAddressHeaderStyles}>
                    <Typography component="h3" variant="body1" css={getAddressTitleStyles} bold>
                        {address.firstName} {address.lastName}
                    </Typography>
                </header>
                <div css={getAddressTextStyles}>
                    <Typography variant="small">
                        <div>
                            <span css={getAddressTextAddressStyles}>{address.street1}</span>
                            <span>
                                {address.zipCode} {address.city}
                            </span>
                            <span> ({address.alpha2Code})</span>
                            <span css={getAddressTextAddressStyles}>{address.phone}</span>
                        </div>
                    </Typography>
                </div>
            </div>
            <footer css={getAddressFooterStyles}>
                <Radio checked={isSelected} value={address?.id} onChange={handleOnChange}></Radio>
            </footer>
        </article>
    );
}
