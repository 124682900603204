import React from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { IconNames, Theme } from '@shipengine/giger-theme';
import { AppHeaderMenuItem } from './AppHeaderMenuItem';
import { useAmplitude } from '@hooks/useAmplitude';
import { getViewAmplitudePayload } from '@hooks/useAmplitude';
import { AmplitudeEvents } from '@constants/amplitude';
import { getAppHeaderMenuDividerStyles, getAppHeaderMenuStyles } from './AppHeaderMenuStyles';
import { useIsInSupportCenter } from '@hooks/useIsInSupportCenter';
import { Interpolation } from '@emotion/react';
import { useGetHelpUrl } from '@SupportCenter/hooks/useGetHelpUrl';

export interface IAppHeaderMenuProps {
    onClickSettings: () => void;
    rootCss?: Interpolation<Theme>;
    itemCss?: Interpolation<Theme>;
    textCss?: Interpolation<Theme>;
}

export const AppHeaderMenu: React.FC<IAppHeaderMenuProps> = ({
    onClickSettings,
    rootCss,
    itemCss,
    textCss,
}: IAppHeaderMenuProps): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();

    const onSupportClick = (): void =>
        sendAmplitudeEvent(AmplitudeEvents.CONTACT_SUPPORT, getViewAmplitudePayload(locale));

    const isInSupportCenter = useIsInSupportCenter();
    const isHelpVisible = !isInSupportCenter;
    const helpUrl = useGetHelpUrl({});

    return (
        <section css={[getAppHeaderMenuStyles, rootCss]}>
            <AppHeaderMenuItem
                icon={IconNames.SETTINGS}
                label={t('menu.options.settings')}
                onClick={onClickSettings}
                rootCss={itemCss}
                textCss={textCss}
            />

            {isHelpVisible && (
                <>
                    <div css={getAppHeaderMenuDividerStyles} />

                    <AppHeaderMenuItem
                        icon={IconNames.HELP}
                        url={helpUrl}
                        target={'_blank'}
                        onClick={onSupportClick}
                        label={t('menu.options.help-and-support')}
                        rootCss={itemCss}
                        textCss={textCss}
                    />
                </>
            )}
        </section>
    );
};
