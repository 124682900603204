import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BraintreeError } from 'braintree-web';
import { useTranslation } from '@packlink/translation-provider';
import { PaymentMethod } from '@packlink/packlink-sdk';
import { PaymentStep } from '@packlink/payment-options';
import { AppDispatch } from '@store';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { savePaymentMethod } from '@store/actions/payment';
import { useCreateClientSubscription } from '../../../domains/subscriptions/hooks/useCreateClientSubscription';
import { useAmplitude } from '@hooks/useAmplitude';
import { SubscriptionPaymentOptions, onSaveProps } from './SubscriptionPaymentOptions/SubscriptionPaymentOptions';

interface SubscriptionPanelPaymentProps {
    planCode: string;
    onSuccess: (method: PaymentMethod, planCode: string) => void;
    onError: (translationKey?: string) => void;
}

export function SubscriptionPanelPayment({ planCode, onSuccess, onError }: SubscriptionPanelPaymentProps): JSX.Element {
    const { t } = useTranslation();
    const [isPaying, setIsPaying] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const { createClientSubscription } = useCreateClientSubscription();

    const createSubscription = (paymentMethodId: string, paymentMethod: PaymentMethod) => {
        createClientSubscription(
            { paymentMethodId, planCode },
            {
                onSuccess: () => onSuccess(paymentMethod, planCode),
                onError: () => onError('error.payment.setup'),
                onSettled: () => setIsPaying(false),
            },
        );
    };

    const handlePayment = ({ method, nonce, type }: onSaveProps) => {
        onError(undefined);
        setIsPaying(true);

        if (nonce) {
            dispatch(savePaymentMethod(type, method, nonce))
                .then(({ id }: { id: string }) => createSubscription(id, method))
                .catch((err) => {
                    onError(err.message);
                    setIsPaying(false);
                });
        } else {
            onError('error.payment.verification');
            setIsPaying(false);
        }
    };

    const sendEvent = (method: PaymentMethod) => {
        sendAmplitudeClickEvent(AmplitudeEvents.SUBSCRIPTION_UPGRADE_NOW, {
            [AmplitudeProperties.PAYMENT_METHOD]: method,
        });
    };

    const handlePaymentError = (
        _step: PaymentStep,
        _paymentMethod?: PaymentMethod,
        _error?: BraintreeError | Error,
        translationKey?: string,
    ) => {
        onError(translationKey);
        setIsPaying(false);
    };

    return (
        <SubscriptionPaymentOptions
            isProcessing={isPaying}
            section="subscription"
            payButtonTexts={{
                paypal: t('subscriptions.button.payment'),
                creditCard: t('subscriptions.button.payment'),
            }}
            onSave={handlePayment}
            onError={handlePaymentError}
            onStart={sendEvent}
        />
    );
}
