import { useTranslation } from '@packlink/translation-provider';
import { Grid, GridChild } from '@shipengine/giger';
import { ChartContainerSkeleton } from '../../components/Charts/components/Layout/ChartContainerSkeleton';
import { DatePicker } from '../../components/DatePicker/DatePicker';

export function AnalyticsSkeleton(): JSX.Element {
    const { t } = useTranslation();
    return (
        <>
            <DatePicker
                onChange={() => {
                    /* Not needed */
                }}
            />

            <Grid noPadding data-id="analytics-skeleton">
                <GridChild colSpan={12} colSpanTablet={6}>
                    <ChartContainerSkeleton title={t('analytics.chart.shipments-over-time')} />
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <ChartContainerSkeleton title={t('analytics.chart.total-shipping-cost')} />
                </GridChild>
                <GridChild colSpan={12}>
                    <ChartContainerSkeleton title={t('analytics.chart.shipments-by-carrier')} />
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <ChartContainerSkeleton title={t('analytics.chart.shipments-per-country')} />
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <ChartContainerSkeleton title={t('analytics.chart.shipments-per-content')} />
                </GridChild>
            </Grid>
        </>
    );
}
