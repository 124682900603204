export interface IShippingRulesConfig {
    conditions: IShippingRulesItemConfig[];
    actions: IShippingRulesItemConfig[];
}

export enum ShippingRuleInputType {
    NONE = 'none',
    TEXT = 'text',
    NUMBER = 'number',
    POSTALZONE = 'postalzone-selector',
    SERVICE = 'service-selector',
    SOURCE = 'source-selector',
    WAREHOUSE = 'warehouse-selector',
    PARCEL = 'parcel-selector',
}
export interface IShippingRulesItemConfig {
    identifier: string;
    operators: string[];
    type: string;
    inputType?: ShippingRuleInputType;
    step?: number;
    defaultValue?: unknown;
}
