import * as yup from 'yup';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Form, Formik, FormikProps } from 'formik';
import omitBy from 'lodash.omitby';
import isNil from 'lodash.isnil';
import qs from 'qs';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { Inbox, ShipmentSource } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { Icon, IconSize, SidePanel, SlideInPosition, Typography } from '@shipengine/giger';

import Packlink from '@sdk';

import { getBulkFilters, getBulkFiltersFromPanel } from '@store/selectors/bulk';
import { getCurrentInbox, getIsCurrentInboxUnderTracking } from '@store/selectors/inbox';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';

import { ITenantConfig, PanelName } from '@types';
import { useSidePanel } from '@hooks/useSidePanel';
import { useCurrentInbox } from '@hooks/useCurrentInbox';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { FILTER_PARAMS } from '@constants/shipmentFilters';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';
import { inboxToRoute } from '@pages/router/utils/paths';

import { AutocompleteFilter } from './filters/AutocompleteFilter';
import { DatesFilter } from './filters/DatesFilter';
import { DoubleSwitchFilter } from './filters/DoubleSwitchFilter';
import { DoubleTextInputFilter } from './filters/DoubleTextInputFilter';
import { ShipmentFilter } from './filters/ShipmentFilter';
import { TextInputFilter } from './filters/TextInputFilter';

import { IFiltersForm, IOptionDefaultSchema } from './types';

import {
    getShipmentFiltersPanelContentStyles,
    getShipmentFiltersPanelFormStyles,
    getShipmentFiltersPanelInboxStyles,
    getShipmentFiltersPanelInboxTextStyles,
} from './ShipmentFiltersStyles';
import { useAmplitude } from '@hooks/useAmplitude';

export const ShipmentFiltersPanel = (): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();
    const navigate = useNavigate();

    const { close, isOpen } = useSidePanel(PanelName.FILTERS);

    const inbox = useSelector(getCurrentInbox);
    const filters = useSelector(getBulkFilters);
    const panelFilters = useSelector(getBulkFiltersFromPanel);

    const initialFormValues = useMemo((): IFiltersForm => {
        return panelFilters || {};
    }, [panelFilters]);

    const validationSchema = useMemo((): yup.ObjectSchema<IFiltersForm | undefined> => {
        return yup.object<IFiltersForm>().shape({
            [FILTER_PARAMS.CREATED_AT_FROM]: yup.string(),
            [FILTER_PARAMS.CREATED_AT_TO]: yup.string(),
            [FILTER_PARAMS.SOURCE]: yup.array<string>(),
            [FILTER_PARAMS.LABEL_DOWNLOADED]: yup.bool(),
            [FILTER_PARAMS.CARRIER_NAME]: yup.array<string>(),
            [FILTER_PARAMS.SHIPMENT_CUSTOM_REFERENCE]: yup
                .string()
                .trim()
                .max(60, t('form.error.max', { field: t('filters.panel.order-id'), number: 60 })),
            [FILTER_PARAMS.RECIPIENT_NAME]: yup
                .string()
                .trim()
                .max(60, t('form.error.max', { field: t('filters.panel.recipient-name'), number: 60 })),
            [FILTER_PARAMS.RECIPIENT_SURNAME]: yup
                .string()
                .trim()
                .max(60, t('form.error.max', { field: t('filters.panel.recipient-surname'), number: 60 })),
        });
    }, [t]);

    const applyFilters = (values: IFiltersForm) => {
        const allFilters = { ...(!!filters?.q && { q: filters.q }), ...values };
        const activeFilters = omitBy(allFilters, (value) => isNil(value) || value === '');

        navigate({
            pathname: inboxToRoute(inbox),
            search: qs.stringify(activeFilters, { arrayFormat: 'brackets', encode: false }),
        });

        sendAmplitudeSidebarClickEvent(AmplitudeEvents.APPLY_FILTERS, {
            [AmplitudeProperties.FILTER_TYPE]: Object.keys(values),
        });

        close();
    };

    return (
        <SidePanel
            css={getSidePanelClipPathStyles(SlideInPosition.RIGHT)}
            isOpen={isOpen}
            applyBackdropToBody={false}
            onBackdropClick={close}
            containerId="shipments-main"
        >
            {isOpen && (
                <Formik
                    initialValues={initialFormValues}
                    validationSchema={validationSchema}
                    validateOnMount={true}
                    onSubmit={applyFilters}
                >
                    {(formProps: FormikProps<IFiltersForm>): React.ReactNode => <PanelContent {...formProps} />}
                </Formik>
            )}
        </SidePanel>
    );
};

function parsePlainArrayToOptions(options: string[]): IOptionDefaultSchema[] {
    return options.map((name) => ({ label: name, value: name }));
}

function PanelContent({ isValid, initialValues, values }: FormikProps<IFiltersForm>): JSX.Element {
    const { t } = useTranslation();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const { close } = useSidePanel(PanelName.FILTERS);
    const { showLabelsFilter } = useTenantConfig<ITenantConfig>();
    const { inbox, inboxIconName, inboxTranslation } = useCurrentInbox();

    const [sources, setSources] = useState<ShipmentSource[]>();
    const [carrierNames, setCarriers] = useState<IOptionDefaultSchema[]>();
    const isCurrentInboxUnderTracking = useSelector(getIsCurrentInboxUnderTracking);

    const isCreationDateActive = !!(
        initialValues[FILTER_PARAMS.CREATED_AT_FROM] || initialValues[FILTER_PARAMS.CREATED_AT_TO]
    );

    const isDeliveryDateActive = !!(
        initialValues[FILTER_PARAMS.DELIVERY_DATE_FROM] || initialValues[FILTER_PARAMS.DELIVERY_DATE_TO]
    );

    const isRecipientActive = !!(
        initialValues[FILTER_PARAMS.RECIPIENT_NAME] || initialValues[FILTER_PARAMS.RECIPIENT_SURNAME]
    );

    const showLabelStatusFilter = showLabelsFilter && (inbox === Inbox.READY_FOR_SHIPPING || inbox === Inbox.ALL);
    const showDeliveryDateFilter = isCurrentInboxUnderTracking || [Inbox.READY_FOR_SHIPPING, Inbox.ALL].includes(inbox);

    useEffectOnce(() => {
        if (sources) return;
        Packlink.v1.shipments.getShipmentsSources(inbox).then(setSources);
    });

    useEffectOnce(() => {
        if (carrierNames) return;
        Packlink.v1.shipments.getShipmentsCarrierNames(inbox).then(parsePlainArrayToOptions).then(setCarriers);
    });

    const handleClose = () => {
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.X, {
            [AmplitudeProperties.PANEL_NAME]: PanelName.FILTERS,
        });
        close();
    };

    const isFormValid = useMemo(() => {
        return (
            isValid &&
            // The form is only valid (which means we can apply filters)
            // if some of the filters have either value or initial value, even if the value is falsy
            !!(Object.values(initialValues).length || Object.values(values).length)
        );
    }, [isValid, initialValues, values]);

    return (
        <Form css={getShipmentFiltersPanelFormStyles}>
            <SidePanelHeader onAction={handleClose} title={t('filters.panel.header')} />
            <SidePanelContent css={getShipmentFiltersPanelContentStyles}>
                <div css={getShipmentFiltersPanelInboxStyles}>
                    <Icon name={inboxIconName} size={IconSize.SIZE_SMALL} />{' '}
                    <Typography variant="body2" css={getShipmentFiltersPanelInboxTextStyles}>
                        {t('filters.panel.inbox-text', { status: inboxTranslation })}
                    </Typography>
                </div>

                <ShipmentFilter
                    title={t('filters.panel.created-at')}
                    isActive={isCreationDateActive}
                    deleteFilters={[FILTER_PARAMS.CREATED_AT_FROM, FILTER_PARAMS.CREATED_AT_TO]}
                    infoMessage={inbox === Inbox.ALL ? 'filters.panel.archived-info' : undefined}
                >
                    <DatesFilter name="created_at" />
                </ShipmentFilter>

                {showDeliveryDateFilter && (
                    <ShipmentFilter
                        title={t('filters.panel.delivery-date')}
                        isActive={isDeliveryDateActive}
                        deleteFilters={[FILTER_PARAMS.DELIVERY_DATE_FROM, FILTER_PARAMS.DELIVERY_DATE_TO]}
                    >
                        <DatesFilter name="delivery_date" />
                    </ShipmentFilter>
                )}

                <ShipmentFilter
                    title={t('filters.panel.recipient')}
                    isActive={isRecipientActive}
                    deleteFilters={[FILTER_PARAMS.RECIPIENT_NAME, FILTER_PARAMS.RECIPIENT_SURNAME]}
                    infoMessage={'filters.panel.recipient-info'}
                >
                    <DoubleTextInputFilter
                        firstName={FILTER_PARAMS.RECIPIENT_NAME}
                        firstLabel={t('filters.panel.recipient-name')}
                        secondName={FILTER_PARAMS.RECIPIENT_SURNAME}
                        secondLabel={t('filters.panel.recipient-surname')}
                    />
                </ShipmentFilter>

                <ShipmentFilter
                    title={t('filters.panel.carrier')}
                    isActive={!!initialValues[FILTER_PARAMS.CARRIER_NAME]}
                >
                    <AutocompleteFilter
                        name={FILTER_PARAMS.CARRIER_NAME}
                        label={t('filters.panel.carrier')}
                        options={carrierNames}
                    />
                </ShipmentFilter>

                {showLabelStatusFilter && (
                    <ShipmentFilter
                        title={t('filters.panel.label-status')}
                        isActive={typeof initialValues[FILTER_PARAMS.LABEL_DOWNLOADED] === 'boolean'}
                        deleteFilters={[FILTER_PARAMS.LABEL_DOWNLOADED]}
                        infoMessage={'filters.panel.managed-info'}
                    >
                        <DoubleSwitchFilter
                            name={FILTER_PARAMS.LABEL_DOWNLOADED}
                            firstSwitchText={t('filters.panel.unmanaged-labels')}
                            secondSwitchText={t('filters.panel.managed-labels')}
                            isFirstTrueSwitch={false}
                        />
                    </ShipmentFilter>
                )}

                <ShipmentFilter title={t('filters.panel.source')} isActive={!!initialValues[FILTER_PARAMS.SOURCE]}>
                    <AutocompleteFilter
                        name={FILTER_PARAMS.SOURCE}
                        label={t('filters.panel.source-label')}
                        options={sources}
                        labelKey="name"
                        valueKey="alias"
                    />
                </ShipmentFilter>

                <ShipmentFilter
                    title={t('filters.panel.order-id')}
                    isActive={!!initialValues[FILTER_PARAMS.SHIPMENT_CUSTOM_REFERENCE]}
                    deleteFilters={[FILTER_PARAMS.SHIPMENT_CUSTOM_REFERENCE]}
                >
                    <TextInputFilter
                        name={FILTER_PARAMS.SHIPMENT_CUSTOM_REFERENCE}
                        label={t('filters.panel.order-id')}
                    />
                </ShipmentFilter>
            </SidePanelContent>

            <SidePanelFooter>
                <SidePanelFooterActions
                    actionButtonType="submit"
                    onCancel={close}
                    actionText={t('filters.panel.apply')}
                    cancelText={t('filters.panel.cancel')}
                    disabledAction={!isFormValid}
                />
            </SidePanelFooter>
        </Form>
    );
}
