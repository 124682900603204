import { useTranslation } from '@packlink/translation-provider';
import { useEvent } from '@packlink/event-handler';
import {
    SupportTicketUploadAttachmentWebSocketResponse,
    SUPPORT_TICKET_ATTACHMENT_UPLOAD_EVENT_NAME,
} from '@packlink/packlink-sdk';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { useSelector } from 'react-redux';
import { getClientIdentifier } from '@store/selectors/client';

import * as yup from 'yup';

export const TEMPORAL_UPLOAD_ID = '-1';
export const EMPTY_FILE_UPLOAD_ID = '';
export const DEFAULT_UPLOAD_TIMEOUT = 10000;

export class SupportCenterAttachment {
    constructor(
        public file: File,
        public uploadId: string,
    ) {
        this.file = file;
        this.uploadId = uploadId;
    }
}

type UploadAttachmentValidationSchemaOptions = {
    required?: string;
};

export function getUploadAttachmentValidationSchema(
    options?: UploadAttachmentValidationSchemaOptions,
): yup.MixedSchema<SupportCenterAttachment | undefined> {
    let schema = yup.mixed<SupportCenterAttachment | undefined>();

    if (options?.required) {
        schema = schema.required(options.required);
    }

    schema = schema.test('isUploading', 'Uploading', (fileData: SupportCenterAttachment | undefined | unknown) => {
        return !fileData || (fileData instanceof SupportCenterAttachment && fileData?.uploadId !== TEMPORAL_UPLOAD_ID);
    });

    return schema;
}

export function getUploadMultipleAttachmentValidationSchema(
    options?: UploadAttachmentValidationSchemaOptions,
): yup.MixedSchema<SupportCenterAttachment> {
    let baseSchema = yup
        .mixed<SupportCenterAttachment>()
        .test(
            'isUploading',
            'Uploading',
            (filesData: SupportCenterAttachment[] | unknown) =>
                filesData instanceof Array && !filesData.some(({ uploadId }) => uploadId === TEMPORAL_UPLOAD_ID),
        );

    if (options?.required) {
        baseSchema = baseSchema.required(options.required);
    }

    return baseSchema;
}

const MAX_MB_FILE_SIZE = 10;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'application/pdf'];

export function useUploadAttachmentFileValidationSchema(): yup.MixedSchema<string, File> {
    const { t } = useTranslation();
    return yup
        .mixed<string, File>()
        .test(
            'fileSize',
            t('support-center.new-ticket.attachment-file-size-error'),
            (value: File | unknown) => value instanceof File && fileSizeToMb(value.size) <= MAX_MB_FILE_SIZE,
        )
        .test(
            'fileType',
            t('support-center.new-ticket.attachment-file-type-error'),
            (value: File | unknown) => value instanceof File && SUPPORTED_FORMATS.includes(value.type),
        );
}

function fileSizeToMb(size: number) {
    return size / 1024 / 1024;
}

// Configure WebSocket config to listen to upload confirmation events
export function useUploadConfirmationEvent() {
    const clientId = useSelector(getClientIdentifier);
    return useEvent<SupportTicketUploadAttachmentWebSocketResponse>(
        clientId,
        SUPPORT_TICKET_ATTACHMENT_UPLOAD_EVENT_NAME,
    );
}

// Creates a function that handles the SDK request to upload an attachment and its confirmation via websockets
export function useUploadRequest(uploadTimeout: number) {
    const sdk = useSdk();
    const { eventBind } = useUploadConfirmationEvent();

    return async (file: File) => {
        // Async call to support center
        const pendingUploadId = await sdk.v1.supportCenter.uploadAttachment(file);

        const waitForEventConfirmation = new Promise<string>((resolve, reject) => {
            const errorTimeout = setTimeout(
                () => reject(new Error('No confirmation received for upload attachment')),
                uploadTimeout,
            );

            // Event binding should be prepared before SDK call to avoid race conditions
            eventBind((data: SupportTicketUploadAttachmentWebSocketResponse) => {
                if (pendingUploadId && pendingUploadId === data?.uploadId) {
                    resolve(data.id);
                    clearTimeout(errorTimeout);
                }
            });
        });

        return waitForEventConfirmation;
    };
}
