import { TFunction } from '@packlink/translation-provider';
import { API_ERRORS_MAPPING, API_ERRORS_MAPPING_KEYS } from '@constants/apiErrors';
import { camelToSnakeUpperCase } from './camelToSnakeUpperCase';

const REASON = 'Reason: ';
// This regexp removes the "variable" part of the message (the ref, see unit tests for examples)
// So the same message is always the same and can be grouped.
const SHIPMENT_REGEXP = /(Shipment [a-zA-Z0-9-]*:\s?)/;

export function parsePaymentErrorMessage(message: string): string;
export function parsePaymentErrorMessage(message?: string): string | undefined;
export function parsePaymentErrorMessage(message?: string): string | undefined {
    if (!message) return;

    let newMessage = message;
    const reasonIndex = message.indexOf(REASON);
    const hasReason = reasonIndex > -1;

    if (hasReason) {
        newMessage = message.substring(reasonIndex + REASON.length);
    }

    newMessage = newMessage.replace(SHIPMENT_REGEXP, '');

    return newMessage;
}

export const getPaymentErrorMessageKey = (error?: { message?: string }): string => {
    if (!error || !error.message) return 'payment.error.generic-message';

    return parsePaymentErrorMessage(error.message) ?? 'payment.error.generic-message';
};

export type PaymentApiError = {
    message?: string;
    error_code?: string;
    error_field?: {
        name?: string;
        value?: string;
    };
};

export type PaymentError = {
    key: string;
    value?: string;
};

export const getPaymentErrors = (errors?: PaymentApiError[]): PaymentError[] => {
    if (!errors || !errors.length) return [{ key: API_ERRORS_MAPPING_KEYS.DEFAULT }];

    return errors.map((error: PaymentApiError) => {
        const errorCode = error.error_code ?? '';
        const errorMessage = error.message ?? '';
        const errorFieldValue = error.error_field?.value ?? '';

        if (errorCode === API_ERRORS_MAPPING.CARRIER_PRODUCT_FAILURE) {
            if (errorMessage.includes('Drop Off point id is not valid')) {
                return { key: 'payment.error.carrier-failure-drop-off' };
            } else if (errorMessage.includes('The dates of the user and the service do not match')) {
                return { key: 'payment.error.collection-date' };
            } else if (errorMessage.includes('Service not available for this user')) {
                return { key: 'payment.error.service-not-available' };
            } else {
                return { key: API_ERRORS_MAPPING_KEYS[camelToSnakeUpperCase(errorCode)] };
            }
        } else if (Object.values<string>(API_ERRORS_MAPPING).includes(errorCode)) {
            return {
                key: API_ERRORS_MAPPING_KEYS[camelToSnakeUpperCase(errorCode as API_ERRORS_MAPPING)],
                ...(errorFieldValue && { value: errorFieldValue }),
            };
        } else {
            return { key: API_ERRORS_MAPPING_KEYS.DEFAULT };
        }
    });
};

export const getPaymentErrorsTranslated = (t: TFunction, errors: PaymentError[]): string => {
    const errorsTranslated = errors.map((error) => {
        const errorValueTranslated =
            (error.value && t('payment.error.please-check-data', { value: error.value })) ?? '';
        return `${t(error.key)}${errorValueTranslated}`;
    });
    return Array.from(new Set(errorsTranslated)).join('<br>');
};
