import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';

import { FormField, Grid, GridChild } from '@shipengine/giger';
import { SupportTicketType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { SupportCenterHeader } from '@SupportCenter/components/SupportCenterHeader/SupportCenterHeader';
import { BillingTicketForm } from './components/BillingTicketForm/BillingTicketForm';
import { CancelTicketForm } from './components/CancelTicketForm/CancelTicketForm';
import { DamageRequirements } from './components/SupportTicketRequirements/DamageRequirements';
import { DamageTicketForm } from './components/DamageTicketForm/DamageTicketForm';
import { DeliveryTicketForm } from './components/DeliveryTicketForm/DeliveryTicketForm';
import { FormWithShipment } from './components/CommonFields/FormWithShipment/FormWithShipment';
import { LabelTicketForm } from './components/LabelTicketForm/LabelTicketForm';
import { LossRequirements } from './components/SupportTicketRequirements/LossRequirements';
import { LossTicketForm } from './components/LossTicketForm/LossTicketForm';
import { NewTicketFieldset } from './components/CommonFields/NewTicketFieldset/NewTicketFieldset';
import { OtherTicketForm } from './components/OtherTicketForm/OtherTicketForm';
import { PickupTicketForm } from './components/PickupTicketForm/PickupTicketForm';
import { ShipmentSummary } from './components/ShipmentSummary/ShipmentSummary';
import { validateCancellableShipment } from './components/CancelTicketForm/CancelTicketFormData';
import { ShipmentValidateFunction } from './components/CommonFields/FormWithShipment/FormWithShipmentData';
import { NewTicketEventHandlers, useNewTicketEventHandlers } from './NewTicketData';
import { getNewTicketStyles } from './NewTicketStyles';
import { getFormElementStyles, getFormFieldStyles, getNewTicketFormSectionStyles } from './styles/FormStyles';
import { getSupportCenterCardStyles, getSupportCenterLayoutHeaderStyles } from '@SupportCenter/SupportCenterStyles';
import { SupportCenterMetricsEvent } from '@SupportCenter/components/types';
import { APP_ROUTE } from '@pages/router/routes';
import { TicketTypeCard } from './components/TicketTypeCard/TicketTypeCard';
import { IconNames } from '@shipengine/giger-theme';
import { useAmplitude } from '@hooks/useAmplitude';

export function NewTicket(): JSX.Element {
    const { t } = useTranslation();
    const { options } = useNewTicketTypeData();
    const [searchParams] = useSearchParams();
    const ticketType = (searchParams.get('ticketType') as SupportTicketType) ?? undefined;
    const { type, handleChange } = useNewTicketTypeHandlers(ticketType);
    const eventHandlers = useNewTicketEventHandlers();
    const activeRoutes = [
        {
            path: APP_ROUTE.SUPPORT_CENTER.NEW_TICKET,
            name: t('support-center.routes.new-ticket'),
        },
    ];

    return (
        <main>
            <SupportCenterHeader css={getSupportCenterLayoutHeaderStyles} activeRoutes={activeRoutes} />
            <section data-id="support-center-new-ticket" css={[getNewTicketStyles, getSupportCenterCardStyles]}>
                <NewTicketFieldset css={getFormElementStyles} title={t('support-center.create-ticket.info')}>
                    <FormField css={getFormFieldStyles}>
                        <Grid noPadding>
                            {options.map((option) => (
                                <GridChild key={option.value} colSpan={6}>
                                    <TicketTypeCard
                                        option={option}
                                        handleChange={handleChange}
                                        selected={type === option.value}
                                    />
                                </GridChild>
                            ))}
                        </Grid>
                    </FormField>
                </NewTicketFieldset>
                {type && getActiveForm(eventHandlers, type)}
            </section>
        </main>
    );
}

function useNewTicketTypeData() {
    const options = [
        {
            value: SupportTicketType.CANCEL,
            icon: IconNames.CANCEL,
        },
        {
            value: SupportTicketType.LABEL,
            icon: IconNames.LABEL,
        },
        {
            value: SupportTicketType.BILLING,
            icon: IconNames.FILE,
        },
        {
            value: SupportTicketType.LOSS,
            icon: IconNames.OPEN_BOX,
        },
        {
            value: SupportTicketType.DAMAGE,
            icon: IconNames.FRAGILE,
        },
        {
            value: SupportTicketType.PICKUP,
            icon: IconNames.SINGLE_COLLECTION,
        },
        {
            value: SupportTicketType.DELIVERY,
            icon: IconNames.TROLLEY,
        },
        {
            value: SupportTicketType.OTHER,
            icon: IconNames.HELP,
        },
    ];
    return { options };
}

function useNewTicketTypeHandlers(ticketType?: SupportTicketType) {
    const [type, setType] = useState<SupportTicketType | null>(ticketType ?? null);
    const { sendAmplitudeEvent } = useAmplitude();
    const handleChange = (value: SupportTicketType | null) => {
        setType(value);
    };

    useEffect(() => {
        if (type) {
            sendAmplitudeEvent(SupportCenterMetricsEvent.OnSupportTicketCategorySelection, { type: type });
        }
    }, [sendAmplitudeEvent, type]);

    return {
        type,
        handleChange,
    };
}

function getActiveForm({ onSubmit }: NewTicketEventHandlers, type: SupportTicketType | undefined) {
    switch (type) {
        case SupportTicketType.PICKUP:
            return (
                <FormWithShipment data-id="pickup-ticket-form" ticketType={SupportTicketType.PICKUP}>
                    <PickupTicketForm onSubmit={onSubmit} />
                </FormWithShipment>
            );
        case SupportTicketType.LOSS:
            return (
                <FormWithShipment data-id="loss-ticket-form" ticketType={SupportTicketType.LOSS}>
                    <LossRequirements css={getNewTicketFormSectionStyles} />
                    <ShipmentSummary css={getNewTicketFormSectionStyles} />
                    <LossTicketForm onSubmit={onSubmit} />
                </FormWithShipment>
            );
        case SupportTicketType.DAMAGE:
            return (
                <FormWithShipment data-id="damage-ticket-form" ticketType={SupportTicketType.DAMAGE}>
                    <DamageRequirements css={getNewTicketFormSectionStyles} />
                    <ShipmentSummary css={getNewTicketFormSectionStyles} />
                    <DamageTicketForm onSubmit={onSubmit} />
                </FormWithShipment>
            );
        case SupportTicketType.CANCEL:
            return (
                <FormWithShipment
                    data-id="cancel-ticket-form"
                    validateShipment={validateCancellableShipment as ShipmentValidateFunction}
                    ticketType={SupportTicketType.CANCEL}
                >
                    <CancelTicketForm />
                </FormWithShipment>
            );
        case SupportTicketType.LABEL:
            return (
                <FormWithShipment data-id="label-ticket-form" ticketType={SupportTicketType.LABEL}>
                    <LabelTicketForm onSubmit={onSubmit} />
                </FormWithShipment>
            );
        case SupportTicketType.DELIVERY:
            return (
                <FormWithShipment data-id="delivery-ticket-form" ticketType={SupportTicketType.DELIVERY}>
                    <DeliveryTicketForm onSubmit={onSubmit} />
                </FormWithShipment>
            );
        case SupportTicketType.BILLING:
            return (
                <FormWithShipment data-id="billing-ticket-form" ticketType={SupportTicketType.BILLING}>
                    <BillingTicketForm onSubmit={onSubmit} />
                </FormWithShipment>
            );
        case SupportTicketType.OTHER:
            return <OtherTicketForm data-id="other-ticket-form" onSubmit={onSubmit} />;
        default:
            return null;
    }
}
