import { Trans } from '@packlink/translation-provider';
import { IconNames } from '@shipengine/giger-theme';
import { Icon, IconSize, Typography } from '@shipengine/giger';
import {
    getExcludedServicesStyles,
    getIconStyles,
    getPlanFeaturesGroupHeaderStyles,
    getPlanFeaturesGroupStyles,
    getPlanFeaturesStyles,
} from './OnBoardingPlanFeaturesStyles';
import { PlanFeature, planTypes } from '@common/hooks/usePlanFeatures';

interface PlanFeaturesGroupProps {
    featuresGroupName: string;
    featuresGroup: PlanFeature[];
    planType: planTypes;
}
export const OnBoardingPlanFeaturesGroup = ({
    planType,
    featuresGroup,
    featuresGroupName,
}: PlanFeaturesGroupProps): JSX.Element => {
    return (
        <div css={getPlanFeaturesGroupStyles}>
            <Typography component="li" variant="body1" bold css={getPlanFeaturesGroupHeaderStyles}>
                <Trans i18nKey={featuresGroupName} />
            </Typography>
            {featuresGroup.map(({ key, values, showDisclaimers, showExcludedServices }) => {
                const defaultKey = key[planTypes.PLUS];
                const featureKey = key[planType] ?? defaultKey;
                const isFeatureActive = !!key[planType];
                return (
                    featureKey && (
                        <div key={featureKey}>
                            <Typography component="li" variant="body1" css={getPlanFeaturesStyles(isFeatureActive)}>
                                <Icon
                                    css={getIconStyles(isFeatureActive)}
                                    name={isFeatureActive ? IconNames.CHECK_FILLED : IconNames.CANCEL_FILLED}
                                    size={IconSize.SIZE_SMALL}
                                />
                                <span data-id="feature-group-plan-type">
                                    {showDisclaimers ? (
                                        <>
                                            <Trans key={featureKey} i18nKey={featureKey} values={values} />
                                            {showDisclaimers && '*'}
                                        </>
                                    ) : (
                                        <Trans key={featureKey} i18nKey={featureKey} values={values} />
                                    )}
                                </span>
                            </Typography>
                            {showExcludedServices && planType === planTypes.PLUS && (
                                <span css={getExcludedServicesStyles}>
                                    <Trans i18nKey={showExcludedServices} values={values} />
                                </span>
                            )}
                        </div>
                    )
                );
            })}
        </div>
    );
};
