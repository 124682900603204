import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSupportTicketsTableActionsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        '> *': {
            marginBottom: theme.spacing(2),
        },
        [theme.mediaQuery('tabletLarge')]: {
            flexDirection: 'row',
        },
    });

export const getTableTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.primary.dark,
        marginBottom: theme.spacing(2),
    });

export const getSubscriptionButtonsStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'block',

        [theme.mediaQuery('tabletLarge')]: {
            display: 'flex',
        },
    });

export const getActionLinkStyles = (theme: Theme): SerializedStyles =>
    css({
        width: '100%',
        height: 'max-content',

        '> a': {
            width: '100%',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
        },

        [theme.mediaQuery('tabletLarge')]: {
            width: 'auto',
            flex: '0 0 auto',

            '> a': {
                marginTop: 0,
            },
        },
    });

export const getFirstActionLinkStyles = (theme: Theme): SerializedStyles =>
    css({
        marginRight: 0,

        [theme.mediaQuery('tabletLarge')]: {
            marginRight: theme.spacing(2),
        },
    });
