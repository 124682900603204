import { WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { getCancelShipmentFootnoteStyles } from './CancelShipmentFootnoteStyles';

type CancelShipmentFootnoteProps = WithCommonProps<unknown>;

export function CancelShipmentFootnote(props: CancelShipmentFootnoteProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <p css={getCancelShipmentFootnoteStyles} {...props}>
            {t('post-sale.details.cancel-shipment-footnote')}
        </p>
    );
}
