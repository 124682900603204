import { useTranslation } from '@packlink/translation-provider';
import { Currency, SubscriptionPlan } from '@packlink/packlink-sdk';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { useSubscriptionPlanPrice } from '@Subscriptions/hooks/useClientSubscriptions';
import { ITenantConfig } from '@types';
import { getTaxCalculationDetailsStyles, getTaxCalculationPanelStyles } from './SubscriptionPanelTaxCalculationStyles';
import { SubscriptionPanelTaxContent } from './SubscriptionPanelTaxContent';

interface SubscriptionPanelTaxCalculationProps {
    planDetails: SubscriptionPlan;
    onError: (translationKey?: string) => void;
}
export function SubscriptionPanelTaxCalculation({
    planDetails,
    onError,
}: SubscriptionPanelTaxCalculationProps): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const { defaultCurrency } = useTenantConfig<ITenantConfig>();

    const showErrorMessage = () => onError('subscriptions.payment.billing-info-error');
    const { planPrice } = useSubscriptionPlanPrice(planDetails.code, showErrorMessage);

    const { taxes, total, subtotal } = planPrice || {};

    return (
        <div css={getTaxCalculationPanelStyles}>
            <div css={getTaxCalculationDetailsStyles}>
                <SubscriptionPanelTaxContent
                    isLoading={!planPrice}
                    isDetailCard
                    label={t('subscriptions.payment.tax-panel-name', { planName: planDetails.name })}
                    value={Currency.format(subtotal || 0, defaultCurrency)}
                />
                <SubscriptionPanelTaxContent
                    isLoading={!planPrice}
                    isDetailCard
                    label={t('subscriptions.payment.tax-panel-taxes', {
                        taxPercentage: (taxes?.rate || 0).toLocaleString(locale, { style: 'percent' }),
                    })}
                    value={Currency.format(taxes?.total || 0, defaultCurrency)}
                />
            </div>
            <SubscriptionPanelTaxContent
                isLoading={!planPrice}
                label={t('subscriptions.payment.tax-panel-total')}
                value={t('subscriptions.payment.tax-panel-monthly-total', {
                    totalPrice: Currency.format(total || 0, defaultCurrency),
                })}
            />
        </div>
    );
}
