import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store';
import { AmplitudeEvents } from '@constants/amplitude';
import { removeSelectedShipments } from '@store/actions/bulk';
import { partition } from 'lodash';
import { ProShipment } from '@packlink/packlink-sdk';
import { BulkFlows } from '../../typings';
import { useAmplitude } from '@hooks/useAmplitude';

interface IUseBulkCollectionProps {
    selectedShipments: ProShipment[];
    onSetFlow: (flow: BulkFlows) => void;
}

export function useBulkCollection({ selectedShipments, onSetFlow }: IUseBulkCollectionProps) {
    const dispatch = useDispatch<AppDispatch>();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const [collectionShipments, notCollectionShipments] = partition(
        selectedShipments,
        (s) => s.data.service?.id && !s.data.service?.dropOff?.origin,
    );

    const handleEditCollectionDate = (): void => {
        if (notCollectionShipments.length) {
            const references = notCollectionShipments.map((s) => s.data.packlinkReference);
            dispatch(removeSelectedShipments(references));
        }

        sendAmplitudeSidebarClickEvent(AmplitudeEvents.EDIT_COLLECTION_DATE);

        onSetFlow(BulkFlows.COLLECTION_DATE);
    };

    return {
        handleEditCollectionDate,
        collectionShipments,
        notCollectionShipments,
    };
}
