import { ClientFeature } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';
import { useSubscriptionFeature } from '@hooks';
import { AnalyticsContent } from './AnalyticsContent';
import { AmplitudeEvents } from '@constants/amplitude';
import { analyticsSectionStyles, titleStyles } from './AnalyticsStyles';
import { SubscriptionNotification } from '@common/components/SubscriptionNotification/SubscriptionNotification';
import { getNotificationSpacingStyles } from '@common/components/SubscriptionNotification/SubscriptionNotificationStyles';
import { AnalyticsSkeleton } from './AnalyticsSkeleton';

export function Analytics(): JSX.Element {
    const { t } = useTranslation();
    const {
        feature: userAnalyticsFeature,
        isTenantSubscriptionEnabled,
        isLoading,
    } = useSubscriptionFeature(ClientFeature.USER_ANALYTICS);
    const isAnalyticsEnabled = userAnalyticsFeature.isEnabled && isTenantSubscriptionEnabled;

    return (
        <section css={analyticsSectionStyles}>
            <Typography css={titleStyles} component="h2" variant="heading3">
                {t('analytics.menu.title')}
            </Typography>

            {isAnalyticsEnabled && !isLoading && (
                <Typography component="p" variant="body2">
                    {t('analytics-page.header.description')}
                </Typography>
            )}

            {!isAnalyticsEnabled && !isLoading && (
                <SubscriptionNotification
                    css={getNotificationSpacingStyles}
                    title={t('subscriptions.upgrade-notification.analytics')}
                    amplitudeEvent={AmplitudeEvents.SETTINGS_TOOLTIP_USER_ANALYTICS}
                >
                    {t('subscriptions.upgrade-notification.analytics-message')}
                </SubscriptionNotification>
            )}

            {isAnalyticsEnabled ? <AnalyticsContent /> : <AnalyticsSkeleton />}
        </section>
    );
}
