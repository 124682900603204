import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';
import { Dialog, DialogContent, DialogHeader, Icon, IconSize, Spinner, SpinnerSize } from '@shipengine/giger';

import { IUseDialogDownloadLabel } from './hooks/useDialogDownloadLabel';

import { getDialogContentStyles, getDialogIconStyles } from './DownloadLabelDialogStyles';

interface IDownloadLabelDialogProps {
    status: IUseDialogDownloadLabel['status'];
    isPrintAction: boolean;
    numberShipments?: number;
    onCloseDialog: () => void;
}

export function DownloadLabelDialog({
    status,
    isPrintAction,
    numberShipments,
    onCloseDialog,
}: IDownloadLabelDialogProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Dialog isOpen={status.isDialogOpen}>
            <DialogHeader
                title={t(isPrintAction ? 'download-label.action.print-label' : 'download-label.action.download-label', {
                    count: numberShipments,
                })}
                showClose
                onClose={onCloseDialog}
                closeAriaLabel={t('dialog.action.close')}
            />
            {status.isLoading && (
                <LabelsDialogContent title="bulk-print.loading.title" subtitle="bulk-print.loading.subtitle" />
            )}

            {status.isReady && (
                <LabelsDialogContent
                    icon={IconNames.CHECK_FILLED}
                    title={`bulk-print.ready.${isPrintAction ? 'print-title' : 'download-title'}`}
                />
            )}

            {status.isError && (
                <LabelsDialogContent
                    icon={IconNames.INFO_FILLED}
                    title="bulk-print.error.title"
                    subtitle="bulk-print.error.subtitle"
                />
            )}
        </Dialog>
    );
}

interface ILabelsDialogContentProps {
    icon?: IconNames;
    title: string;
    subtitle?: string;
}

const LabelsDialogContent = ({ icon, title, subtitle }: ILabelsDialogContentProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <DialogContent css={getDialogContentStyles}>
            {icon ? (
                <Icon name={icon} size={IconSize.SIZE_LARGE} css={getDialogIconStyles(icon)} />
            ) : (
                <Spinner size={SpinnerSize.SIZE_LARGE} css={getDialogIconStyles(icon)} />
            )}
            <div>{t(title)}</div>
            {subtitle && <div>{t(subtitle)}</div>}
        </DialogContent>
    );
};
