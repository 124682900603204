import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getInvoicingDetailsStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(2),
        boxShadow: theme.getElevation('above').boxShadow,
        backgroundColor: theme.palette.white,
        borderRadius: theme.borderRadius.S,
    });

export const getInvoicingDetailsTitleStyles = (theme: Theme): SerializedStyles =>
    css({ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: theme.spacing() });

export const getInvoicingDetailsTitleCaptionStyles = (): SerializedStyles => css({ marginBottom: 0 });
export const getInvoicingDetailsLineStyles = (theme: Theme): SerializedStyles =>
    css({ marginBottom: theme.spacing(0.5) });
