import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getBillingInfoCardStyles = (theme: Theme): SerializedStyles =>
    css({
        ...theme.getCardStyle(),
        ...theme.getElevation('above'),

        margin: `${theme.spacing()}px 0`,
    });
export const getBillingInfoCardListStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.dark,
        listStyle: 'none',
        margin: 0,
        padding: 0,

        li: {
            marginBottom: theme.spacing(),
        },
    });
export const getBillingInfoCardTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'block',
        color: theme.palette.gray.dark,
        marginBottom: theme.spacing(),
    });
export const getBillingInfoCardContainerStyles = (theme: Theme): SerializedStyles =>
    css({
        position: 'relative',
        padding: theme.spacing(3),

        '.btn': {
            position: 'absolute',
            top: theme.spacing(),
            right: theme.spacing(),
        },
    });
