import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSupportHeaderStyles = (theme: Theme): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.mediaQuery('tablet')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

export const getSupportHeaderTitleStyles = (theme: Theme): CSSObject => ({
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(2),

    [theme.mediaQuery('tablet')]: {
        marginBottom: 0,
    },
});
