import { useRef, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { ProShipment } from '@packlink/packlink-sdk';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import Packlink from '@sdk';
import { Popover, useToast } from '@shipengine/giger';
import { logSdkError } from '@utils/logger';
import { CancelShipmentButton } from './CancelShipmentButton';
import { CancelShipmentDialog } from './CancelShipmentDialog';
import { CancelShipmentFootnote } from './CancelShipmentFootnote';
import { getCancelShipmentContainerStyles } from './CancelShipmentStyles';
import { useDialog } from '@hooks/useDialog';
import { useAmplitude } from '@hooks/useAmplitude';

type CancelShipmentProps = {
    shipment: ProShipment;
};

export function CancelShipment({ shipment }: CancelShipmentProps): JSX.Element {
    const { open, handleOpen, handleClose } = useDialog();
    const popoverRef = useRef<HTMLDivElement>(null);

    const { handleCancel, isCancelRequested } = useCancelShipmentHandler(shipment, handleClose);
    return (
        <>
            <div ref={popoverRef}>
                <CancelShipmentButton
                    data-id="cancel-shipment-button"
                    isFullWidth
                    disabled={isCancelRequested}
                    onClick={handleOpen}
                />
                {isCancelRequested && <CancelShipmentFootnote data-id="cancel-shipment-footnote" />}
            </div>
            <Popover
                reference={popoverRef.current}
                isOpen={open}
                placement="left-start"
                onClickAway={handleClose}
                css={getCancelShipmentContainerStyles}
            >
                <CancelShipmentDialog data-id="cancel-shipment-dialog" onCancel={handleCancel} onClose={handleClose} />
            </Popover>
        </>
    );
}

function useCancelShipmentHandler(shipment: ProShipment, onFinish: () => void) {
    const { t } = useTranslation();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const toast = useToast(t);
    const [isCancelRequested, setCancelRequested] = useState(false);

    const handleCancel = async (reason: string) => {
        const { packlinkReference = '' } = shipment.data;
        setCancelRequested(true);

        Packlink.v1.shipments
            .cancelShipment(packlinkReference, reason)
            .then(() => {
                toast.success({ message: t('post-sale.details.cancellation-message-sent') });
                const eventName = `${AmplitudeEvents.CANCEL} ${AmplitudeEventPrefixes.CONFIRMATION_PAGE}`;
                sendAmplitudeClickEvent(eventName);
            })
            .catch(logSdkError)
            .finally(onFinish);
    };

    return {
        handleCancel,
        isCancelRequested,
    };
}
