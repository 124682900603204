import { useNavigate } from 'react-router';
import { TableBodyCell, TableRow, Tag, TagVariant, WithCommonProps } from '@shipengine/giger';
import { AnySupportTicket, hasShipmentReferenceInSupportTicket } from '@packlink/packlink-sdk';
import { SUPPORT_CENTER_KEYS, useSupportCenter } from '@packlink/provider';
import moment from 'moment';
import { useTranslation } from '@packlink/translation-provider';
import { getTicketTableCategoryStyles } from './TicketTableRowStyles';
import { SupportCenterMetricsEvent } from '@SupportCenter/components/types';
import { TicketStatusTag } from '@SupportCenter/components/TicketStatusTag/TicketStatusTag';
import { getTicketDetailsRoute } from '@SupportCenter/pages/routes';
import { useTenantFeatures } from '@SupportCenter/utils/tenantFeatures';
import { useAmplitude } from '@hooks/useAmplitude';

type TicketTableRowProps = WithCommonProps<{
    data: AnySupportTicket;
}>;

export function TicketTableRow({ data, ...props }: TicketTableRowProps): JSX.Element {
    const { t } = useTranslation();
    const getSupportCenter = useSupportCenter();
    const { isInternalDetailsPageEnabled } = useTenantFeatures();
    const navigate = useNavigate();
    const { sendAmplitudeEvent } = useAmplitude();

    const goToZendesk = () => {
        const zendeskLink = getSupportCenter(SUPPORT_CENTER_KEYS.ticketLink, { ticketId: data.externalReference });
        sendAmplitudeEvent(SupportCenterMetricsEvent.OnSupportTicketDetailsClick, {
            id: data.id,
            status: data.status,
            type: data.type,
            externalReference: data.externalReference,
        });
        // Not a big fan of using a window.open here, but links in tables are tricky HTML wise
        window.open(zendeskLink, '_blank');
    };

    const goToDetails = () => {
        const ticketId = data.externalReference || data.id;

        sendAmplitudeEvent(SupportCenterMetricsEvent.OnSupportTicketDetailsClick, {
            id: data.id,
            status: data.status,
            type: data.type,
            externalReference: data.externalReference,
        });
        navigate(getTicketDetailsRoute(ticketId));
    };

    return (
        <TableRow onClick={isInternalDetailsPageEnabled ? goToDetails : goToZendesk} {...props}>
            <TableBodyCell>{data.externalReference || ''}</TableBodyCell>
            <TableBodyCell>{hasShipmentReferenceInSupportTicket(data) ? data.shipmentReference : '-'}</TableBodyCell>
            <TableBodyCell css={getTicketTableCategoryStyles}>
                <Tag uppercase={false} variant={TagVariant.OUTLINED}>
                    {t(`support-center.ticket-types.${data.type}`)}
                </Tag>
            </TableBodyCell>
            <TableBodyCell>{moment(data.createdAt).format('Do MMM YYYY')}</TableBodyCell>
            <TableBodyCell>{moment(data.updatedAt).fromNow()}</TableBodyCell>
            <TableBodyCell>{<TicketStatusTag data={data.status} />}</TableBodyCell>
        </TableRow>
    );
}
