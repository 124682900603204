import { AnySupportTicket, ApiClientError } from '@packlink/packlink-sdk';

export type SupportCenterShipmentErrorData =
    | ApiClientError
    | SupportCenterShipmentCancelAlreadyRequestedError
    | SupportCenterShipmentNotCancellableError
    | SupportCenterTicketAlreadyCreatedError;

enum SupportCenterShipmentErrorMessage {
    CANCEL_ALREADY_REQUESTED = 'CANCEL_ALREADY_REQUESTED',
    SHIPMENT_NOT_CANCELABLE = 'SHIPMENT_NOT_CANCELABLE',
    SHIPMENT_CANCELLATION_DEADLINE_REACHED = 'SHIPMENT_CANCELLATION_DEADLINE_REACHED',
    TICKET_ALREADY_CREATED = 'TICKET_ALREADY_CREATED',
}

export class SupportCenterShipmentCancelAlreadyRequestedError extends Error {
    constructor() {
        super(SupportCenterShipmentErrorMessage.CANCEL_ALREADY_REQUESTED);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, SupportCenterShipmentCancelAlreadyRequestedError.prototype);
    }
}

export class SupportCenterShipmentNotCancellableError extends Error {
    constructor() {
        super(SupportCenterShipmentErrorMessage.SHIPMENT_NOT_CANCELABLE);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, SupportCenterShipmentNotCancellableError.prototype);
    }
}

export class SupportCenterShipmentCancellationDeadlineReachedError extends Error {
    constructor() {
        super(SupportCenterShipmentErrorMessage.SHIPMENT_CANCELLATION_DEADLINE_REACHED);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, SupportCenterShipmentCancellationDeadlineReachedError.prototype);
    }
}

export class SupportCenterTicketAlreadyCreatedError extends Error {
    public ticket: AnySupportTicket;

    constructor(ticket: AnySupportTicket) {
        super(SupportCenterShipmentErrorMessage.TICKET_ALREADY_CREATED);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, SupportCenterTicketAlreadyCreatedError.prototype);

        // Set data
        this.ticket = ticket;
    }
}
