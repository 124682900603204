import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSupportCenterLayoutHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });

export const getSupportCenterCardStyles = (theme: Theme): SerializedStyles =>
    css({
        background: theme.palette.white,
        borderRadius: theme.spacing(0.5),
        ...theme.elevations.above,
    });

export const getHelpAndSupportStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(10)}px ${theme.spacing(2)}px`,
        backgroundColor: theme.palette.gray.megaLight,
        minHeight: '100%',
        height: 'fit-content',
        [theme.mediaQuery('tablet')]: {
            padding: `${theme.spacing(5)}px ${theme.spacing(3)}px ${theme.spacing(10)}px ${theme.spacing(3)}px`,
        },
    });

export const getHelpAndSupportContainerStyles =
    (useAdjustedHeight = false) =>
    (theme: Theme): SerializedStyles =>
        css({
            width: '100%',
            marginRight: 'auto',
            marginLeft: 'auto',
            maxWidth: theme.breakPoints.desktop,
            ...(useAdjustedHeight && {
                [theme.mediaQuery('tablet')]: {
                    height: '100%',
                    minHeight: theme.spacing(82),
                },
            }),
        });

export const getHelpAndSupportTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.primary.dark,
    });

export const getSupportCenterWrapperStyles = (theme: Theme): SerializedStyles =>
    css({
        backgroundColor: theme.palette.white,
        ...theme.elevations.flat2,
        padding: theme.spacing(3),
    });
