import { Textarea } from '@shipengine/formik-giger';
import { Button, WithCommonProps } from '@shipengine/giger';
import { SendSupportTicketMessagePayload } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import {
    getUploadMultipleAttachmentValidationSchema,
    SupportCenterAttachment,
} from '@SupportCenter/components/UploadAttachment/UploadAttachmentData';
import { UploadMultipleAttachmentField } from '@SupportCenter/components/UploadAttachment/UploadMultipleAttachmentField';
import {
    getTicketChatSendMessageFileInputStyles,
    getTicketChatSendMessageFormStyles,
    getTicketChatSendMessageSubmitButtonStyles,
    getTicketChatSendMessageTextInputStyles,
} from './TicketChatSendMessageFormStyles';
import DOMPurify from 'dompurify';

type TicketChatSendMessageFormProps = WithCommonProps<{
    onSubmit: (messagePayload: SendSupportTicketMessagePayload) => Promise<void>;
}>;

export function TicketChatSendMessageForm({ onSubmit, ...props }: TicketChatSendMessageFormProps): JSX.Element {
    const { t } = useTranslation();
    const { handleSubmit } = useTicketChatSendMessageFormHandlers({ onSubmit });
    const validationSchema = useTicketChatSendMessageFormValidationSchema();
    return (
        <Formik
            initialValues={ticketChatSendMessageInitialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
        >
            {({ isValid, isSubmitting }) => (
                <Form css={getTicketChatSendMessageFormStyles} {...props}>
                    <FastField
                        css={getTicketChatSendMessageFileInputStyles}
                        component={UploadMultipleAttachmentField}
                        name="attachments"
                        label={t('support-center.new-ticket.attachment-restrictions-label')}
                        data-id="ticket-chat-send-message-upload-attachments"
                    />
                    <FastField
                        css={getTicketChatSendMessageTextInputStyles}
                        name="htmlBody"
                        component={Textarea}
                        rows="3"
                        label={t('support-center.ticket-chat.send-message-text-input-label')}
                        // Don't show invalid colors and message (design requisite)
                        isInvalid={false}
                    />
                    <Button
                        css={getTicketChatSendMessageSubmitButtonStyles}
                        disabled={!isValid}
                        isLoading={isSubmitting}
                        type="submit"
                    >
                        {t('support-center.ticket-chat.send-message-submit-button')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
}

type TicketChatSendMessageFormData = {
    htmlBody: string;
    attachments: SupportCenterAttachment[];
};

function useTicketChatSendMessageFormHandlers({ onSubmit }: Pick<TicketChatSendMessageFormProps, 'onSubmit'>) {
    const handleSubmit = async (
        { htmlBody, attachments }: TicketChatSendMessageFormData,
        { resetForm, validateField }: FormikHelpers<TicketChatSendMessageFormData>,
    ) => {
        const parsedAttachments = attachments.map(({ uploadId }) => uploadId);
        await onSubmit({ htmlBody: sanitizeHTMLInput(htmlBody), attachments: parsedAttachments });
        resetForm();
        // Force validation to ensure submit button is disabled
        validateField('htmlBody');
    };

    return {
        handleSubmit,
    };
}

function sanitizeHTMLInput(htmlBody: string) {
    // Add line breaks as HTML to ensure consistency
    return DOMPurify.sanitize(htmlBody).replace(/\n/g, '<br />');
}

const ticketChatSendMessageInitialValues: TicketChatSendMessageFormData = {
    htmlBody: '',
    attachments: [],
};

function useTicketChatSendMessageFormValidationSchema() {
    const { t } = useTranslation();
    return yup
        .object()
        .shape({
            htmlBody: yup
                .string()
                .required(
                    t('form.error.required', { field: t('support-center.generic-support-ticket-fields.reason-label') }),
                ),
            attachments: getUploadMultipleAttachmentValidationSchema(),
        })
        .defined();
}
