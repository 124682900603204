import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getTicketChatScrollToTopStyles = css({
    display: 'inline-flex',
    alignItems: 'center',
});

export const getTicketChatScrollToTopIconStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });
