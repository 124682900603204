import { useEffect, useState } from 'react';
import { AmplitudeEvents } from '@constants/amplitude';
import { IInterval, Interval, IShipment, Service, Shipment, ShipmentCollection } from '@packlink/packlink-sdk';
import { ServicesStep } from '../types';
import { useAmplitude } from '@hooks/useAmplitude';

export function useCollection(shipmentData: Shipment<IShipment>, step: ServicesStep, selectedService?: Service) {
    const [selectedCollection, setSelectedCollection] = useState<ShipmentCollection>();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const onChangeCollection = (_name: string, date?: moment.Moment, interval?: IInterval) => {
        if (selectedCollection) {
            // We only want to log when the user has interacted with the calendar
            sendAmplitudeSidebarClickEvent(AmplitudeEvents.CALENDAR_COLLECTION_DATE);
        }
        const newInterval = interval && new Interval(interval?.start, interval?.end);
        const shipmentCollection = new ShipmentCollection(date, newInterval);
        setSelectedCollection(shipmentCollection);
    };

    useEffect(() => {
        if (step === ServicesStep.COLLECTION_DATE && shipmentData.service?.id === selectedService?.id) {
            setSelectedCollection(shipmentData.collection);
        }
    }, [step, selectedService?.id, shipmentData.collection, shipmentData.service?.id]);

    return {
        selectedCollection,
        setSelectedCollection,
        onChangeCollection,
    };
}
