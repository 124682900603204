import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getChartStateStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: `0 ${theme.spacing(2)}px`,
        textAlign: 'center',
    });

export const getImageStyles = (theme: Theme): SerializedStyles =>
    css({
        width: 135,
        marginBottom: theme.spacing(2),
    });

export const getTitleStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
    });

export const getSubtitleStyles = (): SerializedStyles =>
    css({
        textAlign: 'center',
    });
