import { useEffect, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import { useTranslation } from '@packlink/translation-provider';
import { InlineNotification, Input, NotificationType } from '@shipengine/giger';
import { useSubscriptionFeature } from '@hooks';
import { FormField } from '@shipengine/formik-giger';
import { ClientFeature, OwnContractCredentials } from '@packlink/packlink-sdk';

import { ServiceUtils } from '@utils/ServiceUtils';
import { SubscriptionNotice } from '@Subscriptions/components/SubscriptionNotice/SubscriptionNotice';
import { SidePanelContentError } from '@components/SidePanel/SidePanelContentError';
import { SidePanelContentSection } from '@components/SidePanel/SidePanelContentSection';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';

import { OwnContractPanelDeactivate } from './OwnContractPanelDeactivate';
import { OwnContractPanelHelp } from './OwnContractPanelHelp';
import {
    OwnContractCarrierFormData,
    CREDENTIALS_ERROR_STATUS_CODE,
    CREDENTIALS_ERROR_TEXT,
    OwnContractCarriers,
} from '../OwnContractPanel/constants';
import { getCarrierLogoStyles, getRegistrationStyles } from './OwnContractPanelStyles';
import { OwnContractPanelCommonProps } from './OwnContractPanelTypes';
import { toKebabCase } from '@utils/toKebabCase';
import OwnContractVideo from '../OwnContractVideo';

export const OwnContractPanelForm = (props: OwnContractPanelCommonProps): JSX.Element => {
    const {
        onClose,
        isLoading,
        error,
        clearError,
        ownContract,
        onDeactivate,
        formFields,
        videoTutorial,
        customInputTypes,
    } = props;

    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const [hasGeneralError, setHasGeneralError] = useState<boolean>(false);
    const [hasCredentialsError, setHasCredentialsError] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>();

    const { isValid, values, errors, handleChange, setFieldValue, handleBlur, touched, setFieldError } =
        useFormikContext<OwnContractCredentials>();

    const { isTenantSubscriptionEnabled, subscription, feature } = useSubscriptionFeature(ClientFeature.OWN_CONTRACT);

    const commonFieldProps = {
        onChange: handleChange,
        onBlur: handleBlur,
        disabled: isTenantSubscriptionEnabled && !feature.isEnabled,
    };

    const logoUrl = ServiceUtils.getCarrierLogo(ownContract.logoId);

    const isRegistering = !ownContract.isRegistered;

    const { carrierId, helpCaptions } =
        OwnContractCarrierFormData()[ownContract.carrierName as OwnContractCarriers] ?? {};
    const carrierIdTranslation = toKebabCase(carrierId);

    const formTitle = isRegistering
        ? t('own-contract.activation.title')
        : t('own-contract.edit.title', { carrier: ownContract.carrierName });

    const handleDeactivate = (): void => {
        onDeactivate(ownContract.contractId as string).then((contractId?: string | void) => {
            if (contractId) {
                formFields?.forEach((name) => {
                    setFieldValue(name, '');
                });
                setSuccessMessage(
                    t('own-contract.panel.deactivation-success', {
                        carrierName: ownContract.carrierName,
                    }),
                );
            }
        });
    };

    const isInputInvalid = (name: string) =>
        !!(errors[name as keyof OwnContractCredentials] && touched[name as keyof OwnContractCredentials]);

    useEffect(() => {
        const carrierOwnContractFormError = t('own-contract.panel.field-error');
        if (error) {
            const isCredentialsError =
                error.status === CREDENTIALS_ERROR_STATUS_CODE && error.message === CREDENTIALS_ERROR_TEXT;

            setHasCredentialsError(isCredentialsError);
            setHasGeneralError(!isCredentialsError);

            if (isCredentialsError) {
                formFields?.forEach((name) => {
                    setFieldError(name, carrierOwnContractFormError);
                });
            }
        } else {
            setHasCredentialsError(false);
            setHasGeneralError(false);
        }
    }, [error, formFields, setFieldError, t]);

    return (
        <Form css={getRegistrationStyles}>
            {!hasGeneralError && (
                <>
                    {hasCredentialsError && (
                        <InlineNotification type={NotificationType.ERROR}>
                            {t('own-contract.panel.credentials-notification')}
                        </InlineNotification>
                    )}
                    {successMessage && (
                        <InlineNotification type={NotificationType.SUCCESS}>{successMessage}</InlineNotification>
                    )}
                    <img css={getCarrierLogoStyles} alt={ownContract.logoId} src={logoUrl} />
                    {isTenantSubscriptionEnabled && subscription?.isCanceled && (
                        <SubscriptionNotice
                            planName={subscription.planName}
                            isSubscriptionActive={subscription.isActive}
                            isSubscriptionCancelled={subscription.isCanceled}
                            section="carrier contracts"
                            activeMessage="subscription-notification.active.message-oc"
                            expirationDate={subscription.currentPeriodEndsAt}
                        />
                    )}
                    {formFields && (
                        <SidePanelContentSection title={formTitle}>
                            {formFields?.map((name) => (
                                <FormField name={name} key={name}>
                                    {/*TODO https://auctane.atlassian.net/browse/PCK-3795 */}
                                    <Input
                                        label={t(`own-contract.panel.${carrierIdTranslation}-${toKebabCase(name)}`)}
                                        isInvalid={isInputInvalid(name)}
                                        value={values[name as keyof OwnContractCredentials] ?? ''}
                                        type={customInputTypes?.[name] ?? 'text'}
                                        {...commonFieldProps}
                                    />
                                </FormField>
                            ))}
                        </SidePanelContentSection>
                    )}
                    {ownContract.isRegistered && <OwnContractPanelDeactivate handleDeactivate={handleDeactivate} />}

                    {videoTutorial?.src && (
                        <OwnContractVideo
                            src={videoTutorial.src?.[locale] ?? videoTutorial.src?.default}
                            poster={videoTutorial?.poster}
                            carrierName={ownContract.carrierName}
                        />
                    )}

                    <OwnContractPanelHelp
                        captions={helpCaptions}
                        title={t(`own-contract.panel.${carrierIdTranslation}-help-title`)}
                        footNote={t(`own-contract.panel.${carrierIdTranslation}-help-footnote`)}
                    />
                </>
            )}
            {hasGeneralError && (
                <SidePanelContentError
                    title={t('own-contract.panel.error-title')}
                    subtitle={t('own-contract.panel.error-subtitle')}
                    messages={[t('own-contract.panel.error-message')]}
                    onTryAgain={clearError}
                />
            )}

            {!hasGeneralError && (
                <SidePanelFooter>
                    <SidePanelFooterActions
                        onCancel={onClose}
                        actionButtonType="submit"
                        actionText={t('own-contract.list.activate')}
                        cancelText={t('own-contract.panel.cancel')}
                        disabledAction={!isValid || !!ownContract.contractId}
                        isLoading={isLoading}
                    />
                </SidePanelFooter>
            )}
        </Form>
    );
};
