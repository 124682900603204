import { LDContext } from 'launchdarkly-react-client-sdk';
import { TenantUtils } from '@utils/tenant';

export const getIdentifiedUserContext = (userId: string): LDContext => {
    return {
        kind: 'user',
        key: userId,
        tenant: TenantUtils.getTenantName(),
    };
};

export const launchDarklyClientSideID = config.launchDarkly.clientId;

export const launchDarklyFlags = {
    'enable-ups-trademark-notice': false,
};
