import { WithCommonProps, Typography } from '@shipengine/giger';
import React from 'react';
import { InformationTooltip } from '@components/InformationTooltip/InformationTooltip';
import {
    getShipmentDetailsSidebarSectionListItemStyles,
    getShipmentDetailsSidebarSectionListStyles,
    getShipmentDetailsSidebarSectionTitleStyles,
    getShipmentDetailsSidebarSectionTooltipStyles,
} from './ShipmentDetailsSidebarSectionStyles';
import { hasActiveChildren } from '@utils/react';

type ShipmentDetailsSidebarSectionProps = WithCommonProps<{
    title?: React.ReactNode;
    tooltip?: React.ReactNode;
}>;

export function ShipmentDetailsSidebarSection({
    title,
    tooltip,
    children,
    ...props
}: ShipmentDetailsSidebarSectionProps): JSX.Element | null {
    if (!hasActiveChildren(children)) {
        return null;
    }

    return (
        <section {...props}>
            {title && (
                <Typography component="h4" css={getShipmentDetailsSidebarSectionTitleStyles} variant="heading5">
                    {title}{' '}
                    {tooltip && (
                        <InformationTooltip
                            css={getShipmentDetailsSidebarSectionTooltipStyles}
                            data-id="shipment-details-sidebar-section-tooltip"
                        >
                            {tooltip}
                        </InformationTooltip>
                    )}
                </Typography>
            )}
            <ul css={getShipmentDetailsSidebarSectionListStyles}>
                {React.Children.map(children, (child, index) => (
                    <li
                        key={`shipment-details-sidebar-section-${index}`}
                        css={getShipmentDetailsSidebarSectionListItemStyles}
                    >
                        {child}
                    </li>
                ))}
            </ul>
        </section>
    );
}
