import useUnmount from 'react-use/lib/useUnmount';
import { Trans } from '@packlink/translation-provider';
import { videoStyles } from './VideoStyles';
import { Next } from '@shipengine/giger';
import { SyntheticEvent, useRef, VideoHTMLAttributes, useState, useCallback } from 'react';
import { captureException } from '@utils/logger';

type VideoProps = VideoHTMLAttributes<HTMLVideoElement> & {
    onCleanup?: (duration: number) => void;
    fallbackSrc?: string;
    fallbackPoster?: string;
    type?: string;
    children?: React.ReactNode;
};

interface IVideoStatus {
    isPlaying: boolean;
    duration: number;
    lastTimeStamp: number;
}

function timeStampToSeconds(elapsed: number) {
    return Math.floor(elapsed / 1000);
}

export function Video({
    src,
    controls = true,
    children,
    onPlay,
    onPause,
    onCleanup,
    fallbackPoster,
    fallbackSrc,
    type = 'video/mp4',
    ...rest
}: VideoProps): JSX.Element {
    const videoStatusRef = useRef<IVideoStatus>({ isPlaying: false, duration: 0, lastTimeStamp: 0 });
    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoSrc, setVideoSrc] = useState<string | undefined>(src);

    const handleOnPlay = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
        videoStatusRef.current.isPlaying = true;
        videoStatusRef.current.lastTimeStamp = Date.now();
        onPlay?.(e);
    };

    const handleOnPause = (e: SyntheticEvent<HTMLVideoElement, Event>) => {
        const now = Date.now();
        videoStatusRef.current.isPlaying = false;
        videoStatusRef.current.duration += timeStampToSeconds(now - videoStatusRef.current.lastTimeStamp);
        videoStatusRef.current.lastTimeStamp = now;
        onPause?.(e);
    };

    useUnmount(() => {
        if (videoStatusRef.current.isPlaying) {
            videoStatusRef.current.duration += timeStampToSeconds(Date.now() - videoStatusRef.current.lastTimeStamp);
        }

        onCleanup?.(videoStatusRef.current.duration);
    });

    const sendAssetError = (assetUrl: string, fallbackUrl?: string) => {
        captureException(
            new Error(`Error getting asset ${assetUrl} - fallbackUrl: ${fallbackUrl ? fallbackUrl : 'undefined'}`),
        );
    };

    const onVideoError = useCallback(
        (error: SyntheticEvent<HTMLVideoElement, Event>) => {
            error.stopPropagation();
            const element = error.target as HTMLVideoElement;

            if (!fallbackPoster || element.poster === fallbackPoster) {
                sendAssetError(element.poster, fallbackPoster);
                return;
            }
            element.poster = fallbackPoster;

            videoRef.current?.load();
        },
        [fallbackPoster],
    );

    const onSourceError = useCallback(
        (error: SyntheticEvent<HTMLSourceElement, Event>) => {
            error.stopPropagation();
            const element = error.target as HTMLSourceElement;

            if (!fallbackSrc || element.src === fallbackSrc) {
                sendAssetError(element.src, fallbackSrc);
                return;
            }
            setVideoSrc(fallbackSrc);
            videoRef.current?.load();
        },
        [fallbackSrc],
    );

    return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
            ref={videoRef}
            css={videoStyles}
            {...rest}
            onPlay={handleOnPlay}
            onPause={handleOnPause}
            onError={onVideoError}
            crossOrigin="anonymous"
            controls={controls}
        >
            <source src={videoSrc} type={type} data-id="video-source" onError={onSourceError} />
            {children}
            <Trans
                i18nKey="video.text.no-support"
                components={[<Next.Link key="link" target="_blank" href={videoSrc} />]}
            />
        </video>
    );
}
