import React from 'react';
import { Button, ButtonVariant, Icon, IconSize } from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { useTranslation } from '@packlink/translation-provider';

interface ViewMoreLessButtonProps {
    isMoreActive?: boolean;
    handleOnClick: () => void;
}

export const ViewMoreLessButton: React.FC<ViewMoreLessButtonProps> = ({
    isMoreActive = false,
    handleOnClick,
}: ViewMoreLessButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Button variant={ButtonVariant.TEXT} onClick={handleOnClick} data-id="button-side-more">
            <Icon
                name={isMoreActive ? IconNames.DASH : IconNames.PLUS}
                size={isMoreActive ? IconSize.SIZE_REGULAR : IconSize.SIZE_SMALL}
            />
            <span>{t(isMoreActive ? 'service-list.button.less' : 'service-list.button.more')}</span>
        </Button>
    );
};
