import { ButtonColor, ButtonSize, ButtonVariant, Next, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { useGetHelpUrl } from '@SupportCenter/hooks/useGetHelpUrl';
import { useAmplitude } from '@hooks/useAmplitude';

type ContactSupportProps = WithCommonProps<{
    packlinkReference?: string;
}>;

export function ContactSupport({ packlinkReference, ...props }: ContactSupportProps): JSX.Element {
    const { t } = useTranslation();
    const { triggerEvents } = useContactSupportHandlers();
    const supportUrl = useGetHelpUrl({ shipmentReference: packlinkReference });
    return (
        <Next.Link
            href={supportUrl}
            isButton
            buttonProps={{
                size: ButtonSize.SMALL,
                variant: ButtonVariant.OUTLINED,
                color: ButtonColor.PRIMARY,
                isFullWidth: true,
            }}
            onClick={triggerEvents}
            target="_blank"
            isExternal
            {...props}
        >
            {t('post-sale.actions.contact-costumer-support')}
        </Next.Link>
    );
}

function useContactSupportHandlers() {
    const { sendAmplitudeEvent } = useAmplitude();

    const triggerEvents = () => {
        sendAmplitudeEvent(
            `${AmplitudeEvents.CONTACT_SUPPORT} ${AmplitudeEventPrefixes.CONFIRMATION_PAGE}` as AmplitudeEvents,
        );
    };

    return {
        triggerEvents,
    };
}
