import { useTenantConfig } from '@packlink/tenant-config-provider';
import { ILanguageNode, useGetLanguages, useTranslationProviderStatus } from '@packlink/translation-provider';
import { useEffect, useState } from 'react';
import { ITenantConfig } from '@types';

export const useAvailableLanguages = () => {
    const { loading: translationsLoading } = useTranslationProviderStatus();
    const getAvailableLanguages = useGetLanguages();
    const { availableLanguages: tenantAvailableLanguages } = useTenantConfig<ITenantConfig>();
    const [availableLanguages, setAvailableLanguages] = useState<ILanguageNode[]>([]);

    useEffect(() => {
        if (translationsLoading) return;

        getAvailableLanguages((languages) => {
            if (languages.length) {
                const availableAndSupportedLanguages = languages.filter((language) =>
                    tenantAvailableLanguages.includes(language.locale),
                );
                setAvailableLanguages(availableAndSupportedLanguages);
            }
        });
    }, [getAvailableLanguages, translationsLoading, tenantAvailableLanguages]);

    return { availableLanguages };
};
