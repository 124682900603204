import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSubscriptionPlanStyles = (theme: Theme): SerializedStyles =>
    css({
        padding: theme.spacing(5),
        width: theme.breakPoints.mobileLarge,
        boxShadow: theme.getElevation('above').boxShadow,
        backgroundColor: theme.palette.white,

        [theme.mediaQuery('tablet')]: {
            width: 634,
        },
    });

export const getHeaderStyles = (): SerializedStyles =>
    css({
        display: 'flex',
    });

export const getImageStyles = (theme: Theme): SerializedStyles =>
    css({
        height: 100,
        marginRight: theme.spacing(5),
    });

export const getHeadingStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(),
    });

export const getResetMarginStyles = (): SerializedStyles =>
    css({
        margin: 0,
    });

export const getButtonStyles = (theme: Theme): SerializedStyles =>
    css({
        marginTop: theme.spacing(4),
    });

export const gerDividerStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px 0`,
    });

export const getOfferStyles = (theme: Theme): SerializedStyles =>
    css({
        paddingTop: `${theme.spacing(3)}px`,
        margin: `${theme.spacing(4)}px 0 ${theme.spacing()}px 0`,
    });
