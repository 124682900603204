import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { flushSync } from 'react-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';
import useInfiniteScroll, { UseInfiniteScrollHookResult } from 'react-infinite-scroll-hook';
import BluebirdPromise from 'bluebird';
import Packlink from '@sdk';
import { PanelName } from '@types';
import { AppDispatch } from '@store';
import { ShipmentExportPanel } from '@components/ShipmentExport/ShipmentExportPanel';
import { ShipmentFiltersPanel } from '@components/ShipmentFilters/ShipmentFiltersPanel';
import { ShipmentRow } from '@components/ShipmentList/ShipmentRow/ShipmentRow';
import { logSdkError } from '@utils/logger';
import { ApiClientError, Inbox, IProShipmentPage, Order, ProShipment } from '@packlink/packlink-sdk';
import { Spinner, SpinnerSize, useToast } from '@shipengine/giger';
import { AmplitudeEventPrefixes, AmplitudeEvents } from '@constants/amplitude';
import { GTMSectionType } from '@constants/gtm';
import { hasFilters } from '@constants/shipmentFilters';
import { clearActivePanel } from '@store/actions/side-panel';
import { cleanLocalPaymentFallback } from '@store/actions/payment';
import { cleanShipments, handleShipments, PAGE_LIMIT, setBulkPagination } from '@store/actions/bulk';
import { hasTokenizationParams } from '@store/selectors/payment';
import { getIsNewUser } from '@store/selectors/inbox';
import { getBulkPagination, getBulkSortBy, getShipments } from '@store/selectors/bulk';

import { ShipmentPanel } from './ShipmentPanel/ShipmentPanel';
import { ListEmptyState } from './EmptyState/ListEmptyState';
import { FilterEmptyState } from './EmptyState/FilterEmptyState';
import { NewUserEmptyState } from './EmptyState/NewUserEmptyState';
import { ContentEvent, ContentState, reducer } from './ShipmentListContentReducer';
import { ShipmentListError } from './ShipmentListError';
import { GTMEvents, GTMPageType } from '@packlink/metrics';
import { useSidePanel } from '@hooks/useSidePanel';
import { inboxToRoute } from '@pages/router/utils/paths';
import { APP_ROUTE } from '@pages/router/routes';
import { CheckoutRoute } from '@pages/checkout/routes';
import { useSelectedForPayment } from './hooks/useSelectedForPayment';
import { useNavigate } from 'react-router';
import {
    getShipmentListContentLoadingStyles,
    getShipmentListContentStyles,
    getShipmentListContentWrapperStyles,
    getSpinnerStyles,
} from './ShipmentListContentStyles';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useAmplitude } from '@hooks/useAmplitude';

interface ShipmentListContentProps {
    inbox: Inbox;
    filters?: Record<string, unknown>;
}

export const ShipmentListContent = ({ inbox, filters }: ShipmentListContentProps): JSX.Element => {
    const dispatch = useDispatch<AppDispatch>();
    const [contentState, setContentState] = useReducer(reducer, ContentState.LOADING);
    const shipments = useSelector(getShipments);
    const isNewUser = useSelector(getIsNewUser);
    const { currentPage, totalPages } = useSelector(getBulkPagination);
    const hasPaymentWithTokenizationParams = useSelector(hasTokenizationParams);
    const currentSortBy = useSelector(getBulkSortBy);
    const { open: openFiltersPanel } = useSidePanel(PanelName.FILTERS);
    const getShipmentsPromise = useRef<BluebirdPromise<IProShipmentPage<ProShipment>>>();
    const { t } = useTranslation();
    const toast = useToast(t);
    const navigate = useNavigate();
    const { sendGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeClickEvent, sendAmplitudeTableViewClickEvent, sendAmplitudeTableViewEvent } = useAmplitude();

    const isLoading = [ContentState.LOADING, ContentState.LOADING_NEXT_PAGE].includes(contentState);

    const hasNextPage = useMemo((): boolean => {
        return currentPage < totalPages;
    }, [currentPage, totalPages]);

    useSelectedForPayment(contentState);

    const getShipmentsPage = useCallback(
        (page: number) => {
            setContentState(ContentEvent.LOAD);

            const params = {
                inbox,
                limit: PAGE_LIMIT,
                offset: (page - 1) * PAGE_LIMIT,
                order: currentSortBy,
                ...filters,
            };

            if (getShipmentsPromise.current?.isPending()) {
                getShipmentsPromise.current?.cancel();
            }

            getShipmentsPromise.current = Packlink.pro.shipments.getShipments(params);

            return getShipmentsPromise.current
                .then((response: IProShipmentPage<ProShipment>): void => {
                    batch(() => {
                        dispatch(setBulkPagination(response.pagination));
                        dispatch(handleShipments(response.content, false));

                        if (response.content.length > 0) {
                            setContentState(ContentEvent.SHIPMENT_LIST);
                        } else if (hasFilters(filters)) {
                            sendAmplitudeTableViewEvent(AmplitudeEvents.NO_RESULTS);
                            setContentState(ContentEvent.EMPTY_FILTER);
                        } else {
                            setContentState(ContentEvent.EMPTY);
                        }
                    });
                })
                .catch((error: ApiClientError): void => {
                    setContentState(ContentEvent.ERROR);

                    if (error.status !== -1) {
                        logSdkError(error);
                    }

                    if (page > 1) {
                        toast.error({ message: t('shipment-list.error.fetching-shipments') });
                    }
                });
        },
        [currentSortBy, dispatch, filters, inbox, sendAmplitudeTableViewEvent, t, toast],
    );

    const handleLoadMore = (): void => {
        setContentState(ContentEvent.NEXT_PAGE);
        getShipmentsPage(currentPage + 1);
    };

    const tryAgain = (): void => {
        getShipmentsPage(currentPage === 0 ? 1 : currentPage);
    };

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: hasNextPage && contentState !== ContentState.ERROR_NEXT_PAGE,
        onLoadMore: handleLoadMore,
        // This triggers the next page 500px (aprox. 4 rows) before the user gets to the end of the list
        rootMargin: '0px 0px 500px 0px',
    });

    const newShipment = (clickText: string, sectionId: string): void => {
        const eventName = `${AmplitudeEvents.CREATE_SHIPMENT} ${AmplitudeEventPrefixes.EMPTY_STATE}` as AmplitudeEvents;
        sendAmplitudeClickEvent(eventName);

        sendGtmEvent(GTMEvents.BUTTON_CLICK, {
            sectionId,
            sectionType: GTMSectionType.TABLE,
            pageType: GTMPageType.TABLE_PRO,
            clickText,
        });

        navigate(APP_ROUTE.CHECKOUT.STEP.replace(':step', CheckoutRoute.INFO), { state: { isNew: true } });
    };

    const connectShop = (clickText: string, sectionId: string): void => {
        const eventName = `${AmplitudeEvents.INTEGRATIONS} ${AmplitudeEventPrefixes.EMPTY_STATE}` as AmplitudeEvents;
        sendAmplitudeClickEvent(eventName);

        sendGtmEvent(GTMEvents.BUTTON_CLICK, {
            sectionId,
            sectionType: GTMSectionType.TABLE,
            pageType: GTMPageType.TABLE_PRO,
            clickText,
        });
        navigate(APP_ROUTE.SETTINGS.INTEGRATIONS);
    };

    const editFilters = (): void => {
        sendAmplitudeTableViewClickEvent(AmplitudeEvents.EDIT_FILTERS);
        openFiltersPanel();
    };

    const resetFilters = (): void => {
        sendAmplitudeTableViewClickEvent(AmplitudeEvents.DELETE_FILTERS);
        navigate(inboxToRoute(inbox));
    };

    const handlePaymentSuccess = (order: Order): void => {
        if (order.shipments.length === 1) {
            navigate(APP_ROUTE.SHIPMENTS.DETAILS.replace(':id', order.shipments[0].shipmentReference));
        } else {
            navigate(APP_ROUTE.SHIPMENTS.BULK_SUMMARY, { state: { order } });
        }
    };

    const cleanState = useCallback(() => {
        if (!hasPaymentWithTokenizationParams) {
            batch(() => {
                dispatch(clearActivePanel());
                dispatch(cleanShipments());
                dispatch(cleanLocalPaymentFallback());
            });
        }
    }, [dispatch, hasPaymentWithTokenizationParams]);

    useEffect(() => {
        // These can happen due to pusher events, for example deleting the last shipment of the list
        // or importing shipments of an integration
        if (shipments.length > 0 && [ContentState.NEW_USER, ContentState.EMPTY].includes(contentState)) {
            setContentState(ContentEvent.SHIPMENT_LIST);
        } else if (shipments.length === 0 && contentState === ContentState.SHIPMENTS) {
            if (hasNextPage) {
                // To get to this point, the user has deleted all shipments in the view,
                // but there are still more shipments left, so we ask for the new first page.
                getShipmentsPage(1);
            } else {
                setContentState(ContentEvent.EMPTY);
            }
        }
    }, [contentState, currentPage, getShipmentsPage, hasNextPage, shipments.length, totalPages]);

    useEffect(() => {
        const getShipmentsPageDebounced = debounce((): void => {
            getShipmentsPage(1);
        }, 800);

        // Instantly change to loading when requesting 1st page
        queueMicrotask(() => {
            flushSync(() => {
                setContentState(ContentEvent.LOAD);
            });
        });

        cleanState();
        getShipmentsPageDebounced();

        return (): void => {
            if (getShipmentsPromise.current?.isPending()) {
                getShipmentsPromise.current.cancel();
            }
        };
    }, [getShipmentsPage, cleanState]);

    return (
        <>
            {/* This wrapper is needed for the infinite scroll to properly work */}
            <div css={getShipmentListContentWrapperStyles} ref={rootRef}>
                <Content
                    shipments={shipments}
                    contentState={contentState}
                    isNewUser={isNewUser}
                    sentryRef={sentryRef}
                    newShipment={newShipment}
                    connectShop={connectShop}
                    editFilters={editFilters}
                    resetFilters={resetFilters}
                    tryAgain={tryAgain}
                />

                {isLoading && (
                    <div css={getShipmentListContentLoadingStyles}>
                        <Spinner size={SpinnerSize.SIZE_SMALL} data-id="shipments-loading" css={getSpinnerStyles} />
                    </div>
                )}
            </div>

            <ShipmentPanel onPaymentSuccess={handlePaymentSuccess} />
            <ShipmentFiltersPanel />
            <ShipmentExportPanel />
        </>
    );
};

interface IContentProps {
    shipments: ProShipment[];
    contentState: ContentState;
    isNewUser: boolean;
    sentryRef: UseInfiniteScrollHookResult[0];
    newShipment: (clickText: string, sectionId: string) => void;
    connectShop: (clickText: string, sectionId: string) => void;
    editFilters: () => void;
    resetFilters: () => void;
    tryAgain: () => void;
}

const Content = ({
    shipments,
    contentState,
    isNewUser,
    sentryRef,
    newShipment,
    connectShop,
    editFilters,
    resetFilters,
    tryAgain,
    ...rest
}: IContentProps): JSX.Element | null => {
    switch (contentState) {
        case ContentState.SHIPMENTS:
        case ContentState.LOADING_NEXT_PAGE:
        case ContentState.ERROR_NEXT_PAGE:
            return (
                <div css={getShipmentListContentStyles}>
                    {shipments.map(
                        (shipment: ProShipment, index: number): JSX.Element => (
                            <ShipmentRow
                                key={shipment.data.packlinkReference}
                                shipment={shipment}
                                index={index}
                                {...rest}
                            />
                        ),
                    )}

                    {/* This is the element that useInfiniteScroll checks to load next page when it's visible */}
                    <div ref={sentryRef}></div>
                </div>
            );
        case ContentState.EMPTY:
            // This prevents some re-renders that causes a re-fetch of shipments.
            // Previously we were setting setContentState(ContentEvent.NEW_USER) inside getShipmentsPage
            if (isNewUser) {
                return <NewUserEmptyState onNewShipment={newShipment} onConnectShop={connectShop} />;
            } else {
                return <ListEmptyState onNewShipment={newShipment} onConnectShop={connectShop} />;
            }
        case ContentState.EMPTY_FILTER:
            // This prevents some re-renders that causes a re-fetch of shipments.
            // Previously we were setting setContentState(ContentEvent.NEW_USER) inside getShipmentsPage
            if (isNewUser) {
                return <NewUserEmptyState onNewShipment={newShipment} onConnectShop={connectShop} />;
            } else {
                return <FilterEmptyState editFilters={editFilters} resetFilters={resetFilters} />;
            }
        case ContentState.ERROR:
            return <ShipmentListError onTryAgain={tryAgain} />;
        default:
            return null;
    }
};
