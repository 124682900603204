import { useState } from 'react';

export enum Status {
    ERROR = 'error',
    LOADING = 'loading',
    READY = 'ready',
}

export interface IUseStatus {
    status?: Status;
    setStatus: React.Dispatch<React.SetStateAction<Status | undefined>>;
    isError: boolean;
    isLoading: boolean;
    isReady: boolean;
}

export const useStatus = (initialState?: Status | (() => Status | undefined)): IUseStatus => {
    const [status, setStatus] = useState(initialState);

    return {
        status,
        setStatus,
        isError: status === Status.ERROR,
        isLoading: status === Status.LOADING,
        isReady: status === Status.READY,
    };
};
