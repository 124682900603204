import { User } from '@packlink/packlink-sdk';
import { SET_USER_DATA } from '@store/actions/action-types';

export type UserAction = ISetUserAction;

export interface ISetUserAction {
    payload: User;
    type: 'SET_USER_DATA';
}

export const setUser = (payload: User): ISetUserAction => ({ payload, type: SET_USER_DATA });
