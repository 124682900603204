import { WithCommonProps } from '@shipengine/giger';
import { getConnectionCardListStyles } from './ConnectionCardListStyles';

export interface ICardListProps {
    children: React.ReactNode;
}

export const ConnectionCardList: React.FC<WithCommonProps<ICardListProps>> = ({
    children,
    ...rest
}: ICardListProps): JSX.Element => {
    return (
        <section css={getConnectionCardListStyles()} {...rest}>
            {children}
        </section>
    );
};
