import { Outlet } from 'react-router';
import { SettingsMenu } from '../SettingsMenu/SettingsMenu';
import { settingsLayoutContentStyles, settingsLayoutStyles } from './SettingsLayoutStyles';

export function SettingsLayout(): JSX.Element {
    return (
        <main css={settingsLayoutStyles} id="settings-panel-container">
            <SettingsMenu />

            <section css={settingsLayoutContentStyles}>
                <Outlet />
            </section>
        </main>
    );
}
