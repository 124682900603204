import { Theme } from '@shipengine/giger-theme';
import { css } from '@emotion/react';

export function getPageTitleStyles(theme: Theme) {
    return css({
        color: theme.palette.primary.main,
        textAlign: 'center',
        width: '95%',

        [theme.mediaQuery('tablet')]: {
            width: '100%',
        },
    });
}

export function getOnboardingPageBackgroundStyles(theme: Theme) {
    return css({
        position: 'relative',
        display: 'block',
        width: '100%',
        height: '100%',

        // TO-DO: PCK-4474 Refactor this, so it doesn't have to be a pseudo-element
        [theme.mediaQuery('tablet')]: {
            '::before': {
                content: '""',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '5vh',
                zIndex: '-1',
                backgroundImage: `url('${config.cdn.url}/pro/statics/shapes/onboarding-background.png')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '89vw 75vh',
                backgroundPosition: 'center',
                transform: 'rotate(180deg)',
            },
        },
    });
}
