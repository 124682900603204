import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export function getCollapseStyles(collapse: boolean): SerializedStyles {
    return css({
        height: collapse ? '0' : '200px',
        overflow: 'hidden',
        transition: 'height 0.5s ease-in-out',
    });
}

export function getToggleButtonStyles(theme: Theme): SerializedStyles {
    return css({
        color: theme.palette.primary.main,
    });
}

export function getTableStyles(): SerializedStyles {
    return css({
        margin: 0,
        padding: 0,
        textAlign: 'left',
    });
}

export function getWeekdayStyles(theme: Theme): SerializedStyles {
    return css({
        textTransform: 'capitalize',
        padding: `${theme.spacing(0.5)}px ${theme.spacing(3)}px ${theme.spacing(0.5)}px 0`,
    });
}
