/* eslint-disable jsx-a11y/no-autofocus */
import React, { ChangeEvent, useEffect, useState } from 'react';
import * as yup from 'yup';

import { useTranslation } from '@packlink/translation-provider';
import { FieldMessage, FieldMessageType, Input } from '@shipengine/giger';
import { Currency } from '@packlink/packlink-sdk';
import { getContentValueValidation } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelContent/ShipmentPanelContentInfo';

import { getContentValueStyles } from './ContentValueStyles';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { useAmplitude } from '@hooks/useAmplitude';

export interface IContentValueProps {
    value: string;
    currency: string;
    submitError?: string;
    onChange: (value: string) => void;
}

export const ContentValue: React.FC<IContentValueProps> = (props: IContentValueProps): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();

    const { value: initialValue, currency, onChange, submitError } = props;

    const inputName = 'value';

    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        setError(submitError);
    }, [submitError]);

    const onBlurHandler = (): void => {
        sendAmplitudeEvent(AmplitudeEvents.CLICK_INSURANCE_VALUE, {
            [AmplitudeProperties.VALUE]: value,
        });
        validate();
    };

    const onChangeHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
        setValue(value);
        setError(undefined);
        onChange(value);
    };

    const validationSchema = yup.object().shape({
        [inputName]: getContentValueValidation(t),
    });

    const validate = async (): Promise<void> => {
        validationSchema
            .validate({
                [inputName]: value,
            })
            .then(() => setError(''))
            .catch((yupError: yup.ValidationError) => {
                setError(yupError.message);
            });
    };

    return (
        <>
            <Input
                data-id={inputName}
                name={inputName}
                type="number"
                label={t('content-panel.content.value')}
                inputMode="numeric"
                required
                isInvalid={!!error}
                rightContent={Currency.getCurrencySymbol(currency)}
                value={value}
                min="0.1"
                step="0.01"
                maxLength={9}
                pattern="^[0-9]*(.[0-9]{0,2})?$"
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                css={getContentValueStyles}
            />
            {error && <FieldMessage type={FieldMessageType.ERROR}>{error}</FieldMessage>}
        </>
    );
};
