import { useTranslation } from '@packlink/translation-provider';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import { FormField, SelectAutocomplete, Typography } from '@shipengine/giger';
import { formSectionStyles, fieldStyles, ruleRowStyles } from '../RuleForm/RuleFormStyles';
import { IFormRow, IRuleForm, IShippingRuleOption } from '../../types/formTypes';
import { getActionFieldName, getFormFieldMessage } from '../../utils/form';
import { useFormData } from '../../hooks/useFormData';
import { ValueField } from '../ValueField/ValueField';
import { textConditionStyles } from './RuleActionStyles';

export function RuleAction({ isFormBlocked = false }: IFormRow): JSX.Element {
    const { t } = useTranslation();
    const { actionOptions } = useFormData();
    const { handleChange, values, errors, touched } = useFormikContext<IRuleForm>();

    const handleSelectorChange = (name: string, value: IShippingRuleOption) => {
        handleChange({ target: { name: name, value } });
    };

    const handleIdentifierChange = (name: string, value: IShippingRuleOption | null, index: number) => {
        handleChange({
            target: {
                name: getActionFieldName(index, 'value'),
                value: { value: value?.defaultValue ?? '', label: '' },
            },
        });

        handleSelectorChange(name, value ?? { value: '', label: '' });
    };

    return (
        <div css={formSectionStyles}>
            <Typography variant="subtitle1" component="p">
                {t('shipping-rules.form.action')}
            </Typography>

            <FieldArray
                name="actions"
                render={(_arrayProps: FieldArrayRenderProps) => (
                    <>
                        {values.actions.map((action, index) => (
                            <div css={ruleRowStyles} key={index}>
                                <Typography css={textConditionStyles} variant="small">
                                    {t('shipping-rules.form.then')}
                                </Typography>

                                <div css={fieldStyles}>
                                    <FormField
                                        message={getFormFieldMessage(
                                            getActionFieldName(index, 'identifier'),
                                            errors,
                                            touched,
                                        )}
                                    >
                                        <SelectAutocomplete
                                            name={getActionFieldName(index, 'identifier')}
                                            options={actionOptions}
                                            placeholder={t('shipping-rules.form.select-property')}
                                            label={t('shipping-rules.form.select-property')}
                                            showLabel={false}
                                            onChange={(name: string, value: IShippingRuleOption | null) =>
                                                handleIdentifierChange(name, value, index)
                                            }
                                            value={action.identifier}
                                            noResultsLabel={t('autocomplete.messages.no-results-found')}
                                            disabled={isFormBlocked}
                                            isInvalid={
                                                !!getFormFieldMessage(
                                                    getActionFieldName(index, 'identifier'),
                                                    errors,
                                                    touched,
                                                )
                                            }
                                        />
                                    </FormField>
                                </div>

                                <div css={fieldStyles}>
                                    <ValueField
                                        item={action}
                                        fieldPrefix={getActionFieldName(index)}
                                        identifier={action.identifier.value}
                                        disabled={!action.identifier.value || isFormBlocked}
                                    />
                                </div>
                            </div>
                        ))}
                    </>
                )}
            />
        </div>
    );
}
