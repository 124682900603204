import { Breadcrumb, Typography, WithCommonProps } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { RouterCrumb, RouterCrumbProps } from '@SupportCenter/components/Router/RouterCrumb';
import { RouteData } from '@SupportCenter/pages/routes';
import { getSupportHeaderTitleStyles } from './SupportCenterHeaderTitleStyles';
import { APP_ROUTE } from '@pages/router/routes';

type SupportCenterHeaderTitleProps = WithCommonProps<{
    activeRoutes: RouteData[];
}>;

export function SupportCenterHeaderTitle({ activeRoutes, ...props }: SupportCenterHeaderTitleProps): JSX.Element {
    const breadcrumbs = useBreadcrumbs(activeRoutes);

    if (breadcrumbs.length === 1) {
        return (
            <Typography variant="heading4" css={getSupportHeaderTitleStyles} {...props}>
                {breadcrumbs[0].name}
            </Typography>
        );
    } else {
        return (
            <Breadcrumb {...props}>
                {breadcrumbs.map((props) => (
                    <RouterCrumb {...props} key={props.href} />
                ))}
            </Breadcrumb>
        );
    }
}

function useBreadcrumbs(activeRoutes: RouteData[]): RouterCrumbProps[] {
    const { t } = useTranslation();

    const rootRoute = {
        path: APP_ROUTE.SUPPORT_CENTER.INDEX,
        name: t('support-center.routes.root'),
    };
    return [rootRoute, ...activeRoutes].map(routeToBreadcrumb);
}

function routeToBreadcrumb(route: RouteData): RouterCrumbProps {
    return {
        name: route.name,
        href: route.path,
    };
}
