import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getIntegrationDeactivateStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(3),

        '.btn': {
            padding: 0,
        },
    });

export const getIntegrationDeactivateMessageStyles = (): SerializedStyles => css({ margin: 0 });

export const getIntegrationDeactivateConnectionMessageStyles = (theme: Theme): SerializedStyles =>
    css({
        color: theme.palette.gray.dark,
        fontWeight: theme.typography.fontWeight.semibold,
    });
