import { PropsWithChildren, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/core';
import { useSdk } from '@packlink/packlink-sdk-provider';
import {
    ApiClientError,
    SubscriptionFeatureBehaviours,
    SubscriptionsRepository,
    SubscriptionState,
} from '@packlink/packlink-sdk';

import {
    FeatureBehaviourUpsertEventData,
    SubscriptionUpsertEventData,
    useFeatureBehaviourUpsertEvent,
    useSubscriptionPlanFeatures,
    useSubscriptionUpsertEvent,
    useTenantSubscriptionConfig,
} from '../hooks';
import {
    DEFAULT_CONTEXT_VALUE,
    SubscriptionFeatureBehavioursContext,
    SUBSCRIPTION_FEATURE_BEHAVIOURS_CACHE_KEY,
} from './SubscriptionFeatureBehavioursContext';

export type SubscriptionFeatureBehavioursProviderProps = PropsWithChildren<{
    clientId: string;
}>;

export function SubscriptionFeatureBehavioursProvider({
    clientId,
    children,
}: SubscriptionFeatureBehavioursProviderProps): JSX.Element {
    const { data, isLoading } = useSubscriptionFeatureBehaviours();
    const availableFeaturesData = useSubscriptionPlanFeatures();

    useEvents(clientId);

    return (
        <SubscriptionFeatureBehavioursContext.Provider
            value={{ ...DEFAULT_CONTEXT_VALUE, ...data, ...availableFeaturesData, isLoading }}
        >
            {children}
        </SubscriptionFeatureBehavioursContext.Provider>
    );
}

function useSubscriptionFeatureBehaviours() {
    const { isTenantSubscriptionEnabled } = useTenantSubscriptionConfig();
    const sdk = useSdk();
    const subscriptionsRepository = new SubscriptionsRepository(sdk.apiClient);

    const response = useQuery(
        [SUBSCRIPTION_FEATURE_BEHAVIOURS_CACHE_KEY],
        () => subscriptionsRepository.getSubscriptionFeatureBehaviours(),
        {
            enabled: isTenantSubscriptionEnabled,
            refetchOnMount: false,
            staleTime: 150,
            onError: (error: ApiClientError) => {
                Sentry.captureException(error);
            },
        },
    );

    return response;
}

function useEvents(clientId: string) {
    const { isTenantSubscriptionEnabled } = useTenantSubscriptionConfig();
    const { eventBind: featureEventBind, eventUnbind: featureEventUnbind } = useFeatureBehaviourUpsertEvent(clientId);
    const { eventBind: subscriptionEventBind, eventUnbind: subscriptionEventUnbind } =
        useSubscriptionUpsertEvent(clientId);

    const queryClient = useQueryClient();

    useEffect(() => {
        if (!isTenantSubscriptionEnabled) return;

        function updateFeatureBehaviour(eventData: FeatureBehaviourUpsertEventData) {
            queryClient.setQueryData<SubscriptionFeatureBehaviours>(
                SUBSCRIPTION_FEATURE_BEHAVIOURS_CACHE_KEY,
                (cacheData) => {
                    const newFeature = {
                        [eventData.feature_name]: {
                            featureName: eventData.feature_name,
                            behaviourProperties: eventData.behaviour_properties,
                        },
                    };

                    const newData: SubscriptionFeatureBehaviours = {
                        ...cacheData,
                        clientFeatureBehaviours: {
                            ...cacheData?.clientFeatureBehaviours,
                            ...newFeature,
                        },
                    };

                    !eventData.enabled && delete newData.clientFeatureBehaviours[eventData.feature_name];

                    return newData;
                },
            );
        }

        featureEventBind(updateFeatureBehaviour);

        return () => {
            featureEventUnbind(updateFeatureBehaviour);
        };
    }, [featureEventBind, featureEventUnbind, isTenantSubscriptionEnabled, queryClient]);

    useEffect(() => {
        if (!isTenantSubscriptionEnabled) return;

        function updateSubscription(eventData: SubscriptionUpsertEventData) {
            const data = queryClient.getQueryData<SubscriptionFeatureBehaviours>(
                SUBSCRIPTION_FEATURE_BEHAVIOURS_CACHE_KEY,
            );

            // In case there are updates of older subscriptions, we update only the one in use.
            if (data?.subscription && data.subscription.id !== eventData.id) return;

            queryClient.setQueryData<SubscriptionFeatureBehaviours>(
                SUBSCRIPTION_FEATURE_BEHAVIOURS_CACHE_KEY,
                (cacheData) => {
                    const newData: SubscriptionFeatureBehaviours = {
                        subscription: {
                            id: eventData.id,
                            planName: eventData.plan_name,
                            planCode: eventData.plan_code,
                            currentPeriodEndsAt: eventData.current_period_ends_at,
                            state: eventData.state,
                            isActive: eventData.state === SubscriptionState.ACTIVE,
                            isCanceled: eventData.state === SubscriptionState.CANCELED,
                            isActiveOrCanceled: [SubscriptionState.ACTIVE, SubscriptionState.CANCELED].includes(
                                eventData.state,
                            ),
                            isExpired: eventData.state === SubscriptionState.EXPIRED,
                        },
                        clientFeatureBehaviours: cacheData?.clientFeatureBehaviours || {},
                    };

                    return newData;
                },
            );
        }

        subscriptionEventBind(updateSubscription);

        return () => {
            subscriptionEventUnbind(updateSubscription);
        };
    }, [subscriptionEventBind, subscriptionEventUnbind, isTenantSubscriptionEnabled, queryClient]);
}
