import { useTranslation } from '@packlink/translation-provider';
import { Typography } from '@shipengine/giger';
import { getContainerStyles, getHeaderStyles } from './EmailTemplatesListStyles';
import { EmailTemplatesFooter } from './components/EmailTemplatesFooter/EmailTemplatesFooter';
import { EmailTemplateListItem } from './components/EmailTemplateListItem/EmailTemplateListItem';
import { BrandedEmailsSettingsRepository, BrandedSettings } from '@packlink/packlink-sdk';
import { useQuery } from 'react-query';
import { BRANDED_EMAILS_CACHE_KEY } from './constants';
import { useSdk } from '@packlink/packlink-sdk-provider';

export interface EmailTemplatesListProps {
    onEdit: (settings?: BrandedSettings) => void;
    onToggle?: (active: boolean) => void;
    subscribed: boolean;
}

export function EmailTemplatesList({ onEdit, onToggle, subscribed }: EmailTemplatesListProps): JSX.Element {
    const { t } = useTranslation();
    const { apiClient } = useSdk();
    const brandedEmailsSettingsRepository = new BrandedEmailsSettingsRepository(apiClient);

    const { data: brandedEmails, isLoading } = useQuery(
        BRANDED_EMAILS_CACHE_KEY,
        () => brandedEmailsSettingsRepository.getBrandedEmailsSettings(),
        { enabled: subscribed },
    );

    const editEmail = () => {
        brandedEmails && onEdit(brandedEmails.brandedSettings);
    };

    return (
        <section css={getContainerStyles}>
            <Typography component="p" variant="body1" bold css={getHeaderStyles}>
                {t('branded-emails.template-list.header')}
            </Typography>
            <Typography component="p" variant="body2">
                {t('branded-emails.template-list.subtitle')}
            </Typography>

            <section>
                {!isLoading &&
                    brandedEmails?.emails.map((template) => (
                        <EmailTemplateListItem
                            key={template.emailType}
                            template={template}
                            onEdit={editEmail}
                            onToggle={onToggle}
                            disableToggle={!brandedEmails?.brandedSettings?.companyName}
                        />
                    ))}
                <EmailTemplatesFooter subscribed={subscribed} />
            </section>
        </section>
    );
}
