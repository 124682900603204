import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getClientPlanDetailsStyles = (): SerializedStyles =>
    css({
        width: 550,
    });

export const getSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(4),
    });

export const getHeaderStyles = (theme: Theme): SerializedStyles =>
    css({
        marginBottom: theme.spacing(2),
    });
