import {
    ChartContainer,
    formatOnlyIntegersTicks,
    getChartContainerStatus,
    ResponsiveBar,
    useRequestOnIntersection,
} from '../Charts';
import { useTranslation } from '@packlink/translation-provider';
import { AnalyticsSortSeriesParam, UserAnalyticsRepository } from '@packlink/packlink-sdk';
import { apiClient } from '@sdk';
import { IconNames } from '@shipengine/giger-theme';
import { getSubtitleRangeParams } from '../../utils/dates';
import { Charts, OTHERS } from '../../constants/analytics';
import { ChartTable } from './ChartTable';
import { Tooltip } from './Tooltip';

const userAnalyticsRepository = new UserAnalyticsRepository(apiClient);

export function ShipmentsByCarrierChart(props: AnalyticsSortSeriesParam): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const requestShipmentsByCarrier = () => {
        return userAnalyticsRepository.getShipmentsByCarrier(props).then((response) => {
            const data = response[0]?.data.map((element) => ({
                ...element,
                carrier: element.carrier === OTHERS ? t('analytics.label.others') : element.carrier,
            }));

            return { ...response[0], data };
        });
    };

    const { data, status, isFetching, intersectionRef } = useRequestOnIntersection({
        requestCacheKeys: [Charts.ShipmentsByCarrier, props],
        requestData: requestShipmentsByCarrier,
    });

    const isEmpty = !data?.data?.length;
    const containerStatus = getChartContainerStatus(status, isFetching, isEmpty);

    return (
        <ChartContainer
            ref={intersectionRef}
            title={t('analytics.chart.shipments-by-carrier')}
            subtitle={t('analytics.chart.date-range', getSubtitleRangeParams(locale, props.from, props.to))}
            cdnUrl={config.cdn.baseUrl}
            status={containerStatus}
            leftComponent={<ChartTable chartData={data} />}
            rightComponent={
                <>
                    {!isEmpty && (
                        <ResponsiveBar
                            id={Charts.ShipmentsByCarrier}
                            data={data.data}
                            indexBy={'carrier'}
                            keys={['shipments']}
                            tooltip={(tooltip) => <Tooltip tooltip={tooltip} chartData={data} />}
                            axisBottom={{ tickRotation: data.data.length > 4 ? -15 : 0 }}
                            axisLeft={{
                                format: formatOnlyIntegersTicks,
                            }}
                            labelSkipHeight={15}
                        />
                    )}
                </>
            }
            leftIcon={IconNames.LIST}
            rightIcon={IconNames.CHART_UPWARDS}
            leftIconTooltip={t('analytics.tooltip.view-table')}
            rightIconTooltip={t('analytics.tooltip.view-chart')}
        />
    );
}
