import { createContext } from 'react';

import { SubscriptionFeatureBehaviours, SubscriptionPlanFeatures } from '@packlink/packlink-sdk';

export const DEFAULT_CONTEXT_VALUE: SubscriptionFeatureBehavioursContext = {
    subscription: undefined,
    clientFeatureBehaviours: {},
    availableFeatures: {},
    isLoading: true,
};

export interface SubscriptionAvailableFeatures {
    availableFeatures?: SubscriptionPlanFeatures;
}

export type SubscriptionFeatureBehavioursContext = SubscriptionFeatureBehaviours &
    SubscriptionAvailableFeatures & { isLoading: boolean };

export const SubscriptionFeatureBehavioursContext =
    createContext<SubscriptionFeatureBehavioursContext>(DEFAULT_CONTEXT_VALUE);

export const SUBSCRIPTION_FEATURE_BEHAVIOURS_CACHE_KEY = 'subscription-feture-behaviours';
export const SUBSCRIPTION_PLAN_FEATURES_CACHE_KEY = 'subscription-plan-feature';
