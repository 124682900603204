import React from 'react';
import { useFormikContext } from 'formik';
import { Pudo } from '@packlink/pudo';
import { Address, DropOff, IAddress, ProShipment } from '@packlink/packlink-sdk';
import { IRecipientForm } from './types';

import { getShipmentPanelRecipientPudoStyles } from './ShipmentPanelRecipientStyles';

interface IShipmentPanelRecipientPudoProps {
    shipmentData: ProShipment['data'];
}

export const ShipmentPanelRecipientPudo: React.FC<IShipmentPanelRecipientPudoProps> = (
    props: IShipmentPanelRecipientPudoProps,
): JSX.Element => {
    const { shipmentData } = props;
    const { values, setFieldValue } = useFormikContext<IRecipientForm>();

    const selectPickUp = (dropOff: DropOff): void => {
        setFieldValue('dropoffPointId', dropOff.id);
    };

    return (
        <Pudo
            css={getShipmentPanelRecipientPudoStyles}
            onConfirm={selectPickUp}
            address={shipmentData.to as Address<IAddress>}
            serviceId={shipmentData.service?.id?.toString() as string}
            selectedId={values.dropoffPointId}
            mediaQuery="(max-width: 0px)"
        />
    );
};
