import { CSSObject } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getEmptyStateContent = (): CSSObject => ({
    img: {
        width: '143px',
        height: '117px',
    },
});

export const getEmptyStateInfoIconStyles = (theme: Theme): CSSObject => ({
    marginRight: theme.spacing(0.5),
});

export const getEmptyStateInfoTextStyles = (theme: Theme): CSSObject => ({
    display: 'flex',
    flex: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.info.main,
});

export const getEmptyStateActionsStyles = (theme: Theme): CSSObject => ({
    display: 'block',
    width: '70%',

    [theme.mediaQuery('tablet')]: {
        display: 'flex',
        marginTop: theme.spacing(2),
    },
});

export const getEmptyStateActionsLinkStyles = (theme: Theme): CSSObject => ({
    width: '100%',
    margin: `${theme.spacing()}px 0`,

    [theme.mediaQuery('tablet')]: {
        width: '50%',
        height: 'max-content',
        marginRight: theme.spacing(2),
    },
});

export const getNewTicketLinkStyles = (theme: Theme): CSSObject => ({
    width: '35%',
    marginTop: theme.spacing(2),
});
