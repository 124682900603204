import React from 'react';
import { getShipmentRowCellStyles, getShipmentRowEditableCellStyles } from './ShipmentRowStyles';

interface IShipmentRowCell {
    isEditable: boolean;
    onEdit: (e: React.MouseEvent<HTMLParagraphElement>) => void;
    children: React.ReactNode;
}

export const ShipmentRowCell: React.FC<IShipmentRowCell> = (props: IShipmentRowCell): JSX.Element => {
    const { isEditable, onEdit, children } = props;

    const renderEditableCell = (): JSX.Element => (
        <div
            css={[getShipmentRowCellStyles(), getShipmentRowEditableCellStyles]}
            role="button"
            onClick={onEdit}
            data-id="shipment-row-cell"
        >
            {children}
        </div>
    );

    const renderNonEditableCell = (): JSX.Element => (
        <div css={getShipmentRowCellStyles()} data-id="shipment-row-non-editable">
            {children}
        </div>
    );

    return isEditable ? renderEditableCell() : renderNonEditableCell();
};
