import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getRowFooterActionStyles =
    (isOnlyActionInFooter: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            display: 'flex',
            paddingRight: theme.spacing(),
            marginRight: theme.spacing(),
            borderRight: isOnlyActionInFooter ? 'none' : `1px solid ${theme.palette.gray.light}`,
        });

export const getRowFooterActionButtonStyles = (): SerializedStyles =>
    css({
        padding: 0,
        borderWidth: 0,
    });

export const getRowFooterActionTextStyles = (theme: Theme): SerializedStyles =>
    css({
        // btn selector impossible to override .btn span + .icon
        marginLeft: `${theme.spacing()}px !important`,
        fontWeight: theme.typography.fontWeight.semibold,
    });

export const getIconPopoverStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(0.5),
    });
