import { FC } from 'react';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { AmplitudeEventsPro } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { GenerateApiKey } from '@Integrations/components/GenerateApiKey/GenerateApiKey';
import { IntegrationFormLogo } from '@Integrations/components/IntegrationForm/IntegrationFormLogo';
import { IGenericPanelContentProps } from '@Integrations/components/IntegrationPanel/types';
import { GTMEvents } from '@packlink/metrics';
import { TenantName } from '@packlink/packlink-sdk';
import { useTenantConfig } from '@packlink/tenant-config-provider';
import { useTenant } from '@packlink/tenant-provider';
import { useTranslation } from '@packlink/translation-provider';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useAmplitude } from '@hooks/useAmplitude';
import { useGetApiKey } from '@common/hooks/useApiKey';
import { ITenantConfig } from '@types';

import { BigCommerceIntegrationGuide } from './Guides/BigCommerceIntegrationGuide';
import { MagentoIntegrationGuide } from './Guides/MagentoIntegrationGuide';
import { PrestashopIntegrationGuide } from './Guides/PrestashopIntegrationGuide';
import { ShopifyIntegrationGuide } from './Guides/ShopifyIntegrationGuide';
import { ShopwareIntegrationGuide } from './Guides/ShopwareIntegrationGuide';
import { WixIntegrationGuide } from './Guides/WixIntegrationGuide';
import { ZencartIntegrationGuide } from './Guides/ZencartIntegrationGuide';
import { EcwidIntegrationGuide } from './Guides/EcwidIntegrationGuide';
import { WoocommerceIntegrationGuide } from './Guides/WoocommerceIntegrationGuide';
import { getModuleIntegrationCopyMessageStyles } from './ModuleIntegrationStyles';

type IModuleIntegrationFormProps = Omit<
    IGenericPanelContentProps,
    'onSubmit' | 'onDeactivate' | 'isLoading' | 'error' | 'clearError'
>;

export const MODULES = {
    BigCommerce: 'bigcommerce_module',
    Ecwid: 'ecwid_module',
    Magento: 'magento_module',
    Prestashop: 'prestashop_module',
    Shopify: 'shopify_module',
    Shopware: 'shopware_module',
    Wix: 'wix_module',
    Woocommerce: 'woocommerce_module',
    Zencart: 'zencart_module',
};

const DOWNLOADABLE_INTEGRATIONS = [MODULES.Zencart];

export const ModuleIntegrationForm: FC<IModuleIntegrationFormProps> = (
    props: IModuleIntegrationFormProps,
): JSX.Element => {
    const { integration, onClose, onCancel } = props;
    const { sendGtmEvent } = useGoogleTagManager();
    const { sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();

    const { t } = useTranslation();
    const apiKey = useGetApiKey();

    const {
        integrations: {
            [integration.alias]: { action },
        },
    } = useTenantConfig<ITenantConfig>();
    const { name: tenantName } = useTenant();
    const actionUrl = action?.url[tenantName] || action?.url[TenantName.PACKLINKPRO];

    const actionTextId = DOWNLOADABLE_INTEGRATIONS.includes(integration.alias)
        ? 'integrations.action.download-plugin-label'
        : 'integrations.action.go-to-label';

    const handleCancel = (): void => {
        onCancel(integration.name);
    };

    const handleAction = (): void => {
        sendSuffixedAmplitudeEvent(
            sendAmplitudeClickEvent,
            integration.name,
        )(AmplitudeEventsPro.CONNECT_INTEGRATION_SIDE_PANEL);

        sendGtmEvent(GTMEvents.MARKETPLACE_INTEGRATION, {
            action: GTMActionFieldOption.INTEGRATION_INTEREST,
            integrationType: integration.name,
        });
        window.open(actionUrl, '_blank');
    };

    const ModuleIntegrationGuide = (): JSX.Element => {
        return {
            [MODULES.BigCommerce]: <BigCommerceIntegrationGuide collapsed={!apiKey} />,
            [MODULES.Ecwid]: <EcwidIntegrationGuide collapsed={!apiKey} />,
            [MODULES.Magento]: <MagentoIntegrationGuide collapsed={!apiKey} />,
            [MODULES.Prestashop]: <PrestashopIntegrationGuide collapsed={!apiKey} />,
            [MODULES.Shopify]: <ShopifyIntegrationGuide collapsed={!apiKey} />,
            [MODULES.Wix]: <WixIntegrationGuide collapsed={!apiKey} />,
            [MODULES.Woocommerce]: <WoocommerceIntegrationGuide collapsed={!apiKey} />,
            [MODULES.Zencart]: <ZencartIntegrationGuide collapsed={!apiKey} />,
            [MODULES.Shopware]: <ShopwareIntegrationGuide collapsed={!apiKey} />,
        }[integration.alias];
    };

    return (
        <>
            <SidePanelHeader onAction={onClose} title={t('integrations.panel.show-key')} />

            <SidePanelContent>
                <IntegrationFormLogo logo={integration.logo} alt={integration.name} />

                {!apiKey && (
                    <p css={getModuleIntegrationCopyMessageStyles}>{t('integrations.panel.module-copy-api-key')}</p>
                )}

                <GenerateApiKey canDelete={false} />

                <ModuleIntegrationGuide />
            </SidePanelContent>
            <SidePanelFooter>
                <SidePanelFooterActions
                    showActionButton={!!action}
                    onAction={handleAction}
                    onCancel={handleCancel}
                    actionText={t(actionTextId, { module: integration.name })}
                    cancelText={t('integrations.panel.cancel')}
                />
            </SidePanelFooter>
        </>
    );
};
