import {
    PaymentMethod,
    PaymentClientMethod,
    IPaymentClientMethod,
    IPaymentAvailableMethod,
    PaymentAvailableMethod,
} from '@packlink/packlink-sdk';

import { PaymentAction } from '@store/actions/payment';
import {
    SET_LOCAL_PAYMENT_FALLBACK,
    CLEAN_LOCAL_PAYMENT_FALLBACK,
    SET_AVAILABLE_PAYMENT_METHODS,
    SET_PAYMENT_METHODS,
    REMOVE_PAYMENT_METHOD,
    ADD_PAYMENT_METHOD,
} from '@store/actions/action-types';

export interface IPaymentState {
    availablePaymentMethods: IPaymentAvailableMethod[];
    paymentMethods: IPaymentClientMethod[];
    isLoading: boolean;
    localPaymentFallback?: {
        paymentMethod: PaymentMethod;
    };
}

export const initialState = {
    availablePaymentMethods: [],
    paymentMethods: [],
    isLoading: false,
};

const addPaymentMethod = (
    paymentMethods: IPaymentClientMethod[],
    newPaymentMethod: PaymentClientMethod,
): IPaymentClientMethod[] => {
    const notUpdatedMethods = paymentMethods.filter((p: IPaymentClientMethod): boolean => p.id !== newPaymentMethod.id);

    return [...notUpdatedMethods, newPaymentMethod.toJSON() as IPaymentClientMethod];
};

export const reducer = (state: IPaymentState = initialState, action: PaymentAction): IPaymentState => {
    switch (action.type) {
        case SET_LOCAL_PAYMENT_FALLBACK:
            return {
                ...state,
                localPaymentFallback: {
                    paymentMethod: action.payload,
                },
            };
        case CLEAN_LOCAL_PAYMENT_FALLBACK:
            return {
                ...state,
                localPaymentFallback: undefined,
            };
        case SET_AVAILABLE_PAYMENT_METHODS:
            return {
                ...state,
                availablePaymentMethods: action.payload.map(
                    (paymentMethod: PaymentAvailableMethod) => paymentMethod.toJSON() as IPaymentAvailableMethod,
                ),
            };
        case SET_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: action.payload.map(
                    (paymentMethod: PaymentClientMethod) => paymentMethod.toJSON() as IPaymentClientMethod,
                ),
            };
        case REMOVE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethods: state.paymentMethods.filter((p: IPaymentClientMethod) => p.id !== action.payload),
            };
        case ADD_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethods: addPaymentMethod(state.paymentMethods, action.payload),
            };
        default:
            return state;
    }
};
