import { Margin, Theme as NivoTheme } from '@nivo/core';
import { LineSvgProps } from '@nivo/line';
import { AxisProps } from '@nivo/axes';
import { LegendProps } from '@nivo/legends';
import { BarDatum } from '@nivo/bar';

import { useTheme } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

import { ResponsiveBarProps, ResponsiveLineProps, ChartPalette, ResponsiveBarPropsNivo } from '../types';
import { getBarChartLegendPreset, getLineChartLegendPreset } from '../utils';
import { LineTooltip } from '../components';
import { useMargin } from './useMargin';

export interface IUseChartConfig {
    axisLeft: AxisProps;
    axisBottom: AxisProps;
    colors: ResponsiveLineProps['colors'];
    legend?: LegendProps;
    margin?: Margin;
    theme: NivoTheme;
}

type IUseChartConfigProps = {
    axisLeft?: AxisProps | null;
    axisBottom?: AxisProps | null;
    id: string;
    margin?: Partial<Margin>;
    theme?: NivoTheme;
    colorPalette?: ChartPalette;
};

export function useChartConfig(props: IUseChartConfigProps, theme: Theme): IUseChartConfig {
    const { colorPalette = ChartPalette.LOW_CONTRAST, id } = props;
    const colors =
        colorPalette === ChartPalette.ALL
            ? [
                  ...Object.values(theme.palette.dataVisualization.lowContrast),
                  ...Object.values(theme.palette.dataVisualization.highContrast),
              ]
            : Object.values(theme.palette.dataVisualization[colorPalette]);

    const chartTheme: NivoTheme = {
        textColor: theme.palette.gray.dark,
        fontSize: parseInt(theme.typography.fontSize.XS),
        axis: {
            domain: {
                line: {
                    strokeWidth: 1,
                    stroke: theme.palette.gray.light,
                },
            },
        },
        grid: {
            line: {
                strokeWidth: 1,
                stroke: theme.palette.gray.ultraLight,
            },
        },
    };

    const axisConfig: AxisProps = {
        tickSize: 0,
        tickPadding: theme.spacing(),
    };
    const axisLeft = { ...axisConfig, ...props.axisLeft };
    const axisBottom = { ...axisConfig, ...props.axisBottom };

    const margin = useMargin(id);

    return {
        axisLeft,
        axisBottom,
        colors,
        margin,
        theme: chartTheme,
    };
}

export function useBarChartConfig<RawDatum extends BarDatum>(
    props: ResponsiveBarProps<RawDatum>,
): ResponsiveBarPropsNivo<RawDatum> {
    const { colorPalette, axisLeft, axisBottom, legendPreset, margin, theme, id, ...rest } = props;
    const tenantTheme = useTheme();

    const chartConfig = useChartConfig({ colorPalette, axisLeft, axisBottom, id, margin, theme }, tenantTheme);
    const legend = getBarChartLegendPreset(tenantTheme, legendPreset);

    return {
        ...chartConfig,
        legends: legend ? [legend] : undefined,
        colorBy: 'indexValue',
        ...rest,
    };
}

export function useLineChartConfig(props: ResponsiveLineProps): LineSvgProps {
    const {
        colorPalette,
        axisLeft,
        axisBottom,
        legendPreset,
        margin,
        theme,
        tooltipXLabel,
        tooltipYLabel,
        id,
        ...rest
    } = props;
    const tenantTheme = useTheme();

    const chartConfig = useChartConfig({ colorPalette, axisLeft, axisBottom, id, margin, theme }, tenantTheme);
    const legend = getLineChartLegendPreset(tenantTheme, legendPreset);

    return {
        ...chartConfig,
        legends: legend ? [legend] : undefined,
        useMesh: true,
        enableGridX: false,
        enableCrosshair: false,
        tooltip: (tooltipProps) => (
            <LineTooltip
                {...tooltipProps}
                xLabel={tooltipXLabel}
                yLabel={tooltipYLabel}
                numElementsX={rest.data[0].data.length}
            />
        ),
        // To remove a "required" propType error, but lib uses -12 as default
        pointLabelYOffset: -12,
        ...rest,
    };
}
