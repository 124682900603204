import { useRef } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { PopoverCallToAction } from '@components/PopoverCallToAction/PopoverCallToAction';
import { useToggle } from '@packlink/utils';
import { Button, ButtonColor, ButtonSize, ButtonVariant, Typography } from '@shipengine/giger';
import {
    getIntegrationDeactivateConnectionMessageStyles,
    getIntegrationDeactivateMessageStyles,
    getIntegrationDeactivateStyles,
} from './IntegrationDeactivateStyles';

export interface IIntegrationDeactivateProps {
    handleDeactivate: () => void;
}

export const IntegrationDeactivate: React.FC<IIntegrationDeactivateProps> = (
    props: IIntegrationDeactivateProps,
): JSX.Element => {
    const { handleDeactivate } = props;
    const { t } = useTranslation();
    const { state: isDeactivateOpen, toggle: toggleIsDeactivateOpen } = useToggle(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleDeactivateAndReset = (): void => {
        handleDeactivate();
        toggleIsDeactivateOpen();
    };

    return (
        <section css={getIntegrationDeactivateStyles}>
            <Button
                data-id="integration-deactivate-button"
                ref={buttonRef}
                onClick={toggleIsDeactivateOpen}
                variant={ButtonVariant.TEXT}
                color={ButtonColor.ERROR}
                size={ButtonSize.SMALL}
            >
                {t('integrations.panel.deactivate')}
            </Button>
            <PopoverCallToAction
                reference={buttonRef.current}
                isOpen={isDeactivateOpen}
                onClickAway={toggleIsDeactivateOpen}
                placement="bottom-end"
                primaryText="integrations.panel.deactivate-confirm"
                secondaryText="integrations.panel.cancel"
                primaryDataId="integration-deactivate-confirm"
                secondaryDataId="integration-deactivate-cancel"
                onPrimaryClick={handleDeactivateAndReset}
                onSecondaryClick={toggleIsDeactivateOpen}
            >
                <Typography variant="body2" component="p" css={getIntegrationDeactivateMessageStyles}>
                    {t('integrations.panel.deactivate-message')}
                </Typography>
                <Typography variant="body2" css={getIntegrationDeactivateConnectionMessageStyles}>
                    {t('integrations.panel.deactivate-connection-message')}
                </Typography>
            </PopoverCallToAction>
        </section>
    );
};
