import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const lineTooltipStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: 0,
        display: 'flex',
        alignItems: 'center',

        dt: {
            marginLeft: theme.spacing(),
        },
    });
