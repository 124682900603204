import { Navigate, Route, Routes } from 'react-router';
import { RecipientSettings } from '@Addresses/pages/RecipientSettings/RecipientSettings';
import { SenderSettings } from '@Addresses/pages/SenderSettings/SenderSettings';
import { OwnContractSettings } from '@OwnContract/components/OwnContractSettings';
import { ArchivingSettings } from '@components/Archiving/ArchivingSettings';
import { Parcels } from '@CustomParcel/components/Parcels/Parcels';
import { ApiKeySettings } from '@Integrations/components/ApiKeySettings/ApiKeySettings';
import { IntegrationsListSettings } from '@Integrations/components/IntegrationsList/IntegrationsListSettings';
import { SettingsBillingLayout } from '@Invoicing/components/SettingsBillingLayout/SettingsBillingLayout';
import { SettingsPayment } from '@Invoicing/pages/SettingsPayment/SettingsPayment';
import { SettingsBillingDetails } from '@Invoicing/pages/SettingsBillingDetails/SettingsBillingDetails';
import { InvoiceTable } from '@Invoicing/components/InvoiceTable/InvoiceTable';
import { ShippingRulesSettings } from '@ShippingRules/pages/ShippingRulesSettings/ShippingRulesSettings';
import { PrivacySettings } from '@User/pages/PrivacySettings/PrivacySettings';
import { VolumeDiscountSettings } from '@User/pages/VolumeDiscountSettings/VolumeDiscountSettings';
import { YourAccountSettings } from '@User/pages/YourAccountSettings/YourAccountSettings';
import { InvoicingRoute } from '@Invoicing/router/routes';
import { AddressesRoute } from '@Addresses/router/routes';
import { SettingsIntegrationsRoute } from '@Integrations/router/routes';
import { APP_ROUTE } from '../router/routes';
import { SettingsRoute } from './routes';
import { SettingsLayout } from './components/SettingsLayout/SettingsLayout';
import { BrandedEmailsSettings } from '@BrandedEmails/pages/BrandedEmailsSettings/BrandedEmailsSettings';

export function SettingsRouter(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={<SettingsLayout />}>
                <Route path="*" element={<Navigate to={APP_ROUTE.SETTINGS.BILLING_INVOICES} />} />
                <Route path={SettingsRoute.BILLING} element={<SettingsBillingLayout />}>
                    <Route index element={<Navigate to={InvoicingRoute.INVOICES} />} />
                    <Route path={InvoicingRoute.INVOICES} element={<InvoiceTable />} />
                    <Route path={InvoicingRoute.INVOICING} element={<SettingsBillingDetails />} />
                    <Route path={InvoicingRoute.PAYMENT} element={<SettingsPayment />} />
                </Route>
                <Route path={SettingsRoute.YOUR_ACCOUNT} element={<YourAccountSettings />} />
                <Route path={SettingsRoute.PRIVACY} element={<PrivacySettings />} />
                <Route path={SettingsRoute.VOLUME_DISCOUNT} element={<VolumeDiscountSettings />} />
                <Route path={SettingsRoute.OWN_CONTRACTS}>
                    <Route index element={<OwnContractSettings />} />
                </Route>
                <Route path={SettingsRoute.PARCELS} element={<Parcels />} />
                {/* TODO: Create task to separate shipping rules tabs in routes */}
                <Route path={SettingsRoute.SHIPPING_RULES} element={<ShippingRulesSettings />} />
                <Route path={SettingsRoute.ADDRESSES}>
                    <Route index element={<Navigate to={AddressesRoute.SENDER} />} />
                    <Route path={AddressesRoute.SENDER} element={<SenderSettings />} />
                    <Route path={AddressesRoute.RECIPIENT} element={<RecipientSettings />} />
                </Route>
                <Route path={SettingsRoute.BRANDED_EMAILS} element={<BrandedEmailsSettings />} />
                <Route path={SettingsRoute.API_KEY} element={<ApiKeySettings />} />
                <Route path={SettingsRoute.INTEGRATIONS} element={<IntegrationsListSettings />}>
                    <Route path=":integrationAlias" />
                </Route>
                <Route
                    path={`${SettingsRoute.INTEGRATIONS}/ebay/${SettingsIntegrationsRoute.CALLBACK}`}
                    element={<Navigate to={APP_ROUTE.INTEGRATIONS.EBAY_ACTIVATE_COMPLETE_FLOW} replace />}
                />
                <Route path={SettingsRoute.ARCHIVING} element={<ArchivingSettings />} />
            </Route>
        </Routes>
    );
}
