export class Browser {
    static isElementVisible(el: HTMLElement | null): boolean {
        if (!el) {
            return false;
        }

        const boundingRect = el.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const windowWidth = window.innerWidth || document.documentElement.clientWidth;

        return (
            boundingRect.top >= 0 &&
            boundingRect.left >= 0 &&
            boundingRect.bottom <= windowHeight &&
            boundingRect.right <= windowWidth
        );
    }
}
