import { useEffect, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { ServiceUtils } from '@utils/ServiceUtils';
import { Currency, IOrder, IShipment, Service, Shipment } from '@packlink/packlink-sdk';
import { PaymentSummaryAccordionSection } from './PaymentSummaryAccordionSection';
import {
    getPaymentSummaryFooterStyles,
    getPaymentSummaryHeaderStyles,
    getPaymentSummaryNumberStyles,
    getPaymentSummaryTotalAmountStyles,
    getPaymentSummaryTotalStyles,
    getPaymentSummaryTotalTextStyles,
} from '../PaymentSummaryCarriersListStyles';
import { getAccordionStyles } from '../PaymentSummaryAccordionStyles';
import { Typography } from '@shipengine/giger';

interface IPaymentSummaryCarriersListProps {
    shipmentsList?: Shipment<IShipment>[];
    order: IOrder;
}

export const PaymentSummaryCarriersList = (props: IPaymentSummaryCarriersListProps): JSX.Element => {
    const { shipmentsList, order } = props;
    const [selectedServices, setSelectedServices] = useState<Record<string, Service>>();
    const [carriersList, setCarriersList] = useState<Record<string, Shipment<IShipment>[]>>();
    const { t } = useTranslation();

    const numberOfShipments = shipmentsList?.length;

    useEffect(() => {
        const sortedCarriersList =
            shipmentsList?.reduce(
                (groupedCarriers: Record<string, Shipment<IShipment>[]>, shipment: Shipment<IShipment>) => {
                    const carrier = shipment?.service?.carrierName;
                    if (carrier) {
                        if (!groupedCarriers[carrier]) {
                            groupedCarriers[carrier] = [];
                        }
                        groupedCarriers[carrier].push(shipment);
                    }
                    return groupedCarriers;
                },
                {},
            ) || {};
        setCarriersList(sortedCarriersList);

        const logoRequests = Object.keys(sortedCarriersList).map((carrier: string) => {
            const shipment = sortedCarriersList[carrier][0];
            if (shipment.service?.id) {
                return ServiceUtils.getService(shipment, shipment.service.id);
            }

            return undefined;
        });

        Promise.all(logoRequests).then((response) => {
            const groupedSelectedServices = response.reduce<Record<string, Service>>((groupedServices, service) => {
                if (!service) {
                    return groupedServices;
                }

                return {
                    ...groupedServices,
                    [service.carrierName]: service,
                };
            }, {});
            setSelectedServices(groupedSelectedServices);
        });
    }, [shipmentsList]);

    return (
        <div>
            <div css={getPaymentSummaryHeaderStyles}>
                <Typography component="h6" css={getPaymentSummaryNumberStyles}>
                    {t('payment-summary.number-of-shipments', { numberOfShipments: numberOfShipments || 0 })}
                </Typography>
            </div>
            <div css={getAccordionStyles} data-id="payment-summary-accordion">
                {carriersList &&
                    Object.entries(carriersList).map(
                        ([carrierName, carrierShipments]: [string, Shipment<IShipment>[]]): JSX.Element => (
                            <PaymentSummaryAccordionSection
                                key={carrierName}
                                carrierInfo={selectedServices?.[carrierName]}
                                shipments={carrierShipments}
                            />
                        ),
                    )}
            </div>
            <div css={getPaymentSummaryFooterStyles}>
                <Typography component="h6" variant="subtitle1" css={getPaymentSummaryTotalStyles}>
                    <span css={getPaymentSummaryTotalTextStyles}>{t('payment-summary.total-price')}</span>
                    <span css={getPaymentSummaryTotalAmountStyles} data-id="payment-total-amount">
                        {Currency.format(order.totalAmount, shipmentsList?.[0]?.currency)}
                    </span>
                </Typography>
            </div>
        </div>
    );
};
