import { useState } from 'react';
import { Grid, GridChild, InlineNotification, NotificationType } from '@shipengine/giger';
import { DataTimeUnit } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { ShipmentsByCarrierChart } from '../../components/ShipmentsByCarrier/ShipmentsByCarrierChart';
import { ShipmentsOverTimeChart } from '../../components/ShipmentsOverTime/ShipmentsOverTimeChart';
import { ShipmentsPerContentChart } from '../../components/ShipmentsPerContent/ShipmentsPerContentChart';
import { ShipmentsPerCountryChart } from '../../components/ShipmentsPerCountry/ShipmentsPerCountryChart';
import { ShippingCostOverTimeChart } from '../../components/ShippingCostOverTime/ShippingCostOverTimeChart';
import { DatePicker, IDateRange } from '../../components/DatePicker/DatePicker';

import { DATA_LIMIT } from '../../constants/analytics';
import { defaultDatesRange } from '../../utils/dates';
import { notificationStyles } from './AnalyticsStyles';

export function AnalyticsContent(): JSX.Element {
    const { t } = useTranslation();
    const [datesRange, setDatesRange] = useState<IDateRange>(defaultDatesRange);
    const [isInfoNotificationOpen, setIsInfoNotificationOpen] = useState(true);

    return (
        <>
            <DatePicker onChange={setDatesRange} />

            {isInfoNotificationOpen && (
                <InlineNotification
                    css={notificationStyles}
                    type={NotificationType.INFO}
                    title={t('analytics.info.updated-24h')}
                    closeText={t('toast.action.close')}
                    onClose={() => setIsInfoNotificationOpen(false)}
                />
            )}

            <Grid noPadding>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <ShipmentsOverTimeChart {...datesRange} unit={DataTimeUnit.DAY} />
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <ShippingCostOverTimeChart {...datesRange} unit={DataTimeUnit.DAY} />
                </GridChild>
                <GridChild colSpan={12}>
                    <ShipmentsByCarrierChart {...datesRange} limit={DATA_LIMIT} />
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <ShipmentsPerCountryChart {...datesRange} limit={DATA_LIMIT} />
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <ShipmentsPerContentChart {...datesRange} limit={DATA_LIMIT} />
                </GridChild>
            </Grid>
        </>
    );
}
