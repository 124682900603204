import { FormikProps } from 'formik';
import { FC, useEffect, useState } from 'react';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelContentError } from '@components/SidePanel/SidePanelContentError';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { AmplitudeEventsPro } from '@constants/amplitude';
import { IntegrationForm } from '@Integrations/components/IntegrationForm/IntegrationForm';
import {
    IntegrationFormItem,
    IntegrationFormItemSpacingSize,
} from '@Integrations/components/IntegrationForm/IntegrationFormItem';
import { IntegrationFormLogo } from '@Integrations/components/IntegrationForm/IntegrationFormLogo';
import { IntegrationDeactivate } from '@Integrations/components/IntegrationDeactivate/IntegrationDeactivate';
import { IIntegrationPanelContentProps } from '@Integrations/components/IntegrationPanel/types';
import {
    InlineNotification,
    ISelectAutocompleteOption,
    NotificationType,
    SelectAutocomplete,
    Typography,
} from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

import { AmazonIntegrationGuide } from './AmazonIntegrationGuide';
import { AmazonConfiguration, IAmazonFormData } from './types';
import { useAmplitude } from '@hooks/useAmplitude';

type IAmazonIntegrationPanelProps = Omit<IIntegrationPanelContentProps<AmazonConfiguration>, 'onSubmit'>;

interface IAmazonIntegrationFormProps extends IAmazonIntegrationPanelProps {
    formProps: FormikProps<IAmazonFormData>;
}

interface ICountryMarketplace {
    label: string;
    value: string;
}
export interface ICountriesJsonData {
    name: string;
    values: Record<string, ICountriesData>;
}

interface ICountriesData {
    name: Record<string, string>;
}

const MARKETPLACE_CODES = ['ES', 'GB', 'FR', 'NL', 'DE', 'IT', 'SE', 'PL', 'TR', 'AE', 'IN', 'BE'];

export const AmazonIntegrationForm: FC<IAmazonIntegrationFormProps> = ({
    formProps: { isValid, handleBlur, setFieldValue },
    integration,
    isLoading,
    onClose,
    onDeactivate,
    showError,
    clearError,
    onCancel,
}: IAmazonIntegrationFormProps): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();

    const [selectedMarketplaceValue, setSelectedMarketplaceValue] = useState<ISelectAutocompleteOption | null>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [countryMarketplaces, setCountryMarketplaces] = useState<ICountryMarketplace[]>([]);
    const { sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();

    const handleCancel = (): void => {
        onCancel(integration.name);
    };

    const handleDeactivate = (): void => {
        onDeactivate(integration).then((integrationName: string) => {
            if (integrationName) {
                setSuccessMessage(
                    t('integrations.panel.deactivation-success', {
                        integrationName,
                    }),
                );
            }
        });
    };

    const setCountryMarketplaceValue = (field: string, countryMarketplace: ICountryMarketplace | null) => {
        setSelectedMarketplaceValue(countryMarketplace);
        if (countryMarketplace) {
            sendMarketplaceSelectionEvent(countryMarketplace.value);
        }
        setFieldValue(field, countryMarketplace?.value);
    };

    useEffect(() => {
        fetch(`${config.cdn.baseUrl}/apps/countries/countries.json`)
            .then((res) => res.json())
            .then((countries: ICountriesJsonData) => {
                const options = MARKETPLACE_CODES.reduce((result: ICountryMarketplace[], alpha2Code: string) => {
                    const country = countries.values[alpha2Code];
                    if (country) {
                        return result.concat({
                            label: country.name[locale],
                            value: alpha2Code,
                        });
                    }
                    return result;
                }, []);

                setCountryMarketplaces(options);
            });
    }, [locale]);

    const handleOnClear = (field: string): void => {
        setSelectedMarketplaceValue(null);
        setFieldValue(field, null);
    };

    const handleOnClickAway = (): void => {
        sendMarketplaceSelectionEvent(AmplitudeEventsPro.AMAZON_MARKETPLACE_NO_COUNTRY);
    };

    const sendMarketplaceSelectionEvent = (value: string): void => {
        sendSuffixedAmplitudeEvent(sendAmplitudeClickEvent, value)(AmplitudeEventsPro.AMAZON_MARKETPLACE);
    };

    return (
        <IntegrationForm>
            <SidePanelHeader
                onAction={onClose}
                title={t(integration.isConnected ? 'integrations.panel.edit' : 'integrations.panel.connect-shop')}
            />

            <SidePanelContent>
                {!showError && (
                    <>
                        {successMessage && (
                            <InlineNotification type={NotificationType.SUCCESS}>{successMessage}</InlineNotification>
                        )}
                        <IntegrationFormLogo logo={integration.logo} alt={integration.name} />

                        <Typography variant="small">{t('integrations.amazon.description')}</Typography>

                        <IntegrationFormItem>
                            <SelectAutocomplete
                                onClear={() => handleOnClear('countryMarketplace')}
                                clearButtonTooltip={t('autocomplete.messages.clear')}
                                label={t('integrations.amazon.country-marketplace')}
                                onBlur={handleBlur}
                                onChange={setCountryMarketplaceValue}
                                name="countryMarketplace"
                                onClickAway={handleOnClickAway}
                                options={countryMarketplaces}
                                disabled={integration.isConnected}
                                value={selectedMarketplaceValue}
                                noResultsLabel={t('integrations.amazon.marketplace-no-results')}
                            />
                        </IntegrationFormItem>

                        <IntegrationFormItem size={IntegrationFormItemSpacingSize.Big}>
                            {integration.isConnected && <IntegrationDeactivate handleDeactivate={handleDeactivate} />}
                        </IntegrationFormItem>

                        <AmazonIntegrationGuide collapsible={true} collapsed={integration.isConnected} />
                    </>
                )}
                {showError && (
                    <SidePanelContentError
                        title={t('integrations.panel.error-title')}
                        subtitle={t('integrations.panel.error-subtitle')}
                        onTryAgain={clearError}
                    />
                )}
            </SidePanelContent>
            {!showError && (
                <SidePanelFooter>
                    <SidePanelFooterActions
                        onCancel={handleCancel}
                        actionButtonType="submit"
                        actionText={t('integrations.panel.connect')}
                        cancelText={t('integrations.panel.cancel')}
                        disabledAction={!isValid || integration.isConnected}
                        isLoading={isLoading}
                    />
                </SidePanelFooter>
            )}
        </IntegrationForm>
    );
};
