import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getShipmentDetailsSidebarSectionTitleStyles = (theme: Theme): SerializedStyles =>
    css({ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(1), textTransform: 'uppercase' });

export const getShipmentDetailsSidebarSectionListStyles = css({
    margin: 0,
    padding: 0,
    listStyle: 'none',
});

export const getShipmentDetailsSidebarSectionListItemStyles = (theme: Theme): SerializedStyles =>
    css({ marginBottom: theme.spacing(), '&:last-child': { marginBottom: 0 } });

export const getShipmentDetailsSidebarSectionTooltipStyles = (theme: Theme): SerializedStyles =>
    css({
        marginLeft: theme.spacing(),
    });
