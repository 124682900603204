import {
    Button,
    ButtonSize,
    ButtonVariant,
    InlineNotification,
    NotificationType,
    WithCommonProps,
} from '@shipengine/giger';
import { AnySupportTicket, SupportTicketStatus } from '@packlink/packlink-sdk';
import { SUPPORT_CENTER_KEYS, useSupportCenter } from '@packlink/provider';
import { useTranslation } from '@packlink/translation-provider';
import { useNavigate } from 'react-router';
import { getTicketDetailsRoute } from '@SupportCenter/pages/routes';
import { useTenantFeatures } from '@SupportCenter/utils/tenantFeatures';

type TicketAlreadyCreatedNotificationProps = WithCommonProps<{
    ticket: AnySupportTicket;
}>;

export function TicketAlreadyCreatedNotification({
    ticket,
    ...props
}: TicketAlreadyCreatedNotificationProps): JSX.Element {
    const { t } = useTranslation();
    const content = useTicketAlreadyCreatedContent(ticket);
    const getSupportCenter = useSupportCenter();
    const { isInternalDetailsPageEnabled } = useTenantFeatures();
    const navigate = useNavigate();

    const handleClick = () => {
        const ticketId = ticket.externalReference || ticket.id;

        if (isInternalDetailsPageEnabled) {
            const internalTicketDetailsLink = getTicketDetailsRoute(ticketId);
            navigate(internalTicketDetailsLink);
        } else {
            const zendeskTicketLink = getSupportCenter(SUPPORT_CENTER_KEYS.ticketLink, {
                ticketId: ticket.externalReference,
            });
            // Not a big fan of using a window.open here, but links in InlineNotification are not yet supported
            window.open(zendeskTicketLink, '_blank');
        }
    };

    return (
        <InlineNotification
            type={mapNotificationTypeByStatus(ticket)}
            title={t('support-center.notifications.ticket-already-created-error-title')}
            actionElement={
                <Button size={ButtonSize.SMALL} variant={ButtonVariant.TEXT} onClick={handleClick}>
                    {t('support-center.notifications.view-ticket-details')}
                </Button>
            }
            {...props}
        >
            {content}
        </InlineNotification>
    );
}

function mapNotificationTypeByStatus({ status }: AnySupportTicket) {
    switch (status) {
        case SupportTicketStatus.OPEN:
        case SupportTicketStatus.SOLVED:
            return NotificationType.INFO;
        case SupportTicketStatus.AWAITING_INFORMATION:
        default:
            return NotificationType.ALERT;
    }
}

function useTicketAlreadyCreatedContent({ status }: AnySupportTicket) {
    const { t } = useTranslation();

    switch (status) {
        case SupportTicketStatus.OPEN:
        case SupportTicketStatus.NEW:
            return t('support-center.notifications.ticket-created-and-open');
        case SupportTicketStatus.AWAITING_INFORMATION:
            return t('support-center.notifications.ticket-created-and-awaiting-information');
        case SupportTicketStatus.CLOSED:
        case SupportTicketStatus.SOLVED:
            return t('support-center.notifications.ticket-created-and-closed');
        default:
            return undefined;
    }
}
