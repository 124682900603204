import { useTranslation } from '@packlink/translation-provider';
import { EmptyState } from '@components/EmptyState/EmptyState';

export function EmptySearch(): JSX.Element {
    const { t } = useTranslation();

    const {
        cdn: { url: cdnUrl },
    } = config;
    const image = `${cdnUrl}/pro/statics/images/no-results.png`;

    return <EmptyState title={t('search.no-results.title')} subtitle={t('search.no-results.subtitle')} image={image} />;
}
