import { css, SerializedStyles, Theme } from '@emotion/react';

export const getSupportTicketsTableFiltersStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        flexDirection: 'column',
        '> *:not(:last-child)': {
            marginBottom: theme.spacing(2),
            marginRight: theme.spacing(3),
        },

        [theme.mediaQuery('tabletLarge')]: {
            flexDirection: 'row',
            height: 'max-content',
            '> *:not(:last-child)': {
                marginBottom: 0,
            },
        },
    });

export const getShipmentReferenceFilterStyles = (theme: Theme): SerializedStyles =>
    css({
        width: '100%',

        [theme.mediaQuery('tabletLarge')]: {
            width: theme.spacing(32),
        },

        [theme.mediaQuery('desktop')]: {
            width: theme.spacing(60),
        },
    });

export const getStatusFilterStyles = (theme: Theme): SerializedStyles =>
    css({
        width: '100%',

        [theme.mediaQuery('tabletLarge')]: {
            width: theme.spacing(30),
        },

        [theme.mediaQuery('desktop')]: {
            width: theme.spacing(32),
        },
    });
