import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSidebarSummaryStyles = (): SerializedStyles =>
    css({
        display: 'initial',
    });

export const getSidebarSummarySectionStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `0 0 ${theme.spacing(2)}px`,
        paddingTop: 0,

        [theme.mediaQuery('desktop')]: {
            paddingTop: theme.spacing(2),
            position: 'sticky',
            top: 0,
        },
    });

export const getSidebarSummaryButtonsSectionStyles = (theme: Theme): SerializedStyles =>
    css({
        margin: `0 0 ${theme.spacing(2)}px`,

        button: {
            margin: `${theme.spacing()}px 0`,
        },
    });
