import { css, SerializedStyles } from '@emotion/react';

import { Theme } from '@shipengine/giger-theme';

export const getHeaderActionsIntegrationsStyles = (theme: Theme): SerializedStyles =>
    css({
        borderTop: `1px solid ${theme.palette.gray.light}`,
        margin: `0 -${theme.spacing(2)}px`,
        padding: theme.spacing(2),

        [theme.mediaQuery('tablet')]: {
            margin: 0,
            padding: 0,
        },
    });
