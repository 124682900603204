import { useTheme } from '@emotion/react';
import { useTranslation } from '@packlink/translation-provider';
import { useMedia } from '@packlink/utils';
import { Button, ButtonSize, ButtonVariant, Typography } from '@shipengine/giger';
import {
    getNewUserFooterStyles,
    getNewUserImageStyles,
    getNewUserListStyles,
    getNewUserOptionStyles,
    getNewUserTitleStyles,
} from './EmptyStateStyles';

interface INewUserEmptyStateOptionProps {
    image: string;
    title: string;
    buttonText: string;
    buttonVariant: ButtonVariant;
    numSteps: number;
    stepTranslationKey: string;
    onAction: () => void;
}

export const NewUserEmptyStateOption = (props: INewUserEmptyStateOptionProps): JSX.Element => {
    const { image, title, stepTranslationKey, numSteps, buttonText, buttonVariant, onAction } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const isFromTablet = useMedia(theme.mediaQuery('tablet'));

    return (
        <section css={getNewUserOptionStyles}>
            <header>
                <img css={getNewUserImageStyles} src={image} alt={title} />
                <Typography variant={isFromTablet ? 'heading3' : 'heading6'} component="h6" css={getNewUserTitleStyles}>
                    {title}
                </Typography>
            </header>

            <ul css={getNewUserListStyles}>
                {[...Array(numSteps)].map(
                    (_x: undefined, key: number): JSX.Element => (
                        <Typography
                            variant={isFromTablet ? 'body1' : 'small'}
                            component="li"
                            key={key}
                            dangerouslySetInnerHTML={{ __html: t(`${stepTranslationKey}-${key + 1}`) }}
                        />
                    ),
                )}
            </ul>

            <footer css={getNewUserFooterStyles}>
                <Button
                    isFullWidth={true}
                    variant={buttonVariant}
                    size={isFromTablet ? ButtonSize.MEDIUM : ButtonSize.SMALL}
                    onClick={onAction}
                >
                    {buttonText}
                </Button>
            </footer>
        </section>
    );
};
