import {
    getCrmSegment,
    getShipmentCategory,
    GTMActionFieldList,
    GTMActions,
    GTMCustomDimensions,
    GTMEEDimensions,
    GTMEEProduct,
    GTMEvents,
    GTMPageType,
    GTMPayload,
    GTMShipmentSource,
} from '@packlink/metrics';
import { ProviderName } from '@packlink/metrics';
import {
    Address,
    IPriceDetail,
    ServiceUpsale,
    IShipmentService,
    Parcel,
    PaymentMethod,
    Service,
    ServiceCategory,
    ServiceDropOff,
} from '@packlink/packlink-sdk';
import {
    getCheckoutFrom,
    getCheckoutInsuranceType,
    getCheckoutParcels,
    getCheckoutService,
    getCheckoutSource,
    getCheckoutTo,
} from '@store/selectors/checkout';
import { getClientData } from '@store/selectors/client';
import { getInboxes } from '@store/selectors/inbox';
import { getUserId } from '@store/selectors/user';
import { GTM_SHIPMENT_SOURCE_FROM_SHORTCODE, GTMActionFieldOption, GTMShortcodeKeys } from '@constants/gtm';
import { TenantUtils } from '@utils/tenant';
import { UpsellInsuranceType } from '@types';
import { useMetrics } from '@providers/MetricsProvider';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useIntegrations } from '@common/hooks/useIntegrations';
import { useTranslation } from '@packlink/translation-provider';

type GtmServiceInfo =
    | Service
    | (Pick<IShipmentService, 'name' | 'id' | 'carrierName'> & {
          dropOff?: Partial<ServiceDropOff>;
          category?: ServiceCategory;
          price?: Pick<IPriceDetail, 'currency' | 'basePrice'>;
          customsRequired?: ServiceUpsale;
      });

type gtmShipmentsType = {
    service?: GtmServiceInfo;
    from?: Address;
    to?: Address;
    packages?: Parcel[];
    insuranceType?: UpsellInsuranceType;
    source?: string;
};

type purchaseDataType = {
    purchaseId?: string;
    paymentMethod: PaymentMethod;
    purchaseDate: string;
    shipments: gtmShipmentsType[];
    price?: IPriceDetail;
};
type checkoutStepDataType = {
    shipments?: gtmShipmentsType[];
    actionFieldOption: GTMActionFieldOption;
    stepNumber: number;
    paymentMethod?: PaymentMethod;
};

type productClickDataType = Omit<gtmShipmentsType, 'insuranceType'> & {
    actionFieldList: GTMActionFieldList;
    index: number;
};

export function useGoogleTagManager(): {
    sendGtmEvent: (eventName: GTMEvents, payload?: GTMPayload) => void;
    sendVirtualPageViewGtmEvent: (path: string) => void;
    sendPurchaseGtmEvent: (data: purchaseDataType) => void;
    sendCheckoutStepGtmEvent: (data: checkoutStepDataType) => void;
    sendAddToCartGtmEvent: (shipments: gtmShipmentsType[]) => void;
    sendProductClickGtmEvent: (data: productClickDataType) => void;
} {
    const {
        i18n: { language: locale },
    } = useTranslation();
    const metrics = useMetrics();
    const inboxes = useSelector(getInboxes);
    const amountOfOrders = (inboxes?.getAll() ?? 0) - (inboxes?.getPending() ?? 0);
    const createdAt = useSelector(getClientData).createdAt;
    const { connectedIntegrationNames: namesOfActiveIntegrations } = useIntegrations();
    const userHasConnectedIntegrations = namesOfActiveIntegrations?.length > 0;
    const userHasMarketplaces = userHasConnectedIntegrations ? 'yes' : 'no';
    const checkoutService = useSelector(getCheckoutService);
    const checkoutFrom = useSelector(getCheckoutFrom);
    const checkoutTo = useSelector(getCheckoutTo);
    const checkoutParcels = useSelector(getCheckoutParcels);
    const checkoutInsuranceType = useSelector(getCheckoutInsuranceType);
    const checkoutSource = useSelector(getCheckoutSource);
    const userId = useSelector(getUserId);

    const trackEvent = useCallback(
        (eventName: GTMEvents, payload?: GTMPayload): void => {
            const payloadWithCustomDimensions = {
                [GTMCustomDimensions.COUNTRY]: locale,
                [GTMCustomDimensions.TENANT]: TenantUtils.getTenantName(),
                [GTMCustomDimensions.PAGE_TITLE]: document.title,
                [GTMCustomDimensions.PAGE_URL]: window.location.href,
                [GTMCustomDimensions.PAGE_PATH]: window.location.pathname,
                [GTMCustomDimensions.USER_ID_SESSION]: userId,
                [GTMCustomDimensions.USER_ACQUISITION_DATE]: createdAt
                    ? new Date(createdAt).toISOString().split('T')[0]
                    : null,
                [GTMCustomDimensions.USER_MARKETPLACE]: userHasMarketplaces,
                [GTMCustomDimensions.MARKETPLACE_INTEGRATED]: namesOfActiveIntegrations.join(', ') || null,
                [GTMCustomDimensions.CRM_SEGMENT_SESSION]: getCrmSegment(amountOfOrders),
                ...payload,
            };
            metrics.track({
                providerName: ProviderName.GTM,
                payload: payloadWithCustomDimensions,
                eventName,
            });
        },
        [locale, userId, createdAt, userHasMarketplaces, namesOfActiveIntegrations, amountOfOrders, metrics],
    );

    const sendVirtualPageViewGtmEvent = useCallback(
        (path: string): void => {
            const pagePath = `${TenantUtils.getURLPrefix()}${path}`;

            trackEvent(GTMEvents.VIRTUAL_PAGE_VIEW, {
                [GTMCustomDimensions.ENVIRONMENT]: config.gtm.environment || 'development',
                [GTMCustomDimensions.PAGE_TYPE]: GTMPageType.TABLE_PRO,
                [GTMCustomDimensions.USER_LOGGED_IN]: 'true',
                [GTMCustomDimensions.PAGE_URL]: `${window.location.protocol}//${window.location.host}${pagePath}`,
                [GTMCustomDimensions.PAGE_PATH]: pagePath,
            });
        },
        [trackEvent],
    );

    const sendPurchaseGtmEvent = useCallback(
        ({ purchaseId, paymentMethod, purchaseDate, shipments, price }: purchaseDataType): void => {
            const amountOfOrders = (inboxes?.getAll() ?? 0) - (inboxes?.getPending() ?? 0);
            const products = shipments.map((shipment) => ({
                [GTMEEDimensions.QUANTITY]: shipment.packages?.length,
                ...getCommonEEDimensions({
                    service: shipment.service,
                    from: shipment.from,
                    to: shipment.to,
                    packages: shipment.packages,
                    upsells: shipment.insuranceType,
                    source: getSource(shipment.source),
                }),
            }));

            trackEvent(GTMEvents.EECOMMERCE, {
                action: GTMActions.PURCHASE,
                firstPurchaseIntegrated: amountOfOrders === 0 && userHasConnectedIntegrations ? 'true' : 'false',
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: purchaseId,
                            returning: true,
                            timestamp: purchaseDate,
                            currency: shipments[0]?.service?.price?.currency,
                            paymentMethod,
                            subtotal: price?.basePrice,
                            subtotalIncludesTax: price?.totalPrice,
                            tax: price?.taxPrice,
                            revenue: price?.totalPrice,
                        },
                        products,
                    },
                },
            });
        },
        [inboxes, trackEvent, userHasConnectedIntegrations],
    );

    const sendCheckoutStepGtmEvent = useCallback(
        ({ shipments, actionFieldOption, stepNumber, paymentMethod }: checkoutStepDataType): void => {
            const option = paymentMethod ? `${actionFieldOption} + ${paymentMethod}` : null;

            const products = shipments
                ? shipments.map((shipment) => ({
                      ...getCommonEEDimensions({
                          service: shipment.service,
                          from: shipment.from,
                          to: shipment.to,
                          packages: shipment.packages,
                          upsells: shipment.insuranceType,
                          source: getSource(shipment.source),
                      }),
                  }))
                : [
                      {
                          ...getCommonEEDimensions({
                              service: checkoutService,
                              from: checkoutFrom,
                              to: checkoutTo,
                              packages: checkoutParcels,
                              upsells: checkoutInsuranceType,
                              source: getSource(checkoutSource),
                          }),
                      },
                  ];

            trackEvent(GTMEvents.EECOMMERCE, {
                action: `${GTMActions.CHECKOUT_STEP}${stepNumber}`,
                ecommerce: {
                    checkout: {
                        actionField: { step: stepNumber, ...(option && { option }) },
                        products,
                    },
                },
            });
        },
        [checkoutFrom, checkoutInsuranceType, checkoutParcels, checkoutService, checkoutSource, checkoutTo, trackEvent],
    );

    const sendAddToCartGtmEvent = useCallback(
        (shipments: gtmShipmentsType[]): void => {
            const products = shipments.map((shipment) => ({
                [GTMEEDimensions.QUANTITY]: shipment.packages?.length,
                ...getCommonEEDimensions({
                    service: shipment.service,
                    from: shipment.from,
                    to: shipment.to,
                    packages: shipment.packages,
                    upsells: shipment.insuranceType,
                    source: getSource(shipment.source),
                }),
            }));

            trackEvent(GTMEvents.EECOMMERCE, {
                action: GTMActions.ADD_TO_CART,
                ecommerce: {
                    currencyCode: shipments[0]?.service?.price?.currency,
                    add: {
                        products,
                    },
                },
            });
        },
        [trackEvent],
    );

    const sendProductClickGtmEvent = useCallback(
        ({ actionFieldList, service, from, to, packages, index, source }: productClickDataType): void => {
            trackEvent(GTMEvents.EECOMMERCE, {
                action: GTMActions.PRODUCT_CLICK,
                ecommerce: {
                    click: {
                        actionField: { list: actionFieldList },
                        products: [
                            {
                                [GTMEEDimensions.POSITION]: index + 1,
                                ...getCommonEEDimensions({
                                    service,
                                    from,
                                    to,
                                    packages,
                                    source: getSource(source),
                                }),
                            },
                        ],
                    },
                },
            });
        },
        [trackEvent],
    );

    const getCommonEEDimensions = ({
        service,
        from,
        to,
        packages,
        upsells,
        source,
    }: {
        service?: GtmServiceInfo;
        from?: Address;
        to?: Address;
        packages?: Parcel[];
        upsells?: UpsellInsuranceType;
        source: string;
    }): Partial<GTMEEProduct> => {
        return {
            [GTMEEDimensions.NAME]: service?.name,
            [GTMEEDimensions.ID]: service?.id,
            [GTMEEDimensions.BRAND]: service?.carrierName,
            [GTMEEDimensions.CATEGORY]: `${TenantUtils.getTenantName()}/${source}/${
                service?.category
            }/${getShipmentCategory(service?.customsRequired?.available ?? false, from?.alpha2Code, to?.alpha2Code)}`,
            [GTMEEDimensions.VARIANT]: service?.name,
            [GTMEEDimensions.CURRENCY]: service?.price?.currency,
            [GTMEEDimensions.PRICE]: service?.price?.basePrice,
            [GTMEEDimensions.COUNTRY_FROM]: from?.state,
            [GTMEEDimensions.COUNTRY_TO]: to?.state,
            [GTMEEDimensions.POSTAL_CODE_FROM]: from?.zipCode,
            [GTMEEDimensions.POSTAL_CODE_TO]: to?.zipCode,
            [GTMEEDimensions.UPSELLS]: upsells,
            [GTMEEDimensions.LOCATION_TYPE]: `${service?.dropOff?.origin ? 'dropoff' : 'collection'}, ${
                service?.dropOff?.destination ? 'pickup' : 'delivery'
            }`,
            [GTMEEDimensions.PARCELS_COUNT]: packages?.length,
            [GTMEEDimensions.PARCELS_WEIGHT]: packages?.reduce(
                (totalWeight, { weight = 0 }) => totalWeight + weight,
                0,
            ),
        };
    };

    const getSource = (originalSource = ''): string => {
        if (GTM_SHIPMENT_SOURCE_FROM_SHORTCODE[originalSource as GTMShortcodeKeys]) {
            return GTM_SHIPMENT_SOURCE_FROM_SHORTCODE[originalSource as GTMShortcodeKeys];
        }

        return GTMShipmentSource.TECH_ECOMMERCE + originalSource;
    };

    return {
        sendGtmEvent: trackEvent,
        sendVirtualPageViewGtmEvent,
        sendPurchaseGtmEvent,
        sendCheckoutStepGtmEvent,
        sendAddToCartGtmEvent,
        sendProductClickGtmEvent,
    };
}
