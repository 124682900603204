import { WithCommonProps } from '@shipengine/giger';
import {
    AnySupportTicket,
    DamageSupportTicket,
    IShipment,
    LossSupportTicket,
    Shipment,
    SupportTicketType,
} from '@packlink/packlink-sdk';
import { useIsMobile } from '@SupportCenter/utils/useIsMobile';
import { FrequentlyAskedQuestionsLink } from '@SupportCenter/components/FrequentlyAskedQuestionsLink/FrequentlyAskedQuestionsLink';
import { TicketClaimData } from './components/TicketDataPartials/TicketClaimData';
import { TicketCommonData } from './components/TicketDataPartials/TicketCommonData';
import { TicketDataLoadingPlaceholder } from './components/TicketDataPartials/TicketDataLoadingPlaceholder';
import { getTicketDataDetailsStyles, getTicketDataFAQLinkStyles } from './TicketDataStyles';

type TicketDataProps = WithCommonProps<{
    supportTicket: AnySupportTicket | undefined;
    shipment: Shipment<IShipment> | undefined;
    isLoading?: boolean;
}>;

export function TicketData({ supportTicket, shipment, isLoading, ...props }: TicketDataProps): JSX.Element {
    const hasAvailableData = !isLoading && !!supportTicket;
    const isMobile = useIsMobile();

    if (!hasAvailableData) {
        return <TicketDataLoadingPlaceholder data-id="ticket-data-details-loading" {...props} />;
    }

    const hasClaimData =
        shipment && (supportTicket.type === SupportTicketType.DAMAGE || supportTicket.type === SupportTicketType.LOSS);

    return (
        <section css={getTicketDataDetailsStyles} {...props}>
            {isMobile && <FrequentlyAskedQuestionsLink css={getTicketDataFAQLinkStyles} />}

            <TicketCommonData supportTicket={supportTicket} data-id="ticket-data-common-details" />
            {hasClaimData && (
                <TicketClaimData
                    supportTicket={supportTicket as LossSupportTicket | DamageSupportTicket}
                    shipment={shipment}
                    data-id="ticket-data-claim-details"
                />
            )}
        </section>
    );
}
