import Packlink from '@sdk';
import { logSdkError } from '@utils/logger';
import { useEffect, useState } from 'react';
import { Address, IAddress } from '@packlink/packlink-sdk';

import { SelectAddress } from './SelectAddress';
import { CheckoutAddressDetails, setCheckoutAddressDetails } from '@store/actions/checkout';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store';
import { Origin } from '@types';
import { DeepPartial } from 'redux';

interface ISelectAddressBookProps {
    address: Address<DeepPartial<IAddress>>;
    onClear: () => void;
    onSelect: (w: IAddress) => void;
}

export function SelectAddressBook({
    address: { alpha2Code, city, zipCode },
    onClear,
    onSelect,
}: ISelectAddressBookProps): JSX.Element {
    const [addresses, setAddresses] = useState<IAddress[]>([]);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!alpha2Code || !city || !zipCode) {
            return;
        }
        //To get only addresses with common available services
        Packlink.pro.addresses.get(alpha2Code, city, zipCode).then(setAddresses).catch(logSdkError);
    }, [alpha2Code, city, zipCode]);

    const handleSelect = (newAddress: IAddress): void => {
        const address: CheckoutAddressDetails = {
            ...newAddress,
            id: newAddress.id || '',
            email: newAddress.email || '',
            company: newAddress.company || '',
            street1: newAddress.street1 || '',
            street2: newAddress.street2 || '',
        };
        dispatch(setCheckoutAddressDetails(address, Origin.TO));
        onSelect(newAddress);
    };

    const handleClear = (): void => {
        const emptyAddress: CheckoutAddressDetails = {
            id: '',
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            street1: '',
            street2: '',
            phone: '',
        };
        dispatch(setCheckoutAddressDetails(emptyAddress, Origin.TO));
        onClear();
    };
    return <SelectAddress addresses={addresses} onClear={handleClear} onSelect={handleSelect} />;
}
