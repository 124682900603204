import { WithCommonProps } from '@shipengine/giger';
import { RouteData } from '@SupportCenter/pages/routes';
import { SupportCenterHeaderActions } from './SupportCenterHeaderActions';
import { getSupportHeaderStyles, getSupportHeaderTitleStyles } from './SupportCenterHeaderStyles';
import { SupportCenterHeaderTitle } from './SupportCenterHeaderTitle';

type SupportCenterHeaderProps = WithCommonProps<{
    showFrequentlyAskedQuestionsButton?: boolean;
    activeRoutes?: RouteData[];
}>;

export function SupportCenterHeader({
    showFrequentlyAskedQuestionsButton,
    activeRoutes = [],
    ...props
}: SupportCenterHeaderProps): JSX.Element {
    return (
        <header css={getSupportHeaderStyles} {...props}>
            <SupportCenterHeaderTitle
                data-id="support-center-header-title"
                activeRoutes={activeRoutes}
                css={getSupportHeaderTitleStyles}
            />
            <SupportCenterHeaderActions
                showFrequentlyAskedQuestionsButton={showFrequentlyAskedQuestionsButton}
                data-id="support-center-header-actions"
            />
        </header>
    );
}
