import { IChartStateProps } from '../../types';
import { ChartStateLayout } from './ChartStateLayout';

export function ChartStateEmpty({ cdnUrl }: IChartStateProps): JSX.Element {
    return (
        <ChartStateLayout
            image={`${cdnUrl}/apps/analytics/statics/images/not-found.png`}
            title="analytics.empty.title"
            subtitle="analytics.empty.subtitle"
        />
    );
}
