import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BulkDelete } from '@components/BulkDelete/BulkDelete';
import { BulkSelectionInfo } from '@components/SidePanel/BulkSelectionInfo';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelMessage } from '@components/SidePanel/SidePanelMessages/SidePanelMessage';
import { SidePanelMessageType } from '@components/SidePanel/SidePanelMessages/types';
import {
    getIsPendingSectionInbox,
    getSelectedShipments,
    hasInsuranceAction,
    hasPaymentAction,
    hasPrintAction,
} from '@store/selectors/bulk';
import { useTranslation } from '@packlink/translation-provider';
import {
    Button,
    ButtonSize,
    ButtonVariant,
    IconPopover,
    IconSize,
    InlineNotification,
    NotificationType,
    Typography,
} from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import { ProShipment } from '@packlink/packlink-sdk';
import { BulkPrint } from '@components/BulkPrint/BulkPrint';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { deleteSelectedShipments, removeSelectedShipments } from '@store/actions/bulk';
import { useShipmentPanel } from '@hooks/useShipmentPanel';
import { BulkFlows } from '../typings';
import { AppDispatch } from '@store';
import { useShipmentsForServiceRequest } from '../../../hooks/useShipmentsForServiceRequest';
import {
    getActionsWrapperStyles,
    getActionTooltipStyles,
    getBulkActionsMessageStyles,
    getBulkActionsStyles,
    getBulkDeleteStyles,
    getMoreActionsOptionStyles,
    getMoreActionsTitleStyles,
    getWarningStyles,
} from './ShipmentPanelBulkActionsStyles';
import { useBulkCollection } from './hooks/useBulkCollection';
import { UpsellInsuranceType } from '@types';
import { UpsellUtils } from '@utils/UpsellUtils';
import { ShipmentPanelBulkInsuranceAction } from './ShipmentPanelBulkInsuranceAction';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useAmplitude } from '@hooks/useAmplitude';

export const MAX_AMOUNT_OF_SHIPMENTS_TO_EDIT_SERVICES_IN_BULK = 20;

export interface IShipmentPanelBulkActionsProps {
    onSetFlow: (flow: BulkFlows) => void;
}

export const ShipmentPanelBulkActions = (props: IShipmentPanelBulkActionsProps): JSX.Element => {
    const { onSetFlow } = props;
    const { timedClosePanel } = useShipmentPanel();
    const selectedShipments = useSelector(getSelectedShipments);
    const numberSelectedShipments = selectedShipments.length;
    const isPendingInbox = useSelector(getIsPendingSectionInbox);
    const isReadyToPurchase = useSelector(hasPaymentAction);
    const showPrintAction = useSelector(hasPrintAction);
    const { sendAddToCartGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();

    const isPaymentOrDelete = isReadyToPurchase || isPendingInbox;
    const showDeleteAsButton = isPendingInbox && !isReadyToPurchase;
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [hasDeletedSuccessfully, setHasDeletedSuccessfully] = useState<boolean>(false);
    const [shipmentsNotValidForService, shipmentsValidForService] = useShipmentsForServiceRequest(selectedShipments);
    const isOverMaxShipmentsAllowed =
        shipmentsValidForService.length > MAX_AMOUNT_OF_SHIPMENTS_TO_EDIT_SERVICES_IN_BULK;
    const canEditServices = shipmentsValidForService.length > 0 && !isOverMaxShipmentsAllowed;
    const servicesWarningMessage = isOverMaxShipmentsAllowed
        ? t('bulk-edit.actions.services-amount-warning')
        : shipmentsNotValidForService.length
          ? t('bulk-edit.actions.services-missing-info-warning', {
                total: numberSelectedShipments,
                valid: shipmentsValidForService.length,
            })
          : undefined;

    const { collectionShipments, notCollectionShipments, handleEditCollectionDate } = useBulkCollection({
        selectedShipments,
        onSetFlow,
    });

    const showInsuranceAction = useSelector(hasInsuranceAction);

    const onDeleting = (): void => {
        setIsDeleting(true);
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.DELETE);
        dispatch(deleteSelectedShipments()).then(() => {
            setIsDeleting(false);
            setHasDeletedSuccessfully(true);

            timedClosePanel();
        });
    };

    const handlePayClick = (): void => {
        const gtmShipmentsInfo = selectedShipments.map(({ data }) => {
            const baseCoverage = data.upsales?.insurance?.baseCoverage ?? 0;
            let insuranceType = UpsellUtils.getDefaultCheckoutInsuranceType(baseCoverage, data.upsales);
            if (!insuranceType) {
                insuranceType = baseCoverage ? UpsellInsuranceType.STANDARD : UpsellInsuranceType.NO_INSURANCE;
            }
            return {
                service: { ...data.service, price: data.price?.price },
                from: data.from,
                to: data.to,
                packages: data.parcels,
                insuranceType,
                source: data.source,
            };
        });
        sendAddToCartGtmEvent(gtmShipmentsInfo);
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.PAY_ALL);
        onSetFlow(BulkFlows.PAYMENT);
    };

    const handleEditServices = (): void => {
        if (shipmentsNotValidForService.length) {
            const references = shipmentsNotValidForService.map((s: ProShipment) => s.data.packlinkReference);
            dispatch(removeSelectedShipments(references));
        }

        sendAmplitudeSidebarClickEvent(AmplitudeEvents.EDIT_SERVICES, {
            [AmplitudeProperties.NUMBER_OF_SHIPMENTS_EDITED]: shipmentsValidForService.length,
        });

        onSetFlow(BulkFlows.SERVICES);
    };

    const handleEditContent = (): void => {
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.EDIT_CONTENT, {
            [AmplitudeProperties.SHIPMENTS_COUNT]: numberSelectedShipments,
        });
        onSetFlow(BulkFlows.CONTENT);
    };

    const handleEditParcels = (): void => {
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.EDIT_PARCEL, {
            [AmplitudeProperties.SHIPMENTS_COUNT]: numberSelectedShipments,
        });
        onSetFlow(BulkFlows.PARCELS);
    };

    return (
        <SidePanelContent css={getBulkActionsStyles}>
            {!isDeleting && !hasDeletedSuccessfully && (
                <>
                    {isPaymentOrDelete && (
                        <>
                            <section css={getActionsWrapperStyles}>
                                <BulkSelectionInfo
                                    count={numberSelectedShipments}
                                    actionsTitle="bulk-panel.actions.title"
                                    actionsSubtitle="bulk-panel.actions.subtitle"
                                />
                                <section>
                                    {isReadyToPurchase && (
                                        <Button
                                            onClick={handlePayClick}
                                            size={ButtonSize.SMALL}
                                            data-id="bulk-pay-button"
                                        >
                                            {t('bulk-pay.action.pay', { count: numberSelectedShipments })}
                                        </Button>
                                    )}
                                    {showDeleteAsButton && (
                                        <BulkDelete
                                            disabled={false}
                                            buttonVariant={ButtonVariant.FILLED}
                                            onDeleting={onDeleting}
                                            numberSelectedShipments={numberSelectedShipments}
                                        />
                                    )}
                                    <Button
                                        onClick={handleEditServices}
                                        size={ButtonSize.SMALL}
                                        variant={ButtonVariant.OUTLINED}
                                        disabled={!canEditServices}
                                        data-id="bulk-edit-service-button"
                                    >
                                        {t('bulk-edit.actions.edit-service', { count: numberSelectedShipments })}
                                    </Button>
                                </section>
                            </section>
                            {isPendingInbox && (
                                <>
                                    <Typography component="p" variant="subtitle1" css={getMoreActionsTitleStyles}>
                                        {t('bulk-edit.actions.more-actions')}
                                    </Typography>

                                    <div css={getMoreActionsOptionStyles}>
                                        <Button
                                            onClick={handleEditCollectionDate}
                                            variant={ButtonVariant.TEXT}
                                            size={ButtonSize.SMALL}
                                            disabled={!collectionShipments.length || isOverMaxShipmentsAllowed}
                                            data-id="bulk-edit-collection-button"
                                        >
                                            {t('bulk-edit.actions.edit-collection-date')}
                                        </Button>

                                        {notCollectionShipments.length > 0 && (
                                            <IconPopover
                                                icon={IconNames.INFO}
                                                size={IconSize.SIZE_SMALL}
                                                css={getActionTooltipStyles}
                                            >
                                                <Typography variant="body2">
                                                    {collectionShipments.length === 0
                                                        ? t('bulk-edit.actions.collection-message-none', {
                                                              count: notCollectionShipments.length,
                                                          })
                                                        : t('bulk-edit.actions.collection-message', {
                                                              totalShipments: numberSelectedShipments,
                                                              nonEditableShipments: notCollectionShipments.length,
                                                              editableShipments: collectionShipments.length,
                                                          })}
                                                </Typography>
                                            </IconPopover>
                                        )}
                                    </div>

                                    {showInsuranceAction && (
                                        <ShipmentPanelBulkInsuranceAction
                                            onSetFlow={onSetFlow}
                                            isOverMaxShipmentsAllowed={isOverMaxShipmentsAllowed}
                                        />
                                    )}

                                    <div css={getMoreActionsOptionStyles}>
                                        <Button
                                            onClick={handleEditContent}
                                            variant={ButtonVariant.TEXT}
                                            size={ButtonSize.SMALL}
                                            data-id="bulk-edit-content-button"
                                        >
                                            {t('bulk-edit.actions.edit-content')}
                                        </Button>
                                    </div>

                                    <div css={getMoreActionsOptionStyles}>
                                        <Button
                                            onClick={handleEditParcels}
                                            variant={ButtonVariant.TEXT}
                                            size={ButtonSize.SMALL}
                                            data-id="bulk-edit-parcels-button"
                                        >
                                            {t('bulk-edit.actions.edit-parcels')}
                                        </Button>
                                    </div>

                                    {!showDeleteAsButton && (
                                        <div css={getMoreActionsOptionStyles}>
                                            <BulkDelete
                                                css={getBulkDeleteStyles}
                                                disabled={false}
                                                buttonVariant={ButtonVariant.TEXT}
                                                onDeleting={onDeleting}
                                                numberSelectedShipments={numberSelectedShipments}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {servicesWarningMessage && isPendingInbox && (
                        <div css={getWarningStyles}>
                            <InlineNotification type={NotificationType.ALERT}>
                                {servicesWarningMessage}
                            </InlineNotification>
                        </div>
                    )}
                    {showPrintAction && (
                        <BulkPrint
                            numberSelectedShipments={numberSelectedShipments}
                            onActionCompleted={timedClosePanel}
                            selectedLabel={
                                selectedShipments.length === 1 && selectedShipments[0].data?.labels
                                    ? selectedShipments[0].data.labels[0]
                                    : undefined
                            }
                        />
                    )}
                </>
            )}
            {isDeleting && !hasDeletedSuccessfully && (
                <SidePanelMessage
                    title={t('bulk-delete.actions.loading')}
                    type={SidePanelMessageType.LOADING}
                    css={getBulkActionsMessageStyles}
                />
            )}
            {hasDeletedSuccessfully && !isDeleting && (
                <SidePanelMessage
                    title={t('bulk-delete.actions.success')}
                    type={SidePanelMessageType.SUCCESS}
                    css={getBulkActionsMessageStyles}
                />
            )}
        </SidePanelContent>
    );
};
