import { FormikProps } from 'formik';
import { FC, useState } from 'react';

import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelContentError } from '@components/SidePanel/SidePanelContentError';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { IntegrationForm } from '@Integrations/components/IntegrationForm/IntegrationForm';
import {
    IntegrationFormItem,
    IntegrationFormItemSpacingSize,
} from '@Integrations/components/IntegrationForm/IntegrationFormItem';
import { IntegrationFormLogo } from '@Integrations/components/IntegrationForm/IntegrationFormLogo';
import { IntegrationDeactivate } from '@Integrations/components/IntegrationDeactivate/IntegrationDeactivate';
import { IIntegrationPanelContentProps } from '@Integrations/components/IntegrationPanel/types';
import { FormField } from '@shipengine/formik-giger';
import { InlineNotification, Input, NotificationType } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';

import { AliexpressIntegrationGuide } from './AliexpressIntegrationGuide';
import { AliexpressConfiguration, IAliexpressFormData } from './types';

type IAliexpressIntegrationPanelProps = Omit<IIntegrationPanelContentProps<AliexpressConfiguration>, 'onSubmit'>;

interface IAliexpressIntegrationFormProps extends IAliexpressIntegrationPanelProps {
    formProps: FormikProps<IAliexpressFormData>;
}

export const AliexpressIntegrationForm: FC<IAliexpressIntegrationFormProps> = (
    props: IAliexpressIntegrationFormProps,
): JSX.Element => {
    const {
        formProps: { values, isValid, errors, handleChange, handleBlur, touched, setFieldValue },
        integration,
        isLoading,
        onClose,
        onDeactivate,
        showError,
        clearError,
        onCancel,
    } = props;

    const { t } = useTranslation();
    const [successMessage, setSuccessMessage] = useState<string>();

    const handleCancel = (): void => {
        onCancel(integration.name);
    };

    const handleDeactivate = (): void => {
        onDeactivate(integration).then((integrationName?: string) => {
            if (integrationName) {
                setFieldValue('token', '');
                setFieldValue('api_key', '');
                setFieldValue('app_secret', '');
                setSuccessMessage(
                    t('integrations.panel.deactivation-success', {
                        integrationName,
                    }),
                );
            }
        });
    };

    return (
        <IntegrationForm>
            <SidePanelHeader
                onAction={onClose}
                title={t(integration.isConnected ? 'integrations.panel.edit' : 'integrations.panel.connect-shop')}
            />

            <SidePanelContent>
                {!showError && (
                    <>
                        {successMessage && (
                            <InlineNotification type={NotificationType.SUCCESS}>{successMessage}</InlineNotification>
                        )}
                        <IntegrationFormLogo logo={integration.logo} alt={integration.name} />
                        <IntegrationFormItem>
                            <FormField name="token">
                                <Input
                                    label={t('integrations.aliexpress.token')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={integration.isConnected}
                                    value={values.token}
                                    isInvalid={!!(errors.token && touched.token)}
                                />
                            </FormField>
                        </IntegrationFormItem>
                        <IntegrationFormItem size={IntegrationFormItemSpacingSize.None}>
                            <FormField name="api_key">
                                <Input
                                    label={t('integrations.aliexpress.api-key')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={integration.isConnected}
                                    value={values.api_key}
                                    isInvalid={!!(errors.api_key && touched.api_key)}
                                />
                            </FormField>
                        </IntegrationFormItem>
                        <IntegrationFormItem size={IntegrationFormItemSpacingSize.None}>
                            <FormField name="app_secret">
                                <Input
                                    label={t('integrations.aliexpress.app-secret')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={integration.isConnected}
                                    value={values.app_secret}
                                    isInvalid={!!(errors.app_secret && touched.app_secret)}
                                />
                            </FormField>
                        </IntegrationFormItem>
                        {integration.isConnected && (
                            <IntegrationFormItem size={IntegrationFormItemSpacingSize.Big}>
                                <IntegrationDeactivate handleDeactivate={handleDeactivate} />
                            </IntegrationFormItem>
                        )}
                        <AliexpressIntegrationGuide collapsible={true} collapsed={integration.isConnected} />
                    </>
                )}
                {showError && (
                    <SidePanelContentError
                        title={t('integrations.panel.error-title')}
                        subtitle={t('integrations.panel.error-subtitle')}
                        onTryAgain={clearError}
                    />
                )}
            </SidePanelContent>
            {!showError && (
                <SidePanelFooter>
                    <SidePanelFooterActions
                        onCancel={handleCancel}
                        actionButtonType="submit"
                        actionText={t('integrations.panel.connect')}
                        cancelText={t('integrations.panel.cancel')}
                        disabledAction={!isValid || integration.isConnected}
                        isLoading={isLoading}
                    />
                </SidePanelFooter>
            )}
        </IntegrationForm>
    );
};
