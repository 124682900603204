import { Trans, useTranslation } from '@packlink/translation-provider';
import { Button, ButtonSize } from '@shipengine/giger';
import { EmptyState } from '@components/EmptyState/EmptyState';
import { getEmptyListButtonSectionStyles } from './ShipmentListStyles';

interface IShipmentListError {
    onTryAgain: () => void;
}

export const ShipmentListError = (props: IShipmentListError): JSX.Element => {
    const { onTryAgain } = props;
    const { t } = useTranslation();
    const {
        cdn: { url: cdnUrl },
    } = config;
    const image = `${cdnUrl}/pro/statics/images/empty-state.png`;

    return (
        <EmptyState
            title={t('shipment-list.error.title')}
            subtitle={<Trans>shipment-list.error.subtitle</Trans>}
            image={image}
            imageBlackAndWhite
        >
            <section css={getEmptyListButtonSectionStyles}>
                <Button size={ButtonSize.SMALL} onClick={onTryAgain}>
                    {t('shipment-list.button.retry')}
                </Button>
            </section>
        </EmptyState>
    );
};
