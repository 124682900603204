import { useTranslation } from '@packlink/translation-provider';
import { useMemo } from 'react';
import { kebabCase } from 'lodash';
import { ISelectAutocompleteOption } from '@shipengine/giger';
import { ShippingRuleInputType } from '@types';
import { IShippingRuleOption } from '../types/formTypes';

interface IUseFormData {
    conditionOptions: IShippingRuleOption[];
    operatorsByConditionIdentifier: Record<string, ISelectAutocompleteOption[]>;
    numericConditionsOrActions: Record<string, number>;
    actionOptions: IShippingRuleOption[];
    operatorsByActionIdentifier: Record<string, ISelectAutocompleteOption[]>;
}

export function useFormData(): IUseFormData {
    const { t } = useTranslation();

    const { conditions, actions } = config.shippingRules;

    const conditionOptions = useMemo(
        () =>
            conditions.map((condition): IShippingRuleOption => {
                return {
                    ...condition,
                    label: t(`shipping-rules.condition-property.${kebabCase(condition.identifier)}`),
                    value: condition.identifier,
                };
            }),
        [conditions, t],
    );

    const operatorsByConditionIdentifier = useMemo(
        () =>
            Object.fromEntries(
                conditions.map((condition) => [
                    condition.identifier,
                    condition.operators.map((operator) => ({
                        label: t(`shipping-rules.operator.${kebabCase(operator)}`),
                        value: operator,
                    })),
                ]),
            ),
        [conditions, t],
    );

    const numericConditionsOrActions = useMemo(
        () =>
            Object.fromEntries(
                [...conditions, ...actions].map((item) => [
                    item.identifier,
                    item.inputType === ShippingRuleInputType.NUMBER ? item.step || 0 : 0,
                ]),
            ),
        [actions, conditions],
    );

    const actionOptions = useMemo(
        () =>
            actions.map((action): IShippingRuleOption => {
                return {
                    ...action,
                    label: t(`shipping-rules.action-property.${kebabCase(action.identifier)}`),
                    value: action.identifier,
                };
            }),
        [actions, t],
    );

    const operatorsByActionIdentifier = useMemo(
        () =>
            Object.fromEntries(
                actions.map((action) => [
                    action.identifier,
                    action.operators.map((operator) => ({
                        label: t(`shipping-rules.operator.${kebabCase(operator)}`),
                        value: operator,
                    })),
                ]),
            ),
        [actions, t],
    );

    return {
        conditionOptions,
        operatorsByConditionIdentifier,
        numericConditionsOrActions,
        actionOptions,
        operatorsByActionIdentifier,
    };
}
