import { AmplitudeEvents } from '@constants/amplitude';
import { useTranslation } from '@packlink/translation-provider';
import { IAddress } from '@packlink/packlink-sdk';
import { useCallback, useState } from 'react';

import { Address } from './Address';
import { SelectedAddress } from './SelectedAddress';
import {
    Button,
    ButtonColor,
    ButtonIcon,
    ButtonSize,
    ButtonVariant,
    Icon,
    IconSize,
    Typography,
} from '@shipengine/giger';
import { IconNames } from '@shipengine/giger-theme';
import {
    getChangeIconButtonStyles,
    getFloatingButtonStyles,
    getSelectAddressBodyStyles,
    getSelectAddressContainerStyles,
    getSelectAddressFooterStyles,
    getSelectAddressHeaderStyles,
    getSelectAddressStyles,
    getSelectAddressTitleStyles,
} from './SelectAddressStyles';
import { useAmplitude } from '@hooks/useAmplitude';

interface ISelectAddressProps {
    addresses: IAddress[];
    defaultAddress?: IAddress;
    onClear: (a?: IAddress) => void;
    onSelect: (a: IAddress) => void;
    selectedId?: string;
}

export function SelectAddress({
    addresses,
    onClear,
    onSelect,
    selectedId,
    defaultAddress,
}: ISelectAddressProps): JSX.Element {
    const { t } = useTranslation();
    const [isVisible, setVisibility] = useState(false);
    const [selectedAddress, setSelected] = useState(
        addresses.find(({ id }: IAddress) => id === selectedId) || defaultAddress,
    );
    const { sendAmplitudeClickEvent } = useAmplitude();

    const selectWarehouse = useCallback(
        (address: IAddress) => (): void => {
            setSelected(address);
            onSelect(address);
            setVisibility(false);
            if (!selectedId) {
                sendAmplitudeClickEvent(AmplitudeEvents.ADDRESS_BOOK_SELECTION, { ...address });
            }
        },
        [onSelect, selectedId, sendAmplitudeClickEvent],
    );

    const clearWarehouseSelection = useCallback(() => {
        onClear(undefined);
        setSelected(undefined);
        setVisibility(false);
    }, [onClear]);

    const toggleAddressSelector = useCallback(() => {
        setVisibility(!isVisible);
        if (!selectedId) {
            sendAmplitudeClickEvent(AmplitudeEvents.ADDRESS_BOOK_LINK);
        }
    }, [isVisible, selectedId, sendAmplitudeClickEvent]);

    const name = selectedId ? 'warehouse' : 'address-book';

    return (
        <div css={getSelectAddressStyles(name)}>
            {addresses.length > 0 && (!selectedAddress?.id || !selectedId) && (
                <Button
                    data-id="open-saved-addresses"
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.TEXT}
                    onClick={toggleAddressSelector}
                >
                    {t(`create-details.${name}.saved`, { addresses: addresses.length })}
                </Button>
            )}

            {selectedAddress && selectedId && <SelectedAddress address={selectedAddress} />}

            {selectedAddress?.firstName && selectedId && (
                <Button
                    data-id="change-address"
                    css={getFloatingButtonStyles}
                    size={ButtonSize.SMALL}
                    variant={ButtonVariant.TEXT}
                    onClick={(): void => setVisibility(!isVisible)}
                >
                    <Icon name={IconNames.EDIT} size={IconSize.SIZE_SMALL} css={getChangeIconButtonStyles} />
                    {t('CHANGE')}
                </Button>
            )}

            {isVisible && (
                <section role="tooltip" data-children={addresses.length} css={getSelectAddressContainerStyles}>
                    <header css={getSelectAddressHeaderStyles}>
                        <Typography variant="heading4" component="h6" css={getSelectAddressTitleStyles}>
                            {t(`create-details.${name}.saved`, { addresses: addresses.length })}
                        </Typography>
                        <ButtonIcon
                            css={getFloatingButtonStyles}
                            variant={ButtonVariant.TEXT}
                            icon={IconNames.CLOSE}
                            size={ButtonSize.SMALL}
                            color={ButtonColor.PRIMARY}
                            ariaDescribedby="select-address-close"
                            onClick={(): void => setVisibility(!isVisible)}
                            data-id="select-address-close"
                        >
                            {t('CLOSE')}
                        </ButtonIcon>
                    </header>

                    <div css={getSelectAddressBodyStyles}>
                        {addresses.map((address: IAddress) => (
                            <Address
                                key={address?.id}
                                address={address}
                                onClick={selectWarehouse(address)}
                                isSelected={!!address?.id && address?.id === selectedId}
                            />
                        ))}
                    </div>

                    <footer css={getSelectAddressFooterStyles}>
                        <Button
                            data-id="clear-warehouse"
                            size={ButtonSize.SMALL}
                            variant={ButtonVariant.TEXT}
                            onClick={clearWarehouseSelection}
                        >
                            {t(`select-address.${name}.send`)}
                        </Button>
                    </footer>
                </section>
            )}
        </div>
    );
}
