import { useNavigate } from 'react-router';
import { InlineNotification, Next, NotificationType, WithCommonProps } from '@shipengine/giger';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { Address, ClientFeature, IAddress } from '@packlink/packlink-sdk';
import { APP_ROUTE } from '@pages/router/routes';
import { useAmplitude, useSubscriptionFeature } from '@hooks';
import { AmplitudeEvents } from '@constants/amplitude';

type ShipmentDetailsEmailWarningProps = WithCommonProps<{
    address: Address<IAddress>;
}>;

export function ShipmentDetailsEmailWarning({ address, ...props }: ShipmentDetailsEmailWarningProps): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const { isTenantSubscriptionEnabled, feature } = useSubscriptionFeature(ClientFeature.BRANDED_EMAILS);
    const goToSubscriptionsPage = () => {
        const event = AmplitudeEvents.BRANDED_EMAIL_POST_SALE_PAGE;
        sendAmplitudeClickEvent(event);

        navigate(APP_ROUTE.SUBSCRIPTIONS.INDEX);
    };
    return (
        <InlineNotification type={NotificationType.INFO} {...props}>
            {isTenantSubscriptionEnabled && !feature.isEnabled ? (
                <Trans
                    i18nKey={'post-sale.advices.recipient-email-subscription-warning'}
                    tOptions={{ name: address.firstName + ' ' + address.lastName }}
                    components={[<Next.Link key="link" onClick={goToSubscriptionsPage} />]}
                />
            ) : (
                t('post-sale.advices.recipient-email-warning', {
                    name: address.firstName + ' ' + address.lastName,
                })
            )}
        </InlineNotification>
    );
}
