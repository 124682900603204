import { planTypes } from '@common/hooks/usePlanFeatures';
import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getPlanFeaturesWrapperStyles =
    (planType: string) =>
    (theme: Theme): SerializedStyles =>
        css({
            padding: theme.spacing(2),
            backgroundColor: planType === planTypes.FREE ? theme.palette.white : theme.palette.primary.ultraLight,
            marginBottom: planType !== planTypes.FREE ? theme.spacing(2) : 0,
        });

export const getPlanFeaturesGroupStyles = (theme: Theme): SerializedStyles =>
    css({
        ':not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    });

export const getPlanFeaturesGroupHeaderStyles = (): SerializedStyles =>
    css({
        listStyleType: 'none',
    });

export const getPlanFeaturesStyles =
    (isActive?: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            listStyleType: 'none',
            color: isActive ? 'auto' : theme.palette.gray.light,
        });

export const getFeatureStyles = (theme: Theme): SerializedStyles =>
    css({
        display: 'flex',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2),
        },
    });

export const getIconStyles =
    (isActive?: boolean) =>
    (theme: Theme): SerializedStyles =>
        css({
            color: isActive ? theme.palette.primary.light : theme.palette.gray.light,
            marginRight: theme.spacing(),
            marginTop: theme.spacing(0.5),
        });

export const getExcludedServicesStyles = (): SerializedStyles =>
    css({
        fontStyle: 'italic',
    });
